import { QueryClientProvider } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { HistoryRouter } from 'redux-first-history/rr6';

import { Routes } from './components/Routes';
import { SomethingWentWrong } from './components/somethingWentWrong';
import { ConfirmationGuardContextProvider } from './components/various/ConfirmationModal';
import { LanguageLoaderWrapper } from './components/various/LanguageLoaderWrapper';
import { PageTitle } from './components/various/PageTitle';
import { Toaster } from './components/various/Toasts';
import { queryClient } from './services/queryClient';
import { history } from './store/store';
import { store } from './store';

export const Main = () => (
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <LanguageLoaderWrapper>
        <ConfirmationGuardContextProvider>
          <ErrorBoundary FallbackComponent={SomethingWentWrong}>
            <PageTitle>
              <HistoryRouter history={history}>
                <Routes />
                <Toaster />
              </HistoryRouter>
            </PageTitle>
          </ErrorBoundary>
        </ConfirmationGuardContextProvider>
      </LanguageLoaderWrapper>
    </Provider>
  </QueryClientProvider>
);
