import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { pushEvent } from '../../../../../ducks';
import { getIsLoadingMoreProducts } from '../../../../../ducks/products';
import { ProductDetailsModalTrackingEvent } from '../../../../../utils/analytics/events';
import Icon, { IconType } from '../../../../various/Icon';
import { ProductDetailsModalContext } from '../../context/ProductDetailsModalContext';

import styles from './NavigationArrows.module.scss';

export const NavigationArrows = () => {
  const { t } = useTranslation(['products']);
  const dispatch = useDispatch();
  const isLoadingMoreProducts = useSelector(getIsLoadingMoreProducts);
  const { hasNextProduct, hasPreviousProduct, switchProduct } = React.useContext(ProductDetailsModalContext);

  const handleSwitchProduct = (direction: 'prev' | 'next') => {
    dispatch(pushEvent({ event: ProductDetailsModalTrackingEvent.ARROW_CLICKED }));
    switchProduct(direction);
  };

  return (
    <>
      {isLoadingMoreProducts && (
        <div className={styles.loadingMore}>
          <Icon type={IconType.Spinner} />
        </div>
      )}
      {hasPreviousProduct && (
        <button onClick={() => handleSwitchProduct('prev')} className={styles.switchButtonPrev} aria-label={t('products:previous_product')}>
          <Icon type={IconType.ArrowLeft} />
        </button>
      )}
      {hasNextProduct && (
        <button onClick={() => handleSwitchProduct('next')} className={styles.switchButtonNext} aria-label={t('products:next_product')}>
          <Icon type={IconType.ArrowLeft} />
        </button>
      )}
    </>
  );
};
