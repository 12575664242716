import { AttributeDescription, Overview } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getFilterFields, getProductListingAttributes } from '../../../ducks';
import { createOverviewAttributes } from '../../../logic/selectionOverview';
import { isEmpty } from '../../isEmpty';

const IGNORED_ATTRIBUTES = [
  'brandName',
  'brands',
  'categories',
  'collectionName',
  'collections',
  'deliveryWindows',
  'onlyAvailable',
  'productSku',
  'rrp',
  'sku',
  'variant_collection',
];

export const useSelectionOverviewAttributes = () => {
  const { t } = useTranslation(['common']);
  const listingAttributes = useSelector(getProductListingAttributes);
  const filterFields = useSelector(getFilterFields);

  const filteredListingAttributes = React.useMemo(() => {
    return listingAttributes.filter(({ key, name }) => !isEmpty(name) && !IGNORED_ATTRIBUTES.includes(key));
  }, [listingAttributes]);

  const filteredFilterFields: AttributeDescription[] = React.useMemo(() => {
    return filterFields
      .filter(({ field, name }) => !isEmpty(name) && !IGNORED_ATTRIBUTES.includes(field))
      .map(({ field, name }) => ({ key: field, name }));
  }, [filterFields]);

  const overviewAttributes: Record<string, Overview.Dimension> = React.useMemo(() => {
    return {
      ...createOverviewAttributes(filteredListingAttributes),
      ...createOverviewAttributes(filteredFilterFields),
      brandUri: {
        name: 'brandName',
        title: t('common:brand'),
        uri: 'brandUri',
      },
      categoryPathIds: {
        name: 'categoryName',
        title: t('common:category'),
        uri: 'categoryPathIds',
      },
      collectionUri: {
        name: 'collectionName',
        title: t('common:collection'),
        uri: 'collectionUri',
      },
    };
  }, [filteredListingAttributes, filteredFilterFields, t]);

  return overviewAttributes;
};
