import cx from 'classnames';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Icon, { IconType } from '../Icon';
import { Tooltip } from '../Tooltip';

interface Props {
  className?: string;
}

export const CancelledProductsTooltip = ({ className }: Props) => {
  const { t } = useTranslation(['cancelled']);

  const title = React.useMemo(
    () => (
      <Trans t={t} i18nKey="cancelled:cancelled_products_alert">
        Some of the products in the Selection <>have been cancelled</> and they need to be removed before the checkout.
      </Trans>
    ),
    [t],
  );

  return (
    <Tooltip content={title}>
      <div className={cx(className)}>
        <Icon type={IconType.Products} />
      </div>
    </Tooltip>
  );
};
