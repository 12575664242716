import { Cms } from '@typings';

import { ModalShell } from '../../various/ModalShell';

import { PageForm } from './PageForm';

interface Props {
  defaultValues?: Cms.PageDTO;
  title: string;
  isOpen: boolean;
  isLoading?: boolean;
  pageLanguages?: Cms.PageLanguage[];
  onClose: () => void;
  onClosed?: () => void;
  onSubmit: (values: Cms.PageDTO) => void;
}

export const PageFormModal = (props: Props) => {
  const { defaultValues, isLoading, title, isOpen, pageLanguages, onClose, onClosed, onSubmit } = props;

  return (
    <ModalShell title={title} isOpen={isOpen} onClose={onClose} modalClosedCallback={onClosed}>
      <PageForm defaultValues={defaultValues} isLoading={isLoading} pageLanguages={pageLanguages} onCancel={onClose} onSubmit={onSubmit} />
    </ModalShell>
  );
};
