import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useOnEscHandler, useScreenResize } from '../../../../utils/hooks';
import { useStyleEditorPosition } from '../../../../utils/hooks/cms/useStyleEditorPosition';
import { FocusTrap } from '../../../various/FocusTrap';
import Icon, { IconColor, IconType } from '../../../various/Icon';
import { ScrollOverlay } from '../../ScrollOverlay';

import styles from './EditorDialog.module.scss';

interface Props {
  id: string;
  isVisible: boolean;
  title: string;
  onClose: () => void;
}

export const EditorDialog = ({ children, id, isVisible, title, onClose }: React.WithChildren<Props>) => {
  const editorRef = React.useRef<HTMLDivElement>(null);
  const { t } = useTranslation(['common']);

  const { top, left } = useStyleEditorPosition(isVisible, editorRef);

  useOnEscHandler(document.documentElement, onClose, isVisible);
  useScreenResize(onClose);

  return (
    <>
      {isVisible && <ScrollOverlay className={styles.scrollOverlay} />}
      <div
        className={cx(styles.editor, { [styles.visible]: isVisible })}
        style={{ left, top }}
        role="dialog"
        aria-modal="true"
        aria-labelledby={id}
        ref={editorRef}
      >
        <FocusTrap>
          <div id={id} className={styles.headingWrapper}>
            <h2 className={styles.heading} data-testid="editorDialogHeader">
              {title}
            </h2>
            <button aria-label={t('common:close_modal')} className={styles.close} onClick={onClose}>
              <Icon type={IconType.Cancel} color={IconColor.Gray} className={styles.closeIcon} />
            </button>
          </div>
          <div className={styles.content}>{isVisible && children}</div>
        </FocusTrap>
      </div>
    </>
  );
};
