/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;
const ORIGINAL_COLOR = '#848484';

export const Dash = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => (
  <svg width={width} height={width} viewBox="0 0 20 20">
    <path
      d="M12.6539 10.8462H7.34615C7.16155 10.8462 7 10.4154 7 9.92307C7 9.43073 7.16155 9 7.34615 9H12.6539C12.8385 9 13 9.43079 13 9.92307C13 10.4154 12.8385 10.8462 12.6539 10.8462Z"
      fill={color}
    />
  </svg>
);
