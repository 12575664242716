import { Overview } from '@typings';
import React from 'react';

import { getSegmentWidth, PIE_CHART_SIZE as SIZE } from '../../../../logic/selectionOverview';
import { isNull } from '../../../../utils/is';

import { Text } from './Text';

interface Props {
  root: Overview.PieChartNode[];
  svgRef: React.RefObject<SVGSVGElement>;
  getPercentage: (node: Overview.PieChartNode) => string;
}

const MIN_SIZE_TO_SHOW_LABEL = 30;

export const TextGroup = ({ root, svgRef, getPercentage }: Props) => {
  const [textScale, setTextScale] = React.useState(1);

  React.useEffect(() => {
    setTextScale(getTextScale());
  }, [root]);

  const getTextScale = React.useCallback(() => {
    if (isNull(svgRef.current)) {
      return 1;
    }

    return svgRef.current.getBBox().width / SIZE;
  }, [svgRef]);

  const filterTextLabel = React.useCallback((node: Overview.PieChartNode) => {
    return getSegmentWidth(node) > MIN_SIZE_TO_SHOW_LABEL;
  }, []);

  return (
    <g pointerEvents="none" fontSize={13}>
      {root.filter(filterTextLabel).map(node => (
        <Text key={node.data.key} node={node} textScale={textScale} getPercentage={getPercentage} />
      ))}
    </g>
  );
};
