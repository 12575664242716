import { Cms } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { getCmsProducts, getCurrentPriceList, getIsLoadingCmsProducts, getProducts } from '../../../../../ducks';
import { areProductsInMarketsMatch } from '../../../../../logic/cms/markets';
import { convertCmsProductToStandard } from '../../../../../logic/products';
import { useIsInViewMode, useSpecifiedDeviceWidth } from '../../../../../utils/hooks';
import { useGetSectionByGroupId } from '../../../../../utils/hooks/cms/useGetSectionByGroupId';
import { isDefined, isNull } from '../../../../../utils/is';
import { isEmpty } from '../../../../../utils/isEmpty';

import { EditorHotspotItem } from './EditorHotspotItem';
import styles from './Hotspots.module.scss';
import { StandardHotspotItem } from './StandardHotspotItem';

interface Props {
  hotspots: Cms.Hotspots;
  blockSize: Cms.MediumSize;
  background: Cms.ContentBlockBackground;
  isInHotspotTool?: boolean;
  blockId: string;
  groupId: string | undefined;
}

export const Hotspots = ({ hotspots, blockSize, background, blockId, groupId, isInHotspotTool = false }: Props) => {
  const screenWidth = useSpecifiedDeviceWidth();
  const allProducts = useSelector(getProducts);
  const cmsProducts = useSelector(getCmsProducts);
  const isLoadingCmsProducts = useSelector(getIsLoadingCmsProducts);
  const section = useGetSectionByGroupId(groupId);
  const currentPriceList = useSelector(getCurrentPriceList);
  const { naturalImage } = hotspots.general;

  const isInEditor = useIsInViewMode('editor');
  const isInPreview = useIsInViewMode('preview');
  const isInCms = isInEditor || isInPreview;

  const products = React.useMemo(
    () => (isInCms ? convertCmsProductToStandard(cmsProducts, currentPriceList) : allProducts),
    [isInCms, cmsProducts, currentPriceList, allProducts],
  );

  const getHasInactiveProduct = React.useCallback(
    (productId: Nullable<string>) => {
      const isLoadingInitialProducts = isEmpty(cmsProducts) && isLoadingCmsProducts;

      if (isNull(productId) || isLoadingInitialProducts) {
        return false;
      }

      const missingProduct = !areProductsInMarketsMatch({
        productId,
        products,
        section,
      });

      return !isDefined(products[productId]) || missingProduct;
    },
    [cmsProducts, isLoadingCmsProducts, products, section],
  );

  const HotspotItem = isInHotspotTool ? EditorHotspotItem : StandardHotspotItem;

  return (
    <div className={cx({ [styles.hotspotsContainer]: !isInEditor })}>
      {hotspots.points.map(hotspot => {
        const { productId, id } = hotspot.general;
        const hasInactiveProduct = getHasInactiveProduct(productId);

        return (
          <HotspotItem
            key={id}
            background={background}
            blockSize={blockSize}
            hotspot={hotspot}
            blockId={blockId}
            hasInactiveProduct={hasInactiveProduct}
            naturalImage={naturalImage}
            screenWidth={screenWidth}
            products={products}
          />
        );
      })}
    </div>
  );
};
