import { Media, Picture, Product } from '@typings';
import i18next from 'i18next';
import { path, prop, uniqBy, zipObj } from 'ramda';
import { createSelector } from 'reselect';
import { Option } from 'space-lift';

import { isVariantRelation } from '../../logic/merchandise';
import { isDefined } from '../../utils/is';
import { getDefaultImageSize, getImageSizes } from '../config';
import { getProductInfo as getOrderProductInfo } from '../order/selectors';

export const DEFAULT_ASPECT_RATIO = 4 / 3;

function getProductTitle(product: Product.Standard | undefined): string | undefined {
  if (!isDefined(product)) {
    return;
  }

  return `${product.name} ${product.variantName}, ${i18next.t('common:art_no', {
    artNo: product.sku,
  })}`;
}

export function getProductImageOrPlaceholderBySize(product: Product.Standard, size: string, index = 0): Picture {
  const title = getProductTitle(product);

  return Option(path<string>(['media', size, index], product))
    .map(src => ({
      isPlaceholder: false,
      productId: product.product,
      src,
      title,
      variantId: product.variant,
    }))
    .getOrElse({
      isPlaceholder: true,
      productId: product.product,
      src: '',
      title: i18next.t('common:no_image'),
      variantId: product.variant,
    });
}

/**
 * returns array of Picture objects for given product and size or one placeholder image
 */
function getAllImagesOfGivenSizeOrPlaceholder(product: Product.Full | Product.Standard, imageSize: string): Picture[] {
  const title = getProductTitle(product);

  return Option(path<string[]>(['media', imageSize], product))
    .map(images =>
      images.map(
        (src): Picture => ({
          isPlaceholder: false,
          productId: product.product,
          src,
          title,
          variantId: product.variant,
        }),
      ),
    )
    .getOrElse([getProductImageOrPlaceholderBySize(product, imageSize)]);
}

/**
 * return all images of given size from main product all its variants
 */
const getImages = (product: Product.Full | Product.Standard, imageSize: string) => {
  const productImages = getAllImagesOfGivenSizeOrPlaceholder(product, imageSize);

  const variantImages = Option(path<Product.Full[]>(['relatedProducts', 'variants'], product))
    .map(variants => variants.filter(isVariantRelation).map(variant => getAllImagesOfGivenSizeOrPlaceholder(variant, imageSize)))
    .getOrElse([]);

  const flattened = ([] as Picture[]).concat(...variantImages);

  return [...productImages, ...flattened];
};

/**
 * return first image of given size from main product and all its variants
 */
export const getMainVariantImages = (product: Product.Full, imageSize: string): Picture[] => {
  const mainProductImage = getProductImageOrPlaceholderBySize(product, imageSize);

  const variantImages = Option(path<Product.Full[]>(['relatedProducts', 'variants'], product))
    .map(variants => variants.filter(isVariantRelation).map(variant => getProductImageOrPlaceholderBySize(variant, imageSize)))
    .getOrElse([]);

  return uniqBy(prop('src'), [mainProductImage, ...variantImages]);
};

export function hasImageSize(imageSizes: Media.Specification[], query: string): boolean {
  return imageSizes.some(x => x.imageSize === query);
}

const getImageSizeOrDefault = createSelector(
  [getImageSizes, getDefaultImageSize],
  (imageSizes: Media.Specification[], defaultSize: Media.Size) => (requestedImageSize: Media.Size) => {
    return hasImageSize(imageSizes, requestedImageSize) ? requestedImageSize : defaultSize;
  },
);

export const getAllImages = (productInfo: Product.Full | Product.Standard, requestedImageSize = '') =>
  createSelector(getImageSizeOrDefault, imageSizesOrDefaultSelector =>
    getImages(productInfo, imageSizesOrDefaultSelector(requestedImageSize)),
  );

export const getMainVariantImagesFromStore = (product: Product.Full) =>
  createSelector(getDefaultImageSize, defaultImageSize => getMainVariantImages(product, defaultImageSize));

export const createProductImageSelector = <T>(productSelector: (state: Store, product: T) => Product.Standard) =>
  createSelector([productSelector, getDefaultImageSize], getProductImageOrPlaceholderBySize) as (state: Store, product: T) => Picture;

const getProductImageOrPlaceholder = createProductImageSelector(getOrderProductInfo);

export const getImageForVariant = (variant: Product.Standard) =>
  createSelector(getDefaultImageSize, defaultImageSize => getProductImageOrPlaceholderBySize(variant, defaultImageSize));

export const getProductImages = (products: Record<string, Product.Full>) =>
  createSelector(
    Object.values(products).map(product => (state: Store) => getProductImageOrPlaceholder(state, product.product)),
    (...images) =>
      zipObj(
        Object.values(products).map(product => product.product),
        images,
      ),
  );

const getDefaultImageMedia = createSelector([getDefaultImageSize, getImageSizes], (defaultImageSize, imageSizes) =>
  imageSizes.find(media => media.imageSize === defaultImageSize),
);

export const getImageAspectRatio = createSelector([getDefaultImageMedia], defaultImageMedia =>
  defaultImageMedia !== undefined && defaultImageMedia.height > 0 && defaultImageMedia.width > 0 ?
    defaultImageMedia.height / defaultImageMedia.width
  : DEFAULT_ASPECT_RATIO,
);

export const getDefaultImageAspectRatio = (state: Store): number => getImageAspectRatio(state);
