import { DeliveryWindow } from '@typings';

const enum DelwinFilterTrackingEventWithParameters {
  GROUPS_RENDERED = 'delivery-window-filter-groups-rendered',
}

export const enum DelwinFilterTrackingEvent {
  MIXED_OPTIONS_RENDERED = 'delivery-window-filter-mixed-options-rendered',
  GROUP_FILTER_USED = 'delivery-window-filter-group-filter-used',
  DELWIN_FILTER_USED = 'delivery-window-filter-delwin-filter-used',
}

interface DelwinFilterGroupsRenderedEvent {
  deliveryWindowCount: number;
  groupCount: number;
  optionNames: string;
  stockType: DeliveryWindow.StockType | undefined;
}

export const getGroupsRenderedEvent = ({ deliveryWindowCount, groupCount, optionNames, stockType }: DelwinFilterGroupsRenderedEvent) => ({
  deliveryWindowCount,
  event: DelwinFilterTrackingEventWithParameters.GROUPS_RENDERED,
  groupCount,
  optionNames,
  stockType,
});
