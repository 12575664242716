import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Settings } from '@typings';
import { useTranslation } from 'react-i18next';

import { isDefined } from '../../../utils/is';
import { deleteFile, uploadFile } from '../../configRepository';
import { editCustomisationSettings } from '../../settingsRepository';

import { SETTINGS_QUERY_KEY } from './useSettings';

export const useEditCustomisationSettings = () => {
  const { t } = useTranslation(['settings']);
  const queryClient = useQueryClient();

  const mutationFn = async ([defaultValues, newValues]: [Partial<Settings.CustomisationFormFields>, Settings.CustomisationFormFields]) => {
    const { backgroundImage, logoImage, ...editPayload } = newValues;

    const getLogoImageMutation = async () => {
      if (defaultValues.logoImage === logoImage) {
        return;
      }

      if (!isDefined(logoImage)) {
        return deleteFile('logoImageDark').catch(() => {
          throw new Error(t('settings:company_logo_delete_failure'));
        });
      }

      return uploadFile({ file: logoImage, fileId: 'logoImageDark' }).catch(() => {
        throw new Error(t('settings:company_logo_update_failure'));
      });
    };

    const getBackgroundImageMutation = async () => {
      if (defaultValues.backgroundImage === backgroundImage) {
        return;
      }

      if (!isDefined(backgroundImage)) {
        return deleteFile('backgroundImage').catch(() => {
          throw new Error(t('settings:background_media_delete_failure'));
        });
      }

      return uploadFile({ file: backgroundImage, fileId: 'backgroundImage' }).catch(() => {
        throw new Error(t('settings:background_media_update_failure'));
      });
    };

    const results = await Promise.allSettled([
      editCustomisationSettings(editPayload),
      getLogoImageMutation(),
      getBackgroundImageMutation(),
    ]);

    const [editResult] = results;

    if (editResult.status === 'fulfilled') {
      return {
        errors: results.map(result => result.status === 'rejected' && result.reason.message).filter(Boolean),
        values: editResult.value,
      };
    }

    throw new Error(t('settings:customisation_update_fail'));
  };

  return useMutation({
    mutationFn,
    onSuccess: ({ values }) => {
      queryClient.setQueryData([SETTINGS_QUERY_KEY], values);
    },
  });
};
