import { Toast } from '@typings';
import React from 'react';

import { useHasCmsEditorBar, useHasSummariesBar } from '../../../utils/hooks';
import { isDefined, isNull } from '../../../utils/is';

import { ToastBar } from './ToastBar';
import styles from './Toaster.module.scss';
import { useToaster } from './useToaster';

interface Props {
  toast: Toast;
}

const DEFAULT_POSITION: Toast.Position = 'top-right';
const SUMMARY_BAR_OFFSET = 65;

export const ToastWrapper = ({ toast }: Props) => {
  const { handlers } = useToaster();
  const hasSummariesBar = useHasSummariesBar();
  const hasCmsEditorBar = useHasCmsEditorBar();
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (isDefined(toast.height) || isNull(ref.current)) {
      return;
    }

    const boundingRect = ref.current.getBoundingClientRect();
    handlers.updateHeight(toast.id, boundingRect.height);
  }, [toast, ref]);

  const toastPosition = toast.position || DEFAULT_POSITION;
  const offset = handlers.calculateOffset(toast, DEFAULT_POSITION);

  const positionStyle = React.useMemo((): React.CSSProperties => {
    const top = toastPosition.includes('top');
    const right = toastPosition.includes('right');

    const verticalStyle: React.CSSProperties =
      top ?
        { top: 0 }
      : {
          bottom: hasSummariesBar ? SUMMARY_BAR_OFFSET : 0,
        };
    const horizontalStyle: React.CSSProperties = right ? { justifyContent: 'flex-end' } : {};

    const cmsStyles: React.CSSProperties = hasCmsEditorBar ? { right: -16, top: 55 } : {};

    return {
      transform: `translateY(${offset * (top ? 1 : -1)}px)`,
      ...verticalStyle,
      ...horizontalStyle,
      ...cmsStyles,
    };
  }, [toastPosition, hasSummariesBar, hasCmsEditorBar, offset]);

  return (
    <div ref={ref} className={styles.toast} style={positionStyle} data-testid="toast">
      <ToastBar toast={toast} position={toastPosition} />
    </div>
  );
};
