import { omit, without } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Sorting } from '../../../../../typings';
import { getIsProductSortingEnabled, getIsStockTypeSeparationEnabled, getProductSortingFields } from '../../../ducks/config';
import { downloadOrderExcel } from '../../../ducks/documents';
import { addAllProductsToOrderRequest } from '../../../ducks/order';
import { getIsLoadingOrderDetails, getOrderDetails, getOrderProductIds } from '../../../ducks/order/selectors';
import {
  getCurrentOrDefaultProductSortingValue,
  getIsAnyProductLoading,
  getProductFilters,
  getProductIds,
  getProductsTotalItems,
  setProductSortingValue,
} from '../../../ducks/products';
import { pushEvent } from '../../../ducks/track';
import { getIsProductsGridView, getIsSavingAnything, setProductsGridView, setProductsListView } from '../../../ducks/ui';
import { getProductListingSwitcherClickedEvent, getSortBySelectedEvent, TopBarTrackingEvent } from '../../../utils/analytics/events';
import { useRemoveAllProducts } from '../../../utils/hooks';
import { useAmplitude } from '../../../utils/hooks/useAmplitude';
import { useConfirmationGuard } from '../../../utils/hooks/useConfirmationGuard';
import { DownloadLinesheet } from '../../orders/DownloadLinesheet';
import { ClearAllButton } from '../../various/ClearAllButton/ClearAllButton';
import { ContentSwitcher } from '../../various/ContentSwitcher';
import { IconType } from '../../various/Icon';
import { IconButton } from '../../various/IconButton/IconButton';
import { PopoverMenu } from '../../various/PopoverMenu';
import { ActionButton } from '../ActionButton';
import { FiltersBar } from '../FiltersBar';
import { ProductSorting } from '../ProductSorting';

import styles from './ProductsActions.module.scss';

const ProductsActions = () => {
  const { t } = useTranslation(['common', 'confirmationConfig', 'products', 'orders']);
  const isProductsGridView = useSelector(getIsProductsGridView);
  const isLoadingOrderDetails = useSelector(getIsLoadingOrderDetails);
  const isSaving = useSelector((state: Store) => getIsSavingAnything(state) || getIsAnyProductLoading(state));
  const filters = useSelector(getProductFilters);
  const productIds = useSelector(getProductIds);
  const orderDetails = useSelector(getOrderDetails);
  const orderProductIds = useSelector(getOrderProductIds);
  const totalItems = useSelector(getProductsTotalItems);
  const isStockTypeSeparationEnabled = useSelector(getIsStockTypeSeparationEnabled);
  const isProductSortingEnabled = useSelector(getIsProductSortingEnabled);
  const productSortingFields = useSelector(getProductSortingFields);
  const productSortingValue = useSelector(getCurrentOrDefaultProductSortingValue);
  const dispatch = useDispatch();
  const { removeAllProducts, canRemoveAllProducts } = useRemoveAllProducts();
  const confirmationGuard = useConfirmationGuard();
  const { trackEvent } = useAmplitude();

  const handleAddProducts = React.useCallback(() => {
    const filtersToApply = isStockTypeSeparationEnabled ? filters : omit(['stockType'], filters);

    dispatch(pushEvent({ event: TopBarTrackingEvent.ADD_ALL_CLICKED }));
    trackEvent({ name: 'listing.button.addall.click' });

    dispatch(
      addAllProductsToOrderRequest({
        filters: filtersToApply,
        products: without(orderProductIds, productIds),
      }),
    );
  }, [dispatch, filters, isStockTypeSeparationEnabled, orderProductIds, productIds, trackEvent]);

  const addAllProducts = confirmationGuard(() => ({
    okText: t('common:ok'),
    okType: 'info',
    onOk: handleAddProducts,
    ...t('confirmationConfig:add_all_products', { returnObjects: true }),
  }));

  const setGridView = React.useCallback(() => {
    dispatch(pushEvent(getProductListingSwitcherClickedEvent('grid')));
    dispatch(setProductsGridView());
  }, [dispatch]);

  const setListView = React.useCallback(() => {
    dispatch(pushEvent(getProductListingSwitcherClickedEvent('list')));
    dispatch(setProductsListView());
  }, [dispatch]);

  const handleViewTypeChange = React.useCallback(
    (type: string) => {
      const isGridType = type === 'viewAsGrid';
      isGridType ? setGridView() : setListView();
    },
    [setGridView, setListView],
  );

  const handleDownloadOrderExcel = React.useCallback(() => {
    const { order } = orderDetails;
    dispatch(pushEvent({ event: TopBarTrackingEvent.DOWNLOAD_ORDER_CLICKED }));
    dispatch(
      downloadOrderExcel({
        orderId: order.order,
        orderNumber: order.orderNumber,
      }),
    );
  }, [orderDetails, dispatch]);

  const handleSortingChange = React.useCallback(
    (value: Sorting.FieldValue) => {
      dispatch(pushEvent(getSortBySelectedEvent(value)));
      dispatch(setProductSortingValue(value));
      trackEvent({ name: 'listing.select.sortby', properties: { sortBy: value } });
    },
    [dispatch, trackEvent],
  );

  const { canModify } = orderDetails.order;

  const hideAddAllProductsButton = totalItems === 0;

  const downloadOrderDetailsAction = {
    isDisabled: isLoadingOrderDetails,
    key: 'downloadOrderDetails',
    label: t('orders:download_order_details'),
    onClick: handleDownloadOrderExcel,
  };

  const actions = [
    ...(isProductsGridView ?
      [
        {
          key: 'viewAsList',
          label: t('common:view_as_list'),
          onClick: setListView,
        },
      ]
    : [
        {
          key: 'viewAsGrid',
          label: t('common:view_as_grid'),
          onClick: setGridView,
        },
      ]),
    { ...downloadOrderDetailsAction },
    {
      isDisabled: hideAddAllProductsButton || isLoadingOrderDetails,
      key: 'addAllProducts',
      label: t('products:add_all_products'),
      onClick: addAllProducts,
    },
    {
      isDisabled: !canRemoveAllProducts || isLoadingOrderDetails,
      key: 'clearAllProducts',
      label: t('products:clear_all_products'),
      onClick: removeAllProducts,
    },
    ...(isProductSortingEnabled ?
      [
        {
          defaultKey: 'default',
          items: productSortingFields.map(field => ({ key: field.field, label: field.name })),
          key: 'sortBy',
          label: t('common:sort_by'),
          onChange: handleSortingChange,
          selectedKey: productSortingValue,
        },
      ]
    : []),
  ];

  const tabs = [
    {
      icon: IconType.GridView,
      title: t('common:view_as_grid'),
      value: 'viewAsGrid',
    },
    {
      icon: IconType.ListView,
      title: t('common:view_as_list'),
      value: 'viewAsList',
    },
  ];

  return (
    <>
      <div className={styles.filters}>
        <FiltersBar />
      </div>
      <div className={styles.rightToolbar}>
        {canModify && (
          <>
            <ActionButton isHidden={hideAddAllProductsButton} title={t('common:add_all')} onClick={addAllProducts} isDisabled={isSaving} />
            <ClearAllButton />
          </>
        )}
        {isProductSortingEnabled && <ProductSorting onSelect={handleSortingChange} />}
        <IconButton
          icon={IconType.OrderDetails}
          name={t('orders:download_order_details')}
          showTooltip
          variant="secondary"
          tooltipPlacement="bottom-center"
          onClick={handleDownloadOrderExcel}
          disabled={isLoadingOrderDetails}
        />
        <div className={styles.downloadLinesheetButton}>
          <DownloadLinesheet />
        </div>
        <ContentSwitcher
          groupName={t('common:product_view')}
          tabs={tabs}
          selectedValue={isProductsGridView ? 'viewAsGrid' : 'viewAsList'}
          onChange={handleViewTypeChange}
          showTooltip
          variant="secondary"
        />
        <div className={styles.mobileTools}>
          <PopoverMenu
            classNameRegular={styles.mobileToolsButton}
            containerClassName={styles.popover}
            overflowContainer
            name={t('common:more_options')}
            size="small"
            items={canModify ? actions : [downloadOrderDetailsAction]}
            icon={IconType.MoreHorizontal}
          />
        </div>
      </div>
    </>
  );
};

export default ProductsActions;
