import { skipToken, useQuery } from '@tanstack/react-query';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchUserRequest, getUser, setDeliveryWindows, setLookbookToken } from '../../../ducks';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { lookbookLogin } from '../../lookbookRepository';
import persistentStorage from '../../persistentStorage';

export const useLookbookLogin = (lookbookId: string | undefined) => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);

  const query = useQuery({
    queryFn: isDefined(lookbookId) ? async () => lookbookLogin(lookbookId) : skipToken,
    queryKey: ['LOOKBOOK_LOGIN', { lookbookId }],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  React.useEffect(() => {
    if (!isDefined(query.data) || !isEmpty(user)) {
      return;
    }

    dispatch(setDeliveryWindows(query.data.deliveryWindows));

    const hasLocalToken = isDefined(persistentStorage.getItem('token'));
    dispatch(hasLocalToken ? fetchUserRequest() : setLookbookToken(query.data));
  }, [dispatch, query.data, user]);

  return query;
};
