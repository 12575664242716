import { Cms } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { isDefined } from '../../../../utils/is';
import { renderValue } from '../../../../utils/renderValue';

import styles from './PreviouslyTableCell.module.scss';

interface Props<T extends keyof Cms.Page> {
  page: Cms.Page;
  property: T;
  render?: (value: Cms.Page[T]) => React.ReactNode;
}

export const PreviouslyTableCell = ({ page, property, render = renderValue }: Props<keyof Cms.Page>) => {
  const { t } = useTranslation(['cms']);

  const { snapshot } = page;
  const currentValue = page[property];
  const previousValue = isDefined(snapshot) ? snapshot[property] : undefined;
  const valueChanged = JSON.stringify(previousValue) !== JSON.stringify(currentValue);
  const showPreviousValue = isDefined(previousValue) && valueChanged && page.status === 'published_with_changes';

  return (
    <>
      {showPreviousValue && (
        <div className={styles.previousValue}>
          <span className={styles.previouslyLabel}>{t('cms:published')}:&nbsp;</span>
          {render(previousValue)}
        </div>
      )}
    </>
  );
};
