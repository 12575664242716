import { Filter, Filters } from '@typings';
import React from 'react';

interface FiltersPanelSelectors {
  getAttributes: (store: Store) => Filter.Field<string>[];
  getExpandedFilters: (store: Store) => Record<string, boolean>;
  getFilters: (store: Store) => Partial<Filters>;
  getHasActiveFilters: (store: Store) => boolean;
}

const throwError = () => {
  throw new Error('No FiltersPanelSelectorsContext available');
};

export const FiltersPanelSelectorsContext = React.createContext<FiltersPanelSelectors>({
  getAttributes: throwError,
  getExpandedFilters: throwError,
  getFilters: throwError,
  getHasActiveFilters: throwError,
});
