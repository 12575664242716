import cx from 'classnames';
import React from 'react';

import styles from './ModalHeading.module.scss';

interface ModalHeadingProps {
  id?: string;
  className?: string;
  smallPadding?: boolean;
}

export const ModalHeading = ({ id, className, children, smallPadding }: React.WithChildren<ModalHeadingProps>) => {
  return (
    <div className={styles.headingWrapper}>
      <h2 data-testid="modalNameHeader" id={id} className={cx(styles.heading, { [styles.smallPadding]: smallPadding }, className)}>
        {children}
      </h2>
    </div>
  );
};
