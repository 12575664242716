import { Id } from '@typings';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';

import { getIsLoadingMoreProducts, getProductsOrder, getProductsTotalItems, switchDisplayedProduct } from '../../../../ducks';
import { paths } from '../../../../paths';
import { isDefined } from '../../../../utils/is';
import { isEmpty } from '../../../../utils/isEmpty';

interface Props {
  isInDetailsModal: boolean;
  productId: Id | undefined;
}

interface Context {
  drawerScrollRef: React.RefObject<HTMLDivElement>;
  hasNextProduct: boolean;
  hasPreviousProduct: boolean;
  isInDetailsModal: boolean;
  setShouldScrollToEtaMatrix: React.Dispatch<React.SetStateAction<boolean>>;
  shouldScrollToEtaMatrix: boolean;
  switchProduct: (direction: 'prev' | 'next') => void;
}

const initialContext: Context = {
  drawerScrollRef: { current: null },
  hasNextProduct: false,
  hasPreviousProduct: false,
  isInDetailsModal: false,
  setShouldScrollToEtaMatrix: () => null,
  shouldScrollToEtaMatrix: false,
  switchProduct: () => null,
};

export const ProductDetailsModalContext = React.createContext<Context>(initialContext);

export const ProductDetailsModalContextProvider = ({ children, isInDetailsModal, productId }: React.WithChildren<Props>) => {
  const dispatch = useDispatch();

  const productsList = useSelector(getProductsOrder);
  const isLoadingMoreProducts = useSelector(getIsLoadingMoreProducts);
  const totalItems = useSelector(getProductsTotalItems);
  const hasProductId = isDefined(productId) && !isEmpty(productId);

  const drawerScrollRef = React.useRef<HTMLDivElement>(null);

  const isAllProductsPage = useMatch(paths.PRODUCTS_ORDER)?.pattern.end ?? false;
  const [shouldScrollToEtaMatrix, setShouldScrollToEtaMatrix] = React.useState(false);

  const switchProduct = React.useCallback(
    (direction: 'prev' | 'next') => {
      dispatch(switchDisplayedProduct(direction));
    },
    [dispatch],
  );

  const shouldAllowNavigation = isAllProductsPage && hasProductId;
  const hasPreviousProduct = shouldAllowNavigation && hasProductId && productsList.indexOf(productId) > 0;
  const hasNextProduct =
    shouldAllowNavigation && !isLoadingMoreProducts && hasProductId && productsList.indexOf(productId) < totalItems - 1;

  return (
    <ProductDetailsModalContext.Provider
      value={{
        drawerScrollRef,
        hasNextProduct,
        hasPreviousProduct,
        isInDetailsModal,
        setShouldScrollToEtaMatrix,
        shouldScrollToEtaMatrix,
        switchProduct,
      }}
    >
      {children}
    </ProductDetailsModalContext.Provider>
  );
};
