import { DatePicker } from '@typings';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';

import { MONTHS_IN_YEAR } from '../../../../constants/dates';
import { getCalendarDates, getCalendarMonthDates, getCalendarYearDates } from '../../../../utils/dates';
import { isNull } from '../../../../utils/is';

import { DatePickerSettingsContext } from './DatePickerSettingsContext';

interface Props {
  defaultVisibleDate: DatePicker.SingleValue;
}

interface Context {
  calendarDates: Dayjs[];
  onNextClick: () => void;
  onPrevClick: () => void;
  onSuperNextClick: () => void;
  onSuperPrevClick: () => void;
  setVisibleDate: React.Dispatch<React.SetStateAction<Dayjs>>;
  visibleDate: Dayjs;
}

const initialContext: Context = {
  calendarDates: [],
  onNextClick: () => null,
  onPrevClick: () => null,
  onSuperNextClick: () => null,
  onSuperPrevClick: () => null,
  setVisibleDate: () => null,
  visibleDate: dayjs(),
};

export const DatePickerNavigationContext = React.createContext<Context>(initialContext);

export const DatePickerNavigationContextProvider = ({ children, defaultVisibleDate }: React.WithChildren<Props>) => {
  const { isMonthView, isYearView, max, min } = React.useContext(DatePickerSettingsContext);
  const [visibleDate, setVisibleDate] = React.useState(defaultVisibleDate ?? dayjs());

  React.useEffect(() => {
    if (!isNull(defaultVisibleDate)) {
      return;
    }

    const now = dayjs();

    if (!isNull(min) && now.isBefore(min)) {
      return setVisibleDate(min);
    }

    if (!isNull(max) && now.isAfter(max)) {
      return setVisibleDate(max);
    }
  }, [max, min, defaultVisibleDate]);

  const onPrevClick = React.useCallback(() => {
    setVisibleDate(visibleDate.clone().subtract(1, 'month'));
  }, [visibleDate]);

  const onNextClick = React.useCallback(() => {
    setVisibleDate(visibleDate.clone().add(1, 'month'));
  }, [visibleDate]);

  const onSuperPrevClick = React.useCallback(() => {
    setVisibleDate(isYearView ? visibleDate.clone().subtract(MONTHS_IN_YEAR, 'year') : visibleDate.clone().subtract(1, 'year'));
  }, [isYearView, visibleDate]);

  const onSuperNextClick = React.useCallback(() => {
    setVisibleDate(isYearView ? visibleDate.clone().add(MONTHS_IN_YEAR, 'year') : visibleDate.clone().add(1, 'year'));
  }, [isYearView, visibleDate]);

  const calendarDates = React.useMemo(() => {
    const visibleDateMonth = visibleDate.month();
    const visibleDateYear = visibleDate.year();

    if (isYearView) {
      return getCalendarYearDates(visibleDateYear);
    }

    if (isMonthView) {
      return getCalendarMonthDates(visibleDateYear);
    }

    return getCalendarDates(visibleDateMonth, visibleDateYear);
  }, [isMonthView, isYearView, visibleDate]);

  return (
    <DatePickerNavigationContext.Provider
      value={{
        calendarDates,
        onNextClick,
        onPrevClick,
        onSuperNextClick,
        onSuperPrevClick,
        setVisibleDate,
        visibleDate,
      }}
    >
      {children}
    </DatePickerNavigationContext.Provider>
  );
};
