import cx from 'classnames';

import styles from './ProductsList.module.scss';

interface Props {
  isGridView: boolean;
  children: React.ReactNode;
  className?: string;
}

export const ProductsView = ({ isGridView, children, className }: Props) => {
  const baseClassName = isGridView ? styles.gridView : styles.listView;

  return <div className={cx(baseClassName, className)}>{children}</div>;
};
