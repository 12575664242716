import { Id } from '@typings';
import React from 'react';
import { useSelector } from 'react-redux';

import { getActiveDeliveryWindowsIdsForOrderBuyer } from '../../../../ducks';
import { isDefined } from '../../../../utils/is';

interface Props {
  deliveryWindowId: Id;
  isFirstDelWin: boolean;
}

interface Context {
  deliveryWindowId: Id;
  isDeliveryWindowExpired: boolean;
  isFirstDelWin: boolean;
}

export const useMatrixDeliveryWindowContext = (): Context => {
  const context = React.useContext(MatrixDeliveryWindowContext);

  if (!isDefined(context)) {
    throw new Error('MatrixDeliveryWindowContext can not be used outside the scope of MatrixDeliveryWindowContextProvider');
  }

  return context;
};

export const MatrixDeliveryWindowContext = React.createContext<Context | null>(null);

export const MatrixDeliveryWindowContextProvider = (props: React.WithChildren<Props>) => {
  const { children, deliveryWindowId, isFirstDelWin } = props;
  const activeDeliveryWindowsIds = useSelector(getActiveDeliveryWindowsIdsForOrderBuyer);
  const isDeliveryWindowExpired = !activeDeliveryWindowsIds.includes(deliveryWindowId);

  return (
    <MatrixDeliveryWindowContext.Provider
      value={{
        deliveryWindowId,
        isDeliveryWindowExpired,
        isFirstDelWin,
      }}
    >
      {children}
    </MatrixDeliveryWindowContext.Provider>
  );
};
