import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getCancelledOrderProducts } from '../../../../ducks/order';
import { isEmpty } from '../../../../utils/isEmpty';
import { CancelledProductsAlert } from '../../../orders/CancelledProductsAlert';

interface Props {
  hasProducts: boolean;
}

export const CancelledOrderProductsAlert = ({ hasProducts }: Props) => {
  const { t } = useTranslation(['cancelled']);
  const cancelledOrderProducts = useSelector(getCancelledOrderProducts);

  const cancelledProductsInfo = React.useMemo(() => {
    if (hasProducts) {
      return (
        <Trans t={t} i18nKey="cancelled:cancelled_products_some">
          Some products in the order <b>have been cancelled</b>. You may revise cancelled products below.
        </Trans>
      );
    }

    return (
      <Trans t={t} i18nKey="cancelled:cancelled_products_all">
        All products in the order <b>have been cancelled</b>. You may revise cancelled products below.
      </Trans>
    );
  }, [hasProducts, t]);

  return <>{!isEmpty(cancelledOrderProducts) && <CancelledProductsAlert>{cancelledProductsInfo}</CancelledProductsAlert>}</>;
};
