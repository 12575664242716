import * as yup from 'yup';

import { getHasConfidentialId, getIsCmsEditor, getIsSameHostname } from '../../logic/validation';
import i18n from '../../translation/i18n';
import { DEFAULT_ALLOWED_PROTOCOLS, isSafeUrl, Protocol } from '../../utils/safeUrl';

interface Args {
  allowedProtocols?: Protocol[];
  isSameHostNameCheck?: boolean;
  hasConfidentialIdCheck?: boolean;
  isCmsEditorCheck?: boolean;
}

export const getUrlSchema = ({
  allowedProtocols = [...DEFAULT_ALLOWED_PROTOCOLS],
  isSameHostNameCheck = false,
  hasConfidentialIdCheck = false,
  isCmsEditorCheck = false,
}: Args = {}) => {
  return yup
    .string()
    .required(i18n.t('validation:url_hint'))
    .test('Valid URL', i18n.t('validation:valid_url_hint'), url => isSafeUrl(url, allowedProtocols))
    .test('No IDs', i18n.t('validation:no_ids_in_url_hint'), url =>
      !hasConfidentialIdCheck ? true : !getHasConfidentialId(url) || !getIsSameHostname(url),
    )
    .test('No editor URLs', i18n.t('validation:no_cms_editor_url_hint'), url =>
      !isCmsEditorCheck ? true : !getIsCmsEditor(url) || !getIsSameHostname(url),
    )
    .test('No same domain', i18n.t('validation:url_same_domain_hint'), (url: string) =>
      !isSameHostNameCheck ? true : !getIsSameHostname(url),
    );
};
