import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import universalStyles from '../../../../css/utilities/universal.module.scss';
import { getOpenOrClosedOrder, getUserNavigation } from '../../../ducks';
import { getHasActivePageChild, getIsUserFolderMenuItem } from '../../../logic/navigation';
import { getIsOrderClosed } from '../../../logic/Orders';
import { getIsViewer } from '../../../logic/User';
import { useDebouncedCallback, useResizeObserver } from '../../../utils/hooks';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { NavigationItem } from '../../navigation/NavigationItem';

import { MoreFolder } from './MoreFolder';
import { NavigationDropdown } from './NavigationDropdown';
import styles from './TopMenu.module.scss';

const DEBOUNCE_TIME = 100;

export const TopMenu = () => {
  const { t } = useTranslation(['common']);
  const isViewer = useSelector(getIsViewer);
  const orderStatus = useSelector(getOpenOrClosedOrder);
  const isOrderClosed = getIsOrderClosed(orderStatus);
  const navigationItems = useSelector(getUserNavigation);
  const { pathname } = useLocation();
  const [visibleItems, setVisibleItems] = React.useState(navigationItems);
  const navWrapper = React.useRef<null | HTMLDivElement>(null);
  const listWrapper = React.useRef<null | HTMLUListElement>(null);

  const itemProps = {
    activeClassName: styles.active,
    linkClassName: styles.link,
  };

  const resizeHandler = useDebouncedCallback(
    () => {
      setVisibleItems(navigationItems);
    },
    DEBOUNCE_TIME,
    [navigationItems],
  );

  const updateVisibleItems = React.useCallback(() => {
    setVisibleItems(getVisibleItems());
  }, [visibleItems]);

  useResizeObserver(listWrapper, updateVisibleItems, [visibleItems]);

  React.useEffect(updateVisibleItems, [visibleItems]);

  React.useEffect(() => {
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [navigationItems]);

  const getVisibleItems = () => {
    if (!isDefined(listWrapper.current) || !isDefined(navWrapper.current)) {
      return visibleItems;
    }

    const childrenNodes = Array.from(listWrapper.current.children) as HTMLElement[];
    const parentHeight = navWrapper.current.offsetHeight;

    const hiddentItemsCount = childrenNodes.filter(node => node.offsetTop >= parentHeight).length;

    return hiddentItemsCount === 0 ? visibleItems : visibleItems.slice(0, visibleItems.length - hiddentItemsCount);
  };

  if (isViewer && isOrderClosed) {
    return null;
  }

  return (
    <div className={styles.topNavigation}>
      <nav className={styles.nav} ref={navWrapper}>
        <h2 className={universalStyles.srOnly}>{t('common:main_menu')}</h2>
        <ul ref={listWrapper}>
          {visibleItems.map(item => {
            if (!isDefined(item.children) || isEmpty(item.children)) {
              return (
                <li key={item.label}>
                  <NavigationItem item={item} {...itemProps} />
                </li>
              );
            }

            const items = item.children.map(element => (
              <li key={element.label}>
                <NavigationItem item={element} {...itemProps} />
              </li>
            ));
            const hasActivePageChild = getHasActivePageChild(item, pathname);
            const isFolder = getIsUserFolderMenuItem(item);

            return (
              <NavigationDropdown
                key={item.label}
                dropdownItems={items}
                isFolder={isFolder}
                moreLabel={t('common:more_in', { parent: item.label })}
                {...itemProps}
              >
                <NavigationItem
                  linkClassName={cx(itemProps.linkClassName, {
                    [styles.folder]: isFolder && !hasActivePageChild,
                  })}
                  activeClassName={itemProps.activeClassName}
                  active={hasActivePageChild}
                  item={item}
                />
              </NavigationDropdown>
            );
          })}
          <MoreFolder visibleItems={visibleItems} navigationItems={navigationItems} />
        </ul>
      </nav>
    </div>
  );
};
