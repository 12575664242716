import { Cms, Id } from '@typings';
import { createAction } from 'typesafe-actions';

export const updateBlockSettings = createAction('cms/UPDATE_BLOCK_SETTINGS')<{
  blockId: Id;
  updatePath: (string | number)[];
  value: unknown;
}>();

export const updateMultipleBlocksSettings = createAction('cms/UPDATE_MULTIPLE_BLOCKS_SETTINGS')<
  { blockId: Id; updatePath: (string | number)[]; value: unknown }[]
>();

export const setTextPart = createAction('cms/SET_TEXT_PART')<{
  blockId: Id;
  value: Cms.ContentBlockTextPart;
}>();
export const setButtonPart = createAction('cms/SET_BUTTON_PART')<{
  blockId: Id;
  value: Cms.ContentBlockButtonPart;
}>();
export const removeContentParts = createAction('cms/REMOVE_CONTENT_PARTS')<{
  blockId: Id;
  partIds: Id[];
}>();
export const moveContentPart = createAction('cms/MOVE_CONTENT_PART')<{
  blockId: Id;
  index: number;
  offset: number;
}>();
export const replaceCustomStyles = createAction('cms/REPLACE_CUSTOM_STYLES')<Record<string, Cms.ContentPartStyle>>();
