/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;
const ORIGINAL_COLOR = '#848484';

export const Clear = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => (
  <svg width={width} height={width} viewBox="0 0 20 20" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.424 12.3491L7.06282 8.98787L3.55149 12.4992C3.47339 12.5773 3.47339 12.7039 3.55149 12.782L6.62987 15.8604C6.70798 15.9385 6.83461 15.9385 6.91271 15.8604L10.424 12.3491ZM13.0556 3.27795C12.1964 2.41879 10.8035 2.41879 9.94431 3.27794L2.13728 11.085C1.27812 11.9441 1.27812 13.3371 2.13728 14.1963L5.21566 17.2746C5.68544 17.7444 6.31481 17.9573 6.92926 17.9134C6.9525 17.915 6.97596 17.9158 6.99961 17.9158H11.9996C12.5519 17.9158 12.9996 17.4681 12.9996 16.9158C12.9996 16.3635 12.5519 15.9158 11.9996 15.9158H9.68577L16.134 9.4676C16.9931 8.60844 16.9931 7.21548 16.134 6.35633L13.0556 3.27795ZM18.4996 16.8858C18.4996 17.438 18.0519 17.8858 17.4996 17.8858C16.9473 17.8858 16.4996 17.438 16.4996 16.8858C16.4996 16.3335 16.9473 15.8858 17.4996 15.8858C18.0519 15.8858 18.4996 16.3335 18.4996 16.8858ZM14.7496 17.89C15.3019 17.89 15.7496 17.4423 15.7496 16.89C15.7496 16.3378 15.3019 15.89 14.7496 15.89C14.1973 15.89 13.7496 16.3378 13.7496 16.89C13.7496 17.4423 14.1973 17.89 14.7496 17.89Z"
      fill={color}
    />
  </svg>
);
