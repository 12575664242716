import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 19;
const ORIGINAL_HEIGHT = 14;
const PROPORTAION = ORIGINAL_HEIGHT / ORIGINAL_WIDTH;

export const FlagEn = ({ width = ORIGINAL_WIDTH }: IconComponentProps) => {
  return (
    <svg width={width} height={width * PROPORTAION} viewBox="0 0 19 14" fill="none" style={{ borderRadius: '3px' }}>
      <path d="M0 0H19V14H0V0Z" fill="#012169" />
      <path
        d="M2.22656 0L9.47031 5.27917L16.6844 0H19V1.80833L11.875 7.02917L19 12.2208V14H16.625L9.5 8.77917L2.40469 14H0V12.25L7.09531 7.05833L0 1.86667V0H2.22656Z"
        fill="white"
      />
      <path
        d="M12.5875 8.19583L19 12.8333V14L10.9547 8.19583H12.5875ZM7.125 8.77917L7.30312 9.8L1.60312 14H0L7.125 8.77917ZM19 0V0.0875L11.6078 5.57083L11.6672 4.2875L17.5156 0H19ZM0 0L7.09531 5.13333H5.31406L0 1.225V0Z"
        fill="#C8102E"
      />
      <path d="M7.15469 0V14H11.9047V0H7.15469ZM0 4.66667V9.33333H19V4.66667H0Z" fill="white" />
      <path d="M0 5.62917V8.42917H19V5.62917H0ZM8.10469 0V14H10.9547V0H8.10469Z" fill="#C8102E" />
    </svg>
  );
};
