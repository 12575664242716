import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Option } from 'space-lift';

import { getCmsBlockById, getCurrentBlockId, updateBlockSettings } from '../../../ducks';
import { getIsContentBlock,isBlockColorDefined, isBlockMediaDefined } from '../../../logic/pages';
import { useToolbarVisibility } from '../../../utils/hooks';
import { isDefined } from '../../../utils/is';
import { ColorEditor, RemoveButton } from '../TextTools';
import { Toolbar } from '../Toolbar';
import { AcceptDiscard } from '../Toolbar/Tools/AcceptDiscard';

const INIT_DATA = {
  color: '#ffffff',
};

const TOOLBAR_TYPE = 'Background';

export const BackgroundToolbar = () => {
  const dispatch = useDispatch();
  const currentBlock = useSelector(getCurrentBlockId);
  const { hideToolbar, isToolbarVisible } = useToolbarVisibility(TOOLBAR_TYPE);
  const block = useSelector(getCmsBlockById(currentBlock));
  const { t } = useTranslation(['cms']);

  const [toolbarLocked, setToolbarLocked] = React.useState(false);

  const updateBlockColor = React.useCallback(
    (value: string | undefined) => {
      if (!isDefined(currentBlock) || !isToolbarVisible) {
        return;
      }

      dispatch(
        updateBlockSettings({
          blockId: currentBlock,
          updatePath: ['background', 'general', 'color'],
          value,
        }),
      );
    },
    [currentBlock, dispatch, isToolbarVisible],
  );

  React.useEffect(() => {
    if (isDefined(block) && getIsContentBlock(block) && isBlockColorDefined(block)) {
      return;
    }

    updateBlockColor(INIT_DATA.color);
  }, [currentBlock, updateBlockColor]);

  const onChange = React.useCallback((color: string) => updateBlockColor(color), [updateBlockColor]);

  const onRemove = React.useCallback(() => {
    hideToolbar();

    if (!isDefined(currentBlock) || !isDefined(block)) {
      return;
    }

    const settingsPath = isBlockMediaDefined(block) ? ['background', 'general', 'color'] : ['background'];

    dispatch(
      updateBlockSettings({
        blockId: currentBlock,
        updatePath: settingsPath,
        value: undefined,
      }),
    );
  }, [block, currentBlock, dispatch, hideToolbar]);

  const blockColor = React.useMemo(
    () =>
      Option(block)
        .filter(getIsContentBlock)
        .map(blockData => blockData.settings.background?.general.color)
        .get(),
    [block],
  );

  const handleSetToolbarLocked = React.useCallback(
    (isLocked: boolean) => () => {
      setToolbarLocked(isLocked);
    },
    [setToolbarLocked],
  );

  return (
    <Toolbar
      type={TOOLBAR_TYPE}
      title={t('cms:background')}
      tools={[
        <ColorEditor
          key="color_editor"
          value={blockColor || INIT_DATA.color}
          onChange={onChange}
          onCloseEditor={handleSetToolbarLocked(false)}
          onOpenEditor={handleSetToolbarLocked(true)}
        />,
        <RemoveButton key="remove_button" onClick={onRemove} />,
        <AcceptDiscard key="acceptdiscard" acceptAction={hideToolbar} />,
      ]}
      isLockedVisible={toolbarLocked}
    />
  );
};
