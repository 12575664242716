import React from 'react';
import { useInView } from 'react-intersection-observer';

import LoadMore from '../LoadMore';

import styles from './LoadData.module.scss';

interface Props {
  isLoading: boolean;
  title: string;
  intersectionThreshold?: number;
  rootMargin?: string;
  onLoadMore(): void;
  showLoader?: boolean;
}

const DEFAULT_TRESHOLD = 0.1;

export const LoadData = ({
  isLoading,
  title,
  rootMargin = '200px',
  intersectionThreshold = DEFAULT_TRESHOLD,
  onLoadMore,
  showLoader = true,
}: Props) => {
  const [ref, isInView] = useInView({
    root: null,
    rootMargin,
    threshold: intersectionThreshold,
  });

  React.useEffect(() => {
    if (!isLoading && isInView) {
      onLoadMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView, onLoadMore]);

  return (
    <div ref={ref} className={styles.loaderWrapper}>
      {showLoader && <LoadMore title={title} isLoading={isLoading} />}
    </div>
  );
};
