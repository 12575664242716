import React from 'react';
import { useTranslation } from 'react-i18next';
import { Features } from 'typings';

import universalStyles from '../../../../../css/utilities/universal.module.scss';
import Button from '../../Button';

import { Carousel } from './Carousel';
import styles from './Onboarding.module.scss';
import { Steps } from './Steps';

interface Props {
  onboarding: Features.Onboarding;
  hideOnboarding: (id: string) => void;
}

export const Onboarding = ({ onboarding, hideOnboarding }: Props) => {
  const { pages } = onboarding;

  const [currentPageIndex, setCurrentPageIndex] = React.useState(0);
  const { t } = useTranslation(['onboarding']);
  const lastPageIndex = pages.length - 1;

  const isFirstPage = currentPageIndex === 0;
  const isLastPage = currentPageIndex === lastPageIndex;

  const handleCloseTutorial = React.useCallback(() => {
    hideOnboarding(onboarding.id);
  }, [hideOnboarding, onboarding.id]);

  const handleSetPage = React.useCallback((pageIndex: number) => {
    setCurrentPageIndex(pageIndex);
  }, []);

  const handleNextPage = React.useCallback(() => {
    const nextPage = currentPageIndex + 1;

    if (nextPage > lastPageIndex) {
      return;
    }

    handleSetPage(nextPage);
  }, [currentPageIndex, handleSetPage, lastPageIndex]);

  const nextButtonNameLookup = [
    { condition: isFirstPage, name: t('onboarding:find_out_more') },
    { condition: isLastPage, name: t('onboarding:finish') },
    { condition: !isFirstPage && !isLastPage, name: t('onboarding:next') },
  ];

  const nextPageButtonName = nextButtonNameLookup.find(({ condition }) => condition)?.name;

  const nextPageButtonInteraction = isLastPage ? handleCloseTutorial : handleNextPage;

  return (
    <aside className={styles.tutorialContainer}>
      <h2 className={universalStyles.srOnly}>{t(onboarding.nameI18nKey)}</h2>
      <Carousel currentStep={currentPageIndex}>
        {pages.map(page => (
          <img key={page.imageUrl} className={styles.tutorialImage} src={page.imageUrl} alt="" />
        ))}
      </Carousel>
      <Steps items={pages} current={currentPageIndex} setCurrentPage={handleSetPage} />
      <div className={styles.descriptionContainer}>
        <Carousel currentStep={currentPageIndex}>
          {pages.map(page => (
            <div key={page.titleI18nKey} className={styles.description} aria-live="polite">
              <h3>{t(page.titleI18nKey)}</h3>
              <p>{t(page.descriptionI18nKey)}</p>
            </div>
          ))}
        </Carousel>
      </div>
      <div className={styles.buttons}>
        <Button onClick={handleCloseTutorial} variant={['button', 'extraSmall', 'transparent']} dataTestId="skipIntro">
          {t('onboarding:skip_intro')}
        </Button>

        <Button onClick={nextPageButtonInteraction} variant={['button', 'extraSmall', 'blue']} dataTestId="nextIntroStep">
          {nextPageButtonName}
        </Button>
      </div>
    </aside>
  );
};
