import styles from './FormParts.module.scss';

interface Props {
  id: string;
}

export const FieldError = ({ children, id }: React.WithChildren<Props>) => {
  return (
    <span id={id} className={styles.errorMessage}>
      {children}
    </span>
  );
};
