import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import {
  fetchCustomStylesRequest,
  getCmsBlockById,
  getCmsBlocksByIds,
  getCmsGroupById,
  getCmsSections,
  getIsLoadingPageData,
  setPreviewData as setPreviewDataAction,
} from '../../../ducks';
import { compiledPaths } from '../../../paths';
import { getFullPageData } from '../../../services/pagesRepository';
import { usePagePreview } from '../../../utils/hooks/cms/usePagePreview';
import { isDefined } from '../../../utils/is';
import DefaultLoader from '../../various/loaders/DefaultLoader';
import { PageTitle } from '../../various/PageTitle';
import { EditorLanguageContextProvider } from '../context/EditorLanguageContext';
import { EditorUIContextProvider } from '../context/EditorUIContext';
import { SelectorsContext } from '../context/SelectorsContext';
import { SizeContextProvider } from '../context/SizeContext';

import { CmsPreviewContent } from './CmsPreviewContent';

import '../Cms.scss';

const selectorsContextValue = {
  getBlockById: getCmsBlockById,
  getBlocksByIds: getCmsBlocksByIds,
  getGroupById: getCmsGroupById,
  getSections: getCmsSections,
};

export const CmsPreview = () => {
  const isLoadingPageData = useSelector(getIsLoadingPageData);
  const { t } = useTranslation(['cms']);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { previewData, setPreviewData } = usePagePreview();
  const { pageId } = useParams<{ pageId: string }>();

  React.useEffect(() => {
    if (!isDefined(pageId)) {
      return;
    }

    dispatch(fetchCustomStylesRequest());

    if (isDefined(previewData) && previewData.id === pageId) {
      dispatch(setPreviewDataAction({ data: previewData }));
      setPreviewData(null);

      return;
    }

    getFullPageData(pageId)
      .then(data => {
        dispatch(setPreviewDataAction({ data }));
      })
      .catch(() => {
        navigate({ pathname: compiledPaths.PAGE_NOT_FOUND({}) }, { replace: true });
      });
  }, [pageId]);

  return (
    <PageTitle title={t('cms:cms_preview')}>
      <SelectorsContext.Provider value={selectorsContextValue}>
        <EditorUIContextProvider viewMode="preview">
          <EditorLanguageContextProvider>
            <SizeContextProvider>
              {isLoadingPageData ?
                <DefaultLoader fullHeight />
              : <CmsPreviewContent />}
            </SizeContextProvider>
          </EditorLanguageContextProvider>
        </EditorUIContextProvider>
      </SelectorsContext.Provider>
    </PageTitle>
  );
};
