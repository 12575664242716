import { Cms } from '@typings';

import { PreviouslyTableCell } from '../PreviouslyTableCell/PreviouslyTableCell';

import styles from './DescriptionCell.module.scss';

interface Props {
  description: string;
  row: Cms.Page;
}

export const DescriptionCell = ({ description, row }: Props) => (
  <>
    <span className={styles.truncation}>{description}</span>
    <PreviouslyTableCell property="description" page={row} />
  </>
);
