import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  getCurrentGroupId,
  getCurrentSectionId,
  getManualLayoutEditorBlockTypeToSelect,
  getManualLayoutEditorModels,
  manualLayoutEditingDiscard,
  manualLayoutEditingSuccess,
} from '../../../ducks';
import { removeLocalizedBlocks } from '../../../ducks/cms/localizations';
import { getBlocksWithInsertedTemplates, getContentModelsCount, getProductSlotsCountInModels } from '../../../logic/cms/changeGroup';
import { getIsProductBlock } from '../../../logic/pages';
import { useGetBlocksByGroupId } from '../../../utils/hooks';
import { isDefined, isNull } from '../../../utils/is';
import { FocusTrap } from '../../various/FocusTrap';
import { HoverToolsContext } from '../context/HoverToolsContext';
import { ManualLayoutEditorContext, ManualLayoutEditorContextProvider } from '../context/ManualLayoutEditorContext';
import { ScrollOverlay } from '../ScrollOverlay';
import { ToolbarBase } from '../Toolbar/ToolbarBase';
import { AcceptDiscard } from '../Toolbar/Tools/AcceptDiscard';

import styles from './ManualLayoutEditor.module.scss';
import { SelectedElementsInfo } from './SelectedElementsInfo';

export const ManualLayoutEditor = () => {
  const dispatch = useDispatch();
  const { manualLayoutChangeData, setManualLayoutChangeData } = React.useContext(ManualLayoutEditorContext);
  const { t } = useTranslation(['cms']);
  const models = useSelector(getManualLayoutEditorModels);
  const contentModelsCount = getContentModelsCount(models);
  const productSlotsCount = getProductSlotsCountInModels(models);
  const groupId = useSelector(getCurrentGroupId);
  const sectionId = useSelector(getCurrentSectionId);
  const blockTypeToSelect = useSelector(getManualLayoutEditorBlockTypeToSelect);
  const blocks = useGetBlocksByGroupId(groupId);
  const disabledBlocks = blocks.filter(block => block.blockType !== blockTypeToSelect);
  const areProductsToSelect = blockTypeToSelect === 'product';
  const { setIsHoverToolsHidden } = React.useContext(HoverToolsContext);

  React.useEffect(() => {
    setIsHoverToolsHidden(true);

    return () => setIsHoverToolsHidden(false);
  }, []);

  const discardAction = React.useCallback(() => {
    dispatch(manualLayoutEditingDiscard());
    setManualLayoutChangeData({});
  }, []);

  const getUpdatedBlocks = React.useCallback(() => {
    if (!areProductsToSelect) {
      return Object.values(manualLayoutChangeData);
    }

    const originalProductsBlock = blocks.find(block => block.blockType === 'product');
    const [productsBlock] = Object.values(manualLayoutChangeData);

    if (!getIsProductBlock(originalProductsBlock) || !getIsProductBlock(productsBlock)) {
      return [];
    }

    const originalProducts = originalProductsBlock.settings.products;
    const { products } = productsBlock.settings;
    const sortedProducts = [...products].sort((a, b) => originalProducts.indexOf(a) - originalProducts.indexOf(b));

    return [
      {
        ...productsBlock,
        settings: {
          ...productsBlock.settings,
          products: sortedProducts,
        },
      },
    ];
  }, [areProductsToSelect, blocks, manualLayoutChangeData]);

  const acceptAction = React.useCallback(() => {
    if (isNull(groupId) || isNull(sectionId)) {
      return;
    }

    const newBlocks = [...getUpdatedBlocks(), ...disabledBlocks];
    const newData = getBlocksWithInsertedTemplates(newBlocks, models, groupId);

    const newBlockIds = newBlocks.map(({ id }) => id);
    const blockIdsToRemove = blocks.filter(({ id }) => !newBlockIds.includes(id)).map(({ id }) => id);

    dispatch(removeLocalizedBlocks({ blockIds: blockIdsToRemove }));
    dispatch(manualLayoutEditingSuccess({ blocks: newData, groupId, sectionId }));
    setManualLayoutChangeData({});
  }, [groupId, sectionId, getUpdatedBlocks, disabledBlocks, models, blocks, dispatch, setManualLayoutChangeData]);

  const totalSelectableBlocks = React.useMemo(() => {
    if (areProductsToSelect) {
      return productSlotsCount;
    }

    return contentModelsCount;
  }, [areProductsToSelect, productSlotsCount, contentModelsCount]);

  const selectedBlocksCount = React.useMemo(() => {
    if (!areProductsToSelect) {
      return Object.keys(manualLayoutChangeData).length;
    }

    const [productBlock] = Object.values(manualLayoutChangeData);

    if (!isDefined(productBlock) || !getIsProductBlock(productBlock)) {
      return 0;
    }

    return productBlock.settings.products.length;
  }, [areProductsToSelect, manualLayoutChangeData, blockTypeToSelect]);

  const toolbarTitle = areProductsToSelect ? t('cms:select_products_to_keep') : t('cms:select_content_to_keep');

  return (
    <ManualLayoutEditorContextProvider>
      <ScrollOverlay className={styles.scrollOverlay} />
      <div role="dialog" className={styles.editor}>
        <FocusTrap>
          <ToolbarBase
            title={toolbarTitle}
            tools={[
              <SelectedElementsInfo key="selectedBlocksInfo" selected={selectedBlocksCount} total={totalSelectableBlocks} />,
              <AcceptDiscard key="acceptdiscard" discardAction={discardAction} acceptAction={acceptAction} testId="applyItems" />,
            ]}
          />
        </FocusTrap>
      </div>
    </ManualLayoutEditorContextProvider>
  );
};
