import { assocPath } from 'ramda';
import { AnyAction } from 'redux';
import { isActionOf } from 'typesafe-actions';

import { setCurrentItem } from '../ducks';

const REPLACEMENT_VALUE = '<<REACT_REF>>';

export const actionSanitizer = (action: AnyAction) =>
  isActionOf(setCurrentItem, action) ? { ...action, payload: { ...action.payload, element: REPLACEMENT_VALUE } } : action;

export const stateSanitizer = (state: Store) => {
  if (state.cms.ui.currentItem.element) {
    return assocPath(['cms', 'ui', 'currentItem', 'element'], REPLACEMENT_VALUE, state);
  }

  return state;
};
