import cx from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { getDeliveryWindowsGroupsIds, getStockTypeFilter } from '../../../../../ducks';
import { isDeliveryWindowFilterGroup } from '../../../../../logic/deliveryWindows';
import { useCheckboxGroupState } from '../../../../../utils/hooks/checkboxGroup/useCheckboxGroupState';
import { useIsTouchScreen } from '../../../../../utils/hooks/useIsTouchScreen';
import { isDefined } from '../../../../../utils/is';

import { DeliveryWindowFilter } from './components/DeliveryWindowFilter';
import { DeliveryWindowFilterGroup } from './components/DeliveryWindowFilterGroup';
import { DeliveryWindowFilterGroupTouchPanel } from './components/DeliveryWindowFilterGroupTouchPanel';
import { useDeliveryWindowFilterContext } from './DeliveryWindowFilterContext';
import styles from './FiltersDeliveryWindows.module.scss';

interface Props {
  selectedOptions: string[];
  onSelectedOptionsChange: (selectedValues: string[]) => void;
}

export const FiltersDeliveryWindows = ({ selectedOptions, onSelectedOptionsChange }: Props) => {
  const stockTypeFilter = useSelector(getStockTypeFilter);
  const delwinGroupsIds = useSelector(getDeliveryWindowsGroupsIds);
  const { items, activeGroup, options, isTopLevelOptionsOpened, closeGroup } = useDeliveryWindowFilterContext();

  const isTouchScreen = useIsTouchScreen();

  const [currentStockType, setCurrentStockType] = React.useState(stockTypeFilter);

  const setSelectedFilters = React.useCallback(
    (selectedValues: string[]) => {
      const filteredSelectedValues = selectedValues.filter(value => !delwinGroupsIds.includes(value));
      onSelectedOptionsChange(filteredSelectedValues);
    },
    [delwinGroupsIds],
  );

  const { batchUpdate, optionsState, toggleOptionState } = useCheckboxGroupState({
    onOptionToggle: setSelectedFilters,
    options,
  });

  React.useEffect(() => {
    if (isDefined(selectedOptions) && Array.isArray(selectedOptions)) {
      batchUpdate(selectedOptions);
    }
  }, [selectedOptions, batchUpdate]);

  React.useEffect(() => {
    if (!isDefined(stockTypeFilter) || stockTypeFilter === currentStockType) {
      return;
    }

    closeGroup();
    setCurrentStockType(stockTypeFilter);
  }, [closeGroup, currentStockType, stockTypeFilter]);

  const shouldHideTopLevelOptions = !isTopLevelOptionsOpened;

  const wrapperClassNames = cx({
    [styles.mobileWrapper]: isTouchScreen,
    [styles.hasActiveOption]: isDefined(activeGroup),
  });

  return (
    <div className={wrapperClassNames}>
      <ul className={cx(styles.optionsList, { [styles.hidden]: shouldHideTopLevelOptions })}>
        {items.map(deliveryWindowFilterItem => {
          const isGroup = isDeliveryWindowFilterGroup(deliveryWindowFilterItem);
          const contentKey =
            isGroup ?
              `delivery-window-group-${deliveryWindowFilterItem.groupId}`
            : `delivery-window-${deliveryWindowFilterItem.deliveryWindow}`;

          const content =
            isGroup ?
              <DeliveryWindowFilterGroup
                group={deliveryWindowFilterItem}
                optionsState={optionsState}
                toggleOptionState={toggleOptionState}
              />
            : <DeliveryWindowFilter
                deliveryWindow={deliveryWindowFilterItem}
                optionsState={optionsState}
                toggleOptionState={toggleOptionState}
            />;

          return (
            <li className={styles.listItem} key={contentKey}>
              {content}
            </li>
          );
        })}
      </ul>
      <DeliveryWindowFilterGroupTouchPanel optionsState={optionsState} toggleOptionState={toggleOptionState} />
    </div>
  );
};
