import cx from 'classnames';
import { Trans, useTranslation } from 'react-i18next';

import universalStyles from '../../../../../css/utilities/universal.module.scss';

import styles from './FilePicker.module.scss';

interface Props {
  disabled: boolean;
  hasFile: boolean;
  isImageOnly: boolean;
}

export const Message = ({ disabled, hasFile, isImageOnly }: Props) => {
  const { t } = useTranslation(['common']);

  const imageMessage = (
    <Trans t={t} i18nKey="common:drag_image_or_click">
      Drag image here <br /> or <span>Choose file</span>
    </Trans>
  );

  const fileMessage = (
    <Trans t={t} i18nKey="common:drag_file_or_click">
      Drag file here <br /> or <span>Choose file</span>
    </Trans>
  );

  return (
    <p
      className={cx(styles.message, {
        [styles.disabled]: disabled,
        [universalStyles.srOnly]: hasFile,
      })}
    >
      {isImageOnly ? imageMessage : fileMessage}
    </p>
  );
};
