import { Order, Product } from '@typings';
import { useTranslation } from 'react-i18next';

import { getRelatedProducts } from '../../../logic/products';
import { isDefined } from '../../../utils/is';
import Heading from '../../various/Heading';

import styles from './CancelledProducts.module.scss';
import { GrayedOutProduct } from './GrayedOutProduct';

interface Props {
  cancelledProducts: Order.Product[];
  products: Product.Full[];
}

export const CancelledProducts = ({ cancelledProducts, products }: Props) => {
  const { t } = useTranslation(['cancelled']);

  const title = t('cancelled:cancelled_products_title');
  const info = t('cancelled:cancelled_products_info');

  return (
    <div className={styles.container}>
      <Heading variant={['blue', 'h2']} title={title} />

      <p className={styles.info}>{info}</p>

      <div className={styles.productList}>
        {cancelledProducts.map(cancelled => {
          const productFullInfo = products.find(orderProduct => cancelled.product === orderProduct.product);

          if (!isDefined(productFullInfo)) {
            return null;
          }

          const allFullProductsInfo = [productFullInfo, ...getRelatedProducts(productFullInfo)];

          const filteredVariants = allFullProductsInfo.filter(
            full => cancelled.product === full.product && cancelled.variant === full.variant,
          );

          const cancelledItemsIds = cancelled.items.map(item => item.item);

          const variantsWithFullyCancelledItems = filteredVariants.map(variant => {
            const cancelledItems = variant.items.filter(item => cancelledItemsIds.includes(item.item));

            return {
              ...variant,
              items: cancelledItems,
            };
          });

          return (
            <div key={`${cancelled.product} - ${cancelled.variant} - ${cancelled.deliveryWindow}`} className={styles.productBox}>
              <GrayedOutProduct delwinId={cancelled.deliveryWindow} product={productFullInfo} variants={variantsWithFullyCancelledItems} />
            </div>
          );
        })}
      </div>
    </div>
  );
};
