import { ItemEtasList } from '@typings';
import { useTranslation } from 'react-i18next';

import { formatLocaleDate } from '../../../../../utils/dates';
import Icon, { IconType } from '../../../Icon';

import styles from './QuantityCellInfo.module.scss';

const ALLOWED_DATES_COUNT_IN_FIRST_LINE = 2;
const ALLOWED_DATES_COUNT_IN_TOTAL = 3;

interface Props {
  itemEtas: ItemEtasList;
}

export const EtaTooltipContent = ({ itemEtas }: Props) => {
  const { t } = useTranslation(['common']);

  const firstLine = itemEtas
    .slice(0, ALLOWED_DATES_COUNT_IN_FIRST_LINE)
    .map(eta => formatLocaleDate(eta))
    .join('; ');

  const restEtas = itemEtas
    .slice(ALLOWED_DATES_COUNT_IN_FIRST_LINE, ALLOWED_DATES_COUNT_IN_TOTAL)
    .map(eta => formatLocaleDate(eta))
    .join('; ');

  const isMoreThanMax = itemEtas.length > ALLOWED_DATES_COUNT_IN_TOTAL;
  const isMoreThanInFirstLine = itemEtas.length > ALLOWED_DATES_COUNT_IN_FIRST_LINE;

  return (
    <div className={styles.tooltipLine}>
      <Icon className={styles.icon} type={IconType.Hourglass} size={20} />
      <span>
        {t('common:more_arriving_on')}
        <br />
        {firstLine}
        {isMoreThanInFirstLine && ';'}
        <br />
        {restEtas}
        {isMoreThanMax && <span> {t('common:and_more')}</span>}
      </span>
    </div>
  );
};
