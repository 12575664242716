import { Form } from '@typings';
import React from 'react';
import { UseFormRegisterReturn, useFormState } from 'react-hook-form';

import { isDefined } from '../../../../utils/is';
import { Checkbox } from '../../Fields/Checkbox';

import { FieldError } from './FieldParts';
import styles from './FormField.module.scss';

type CheckboxComponentProps = Pick<React.ComponentProps<typeof Checkbox>, 'onKeyDown' | 'value'>;
type FieldProps = Omit<Form.FieldProps, 'orientation' | 'description' | 'helpMessage' | 'label'>;
type CheckboxProps = Omit<Partial<UseFormRegisterReturn>, 'ref'>;

interface Props extends CheckboxProps, FieldProps, CheckboxComponentProps {
  name: string;
  inputClassName?: string;
  checked?: boolean;
  label?: React.ReactNode | string;
  className?: string;
}

export const CheckboxField = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { label, name, isRequired, ...rest } = props;

  const errorId = React.useId();

  const { errors } = useFormState({ name });
  const errorMessage = errors[name]?.message as string | undefined;

  return (
    <div className={styles.checkbox}>
      <Checkbox
        ref={ref}
        {...rest}
        name={name}
        isRequired={isRequired}
        isInvalid={isDefined(errorMessage)}
        errorMessageId={isDefined(errorMessage) ? errorId : undefined}
      >
        {label}
      </Checkbox>
      {isDefined(errorMessage) && <FieldError id={errorId}>{errorMessage}</FieldError>}
    </div>
  );
});
