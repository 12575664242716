import cx from 'classnames';
import { PlacementType } from 'react-laag/dist/PlacementType';

import Button from '../Button';
import Icon, { IconColor, IconType } from '../Icon';
import { MaybeTooltip } from '../MaybeTooltip';

import styles from './IconButton.module.scss';

export type Variant = 'primary' | 'secondary' | 'primary-light' | 'secondary-light';

const iconColor: Record<Variant, IconColor> = {
  primary: IconColor.White,
  'primary-light': IconColor.Light,
  secondary: IconColor.Default,
  'secondary-light': IconColor.Gray,
};

interface Props {
  icon: IconType;
  name: string;
  iconSize?: number;
  color?: IconColor;
  disabled?: boolean;
  size?: 'small';
  onClick?: () => void;
  variant?: Variant;
  className?: string;
  showTooltip?: boolean;
  tooltipPlacement?: PlacementType;
  highlighted?: boolean;
  dataTestId?: string;
}

export const IconButton = ({
  className,
  icon,
  iconSize,
  name,
  size,
  variant = 'primary',
  showTooltip = false,
  highlighted = false,
  tooltipPlacement,
  dataTestId,
  ...props
}: Props) => {
  const isSecondary = variant === 'secondary' || variant === 'secondary-light';

  return (
    <MaybeTooltip content={showTooltip ? name : undefined} placement={tooltipPlacement} disableOnTouchScreen>
      <Button
        variant={['button', 'small', 'blue']}
        className={cx(
          styles.button,
          {
            [styles.secondaryVariant]: isSecondary,
            [styles.highlighted]: highlighted,
            [styles.sizeSmall]: size === 'small',
          },
          className,
        )}
        aria-label={name}
        dataTestId={dataTestId}
        {...props}
      >
        <Icon type={icon} size={iconSize} color={iconColor[variant]} />
      </Button>
    </MaybeTooltip>
  );
};
