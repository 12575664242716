import React from 'react';

import { useScreenResize } from './useScreenResize';

export const useIsScreenSize = (breakpoint: number) => {
  const [isScreenSize, setIsScreenSize] = React.useState(window.innerWidth <= breakpoint);

  const onResize = React.useCallback(() => {
    setIsScreenSize(window.innerWidth <= breakpoint);
  }, [breakpoint]);

  useScreenResize(onResize);

  return isScreenSize;
};
