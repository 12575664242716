import { ConfirmationGuard } from '@typings';
import React from 'react';

import { useModalVisibility } from '../../../../utils/hooks';
import { isDefined } from '../../../../utils/is';
import Button from '../../Button';

import styles from './ConfirmationButtons.module.scss';

interface Props {
  config: ConfirmationGuard.Config;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

export const ConfirmationButtons = ({ config, isLoading, setIsLoading }: Props) => {
  const shouldShowExtraButton = isDefined(config.onExtraButton) && isDefined(config.extraButtonText);
  const shouldShowCancel = config.type !== 'warning';
  const { hideModal } = useModalVisibility('ConfirmationModal');

  const handleSubmit = React.useCallback(() => {
    if (config.type !== 'confirm') {
      return;
    }

    config.onOk();

    if (!config.loadingOnOk) {
      hideModal();

      return;
    }

    setIsLoading(true);
  }, [config, hideModal, setIsLoading]);

  const handleExtraButton = React.useCallback(() => {
    hideModal();
    config.onExtraButton?.();
  }, [config, hideModal]);

  const handleCancelButton = React.useCallback(
    (event: React.SyntheticEvent<EventTarget>) => {
      config.onRequestClose?.(event);
      hideModal();
    },
    [config, hideModal],
  );

  return (
    <div className={styles.buttonsWrapper}>
      {shouldShowCancel && (
        <Button onClick={handleCancelButton} variant={['button', 'small', 'transparent']} disabled={isLoading}>
          {config.cancelText}
        </Button>
      )}
      {shouldShowExtraButton && (
        <>
          <div className={styles.spacer} />
          <Button
            onClick={handleExtraButton}
            variant={['button', 'small', 'transparent']}
            dataTestId="confirmationExtraButton"
            disabled={isLoading}
          >
            {config.extraButtonText}
          </Button>
        </>
      )}
      <Button
        type="submit"
        onClick={handleSubmit}
        variant={['button', 'small', config.okType === 'danger' ? 'red' : 'blue']}
        dataTestId="confirmationSubmitButton"
        disabled={(config.type === 'confirm' && config.okDisabled) || isLoading}
        isLoading={isLoading}
      >
        {config.okText}
      </Button>
    </div>
  );
};
