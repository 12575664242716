import cx from 'classnames';

import styles from './ScrollOverlay.module.scss';

interface Props {
  className?: string;
}

export const ScrollOverlay = ({ className }: Props) => {
  return <div className={cx(styles.scrollOverlay, className)} />;
};
