export const ListTemplate = () => {
  return (
    <svg fill="none" height="115" viewBox="0 0 80 115" width="80">
      <path d="m0 0h80v115h-80z" fill="#fff" />
      <path d="m.5.5h79v114h-79z" stroke="#d9d9d9" />
      <path d="m7.78523 9.03571h12.817v17.9625h-12.817z" fill="#bababa" />
      <path d="m27.0667 9.03571h16.7178v5.11591h-16.7178z" fill="#d9d9d9" />
      <path d="m27.0667 18.2443h29.9806v8.75391h-29.9806z" fill="#d9d9d9" />
      <path d="m8.0537 35.0476h12.817v17.9625h-12.817z" fill="#bababa" />
      <path d="m27.3351 35.0476h16.7178v5.11591h-16.7178z" fill="#d9d9d9" />
      <path d="m27.3351 44.2562h29.9806v8.75391h-29.9806z" fill="#d9d9d9" />
      <path d="m8.0537 61.0595h12.817v17.9625h-12.817z" fill="#bababa" />
      <path d="m27.3351 61.0595h16.7178v5.11591h-16.7178z" fill="#d9d9d9" />
      <path d="m27.3351 70.2682h29.9806v8.75391h-29.9806z" fill="#d9d9d9" />
      <path d="m8.0537 87.0714h12.817v17.9626h-12.817z" fill="#bababa" />
      <g fill="#d9d9d9">
        <path d="m27.3351 87.0714h16.7178v5.11592h-16.7178z" />
        <path d="m27.3351 96.2801h29.9806v8.75391h-29.9806z" />
      </g>
    </svg>
  );
};
