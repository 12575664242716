import { Preset, Product } from '@typings';
import { createSelector } from 'reselect';

import { validatePresets } from '../../logic/presets';

export const getDistributions = (store: Store) => store.presets.distributions;

export const getPrepacks = (store: Store) => store.presets.prepacks;

export const getDistributionsForVariants = (variants: Product.Standard[], deliveryWindowId: string) =>
  createSelector(getDistributions, distributions => {
    return variants.reduce<Record<Product.Id, Preset[]>>(
      (acc, variant) => ({
        ...acc,
        [variant.variant]: validatePresets(variant, deliveryWindowId, distributions, 'distributions'),
      }),
      {},
    );
  });

export const getPrepacksForVariants = (variants: Product.Standard[], deliveryWindowId: string) =>
  createSelector(getPrepacks, prepacks => {
    return variants.reduce<Record<string, Preset[]>>(
      (acc, variant) => ({
        ...acc,
        [variant.variant]: validatePresets(variant, deliveryWindowId, prepacks, 'prepacks'),
      }),
      {},
    );
  });
