import { PopoverMenuItems } from '@typings';
import React from 'react';

import styles from '../PopoverMenu.module.scss';

import { Label } from './Label';
import { MenuButton } from './MenuButton';

interface Props {
  item: PopoverMenuItems.ClickableItem;
  closeMenu: () => void;
}

export const ClickableItem = ({ item, closeMenu }: Props) => {
  const { onClick, icon, label, isDisabled, testId, disabledTooltip, subLabel, description } = item;

  const handleClick = React.useCallback(() => {
    onClick?.();
    closeMenu();
  }, [onClick, closeMenu]);

  return (
    <li className={styles.menuItem}>
      <MenuButton onClick={handleClick} icon={icon} isDisabled={isDisabled} testId={testId} disabledTooltip={disabledTooltip}>
        <Label description={description} subLabel={subLabel}>
          {label}
        </Label>
      </MenuButton>
    </li>
  );
};
