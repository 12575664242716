import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { addToast } from '../../../components/various/Toasts';
import { addShippingCountry } from '../../accountRepository';

export const useAddShippingCountry = () => {
  const { t } = useTranslation(['shipping']);

  const handleSuccess = () => {
    addToast(t('shipping:shipping_country_added'));
  };

  const handleError = () => {
    addToast(t('shipping:shipping_country_addition_fail'));
  };

  return useMutation({
    mutationFn: addShippingCountry,
    onError: handleError,
    onSuccess: handleSuccess,
  });
};
