import { Cms, Dates, Id, Translations } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { EditorLanguageContext } from '../../../components/cms/context/EditorLanguageContext';
import { IconType } from '../../../components/various/Icon';
import { LANGUAGE_NAME_MAP } from '../../../constants/translation';
import { discardChangesRequest, getPageLanguages, publishPageRequest, unpublishPageRequest } from '../../../ducks';
import { getHasUnpublishedChanges, getIsPublished } from '../../../logic/pages';
import { formatDate } from '../../dates';
import { isDefined } from '../../is';
import { isEmpty } from '../../isEmpty';
import { useConfirmationGuard } from '../useConfirmationGuard';

interface Props {
  pageId: Id;
  languages: Translations.SupportedLanguagesCodes[];
  closeMenu: () => void;
  status?: Cms.PageStatus;
  hasModifiedLayout?: boolean;
  lastPublishedDate?: Dates.DateAsSQLString;
}

export const usePagePublicationActions = ({ status, pageId, lastPublishedDate, hasModifiedLayout, languages, closeMenu }: Props) => {
  const { defaultLanguage } = React.useContext(EditorLanguageContext);
  const { t } = useTranslation(['cms', 'cmsConfirmationConfig']);
  const pageLanguages = useSelector(getPageLanguages);
  const confirmationGuard = useConfirmationGuard();
  const dispatch = useDispatch();

  const defaultLanguageName = LANGUAGE_NAME_MAP[defaultLanguage];

  const isPublished = getIsPublished(status);
  const hasUnpublishedChanges = getHasUnpublishedChanges(status);

  const lastPublished = isDefined(lastPublishedDate) ? formatDate('YYYY-MM-DD, HH:mm:ss')(lastPublishedDate) : '';

  const handlePublishPage = React.useCallback(() => {
    closeMenu();
    const requestPublication = () => dispatch(publishPageRequest({ id: pageId, languages }));

    if (!hasModifiedLayout) {
      requestPublication();

      return;
    }

    confirmationGuard(() => ({
      okType: 'info',
      onOk: requestPublication,
      ...t('cmsConfirmationConfig:publish_modified_default_language', { defaultLanguage: defaultLanguageName, returnObjects: true }),
    }))();
  }, [closeMenu, confirmationGuard, defaultLanguageName, dispatch, hasModifiedLayout, languages, pageId, t]);

  const handleUnpublishPage = React.useCallback(() => {
    const isUnpublishingDefaultLanguage = languages.includes(defaultLanguage);

    const unpublishConfirmation =
      isUnpublishingDefaultLanguage ?
        t('cmsConfirmationConfig:unpublish_default_language', { returnObjects: true })
      : t('cmsConfirmationConfig:unpublish_page', { returnObjects: true });

    closeMenu();
    confirmationGuard(() => ({
      onOk: () => dispatch(unpublishPageRequest({ id: pageId, languages })),
      ...unpublishConfirmation,
    }))();
  }, [languages, defaultLanguage, closeMenu, confirmationGuard, t, dispatch, pageId]);

  const handleDiscardChanges = React.useCallback(() => {
    const [language] = languages;
    const isRevertingDefaultLanguage = language === defaultLanguage;
    const hasMultipleLanguages = pageLanguages.length > 1;

    const revertConfirmation =
      isRevertingDefaultLanguage && hasMultipleLanguages ?
        t('cmsConfirmationConfig:dismiss_changes_default_language', { defaultLanguage: defaultLanguageName, returnObjects: true })
      : t('cmsConfirmationConfig:dismiss_changes', { returnObjects: true });

    closeMenu();
    confirmationGuard(() => ({
      onOk: () => dispatch(discardChangesRequest({ id: pageId, language: language ?? defaultLanguage })),
      ...revertConfirmation,
    }))();
  }, [languages, pageLanguages.length, defaultLanguage, t, defaultLanguageName, closeMenu, confirmationGuard, dispatch, pageId]);

  const unpublishAction = {
    icon: IconType.UnpublishedPage,
    isDisabled: !isPublished,
    key: 'unpublish',
    label: t('cmsConfirmationConfig:unpublish_page.title'),
    onClick: handleUnpublishPage,
  };

  const revertAction = {
    ...(!isEmpty(lastPublished) && {
      description: t('cms:previous_version', { date: lastPublished }),
    }),
    icon: IconType.Revert,
    isDisabled: !hasUnpublishedChanges,
    key: 'revertChanges',
    label: t('cms:revert_to_last_published'),
    onClick: handleDiscardChanges,
  };

  const secondaryMenuActions = [revertAction, unpublishAction];

  return { handleDiscardChanges, handlePublishPage, handleUnpublishPage, secondaryMenuActions };
};
