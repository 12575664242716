import React from 'react';

import { useContentSetContext } from '../../../components/cms/context/ContentSetContext';
import { EditorUIContext } from '../../../components/cms/context/EditorUIContext';
import { GroupResizerContext } from '../../../components/cms/context/GroupResizerContext';
import { SNAP_THRESHOLD } from '../../../constants/cms';
import { calculateMovementPosition, calculateResizePosition, ContentPosition, getOffsetParentSize } from '../../../logic/cms/contentSet';
import { clamp } from '../../clamp';
import { isDefined } from '../../is';
import { roundValueToQuarter } from '../../roundValueToQuarter';
import { MovementOffset } from '../useMovement';
import { useResizeObserver } from '../useResizeObserver';

export const useContentSetAdjustor = (ref: React.RefObject<HTMLElement>, initialPosition: ContentPosition) => {
  const { isResizing } = React.useContext(GroupResizerContext);
  const { screenType } = React.useContext(EditorUIContext);
  const { setContentPosition } = useContentSetContext();

  const positionRef = React.useRef(initialPosition);
  const snapDirectionRef = React.useRef<'horizontal' | 'vertical' | null>(null);

  const dimensionsRef = React.useRef({
    height: 0,
    width: 0,
  });

  const parentDimensionsRef = React.useRef({
    height: 0,
    width: 0,
  });

  const updateDimensions = React.useCallback(() => {
    dimensionsRef.current = {
      height: ref.current?.offsetHeight ?? 0,
      width: ref.current?.offsetWidth ?? 0,
    };

    parentDimensionsRef.current = getOffsetParentSize(ref.current);
  }, [ref]);

  const updateStyles = React.useCallback(
    (style: React.CSSProperties) => {
      requestAnimationFrame(() => {
        if (isDefined(ref.current)) {
          Object.assign(ref.current.style, style);
        }
      });
    },
    [ref],
  );

  React.useEffect(() => {
    positionRef.current = initialPosition;

    updateStyles({
      left: `${initialPosition.left}%`,
      top: `${initialPosition.top}%`,
      width: `${initialPosition.width}%`,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenType]);

  const onResizing = React.useCallback(
    (xOffset: number) => {
      const { left, width } = calculateResizePosition({
        currentPosition: positionRef.current,
        dimensions: dimensionsRef.current,
        initialPosition,
        parentDimensions: parentDimensionsRef.current,
        xOffset,
      });

      updateStyles({
        left: `${left}%`,
        width: `${width}%`,
      });

      return { width };
    },
    [initialPosition, updateStyles],
  );

  const onEndResizing = React.useCallback(
    (xOffset: number) => {
      positionRef.current = calculateResizePosition({
        currentPosition: positionRef.current,
        dimensions: dimensionsRef.current,
        initialPosition,
        parentDimensions: parentDimensionsRef.current,
        xOffset,
      });
      setContentPosition(positionRef.current);
    },
    [initialPosition, setContentPosition],
  );

  const onMovement = React.useCallback(
    (offset: MovementOffset, event: MouseEvent | TouchEvent) => {
      const { shiftKey } = event;

      if (!shiftKey) {
        snapDirectionRef.current = null;
      }

      if (shiftKey) {
        if (Math.abs(offset.xOffset) < SNAP_THRESHOLD && Math.abs(offset.yOffset) < SNAP_THRESHOLD) {
          return;
        }

        if (!isDefined(snapDirectionRef.current)) {
          snapDirectionRef.current = Math.abs(offset.xOffset) > Math.abs(offset.yOffset) ? 'vertical' : 'horizontal';
        }
      }

      const { left, top, width } = calculateMovementPosition({
        ...offset,
        currentPosition: positionRef.current,
        dimensions: dimensionsRef.current,
        parentDimensions: parentDimensionsRef.current,
        snapDirection: snapDirectionRef.current,
      });

      setContentPosition({ left, top, width });

      updateStyles({
        left: `${left}%`,
        top: `${top}%`,
      });
    },
    [setContentPosition, updateStyles],
  );

  const onEndMovement = React.useCallback(
    (offset: MovementOffset) => {
      const position = calculateMovementPosition({
        ...offset,
        currentPosition: positionRef.current,
        dimensions: dimensionsRef.current,
        parentDimensions: parentDimensionsRef.current,
        snapDirection: snapDirectionRef.current,
      });

      setContentPosition(position);

      positionRef.current = position;
      snapDirectionRef.current = null;
    },
    [setContentPosition],
  );

  const recalculateVerticalPosition = React.useCallback(
    (height: number, parentHeight: number) => {
      if (height === 0 || parentHeight === 0) {
        return positionRef.current.top;
      }

      const topMin = (Math.min(100, height / parentHeight) * 100) / 2;
      const topMax = 100 - topMin;
      const top = roundValueToQuarter(clamp(topMin, topMax)(positionRef.current.top));

      positionRef.current.top = top;

      updateStyles({ top: `${top}%` });
      setContentPosition(positionRef.current);

      return top;
    },
    [setContentPosition, updateStyles],
  );

  React.useEffect(() => {
    if (isResizing) {
      return;
    }

    updateDimensions();
    recalculateVerticalPosition(dimensionsRef.current.height, parentDimensionsRef.current.height);
  }, [isResizing, recalculateVerticalPosition, updateDimensions]);

  useResizeObserver(ref, ([entry]) => {
    if (!isDefined(entry)) {
      return;
    }

    const { height } = entry.contentRect;
    recalculateVerticalPosition(height, getOffsetParentSize(entry.target.parentElement).height);
  });

  useResizeObserver({ current: ref.current?.parentElement as HTMLElement }, ([entry]) => {
    if (!isDefined(entry)) {
      return;
    }

    const { height } = entry.contentRect;
    recalculateVerticalPosition(ref.current?.offsetHeight ?? 0, height);
  });

  return {
    onEndMovement,
    onEndResizing,
    onMovement,
    onResizing,
    onStartMovement: updateDimensions,
    onStartResizing: updateDimensions,
    positionRef,
  };
};
