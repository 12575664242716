import { Cms } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Iframe } from '../../../../components/various/Iframe';
import { getPlayerUrl, getSourceByUrl, getVideoId } from '../../../../logic/cms/player';
import { useIsReorderingContent } from '../../../../utils/hooks';
import { isDefined } from '../../../../utils/is';
import { isSafeUrl } from '../../../../utils/safeUrl';
import { LazyRender } from '../../../various/LazyRender';

interface Props {
  parameters: Cms.EmbedSettings;
}

export const Embed = ({ parameters }: Props) => {
  const { t } = useTranslation(['cms']);

  const { url, playerData } = parameters;
  const source = getSourceByUrl(url);
  const isReorderingContent = useIsReorderingContent();

  const getVimeoParams = () => {
    if (!isDefined(playerData)) {
      return '';
    }
    const autoplay = `autoplay=${+playerData.autoplay}`;
    const mute = `muted=${+playerData.mute}`;
    const loop = `loop=${+playerData.loop}`;

    const totalSeconds = playerData.start;
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor(totalSeconds / 60) % 60;
    const seconds = totalSeconds % 60;
    const start = `#t=${hours}h${minutes}m${seconds}s`;

    return [autoplay, mute, loop].join('&') + start;
  };

  const getYouTubeParams = (id: string) => {
    if (!isDefined(playerData)) {
      return '';
    }

    const autoplay = `autoplay=${+playerData.autoplay}`;
    const mute = `mute=${+playerData.mute}`;
    const loop = playerData.loop ? `loop=${+playerData.loop}&playlist=${id}` : '';
    const start = `start=${playerData.start}`;

    return [autoplay, mute, loop, start].join('&');
  };

  const getVideoParams = (id: string) => {
    if (source === 'Vimeo') {
      return getVimeoParams();
    }

    if (source === 'YouTube') {
      return getYouTubeParams(id);
    }

    return '';
  };

  const getIframeSrc = React.useCallback(() => {
    if (isDefined(source) && source !== 'URL') {
      const playerUrl = getPlayerUrl(source);
      const videoId = getVideoId(url, source);
      const videoParams = getVideoParams(videoId);

      return `${playerUrl}/${videoId}?${videoParams}`;
    }

    return url;
  }, [source, url]);

  const iframeSrc = getIframeSrc();
  const isSafeIframeSrc = isSafeUrl(iframeSrc);
  const safeIframeSrc = isSafeIframeSrc ? iframeSrc : '';

  const iframeElement = <Iframe src={safeIframeSrc} title={t('cms:embedded', { source })} />;

  if (isReorderingContent) {
    return iframeElement;
  }

  return (
    <LazyRender estimatedHeight="100%" triggerOnce>
      {iframeElement}
    </LazyRender>
  );
};
