import { Addresses } from '@typings';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getUser, updateDefaultShippingAddress } from '../../../ducks';
import { getIsBuyer } from '../../../logic/User';
import { useAddShippingCountry } from '../../../services/hooks/account/useAddShippingCountry';
import { useUpdateShippingAddress } from '../../../services/hooks/account/useUpdateShippingAddress';
import { useModalVisibility } from '../../../utils/hooks';
import { ModalSize } from '../../various/ModalElements';
import { ModalShell } from '../../various/ModalShell';

import { AddShippingCountryForm } from './AddShippingCountryForm';

const DEFAULT_SHIPPING_ADDRESS_ID = '0';

interface Props {
  selectedAddress?: Addresses.Shipping;
  accountId: string;
}

export const AddShippingCountryModal = ({ selectedAddress, accountId }: Props) => {
  const { t } = useTranslation(['shipping']);
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const isBuyer = getIsBuyer(user);

  const { isModalVisible, hideModal } = useModalVisibility('AddShippingCountryModal');

  const { mutate: addShippingCountry, isPending: isAddingCountry } = useAddShippingCountry();
  const { mutate: updateShippingAddress, isPending: isUpdatingShippingAddress } = useUpdateShippingAddress();

  const handleSubmit = (data: Addresses.ShippingAddressDTO) => {
    if (isBuyer) {
      addShippingCountry(
        { country: data.country as string, state: data.state },
        {
          onSuccess: resData => {
            dispatch(updateDefaultShippingAddress(resData));
          },
        },
      );

      return;
    }

    const payload = {
      accountId,
      data,
      shippingAddressId: selectedAddress?.shippingAddress ?? DEFAULT_SHIPPING_ADDRESS_ID,
    };

    updateShippingAddress(payload, {
      onSuccess: resData => {
        dispatch(updateDefaultShippingAddress(resData));
      },
    });
  };

  const isLoading = isAddingCountry || isUpdatingShippingAddress;

  return (
    <ModalShell isOpen={isModalVisible} title={t('shipping:add_country_or_state')} onClose={hideModal} size={ModalSize.Medium}>
      <AddShippingCountryForm selectedAddress={selectedAddress} isLoading={isLoading} onCancel={hideModal} onSubmit={handleSubmit} />
    </ModalShell>
  );
};
