import { useQuery } from '@tanstack/react-query';

import { fetchLinesheetTemplates } from '../../documentsRepository';

export const useLinesheetTemplates = () => {
  return useQuery({
    queryFn: fetchLinesheetTemplates,
    queryKey: ['linesheetTemplates'],
  });
};
