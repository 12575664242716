import { AvailabilityAttribute, Brand, Category, Collection, DeliveryWindowAttribute, Filter, Product, SwatchData } from '@typings';

import { is } from '../../utils/is';
import { report } from '../../utils/monitoring';

type Any = Filter.Shapes.Any;

const isBrandsField = is<Filter.Field<Brand>>(({ field }: Filter.Field<Any>) => field === 'brands');
const isCategoriesField = is<Filter.Field<Category>>(({ field }: Filter.Field<Any>) => field === 'categories');
const isCollectionsField = is<Filter.Field<Collection>>(({ field }: Filter.Field<Any>) => field === 'collections');
const isDeliveryWindowsField = is<Filter.Field<DeliveryWindowAttribute>>(({ field }: Filter.Field<Any>) => field === 'deliveryWindows');
const isAvailabilityFilter = is<Filter.Field<AvailabilityAttribute>>(({ field }: Filter.Field<Any>) => field === 'onlyAvailable');
const isBadgeFilter = is<Filter.Field<Product.CustomBadgeDTO>>(({ field }: Filter.Field<Any>) => field === 'sh_custom_badge.name');

const isSwatchField = is<Filter.Field<SwatchData>>(({ field }: Filter.Field<Any>) => field === 'swatch.desc' || field === 'sh_swatch.desc');

/**
 * Makes sure the fields contain display names expressed as strings.
 *
 * @param field Raw data coming from the API.
 * @returns Data consumed by `<Fieldset />` component.
 */
export const getFlattenedField = (field: Filter.Field<Any>): Filter.Field<string> => {
  const { field: attribute, isRadio } = field;

  if (isBrandsField(field)) {
    return {
      field: attribute,
      values: field.values.map(value => ({
        ...value,
        data: value.data.brandName,
      })),
    };
  }

  if (isCategoriesField(field)) {
    return {
      field: attribute,
      values: field.values.map(value => ({
        ...value,
        data: value.data.name[value.data.name.length - 1] ?? '',
        parent: value.data.inCategory,
      })),
    };
  }

  if (isCollectionsField(field)) {
    return {
      field: attribute,
      values: field.values.map(value => ({
        ...value,
        data: value.data.collectionName,
      })),
    };
  }

  if (isDeliveryWindowsField(field)) {
    return {
      field: attribute,
      values: field.values.map(value => ({
        ...value,
        data: value.data.name,
      })),
    };
  }

  if (isAvailabilityFilter(field)) {
    return {
      field: attribute,
      isRadio,
      values: field.values.map(value => ({
        ...value,
        data: value.data.name,
      })),
    };
  }

  if (isBadgeFilter(field)) {
    return {
      field: attribute,
      values: field.values.map(value => ({
        ...value,
        badge: value.data,
        data: value.data.name,
      })),
    };
  }

  if (isSwatchField(field)) {
    return {
      field: attribute,
      values: field.values.map(value => ({
        ...value,
        data: value.data.desc,
        swatch: value.data,
      })),
    };
  }

  /**
   * Ignore unrecognized attribute and proceed with the rest.
   *
   * Centra allows creating custom attributes of any shape.
   * These should not be available in Showroom API settings,
   * but until it's done, it's better to hide them from the users.
   */
  const hasInvalidValues = field.values.some(({ data }) => typeof data !== 'string');

  if (field.values.length && hasInvalidValues) {
    return {
      ...field,
      values: field.values.filter(value => {
        if (typeof value.data !== 'string') {
          report(
            `Unrecognized attribute of type "${attribute}" was ignored. Add following shape to the mapping function: ${JSON.stringify(
              value.data,
            )}`,
          );
        }

        return typeof value.data === 'string';
      }) as Filter.Option<string>[],
    };
  }

  return field as Filter.Field<string>;
};

/**
 * Top-level categories don't belong to any category themselves.
 */
export const isMainCategory = (category: Category): boolean => category.inCategory === undefined;
