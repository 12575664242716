import cx from 'classnames';

import styles from './FormDivider.module.scss';

interface Props {
  className?: string;
}

export const FormDivider = ({ className }: Props) => {
  return <hr className={cx(styles.divider, className)} />;
};
