import { isDefined } from './is';

const HTML_ATTRIBUTE_REGEXP = /((\w+)(=["'](.*?)["'])?)/g;
const QUOTATION_REGEXP = /^['"]|['"]$/g;
const NAME_VALUE_REGEXP = /=(.+)/;

export const getHtmlAttributesFromString = (source: string): Record<string, string> | undefined =>
  source.match(HTML_ATTRIBUTE_REGEXP)?.reduce((acc, cur) => {
    const { 0: name, 1: value } = cur.split(NAME_VALUE_REGEXP).flatMap(str => str.replace(QUOTATION_REGEXP, ''));

    if (!isDefined(name)) {
      return acc;
    }

    return { ...acc, ...{ [name]: value } };
  }, {});
