import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getIsLoadingCmsProducts, getIsLoadingCmsProductsFromSearch, getIsLoadingMoreCmsProductsFromSearch } from '../../../ducks/cms';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { Input } from '../../various/Fields/Input';
import { IconType } from '../../various/Icon';
import { LazyList } from '../../various/LazyList';
import LoadMore from '../../various/loaders/LoadMore';

import { NoProductsMessage } from './NoProductsMessage';
import styles from './ProductsList.module.scss';

interface Props {
  allLoaded: boolean;
  searchPhrase: string;
  onSearchChange: (value: string) => void;
  items: (string | number)[];
  renderFunction: (property: string | number) => JSX.Element | null;
  loadMore: () => void;
  markets: string[];
  listText?: JSX.Element | string;
  elementBelowSearch?: JSX.Element | string;
  compactSearch?: boolean;
}

export const LazyProductsList = React.memo(
  ({
    allLoaded,
    searchPhrase,
    onSearchChange,
    renderFunction,
    items,
    loadMore,
    listText,
    markets,
    elementBelowSearch,
    compactSearch = false,
  }: Props) => {
    const { t } = useTranslation(['cms']);
    const isLoading = useSelector(getIsLoadingCmsProducts);
    const isLoadingSearch = useSelector(getIsLoadingCmsProductsFromSearch);
    const inputRef = React.useRef<HTMLInputElement>(null);
    const isLoadingMore = useSelector(getIsLoadingMoreCmsProductsFromSearch);
    const isLoadingAnything = isLoading || isLoadingSearch || isLoadingMore;
    const thereAreNoProducts = isEmpty(items) && !isLoadingAnything;

    React.useEffect(() => {
      inputRef.current?.focus();
    }, []);

    return (
      <div className={cx(styles.listWrapper, { [styles.compact]: compactSearch })}>
        {isDefined(listText) && <p className={styles.listText}>{listText}</p>}
        <div className={styles.lazyProductsList}>
          <label aria-label={t('cms:search_for_products')} className={styles.search}>
            <Input
              ref={inputRef}
              icon={IconType.Search}
              value={searchPhrase}
              placeholder={t('cms:search_for_products')}
              isClearable
              onChange={event => onSearchChange(event.target.value)}
            />
          </label>
          {elementBelowSearch}
          {(isEmpty(items) && isLoading) || isLoadingSearch ?
            <LoadMore isLoading title={t('cms:products_loading')} />
          : thereAreNoProducts ?
            <NoProductsMessage markets={markets} />
          : <div className={styles.scrollableList}>
              <LazyList
                items={items}
                allLoaded={allLoaded}
                loaderMessage={t('cms:products_loading')}
                isLoading={isLoadingMore}
                onLoadMore={loadMore}
              >
                {product => (
                  <div className={styles.listItem} key={product}>
                    {renderFunction(product)}
                  </div>
                )}
              </LazyList>
            </div>
          }
        </div>
      </div>
    );
  },
);
