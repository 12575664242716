import i18next from 'i18next';
import { push, replace } from 'redux-first-history';
import { combineEpics, ofType } from 'redux-observable';
import { EMPTY, merge, of, switchMap, takeUntil, timer } from 'rxjs';
import { filter, map, mergeMap, scan } from 'rxjs/operators';
import { getType, isActionOf } from 'typesafe-actions';

import { addToast } from '../components/various/Toasts';
import {
  addProductByBarcodeSoldOutFailure,
  addProductsToOrder,
  clearProductDetailsData,
  fetchOrderSuccess,
  getActiveDeliveryWindowsIdsForOrderBuyer,
  getActiveFilterKeys,
  getBuyerIdFromCurrentOrder,
  getIsStockTypeSeparationEnabled,
  getProductFilters,
  getProductsPageCount,
  getProductsPerRequest,
  getUser,
  getUserAccess,
  loadInitialOrderDetails,
  loadMoreProductsFailure,
  loadMoreProductsRequest,
  loadMoreProductsSuccess,
  loadProductsFailure,
  loadProductsRequest,
  loadProductsSuccess,
  loadSingleProductByBarcodeRequest,
  loadSingleProductFailure,
  loadSingleProductRequest,
  loadSingleProductSuccess,
  pushEvent,
  reloadPage,
  resetFilters,
  setProductsEtaDates,
  updateFilters,
} from '../ducks';
import { getHasAvailableVariants, getNativeVariants } from '../ducks/helpers';
import { getCurrentOrDefaultProductSortingValue, getStockTypeFilter } from '../ducks/products/selectors';
import { getProductSearchQuery } from '../logic/products';
import { compiledPaths } from '../paths';
import { BarcodeScannerTrackingEvent } from '../utils/analytics/events';
import { isDefined } from '../utils/is';
import { isEmpty } from '../utils/isEmpty';
import { holdWhile } from '../utils/operators/holdWhile';
import { mapResponse } from '../utils/operators/mapResponse';

import { PRODUCTS_RELOAD_ACTIONS_GROUP } from './actionGroups';

const NUMBER_OF_FAILED_ATTEMPTS = 3;
const DEBOUNCE_TIME = 300;

const getUserData = (store: Store) => ({
  access: getUserAccess(store),
  forBuyer: getBuyerIdFromCurrentOrder(store),
});

const getData = (store: Store) => {
  const activeFilterKeys = getActiveFilterKeys(store);

  const filterEntries = Object.entries(getProductFilters(store)).filter(([key]) => activeFilterKeys.includes(key));

  return {
    filters: Object.fromEntries(filterEntries),
    limit: getProductsPerRequest(store),
    sorting: getCurrentOrDefaultProductSortingValue(store),
    userData: getUserData(store),
  };
};

const loadProductsEpic: AppEpic = (action$, store$, { productsRepository }) =>
  action$.pipe(
    filter(isActionOf(loadProductsRequest)),
    holdWhile(store$, store => !getIsStockTypeSeparationEnabled(store) || isDefined(getStockTypeFilter(store))),
    map(() => getData(store$.value)),
    switchMap(async ({ filters, limit, userData, sorting }) => {
      return productsRepository.fetchProductsByFilters({
        filters,
        pagination: { limit, offset: 0 },
        sorting,
        userData,
      });
    }),
    mapResponse(
      response => loadProductsSuccess(response.data),
      () => [replace({ pathname: compiledPaths.PAGE_NOT_FOUND({}) }), loadInitialOrderDetails(), loadProductsFailure()],
    ),
  );

const loadProductsEtaDatesEpic: AppEpic = (action$, store$, { productsRepository }) =>
  merge(
    action$.pipe(filter(isActionOf([fetchOrderSuccess, resetFilters]))),
    action$.pipe(
      filter(isActionOf(updateFilters)),
      filter(({ payload }) => Object.keys(payload.filters).some(key => ['deliveryWindows', 'search'].includes(key))),
    ),
  ).pipe(
    switchMap(async () => {
      const { search, deliveryWindows } = getProductFilters(store$.value);

      return productsRepository.fetchProductsEtaDates({ deliveryWindows, search }, getUserData(store$.value));
    }),
    mapResponse(
      response => setProductsEtaDates(response.data),
      () => EMPTY,
    ),
  );

const loadMoreProductsEpic: AppEpic = (action$, store$, { productsRepository }) =>
  action$.pipe(
    filter(isActionOf(loadMoreProductsRequest)),
    map(() => getProductsPageCount(store$.value) * getProductsPerRequest(store$.value)),
    scan((acc, offset) => ({ count: acc.count + 1, offset }), { count: 0, offset: 0 }),
    mergeMap(payload =>
      of(payload).pipe(
        map(({ offset }) => ({
          ...getData(store$.value),
          offset,
        })),
        mergeMap(async ({ filters, sorting, limit, offset, userData }) => {
          return productsRepository.fetchProductsByFilters({
            filters,
            pagination: { limit, offset },
            sorting,
            userData,
          });
        }),
        mapResponse(
          response => loadMoreProductsSuccess(response.data),
          () => {
            const getAction = () => {
              if (payload.count <= NUMBER_OF_FAILED_ATTEMPTS) {
                return {
                  action: loadMoreProductsRequest(),
                  label: i18next.t('common:try_again'),
                };
              }

              return {
                action: reloadPage(),
                label: i18next.t('common:reload_page'),
              };
            };

            addToast(
              i18next.t('products:load_more_products_fail', {
                actions: [getAction()],
                duration: 0,
              }),
            );

            return loadMoreProductsFailure();
          },
        ),
      ),
    ),
  );

const loadSingleProductEpic: AppEpic = (action$, store$, { productsRepository }) =>
  action$.pipe(
    filter(isActionOf(loadSingleProductRequest)),
    holdWhile(
      store$,
      store => isDefined(getBuyerIdFromCurrentOrder(store)) && !isEmpty(getBuyerIdFromCurrentOrder(store)) && !isEmpty(getUser(store)),
    ),
    switchMap(action =>
      of({
        deliveryWindows: [],
        productId: action.payload.productId,
        userData: getUserData(store$.value),
      }).pipe(
        switchMap(async ({ productId, userData, deliveryWindows }) =>
          productsRepository.fetchSingleProduct(productId, deliveryWindows, userData),
        ),
        mapResponse(
          response => loadSingleProductSuccess({ ...response.data, isLookbook: false }),
          () => loadSingleProductFailure(),
        ),
      ),
    ),
  );

const loadSingleProductByBarcodeEpic: AppEpic = (action$, store$, { productsRepository }) =>
  action$.pipe(
    filter(isActionOf(loadSingleProductByBarcodeRequest)),
    map(action => {
      const store = store$.value;
      const deliveryWindows = getActiveDeliveryWindowsIdsForOrderBuyer(store);

      return {
        deliveryWindows,
        productBarcode: action.payload.productBarcode,
        userData: getUserData(store),
      };
    }),
    switchMap(({ productBarcode, userData, deliveryWindows }) =>
      timer(DEBOUNCE_TIME).pipe(
        mergeMap(async () => productsRepository.fetchSingleProductByBarcode(productBarcode, deliveryWindows, userData)),
        mapResponse(
          ({ data }) => {
            const { product, items } = data.product;
            const hasMultipleSizesWithSameBarcode = items.filter(({ ean }) => ean === productBarcode).length > 1;

            const sharedEventsToReturn = [
              loadSingleProductSuccess({ ...data, isLookbook: false, scannedBarcode: productBarcode }),
              pushEvent({ event: BarcodeScannerTrackingEvent.PRODUCT_LOADED }),
              ...(hasMultipleSizesWithSameBarcode ? [pushEvent({ event: BarcodeScannerTrackingEvent.MULTIPLE_SIZES })] : []),
            ];
            const nativeVariants = getNativeVariants(data.product, data.product.product);
            const hasAvailableVariants = getHasAvailableVariants(nativeVariants);
            const { shouldAddToSelection } = store$.value.barcodeScanner;

            const addingByBarcodeAction =
              hasAvailableVariants ?
                addProductsToOrder({ deliveryWindows, isAddingByBarcode: shouldAddToSelection, products: [product] })
              : addProductByBarcodeSoldOutFailure();

            return shouldAddToSelection ?
                [...sharedEventsToReturn, addingByBarcodeAction]
              : [push({ search: getProductSearchQuery({ productId: product }) }), ...sharedEventsToReturn];
          },
          () => [
            loadSingleProductFailure(),
            clearProductDetailsData(),
            pushEvent({ event: BarcodeScannerTrackingEvent.PRODUCT_NOT_FOUND }),
          ],
        ),
        takeUntil(action$.pipe(ofType(getType(loadSingleProductByBarcodeRequest)))),
      ),
    ),
  );

const reloadProductsEpics: AppEpic = action$ =>
  action$.pipe(
    filter(isActionOf(PRODUCTS_RELOAD_ACTIONS_GROUP)),
    map(() => loadProductsRequest()),
  );

export const productsEpic = combineEpics(
  loadMoreProductsEpic,
  loadProductsEpic,
  loadProductsEtaDatesEpic,
  loadSingleProductEpic,
  reloadProductsEpics,
  loadSingleProductByBarcodeEpic,
);
