import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Buyer, Id } from '@typings';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

import { addToast } from '../../../components/various/Toasts';
import { getResponseErrors } from '../../../utils/errors';
import { updateBuyer } from '../../accountRepository';

interface MutationVariables {
  accountId: Id;
  buyerId: Id;
  data: Buyer.BuyerDTO;
}

export const useUpdateBuyer = () => {
  const { t } = useTranslation(['buyers']);
  const queryClient = useQueryClient();

  const mutationFn = async ({ accountId, buyerId, data }: MutationVariables) => {
    return updateBuyer(accountId, buyerId, data);
  };

  const handleSuccess = (_: Buyer.Extended, { accountId }: MutationVariables) => {
    addToast(t('buyers:buyer_updated'));
    queryClient.invalidateQueries({ queryKey: ['accountDetails', { accountId }] });
  };

  const handleError = (error: AxiosError<Responses.Errors>) => {
    const [errorMessage] = getResponseErrors(error);
    addToast(errorMessage);
  };

  return useMutation({
    mutationFn,
    mutationKey: ['updateBuyer'],
    onError: handleError,
    onSuccess: handleSuccess,
  });
};
