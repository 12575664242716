import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { closeButtonStyleEditor, getButtonStyleEditorMode, getIsButtonStyleEditorVisible, setIsHoverTabOpen } from '../../../../ducks';
import { EditorDialog } from '../EditorDialog';

import { ButtonStylesEditorContent } from './ButtonStylesEditorContent';

export const ButtonStyleEditor = () => {
  const { t } = useTranslation(['cms']);
  const dispatch = useDispatch();
  const isEditorVisible = useSelector(getIsButtonStyleEditorVisible);
  const mode = useSelector(getButtonStyleEditorMode);

  const handleClose = () => {
    dispatch(closeButtonStyleEditor());
    dispatch(setIsHoverTabOpen(false));
  };

  const title = mode === 'edit' ? t('cms:edit_button_style') : t('cms:create_new_button_style');

  return (
    <EditorDialog isVisible={isEditorVisible} id="buttonStyleEditorHeading" title={title} onClose={handleClose}>
      <ButtonStylesEditorContent />
    </EditorDialog>
  );
};
