/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;

export const TranslationWarning = ({ width = ORIGINAL_WIDTH }: IconComponentProps) => {
  return (
    <svg width={width} height={width} viewBox="0 0 20 20">
      <path
        d="M9.88684 18L13.3182 9H14.9019L18.3333 18H16.7496L15.9577 15.7125H12.3001L11.4705 18H9.88684ZM12.7526 14.4H15.4675L14.1478 10.6875H14.0724L12.7526 14.4ZM3.92907 15.75L2.87326 14.7L6.68171 10.9125C6.20408 10.3875 5.78628 9.84375 5.42832 9.28125C5.06984 8.71875 4.75863 8.125 4.49468 7.5H6.07839C6.30464 7.95 6.54672 8.35625 6.80464 8.71875C7.06205 9.08125 7.37301 9.4625 7.73752 9.8625C8.29056 9.2625 8.74933 8.647 9.11384 8.016C9.47834 7.3845 9.78629 6.7125 10.0377 6H1.66663V4.5H6.94566V3H8.45396V4.5H13.733V6H11.546C11.282 6.8875 10.9238 7.75 10.4713 8.5875C10.0188 9.425 9.45949 10.2125 8.79332 10.95L10.6033 12.7875L10.0377 14.325L7.69981 12L3.92907 15.75Z"
        fill="#EC7A09"
      />
    </svg>
  );
};
