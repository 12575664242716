/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 14;

export const Info = ({ width = ORIGINAL_WIDTH }: IconComponentProps) => {
  return (
    <svg width={width} height={width} viewBox="0 0 14 14">
      <path
        d="M7 0C3.136 0 0 3.136 0 7C0 10.864 3.136 14 7 14C10.864 14 14 10.864 14 7C14 3.136 10.864 0 7 0ZM7 10.5C6.615 10.5 6.3 10.185 6.3 9.8V7C6.3 6.615 6.615 6.3 7 6.3C7.385 6.3 7.7 6.615 7.7 7V9.8C7.7 10.185 7.385 10.5 7 10.5ZM7.7 4.9H6.3V3.5H7.7V4.9Z"
        fill="#80A3B0"
      />
    </svg>
  );
};
