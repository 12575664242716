import { Variants } from '@typings';

function getDisplayedMax(stock: number | string, stockNumbersMaximum: number, stockNumbersVisible: boolean): string {
  if (stockNumbersMaximum !== 0) {
    return `${stockNumbersMaximum}+`;
  }

  if (stockNumbersMaximum === 0 && !stockNumbersVisible) {
    return '...';
  }

  // If stockNumbersVisible=true && stockNumbersMaximum === 0 we show real stock as well
  return `(${stock})`;
}

export function formatQuantity(stock: Variants.Stock, stockNumbersMaximum: number, stockNumbersVisible: boolean): string {
  if (stock === 'no' || stock === 0) {
    /** 1. false → we cannot add product unit to order */
    return '';
  }

  if (stock === 'limited') {
    /** 2. few → product unit can be added, but only a few left, we don't now how many exactly */
    return 'few';
  }

  if (stock === 'yes' || stock === 'preorder' || stock === 'infinite' || (stock as number) > stockNumbersMaximum) {
    /**
     * 3. if stock is not visible are greater than maximum visible
     * → let's hide it under generic stockNumbersMaximum+
     */
    return getDisplayedMax(stock, stockNumbersMaximum, stockNumbersVisible);
  }

  /** 4. finally we can also display real stock */
  return `(${stock})`;
}
