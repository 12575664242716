import { Preset } from '@typings';
import { mergeDeepRight } from 'ramda';
import { createReducer } from 'typesafe-actions';

import * as actions from './actions';

const initialState: Preset.Map = {
  distributions: {},
  prepacks: {},
};

export default createReducer<Preset.Map, AppAction>(initialState).handleAction(actions.setPresets, (state, action) =>
  mergeDeepRight(state, action.payload),
);
