import { Announcements, UniqueIdentifier } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { FlattenedItem, SortableNestItems, SortableTreeItemProjection } from '@typings/components/sortableTree';
import { clone } from 'ramda';
import React from 'react';

import { flattenTree } from '../../../components/various/Sortable/SortableTree/utilities';

export const useAccessibility = (projected: SortableTreeItemProjection | null, items: SortableNestItems) => {
  const [currentPosition, setCurrentPosition] = React.useState<{
    parentId: UniqueIdentifier | null;
    overId: UniqueIdentifier;
  } | null>(null);

  const getMovementAnnouncement = React.useCallback(
    (eventName: string, currentlyActiveId: UniqueIdentifier, currentOverId?: UniqueIdentifier) => {
      if (!currentOverId || !projected) {
        return;
      }
      if (eventName !== 'onDragEnd') {
        if (currentPosition && projected.parentId === currentPosition.parentId && currentOverId === currentPosition.overId) {
          return;
        }
        setCurrentPosition({
          overId: currentOverId,
          parentId: projected.parentId,
        });
      }

      const clonedItems: FlattenedItem[] = clone(flattenTree(items));
      const overIndex = clonedItems.findIndex(({ id }) => id === currentOverId);
      const activeIndex = clonedItems.findIndex(({ id }) => id === currentlyActiveId);
      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);

      const previousItem = sortedItems[overIndex - 1];

      let announcement;
      const movedVerb = eventName === 'onDragEnd' ? 'dropped' : 'moved';
      const nestedVerb = eventName === 'onDragEnd' ? 'dropped' : 'nested';

      if (!previousItem) {
        const nextItem = sortedItems[overIndex + 1];
        announcement = `${currentlyActiveId} was ${movedVerb} before ${nextItem?.id}.`;
      } else {
        if (projected.depth > previousItem.depth) {
          announcement = `${currentlyActiveId} was ${nestedVerb} under ${previousItem.id}.`;
        } else {
          let previousSibling: FlattenedItem | undefined = previousItem;
          while (previousSibling && projected.depth < previousSibling.depth) {
            // eslint-disable-next-line prefer-destructuring
            const parentId: UniqueIdentifier | null = previousSibling.parentId;
            previousSibling = sortedItems.find(({ id }) => id === parentId);
          }

          if (previousSibling) {
            announcement = `${currentlyActiveId} was ${movedVerb} after ${previousSibling.id}.`;
          }
        }
      }

      return announcement;
    },
    [projected, items, currentPosition],
  );

  const resetAccessibilityPosition = () => {
    setCurrentPosition(null);
  };

  const announcements: Announcements = {
    onDragCancel({ active }) {
      return `Moving was cancelled. ${active.id} was dropped in its original position.`;
    },
    onDragEnd({ active, over }) {
      return getMovementAnnouncement('onDragEnd', active.id, over?.id);
    },
    onDragMove({ active, over }) {
      return getMovementAnnouncement('onDragMove', active.id, over?.id);
    },
    onDragOver({ active, over }) {
      return getMovementAnnouncement('onDragOver', active.id, over?.id);
    },
    onDragStart({ active }) {
      return `Picked up ${active.id}.`;
    },
  };

  return { announcements, resetAccessibilityPosition, setCurrentAccessibilityPosition: setCurrentPosition };
};
