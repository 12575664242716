/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;
const ORIGINAL_COLOR = '#848484';

export const Hotspot = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => (
  <svg width={width} height={width} viewBox="0 0 20 20" fill="none">
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.594 10.287L10.294 16.587C10.042 16.839 9.685 17 9.3 17C8.915 17 8.565 16.846 8.313 16.587L3.413 11.687C3.154 11.435 3 11.085 3 10.7C3 10.315 3.154 9.965 3.406 9.713L9.706 3.413C9.965 3.154 10.315 3 10.7 3L15.6 3C16.37 3 17 3.63 17 4.4L17 9.3C17 9.685 16.846 10.035 16.594 10.287ZM14.55 4.4C13.969 4.4 13.5 4.869 13.5 5.45C13.5 6.031 13.969 6.5 14.55 6.5C15.131 6.5 15.6 6.031 15.6 5.45C15.6 4.869 15.131 4.4 14.55 4.4Z"
    />
  </svg>
);
