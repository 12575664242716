import { Sorting } from '../../../../../typings';
import { CheckoutStepName } from '../../../components/various/CheckoutSteps';

type ProductListingView = 'list' | 'grid';

const enum TopBarTrackingEventWithParameters {
  PRODUCT_LISTING_SWITCHER_CLICKED = 'top-bar-product-listing-clicked',
  SORT_BY_SELECTED = 'top-bar-sort-by-selected',
  CHECKOUT_STEPS_CLICKED = 'top-bar-checkout-steps-clicked',
}

export const getProductListingSwitcherClickedEvent = (productListingView: ProductListingView) => ({
  event: TopBarTrackingEventWithParameters.PRODUCT_LISTING_SWITCHER_CLICKED,
  productListingView,
});

export const getSortBySelectedEvent = (sortByValue: Sorting.FieldValue) => ({
  event: TopBarTrackingEventWithParameters.SORT_BY_SELECTED,
  sortByValue,
});

export const getCheckoutStepsClickedEvent = (checkoutStepName: CheckoutStepName) => ({
  checkoutStepName,
  event: TopBarTrackingEventWithParameters.CHECKOUT_STEPS_CLICKED,
});

export const enum TopBarTrackingEvent {
  CLEAR_ALL_CLICKED = 'top-bar-clear-all-products-clicked',
  ADD_ALL_CLICKED = 'top-bar-add-all-clicked',
  DOWNLOAD_ORDER_CLICKED = 'top-bar-download-order-clicked',
}
