import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { Cms, Id } from '@typings';
import cx from 'classnames';
import { $getSelection, $isRangeSelection, $setSelection } from 'lexical';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { updateLocalizedBlockSettings } from '../../../../../../ducks/cms/localizations';
import { isDefined } from '../../../../../../utils/is';
import { isEmpty } from '../../../../../../utils/isEmpty';
import { ContentBlockContext } from '../../../../../cms/context/ContentBlockContext';
import { EditorLanguageContext } from '../../../../../cms/context/EditorLanguageContext';
import { LinkMarkerActions } from '../../../../../cms/TextLink/LinkMarkerActions';
import linkStyles from '../../../../../cms/TextLink/TextLink.module.scss';
import { TextLinkModal } from '../../../../../cms/TextLink/TextLinkModal';
import { IconType } from '../../../../../various/Icon';
import { TextEllipsis } from '../../../../../various/TextEllipsis';

import styles from './ButtonPart.module.scss';

interface Props {
  link: Cms.LinkSettings | undefined;
  isInEditing: boolean;
  partId: Nullable<Id>;
}

export const ButtonLinkMarkerPlugin = ({ link, isInEditing, partId }: Props) => {
  const [editor] = useLexicalComposerContext();
  const { editorLanguage } = React.useContext(EditorLanguageContext);
  const url = link?.url ?? '';
  const newTab = link?.newTab ?? false;
  const dispatch = useDispatch();
  const { blockId } = React.useContext(ContentBlockContext);
  const { t } = useTranslation(['cms']);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const isLinkEmpty = isEmpty(url);
  const addEditActionName = isEmpty(url) ? t('cms:add_link') : t('cms:change_link');
  const initialEntitySettings = {
    newTab,
    url,
  };

  const updateSelection = React.useCallback(() => {
    editor.update(() => {
      const newSelection = $getSelection();
      $isRangeSelection(newSelection) && $setSelection(newSelection);
    });
  }, [editor]);

  const handleCloseModal = React.useCallback(() => {
    updateSelection();
    setIsModalOpen(false);
  }, [updateSelection]);

  const updateLink = React.useCallback(
    (newLink: Nullable<Cms.LinkSettings>) => {
      if (!isDefined(blockId) || !isDefined(partId)) {
        return;
      }

      handleCloseModal();
      const updatePayload = {
        blockId,
        updatePath: ['text', 'parts', partId, 'general', 'link'],
        value: newLink,
      };

      dispatch(
        updateLocalizedBlockSettings({
          ...updatePayload,
          language: editorLanguage,
        }),
      );
    },
    [blockId, dispatch, editorLanguage, handleCloseModal, partId],
  );

  const handleAddChangeLink = React.useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleRemoveLink = () => updateLink(null);

  const actions = [
    {
      actionName: addEditActionName,
      dataTestId: 'addLinkToButton',
      hidden: false,
      iconType: isLinkEmpty ? IconType.Link : IconType.Edit,
      onClick: handleAddChangeLink,
    },
    {
      actionName: t('cms:unlink'),
      hidden: isLinkEmpty,
      iconType: IconType.Unlink,
      onClick: handleRemoveLink,
    },
  ];

  const handleSubmit = React.useCallback(
    (settings: Cms.LinkSettings) => {
      if (isEmpty(settings.url)) {
        return;
      }

      updateLink({
        newTab: settings.newTab,
        url: settings.url,
      });
    },
    [updateLink],
  );

  if (isInEditing) {
    return (
      <>
        <div className={cx(linkStyles.linkMarker, styles.linkMarker)}>
          {!isLinkEmpty && (
            <TextEllipsis as="div" className={linkStyles.linkText}>
              {url}
            </TextEllipsis>
          )}
          <LinkMarkerActions actions={actions} />
        </div>
        <TextLinkModal
          title={addEditActionName}
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onSubmit={handleSubmit}
          defaultValues={initialEntitySettings}
        />
      </>
    );
  }

  if (isLinkEmpty) {
    return <div className={styles.linkWarning} data-content={t('cms:unlinked_button')} />;
  }

  return null;
};
