import accounts from '../../../translations/en/accounts.json';
import buyers from '../../../translations/en/buyers.json';
import cancelled from '../../../translations/en/cancelled.json';
import checkout from '../../../translations/en/checkout.json';
import checkoutMessages from '../../../translations/en/checkoutMessages.json';
import cms from '../../../translations/en/cms.json';
import cmsConfirmationConfig from '../../../translations/en/cmsConfirmationConfig.json';
import common from '../../../translations/en/common.json';
import configErrors from '../../../translations/en/configErrors.json';
import confirmationConfig from '../../../translations/en/confirmationConfig.json';
import dates from '../../../translations/en/dates.json';
import expiredDelwins from '../../../translations/en/expiredDelwins.json';
import genericErrors from '../../../translations/en/genericErrors.json';
import invoices from '../../../translations/en/invoices.json';
import linesheets from '../../../translations/en/linesheets.json';
import login from '../../../translations/en/login.json';
import lookbook from '../../../translations/en/lookbook.json';
import menu from '../../../translations/en/menu.json';
import onboarding from '../../../translations/en/onboarding.json';
import orders from '../../../translations/en/orders.json';
import pagination from '../../../translations/en/pagination.json';
import passwordManagement from '../../../translations/en/passwordManagement.json';
import payments from '../../../translations/en/payments.json';
import products from '../../../translations/en/products.json';
import selectionOverview from '../../../translations/en/selectionOverview.json';
import selections from '../../../translations/en/selections.json';
import settings from '../../../translations/en/settings.json';
import shipping from '../../../translations/en/shipping.json';
import totals from '../../../translations/en/totals.json';
import translations from '../../../translations/en/translations.json';
import validation from '../../../translations/en/validation.json';

export const en = {
  accounts,
  buyers,
  cancelled,
  checkout,
  checkoutMessages,
  cms,
  cmsConfirmationConfig,
  common,
  configErrors,
  confirmationConfig,
  dates,
  expiredDelwins,
  genericErrors,
  invoices,
  linesheets,
  login,
  lookbook,
  menu,
  onboarding,
  orders,
  pagination,
  passwordManagement,
  payments,
  products,
  selectionOverview,
  selections,
  settings,
  shipping,
  totals,
  translations,
  validation,
} as const;
