/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 30;
const ORIGINAL_HEIGHT = 28;
const ORIGINAL_COLOR = '#ffffff';
const PROPORTION = ORIGINAL_HEIGHT / ORIGINAL_WIDTH;

export const Alert = ({ width = 14, color = ORIGINAL_COLOR }: IconComponentProps): JSX.Element => {
  const strokeWidth = ORIGINAL_WIDTH / width;

  return (
    <svg width={width} height={width * PROPORTION} version="1.1" viewBox="0 0 30 28">
      <g stroke="none" strokeWidth={strokeWidth} fill="none" fillRule="evenodd">
        <path
          d="M29.6251042,24.8437354 C29.843855,25.1562365 30.0001055,25.5624879 30.0001055,25.9999894 C30.0001055,27.1249934 29.0938523,27.9999965 28.0000985,27.9999965 L2.00000703,27.9999965 C1.28125451,27.9999965 0.656252308,27.6249952 0.281250989,27.0312431 C-0.0937503297,26.437491 -0.0937503297,25.6874884 0.250000879,25.0624862 L13.2500466,1.03115164 C13.5937978,0.406149445 14.2500501,-0.000101983547 15.0000528,-0.000101983547 C15.7188053,-0.000101983547 16.4063077,0.406149445 16.7500589,1.03115164 L29.6251042,24.8437354 Z M14.9375525,18.187462 L15.0313029,18.187462 C15.6250549,18.187462 16.1875569,17.62496 16.2813073,16.9999578 C16.781309,14.3749486 17.0313099,11.5624387 17.2813108,9.21868044 C17.3125609,8.68742857 16.9375596,8.09367648 16.3438075,7.96867604 C15.3125538,7.78117538 14.7188018,7.78117538 13.6875481,7.96867604 C13.1250462,8.09367648 12.6875446,8.68742857 12.7187947,9.21868044 C12.9687956,11.5624387 13.2187965,14.3749486 13.7187982,16.9999578 C13.8125486,17.62496 14.3750506,18.187462 14.9375525,18.187462 Z M15.0000528,25.2812369 C16.2500571,25.2812369 17.2500607,24.2812334 17.2500607,23.031229 C17.2500607,21.7812246 16.2500571,20.7812211 15.0000528,20.7812211 C13.7500484,20.7812211 12.7500448,21.7812246 12.7500448,23.031229 C12.7500448,24.2812334 13.7500484,25.2812369 15.0000528,25.2812369 Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
