import { Checkout, CheckoutForm, DeliveryWindow } from '@typings';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getCheckoutFormData,
  getInitialSelectedShippingAddressId,
  getOrderDetails,
  getPaymentTerm,
  getUser,
  setActiveOrderPaymentMethod,
} from '../../ducks';
import { getPaymentMethodsByMode } from '../../logic/payments';
import { getIsSeller } from '../../logic/User';
import { useValidatedForm } from '../../utils/hooks/useValidatedForm';
import { isDefined } from '../../utils/is';

import { useCheckoutFormValidationSchema } from './useCheckoutFormValidationSchema';

interface Props {
  deliveryWindows: (DeliveryWindow.Running | DeliveryWindow.Seasonal)[];
  checkoutFields: Checkout.Requirements;
}

export const useCheckoutForm = ({ deliveryWindows, checkoutFields }: Props) => {
  const dispatch = useDispatch();
  const orderDetails = useSelector(getOrderDetails);
  const initialSelectedShippingAddressId = useSelector(getInitialSelectedShippingAddressId);
  const user = useSelector(getUser);
  const isSeller = getIsSeller(user);
  const paymentTerm = useSelector(getPaymentTerm);
  const paymentMethods = getPaymentMethodsByMode(paymentTerm, orderDetails.order.paymentMethods ?? []);
  const defaultFormValues = useSelector(getCheckoutFormData);
  const validationSchema = useCheckoutFormValidationSchema({ checkoutFields, deliveryWindows, orderDetails });
  const defaultPaymentMethod = paymentMethods[0]?.paymentMethod ?? null;

  const defaultShippingDatesPreferences = React.useMemo(
    () =>
      (isDefined(checkoutFields.preferredShippingDateByDeliveryWindow) || isDefined(checkoutFields.cancelDateByDeliveryWindow)) && {
        shippingDatesPreferences: deliveryWindows.reduce((acc, delwin) => {
          const delwinId = delwin.deliveryWindow;
          const preferredShippingDate = defaultFormValues?.shippingDatesPreferences?.[delwinId]?.preferredShippingDate;
          const cancelDate = defaultFormValues?.shippingDatesPreferences?.[delwinId]?.cancelDate;

          return {
            ...acc,
            [delwinId]: {
              cancelDate: cancelDate ?? null,
              preferredShippingDate: preferredShippingDate ?? null,
            },
          };
        }, {}),
      },
    [
      checkoutFields.cancelDateByDeliveryWindow,
      checkoutFields.preferredShippingDateByDeliveryWindow,
      defaultFormValues?.shippingDatesPreferences,
      deliveryWindows,
    ],
  );

  const defaultCancelDate = React.useMemo(
    () =>
      !isDefined(checkoutFields.cancelDateByDeliveryWindow) && {
        cancelDate: defaultFormValues?.cancelDate ?? null,
      },
    [checkoutFields.cancelDateByDeliveryWindow, defaultFormValues?.cancelDate],
  );

  const defaultPreferredShippingDate = React.useMemo(
    () =>
      !isDefined(checkoutFields.preferredShippingDateByDeliveryWindow) && {
        preferredShippingDate: defaultFormValues?.preferredShippingDate ?? null,
      },
    [checkoutFields.preferredShippingDateByDeliveryWindow, defaultFormValues?.preferredShippingDate],
  );

  // account is undefined on initial render and shippingAddresses might be an empty array
  // eslint-disable-next-line no-autofix/@typescript-eslint/no-unnecessary-condition
  const firstShippingAddressId = orderDetails.account?.shippingAddresses?.[0]?.shippingAddress;

  const defaultShippingAddressId = React.useMemo(
    () => initialSelectedShippingAddressId ?? firstShippingAddressId,
    [initialSelectedShippingAddressId, firstShippingAddressId],
  );

  const defaultValues: Partial<CheckoutForm> = React.useMemo(
    () =>
      defaultFormValues ?? {
        additionalNotes: '',
        isTermsAndConditionsChecked: isSeller,
        poNumber: '',
        ...(isDefined(checkoutFields.shippingAddressId) && { shippingAddressId: defaultShippingAddressId }),
        ...defaultShippingDatesPreferences,
        ...defaultCancelDate,
        ...defaultPreferredShippingDate,
      },
    [
      checkoutFields.shippingAddressId,
      defaultCancelDate,
      defaultFormValues,
      defaultPreferredShippingDate,
      defaultShippingAddressId,
      defaultShippingDatesPreferences,
      isSeller,
    ],
  );

  const formMethods = useValidatedForm<CheckoutForm>({ defaultValues, validationSchema });

  const {
    setValue,
    getValues,
    trigger,
    formState: { isDirty },
  } = formMethods;

  React.useEffect(() => {
    if (isDirty) {
      trigger('shippingAddressId');
    }

    // account is undefined on initial render
    // eslint-disable-next-line no-autofix/@typescript-eslint/no-unnecessary-condition
  }, [isDirty, orderDetails.account?.shippingAddresses, trigger]);

  React.useEffect(() => {
    if (!isDefined(getValues('paymentMethod')) && isDefined(defaultPaymentMethod)) {
      setValue('paymentMethod', defaultPaymentMethod);
      dispatch(setActiveOrderPaymentMethod(defaultPaymentMethod));
    }
  }, [defaultPaymentMethod, dispatch, getValues, setValue]);

  return formMethods;
};
