import { Cms } from '@typings';
import cx from 'classnames';
import React from 'react';

import { useIsInViewMode } from '../../../utils/hooks';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { BlockGroup } from '../../cms/blockGroup';
import { HoverToolsContextProvider } from '../../cms/context/HoverToolsContext';

import styles from './section.module.scss';
import { SectionEmptyState } from './SectionEmptyState';

export interface Props {
  sectionData: Cms.Section;
  className?: string;
  shouldHideTools?: boolean;
}

export const createSection = (extensions?: Record<string, React.ComponentType<Props>>) =>
  React.memo((props: Props) => {
    const { sectionData, className } = props;
    const { visibleFor, id, groupsOrder } = sectionData;
    const { screen, isHidden } = visibleFor;

    const isEditorView = useIsInViewMode('editor');
    const shouldShowEmptyState = isEditorView && isEmpty(groupsOrder);

    const classNames = cx(
      {
        [styles.desktopHidden]: !screen.includes('desktop'),
        [styles.mobileHidden]: !screen.includes('mobile'),
        [styles.tabletHidden]: !screen.includes('tablet'),
        [styles.muted]: isHidden,
        [styles.withTools]: isDefined(extensions),
      },
      className,
    );

    return (
      <HoverToolsContextProvider>
        <div id={sectionData.id} className={cx(styles.section, classNames)}>
          {isDefined(extensions) &&
            Object.entries(extensions).map(([name, Extension]) => <Extension key={name} sectionData={sectionData} />)}
          {groupsOrder.map((key, index) => (
            <BlockGroup key={key} groupId={key} sectionId={id} groupIndex={index} />
          ))}
          {shouldShowEmptyState && <SectionEmptyState sectionId={sectionData.id} />}
        </div>
      </HoverToolsContextProvider>
    );
  });
