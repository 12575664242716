import React from 'react';

import { MATRIX_MOBILE_BREAKPOINT, MOBILE_PDM_BREAKPOINT } from '../../../../constants/breakpoints';
import { useScreenWidth } from '../../../../utils/hooks';
import { useIsLookbook } from '../../../../utils/hooks/useIsLookbook';

interface Context {
  hasMobileDetailsLayout: boolean;
  hasMobileMatrixLayout: boolean;
  isLookbook: boolean;
}

const initialContext: Context = {
  hasMobileDetailsLayout: false,
  hasMobileMatrixLayout: false,
  isLookbook: false,
};

export const ProductDetailsLayoutContext = React.createContext<Context>(initialContext);

export const ProductDetailsLayoutContextProvider = ({ children }: React.WithChildren) => {
  const isLookbook = useIsLookbook();
  const hasMobileMatrixLayout = useScreenWidth() <= MATRIX_MOBILE_BREAKPOINT;
  const hasMobileDetailsLayout = useScreenWidth() <= MOBILE_PDM_BREAKPOINT && !isLookbook;

  return (
    <ProductDetailsLayoutContext.Provider value={{ hasMobileDetailsLayout, hasMobileMatrixLayout, isLookbook }}>
      {children}
    </ProductDetailsLayoutContext.Provider>
  );
};
