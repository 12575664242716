import cx from 'classnames';
import { useSelector } from 'react-redux';

import universalStyles from '../../../../css/utilities/universal.module.scss';
import { getCompanyName, getIsLoggedIn } from '../../../ducks';
import { Header } from '../../header/Header';

import { LayoutContent } from './LayoutParts/LayoutContent';
import { LayoutImage } from './LayoutParts/LayoutImage';
import styles from './StartPageLayout.module.scss';

interface Props {
  title: string;
  text?: string;
}

export const StartPageLayout = ({ children, title, text }: React.WithChildren<Props>) => {
  const isUserLoggedIn = useSelector(getIsLoggedIn);
  const companyName = useSelector(getCompanyName);

  return (
    <div className={cx(styles.wrapper, { [styles.withHeader]: isUserLoggedIn })}>
      <main className={styles.panel}>
        {isUserLoggedIn ?
          <Header isSimple />
        : <h1 className={universalStyles.srOnly}>{companyName}</h1>}
        <div className={styles.mobilePanel}>
          <LayoutContent title={title} text={text}>
            {children}
          </LayoutContent>
        </div>
      </main>
      <LayoutImage className={styles.imageWrapper} />
    </div>
  );
};
