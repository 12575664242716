import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

import { getMissingCustomStyles } from '../../../ducks';
import { paths } from '../../../paths';
import { useCustomStyles } from '../../../utils/hooks/cms/useCustomStyles';
import { useValidatedForm } from '../../../utils/hooks/useValidatedForm';
import { FieldLabel, Form, FormButtons, FormFieldset, FormGrid } from '../../various/Form';
import { Button } from '../../various/NewButton';

import { CustomStyleSelectField } from './CustomStyleSelectField';
import styles from './MissingCustomStylesModal.module.scss';

interface Props {
  defaultValues: Record<string, Nullable<string>>;
  onCancel: () => void;
  onSubmit: (values: Record<string, string>) => void;
}

export const MissingCustomStylesForm = ({ defaultValues, onCancel, onSubmit }: Props) => {
  const { t } = useTranslation(['cms', 'common', 'validation']);
  const missingCustomStyles = useSelector(getMissingCustomStyles);
  const { missingStyles, textStyles, buttonStyles } = useCustomStyles();

  const missingStyleFields = React.useMemo(() => {
    return missingStyles.map(({ type, name }) => ({
      className: name,
      name: missingCustomStyles.find(missingStyle => missingStyle.className === name)?.name ?? name,
      type,
    }));
  }, [missingCustomStyles, missingStyles]);

  const validationSchema = React.useMemo(() => {
    return yup.object(
      Object.keys(defaultValues).reduce(
        (acc, current) => ({
          ...acc,
          [current]: yup.string().required(t('validation:text_style_hint')).nullable(),
        }),
        {},
      ),
    );
  }, [defaultValues, t]);

  const formMethods = useValidatedForm({ defaultValues, validationSchema });

  return (
    <Form formMethods={formMethods} onSubmit={onSubmit}>
      <FormFieldset>
        <div className={styles.row}>
          <FieldLabel label={t('cms:replace_with')} isRequired />
        </div>
        <FormGrid>
          {missingStyleFields.map(({ name, className, type }) => (
            <CustomStyleSelectField
              key={className}
              className={className}
              name={name}
              type={type}
              textStyles={textStyles}
              buttonStyles={buttonStyles}
            />
          ))}
        </FormGrid>
      </FormFieldset>
      <FormButtons showDivider>
        <Button as={Link} to={paths.PAGES_LIST} replace size="large" variant="ghost" color="dark" onClick={onCancel}>
          {t('cms:return_to_pages')}
        </Button>
        <Button type="submit" size="large">
          {t('common:replace')}
        </Button>
      </FormButtons>
    </Form>
  );
};
