import { matchPath, useLocation } from 'react-router-dom';

import { paths } from '../../paths';
import { isDefined } from '../../utils/is';

export const useHasSummariesBar = () => {
  const { pathname } = useLocation();
  const pagesWithBar = [paths.PAGES, paths.PRODUCTS_ORDER, paths.ORDER_DETAILS, paths.CHECKOUT_ORDER];

  return pagesWithBar.some(page => {
    const match = matchPath(page, pathname);

    return isDefined(match);
  });
};
