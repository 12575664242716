import { Buyer, BuyerDataChangePayloadDTO } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import { NAME_ALLOWED_CHARACTERS } from '../../constants/regExp';
import { getChangeBuyersData, resetEditBuyerData, updateBuyersDataRequest } from '../../ducks';
import { useValidatedForm } from '../../utils/hooks/useValidatedForm';
import { getPhoneSchema } from '../../utils/schemas';
import { Input } from '../various/Fields/Input';
import { FieldWrapper, Form, FormFieldset, FormGrid, InputField } from '../various/Form';
import { SectionFormHeading } from '../various/SectionHeading';

type FormData = BuyerDataChangePayloadDTO;

interface Props {
  buyer: Buyer | Empty.Object;
}

export const BuyerForm = ({ buyer }: Props) => {
  const { t } = useTranslation(['accounts', 'common', 'validation']);
  const dispatch = useDispatch();
  const {
    isLoading,
    data: { isChanged },
  } = useSelector(getChangeBuyersData);
  const [isEditing, setIsEditing] = React.useState(false);

  React.useEffect(() => {
    if (isChanged) {
      setIsEditing(false);
      dispatch(resetEditBuyerData());
    }
  }, [dispatch, isChanged]);

  const defaultValues: FormData = {
    firstName: buyer.firstName,
    lastName: buyer.lastName,
    mobileNumber: buyer.mobileNumber,
    phoneNumber: buyer.phoneNumber,
  };

  const validationSchema: yup.ObjectSchema<FormData> = yup.object({
    firstName: yup.string().required(t('validation:first_name_hint')).matches(NAME_ALLOWED_CHARACTERS, t('validation:valid_name_hint')),
    lastName: yup.string().required(t('validation:last_name_hint')).matches(NAME_ALLOWED_CHARACTERS, t('validation:valid_name_hint')),
    mobileNumber: getPhoneSchema(),
    phoneNumber: getPhoneSchema(),
  });

  const formMethods = useValidatedForm<FormData>({
    defaultValues,
    validationSchema,
  });

  const { register, reset } = formMethods;

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
    reset();
  };

  const handleSubmit = (values: FormData) => {
    dispatch(updateBuyersDataRequest(values));
  };

  return (
    <section>
      <Form formMethods={formMethods} onSubmit={handleSubmit}>
        <SectionFormHeading
          heading={t('common:about_me')}
          isEditing={isEditing}
          isLoading={isLoading}
          onCancel={handleCancel}
          onEdit={handleEdit}
        />
        <FormFieldset isDisabled={!isEditing || isLoading}>
          <FormGrid cols={2} colGap="xlarge">
            <InputField label={t('common:first_name')} isRequired {...register('firstName')} />
            <InputField label={t('common:last_name')} isRequired {...register('lastName')} />
            <FieldWrapper label={t('common:email')} helpMessage={t('accounts:email_disabled_tooltip')}>
              <Input size="large" value={buyer.email} isDisabled />
            </FieldWrapper>
            <InputField label={t('common:phone_number')} type="tel" {...register('phoneNumber')} />
            <InputField label={t('common:mobile_number')} type="tel" {...register('mobileNumber')} />
          </FormGrid>
        </FormFieldset>
      </Form>
    </section>
  );
};
