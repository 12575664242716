import { Cms } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchCmsProductsRequest,
  getCmsBlockById,
  getCmsSectionById,
  getCurrentBlockId,
  getCurrentSectionId,
  searchProductsRequest,
  updateBlockSettings,
} from '../../../ducks';
import { useModalVisibility } from '../../../utils/hooks';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { FormButtons } from '../../various/Form';
import { ModalSize } from '../../various/ModalElements';
import { ModalShell } from '../../various/ModalShell';
import { Button } from '../../various/NewButton';

import { AddedProductsSelectors } from './AddedProductsSelectors';
import styles from './AddProductsModal.module.scss';
import { AvailableProductsSelector } from './AvailableProductsSelector';

export const AddProductsModal = () => {
  const dispatch = useDispatch();
  const currentBlock = useSelector(getCurrentBlockId);

  const blockParameters = useSelector(getCmsBlockById(currentBlock)) as Cms.ProductBlock;
  const [searchAllPhrase, setSearchAllPhrase] = React.useState<string>('');
  const [searchAddedPhrase, setSearchAddedPhrase] = React.useState<string>('');
  const [selectedProducts, setSelectedProducts] = React.useState<string[]>([]);
  const [skipFirst, setSkipFirst] = React.useState<number>(0);
  const currentSectionId = useSelector(getCurrentSectionId);
  const currentSection = useSelector(getCmsSectionById(currentSectionId));

  const { isModalVisible, hideModal } = useModalVisibility('AddProductsModal');

  const { t } = useTranslation(['cms', 'common']);

  const marketIds = React.useMemo(() => {
    if (!isModalVisible || !isDefined(currentSection)) {
      return;
    }

    return currentSection.visibleFor.marketIds;
  }, [isModalVisible, currentSection]);

  React.useEffect(() => {
    if (!isModalVisible || !isDefined(marketIds) || isEmpty(marketIds)) {
      return;
    }

    dispatch(
      fetchCmsProductsRequest({
        marketIds,
        search: searchAllPhrase,
        skipFirst: 0,
      }),
    );
  }, [isModalVisible, marketIds]);

  React.useEffect(() => {
    if (!isDefined(marketIds) || isEmpty(marketIds)) {
      return;
    }

    dispatch(searchProductsRequest({ marketIds, search: searchAllPhrase }));
    setSkipFirst(0);
  }, [searchAllPhrase]);

  const handleSetProducts = React.useCallback(() => {
    if (!isDefined(currentBlock)) {
      return;
    }

    dispatch(
      updateBlockSettings({
        blockId: currentBlock,
        updatePath: ['products'],
        value: selectedProducts,
      }),
    );
    hideModal();
  }, [selectedProducts, currentBlock]);

  React.useEffect(() => {
    if (!isModalVisible) {
      return;
    }

    setSelectedProducts(isDefined(blockParameters) ? blockParameters.settings.products : []);
  }, [isModalVisible]);

  const onModalClosed = React.useCallback(() => {
    setSearchAddedPhrase('');
    setSearchAllPhrase('');
    setSkipFirst(0);
  }, []);

  return (
    <ModalShell
      isOpen={isModalVisible}
      onClose={hideModal}
      title={t('cms:choose_products')}
      size={ModalSize.XLarge}
      modalClosedCallback={onModalClosed}
    >
      <div className={styles.productSelectorsWrapper}>
        <AvailableProductsSelector
          selectedProducts={selectedProducts}
          onSelectedProducts={setSelectedProducts}
          onSearchAddedPhrase={setSearchAddedPhrase}
          onSearchAllPhrase={setSearchAllPhrase}
          searchAllPhrase={searchAllPhrase}
          skipFirst={skipFirst}
          onSkipFirst={setSkipFirst}
          marketIds={marketIds}
        />
        <AddedProductsSelectors
          selectedProducts={selectedProducts}
          onSelectedProducts={setSelectedProducts}
          onSearchAddedPhrase={setSearchAddedPhrase}
          searchAddedPhrase={searchAddedPhrase}
        />
      </div>
      <FormButtons showDivider>
        <Button size="large" variant="ghost" color="dark" data-testid="cancelAddProducts" onClick={hideModal}>
          {t('common:cancel')}
        </Button>
        <Button type="submit" size="large" data-testid="saveAddProducts" onClick={handleSetProducts}>
          {t('common:save')}
        </Button>
      </FormButtons>
    </ModalShell>
  );
};
