/* eslint-disable max-len */

import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 21;
const ORIGINAL_HEIGHT = 20;
const ORIGINAL_COLOR = '#848484';
const PROPORTION = ORIGINAL_HEIGHT / ORIGINAL_WIDTH;

export const Add = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => (
  <svg width={width} height={width * PROPORTION} viewBox="0 0 21 20" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.81776 3H15.7067C16.5622 3 17.2622 3.7 17.2622 4.55556V15.4444C17.2622 16.3 16.5622 17 15.7067 17H4.81776C3.95443 17 3.26221 16.3 3.26221 15.4444V4.55556C3.26221 3.7 3.95443 3 4.81776 3ZM11.04 10.7777H13.3734C13.8011 10.7777 14.1511 10.4277 14.1511 9.99992C14.1511 9.57215 13.8011 9.22215 13.3734 9.22215H11.04V6.88881C11.04 6.46104 10.69 6.11104 10.2622 6.11104C9.83447 6.11104 9.48447 6.46104 9.48447 6.88881V9.22215H7.15113C6.72336 9.22215 6.37336 9.57215 6.37336 9.99992C6.37336 10.4277 6.72336 10.7777 7.15113 10.7777H9.48447V13.111C9.48447 13.5388 9.83447 13.8888 10.2622 13.8888C10.69 13.8888 11.04 13.5388 11.04 13.111V10.7777Z"
      fill={color}
    />
  </svg>
);
