import { Option, Select } from '../../../various/Fields/Select';

import styles from './FontWeightSelect.module.scss';

interface Props {
  value: number;
  onChange: (value: number) => void;
  disabled?: boolean;
  fontWeights: { title: string; value: number }[];
}

export const FontWeightSelect = ({ value, onChange, disabled, fontWeights }: Props) => {
  return (
    <div className={styles.fontWeightSelect}>
      <Select size="small" value={value} dataTestId="textStyleEditorFontWeight" isDisabled={disabled} onChange={onChange}>
        {fontWeights.map(fontWeight => (
          <Option key={fontWeight.value} value={fontWeight.value}>
            {fontWeight.title}
          </Option>
        ))}
      </Select>
    </div>
  );
};
