import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getCompanyName, getResetPassword, resetPasswordDefault } from '../../../ducks';
import { paths } from '../../../paths';
import { StartPageLayout } from '../../layout/StartPageLayout';
import { FormButtons } from '../../various/Form';
import { Button } from '../../various/NewButton';

import { ForgotPasswordForm } from './ForgotPasswordForm';

export const ForgotPasswordPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'genericErrors', 'passwordManagement', 'login']);
  const companyName = useSelector(getCompanyName);
  const {
    data: { isReset },
  } = useSelector(getResetPassword);

  React.useEffect(() => {
    return () => {
      dispatch(resetPasswordDefault());
    };
  }, [dispatch]);

  const title = isReset ? t('common:reset_password_email_sent') : t('passwordManagement:reset_password_for_showroom', { companyName });

  const text = isReset ? t('genericErrors:no_email_hint') : t('passwordManagement:reset_password_description');

  return (
    <StartPageLayout title={title} text={text}>
      {isReset ?
        <FormButtons direction="column">
          <Button as={Link} to={paths.LOGIN} size="large" color="dark" isFluid>
            {t('login:sign_in')}
          </Button>
        </FormButtons>
      : <ForgotPasswordForm />}
    </StartPageLayout>
  );
};
