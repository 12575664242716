import { DatePicker, Input as InputType } from '@typings';
import React from 'react';

import { DATE_STRING_FORMAT } from '../../../../constants/dates';
import { handleSpaceAndEnter } from '../../../../utils/handleEnterKey';
import { isDefined, isNull } from '../../../../utils/is';
import { Input } from '../../../various/Fields/Input';
import { DatePickerSelectionContext } from '../context';

import styles from './DatePickerTrigger.module.scss';

interface Props {
  dataTestId?: string;
  disabled: boolean;
  invalid: boolean;
  placeholder: string;
  formatter?: (value: DatePicker.Value) => string;
  onClick: () => void;
  size?: InputType.Size;
}

export const DatePickerTrigger = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { dataTestId, disabled, invalid, placeholder, formatter, onClick, size } = props;
  const { startDate, endDate, isRangeSelection } = React.useContext(DatePickerSelectionContext);

  const selectionLabel = React.useMemo(() => {
    if (isDefined(formatter)) {
      return formatter(isRangeSelection ? [startDate, endDate] : startDate);
    }

    if (!isRangeSelection) {
      return startDate?.format(DATE_STRING_FORMAT) ?? '';
    }

    if (isNull(startDate) && isNull(endDate)) {
      return '';
    }

    return `${startDate?.format(DATE_STRING_FORMAT)} - ${endDate?.format(DATE_STRING_FORMAT) ?? ''}`;
  }, [endDate, formatter, isRangeSelection, startDate]);

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className={styles.trigger}>
      <Input
        ref={ref}
        data-testid={dataTestId}
        value={selectionLabel}
        className={styles.triggerInput}
        placeholder={placeholder}
        isDisabled={disabled}
        isInvalid={invalid}
        isReadonly
        onClick={onClick}
        onKeyDown={handleSpaceAndEnter(onClick)}
        size={size}
      />
    </label>
  );
});
