import { Id, Navigation } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getHomepageSlug } from '../../../../../ducks';
import { getIsPageAvailable, getPublishedPageData } from '../../../../../logic/pages';
import { isDefined } from '../../../../../utils/is';
import { isEmpty } from '../../../../../utils/isEmpty';
import { ExpandableElement } from '../../../../various/ExpandableContainer/ExpandableElement';
import { Input } from '../../../../various/Fields/Input';
import { Switch } from '../../../../various/Fields/Switch';
import { FieldLabel } from '../../../../various/Form/FormField';
import Icon, { IconColor, IconType } from '../../../../various/Icon';
import { ListItem } from '../../../ListItem';
import { ListItemHead } from '../../../ListItem/ListItemHead';
import { ListItemTitle } from '../../../ListItem/ListItemTitle';
import { MenuItemMarkets } from '../../../MenuItemMarkets';
import { useMenuSectionContext } from '../../context/MenuSectionContext';

import styles from './AddedMenuItem.module.scss';
import { AddedMenuItemActions } from './AddedMenuItemActions';

interface Props {
  onRemoveMenuItem: (id: Id) => void;
  onLabelChange: (value: string, id: Id) => void;
  onToggleNewTab: (value: boolean, id: Id) => void;
  onToggleOpen: (id: Id) => void;
  isOpen: boolean;
  item: Navigation.PageMenuItem;
}

export const PageMenuItem = ({ onRemoveMenuItem, onLabelChange, onToggleNewTab, isOpen, onToggleOpen, item }: Props) => {
  const { t } = useTranslation(['menu', 'common', 'settings']);

  const handleLabelChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onLabelChange(event.target.value, item.id);
    },
    [onLabelChange, item.id],
  );

  const handleToggleNewTab = React.useCallback(
    (isChecked: boolean) => {
      if (isChecked === item.openNewTab) {
        return;
      }

      onToggleNewTab(isChecked, item.id);
    },
    [onToggleNewTab, item],
  );

  const { areInputsDisabled, childIdsWithMarketsMismatch, pagesRecords } = useMenuSectionContext();
  const homepageSlug = useSelector(getHomepageSlug);

  const handleRemoveMenuItem = React.useCallback(() => {
    onRemoveMenuItem(item.id);
  }, [onRemoveMenuItem, item.id]);

  const handleToggleOpen = React.useCallback(() => {
    onToggleOpen(item.id);
  }, [item.id]);

  if (!isDefined(item)) {
    return null;
  }

  const page = getPublishedPageData(pagesRecords[item.pageId]);

  if (!getIsPageAvailable(page)) {
    const name = (
      <>
        {t('menu:missing_page_with_id', { pageId: item.pageId })}
        <Icon type={IconType.Warning} className={styles.noAvailableIcon} />
      </>
    );

    return (
      <ListItem isOpen={isOpen}>
        <ListItemHead
          action={
            !areInputsDisabled && (
              <button
                type="button"
                className={cx(styles.actionButton)}
                aria-label={t('common:remove_item', { item: item.label })}
                onClick={handleRemoveMenuItem}
              >
                <Icon type={IconType.TrashLinear} color={IconColor.Gray} />
              </button>
            )
          }
        >
          <ListItemTitle name={name} />
        </ListItemHead>
        <div className={styles.noAvailablePageMessage}>{t('menu:no_available_page_message')}</div>
      </ListItem>
    );
  }

  const { slug, markets } = page;
  const marketNames = markets.map(market => market.name);
  const hasNoMarkets = isEmpty(marketNames);

  return (
    <ListItem isOpen={isOpen}>
      <ListItemHead
        action={
          <AddedMenuItemActions
            label={item.label}
            id={item.id}
            handleRemoveMenuItem={handleRemoveMenuItem}
            handleToggleOpen={handleToggleOpen}
            isOpen={isOpen}
          />
        }
      >
        <ListItemTitle pageId={item.pageId} slug={slug} name={item.label} isHomepage={slug === homepageSlug}></ListItemTitle>
        {isDefined(marketNames) && (
          <MenuItemMarkets
            markets={marketNames}
            isMissingMarket={childIdsWithMarketsMismatch.includes(item.pageId) || hasNoMarkets}
            emptyMarketsInfo={hasNoMarkets ? t('menu:no_markets_assigned') : t('menu:by_nested_items')}
          />
        )}
      </ListItemHead>
      <ExpandableElement id={`menuItemOptions-${item.id}`} isOpen={isOpen}>
        <div className={styles.nameInput}>
          <FieldLabel size="regular" label={t('settings:navigation_label')} isRequired>
            <Input
              value={item.label}
              size="regular"
              isInvalid={isEmpty(item.label)}
              isDisabled={areInputsDisabled}
              onChange={handleLabelChange}
            />
          </FieldLabel>
        </div>
        <div className={styles.expandColumns}>
          <span className={styles.navLabel}>{t('settings:original_page_title')}</span>
          <span className={styles.originalTitle}>{page.name}</span>
          <label htmlFor={`newTab-${item.id}`} className={styles.navLabel}>
            {t('common:open_in_new_tab')}
          </label>
          <Switch
            size="small"
            id={`newTab-${item.id}`}
            onValueChange={handleToggleNewTab}
            checked={item.openNewTab}
            disabled={areInputsDisabled}
          />
        </div>
      </ExpandableElement>
    </ListItem>
  );
};
