import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Checkboxes, DeliveryWindow } from '../../../../../../../typings';
import { getStockTypeFilter, pushEvent } from '../../../../../ducks';
import { getDeliveryWindowsCheckboxOptions, isDeliveryWindowFilterGroup } from '../../../../../logic/deliveryWindows';
import { DelwinFilterTrackingEvent, getGroupsRenderedEvent } from '../../../../../utils/analytics/events';
import { useDeliveryWindowFilterItems } from '../../../../../utils/hooks/filtersBar';
import { isDefined } from '../../../../../utils/is';

const TOGGLE_GROUP_TIMEOUT = 500;

interface Props {
  children: React.ReactNode;
}

type OpenedPopupId = Nullable<string>;

interface Context {
  openedPopupId: OpenedPopupId;
  setOpenedPopupId: React.Dispatch<React.SetStateAction<Nullable<OpenedPopupId>>>;
  items: DeliveryWindow.DeliveryWindowFilterItem[];
  isTopLevelOptionsOpened: boolean;
  options: Checkboxes.Option[];
  activeGroup: Nullable<DeliveryWindow.DeliveryWindowFilterGroup>;
  openGroup: (group: DeliveryWindow.DeliveryWindowFilterGroup) => void;
  closeGroup: VoidFunction;
  activeGroupClosing: Nullable<DeliveryWindow.DeliveryWindowFilterGroup>;
}

const DeliveryWindowFilterContext = React.createContext<Context | null>(null);

export const useDeliveryWindowFilterContext = () => {
  const context = React.useContext(DeliveryWindowFilterContext);

  if (!isDefined(context)) {
    throw new Error('DeliveryWindowFilterContext can not be used outside the scope of DeliveryWindowFilterContextProvider');
  }

  return context;
};

export const DeliveryWindowFilterContextProvider = ({ children }: Props) => {
  const dispatch = useDispatch();
  const stockTypeFilter = useSelector(getStockTypeFilter);

  const [openedPopupId, setOpenedPopupId] = React.useState<Nullable<OpenedPopupId>>(null);
  const [activeGroup, setActiveGroup] = React.useState<Nullable<DeliveryWindow.DeliveryWindowFilterGroup>>(null);
  const [activeGroupClosing, setActiveGroupClosing] = React.useState<Nullable<DeliveryWindow.DeliveryWindowFilterGroup>>(null);
  const [isTopLevelOptionsOpened, setIsTopLevelOptionsOpened] = React.useState(true);

  const items = useDeliveryWindowFilterItems();
  const options = getDeliveryWindowsCheckboxOptions(items);

  React.useEffect(() => {
    const groupCount = items.filter(isDeliveryWindowFilterGroup).length;
    const hasGroups = groupCount > 0;
    const deliveryWindowCount = options.length - groupCount;
    const hasMixedOptions = hasGroups && items.length !== groupCount;

    if (hasGroups) {
      dispatch(
        pushEvent(
          getGroupsRenderedEvent({
            deliveryWindowCount,
            groupCount,
            optionNames: items.map(({ name }) => name).join(', '),
            stockType: stockTypeFilter,
          }),
        ),
      );
    }

    if (hasMixedOptions) {
      dispatch(
        pushEvent({
          event: DelwinFilterTrackingEvent.MIXED_OPTIONS_RENDERED,
        }),
      );
    }
  }, [items]);

  const openGroup = React.useCallback((group: DeliveryWindow.DeliveryWindowFilterGroup) => {
    setActiveGroup(group);
    setTimeout(() => setIsTopLevelOptionsOpened(false), TOGGLE_GROUP_TIMEOUT);
  }, []);

  const closeGroup = React.useCallback(() => {
    setIsTopLevelOptionsOpened(true);
    setActiveGroupClosing(activeGroup);
    setActiveGroup(null);
    setTimeout(() => setActiveGroupClosing(null), TOGGLE_GROUP_TIMEOUT);
  }, [activeGroup]);

  return (
    <DeliveryWindowFilterContext.Provider
      value={{
        activeGroup,
        activeGroupClosing,
        closeGroup,
        isTopLevelOptionsOpened,
        items,
        openGroup,
        openedPopupId,
        options,
        setOpenedPopupId,
      }}
    >
      {children}
    </DeliveryWindowFilterContext.Provider>
  );
};
