import { Linesheet } from '@typings';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getIsSeller } from '../../../ducks';
import { useLinesheetTemplates } from '../../../services/hooks/linesheet/useLinesheetTemplates';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { Option } from '../../various/Fields/Select';
import { FieldWrapper, Form, FormButtons, FormFieldset, FormGrid, SelectField } from '../../various/Form';
import { Button } from '../../various/NewButton';

import styles from './DownloadLinesheet.module.scss';
import { DownloadLinesheetOptions } from './DownloadLinesheetOptions';
import { DownloadLinesheetTemplates } from './DownloadLinesheetTemplates';
import { NoLinesheetsMessage } from './NoLinesheetsMessage';

interface Props {
  onCancel: () => void;
  onSubmit: (values: Linesheet.OrderFormData) => void;
  downloadOnlySelected?: boolean;
  isPending: boolean;
}

const DEFAULT_TEMPLATE_ID = '1';

export const DownloadLinesheetForm = ({ onCancel, onSubmit, downloadOnlySelected, isPending }: Props) => {
  const { t } = useTranslation(['linesheets', 'common']);
  const isSeller = useSelector(getIsSeller);

  const { data, isLoading: isLoadingTemplates } = useLinesheetTemplates();

  const templates = React.useMemo(() => data?.templates ?? {}, [data]);
  const defaultTemplate = templates[DEFAULT_TEMPLATE_ID] as Maybe<Linesheet.TemplateData>;

  const defaultValues: Linesheet.OrderFormData = {
    attributes: defaultTemplate?.attributes ?? [],
    groupBy: defaultTemplate?.groupBy ?? null,
    linesheetProducts: downloadOnlySelected ? 'selected' : 'all',
    linesheetTemplate: DEFAULT_TEMPLATE_ID,
    showCustomerPrice: true,
    sortBy: defaultTemplate?.sortBy ?? null,
    thenSortBy: defaultTemplate?.thenSortBy ?? 'none',
  };

  const formMethods = useForm({ defaultValues, mode: 'onTouched' });
  const selectedTemplateId = formMethods.watch('linesheetTemplate');
  const selectedTemplate = templates[selectedTemplateId];

  const templateAttributes = React.useMemo(() => {
    if (!isDefined(selectedTemplate)) {
      return '';
    }

    const { attributes } = templates[selectedTemplateId] ?? {};
    const attributesList = attributes?.map(key => data?.dictionary.attributes.find(attr => attr.key === key)?.value).join(', ');

    return !isDefined(attributesList) || isEmpty(attributesList) ? t('common:none') : attributesList;
  }, [selectedTemplate, templates, selectedTemplateId, t, data]);

  if (isEmpty(templates) && !isLoadingTemplates) {
    return <NoLinesheetsMessage onOk={onCancel} />;
  }

  return (
    <Form formMethods={formMethods} onSubmit={onSubmit}>
      <FormFieldset isDisabled={isPending}>
        <FormGrid>
          {!downloadOnlySelected && (
            <SelectField name="linesheetProducts" label={t('linesheets:products_to_include.label')}>
              <Option value="all">{t('linesheets:products_to_include.options.all')}</Option>
              <Option value="selected">{t('linesheets:products_to_include.options.selected')}</Option>
            </SelectField>
          )}
          <DownloadLinesheetTemplates templates={templates} isLoading={isLoadingTemplates} />
          {!isSeller && (
            <FieldWrapper as="span" label={t('linesheets:product_attributes')}>
              <div className={styles.templateAttributes}>{templateAttributes}</div>
            </FieldWrapper>
          )}
          <DownloadLinesheetOptions selectedTemplate={selectedTemplate} />
        </FormGrid>
      </FormFieldset>
      <FormButtons showDivider>
        <Button size="large" variant="ghost" color="dark" onClick={onCancel} disabled={isPending}>
          {t('common:cancel')}
        </Button>
        <Button type="submit" size="large" isLoading={isPending} disabled={isLoadingTemplates}>
          {t('linesheets:generate')}
        </Button>
      </FormButtons>
    </Form>
  );
};
