import { Order } from '@typings';
import { createAction } from 'typesafe-actions';

export const fetchOrdersRequest = createAction('selections/ORDERS_FETCH_REQUEST')();

export const fetchOrdersSuccess = createAction('selections/ORDERS_FETCH_SUCCESS')<Responses.Orders>();
export const fetchOrdersFailure = createAction('selections/ORDERS_FETCH_FAILURE')();

export const fetchMoreOrdersRequest = createAction('selections/MORE_ORDERS_FETCH_REQUEST)')();

export const fetchMoreOrdersSuccess = createAction('selections/MORE_ORDERS_FETCH_SUCCESS')<Responses.Orders>();
export const fetchMoreOrdersFailure = createAction('selections/MORE_ORDERS_FETCH_FAILURE')();

export const redirectedUserToLatestSelection = createAction('selection/REDIRECTED_USER_TO_LATEST_SELECTION')();

export const sharedSelectionRequest = createAction('selection/SHARED_SELECTION_REQUEST')<{ orderId: string; sharedToken: string }>();
export const sharedSelectionSuccess = createAction('selections/RESET_PAGE_ORDERS')<Order.Single & { token: string }>();
export const sharedSelectionFailure = createAction('selections/SHARED_SELECTION_FAILURE')();
