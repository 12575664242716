import { Checkout, FailedProducts, Product } from '@typings';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Option } from 'space-lift';

import { getFailedOrderDetails, getOrderId } from '../../../ducks';
import { compiledPaths, paths } from '../../../paths';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { Alert } from '../../various/Alert';
import { FailedProductsList } from '../../various/FailedProductsList';
import {
  useCancelledItemsModel,
  useMinimumQuantityModel,
  useMissingProductsModel,
  useMissingProductsWithoutDataModel,
  useMultiplicityModel,
} from '../../various/FailedProductsRowModels';
import { PageTitle } from '../../various/PageTitle';

import styles from './CheckoutFailure.module.scss';
import { CreditLimitExceededError } from './CreditLimitExceededError';
import { ExpiredDelwinError } from './ExpiredDelwinError';
import { FailedCheckoutContent } from './FailedCheckoutContent';
import { Info } from './Info';
import { UnpaidInvoicesError } from './UnpaidInvoicesError';

type Error = Omit<React.ComponentProps<typeof FailedProductsList>, 'products'> & React.ComponentProps<typeof Info>;

export const CheckoutFailure = () => {
  const { t } = useTranslation(['checkoutMessages', 'checkout']);
  const orderId = useSelector(getOrderId);
  const failedOrder = Option(useSelector(getFailedOrderDetails));
  const missingProductsModel = useMissingProductsModel();
  const missingProductsWithoutDataModel = useMissingProductsWithoutDataModel();
  const cancelledItemsModel = useCancelledItemsModel();
  const minimumQuantityModel = useMinimumQuantityModel();
  const multiplicityModel = useMultiplicityModel();
  const navigate = useNavigate();

  const products = React.useMemo(() => {
    return failedOrder.map(failed => failed.products).getOrElse([]) as Record<string, Product.Full>;
  }, [failedOrder]);

  const getHasVariantData = React.useCallback(
    ({ variantId }: FailedProducts.Any) => {
      return Object.values(products).some(({ centraVariant }) => centraVariant === variantId.toString());
    },
    [products],
  );

  const wrongMultipleVariants = React.useMemo(() => {
    return failedOrder.map(failed => failed.itemQuantityWrongMultiple).getOrElse([]);
  }, [failedOrder]);

  const allNotAvailableVariants = failedOrder.map(failed => failed.unavailable).getOrElse([]);

  const notAvailableVariants = React.useMemo(() => {
    return allNotAvailableVariants.filter(getHasVariantData);
  }, [allNotAvailableVariants, getHasVariantData]);

  const notAvailableVariantsWithoutData = React.useMemo(() => {
    return allNotAvailableVariants.filter(variant => !getHasVariantData(variant));
  }, [allNotAvailableVariants, getHasVariantData]);

  const minimumQuantityVariants = React.useMemo(
    () => failedOrder.map(failed => failed.itemQuantityLessThanMinimum).getOrElse([]),
    [failedOrder],
  );

  const cancelledVariants = React.useMemo(() => failedOrder.map(failed => failed.cancelled).getOrElse([]), [failedOrder]);

  const getDelwinFailure = (failed: Checkout.Failure) =>
    isDefined(failed.errors) && !Array.isArray(failed.errors) ? failed.errors.order : undefined;

  const expiredDeliveryWindows = failedOrder.map(failed => getDelwinFailure(failed)?.expiredDeliveryWindowsIds).get();

  const hasUnpaidInvoices = failedOrder.map(failed => failed.hasUnpaidInvoices).get();
  const creditLimitExceeded = failedOrder.map(failed => failed.creditLimitExceeded).get();

  const messages = React.useMemo(() => failedOrder.map(failed => failed.messages).get(), [failedOrder]);

  const errors: Record<string, Error> = {
    cancelled: {
      details: t('checkoutMessages:cancelled_error.details'),
      failedProducts: cancelledVariants,
      header: (
        <Trans t={t} i18nKey="checkoutMessages:cancelled_error.header">
          Following products have <b>been cancelled</b>.
        </Trans>
      ),
      isCancelled: true,
      model: cancelledItemsModel,
    },
    minimumQuantity: {
      details: t('checkoutMessages:min_quantity_error.details'),
      failedProducts: minimumQuantityVariants,
      header: (
        <Trans t={t} i18nKey="checkoutMessages:min_quantity_error.header">
          Following products have <b>minimum quantity restriction</b>.
        </Trans>
      ),
      model: minimumQuantityModel,
    },
    notAvailable: {
      details: t('checkoutMessages:not_available_error.details'),
      failedProducts: notAvailableVariants,
      header: (
        <Trans t={t} i18nKey="checkoutMessages:not_available_error.header">
          Following products were <b>not available</b>.
        </Trans>
      ),
      model: missingProductsModel,
    },
    notAvailableWithoutData: {
      details: t('checkoutMessages:missing_products_data_error.details'),
      failedProducts: notAvailableVariantsWithoutData,
      header: (
        <Trans t={t} i18nKey="checkoutMessages:missing_products_data_error.header">
          Following products were <b>not available</b>.
        </Trans>
      ),
      model: missingProductsWithoutDataModel,
    },
    wrongMultiple: {
      details: t('checkoutMessages:wrong_multiple_error.details'),
      failedProducts: wrongMultipleVariants,
      header: (
        <Trans t={t} i18nKey="checkoutMessages:wrong_multiple_error.header">
          Following products have <b>multiplicity restriction</b>.
        </Trans>
      ),
      model: multiplicityModel,
      tableStyles: styles.multiplicityErrorTable,
    },
  };

  const checkoutPath = isDefined(orderId) ? compiledPaths.CHECKOUT_ORDER({ id: orderId }) : undefined;

  React.useLayoutEffect(() => {
    if (!isDefined(orderId)) {
      navigate(paths.ROOT, { replace: true });
    }
  }, [history, orderId]);

  return (
    <PageTitle title={t('checkout:checkout_failed')}>
      <FailedCheckoutContent title={t('checkout:checkout_failed')} checkoutUrl={checkoutPath}>
        {Object.entries(errors).map(
          ([key, { header, details, ...listProps }]) =>
            !isEmpty(listProps.failedProducts) && (
              <React.Fragment key={key}>
                <Info header={header} details={details} />
                <FailedProductsList productsContainerClassname={styles.productsContainer} products={products} {...listProps} />
              </React.Fragment>
            ),
        )}
        {hasUnpaidInvoices && <UnpaidInvoicesError />}
        {creditLimitExceeded && <CreditLimitExceededError />}
        {isDefined(expiredDeliveryWindows) && <ExpiredDelwinError deliveryWindows={expiredDeliveryWindows} />}
        {isDefined(messages) && (
          <div>
            {messages.map(item => (
              <Alert key={item} type="error" message={item} />
            ))}
          </div>
        )}
      </FailedCheckoutContent>
    </PageTitle>
  );
};
