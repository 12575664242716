import { Position } from '@typings';
import React from 'react';

import { useMatrixNavigation } from '../../../../utils/hooks/matrix/useMatrixNavigation';

import { PinnableSummaryCell } from './PinnableSummaryCell';
import { RegularSummaryCell } from './RegularSummaryCell';

interface Props {
  position: Position;
}

export const RowSummaryCell = ({ children, position }: React.WithChildren<Props>) => {
  const { isFocused, isSelected, setFocusableElement, isFocusTarget } = useMatrixNavigation(position);

  return (
    <PinnableSummaryCell>
      <RegularSummaryCell
        dataTestId={`matrixRowTotals-${position.y}`}
        isFocusTarget={isFocusTarget}
        isSelected={isFocused || isSelected}
        ref={setFocusableElement}
      >
        {children}
      </RegularSummaryCell>
    </PinnableSummaryCell>
  );
};
