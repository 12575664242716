import { ActionHandler, Country } from '@typings';
import { createReducer } from 'typesafe-actions';

import { update } from '../../utils/update';

import { fetchCountriesSuccess } from './actions';

export type CountriesReducer = Record<string, Country>;

const initialState: CountriesReducer = {};

const handleFetchCountriesSuccess: ActionHandler<CountriesReducer, typeof fetchCountriesSuccess> = (state, action) => {
  const { countries } = action.payload;

  return update(state, countries);
};

export default createReducer<CountriesReducer, AppAction>(initialState).handleAction(fetchCountriesSuccess, handleFetchCountriesSuccess);
