import { API, Order } from '@typings';
import { omit } from 'ramda';

import { checkoutOrderSplitSuccess, checkoutOrderSuccess, fetchOrderSuccess } from '../../ducks';
import { getIsOrderSplit } from '../../logic/Orders';

export const handlePaymentResultSuccess = (response: API.SuccessResponse<Order.Single | Order.Split>, orderId: Order.Id) => {
  const order = response.data;

  const action =
    getIsOrderSplit(order) ?
      checkoutOrderSplitSuccess({
        currency: order.currency,
        orders: order.orders,
        originalOrderId: orderId,
      })
    : checkoutOrderSuccess(
        omit(['order'], {
          ...order,
          orders: [order.order] as Monuple<Order.Closed>,
          status: order.order.status,
        }),
      );

  return [fetchOrderSuccess(response.data as Order.Single), action]; // should be removed after fixing order response types
};
