import cx from 'classnames';
import React from 'react';

import { isDefined } from '../../../utils/is';
import { Key } from '../../../utils/keys';

import styles from './Toolbar.module.scss';

interface Props {
  tools: JSX.Element[];
  title: string;
  isToolbarVisible?: boolean;
  roundedBottomCorners?: boolean;
  style?: React.CSSProperties;
  closeToolbar?: () => void;
  ref?: React.MutableRefObject<HTMLDivElement | null>;
}

export const ToolbarBase = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { tools, title, closeToolbar, style, isToolbarVisible = true, roundedBottomCorners = false } = props;
  const onClose = React.useCallback(
    (event: React.KeyboardEvent) => {
      if (event.code === Key.ESCAPE && isDefined(closeToolbar)) {
        closeToolbar();
      }
    },
    [closeToolbar],
  );

  return (
    <div
      aria-label={title}
      role="toolbar"
      className={cx(styles.toolbarHolder, {
        [styles.visible]: isToolbarVisible,
        [styles.roundedBottomCorners]: roundedBottomCorners,
      })}
      onKeyDown={onClose}
      tabIndex={0}
      ref={ref}
      style={style}
    >
      <div className={styles.toolbarEditor}>
        <span className={styles.title}>{title}</span>
        {tools.map((tool, index) => (
          <div key={index} className={styles.tool}>
            {tool}
          </div>
        ))}
      </div>
    </div>
  );
});
