import React from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { App } from '../components/App';
import DefaultLoader from '../components/various/loaders/DefaultLoader';
import { paths } from '../paths';
import { useLookbookLogin } from '../services/hooks/lookbook/useLookbookLogin';
import { isDefined } from '../utils/is';

interface Props {
  children: React.ReactNode;
}

const AuthenticatedLookbookPathComponent = ({ children }: Props) => {
  const { lookbookId } = useParams<{ lookbookId: string }>();
  const { data: lookbookToken, isError } = useLookbookLogin(lookbookId);

  if (isError) {
    return <Navigate replace to={paths.PAGE_NOT_FOUND} />;
  }

  if (!isDefined(lookbookToken)) {
    return <DefaultLoader overlay />;
  }

  return <App>{children}</App>;
};

export const AuthenticatedLookbookPath = React.memo(AuthenticatedLookbookPathComponent);
