import { Product } from '@typings';

import { isDefined } from '../utils/is';

export const isVariantRelation = (variant: Product.Standard): boolean => {
  return !isDefined(variant.relation) || variant.relation === 'variant';
};

export const isMultivariantRelation = (variant: Product.Standard): boolean => {
  return isDefined(variant.relation) && variant.relation === 'Multivariant';
};

export const isVariantOrMultivariantRelation = (variant: Product.Standard): boolean => {
  return !isDefined(variant.relation) || variant.relation === 'variant' || variant.relation === 'Multivariant';
};

export const isDistinctVariantOrMultivariantRelation = (variant: Product.Standard): boolean => {
  return isDefined(variant.relation) && (variant.relation === 'Distinct variant' || variant.relation === 'Multivariant');
};

export const getIsStandardRelation = (variant: Product.Standard): boolean => {
  return isDefined(variant.relation) && variant.relation === 'standard';
};
