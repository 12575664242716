import { Cms } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { fetchMarketsRequest } from '../../ducks/markets';
import { latestPageStructureVersion } from '../../logic/cms/convertPageToLatestVersion';
import { pageFilter } from '../../logic/cms/pagesList';
import { useCreatePage } from '../../services/hooks/pages/useCreatePage';
import { usePages } from '../../services/hooks/pages/usePages';
import { useDebouncedCallback, useModalVisibility, usePageSettings, useSearchInput, useSharePage, useUrlState } from '../../utils/hooks';
import { isDefined } from '../../utils/is';
import { isEmpty } from '../../utils/isEmpty';
import { PageFormModal } from '../cms/PageFormModal';
import { Input } from '../various/Fields/Input';
import { FloatingButton } from '../various/FloatingButton';
import { IconType } from '../various/Icon';
import { PageTitle } from '../various/PageTitle';
import { TopBar } from '../various/TopBar';

import { PagesListFallback } from './PagesListFallback';
import { PagesTable } from './PagesTable';
import { SharePageModal } from './SharePageModal';

export const PagesList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['confirmationConfig', 'common', 'cms']);

  const [searchQuery, setSearchQuery] = useUrlState('search');
  const [statusFilter, setStatusFilter] = useUrlState<Cms.PageStatus>('filter');

  const { data: pages = [], isError, isLoading } = usePages({ search: searchQuery });
  const { mutate: createPage, isPending: isCreatingPage } = useCreatePage();

  const hasPages = !isEmpty(pages);

  const { value, onChange } = useSearchInput({
    initialValue: searchQuery,
    onChangeValue: useDebouncedCallback(setSearchQuery),
  });

  const dataSource = React.useMemo(() => {
    const filteredPages = pages.filter(pageFilter(statusFilter));

    return [...filteredPages].sort((a, b) => (b.lastModifiedDate > a.lastModifiedDate ? 1 : -1));
  }, [pages, statusFilter]);

  React.useEffect(() => {
    dispatch(fetchMarketsRequest());
  }, [dispatch, searchQuery]);

  const { isSavingPage, setEditingPage, onEdit, selectedPageLanguages, selectedPageSettings } = usePageSettings(pages);

  const {
    isModalVisible: isCreateModalVisible,
    hideModal: hideCreateModal,
    showModal: showCreateModal,
  } = useModalVisibility('CreatePageFormModal');

  const { isModalVisible: isSettingsModalVisible, hideModal: hideSettingsModal } = useModalVisibility('EditPageFormModal');

  const { isSharePageOpen, pageToShare, openSharePage, closeSharePage } = useSharePage(pages);

  const onCreatePage = React.useCallback(
    (data: Cms.CreatePageDTO) => {
      createPage(
        { ...data, version: latestPageStructureVersion },
        {
          onSuccess: hideCreateModal,
        },
      );
    },
    [createPage, hideCreateModal],
  );

  const handlePageSettingsClosed = () => {
    setEditingPage(undefined);
  };

  const numberOfPages = dataSource.length;

  const hasNoPages = isEmpty(searchQuery) && isEmpty(pages);
  const shouldShowFallback = !isLoading && (isError || hasNoPages);

  return (
    <PageTitle title={t('common:page_other')}>
      <TopBar title={t('common:page_other')} details={t('common:page_count', { count: numberOfPages })}>
        <label aria-label={t('common:search_placeholder')}>
          <Input placeholder={t('common:search_placeholder')} icon={IconType.Search} value={value} isClearable onChange={onChange} />
        </label>
      </TopBar>
      {shouldShowFallback ?
        <PagesListFallback hasError={isError} hasPages={hasNoPages} />
      : <PagesTable
          dataSource={dataSource}
          isLoading={isLoading}
          setStatusFilter={setStatusFilter}
          statusFilter={statusFilter}
          setEditingPage={setEditingPage}
          openSharePage={openSharePage}
      />
      }
      {!isError && hasPages && <FloatingButton onClick={showCreateModal} label={t('cms:create_page')} />}
      <PageFormModal
        onSubmit={onCreatePage}
        title={t('cms:create_page')}
        onClose={hideCreateModal}
        isOpen={isCreateModalVisible}
        isLoading={isCreatingPage}
      />
      <PageFormModal
        defaultValues={selectedPageSettings}
        onSubmit={onEdit}
        title={t('cms:page_settings')}
        onClose={hideSettingsModal}
        onClosed={handlePageSettingsClosed}
        isOpen={isSettingsModalVisible && isDefined(selectedPageSettings)}
        isLoading={isSavingPage}
        pageLanguages={selectedPageLanguages}
      />
      {isDefined(pageToShare) && <SharePageModal isOpen={isSharePageOpen} onClose={closeSharePage} {...pageToShare} />}
    </PageTitle>
  );
};
