import { Order } from '@typings';
import { createSelector } from 'reselect';

import { getIsOrderEditable } from '../../logic/Orders';
import { isEmpty } from '../../utils/isEmpty';
import { getIsBuyer } from '../user';

export const getStages = (state: Store): Order.Stage[] => {
  const isBuyer = getIsBuyer(state.user);

  return isBuyer ? state.selections.stages.buyer : state.selections.stages.seller;
};

export const getEditableStages = createSelector(getStages, stages =>
  stages.filter(stage => isEmpty(stage.value) || getIsOrderEditable(stage.value)),
);

export const getNotEditableStages = createSelector(getStages, stages =>
  stages.filter(stage => isEmpty(stage.value) || !getIsOrderEditable(stage.value)),
);

export const getSelections = (state: Store): Order.Essentials[] => state.selections.orders.data;
export const getSelectionById = (state: Store, id: Order.Id) => state.selections.orders.data.find(({ order: orderId }) => orderId === id);

export const getOpenSelections = createSelector(getSelections, selections => selections.filter(selection => selection.status === 'open'));

export const getAreSelectionsLoading = (state: Store): boolean => state.selections.orders.isLoading;
export const getHasLoadedInitialSelections = (state: Store): boolean => state.selections.orders.hasLoadedInitial;
export const getIsLoadingMore = (state: Store): boolean => state.selections.orders.isLoadingMore;

export const getOrderCount = (state: Store): number => state.selections.orders.orderCount;
export const hasLoadedAll = (state: Store): boolean => getOrderCount(state) === getSelections(state).length;

export const getStagesByUserType = (state: Store): Order.Stage[] => {
  const isBuyer = getIsBuyer(state.user);

  return isBuyer ? state.selections.stages.buyer : state.selections.stages.seller;
};
