import { Order } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { isDefined } from '../../../../utils/is';
import { isEmpty } from '../../../../utils/isEmpty';
import Heading from '../../../various/Heading';
import { PaymentMethodsField } from '../../../various/PaymentMethods';

import styles from './CheckoutPayments.module.scss';

interface Props {
  order: Order.Open | Order.Closed;
}

export const CheckoutPayments = React.memo(({ order }: Props) => {
  const { t } = useTranslation(['payments']);

  if (!isDefined(order.paymentMethods) || isEmpty(order.paymentMethods)) {
    return null;
  }

  return (
    <div>
      <Heading variant={['blue', 'h2']} title={t('payments:payment_methods')} />
      <div className={styles.amountSection}>
        <span className={styles.title}>{t('payments:payment_amount_title')}</span>
        <span className={styles.amount}>{order.totals.prepaymentPriceAsText}</span>
      </div>
      <PaymentMethodsField className={styles.paymentMethods} />
    </div>
  );
});
