import { Item, Order, Prepack, Preset, Product } from '@typings';
import { groupBy, mapObjIndexed, pipe, splitEvery, uniqBy } from 'ramda';
import { Option } from 'space-lift';

import { isDefined } from '../utils/is';
import { isEmpty } from '../utils/isEmpty';

import { getSizeIds } from './products';
import { getIsPresetStockAvailable } from './unitsDistribution';

export type PresetValidator = (preset: Preset, product: Product.Standard, deliveryWindowId: string, type?: Preset.Type) => boolean;

export const getProductPresetsIds = (product: Product.Standard, type: Preset.Type) =>
  product.presets?.[type].map(preset => preset.id) ?? [];

const productSizeTableMatchesPreset: PresetValidator = (preset, product) => {
  const sizeIds = getSizeIds(product);

  return preset.items.every(item => item.quantity === 0 || sizeIds.includes(item.sizeId));
};

const canBeDistributed: PresetValidator = (_, product) => {
  return product.itemQuantityMinimum === 1 && product.itemQuantityMultipleOf === 1;
};

const hasAtLeastOneStockAvailable: PresetValidator = (_, product, deliveryWindowId) => {
  const stockPerSize = product.items.map(item => getIsPresetStockAvailable(item, deliveryWindowId));

  const isStockAvailable = stockPerSize.find(stock => stock);

  return isDefined(isStockAvailable) && isStockAvailable;
};

const all =
  (...validators: PresetValidator[]) =>
  (preset: Preset, product: Product.Standard, deliveryWindowId: string) =>
    validators.every(validator => validator(preset, product, deliveryWindowId));

export const validatePreset = all(productSizeTableMatchesPreset, canBeDistributed, hasAtLeastOneStockAvailable);

export const getIsPresetEnforced = (type: Preset.Type) => (presetId: string, product: Product.Standard) =>
  Option(product.presets)
    .map(presets => presets[type])
    .map(presets => presets.find(({ id }) => id === presetId))
    .filter(isDefined)
    .map(({ isEnforced }) => Boolean(isEnforced))
    .getOrElse(false);

export const getUniqPrepacks = (prepacksMap: Prepack.Map) => {
  return uniqBy(
    ({ id }) => id,
    Object.values(prepacksMap).flatMap(({ prepacks }) => prepacks),
  );
};

export const getPrepacksFromMap = (prepacksMap: Prepack.Map, variantId: string) =>
  Option(prepacksMap)
    .map(mappedData => mappedData[variantId])
    .map(({ prepacks }) => prepacks)
    .getOrElse([]);

export const getPrepackIdsFromMap = (prepacksMap: Prepack.Map, variantId: string) =>
  getPrepacksFromMap(prepacksMap, variantId).map(prepack => prepack.id);

export const getArePrepacksEnforced = (prepacks: Preset[], product: Product.Standard) =>
  prepacks.some(prepack => getIsPresetEnforced('prepacks')(prepack.id, product));

export const sumPresetsResults = pipe(
  (results: Preset.Item[][]) => results.flat(),
  groupBy(result => `${result.itemTableX},${result.itemTableY}`),
  groups =>
    mapObjIndexed(
      item =>
        item.reduce(
          (acc, cur) => ({ ...cur, quantity: acc.quantity + cur.quantity }),
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          { quantity: 0 } as Preset.Item,
        ),
      groups,
    ),
  item => Object.values(item),
);

export const updatePrepackQuantity =
  (value: number, prepackId: string) =>
  (prepacksData: Prepack.Full[]): Prepack.Full[] =>
    Option(prepacksData)
      .map(prepacks => prepacks.map(prepack => (prepack.id === prepackId ? { ...prepack, quantity: value } : prepack)))
      .getOrElse([]);

export const getPrepacksRequestData = (prepacksData: Prepack.Full[], localizedSizeChartName?: string): Prepack.QuantityPayload[] =>
  Option(prepacksData)
    .map(prepacks =>
      prepacks.map(({ id, quantity }) => ({
        prepack: id,
        quantity,
        ...(isDefined(localizedSizeChartName) ? { localizedSizeChartName } : {}),
      })),
    )
    .getOrElse([]);

export const calculateOptimisticItems = (items: Item[], deliveryWindow: string) => (prepacksData: Prepack.Full[]) =>
  Option(prepacksData)
    .map(prepacks =>
      prepacks.map(prepack =>
        prepack.items.filter(item => item.quantity > 0).map(item => ({ ...item, quantity: item.quantity * prepack.quantity })),
      ),
    )
    .map(prepacks => sumPresetsResults(prepacks))
    .map(prepacks =>
      prepacks
        .map(item => {
          const sizeItem = items.find(({ sizeId }) => sizeId === item.sizeId);

          return isDefined(sizeItem) ? { deliveryWindow, item: sizeItem.item, quantity: item.quantity } : null;
        })
        .filter(isDefined),
    )
    .getOrElse([]);

export const getVariantsIdsWithPrepacksAdded = (variants: Product.Standard[], deliveryWindowId: string, orderDetails: Order.Single) => {
  return variants.flatMap(variant =>
    orderDetails.order.products
      .filter(product => product.variant === variant.variant && product.deliveryWindow === deliveryWindowId)
      .filter(orderVariant => !isEmpty(orderVariant.prepacks))
      .map(orderVariant => orderVariant.variant),
  );
};

export const validatePresets = (
  variant: Product.Standard,
  deliveryWindowId: string,
  presets: Record<string, Preset>,
  type: Preset.Type,
) => {
  const productPrepacksIds = getProductPresetsIds(variant, type);
  const sizeIds = getSizeIds(variant);
  const ySizesCount = Math.max(variant.itemTable.y.length, 1);
  const sizeIdsByItemY = splitEvery(ySizesCount, sizeIds);

  return Object.values(presets)
    .filter(prepack => productPrepacksIds.includes(prepack.id) && validatePreset(prepack, variant, deliveryWindowId))
    .map(prepack => ({
      ...prepack,
      items: prepack.items
        .filter(item => sizeIds.includes(item.sizeId))
        .map(item => ({
          ...item,
          itemTableX: sizeIdsByItemY.findIndex(sizesInColumn => sizesInColumn.includes(item.sizeId)),
        })),
    }));
};
