import { Cms } from '@typings';

import { isDefined } from '../../utils/is';
import { getIsContentBlock } from '../pages';

export const getTextPartsOrderForBlock = (block: Cms.AnyBlock | null) => {
  if (!isDefined(block) || !getIsContentBlock(block)) {
    return [];
  }

  return block.settings.text?.partsOrder ?? [];
};
