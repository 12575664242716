import { map, pick } from 'ramda';
import React from 'react';
import { PlacementType } from 'react-laag/dist/PlacementType';

import { RadioGroup } from '../Fields/RadioGroup';

import { Dropdown } from './Dropdown';
import styles from './Dropdown.module.scss';

interface Option {
  name: string;
  value: string;
  selectedLabel?: string;
}

interface Props {
  allowClear?: boolean;
  name: string;
  options: Option[];
  placement?: PlacementType;
  title: string;
  value: string;
  onChange: (value: string) => void;
  onClear?: () => void;
}

export const DropdownRadioGroup = ({ allowClear, name, options, placement, title, value, onChange, onClear }: Props) => {
  const [isVisible, setIsVisible] = React.useState(false);

  const handleChange = (newValue: string) => {
    onChange(newValue);
    setIsVisible(false);
  };

  const handleClear = () => {
    onClear?.();
    setIsVisible(false);
  };

  const selected = React.useMemo(() => {
    const selectedOption = options.find(option => option.value === value);

    return selectedOption?.selectedLabel ?? selectedOption?.name;
  }, [options, value]);

  const radioGroupOptions = map(pick(['name', 'value']), options);

  return (
    <Dropdown
      allowClear={allowClear}
      placement={placement}
      selected={selected}
      title={title}
      visible={isVisible}
      onVisibilityChange={setIsVisible}
      onClear={handleClear}
    >
      <div className={styles.dropdownRadioGroup}>
        <RadioGroup name={name} options={radioGroupOptions} size="small" value={value} onChange={handleChange} />
      </div>
    </Dropdown>
  );
};
