import {
  AttributeDescription,
  Country,
  DeliveryWindow,
  DeliveryWindowSpecificPrice,
  Id,
  Order,
  Picture,
  Product,
  StockByDeliveryWindow,
  Variants,
  Video,
} from '@typings';
import { stringify } from 'qs';
import { groupBy, prop, uniq, uniqBy } from 'ramda';
import { Option } from 'space-lift';

import { compiledPaths } from '../paths';
import { getQueryParams } from '../utils/getQueryParams';
import { getHasRelatedProducts } from '../utils/guards';
import { isDefined } from '../utils/is';
import { isEmpty } from '../utils/isEmpty';
import { removeDuplicates } from '../utils/lists';
import { listToRecord, toIdList } from '../utils/normalize';
import { isNumberRepresentation } from '../utils/numberRepresentation';

import { filtersDecoder } from './filters';
import {
  isDistinctVariantOrMultivariantRelation,
  isMultivariantRelation,
  isVariantOrMultivariantRelation,
  isVariantRelation,
} from './merchandise';

const COUNTRY_OF_ORIGIN = 'Country of origin';

export const getAllOrderedVariants = (productInfo: Product.Full | Product.Standard) => {
  if (getHasRelatedProducts(productInfo)) {
    return [productInfo, ...productInfo.relatedProducts.variants.filter(isVariantOrMultivariantRelation)] as (
      | Product.Full
      | Product.Standard
    )[];
  }

  return [productInfo];
};

interface QuantityFromItemProps {
  deliveryWindowId: DeliveryWindow.Id;
  stockByDeliveryWindow?: StockByDeliveryWindow;
}

export const getStockForDeliveryWindow = ({ deliveryWindowId, stockByDeliveryWindow }: QuantityFromItemProps): Variants.Stock => {
  const deliveryWindowStock = isDefined(stockByDeliveryWindow) ? stockByDeliveryWindow[deliveryWindowId] : null;

  return deliveryWindowStock ?? 0;
};

function getAllVariantsInfoFromProductInfo(productInfo: Product.Standard): Record<string, Product.Standard> {
  const firstVariantsInfo: Record<string, Product.Standard> = {
    [productInfo.variant]: productInfo,
  };

  if (getHasRelatedProducts(productInfo)) {
    return productInfo.relatedProducts.variants.reduce<Record<string, Product.Standard>>(
      (acc, variant) => ({ ...acc, [variant.variant]: variant }),
      firstVariantsInfo,
    );
  }

  return firstVariantsInfo;
}

export const getVariantRelationRelatedProducts = (products: Product.Full[]) =>
  products.reduce((productsAcc: Product.Full[], currentProduct: Product.Full) => {
    if (!getHasRelatedProducts(currentProduct)) {
      return [...productsAcc];
    }

    const { variants } = currentProduct.relatedProducts;
    const variantRelations = variants.filter((variant: Product.Standard) => isVariantRelation(variant));
    const productWithVariantRelationRelatedProductsOnly = {
      ...currentProduct,
      relatedProducts: {
        variants: variantRelations,
      },
    };

    return [...productsAcc, productWithVariantRelationRelatedProductsOnly];
  }, []);

export const getRelatedProducts = ({ relatedProducts }: Product.Standard) =>
  isDefined(relatedProducts) && !Array.isArray(relatedProducts) ? relatedProducts.variants : [];

export const getMultivariantRelatedProductIds = (variant: Product.Standard | undefined) => {
  return isDefined(variant) && getHasRelatedProducts(variant) ?
      variant.relatedProducts.variants.filter(isMultivariantRelation).map(product => product.product)
    : [];
};

export const getProductVariantsWithVariantRelation = (product: Product.Standard | Product.Full) =>
  getRelatedProducts(product).filter(isVariantRelation);

export const getUniqForProductVariants = (product: Product.Full, variants: Product.Standard[]) => {
  return Option(product)
    .filter(getHasRelatedProducts)
    .filter(productDetails => !isEmpty(productDetails.relatedProducts.variants))
    .map(productDetails => [productDetails, ...variants.filter(isVariantRelation)])
    .map(uniqBy(prop('product')))
    .getOrElse([]);
};

const getProductAndVariantsIds = (product: Product.Full | Product.Standard) =>
  Option(getProductVariantsWithVariantRelation(product))
    .map(variants => toIdList(variants, 'product'))
    .map(ids => [product.product, ...ids])
    .map(removeDuplicates)
    .getOrElse([product.product]);

export const filterProductRelatedProducts =
  (condition: (product: Product.Standard) => boolean) => (product: Product.Standard | Product.Full) =>
    Option(product)
      .map(getRelatedProducts)
      .map(relations => relations.filter(condition))
      .map(relations => (getHasRelatedProducts(product) ? { ...product, relatedProducts: { variants: relations } } : product))
      .getOrElse(product);

interface ProductSearchQueryProps {
  productId?: string;
  locationSearch?: string;
  variantId?: string;
}

export const getProductSearchQuery = ({ productId, locationSearch, variantId }: ProductSearchQueryProps) => {
  const currentFilters = getQueryParams(locationSearch ?? window.location.search, {
    decoder: filtersDecoder,
    ignoreQueryPrefix: true,
  });

  return stringify({ ...currentFilters, showProduct: productId, showVariant: variantId }, { addQueryPrefix: true, encode: false });
};

export interface ProductUrlProps {
  productId?: string;
  variantId?: string;
}

export type GetProductUrl = (productUrlProps: ProductUrlProps) => string;
export type GetProductUrlForLookbook = (productUrlProps: ProductUrlProps) => (lookbook: string) => string;

export const getUrlForProductAndVariant = ({ productId, variantId }: ProductUrlProps) => {
  if (!isDefined(productId)) {
    return '';
  }

  return `${window.location.pathname}${getProductSearchQuery({ productId, variantId })}`;
};

export const getUrlForLookbookProduct =
  ({ productId, variantId }: ProductUrlProps) =>
  (lookbookId: string | undefined) => {
    return `${compiledPaths.LOOKBOOK_SINGLE_PRODUCT({ lookbookId, productId })}${getProductSearchQuery({ variantId })}`;
  };

export const getUrlForLookbookVariant = ({ variantId }: ProductUrlProps) => {
  if (!isDefined(variantId)) {
    return '';
  }

  return `${window.location.pathname}${getProductSearchQuery({ variantId })}`;
};

export const getProductIdFromUrl = (searchLocation: string) =>
  getQueryParams(searchLocation, { decoder: filtersDecoder, ignoreQueryPrefix: true }).showProduct;

/**
 * Returns an array of main variants of distinct variants sorted by variant id
 */
export const getDistinctVariantsOrMultivariants = (variants: Product.Standard[]) => {
  const distinctOrMultiVariants = variants.filter(isDistinctVariantOrMultivariantRelation);
  const [firstVariant] = variants;

  if (!isDefined(firstVariant)) {
    return [];
  }

  return uniqBy(prop('product'), [firstVariant, ...distinctOrMultiVariants]);
};

export const getUniqueVariantImages = (images: Picture[]) => {
  return uniqBy(image => (image.src !== '' ? image.src : image.variantId), images);
};

/**
 * Returns an array of variant videos from custom attribute video_vimeo_text.
 */
export const getVariantVideos = (product: Product.Full | Product.Standard): Video[] => {
  const otherVariants = getProductVariantsWithVariantRelation(product);
  const allVariants = [product, ...otherVariants];

  return allVariants
    .filter(variant => isDefined(variant.video_vimeo_text))
    .map(variant => ({
      productId: variant.product,
      src: variant.video_vimeo_text,
      type: 'video',
      variantId: variant.variant,
    }));
};

const getRequestedModalNavigationIndex = (productId: Id, productsList: string[], direction: 'next' | 'prev') => {
  const currentIndex = productsList.indexOf(productId);

  return currentIndex + (direction === 'next' ? 1 : -1);
};

const getRequestedProductId = (requestedIndex: number, productsList: string[]) => {
  if (requestedIndex < 0) {
    return productsList[0];
  }

  if (requestedIndex >= productsList.length) {
    return productsList[productsList.length - 1];
  }

  return productsList[requestedIndex];
};

const getSortedSizes = (sizes: string[], sortingArray: string[]) => {
  return [...sizes].sort((sizeA, sizeB) => sortingArray.indexOf(sizeA) - sortingArray.indexOf(sizeB));
};

export const getVariantsSizes = (variants: Product.Standard[]) => {
  const allSizes = variants.reduce<string[]>((acc: string[], item) => acc.concat(item.itemTable.x), []);
  const originals = variants
    .filter(variant => isDefined(variant.itemTable.original))
    .reduce<string[]>((acc: string[], item) => acc.concat(item.itemTable.original.x), []);
  const uniqueSortedSizes = uniq(getSortedSizes(allSizes, originals));
  const areSizesNumeric = uniqueSortedSizes.every(size => isNumberRepresentation(size));
  const areSizesMerging = uniqueSortedSizes.length !== allSizes.length;

  return areSizesNumeric && areSizesMerging ?
      [...uniqueSortedSizes].sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))
    : uniqueSortedSizes;
};

const getAttributeValue = (key: string, product: Product.Full | Product.Standard) => {
  const { deliveryWindows } = product;
  const { deliveryWindowSpecificPrices } = product as Product.Full;

  const [firstDelwin] = deliveryWindows;
  const delwinSpecificPrices =
    isDefined(firstDelwin) ? (deliveryWindowSpecificPrices?.[firstDelwin] as unknown as Record<string, DeliveryWindowSpecificPrice>) : {};
  const productSpecificPrice = delwinSpecificPrices?.[key];
  if (!getHasRelatedProducts(product) && isDefined(productSpecificPrice)) {
    return productSpecificPrice;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: No index signature with a parameter of type 'string' was found on type 'Full'.
  return product[key];
};

export const getCustomAttributesWithValue = (attributes: AttributeDescription[], product: Product.Standard): Product.AttributeInfo[] =>
  attributes.reduce((pair: Product.AttributeInfo[], { name, key }) => {
    const attributeValue = getAttributeValue(key, product) as string | { desc: string };

    if (!isDefined(attributeValue) || isEmpty(attributeValue)) {
      return pair;
    }

    const valueAsString = typeof attributeValue === 'object' ? attributeValue.desc : attributeValue;

    return isDefined(valueAsString) ? [...pair, { key, name, value: valueAsString }] : pair;
  }, []);

export const getVariantIdFromItem = (item: Variants.Item) => item.item.split('-')[0];

export const getShippedProducts = (shippedItems: Record<Id, Variants.Item[]>) => {
  const items = Object.entries(shippedItems)
    .map(([productId, productItems]) =>
      productItems.map(item => ({
        ...item,
        productId,
        variantId: getVariantIdFromItem(item),
      })),
    )
    .flat();

  return groupBy(item => `${item.productId}-${item.variantId}`, items);
};

export const isCmsProduct = (candidate: Product.Standard | Product.CmsProduct): candidate is Product.CmsProduct => {
  return 'markets' in candidate && 'prices' in candidate;
};

const cmsProductToStandard =
  (priceListId: Id) =>
  (product: Product.CmsProduct): Product.Standard & Product.CmsProduct => {
    const { relatedProducts, ...base } = product;
    const currentPrices = product.prices[priceListId] as Product.Price;
    const converter = cmsProductToStandard(priceListId);

    const hasVariants = (candidate: Product.CmsProduct['relatedProducts']): candidate is { variants: Product.CmsProduct[] } =>
      !Array.isArray(candidate);

    const variants = Option(relatedProducts)
      .filter(hasVariants)
      .map(related => related.variants)
      .map(productVariants => productVariants.map(converter))
      .getOrElse([]);

    return { ...base, ...currentPrices, relatedProducts: { variants } };
  };

export const convertCmsProductToStandard = (products: Record<Id, Product.CmsProduct>, priceList: Nullable<Id>) => {
  if (!isDefined(priceList) || isEmpty(products)) {
    return products as unknown as Record<Id, Product.Standard>;
  }
  const mappedProduct = Object.values(products).map(cmsProductToStandard(priceList));

  return listToRecord(mappedProduct, 'product');
};

export const localProductsSearch =
  (
    product: Pick<Product.Base, TypedProps<Required<Product.Base>, Nullable<string>>>,
    searchKeys: TypedProps<Required<Product.Base>, Nullable<string>>[],
  ) =>
  (searchPhrase: string) => {
    return searchKeys.some(key => {
      const searchParam = product[key];

      if (!isDefined(searchParam)) {
        return false;
      }

      const productKey = searchParam.toLowerCase();

      return productKey.includes(searchPhrase.toLowerCase());
    });
  };

export const applyCountryNameToAttribute = (attributes: Product.AttributeInfo[], countries: Record<string, Country>) => {
  return attributes.map(({ name, value, key }) => {
    if (name === COUNTRY_OF_ORIGIN) {
      const countryName = countries[value]?.name ?? value;

      return { key, name, value: countryName };
    }

    return { key, name, value };
  });
};

export const getHasDeliveryWindowSpecificPrices = (product: Product.Full | Product.Standard): product is Product.Full =>
  isDefined((product as Product.Full).deliveryWindowSpecificPrices);

const getOrderDetails = (orderDetails: Order.Single | Order.Open) => {
  return 'status' in orderDetails ? orderDetails : orderDetails.order;
};
export const getOrderedProducts = (orderDetails: Order.Single | Order.Open) => {
  return getOrderDetails(orderDetails).products;
};

export const getCancelledProducts = (orderDetails: Order.Single | Order.Open) => {
  return getOrderDetails(orderDetails).cancelledProducts ?? [];
};

export const getOrderedProductsById = (orderDetails: Order.Single, productId: Id) =>
  getOrderedProducts(orderDetails).filter(product => product.product === productId);

export const getSizeIds = (product: Product.Standard) => product.items.map(item => item.sizeId);

export const getVariantAttributeValue = (variant: Product.Standard | undefined, attributeKey: string) => {
  const ATTRIBUTE_PLACEHOLDER = '-';

  if (!isDefined(variant)) {
    return ATTRIBUTE_PLACEHOLDER;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: No index signature with a parameter of type 'string' was found on type 'Standard'.
  const variantAttribute = variant[attributeKey] as string | Product.Attribute | undefined;

  if (!isDefined(variantAttribute)) {
    return ATTRIBUTE_PLACEHOLDER;
  }

  return typeof variantAttribute === 'object' ? variantAttribute.desc : variantAttribute;
};

export default {
  getAllOrderedVariants,
  getAllVariantsInfoFromProductInfo,
  getProductAndVariantsIds,
  getProductIdFromUrl,
  getRelatedProducts,
  getRequestedModalNavigationIndex,
  getRequestedProductId,
  getSizeIds,
  getVariantAttributeValue,
};
