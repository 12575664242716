import { Translations as TranslationsNs } from '@typings';
import { mapObjIndexed, pick } from 'ramda';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { fetchCmsNavigationRequest, getIsCmsNavigationLoading } from '../../ducks';
import { useTranslations } from '../../services/hooks/translations/useTranslations';
import { useUpdateTranslations } from '../../services/hooks/translations/useUpdateTranslations';
import { useTranslationsConfig } from '../../utils/hooks';
import { isDefined } from '../../utils/is';
import { Form, FormFieldset } from '../various/Form';
import { PageTitle } from '../various/PageTitle';
import { TopBar } from '../various/TopBar';
import { Wrapper, WrapperSize } from '../various/Wrapper';

import { TranslationsContextProvider, useTranslationsContext } from './context/TranslationsContext';
import { NavigationItemsTable } from './TranslationsContent/NavigationItemsTable';
import { PageContentTable } from './TranslationsContent/PageContentTable';
import { TranslationsHead } from './TranslationsContent/TranslationsHead';

const TranslationsComponent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'translations']);
  const isLoadingNavigation = useSelector(getIsCmsNavigationLoading);
  const pageTitle = t('translations:translations');

  const { data: translations, isPending } = useTranslations();
  const { mutate, isPending: isUpdating } = useUpdateTranslations();
  const { isEditing, setIsEditing } = useTranslationsContext();
  const { additionalLanguages, areMultipleLanguagesAvailable } = useTranslationsConfig();
  const formMethods = useForm<TranslationsNs.TranslationsDTOAdditional>();

  const isLoading = isLoadingNavigation || isPending;

  React.useEffect(() => {
    if (!isDefined(translations)) {
      return;
    }

    const additionalLanguagesCodes = additionalLanguages.map(({ code }) => code);

    formMethods.reset({
      labels: mapObjIndexed(value => pick(additionalLanguagesCodes, value), translations.labels),
      navigationItems: mapObjIndexed(value => pick(additionalLanguagesCodes, value), translations.navigationItems),
    });
  }, [additionalLanguages, formMethods, translations]);

  React.useEffect(() => {
    if (!areMultipleLanguagesAvailable) {
      return;
    }

    dispatch(fetchCmsNavigationRequest());
  }, [dispatch, areMultipleLanguagesAvailable]);

  const handleSubmit = (data: TranslationsNs.TranslationsDTOAdditional) => {
    mutate(data, {
      onSuccess: () => {
        setIsEditing(false);
      },
    });
  };

  const canApplyTranslations = !isLoading && areMultipleLanguagesAvailable;

  return (
    <PageTitle title={pageTitle}>
      <TopBar title={pageTitle} />
      <Wrapper size={WrapperSize.MEDIUM}>
        <Form formMethods={formMethods} onSubmit={handleSubmit}>
          <TranslationsHead sectionName={pageTitle} isEditable={canApplyTranslations} isLoading={isUpdating} />
          {areMultipleLanguagesAvailable && (
            <FormFieldset isDisabled={!isEditing || isUpdating}>
              <PageContentTable isLoading={isLoading} />
              <NavigationItemsTable isLoading={isLoading} />
            </FormFieldset>
          )}
        </Form>
      </Wrapper>
    </PageTitle>
  );
};

export const Translations = () => (
  <TranslationsContextProvider>
    <TranslationsComponent />
  </TranslationsContextProvider>
);
