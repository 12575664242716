import { Addresses, Checkout, DeliveryWindow, Id, Order, Product, ShippingDatesPreferences, Variants } from '@typings';
import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import { flatten, uniq } from 'ramda';
import { defaultMemoize } from 'reselect';

import { compiledPaths } from '../paths';
import { isDefined } from '../utils/is';
import { isEmpty } from '../utils/isEmpty';

import { getDeliveryWindowIdsFromVariants } from './deliveryWindows';
import { getCancelledProducts, getOrderedProducts, getOrderedProductsById } from './products';

const DEFAULT_SHIPPING_ADDRESS_ID = '0';

type ProductParameterSelector = (productId: Product.Id, orderProducts: Order.Product[]) => number;

export function getProductByProductIdVariantIdFromProducts<T extends { product: Id; variant: Id }>(
  productId: string,
  variantId: string,
  products: T[],
) {
  return products.find(product => product.product === productId && product.variant === variantId);
}

export const getProductsList = (products: Order.Product[], productId: string): Order.Product[] => {
  return products.filter(product => product.product === productId);
};

export const calculateTotalProductAmount = (productId: string, products: Order.Product[]) => {
  return getProductsList(products, productId).reduce(
    (total, product) => total + product.items.reduce((totalItems, item) => totalItems + item.quantity, 0),
    0,
  );
};

export const getDeliveryWindowsFromProducts = (orderProducts: Order.Product[], deliveryWindows: DeliveryWindow.Mixed[] | undefined) => {
  if (!isDefined(deliveryWindows)) {
    return [];
  }

  const delwins = orderProducts.map(orderProduct => orderProduct.deliveryWindow);
  const uniqueDelwins = [...new Set(delwins)];

  return deliveryWindows.filter(delwin => uniqueDelwins.includes(delwin.deliveryWindow));
};

export const getProductsCountInDelwin = (delwinId: string, products: Order.Product[]) => {
  return getProductsInDelwin(delwinId, products).length;
};

export const getProductsInDelwin = (delwinId: string, orderProducts: Order.Product[]) =>
  orderProducts.filter(product => product.deliveryWindow === delwinId && !isEmpty(product.items));

const getTotalQuantityInProduct = (items: Variants.Item[]) => items.reduce((acc, item) => acc + item.quantity, 0);

export const getUnitsCountInDelwin = (delwinId: string, products: Order.Product[]) => {
  const productsInDelwin = getProductsInDelwin(delwinId, products);

  return productsInDelwin.reduce((acc, product) => acc + getTotalQuantityInProduct(product.items), 0);
};

export const getTotalPriceInDelwin = (delwinId: string, products: Order.Product[]) => {
  const productsInDelwin = getProductsInDelwin(delwinId, products);

  return productsInDelwin.reduce((acc, product) => acc + product.totalPriceAsNumber, 0);
};

/**
 * `true` if an order is open and can still be edited. Keep in mind
 * there's also `pending` and `inProgress` which can no longer be edited.
 */
export const getIsOrderEditable = (status: Order.Status) => ['open', 'checkoutRequested'].includes(status);

export const getIsOrderClosed = (order: Order.Open | Order.Closed): order is Order.Closed => !getIsOrderEditable(order.status);

/**
 * Calculate total variant quantity for product in specified delwin
 */
export function getTotalVariantQuantityByProductAndDelwins(productId: string, delwinIds: string[], orderProducts: Order.Product[]): number {
  const variantsInDelwin = Object.values(orderProducts).filter(
    product => delwinIds.includes(product.deliveryWindow) && product.product === productId,
  );

  return variantsInDelwin.reduce((total, product) => (product.items.length ? total + 1 : total), 0);
}

/**
 * Calculate total variant quantity for given product
 * There are multiple entries for the same product variant if it exists
 * in multiple delivery windows, and it should not be counted as separate variants.
 */
export const getTotalVariantQuantityByProduct: ProductParameterSelector = (productId: string, products: Order.Product[]) => {
  const productsList = getProductsList(products, productId);
  const uniqueVariantIds = uniq(productsList.map(value => value.variant));

  const variantItems = uniqueVariantIds.map(variantId => {
    const variants = productsList.filter(item => item.variant === variantId);
    const items = variants.map(variant => variant.items);

    return flatten(items);
  });

  return variantItems.reduce((total, variant) => (variant.length > 0 ? total + 1 : total), 0);
};

/**
 * Get total price of all units for product (with all variants) from list of order products
 */
export const getTotalProductPrice: ProductParameterSelector = (productId: string, orderProducts: Order.Product[]) => {
  return orderProducts
    .filter(orderProduct => orderProduct.product === productId)
    .reduce((total, orderProduct) => total + orderProduct.totalPriceAsNumber, 0);
};

/**
 * Get total price of all units for product (with all variants) in specified delivery window
 */
export function getTotalProductPriceInDelwins(productId: string, delwinIds: string[], orderProducts: Order.Product[]): number {
  return orderProducts
    .filter(prod => prod.product === productId && delwinIds.includes(prod.deliveryWindow))
    .reduce((total, prod) => total + prod.totalPriceAsNumber, 0);
}

/**
 * Calculate total units in all product variants for specific delivery window
 */
export function getTotalUnitQuantityByProductAndDelwins(productId: string, delwinIds: string[], orderProducts: Order.Product[]): number {
  const variantArray = orderProducts.filter(item => delwinIds.includes(item.deliveryWindow) && productId === item.product);

  return variantArray ? getTotalUnitsInProduct(variantArray) : 0;
}

const getTotalUnitsInProduct = (variantArray: Order.Product[]) =>
  variantArray.reduce((total, variant) => total + variant.items.reduce((acc, item) => acc + item.quantity, 0), 0);

export const getIsProductInOrderByDelwinIds = (productId: string, delwinIds: DeliveryWindow.Id[], orderProducts: Order.Product[]) =>
  orderProducts.some(({ product, deliveryWindow }) => product === productId && delwinIds.includes(deliveryWindow));

export const getOrderPathByStatus = (orderId: string, status: Order.Status): string => {
  if (status === 'open') {
    return compiledPaths.PRODUCTS_ORDER({ id: orderId });
  }
  if (status === 'checkoutRequested') {
    return compiledPaths.ORDER_DETAILS({ id: orderId });
  }

  return compiledPaths.CHECKOUT_SUCCESS({ id: orderId });
};

export const getUniqProductIds = (orderDetails: Order.Single) => uniq(orderDetails.order.products.map(product => product.product));

export const hasCancelledProducts = (products: Order.Product[]) => {
  return products.some(product => product.isCancelled);
};

export const getProductsFromOrder = (orderDetails: Order.Single) =>
  getUniqProductIds(orderDetails)
    .map(product => (Array.isArray(orderDetails.products) ? undefined : orderDetails.products[product]))
    .filter(isDefined);

export const getHasAnyUnitsAdded = (orderProducts: Order.Product[]) => orderProducts.some(product => product.items.length > 0);

export const extractShippingAddressIdFromOrderResponse = (response: Order.Single | Order.Split) => {
  if (getIsOrderSplit(response)) {
    return null;
  }

  const {
    order,
    account: { shippingAddresses },
  } = response;

  if (isDefined(order.shippingAddressId)) {
    return order.shippingAddressId;
  }

  const defaultAddress =
    shippingAddresses?.find(({ shippingAddress }) => shippingAddress === DEFAULT_SHIPPING_ADDRESS_ID) ?? shippingAddresses?.[0];

  return defaultAddress?.shippingAddress ?? null;
};

interface ItemsByProductIdVariantIdDelwinsFromOrderProductsProps {
  productId: Id;
  variantId: Id;
  deliveryWindowId: DeliveryWindow.Id;
  orderProducts: Order.Product[];
}

const getItemsByProductIdVariantIdDelwinsFromOrderProducts = defaultMemoize(
  ({ productId, variantId, deliveryWindowId, orderProducts }: ItemsByProductIdVariantIdDelwinsFromOrderProductsProps) => {
    const getHasSameProductAndVariantIdAndDelwin = (orderProduct: Order.Product) =>
      orderProduct.product === productId && orderProduct.variant === variantId && orderProduct.deliveryWindow === deliveryWindowId;

    return orderProducts.filter(getHasSameProductAndVariantIdAndDelwin).reduce((productsAcc, product) => {
      const { deliveryWindow, items } = product;

      return {
        ...productsAcc,
        ...items.reduce(
          (itemsAcc, item) => ({
            ...itemsAcc,
            [`${deliveryWindow}-${item.item}-${item.itemId}`]: item,
          }),
          {},
        ),
      };
    }, {});
  },
);

export const getOrderedItems = (
  variants: Product.Standard[],
  orderDetails: Order.Single,
  deliveryWindowId: DeliveryWindow.Id,
  cancelled?: boolean,
) => {
  const orderProducts = cancelled ? getCancelledProducts(orderDetails) : getOrderedProducts(orderDetails);

  return variants.reduce<Record<string, Variants.OrderedItems>>(
    (acc, { variant: variantId, product: productId }) => ({
      ...acc,
      [variantId]: getItemsByProductIdVariantIdDelwinsFromOrderProducts({ deliveryWindowId, orderProducts, productId, variantId }),
    }),
    {},
  );
};

export const getProductsToRemoveByDeliveryWindows = (order: Order.Open, deliveryWindows: string[]) => {
  return order.products
    .filter(({ deliveryWindow }) => deliveryWindows.includes(deliveryWindow))
    .filter(({ items }) => !isEmpty(items))
    .map(({ product }) => product);
};

export const getDeliveryWindowNamesCombined = (deliveryWindows: DeliveryWindow.Mixed[]) => {
  return deliveryWindows.reduce<string>(
    (acc, current, index, array) =>
      index === 0 ? current.name
      : index === array.length - 1 ? `${acc} and ${current.name}`
      : `${acc}, ${current.name}`,
    '',
  );
};

export const getDeliveryWindowsExpiredMessage = (deliveryWindows: DeliveryWindow.Mixed[], t: TFunction<['expiredDelwins']>) =>
  isEmpty(deliveryWindows) ? '' : (
    t('expiredDelwins:delivery_window_has_expired', {
      count: deliveryWindows.length,
      name: getDeliveryWindowNamesCombined(deliveryWindows),
    })
  );

export const getOrderDelwinIds = (variants: (Product.Standard | Product.Full)[], orderDetails: Order.Single, productId: Id) => {
  const delwinsFromVariants = getDeliveryWindowIdsFromVariants(variants);
  const products = getOrderedProductsById(orderDetails, productId);
  const orderDelwinIds = getDeliveryWindowsFromProducts(products, orderDetails.deliveryWindows).map(delwin => delwin.deliveryWindow);

  return uniq([...delwinsFromVariants, ...orderDelwinIds]);
};

export const getIsProductCancelled = (productId: Id, orderProducts: Order.Product[]) => {
  return orderProducts.filter(({ product }) => product === productId).every(({ isCancelled }) => isCancelled);
};

export const getOrderPreferredShippingDate = (order: Order.Closed | Order.Open) => {
  if (!isDefined(order) || !getIsOrderClosed(order)) {
    return 'none';
  }

  const [firstDate] = order.preferredShippingDateByDeliveryWindow ?? [];

  if (isDefined(firstDate)) {
    return firstDate.date;
  }

  if (isDefined(order.preferredShippingDate)) {
    return order.preferredShippingDate;
  }

  return 'none';
};

export const getOrderCancelDate = (order: Order.Closed | Order.Open) => {
  if (!isDefined(order) || !getIsOrderClosed(order)) {
    return 'none';
  }
  const [firstDate] = order.cancelDateByDeliveryWindow ?? [];

  if (isDefined(firstDate)) {
    return firstDate.date;
  }

  if (isDefined(order.cancelDate)) {
    return order.cancelDate;
  }

  return 'none';
};

export const getIsOrderSplit = (order: Order.Single | Order.Split | Checkout.Failure): order is Order.Split => {
  return isDefined((order as Order.Split).orders);
};

export const getPrepaymentFromOrder = (order: Order.Base) => {
  return order.payments?.find(({ paymentType }) => paymentType === 'prepayment' || paymentType === 'prepaymentSplit');
};

export const getShippingAddressId = (shippingAddresses: Addresses.MixedShipping[] | undefined, shippingAddressId: Nullable<string>) =>
  shippingAddresses?.find(address => address.shippingAddress === shippingAddressId);

export const getCountryByShippingAddressId = (
  shippingAddresses: Addresses.MixedShipping[] | undefined,
  shippingAddressId: Nullable<string>,
) => getShippingAddressId(shippingAddresses, shippingAddressId)?.country;

export const mapDateByDeliveryWindowToArray = (
  data: ShippingDatesPreferences,
): {
  cancelDateByDeliveryWindow?: Order.DateByDeliveryWindow[];
  preferredShippingDateByDeliveryWindow?: Order.DateByDeliveryWindow[];
} => {
  const hasCancelDateByDeliveryWindow = Object.values(data).some(({ cancelDate }) => isDefined(cancelDate));
  const hasPreferredShippingDateByDeliveryWindow = Object.values(data).some(({ preferredShippingDate }) =>
    isDefined(preferredShippingDate),
  );

  return {
    ...(hasCancelDateByDeliveryWindow && {
      cancelDateByDeliveryWindow: Object.entries(data)
        .map(([key, value]) => ({
          date: dayjs(value.cancelDate).format('YYYY-MM-DD'),
          deliveryWindow: key,
        }))
        .filter(item => isDefined(item.date)) as Order.DateByDeliveryWindow[],
    }),
    ...(hasPreferredShippingDateByDeliveryWindow && {
      preferredShippingDateByDeliveryWindow: Object.entries(data)
        .map(([key, value]) => ({
          date: dayjs(value.preferredShippingDate).format('YYYY-MM-DD'),
          deliveryWindow: key,
        }))
        .filter(item => isDefined(item.date)) as Order.DateByDeliveryWindow[],
    }),
  };
};

const getTotalAmountInProduct = (items: Variants.Item[]) => {
  return items.reduce((itemsAcc, item) => itemsAcc + item.quantity, 0);
};

export const getTotalAmountInWholeOrder = (order: Order.Open) => {
  return order.products.reduce((productsAcc, product) => productsAcc + getTotalAmountInProduct(product.items), 0);
};
