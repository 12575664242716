import { Cms } from '@typings';
import cx from 'classnames';
import { times } from 'ramda';
import React from 'react';

import { getBlockGridPlacement } from '../../../logic/cms/styles';

import styles from './BlockTemplate.module.scss';

interface Props {
  templateModel: Cms.BlockModel;
  isLarge?: boolean;
  isFiller?: boolean;
  isPlaceholder?: boolean;
  screenType?: Cms.ScreenType;
  className?: string;
  style?: React.CSSProperties;
}

export const BlockTemplate = React.forwardRef<HTMLDivElement, React.WithOptionalChildren<Props>>(
  ({ templateModel, isPlaceholder = false, isFiller = false, isLarge, children, className, screenType = 'desktop', style }, ref) => {
    const { blockType, position } = templateModel;

    const isProductBlock = blockType === 'product';
    const isContentBlock = blockType === 'content';
    const productCount = position.desktop.spanX * position.desktop.spanY;

    return (
      <div
        ref={ref}
        style={{ ...getBlockGridPlacement(position[screenType]), ...style }}
        className={cx(
          styles.template,
          {
            [styles.contentTemplate]: !isFiller && isContentBlock,
            [styles.productTemplate]: !isFiller && isProductBlock,
            [styles.large]: isLarge,
          },
          className,
        )}
      >
        {!isPlaceholder &&
          isProductBlock &&
          productCount > 0 &&
          times(index => <div key={index} className={styles.productItem} />, productCount)}
        {children}
      </div>
    );
  },
);
