import Icon, { IconColor, IconType } from '../../various/Icon';

import styles from './TextLink.module.scss';

type Action = {
  actionName: string;
  dataTestId?: string;
  iconType: IconType;
  hidden: boolean;
  onClick: () => void;
};
interface Props {
  actions: Action[];
}

export const LinkMarkerActions = ({ actions }: Props) => {
  return (
    <span className={styles.actions}>
      {actions.map(
        ({ actionName, dataTestId, iconType, hidden, onClick }) =>
          !hidden && (
            <button
              key={actionName}
              type="button"
              aria-label={actionName}
              data-testid={dataTestId}
              className={styles.action}
              onClick={onClick}
            >
              <Icon type={iconType} color={IconColor.White} size={20} />
            </button>
          ),
      )}
    </span>
  );
};
