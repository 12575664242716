import { Skeleton } from '../../../../Skeleton';

import styles from './FilterGroupSetSkeleton.module.scss';

interface Props {
  rows: number;
}

export const FilterGroupSetSkeleton = ({ rows }: Props) => {
  return (
    <div className={styles.group}>
      <Skeleton type="text" size="large" itemClassName={styles.groupHeaderSkeleton} />
      <Skeleton type="text" size="medium" containerClassName={styles.itemSet} gap="large" rows={rows} width={60} />
    </div>
  );
};
