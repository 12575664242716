import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 24;
const ORIGINAL_COLOR = '#C4C4C4';

export const ImagePlaceholder = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox="0 0 24 24">
    <path
      d="M7.625 8.143c1.45 0 2.625-1.151 2.625-2.572C10.25 4.151 9.075 3 7.625 3S5 4.151 5 5.571c0 1.42 1.175 2.572 2.625 2.572ZM5 13.286 1.5 21h21L16.375 8.143l-6.125 7.714L5 13.286Z"
      clipRule="evenodd"
      fillRule="evenodd"
      fill={color}
    />
  </svg>
);
