import { useSelector } from 'react-redux';

import { getHasSelectionImportCompleted, getHasSelectionImportFailed, getIsSelectionImportLoading } from '../../../ducks';

import { SelectionImportFailure } from './SelectionImportFailure';
import { SelectionImportForm } from './SelectionImportForm';
import { SelectionImportLoader } from './SelectionImportLoader';
import { SelectionImportSuccess } from './SelectionImportSuccess';

export const SelectionImportContent = () => {
  const hasImportFailed = useSelector(getHasSelectionImportFailed);
  const hasImportCompleted = useSelector(getHasSelectionImportCompleted);
  const isImportLoading = useSelector(getIsSelectionImportLoading);

  if (hasImportFailed) {
    return <SelectionImportFailure />;
  }

  if (hasImportCompleted) {
    return <SelectionImportSuccess />;
  }

  if (isImportLoading) {
    return <SelectionImportLoader />;
  }

  return <SelectionImportForm />;
};
