import { ActionHandler, BuyerAccount, BuyerDataChangeOperation, PasswordChangeOperation, User } from '@typings';
import i18next from 'i18next';
import { createReducer } from 'typesafe-actions';

import { update } from '../../utils/update';
import { setLookbookToken } from '../lookbook';
import { createSelectionSuccess } from '../order/actions';
import { sharedSelectionSuccess } from '../selections/actions';

import {
  authoriseAccessRequest,
  authoriseAccessSuccess,
  changeAccountPasswordFailure,
  changeAccountPasswordRequest,
  changeAccountPasswordSuccess,
  changePasswordDefault,
  changePasswordFailure,
  changePasswordRequest,
  changePasswordSuccess,
  fetchLoginSuccess,
  fetchUserSuccess,
  logoutRequest,
  resetChangeAccountPassword,
  resetEditBuyerData,
  resetPasswordDefault,
  resetPasswordFailure,
  resetPasswordRequest,
  resetPasswordSuccess,
  setUserAsViewer,
  updateBuyersDataFailure,
  updateBuyersDataRequest,
  updateBuyersDataSuccess,
} from './actions';

export interface UserReducer {
  // Available only when logged in as buyer.
  account?: BuyerAccount | null;
  isLoggedIn: boolean;
  accessToken: string | null;
  authoriseAccess: {
    isLoading: boolean;
  };
  user: {
    // eslint-disable-next-line @typescript-eslint/ban-types
    data: User | {};
  };
  changeAccountPassword: PasswordChangeOperation & {
    data: {
      hasChanged: boolean | null;
    };
  };
  changeBuyerData: BuyerDataChangeOperation & {
    data: {
      isChanged: boolean | null;
    };
  };
  changePassword: PasswordChangeOperation & {
    data: {
      hasChanged: boolean | null;
      hint: string | null;
    };
  };
  resetPassword: PasswordChangeOperation & {
    data: {
      isReset: boolean | null;
      hint: string | null;
      msg: string | null;
    };
  };
  selectedAccountId?: Nullable<number | string>;
  selectedBuyerId?: Nullable<number | string>;
}

const initialState: UserReducer = {
  accessToken: null,
  authoriseAccess: {
    isLoading: false,
  },
  changeAccountPassword: {
    data: {
      hasChanged: null,
      msg: null,
    },
    isFailed: false,
    isLoading: false,
  },
  changeBuyerData: {
    data: {
      isChanged: null,
      msg: null,
    },
    isFailed: false,
    isLoading: false,
  },
  changePassword: {
    data: {
      hasChanged: null,
      hint: null,
      msg: null,
    },
    isFailed: false,
    isLoading: false,
  },
  isLoggedIn: false,
  resetPassword: {
    data: {
      hint: null,
      isReset: null,
      msg: null,
    },
    isFailed: false,
    isLoading: false,
  },
  selectedAccountId: null,
  selectedBuyerId: null,
  user: {
    data: {},
  },
};

/**
 * Note: it really is one of Login.Buyer | Login.Seller | Login.Automatic.Buyer | Login.Automatic.Seller.
 * We're using merge types, because no matter which one is the actual one, we are saving its payload.
 * In some cases in means saving proper data structures, in others is means saving null.
 */
const handleSetLoginSuccess: ActionHandler<UserReducer, typeof fetchLoginSuccess> = (state, action) => {
  const { token, user } = action.payload!;

  return update(state, {
    accessToken: token,
    isLoggedIn: true,
    selectedAccountId: null,
    selectedBuyerId: null,
    user: update(state.user, {
      data: user,
    }),
  });
};

const handleAuthoriseAccessRequest: ActionHandler<UserReducer, typeof authoriseAccessRequest> = state => {
  return update(state, {
    authoriseAccess: {
      isLoading: true,
    },
  });
};

const handleAuthoriseAccessSuccess: ActionHandler<UserReducer, typeof authoriseAccessSuccess> = (state, action) => {
  const { token } = action.payload!;

  return update(state, {
    accessToken: token,
    authoriseAccess: {
      isLoading: false,
    },
    isLoggedIn: true,
  });
};

const handleLogoutRequest = (): UserReducer => initialState;

const handleResetPasswordDefault = (state: UserReducer): UserReducer =>
  update(state, {
    resetPassword: initialState.resetPassword,
  });

const handleResetPasswordRequest = (state: UserReducer): UserReducer =>
  update(state, {
    resetPassword: update(state.resetPassword, {
      isFailed: false,
      isLoading: true,
    }),
  });

const handleResetPasswordSuccess = (state: UserReducer): UserReducer =>
  update(state, {
    resetPassword: update(state.resetPassword, {
      data: update(state.resetPassword.data, {
        hint: i18next.t('genericErrors:no_email_hint'),
        isReset: true,
        msg: i18next.t('common:reset_password_email_sent'),
      }),
      isLoading: false,
    }),
  });

const handleResetPasswordFailure = (state: UserReducer): UserReducer =>
  update(state, {
    resetPassword: update(state.resetPassword, {
      data: update(state.resetPassword.data, {
        isReset: false,
        msg: i18next.t('passwordManagement:reset_password_error'),
      }),
      isFailed: true,
      isLoading: false,
    }),
  });

const handleSetChangePasswordDefault = (state: UserReducer): UserReducer =>
  update(state, {
    changePassword: initialState.changePassword,
  });

const handleSetChangePasswordRequest = (state: UserReducer): UserReducer =>
  update(state, {
    changePassword: update(state.changePassword, {
      isFailed: false,
      isLoading: true,
    }),
  });

const handleSetChangePasswordSuccess = (state: UserReducer): UserReducer =>
  update(state, {
    changePassword: update(state.changePassword, {
      data: update(state.changePassword.data, {
        hasChanged: true,
        msg: i18next.t('passwordManagement:password_changed'),
      }),
      isLoading: false,
    }),
  });

const handleSetChangePasswordFailure = (state: UserReducer): UserReducer =>
  update(state, {
    changePassword: update(state.changePassword, {
      data: update(state.changePassword.data, {
        hasChanged: false,
        msg: i18next.t('passwordManagement:change_password_fail'),
      }),
      isFailed: true,
      isLoading: false,
    }),
  });

const handleResetUpdateBuyersData = (state: UserReducer) => {
  return update(state, {
    changeBuyerData: initialState.changeBuyerData,
  });
};

const handleUpdateBuyersDataRequest = (state: UserReducer) => {
  return update(state, {
    changeBuyerData: update(state.changeBuyerData, {
      isFailed: false,
      isLoading: true,
    }),
  });
};

const handleUpdateBuyersDataSuccess = (state: UserReducer) =>
  update(state, {
    changeBuyerData: update(state.changeBuyerData, {
      data: {
        isChanged: true,
        msg: null,
      },
      isLoading: false,
    }),
  });

const handleUpdateBuyersDataFailure = (state: UserReducer) =>
  update(state, {
    changeBuyerData: {
      data: {
        isChanged: false,
        msg: null,
      },
      isFailed: true,
      isLoading: false,
    },
  });

const handleResetChangeAccountPassword = (state: UserReducer) => {
  return update(state, {
    changeAccountPassword: initialState.changeAccountPassword,
  });
};

const handleSetChangeAccountPasswordRequest = (state: UserReducer): UserReducer => {
  return update(state, {
    changeAccountPassword: update(state.changeAccountPassword, {
      isFailed: false,
      isLoading: true,
    }),
  });
};

const handleSetChangeAccountPasswordSuccess = (state: UserReducer): UserReducer =>
  update(state, {
    changeAccountPassword: update(state.changeAccountPassword, {
      data: {
        hasChanged: true,
        msg: null,
      },
      isLoading: false,
    }),
  });

const handleSetChangeAccountPasswordFailure: ActionHandler<UserReducer, typeof changeAccountPasswordFailure> = state =>
  update(state, {
    changeAccountPassword: {
      data: {
        hasChanged: false,
        msg: i18next.t('passwordManagement:change_account_password_fail'),
      },
      isFailed: true,
      isLoading: false,
    },
  });

const handleSetCreateSelectionSuccess: ActionHandler<UserReducer, typeof createSelectionSuccess> = (state, action) => {
  const {
    account: { account },
    buyer: { buyer },
  } = action.payload!;

  if (state.selectedBuyerId !== null && state.selectedBuyerId !== buyer) {
    return update(state, {
      selectedAccountId: account,
      selectedBuyerId: buyer,
    });
  }

  return state;
};

const handleSetUserAsViewer = (state: UserReducer) =>
  update(state, {
    authoriseAccess: {
      isLoading: false,
    },
    user: {
      data: {
        access: 'viewer',
      },
    },
  });

const handleFetchUserSuccess: ActionHandler<UserReducer, typeof fetchUserSuccess> = (state, action) => {
  const { account, user } = action.payload!;

  return update(state, {
    account,
    user: update(state.user, {
      data: user,
    }),
  });
};

const handleSharedSelection: ActionHandler<UserReducer, typeof sharedSelectionSuccess> = (state, action) => {
  return update(state, {
    accessToken: action.payload.token,
    isLoggedIn: false,
    user: {
      data: {
        access: 'viewer',
      },
    },
  });
};

const handleSetTokenFromLookbook: ActionHandler<UserReducer, typeof setLookbookToken> = (state, action) => {
  return update(state, {
    accessToken: action.payload.token,
    isLoggedIn: false,
    user: {
      data: {
        access: 'viewer',
      },
    },
  });
};

export default createReducer<UserReducer, AppAction>(initialState)
  .handleAction(fetchLoginSuccess, handleSetLoginSuccess)
  .handleAction(authoriseAccessRequest, handleAuthoriseAccessRequest)
  .handleAction(authoriseAccessSuccess, handleAuthoriseAccessSuccess)
  .handleAction(logoutRequest, handleLogoutRequest)
  .handleAction(resetPasswordDefault, handleResetPasswordDefault)
  .handleAction(resetPasswordRequest, handleResetPasswordRequest)
  .handleAction(resetPasswordSuccess, handleResetPasswordSuccess)
  .handleAction(resetPasswordFailure, handleResetPasswordFailure)
  .handleAction(changePasswordDefault, handleSetChangePasswordDefault)
  .handleAction(changePasswordRequest, handleSetChangePasswordRequest)
  .handleAction(changePasswordSuccess, handleSetChangePasswordSuccess)
  .handleAction(resetEditBuyerData, handleResetUpdateBuyersData)
  .handleAction(updateBuyersDataFailure, handleUpdateBuyersDataFailure)
  .handleAction(updateBuyersDataRequest, handleUpdateBuyersDataRequest)
  .handleAction(updateBuyersDataSuccess, handleUpdateBuyersDataSuccess)
  .handleAction(changePasswordFailure, handleSetChangePasswordFailure)
  .handleAction(changeAccountPasswordRequest, handleSetChangeAccountPasswordRequest)
  .handleAction(resetChangeAccountPassword, handleResetChangeAccountPassword)
  .handleAction(changeAccountPasswordSuccess, handleSetChangeAccountPasswordSuccess)
  .handleAction(createSelectionSuccess, handleSetCreateSelectionSuccess)
  .handleAction(fetchUserSuccess, handleFetchUserSuccess)
  .handleAction(setUserAsViewer, handleSetUserAsViewer)
  .handleAction(setLookbookToken, handleSetTokenFromLookbook)
  .handleAction(sharedSelectionSuccess, handleSharedSelection)
  .handleAction(changeAccountPasswordFailure, handleSetChangeAccountPasswordFailure);
