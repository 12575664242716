import { Picture, Product } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getAreBackordersEnabled } from '../../../../ducks';
import { getDefaultImageAspectRatio } from '../../../../ducks/helpers';
import { isDefined } from '../../../../utils/is';
import { isEmpty } from '../../../../utils/isEmpty';
import { loadImage } from '../../../../utils/loadImage';
import { CustomImage } from '../../../various/CustomImage';
import Icon, { IconType } from '../../../various/Icon';
import { ProductBadges } from '../../ProductBadges';
import { useProductContext } from '../context/ProductContext';
import { ProductButtons } from '../ProductButtons';

import styles from './GallerySlide.module.scss';

interface Props {
  image: Picture;
  isActive: boolean;
  isAvailable: boolean;
  variant: Product.Standard;
}

export const GallerySlide = ({ image, isActive, isAvailable, variant }: Props) => {
  const hasImage = isDefined(image.src) && !isEmpty(image.src);

  const { t } = useTranslation(['products']);
  const { productPathName, shouldHideButtons, hasAvailableVariants } = useProductContext();
  const [isLoading, setIsLoading] = React.useState(false);
  const [hasLoaded, setHasLoaded] = React.useState(!hasImage);

  const imageAspectRatio = useSelector(getDefaultImageAspectRatio);
  const areBackordersEnabled = useSelector(getAreBackordersEnabled);

  React.useEffect(() => {
    if (!isActive || hasLoaded) {
      return;
    }

    setIsLoading(true);

    const handleLoad = () => {
      setIsLoading(false);
      setHasLoaded(true);
      loadImageSubscription.unsubscribe();
    };

    const loadImageSubscription = loadImage(image.src).subscribe({
      complete: handleLoad,
      error: handleLoad,
    });
  }, [hasLoaded, image.src, isActive]);

  const placeholderClassName = cx(styles.imageWrapper, {
    [styles.loading]: isLoading,
  });

  const spinnerWrapperClassNames = cx(styles.spinnerWrapper, {
    [styles.loading]: isLoading,
  });

  const errorStyle = cx(styles.thumbnailError, {
    [styles.hidden]: hasImage,
  });

  const imageSrc = hasLoaded ? image.src : undefined;

  const canAddToSelection = isAvailable || areBackordersEnabled;
  const shouldShowProductButtons = !shouldHideButtons && canAddToSelection;
  const shouldShowAvailabilityBanner = !shouldHideButtons && !canAddToSelection && hasAvailableVariants;

  return (
    <div className={styles.slide}>
      <Link to={productPathName} tabIndex={-1}>
        <div className={spinnerWrapperClassNames}>{isLoading && <Icon className={styles.spinner} type={IconType.Spinner} />}</div>
        <CustomImage src={imageSrc} aspectRatio={imageAspectRatio} placeholderClassName={placeholderClassName} errorStyle={errorStyle} />
      </Link>
      <div className={styles.badgesContainer}>
        <ProductBadges hasAvailableVariants={hasAvailableVariants} variant={variant} />
      </div>
      {shouldShowProductButtons && (
        <div className={styles.buttonsWrapper}>
          <ProductButtons isActive={isActive} productId={variant.product} />
        </div>
      )}
      {shouldShowAvailabilityBanner && <div className={styles.availabilityBanner}>{t('products:variant_unavailable')}</div>}
    </div>
  );
};
