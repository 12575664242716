import { yupResolver } from '@hookform/resolvers/yup';
import { DefaultValues, FieldValues, Mode, useForm } from 'react-hook-form';
import { AnyObjectSchema } from 'yup';

interface Props<T> {
  defaultValues?: DefaultValues<T>;
  mode?: Mode;
  validationSchema: AnyObjectSchema;
}

export const useValidatedForm = <T extends FieldValues>({ defaultValues, mode = 'onTouched', validationSchema }: Props<T>) => {
  return useForm<T>({
    defaultValues,
    mode,
    resolver: yupResolver(validationSchema),
  });
};
