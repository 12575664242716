import React from 'react';

export const getChildrenByComponent = (children: React.ReactNode, component: React.FC): React.ReactElement[] => {
  return React.Children.toArray(children).reduce((acc: React.ReactElement[], child) => {
    if (!React.isValidElement(child)) {
      return acc;
    }

    if (child.type === React.Fragment) {
      return [...acc, ...getChildrenByComponent(child.props.children, component)];
    }

    if (typeof child.type !== 'string' && child.type === component) {
      return [...acc, child];
    }

    return acc;
  }, []) as React.ReactElement[];
};
