import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

import { selectionImportRequest } from '../../../ducks';
import { useModalVisibility } from '../../../utils/hooks';
import { useValidatedForm } from '../../../utils/hooks/useValidatedForm';
import { isDefined } from '../../../utils/is';
import { RadioGroup } from '../../various/Fields/RadioGroup';
import { FilePickerField, Form, FormButtons, FormGrid } from '../../various/Form';
import { Button } from '../../various/NewButton';

import styles from './SelectionImportModal.module.scss';

type AddToOrder = 'true' | 'false';

interface FormFields {
  addToOrder: AddToOrder;
  file: Nullable<File>;
}

const defaultValues: FormFields = {
  addToOrder: 'true',
  file: null,
};

export const SelectionImportForm = () => {
  const { t } = useTranslation(['common', 'selections', 'validation']);
  const dispatch = useDispatch();
  const { hideModal } = useModalVisibility('SelectionImportModal');

  const handleSubmit = ({ addToOrder, file }: FormFields) => {
    if (!isDefined(file)) {
      return;
    }

    const formData = new FormData();

    formData.append('importFile', file);
    formData.append('addToOrder', addToOrder);

    dispatch(selectionImportRequest(formData));
  };

  const validationSchema: yup.ObjectSchema<FormFields> = yup.object({
    addToOrder: yup.string<AddToOrder>().required(),
    file: yup.mixed<File>().required(t('validation:file_hint')),
  });

  const formMethods = useValidatedForm<FormFields>({ defaultValues, validationSchema });
  const { control } = formMethods;

  const addToOrderOptions = [
    {
      name: t('selections:import_selection_config.options.add'),
      value: 'true',
    },
    {
      name: t('selections:import_selection_config.options.override'),
      value: 'false',
    },
  ];

  return (
    <>
      <div className={styles.content}>
        {t('selections:import_selection.description')}
        {t('selections:import_selection.columns', { returnObjects: true }).map((item, index) => (
          <React.Fragment key={index}>
            {index !== 0 && t('common:or').toUpperCase()}
            <ul>
              <li>{item}</li>
            </ul>
          </React.Fragment>
        ))}
        {t('selections:import_selection.hint')}
      </div>
      <Form formMethods={formMethods} onSubmit={handleSubmit}>
        <FormGrid>
          <FilePickerField name="file" label={`${t('common:csv_file')}:`} size="mini" isRequired accept={['text/csv']} />
          <div className={styles.content}>
            <span id="addToOrderQuestion">{t('selections:import_selection_config.description')}</span>
            <Controller
              control={control}
              name="addToOrder"
              render={({ field }) => (
                <RadioGroup
                  size="small"
                  name="addToOrder"
                  labelledById="addToOrderQuestion"
                  options={addToOrderOptions}
                  value={field.value}
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                />
              )}
            />
          </div>
        </FormGrid>
        <FormButtons showDivider>
          <Button size="large" variant="ghost" color="dark" onClick={hideModal}>
            {t('common:cancel')}
          </Button>
          <Button type="submit" size="large">
            {t('common:import')}
          </Button>
        </FormButtons>
      </Form>
    </>
  );
};
