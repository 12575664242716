import { Id, Product } from '@typings';

import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import Icon, { IconType } from '../../various/Icon';
import { MissingProduct } from '../ProductsList/MissingProduct';
import { ProductRow } from '../ProductsList/ProductRow';

import styles from './HotspotEditor.module.scss';

interface Props {
  handleSetProduct: (productId: string | null) => () => void;
  productInfo: Product.CmsProduct | null;
  imageSize: string;
  selectedId: Id | null;
}

export const SelectedProduct = ({ handleSetProduct, productInfo, imageSize, selectedId }: Props) => {
  const removeButton = (
    <button className={styles.actionButton} onClick={handleSetProduct(null)} data-testid="deselectProduct">
      <Icon type={IconType.Trash} />
    </button>
  );

  if (!isDefined(productInfo) && !isDefined(selectedId)) {
    return null;
  }

  return (
    <div className={styles.selectedProduct}>
      {isDefined(productInfo) ?
        <ProductRow
          key={productInfo.product}
          name={productInfo.name}
          sku={productInfo.productSku}
          thumbnail={isEmpty(productInfo.media) ? undefined : productInfo.media[imageSize]?.[0]}
          actionAlwaysVisible
        >
          {removeButton}
        </ProductRow>
      : <MissingProduct actionAlwaysVisible id={selectedId} action={removeButton} />}
    </div>
  );
};
