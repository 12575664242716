/* eslint-disable max-len */

import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;
const ORIGINAL_HEIGHT = 20;
const ORIGINAL_COLOR = '#848484';
const PROPORTION = ORIGINAL_HEIGHT / ORIGINAL_WIDTH;

export const Download = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => (
  <svg width={width} height={width * PROPORTION} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.24 9.05889L10.7778 10.5211V3.77778C10.7778 3.35 10.4278 3 10 3C9.57227 3 9.22227 3.35 9.22227 3.77778V10.5211L7.75227 9.05889C7.44894 8.75556 6.95894 8.75556 6.6556 9.05889C6.50996 9.2042 6.42812 9.40149 6.42812 9.60722C6.42812 9.81296 6.50996 10.0102 6.6556 10.1556L9.44783 12.9478C9.75116 13.2511 10.2412 13.2511 10.5445 12.9478L13.3367 10.1556C13.64 9.85222 13.64 9.36222 13.3367 9.05889C13.0334 8.75556 12.5434 8.75556 12.24 9.05889ZM15.4444 14.6667V10.7778C15.4444 10.35 15.7944 10 16.2222 10C16.65 10 17 10.35 17 10.7778V15.4444C17 16.3 16.3 17 15.4444 17H4.55556C3.7 17 3 16.3 3 15.4444V10.7778C3 10.35 3.35 10 3.77778 10C4.20556 10 4.55556 10.35 4.55556 10.7778V14.6667C4.55556 15.0944 4.90556 15.4444 5.33333 15.4444H14.6667C15.0944 15.4444 15.4444 15.0944 15.4444 14.6667Z"
      fill={color}
    />
  </svg>
);
