import React from 'react';
import { Navigate } from 'react-router-dom';

import { paths } from '../paths';
import { getToken } from '../utils/auth';
import { isDefined } from '../utils/is';

interface Props {
  children: React.ReactNode;
}

export const UnauthenticatedPath = ({ children }: Props) => {
  const token = getToken();

  if (isDefined(token)) {
    return <Navigate to={paths.ROOT} />;
  }

  return children;
};
