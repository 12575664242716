import React from 'react';

import { isDefined } from '../is';

interface Options {
  element: Nullable<HTMLElement>;
  handler: (event: MouseEvent) => void;
  enabled?: boolean;
  omit?: Nullable<HTMLElement>[];
}

export const useClickOutside = ({ element, handler, enabled = true, omit }: Options) => {
  React.useEffect(() => {
    const clickHandler = (event: MouseEvent) => {
      const target = event.target as Node;
      const isTargetInElement = isDefined(element) && element.contains(target);
      const isTargetInOmitedElement = isDefined(omit) && omit.some(omitElement => omitElement?.contains(target));

      if (isTargetInElement || isTargetInOmitedElement) {
        return;
      }

      handler(event);
    };

    if (enabled) {
      document.addEventListener('mousedown', clickHandler);
    }

    return () => {
      document.removeEventListener('mousedown', clickHandler);
    };
  }, [element, handler, enabled]);
};
