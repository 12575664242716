import React from 'react';
import { useSelector } from 'react-redux';

import { getCheckoutSplitData, getOrderDetails } from '../../ducks';
import { getIsOrderEditable } from '../../logic/Orders';
import { isDefined } from '../is';
import { isEmpty } from '../isEmpty';

export const useIsViewingOrder = () => {
  const orderDetails = useSelector(getOrderDetails);
  const orderSplitDetails = useSelector(getCheckoutSplitData);
  const isSplitOrder = !isEmpty(orderSplitDetails.orders);

  return React.useMemo(() => {
    const canModify = isDefined(orderDetails.order.canModify) && orderDetails.order.canModify;
    const isOrderClosed = isDefined(orderDetails.order.status) && !getIsOrderEditable(orderDetails.order.status);

    return isSplitOrder || isOrderClosed || !canModify;
  }, [isSplitOrder, orderDetails]);
};
