import { DndContext, DragEndEvent, PointerSensor, UniqueIdentifier, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, SortingStrategy, verticalListSortingStrategy } from '@dnd-kit/sortable';
import cx from 'classnames';

import { isDefined } from '../../../../utils/is';

import { SortableItem } from './components/SortableItem';
import styles from './SortableList.module.scss';

interface Props {
  items: UniqueIdentifier[];
  renderItem: (property: UniqueIdentifier) => JSX.Element | null;
  reorderItems?: typeof arrayMove;
  handleSortEnd?: (sortedArray: UniqueIdentifier[]) => void;
  isSortDisabled?: boolean;
  className?: string;
  strategy?: SortingStrategy;
}

export const SortableList = ({
  items,
  handleSortEnd,
  renderItem,
  isSortDisabled = false,
  className,
  strategy = verticalListSortingStrategy,
}: Props) => {
  const sensors = useSensors(useSensor(PointerSensor));

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (!isDefined(over) || active.id === over.id) {
      return;
    }
    const activeIndex = items.indexOf(active.id);
    const overIndex = items.indexOf(over.id);

    handleSortEnd && handleSortEnd(arrayMove(items, activeIndex, overIndex));
  };

  return (
    <DndContext sensors={sensors} onDragEnd={handleDragEnd} modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}>
      <div className={cx(styles.sortableList, className)}>
        <ul className={styles.wrapper}>
          <SortableContext items={items} disabled={isSortDisabled} strategy={strategy}>
            {items.map(item => (
              <SortableItem
                key={item}
                id={item}
                renderItem={renderItem}
                isHandleDisabled={isSortDisabled}
                showHandle={isDefined(handleSortEnd)}
              />
            ))}
          </SortableContext>
        </ul>
      </div>
    </DndContext>
  );
};
