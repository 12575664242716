/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 14;
const ORIGINAL_HEIGHT = 18;
const PROPORTION = ORIGINAL_HEIGHT / ORIGINAL_WIDTH;

export const OrderDetails = ({ width = ORIGINAL_WIDTH }: IconComponentProps) => {
  return (
    <svg width={width} height={width * PROPORTION} viewBox="0 0 14 18">
      <path
        d="m13.5 5.40203-4.99474-4.90203-5.29693.000001c-1.49577 0-2.70833 1.189239-2.70833 2.656249l.000001 11.68755c0 1.467 1.212559 2.6562 2.708329 2.6562h7.58337c1.4957 0 2.7083-1.1892 2.7083-2.6562zm-10.29167-3.30828h4.29167v2.90625s0 1.5 1.5 1.5h2.875v8.3438c0 .5868-.485 1.0624-1.0833 1.0624h-7.58337c-.5983 0-1.08333-.4756-1.08333-1.0624v-11.68755c0-.5868.48502-1.0625 1.08333-1.0625z"
        fill="#5d5d5d"
      />
    </svg>
  );
};
