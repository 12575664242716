import { Cms } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { setCurrentCustomTemplateId } from '../../../ducks';
import { isCustomTemplate } from '../../../logic/cms/customLayouts';
import { handleSpaceAndEnter } from '../../../utils/handleEnterKey';
import { useModalVisibility } from '../../../utils/hooks';
import { useCustomTemplates } from '../../../utils/hooks/cms/useCustomTemplates';
import { isDefined } from '../../../utils/is';
import Icon, { IconColor, IconType } from '../../various/Icon';
import { Button } from '../../various/NewButton';
import { TextEllipsis } from '../../various/TextEllipsis';
import { BlockTemplate } from '../BlockTemplate';

import styles from './TemplateModal.module.scss';

interface Props {
  templateModel: Cms.BlockModel[] | Cms.CustomTemplate;
  handleClickTemplate: (_: Cms.BlockModel[]) => void;
  id: string;
}

export const TemplateThumbnail = ({ templateModel, handleClickTemplate, id }: Props) => {
  const { t } = useTranslation(['common']);
  const isCustom = isCustomTemplate(templateModel);
  const thumbnailRef = React.useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = React.useState(false);
  const [isExpanded, setIsExpanded] = React.useState(false);

  const handleOnClick = React.useCallback(
    () => handleClickTemplate(isCustom ? templateModel.templateBlocks : templateModel),
    [templateModel, handleClickTemplate, isCustom],
  );

  const { removeCustomTemplate } = useCustomTemplates();
  const dispatch = useDispatch();
  const { showModal } = useModalVisibility('CustomTemplateEditorModal');

  const handleRemoveLayout = () => {
    removeCustomTemplate(id);
  };

  const handleEditLayout = () => {
    dispatch(setCurrentCustomTemplateId(id));
    showModal();
  };
  const templateBlocks = isCustom ? templateModel.templateBlocks : templateModel;

  React.useEffect(() => {
    const node = thumbnailRef.current;
    if (!isDefined(node) || node.offsetHeight === 0) {
      return;
    }

    setIsOverflowing(node.scrollHeight > node.offsetHeight);
  }, [templateModel]);

  return (
    <div className={styles.templateContainer}>
      {isCustom && (
        <div className={styles.templateHeader}>
          <TextEllipsis className={styles.templateName}>{templateModel.name}</TextEllipsis>
          <div className={styles.templateButtons}>
            <Button
              className={styles.deleteButton}
              name="Delete"
              variant="flat"
              color="dark"
              onClick={handleRemoveLayout}
              icon={IconType.TrashLinear}
              size="mini"
            />
            <Button onClick={handleEditLayout} name="Edit" variant="flat" color="dark" icon={IconType.EditLinear} size="mini" />
          </div>
        </div>
      )}
      <div
        tabIndex={-1}
        role="button"
        data-testid={id}
        className={styles.templateButton}
        onClick={handleOnClick}
        onKeyDown={handleSpaceAndEnter(handleOnClick)}
      >
        <div className={cx(styles.templateThumbnailWrapper, { [styles.isExpanded]: isExpanded, [styles.isOverflowing]: isOverflowing })}>
          <div ref={thumbnailRef} className={cx(styles.templateThumbnail)}>
            {templateBlocks.map((block, idx) => (
              <BlockTemplate key={idx} templateModel={block} />
            ))}
          </div>
          {(isOverflowing || isExpanded) && (
            <button
              className={styles.showMore}
              onClick={event => {
                event.stopPropagation();
                setIsExpanded(value => !value);
              }}
            >
              {isExpanded ? t('common:show_less') : t('common:show_more')}
              <Icon type={IconType.Chevron} color={IconColor.Dark} size={12} className={styles.showMoreChevron} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
