import { Features } from '@typings';

export const customLayoutsOnboarding: Features.Onboarding = {
  id: 'custom-layouts-feature',
  nameI18nKey: 'onboarding:custom_layouts_feature.name',
  pages: [
    {
      descriptionI18nKey: 'onboarding:custom_layouts_feature.page_1.description',
      imageUrl: '/onboardings/customLayouts/1.webp',
      titleI18nKey: 'onboarding:custom_layouts_feature.page_1.title',
    },
    {
      descriptionI18nKey: 'onboarding:custom_layouts_feature.page_2.description',
      imageUrl: '/onboardings/customLayouts/2.webp',
      titleI18nKey: 'onboarding:custom_layouts_feature.page_2.title',
    },
    {
      descriptionI18nKey: 'onboarding:custom_layouts_feature.page_3.description',
      imageUrl: '/onboardings/customLayouts/3.webp',
      titleI18nKey: 'onboarding:custom_layouts_feature.page_3.title',
    },
  ],
};

export const buttonsFeatureOnboarding: Features.Onboarding = {
  id: 'buttons-feature',
  nameI18nKey: 'onboarding:buttons_feature.name',
  pages: [
    {
      descriptionI18nKey: 'onboarding:buttons_feature.page_1.description',
      imageUrl: '/onboardings/buttons/1.jpg',
      titleI18nKey: 'onboarding:buttons_feature.page_1.title',
    },
    {
      descriptionI18nKey: 'onboarding:buttons_feature.page_2.description',
      imageUrl: '/onboardings/buttons/2.jpg',
      titleI18nKey: 'onboarding:buttons_feature.page_2.title',
    },
    {
      descriptionI18nKey: 'onboarding:buttons_feature.page_3.description',
      imageUrl: '/onboardings/buttons/3.jpg',
      titleI18nKey: 'onboarding:buttons_feature.page_3.title',
    },
    {
      descriptionI18nKey: 'onboarding:buttons_feature.page_4.description',
      imageUrl: '/onboardings/buttons/4.jpg',
      titleI18nKey: 'onboarding:buttons_feature.page_4.title',
    },
  ],
};
