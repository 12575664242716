import { Addresses, Buyer, Category, Checkout, Currency, DeliveryWindow, Invoice, Order, Product } from '@typings';
import { path, uniq } from 'ramda';
import { createSelector } from 'reselect';
import { Option } from 'space-lift';

import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants/empty';
import { getProductByProductIdVariantIdFromProducts } from '../../logic/Orders';
import { isDefined } from '../../utils/is';
import { isEmpty } from '../../utils/isEmpty';
import { isMainCategory } from '../helpers/getFlattenedField';

import { initialOrderData } from './reducers';

export const getOrderDetails = (state: Store) => state.order.order.data;

const getOrderAccount = createSelector(getOrderDetails, (order: Order.Single) => order.account);

export const getOpenOrClosedOrder = (state: Store) => state.order.order.data.order;

export const getBuyerFromCurrentOrder = (state: Store): Buyer.Extended => state.order.order.data.buyer;

export const getBuyerIdFromCurrentOrder = (state: Store): string => state.order.order.data.buyer.buyer;

export const getIsLoggedInAsThisBuyer = (state: Store) => state.order.order.data.buyer.loggedInAsThisBuyer;

export const getCategories = (state: Store): Category[] => state.order.categories.data;

export const getTopLevelCategories = createSelector(getCategories, (categories: Category[]) => categories.filter(isMainCategory));

export const getTermsText = (state: Store): string => state.order.termsText;

export const getInitialSelectedShippingAddressId = (state: Store) => state.order.initialSelectedShippingAddressId;

export const getBillingAddress = createSelector(getOrderAccount, (account: Order.Account | undefined): Empty.Object | Addresses.Billing =>
  !isDefined(account) ? {} : account.billingAddress,
);

export const getFailedProducts = (state: Store) => {
  return state.order.failedProducts;
};

export const getOrderAccountId = (state: Store) => state.order.order?.data?.account?.account;

export const getInvoices = (state: Store): Invoice.Standard[] => state.order.invoices;

export const getIsLoadingOrderDetails = (state: Store): boolean => state.order.order.isLoading;

export const getOrderId = (state: Store): Order.Id => state.order.order.data.order.order;
export const getOrderNumber = (state: Store): string => state.order.order.data.order.orderNumber;

export const getIsOrderCheckoutSuccess = (state: Store): boolean => state.order.isCheckoutSuccess;
export const getCheckoutFormData = (state: Store) => state.order.checkoutForm;
export const getFailedOrderDetails = (state: Store): Nullable<Checkout.Failure> => state.order.failedOrder;
export const getCheckoutSplitData = (state: Store) => state.order.checkoutSplit;
export const getIsRequestingSplitOrder = (state: Store) => state.order.isRequestingSplitOrder;
export const getSplitOrderOriginalId = (state: Store) => state.order.checkoutSplit.originalOrderId;

export const getOrderDeliveryWindowsIds = (state: Store): DeliveryWindow.Id[] => {
  return Option<DeliveryWindow.Id[]>(path(['order', 'order', 'data', 'order', 'deliveryWindows'], state)).getOrElse([]);
};

export const getOrderCurrency = (state: Store): Currency => {
  return state.order.order.data.currency;
};

export const getOrderProducts = (state: Store): Order.Product[] => {
  return state.order.order.data.order.products;
};

export const getOrderProductIds = createSelector(getOrderProducts, products => products.map(product => product.product));

export const getOrderStatus = (state: Store) => {
  return state.order.order.data.order.status;
};

export const getCancelledOrderProducts = (state: Store): Order.Product[] => {
  return state.order.order.data.order.cancelledProducts ?? EMPTY_ARRAY;
};

export const getOrderProductsFilteredBy =
  (cancelled?: boolean) =>
  (state: Store): Order.Product[] => {
    return cancelled ? getCancelledOrderProducts(state) : getOrderProducts(state);
  };

const getProductVariants = (product: Product.Standard): Product.Standard[] => {
  const { relatedProducts } = product;
  if (!isDefined(relatedProducts) || Array.isArray(relatedProducts)) {
    return [];
  }

  return relatedProducts.variants;
};

export const getOrderVariants = createSelector(getOrderDetails, (orderDetails): Product.Standard[] => {
  return Object.values(orderDetails.products)
    .reduce((acc: Product.Standard[], product) => [...acc, product, ...getProductVariants(product)], [])
    .filter(item => orderDetails.order.products.find(({ variant }) => item.variant === variant));
});

export const getOrderedProductByDeliveryWindow = (product: string | undefined, variant: string | undefined, deliveryWindowId: string) =>
  createSelector(getOrderProducts, products => {
    if (!isDefined(product) || !isDefined(variant)) {
      return;
    }

    const orderedProducts = products.filter(({ deliveryWindow }) => deliveryWindow === deliveryWindowId);

    return getProductByProductIdVariantIdFromProducts(product, variant, orderedProducts);
  });

const getAllOptimisticUpdates = (state: Store) => state.order.optimisticUpdates;

export const getOptimisticUpdatesForProduct =
  (productId: Product.Id | undefined) =>
  (state: Store): Record<string, number> => {
    if (!isDefined(productId)) {
      return EMPTY_OBJECT;
    }

    return getAllOptimisticUpdates(state)[productId] ?? EMPTY_OBJECT;
  };

export const getOptimisticUpdatesMultipleProducts = (productIds: Product.Id[]) =>
  createSelector(getAllOptimisticUpdates, allOptimisticUpdates =>
    productIds.reduce<Record<string, number>>(
      (acc, cur) => ({
        ...acc,
        ...allOptimisticUpdates[cur],
      }),
      {},
    ),
  );

export const getOptimisticUpdatesDeliveryWindowIds = createSelector(getAllOptimisticUpdates, allOptimisticUpdates => {
  const optimisticUpdateValues = Object.values(allOptimisticUpdates);
  const deliveryWindowIds = optimisticUpdateValues.flatMap(update => Object.keys(update).map(key => key.split('-')[0]));

  return uniq(deliveryWindowIds);
});

export const getHasOptimisticUpdatesByProductId = (productId: Product.Id) => (state: Store) => {
  const optimisticUpdates = getOptimisticUpdatesForProduct(productId)(state);

  return isDefined(optimisticUpdates) && !isEmpty(optimisticUpdates);
};

export const getHasOptimisticAdditions = (state: Store) =>
  Object.values(state.order.optimisticUpdates).some(id => Object.values(id).some(value => value > 0));

export const getOrderDeliveryWindows = createSelector(getOrderDetails, (orderDetails): DeliveryWindow.Mixed[] => {
  return orderDetails.deliveryWindows ?? [];
});

export const getProductInfo = (state: Store, productId: string) => {
  const { products } = state.order.order.data;
  if (Array.isArray(products)) {
    throw new Error('Requested a product when there is no products in store.');
  }

  const requestedProduct = products[productId];
  if (!isDefined(requestedProduct)) {
    throw new Error('Requested a product which does not exist in the memory.');
  }

  return requestedProduct;
};

export const getIsSubmittingOrder = (state: Store): boolean => state.order.isSubmitting;

export const getIsVoucherValid = (state: Store): boolean => state.order.voucher.isValid;
export const getIsSubmittingVoucher = (state: Store): boolean => state.order.voucher.isSubmitting;

export const getIsInitialOrderData = createSelector(getOrderDetails, orderDetails => orderDetails === initialOrderData);

export const getHasOrderProducts = createSelector(getOrderProducts, products => !isEmpty(products));

export const getCanModify = (state: Store) => state.order.order.data.order.canModify;

export const getPaymentTerm = (state: Store) => state.order.order.data.order.paymentTerm;

export const getOrderPaymentMethods = (state: Store) => state.order.order.data.order.paymentMethods;

export const getIsPrepaymentEnabled = createSelector(
  getPaymentTerm,
  (paymentTerm: Maybe<Order.PaymentTerm>) => isDefined(paymentTerm) && paymentTerm.paymentMode !== 'disabled',
);

export const getIsPrepaymentRequired = createSelector(
  getPaymentTerm,
  (paymentTerm: Maybe<Order.PaymentTerm>) => isDefined(paymentTerm) && paymentTerm.paymentMode === 'required',
);

export const getActivePaymentMethod = (state: Store): Nullable<string> => state.order.paymentMethod;

export const getHasUnpaidInvoices = (state: Store) => state.order.hasUnpaidInvoices;

export const getShouldBlockIfUnpaidInvoices = (state: Store) => state.order.blockIfUnpaidInvoices;
