import cx from 'classnames';
import React from 'react';
import { CSSTransition } from 'react-transition-group';

import { useModalContext } from '../context/ModalContext';

import styles from './ModalSheet.module.scss';

interface ModalSheetProps {
  onUnmount?: () => void;
  id?: string;
  dataTestId?: string;
  className?: string;
  equalPadding?: boolean;
  contentLabel?: string;
  noHeading?: boolean;
}

export const ModalSheet = React.forwardRef<HTMLDivElement, React.WithChildren<ModalSheetProps>>(
  ({ onUnmount, id, dataTestId, className, children, equalPadding, noHeading = false, contentLabel }, ref) => {
    const { fullScreenOnMobile } = useModalContext();
    const classes = cx(
      styles.sheet,
      { [styles.equalPadding]: equalPadding, [styles.noHeading]: noHeading, [styles.fullScreen]: fullScreenOnMobile },
      className,
    );
    const { isOpen } = useModalContext();

    React.useEffect(() => {
      return () => onUnmount?.();
    }, [onUnmount]);

    return (
      <CSSTransition
        timeout={200}
        in={isOpen}
        classNames={{
          enter: styles.enter,
          enterActive: styles.enterActive,
          exit: styles.exit,
          exitActive: styles.exitActive,
        }}
        unmountOnExit
      >
        <div id={id} data-testid={dataTestId} aria-label={contentLabel} className={classes} ref={ref}>
          {children}
        </div>
      </CSSTransition>
    );
  },
);
