import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  getButtonStyleEditorMode,
  getButtonStyleElementStyles,
  getButtonStyles,
  getCustomStyleErrors,
  getIsButtonStyleEditorVisible,
  setIsHoverTabOpen,
} from '../../../../ducks/cms';
import { useStyleEditor } from '../../../../utils/hooks/cms/useStyleEditor';
import { isDefined } from '../../../../utils/is';
import { isEmpty } from '../../../../utils/isEmpty';
import { FormButtons } from '../../../various/Form';
import { Button } from '../../../various/NewButton';
import { Tabs } from '../../../various/Tabs';
import styles from '../CustomStyleEditor.module.scss';

import { ButtonStyleGeneralTab } from './ButtonStyleGeneralTab';
import { ButtonStyleHoverTab } from './ButtonStyleHoverTab';
import { ButtonStyleTypographyTab } from './ButtonStyleTypographyTab';

type EditorTab = 'general' | 'typography' | 'hover';

export const ButtonStylesEditorContent = () => {
  const dispatch = useDispatch();
  const [activeTabId, setActiveTabId] = React.useState<EditorTab>('general');

  const isEditorVisible = useSelector(getIsButtonStyleEditorVisible);
  const buttonStyle = useSelector(getButtonStyleElementStyles);
  const allButtonStyles = useSelector(getButtonStyles);
  const customStyleErrors = useSelector(getCustomStyleErrors);
  const mode = useSelector(getButtonStyleEditorMode);
  const { t } = useTranslation(['cms', 'common']);

  const {
    availableFontWeights,
    closeEditor,
    handleCreateCustomStyle,
    handleEditCustomStyle,
    isModifyingCustomStyle,
    name,
    nameError,
    onNameChange,
    setFontFamily,
    setNameError,
    setStyleProperty,
  } = useStyleEditor({
    allStyles: allButtonStyles,
    customStyle: buttonStyle,
    isEditorVisible,
    mode,
  });

  const setNameErrorAndChangeTab = React.useCallback(
    (value: Nullable<string>): void => {
      setNameError(value);
      setActiveTabId('general');
    },
    [setNameError],
  );

  React.useEffect(() => {
    setNameErrorAndChangeTab(customStyleErrors);
  }, [customStyleErrors, setNameErrorAndChangeTab]);

  React.useEffect(() => {
    dispatch(setIsHoverTabOpen(isEditorVisible && activeTabId === 'hover'));
  }, [isEditorVisible, activeTabId, dispatch]);

  const handleCloseEditor = React.useCallback(() => {
    dispatch(setIsHoverTabOpen(false));
    closeEditor();
  }, [dispatch, closeEditor]);

  const handleNameExistsFailure = React.useCallback(() => {
    setNameErrorAndChangeTab(t('cms:button_style_name_exists'));
  }, [setNameErrorAndChangeTab, t]);

  const onCreateButtonStyle = React.useCallback(() => {
    handleCreateCustomStyle({
      onNameExistsFailure: handleNameExistsFailure,
    });
  }, [handleCreateCustomStyle, handleNameExistsFailure]);

  const onEditButtonStyle = React.useCallback(() => {
    handleEditCustomStyle({
      onNameExistsFailure: handleNameExistsFailure,
    });
  }, [handleEditCustomStyle, handleNameExistsFailure]);

  const onSave = React.useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      dispatch(setIsHoverTabOpen(false));
      if (!isDefined(buttonStyle)) {
        return;
      }

      if (isEmpty(name)) {
        setNameErrorAndChangeTab(t('cms:button_style_name_is_empty'));

        return;
      }

      const action = mode === 'edit' ? onEditButtonStyle : onCreateButtonStyle;
      action();
    },
    [dispatch, buttonStyle, name, mode, setNameErrorAndChangeTab, onEditButtonStyle, onCreateButtonStyle, t],
  );

  if (!isDefined(buttonStyle)) {
    return null;
  }

  const tabs = [
    {
      content: (
        <ButtonStyleGeneralTab
          buttonStyle={buttonStyle}
          name={name}
          nameError={nameError}
          onNameChange={onNameChange}
          setStyleProperty={setStyleProperty}
          isModifyingButtonStyle={isModifyingCustomStyle}
        />
      ),
      tab: {
        id: 'general',
        label: t('cms:button_style_editor_tab_general'),
        testId: 'buttonGeneralTab',
      },
    },
    {
      content: (
        <ButtonStyleTypographyTab
          buttonStyle={buttonStyle}
          availableFontWeights={availableFontWeights}
          setFontFamily={setFontFamily}
          setStyleProperty={setStyleProperty}
          isModifyingButtonStyle={isModifyingCustomStyle}
        />
      ),
      tab: {
        id: 'typography',
        label: t('cms:button_style_editor_tab_typography'),
        testId: 'buttonTypographyTab',
      },
    },
    {
      content: <ButtonStyleHoverTab buttonStyle={buttonStyle} />,
      tab: {
        id: 'hover',
        label: t('cms:button_style_editor_tab_hover'),
        testId: 'buttonHoverTab',
      },
    },
  ];

  return (
    <>
      <p className={styles.message}>{t('cms:button_style_editor_description')}</p>
      <form onSubmit={onSave}>
        <fieldset disabled={isModifyingCustomStyle}>
          <Tabs<EditorTab>
            tabSets={tabs}
            selectedId={activeTabId}
            onChange={setActiveTabId}
            tablistName={t('cms:button_elements')}
            size="small"
          />
          <FormButtons>
            <Button disabled={isModifyingCustomStyle} variant="ghost" color="dark" onClick={handleCloseEditor}>
              {t('common:cancel')}
            </Button>
            <Button type="submit" data-testid="buttonStyleEditorSave" isLoading={isModifyingCustomStyle}>
              {t('common:save')}
            </Button>
          </FormButtons>
        </fieldset>
      </form>
    </>
  );
};
