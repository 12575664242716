import { CellContext, Updater } from '@tanstack/react-table';
import { ColumnsConfiguration } from '@typings';
import { map, prop } from 'ramda';

import { renderSentryMaskedText } from './monitoring';
export const ACTION_COLUMN_WIDTH = 64;

export const mapBooleanRecordToList = (record: Record<string, boolean>): string[] => {
  return Object.entries(record)
    .filter(([_, value]) => value)
    .map(([key, _]) => key);
};

export const mapColumnsConfigurationToVisibilityRecord = (obj: ColumnsConfiguration): Record<string, boolean> => {
  return map(prop('isVisible'), obj);
};

export const renderStandardCell = <T, V extends Nullable<string | number> | undefined>(record: CellContext<T, V>) => {
  return record.getValue() ?? '';
};

export const renderMaskedCell = <T>(record: CellContext<T, Nullable<string> | undefined>) => {
  const value = record.getValue() ?? '';

  return renderSentryMaskedText(value);
};

export const getUpdatedStateValue = <T extends object>(updaterOrValue: Updater<T>, currentValue: T) => {
  return typeof updaterOrValue === 'function' ? updaterOrValue(currentValue) : updaterOrValue;
};
