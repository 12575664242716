import { Order, Payments } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getOrderPaymentMethods } from '../../../ducks';
import { Card } from '../../various/Card';
import Heading from '../../various/Heading';
import { PaymentStatusLabel } from '../../various/StatusLabel';
import { SplitAlert } from '../SplitAlert';

import styles from './CheckoutCards.module.scss';

interface Props {
  order: Order.Open | Order.Closed;
  payment: Payments.Payment;
}

export const PaymentCard = React.memo<Props>(({ order, payment }) => {
  const { t } = useTranslation(['common', 'checkout', 'totals', 'payments']);

  const paymentMethods = useSelector(getOrderPaymentMethods) ?? [];
  const paymentMethodName = paymentMethods.find(method => method.paymentMethod === payment.paymentPluginName)?.name;

  const address = (
    <>
      <div>
        {order.billingAddress.address1} {order.billingAddress.address2}
      </div>
      <div>
        {order.billingAddress.zipCode} {order.billingAddress.city}
      </div>
    </>
  );

  return (
    <Card.Wrapper>
      <Card.Group>
        <Card.Title>
          <Heading className={styles.id} variant={['h3']} title={t('payments:prepayment')} />
          <PaymentStatusLabel status={payment.status} />
        </Card.Title>
        <Card.DescriptionList>
          <Card.Description className={Card.styles.row1col2} name={t('common:created')} value={payment.createdAt} />
          <Card.Description className={Card.styles.row2col1} name={t('common:company')} value={order.billingAddress.company} />
          <Card.Description className={Card.styles.row2col2} name={t('payments:paid_with')} value={paymentMethodName} />
          <Card.Anchor
            className={Card.styles.row3col2}
            name={t('payments:receipt')}
            linkName={t('common:preview')}
            href={payment.receiptUrl}
          />
          <Card.Description className={Card.styles.row3col1} name={t('common:address')} value={address} />
          <div className={Card.styles.row2to4col3}>
            <Card.Description
              className={cx(Card.styles.alignRight, Card.styles.verticallyCentered)}
              name={t('totals:grandtotal_with_tax')}
              value={order.totals.grandTotalPrice}
            />
            <Card.Description
              className={cx(Card.styles.alignRight, Card.styles.emphases, Card.styles.verticallyCentered)}
              name={t('totals:amount_paid')}
              value={payment.amount}
            />
          </div>
        </Card.DescriptionList>
        <SplitAlert messageType="payment" order={order} />
      </Card.Group>
    </Card.Wrapper>
  );
});
