import { InfiniteData, keepPreviousData, QueryFunctionContext, QueryKey, skipToken, useInfiniteQuery } from '@tanstack/react-query';
import { DeliveryWindow, Filter, Product } from '@typings';
import { AxiosError } from 'axios';
import { useSelector } from 'react-redux';

import { getLookbookFilters, getProductsPerRequest } from '../../../ducks';
import { getFlattenedField } from '../../../ducks/helpers';
import { filterLookbookAttributes } from '../../../logic/filters';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { fetchLookbookProducts } from '../../productsRepository';

import { useLookbookLogin } from './useLookbookLogin';

const LOOKBOOK_PRODUCTS_QUERY_KEY = 'LOOKBOOK_PRODUCTS_QUERY_KEY';

interface LookbookProductsResponse {
  products: Record<string, Product.Full>;
  totalItems: number;
  pageCount: number;
  orderedProducts: Product.Full[];
  productsOrder: string[];
  deliveryWindows: DeliveryWindow.Mixed[];
  attributes: Filter.Field<string>[];
}

export const useLookbookProducts = (lookbookId: string | undefined) => {
  const lookbookLogin = useLookbookLogin(lookbookId);
  const token = lookbookLogin.data?.token;
  const limit = useSelector(getProductsPerRequest);
  const lookbookFilters = useSelector(getLookbookFilters);

  const isEnabled = isDefined(token) && isDefined(lookbookId);

  const queryFn =
    isEnabled ?
      async ({ pageParam }: QueryFunctionContext<QueryKey, number>) => {
        return await fetchLookbookProducts(lookbookFilters, { limit, offset: (pageParam - 1) * limit }, token);
      }
    : skipToken;

  const select = (data: InfiniteData<Product.ProductsResultDTO, number>) => {
    const flattenedProducts = data.pages.reduce(
      (acc: Record<string, Product.Full>, page) => ({
        ...acc,
        ...page.products,
      }),
      {},
    );

    const productsAttributes = data.pages.flatMap(page => page.filter.map(getFlattenedField));
    const productsOrder = data.pages.flatMap(page => page.productsOrder);

    return {
      attributes: productsAttributes.filter(({ values }) => !isEmpty(values)).filter(filterLookbookAttributes),
      deliveryWindows: data.pages[0]?.deliveryWindows ?? [],
      orderedProducts: productsOrder.map(id => flattenedProducts[id]).filter(isDefined),
      pageCount: data.pages.length,
      products: flattenedProducts,
      productsOrder,
      totalItems: data.pages[0]?.productCount ?? 0,
    };
  };

  return useInfiniteQuery<Product.ProductsResultDTO, AxiosError<Responses.Errors>, LookbookProductsResponse, QueryKey, number>({
    enabled: isEnabled,
    getNextPageParam: (_, allPages) => allPages.length + 1,
    getPreviousPageParam: (_, allPages) => allPages.length - 1,
    initialPageParam: 1,
    placeholderData: keepPreviousData,
    queryFn,
    queryKey: [LOOKBOOK_PRODUCTS_QUERY_KEY, { limit, lookbookFilters, lookbookId }],
    select,
  });
};
