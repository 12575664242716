import { Cms, Id, Translations } from '@typings';
import { createSelector } from 'reselect';

import { getIsBlockLocalizable } from '../../../logic/pages';
import { isDefined } from '../../../utils/is';
import { findCmsBlockById, getCmsBlocks } from '../blocks';
import { findCmsGroupById, getCmsGroups } from '../groups';
import { getCmsSections } from '../sections';

const EMPTY_SETTINGS = { settings: {} };

export const getAllCmsLocalizations = (state: Store) => state.cms.localizations;

export const getCmsBlockLocalizationById =
  (lang: Translations.SupportedLanguagesCodes, id: Nullable<Id>) =>
  (state: Store): Cms.LocalizedBlockSettings => {
    if (!isDefined(id)) {
      return EMPTY_SETTINGS;
    }

    const allLocalizations = getAllCmsLocalizations(state);

    return allLocalizations[lang]?.blocks[id] ?? EMPTY_SETTINGS;
  };

export const getAllCmsPartsForTranslationsFile = createSelector(
  [getCmsSections, getCmsGroups, getCmsBlocks],
  (cmsSections, cmsGroups, cmsBlocks) => {
    const blocks = cmsSections
      .flatMap(section => section.groupsOrder.map(groupId => findCmsGroupById(groupId, cmsGroups)).filter(isDefined))
      .flatMap(group =>
        group.blocks
          .map(blockId => findCmsBlockById(blockId, cmsBlocks))
          .filter(isDefined)
          .filter(getIsBlockLocalizable),
      );

    return blocks
      .flatMap(block =>
        block.settings.text?.partsOrder.map(partId => {
          const part = block.settings.text?.parts[partId];

          return { blockId: block.id, part, partId };
        }),
      )
      .filter(isDefined);
  },
);
