import React from 'react';

import { isDefined } from '../is';

import { useLatest } from './useLatest';

export const useDebouncedCallback = <T extends Unrestricted[]>(
  callback: (...args: T) => void,
  wait = 300,
  deps: unknown[] = [],
): ((...args: T) => void) => {
  const latestCallback = useLatest(callback);
  const timeout = React.useRef<NodeJS.Timeout | null>(null);

  const cleanup = () => {
    if (!isDefined(timeout.current)) {
      return;
    }

    clearTimeout(timeout.current);
    timeout.current = null;
  };

  React.useEffect(
    () => () => {
      cleanup();
    },
    [wait, latestCallback],
  );

  return React.useCallback(
    (...args: T) => {
      cleanup();
      timeout.current = setTimeout(() => {
        latestCallback.current.apply(null, args);
      }, wait);
    },
    [wait, latestCallback, ...deps],
  );
};
