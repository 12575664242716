import cx from 'classnames';
import React from 'react';
import { useHover } from 'react-laag';
import { useDispatch, useSelector } from 'react-redux';

import { DEFAULT_HOVER_DELAY } from '../../../../../constants/delays';
import { getIsFilterPanelPinned, setFilterPanelPinned } from '../../../../../ducks';
import { useIsTouchScreen } from '../../../../../utils/hooks/useIsTouchScreen';
import { SkeletonLayout } from '../../../../various/SkeletonLayout';
import { FiltersPanelGroupSkeleton } from '../../../../various/SkeletonLayout/layouts/FiltersPanelGroupSkeleton';
import styles from '../FiltersPanel.module.scss';
import { FiltersPanelButtons } from '../FiltersPanelButtons';

const PANEL_CLOSE_TIMEOUT = 350;

interface Props {
  showBarcodeScanner?: boolean;
  isLoading?: boolean;
  className?: string;
}

export const FiltersPanelWrapper = ({ className, children, showBarcodeScanner = false, isLoading = false }: React.WithChildren<Props>) => {
  const dispatch = useDispatch();
  const [isClosing, setIsClosing] = React.useState(false);
  const isTouchScreen = useIsTouchScreen();

  const [isOver, hoverProps, close] = useHover({
    delayEnter: DEFAULT_HOVER_DELAY,
    delayLeave: DEFAULT_HOVER_DELAY,
    hideOnScroll: false,
  });

  const isFilterPanelPinned = useSelector(getIsFilterPanelPinned);

  const handleClosePanel = React.useCallback(() => {
    if (!isFilterPanelPinned) {
      return;
    }

    close();
    setIsClosing(true);
    setTimeout(() => setIsClosing(false), PANEL_CLOSE_TIMEOUT);
  }, [close, isFilterPanelPinned]);

  const togglePanelPinned = React.useCallback(() => {
    handleClosePanel();
    dispatch(setFilterPanelPinned(!isFilterPanelPinned));
  }, [dispatch, handleClosePanel, isFilterPanelPinned]);

  const hidePanel = React.useCallback(() => {
    handleClosePanel();

    dispatch(setFilterPanelPinned(false));
  }, [dispatch, handleClosePanel]);

  const isFilterPanelExpanded = isFilterPanelPinned || (isOver && !isTouchScreen);

  return (
    <>
      {isFilterPanelPinned && <div className={styles.filtersOverlay} onClick={hidePanel} role="presentation" />}
      <div
        {...(isFilterPanelExpanded && hoverProps)}
        className={cx(styles.filtersPanelContainer, {
          [styles.expanded]: isFilterPanelExpanded,
          [styles.pinned]: isFilterPanelPinned,
          [styles.closed]: !isFilterPanelExpanded && !isClosing,
        })}
      >
        <div
          className={cx(styles.filtersWrapper, className, {
            [styles.static]: isLoading,
          })}
        >
          <div {...(!isFilterPanelExpanded && hoverProps)} className={styles.panel}>
            <SkeletonLayout skeleton={<FiltersPanelGroupSkeleton />} isLoading={isLoading}>
              {children}
            </SkeletonLayout>
          </div>
        </div>
        <FiltersPanelButtons
          showBarcodeScanner={showBarcodeScanner}
          isFilterPanelPinned={isFilterPanelPinned}
          isFilterPanelExpanded={isFilterPanelExpanded}
          togglePanelPinned={togglePanelPinned}
        />
      </div>
    </>
  );
};
