import cx from 'classnames';
import React from 'react';

import { useResizeObserver } from '../../../../utils/hooks';
import { isDefined } from '../../../../utils/is';
import { useMatrixColumnsContext, useMatrixDistributionContext, useMatrixPrepackContext, useMatrixQuantitiesContext } from '../context';

import { ControlsHeader } from './ControlsHeader';
import styles from './MatrixOverflow.module.scss';

export const MatrixOverflow = ({ children }: React.WithChildren) => {
  const { setCanPinColumns, setPixelsToScrollEnd } = useMatrixColumnsContext();
  const { cellQuantitiesMap } = useMatrixQuantitiesContext();
  const { visiblePrepacksLength } = useMatrixPrepackContext();
  const { isDistributionPopupOpen } = useMatrixDistributionContext();

  const scrollContainerRef = React.useRef<HTMLDivElement | null>(null);

  const updateIsOverflowing = React.useCallback(() => {
    const scrollContainer = scrollContainerRef.current;

    if (!isDefined(scrollContainer)) {
      return;
    }

    setCanPinColumns(scrollContainer.scrollWidth > scrollContainer.clientWidth);
  }, [setCanPinColumns]);

  const updateHasMoreContent = React.useCallback(() => {
    const scrollContainer = scrollContainerRef.current;

    if (!isDefined(scrollContainer)) {
      return;
    }

    setPixelsToScrollEnd(scrollContainer.scrollWidth - scrollContainer.clientWidth - scrollContainer.scrollLeft);
  }, [setPixelsToScrollEnd]);

  const handleResize = React.useCallback(() => {
    updateIsOverflowing();
    updateHasMoreContent();
  }, [updateHasMoreContent, updateIsOverflowing]);

  useResizeObserver(scrollContainerRef, handleResize);

  React.useEffect(() => {
    handleResize();
  }, [handleResize, cellQuantitiesMap, visiblePrepacksLength]);

  return (
    <div className={cx(styles.matrixWrapper, { [styles.distributionPreview]: isDistributionPopupOpen })}>
      <ControlsHeader />
      <div className={styles.matrixBackground}>
        <div onScroll={updateHasMoreContent} ref={scrollContainerRef} className={styles.matrixOverflow}>
          {children}
        </div>
      </div>
    </div>
  );
};
