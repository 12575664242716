import { Id } from '@typings';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { setCurrentSection, showModal } from '../../../ducks';
import { useGroupCopyPaste } from '../../../utils/hooks/cms/useGroupCopyPaste';
import { IconType } from '../../various/Icon';
import { Button } from '../../various/NewButton';

import styles from './section.module.scss';

interface Props {
  sectionId: Id;
}

export const SectionEmptyState = ({ sectionId }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['cms', 'common']);
  const { hasGroupInClipboard, pasteCopiedGroup } = useGroupCopyPaste();

  const handleAddLayout = () => {
    dispatch(setCurrentSection(sectionId));
    dispatch(showModal('AddTemplateModal'));
  };

  const handlePasteGroup = () => {
    pasteCopiedGroup(0, sectionId);
  };

  return (
    <div className={styles.emptyState}>
      <Button
        size="mini"
        variant={hasGroupInClipboard ? 'bordered' : 'solid'}
        icon={IconType.AddSquareOutline}
        data-testid="addLayoutButton"
        onClick={handleAddLayout}
      >
        {t('cms:add_layout')}
      </Button>
      {hasGroupInClipboard && (
        <Button size="mini" icon={IconType.Paste} data-testid="pasteLayoutButton" onClick={handlePasteGroup}>
          {t('common:paste')}
        </Button>
      )}
    </div>
  );
};
