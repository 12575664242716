import { Cms } from '@typings';

import { isDefined } from '../../utils/is';

export const statusFilter = (filter: Cms.PageStatus | '') => (status: Cms.PageStatus) => {
  if (filter === '') {
    return true;
  }

  if (filter === 'published') {
    return status === 'published' || status === 'published_with_changes';
  }

  return status === filter;
};

export const pageFilter = (filter: Cms.PageStatus | '') => (page: Cms.Page) => {
  if (isDefined(page.languages)) {
    return page.languages.some(language => statusFilter(filter)(language.status));
  }

  return statusFilter(filter)(page.status);
};

export const languagesSorter = (a: Cms.Page, b: Cms.Page) => {
  if ((a.languages?.length ?? 0) > (b.languages?.length ?? 0)) {
    return 1;
  }
  if ((a.languages?.length ?? 0) < (b.languages?.length ?? 0)) {
    return -1;
  }

  if (a.languages?.every(l1 => b.languages?.some(l2 => l1.code === l2.code))) {
    return 1;
  }

  return -1;
};
