import cx from 'classnames';

import { isDefined } from '../../../../../utils/is';
import { HelpTooltip } from '../../../HelpTooltip';

import styles from './FormParts.module.scss';

interface Props {
  as?: 'label' | 'span';
  label: string;
  id?: string;
  isRequired?: boolean;
  helpMessage?: string;
  className?: string;
  size?: 'small' | 'regular' | 'large';
}

export const FieldLabel = (props: React.WithOptionalChildren<Props>) => {
  const { as: As = 'label', size = 'large', label, id, children, isRequired, helpMessage, className } = props;

  return (
    <As className={cx(styles.labelWrapper, className)}>
      <span
        id={id}
        className={cx(styles.label, {
          [styles.small]: size === 'small',
          [styles.regular]: size === 'regular',
          [styles.large]: size === 'large',
        })}
      >
        <span className={styles.labelText}>
          {isRequired && (
            <span className={styles.required} aria-hidden="true">
              *
            </span>
          )}
          {label}
        </span>
        {isDefined(helpMessage) && <HelpTooltip content={helpMessage} />}
      </span>
      {children}
    </As>
  );
};
