import { Selects } from '@typings';

import { useSelectContext } from '../../../components/various/Fields/Select/context/SelectContext';
import { isDefined } from '../../is';
import { isEmpty } from '../../isEmpty';
import { Key } from '../../keys';

interface Props {
  search: string;
  options: Selects.Option[];
  onSearchChange: (value: string) => void;
}

export const useAutocompleteKeyboardHandler = ({ search, options, onSearchChange }: Props) => {
  const { isOpen, isMultiple, open, close, selection, activePosition, focusOption, handleSelect } = useSelectContext();

  const handleArrowUpKey = (event: React.KeyboardEvent) => {
    event.preventDefault();

    if (!isOpen) {
      return open();
    }

    if (!isDefined(activePosition)) {
      return;
    }

    const newPosition = Math.max(0, activePosition - 1);
    focusOption(newPosition);
  };

  const handleArrowDownKey = (event: React.KeyboardEvent) => {
    event.preventDefault();

    if (!isOpen) {
      return open();
    }

    if (!isDefined(activePosition)) {
      return focusOption(0);
    }

    const newPosition = Math.min(options.length - 1, activePosition + 1);
    focusOption(newPosition);
  };

  const handleEnterKey = (event: React.KeyboardEvent) => {
    event.preventDefault();

    if (!isOpen) {
      return open();
    }

    const focusedValue = options[activePosition ?? -1]?.value;

    if (!isDefined(focusedValue)) {
      return;
    }

    handleSelect(focusedValue);

    if (isMultiple && !isEmpty(search)) {
      onSearchChange('');
    }
  };

  const handleTabKey = () => {
    close();
  };

  const handleEscapeKey = (event: React.KeyboardEvent) => {
    if (isOpen) {
      event.stopPropagation();
      close();
    }
  };

  const handleBackspaceKey = () => {
    if (!isEmpty(search) || !Array.isArray(selection) || isEmpty(selection)) {
      return;
    }

    const lastValue = selection[selection.length - 1];

    if (isDefined(lastValue)) {
      handleSelect(lastValue);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    const handlers: Record<string, Maybe<(event: React.KeyboardEvent) => void>> = {
      [Key.UP]: handleArrowUpKey,
      [Key.DOWN]: handleArrowDownKey,
      [Key.ENTER]: handleEnterKey,
      [Key.TAB]: handleTabKey,
      [Key.ESCAPE]: handleEscapeKey,
      [Key.BACKSPACE]: handleBackspaceKey,
    };

    handlers[event.code]?.(event);
  };

  return {
    handleKeyDown,
  };
};
