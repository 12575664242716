import { nanoid } from 'nanoid';
import { Cms } from 'typings';

export const enum ButtonStyles {
  FilledPrimary = 'cms-btn-filledPrimary',
  FilledRounded = 'cms-btn-filledRounded',
  Outlined = 'cms-btn-outlined',
}

type CommonButtonProperties =
  | 'fontFamily'
  | 'fontStyle'
  | 'justifySelf'
  | 'paddingTop'
  | 'paddingBottom'
  | 'paddingRight'
  | 'paddingLeft'
  | 'textAlign'
  | 'textTransform'
  | 'width';

const BUTTON_STYLE_COMMON: Pick<Cms.ButtonStyleProperties, CommonButtonProperties> = {
  fontFamily: 'proxima-nova',
  fontStyle: 'normal',
  justifySelf: 'center',
  paddingBottom: 12,
  paddingLeft: 25,
  paddingRight: 25,
  paddingTop: 12,
  textAlign: 'center',
  textTransform: 'uppercase',
  width: 'auto',
};

export const BUTTON_STYLE_HOVER_COMMON = {
  backgroundColor: '#80A3B0',
  borderColor: '#80A3B0',
  borderWidth: 0,
  color: '#ffffff',
  isHoverEffectEnabled: false,
};
export const DEFAULT_BUTTON_FONT_SIZE = 10;

export const buttonStylesConfig: Readonly<Record<ButtonStyles, Cms.ButtonStyle>> = {
  [ButtonStyles.FilledPrimary]: {
    className: ButtonStyles.FilledPrimary,
    id: nanoid(),
    isInternal: true,
    name: 'Filled Primary',
    properties: {
      default: {
        ...BUTTON_STYLE_COMMON,
        backgroundColor: '#80A3B0',
        borderColor: '#80A3B0',
        borderRadius: 3,
        borderWidth: 0,
        color: '#ffffff',
        fontSize: DEFAULT_BUTTON_FONT_SIZE,
        fontWeight: 500,
        letterSpacing: 0.5,
        lineHeight: 1,
        width: 'auto',
      },
      hover: {
        ...BUTTON_STYLE_HOVER_COMMON,
      },
    },
  },
  [ButtonStyles.FilledRounded]: {
    className: ButtonStyles.FilledRounded,
    id: nanoid(),
    isInternal: true,
    name: 'Filled Rounded',
    properties: {
      default: {
        ...BUTTON_STYLE_COMMON,
        backgroundColor: '#80A3B0',
        borderColor: '#80A3B0',
        borderRadius: 35,
        borderWidth: 0,
        color: '#ffffff',
        fontSize: 15,
        fontWeight: 500,
        letterSpacing: 0.25,
        lineHeight: 2,
        width: 'auto',
      },
      hover: {
        ...BUTTON_STYLE_HOVER_COMMON,
      },
    },
  },
  [ButtonStyles.Outlined]: {
    className: ButtonStyles.Outlined,
    id: nanoid(),
    isInternal: true,
    name: 'Outlined',
    properties: {
      default: {
        ...BUTTON_STYLE_COMMON,
        backgroundColor: '#ffffff',
        borderColor: '#000000',
        borderRadius: 3,
        borderWidth: 1,
        color: '#232323',
        fontSize: 10,
        fontWeight: 500,
        letterSpacing: 0.5,
        lineHeight: 1,
        width: 'auto',
      },
      hover: {
        backgroundColor: '#ffffff',
        borderColor: '#000000',
        borderWidth: 1,
        color: '#232323',
        isHoverEffectEnabled: false,
      },
    },
  },
};
