import { Product } from '@typings';
import React from 'react';
import { useSelector } from 'react-redux';

import { getDefaultImageSize, getFilteredDelwinIds } from '../../../ducks';
import { getDefaultImageAspectRatio, getProductImageOrPlaceholderBySize } from '../../../ducks/helpers';
import ProductsLogic from '../../../logic/products';
import { useDelwinIdsWithCurrentStockType } from '../../../utils/hooks';
import { isEmpty } from '../../../utils/isEmpty';
import { ProductBaseInfo } from '../../various/ProductBaseInfo';
import { ProductLineContainer } from '../../various/ProductLineContainer';
import { MatrixHelpIcon } from '../ProductMatrix/MatrixHelp';
import { ProductMatrixSectionGeneral } from '../ProductMatrix/ProductMatrixSectionGeneral';

import styles from './ProductLine.module.scss';

interface Props {
  product: Product.Full;
  showHelp?: boolean;
}

const ProductLineComponent = ({ product, showHelp }: Props) => {
  const defaultImageSize = useSelector(getDefaultImageSize);
  const aspectRatio = useSelector(getDefaultImageAspectRatio);
  const filteredDelwinIds = useSelector(getFilteredDelwinIds);
  const productImage = React.useMemo(() => getProductImageOrPlaceholderBySize(product, defaultImageSize), [product, defaultImageSize]);
  const filteredDelwinIdsWithCurrentStockTypes = useDelwinIdsWithCurrentStockType(filteredDelwinIds);
  const allDelwinIdsWithCurrentStockType = useDelwinIdsWithCurrentStockType();

  const variants = React.useMemo(() => (isEmpty(product) ? [] : ProductsLogic.getAllOrderedVariants(product)), [product]);

  const delwinToShowIds =
    isEmpty(filteredDelwinIdsWithCurrentStockTypes) ? allDelwinIdsWithCurrentStockType : filteredDelwinIdsWithCurrentStockTypes;

  const productVariants = React.useMemo(() => variants.filter(variant => variant.product === product.product), [product, variants]);

  return (
    <ProductLineContainer>
      <ProductBaseInfo
        imageAspectRatio={aspectRatio}
        imageSrc={productImage.src}
        productInfo={product}
        delwinIds={delwinToShowIds}
        showAddButton
        showBadges
      />
      {showHelp && <MatrixHelpIcon />}
      <div className={styles.row}>
        <ProductMatrixSectionGeneral variants={productVariants} attributesSource="listing" showOnlyDeliveryWindows={delwinToShowIds} />
      </div>
    </ProductLineContainer>
  );
};

export const ProductLine = React.memo(ProductLineComponent);
