import { User } from '@typings';
import { createSelector } from 'reselect';

import { is, isDefined } from '../../utils/is';
import { isEmpty } from '../../utils/isEmpty';

import { UserReducer } from './reducers';

export const getIsUserKnown = is<User>(data => 'access' in data && 'buyer' in data);

export const getUser = (state: Store): User | Empty.Object => state.user.user.data;
export const getUserAccount = (state: Store) => state.user.account;

export const getIsUserDefined = (state: Store): boolean => !isEmpty(getUser(state));

export const getIsLoadingAuthoriseAccess = (state: Store): boolean => state.user.authoriseAccess.isLoading;

export const getAccessToken = (state: Store): Nullable<string> => state.user.accessToken;

/**
 * Note: explicit checks are preferred in order to avoid situations
 * where `selectedBuyerId === 0` would give a false negative.
 */
export const getIsBuyer = (state: UserReducer) => {
  return (
    state.selectedAccountId !== undefined &&
    state.selectedAccountId !== null &&
    state.selectedBuyerId !== undefined &&
    state.selectedBuyerId !== null
  );
};

export const getIsSeller = (state: Store): boolean => 'access' in state.user.user.data && state.user.user.data.access === 'seller';

export const getCurrentBuyer = createSelector([getUser, getUserAccount], (userData, userAccount) => {
  const isUserKnown = getIsUserKnown(userData);

  return isUserKnown && isDefined(userAccount) ? userAccount.buyers[userData.buyer] ?? {} : {};
});

export function getSelectedBuyerId(state: Store): number | string | null | undefined {
  return state.user.selectedBuyerId;
}

export const getUserAccess = createSelector(getUser, user => (getIsUserKnown(user) ? user.access : 'viewer'));

export function getIsLoggedIn(state: Store): boolean {
  return state.user.isLoggedIn;
}

/**
 * Returns true if user is has administrator rights and can modify showroom settings
 */
export const getIsUserAdmin = createSelector(getUser, user => (getIsUserKnown(user) ? user.isAdmin : false));

export const getChangePassword = (state: Store) => state.user.changePassword;

export const getChangeAccountPassword = (state: Store) => state.user.changeAccountPassword;

export const getChangeBuyersData = (state: Store) => state.user.changeBuyerData;

export const getResetPassword = (state: Store) => state.user.resetPassword;
