import { combineEpics } from 'redux-observable';
import { EMPTY } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { fetchConfigurationSuccess, setPresets } from '../ducks';
import { mapResponse } from '../utils/operators/mapResponse';

const setPresetsEpic: AppEpic = (action$, _, { presetsRepository }) =>
  action$.pipe(
    filter(isActionOf(fetchConfigurationSuccess)),
    mergeMap(async () => presetsRepository.getPresets()),
    mapResponse(
      res => setPresets(res.data),
      () => EMPTY,
    ),
  );

export const presetsEpic = combineEpics(setPresetsEpic);
