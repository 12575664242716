import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;

export const Check = ({ width = ORIGINAL_WIDTH, color }: IconComponentProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width={width} height={width} fill={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.81 5.218a.742.742 0 0 1-.017 1.01L8.363 14.8a.653.653 0 0 1-.94 0l-4.216-4.286a.742.742 0 0 1-.017-1.01.653.653 0 0 1 .958-.018l3.744 3.807 7.96-8.093a.653.653 0 0 1 .958.018Z"
      />
    </svg>
  );
};
