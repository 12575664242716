import cx from 'classnames';
import React from 'react';

import styles from './Switch.module.scss';

interface Props {
  checked?: boolean;
  className?: string;
  dataTestId?: string;
  defaultChecked?: boolean;
  describedById?: string;
  disabled?: boolean;
  errorMessageId?: string;
  id?: string;
  label?: string;
  name?: string;
  onValueChange?: (checked: boolean) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  size?: 'small' | 'large';
  tabIndex?: number;
  hasFocusVisible?: boolean;
}

export const Switch = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    className,
    onValueChange,
    onChange,
    defaultChecked,
    label,
    disabled,
    checked,
    size,
    dataTestId,
    describedById,
    errorMessageId,
    hasFocusVisible = true,
    ...restProps
  } = props;

  const initialState = checked ?? defaultChecked ?? false;
  const [isChecked, setIsChecked] = React.useState(initialState);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsChecked(event.target.checked);
      onValueChange?.(event.target.checked);
      onChange?.(event);
    },
    [onValueChange, onChange],
  );

  React.useEffect(() => {
    setIsChecked(!!checked);
  }, [checked]);

  const classNames = cx(styles.wrapper, {
    [styles.checked]: isChecked,
    [styles.disabled]: disabled,
    [styles.large]: size === 'large',
  });

  return (
    <div className={classNames}>
      <input
        className={cx(styles.checkboxInput, className)}
        type="checkbox"
        onChange={handleChange}
        checked={isChecked}
        disabled={disabled}
        role="switch"
        ref={ref}
        data-testid={dataTestId}
        aria-label={label}
        aria-describedby={describedById}
        aria-errormessage={errorMessageId}
        {...restProps}
      />
      <div
        className={cx(styles.switch, {
          [styles.focusVisible]: hasFocusVisible,
        })}
      />
    </div>
  );
});
