import { Overview, Product } from '@typings';

import { getIsTopLevelRow, isVariantRow } from '../../../../logic/selectionOverview';
import { isDefined } from '../../../../utils/is';
import { isEmpty } from '../../../../utils/isEmpty';
import { MaybeText } from '../MaybeText';
import { ProductCell } from '../ProductCell';

export const renderPrimaryDimensionCellContent = ({
  record,
  variantsRecords,
  secondaryDimension,
}: {
  record: Overview.Row;
  variantsRecords: Record<string, Product.Standard>;
  secondaryDimension?: Overview.Dimension;
}) => {
  if (isVariantRow(record) && !isEmpty(variantsRecords)) {
    const product = variantsRecords[record.key];

    return isDefined(product) ? <ProductCell product={product} /> : null;
  }

  const cellText = !isVariantRow(record) ? record.primaryDimension : undefined;

  if (!getIsTopLevelRow(record) && isDefined(secondaryDimension)) {
    return cellText;
  }

  return <MaybeText text={cellText} />;
};

export const renderSecondaryDimensionCell = (text: string | undefined, record: Overview.Row) => {
  if (!getIsTopLevelRow(record) || isVariantRow(record)) {
    return null;
  }

  return <MaybeText text={text} />;
};

export const renderMaybeCell = (text: string | number | undefined, record: Overview.Row) => {
  if (isVariantRow(record)) {
    return null;
  }

  return <MaybeText text={text?.toString()} />;
};
