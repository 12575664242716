import { createColumnHelper, getCoreRowModel, getExpandedRowModel, getSortedRowModel, Row } from '@tanstack/react-table';
import { Addresses, Id } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getIsAccountEditingEnabled } from '../../../../../ducks/config';
import { useDeleteShippingAddress } from '../../../../../services/hooks/account/useDeleteShippingAddress';
import { useTable } from '../../../../../utils/hooks/table/useTable';
import { useConfirmationGuard } from '../../../../../utils/hooks/useConfirmationGuard';
import { isDefined } from '../../../../../utils/is';
import { ACTION_COLUMN_WIDTH, renderMaskedCell } from '../../../../../utils/table';
import { DataPreview } from '../../../../various/DataPreview/DataPreview';
import { PopoverMenu } from '../../../../various/PopoverMenu';
import { Table } from '../../../../various/Table';
import { ExpanderCell } from '../../../../various/Table/components/ExpanderCell';
import { TableCell } from '../../../../various/Table/components/TableCell';
import { TableRow } from '../../../../various/Table/components/TableRow';

import { ShippingAddressRow } from './ShippingAddressRow';

interface Props {
  accountId: Id;
  shippingAddresses: Addresses.Shipping[];
  isLoading?: boolean;
  onRowEdit: (shippingAddressId: string) => void;
}

const columnHelper = createColumnHelper<Addresses.Shipping>();

export const ShippingAddressesTable = ({ accountId, shippingAddresses, isLoading = false, onRowEdit }: Props) => {
  const { t } = useTranslation(['common', 'shipping', 'buyers']);
  const confirmationGuard = useConfirmationGuard();
  const isAccountEditingEnabled = useSelector(getIsAccountEditingEnabled);

  const { mutate: deleteShippingAddress } = useDeleteShippingAddress();

  const handleDeleteClick = (shippingAddressId: string) => {
    const shippingAddress = shippingAddresses.find(address => address.shippingAddress === shippingAddressId);

    if (!isDefined(shippingAddress)) {
      return;
    }

    if (shippingAddresses.length === 1) {
      // there's only one shipping address left, prevent deleting it
      confirmationGuard(() => ({
        content: t('shipping:last_address_warning'),
        title: `${t('common:warning')}!`,
        type: 'warning',
      }))();

      return;
    }

    confirmationGuard(() => ({
      content: (
        <div>
          {t('shipping:delete_warning')}
          <DataPreview
            data={shippingAddress}
            labels={[
              ['company', t('common:company')],
              ['contactPerson', t('common:contact_person')],
              ['email', t('common:email')],
              ['address1', t('common:address')],
              ['city', t('common:city')],
              ['countryName', t('common:country')],
            ]}
          />
        </div>
      ),
      onOk: () => deleteShippingAddress({ accountId, shippingAddressId }),
      title: `${t('shipping:delete')}?`,
    }))();
  };

  const columns = React.useMemo(
    () => [
      columnHelper.display({
        cell: ({ row }) => {
          return row.getCanExpand() ? <ExpanderCell onExpand={row.getToggleExpandedHandler()} expanded={row.getIsExpanded()} /> : null;
        },
        id: 'expander',
        meta: {
          width: 50,
        },
      }),
      columnHelper.accessor('contactPerson', {
        cell: renderMaskedCell,
        header: t('common:contact_person'),
      }),
      columnHelper.accessor('address1', {
        cell: renderMaskedCell,
        header: t('common:address'),
      }),
      columnHelper.accessor('city', {
        cell: renderMaskedCell,
        header: t('common:city'),
      }),
      columnHelper.accessor('countryName', {
        cell: renderMaskedCell,
        header: t('common:country'),
      }),
      ...(isAccountEditingEnabled ?
        [
          columnHelper.display({
            cell: props => (
              <PopoverMenu
                overflowContainer
                items={[
                  {
                    key: 'edit',
                    label: t('common:edit'),
                    onClick: () => onRowEdit(props.row.original.shippingAddress),
                  },
                  {
                    key: 'delete',
                    label: t('common:delete'),
                    onClick: () => handleDeleteClick(props.row.original.shippingAddress),
                  },
                ]}
                name={t('buyers:address_options')}
              />
            ),
            id: 'actions',
            meta: {
              width: ACTION_COLUMN_WIDTH,
            },
          }),
        ]
      : []),
    ],
    [t, isAccountEditingEnabled, onRowEdit],
  );

  const expandedRowRender = React.useCallback((row: Row<Addresses.Shipping>) => {
    return (
      <TableRow>
        <TableCell colSpan={row.getVisibleCells().length}>
          <ShippingAddressRow address={row.original} />
        </TableCell>
      </TableRow>
    );
  }, []);

  const table = useTable({
    columns,
    data: shippingAddresses,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getRowCanExpand: () => true,
    getSortedRowModel: getSortedRowModel(),
  });

  return <Table table={table} renderSubComponent={expandedRowRender} isLoading={isLoading} />;
};
