import React from 'react';

import { isDefined } from '../../../../utils/is';
import { getSelectedCells } from '../../../../utils/matrix';

import { useMatrixNavigationContext } from './MatrixNavigationContext';

interface Context {
  bulkValue: Nullable<number>;
  isBulkEditing: boolean;
  setBulkValue: (value: Nullable<number>) => void;
}

export const useMatrixBulkEditingContext = (): Context => {
  const context = React.useContext(MatrixBulkEditingContext);

  if (!isDefined(context)) {
    throw new Error('MatrixBulkEditingContext can not be used outside the scope of MatrixBulkEditingContextProvider');
  }

  return context;
};

export const MatrixBulkEditingContext = React.createContext<Context | null>(null);

export const MatrixBulkEditingContextProvider = (props: React.WithChildren) => {
  const { children } = props;
  const [bulkValue, setBulkValue] = React.useState<Nullable<number>>(null);
  const { selectedPosition } = useMatrixNavigationContext();

  const isBulkEditing = getSelectedCells(selectedPosition.start, selectedPosition.end).length > 1;

  return (
    <MatrixBulkEditingContext.Provider
      value={{
        bulkValue,
        isBulkEditing,
        setBulkValue,
      }}
    >
      {children}
    </MatrixBulkEditingContext.Provider>
  );
};
