import React from 'react';

export type Header = string;
export type ListItem<T> = React.ComponentType<T>;
type ListModel<T> = Map<Header, ListItem<T>>;

interface Props<T, K> {
  data: T[];
  model: ListModel<T>;
  keyProperty: K;
  className?: string;
  footer?: ListModel<{ data: T[] }>;
}

export const List = <T extends { [property in K]?: string }, K extends keyof T>(props: Props<T, K>) => {
  const { data, model, keyProperty, className, footer } = props;

  return (
    <table className={className}>
      <thead>
        <tr>
          {Array.from(model.keys()).map(header => {
            return <th key={header}>{header}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {data.map((rowData, index) => {
          return (
            <tr key={rowData[keyProperty] ?? index}>
              {Array.from(model).map(([columnName, Column]: [string, ListItem<T>]) => {
                return <Column {...rowData} key={columnName} />;
              })}
            </tr>
          );
        })}
      </tbody>
      {footer && (
        <tfoot>
          <tr>
            {Array.from(footer).map(([columnName, Column]: [string, ListItem<{ data: T[] }>]) => (
              <Column data={data} key={columnName} />
            ))}
          </tr>
        </tfoot>
      )}
    </table>
  );
};
