import { Filters, Id, Product } from '@typings';
import { equals, omit } from 'ramda';
import { createSelector } from 'reselect';

import { EMPTY_ARRAY } from '../../constants/empty';
import { isEmpty } from '../../utils/isEmpty';

import { initialProductsState } from './reducers';

export const getProductsAttributes = createSelector(
  (state: Store) => state.products?.attributes,
  attributes => attributes || EMPTY_ARRAY,
);

export const getProductFilters = (state: Store) => state.products.filters;

export const getFilteredDelwinIds = (state: Store) => {
  return getProductFilters(state).deliveryWindows;
};

export const getCurrentOrDefaultProductSortingValue = (state: Store) => {
  const currentSorting = state.products.sorting;
  const defaultSorting = state.config.data.productSorting.default;

  return currentSorting ?? defaultSorting;
};

export const getLoadingProducts = (state: Store) => state.products.loadingProducts;

export const getIsProductLoading = (productId: string) =>
  createSelector(getLoadingProducts, loadingProducts => loadingProducts.includes(productId));

export const getIsAnyProductLoading = (state: Store) => !isEmpty(getLoadingProducts(state));

const getNonEmptyFilters = (filters: Partial<Filters>) => {
  return Object.values(filters)
    .flat()
    .filter(value => !isEmpty(value));
};

export const getHasActiveFilters = (filters: Partial<Filters>) => () => {
  return !equals(getNonEmptyFilters(omit(['stockType'], filters)), getNonEmptyFilters(initialProductsState.filters));
};

export const getHasAnyActiveFilters = (state: Store) => {
  return getHasActiveFilters(state.products.filters)();
};

export const getHasLoadedInitialFilters = (state: Store) => state.products.hasLoadedInitialFilters;

export const getProducts = (state: Store): Record<string, Product.Full> => {
  return state.products.products;
};

export const getProductsArray = createSelector(getProducts, products => Object.values(products));

export const getProductIds = createSelector(getProductsArray, products => products.map(product => product.product));

export const getIsLoadingMoreProducts = (state: Store) => state.products.isLoadingMoreProducts;

export const getIsLoadingProducts = (state: Store) => state.products.isLoadingProducts;

export const getProductsPageCount = (state: Store) => state.products.pageCount;

export const getProductsOrder = (state: Store) => state.products.productsOrder;

export const getProductsTotalItems = (state: Store) => state.products.totalItems;

export const getProductsEtaDates = (state: Store) => state.products.productsEtaDates;

export const getProductDetailsById = (state: Store, id: Id) => getProducts(state)[id];

export const getStockTypeFilter = (state: Store) => {
  return getProductFilters(state).stockType;
};
export const getProductsExpandedFiltersStates = (state: Store) => state.products.expandedFilters.states;
