/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 14;

const ORIGINAL_COLOR = '#F2F2F2';

export const Hourglass = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" width={width} height={width}>
    <path
      d="M4.523 3.073V4.6l2.471 1.854 2.474-1.871v-1.51H4.523ZM6.268 7 3.97 5.276a.8.8 0 0 1-.32-.64V3a.8.8 0 0 1 .8-.8h5.09a.8.8 0 0 1 .8.8v1.621a.801.801 0 0 1-.317.636L7.72 7l2.304 1.742a.801.801 0 0 1 .318.638V11a.8.8 0 0 1-.8.8H4.45a.8.8 0 0 1-.8-.8V9.364a.8.8 0 0 1 .32-.64L6.268 7Zm.726.546 2.474 1.87v1.511H4.523V9.4l2.471-1.854Zm2.503 1.892Z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill={color}
    />
  </svg>
);
