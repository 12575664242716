import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { fetchMarketsRequest, getIsLoadingMarkets, getMarkets } from '../../../ducks';
import { getIsFolderMenuItem, getIsPageMenuItem } from '../../../logic/pages';
import { useEditNavigationItems } from '../../../services/hooks/settings/useEditNavigationItems';
import { useSettings } from '../../../services/hooks/settings/useSettings';
import { isDefined } from '../../../utils/is';
import { Alert } from '../../various/Alert';
import DefaultLoader from '../../various/loaders/DefaultLoader';
import { EditableSectionHeading } from '../../various/SectionHeading';

import { MenuSectionContextProvider, useMenuSectionContext } from './context/MenuSectionContext';
import { AddedList } from './lists/AddedList';
import { AvailableList } from './lists/AvailableList';
import styles from './MenuSection.module.scss';

const MenuSectionComponent = () => {
  const dispatch = useDispatch();
  const allMarkets = useSelector(getMarkets);
  const {
    addedMenuItems,
    hasItemWithoutMarkets,
    setAddedMenuItems,
    isInEditMode,
    isLoadingPages,
    isMarketsMismatch,
    isUnavailablePage,
    setIsInEditMode,
    setSelectedMarket,
    setSearchPhrase,
    warningsOrder,
  } = useMenuSectionContext();
  const { t } = useTranslation(['common', 'settings']);

  React.useEffect(() => {
    dispatch(fetchMarketsRequest());
  }, [dispatch]);

  const { data: settings, isPending: isLoadingSettings } = useSettings();
  const { mutate: mutateNavigationItems, isPending: isUpdatingNavigationItems } = useEditNavigationItems();

  const navigationItems = React.useMemo(() => {
    return settings?.navigationItems ?? [];
  }, [settings?.navigationItems]);

  const isLoadingMarkets = useSelector(getIsLoadingMarkets);
  const isLoadingData = isLoadingMarkets || isLoadingPages || isLoadingSettings;

  const navigationItemsWithIds = React.useMemo(
    () =>
      navigationItems.map(parentItem => {
        const { children, id: parentId } = parentItem;

        const childrenWithParentId = children?.map(child => ({
          ...child,
          markets: child.markets?.filter(marketId => allMarkets.some(({ id }) => id === marketId)) ?? child.markets,
          parentId,
        }));

        const childrenToReturn = isDefined(childrenWithParentId) ? { children: childrenWithParentId } : {};

        if (getIsFolderMenuItem(parentItem)) {
          return {
            ...parentItem,
            ...childrenToReturn,
            id: parentId,
          };
        }

        return {
          ...parentItem,
          ...childrenToReturn,
          id: parentId,
          markets: parentItem.markets?.filter(marketId => allMarkets.some(({ id }) => id === marketId)) ?? null,
        };
      }),
    [allMarkets, navigationItems],
  );

  React.useEffect(() => setAddedMenuItems(navigationItemsWithIds), [navigationItemsWithIds, setAddedMenuItems]);

  const handleSubmit = React.useCallback(() => {
    setIsInEditMode(false);
    setSelectedMarket(null);
    setSearchPhrase('');
    const items = addedMenuItems.map(item => (getIsPageMenuItem(item) ? { ...item, markets: null } : item));

    mutateNavigationItems(
      { navigationItems: items },
      {
        onError: () => {
          setIsInEditMode(true);
        },
      },
    );
  }, [addedMenuItems, mutateNavigationItems, setIsInEditMode, setSearchPhrase, setSelectedMarket]);

  const handleEnableEditing = React.useCallback(() => setIsInEditMode(true), []);

  const handleCancel = React.useCallback(() => {
    setIsInEditMode(false);
    setSelectedMarket(null);
    setSearchPhrase('');
    setAddedMenuItems(navigationItemsWithIds);
  }, [navigationItemsWithIds, setAddedMenuItems, setIsInEditMode, setSearchPhrase, setSelectedMarket]);

  const reverseWarnings = warningsOrder.missingPage > warningsOrder.missingMarket;

  return (
    <section>
      <EditableSectionHeading
        sectionName={t('settings:navigation_menu')}
        isEditing={isInEditMode}
        handleCancel={handleCancel}
        handleEnableEditing={handleEnableEditing}
        handleSubmit={handleSubmit}
        isEditingDisabled={isLoadingData || isUpdatingNavigationItems}
      />
      <>
        <p className={styles.menuManagementDescription}>{t('settings:navigation_menu_help')}</p>
        <div
          className={cx(styles.warningContainer, {
            [styles.reverse]: reverseWarnings,
          })}
        >
          {isUnavailablePage && <Alert message={t('settings:missing_page')} className={styles.warningAlert} />}
          {isMarketsMismatch && <Alert message={t('settings:missing_market_in_common')} className={styles.warningAlert} />}
          {hasItemWithoutMarkets && <Alert message={t('settings:missing_markets')} className={styles.warningAlert} />}
        </div>
        {isLoadingData ?
          <DefaultLoader />
        : <div className={styles.listsWrapper}>
            <AvailableList />
            <AddedList />
          </div>
        }
      </>
    </section>
  );
};

export const MenuSection = () => (
  <MenuSectionContextProvider>
    <MenuSectionComponent />
  </MenuSectionContextProvider>
);
