import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { getHasUnpublishedChanges, getIsPublished } from '../../../logic/pages';

import styles from './PageStatus.module.scss';

interface Props {
  status: string;
}

export const PageStatus = (props: Props) => {
  const isPublished = getIsPublished(props.status);
  const withChanges = getHasUnpublishedChanges(props.status);
  const { t } = useTranslation(['cms']);

  return (
    <span
      className={cx(styles.badge, {
        [styles.published]: isPublished,
        [styles.withChanges]: withChanges,
      })}
    >
      {t(isPublished ? 'cms:published' : 'cms:unpublished')}
      {withChanges && <span className={styles.changesMarker}>{t('cms:unpublished_changes')}</span>}
    </span>
  );
};
