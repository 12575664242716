/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 15;
const ORIGINAL_HEIGHT = 9;
const ORIGINAL_COLOR = '#848484';
const PROPORTION = ORIGINAL_HEIGHT / ORIGINAL_WIDTH;

export const Embed = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => (
  <svg width={width} height={width * PROPORTION} viewBox="0 0 15 9">
    <path
      fill={color}
      d="M10.3193 6.98804L12.6776 4.62971L10.3193 2.26178C9.94541 1.8879 9.94541 1.28393 10.3193 0.910051C10.6932 0.536168 11.2971 0.536168 11.671 0.910051L14.7196 3.94905C14.8991 4.12816 15 4.37133 15 4.62491C15 4.8785 14.8991 5.12167 14.7196 5.30078L11.671 8.33977C11.2971 8.71366 10.6932 8.71366 10.3193 8.33977C9.94541 7.96589 9.94541 7.36193 10.3193 6.98804ZM5.20593 2.2713L2.84759 4.62964L5.19634 6.99756C5.37586 7.17667 5.47674 7.41984 5.47674 7.67343C5.47674 7.92701 5.37586 8.17018 5.19634 8.34929C4.82246 8.72317 4.2185 8.72317 3.84461 8.34929L0.805618 5.3103C0.431736 4.93641 0.431736 4.33245 0.805618 3.95857L3.8542 0.919572C4.22808 0.545689 4.83205 0.545689 5.20593 0.919572C5.38544 1.09868 5.48633 1.34185 5.48633 1.59544C5.48633 1.84902 5.38544 2.09219 5.20593 2.2713Z"
    />
  </svg>
);
