import { HttpStatusCode } from 'axios';
import { from, of, timer } from 'rxjs';
import { catchError, filter, map, mergeMap, takeWhile } from 'rxjs/operators';

import { isError } from '../utils/is';

const POLLING_INTERVAL = 2000;
const MAX_RETRY_ATTEMPTS = 30;

async function pingLink(link: string): Promise<Response> {
  return fetch(link, { headers: { range: 'bytes=0-0' }, method: 'GET' });
}

const isFileAvailable = (res: Response) => res.status === HttpStatusCode.PartialContent;
const isFileBeingPrepared = (res: Response) => res.status === HttpStatusCode.NotFound;
const shouldPoll = (res: Response, index: number) => isFileBeingPrepared(res) && index < MAX_RETRY_ATTEMPTS;

export const productImagesLongPolling = (link: string) =>
  timer(0, POLLING_INTERVAL).pipe(
    mergeMap(_ => from(pingLink(link))),
    takeWhile((res, index) => shouldPoll(res, index), true),
    catchError(err => of(new Error(err))),
    filter((res, index) => isError(res) || !isFileBeingPrepared(res) || index === MAX_RETRY_ATTEMPTS),
    map(res => (!isError(res) && isFileAvailable(res) ? link : undefined)),
  );
