import { Order } from '@typings';
import { uniq } from 'ramda';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { removeProductsFromOrder } from '../../../ducks';
import { useConfirmationGuard } from '../../../utils/hooks';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { Alert } from '../../various/Alert';
import { AlertAction } from '../../various/Alert/AlertAction';
import { IconType } from '../../various/Icon';

import styles from './CancelledProductsAlert.module.scss';

interface Props {
  className?: string;
  orderProducts?: Order.Product[];
}

export const CancelledProductsAlert = ({ className, children, orderProducts = [] }: React.WithOptionalChildren<Props>) => {
  const { t } = useTranslation(['cancelled', 'confirmationConfig']);
  const dispatch = useDispatch();
  const confirmationGuard = useConfirmationGuard();

  const deliveryWindows = uniq(orderProducts.map(product => product.deliveryWindow));

  const removeProduct = confirmationGuard(() => ({
    onOk: () => {
      dispatch(
        removeProductsFromOrder({
          deliveryWindows,
          products: uniq(orderProducts.map(product => product.product)),
          variants: uniq(orderProducts.map(product => product.variant)),
        }),
      );
    },
    ...t('confirmationConfig:remove_variants_from_order', { returnObjects: true }),
  }));

  const defaultMessage = (
    <>
      <Trans t={t} i18nKey="cancelled:cancelled_products_alert">
        Some of the products in the Selection <span className={styles.boldText}>have been cancelled</span>
        and they need to be removed before the checkout.
      </Trans>
      {!isEmpty(orderProducts) && (
        <>
          {' '}
          <AlertAction onClick={removeProduct}>{t('confirmationConfig:remove_variants_from_order.title')}</AlertAction>
        </>
      )}
    </>
  );

  return <Alert type="warning" icon={IconType.Products} className={className} message={isDefined(children) ? children : defaultMessage} />;
};
