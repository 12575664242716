import { Form, Selects } from '@typings';
import React from 'react';
import { FieldPath, FieldValues, useController, useFormState } from 'react-hook-form';

import { isDefined } from '../../../../utils/is';
import { Autocomplete } from '../../Fields/Select';

import { FieldWrapper } from './FieldParts';

type AutocompleteProps = Omit<Selects.AutocompleteProps, 'isMultiple' | 'value' | 'onChange'>;

interface Props<K extends string> extends AutocompleteProps, Form.FieldProps {
  name: K;
  onChange?: (value: Selects.Selection) => void;
}

export const AutocompleteField = <T extends FieldValues, K extends FieldPath<T>>(props: Props<K>) => {
  const { children, description, helpMessage, label, name, isRequired = false, orientation, placeholder, onChange, ...rest } = props;

  const errorId = React.useId();
  const describedById = React.useId();

  const { field } = useController<T, K>({ name });
  const { errors } = useFormState({ name });

  const handleChange = (value: T[K]) => {
    field.onChange(value);
    onChange?.(value);
  };

  const errorMessage = errors[name]?.message as string | undefined;

  return (
    <FieldWrapper
      description={description}
      describedById={describedById}
      helpMessage={helpMessage}
      errorId={errorId}
      errorMessage={errorMessage}
      isRequired={isRequired}
      label={label}
      orientation={orientation}
    >
      <Autocomplete
        {...rest}
        value={field.value}
        size="large"
        name={name}
        placeholder={placeholder ?? label}
        isMultiple={Array.isArray(field.value)}
        isInvalid={isDefined(errorMessage)}
        describedById={isDefined(description) ? describedById : undefined}
        errorMessageId={isDefined(errorMessage) ? errorId : undefined}
        onBlur={field.onBlur}
        onChange={handleChange}
      >
        {children}
      </Autocomplete>
    </FieldWrapper>
  );
};
