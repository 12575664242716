import { Skeleton } from '@typings';
import cx from 'classnames';

import { isDefined } from '../../../../utils/is';
import { SkeletonBase } from '../SkeletonBase';

import styles from './TextSkeleton.module.scss';

export const TextSkeleton = ({
  containerClassName,
  gap,
  itemClassName,
  lastWidth,
  rows = 1,
  size,
  width,
  color,
}: Skeleton.TextSkeleton) => {
  const itemClassNames = cx(styles.textSkeleton, isDefined(size) && styles[size], itemClassName);
  const itemStyle = {
    width: `${width}%`,
  };

  const containerClassNames = cx(styles.textSkeletonContainer, isDefined(gap) && styles[gap], containerClassName);

  const content = Array.from({ length: rows }, (_, idx) => {
    const isLast = idx === rows - 1;
    const style = isLast ? { ...itemStyle, width: `${lastWidth ?? width}%` } : itemStyle;

    return <SkeletonBase key={idx} className={itemClassNames} style={style} color={color} />;
  });

  if (rows <= 1) {
    return content;
  }

  return <div className={containerClassNames}>{content}</div>;
};
