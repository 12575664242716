import { DeliveryWindow, Id } from '@typings';
import { uniq } from 'ramda';
import { createSelector } from 'reselect';

import { EMPTY_ARRAY } from '../../constants/empty';
import { isDeliveryWindowOrderGroup } from '../../logic/deliveryWindows';
import { selectValidStockTypeForBuyer } from '../../logic/stockType';
import { isDefined } from '../../utils/is';
import { getDefaultStockType } from '../config';
import { getBuyerIdFromCurrentOrder } from '../order';

export const getDeliveryWindowMap = (state: Store) => state.deliveryWindows.all;
export const getDeliveryWindows = (state: Store) => state.deliveryWindows;
export const getDeliveryWindowsOrder = (state: Store) => state.deliveryWindows.deliveryWindowsOrder;
export const getDeliveryWindowsGroupsIds = createSelector(getDeliveryWindowsOrder, deliveryWindowsOrder =>
  deliveryWindowsOrder.filter(isDeliveryWindowOrderGroup).map(group => group.groupId),
);
export const getDeliveryWindowsIdsByBuyerMap = (state: Store) => state.deliveryWindows.byBuyerId;
export const getDeliveryWindowById = (id: DeliveryWindow.Id) =>
  createSelector(
    (state: Store) => state.deliveryWindows,
    deliveryWindows => deliveryWindows.all[id],
  );

export const getAllDeliveryWindows = createSelector(getDeliveryWindowMap, allDeliveryWindows => Object.values(allDeliveryWindows));

export const getIsDeliveryWindowsLoading = (state: Store) => state.deliveryWindows.isLoading || state.deliveryWindows.isLoadingByBuyer;
const getDeliveryWindowsByBuyerMap = createSelector(
  getDeliveryWindowsIdsByBuyerMap,
  getDeliveryWindowMap,
  (deliveryWindowsIdsByBuyerMap, deliveryWindowsMap) =>
    Object.entries(deliveryWindowsIdsByBuyerMap).reduce<Record<string, DeliveryWindow.Mixed[]>>((acc, [buyerId, deliveryWindowsIds]) => {
      return {
        ...acc,
        [buyerId]: deliveryWindowsIds.map(deliveryWindowId => deliveryWindowsMap[deliveryWindowId]).filter(isDefined),
      };
    }, {}),
);

export const getDeliveryWindowsByBuyerId = (buyerId: Maybe<Id>) =>
  createSelector(getDeliveryWindowsByBuyerMap, deliveryWindowsByBuyerMap =>
    isDefined(buyerId) ? deliveryWindowsByBuyerMap[buyerId] ?? EMPTY_ARRAY : EMPTY_ARRAY,
  );

export const getValidStockTypesByBuyerId = (buyerId: Id) =>
  createSelector(getDeliveryWindowsByBuyerId(buyerId), buyerDelwins => uniq(buyerDelwins.map(delwin => delwin.stockType)));

export const getValidDefaultStockTypeByBuyerId = (buyerId: Id) =>
  createSelector(getValidStockTypesByBuyerId(buyerId), getDefaultStockType, (validStockTypes, defaultStockType) =>
    selectValidStockTypeForBuyer(defaultStockType, validStockTypes),
  );

export const getHasDeliveryWindowsByBuyerIdLoaded = (buyerId: Id) =>
  createSelector(getDeliveryWindowsIdsByBuyerMap, deliveryWindowsIdsByBuyerMap => isDefined(deliveryWindowsIdsByBuyerMap[buyerId]));

export const getAllDeliveryWindowsByBuyerId = (state: Store) => state.deliveryWindows.byBuyerId;

export const getActiveDeliveryWindowsIdsForOrderBuyer = createSelector(
  getBuyerIdFromCurrentOrder,
  getAllDeliveryWindowsByBuyerId,
  (buyerId, allDeliveryWindowsByBuyerId) => allDeliveryWindowsByBuyerId[buyerId] ?? EMPTY_ARRAY,
);
