import { Checkout, CheckoutForm, Order } from '@typings';

import { mapDateByDeliveryWindowToArray } from '../../logic/Orders';
import { isDefined } from '../../utils/is';
import { isEmpty } from '../../utils/isEmpty';
import { omit } from '../../utils/omit';

interface Props {
  data: CheckoutForm;
  orderDetails: Order.Single;
}

export const convertCheckoutFormData = ({ data, orderDetails }: Props): Checkout.Data => {
  const orderId = orderDetails.order.order;
  const shippingAddressData = orderDetails?.account?.shippingAddresses?.find(address => address.shippingAddress === data.shippingAddressId);
  const isShippingAddressTemporary = isDefined(shippingAddressData) && 'isTemporary' in shippingAddressData;
  const { cancelDateByDeliveryWindow, preferredShippingDateByDeliveryWindow } = mapDateByDeliveryWindowToArray(
    data.shippingDatesPreferences ?? {},
  );

  return {
    order: orderId,
    ...(isDefined(data.additionalNotes) && !isEmpty(data.additionalNotes) && { additionalNotes: data.additionalNotes }),
    ...(isDefined(data.cancelDate) && { cancelDate: data.cancelDate.format('YYYY-MM-DD') }),
    ...(isDefined(cancelDateByDeliveryWindow) && { cancelDateByDeliveryWindow }),
    ...(isDefined(data.poNumber) && !isEmpty(data.poNumber) && { poNumber: data.poNumber }),
    ...(isDefined(data.preferredShippingDate) && { preferredShippingDate: data.preferredShippingDate.format('YYYY-MM-DD') }),
    ...(isDefined(preferredShippingDateByDeliveryWindow) && { preferredShippingDateByDeliveryWindow }),
    ...(isShippingAddressTemporary && {
      shippingAddress: omit(shippingAddressData, ['isTemporary', 'shippingAddress']),
    }),
    ...(!isShippingAddressTemporary && isDefined(data.shippingAddressId) && { shippingAddressId: data.shippingAddressId }),
    termsAndConditions: data.isTermsAndConditionsChecked,
  };
};
