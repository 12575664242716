import { useGallery } from '../../../../utils/hooks/useGallery';
import { useProductContext } from '../context/ProductContext';

import styles from './Gallery.module.scss';
import { GalleryDots } from './GalleryDots';
import { GallerySlide } from './GallerySlide';

export const Gallery = () => {
  const { activeSlideIndex, gallerySlides, setActiveSlideIndex } = useProductContext();

  const { ref, handleScroll } = useGallery({
    activeIndex: activeSlideIndex,
    onChange: setActiveSlideIndex,
    orientation: 'horizontal',
  });

  return (
    <>
      <div ref={ref} className={styles.gallery} onScroll={handleScroll}>
        {gallerySlides.map((slide, index) => (
          <GallerySlide
            key={slide.id}
            isActive={activeSlideIndex === index}
            isAvailable={slide.isAvailable}
            image={slide.image}
            variant={slide.variant}
          />
        ))}
      </div>
      <GalleryDots />
    </>
  );
};
