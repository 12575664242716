import React from 'react';

import { Order } from '../../../../typings';
import { SortOrder, useOrdersSorting, usePageScrolling, useUrlPagination } from '../../utils/hooks';
import { useUrlState } from '../../utils/hooks/useUrlState';
import { isEmpty } from '../../utils/isEmpty';

export const useOrdersSearchParams = () => {
  const [page, setPage] = useUrlPagination();
  const { scrollToTopDelay } = usePageScrolling();
  const [status, setStatus] = useUrlState<Order.OrderStatus | Order.SelectionStatus>('status');
  const [search, setSearch] = useUrlState('search');
  const [sortOrder, setSortOrder] = useOrdersSorting();

  const hasFilter = !isEmpty(status) || !isEmpty(search) || page > 1;

  const handleStatusChange = React.useCallback(
    (statusValue: Order.OrderStatus | Order.SelectionStatus) => {
      setStatus(statusValue, { resetParams: ['page'] });
    },
    [setStatus],
  );

  const handleSearchChange = React.useCallback(
    (searchValue: string) => {
      setSearch(searchValue, { resetParams: ['page'] });
      scrollToTopDelay();
    },
    [scrollToTopDelay, setSearch],
  );

  const handleSortChange = React.useCallback(
    (newSorter: SortOrder) => {
      setSortOrder(newSorter);
    },
    [setSortOrder],
  );

  const handlePageChange = React.useCallback(
    (newPage: number) => {
      setPage(newPage);
      scrollToTopDelay();
    },
    [scrollToTopDelay, setPage],
  );

  return {
    handlePageChange,
    handleSearchChange,
    handleSortChange,
    handleStatusChange,
    hasFilter,
    page,
    search,
    setStatus,
    sortOrder,
    status,
  };
};
