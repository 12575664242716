/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 17;
const ORIGINAL_HEIGHT = 16;
const PROPORTION = ORIGINAL_HEIGHT / ORIGINAL_WIDTH;

export const Openbook = ({ width = ORIGINAL_WIDTH }: IconComponentProps) => {
  return (
    <svg width={width} height={width * PROPORTION} viewBox="0 0 17 16" version="1.1">
      <g id="Page-1" stroke="none">
        <g id="Landing---Drawer-Open-Copy" transform="translate(-27.000000, -293.000000)">
          <path
            d="M35.5646133,295.430751 C36.3646162,294.030746 37.4146198,293.780745 39.2479596,293.780745 L40.3646302,293.780745 L40.3646302,303.914114 L39.3146265,303.914114 C38.1812892,303.914114 36.7146174,304.080782 35.5646133,304.747451 L35.5646133,295.430751 Z M29.697926,303.914114 L29.697926,293.780745 L30.8312634,293.780745 C32.6646031,293.780745 33.6979401,294.030746 34.4979429,295.430751 L34.4979429,304.747451 C33.3479389,304.080782 31.8979338,303.914114 30.7645965,303.914114 L29.697926,303.914114 Z M42.4813043,294.847416 C42.7813054,294.847416 43.0313063,295.097417 43.0313063,295.397418 L43.0313063,306.564124 C43.0313063,306.864125 42.7813054,307.114126 42.4813043,307.114126 L36.9479515,307.114126 C36.5979503,307.730794 35.8812811,308.180796 35.0312781,308.180796 C34.1812751,308.180796 33.464606,307.730794 33.1146047,307.114126 L27.5812519,307.114126 C27.2812509,307.114126 27.03125,306.864125 27.03125,306.564124 L27.03125,295.397418 C27.03125,295.097417 27.2812509,294.847416 27.5812519,294.847416 L28.6312556,294.847416 L28.6312556,304.44745 C28.6312556,304.747451 28.8645898,304.980785 29.1645908,304.980785 L30.7645965,304.980785 C32.0146009,304.980785 33.7146068,305.197452 34.6312767,306.197456 C34.8479442,306.43079 35.2146121,306.414123 35.4312795,306.197456 C36.3479494,305.197452 38.0479554,304.980785 39.2979598,304.980785 L40.9146322,304.980785 C41.1979665,304.980785 41.4313006,304.747451 41.4313006,304.44745 L41.4313006,294.847416 L42.4813043,294.847416 Z"
            id="openbook"
          />
        </g>
      </g>
    </svg>
  );
};
