import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getBackgroundImage, getIsLoggedIn } from '../../../../../ducks';
import { isEmpty } from '../../../../../utils/isEmpty';
import { Logo, LogoType } from '../../../../various/Logo';

import styles from './LayoutImage.module.scss';

interface Props {
  className?: string;
}

export const LayoutImage = ({ className }: Props) => {
  const { t } = useTranslation(['common']);
  const isUserLoggedIn = useSelector(getIsLoggedIn);
  const backgroundImageUrl = useSelector(getBackgroundImage);

  return (
    <div className={cx(styles.wrapper, className)}>
      {!isEmpty(backgroundImageUrl) ?
        <img src={backgroundImageUrl} className={styles.image} alt="" />
      : <span className={styles.placeholder} />}
      {!isUserLoggedIn && (
        <div className={styles.poweredBy}>
          <div className={styles.poweredByLabel}>{t('common:powered_by')}</div>
          <Logo type={LogoType.Centra} className={styles.logo} size={78} />
        </div>
      )}
    </div>
  );
};
