import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';

import { paths } from '../../../paths';
import { useUrlProductId } from '../../../utils/hooks';
import { isDefined } from '../../../utils/is';
import { EtaLegend } from '../../various/Matrix/EtaLegend';

import { useMatrixDeliveryWindowContext, useMatrixEtaContext } from './context';
import { MatrixHeader } from './MatrixHeader';
import styles from './ProductMatrixSection.module.scss';

export const ProductMatrixSectionGroup = ({ children }: React.WithChildren) => {
  const { t } = useTranslation(['common']);
  const { isDeliveryWindowExpired } = useMatrixDeliveryWindowContext();
  const { containerRef, hasActiveEta, hoverProps, isEtaVisible, legendItems, setActiveEta, shouldShowEtaLegend } = useMatrixEtaContext();

  const isShowingProduct = isDefined(useUrlProductId());
  const isCheckoutPage = useMatch(paths.CHECKOUT_ORDER);
  const isMySelectionPage = useMatch(paths.ORDER_DETAILS)?.pattern.end ?? false;
  const showTooltip = isDeliveryWindowExpired && (isMySelectionPage || isShowingProduct);

  return (
    <div
      ref={containerRef}
      className={cx(styles.container, {
        [styles.expired]: isDeliveryWindowExpired && (!isCheckoutPage || isShowingProduct),
        etaHighlighted: hasActiveEta,
      })}
      {...hoverProps}
    >
      <MatrixHeader shouldShowTooltip={showTooltip} />
      {children}
      {shouldShowEtaLegend && (
        <div className={cx(styles.etaLegend, { [styles.visible]: isEtaVisible })}>
          <EtaLegend
            className={styles.etaLegendItems}
            label={t('common:arriving_on')}
            items={legendItems}
            isHoverable
            onHover={setActiveEta}
          />
        </div>
      )}
    </div>
  );
};
