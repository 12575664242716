import cx from 'classnames';

import styles from './SnapGuides.module.scss';

interface Props {
  bottom?: string;
  direction: 'vertical' | 'horizontal';
  isDashed?: boolean;
  left?: string;
  right?: string;
  top?: string;
}

export const Line = ({ direction, children, isDashed = false, bottom, left, right, top }: React.PropsWithChildren<Props>) => {
  return (
    <div
      style={{ bottom, left, right, top }}
      className={cx(styles.line, {
        [styles[direction]]: direction,
        [styles.dashed]: isDashed,
      })}
    >
      {children}
    </div>
  );
};
