/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;

export const FileImport = ({ width = ORIGINAL_WIDTH, color = 'currentColor' }: IconComponentProps): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox="0 0 20 20">
      <path
        fill={color}
        fillRule="evenodd"
        d="M3.75 3.75V10a.75.75 0 0 1-1.5 0V3.7A1.45 1.45 0 0 1 3.7 2.25h8.4a.75.75 0 0 1 .53.22l4.9 4.9c.141.14.22.331.22.53V10a.75.75 0 0 1-1.5 0V8.75H12a.75.75 0 0 1-.75-.75V3.75h-7.5Zm9 .96v2.54h2.54l-2.54-2.54ZM10 10.25a.75.75 0 0 1 .6.3l1.5 2a.75.75 0 1 1-1.2.9l-.15-.2V17a.75.75 0 0 1-1.5 0v-3.75l-.15.2a.75.75 0 1 1-1.2-.9l1.5-2a.75.75 0 0 1 .6-.3Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
