import { flexRender } from '@tanstack/react-table';
import { Tables } from '@typings';
import cx from 'classnames';

import { isDefined } from '../../../../utils/is';
import { SkeletonLayout } from '../../SkeletonLayout';
import styles from '../Table.module.scss';

import { TextSkeleton } from './Skeletons';

export const TableFooter = <T,>({ table, isLoading }: Tables.FooterProps<T>) => {
  const hasFooter = table
    .getFooterGroups()
    .some(footerGroup => footerGroup.headers.some(header => isDefined(header.column.columnDef.footer)));

  if (!hasFooter) {
    return null;
  }

  return (
    <tfoot>
      {table.getFooterGroups().map(footerGroup => (
        <tr key={footerGroup.id}>
          {footerGroup.headers
            .filter(header => isDefined(header.column.columnDef.footer))
            .map(header => {
              const { meta, footer } = header.column.columnDef;

              return (
                <td
                  className={cx(styles.footerCell, meta?.align ? styles[meta.align] : undefined, meta?.footerCellClassName)}
                  key={header.id}
                  colSpan={meta?.footerCellColspan ?? header.colSpan}
                >
                  <SkeletonLayout isLoading={isLoading} skeleton={<TextSkeleton />}>
                    {flexRender(footer, header.getContext())}
                  </SkeletonLayout>
                </td>
              );
            })}
        </tr>
      ))}
    </tfoot>
  );
};
