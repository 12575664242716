import { Position } from '@typings';
import React from 'react';

import { useMatrixNavigation } from '../../../../utils/hooks/matrix/useMatrixNavigation';

import { RegularSummaryCell } from './RegularSummaryCell';

interface Props {
  position: Position;
}

export const ColumnSummaryCell = ({ children, position }: React.WithChildren<Props>) => {
  const { isFocused, isSelected, setFocusableElement, isFocusTarget } = useMatrixNavigation(position);

  return (
    <RegularSummaryCell
      dataTestId={`matrixColumnSummaryCell-${position.x}`}
      isFocusTarget={isFocusTarget}
      isSelected={isFocused || isSelected}
      ref={setFocusableElement}
    >
      {children}
    </RegularSummaryCell>
  );
};
