export const isLocalStorageSupported: boolean = (() => {
  try {
    localStorage.setItem('test', 'passed');
    localStorage.removeItem('test');

    return true;
  } catch {
    return false;
  }
})();

function getStorage(): Storage {
  try {
    return window.localStorage;
  } catch {
    return {
      clear: () => undefined,
      getItem: () => null,
      key: () => null,
      length: 0,
      removeItem: () => undefined,
      setItem: () => undefined,
    };
  }
}

/**
 * Intercepts calls to the Local Storage API, ignoring illegal attempts to write to it.
 */
export const storage: Storage =
  'Proxy' in window ?
    new Proxy(getStorage(), {
      get(target, name): Storage[keyof Storage] {
        if (!isLocalStorageSupported && name === 'setItem') {
          // eslint-disable-next-line no-console
          console.warn('Your browser does not support Local Storage API. Application data will not be persisted.');

          return () => undefined;
        }

        const property = Reflect.get(target, name);

        return typeof property === 'function' ? property.bind(localStorage) : property;
      },
    })
  : getStorage();
