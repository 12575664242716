import React from 'react';

import { isDefined } from '../is';

type UseEventOverload<T> =
  | {
      eventName: keyof HTMLElementEventMap;
      handler: (event: Event) => void;
      element: React.RefObject<T>;
      isDisabled?: boolean;
    }
  | {
      eventName: keyof WindowEventMap;
      handler: (event: Event) => void;
      element: React.RefObject<Window>;
      isDisabled?: boolean;
    }
  | {
      eventName: keyof DocumentEventMap;
      handler: (event: Event) => void;
      element: React.RefObject<Document>;
      isDisabled?: boolean;
    };

export const useEventListener = <T extends HTMLElement>({ eventName, handler, element, isDisabled = false }: UseEventOverload<T>) => {
  const savedHandler = React.useRef<((event: Event) => void) | null>(null);

  React.useLayoutEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  React.useLayoutEffect(() => {
    const { current } = element;

    const isSupported = isDefined(current?.addEventListener);

    if (!isSupported || !isDefined(current)) {
      return;
    }

    const eventListener = (event: Event) => savedHandler.current?.(event);

    if (isDisabled) {
      current.removeEventListener(eventName, eventListener);

      return;
    }

    current.addEventListener(eventName, eventListener);

    return () => {
      if (isDefined(current)) {
        current.removeEventListener(eventName, eventListener);
      }
    };
  }, [eventName, element.current, isDisabled]);
};
