import cx from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { getDefaultImageAspectRatio } from '../../../../ducks/helpers';
import { isImageMedia, isVideoMedia } from '../../../../services/media';
import { useVideoThumbnails } from '../../../../utils/hooks/useVideoThumbnails';
import { isDefined } from '../../../../utils/is';
import { CustomImage } from '../../../various/CustomImage';
import Icon, { IconType } from '../../../various/Icon';
import { useProductDetailsVariantContext } from '../context/ProductDetailsVariantContext';

import styles from './ProductLightbox.module.scss';

const MIN_IMAGES_LENGTH = 4;

export const ProductMediaThumbnails = () => {
  const aspectRatio = useSelector(getDefaultImageAspectRatio);
  const { activeMediaIndex, medias, handleProductLightboxMediaChange } = useProductDetailsVariantContext();
  const { videoThumbnails } = useVideoThumbnails({ videos: medias.filter(isVideoMedia).filter(({ src }) => isDefined(src)) });
  const areArrowsVisible = medias.length > MIN_IMAGES_LENGTH;

  const handlePrevious = () => {
    if (activeMediaIndex === 0) {
      return;
    }

    const previousMediaIndex = activeMediaIndex - 1;
    handleProductLightboxMediaChange(previousMediaIndex);
  };

  const handleNext = () => {
    if (activeMediaIndex === medias.length - 1) {
      return;
    }

    const nextMediaIndex = activeMediaIndex + 1;
    handleProductLightboxMediaChange(nextMediaIndex);
  };

  const classNames = cx(styles.productMediaThumbnails, {
    [styles.arrows]: areArrowsVisible,
  });

  const trackStyles = React.useMemo(() => {
    const position = Math.max(0, activeMediaIndex - 1);
    const translateValue = position * (-100 / medias.length);

    return areArrowsVisible ? { transform: `translateY(${translateValue}%)` } : undefined;
  }, [activeMediaIndex, areArrowsVisible, medias.length]);

  return (
    <div className={classNames}>
      {areArrowsVisible && (
        <button className={styles.arrowPrev} onClick={handlePrevious}>
          <Icon type={IconType.ArrowBlackDown} size={20} className={styles.prev} />
        </button>
      )}
      <div className={styles.track}>
        <ul style={trackStyles}>
          {medias.map((item, index) => {
            const isActive = index === activeMediaIndex;

            return (
              <li key={`${item.variantId}-${item.src}}`}>
                <button type="button" onClick={() => handleProductLightboxMediaChange(index)}>
                  <div
                    className={cx(styles.imageWrapper, {
                      [styles.active]: isActive,
                      [styles.videoPlaceholder]: !isImageMedia(item),
                    })}
                  >
                    {isImageMedia(item) ?
                      <CustomImage
                        src={item.src}
                        rounded
                        aspectRatio={aspectRatio}
                        size="cover"
                        errorStyle={styles.errorMessageThumbnail}
                      />
                    : <>
                        <Icon type={IconType.Play} className={styles.playIcon} />
                        {isDefined(item.src) && (
                          <CustomImage
                            src={videoThumbnails[item.src]}
                            isSourceReady={isDefined(videoThumbnails[item.src])}
                            rounded
                            aspectRatio={aspectRatio}
                            size="cover"
                            errorStyle={styles.errorMessageThumbnail}
                          />
                        )}
                      </>
                    }
                  </div>
                </button>
              </li>
            );
          })}
        </ul>
      </div>
      {areArrowsVisible && (
        <button className={styles.arrowNext} onClick={handleNext}>
          <Icon type={IconType.ArrowBlackDown} size={20} className={styles.next} />
        </button>
      )}
    </div>
  );
};
