import { Order } from '@typings';
import { useTranslation } from 'react-i18next';

import { useModalVisibility, useUrlState } from '../../../utils/hooks';
import { isEmpty } from '../../../utils/isEmpty';
import { EmptyState, EmptyStateButtonSeparator } from '../../various/EmptyState';
import { Button } from '../../various/NewButton';

export const OrdersListEmptyState = () => {
  const { t } = useTranslation(['common', 'orders', 'selections']);

  const [status, setStatus] = useUrlState<Order.OrderStatus | Order.SelectionStatus>('status');
  const [search, setSearch] = useUrlState('search');

  const { showModal } = useModalVisibility('CreateOrderModal');

  const clearSearchButton = (
    <Button variant="bordered" color="dark" onClick={() => setSearch('')}>
      {t('common:clear_search')}
    </Button>
  );

  const clearFiltersButton = (
    <Button variant="bordered" color="dark" onClick={() => setStatus('')}>
      {t('common:clear_filters')}
    </Button>
  );

  if (!isEmpty(search) && !isEmpty(status)) {
    return (
      <EmptyState
        title={t('orders:no_results.search_and_filters.title', { searchQuery: search })}
        subtitle={t('orders:no_results.search_and_filters.subtitle')}
      >
        {clearFiltersButton}
        <EmptyStateButtonSeparator>{t('common:or')}</EmptyStateButtonSeparator>
        {clearSearchButton}
      </EmptyState>
    );
  }

  if (!isEmpty(search)) {
    return (
      <EmptyState title={t('orders:no_results.search.title', { searchQuery: search })} subtitle={t('orders:no_results.search.subtitle')}>
        {clearSearchButton}
      </EmptyState>
    );
  }

  if (!isEmpty(status)) {
    return (
      <EmptyState title={t('orders:no_results.filters.title')} subtitle={t('orders:no_results.filters.subtitle')}>
        {clearFiltersButton}
      </EmptyState>
    );
  }

  return (
    <EmptyState title={t('orders:no_results.default.title')} subtitle={t('orders:no_results.default.subtitle')}>
      <Button variant="bordered" color="dark" onClick={showModal}>
        {t('selections:create_selection')}
      </Button>
    </EmptyState>
  );
};
