import React from 'react';
import { useLocation } from 'react-router-dom';

import { useOnValueChange } from '../../../../utils/hooks';
import { add, remove } from '../../../../utils/lockBody';
import { ModalSize } from '../Modal';

interface Context {
  containerRef: React.RefObject<HTMLDivElement>;
  size?: ModalSize;
  isOpen: boolean;
  hideCloseIcon: boolean;
  shouldCloseOnEsc: boolean;
  fullScreenOnMobile: boolean;
  shouldCloseOnLocationChange?: boolean;
  shouldReturnFocusAfterClose?: boolean;
  shouldCloseOnOverlayClick: boolean;
  onClose: () => void;
  onCloseContent: () => void;
}

export const useModalContext = (): Context => {
  const context = React.useContext(ModalContext);
  if (context === null) {
    throw new Error('ModalContext can not be used outside the scope of ModalContextProvider');
  }

  return context;
};

export const ModalContext = React.createContext<Context | null>(null);
interface ModalContextProviderProps {
  size?: ModalSize;
  isOpen: boolean;
  shouldCloseOnOverlayClick?: boolean;
  shouldCloseOnLocationChange?: boolean;
  shouldReturnFocusAfterClose?: boolean;
  shouldCloseOnEsc: boolean;
  fullScreenOnMobile?: boolean;
  hideCloseIcon?: boolean;
  modalClosedCallback?: () => void;
  onClose: () => void;
}

export const ModalContextProvider = ({
  children,
  size,
  shouldCloseOnEsc,
  shouldCloseOnLocationChange,
  shouldCloseOnOverlayClick = true,
  fullScreenOnMobile = false,
  hideCloseIcon = false,
  shouldReturnFocusAfterClose,
  isOpen,
  modalClosedCallback,
  onClose,
}: React.WithChildren<ModalContextProviderProps>) => {
  const containerRef = React.useRef<HTMLDivElement>(null);

  const onExited = () => {
    modalClosedCallback?.();
  };
  const location = useLocation();
  const onLocationChange = React.useCallback(() => {
    if (shouldCloseOnLocationChange && isOpen) {
      onClose();
    }
  }, [shouldCloseOnLocationChange, isOpen, onClose]);

  useOnValueChange(location.key, onLocationChange);

  React.useEffect(() => {
    if (!isOpen) {
      return;
    }

    add(document.body, 'modalOpen');

    return () => {
      remove(document.body, 'modalOpen');
    };
  }, [isOpen]);

  return (
    <ModalContext.Provider
      value={{
        containerRef,
        fullScreenOnMobile,
        hideCloseIcon,
        isOpen,
        onClose,
        onCloseContent: onExited,
        shouldCloseOnEsc,
        shouldCloseOnOverlayClick,
        shouldReturnFocusAfterClose,
        size,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
