import { Addresses, Id } from '@typings';
import { pick } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { SHIPPING_ADDRESS_FIELDS } from '../../../../constants/address';
import { getIsAccountEditingEnabled } from '../../../../ducks/config';
import { useCreateShippingAddress } from '../../../../services/hooks/account/useCreateShippingAddress';
import { useUpdateShippingAddress } from '../../../../services/hooks/account/useUpdateShippingAddress';
import { useModalVisibility } from '../../../../utils/hooks';
import { isDefined } from '../../../../utils/is';
import Heading from '../../../various/Heading';
import { ModalShell } from '../../../various/ModalShell';
import { Button } from '../../../various/NewButton';
import { SectionHeadingWrapper } from '../../../various/SectionHeading';

import { ShippingAddressesTable } from './ShippingAddressesTable';
import { ShippingAddressForm } from './ShippingAddressForm';

interface Props {
  accountId: Id;
  shippingAddresses: Addresses.Shipping[] | undefined;
  isLoading?: boolean;
}

export const ShippingAddresses = ({ accountId, shippingAddresses = [], isLoading }: Props) => {
  const { t } = useTranslation(['common', 'shipping', 'buyers', 'validation']);
  const isAccountEditingEnabled = useSelector(getIsAccountEditingEnabled);

  const [editedAddressId, setEditedAddressId] = React.useState('');

  const { mutate: createShippingAddress, isPending: isCreatingShippingAddress } = useCreateShippingAddress();
  const { mutate: updateShippingAddress, isPending: isUpdatingShippingAddress } = useUpdateShippingAddress();

  const editedAddress = React.useMemo(() => {
    return shippingAddresses.find(({ shippingAddress }) => shippingAddress === editedAddressId);
  }, [editedAddressId, shippingAddresses]);

  const {
    isModalVisible: isCreateModalVisible,
    showModal: showCreateModal,
    hideModal: hideCreateModal,
  } = useModalVisibility('CreateShippingAddressModal');
  const {
    isModalVisible: isUpdateModalVisible,
    showModal: showUpdateModal,
    hideModal: hideUpdateModal,
  } = useModalVisibility('UpdateShippingAddressModal');

  const handleEditShippingAddress = (shippingAddressId: string) => {
    setEditedAddressId(shippingAddressId);
    showUpdateModal();
  };

  const handleAddShippingAddress = () => {
    setEditedAddressId('');
    showCreateModal();
  };

  const handleCreateShippingAddressSubmit = (data: Addresses.ShippingAddressDTO) => {
    createShippingAddress(
      { accountId, data },
      {
        onSuccess: hideCreateModal,
      },
    );
  };

  const handleUpdateShippingAddressSubmit = (data: Addresses.ShippingAddressDTO) => {
    updateShippingAddress(
      { accountId, data, shippingAddressId: editedAddressId },
      {
        onSuccess: hideUpdateModal,
      },
    );
  };

  const editedAddressInitialValues = isDefined(editedAddress) ? pick(SHIPPING_ADDRESS_FIELDS, editedAddress) : undefined;

  return (
    <>
      <SectionHeadingWrapper>
        <Heading title={t('shipping:shipping_address_other')} variant={['h2', 'black']} />
        {isAccountEditingEnabled && (
          <>
            <Button size="large" onClick={handleAddShippingAddress}>
              {t('shipping:add')}
            </Button>
            <ModalShell title={t('shipping:add')} isOpen={isCreateModalVisible} onClose={hideCreateModal}>
              <ShippingAddressForm
                isLoading={isCreatingShippingAddress}
                onCancel={hideCreateModal}
                onSubmit={handleCreateShippingAddressSubmit}
              />
            </ModalShell>
          </>
        )}
      </SectionHeadingWrapper>
      <ShippingAddressesTable
        accountId={accountId}
        shippingAddresses={shippingAddresses}
        isLoading={isLoading}
        onRowEdit={handleEditShippingAddress}
      />
      <ModalShell title={t('shipping:update')} isOpen={isUpdateModalVisible} onClose={hideUpdateModal}>
        <ShippingAddressForm
          defaultValues={editedAddressInitialValues}
          isLoading={isUpdatingShippingAddress}
          onCancel={hideUpdateModal}
          onSubmit={handleUpdateShippingAddressSubmit}
        />
      </ModalShell>
    </>
  );
};
