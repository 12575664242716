import { Preset, Product } from '@typings';
import { useTranslation } from 'react-i18next';

import { useMatrixDistributionContext } from '../../../../../../../products/ProductMatrix/context';
import { Form } from '../../../../../../Form';
import { List } from '../../../../../../List';
import { Button } from '../../../../../../NewButton';
import { AddDistributionButton } from '../../AddDistributionButton';
import { usePresetListElements } from '../../PresetsListElements/usePresetsListElements';
import { usePresetsListData } from '../usePresetsListData';

import styles from './Forms.module.scss';

interface Props {
  close: () => void;
  onSubmit: () => void;
  variant: Product.Standard;
  distributions: Preset[];
}

export const QuantitiesForm = ({ close, onSubmit, variant, distributions }: Props) => {
  const { quantitiesFormMethods } = useMatrixDistributionContext();
  const { t } = useTranslation(['common']);

  const { footer, model } = usePresetListElements({ variant });

  const { presetsListData } = usePresetsListData({ distributions, variant });

  return (
    <Form className={styles.form} formMethods={quantitiesFormMethods} onSubmit={onSubmit}>
      <List data={presetsListData} className={styles.table} keyProperty="id" model={model} footer={footer} />

      <div className={styles.formButtonsContainer}>
        <Button size="small" variant="ghost" color="dark" onClick={close}>
          {t('common:cancel')}
        </Button>
        <AddDistributionButton />
      </div>
    </Form>
  );
};
