import { Cms } from '@typings';

import { DEFAULT_CONTENT_ALIGNMENT, EMPTY_TEXT_CONTENT } from '../../constants/cms';
import { assignIdsToParts } from '../../logic/cms/assignIdsToParts';

import { TextStyles, textStylesConfig } from './textStyles';

const PREHEADING = '<p><span>Lorem ipsum</span></p>';
const PARAGRAPH = '<p><span>Etiam sit amet hendrerit turpis. Curabitur in fermentum erat.</span></p>';
const PARAGRAPH_LONG =
  '<p><span>Etiam sit amet hendrerit turpis. Curabitur in fermentum erat. Nunc et nibh a justo vestibulum fringilla sed quis nunc.</span></p>';
const QUOTE = '<p><span>"Nulla sit amet ipsum vel ante auctor tempus in in lorem."</span></p>';
const QUOTE_PREHEADING = '<p><span>John Anthony Doe</span></p>';

export const placeholderTextPart: Cms.ContentBlockTextPart = {
  desktop: {
    fontSize: textStylesConfig[TextStyles.Paragraph].properties.default.fontSize,
    textAlignment: 'center',
  },
  general: {
    content: EMPTY_TEXT_CONTENT,
    spacing: 15,
    textColor: '#000000',
    textStyle: TextStyles.Paragraph,
  },
  type: 'text',
};

interface TextTemplatePart {
  content: string;
  textStyles: TextStyles;
}

const getTextTemplateParts = (parts: TextTemplatePart[]): Cms.ContentBlockPart[] =>
  parts.map(part => ({
    desktop: {
      fontSize: textStylesConfig[part.textStyles].properties.default.fontSize,
      textAlignment: 'center',
    },
    general: {
      content: part.content,
      spacing: 15,
      textColor: '#000000',
      textStyle: part.textStyles,
    },
    type: 'text',
  }));

const getTextTemplate = (templateParts: TextTemplatePart[]) => ({
  ...DEFAULT_CONTENT_ALIGNMENT,
  ...assignIdsToParts(getTextTemplateParts(templateParts)),
});

const multilineTextTemplate1 = (): Cms.ContentBlockText =>
  getTextTemplate([
    {
      content: PREHEADING,
      textStyles: TextStyles.Preheading,
    },
    {
      content: PARAGRAPH_LONG,
      textStyles: TextStyles.Paragraph,
    },
  ]);

const multilineTextTemplate2 = (): Cms.ContentBlockText =>
  getTextTemplate([
    {
      content: PREHEADING,
      textStyles: TextStyles.Preheading,
    },
    {
      content: PREHEADING,
      textStyles: TextStyles.PrimaryHeading,
    },
    {
      content: PARAGRAPH_LONG,
      textStyles: TextStyles.Paragraph,
    },
  ]);

const multilineTextTemplate3 = (): Cms.ContentBlockText =>
  getTextTemplate([
    {
      content: PREHEADING,
      textStyles: TextStyles.Preheading,
    },
    {
      content: PARAGRAPH,
      textStyles: TextStyles.Quote,
    },
  ]);

const multilineTextTemplate4 = (): Cms.ContentBlockText =>
  getTextTemplate([
    {
      content: QUOTE,
      textStyles: TextStyles.Quote,
    },
    {
      content: QUOTE_PREHEADING,
      textStyles: TextStyles.Preheading,
    },
  ]);

export const singleLineTextTemplate = (): Cms.ContentBlockText =>
  getTextTemplate([
    {
      content: EMPTY_TEXT_CONTENT,
      textStyles: TextStyles.Paragraph,
    },
  ]);

export const textTemplates = () => [
  multilineTextTemplate1(),
  multilineTextTemplate2(),
  multilineTextTemplate3(),
  multilineTextTemplate4(),
  singleLineTextTemplate(),
];
