import React from 'react';

import { isDefined } from '../is';

import { useLatest } from './useLatest';

const DEFAULT_DELAY = 300;

export const useThrottledCallback = <T extends Unrestricted[]>(
  callback: (...args: T) => void,
  delay: number = DEFAULT_DELAY,
  deps: unknown[] = [],
) => {
  const latestCallback = useLatest(callback);
  const latestCalledAt = React.useRef(0);
  const timeout = React.useRef<NodeJS.Timeout | null>(null);

  const cleanup = () => {
    if (!isDefined(timeout.current)) {
      return;
    }

    clearTimeout(timeout.current);
  };

  return React.useCallback(
    (...args: T) => {
      cleanup();

      const call = () => {
        latestCallback.current.apply(null, args);
        latestCalledAt.current = Date.now();
      };

      const delayLeft = delay + latestCalledAt.current - Date.now();

      if (delayLeft <= 0) {
        call();
      } else {
        timeout.current = setTimeout(call, delayLeft);
      }
    },
    [delay, latestCallback, ...deps],
  );
};
