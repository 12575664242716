import { Checkout, CheckoutForm } from '@typings';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { isDefined } from '../../../../utils/is';
import { InputField, TextareaField } from '../../../various/Form';
import Heading from '../../../various/Heading';

import styles from './CheckoutAdditional.module.scss';

interface Props {
  checkoutFields: Checkout.Requirements;
  isLoading: boolean;
}

export const CheckoutAdditional = ({ checkoutFields, isLoading }: Props) => {
  const { poNumber, additionalNotes } = checkoutFields;

  const { t } = useTranslation(['checkout']);
  const {
    register,
    formState: { errors },
  } = useFormContext<CheckoutForm>();

  const handleTextareaOnChange = React.useCallback(({ currentTarget }: React.SyntheticEvent<HTMLTextAreaElement>) => {
    // eslint-disable-next-line functional/immutable-data
    currentTarget.style.height = 'auto';
    // eslint-disable-next-line functional/immutable-data
    currentTarget.style.height = `${currentTarget.scrollHeight}px`;
  }, []);

  if (!isDefined(poNumber) && !isDefined(additionalNotes)) {
    return null;
  }

  return (
    <>
      <Heading variant={['blue', 'h2']} title={t('checkout:additional_info')} />
      {isDefined(poNumber) && (
        <div className={styles.flexRow}>
          <InputField
            {...register('poNumber')}
            isLoading={isLoading}
            isRequired={poNumber.required}
            isInvalid={isDefined(errors.poNumber)}
            data-testid="po-number-input"
            placeholder={t('checkout:po_number')}
            label={`${t('checkout:po_number')}:`}
          />
        </div>
      )}

      {isDefined(additionalNotes) && (
        <div className={styles.flexRow}>
          <TextareaField
            {...register('additionalNotes', {
              onChange: handleTextareaOnChange,
            })}
            isLoading={isLoading}
            className={styles.textArea}
            placeholder={t('checkout:additional_info')}
            label={`${t('checkout:additional_notes')}:`}
          />
        </div>
      )}
    </>
  );
};
