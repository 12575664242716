import { Cms, Id } from '@typings';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getIsToolbarVisible, getIsToolbarVisibleInBlock, hideToolbar as hide, showToolbar as show } from '../../../ducks';
import { isDefined } from '../../is';

export const useToolbarVisibility = (toolbarName: Cms.ToolbarType, blockId: Nullable<Id> = null) => {
  const dispatch = useDispatch();
  const selector = isDefined(blockId) ? getIsToolbarVisibleInBlock(blockId, toolbarName) : getIsToolbarVisible(toolbarName);

  const isToolbarVisible = useSelector(selector);

  const hideToolbar = React.useCallback(() => {
    dispatch(hide(toolbarName));
  }, [dispatch, toolbarName]);

  const showToolbar = React.useCallback(() => {
    dispatch(show(toolbarName));
  }, [dispatch, toolbarName]);

  return {
    hideToolbar,
    isToolbarVisible,
    showToolbar,
  };
};
