import { Media } from '@typings';
import cx from 'classnames';

import { useFileUrl } from '../../../../utils/hooks';
import { isDefined } from '../../../../utils/is';

import styles from './FilePicker.module.scss';

interface Props {
  file: Media.UploadFile | null;
  fitPreview: boolean;
}

export const FilePreview = ({ file, fitPreview }: Props) => {
  const [fileUrl, fileType] = useFileUrl(file);

  const previewUrl = file?.previewUrl ?? (fileType === 'image' ? fileUrl : null);

  if (!isDefined(previewUrl)) {
    return <>{file?.name}</>;
  }

  return (
    <div className={styles.previewWrapper}>
      <img className={cx(styles.previewImage, { [styles.fitPreview]: fitPreview })} src={previewUrl} alt="" />
    </div>
  );
};
