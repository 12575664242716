import cx from 'classnames';
import React from 'react';

import { isDefined } from '../../../../utils/is';
import { TextEllipsis } from '../../TextEllipsis';
import styles from '../PopoverMenu.module.scss';

interface Props {
  subLabel?: string | React.ReactNode;
  description?: string | React.ReactNode;
  className?: string;
  containerClassName?: string;
  descriptionClassName?: string;
}

export const Label = ({
  children,
  subLabel,
  description,
  className,
  containerClassName,
  descriptionClassName,
}: React.WithChildren<Props>) => {
  const labelBase = (
    <span className={cx(styles.label, className)}>
      {children}
      {isDefined(subLabel) && (
        <TextEllipsis noWrapText={false} className={styles.subLabel}>
          ({subLabel})
        </TextEllipsis>
      )}
    </span>
  );

  if (!isDefined(description)) {
    return labelBase;
  }

  return (
    <span className={cx(styles.labelContainer, containerClassName)}>
      {labelBase}
      <span className={cx(styles.description, descriptionClassName)}>{description}</span>
    </span>
  );
};
