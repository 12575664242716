import React from 'react';
import { useTranslation } from 'react-i18next';

import { useEtaLegend } from '../../../utils/hooks/useEtaLegend';
import { EtaLegend } from '../../various/Matrix/EtaLegend';
import { ModalShell } from '../../various/ModalShell';

import { ProductsEtaContext } from './context/ProductsEtaContext';

export const ProductsEtaModal = () => {
  const { t } = useTranslation(['common']);
  const { isProductsEtaModalVisible, setIsProductsEtaModalVisible } = React.useContext(ProductsEtaContext);
  const { legendItems } = useEtaLegend();

  const handleClose = React.useCallback(() => {
    setIsProductsEtaModalVisible(false);
  }, [setIsProductsEtaModalVisible]);

  return (
    <ModalShell title={t('common:size_availability')} isOpen={isProductsEtaModalVisible} onClose={handleClose}>
      <EtaLegend vertical label={t('common:arriving_on')} items={legendItems} />
    </ModalShell>
  );
};
