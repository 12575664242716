import React from 'react';
import { Link } from 'react-router-dom';

import universalStyles from '../../../../css/utilities/universal.module.scss';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';

import styles from './ClientLogo.module.scss';

interface Props {
  link?: string;
  src: string;
  companyName: string;
  onClick?: () => void;
}

export const ClientLogo = React.memo(({ link, src, companyName, onClick }: Props) => {
  const [isBroken, setIsBroken] = React.useState(false);

  const handleError = React.useCallback(() => setIsBroken(true), []);
  const hasValidImage = !isBroken && !isEmpty(src);

  const image =
    hasValidImage ?
      <>
        <img onError={handleError} src={src} alt={companyName} className={styles.image} />
        <span className={universalStyles.srOnly}>{companyName}</span>
      </>
    : companyName ?? 'Company logo';

  const content =
    isDefined(link) ?
      <Link to={link} className={styles.logoContainer} onClick={onClick}>
        {image}
      </Link>
    : <div className={styles.logoContainer}>{image}</div>;

  return <h1 className={styles.header}>{content}</h1>;
});
