import { useMutation } from '@tanstack/react-query';
import { Id, OrderLinesheet } from '@typings';
import { uniq } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Icon, { IconType } from '../../../components/various/Icon';
import { addToast, dismissToast } from '../../../components/various/Toasts';
import { getDownloadLinesheetOrderGeneratedEvent } from '../../../utils/analytics/events';
import { useUnitSwitcher } from '../../../utils/hooks/unitSwitcher/useUnitSwitcher';
import { generateLinesheet } from '../../documentsRepository';
import { downloadURI } from '../../download';
import { pushEventGTM } from '../../gtm';

interface Props {
  orderNumber: Id;
}

const NOTIFICATION_KEY = 'generateLinesheetNotification';

export const useOrderLinesheetDownload = ({ orderNumber }: Props) => {
  const { t } = useTranslation(['linesheets', 'orders']);

  const { selectedUnits } = useUnitSwitcher();

  const closeToast = React.useCallback(() => {
    dismissToast(NOTIFICATION_KEY);
  }, []);

  const mutationFn = async (values: OrderLinesheet.Payload) => {
    const eventPayload = {
      location: values.location,
      onlySelected: values.onlySelected,
      showCustomerPrice: values.showCustomerPriceFlag,
      template: values.template,
    };

    pushEventGTM(getDownloadLinesheetOrderGeneratedEvent(eventPayload));

    const payload = {
      buyer: values.buyer,
      onlySelected: values.onlySelected,
      order: values.order,
      showCustomerPriceFlag: values.showCustomerPriceFlag,
      template: values.template,
      templateDO: values.templateDO,
      units: uniq(Object.values(selectedUnits)),
    };

    return generateLinesheet(payload);
  };

  const handleMutate = () => {
    addToast(t('linesheets:generating_linesheet'), {
      description: t('linesheets:generate_linesheet_hint', {
        orderNumberText: t('orders:for_order', { orderNumber }),
      }),
      duration: 0,
      icon: <Icon type={IconType.Spinner} />,
      id: NOTIFICATION_KEY,
      onClose: closeToast,
      position: 'bottom-left',
    });
  };

  const handleError = () => {
    addToast(t('linesheets:generate_linesheet_fail'), {
      description: t('linesheets:generate_linesheet_fail_hint', {
        orderNumberText: t('orders:for_order', { orderNumber }),
      }),
      duration: 0,
      icon: <Icon type={IconType.Alert} />,
      id: NOTIFICATION_KEY,
      onClose: closeToast,
      position: 'bottom-left',
    });
  };

  return useMutation({
    mutationFn,
    onError: handleError,
    onMutate: handleMutate,
    onSuccess: ({ url }) => {
      downloadURI(url, 'linesheet');
      closeToast();
    },
  });
};
