import React from 'react';
import { useSelector } from 'react-redux';

import { DeliveryWindow } from '../../../../../typings';
import {
  getBuyerIdFromCurrentOrder,
  getDeliveryWindowsByBuyerId,
  getDeliveryWindowsOrder,
  getIsStockTypeSeparationEnabled,
  getStockTypeFilter,
} from '../../../ducks';
import { isDeliveryWindowOrderGroup } from '../../../logic/deliveryWindows';
import { isDefined } from '../../is';
import { isEmpty } from '../../isEmpty';

export const useDeliveryWindowFilterItems = () => {
  const currentBuyerId = useSelector(getBuyerIdFromCurrentOrder);
  const stockTypeFilter = useSelector(getStockTypeFilter);
  const allDeliveryWindowsSelector = React.useMemo(() => getDeliveryWindowsByBuyerId(currentBuyerId), [currentBuyerId]);
  const allDeliveryWindows = useSelector(allDeliveryWindowsSelector);
  const hasStockSwitcher = useSelector(getIsStockTypeSeparationEnabled);
  const deliveryWindowsOrder = useSelector(getDeliveryWindowsOrder);

  const deliveryWindowsByStock = React.useMemo(() => {
    return hasStockSwitcher ?
        allDeliveryWindows.filter(deliveryWindow => deliveryWindow.stockType === stockTypeFilter)
      : allDeliveryWindows;
  }, [allDeliveryWindows, hasStockSwitcher, stockTypeFilter]);

  const getDeliveryWindowsDetails = React.useCallback(
    (deliveryWindowId: string) => {
      const deliveryWindow = deliveryWindowsByStock.find(delwin => delwin.deliveryWindow === deliveryWindowId);

      return isDefined(deliveryWindow) ? deliveryWindow : null;
    },
    [deliveryWindowsByStock],
  );

  const getGroupOptions = React.useCallback(
    (group: DeliveryWindow.DeliveryWindowsGroup) => {
      return group.items.reduce((acc: DeliveryWindow.Mixed[], delwinItem) => {
        const deliveryWindowDetails = getDeliveryWindowsDetails(delwinItem.deliveryWindowId);

        if (!isDefined(deliveryWindowDetails)) {
          return acc;
        }

        return [...acc, deliveryWindowDetails];
      }, []);
    },
    [getDeliveryWindowsDetails],
  );

  return React.useMemo(() => {
    if (isEmpty(deliveryWindowsOrder)) {
      return deliveryWindowsByStock;
    }

    return deliveryWindowsOrder.reduce((acc: DeliveryWindow.DeliveryWindowFilterItem[], delwinOrder) => {
      const isGroup = isDeliveryWindowOrderGroup(delwinOrder);

      if (!isGroup) {
        const deliveryWindowDetails = deliveryWindowsByStock.find(({ deliveryWindow }) => deliveryWindow === delwinOrder.deliveryWindowId);

        return isDefined(deliveryWindowDetails) ? [...acc, deliveryWindowDetails] : acc;
      }
      const groupOptions = getGroupOptions(delwinOrder);

      return !isEmpty(groupOptions) ?
          [
            ...acc,
            {
              groupId: delwinOrder.groupId,
              items: groupOptions,
              name: delwinOrder.name,
            },
          ]
        : acc;
    }, []);
  }, [deliveryWindowsByStock, deliveryWindowsOrder, getGroupOptions]);
};
