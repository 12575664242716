import { Translations } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { requestLanguageChange } from '../../../ducks';
import { useTranslationsConfig } from '../../../utils/hooks';
import { DropdownSelect, RenderTrigger } from '../../various/DropdownSelect';
import Icon, { IconColor, IconType } from '../../various/Icon';

import styles from './LanguageSwitcher.module.scss';

interface Props {
  variant?: 'light' | 'dark';
  className?: string;
}

export const LanguageSwitcher = ({ variant = 'light', className }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common']);
  const { currentLanguageCode, availableLanguages, areMultipleLanguagesAvailable } = useTranslationsConfig();

  const handleSelect = React.useCallback((lang: Translations.SupportedLanguagesCodes) => dispatch(requestLanguageChange(lang)), [dispatch]);

  const currentLanguageName = availableLanguages.find(lang => lang.code === currentLanguageCode)?.label;

  const iconColor = variant === 'light' ? IconColor.White : IconColor.Gray;

  const options = React.useMemo(() => {
    return availableLanguages.map(({ code, label }) => ({
      key: code,
      label,
    }));
  }, [availableLanguages]);

  const renderTrigger = React.useCallback<RenderTrigger>(
    (triggerProps, isOpen) => (
      <button
        className={cx(styles.langButton, className)}
        aria-label={`${t('common:language_one')} (${currentLanguageName})`}
        {...triggerProps}
      >
        <Icon type={IconType.Language} color={iconColor} />
        <div className={cx(styles.languageText, { [styles.dark]: variant === 'dark' })}>{currentLanguageName}</div>
        <Icon type={IconType.ArrowSharp} color={iconColor} rotation={isOpen ? 180 : 0} />
      </button>
    ),
    [className, currentLanguageName, iconColor, t, variant],
  );

  if (!areMultipleLanguagesAvailable) {
    return null;
  }

  return <DropdownSelect value={currentLanguageCode} options={options} renderTrigger={renderTrigger} onSelect={handleSelect} />;
};
