import cx from 'classnames';
import React from 'react';

import { Cms } from '../../../../../typings';
import { isHiddenInEditorForScreen } from '../../../logic/pages';
import { Section } from '../../pages/section';
import sectionStyles from '../../pages/section/section.module.scss';
import { EditorLanguageContext } from '../context/EditorLanguageContext';
import { EditorUIContext } from '../context/EditorUIContext';
import { SectionButtons } from '../SectionButtons';

interface Props {
  section: Cms.Section;
  index: number;
}

export const EditorSection = ({ section, index }: Props) => {
  const { isDefaultLanguage } = React.useContext(EditorLanguageContext);
  const { screenType } = React.useContext(EditorUIContext);

  const className = cx(sectionStyles.forceVisible, {
    [sectionStyles.hiddenOnCurrentDevice]: isHiddenInEditorForScreen(section.visibleFor.screen, screenType),
  });

  return (
    <>
      {index === 0 && <SectionButtons sectionIndex={0} isHidden={!isDefaultLanguage} />}
      <Section sectionData={section} className={className} shouldHideTools={!isDefaultLanguage} />
      <SectionButtons sectionIndex={index + 1} isHidden={!isDefaultLanguage} />
    </>
  );
};
