import { ActionHandler, Id, Product } from '@typings';
import { createReducer } from 'typesafe-actions';

import { update } from '../../../utils/update';
import { resetCms } from '../general';

import * as actions from './actions';

interface State {
  allProducts: Record<Id, Product.CmsProduct>;
  isLoading: boolean;
  isLoadingFromSearch: boolean;
  productsOrder: Id[];
  productCount: number;
  isLoadingMoreFromSearch: boolean;
}

const initialState: State = {
  allProducts: {},
  isLoading: false,
  isLoadingFromSearch: false,
  isLoadingMoreFromSearch: false,
  productCount: 0,
  productsOrder: [],
};

const handleResetProducts: ActionHandler<State, typeof resetCms> = () => initialState;

const handleFetchProductsRequest = (state: State) =>
  update(state, {
    isLoading: true,
  });

const handleSearchProductsRequest = (state: State) =>
  update(state, {
    isLoadingFromSearch: true,
  });

const handleFetchProductsSuccess: ActionHandler<State, typeof actions.fetchCmsProductsSuccess> = (state: State, action) => {
  const { products, productCount, productsOrder } = action.payload;

  return update(state, {
    allProducts: update(state.allProducts, products),
    isLoading: false,
    isLoadingMoreFromSearch: false,
    productCount,
    productsOrder,
  });
};

const handleFetchProductsFailure = (state: State) =>
  update(state, {
    allProducts: {},
    isLoading: false,
    isLoadingMoreFromSearch: false,
    productsOrder: [],
  });

const handleLoadMoreProductsRequest = (state: State) =>
  update(state, {
    isLoadingMoreFromSearch: true,
  });

const handleLoadMoreProductsSuccess: ActionHandler<State, typeof actions.loadMoreCmsProductsSuccess> = (state: State, action) => {
  const { products, productsOrder, productCount } = action.payload;

  return update(state, {
    allProducts: update(state.allProducts, products),
    isLoadingMoreFromSearch: false,
    productCount,
    productsOrder: [...new Set([...state.productsOrder, ...productsOrder])],
  });
};

const handleLoadMoreProductsFailure = (state: State) =>
  update(state, {
    isLoadingMoreFromSearch: false,
  });

const handleSearchProductsSuccess: ActionHandler<State, typeof actions.searchProductsSuccess> = (state: State, action) => {
  const { productsOrder, products, productCount } = action.payload;

  return update(state, {
    allProducts: update(state.allProducts, products),
    isLoadingFromSearch: false,
    productCount,
    productsOrder,
  });
};

const handleFetchProductsForPreviewSuccess: ActionHandler<State, typeof actions.fetchCmsProductsForPreviewSuccess> = (
  state: State,
  action,
) => {
  const { products, productsOrder, productCount } = action.payload;

  return update(state, {
    allProducts: products,
    isLoading: false,
    isLoadingMoreFromSearch: false,
    productCount,
    productsOrder,
  });
};

export default createReducer<State, AppAction>(initialState)
  .handleAction(resetCms, handleResetProducts)
  .handleAction(actions.fetchCmsProductsRequest, handleFetchProductsRequest)
  .handleAction(actions.fetchCmsProductsSuccess, handleFetchProductsSuccess)
  .handleAction(actions.loadMoreCmsProductsRequest, handleLoadMoreProductsRequest)
  .handleAction(actions.loadMoreCmsProductsSuccess, handleLoadMoreProductsSuccess)
  .handleAction(actions.loadMoreCmsProductsFailure, handleLoadMoreProductsFailure)
  .handleAction(actions.fetchCmsProductsFailure, handleFetchProductsFailure)
  .handleAction(actions.searchProductsRequest, handleSearchProductsRequest)
  .handleAction(actions.searchProductsSuccess, handleSearchProductsSuccess)
  .handleAction(actions.fetchCmsProductsForPreviewRequest, handleFetchProductsRequest)
  .handleAction(actions.fetchCmsProductsForPreviewSuccess, handleFetchProductsForPreviewSuccess);
