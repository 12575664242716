import { Translations } from '@typings';

import { DEFAULT_LANGUAGES_CODES } from '../constants/translation';
import { isDefined } from '../utils/is';

import i18n from './i18n';

export const updateSupportedLanguages = (additionalLanguagesCodes: Translations.AdditionalLanguagesCodes[] | undefined) => {
  i18n.init({
    supportedLngs:
      isDefined(additionalLanguagesCodes) ? [...DEFAULT_LANGUAGES_CODES, ...additionalLanguagesCodes] : DEFAULT_LANGUAGES_CODES,
  });
};
