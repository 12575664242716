import { createColumnHelper, getCoreRowModel } from '@tanstack/react-table';
import { Account } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getUser } from '../../../ducks';
import { getIsAccountEditingEnabled } from '../../../ducks/config';
import { getHasPermissions } from '../../../logic/User';
import { compiledPaths } from '../../../paths';
import { useAccounts } from '../../../services/hooks/accounts/useAccounts';
import { useBuyers } from '../../../services/hooks/accounts/useBuyers';
import { useDebouncedCallback, useModalVisibility, usePageScrolling, useUrlPagination, useUrlState } from '../../../utils/hooks';
import { usePaginationHandler } from '../../../utils/hooks/table/usePaginationHandler';
import { useTable } from '../../../utils/hooks/table/useTable';
import { isDefined } from '../../../utils/is';
import { renderMaskedCell, renderStandardCell } from '../../../utils/table';
import { EmptyState } from '../../various/EmptyState';
import { Input } from '../../various/Fields/Input';
import { FloatingButton } from '../../various/FloatingButton';
import { IconType } from '../../various/Icon';
import { Link } from '../../various/Link';
import { Button } from '../../various/NewButton';
import { PageTitle } from '../../various/PageTitle';
import { Table } from '../../various/Table';
import { TopBar } from '../../various/TopBar';
import { Wrapper, WrapperSize } from '../../various/Wrapper';
import { CreateAccountModal } from '../CreateAccount/CreateAccountModal';

interface AccountListing {
  accountId: string;
  buyer: string;
  email?: string;
  name: string;
  numberOfBuyers: number;
  uid: string;
}

const columnHelper = createColumnHelper<AccountListing>();

export const AccountList = () => {
  const { t } = useTranslation(['common', 'accounts', 'buyers']);
  const { showModal } = useModalVisibility('CreateAccountModal');
  const { scrollToTopDelay } = usePageScrolling();

  const user = useSelector(getUser);
  const isAccountEditingEnabled = useSelector(getIsAccountEditingEnabled);
  const hasUserCreateAccountPermissions = getHasPermissions(user, 'CREATE_ACCOUNT');

  const [page, setPage] = useUrlPagination();
  const [searchQuery, setSearchQuery] = useUrlState('search');
  const [searchInputValue, setSearchInputValue] = React.useState(searchQuery);

  const { data: accountsData, isPending, isError } = useAccounts({ page, search: searchQuery });
  const accounts = React.useMemo(() => Object.values(accountsData?.data ?? {}), [accountsData]);
  const accountsTotal = accountsData?.total ?? 0;
  const buyersTotal = useBuyers({ limit: 1, page: 1 }).data?.total;

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('accountId', {
        cell: renderMaskedCell,
        header: t('accounts:account_id'),
        meta: {
          width: 180,
        },
      }),
      columnHelper.accessor('name', {
        cell: ({ row }) => {
          const path = compiledPaths.ACCOUNT_DETAILS({ accountId: row.original.accountId });

          return (
            <Link variant="blue" to={path} data-sentry-mask>
              {row.original.name}
            </Link>
          );
        },
        header: t('common:name'),
      }),
      columnHelper.accessor('buyer', {
        cell: renderMaskedCell,
        header: t('buyers:buyer_one'),
      }),
      columnHelper.accessor('email', {
        cell: ({ row }) => {
          const { email } = row.original;
          if (!isDefined(email)) {
            return null;
          }

          return (
            <Link variant="blue" external target="_blank" to={`mailto:${email}`} data-sentry-mask>
              {email}
            </Link>
          );
        },
        header: t('common:email'),
      }),
      columnHelper.accessor('numberOfBuyers', {
        cell: renderStandardCell,
        header: t('buyers:number_of_buyers'),
        meta: {
          width: 180,
        },
      }),
    ],
    [t],
  );

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
    scrollToTopDelay();
  };

  const { pagination, handlePaginationChange } = usePaginationHandler({ onPageChange: handlePageChange, page });

  const updateSearchQuery = useDebouncedCallback((inputValue: string) => {
    setSearchQuery(inputValue, { resetParams: ['page'] });
    scrollToTopDelay();
  });

  const onSearchChange = (value: string) => {
    setSearchInputValue(value);
    updateSearchQuery(value);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearchChange(event.target.value);
  };

  const clearSearch = () => {
    setSearchInputValue('');
    updateSearchQuery('');
  };

  React.useEffect(() => {
    setSearchInputValue(searchQuery);
  }, [searchQuery]);

  const getAccountListing = React.useCallback(
    (account: Account): AccountListing[] =>
      Object.values(account.buyers).map(buyer => ({
        accountId: account.account,
        buyer: `${buyer.firstName} ${buyer.lastName}`,
        email: buyer.email,
        name: account.name,
        numberOfBuyers: Object.keys(account.buyers).length,
        uid: `${account.account}-${buyer.buyer}`,
      })),
    [],
  );

  const hasAccounts = React.useMemo(() => accounts.length > 0, [accounts]);

  const paginationInfo = React.useMemo(
    () => ({
      current: page,
      hideOnSinglePage: true,
      total: accountsTotal,
    }),
    [page, accountsTotal],
  );

  const tableData = React.useMemo(
    () =>
      accounts.filter(isDefined).reduce<AccountListing[]>((acc, account) => {
        return [...acc, ...getAccountListing(account)];
      }, []),
    [accounts, getAccountListing],
  );

  const shouldShowTable = isPending || isError || hasAccounts;

  const table = useTable({
    columns,
    data: tableData,
    enableSorting: false,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    onPaginationChange: handlePaginationChange,
    state: {
      pagination,
    },
  });

  const topBarDetails = isDefined(buyersTotal) && `${buyersTotal} ${t('buyers:buyer', { count: buyersTotal }).toLowerCase()}`;

  return (
    <PageTitle title={t('accounts:account_other')}>
      <>
        <TopBar title={t('accounts:account_other')} details={topBarDetails}>
          <label aria-label={t('common:search_placeholder')}>
            <Input
              placeholder={t('common:search_placeholder')}
              icon={IconType.Search}
              value={searchInputValue}
              isClearable
              onChange={handleSearch}
            />
          </label>
        </TopBar>

        <Wrapper size={WrapperSize.XXLARGE}>
          {shouldShowTable ?
            <Table table={table} pagination={paginationInfo} isLoading={isPending} />
          : <EmptyState title={t('accounts:no_results.search.title', { searchQuery })} subtitle={t('accounts:no_results.search.subtitle')}>
              <Button variant="bordered" color="dark" onClick={clearSearch}>
                {t('common:clear_search')}
              </Button>
            </EmptyState>
          }
        </Wrapper>
        {isAccountEditingEnabled && hasUserCreateAccountPermissions && (
          <>
            <CreateAccountModal />
            <FloatingButton onClick={showModal} label={t('accounts:create_new_account')} />
          </>
        )}
      </>
    </PageTitle>
  );
};
