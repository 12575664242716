import React from 'react';

import { isDefined } from '../../../utils/is';

interface Context {
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
}

export const TranslationsContext = React.createContext<Context | undefined>(undefined);

export const useTranslationsContext = () => {
  const context = React.useContext(TranslationsContext);

  if (!isDefined(context)) {
    throw new Error('TranslationsContext can not be used outside the scope of TranslationsContextProvider');
  }

  return context;
};

export const TranslationsContextProvider = ({ children }: React.WithChildren) => {
  const [isEditing, setIsEditing] = React.useState(false);

  return (
    <TranslationsContext.Provider
      value={{
        isEditing,
        setIsEditing,
      }}
    >
      {children}
    </TranslationsContext.Provider>
  );
};
