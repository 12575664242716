/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 106;
const ORIGINAL_HEIGHT = 29;
const PROPORTION = ORIGINAL_HEIGHT / ORIGINAL_WIDTH;

export const PayPalLogo = ({ width = ORIGINAL_WIDTH }: IconComponentProps) => {
  return (
    <svg width={width} height={width * PROPORTION} viewBox="0 0 106 29" fill="none">
      <path
        d="M39.504 6.491h-5.847a.816.816 0 0 0-.802.68l-2.365 14.88a.48.48 0 0 0 .278.515.49.49 0 0 0 .204.044h2.791a.815.815 0 0 0 .803-.681l.638-4.014a.804.804 0 0 1 .274-.487.816.816 0 0 1 .528-.194h1.85c3.851 0 6.074-1.85 6.654-5.515.262-1.604.012-2.864-.745-3.746-.83-.97-2.305-1.482-4.261-1.482Zm.674 5.435c-.32 2.082-1.922 2.082-3.472 2.082h-.882l.619-3.889a.483.483 0 0 1 .48-.408h.405c1.056 0 2.052 0 2.566.598.307.356.401.885.284 1.617Zm16.801-.067h-2.8a.49.49 0 0 0-.48.408l-.125.777-.195-.282c-.607-.873-1.958-1.165-3.307-1.165-3.094 0-5.736 2.326-6.25 5.589-.268 1.627.112 3.183 1.042 4.268.854.998 2.074 1.414 3.527 1.414 2.492 0 3.875-1.591 3.875-1.591l-.125.772a.48.48 0 0 0 .276.515.49.49 0 0 0 .204.045h2.522a.816.816 0 0 0 .528-.193.805.805 0 0 0 .275-.488l1.513-9.511a.48.48 0 0 0-.48-.558Zm-3.902 5.408c-.27 1.588-1.54 2.653-3.159 2.653-.813 0-1.462-.259-1.88-.749-.413-.487-.57-1.18-.439-1.952.252-1.574 1.543-2.675 3.137-2.675.795 0 1.442.262 1.867.757.427.5.596 1.197.474 1.966Zm18.812-5.408h-2.813a.82.82 0 0 0-.673.354l-3.88 5.673-1.645-5.452a.808.808 0 0 0-.78-.575h-2.764a.49.49 0 0 0-.483.413.48.48 0 0 0 .02.226l3.1 9.027-2.914 4.082a.481.481 0 0 0 .398.763h2.81a.817.817 0 0 0 .667-.346l9.357-13.406a.482.482 0 0 0-.4-.76Z"
        fill="#253B80"
      />
      <path
        d="M81.203 6.491h-5.847a.816.816 0 0 0-.527.193.804.804 0 0 0-.275.488l-2.364 14.88a.479.479 0 0 0 .276.513.49.49 0 0 0 .204.045h3a.571.571 0 0 0 .561-.477l.671-4.218a.805.805 0 0 1 .275-.488.816.816 0 0 1 .527-.193h1.85c3.852 0 6.074-1.85 6.655-5.515.262-1.604.01-2.864-.746-3.746-.83-.97-2.303-1.482-4.26-1.482Zm.674 5.435c-.318 2.082-1.921 2.082-3.472 2.082h-.881l.62-3.889a.481.481 0 0 1 .48-.408h.404c1.055 0 2.052 0 2.566.597.307.357.4.886.284 1.618Zm16.8-.067H95.88a.487.487 0 0 0-.48.408l-.125.777-.196-.282c-.606-.873-1.957-1.165-3.306-1.165-3.094 0-5.735 2.326-6.25 5.588-.266 1.628.112 3.184 1.042 4.27.855.997 2.074 1.413 3.527 1.413 2.492 0 3.875-1.591 3.875-1.591l-.125.772a.48.48 0 0 0 .277.516.49.49 0 0 0 .205.044h2.52c.194 0 .382-.069.529-.194a.805.805 0 0 0 .274-.487l1.514-9.511a.48.48 0 0 0-.28-.514.492.492 0 0 0-.203-.044Zm-3.902 5.408c-.268 1.587-1.54 2.653-3.158 2.653-.812 0-1.463-.259-1.88-.75-.414-.486-.57-1.18-.44-1.952.254-1.573 1.543-2.674 3.138-2.674.795 0 1.441.262 1.867.757.428.5.597 1.197.473 1.966Zm7.203-10.368-2.4 15.152a.479.479 0 0 0 .277.514.49.49 0 0 0 .204.045h2.412a.809.809 0 0 0 .803-.682L105.64 7.05a.481.481 0 0 0-.48-.559h-2.701a.49.49 0 0 0-.481.41Z"
        fill="#179BD7"
      />
      <path
        d="m6.21 25.502.448-2.819-.996-.023H.906L4.211 1.861a.268.268 0 0 1 .268-.227h8.019c2.662 0 4.499.55 5.458 1.635.45.509.736 1.04.875 1.626.145.615.147 1.349.006 2.244l-.01.065v.574l.449.253c.343.172.652.406.91.688.385.436.634.989.739 1.645.108.674.072 1.477-.105 2.386-.205 1.045-.537 1.956-.985 2.7a5.57 5.57 0 0 1-1.56 1.698 6.355 6.355 0 0 1-2.101.94c-.775.2-1.658.302-2.626.302h-.624a1.872 1.872 0 0 0-1.856 1.572l-.047.254-.79 4.967-.036.183c-.01.058-.026.087-.05.106a.133.133 0 0 1-.082.03H6.211Z"
        fill="#253B80"
      />
      <path
        d="M19.701 7.27a13.92 13.92 0 0 1-.082.466c-1.057 5.389-4.675 7.25-9.295 7.25H7.97a1.14 1.14 0 0 0-1.129.961l-1.204 7.582-.342 2.15a.593.593 0 0 0 .343.635.606.606 0 0 0 .252.055h4.172c.494 0 .914-.356.992-.84l.04-.21.786-4.949.05-.271c.078-.486.498-.842.992-.842h.624c4.043 0 7.207-1.63 8.132-6.343.387-1.97.187-3.614-.836-4.77a3.985 3.985 0 0 0-1.142-.874Z"
        fill="#179BD7"
      />
      <path
        d="M18.597 6.832a8.393 8.393 0 0 0-1.028-.226 13.163 13.163 0 0 0-2.074-.15H9.21a.993.993 0 0 0-.99.842l-1.338 8.404-.038.245c.041-.267.178-.511.385-.687.207-.176.471-.273.744-.273h2.353c4.62 0 8.238-1.863 9.295-7.25.032-.16.058-.315.082-.467a5.655 5.655 0 0 0-.87-.364 7.644 7.644 0 0 0-.236-.074Z"
        fill="#222D65"
      />
      <path
        d="M8.218 7.297a.99.99 0 0 1 .339-.603c.181-.154.413-.238.652-.238h6.285c.744 0 1.44.049 2.074.15.429.067.852.168 1.266.3.312.103.602.224.87.364.314-1.991-.003-3.347-1.088-4.575C17.42 1.343 15.26.765 12.499.765H4.481c-.564 0-1.045.407-1.133.961L.008 22.739a.68.68 0 0 0 .392.727c.09.042.189.063.288.063h4.95l1.243-7.827 1.337-8.405Z"
        fill="#253B80"
      />
    </svg>
  );
};
