/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;

export const DelwinStockWithDates = ({ width = ORIGINAL_WIDTH }: IconComponentProps) => {
  return (
    <svg width={width} height={width} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.7586 4.46244C9.72465 4.24678 8.65243 4.35669 7.6762 4.77977C6.69956 5.20302 5.85972 5.92224 5.26658 6.85135C4.67325 7.78075 4.35484 8.87657 4.35484 10C4.35484 11.1234 4.67325 12.2193 5.26658 13.1487C5.85972 14.0778 6.69956 14.797 7.6762 15.2202C8.65243 15.6433 9.72465 15.7532 10.7586 15.5376C11.7929 15.3218 12.7473 14.789 13.4992 14.0012C13.7575 13.7306 14.1863 13.7206 14.4569 13.9789C14.7276 14.2372 14.7376 14.666 14.4792 14.9366C13.5423 15.9183 12.3444 16.5908 11.0353 16.8639C9.72591 17.137 8.36895 16.9971 7.13746 16.4634C5.90638 15.9298 4.85916 15.0283 4.12461 13.8777C3.39025 12.7274 3 11.378 3 10C3 8.62201 3.39025 7.27262 4.12461 6.12231C4.85916 4.97171 5.90638 4.07017 7.13746 3.53665C8.36895 3.00295 9.7259 2.86305 11.0353 3.13614C12.3441 3.40913 13.5418 4.08143 14.4787 5.06283C14.4785 5.06265 14.4789 5.06301 14.4787 5.06283L15.6452 6.28038V4.51804C15.6452 4.14391 15.9485 3.84062 16.3226 3.84062C16.6967 3.84062 17 4.14391 17 4.51804V7.96671C17 8.34084 16.6967 8.64413 16.3226 8.64413H13.0283C12.6542 8.64413 12.3509 8.34084 12.3509 7.96671C12.3509 7.59259 12.6542 7.2893 13.0283 7.2893H14.7355L13.4992 5.9988C12.7473 5.21101 11.7929 4.67815 10.7586 4.46244Z"
        fill="#848484"
      />
      <path
        d="M9.8889 14C9.8889 12.2827 11.2796 10.8889 12.9969 10.8889C14.7173 10.8889 16.1111 12.2827 16.1111 14C16.1111 15.7173 14.7173 17.1111 12.9969 17.1111C11.2796 17.1111 9.8889 15.7173 9.8889 14ZM12.9093 12.3796H12.9288C13.0583 12.3796 13.1621 12.4833 13.1621 12.613V14.0842L14.4163 14.8297C14.5297 14.8944 14.5653 15.0403 14.4972 15.1505C14.4324 15.2606 14.2899 15.2931 14.1797 15.2283L12.8347 14.4213C12.7343 14.363 12.676 14.256 12.676 14.1426V12.613C12.676 12.4833 12.7797 12.3796 12.9093 12.3796Z"
        fill="#EC7A09"
      />
      <path
        d="M12.676 14.1426C12.676 14.256 12.7343 14.363 12.8347 14.4213L14.1797 15.2283C14.1819 15.2296 14.1841 15.2308 14.1863 15.2321C14.2956 15.2917 14.4337 15.2584 14.4972 15.1505C14.5649 15.0409 14.5301 14.8961 14.4182 14.8308L14.4163 14.8297L13.1621 14.0842V12.613C13.1621 12.4833 13.0583 12.3796 12.9288 12.3796H12.9093C12.7797 12.3796 12.676 12.4833 12.676 12.613V14.1426ZM9 14C9 11.7929 10.7875 10 12.9969 10C15.2077 10 17 11.7912 17 14C17 16.2088 15.2077 18 12.9969 18C10.7875 18 9 16.2071 9 14ZM12.9969 10.8889C11.2796 10.8889 9.8889 12.2827 9.8889 14C9.8889 15.7173 11.2796 17.1111 12.9969 17.1111C14.7173 17.1111 16.1111 15.7173 16.1111 14C16.1111 12.2827 14.7173 10.8889 12.9969 10.8889Z"
        fill="white"
      />
    </svg>
  );
};
