import { Skeleton } from '../../../Skeleton/Skeleton';

import styles from './MatrixSkeleton.module.scss';

export const MatrixSkeleton = () => {
  return (
    <div className={styles.matrixSkeleton}>
      <Skeleton type="rectangle" className={styles.delwin} />
      <Skeleton type="rectangle" className={styles.matrix} />
      <Skeleton type="rectangle" className={styles.image} />
      <Skeleton type="text" rows={3} size="medium" gap="small" containerClassName={styles.title} />
    </div>
  );
};
