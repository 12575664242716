import { Id, Navigation } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getMarkets } from '../../../../../ducks';
import { usePopover } from '../../../../../utils/hooks/usePopover';
import { isDefined } from '../../../../../utils/is';
import { isEmpty } from '../../../../../utils/isEmpty';
import { ExpandableElement } from '../../../../various/ExpandableContainer/ExpandableElement';
import { Input } from '../../../../various/Fields/Input';
import { Switch } from '../../../../various/Fields/Switch';
import { FocusTrap } from '../../../../various/FocusTrap';
import { FieldLabel } from '../../../../various/Form/FormField';
import { IconType } from '../../../../various/Icon';
import { Button } from '../../../../various/NewButton';
import { PopoverSheet } from '../../../../various/Popover/PopoverSheet';
import { ListItem } from '../../../ListItem';
import { ListItemHead } from '../../../ListItem/ListItemHead';
import { ListItemTitle } from '../../../ListItem/ListItemTitle';
import { MenuItemMarkets } from '../../../MenuItemMarkets';
import { useMenuSectionContext } from '../../context/MenuSectionContext';
import { MarketSelectForm } from '../../Forms/MarketSelectForm/MarketSelectForm';

import styles from './AddedMenuItem.module.scss';
import { AddedMenuItemActions } from './AddedMenuItemActions';

interface Props {
  onRemoveMenuItem: (id: Id) => void;
  onLabelChange: (value: string, id: Id) => void;
  onToggleNewTab: (value: boolean, id: Id) => void;
  onMarketsChange: (value: string[], id: Id) => void;
  onToggleOpen: (id: Id) => void;
  isOpen: boolean;
  item: Navigation.LinkMenuItem;
}

export const LinkMenuItem = ({ onRemoveMenuItem, onLabelChange, onToggleNewTab, onMarketsChange, isOpen, onToggleOpen, item }: Props) => {
  const allMarkets = useSelector(getMarkets);
  const { t } = useTranslation(['menu', 'common', 'settings']);
  const [isMarketSelectVisible, setIsMarketSelectVisible] = React.useState<boolean>(false);
  const { areInputsDisabled, childIdsWithMarketsMismatch } = useMenuSectionContext();
  const handleLabelChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onLabelChange(event.target.value, item.id);
    },
    [onLabelChange, item.id],
  );

  const handleToggleNewTab = React.useCallback(
    (isChecked: boolean) => {
      if (isChecked === item.openNewTab) {
        return;
      }

      onToggleNewTab(isChecked, item.id);
    },
    [onToggleNewTab, item],
  );

  const handleMarketsChange = React.useCallback(
    (value: string[]) => {
      onMarketsChange(value, item.id);
    },
    [onMarketsChange, item],
  );

  const handleRemoveMenuItem = React.useCallback(() => {
    onRemoveMenuItem(item.id);
  }, [onRemoveMenuItem, item.id]);

  const handleToggleOpen = React.useCallback(() => {
    onToggleOpen(item.id);
  }, [item.id, onToggleOpen]);

  const existingMarkets = React.useMemo(
    () => item.markets?.map(marketId => allMarkets.find(market => market.id === marketId)).filter(isDefined) ?? [],
    [allMarkets, item.markets],
  );
  const marketNames = existingMarkets.map(market => market.name);

  const { layerProps, triggerProps, layerSide, renderLayer } = usePopover({
    escapeHandlerEnabled: isMarketSelectVisible,
    layerProps: {
      isOpen: isMarketSelectVisible,
      placement: 'top-center',
      triggerOffset: 0,
    },
    onVisibleChange: setIsMarketSelectVisible,
  });

  if (!isDefined(item)) {
    return null;
  }

  return (
    <ListItem isOpen={isOpen}>
      <ListItemHead
        action={
          <AddedMenuItemActions
            label={item.label}
            id={item.id}
            handleRemoveMenuItem={handleRemoveMenuItem}
            handleToggleOpen={handleToggleOpen}
            isOpen={isOpen}
          />
        }
      >
        <ListItemTitle name={item.label} url={item.url} isLink></ListItemTitle>
      </ListItemHead>
      {renderLayer(
        <PopoverSheet {...layerProps} isOpen={isMarketSelectVisible} layerSide={layerSide}>
          <FocusTrap shouldReturnFocus>
            <MarketSelectForm
              onClose={() => setIsMarketSelectVisible(false)}
              onSubmit={newMarkets => {
                handleMarketsChange(newMarkets);
                setIsMarketSelectVisible(false);
              }}
              selectedMarkets={item.markets ?? []}
            />
          </FocusTrap>
        </PopoverSheet>,
      )}
      <div className={cx(styles.linkMarkets, { [styles.expanded]: !isOpen })}>
        <MenuItemMarkets
          markets={marketNames}
          isMissingMarket={childIdsWithMarketsMismatch.includes(item.id) || isEmpty(marketNames)}
          emptyMarketsInfo={t('menu:no_markets_assigned')}
        />
        {isOpen && (
          <span {...triggerProps}>
            <Button
              variant="ghost"
              icon={IconType.EditLinear}
              onClick={() => {
                setIsMarketSelectVisible(true);
              }}
            >
              {t('common:edit')}
            </Button>
          </span>
        )}
      </div>
      <ExpandableElement id={`menuItemOptions-${item.id}`} isOpen={isOpen}>
        <div className={styles.nameInput}>
          <FieldLabel size="regular" label={t('settings:navigation_label')} isRequired>
            <Input
              value={item.label}
              size="regular"
              isInvalid={isEmpty(item.label)}
              isDisabled={areInputsDisabled}
              onChange={handleLabelChange}
            />
          </FieldLabel>
        </div>
        <div className={styles.expandColumns}>
          <label htmlFor={`newTab-${item.id}`} className={styles.navLabel}>
            {t('common:open_in_new_tab')}
          </label>
          <Switch
            size="small"
            id={`newTab-${item.id}`}
            onValueChange={handleToggleNewTab}
            checked={item.openNewTab}
            disabled={areInputsDisabled}
          />
        </div>
      </ExpandableElement>
    </ListItem>
  );
};
