import { Dates, Filters, Sorting } from '@typings';
import { createAction } from 'typesafe-actions';

export const loadProductsRequest = createAction('products/PRODUCTS_LOAD_REQUEST')();
export const loadProductsSuccess = createAction('products/PRODUCTS_LOAD_SUCCESS')<Responses.Products>();
export const loadProductsFailure = createAction('products/PRODUCTS_LOAD_FAILURE')();
export const loadMoreProductsRequest = createAction('products/PRODUCTS_LOAD_MORE_REQUEST')();
export const loadMoreProductsSuccess = createAction('products/PRODUCTS_LOAD_MORE_SUCCESS')<Responses.Products>();
export const loadMoreProductsFailure = createAction('products/PRODUCTS_LOAD_MORE_FAILURE')();

export const setEtaFilter = createAction('products/FILTERS_ETA_SET')<Pick<Filters, 'etaFrom' | 'etaTo'>>();
export const resetFilters = createAction('products/FILTERS_RESET')();
export const updateFilters = createAction('products/FILTERS_UPDATE')<{
  filters: Partial<Filters>;
  preserveCurrent: boolean;
}>();
export const setExpandedProductFilters = createAction('products/SET_EXPANDED_FILTERS')<Record<string, boolean>>();
export const setInitialFilters = createAction('products/FILTERS_SET_INITIAL')<Partial<Filters>>();
export const setProductsEtaDates = createAction('products/PRODUCTS_ETA_DATES_SET')<Dates.DateAsSQLString[]>();
export const setProductSortingValue = createAction('products/SORTING_VALUE_SET')<Sorting.FieldValue>();
export const setProductSortingDefaultValue = createAction('products/SORTING_DEFAULT_VALUE_SET')<Sorting.FieldValue>();
