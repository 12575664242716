import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getIsSeller } from '../../../../../ducks';
import { useAllProductsLink } from '../../../../../utils/hooks';
import { EmptyState } from '../../../../various/EmptyState';
import { Link } from '../../../../various/Link';
import { Button } from '../../../../various/NewButton';

export const ProductNotFoundContent = () => {
  const { t } = useTranslation(['products']);
  const { id } = useParams<{ id: string }>();
  const allProductsLink = useAllProductsLink(id);
  const isUserSeller = useSelector(getIsSeller);

  const notFoundMessage = isUserSeller ? t('products:product_not_found_seller') : t('products:product_not_found_buyer');

  return (
    <EmptyState title={notFoundMessage}>
      <Button as={Link} variant="bordered" color="dark" to={allProductsLink}>
        {t('products:see_all_products')}
      </Button>
    </EmptyState>
  );
};
