import { Checkboxes, Overview } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getOrderDeliveryWindowsIds } from '../../../ducks';
import { generateAllRowLevels, getDimensionValues, getFilteredVariants } from '../../../logic/selectionOverview';
import { useOnValueChange } from '../../../utils/hooks';
import { useCheckboxGroupState } from '../../../utils/hooks/checkboxGroup/useCheckboxGroupState';
import { useSelectionOverviewAttributes } from '../../../utils/hooks/selectionOverview/useSelectionOverviewAttributes';
import { useSelectionOverviewVariants } from '../../../utils/hooks/selectionOverview/useSelectionOverviewVariants';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';

interface Context {
  percentageBase: Overview.PercentageBase;
  setPercentageBase: (base: Overview.PercentageBase) => void;
  deliveryWindowBase: Overview.DeliveryWindowBase;
  setDeliveryWindowBase: (base: Overview.DeliveryWindowBase) => void;
  primaryDimensionKey: string;
  setPrimaryDimensionKey: (dimensionKey: string) => void;
  secondaryDimensionKey: Nullable<string>;
  setSecondaryDimensionKey: (dimensionKey: Nullable<string>) => void;
  shouldShowWithUnits: boolean;
  setShouldShowWithUnits: (showWithUnits: boolean) => void;
  rowFilterOptionsState: Checkboxes.OptionsState;
  toggleRowFilterOptionsState: (changedValue: string) => void;
  deselectAllRowFilterOptions: () => void;
  filteredVariants: Overview.Variant[];
  overviewAttributes: Record<string, Overview.Dimension>;
  rowFilterOptions: Checkboxes.Option[];
}

const initialContext: Context = {
  deliveryWindowBase: 'all',
  deselectAllRowFilterOptions: () => null,
  filteredVariants: [],
  overviewAttributes: {},
  percentageBase: 'units',
  primaryDimensionKey: 'categoryPathIds',
  rowFilterOptions: [],
  rowFilterOptionsState: {},
  secondaryDimensionKey: null,
  setDeliveryWindowBase: () => null,
  setPercentageBase: () => null,
  setPrimaryDimensionKey: () => null,
  setSecondaryDimensionKey: () => null,
  setShouldShowWithUnits: () => null,
  shouldShowWithUnits: true,
  toggleRowFilterOptionsState: () => null,
};

export const SelectionOverviewContext = React.createContext(initialContext);

export const SelectionOverviewContextProvider = ({ children }: React.WithChildren) => {
  const { t } = useTranslation(['common']);
  const params = useParams<{ id?: string }>();
  const deliveryWindowsIds = useSelector(getOrderDeliveryWindowsIds);

  const [percentageBase, setPercentageBase] = React.useState(initialContext.percentageBase);
  const [deliveryWindowBase, setDeliveryWindowBase] = React.useState(initialContext.deliveryWindowBase);
  const [primaryDimensionKey, setPrimaryDimensionKey] = React.useState(initialContext.primaryDimensionKey);
  const [secondaryDimensionKey, setSecondaryDimensionKey] = React.useState(initialContext.secondaryDimensionKey);
  const [shouldShowWithUnits, setShouldShowWithUnits] = React.useState<boolean>(initialContext.shouldShowWithUnits);

  const { overviewVariants } = useSelectionOverviewVariants({
    deliveryWindowBase,
    shouldShowWithUnits,
  });
  const overviewAttributes = useSelectionOverviewAttributes();

  React.useEffect(() => {
    if (deliveryWindowBase !== 'all' && !deliveryWindowsIds.includes(deliveryWindowBase)) {
      setDeliveryWindowBase('all');
    }
  }, [deliveryWindowsIds, deliveryWindowBase]);

  const resetSettings = React.useCallback(() => {
    setPercentageBase('units');
    setDeliveryWindowBase('all');
    setPrimaryDimensionKey('category');
    setSecondaryDimensionKey(null);
    setShouldShowWithUnits(true);
  }, []);

  useOnValueChange(params.id, resetSettings);

  const allPrimaryDimensionValues = getDimensionValues(overviewVariants, overviewAttributes[primaryDimensionKey]);
  const rowFilterOptions = generateAllRowLevels(allPrimaryDimensionValues).reduce((acc, values) => {
    const value = Array.isArray(values.key) ? values.key[values.key.length - 1] : values.key;

    if (!isDefined(value)) {
      return acc;
    }

    const name = values.name.toString();
    const parentValue = Array.isArray(values.key) ? values.key[values.key.length - 2] : undefined;

    return [
      ...acc,
      {
        name: isEmpty(name) ? t('common:none') : name,
        parentValue,
        value,
      },
    ];
  }, []);

  const { optionsState, selectedValues, toggleOptionState, deselectAll } = useCheckboxGroupState({
    options: rowFilterOptions,
  });

  const primaryAttributeUri = overviewAttributes[primaryDimensionKey]?.uri;
  const filteredVariants = getFilteredVariants(overviewVariants, selectedValues, primaryAttributeUri);

  return (
    <SelectionOverviewContext.Provider
      value={{
        deliveryWindowBase,
        deselectAllRowFilterOptions: deselectAll,
        filteredVariants,
        overviewAttributes,
        percentageBase,
        primaryDimensionKey,
        rowFilterOptions,
        rowFilterOptionsState: optionsState,
        secondaryDimensionKey,
        setDeliveryWindowBase,
        setPercentageBase,
        setPrimaryDimensionKey,
        setSecondaryDimensionKey,
        setShouldShowWithUnits,
        shouldShowWithUnits,
        toggleRowFilterOptionsState: toggleOptionState,
      }}
    >
      {children}
    </SelectionOverviewContext.Provider>
  );
};
