import { Modals } from '@typings';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getIsModalVisible, hideModal as hide, showModal as show } from '../../ducks';

export const useModalVisibility = (modalName: Modals.Type) => {
  const dispatch = useDispatch();
  const isModalVisible = useSelector(getIsModalVisible(modalName));

  const hideModal = React.useCallback(() => {
    dispatch(hide(modalName));
  }, [dispatch, modalName]);

  const showModal = React.useCallback(() => {
    dispatch(show(modalName));
  }, [dispatch, modalName]);

  return {
    hideModal,
    isModalVisible,
    showModal,
  };
};
