import React from 'react';
import { useTranslation } from 'react-i18next';

import { getPageIdsGroupedBySlug, isPublishedPage, isPublishedWithChanges } from '../../../../logic/pages';
import { includesSearchIgnoreCase } from '../../../../utils/includes';
import { isDefined } from '../../../../utils/is';
import { isEmpty } from '../../../../utils/isEmpty';
import { Input } from '../../../various/Fields/Input';
import { IconType } from '../../../various/Icon';
import { Notification } from '../../../various/Notification/Notification';
import { Sortable } from '../../../various/Sortable';
import { useMenuSectionContext } from '../context/MenuSectionContext';
import { AvailableMenuItem } from '../MenuItems';

import styles from './Forms.module.scss';

export const PagesForm = () => {
  const { addedMenuItems, areInputsDisabled, setSearchPhrase, searchPhrase, pagesRecords } = useMenuSectionContext();

  const { t } = useTranslation(['common', 'settings']);

  const publishedPages = Object.values(pagesRecords).filter(page => isPublishedPage(page) || isPublishedWithChanges(page));
  const groupedPageIds = getPageIdsGroupedBySlug(publishedPages);

  const addedIds = addedMenuItems.reduce((acc, cur) => {
    const childItems = cur.children?.map(child => child.pageId) ?? [];

    return [...acc, cur.pageId, ...childItems];
  }, []);

  const notAddedPageIds = groupedPageIds.filter(id => !addedIds.includes(id));

  const filteredNotAddedPageIds =
    isEmpty(searchPhrase) ? notAddedPageIds : (
      notAddedPageIds.filter(id => {
        const { name = '', slug = '', snapshot } = pagesRecords[id] ?? {};

        return isDefined(snapshot) ?
            includesSearchIgnoreCase(snapshot.name, searchPhrase) || includesSearchIgnoreCase(snapshot.slug, searchPhrase)
          : includesSearchIgnoreCase(name, searchPhrase) || includesSearchIgnoreCase(slug, searchPhrase);
      })
    );

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchPhrase(event.target.value);
  };

  return (
    <>
      <label aria-label={t('settings:page_search_placeholder')} className={styles.search}>
        <Input
          icon={IconType.Search}
          placeholder={t('settings:page_search_placeholder')}
          value={searchPhrase}
          isClearable
          onChange={handleSearch}
          isDisabled={areInputsDisabled}
        />
      </label>
      {isEmpty(filteredNotAddedPageIds) ?
        <Notification className={styles.notification}>{t('common:no_results')}</Notification>
      : <Sortable items={filteredNotAddedPageIds} renderItem={id => <AvailableMenuItem pageId={id.toString()} />} isSortDisabled />}
    </>
  );
};
