import { Cms } from '@typings';

import { isButtonPart } from '../../../../../../logic/cms/styles';
import { getContentSetStyles } from '../../../../../../logic/pages';
import { useSpecifiedDeviceWidth } from '../../../../../../utils/hooks';
import { isDefined } from '../../../../../../utils/is';
import { ButtonPart } from '../Button/ButtonPart';
import { TextPart } from '../Text/TextPart';

import styles from './ContentSet.module.scss';

interface Props {
  groupId: Maybe<string>;
  blockId: Nullable<string>;
  parameters: Cms.ContentBlockText;
}

export const StandardContentSet = ({ blockId, parameters }: Props) => {
  const screenWidth = useSpecifiedDeviceWidth();
  const style = getContentSetStyles(screenWidth, parameters);

  return (
    <div className={styles.textWrapper} style={style}>
      {parameters.partsOrder.map((partId, idx) => {
        const part = parameters.parts[partId];

        if (!isDefined(part)) {
          return null;
        }

        if (isButtonPart(part)) {
          return <ButtonPart key={partId} parameters={part} screenWidth={screenWidth} index={idx} blockId={blockId} partId={partId} />;
        }

        return <TextPart key={partId} parameters={part} screenWidth={screenWidth} index={idx} blockId={blockId} />;
      })}
    </div>
  );
};
