import type { UniqueIdentifier } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import type { FlattenedItem, SortableNestItems, SortableTreeItem, SortableTreeItemProjection } from '@typings/components/sortableTree';

import { isDefined } from '../../../../utils/is';
export const iOS = /iPad|iPhone|iPod/.test(navigator.platform);

const getDragDepth = (offset: number, indentationWidth: number) => {
  return Math.round(offset / indentationWidth);
};

export const getProjection = ({
  items,
  activeId,
  overId,
  dragOffset,
  indentationWidth,
  maxAllowedDepth,
}: {
  items: FlattenedItem[];
  activeId: UniqueIdentifier;
  overId: UniqueIdentifier;
  dragOffset: number;
  indentationWidth: number;
  maxAllowedDepth?: number;
}): SortableTreeItemProjection => {
  const getParentId = () => {
    if (depth === 0 || !previousItem) {
      return null;
    }

    if (depth === previousItem.depth) {
      return previousItem.parentId;
    }

    if (depth > previousItem.depth) {
      return previousItem.id;
    }

    const newParent = newItems
      .slice(0, overItemIndex)
      .reverse()
      .find(item => item.depth === depth)?.parentId;

    return newParent ?? null;
  };
  const overItemIndex = items.findIndex(({ id }) => id === overId);
  const activeItemIndex = items.findIndex(({ id }) => id === activeId);
  const activeItem = items[activeItemIndex];
  const newItems = arrayMove(items, activeItemIndex, overItemIndex);
  const previousItem = newItems[overItemIndex - 1];
  const nextItem = newItems[overItemIndex + 1];
  const dragDepth = getDragDepth(dragOffset, indentationWidth);
  const projectedDepth = (activeItem?.depth ?? 0) + dragDepth;
  const maxDepth = getMaxDepth({
    maxAllowedDepth,
    previousItem,
  });
  const minDepth = getMinDepth({ nextItem });
  let depth = projectedDepth;

  if (projectedDepth >= maxDepth) {
    depth = maxDepth;
  } else if (projectedDepth < minDepth) {
    depth = minDepth;
  }

  return { depth, maxDepth, minDepth, parentId: getParentId() };
};

const getMaxDepth = ({
  previousItem,
  maxAllowedDepth = Number.MAX_VALUE,
}: {
  previousItem: FlattenedItem | undefined;
  maxAllowedDepth?: number;
}) => {
  if (isDefined(previousItem)) {
    return previousItem.depth < maxAllowedDepth ? previousItem.depth + 1 : maxAllowedDepth;
  }

  return 0;
};

const getMinDepth = ({ nextItem }: { nextItem: FlattenedItem | undefined }) => {
  if (isDefined(nextItem)) {
    return nextItem.depth;
  }

  return 0;
};

const flatten = (items: SortableNestItems, parentId: UniqueIdentifier | null = null, depth = 0): FlattenedItem[] => {
  return items.flatMap((item, index) => [{ ...item, depth, index, parentId }, ...flatten(item.children, item.id, depth + 1)]);
};

export const flattenTree = (items: SortableNestItems): FlattenedItem[] => {
  return flatten(items);
};

export const buildTree = (flattenedItems: FlattenedItem[]): SortableNestItems => {
  const root: SortableTreeItem = { children: [], id: 'root' };
  const nodes: Record<string, SortableTreeItem> = { [root.id]: root };
  const items = flattenedItems.map(item => ({ ...item, children: [] }));

  for (const item of items) {
    const { id, children } = item;
    const parentId = item.parentId ?? root.id;
    const parent = nodes[parentId] ?? getItemById(items, parentId);

    // eslint-disable-next-line functional/immutable-data
    nodes[id] = { children, id };
    // eslint-disable-next-line clean-codestyle/no-foreach-push, functional/immutable-data
    parent?.children.push(item);
  }

  return root.children;
};

export const getItemById = (items: SortableTreeItem[], itemId: UniqueIdentifier) => {
  return items.find(({ id }) => id === itemId);
};

export const removeChildrenOf = (items: FlattenedItem[], ids: UniqueIdentifier[]) => {
  const isChildOrDescendantOf = (item: FlattenedItem, parentIds: UniqueIdentifier[]): boolean => {
    if (!item.parentId) {
      return false;
    }

    if (parentIds.includes(item.parentId)) {
      return true;
    }

    const parentItem = items.find(it => it.id === item.parentId);

    return parentItem ? isChildOrDescendantOf(parentItem, parentIds) : false;
  };

  return items.filter(item => !isChildOrDescendantOf(item, ids));
};
