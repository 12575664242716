import { Id } from '@typings/@centra';
import { createSelector } from 'reselect';

import { EMPTY_ARRAY } from '../../../constants/empty';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { isEveryElementInArray } from '../../../utils/isEveryElementInArray';
import { getCmsProducts } from '../products';

export const getCmsSections = (state: Store) => state.cms.sections;

export const getCmsSectionById = (sectionId: Nullable<string>) => (state: Store) =>
  state.cms.sections.find(section => section.id === sectionId);

export const getSectionIdByGroupId = (groupId: Maybe<Id>) => (state: Store) =>
  !isDefined(groupId) ? undefined : state.cms.sections.find(section => section.groupsOrder.includes(groupId))?.id;

export const getGroupsOrderBySectionId =
  (sectionId: Nullable<string>) =>
  (state: Store): Id[] =>
    state.cms.sections.find(({ id }) => sectionId === id)?.groupsOrder || EMPTY_ARRAY;

export const getIsFirstSection = (sectionId: Nullable<string>) => (state: Store) => {
  const sections = getCmsSections(state);

  return sections[0]?.id === sectionId;
};

export const getIsLastSection = (sectionId: Nullable<string>) => (state: Store) => {
  const sections = getCmsSections(state);

  return sections[sections.length - 1]?.id === sectionId;
};

export const getHasMissingProducts = (productsIds: string[], sectionId: Nullable<string>) =>
  createSelector([getCmsSectionById(sectionId), getCmsProducts], (section, cmsProducts) => {
    if (isEmpty(cmsProducts) || isEmpty(productsIds) || !isDefined(sectionId)) {
      return false;
    }

    const availableProducts = productsIds.filter(productId => {
      const product = cmsProducts[productId];

      if (!isDefined(product)) {
        return false;
      }

      const markets = product.markets.map(marketId => marketId.toString());

      return isEveryElementInArray(section?.visibleFor.marketIds, markets);
    });

    return productsIds.length - availableProducts.length > 0;
  });
