import { DeliveryWindow } from '@typings';
import cx from 'classnames';
import { prop, uniqBy } from 'ramda';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getActiveDeliveryWindowsIdsForOrderBuyer } from '../../ducks';
import { getOrderDetails } from '../../ducks/order';
import { getHumanTimePeriod } from '../../logic/deliveryWindows';
import { getIsProductInOrderByDelwinIds, getProductsCountInDelwin, getTotalPriceInDelwin, getUnitsCountInDelwin } from '../../logic/Orders';
import { formatPriceWithCurrencyAffix } from '../../logic/price';
import { getAllOrderedVariants } from '../../logic/products';
import { isDefined } from '../../utils/is';
import { isEmpty } from '../../utils/isEmpty';
import { alphabeticSortByKey } from '../../utils/objectSort';

import OrderProduct from './OrderProduct/OrderProduct';
import styles from './OrderDelwin.module.scss';

interface Props {
  delwin: DeliveryWindow.Mixed;
  sortBySKU: boolean;
}

export const OrderDelwin = ({ delwin, sortBySKU }: Props) => {
  const orderDetails = useSelector(getOrderDetails);
  const delwinId = delwin.deliveryWindow;
  const totalPrice = getTotalPriceInDelwin(delwinId, orderDetails.order.products);
  const { t } = useTranslation(['common', 'totals', 'products']);
  const activeDeliveryWindowsIds = useSelector(getActiveDeliveryWindowsIdsForOrderBuyer);
  const isExpired = !activeDeliveryWindowsIds.includes(delwinId);

  const delwinVariants = Object.values(orderDetails.products)
    .flatMap(getAllOrderedVariants)
    .filter(product => getIsProductInOrderByDelwinIds(product.product, [delwinId], orderDetails.order.products));

  const uniqueVariants = uniqBy(prop('product'), delwinVariants);
  const variants = sortBySKU ? alphabeticSortByKey(uniqueVariants, 'sku') : uniqueVariants;
  const { deliveryPeriod } = getHumanTimePeriod(delwin);

  if (isEmpty(variants)) {
    return null;
  }

  return (
    <div>
      <div className={cx(styles.delwinBar, { [styles.isExpired]: isExpired })}>
        <div>
          <div className={styles.subtleHeadingAlternate}>
            <div className={styles.text}>{delwin.name}</div>
            <div className={styles.subtleHeading}>{t('common:delivery_window_one')}</div>
          </div>
          {isDefined(deliveryPeriod) && (
            <div className={styles.subtleHeadingAlternate}>
              <div className={styles.text}>{deliveryPeriod}</div>
              <div className={styles.subtleHeading}>{t('common:delivery')}</div>
            </div>
          )}
        </div>
        <div className={styles.right}>
          <div className={styles.subtleHeadingAlternate}>
            <div className={styles.text}>{getProductsCountInDelwin(delwinId, orderDetails.order.products)}</div>
            <div className={styles.subtleHeading}>{t('products:product_other')}</div>
          </div>

          <div className={styles.subtleHeadingAlternate}>
            <div className={styles.text}>{getUnitsCountInDelwin(delwinId, orderDetails.order.products)}</div>
            <div className={styles.subtleHeading}>{t('common:unit_other')}</div>
          </div>

          <div className={styles.subtleHeadingAlternate}>
            <div className={styles.text}>{formatPriceWithCurrencyAffix(orderDetails.currency)(totalPrice)}</div>
            <div className={styles.subtleHeading}>{t('totals:total_one')}</div>
          </div>
        </div>
      </div>
      {variants.map(variant => (
        <OrderProduct delwinIds={[delwinId]} key={variant.product} orderProductVersion="delwin" productId={variant.product} />
      ))}
    </div>
  );
};
