import { Preset, Product, UnitsDistribution } from '@typings';
import { useController, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { itemsToChart } from '../../../../../../../ducks/helpers';
import { getNumericValue } from '../../../../../../../logic/validation';
import { isDefined } from '../../../../../../../utils/is';
import MeasurementChart from '../../../../../Chart';
import { Input } from '../../../../../Fields/Input';
import { Help } from '../../../../../Help';

import styles from './PresetsListElements.module.scss';

const MAX_VALUE = 99_999;
const INPUT_MAX_LENGTH = String(MAX_VALUE).length;

interface PresetNameCellProps {
  preset: Preset;
  variant: Product.Standard;
  name: string;
}

interface AdderCellProps {
  id: string;
  name: string;
}

interface TotalPriceCellProps {
  getTotalPrice: (count: number) => string;
}

interface PriceCellProps extends TotalPriceCellProps {
  cellId: string;
}

const getTotalPresetsCount = (formData: UnitsDistribution.QuantitiesFormData) =>
  Object.values(formData).reduce((acc = 0, item) => {
    if (!isDefined(item)) {
      return acc;
    }

    return acc + item;
  }, 0);

export const PresetNameCell = ({ preset, variant, name }: PresetNameCellProps) => {
  const { t } = useTranslation(['orders']);

  return (
    <td>
      <div className={styles.listElement}>
        {preset.name}
        <Help placement="top-end" triggerClassName={styles.help} className={styles.helpContent}>
          {t('orders:size_distribution_for')} <span className={styles.highlight}>{name}</span> [%]
          <MeasurementChart table={itemsToChart(preset.items, variant.itemTable)} />
        </Help>
      </div>
    </td>
  );
};

export const AdderCell = ({ id, name }: AdderCellProps) => {
  const { t } = useTranslation(['orders']);
  const { field } = useController<UnitsDistribution.QuantitiesFormData>({ name: id });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    field.onChange(getNumericValue(value));
  };

  return (
    <td>
      <label aria-label={t('orders:size_distribution_label', { presetName: name })}>
        <Input
          ref={field.ref}
          value={field.value === 0 ? '' : field.value}
          size="mini"
          className={styles.input}
          placeholder="0"
          maxLength={INPUT_MAX_LENGTH}
          inputMode="numeric"
          onBlur={field.onBlur}
          onChange={handleChange}
        />
      </label>
    </td>
  );
};

export const PriceCell = ({ getTotalPrice, cellId }: PriceCellProps) => {
  const cellValue = useWatch<UnitsDistribution.QuantitiesFormData>({ name: cellId });

  const presetsPrice = getTotalPrice(cellValue);

  return <td className={styles.priceCell}>{presetsPrice}</td>;
};

export const TotalPresetsPrice = ({ getTotalPrice }: TotalPriceCellProps) => {
  const formData = useWatch<UnitsDistribution.QuantitiesFormData>();
  const totalPresetsCount = getTotalPresetsCount(formData as UnitsDistribution.QuantitiesFormData);
  const totalPresetsPrice = getTotalPrice(totalPresetsCount);

  return <td className={styles.priceCell}>{totalPresetsPrice}</td>;
};

export const TotalPresetsCount = () => {
  const formData = useWatch<UnitsDistribution.QuantitiesFormData>();
  const totalPresets = getTotalPresetsCount(formData as UnitsDistribution.QuantitiesFormData);

  return <td>{totalPresets}</td>;
};
