import { Trans, useTranslation } from 'react-i18next';

import { Notification } from '../../various/Notification/Notification';

import styles from './ProductsList.module.scss';

export const NoProductsMessage = ({ markets }: { markets: string[] }) => {
  const { t } = useTranslation(['cms']);

  return (
    <Notification className={styles.noProductsMessage}>
      <p>{t('cms:no_products_available_for_section')}</p>
      <p>{t('cms:available_products_rules.title')}</p>
      <ul>
        <li>
          <Trans t={t} i18nKey="cms:available_products_rules.must_be_in.all">
            available in <strong>all</strong> of the markets set on the section
          </Trans>
          - &nbsp;<span className={styles.markets}>{markets.join(', ')}</span>
        </li>
        <li>
          <Trans t={t} i18nKey="cms:available_products_rules.must_be_in.at_least">
            available in <strong>at least</strong> one delivery window <strong>active for all</strong> the markets set on the section
          </Trans>
        </li>
      </ul>
    </Notification>
  );
};
