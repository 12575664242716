/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 16;
const ORIGINAL_COLOR = '#5d5d5d';

export const DownloadLinesheet = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => {
  return (
    <svg width={width} height={width} viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5601 6.92444L8.88894 8.59556V0.888889C8.88894 0.4 8.48894 0 8.00005 0C7.51116 0 7.11116 0.4 7.11116 0.888889V8.59556L5.43116 6.92444C5.0845 6.57778 4.5245 6.57778 4.17783 6.92444C4.01138 7.09052 3.91784 7.31598 3.91784 7.55111C3.91784 7.78624 4.01138 8.0117 4.17783 8.17778L7.36894 11.3689C7.71561 11.7156 8.27561 11.7156 8.62228 11.3689L11.8134 8.17778C12.1601 7.83111 12.1601 7.27111 11.8134 6.92444C11.4667 6.57778 10.9067 6.57778 10.5601 6.92444ZM14.2222 13.3333V8.88889C14.2222 8.4 14.6222 8 15.1111 8C15.6 8 16 8.4 16 8.88889V14.2222C16 15.2 15.2 16 14.2222 16H1.77778C0.8 16 0 15.2 0 14.2222V8.88889C0 8.4 0.4 8 0.888889 8C1.37778 8 1.77778 8.4 1.77778 8.88889V13.3333C1.77778 13.8222 2.17778 14.2222 2.66667 14.2222H13.3333C13.8222 14.2222 14.2222 13.8222 14.2222 13.3333Z"
        fill={color}
      />
    </svg>
  );
};
