import cx from 'classnames';
import React from 'react';

import { MovementOffset, useMovement } from '../../../../../utils/hooks';
import { ProductDetailsLayoutContext } from '../../context/ProductDetailsLayoutContext';
import { ProductDetailsModalContext } from '../../ProductDetailsModal';

interface Props {
  className?: string;
  lightboxRef: React.MutableRefObject<HTMLDivElement | null>;
}

const SWIPE_ANIMATION_DURATION = 300;
const AUTO_SWIPE_OFFSET = 100;
const BROWSER_EDGE_PROXIMITY_THRESHOLD = 40;

export const ProductSwiper = ({ children, className, lightboxRef }: React.WithChildren<Props>) => {
  const { hasNextProduct, hasPreviousProduct, switchProduct } = React.useContext(ProductDetailsModalContext);
  const { hasMobileDetailsLayout } = React.useContext(ProductDetailsLayoutContext);

  const statusRef = React.useRef<Nullable<'dragging' | 'disabled'>>(null);
  const [isDragging, setIsDragging] = React.useState(false);
  const [containerPosition, setContainerPosition] = React.useState(0);

  const handleDragStart = React.useCallback(
    ({ clientX }: MovementOffset) => {
      const edgeProximity = Math.min(clientX, (lightboxRef.current?.clientWidth ?? 0) - clientX);
      const isDraggingNearEdges = edgeProximity < BROWSER_EDGE_PROXIMITY_THRESHOLD;

      if (isDraggingNearEdges) {
        statusRef.current = 'disabled';
      }
    },
    [lightboxRef],
  );

  const handleDrag = React.useCallback(({ xOffset, yOffset }: MovementOffset) => {
    if (statusRef.current === 'disabled') {
      return;
    }

    const isDraggingVertically = Math.abs(yOffset) > Math.abs(xOffset);

    if (isDraggingVertically && statusRef.current !== 'dragging') {
      statusRef.current = 'disabled';

      return;
    }

    setIsDragging(true);
    setContainerPosition(xOffset);
    statusRef.current = 'dragging';
  }, []);

  const handleDragEnd = React.useCallback(
    ({ xOffset }: MovementOffset) => {
      if (statusRef.current === 'disabled') {
        statusRef.current = null;

        return;
      }

      setIsDragging(false);
      statusRef.current = null;

      if (Math.abs(xOffset) < AUTO_SWIPE_OFFSET) {
        setContainerPosition(0);

        return;
      }

      const lightboxWidth = lightboxRef.current?.clientWidth ?? 0;
      const direction = xOffset > 0 ? 'prev' : 'next';

      const shouldResetDrag = (direction === 'prev' && !hasPreviousProduct) || (direction === 'next' && !hasNextProduct);

      if (shouldResetDrag) {
        setContainerPosition(0);

        return;
      }

      setContainerPosition(direction === 'prev' ? lightboxWidth : -lightboxWidth);

      setTimeout(() => {
        switchProduct(direction);
      }, SWIPE_ANIMATION_DURATION);
    },
    [hasNextProduct, hasPreviousProduct, lightboxRef, switchProduct],
  );

  useMovement(
    lightboxRef,
    {
      onEndMovement: handleDragEnd,
      onMovement: handleDrag,
      onStartMovement: handleDragStart,
      useTouch: true,
    },
    hasMobileDetailsLayout,
  );

  const styles = {
    ...(!isDragging && { transition: `${SWIPE_ANIMATION_DURATION}ms transform` }),
    ...(containerPosition !== 0 && { transform: `translateX(${containerPosition}px)` }),
  };

  return (
    <div className={cx(className)} style={styles}>
      {children}
    </div>
  );
};
