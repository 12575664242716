import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getProductFilters, updateFilters } from '../../../ducks';
import { pushEventGTM } from '../../../services';
import { useDebouncedCallback, useSearchInput } from '../../../utils/hooks';
import { Input } from '../Fields/Input';
import { IconType } from '../Icon';

import { BarcodeScannerButton } from './BarcodeScannerButton';
import styles from './SearchFilter.module.scss';

export const SearchFilter = () => {
  const { t } = useTranslation(['common', 'products']);
  const dispatch = useDispatch();

  const filters = useSelector(getProductFilters);

  const handleDispatchSearchValue = (search: string) => {
    dispatch(updateFilters({ filters: { search }, preserveCurrent: true }));
    pushEventGTM({ event: 'filters-search' });
  };

  const { value, onChange, setValue } = useSearchInput({
    initialValue: filters.search,
    onChangeValue: useDebouncedCallback(handleDispatchSearchValue),
  });

  React.useEffect(() => {
    setValue(filters.search);
  }, [filters.search]);

  return (
    <>
      <div className={styles.header}>
        <span className={styles.title}>{t('common:search')}</span>
        <BarcodeScannerButton />
      </div>

      <label aria-label={t('products:search_for_product')}>
        <Input
          placeholder={t('products:product_search_placeholder')}
          icon={IconType.Search}
          size="small"
          value={value}
          isClearable
          onChange={onChange}
        />
      </label>
    </>
  );
};
