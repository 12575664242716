import { Cms } from '@typings';
import { useDispatch, useSelector } from 'react-redux';

import { addGroupFromTemplate, getCurrentGroupId, getCurrentSectionId } from '../../../ducks';
import { useModalVisibility } from '../../../utils/hooks';
import { isDefined } from '../../../utils/is';

import { TemplateModal } from './TemplateModal';

export const AddTemplateModal = () => {
  const dispatch = useDispatch();
  const sectionId = useSelector(getCurrentSectionId);
  const currentGroupId = useSelector(getCurrentGroupId);
  const { isModalVisible, hideModal } = useModalVisibility('AddTemplateModal');

  const handleAddTemplate = (model: Cms.BlockModel[]) => {
    if (!isDefined(sectionId)) {
      return;
    }

    dispatch(addGroupFromTemplate({ insertAfter: currentGroupId, model, sectionId }));
    hideModal();
  };

  return (
    <TemplateModal
      isModalVisible={isModalVisible}
      hideModal={hideModal}
      titleKey="cms:add_layout"
      handleClickTemplate={handleAddTemplate}
    />
  );
};
