import type { UniqueIdentifier } from '@dnd-kit/core';
import { forwardRef } from 'react';

import sortableStyles from '../../SortableList.module.scss';
import { Handle } from '../Handle';

interface Props {
  itemId: UniqueIdentifier;
  renderItem: (itemId: UniqueIdentifier) => JSX.Element | null;
  showHandle?: boolean;
  style?: React.CSSProperties;
  isHandleDisabled?: boolean;
}

export const Item = forwardRef<HTMLLIElement, Props>(({ showHandle, style, renderItem, itemId, isHandleDisabled }, ref) => {
  return (
    <li ref={ref} className={sortableStyles.item} style={style}>
      {showHandle && <Handle id={itemId} isDisabled={isHandleDisabled} />}
      {renderItem(itemId)}
    </li>
  );
});
