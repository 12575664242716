import { merge, Observable } from 'rxjs';
import { buffer, filter, map, mergeAll, withLatestFrom } from 'rxjs/operators';

export const holdWhile =
  <T>(state$: Observable<T>, predicate: (candidate: T) => boolean) =>
  <Data>(stream$: Observable<Data>) =>
    merge(
      stream$.pipe(
        withLatestFrom(state$),
        filter(([_, state]) => predicate(state)),
      ),
      stream$.pipe(
        withLatestFrom(state$),
        filter(([_, state]) => !predicate(state)),
        buffer(state$.pipe(filter(predicate))),
        mergeAll(),
      ),
    ).pipe(map(([action]) => action));
