import { Product } from '@typings';

import { isDefined } from '../../../../../utils/is';
import { useMatrixSettingsContext } from '../../../../products/ProductMatrix/context';
import { VariantImage } from '../../MatrixContents/VariantImage';
import { VariantInfo } from '../../MatrixContents/VariantInfo';

import { ConfigurationVariantCells } from './ConfigurationVariantCells';
import styles from './VariantBodyCells.module.scss';

interface Props {
  variant: Product.Standard | Product.Full;
  rowIndex: number;
  rowSpan?: number;
}

export const VariantBodyCells = ({ variant, rowIndex, rowSpan }: Props) => {
  const { productConfiguration } = useMatrixSettingsContext();

  const hasProductConfiguration = isDefined(productConfiguration);

  return (
    <>
      {hasProductConfiguration ?
        <ConfigurationVariantCells productConfiguration={productConfiguration} rowIndex={rowIndex} rowSpan={rowSpan} />
      : <th className={styles.variantImageCell} rowSpan={rowSpan}>
          <VariantImage variant={variant} />
        </th>
      }
      <th className={styles.variantInfoCell} rowSpan={rowSpan}>
        <VariantInfo variant={variant} />
      </th>
    </>
  );
};
