import cx from 'classnames';
import React from 'react';
import { Transition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';

import { useMatrixColumnsContext } from '../../../products/ProductMatrix/context';

import styles from './MatrixCells.module.scss';

interface Props {
  children: React.ReactElement<{ className?: string; style?: React.CSSProperties }>;
}

export const PinnableSummaryCell = ({ children }: Props) => {
  const { isTotalsColumnPinned, hasMoreContent, pixelsToScrollEnd } = useMatrixColumnsContext();

  const transitionedStyles = {
    transform: 'translateX(0px)',
    transition: 'transform 90ms ease-out',
  };

  const transitionStyles: Partial<Record<TransitionStatus, React.CSSProperties>> = {
    entered: transitionedStyles,
    entering: {
      transform: `translateX(${pixelsToScrollEnd}px)`,
    },
    exited: transitionedStyles,
    exiting: {
      transform: `translateX(${-pixelsToScrollEnd}px)`,
    },
  };

  const classNames = cx(
    {
      [styles.pinnedColumn]: isTotalsColumnPinned,
      [styles.fade]: hasMoreContent,
    },
    children.props.className,
  );

  return (
    <Transition timeout={0} in={isTotalsColumnPinned}>
      {status => {
        if (React.isValidElement(children)) {
          return React.cloneElement(children, { className: classNames, style: { ...children.props.style, ...transitionStyles[status] } });
        }

        return null;
      }}
    </Transition>
  );
};
