import { Cms, CmsBlocks } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { getIsCustomStyleEditorVisibleInBlock, getIsHotspotEditingInBlock, getIsRepositioningImageInBlock } from '../../../ducks';
import { useResizeObserver } from '../../../utils/hooks';
import { useResizeBlockHeight } from '../../../utils/hooks/cms/useResizeBlockHeight';
import { isDefined, isNull } from '../../../utils/is';
import { ContentBlockContextProvider } from '../../cms/context/ContentBlockContext';
import { ImageSizeContextProvider } from '../../cms/context/ImageSizeContext';
import { HotspotEditor } from '../../cms/HotspotEditor';
import { MediaPositioner } from '../../cms/MediaPositioner';

import { Background } from './parts/Background';
import { ContentSet } from './parts/ContentSet';
import { Embed } from './parts/Embed';
import { Hotspots } from './parts/Hotspots';
import { LinkWrapper } from './parts/LinkWrapper';
import { Overlay } from './parts/Overlay';
import styles from './contentBlock.module.scss';

export const createContentBlock =
  (extensions?: CmsBlocks.BlockExtensions<Cms.ContentBlock>) => (props: CmsBlocks.BlockProps<Cms.ContentBlock>) => {
    const { parameters, rowHeight } = props;
    const { id, settings, groupId } = parameters;

    const ref = React.useRef<HTMLDivElement | null>(null);
    const isCurrentBlockRepositioning = useSelector(getIsRepositioningImageInBlock(id));
    const isCurrentBlockHotspotEditing = useSelector(getIsHotspotEditingInBlock(id));
    const isCustomStyleEditorVisibleForCurrentBlock = useSelector(getIsCustomStyleEditorVisibleInBlock(id));
    const [width, setWidth] = React.useState(0);

    const { background, text, overlay, link, embed, hotspots } = settings;

    useResizeObserver(ref, () => {
      !isNull(ref.current) && setWidth(ref.current.offsetWidth);
    });

    const height = useResizeBlockHeight({ blockSettings: parameters, rowHeight, shouldApplyResize: true });

    React.useEffect(() => {
      requestAnimationFrame(() => isDefined(ref.current) && Object.assign(ref.current.style, { height: `${height}px` }));
    }, [ref.current, height]);

    const classNames = React.useMemo(
      () =>
        cx(styles.contentBlock, 'contentBlock', {
          [styles.highlightedBlock]:
            isCurrentBlockRepositioning || isCustomStyleEditorVisibleForCurrentBlock || isCurrentBlockHotspotEditing,
        }),
      [isCurrentBlockRepositioning, isCustomStyleEditorVisibleForCurrentBlock, isCurrentBlockHotspotEditing],
    );

    return (
      <>
        <ContentBlockContextProvider>
          <div className={classNames} ref={ref}>
            <LinkWrapper link={link}>
              <ImageSizeContextProvider>
                {isDefined(background) && <Background parameters={background} />}
                {isDefined(overlay) && <Overlay parameters={overlay} />}
                {isCurrentBlockRepositioning && <MediaPositioner />}
                {isDefined(text) && <ContentSet parameters={text} blockId={id} groupId={groupId} />}
                {isDefined(embed) && <Embed parameters={embed} />}
                {isCurrentBlockHotspotEditing && isDefined(background) && (
                  <HotspotEditor initialHotspots={hotspots} blockId={id} groupId={groupId} background={background} />
                )}
                {isDefined(hotspots) && isDefined(background) && !isCurrentBlockHotspotEditing && (
                  <Hotspots groupId={groupId} blockId={id} background={background} hotspots={hotspots} blockSize={{ height, width }} />
                )}
              </ImageSizeContextProvider>
            </LinkWrapper>
          </div>
          {isDefined(extensions) && Object.entries(extensions).map(([name, Extension]) => <Extension key={name} {...props} />)}
        </ContentBlockContextProvider>
      </>
    );
  };
