import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getMarkets } from '../../../../ducks';
import { isDefined } from '../../../../utils/is';
import { DropdownRadioGroup } from '../../../various/Dropdown';
import { useMenuSectionContext } from '../context/MenuSectionContext';

export const MarketSelector = () => {
  const { t } = useTranslation(['common', 'settings']);
  const markets = useSelector(getMarkets);

  const { selectedMarket, isInEditMode, setSelectedMarket } = useMenuSectionContext();

  if (!isInEditMode) {
    return null;
  }

  const handleChange = (value: string) => {
    if (value === 'allMarkets') {
      return setSelectedMarket(null);
    }

    setSelectedMarket(value);
  };

  const handleClear = () => {
    setSelectedMarket(null);
  };

  const marketsOptions = [
    {
      name: t('settings:all_markets'),
      selectedLabel: t('common:all'),
      value: 'allMarkets',
    },
    ...markets.map(({ id, name }) => ({ name, value: id })),
  ];

  return (
    <DropdownRadioGroup
      title={t('common:market')}
      name="market"
      allowClear={isDefined(selectedMarket)}
      value={selectedMarket ?? 'allMarkets'}
      options={marketsOptions}
      onChange={handleChange}
      onClear={handleClear}
    />
  );
};
