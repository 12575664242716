/* eslint-disable max-len */

import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;

export const YouTube = ({ width = ORIGINAL_WIDTH }: IconComponentProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox="0 0 20 20" fill="none">
    <path
      d="M16.7115 7.40827C16.5501 6.85762 16.0773 6.42356 15.4777 6.27528C14.3821 6 9.99988 6 9.99988 6C9.99988 6 5.61773 6 4.52217 6.26479C3.93406 6.41297 3.44967 6.85771 3.28828 7.40827C3 8.41407 3 10.5 3 10.5C3 10.5 3 12.5964 3.28828 13.5917C3.44978 14.1423 3.92253 14.5765 4.52227 14.7247C5.62926 15 10 15 10 15C10 15 14.3821 15 15.4777 14.7352C16.0774 14.5871 16.5501 14.1529 16.7117 13.6023C16.9999 12.5964 16.9999 10.5106 16.9999 10.5106C16.9999 10.5106 17.0115 8.41407 16.7115 7.40827Z"
      style={{ fill: '#FF0000' }}
    />
    <path d="M8.60431 12.4269L12.2484 10.4998L8.60431 8.57275V12.4269Z" style={{ fill: 'white' }} />
  </svg>
);
