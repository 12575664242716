/* eslint-disable max-len */

import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;
const ORIGINAL_COLOR = '#848484';

export const Cog = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => (
  <svg fill="none" height={width} viewBox="0 0 20 20" width={width}>
    <path
      clipRule="evenodd"
      d="m17.4531 9.16721c-.0238-.21085-.2696-.3693-.4822-.3693-.6873 0-1.2972-.40357-1.553-1.0277-.2613-.63926-.0928-1.38478.4194-1.85465.1612-.1474.1808-.39414.0456-.56553-.3518-.44671-.7516-.85023-1.1882-1.19994-.1709-.13718-.4218-.11809-.57.04605-.4471.4951-1.2501.6791-1.8706.4202-.6458-.27163-1.053-.92596-1.0131-1.62834.0131-.22062-.1481-.41244-.3681-.43806-.5602-.0648-1.12539-.06681-1.68731-.00447-.21744.024-.37868.2113-.37137.42913.02445.69546-.38767 1.33835-1.027 1.60021-.6131.25043-1.41049.06798-1.85665-.42271-.14746-.16163-.3942-.18149-.56599-.0476-.44952.35267-.85834.75651-1.21336 1.19954-.13847.17223-.1181.42187.04476.57005.52201.47272.69056 1.22472.41937 1.87134-.25891.61649-.89908 1.01381-1.63195 1.01381-.23781-.00765-.4072.15197-.43321.36818-.06597.56347-.06675 1.13758-.00324 1.70568.02355.2117.27688.3688.49182.3688.65311-.0167 1.28016.3876 1.54321 1.0277.2622.6393.09365 1.3843-.41938 1.8546-.16046.1474-.18083.3938-.0456.5651.3485.4439.74839.8478 1.18652 1.2004.17184.1385.42188.119.57085-.0452.44873-.4963 1.2517-.68 1.86976-.4206.64737.2708 1.05457.9251 1.01472 1.6279-.013.2207.14902.4129.36809.4381.28659.0334.57492.0501.86403.0501.27443 0 .54893-.0151.82333-.0456.2175-.024.3786-.2113.3713-.4295-.0251-.6951.3877-1.338 1.0262-1.5994.6172-.2521 1.4113-.0676 1.8575.4226.1483.1613.3933.1808.566.0473.4487-.3518.8567-.7553 1.2134-1.1996.1384-.1718.1189-.4218-.0448-.57-.522-.4727-.6914-1.2248-.4202-1.871.2549-.6082.8713-1.0167 1.5343-1.0167l.0928.0024c.215.0175.4129-.1481.4389-.3676.0661-.564.0669-1.13764.0034-1.70569zm-7.4412 3.35029c-1.37957 0-2.50173-1.1221-2.50173-2.5016s1.12216-2.50169 2.50173-2.50169c1.3795 0 2.5017 1.12219 2.5017 2.50169s-1.1222 2.5016-2.5017 2.5016z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);
