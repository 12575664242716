const enum CheckoutTrackingEventWithParameters {
  DOWNLOAD_BUTTON_CHOICE_SELECTED = 'checkout-download-button-choice-clicked',
  SHIPPING_ADDRESS_SELECTED = 'checkout-shipping-address-selected',
  PAYMENT_METHOD_SELECTED = 'checkout-payment-method-selected',
}
export const enum DownloadChoice {
  OrderDetailsXls = 'order-details.xls',
  OrderDetailsCsv = 'order-details.csv',
  OrderConfirmationPdf = 'order-confirmation.pdf',
  LinesheetPdf = 'linesheet.pdf',
  ProductInfoXls = 'product-information.xls',
  ProductImagesZip = 'product-images.zip',
}

type Type = 'existing' | 'new';

export const getDownloadButtonChoiceSelectedEvent = (downloadChoice: DownloadChoice) => ({
  downloadChoice,
  event: CheckoutTrackingEventWithParameters.DOWNLOAD_BUTTON_CHOICE_SELECTED,
});

export const getShippingAddressSelectedEvent = (type: Type) => ({
  event: CheckoutTrackingEventWithParameters.SHIPPING_ADDRESS_SELECTED,
  type,
});

export const getPaymentMethodSelectedEvent = (paymentMethod: string) => ({
  event: CheckoutTrackingEventWithParameters.PAYMENT_METHOD_SELECTED,
  paymentMethod,
});

export const enum CheckoutTrackingEvent {
  ADD_VOUCHER_BUTTON_CLICKED = 'checkout-add-voucher-button-clicked',
  APPLY_VOUCHER_BUTTON_CLICKED = 'checkout-apply-voucher-button-clicked',
  VOUCHER_INVALID_ERROR_SHOWED = 'checkout-voucher-invalid-error-showed',
  EXPAND_DELIVERY_WINDOW_CLICKED = 'checkout-expand-delivery-window-clicked',
  VALIDATION_ERRORS_ENCOUNTERED = 'checkout-validation-errors-encountered',
}
