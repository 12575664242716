import { createSelector } from 'reselect';

import { EMPTY_ARRAY } from '../../constants/empty';

import { initialLookbookState } from './reducers';

export const getLookbookFilters = (state: Store) => state.lookbook.filters;

export const getHasActiveLookbookFilters = (state: Store) => {
  return state.lookbook.filters !== initialLookbookState.filters;
};

export const getLookbookAttributes = createSelector(
  (state: Store) => state.lookbook.attributes,
  attributes => attributes || EMPTY_ARRAY,
);
export const getLookbookExpandedFiltersStates = (state: Store) => state.lookbook.expandedFilters.states;
