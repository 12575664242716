import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './Preview.module.scss';

interface Props {
  type?: string;
  src?: string;
}

export const Preview = ({ type, children, src }: React.WithChildren<Props>) => {
  const { t } = useTranslation(['validation']);

  const previewContent = () => {
    if (type === 'image') {
      return <img className={styles.media} src={src} alt="preview" />;
    }

    if (type === 'video') {
      return <video className={styles.media} autoPlay muted loop src={src} />;
    }

    if (type === 'unknown') {
      return t('validation:unknown_file_type_hint');
    }

    return children;
  };

  return <div className={styles.wrapper}>{previewContent()}</div>;
};
