import { Id, Position } from '@typings';

import { useMatrixNavigation } from '../../../../utils/hooks/matrix/useMatrixNavigation';
import { PrepackSwitch } from '../MatrixContents/PrepackSwitch';

import styles from './MatrixCells.module.scss';

interface Props {
  variantId: Id;
  isDisabled: boolean;
  position: Position;
}

export const PrepackSwitchCell = ({ variantId, isDisabled, position }: Props) => {
  const { setFocusableElement, isFocusTarget } = useMatrixNavigation(position);

  return (
    <td className={styles.prepackSwitchCell}>
      <PrepackSwitch ref={setFocusableElement} isDisabled={isDisabled} isFocusTarget={isFocusTarget} variantId={variantId} />
    </td>
  );
};
