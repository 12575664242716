import { Buyer } from '@typings';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { NAME_ALLOWED_CHARACTERS } from '../../../../constants/regExp';
import { useValidatedForm } from '../../../../utils/hooks/useValidatedForm';
import { isDefined } from '../../../../utils/is';
import { getPhoneSchema } from '../../../../utils/schemas';
import { Form, FormButtons, FormFieldset, FormGrid, InputField } from '../../../various/Form';
import { Button } from '../../../various/NewButton';

interface Props {
  defaultValues?: Buyer.BuyerDTO;
  isLoading: boolean;
  onCancel: () => void;
  onSubmit: (data: Buyer.BuyerDTO) => void;
}

const DEFAULT_VALUES = {
  email: '',
  firstName: '',
  lastName: '',
  mobileNumber: '',
  phoneNumber: '',
};

export const BuyerForm = ({ defaultValues, isLoading, onCancel, onSubmit }: Props) => {
  const { t } = useTranslation(['buyers', 'common', 'validation']);

  const validationSchema: yup.ObjectSchema<Buyer.BuyerDTO> = yup.object({
    email: yup.string().required(t('validation:buyers_email_address_hint')).email(t('validation:invalid_email_hint')),
    firstName: yup
      .string()
      .required(t('validation:buyers_first_name_hint'))
      .matches(NAME_ALLOWED_CHARACTERS, t('validation:valid_name_hint')),
    lastName: yup
      .string()
      .required(t('validation:buyers_last_name_hint'))
      .matches(NAME_ALLOWED_CHARACTERS, t('validation:valid_name_hint')),
    mobileNumber: getPhoneSchema(),
    phoneNumber: getPhoneSchema(),
  });

  const formMethods = useValidatedForm({ defaultValues: defaultValues ?? DEFAULT_VALUES, validationSchema });
  const { register } = formMethods;

  return (
    <Form formMethods={formMethods} onSubmit={onSubmit}>
      <FormFieldset isDisabled={isLoading}>
        <FormGrid cols={2}>
          <InputField label={t('common:first_name')} isRequired {...register('firstName')} />
          <InputField label={t('common:last_name')} isRequired {...register('lastName')} />
          <InputField label={t('common:email_address')} isRequired {...register('email')} type="email" />
          <InputField label={t('common:mobile_number')} type="tel" {...register('mobileNumber')} />
          <InputField label={t('common:phone_number')} type="tel" {...register('phoneNumber')} />
        </FormGrid>
        <FormButtons showDivider>
          <Button size="large" variant="ghost" color="dark" onClick={onCancel}>
            {t('common:cancel')}
          </Button>
          <Button type="submit" size="large" isLoading={isLoading}>
            {isDefined(defaultValues) ? t('common:save_changes') : t('buyers:add_buyer')}
          </Button>
        </FormButtons>
      </FormFieldset>
    </Form>
  );
};
