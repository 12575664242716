import React from 'react';

import { isDefined } from '../../../../../utils/is';

interface Props<T> {
  ref: React.RefObject<T>;
}

type HorizontalSide = 'left' | 'right';
type VerticalSide = 'top' | 'bottom';

export type MouseClosestSideOptions = HorizontalSide | VerticalSide;

export const useMouseClosestSide = <T extends HTMLElement>({ ref }: Props<T>) => {
  const [verticalSide, setVerticalSide] = React.useState<Nullable<VerticalSide>>(null);
  const [horizontalSide, setHorizontalSide] = React.useState<Nullable<HorizontalSide>>(null);

  const handleMouseMove = React.useCallback(
    (event: MouseEvent) => {
      if (!isDefined(ref.current)) {
        return;
      }

      const { clientX, clientY } = event;
      const { left, top, width, height } = ref.current.getBoundingClientRect();

      const isMouseCloserToLeft = clientX < left + width / 2;
      const isMouseCloserToTop = clientY < top + height / 2;

      setHorizontalSide(isMouseCloserToLeft ? 'left' : 'right');
      setVerticalSide(isMouseCloserToTop ? 'top' : 'bottom');
    },
    [ref],
  );

  const handleMouseOut = React.useCallback(() => {
    if (!isDefined(ref.current)) {
      return;
    }

    setHorizontalSide(null);
    setVerticalSide(null);
  }, [ref]);

  React.useEffect(() => {
    const element = ref.current;
    if (!isDefined(element)) {
      return;
    }

    element.addEventListener('mousemove', handleMouseMove);
    element.addEventListener('mouseout', handleMouseOut);

    return () => {
      element.removeEventListener('mousemove', handleMouseMove);
      element.removeEventListener('mouseout', handleMouseOut);
    };
  }, [handleMouseMove, handleMouseOut, ref]);

  return { horizontalSide, verticalSide };
};
