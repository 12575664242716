import { API, HostAPI, Method, ShowroomAPI } from '@typings';
import axios, { AxiosHeaderValue, AxiosInstance, AxiosPromise, AxiosStatic } from 'axios';
import * as pathToRegexp from 'path-to-regexp';

import { ACCEPT_HEADER_BASE, CONTENT_TYPE_HEADER, ResponseStatus } from '../constants/api';
import { getAuthHeader, getToken } from '../utils/auth';
import { isDefined } from '../utils/is';

class REST<RestAPI extends API> {
  private readonly httpClient: AxiosInstance;
  private static async getData<A extends API, M extends Method, Endpoint extends string>(
    request: AxiosPromise<API.EndpointResponse<A, M, Endpoint>>,
  ): Promise<API.Response<A, M, Endpoint>> {
    try {
      const response = await request;

      return {
        data: response.data,
        status: ResponseStatus.SUCCESS,
        statusCode: response.status,
      };
    } catch (error) {
      if (isDefined(error.response) && isDefined(error.response.data)) {
        return {
          data: error.response.data,
          status: ResponseStatus.FAILURE,
          statusCode: error.response.status,
        };
      }

      return {
        data: error.message,
        status: ResponseStatus.FAILURE,
        statusCode: 0,
      };
    }
  }

  private static async getQueryData<A extends API, M extends Method, Endpoint extends string>(
    request: AxiosPromise<API.EndpointResponse<A, M, Endpoint>>,
  ): Promise<API.ResponseQuery<A, M, Endpoint>> {
    const response = await request;

    return response.data;
  }

  constructor(baseURL: string, headers: Record<string, string>, client: AxiosStatic) {
    this.httpClient = client.create({ baseURL, headers });
  }

  GET: API.Request<RestAPI, Method.GET> =
    <P extends string>(url: P) =>
    async <Options extends API.RequestOptions>(options: Options) =>
      REST.getData<RestAPI, Method.GET, P>(
        this.httpClient.request(
          Object.assign({}, isDefined(options) ? options : {}, {
            method: 'get' as const,
            params: options?.query ?? {},
            url: options && 'pathParams' in options ? pathToRegexp.compile(url)(options.pathParams) : url,
          }),
        ),
      );

  POST: API.Request<RestAPI, Method.POST> =
    <P extends string>(url: P) =>
    async <Options extends API.RequestOptions>(options: Options) =>
      REST.getData<RestAPI, Method.POST, P>(
        this.httpClient.request(
          Object.assign({}, isDefined(options) ? options : {}, {
            data: options?.data ?? {},
            method: 'post' as const,
            params: options?.query ?? {},
            url: options && 'pathParams' in options ? pathToRegexp.compile(url)(options.pathParams) : url,
          }),
        ),
      );

  PUT: API.Request<RestAPI, Method.PUT> =
    <P extends string>(url: P) =>
    async <Options extends API.RequestOptions>(options: Options) =>
      REST.getData<RestAPI, Method.PUT, P>(
        this.httpClient.request(
          Object.assign({}, isDefined(options) ? options : {}, {
            data: options?.data ?? {},
            method: 'put' as const,
            params: options?.query ?? {},
            url: options && 'pathParams' in options ? pathToRegexp.compile(url)(options.pathParams) : url,
          }),
        ),
      );

  DELETE: API.Request<RestAPI, Method.DELETE> =
    <P extends string>(url: P) =>
    async <Options extends API.RequestOptions>(options: Options) =>
      REST.getData<RestAPI, Method.DELETE, P>(
        this.httpClient.request(
          Object.assign({}, isDefined(options) ? options : {}, {
            data: options?.data ?? {},
            method: 'delete' as const,
            params: options?.query ?? {},
            url: options && 'pathParams' in options ? pathToRegexp.compile(url)(options.pathParams) : url,
          }),
        ),
      );

  QUERY_GET: API.RequestQuery<RestAPI, Method.GET> =
    <P extends string>(url: P) =>
    async <Options extends API.RequestOptions>(options: Options) =>
      REST.getQueryData<RestAPI, Method.GET, P>(
        this.httpClient.request(
          Object.assign({}, isDefined(options) ? options : {}, {
            method: 'get' as const,
            params: options?.query ?? {},
            url: options && 'pathParams' in options ? pathToRegexp.compile(url)(options.pathParams) : url,
          }),
        ),
      );

  QUERY_POST: API.RequestQuery<RestAPI, Method.POST> =
    <P extends string>(url: P) =>
    async <Options extends API.RequestOptions>(options: Options) =>
      REST.getQueryData<RestAPI, Method.POST, P>(
        this.httpClient.request(
          Object.assign({}, isDefined(options) ? options : {}, {
            data: options?.data ?? {},
            method: 'post' as const,
            params: options?.query ?? {},
            url: options && 'pathParams' in options ? pathToRegexp.compile(url)(options.pathParams) : url,
          }),
        ),
      );

  QUERY_PUT: API.RequestQuery<RestAPI, Method.PUT> =
    <P extends string>(url: P) =>
    async <Options extends API.RequestOptions>(options: Options) =>
      REST.getQueryData<RestAPI, Method.PUT, P>(
        this.httpClient.request(
          Object.assign({}, isDefined(options) ? options : {}, {
            data: options?.data ?? {},
            method: 'put' as const,
            params: options?.query ?? {},
            url: options && 'pathParams' in options ? pathToRegexp.compile(url)(options.pathParams) : url,
          }),
        ),
      );

  QUERY_DELETE: API.RequestQuery<RestAPI, Method.DELETE> =
    <P extends string>(url: P) =>
    async <Options extends API.RequestOptions>(options: Options) =>
      REST.getQueryData<RestAPI, Method.DELETE, P>(
        this.httpClient.request(
          Object.assign({}, isDefined(options) ? options : {}, {
            data: options?.data ?? {},
            method: 'delete' as const,
            params: options?.query ?? {},
            url: options && 'pathParams' in options ? pathToRegexp.compile(url)(options.pathParams) : url,
          }),
        ),
      );

  updateHeaders = (headers: { [key: string]: AxiosHeaderValue }) => {
    Object.entries(headers).forEach(([key, value]) => {
      // eslint-disable-next-line functional/immutable-data
      this.httpClient.defaults.headers[key] = value;
    });
  };

  clearAuthToken = () => {
    this.updateHeaders(getAuthHeader(ACCEPT_HEADER_BASE, null));
  };
}

export const showroomApi = new REST<ShowroomAPI>(
  window.__SHOWROOM_SETTINGS__.API,
  {
    ...getAuthHeader(ACCEPT_HEADER_BASE, getToken()),
    'Content-Type': CONTENT_TYPE_HEADER,
  },
  axios,
);

export const hostApi = new REST<HostAPI>(
  '',
  {
    Accept: ACCEPT_HEADER_BASE,
    'Content-Type': CONTENT_TYPE_HEADER,
  },
  axios,
);
