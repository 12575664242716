import { Cms, Id, Position } from '@typings';

import { clamp } from '../../utils/clamp';
import { isDefined } from '../../utils/is';
import { getScreenRelativeStyles } from '../pages';

const OFFSET_CENTER = 50;
const HOTSPOT_RADIUS = 12;

/*
  Given a original height and width, and a desired height and width,
  it calculates width, height and offset that needed for image to fit the desired container
*/
const getObjectFitCoverSize = (
  screenRelative: Cms.BackgroundScreenRelativeProps,
  container: Cms.MediumSize,
  naturalImage: Cms.MediumSize,
) => {
  const naturalRatio = naturalImage.width / naturalImage.height;
  const containerRatio = container.width / container.height;

  const targetWidth = naturalRatio < containerRatio ? container.width : container.height * naturalRatio;
  const targetHeight = naturalRatio < containerRatio ? container.width / naturalRatio : container.height;

  const offset =
    isDefined(screenRelative?.offset) ? { x: screenRelative.offset.x, y: screenRelative.offset.y } : { x: OFFSET_CENTER, y: OFFSET_CENTER };

  return {
    height: targetHeight,
    width: targetWidth,
    x: ((container.width - targetWidth) * offset.x) / 100,
    y: ((container.height - targetHeight) * offset.y) / 100,
  };
};

interface HotspotPositionParameters {
  hotspotPoint: Cms.HotspotPoint;
  blockSize: Cms.MediumSize;
  background: Cms.ContentBlockBackground;
  screenWidth: number;
  naturalImage: Cms.MediumSize;
}

export const getHotspotPosition = ({ hotspotPoint, blockSize, background, screenWidth, naturalImage }: HotspotPositionParameters) => {
  const { originalBlockSize, originalScreenRelative } = hotspotPoint.general;
  const { xRatio, yRatio } = getScreenRelativeStyles(screenWidth, hotspotPoint);
  const screenRelative = getScreenRelativeStyles(screenWidth, background);

  const newObjectFitSize = getObjectFitCoverSize(screenRelative, blockSize, naturalImage);
  const originalObjectFitSize = getObjectFitCoverSize(
    originalScreenRelative,
    { height: originalBlockSize.height, width: originalBlockSize.width },
    naturalImage,
  );

  const proportionY = newObjectFitSize.height / originalObjectFitSize.height;
  const proportionX = newObjectFitSize.width / originalObjectFitSize.width;

  const oldXPos = (xRatio / 100) * originalBlockSize.width;
  const oldYPos = (yRatio / 100) * originalBlockSize.height;
  const offsetX = newObjectFitSize.x - originalObjectFitSize.x * proportionX;
  const offsetY = newObjectFitSize.y - originalObjectFitSize.y * proportionY;

  return {
    left: oldXPos * proportionX + offsetX,
    top: oldYPos * proportionY + offsetY,
  };
};

export const getIsOutOfBoundaries = ({ left, top, blockSize }: { left: number; top: number; blockSize: Cms.MediumSize }) => {
  const isOutOfBoundariesX = left + HOTSPOT_RADIUS < 0 || left > blockSize.width - HOTSPOT_RADIUS;
  const isOutOfBoundariesY = top + HOTSPOT_RADIUS < 0 || top > blockSize.height - HOTSPOT_RADIUS;

  return isOutOfBoundariesX || isOutOfBoundariesY;
};

export const removeEmptyHotspots = (points: Record<Id, Cms.HotspotPoint>) =>
  !isDefined(points) ?
    {}
  : Object.entries(points).reduce((acc, cur) => {
      const [key, point] = cur;

      return isDefined(point.general.productId) ?
          {
            ...acc,
            [key]: point,
          }
        : acc;
    }, {});

export const mousePositionToRatio = (hotspotEditorRef: React.MutableRefObject<HTMLElement | null>, mousePosition: Position) => {
  if (!isDefined(hotspotEditorRef.current)) {
    return {
      xRatio: 0,
      yRatio: 0,
    };
  }

  const { x, y } = mousePosition;

  const clientRect = hotspotEditorRef.current.getBoundingClientRect();
  const { offsetWidth, offsetHeight } = hotspotEditorRef.current;
  const xRatio = ((x - clientRect.x - HOTSPOT_RADIUS) / offsetWidth) * 100;
  const yRatio = ((y - clientRect.y - HOTSPOT_RADIUS) / offsetHeight) * 100;

  return {
    xRatio,
    yRatio,
  };
};

export const getClampedMousePosition = (rect: DOMRect, mousePosition: Position) => {
  const { top, left, bottom, right } = rect;

  const rangeX = clamp(left, right);
  const rangeY = clamp(top, bottom);

  return { x: rangeX(mousePosition.x), y: rangeY(mousePosition.y) };
};
