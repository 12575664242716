import { useTranslation } from 'react-i18next';

import Icon, { IconColor, IconType } from '../../Icon';
import styles from '../ScannerContent.module.scss';
interface Props {
  scannedCode: Maybe<string>;
  isAddingProductByBarcode: boolean | null;
  productName: string | undefined;
}

export const LoadingProduct = ({ scannedCode, isAddingProductByBarcode, productName }: Props) => {
  const { t } = useTranslation(['common', 'selections']);

  const title = productName ?? t('common:barcode', { code: scannedCode });

  return (
    <div className={styles.message}>
      <div className={styles.iconTitleWrapper}>
        <Icon type={IconType.Barcode} color={IconColor.Faded} size={48} />

        <span className={styles.title}>{title}</span>
      </div>

      <div className={styles.loading}>
        <Icon type={IconType.Spinner} />
        {isAddingProductByBarcode ? t('selections:adding_product_to_selection') : t('common:looking_for_product')}
      </div>
    </div>
  );
};
