import { Filters } from '@typings';
import React from 'react';

import styles from './FiltersBar.module.scss';

interface Props {
  filters: Partial<Filters>;
}

export const FiltersNumber = ({ filters }: Props) => {
  const filtersNumber = React.useMemo(
    () =>
      Object.values(filters).reduce((acc: number, cur: string | string[]) => {
        if (Array.isArray(cur)) {
          return acc + cur.length;
        }
        if (cur === 'anyVariant' || cur === 'arrivingSoon') {
          return acc + 1;
        }

        return acc;
      }, 0),
    [filters],
  );

  if (filtersNumber === 0) {
    return null;
  }

  return <div className={styles.filtersNumber}>{filtersNumber}</div>;
};
