/* eslint-disable max-len */

import { IconComponentProps } from '@typings';

const WIDTH = 20;
const COLOR = '#737373';

export const Overlay = ({ width = WIDTH, color = COLOR }: IconComponentProps) => {
  return (
    <svg width={width} height={width} viewBox="0 0 20 20" fill="none">
      <path
        d="M15.8411 3.06222L3.06222 15.8411C3.13222 16.1056 3.27222 16.3467 3.45889 16.5411C3.65333 16.7278 3.89444 16.8678 4.15889 16.9378L16.9456 4.15889C16.7978 3.62222 16.3778 3.20222 15.8411 3.06222ZM9.90667 3L3 9.90667V12.1078L12.1078 3H9.90667ZM4.55556 3C3.7 3 3 3.7 3 4.55556V6.11111L6.11111 3H4.55556ZM15.4444 17C15.8722 17 16.2611 16.8289 16.5411 16.5411C16.8289 16.2611 17 15.8722 17 15.4444V13.8889L13.8889 17H15.4444ZM7.89222 17H10.0933L17 10.0933V7.89222L7.89222 17Z"
        fill={color}
      />
    </svg>
  );
};
