import { Cms } from '@centra/cms';
import React from 'react';

import { getScreenRelativeStyles } from '../../../../../logic/pages';
import { useSpecifiedDeviceWidth } from '../../../../../utils/hooks';
import { isDefined } from '../../../../../utils/is';
import { LazyRender } from '../../../../various/LazyRender';

import styles from './Background.module.scss';
import { ImageBackground } from './ImageBackground';
import VideoBackground from './VideoBackground';

interface Props {
  parameters: Cms.ContentBlockBackground;
}

export const Background = ({ parameters }: Props) => {
  const { general } = parameters;
  const screenWidth = useSpecifiedDeviceWidth();

  const screenRelative = getScreenRelativeStyles(screenWidth, parameters);

  const style = React.useMemo(() => {
    if (isDefined(screenRelative) && screenRelative.offset) {
      return {
        objectPosition: `${screenRelative.offset.x}% ${screenRelative.offset.y}%`,
      };
    }

    return undefined;
  }, [screenRelative]);

  if (!general.media) {
    return <div className={styles.color} style={{ backgroundColor: general.color }} />;
  }

  const [mediaType] = general.media.type.split('/');
  const isImage = mediaType === 'image';
  const isVideo = mediaType === 'video';

  if (isImage) {
    return <ImageBackground media={general.media} style={style} />;
  }

  if (isVideo) {
    return (
      <LazyRender estimatedHeight="100%" triggerOnce>
        <VideoBackground className={styles.medium} src={general.media.src} style={style} />
      </LazyRender>
    );
  }

  return null;
};
