import { CmsV0 } from '@centra/cms/legacy/v0';
import { mapObjIndexed, pick } from 'ramda';

import { isDefined } from '../../../../../../utils/is';
import { isEmpty } from '../../../../../../utils/isEmpty';
import { listToRecord } from '../../../../../../utils/normalize';
import { localizationMerger } from '../../../../applyBlockLocalization';

const isContentBlockV0 = (block: Maybe<CmsV0.AnyBlock>): block is CmsV0.ContentBlock => block?.blockType === 'content';

export const extractEnglishLanguageContent = (response: CmsV0.SectionsResponse) => {
  const { sections, localizations } = response;

  const blocks = listToRecord(
    sections.flatMap(section => section.blocks),
    'id',
  );

  const mergedBlocksWithLocalizations =
    localizations?.en?.blocks ? (localizationMerger(blocks, localizations.en.blocks) as Record<string, CmsV0.AnyBlock>) : blocks;

  const contentBlocks = listToRecord(Object.values(mergedBlocksWithLocalizations).filter(isContentBlockV0), 'id');

  const extractedEnContent =
    !isEmpty(contentBlocks) ?
      {
        en: {
          blocks: mapObjIndexed(block => {
            const { text, link } = block.settings;

            const localizableTextParts =
              isDefined(text) ?
                {
                  text: {
                    parts: mapObjIndexed(part => ({ general: pick(['content', 'link'], part.general) }), text.parts),
                  },
                }
              : {};

            const normalizedBlockLink = isDefined(link) ? { link } : {};

            return {
              settings: {
                ...normalizedBlockLink,
                ...localizableTextParts,
              },
            };
          }, contentBlocks),
        },
      }
    : {};

  return {
    ...response,
    localizations: {
      ...localizations,
      ...extractedEnContent,
    },
  };
};
