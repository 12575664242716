import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getIsBarcodeScannerEnabled } from '../../../../ducks';
import { useModalVisibility } from '../../../../utils/hooks';
import Icon, { IconColor, IconType } from '../../../various/Icon';

import styles from './BarcodeScannerButton.module.scss';

interface Props {
  hidden?: boolean;
}

export const BarcodeScannerButton = ({ hidden = false }: Props) => {
  const { t } = useTranslation(['common']);
  const isBarcodeScannerEnabled = useSelector(getIsBarcodeScannerEnabled);
  const { showModal: showBarcodeScannerModal } = useModalVisibility('BarcodeScannerModal');

  if (!isBarcodeScannerEnabled) {
    return null;
  }

  return (
    <button
      type="button"
      aria-hidden={hidden}
      aria-label={t('common:scan_barcode')}
      className={cx(styles.scanButton, { [styles.hidden]: hidden })}
      onClick={showBarcodeScannerModal}
    >
      <Icon type={IconType.Barcode} color={IconColor.Gray} />
    </button>
  );
};
