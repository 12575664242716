import cx from 'classnames';
import React from 'react';

import styles from './Wrapper.module.scss';

export enum WrapperSize {
  FULL = '100%',
  XXLARGE = '1660px',
  XLARGE = '1380px',
  LARGE = '1260px',
  MEDIUM = '1000px',
  SMALL = '660px',
}

interface Props {
  size: WrapperSize;
  className?: string;
  isSeparated?: boolean;
}

export const Wrapper = React.memo(({ className, size, isSeparated, children }: React.WithChildren<Props>) => {
  const style = React.useMemo(() => {
    return size !== WrapperSize.FULL ? { maxWidth: size } : undefined;
  }, [size]);

  const classNames = cx(styles.wrapper, className, {
    [styles.isSeparated]: isSeparated,
  });

  return (
    <div className={classNames} style={style}>
      {children}
    </div>
  );
});
