/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;
const ORIGINAL_COLOR = '#848484';

export const Italic = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => (
  <svg width={width} height={width} viewBox="0 0 20 20">
    <path
      clipRule="evenodd"
      d="m10.3403 7.09829.1368-.34265h-.369-.49821c-.42842 0-.80263-.37531-.80263-.87782 0-.50252.37421-.87782.80263-.87782h3.50881c.4284 0 .8026.3753.8026.87782 0 .50251-.3742.87782-.8026.87782h-.9053-.1694l-.0628.15735-2.4 6.01501-.13671.3427h.36891.4982c.4285 0 .8027.3753.8027.8778s-.3742.8778-.8027.8778h-3.50872c-.42842 0-.80264-.3753-.80264-.8778s.37422-.8778.80264-.8778h.90526.16941l.06279-.1574z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);
