import { Cms } from '@typings';
import { t } from 'i18next';
import { assocPath } from 'ramda';
import React from 'react';
import { useDispatch } from 'react-redux';

import { setButtonStyleEditorStyles } from '../../../../ducks';
import { Switch } from '../../../various/Fields/Switch';
import { ColorEditor, PixelField } from '../../TextTools';
import styles from '../CustomStyleEditor.module.scss';
import { InputRow } from '../FieldRow';

type Props = {
  buttonStyle: Cms.ButtonStyle;
};

export const ButtonStyleHoverTab = ({ buttonStyle }: Props) => {
  const dispatch = useDispatch();
  const {
    properties: { hover },
  } = buttonStyle;

  const setHoverStyleProperty = React.useCallback<Cms.SetStyleProperty<Cms.ButtonHoverStyleProperites>>(
    property => value => {
      const newStyle = assocPath(['properties', 'hover', String(property)], value, buttonStyle);
      dispatch(setButtonStyleEditorStyles(newStyle));
    },
    [buttonStyle, dispatch],
  );

  const { isHoverEffectEnabled, backgroundColor, borderWidth, borderColor, color } = hover;

  return (
    <div>
      <InputRow label={t('cms:button_style_editor_tab_hover')}>
        <Switch
          onValueChange={setHoverStyleProperty('isHoverEffectEnabled')}
          checked={isHoverEffectEnabled}
          aria-label={t('cms:toggle_hover_button_styles')}
          dataTestId="buttonStyleSwitchHover"
        />
      </InputRow>
      {isHoverEffectEnabled && (
        <>
          <InputRow label={t('cms:background')} preventLabelFocus>
            <ColorEditor
              value={backgroundColor}
              onChange={setHoverStyleProperty('backgroundColor')}
              className={styles.colorEditor}
              disableAlpha={false}
            />
          </InputRow>
          <InputRow label={t('cms:border')}>
            <PixelField
              value={borderWidth}
              onChange={setHoverStyleProperty('borderWidth')}
              minValue={0}
              maxValue={50}
              className={styles.buttonBorderWidth}
            />
            <ColorEditor
              value={borderColor}
              onChange={setHoverStyleProperty('borderColor')}
              className={styles.colorEditor}
              disableAlpha={false}
            />
          </InputRow>
          <InputRow label={t('cms:text_color')} preventLabelFocus>
            <ColorEditor value={color} onChange={setHoverStyleProperty('color')} className={styles.colorEditor} disableAlpha={false} />
          </InputRow>
        </>
      )}
    </div>
  );
};
