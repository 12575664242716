import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getCheckoutSplitData, getOrderDetails } from '../../ducks';
import { isDefined } from '../is';

import { usePreviousValue } from './usePreviousValue';

export const useUrlOrderIdMatch = () => {
  const { id: urlId } = useParams<{ id: string }>();
  const previousUrlId = usePreviousValue(urlId);
  const orderDetails = useSelector(getOrderDetails);
  const orderSplitDetails = useSelector(getCheckoutSplitData);

  const singleOrderId = orderDetails.order.order;
  const splitOrderId = orderSplitDetails.originalOrderId;

  const isMatchingSingleOrderId = isDefined(urlId) && isDefined(singleOrderId) && urlId === singleOrderId;
  const isMatchingSplitOrderId = isDefined(urlId) && isDefined(splitOrderId) && urlId === splitOrderId;
  const isMatchingAnyId = isMatchingSingleOrderId || isMatchingSplitOrderId;
  const hasOrderUrlIdChanged = previousUrlId !== urlId;
  const hasOrderChanged = hasOrderUrlIdChanged && !isMatchingSingleOrderId;

  return {
    hasOrderChanged,
    hasOrderUrlIdChanged,
    isMatchingAnyId,
    isMatchingSingleOrderId,
    isMatchingSplitOrderId,
    singleOrderId,
    splitOrderId,
    urlId,
  };
};
