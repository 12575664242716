import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getAreBackordersEnabled, getSoldOutLabel } from '../../../../ducks/config';
import { getHasOptimisticUpdatesByProductId, getOrderDetails } from '../../../../ducks/order';
import { useIsLookbook } from '../../../../utils/hooks/useIsLookbook';
import { useIsTouchScreen } from '../../../../utils/hooks/useIsTouchScreen';
import { useToggleInOrder } from '../../../../utils/hooks/useToggleInOrder';
import { IconType } from '../../../various/Icon';
import { Button } from '../../../various/NewButton';
import { ButtonGroup } from '../../../various/NewButton/ButtonGroup';
import { useProductDetailsVariantContext } from '../context/ProductDetailsVariantContext';

import styles from './ProductInfoAddButton.module.scss';

interface Props {
  className?: string;
}

export const ProductInfoAddButton = ({ className }: Props) => {
  const { t } = useTranslation(['common', 'selections']);

  const [isInteracting, setIsInteracting] = React.useState(false);
  const { hasAvailableVariants, activeProductId } = useProductDetailsVariantContext();

  const orderDetails = useSelector(getOrderDetails);
  const backordersEnabled = useSelector(getAreBackordersEnabled);
  const isLookbook = useIsLookbook();
  const isMutatingUnits = useSelector(getHasOptimisticUpdatesByProductId(activeProductId));
  const soldOutLabel = useSelector(getSoldOutLabel);

  const { isAdding, isRemoving, isInOrder, hasUnits, toggleInOrder } = useToggleInOrder({ productId: activeProductId });
  const isLoading = isAdding || isRemoving || isMutatingUnits;
  const shouldShowTrashButton = isInOrder && isInteracting;
  const isTouchScreen = useIsTouchScreen();
  const isSoldOut = !hasAvailableVariants && !backordersEnabled;
  const testId = isInOrder && hasAvailableVariants ? 'removeFromSelectionButton' : 'addToSelectionButton';

  const title = React.useMemo(() => {
    if (isAdding || (!hasUnits && isMutatingUnits)) {
      return t('common:adding');
    }

    if (isMutatingUnits) {
      return t('common:updating');
    }

    if (isRemoving) {
      return t('common:removing');
    }

    if (shouldShowTrashButton) {
      return t('selections:remove_from_selection');
    }

    if (isInOrder) {
      return t('selections:added_to_selection');
    }

    return isSoldOut ? soldOutLabel : t('selections:add_to_selection');
  }, [isAdding, hasUnits, isMutatingUnits, isRemoving, shouldShowTrashButton, isInOrder, isSoldOut, soldOutLabel, t]);

  const compactButtonIcon = React.useMemo(() => {
    if (shouldShowTrashButton) {
      return IconType.TrashLinear;
    }

    if (hasUnits) {
      return IconType.DoubleCheck;
    }

    return IconType.Check;
  }, [hasUnits, shouldShowTrashButton]);

  if (isLookbook || !orderDetails.order.canModify) {
    return null;
  }

  const buttonIcon = !isInOrder && !isSoldOut ? IconType.Basket : undefined;

  const buttonColor = isInOrder ? 'dark' : 'primary';

  const buttonVariant = isInOrder ? 'ghost' : 'solid';

  const compactButtonColor = shouldShowTrashButton ? 'danger' : 'success';

  const isDisabled = !isInOrder && isSoldOut;
  const buttonListeners = !isTouchScreen && {
    onBlur: () => setIsInteracting(false),
    onFocus: () => setIsInteracting(true),
    onMouseEnter: () => setIsInteracting(true),
    onMouseLeave: () => setIsInteracting(false),
  };

  return (
    <ButtonGroup
      as="button"
      className={cx(styles.infoButton, className, { [styles.loading]: isLoading })}
      disabled={isDisabled || isLoading}
      variant="shadow"
      data-testid={testId}
      onClick={toggleInOrder}
      {...buttonListeners}
    >
      <Button as="div" isFluid disabled={isDisabled} isLoading={isLoading} icon={buttonIcon} color={buttonColor} variant={buttonVariant}>
        {title}
      </Button>
      {isInOrder && !isRemoving && <Button as="div" color={compactButtonColor} variant="flat" icon={compactButtonIcon} />}
    </ButtonGroup>
  );
};
