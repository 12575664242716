/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;
const ORIGINAL_COLOR = '#848484';

export const ReplaceVertical = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => (
  <svg width={width} height={width} viewBox="0 0 20 20" fill="none">
    <path
      d="M8.976 13.113c-.042-.115-.117-.172-.227-.172H7V3.29a.308.308 0 0 0-.07-.208A.226.226 0 0 0 6.75 3h-1.5a.227.227 0 0 0-.179.082.308.308 0 0 0-.07.208v9.65H3.25c-.104 0-.18.058-.226.173a.328.328 0 0 0 .039.317l2.773 3.48a.24.24 0 0 0 .187.09c.068 0 .128-.03.18-.09l2.734-3.48a.328.328 0 0 0 .04-.317ZM11.024 6.887c.042.115.117.173.227.173H13v9.65c0 .085.023.154.07.209a.226.226 0 0 0 .18.081h1.5a.226.226 0 0 0 .179-.081.308.308 0 0 0 .07-.209V7.06h1.75c.104 0 .18-.058.226-.173a.328.328 0 0 0-.039-.317l-2.773-3.48a.24.24 0 0 0-.187-.09.232.232 0 0 0-.18.09l-2.734 3.48a.328.328 0 0 0-.04.317Z"
      fill={color}
    />
  </svg>
);
