import { Items, MeasurementChart } from '@typings';
import { ascend, groupBy, prop, sortWith } from 'ramda';

import { getIsTwoDimensional } from '../../logic/sizeCharts';
import { isDefined } from '../../utils/is';
import { isObject } from '../../utils/objectSort';

interface Cell {
  content: string | number;
  key: string | number;
  th: boolean;
}

type Row = Cell[];

export interface Table {
  headers: Row;
  body: Row[];
}

interface Point {
  x: number;
  y: number;
}

interface Item {
  itemTableY: number;
  itemTableX: number;
  quantity: number | string;
}

/**
 * Runtime validation for measurement charts.
 */
export function isValidMeasurementChart(chart: any): chart is MeasurementChart {
  return isObject(chart) && Array.isArray(chart.x) && Array.isArray(chart.y) && Array.isArray(chart.contents);
}

const isValidTableBody = (body: Nullable<Cell>[][]): body is Row[] => {
  return !body.some(row => row.includes(null));
};

const groupByRow = groupBy<Item>(item => item.itemTableY.toString());

const itemToCell = (item: Item) => ({
  content: item.quantity,
  key: item.itemTableX,
  th: false,
});

const emptyHeaderCell = { content: '', key: 'empty', th: true };

const addMissingItems = (items: Item[], itemTable: Items.Table) => {
  const sizesY = itemTable.y.length === 0 ? [''] : itemTable.y;

  return itemTable.x
    .map((_, indexX) =>
      sizesY.map(
        (__, indexY) =>
          items.find(item => item.itemTableX === indexX && item.itemTableY === indexY) || {
            itemTableX: indexX,
            itemTableY: indexY,
            quantity: '',
          },
      ),
    )
    .flat();
};

export const itemsToChart = (items: Item[], itemTable: Items.Table): Table => {
  const allItems = addMissingItems(items, itemTable);

  const sortedItems = sortWith([ascend(prop('itemTableY')), ascend(prop('itemTableX'))], allItems);

  const sizesX = itemTable.x.map(sizeX => ({
    content: sizeX,
    key: sizeX,
    th: true,
  }));
  const values = Object.values(groupByRow(sortedItems)).map(row => row.map(itemToCell));

  if (getIsTwoDimensional(itemTable)) {
    const sizesY = itemTable.y.map(sizeY => ({
      content: sizeY,
      key: sizeY,
      th: true,
    }));

    return {
      body: values.map((row, index) => [sizesY[index] || emptyHeaderCell, ...row]),
      headers: [emptyHeaderCell, ...sizesX],
    };
  }

  return {
    body: values,
    headers: sizesX,
  };
};

/**
 * Maps raw API data to arrays of cells.
 */
export function convertToTableFormat({ x: horizontal, y: vertical, contents }: MeasurementChart): Nullable<Table> {
  const corner: Cell = {
    content: '',
    key: 'Corner',
    th: true,
  };

  const headers: Row = [corner, ...horizontal.map(x => ({ content: x, key: x, th: true }))];

  const body = vertical.map(y => [
    { content: y, key: y, th: true },
    ...horizontal.map(x => getSingleCellData(horizontal, vertical, x, y, contents)),
  ]);

  if (!isValidTableBody(body)) {
    return null;
  }

  return { body, headers };
}

/**
 * Maps raw measurement chart data into single cells.
 */
function getSingleCellData(
  horizontal: MeasurementChart['x'],
  vertical: MeasurementChart['y'],
  x: MeasurementChart['x'][number],
  y: MeasurementChart['y'][number],
  contents: MeasurementChart['contents'],
): Nullable<Cell> {
  const horizontalIndex = horizontal.indexOf(x);
  const verticalIndex = vertical.indexOf(y);
  const content = contents.find(matchesCoordinates(horizontalIndex, verticalIndex))?.content;

  if (!isDefined(content)) {
    return null;
  }

  return {
    content,
    key: `${x}${y}`,
    th: false,
  };
}

/**
 * Curried helper that helps find the cell of given coordinates.
 */
function matchesCoordinates(x: number, y: number): <T extends Point>(point: T) => boolean {
  return <T extends Point>(point: T) => {
    return point.x === x && point.y === y;
  };
}
