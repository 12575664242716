/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 24;
const ORIGINAL_COLOR = '#ffffff';

export const Play = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => {
  return (
    <svg width={width} height={width} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={color}
        d="M20.8334 32.7711L31.5359 24.8996C32.1547 24.4498 32.1547 23.5502 31.5359 23.1004L20.8334 15.2289C20.0771 14.6666 19 15.2064 19 16.1285V31.8715C19 32.7936 20.0771 33.3334 20.8334 32.7711Z"
      />
      <circle cx="24" cy="24" r="22.5" stroke="white" strokeWidth="3" />
    </svg>
  );
};
