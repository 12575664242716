import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getIsStockTypeSeparationEnabled } from '../../../ducks';

export const useTranslatableFields = () => {
  const isStockTypeSeparationEnabled = useSelector(getIsStockTypeSeparationEnabled);
  const { t } = useTranslation(['common', 'translations']);

  const standardFields = React.useMemo(
    () =>
      [
        {
          field: 'loginPageMessage',
          fieldName: t('common:login_page_message'),
        },
        {
          field: 'soldOutLabel',
          fieldName: t('translations:sold_out_label'),
        },
      ] as const,
    [t],
  );

  const stockSeparationFields = React.useMemo(
    () =>
      [
        {
          description: t('translations:filter_name_description'),
          field: 'stockFilterName',
          fieldName: t('translations:stock_filter_name'),
        },
        {
          description: t('translations:filter_name_description'),
          field: 'preorderFilterName',
          fieldName: t('translations:preorder_filter_name'),
        },
      ] as const,
    [t],
  );

  return React.useMemo(
    () => [...standardFields, ...(isStockTypeSeparationEnabled ? stockSeparationFields : [])],
    [standardFields, isStockTypeSeparationEnabled, stockSeparationFields],
  );
};
