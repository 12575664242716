import { CSSTransition } from 'react-transition-group';

import { useModalContext } from '../context/ModalContext';

import styles from './ModalBackdrop.module.scss';

export const ModalBackdrop = () => {
  const { onClose, isOpen, onCloseContent, shouldCloseOnOverlayClick } = useModalContext();

  return (
    <CSSTransition
      timeout={150}
      in={isOpen}
      classNames={{
        enter: styles.enter,
        enterActive: styles.enterActive,
        exit: styles.exit,
        exitActive: styles.exitActive,
      }}
      onExited={onCloseContent}
      unmountOnExit
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div className={styles.backdrop} data-testid="modalBackdrop" onClick={shouldCloseOnOverlayClick ? onClose : undefined} />
    </CSSTransition>
  );
};
