import { useQuery } from '@tanstack/react-query';

import { parseBackendCustomTemplateResponse } from '../../../logic/cms/customLayouts';
import { isDefined } from '../../../utils/is';
import { fetchCustomLayouts } from '../../customLayoutsRepository';

export const CUSTOM_LAYOUTS_QUERY_KEY = 'customLayouts';

export const useCustomLayoutsList = () =>
  useQuery({
    queryFn: async () => fetchCustomLayouts(),
    queryKey: [CUSTOM_LAYOUTS_QUERY_KEY],
    refetchOnMount: false,
    select: data => {
      return data.map(parseBackendCustomTemplateResponse).filter(isDefined);
    },
  });
