/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 14;

export const Error = ({ width = ORIGINAL_WIDTH }: IconComponentProps) => {
  return (
    <svg width={width} height={width} viewBox="0 0 14 14">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 0C3.136 0 0 3.136 0 7s3.136 7 7 7 7-3.136 7-7-3.136-7-7-7Zm0 7.7a.702.702 0 0 1-.7-.7V4.2c0-.385.315-.7.7-.7.385 0 .7.315.7.7V7c0 .385-.315.7-.7.7Zm-.7 1.4v1.4h1.4V9.1H6.3Z"
        fill="#DC5151"
      />
    </svg>
  );
};
