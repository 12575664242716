import { Item, Preset, UnitsDistribution } from '@typings';
import { pipe, sortWith } from 'ramda';

import { isDefined } from '../../utils/is';

export const getIsPresetStockAvailable = (itemForXY: Item, deliveryWindowId: string) => {
  const stock = itemForXY.stockByDeliveryWindow?.[deliveryWindowId];

  return isDefined(stock) && stock !== 'no' && stock !== 0;
};

export const getPresetAvailableStock = (itemForXY: Item, deliveryWindowId: string) => {
  const stock = itemForXY.stockByDeliveryWindow?.[deliveryWindowId];

  if (!getIsPresetStockAvailable(itemForXY, deliveryWindowId)) {
    return 0;
  }

  return typeof stock === 'number' ? stock : Number.MAX_SAFE_INTEGER;
};

export const createDistributionSet = (
  requestedValues: UnitsDistribution.QuantitiesFormData,
  distributions: Record<string, Preset.Item[]>,
) => {
  return Object.entries(requestedValues)
    .map(([id, requestedValue]) => {
      const distribution = distributions[id];

      if (!isDefined(distribution)) {
        return undefined;
      }

      return {
        distributionQuantities: distribution,
        requestedValue,
      };
    })
    .filter(isDefined);
};

export const getDistributionByItem = (distributionResult: Preset.Item[], items: Item[]) => {
  return distributionResult.reduce<Record<string, Preset.Item>>((acc, cur) => {
    const item = items.find(({ sizeId }) => sizeId === cur.sizeId.toString())?.item;

    if (!isDefined(item)) {
      return acc;
    }

    return {
      ...acc,
      [item]: cur,
    };
  }, {});
};

const applyCorrection = (parameters: UnitsDistribution.SingleDistributionParameters) => {
  const { distributionQuantities, requestedValue } = parameters;
  const distributedSum = distributionQuantities.reduce((acc, cur) => acc + cur.quantity, 0);
  let toCorrect = requestedValue - distributedSum;

  if (toCorrect < 0) {
    return {
      ...parameters,
      distributionQuantities: [...distributionQuantities].reverse().map(item => {
        if (toCorrect === 0 || item.quantity - 1 < 0) {
          return item;
        }
        toCorrect++;

        return {
          ...item,
          quantity: item.quantity - 1,
        };
      }),
    };
  }

  return { ...parameters, distributionQuantities };
};

const calculateBaseDistribution = (parameters: UnitsDistribution.SingleDistributionParameters) => {
  const { distributionQuantities, requestedValue } = parameters;
  const distributionResult = distributionQuantities.map(item => ({
    ...item,
    quantity: Math.ceil(item.quantity * requestedValue),
  }));

  return { ...parameters, distributionQuantities: distributionResult };
};

const recalculateDistributionChart = (parameters: UnitsDistribution.SingleDistributionParameters) => {
  return {
    ...parameters,
    distributionQuantities: parameters.distributionQuantities.map(item => ({
      ...item,
      quantity: item.quantity / 100,
    })),
  };
};

const sortDistribution = (parameters: UnitsDistribution.SingleDistributionParameters) => {
  const distributionCenter = parameters.distributionQuantities.reduce((acc, cur) => (acc.quantity > cur.quantity ? acc : cur));

  const sortQuantity = (a: Preset.Item, b: Preset.Item) => b.quantity - a.quantity;
  const sortItemTableX = (a: Preset.Item, b: Preset.Item) =>
    distributionCenter.itemTableX - a.itemTableX - (distributionCenter.itemTableX - b.itemTableX);
  const sortItemTableY = (a: Preset.Item, b: Preset.Item) =>
    distributionCenter.itemTableY - a.itemTableY - (distributionCenter.itemTableY - b.itemTableY);

  const sorter = sortWith([sortQuantity, sortItemTableX, sortItemTableY]);

  return {
    ...parameters,
    distributionQuantities: sorter(parameters.distributionQuantities),
  };
};

export const calculateDistributionBase = pipe(recalculateDistributionChart, sortDistribution, calculateBaseDistribution, applyCorrection);
