import { convertBlocksPositionToGrid } from './convertBlocksPositionToGrid';

export const convertGroupBlocksOrder = (response: ReturnType<typeof convertBlocksPositionToGrid>) => {
  const updatedSections = response.sections.map(section => {
    // fallback empty object for first version of CMS that did not have groups (no clients should have such pages)
    const groups = section?.groups ?? {};

    return {
      ...section,
      groups: Object.entries(groups).reduce(
        (acc, [groupId, group]) => ({
          ...acc,
          [groupId]: { blocks: group.blocksOrder.desktop, id: groupId },
        }),
        {},
      ),
    };
  });

  return {
    ...response,
    sections: updatedSections,
  };
};
