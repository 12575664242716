import i18next from 'i18next';
import { combineEpics } from 'redux-observable';
import { EMPTY, interval } from 'rxjs';
import { filter, ignoreElements, mergeMap, tap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { addToast } from '../components/various/Toasts';
import { DEFAULT_LANGUAGES_CODES, FALLBACK_LANGUAGE_CODE } from '../constants/translation';
import {
  authoriseAccessSuccess,
  fetchConfigurationRequest,
  fetchConfigurationSuccess,
  getVersions,
  invalidConfiguration,
  logoutRequest,
  pushEvent,
  reloadPage,
  setUserAsViewer,
} from '../ducks';
import { updateSupportedLanguages } from '../translation/utils';
import { getLanguageAppliedEvent } from '../utils/analytics/events';
import { getToken } from '../utils/auth';
import { getError, getErrorMessages } from '../utils/errors';
import { hideLoader } from '../utils/hideLoader';
import { isDefined } from '../utils/is';
import { report } from '../utils/monitoring';
import { mapResponse } from '../utils/operators/mapResponse';
import { isLocalStorageSupported } from '../utils/persistence';

const ONE_HOUR_IN_MILISECONDS = 3600000;
const UPDATE_CHECK_INTERVAL = 5 * ONE_HOUR_IN_MILISECONDS;

const hideLoaderEpic: AppEpic = action$ =>
  action$.pipe(
    filter(isActionOf([fetchConfigurationSuccess, invalidConfiguration, authoriseAccessSuccess, setUserAsViewer, logoutRequest])),
    tap((action: AppAction) => {
      if (isActionOf(fetchConfigurationSuccess)(action) && isDefined(getToken())) {
        return;
      }

      hideLoader();
    }),
    ignoreElements(),
  );

const fetchConfigurationEpic: AppEpic = (action$, _, { configRepository }) =>
  action$.pipe(
    filter(isActionOf(fetchConfigurationRequest)),
    mergeMap(async ({ payload = undefined }) => configRepository.fetchConfiguration(payload)),
    mapResponse(
      response => {
        const { data } = response;

        updateSupportedLanguages(data.additionalLanguages);

        const trackingData = {
          appliedLanguage: i18next.languages[0] ?? FALLBACK_LANGUAGE_CODE,
          availableLanguages: [...DEFAULT_LANGUAGES_CODES, ...(data.additionalLanguages ?? [])],
          browserLanguage: navigator.language,
        };

        const actions = [pushEvent(getLanguageAppliedEvent(trackingData))];

        return data.deliveryWindows ?
            [...actions, fetchConfigurationSuccess(data)]
          : [...actions, invalidConfiguration({ error: '', message: i18next.t('configErrors:delwins_not_enabled') })];
      },
      failure => {
        const message = isLocalStorageSupported ? i18next.t('configErrors:unspecified_error') : i18next.t('configErrors:no_storage');
        const { errors } = getError(failure);
        const errorMessages = getErrorMessages(errors).join(' ');

        report(`Configuration endpoint failed with status code ${failure.statusCode} and message "${errorMessages}"`);

        return [invalidConfiguration({ error: errorMessages, message })];
      },
    ),
  );

const checkForUpdateEpic: AppEpic = (_, store$, { configRepository }) =>
  interval(UPDATE_CHECK_INTERVAL).pipe(
    mergeMap(configRepository.fetchVersion),
    mapResponse(
      ({ data }) => {
        const store = store$.value;
        const currentVersion = getVersions(store).app;

        if (currentVersion === data.version) {
          return EMPTY;
        }

        addToast(i18next.t('common:new_showroom_available'), {
          actions: [
            {
              action: reloadPage(),
              label: i18next.t('common:refresh'),
            },
          ],
          description: i18next.t('genericErrors:refresh_page_hint'),
          duration: 0,
          id: 'new_showroom_available',
        });

        return EMPTY;
      },
      () => EMPTY,
    ),
  );

const reloadPageEpic: AppEpic = action$ =>
  action$.pipe(
    filter(isActionOf(reloadPage)),
    tap(() => window.location.reload()),
    ignoreElements(),
  );

export const configEpic = combineEpics(fetchConfigurationEpic, hideLoaderEpic, checkForUpdateEpic, reloadPageEpic);
