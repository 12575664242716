import { ActionHandler, Translations } from '@typings';
import { createReducer } from 'typesafe-actions';

import { update } from '../../../utils/update';
import { fetchEditorPageDataFailure, fetchEditorPageDataRequest, fetchEditorPageDataSuccess, refreshPageDataSuccess } from '../../pages';
import { resetCms, setPreviewData } from '../general';

import { setHasUnsavedLayoutChanges, setModifiedLanguages } from './actions';

interface State {
  hasUnsavedLayoutChanges: boolean;
  isLoadingPageData: boolean;
  modifiedLanguages: Translations.SupportedLanguagesCodes[];
}

const initialState: State = {
  hasUnsavedLayoutChanges: false,
  isLoadingPageData: false,
  modifiedLanguages: [],
};

const handleResetStatus: ActionHandler<State, typeof resetCms> = () => initialState;

const handleFetchEditorPageDataFailure: ActionHandler<State, typeof fetchEditorPageDataFailure> = state =>
  update(state, { isLoadingPageData: false });

const handleFetchEditorPageDataRequest: ActionHandler<State, typeof fetchEditorPageDataRequest> = state =>
  update(state, { isLoadingPageData: true });

const handleFetchEditorPageDataSuccess: ActionHandler<State, typeof fetchEditorPageDataSuccess> = state => update(state, initialState);

const handleSetPreviewData: ActionHandler<State, typeof setPreviewData> = state => update(state, { isLoadingPageData: false });

const handleSetModifiedLanguages: ActionHandler<State, typeof setModifiedLanguages> = (state, action) =>
  update(state, { modifiedLanguages: action.payload });

const handleSetHasUnsavedLayoutChanges: ActionHandler<State, typeof setHasUnsavedLayoutChanges> = (state, action) =>
  update(state, {
    hasUnsavedLayoutChanges: action.payload,
  });

const handleRefreshPageDataSuccess: ActionHandler<State, typeof refreshPageDataSuccess> = state =>
  update(state, {
    hasUnsavedLayoutChanges: false,
    modifiedLanguages: initialState.modifiedLanguages,
  });

export default createReducer<State, AppAction>(initialState)
  .handleAction(fetchEditorPageDataFailure, handleFetchEditorPageDataFailure)
  .handleAction(fetchEditorPageDataRequest, handleFetchEditorPageDataRequest)
  .handleAction(fetchEditorPageDataSuccess, handleFetchEditorPageDataSuccess)
  .handleAction(setPreviewData, handleSetPreviewData)
  .handleAction(setHasUnsavedLayoutChanges, handleSetHasUnsavedLayoutChanges)
  .handleAction(setModifiedLanguages, handleSetModifiedLanguages)
  .handleAction(refreshPageDataSuccess, handleRefreshPageDataSuccess)
  .handleAction(resetCms, handleResetStatus);
