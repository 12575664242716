import { useQuery } from '@tanstack/react-query';

import { getDelwinNames } from '../../../ducks/helpers';
import { fetchAllLookbooks } from '../../lookbookRepository';

export const LOOKBOOKS_LIST_QUERY_KEY = 'LOOKBOOKS_LIST_QUERY_KEY';

export const useLookbooksList = () => {
  return useQuery({
    queryFn: fetchAllLookbooks,
    queryKey: [LOOKBOOKS_LIST_QUERY_KEY],
    select: response => {
      const { deliveryWindows: allDelwins, lookbooks, markets } = response;

      const lookbooksForTable = lookbooks.map(lookbook => ({
        ...lookbook,
        deliveryWindows: getDelwinNames(allDelwins, lookbook.deliveryWindows),
      }));

      return {
        lookbooks: lookbooksForTable,
        markets,
      };
    },
  });
};
