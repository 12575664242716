import { ColumnSort } from '@tanstack/react-table';
import React from 'react';

import { isDefined } from '../is';

import { useUrlState } from './useUrlState';

export const useUrlSort = () => {
  const [sortValue, setParam] = useUrlState('sort');

  const sortObj: ColumnSort | null = React.useMemo(() => {
    const parts = sortValue.split('.');
    if (isDefined(parts[0]) && isDefined(parts[1])) {
      return {
        desc: parts[1] === 'desc',
        id: parts[0],
      };
    }

    return null;
  }, [sortValue]);

  const onSortChange = React.useCallback(
    (newSort: ColumnSort | null) => {
      if (newSort === null) {
        setParam('');

        return;
      }
      setParam(`${newSort.id}.${newSort.desc ? 'desc' : 'asc'}`);
    },
    [setParam],
  );

  return [sortObj, onSortChange] as const;
};
