import type { UniqueIdentifier } from '@dnd-kit/core';
import { AnimateLayoutChanges, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { CSSProperties } from 'react';

import { iOS } from '../utilities';

import { Item, Props as TreeItemProps } from './Item';

interface Props extends TreeItemProps {
  id: UniqueIdentifier;
}

const animateLayoutChanges: AnimateLayoutChanges = ({ isSorting, wasDragging }) => !(isSorting || wasDragging);

export const SortableItem = ({ id, depth, cannotDrop, isDragOverlay, ...props }: Props) => {
  const {
    attributes,
    isDragging,
    isSorting,
    listeners,
    setDraggableNodeRef,
    setDroppableNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({
    animateLayoutChanges,
    disabled: props.disableInteraction,
    id,
  });

  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <Item
      ref={setDraggableNodeRef}
      wrapperRef={setDroppableNodeRef}
      handleRef={setActivatorNodeRef}
      style={style}
      depth={depth}
      isDragOverlay={isDragOverlay}
      isDragging={isDragging}
      disableSelection={iOS}
      disableInteraction={isSorting}
      handleProps={{
        ...attributes,
        ...listeners,
      }}
      cannotDrop={cannotDrop}
      {...props}
    />
  );
};
