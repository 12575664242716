import React from 'react';

const DEFAULT_INTERVAL = 300;

interface SetInterval {
  callback: () => void;
  interval?: number;
  isPaused?: boolean;
}

export const useSetInterval = ({ callback, interval = DEFAULT_INTERVAL, isPaused = false }: SetInterval) => {
  React.useEffect(() => {
    if (isPaused) {
      return;
    }

    const intervalId = setInterval(callback, interval);

    return () => {
      clearInterval(intervalId);
    };
  }, [interval, isPaused, callback]);
};
