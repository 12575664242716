import { Column } from '@tanstack/react-table';
import { TablesIdentifier } from '@typings';
import React from 'react';

import { useTableColumnsConfiguration } from '../../../utils/hooks/table/useTableColumnsConfiguration';
import { Checkbox } from '../Fields/Checkbox';

import styles from './TableControls.module.scss';

interface Props<T> {
  column: Column<T>;
  tableName: TablesIdentifier;
  onChange?: (columnId: string, isVisible: boolean) => void;
}

export const ColumnItem = <T,>({ column, tableName, onChange }: Props<T>) => {
  const { toggleColumnVisibility } = useTableColumnsConfiguration({ tableName });

  const toggleColumn = React.useCallback(() => {
    onChange?.(column.id, !column.getIsVisible());
    column.toggleVisibility();
    toggleColumnVisibility(column.id);
  }, [column, onChange, toggleColumnVisibility]);

  const columnHeader = React.useMemo(() => {
    const { header } = column.columnDef;

    if (typeof header === 'string') {
      return header;
    }

    return '';
  }, [column]);

  return (
    <li>
      <Checkbox className={styles.checkbox} inputClassName={styles.inputCheckbox} onChange={toggleColumn} checked={column.getIsVisible()}>
        {columnHeader}
      </Checkbox>
    </li>
  );
};
