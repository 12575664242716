import { Features } from '@typings';
import { uniq } from 'ramda';
import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { DISMISSED_ONBOARDINGS_KEY } from '../../../constants/localStorage';
import { paths } from '../../../paths';
import { useLocalStorage } from '../../../utils/hooks/useLocalStorage';
import { isDefined } from '../../../utils/is';

import { Onboarding } from './Onboarding';
import { buttonsFeatureOnboarding, customLayoutsOnboarding } from './onboardingsContent';

export const OnboardingNotifications = () => {
  const { pathname } = useLocation();
  const onboardings: [string, Features.Onboarding][] = [
    [paths.CMS_EDITOR, customLayoutsOnboarding],
    [paths.CMS_EDITOR, buttonsFeatureOnboarding],
  ];

  const [dismissedOnboardingsKeys, setDismissedOnboardingsKeys] = useLocalStorage<string[]>({
    asJson: true,
    defaultValue: [],
    key: DISMISSED_ONBOARDINGS_KEY,
  });

  const hideOnboarding = React.useCallback(
    (id: string) => {
      setDismissedOnboardingsKeys(uniq([...dismissedOnboardingsKeys, id]));
    },
    [dismissedOnboardingsKeys, setDismissedOnboardingsKeys],
  );
  const firstMatchingOnboarding = React.useMemo(() => {
    return onboardings.find(
      ([onboardingPath, onboarding]) => !dismissedOnboardingsKeys.includes(onboarding.id) && matchPath({ path: onboardingPath }, pathname),
    );
  }, [dismissedOnboardingsKeys, pathname]);

  if (!isDefined(firstMatchingOnboarding)) {
    return null;
  }

  const [_, onboardingToDisplay] = firstMatchingOnboarding;

  return <Onboarding key={onboardingToDisplay.id} hideOnboarding={hideOnboarding} onboarding={onboardingToDisplay} />;
};
