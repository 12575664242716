/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;
const ORIGINAL_COLOR = 'currentColor';

export const ArrowDown = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => {
  return (
    <svg viewBox="0 0 20 20" fill="none" width={width} height={width}>
      <path
        d="M13.851 8.132A.511.511 0 0013.5 8h-7a.512.512 0 00-.352.132.406.406 0 00-.148.312c0 .12.05.225.148.313l3.5 3.111c.1.088.216.132.352.132a.511.511 0 00.351-.132l3.5-3.111A.406.406 0 0014 8.444c0-.12-.05-.224-.149-.312z"
        fill={color}
      />
    </svg>
  );
};
