/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;

export const Vimeo = ({ width = ORIGINAL_WIDTH }: IconComponentProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox="0 0 20 20" fill="none">
    <path
      d="M14.9564 4.00335C13.015 3.92845 11.7037 5.10957 11.0119 7.52813C11.3677 7.35914 11.7137 7.28362 12.0485 7.28362C14.5095 7.28362 11.4821 12.3319 10.7284 12.3319C9.84061 12.3319 9.30686 7.82279 9.12603 6.59154C8.87463 4.87991 8.2044 4.07949 7.11533 4.19154C6.65509 4.23796 5.96327 4.68615 5.04221 5.54165C4.37139 6.19473 3.69124 6.84472 3 7.4978L3.65857 8.40903C4.28564 7.93732 4.65138 7.70085 4.7558 7.70085C6.55943 7.70085 6.44043 17 9.29577 17C12.0386 17 16.8662 9.86809 16.9863 7.00939L16.9939 7.00319C17.072 5.04395 16.3983 4.04111 14.9564 4.00335Z"
      style={{ fill: '#19B2E5' }}
    />
  </svg>
);
