import { Product as ProductType } from '@typings';
import cx from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import { GetProductUrl } from '../../../logic/products';
import { useOverflowingDistance } from '../../../utils/hooks';
import { isDefined } from '../../../utils/is';

import { ProductContextProvider, useProductContext } from './context/ProductContext';
import { Gallery } from './Gallery';
import styles from './Product.module.scss';
import { ProductAttributes } from './ProductAttributes';
import { ProductInfo } from './ProductInfo';

const BOTTOM_MARGIN = 15;

interface ProductComponentProps {
  className?: string;
  isHoverable?: boolean;
  style?: Record<string, unknown>;
}

interface Props extends ProductComponentProps {
  productInfo: ProductType.Standard;
  shouldHideButtons?: boolean;
  getProductUrl: GetProductUrl;
}

const ProductComponent = ({ className, style, isHoverable = true }: ProductComponentProps) => {
  const { containerRef, activeSlideIndex, gallerySlides, containerBottomOffset, activeSlideVariant, productPathName } = useProductContext();
  const wrapperClassNames = cx(styles.big, styles.list, styles.wrapper, { [styles.hoverable]: isHoverable }, className);
  const variantInfoRef = React.useRef<HTMLDivElement>(null);
  const firstVariant = gallerySlides[0]?.variant;

  const overflowingVariantInfoDistance = useOverflowingDistance(
    {
      childRef: variantInfoRef,
      direction: 'bottom',
      wrapperRef: containerRef,
    },
    [activeSlideIndex],
  );

  const bottomOffset = React.useMemo(() => {
    if (containerBottomOffset > 0) {
      return -containerBottomOffset;
    }

    if (overflowingVariantInfoDistance > 0) {
      return -overflowingVariantInfoDistance;
    }

    return -BOTTOM_MARGIN;
  }, [overflowingVariantInfoDistance, containerBottomOffset]);

  const wrapperStyle = {
    bottom: bottomOffset,
  };
  const isFirstVariantSelected = activeSlideIndex === 0;

  return (
    <div ref={containerRef} className={wrapperClassNames} style={style} data-testid="productCard">
      <Gallery />
      <div className={styles.hoverShadow} style={wrapperStyle} />
      <div className={styles.productContent} ref={variantInfoRef}>
        <div className={cx(styles.productData, { [styles.hidden]: !isFirstVariantSelected })}>
          {isDefined(firstVariant) && (
            <Link to={productPathName}>
              <ProductInfo variant={firstVariant} />
              <ProductAttributes variant={firstVariant} shouldUpdateContainerBottomOffset={isFirstVariantSelected} />
            </Link>
          )}
        </div>
        {!isFirstVariantSelected && isDefined(activeSlideVariant) && (
          <div className={cx(styles.productData, styles.productVariantOverlay)}>
            <Link to={productPathName}>
              <ProductInfo variant={activeSlideVariant} />
              <ProductAttributes variant={activeSlideVariant} shouldUpdateContainerBottomOffset={!isFirstVariantSelected} />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export const Product = React.memo(
  ({ getProductUrl, productInfo, shouldHideButtons = false, isHoverable = true, className, style }: Props) => {
    return (
      <ProductContextProvider productInfo={productInfo} shouldHideButtons={shouldHideButtons} getProductUrl={getProductUrl}>
        <ProductComponent className={className} style={style} isHoverable={isHoverable} />
      </ProductContextProvider>
    );
  },
);
