import accounts from '../../../translations/sv/accounts.json';
import buyers from '../../../translations/sv/buyers.json';
import cancelled from '../../../translations/sv/cancelled.json';
import checkout from '../../../translations/sv/checkout.json';
import checkoutMessages from '../../../translations/sv/checkoutMessages.json';
import cms from '../../../translations/sv/cms.json';
import cmsConfirmationConfig from '../../../translations/sv/cmsConfirmationConfig.json';
import common from '../../../translations/sv/common.json';
import configErrors from '../../../translations/sv/configErrors.json';
import confirmationConfig from '../../../translations/sv/confirmationConfig.json';
import dates from '../../../translations/sv/dates.json';
import expiredDelwins from '../../../translations/sv/expiredDelwins.json';
import genericErrors from '../../../translations/sv/genericErrors.json';
import invoices from '../../../translations/sv/invoices.json';
import linesheets from '../../../translations/sv/linesheets.json';
import login from '../../../translations/sv/login.json';
import lookbook from '../../../translations/sv/lookbook.json';
import menu from '../../../translations/sv/menu.json';
import onboarding from '../../../translations/sv/onboarding.json';
import orders from '../../../translations/sv/orders.json';
import pagination from '../../../translations/sv/pagination.json';
import passwordManagement from '../../../translations/sv/passwordManagement.json';
import payments from '../../../translations/sv/payments.json';
import products from '../../../translations/sv/products.json';
import selectionOverview from '../../../translations/sv/selectionOverview.json';
import selections from '../../../translations/sv/selections.json';
import settings from '../../../translations/sv/settings.json';
import shipping from '../../../translations/sv/shipping.json';
import totals from '../../../translations/sv/totals.json';
import translations from '../../../translations/sv/translations.json';
import validation from '../../../translations/sv/validation.json';

export const sv = {
  accounts,
  buyers,
  cancelled,
  checkout,
  checkoutMessages,
  cms,
  cmsConfirmationConfig,
  common,
  configErrors,
  confirmationConfig,
  dates,
  expiredDelwins,
  genericErrors,
  invoices,
  linesheets,
  login,
  lookbook,
  menu,
  onboarding,
  orders,
  pagination,
  passwordManagement,
  payments,
  products,
  selectionOverview,
  selections,
  settings,
  shipping,
  totals,
  translations,
  validation,
} as const;
