import cx from 'classnames';
import React from 'react';

import Icon, { IconType } from '../../Icon';

import styles from './EtaLegend.module.scss';

interface Props {
  color: string;
  label: string;
  isHoverable?: boolean;
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
}

export const EtaLegendItem = ({ color, label, isHoverable = false, onMouseEnter, onMouseLeave }: Props) => {
  const hoverableAttributes = {
    onMouseEnter,
    onMouseLeave,
  };

  const classNames = cx(styles.item, {
    [styles.hoverable]: isHoverable,
  });

  return (
    <div className={classNames} {...(isHoverable && hoverableAttributes)}>
      <span className={styles.icon} style={{ color }}>
        <Icon type={IconType.Hourglass} />
      </span>
      {label}
    </div>
  );
};
