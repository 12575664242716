import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Id } from '@typings';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

import { addToast } from '../../../components/various/Toasts';
import { getResponseErrors } from '../../../utils/errors';
import { deleteBuyer } from '../../accountRepository';
import { ACCOUNTS_QUERY_KEY } from '../accounts/useAccounts';
import { BUYERS_QUERY_KEY } from '../accounts/useBuyers';

interface MutationVariables {
  accountId: Id;
  buyerId: Id;
}

export const useDeleteBuyer = () => {
  const { t } = useTranslation(['buyers']);
  const queryClient = useQueryClient();

  const mutationFn = async ({ accountId, buyerId }: MutationVariables) => {
    return deleteBuyer(accountId, buyerId);
  };

  const handleSuccess = (_: unknown, { accountId }: MutationVariables) => {
    addToast(t('buyers:buyer_deleted'));
    queryClient.invalidateQueries({ queryKey: ['accountDetails', { accountId }] });
    queryClient.invalidateQueries({ queryKey: [BUYERS_QUERY_KEY] });
    queryClient.invalidateQueries({ queryKey: [ACCOUNTS_QUERY_KEY] });
  };

  const handleError = (error: AxiosError<Responses.Errors>) => {
    const [errorMessage] = getResponseErrors(error);
    addToast(errorMessage);
  };

  return useMutation({
    mutationFn,
    onError: handleError,
    onSuccess: handleSuccess,
  });
};
