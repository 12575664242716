/**
 * Given an object of type `T` and `updateSpec` object with matching
 * properties to be merged, returns a new object of type `T`.
 *
 * To remove a value, explicitly set it to `undefined`. If you'd like
 * a property to have a value of `undefined`, set `prune` to `false`.
 *
 * @param source Original object.
 * @param updateSpec Object to be merged with `source`.
 * @param prune Use `false` to preserve values explicitly set as `undefined`.
 *  Prune invalidates all object's properties. Use with caution❗
 */
export function update<T extends Record<string, Unrestricted>>(source: T, updateSpec: Partial<T>, prune = false): T {
  const result = Object.assign({}, source, updateSpec);

  return prune ? JSON.parse(JSON.stringify(result)) : result;
}

/**
 * Merges two arrays. Returns new array.
 */
export function merge<T>(source: T[], pushedArray: T[]): T[] {
  return source.concat(pushedArray);
}
