import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { isNull } from '../../../../utils/is';
import { DatePickerSelectionContext } from '../context';

import styles from './DatePickerControls.module.scss';

interface Props {
  size?: 'small';
}

export const DatePickerClearButton = ({ size }: Props) => {
  const { t } = useTranslation(['common']);
  const { startDate, resetSelection } = React.useContext(DatePickerSelectionContext);

  return (
    <button
      type="button"
      className={cx(styles.button, styles.colorBlue, { [styles.sizeSmall]: size === 'small' })}
      disabled={isNull(startDate)}
      onClick={resetSelection}
    >
      {t('common:clear')}
    </button>
  );
};
