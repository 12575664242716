import {
  CellContext,
  createColumnHelper,
  ExpandedStateList,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  Row,
  SortingState,
} from '@tanstack/react-table';
import { Overview } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getOrderCurrency } from '../../../../ducks';
import { formatPriceWithCurrencyAffix } from '../../../../logic/price';
import {
  buildTree,
  getPercentageSource,
  isVariantRow,
  sortOverviewListing,
  sortPrimaryDimension,
} from '../../../../logic/selectionOverview';
import { getIsFirefox } from '../../../../utils/getIsFirefox';
import { useTable } from '../../../../utils/hooks/table/useTable';
import { isDefined } from '../../../../utils/is';
import { isEmpty } from '../../../../utils/isEmpty';
import { listToRecord } from '../../../../utils/normalize';
import { mapBooleanRecordToList, renderStandardCell } from '../../../../utils/table';
import { Table } from '../../../various/Table';
import { ExpanderCell } from '../../../various/Table/components/ExpanderCell';
import { BarChart } from '../BarChart';
import { PieChart } from '../PieChart';
import { SelectionOverviewContext } from '../SelectionOverviewContext';

import styles from './OverviewTable.module.scss';
import { renderMaybeCell, renderPrimaryDimensionCellContent, renderSecondaryDimensionCell } from './overviewTableCells';
import { PercentageColumnTitle } from './PercentageColumnTitle';

const NESTED_ROW_PADDING = 40;

const columnHelper = createColumnHelper<Overview.Row>();

export const OverviewTable = () => {
  const { primaryDimensionKey, secondaryDimensionKey, overviewAttributes, filteredVariants, percentageBase } =
    React.useContext(SelectionOverviewContext);

  const { t } = useTranslation(['common', 'totals', 'products']);
  const [chartType, setChartType] = React.useState<Overview.ChartType>('pie');
  const [expanded, setExpanded] = React.useState<ExpandedStateList>({});
  const currency = useSelector(getOrderCurrency);
  const variantsRecords = React.useMemo(() => listToRecord(filteredVariants, 'variant'), [filteredVariants]);
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const primaryDimension = overviewAttributes[primaryDimensionKey];
  const secondaryDimension = isDefined(secondaryDimensionKey) ? overviewAttributes[secondaryDimensionKey] : undefined;
  const categoryTree = React.useMemo(() => {
    if (!isDefined(primaryDimension)) {
      return [];
    }

    return buildTree({
      primaryDimension,
      secondaryDimension,
      variants: filteredVariants,
    });
  }, [filteredVariants, primaryDimension, secondaryDimension]);

  const isPieChart = chartType === 'pie';
  const expandedRowKeys = React.useMemo(() => mapBooleanRecordToList(expanded), [expanded]);
  const renderChart = React.useCallback(
    (record: Overview.Row) => {
      const source = getPercentageSource({
        percentageBase,
        record,
        variants: filteredVariants,
      });
      if (chartType === 'bar') {
        return <BarChart rowCount={source.row} totalCount={source.total} isVariantBar={isVariantRow(record)} />;
      }

      return <PieChart data={categoryTree} totalCount={source.total} percentageBase={percentageBase} expandedRowKeys={expandedRowKeys} />;
    },
    [categoryTree, chartType, filteredVariants, percentageBase, expandedRowKeys],
  );

  const chartColumnRowSpan = React.useMemo(() => {
    if (!isPieChart) {
      return undefined;
    }

    return getIsFirefox() ? 0 : 1000;
  }, [isPieChart]);

  const shouldSkipRenderChartColumnCell = React.useCallback(
    (info: CellContext<Overview.Row, unknown>) => {
      return isPieChart && info.table.getSortedRowModel().rows[0]?.id !== info.row.id;
    },
    [isPieChart],
  );

  const renderPrimaryDimensionCell = React.useCallback(
    ({ row }: CellContext<Overview.Row, unknown>) => {
      return (
        <div
          style={{
            paddingLeft: `${row.depth * NESTED_ROW_PADDING}px`,
          }}
        >
          <div className={styles.cell}>
            {row.getCanExpand() && (
              <ExpanderCell className={styles.cellExpander} onExpand={row.getToggleExpandedHandler()} expanded={row.getIsExpanded()} />
            )}{' '}
            {renderPrimaryDimensionCellContent({
              record: row.original,
              secondaryDimension,
              variantsRecords,
            })}
          </div>
        </div>
      );
    },
    [secondaryDimension, variantsRecords],
  );

  const getPrimaryDimensionColSpan = React.useCallback(
    (row: Row<Overview.Row>) => {
      const isNested = row.depth > 0;

      if (!isEmpty(row.subRows)) {
        return isNested && isDefined(secondaryDimension) ? 2 : 1;
      }

      // eslint-disable-next-line no-magic-numbers
      return isDefined(secondaryDimension) ? 4 : 3;
    },
    [secondaryDimension],
  );

  const handleRowClick = React.useCallback(
    (row: Row<Overview.Row>) => {
      if (isVariantRow(row.original)) {
        return;
      }

      const isRowExpanded = expanded[row.id] ?? false;
      setExpanded(expandedState => {
        return { ...expandedState, [row.id]: !isRowExpanded };
      });
    },
    [setExpanded, expanded],
  );

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('primaryDimension', {
        cell: renderPrimaryDimensionCell,
        header: primaryDimension?.title,
        meta: {
          colSpan: getPrimaryDimensionColSpan,
          width: isDefined(secondaryDimension) ? '20%' : '40%',
        },
        sortingFn: (rowA, rowB) => sortPrimaryDimension(variantsRecords)(rowA.original, rowB.original),
      }),
      ...(isDefined(secondaryDimension) ?
        [
          columnHelper.accessor('secondaryDimension', {
            cell: info =>
              renderSecondaryDimensionCell(
                !isVariantRow(info.row.original) ? info.row.original.secondaryDimension : undefined,
                info.row.original,
              ),
            header: secondaryDimension.title,
            meta: {
              skipCellRender: info => info.row.depth > 0,
            },
            sortingFn: (rowA, rowB) => sortOverviewListing('secondaryDimension')(rowA.original, rowB.original),
          }),
        ]
      : []),
      columnHelper.accessor('productsCount', {
        cell: info => renderMaybeCell(!isVariantRow(info.row.original) ? info.row.original.productsCount : undefined, info.row.original),
        header: t('products:product_other'),
        meta: {
          align: 'right',
          skipCellRender: info => isEmpty(info.row.subRows),
        },
        sortingFn: (rowA, rowB) => sortOverviewListing('productsCount')(rowA.original, rowB.original),
      }),
      columnHelper.accessor('variantsCount', {
        cell: info => renderMaybeCell(!isVariantRow(info.row.original) ? info.row.original.variantsCount : undefined, info.row.original),
        header: t('products:variant_other'),
        meta: {
          align: 'right',
          skipCellRender: info => isEmpty(info.row.subRows),
        },
        sortingFn: (rowA, rowB) => sortOverviewListing('variantsCount')(rowA.original, rowB.original),
      }),
      columnHelper.accessor('totalUnits', {
        cell: renderStandardCell,
        header: t('common:unit_other'),
        meta: {
          align: 'right',
        },
        sortingFn: (rowA, rowB) => sortOverviewListing('totalUnits')(rowA.original, rowB.original),
      }),
      columnHelper.accessor('totalPrice', {
        cell: info => {
          return formatPriceWithCurrencyAffix(currency)(info.getValue());
        },
        header: t('totals:total_one'),
        meta: {
          align: 'right',
        },
        sortingFn: (rowA, rowB) => sortOverviewListing('totalPrice')(rowA.original, rowB.original),
      }),
      columnHelper.display({
        cell: info => renderChart(info.row.original),
        header: () => <PercentageColumnTitle percentageBase={percentageBase} isPieChart={isPieChart} setChartType={setChartType} />,
        id: 'chart',
        meta: {
          cellClassName: cx(styles.chartCell, { [styles.pieChart]: isPieChart }),
          rowSpan: chartColumnRowSpan,
          skipCellRender: shouldSkipRenderChartColumnCell,
          width: '30%',
        },
      }),
    ],
    [
      renderPrimaryDimensionCell,
      primaryDimension?.title,
      getPrimaryDimensionColSpan,
      secondaryDimension,
      t,
      isPieChart,
      chartColumnRowSpan,
      shouldSkipRenderChartColumnCell,
      variantsRecords,
      currency,
      renderChart,
      percentageBase,
    ],
  );

  const tableClassNames = cx(styles.overviewTable, {
    [styles.withSpacing]: isPieChart,
  });

  const table = useTable({
    columns,
    data: categoryTree,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getRowCanExpand: row => row.subRows.length > 0,
    getRowId: row => row.key,
    getSortedRowModel: getSortedRowModel(),
    getSubRows: row => (isVariantRow(row) ? [] : row.children),
    onExpandedChange: setExpanded,
    onSortingChange: setSorting,
    state: {
      expanded,
      sorting,
    },
  });

  return (
    <Table
      table={table}
      extraBodyRow={() => (
        <tr className={styles.spacer}>
          <td colSpan={5} />
        </tr>
      )}
      className={tableClassNames}
      rowClassName={row => cx({ [styles.variantRow]: isVariantRow(row.original), [styles.clickableRow]: !isVariantRow(row.original) })}
      onRowClick={handleRowClick}
    />
  );
};
