import { DeliveryWindow } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getStockTypeLabels } from '../../../ducks';
import { getHumanTimePeriod, isRunning } from '../../../logic/deliveryWindows';
import { isNull } from '../../../utils/is';

import styles from './DelwinNameAndDate.module.scss';

interface Props {
  deliveryWindow: DeliveryWindow.Mixed;
}

export const TooltipContent = React.memo(({ deliveryWindow }: Props) => {
  const { t } = useTranslation(['common']);
  const stockTypeLabel = useSelector(getStockTypeLabels)[deliveryWindow.stockType];
  const { deliveryPeriod, saleEndDate } = getHumanTimePeriod(deliveryWindow);

  if (isRunning(deliveryWindow)) {
    return (
      <div className={styles.tooltipContent}>
        <span>{t('common:stock_type')}</span>
        <span>{stockTypeLabel}</span>
        <span className={styles.onGoing}>{t('common:ongoing_delivery')}</span>
      </div>
    );
  }

  return (
    <div className={styles.tooltipContent}>
      <span>{t('common:stock_type')}</span>
      <span>{stockTypeLabel}</span>
      {!isNull(deliveryPeriod) && (
        <>
          <span>{t('common:delivery_dates')}</span>
          <span>{deliveryPeriod}</span>
        </>
      )}
      {!isNull(saleEndDate) && (
        <>
          <span>{t('common:sale_end_date')}</span>
          <span>{saleEndDate}</span>
        </>
      )}
    </div>
  );
});
