import { isEmpty } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  getHasAddedProductByBarcode,
  getHasAddingByBarcodeFailed,
  getHasProductDetailsFailed,
  getIsAddingProductByBarcode,
  getIsLoadingProductDetails,
  getIsProductByBarcodeSoldOut,
  getProductDetails,
  getShouldAddToSelection,
  loadSingleProductByBarcodeRequest,
  pushEvent,
  toggleShouldAddToSelection,
} from '../../../ducks';
import { BarcodeScannerTrackingEvent } from '../../../utils/analytics/events';
import { getHasBarcodeDetector } from '../../../utils/getHasBarcodeDetector';
import { isDefined } from '../../../utils/is';
import { Alert } from '../Alert';
import { Option, Select } from '../Fields/Select';
import { Switch } from '../Fields/Switch';

import { LoadingProduct } from './ScannerMessages/LoadingProduct';
import { NoPermissions } from './ScannerMessages/NoPermissions';
import { NoSupport } from './ScannerMessages/NoSupport';
import { PreparingScanner } from './ScannerMessages/PreparingScanner';
import styles from './ScannerContent.module.scss';
import { useCamera } from './useCamera';
import { useScanWithBarcodeDetector } from './useScanWithBarcodeDetector';
import { useScanWithWasm } from './useScanWithWasm';

const VIDEO_FRAME_RATE = 15;
const LAST_CAMERA_ID_KEY = 'lastBarcodeScannerCamera';

const useScan = getHasBarcodeDetector() ? useScanWithBarcodeDetector : useScanWithWasm;

export const ScannerContent = () => {
  const videoRef = React.useRef<Nullable<HTMLVideoElement>>(null);
  const streamRef = React.useRef<Nullable<MediaStream>>(null);

  const { t } = useTranslation(['common', 'selections', 'products']);
  const [scannedCode, setScannedCode] = React.useState<Nullable<string>>(null);
  const [selectedCameraId, setSelectedCameraId] = React.useState<Nullable<string>>(null);
  const [hasPermissions, setHasPermissions] = React.useState<Nullable<boolean>>(null);
  const [hasRequestedPermissions, setHasRequestedPermissions] = React.useState(false);

  const dispatch = useDispatch();
  const productDetails = useSelector(getProductDetails);
  const isLoadingProduct = useSelector(getIsLoadingProductDetails);
  const hasAddedProductByBarcode = useSelector(getHasAddedProductByBarcode);
  const hasProductDetailsFailed = useSelector(getHasProductDetailsFailed);
  const isProductByBarcodeSoldOut = useSelector(getIsProductByBarcodeSoldOut);
  const hasAddingByBarcodeFailed = useSelector(getHasAddingByBarcodeFailed);
  const isAddingProductByBarcode = useSelector(getIsAddingProductByBarcode);
  const shouldAddToSelection = useSelector(getShouldAddToSelection);

  const { availableCameras, hasCamera } = useCamera({ hasPermissions });
  const hasMultipleCameras = availableCameras.length > 1;

  const startScanner = React.useCallback((deviceId: Nullable<string>) => {
    const defaultRearCamera = {
      facingMode: 'environment',
      frameRate: VIDEO_FRAME_RATE,
    };
    const deviceById = {
      deviceId: { exact: deviceId ?? undefined },
      frameRate: VIDEO_FRAME_RATE,
    };

    const constraints = {
      video: isDefined(deviceId) ? deviceById : defaultRearCamera,
    };

    stopStream(streamRef.current);

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(stream => {
        if (!isDefined(videoRef.current)) {
          stopStream(stream);

          return;
        }

        streamRef.current = stream;
        videoRef.current.srcObject = stream;
        const [videoTrack] = stream.getTracks();
        const videoTrackDeviceId = videoTrack?.getSettings().deviceId;
        setHasPermissions(true);

        if (!isDefined(videoTrackDeviceId)) {
          return;
        }

        setSelectedCameraId(videoTrackDeviceId);
        localStorage.setItem(LAST_CAMERA_ID_KEY, videoTrackDeviceId);
      })
      .catch((error: unknown) => {
        // eslint-disable-next-line no-console
        console.error({ error });
        setHasPermissions(false);
      });
  }, []);

  const requestPermissions = () => {
    setHasRequestedPermissions(true);
    startScanner(selectedCameraId);
  };

  const { activateScanner, isLoadingScanner } = useScan({ setScannedCode, videoRef });

  const handleToggleShouldAddToSelection = React.useCallback(() => {
    dispatch(toggleShouldAddToSelection(!shouldAddToSelection));
  }, [dispatch, shouldAddToSelection]);

  const updateCamera = React.useCallback(
    (deviceId: string) => {
      if (deviceId === selectedCameraId) {
        return;
      }

      localStorage.setItem(LAST_CAMERA_ID_KEY, deviceId);

      setSelectedCameraId(deviceId);
      startScanner(deviceId);
    },
    [selectedCameraId, startScanner],
  );

  React.useEffect(() => {
    if (!isDefined(scannedCode)) {
      return;
    }

    dispatch(pushEvent({ event: BarcodeScannerTrackingEvent.SCANNER_USED }));
    dispatch(loadSingleProductByBarcodeRequest({ productBarcode: scannedCode }));
  }, [dispatch, scannedCode]);

  React.useEffect(() => {
    if (isAddingProductByBarcode) {
      return;
    }

    activateScanner();
    setScannedCode(null);
  }, [isAddingProductByBarcode, activateScanner]);

  React.useEffect(() => {
    if (!hasProductDetailsFailed) {
      return;
    }

    activateScanner();
    setScannedCode(null);
  }, [hasProductDetailsFailed, activateScanner]);

  const stopStream = (stream?: Maybe<MediaStream>) => {
    stream?.getTracks().forEach(track => {
      // eslint-disable-next-line functional/immutable-data
      track.enabled = false;
      track.stop();
      stream.removeTrack(track);
    });
  };

  React.useEffect(() => {
    if (hasPermissions || !hasCamera || isEmpty(availableCameras)) {
      return;
    }

    const savedId = localStorage.getItem(LAST_CAMERA_ID_KEY) ?? null;

    startScanner(savedId);
  }, [availableCameras, hasCamera, hasPermissions, startScanner]);

  React.useEffect(() => {
    return () => {
      stopStream(streamRef.current);
    };
  }, []);

  return (
    <>
      {hasMultipleCameras && (
        <div className={styles.cameraSelectorContainer}>
          <div className={styles.cameraDescription}>{t('common:camera_hint')}</div>
          <div className={styles.cameraSelector}>
            <span>{t('common:camera')}</span>
            <Select value={selectedCameraId} size="small" isDisabled={!isDefined(selectedCameraId)} onChange={updateCamera}>
              {availableCameras.map(({ label, deviceId }) => (
                <Option key={deviceId} value={deviceId}>
                  {label}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      )}

      <div className={styles.addToSelectionSwitch}>
        <Switch size="large" onValueChange={handleToggleShouldAddToSelection} checked={shouldAddToSelection} />
        <span>{t('common:automatically_add_scanned_products')}</span>
      </div>
      <div className={styles.scanArea}>
        <video className={styles.scanner} ref={videoRef} muted autoPlay playsInline />
        {isLoadingScanner && <PreparingScanner />}

        {hasPermissions === false && (
          <NoPermissions hasRequestedPermissions={hasRequestedPermissions} requestPermissions={requestPermissions} />
        )}
        {(isLoadingProduct || isAddingProductByBarcode) && (
          <LoadingProduct
            scannedCode={scannedCode}
            isAddingProductByBarcode={isAddingProductByBarcode && !isLoadingProduct}
            productName={productDetails.name}
          />
        )}
        {!hasCamera && <NoSupport />}
        {hasProductDetailsFailed && <Alert className={styles.alert} type="error" message={t('common:scan_again')} />}
        {isProductByBarcodeSoldOut && <Alert className={styles.alert} type="error" message={t('products:product_is_sold_out')} />}
        {hasAddingByBarcodeFailed && (
          <Alert className={styles.alert} type="error" message={t('common:failed_to_add_product_to_selection')} />
        )}
        {hasAddedProductByBarcode && <Alert className={styles.alert} type="success" message={t('selections:added_to_selection')} />}
      </div>
    </>
  );
};
