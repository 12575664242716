import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { MATRIX_MOBILE_BREAKPOINT } from '../../../../constants/breakpoints';
import { getScannedBarcode, resetScannedBarcode, setEtaVisibilityOnDesktop } from '../../../../ducks';
import { getHasProductDetailsFailed, loadSingleProductRequest, resetAllProductDetailsData } from '../../../../ducks/productDetails';
import { closeProductDetailsModal } from '../../../../utils/closeProductDetailsModal';
import { useIsScreenSize, useUrlProductId } from '../../../../utils/hooks';
import { isDefined } from '../../../../utils/is';
import { isEmpty } from '../../../../utils/isEmpty';
import { Modal, ModalCloseButton, ModalSheet, ModalSize } from '../../../various/ModalElements';
import { ProductDetailsModalContextProvider } from '../context/ProductDetailsModalContext';

import { NavigationArrows } from './NavigationArrows';
import styles from './ProductDetailsModal.module.scss';
import { ProductDetailsModalInner } from './ProductDetailsModalInner';

const customModalStyles = { overlay: { zIndex: 5 } };

export const ProductDetailsModal = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const scannedBarcode = useSelector(getScannedBarcode);
  const productId = useUrlProductId();
  const hasProductId = isDefined(productId) && !isEmpty(productId);
  const hasProductDetailsFailed = useSelector(getHasProductDetailsFailed);
  const isMobile = useIsScreenSize(MATRIX_MOBILE_BREAKPOINT);

  const handleUnmount = React.useCallback(() => {
    if (!isMobile) {
      dispatch(setEtaVisibilityOnDesktop(false));
    }
  }, [dispatch, isMobile]);

  const onModalClosed = () => {
    dispatch(resetAllProductDetailsData());
  };

  React.useEffect(() => {
    if (hasProductId) {
      dispatch(resetAllProductDetailsData());
      dispatch(loadSingleProductRequest({ productId }));
    }
  }, [productId, hasProductId]);

  const hideModal = React.useCallback(() => {
    if (isDefined(scannedBarcode)) {
      dispatch(resetScannedBarcode());
    }

    closeProductDetailsModal(location.search, navigate);
  }, [navigate, location.search, scannedBarcode, dispatch]);

  const modalSize = hasProductDetailsFailed ? ModalSize.Large : ModalSize.XXLarge;

  return (
    <Modal
      isOpen={hasProductId}
      onRequestClose={hideModal}
      size={modalSize}
      customModalStyles={customModalStyles}
      shouldCloseOnLocationChange={false}
      fullScreenOnMobile
      shouldCloseOnOverlayClick
      modalClosedCallback={onModalClosed}
    >
      <ProductDetailsModalContextProvider productId={productId} isInDetailsModal>
        <ModalSheet id="productDetailsModal" className={styles.modalInner} onUnmount={handleUnmount} noHeading>
          <ProductDetailsModalInner />
          <ModalCloseButton dataTestId="closeProductDetailsModal" />
        </ModalSheet>
        {!hasProductDetailsFailed && <NavigationArrows />}
      </ProductDetailsModalContextProvider>
    </Modal>
  );
};
