import { useSelector } from 'react-redux';

import { getAllDeliveryWindows, getIsStockTypeSeparationEnabled, getStockTypeFilter } from '../../ducks';
import { isDefined } from '../is';

export const useDelwinIdsWithCurrentStockType = (delwinIdsToLimitTo?: string[]) => {
  const allDeliveryWindows = useSelector(getAllDeliveryWindows);
  const isStockTypeSeparationEnabled = useSelector(getIsStockTypeSeparationEnabled);
  const currentStockType = useSelector(getStockTypeFilter);

  if (!isDefined(delwinIdsToLimitTo)) {
    const delwinsWithCurrentStockType =
      isStockTypeSeparationEnabled ? allDeliveryWindows.filter(delwin => delwin.stockType === currentStockType) : allDeliveryWindows;

    return delwinsWithCurrentStockType.map(delwin => delwin.deliveryWindow);
  }

  if (!isStockTypeSeparationEnabled) {
    return delwinIdsToLimitTo;
  }

  const hasDelwinCurrentStockType = (delwinId: string) =>
    allDeliveryWindows.find(delwin => delwin.deliveryWindow === delwinId)?.stockType === currentStockType;

  return delwinIdsToLimitTo.filter(hasDelwinCurrentStockType);
};
