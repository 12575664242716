import { Cms } from '@typings';

import { BLOCK_SPACING, COLUMN_WIDTH, MAX_COLUMN_SPAN, ROW_HEIGHT } from '../../constants/cms';
import { clamp } from '../../utils/clamp';
import { isDefined } from '../../utils/is';

interface GetProductsCountOnHorizonalArgs {
  block: Cms.CustomBlockModel;
  screenType: Cms.ScreenType;
  spanX: number;
}

export const getProductsCountOnHorizonal = ({ block, screenType, spanX }: GetProductsCountOnHorizonalArgs) => {
  return block.position.desktop.spanY * (screenType === 'desktop' ? spanX : block.position.desktop.spanX);
};

interface GetNewBlockOnResizeUpdateArgs {
  isContentBlock: boolean;
  productsCount: number;
  spanX: number;
  startX?: number;
}

export const getNewBlockOnLeftResizeUpdate = ({ isContentBlock, productsCount, spanX, startX }: GetNewBlockOnResizeUpdateArgs) => {
  if (isContentBlock) {
    return { spanX, startX };
  }

  return { spanX: Math.min(productsCount, spanX), spanY: Math.ceil(productsCount / spanX), startX };
};

interface GetBlockOnRightResizingArgs {
  blockWidth: number;
  screenType: Cms.ScreenType;
  position: Cms.BlockPosition;
}

export const getBlockOnRightResizing = ({ blockWidth, screenType, position }: GetBlockOnRightResizingArgs) => {
  const maxColumnSpan = MAX_COLUMN_SPAN[screenType] - position[screenType].startX;
  const maxWidth = maxColumnSpan * (COLUMN_WIDTH + BLOCK_SPACING) - BLOCK_SPACING;
  const width = clamp(COLUMN_WIDTH, maxWidth)(blockWidth);
  const calculatedColumnSpan = Math.round(width / (COLUMN_WIDTH + BLOCK_SPACING));
  const spanX = clamp(1, maxColumnSpan)(calculatedColumnSpan);

  return { spanX, width };
};

interface GetBlockOnLeftResizingArgs extends GetBlockOnRightResizingArgs {
  dragOffset?: number;
}

export const getBlockOnLeftResizing = ({ blockWidth, screenType, position, dragOffset = 0 }: GetBlockOnLeftResizingArgs) => {
  const maxColumnSpan = position[screenType].startX + position[screenType].spanX - 1;
  const maxWidth = maxColumnSpan * (COLUMN_WIDTH + BLOCK_SPACING) - BLOCK_SPACING;
  const width = clamp(COLUMN_WIDTH, maxWidth)(blockWidth);
  const calculatedColumnSpan = Math.round(width / (COLUMN_WIDTH + BLOCK_SPACING));
  const spanX = clamp(1, maxColumnSpan)(calculatedColumnSpan);
  const columnsDelta = spanX - position[screenType].spanX;
  const startX = position[screenType].startX - columnsDelta;
  const leftDragOffset = width >= maxWidth || width <= COLUMN_WIDTH ? 0 : dragOffset + columnsDelta * (COLUMN_WIDTH + BLOCK_SPACING);

  return { leftDragOffset, spanX, startX, width };
};

interface GetBlockOnBottomResizingArgs {
  blockHeight: number;
}

export const getBlockOnBottomResizing = (blockHeight: number) => {
  const currentRowsHeight = Math.max(ROW_HEIGHT, blockHeight);
  const spanY = Math.round(Math.abs(currentRowsHeight / ROW_HEIGHT));
  const height = Math.max(ROW_HEIGHT, blockHeight);

  return { height, spanY };
};

interface GetBlockOnTopResizingArgs extends GetBlockOnBottomResizingArgs {
  dragOffset?: number;
  screenType: Cms.ScreenType;
  position: Cms.BlockPosition;
}

export const getBlockOnTopResizing = ({ blockHeight, position, dragOffset = 0, screenType }: GetBlockOnTopResizingArgs) => {
  const rowsHeight = Math.max(ROW_HEIGHT, blockHeight);
  const currentRows = Math.round(Math.abs(rowsHeight / ROW_HEIGHT));
  const rowsDelta = currentRows - position[screenType].spanY;
  const projectedStartY = Math.max(1, position[screenType].startY - rowsDelta);
  const maxRowsSpan = position[screenType].startY + position[screenType].spanY - 1;
  const maxHeightInPx = maxRowsSpan * (ROW_HEIGHT + BLOCK_SPACING) - BLOCK_SPACING;
  const projectedHeight = clamp(ROW_HEIGHT, maxHeightInPx)(blockHeight);
  const topOffset =
    projectedHeight >= maxHeightInPx || projectedHeight <= ROW_HEIGHT ? 0 : dragOffset + rowsDelta * (ROW_HEIGHT + BLOCK_SPACING);
  const spanY = clamp(1, maxRowsSpan)(currentRows);

  return { height: projectedHeight, spanY, startY: projectedStartY, topOffset };
};

interface DefaultUpdatedBlocksArs {
  block: Cms.CustomBlockModel;
  screenType: Cms.ScreenType;
}

interface GetUpdatedBlockByWidthArgs extends DefaultUpdatedBlocksArs {
  spanX: number;
  startX?: number;
}

export const getUpdatedBlockByWidth = ({ block, spanX, startX, screenType }: GetUpdatedBlockByWidthArgs) => {
  const blockStartX = isDefined(startX) ? startX : block.position[screenType].startX;

  if (block.blockType === 'content') {
    return {
      ...block,
      position: {
        ...block.position,
        [screenType]: {
          ...block.position[screenType],
          spanX,
          startX: blockStartX,
        },
      },
    };
  }

  const productsCount = getProductsCountOnHorizonal({ block, screenType, spanX });

  return {
    ...block,
    position: {
      ...block.position,
      [screenType]: {
        ...block.position[screenType],
        spanX: Math.min(productsCount, spanX),
        spanY: Math.ceil(productsCount / spanX),
        startX: blockStartX,
      },
    },
  };
};

interface GetUpdatedBlockByHeightArgs extends DefaultUpdatedBlocksArs {
  spanY: number;
  startY?: number;
}

export const getUpdatedBlockByHeight = ({ block, spanY, startY, screenType }: GetUpdatedBlockByHeightArgs) => {
  const blockStartY = isDefined(startY) ? startY : block.position[screenType].startY;

  if (block.blockType === 'content') {
    return {
      ...block,
      position: {
        ...block.position,
        [screenType]: {
          ...block.position[screenType],
          spanY,
          startY: blockStartY,
        },
      },
    };
  }

  if (screenType === 'desktop') {
    const productsCount = block.position.desktop.spanX * spanY;

    return {
      ...block,
      position: {
        ...block.position,
        desktop: {
          ...block.position.desktop,
          spanY,
          startY: blockStartY,
        },
        mobile: {
          ...block.position.mobile,
          spanY: Math.ceil(productsCount / block.position.mobile.spanX),
        },
        tablet: {
          ...block.position.tablet,
          spanY: Math.ceil(productsCount / block.position.tablet.spanX),
        },
      },
    };
  }

  return block;
};
