import cx from 'classnames';
import React from 'react';

import { Autocomplete, Option } from '../../../various/Fields/Select';

import styles from './SizeSelect.module.scss';

interface Props {
  value: number;
  onChange: (value: string | number) => void;
  className?: string;
  disabled?: boolean;
  dataTestId?: string;
}

const MIN_SIZE = 6;
const MAX_SIZE = 288;
// eslint-disable-next-line no-magic-numbers
const AVAILABLE_SIZES = [MIN_SIZE, 8, 13, 16, 18, 24, 36, 48, 68, 72, 96, 144, MAX_SIZE];

export const SizeSelect = ({ value, onChange, className, disabled = false, dataTestId }: Props) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const getIsSizeValid = (size: number) => {
    return size >= MIN_SIZE && size <= MAX_SIZE;
  };

  const handleChange = (size: number) => {
    onChange(size);
  };

  return (
    <div ref={ref} className={cx(styles.sizeSelectHolder, className)}>
      <Autocomplete
        size="small"
        containerRef={ref}
        overflowContainer
        value={value}
        inputMode="numeric"
        placeholder="0"
        allowCustomValues
        validateCustomValue={getIsSizeValid}
        dataTestId={dataTestId}
        isDisabled={disabled}
        onChange={handleChange}
      >
        {AVAILABLE_SIZES.map(size => (
          <Option key={size} value={size}>
            {size}
          </Option>
        ))}
      </Autocomplete>
    </div>
  );
};
