import { Id } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getCmsProductCount, getCmsProducts, getCmsProductsOrder, getDefaultImageSize } from '../../../ducks';
import { useSearchProducts } from '../../../utils/hooks/cms/useSearchProducts';
import { useSectionMarkets } from '../../../utils/hooks/cms/useSectionMarkets';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { HotspotsContext } from '../context/HotspotsContext';
import { LazyProductsList } from '../ProductsList/LazyProductsList';
import { MissingProduct } from '../ProductsList/MissingProduct';
import { ProductRow } from '../ProductsList/ProductRow';
import { RoundCloseButton } from '../RoundCloseButton';

import styles from './HotspotEditor.module.scss';
import { SelectedProduct } from './SelectedProduct';

interface Props {
  selectedId: Id | null;
  updateProductId: (productId: Id | null) => void;
}

export const ProductPicker = ({ selectedId, updateProductId }: Props) => {
  const { t } = useTranslation(['common']);
  const productsOrder = useSelector(getCmsProductsOrder);
  const imageSize = useSelector(getDefaultImageSize);
  const cmsProducts = useSelector(getCmsProducts);
  const allProductsCount = useSelector(getCmsProductCount);
  const { availableMarketsNames } = useSectionMarkets();
  const currentProductData = isDefined(selectedId) ? cmsProducts[selectedId] ?? null : null;
  const { setActiveHotspot } = React.useContext(HotspotsContext);

  const availableProducts = React.useMemo(
    () => productsOrder.map(productId => cmsProducts[productId]).filter(isDefined),
    [productsOrder, cmsProducts],
  );

  const areAllProductsLoaded = allProductsCount === availableProducts.length;

  const { loadMoreProducts, searchPhrase, updateSearchPhrase } = useSearchProducts();

  const handleSetProduct = React.useCallback(
    (productId: string | null) => () => {
      updateProductId(productId);
    },
    [updateProductId],
  );

  const handleClose = React.useCallback(() => {
    setActiveHotspot(null);
  }, []);

  const renderProduct = (id: string) => {
    const productData = cmsProducts[id];

    if (!isDefined(productData)) {
      return <MissingProduct actionAlwaysVisible id={id} action={null} />;
    }

    const buttonName = t(isDefined(selectedId) ? 'common:change' : 'common:select');

    return (
      <ProductRow
        key={productData.product}
        name={productData.name}
        sku={productData.productSku}
        thumbnail={isEmpty(productData.media) ? undefined : productData.media[imageSize]?.[0]}
      >
        <button
          className={styles.actionButton}
          data-testid={`selectProduct-${productData.product}`}
          onClick={handleSetProduct(productData.product)}
        >
          {buttonName}
        </button>
      </ProductRow>
    );
  };

  const selectedProduct = (
    <SelectedProduct productInfo={currentProductData} handleSetProduct={handleSetProduct} imageSize={imageSize} selectedId={selectedId} />
  );

  const productsNotAdded = availableProducts.filter(product => product.product !== selectedId).map(product => product.product);

  return (
    <div className={styles.productSelector}>
      <RoundCloseButton onClick={handleClose} />
      <LazyProductsList
        elementBelowSearch={selectedProduct}
        searchPhrase={searchPhrase}
        onSearchChange={updateSearchPhrase}
        allLoaded={areAllProductsLoaded}
        loadMore={loadMoreProducts}
        markets={availableMarketsNames}
        items={productsNotAdded}
        renderFunction={renderProduct}
        compactSearch
      />
    </div>
  );
};
