import { Cms } from '@typings';
import cx from 'classnames';
import React from 'react';

import { isDesktopWidth, isHiddenInEditorForScreen, isTabletWidth } from '../../../logic/pages';
import { useScreenWidth } from '../../../utils/hooks';
import { Section } from '../../pages/section';
import sectionStyles from '../../pages/section/section.module.scss';
import { EditorUIContext } from '../context/EditorUIContext';

interface Props {
  section: Cms.Section;
}

export const CmsPreviewSection = ({ section }: Props) => {
  const screenWidth = useScreenWidth();
  const { screenType } = React.useContext(EditorUIContext);

  const getActualScreenType = () => {
    if (isDesktopWidth(screenWidth)) {
      return screenType;
    }

    if (isTabletWidth(screenWidth)) {
      return 'tablet';
    }

    return 'mobile';
  };

  const sectionClassNames = cx(sectionStyles.preview, {
    [sectionStyles.hiddenOnCurrentDevice]: isHiddenInEditorForScreen(section.visibleFor.screen, getActualScreenType()),
  });

  return <Section sectionData={section} className={sectionClassNames} />;
};
