import { Selects } from '@typings';
import cx from 'classnames';
import React from 'react';

import universalStyles from '../../../../../../css/utilities/universal.module.scss';
import { useAutocompleteKeyboardHandler } from '../../../../../utils/hooks/select/useAutocompleteKeyboardHandler';
import { isDefined } from '../../../../../utils/is';
import { isEmpty } from '../../../../../utils/isEmpty';
import Icon, { IconColor, IconType } from '../../../Icon';
import { useSelectContext } from '../context/SelectContext';
import { Pills } from '../Pills';

import styles from './SelectTrigger.module.scss';

const ICON_SIZE = {
  large: 20,
  regular: 20,
  small: 16,
};

interface Props {
  options: Selects.Option[];
  search: string;
  renderValue: (value: Selects.NonNullableValue) => string;
  inputMode?: Selects.AutocompleteProps['inputMode'];
  onSearchChange: (value: string) => void;
}

export const SelectInput = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { options, search, inputMode = 'text', renderValue, onSearchChange } = props;
  const {
    dataTestId,
    describedById,
    errorMessageId,
    focusedPosition,
    isDisabled,
    isInvalid,
    isLoading,
    isOpen,
    onBlur,
    open,
    optionsId,
    placeholder,
    selection,
    showArrow,
    size,
    toggleIsOpen,
    triggerId,
  } = useSelectContext();
  const { handleKeyDown } = useAutocompleteKeyboardHandler({ onSearchChange, options, search });

  const [isFocused, setIsFocused] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const isMultipleSelection = Array.isArray(selection);

  React.useEffect(() => {
    if (isOpen) {
      inputRef.current?.focus();
    }
  }, [isOpen]);

  const handleClick = (event: React.MouseEvent) => {
    if (!inputRef.current?.contains(event.target as HTMLElement) && !isDisabled) {
      toggleIsOpen();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (inputMode === 'numeric' && isNaN(Number(value))) {
      return;
    }

    const normalizedValue = isEmpty(value) || inputMode !== 'numeric' ? value : value.replaceAll(/[^0-9]/g, '');

    open();
    onSearchChange(normalizedValue);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    onBlur?.(event);
  };

  const inputValue = React.useMemo(() => {
    if (isOpen || isMultipleSelection || !isDefined(selection)) {
      return search;
    }

    return renderValue(selection);
  }, [renderValue, isMultipleSelection, isOpen, search, selection]);

  const inputPlaceholder = React.useMemo(() => {
    if (isOpen && isDefined(selection) && !isMultipleSelection) {
      return renderValue(selection);
    }

    return placeholder;
  }, [renderValue, isMultipleSelection, isOpen, placeholder, selection]);

  const classNames = cx(styles.trigger, {
    [styles.active]: isOpen,
    [styles.disabled]: isDisabled,
    [styles.invalid]: isInvalid,
    [styles.sizeSmall]: size === 'small',
    [styles.sizeLarge]: size === 'large',
  });

  const focusedValue = options[focusedPosition ?? -1]?.value;
  const focusedOptionId = isDefined(focusedValue) ? `${optionsId}-${focusedValue}` : undefined;

  return (
    // Keyboard events are handled by input
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div ref={ref} className={classNames} onClick={handleClick}>
      {isFocused && isMultipleSelection && !isOpen && (
        <span aria-live="polite" className={universalStyles.srOnly}>
          {selection.map(renderValue).join(', ')}
        </span>
      )}
      <div className={styles.inputWrapper}>
        <Pills renderValue={renderValue} />
        <input
          ref={inputRef}
          id={triggerId}
          data-testid={dataTestId}
          role="combobox"
          autoComplete="off"
          aria-busy={isLoading}
          aria-expanded={isOpen}
          aria-autocomplete="list"
          aria-controls={optionsId}
          aria-activedescendant={focusedOptionId}
          aria-describedby={describedById}
          aria-invalid={isInvalid}
          aria-errormessage={errorMessageId}
          disabled={isDisabled}
          inputMode={inputMode}
          placeholder={inputPlaceholder}
          value={inputValue}
          className={styles.input}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onChange={handleChange}
          onClick={open}
          onKeyDown={handleKeyDown}
        />
      </div>
      {showArrow && (
        <Icon
          dataTestId="searchInputArrow"
          type={IconType.ArrowDown}
          color={IconColor.DarkGray}
          size={ICON_SIZE[size]}
          rotation={isOpen ? 180 : 0}
        />
      )}
    </div>
  );
});
