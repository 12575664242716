import { createAction } from 'typesafe-actions';

export const selectionImportRequest = createAction('selectionImport/SELECTION_IMPORT_REQUEST')<FormData>();
export const selectionImportSuccess = createAction('selectionImport/SELECTION_IMPORT_SUCCESS')<Responses.SelectionImportSuccess>();
export const selectionImportFailure = createAction('selectionImport/SELECTION_IMPORT_FAILURE')<
  Responses.Errors & {
    wasSelectionClosed?: boolean;
    wasSelectionRemoved?: boolean;
  }
>();

export const selectionImportStatusRequest = createAction('selectionImport/SELECTION_IMPORT_STATUS_REQUEST')<{ jobId: string }>();

export const resetSelectionImport = createAction('selectionImport/RESET_SELECTION_IMPORT')();
