import { Product } from '@typings';

import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { MissingProduct } from '../ProductsList/MissingProduct';
import { ProductRow } from '../ProductsList/ProductRow';

interface Props {
  cmsProducts: Record<string, Product.CmsProduct>;
  imageSize: string;
  id: string;
  action: JSX.Element | null;
}

export const GenericProduct = ({ cmsProducts, imageSize, id, action }: Props) => {
  const productData = cmsProducts[id];

  if (!isDefined(productData)) {
    return <MissingProduct id={id} action={action} />;
  }

  return (
    <ProductRow
      key={productData.product}
      name={productData.name}
      sku={productData.productSku}
      thumbnail={isEmpty(productData.media) ? undefined : productData?.media[imageSize]?.[0]}
    >
      {action}
    </ProductRow>
  );
};
