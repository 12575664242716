import React from 'react';

import { isDefined } from '../is';
import { Key } from '../keys';

export const useOnEscHandler = (
  element: Nullable<HTMLElement>,
  handler: (keyboardEvent?: KeyboardEvent) => void,
  enable: boolean = true,
) => {
  React.useEffect(() => {
    if (!isDefined(element)) {
      return;
    }

    const escHandler = (event: KeyboardEvent) => {
      if (event.code === Key.ESCAPE) {
        handler();
      }
    };

    if (enable) {
      element.addEventListener('keydown', escHandler);
    }

    return () => {
      if (isDefined(element)) {
        element.removeEventListener('keydown', escHandler);
      }
    };
  }, [element, handler, enable]);
};
