const ORIGINAL_WIDTH = 20;
const ORIGINAL_COLOR = '#848484';

export const Eye = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox="0 0 20 20" fill={color}>
      <path d="M10 5a8.235 8.235 0 0 0-7.47 4.765.321.321 0 0 0 0 .272A8.235 8.235 0 0 0 10 14.8c3.309 0 6.16-1.95 7.47-4.764a.32.32 0 0 0 0-.272A8.235 8.235 0 0 0 10 5Zm0 8.283a3.38 3.38 0 1 1 0-6.762 3.38 3.38 0 0 1 0 6.762Z" />
      <path d="M10 12.064a2.164 2.164 0 1 0 0-4.329 2.164 2.164 0 0 0 0 4.33Z" />
    </svg>
  );
};
