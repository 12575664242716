/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;
const ORIGINAL_COLOR = '#848484';

export const AlignLeft = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => (
  <svg width={width} height={width} viewBox="0 0 20 20">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.0001 3.92817L3 16.0719C3 16.5669 3.42 17 3.89995 17C4.3799 17 4.7999 16.5669 4.7999 16.0718L4.8 3.92817C4.8 3.43317 4.38005 3 3.90005 3C3.42004 3 3.0001 3.43317 3.0001 3.92817ZM8.8889 12.2912C8.8889 12.3824 8.84478 12.4454 8.75688 12.4801C8.6689 12.5149 8.5879 12.504 8.51365 12.4476L5.84704 10.1692C5.80076 10.1259 5.7777 10.076 5.7777 10.0195C5.7777 9.95871 5.80078 9.90663 5.84704 9.86325L8.51365 7.55242C8.5879 7.49601 8.6689 7.48511 8.75678 7.51979C8.84478 7.55885 8.88881 7.6218 8.88881 7.70858V9.16669H16.7778C16.8427 9.16669 16.8959 9.18627 16.9376 9.22528C16.9792 9.26434 17 9.3142 17 9.37501V10.6248C17 10.6857 16.9792 10.7357 16.9376 10.7745C16.8959 10.8135 16.8427 10.8331 16.7778 10.8331H8.8889V12.2912Z"
      fill={color}
    />
  </svg>
);
