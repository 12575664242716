import { Product, Switcher } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { pushEvent } from '../../../ducks';
import { getFirstUnit, getProductUnits, getUnitSwitcherName, isSwitchAvailable } from '../../../logic/unitsSwitch';
import { CommonTrackingEvent } from '../../../utils/analytics/events';
import { useUnitSwitcher } from '../../../utils/hooks/unitSwitcher/useUnitSwitcher';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { ContentSwitcher } from '../../various/ContentSwitcher';

import styles from './UnitSwitcher.module.scss';

interface Props {
  defaultUnit?: string;
  tableMappings: Product.UnitMappings;
  size?: Switcher.Size;
  switcherName?: string;
}

export const UnitSwitcher = ({ defaultUnit, tableMappings, size, switcherName }: Props) => {
  const { t } = useTranslation(['common']);
  const dispatch = useDispatch();
  const switchName = getUnitSwitcherName(tableMappings);
  const { selectedUnits, setSelectedUnits } = useUnitSwitcher();
  const selectedUnit = selectedUnits[switchName];

  React.useEffect(() => {
    if (isDefined(selectedUnit) || !isSwitchAvailable(tableMappings)) {
      return;
    }

    const hasDefaultUnit = isDefined(defaultUnit) && !isEmpty(defaultUnit);

    setSelectedUnits({
      ...selectedUnits,
      [getUnitSwitcherName(tableMappings)]: hasDefaultUnit ? defaultUnit : getFirstUnit(tableMappings),
    });
  }, [defaultUnit, selectedUnit, selectedUnits, setSelectedUnits, tableMappings]);

  const onChange = React.useCallback(
    (value: string) => {
      dispatch(pushEvent({ event: CommonTrackingEvent.UNIT_SWITCHER_CLICKED }));
      setSelectedUnits({
        ...selectedUnits,
        [getUnitSwitcherName(tableMappings)]: value,
      });
    },
    [dispatch, setSelectedUnits, selectedUnits, tableMappings],
  );

  if (!isSwitchAvailable(tableMappings)) {
    return null;
  }

  const groupName = isDefined(switcherName) ? `${t('common:unit_one')} - ${switcherName}` : t('common:unit_one');

  return (
    <div className={styles.wrapper}>
      <ContentSwitcher
        tabs={getProductUnits(tableMappings).map(unit => ({
          title: unit,
          value: unit,
        }))}
        groupName={groupName}
        selectedValue={selectedUnit}
        onChange={onChange}
        variant="secondary"
        size={size}
      />
    </div>
  );
};
