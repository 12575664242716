import { API } from '@typings';

export const handleResponseStatus =
  <Success, Fail>(response: API.SuccessResponse<Success> | API.FailedResponse<Fail>) =>
  <SuccessRes, FR>(success: (res: API.SuccessResponse<Success>) => SuccessRes, failure: (res: API.FailedResponse<Fail>) => FR) => {
    const responseStatus = response.status;

    switch (responseStatus) {
      case 'SUCCESS':
        return success(response);
      case 'FAILURE':
        return failure(response);
      default:
        throw new Error(`Unexpected response status: ${responseStatus}`);
    }
  };
