import { SELECTED_UNITS_KEY } from '../../../constants/localStorage';
import { useLocalStorage } from '../useLocalStorage';

export const useUnitSwitcher = () => {
  const [selectedUnits, setSelectedUnits] = useLocalStorage<Record<string, string>>({
    asJson: true,
    defaultValue: {},
    key: SELECTED_UNITS_KEY,
  });

  return {
    selectedUnits,
    setSelectedUnits,
  };
};
