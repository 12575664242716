import { DeliveryWindow } from '@centra';
import { Id } from '@typings';
import { createAction } from 'typesafe-actions';

export const fetchDeliveryWindowsRequest = createAction('deliveryWindows/DELIVERY_WINDOWS_FETCH_REQUEST')();

export const fetchDeliveryWindowsSuccess = createAction('deliveryWindows/DELIVERY_WINDOWS_FETCH_SUCCESS')<{
  deliveryWindows: DeliveryWindow.Mixed[];
  deliveryWindowsOrder?: DeliveryWindow.DeliveryWindowsOrder;
}>();

export const fetchDeliveryWindowsFailure = createAction('deliveryWindows/DELIVERY_WINDOWS_FETCH_FAILURE')();

export const fetchDeliveryWindowsByBuyerIdRequest = createAction('deliveryWindows/DELIVERY_WINDOWS_BY_BUYER_ID_FETCH_REQUEST')<{
  orderId?: Id;
  buyerId: Id;
}>();

export const fetchDeliveryWindowsByBuyerIdSuccess = createAction('deliveryWindows/DELIVERY_WINDOWS_BY_BUYER_ID_SUCCESS')<{
  ids: DeliveryWindow.Id[];
  buyerId: Id;
}>();

export const fetchDeliveryWindowsByBuyerIdFailure = createAction('deliveryWindows/DELIVERY_WINDOWS_BY_BUYER_ID_FAILURE')();

export const setDeliveryWindows = createAction('deliveryWindows/SET_DELIVERY_WINDOWS')<DeliveryWindow.Mixed[]>();
