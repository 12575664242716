import React from 'react';

const DEFAULT_OFFSET_TOP = 75;

interface Props {
  offsetTop?: number;
  isEnabled?: boolean;
}

export const useIsScrollingDown = ({ offsetTop = DEFAULT_OFFSET_TOP, isEnabled = true }: Props = {}) => {
  const lastOffset = React.useRef(0);
  const [isScrollingDown, setIsScrollingDown] = React.useState(false);

  React.useEffect(() => {
    if (!isEnabled) {
      return;
    }

    const handleScroll = () => {
      const { scrollY } = window;
      const isOffsetReached = scrollY > offsetTop;

      setIsScrollingDown(isOffsetReached && scrollY > lastOffset.current);

      lastOffset.current = scrollY;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isEnabled, offsetTop]);

  return isScrollingDown;
};
