import { Product as ProductType } from '@typings';
import { combineEpics, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { filter, flatMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { getBuyerIdFromCurrentOrder } from '../ducks/order';
import { loadRelatedProductDetailsFailure, loadRelatedProductDetailsSuccess, loadSingleProductSuccess } from '../ducks/productDetails';
import { getUserAccess } from '../ducks/user';
import { getIsStandardRelation } from '../logic/merchandise';
import { getVariantRelationRelatedProducts } from '../logic/products';
import { isDefined } from '../utils/is';
import { mapResponse } from '../utils/operators/mapResponse';

export const getStandardRelationProducts = (variants: ProductType.Standard[]) => variants.filter(variant => getIsStandardRelation(variant));

const sendBuyerIdIfRequired = (isLookbook: boolean, store$: StateObservable<Store>) => {
  const access = getUserAccess(store$.value);
  const shouldSendBuyerId = !isLookbook && access === 'seller';

  return shouldSendBuyerId ? getBuyerIdFromCurrentOrder(store$.value) : undefined;
};

const loadRelatedProductsDetailsEpic: AppEpic = (action$, store$, { productsRepository }) => {
  return action$.pipe(
    filter(isActionOf(loadSingleProductSuccess)),
    flatMap((action: { payload: Responses.Product & { isLookbook: boolean } }) => {
      const { product, isLookbook } = action.payload;

      const standardRelationProducts: ProductType.Standard[] =
        isDefined(product.relatedProducts) && isDefined(product.relatedProducts.variants) ?
          getStandardRelationProducts(product.relatedProducts.variants)
        : [];

      const productsToFetchIds = [...new Set(standardRelationProducts.map(({ product: productId }) => productId))];

      return of({ productsToFetchIds }).pipe(
        filter(() => productsToFetchIds.length > 0),
        flatMap(async data => productsRepository.fetchProductsByIds(data.productsToFetchIds, sendBuyerIdIfRequired(isLookbook, store$))),
        mapResponse(
          response => {
            const { products } = response.data;
            const productsArray = Object.values(products);

            const sortedProducts = [...productsArray].sort((a, b) => {
              return productsToFetchIds.indexOf(a.product) - productsToFetchIds.indexOf(b.product);
            });

            return loadRelatedProductDetailsSuccess(getVariantRelationRelatedProducts(sortedProducts));
          },
          () => loadRelatedProductDetailsFailure(),
        ),
      );
    }),
  );
};

export const productDetailsEpic = combineEpics(loadRelatedProductsDetailsEpic);
