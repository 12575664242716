/* eslint-disable max-len */

import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;

export const Link = ({ width = ORIGINAL_WIDTH, color = 'currentColor' }: IconComponentProps) => (
  <svg width={width} height={width} viewBox="0 0 20 20" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.481 4.996L10.07 6.408a.677.677 0 11-.958-.958l1.424-1.424a3.85 3.85 0 015.438 5.44l-2.034 2.026v.001a3.835 3.835 0 01-5.433 0 .677.677 0 11.96-.957 2.48 2.48 0 003.514 0l.001-.002 2.024-2.016a2.495 2.495 0 00-3.524-3.522zM8.777 8.734a2.48 2.48 0 00-1.757.73l-.001.002-2.024 2.016a2.494 2.494 0 003.524 3.522l1.412-1.412a.677.677 0 11.958.958l-1.424 1.424a3.85 3.85 0 01-5.438-5.44L6.06 8.508v-.001a3.835 3.835 0 015.433 0 .678.678 0 01-.96.957 2.48 2.48 0 00-1.757-.73z"
      fill={color}
    />
  </svg>
);
