import { BuyerAccount, BuyerDataChangePayloadDTO, LoginDTO, User } from '@typings';
import { createAction } from 'typesafe-actions';

export const fetchLoginSuccess = createAction('user/LOGIN_SUCCESS')<LoginDTO>();
export const authoriseAccessRequest = createAction('user/AUTHORISE_ACCESS_REQUEST')();
export const authoriseAccessSuccess = createAction('user/AUTHORISE_ACCESS_SUCCESS')<LoginDTO>();
export const logoutRequest = createAction('user/LOGOUT_REQUEST')();
export const resetPasswordDefault = createAction('user/RESET_PASSWORD_DEFAULT')();
export const resetPasswordRequest = createAction('user/RESET_PASSWORD_REQUEST')<{
  email: string;
}>();
export const resetPasswordSuccess = createAction('user/RESET_PASSWORD_SUCCESS')();
export const resetPasswordFailure = createAction('user/RESET_PASSWORD_FAILURE')();
export const changePasswordDefault = createAction('user/CHANGE_PASSWORD_DEFAULT')();

export const changePasswordRequest = createAction('user/CHANGE_PASSWORD_REQUEST')<{
  i: string;
  id: string;
  newPassword: string;
}>();

export const changePasswordSuccess = createAction('user/CHANGE_PASSWORD_SUCCESS')();
export const changePasswordFailure = createAction('user/CHANGE_PASSWORD_FAILURE')<string>();

export const resetEditBuyerData = createAction('user/RESET_EDIT_BUYER_DATA')();
export const updateBuyersDataRequest = createAction('user/UPDATE_BUYER_DATA_REQUEST')<BuyerDataChangePayloadDTO>();
export const updateBuyersDataSuccess = createAction('user/UPDATE_BUYER_DATA_SUCCESS')();
export const updateBuyersDataFailure = createAction('user/UPDATE_BUYER_DATA_FAILURE')();

export const resetChangeAccountPassword = createAction('user/RESET_CHANGE_ACCOUNT_PASSWORD')();
export const changeAccountPasswordRequest = createAction('user/CHANGE_ACCOUNT_PASSWORD_REQUEST')<{
  token: string;
  currentPassword: string;
  newPassword: string;
}>();

export const changeAccountPasswordSuccess = createAction('user/CHANGE_ACCOUNT_PASSWORD_SUCCESS')<{
  token: string;
  password: string;
}>();

export const changeAccountPasswordFailure = createAction<string>('user/CHANGE_ACCOUNT_PASSWORD_FAILURE')();

export const fetchUserRequest = createAction('user/USER_FETCH_REQUEST')();
export const fetchUserSuccess = createAction('user/USER_FETCH_SUCCESS')<{
  user: User;
  account?: Nullable<BuyerAccount>;
}>();

export const setUserAsViewer = createAction('user/SET_USER_AS_VIEWER')();
