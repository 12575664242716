import { Cms } from '@typings';
import cx from 'classnames';

import { ContentSwitcher } from '../../various/ContentSwitcher';
import { IconType } from '../../various/Icon';

import styles from './DeviceSwitcher.module.scss';

interface Props {
  className?: string;
  onChange: (type: Cms.ScreenType) => void;
  value: Cms.ScreenType;
  groupName: string;
}

const options = [
  {
    className: cx(styles.option, styles.desktopOption),
    icon: IconType.DeviceDesktop,
    iconSize: 20,
    value: 'desktop',
  },
  {
    className: styles.option,
    icon: IconType.DeviceTablet,
    iconSize: 14,
    value: 'tablet',
  },
  {
    className: styles.option,
    icon: IconType.DeviceMobile,
    iconSize: 10,
    value: 'mobile',
  },
];

export const DeviceSwitcher = ({ className, onChange, value, groupName }: Props) => {
  return (
    <ContentSwitcher
      tabs={options}
      groupName={groupName}
      selectedValue={value}
      onChange={onChange}
      variant="secondary"
      keepEvenWidth={false}
      className={cx(styles.screenButtonSet, className)}
    />
  );
};
