import { Filter, SwatchData } from '@typings';
import React from 'react';

import { isDefined } from '../../../../../utils/is';
import { isEmpty } from '../../../../../utils/isEmpty';
import { alphabeticSortByKey } from '../../../../../utils/objectSort';

import styles from './FiltersSwatchesGroup.module.scss';
import { SwatchCheckbox } from './SwatchCheckbox';

interface Props {
  filterField: string;
  fieldOptions: Filter.Option<string>[];
  selectedOptions: string[];
  onSelectedOptionsChange: (selectedValues: string[]) => void;
}

const getHasValidSwatch = (option: Filter.Option<string>) => {
  const { swatch } = option;

  if (!isDefined(swatch)) {
    return false;
  }

  const isValidHex = isDefined(swatch.hex) && !isEmpty(swatch.hex);
  const isValidImageUrl = isDefined(swatch.image) && isDefined(swatch.image.url) && !isEmpty(swatch.image.url);
  const isValidDesc = isDefined(swatch.desc) && !isEmpty(swatch.desc);

  return (isValidHex || isValidImageUrl) && isValidDesc;
};

export const FiltersSwatchesGroup = ({ fieldOptions, filterField, selectedOptions, onSelectedOptionsChange }: Props) => {
  const options = React.useMemo(() => {
    const validOptions = fieldOptions.filter(getHasValidSwatch).map(({ filterValue, swatch }) => ({
      swatchData: swatch as SwatchData,
      value: filterValue,
    }));

    return alphabeticSortByKey(validOptions, 'value');
  }, [fieldOptions]);

  const handleToggleSwatch = React.useCallback(
    (value: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;

      const selectedValues = checked ? [...selectedOptions, value] : selectedOptions.filter(selectedValue => selectedValue !== value);
      onSelectedOptionsChange(selectedValues);
    },
    [filterField, selectedOptions],
  );

  return (
    <div className={styles.swatchGrid}>
      {options.map(({ value, swatchData }) => (
        <SwatchCheckbox
          key={value}
          isSelected={selectedOptions.includes(value)}
          handleClick={handleToggleSwatch(value)}
          swatchData={swatchData}
        />
      ))}
    </div>
  );
};
