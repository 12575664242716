import { PopoverMenuItems } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useDispatch } from 'react-redux';

import { hideAllToolbars } from '../../../ducks';
import { isDefined } from '../../../utils/is';
import { HoverToolsContext } from '../../cms/context/HoverToolsContext';
import Icon, { IconColor } from '../../various/Icon';
import { PopoverMenu } from '../../various/PopoverMenu';
import { isParentMenuItem } from '../../various/PopoverMenu/MenuElements/MenuItems';
import { Tooltip } from '../../various/Tooltip';
import { EditorLanguageContext } from '../context/EditorLanguageContext';

import styles from './HoverTools.module.scss';

type ToolsElement = (PopoverMenuItems.ParentItem | PopoverMenuItems.ClickableItem) & {
  hideExpandIndicator?: boolean;
};

interface Props {
  name?: React.ReactNode;
  className?: string;
  elements: ToolsElement[];
  elementRef?: React.RefObject<HTMLDivElement> | React.MutableRefObject<HTMLDivElement>;
  testId?: string;
  hideForTranslation?: boolean;
}

export const HoverTools = ({
  name,
  className,
  children,
  elements,
  elementRef,
  testId,
  hideForTranslation = true,
}: React.WithOptionalChildren<Props>) => {
  const dispatch = useDispatch();
  const [isLockedVisible, setLockedVisible] = React.useState(false);
  const { isHoverToolsHidden, setIsHoverToolsHidden } = React.useContext(HoverToolsContext);
  const { isDefaultLanguage } = React.useContext(EditorLanguageContext);

  const onOpenPopover = React.useCallback(() => {
    setLockedVisible(true);
    setIsHoverToolsHidden(true);
    dispatch(hideAllToolbars());
  }, []);
  const onClosePopover = React.useCallback(() => {
    setLockedVisible(false);
    setIsHoverToolsHidden(false);
  }, []);

  const shouldHideTools = hideForTranslation && !isDefaultLanguage;

  return (
    <div
      className={cx(styles.toolsHover, className, {
        [styles.lockedVisible]: isLockedVisible,
        [styles.lockedHidden]: isHoverToolsHidden && !isLockedVisible,
      })}
      ref={elementRef}
      data-testid={testId}
    >
      <span className={styles.nameWrapper}>{name}</span>
      {!shouldHideTools && (
        <>
          <div className={styles.toolsWrapper}>
            {elements.map((element: ToolsElement, idx) => {
              if (isParentMenuItem(element)) {
                return (
                  <PopoverMenu
                    icon={element.icon}
                    name={element.label}
                    isDisabled={element.isDisabled}
                    classNameRegular={cx(styles.toolButton, {
                      [styles.expandIndicator]: !element.hideExpandIndicator,
                    })}
                    classNameExpanded={styles.expanded}
                    showTooltip={!element.hideExpandIndicator}
                    items={element.items}
                    onOpen={onOpenPopover}
                    onClose={onClosePopover}
                    key={idx}
                    testId={element.testId}
                    targetOffset={4}
                    containerClassName={styles.popoverMenuStyles}
                  />
                );
              }

              return (
                <Tooltip content={element.label} isInteractive={false} key={idx}>
                  <button
                    type="button"
                    className={styles.toolButton}
                    disabled={element.isDisabled}
                    onClick={element.onClick}
                    aria-label={element.label}
                    data-testid={element.testId}
                  >
                    {isDefined(element.icon) && <Icon type={element.icon} color={IconColor.White} />}
                  </button>
                </Tooltip>
              );
            })}
          </div>
          {children}
        </>
      )}
    </div>
  );
};
