import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getOrderId, getSelectionImportWarning } from '../../../ducks';
import { compiledPaths } from '../../../paths';
import { useModalVisibility } from '../../../utils/hooks';
import { isEmpty } from '../../../utils/isEmpty';
import { Alert } from '../../various/Alert';
import { FormButtons } from '../../various/Form';
import Icon, { IconType } from '../../various/Icon';
import { Button } from '../../various/NewButton';

import styles from './SelectionImportModal.module.scss';

export const SelectionImportSuccess = () => {
  const { t } = useTranslation(['common', 'selections']);
  const { hideModal } = useModalVisibility('SelectionImportModal');

  const orderId = useSelector(getOrderId);
  const warningMessage = useSelector(getSelectionImportWarning);

  return (
    <>
      <div className={cx(styles.importStatus, styles.success)}>
        <Icon type={IconType.Check} className={styles.icon} size={32} />
        <div>{t('common:import_completed')}</div>
      </div>
      {!isEmpty(warningMessage) && <Alert type="warning" message={warningMessage} className={styles.alert} />}
      <FormButtons>
        <Button size="large" variant="ghost" color="dark" onClick={hideModal}>
          {t('common:keep_browsing')}
        </Button>
        <Button size="large" as={Link} to={compiledPaths.ORDER_DETAILS({ id: orderId })} onClick={hideModal}>
          {t('selections:see_my_selection')}
        </Button>
      </FormButtons>
    </>
  );
};
