import { Tables } from '@typings';
import cx from 'classnames';
import { WithChildren } from 'react';

import styles from '../Table.module.scss';

export const TableRow = ({ onClick, children, className, level = 0 }: WithChildren<Tables.TableRowProps>) => {
  return (
    <tr onClick={onClick} className={cx(styles.tableRow, `row-level-${level}`, className)}>
      {children}
    </tr>
  );
};
