import { DatePicker } from '@typings';
import { splitEvery } from 'ramda';
import React from 'react';

import { useEventListener } from '../../../../utils/hooks';
import { useContainsActiveElement } from '../../../../utils/hooks/useContainsActiveElement';
import { isDefined } from '../../../../utils/is';
import { Key } from '../../../../utils/keys';
import { DatePickerNavigationContext, DatePickerSelectionContext, DatePickerSettingsContext } from '../context';

import styles from './DatePickerCalendar.module.scss';
import { DatePickerCalendarCell } from './DatePickerCalendarCell';

const HORIZONTAL_NAVIGATION_OFFSET_MAP = {
  [Key.RIGHT]: 1,
  [Key.LEFT]: -1,
};

const NAVIGATION_OFFSET_MAP: Record<DatePicker.View, Record<string, number>> = {
  date: {
    ...HORIZONTAL_NAVIGATION_OFFSET_MAP,
    [Key.UP]: -7,
    [Key.DOWN]: 7,
  },
  month: {
    ...HORIZONTAL_NAVIGATION_OFFSET_MAP,
    [Key.UP]: -3,
    [Key.DOWN]: 3,
  },
  year: {
    ...HORIZONTAL_NAVIGATION_OFFSET_MAP,
    [Key.UP]: -3,
    [Key.DOWN]: 3,
  },
};

const CELLS_PER_ROW = {
  date: 7,
  month: 3,
  year: 3,
};

export const DatePickerCalendar = () => {
  const { view, setView } = React.useContext(DatePickerSettingsContext);
  const { calendarDates, setVisibleDate } = React.useContext(DatePickerNavigationContext);
  const { isRangeSelection } = React.useContext(DatePickerSelectionContext);
  const { containerRef, hasActiveElement } = useContainsActiveElement<HTMLDivElement>();

  React.useEffect(() => setView('date'), []);

  const handleKeyboardNav = React.useCallback(
    (event: KeyboardEvent) => {
      const offset = NAVIGATION_OFFSET_MAP[view][event.key];

      if (!isDefined(offset)) {
        return;
      }

      event.preventDefault();

      const unit = view === 'date' ? 'day' : view;

      setVisibleDate(previousVisibleDate => previousVisibleDate.clone().add(offset, unit));
    },
    [view, setVisibleDate],
  );

  useEventListener({
    element: containerRef,
    eventName: 'keydown',
    handler: handleKeyboardNav,
  });

  const rows = splitEvery(CELLS_PER_ROW[view], calendarDates);

  return (
    <div ref={containerRef} role="grid" aria-multiselectable={isRangeSelection} className={styles.calendar}>
      {rows.map((row, index) => (
        <div key={index} role="row" className={styles.row}>
          {row.map(date => (
            <DatePickerCalendarCell key={date.toString()} shouldFocus={hasActiveElement} date={date} />
          ))}
        </div>
      ))}
    </div>
  );
};
