import { AccountMarket, ActionHandler } from '@typings';
import { createReducer } from 'typesafe-actions';

import { update } from '../../utils/update';

import * as actions from './actions';

export interface MarketsReducer {
  isLoading: boolean;
  markets: AccountMarket[];
}

const initialState: MarketsReducer = {
  isLoading: false,
  markets: [],
};

const handleFetchMarketsSuccess: ActionHandler<MarketsReducer, typeof actions.fetchMarketsSuccess> = (state, action) =>
  update(state, { isLoading: false, markets: action.payload });

const handleFetchMarketsRequest: ActionHandler<MarketsReducer, typeof actions.fetchMarketsRequest> = state =>
  update(state, { isLoading: true });

const handleFetchMarketsFailure: ActionHandler<MarketsReducer, typeof actions.fetchMarketsFailure> = state =>
  update(state, { isLoading: false });

export default createReducer<MarketsReducer, AppAction>(initialState)
  .handleAction(actions.fetchMarketsRequest, handleFetchMarketsRequest)
  .handleAction(actions.fetchMarketsFailure, handleFetchMarketsFailure)
  .handleAction(actions.fetchMarketsSuccess, handleFetchMarketsSuccess);
