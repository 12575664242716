import { Trans, useTranslation } from 'react-i18next';

import { HelpTooltip } from '../../various/HelpTooltip';

export const AvailableProductsTooltip = () => {
  const { t } = useTranslation(['cms']);

  const title = (
    <>
      {t('cms:available_products_rules.title')}
      <ul>
        <li>
          <Trans t={t} i18nKey="cms:available_products_rules.must_be_in.all">
            available in <strong>all</strong> of the markets set on the section
          </Trans>
        </li>
        <li>
          <Trans t={t} i18nKey="cms:available_products_rules.must_be_in.at_least">
            available in <strong>at least</strong> one delivery window <strong>active for all</strong> the markets set on the section
          </Trans>
        </li>
      </ul>
    </>
  );

  return <HelpTooltip content={title} />;
};
