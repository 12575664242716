import React from 'react';
import { useSelector } from 'react-redux';

import { getCmsProducts, getCmsProductsOrder } from '../../../ducks/cms/products';
import { isDefined } from '../../is';

export const useAvailableCmsProducts = () => {
  const productsOrder = useSelector(getCmsProductsOrder);
  const cmsProducts = useSelector(getCmsProducts);

  return React.useMemo(() => productsOrder.map(productId => cmsProducts[productId]).filter(isDefined), [productsOrder, cmsProducts]);
};
