/* eslint-disable functional/immutable-data */
const htmlClassList: Record<string, number> = {};
const docBodyClassList: Record<string, number> = {};

interface ClassProps {
  classListRef: HTMLElement['classList'];
  poll: Record<string, number>;
  classes: string[];
}

const incrementReference = (poll: Record<string, number>, className: string) => {
  if (!poll[className]) {
    poll[className] = 0;
  }
  poll[className] += 1;

  return className;
};

const decrementReference = (poll: Record<string, number>, className: string) => {
  if (poll[className]) {
    poll[className] -= 1;
  }

  return className;
};

const trackClass = ({ classListRef, poll, classes }: ClassProps) => {
  classes.forEach(className => {
    incrementReference(poll, className);
    classListRef.add(className);
  });
};

const untrackClass = ({ classListRef, poll, classes }: ClassProps) => {
  classes.forEach(className => {
    decrementReference(poll, className);
    poll[className] === 0 && classListRef.remove(className);
  });
};

export const add = (element: HTMLElement, classString: string) =>
  trackClass({
    classListRef: element.classList,
    classes: classString.split(' '),
    poll: element.nodeName.toLowerCase() === 'html' ? htmlClassList : docBodyClassList,
  });

export const remove = (element: HTMLElement, classString: string) =>
  untrackClass({
    classListRef: element.classList,
    classes: classString.split(' '),
    poll: element.nodeName.toLowerCase() === 'html' ? htmlClassList : docBodyClassList,
  });
