import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../various/Button';
import Icon, { IconType } from '../../various/Icon';

import styles from './MediaPositioner.module.scss';

interface Props {
  onClose: () => void;
}

export const PreparingMessage = ({ onClose }: Props) => {
  const { t } = useTranslation(['cms', 'common']);
  const handleClose = React.useCallback(() => onClose(), [onClose]);

  return (
    <div className={styles.preparingWrapper} data-testid="imageLoader">
      <div className={styles.preparingInfo}>
        <Icon type={IconType.Spinner} className={styles.spinner} />
        {t('cms:preparing_media')}
      </div>
      <Button onClick={handleClose} variant={['button', 'extraSmall', 'transparent']}>
        {t('common:cancel')}
      </Button>
    </div>
  );
};
