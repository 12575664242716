import React from 'react';

import { useShippingCountries } from '../../../services/hooks/account/useShippingCountries';
import { isDefined } from '../../is';

interface Props {
  country: Maybe<string>;
}

export const useShippingCountryOptions = ({ country }: Props) => {
  const { data: shippingCountries } = useShippingCountries();

  const countryOptions = React.useMemo(() => {
    return Object.values(shippingCountries?.countries ?? {}).map(shippingCountry => ({
      country: shippingCountry.country,
      name: shippingCountry.name,
    }));
  }, [shippingCountries]);

  const stateOptions = React.useMemo(() => {
    if (!isDefined(country)) {
      return [];
    }

    return Object.values(shippingCountries?.countries[country]?.states ?? {});
  }, [country, shippingCountries?.countries]);

  return { countryOptions, stateOptions };
};
