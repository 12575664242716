/* eslint-disable id-length */
/* eslint-disable no-magic-numbers */
import { clamp } from 'ramda';
import { RGBColor } from 'react-color';

import { numberToFixedDecimals } from './numberToFixedDecimals';

const colorComponentToHex = (component: number) => {
  const hex = component.toString(16);

  return hex.padStart(2, '0');
};

const hexValueToInt = (value: string) => parseInt(`0x${value}`, 16);

export const rgbaToHex = ({ r, g, b, a }: RGBColor) => {
  const hexColor = `${colorComponentToHex(r)}${colorComponentToHex(g)}${colorComponentToHex(b)}`;

  const opacity = Math.round(clamp(0, 1, a ?? 1) * 255);
  const hexOpacity = colorComponentToHex(opacity);

  return `#${hexColor}${hexOpacity}`;
};

export const hexToRgba = (hex: string): RGBColor => {
  switch (hex.length) {
    case 4:
      return {
        b: hexValueToInt(`${hex[3]}${hex[3]}`),
        g: hexValueToInt(`${hex[2]}${hex[2]}`),
        r: hexValueToInt(`${hex[1]}${hex[1]}`),
      };
    case 7:
      return {
        b: hexValueToInt(`${hex[5]}${hex[6]}`),
        g: hexValueToInt(`${hex[3]}${hex[4]}`),
        r: hexValueToInt(`${hex[1]}${hex[2]}`),
      };
    case 5:
      return {
        a: numberToFixedDecimals(hexValueToInt(`${hex[4]}${hex[4]}`) / 255),
        b: hexValueToInt(`${hex[3]}${hex[3]}`),
        g: hexValueToInt(`${hex[2]}${hex[2]}`),
        r: hexValueToInt(`${hex[1]}${hex[1]}`),
      };
    case 9:
      return {
        a: numberToFixedDecimals(hexValueToInt(`${hex[7]}${hex[8]}`) / 255),
        b: hexValueToInt(`${hex[5]}${hex[6]}`),
        g: hexValueToInt(`${hex[3]}${hex[4]}`),
        r: hexValueToInt(`${hex[1]}${hex[2]}`),
      };
    default:
      return {
        a: 0,
        b: 0,
        g: 0,
        r: 0,
      };
  }
};
