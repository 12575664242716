import React from 'react';
import { LinkProps } from 'react-router-dom';

import { isDefined } from '../../../utils/is';
import { Link } from '../Link';

import styles from './Alert.module.scss';

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> | LinkProps;

const isLink = (candidate: Props): candidate is LinkProps => isDefined((candidate as LinkProps).to);

export const AlertAction = ({ children, ...props }: Props) => {
  if (isLink(props)) {
    return (
      <Link variant="black" {...props}>
        {children}
      </Link>
    );
  }

  return (
    <button type="button" className={styles.alertAction} {...(props as React.ButtonHTMLAttributes<HTMLButtonElement>)}>
      {children}
    </button>
  );
};
