import { ActionHandler, Modals, Navigation } from '@typings';
import { update } from 'space-lift';
import { createReducer, EmptyAction } from 'typesafe-actions';

import { update as updated } from '../../utils/update';
import {
  copyToNewSelectionFailure,
  copyToNewSelectionRequest,
  copyToNewSelectionSuccess,
  createSelection,
  createSelectionSuccess,
  editSelection,
  editSelectionSuccess,
  setItemsFailure,
  setItemsRequest,
  setItemsSuccess,
  setMultipleItemsRequest,
  setPrepacksRequest,
} from '../order';

import * as actions from './actions';

const MOBILE_WIDTH = 960;

export interface UIReducer {
  isFilterPanelPinned: boolean;
  isMatrixTotalsColumnPinned: boolean;
  isMenuOpened: boolean;
  isOffline: boolean;
  isOrderSettingsFormDisabled: boolean;
  isSaving: boolean;
  shouldShowMoreShippingAddresses: boolean;
  isSoftLogoutMode: boolean;
  shouldShowEtaOnMobile: boolean;
  shouldShowEtaOnDesktop: boolean;
  isProductsGridView: boolean;
  isProductsGroupedByDelwin: boolean;
  userNavigation: Navigation.UserMenuItem[];
  visibleModals: Modals.Type[];
  languageToSet?: string;
}

const initialState: UIReducer = {
  isFilterPanelPinned: window.innerWidth >= MOBILE_WIDTH,
  isMatrixTotalsColumnPinned: true,
  isMenuOpened: false,
  isOffline: false,
  isOrderSettingsFormDisabled: false,
  isProductsGridView: true,
  isProductsGroupedByDelwin: false,
  isSaving: false,
  isSoftLogoutMode: false,
  shouldShowEtaOnDesktop: false,
  shouldShowEtaOnMobile: false,
  shouldShowMoreShippingAddresses: false,
  userNavigation: [],
  visibleModals: [],
};

export { initialState as initialUIState };

const handleHideModal: ActionHandler<UIReducer, typeof actions.hideModal> = (state, action) => {
  return updated(state, {
    visibleModals: state.visibleModals.filter(modal => modal !== action.payload),
  });
};

const handleShowModal: ActionHandler<UIReducer, typeof actions.showModal> = (state, action) => {
  return updated(state, {
    visibleModals: [...state.visibleModals, ...(state.visibleModals.includes(action.payload) ? [] : [action.payload])],
  });
};

const handleToggleConnectivityStatus: ActionHandler<UIReducer, typeof actions.toggleNetworkConnectivity> = (state, action) =>
  updated(state, {
    isOffline: action.payload.isOffline,
  });

const handleToggleMenu: ActionHandler<UIReducer, typeof actions.toggleMenu> = (state, action) => {
  const isMenuOpened = action.payload !== undefined ? action.payload : !state.isMenuOpened;

  return updated(state, {
    isMenuOpened,
  });
};

const handleSetFiltersPanelPinned: ActionHandler<UIReducer, typeof actions.setFilterPanelPinned> = (state, action) =>
  updated(state, {
    isFilterPanelPinned: action.payload,
  });

const handleSetItemAmount: ActionHandler<UIReducer, EmptyAction<string>> = state =>
  update(state, {
    isSaving: true,
  });

const handleSetItemAmountSuccess: ActionHandler<UIReducer, typeof setItemsSuccess> = state =>
  updated(state, {
    isSaving: false,
  });

const handleSetItemAmountFailure: ActionHandler<UIReducer, typeof setItemsFailure> = state =>
  updated(state, {
    isSaving: false,
  });

const handleEditSelection: ActionHandler<UIReducer, typeof editSelection> = state =>
  updated(state, {
    isOrderSettingsFormDisabled: true,
  });

const handleEditSelectionSuccess: ActionHandler<UIReducer, typeof editSelectionSuccess> = state => {
  return updated(state, {
    isOrderSettingsFormDisabled: false,
    visibleModals: state.visibleModals.filter(modal => modal !== 'EditOrderModal'),
  });
};

const handleCopyToNewSelection: ActionHandler<UIReducer, typeof copyToNewSelectionRequest> = state =>
  updated(state, {
    isOrderSettingsFormDisabled: true,
  });

const handleCopyToNewSelectionFailure: ActionHandler<UIReducer, typeof copyToNewSelectionFailure> = state =>
  updated(state, {
    isOrderSettingsFormDisabled: false,
  });

const handleCopyToNewSelectionSuccess: ActionHandler<UIReducer, typeof copyToNewSelectionSuccess> = state => {
  return updated(state, {
    isOrderSettingsFormDisabled: false,
  });
};

const handleCreateSelection: ActionHandler<UIReducer, typeof createSelection> = state =>
  updated(state, {
    isOrderSettingsFormDisabled: true,
  });

const handleCreateSelectionSuccess: ActionHandler<UIReducer, typeof createSelectionSuccess> = state => {
  return updated(state, {
    isOrderSettingsFormDisabled: false,
    visibleModals: state.visibleModals.filter(modal => modal !== 'CreateOrderModal'),
  });
};

const handleSetProductsGridView: ActionHandler<UIReducer, typeof actions.setProductsGridView> = state =>
  updated(state, { isProductsGridView: true });

const handleToggleProductsGroupedByDelwin: ActionHandler<UIReducer, typeof actions.toggleProductsGroupedByDelwin> = state =>
  updated(state, {
    isProductsGroupedByDelwin: !state.isProductsGroupedByDelwin,
  });

const handleSetProductsListView: ActionHandler<UIReducer, typeof actions.setProductsListView> = state =>
  updated(state, { isProductsGridView: false });

const handleFetchUserNavigationSuccess: ActionHandler<UIReducer, typeof actions.fetchUserNavigationSuccess> = (state, action) =>
  update(state, { userNavigation: action.payload });

const handleFetchUserNavigationFailure: ActionHandler<UIReducer, typeof actions.fetchUserNavigationFailure> = state =>
  update(state, { userNavigation: [] });

const handleSetSoftLogout: ActionHandler<UIReducer, typeof actions.setSoftLogoutMode> = state =>
  update(state, {
    isSoftLogoutMode: true,
  });

const handleSetShouldShowMoreShippingAddresses: ActionHandler<UIReducer, typeof actions.setShouldShowMoreShippingAddresses> = (
  state,
  action,
) =>
  update(state, {
    shouldShowMoreShippingAddresses: action.payload,
  });

const handleRequestLanguageChange: ActionHandler<UIReducer, typeof actions.requestLanguageChange> = (state, action) =>
  update(state, {
    languageToSet: action.payload,
  });

const handleToggleEtaVisibilityOnMobile: ActionHandler<UIReducer, typeof actions.toggleEtaVisibilityOnMobile> = state =>
  update(state, {
    shouldShowEtaOnMobile: !state.shouldShowEtaOnMobile,
  });

const handleSetEtaVisibilityOnMobile: ActionHandler<UIReducer, typeof actions.setEtaVisibilityOnMobile> = (state, action) =>
  update(state, {
    shouldShowEtaOnMobile: action.payload,
  });

const handleSetEtaVisibilityOnDesktop: ActionHandler<UIReducer, typeof actions.setEtaVisibilityOnDesktop> = (state, action) =>
  update(state, {
    shouldShowEtaOnDesktop: action.payload,
  });

const handleSetMatrixTotalsColumnPinned: ActionHandler<UIReducer, typeof actions.setMatrixTotalsColumnPinned> = (state, action) =>
  update(state, {
    isMatrixTotalsColumnPinned: action.payload,
  });

export default createReducer<UIReducer>(initialState)
  .handleAction(actions.hideModal, handleHideModal)
  .handleAction(actions.showModal, handleShowModal)
  .handleAction(actions.toggleMenu, handleToggleMenu)
  .handleAction(actions.setFilterPanelPinned, handleSetFiltersPanelPinned)
  .handleAction(actions.toggleNetworkConnectivity, handleToggleConnectivityStatus)
  .handleAction(actions.setProductsGridView, handleSetProductsGridView)
  .handleAction(actions.setProductsListView, handleSetProductsListView)
  .handleAction(actions.toggleProductsGroupedByDelwin, handleToggleProductsGroupedByDelwin)
  .handleAction(setItemsRequest, handleSetItemAmount)
  .handleAction(setMultipleItemsRequest, handleSetItemAmount)
  .handleAction(setPrepacksRequest, handleSetItemAmount)
  .handleAction(setItemsSuccess, handleSetItemAmountSuccess)
  .handleAction(setItemsFailure, handleSetItemAmountFailure)
  .handleAction(setItemsFailure, handleSetItemAmountFailure)
  .handleAction(editSelection, handleEditSelection)
  .handleAction(editSelectionSuccess, handleEditSelectionSuccess)
  .handleAction(copyToNewSelectionRequest, handleCopyToNewSelection)
  .handleAction(copyToNewSelectionSuccess, handleCopyToNewSelectionSuccess)
  .handleAction(copyToNewSelectionFailure, handleCopyToNewSelectionFailure)
  .handleAction(createSelection, handleCreateSelection)
  .handleAction(createSelectionSuccess, handleCreateSelectionSuccess)
  .handleAction(actions.fetchUserNavigationSuccess, handleFetchUserNavigationSuccess)
  .handleAction(actions.fetchUserNavigationFailure, handleFetchUserNavigationFailure)
  .handleAction(actions.setSoftLogoutMode, handleSetSoftLogout)
  .handleAction(actions.setShouldShowMoreShippingAddresses, handleSetShouldShowMoreShippingAddresses)
  .handleAction(actions.requestLanguageChange, handleRequestLanguageChange)
  .handleAction(actions.toggleEtaVisibilityOnMobile, handleToggleEtaVisibilityOnMobile)
  .handleAction(actions.setEtaVisibilityOnMobile, handleSetEtaVisibilityOnMobile)
  .handleAction(actions.setEtaVisibilityOnDesktop, handleSetEtaVisibilityOnDesktop)
  .handleAction(actions.setMatrixTotalsColumnPinned, handleSetMatrixTotalsColumnPinned);
