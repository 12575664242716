import { Cms, CmsBlocks } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getCmsProducts, getIsLoadingCmsProducts } from '../../../../ducks';
import { getCmsBlockLocalizationById } from '../../../../ducks/cms/localizations';
import { getHotspotPosition, getIsOutOfBoundaries } from '../../../../logic/cms/hotspot';
import { areProductsInMarketsMatch } from '../../../../logic/cms/markets';
import { getIsBlockFullyLocalized, getScreenRelativeStyles } from '../../../../logic/pages';
import { useSpecifiedDeviceWidth } from '../../../../utils/hooks';
import { useBlockWidth } from '../../../../utils/hooks/cms/useBlockWidth';
import { useGetSectionByGroupId } from '../../../../utils/hooks/cms/useGetSectionByGroupId';
import { useResizeBlockHeight } from '../../../../utils/hooks/cms/useResizeBlockHeight';
import { isDefined, isNull } from '../../../../utils/is';
import { isEmpty } from '../../../../utils/isEmpty';
import { EditorLanguageContext } from '../../../cms/context/EditorLanguageContext';
import { SizeContext } from '../../../cms/context/SizeContext';
import { PopoverWarning } from '../../../cms/PopoverWarning';

const BORDER_OFFSET = 2;

const useAreHotspotsWithInactiveProducts = (groupId: string | undefined, hotspots?: Cms.Hotspots) => {
  const cmsProducts = useSelector(getCmsProducts);
  const isLoadingCmsProducts = useSelector(getIsLoadingCmsProducts);
  const isLoadingInitialProducts = isEmpty(cmsProducts) && isLoadingCmsProducts;
  const section = useGetSectionByGroupId(groupId);

  if (!isDefined(hotspots) || isLoadingInitialProducts) {
    return false;
  }

  return hotspots.points.some(point => {
    const { productId } = point.general;

    if (isNull(productId)) {
      return false;
    }

    const missingProduct = !areProductsInMarketsMatch({
      productId,
      products: cmsProducts,
      section,
    });

    return !isDefined(cmsProducts[productId]) || missingProduct;
  });
};

const useAreHotspotsOutOfBoundaries = (parameters: Cms.ContentBlock) => {
  const { hotspots, background } = parameters.settings;
  const { rowHeight } = React.useContext(SizeContext);
  const screenWidth = useSpecifiedDeviceWidth();
  const height = useResizeBlockHeight({ blockSettings: parameters, rowHeight, shouldApplyResize: false });
  const screenRelativePosition = getScreenRelativeStyles(screenWidth, parameters.position);
  const blockWidth = useBlockWidth(screenRelativePosition.spanX);

  if (!isDefined(hotspots) || !isDefined(background)) {
    return false;
  }

  const width = blockWidth + BORDER_OFFSET;
  const blockSize = { height, width };

  return hotspots.points.some(hotspotPoint => {
    const { left, top } = getHotspotPosition({
      background,
      blockSize,
      hotspotPoint,
      naturalImage: hotspots.general.naturalImage,
      screenWidth,
    });

    return getIsOutOfBoundaries({ blockSize, left, top });
  });
};

const useHasMissingLanguageOverwrite = (parameters: Cms.ContentBlock) => {
  const { isDefaultLanguage, editorLanguage } = React.useContext(EditorLanguageContext);
  const blockLocalization = useSelector(getCmsBlockLocalizationById(editorLanguage, parameters.id));
  const isBlockFullyLocalized = React.useMemo(
    () => getIsBlockFullyLocalized(parameters, blockLocalization),
    [parameters, blockLocalization],
  );

  return !isDefaultLanguage && !isBlockFullyLocalized;
};

export const BlockWarnings = ({ parameters, isManualLayoutEditing }: CmsBlocks.BlockProps<Cms.ContentBlock>) => {
  const { t } = useTranslation(['cms']);
  const { settings, groupId } = parameters;
  const areHotspotsWithInactiveProducts = useAreHotspotsWithInactiveProducts(groupId, settings.hotspots);
  const areHotspotsOutOfBoundaries = useAreHotspotsOutOfBoundaries(parameters);
  const hasMissingLanguageOverwrite = useHasMissingLanguageOverwrite(parameters);

  if (isManualLayoutEditing) {
    return null;
  }

  const warnings = [
    ...(hasMissingLanguageOverwrite ? [{ message: t('cms:missing_block_localization'), type: 'translationWarning' as const }] : []),
    ...(areHotspotsWithInactiveProducts ? [{ message: t('cms:inactive_product_hotspot') }] : []),
    ...(areHotspotsOutOfBoundaries ? [{ message: t('cms:hotspot_outside_warning') }] : []),
  ];

  return <PopoverWarning warnings={warnings} />;
};
