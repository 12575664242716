import { ActionHandler, Id, PriceList } from '@typings';
import { createReducer } from 'typesafe-actions';

import { update } from '../../utils/update';

import * as actions from './actions';

export interface PricelistsReducer {
  data: PriceList[];
  isLoading: boolean;
  currentPriceList: Nullable<Id>;
}

const initialState: PricelistsReducer = {
  currentPriceList: null,
  data: [],
  isLoading: false,
};

const handleFetchPricelistsSuccess: ActionHandler<PricelistsReducer, typeof actions.fetchPricelistsSuccess> = (state, action) =>
  update(state, { data: action.payload, isLoading: false });

const handleFetchPricelistsRequest: ActionHandler<PricelistsReducer, typeof actions.fetchPricelistsRequest> = state =>
  update(state, { isLoading: true });

const handleFetchPricelistsFailure: ActionHandler<PricelistsReducer, typeof actions.fetchPricelistsFailure> = state =>
  update(state, { isLoading: false });

const handleSetPriceList: ActionHandler<PricelistsReducer, typeof actions.setCurrentPricelist> = (state, action) =>
  update(state, {
    currentPriceList: action.payload,
  });

export default createReducer<PricelistsReducer, AppAction>(initialState)
  .handleAction(actions.fetchPricelistsSuccess, handleFetchPricelistsSuccess)
  .handleAction(actions.fetchPricelistsRequest, handleFetchPricelistsRequest)
  .handleAction(actions.setCurrentPricelist, handleSetPriceList)
  .handleAction(actions.fetchPricelistsFailure, handleFetchPricelistsFailure);
