import React from 'react';
import ResizeObserver from 'resize-observer-polyfill';

import { isDefined } from '../is';

export const useResizeObserver = (
  ref: Maybe<React.MutableRefObject<Maybe<HTMLElement>>>,
  callback: ResizeObserverCallback,
  deps: unknown[] = [],
) => {
  React.useLayoutEffect(() => {
    if (!isDefined(ref) || !isDefined(ref.current)) {
      return;
    }

    const resizeObserver = new ResizeObserver(callback);

    resizeObserver.observe(ref.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [ref?.current, ...deps]);
};
