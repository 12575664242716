import { PopoverMenuItems } from '@typings';
import React from 'react';

import styles from '../PopoverMenu.module.scss';

import { Label } from './Label';
import { MenuButton } from './MenuButton';

interface Props {
  item: PopoverMenuItems.ClickableItem;
  onSelect: (key: string | number) => void;
  isSelected: boolean;
}

export const SelectableItem = ({ item, isSelected, onSelect }: Props) => {
  const { label, onClick, icon, isDisabled, testId, disabledTooltip, key, description } = item;

  const handleClick = React.useCallback(() => {
    onClick?.();
    onSelect(key);
  }, [onSelect, onClick, key]);

  return (
    <li className={styles.menuItem}>
      <MenuButton
        onClick={handleClick}
        icon={icon}
        isDisabled={isDisabled}
        testId={testId}
        disabledTooltip={disabledTooltip}
        isSelected={isSelected}
      >
        <Label description={description}>{label}</Label>
      </MenuButton>
    </li>
  );
};
