import { Invoice } from '@typings';

import { isDefined } from '../utils/is';
import { isEmpty } from '../utils/isEmpty';

export const getIsInvoiceFull = (invoice: Invoice.AnyInvoice): invoice is Invoice.Full =>
  isDefined((invoice as Invoice.Full).paymentMethods) && isDefined((invoice as Invoice.Full).invoiceItems);

export const getIsInvoiceStandard = (invoice: Invoice.AnyInvoice): invoice is Invoice.Standard =>
  isDefined((invoice as Invoice.Standard).address) && isDefined((invoice as Invoice.Standard).isOverdue);

export const composeFullProductName = (item: Invoice.InvoiceItem) =>
  [item.name, item.variantName, item.size].filter(value => isDefined(value) && !isEmpty(value)).join(', ');

export const getCalculatedInvoiceStatus = (invoice: Invoice.AnyInvoice) => {
  if (getIsInvoiceFull(invoice) || getIsInvoiceStandard(invoice)) {
    return invoice.isOverdue ? 'Overdue' : invoice.status;
  }

  return invoice.status;
};
