import cx from 'classnames';
import React from 'react';

import styles from './ExpandableContainer.module.scss';

interface Props {
  id: string;
  isOpen: boolean;
}

export const ExpandableElement = ({ id, children, isOpen }: React.WithChildren<Props>) => {
  return (
    <div
      id={id}
      className={cx(styles.expandableElement, {
        [styles.expanded]: isOpen,
      })}
    >
      <div {...(isOpen ? undefined : { inert: '' })}>{children}</div>
    </div>
  );
};
