import { AnyMedia, Picture, Product } from '@typings';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getActiveDeliveryWindowsIdsForOrderBuyer, getIsStockTypeSeparationEnabled } from '../../../../ducks';
import { getHasAvailableVariants, getNativeVariants } from '../../../../ducks/helpers';
import { GetProductUrl, getRelatedProducts, getUniqForProductVariants } from '../../../../logic/products';
import { useDelwinIdsWithCurrentStockType, useProductMedia } from '../../../../utils/hooks';
import { useSearchParamByName } from '../../../../utils/hooks/useSearchParamByName';
import { isDefined } from '../../../../utils/is';

interface Props {
  productDetails: Product.Full & Product.StandardRelation;
  getProductUrl: GetProductUrl;
}

interface Context {
  activeVariant: Product.Standard | undefined;
  activeProductId: Product.Full['product'];
  medias: AnyMedia[];
  images: Picture[];
  fullImages: Picture[];
  hasAvailableVariants: boolean;
  handleProductLightboxMediaChange: (mediaIndex: number) => void;
  activeMediaIndex: number;
  nativeVariants: Product.Standard[];
  productDetails: Product.Full & Product.StandardRelation;
  variants: Product.Standard[];
  getProductUrl: GetProductUrl;
  setActiveMedia: (media: AnyMedia) => void;
  uniqByProductVariants: Product.Standard[];
  standardRelationByStock: Product.Full[] | undefined;
}

const ProductDetailsVariantContext = React.createContext<Context | null>(null);

export const useProductDetailsVariantContext = (): Context => {
  const context = React.useContext(ProductDetailsVariantContext);
  if (context === null) {
    throw new Error('ProductDetailsVariantContext can not be used outside the scope of ProductDetailsVariantContextProvider');
  }

  return context;
};

const getIsProductInAnyDelwin = (product: Product.Full | Product.Standard, delwinIds: string[]) => {
  return product.deliveryWindows.some(delwin => {
    // wrong type as string in product.deliveryWindows. Should be a number
    return delwinIds.includes(delwin.toString());
  });
};

export const ProductDetailsVariantContextProvider = ({ children, productDetails, getProductUrl }: React.WithChildren<Props>) => {
  const navigate = useNavigate();
  const variantIdSearchParam = useSearchParamByName('showVariant');
  const { fullImages, images, medias } = useProductMedia({ productDetails });
  const isStockTypeSeparationEnabled = useSelector(getIsStockTypeSeparationEnabled);

  const activeDeliveryWindowsIds = useSelector(getActiveDeliveryWindowsIdsForOrderBuyer);
  const delwinsWithCurrentStockType = useDelwinIdsWithCurrentStockType(activeDeliveryWindowsIds);
  const productVariantsWithCurrentStockType = React.useMemo(() => {
    const variants = getRelatedProducts(productDetails);

    if (!isStockTypeSeparationEnabled) {
      return variants;
    }

    return variants.filter(variant => getIsProductInAnyDelwin(variant, delwinsWithCurrentStockType));
  }, [delwinsWithCurrentStockType, isStockTypeSeparationEnabled, productDetails]);

  const uniqByProductVariants = React.useMemo(
    () => getUniqForProductVariants(productDetails, productVariantsWithCurrentStockType),
    [productDetails, productVariantsWithCurrentStockType],
  );

  const standardRelationByStock = React.useMemo(() => {
    const { standardRelation } = productDetails;
    if (!isStockTypeSeparationEnabled) {
      return standardRelation;
    }

    return standardRelation?.filter(product => getIsProductInAnyDelwin(product, delwinsWithCurrentStockType));
  }, [delwinsWithCurrentStockType, isStockTypeSeparationEnabled, productDetails.standardRelation]);

  const variants = React.useMemo(
    () => [productDetails, ...productVariantsWithCurrentStockType],
    [productDetails, productVariantsWithCurrentStockType],
  );
  const activeProductId = productDetails.product;

  const getInitialActiveVariant = () => {
    return variants.find(variant => variant.variant === variantIdSearchParam) ?? variants[0];
  };

  const getInitialActiveMediaIndex = () => {
    const initialValidActiveMediaIndex = medias.findIndex(media => media.variantId === getInitialActiveVariant()?.variant);

    return initialValidActiveMediaIndex === -1 ? 0 : initialValidActiveMediaIndex;
  };

  const [activeVariant, setActiveVariant] = React.useState<Product.Standard | undefined>(getInitialActiveVariant);
  const [activeMediaIndex, setActiveMediaIndex] = React.useState(getInitialActiveMediaIndex);

  const nativeVariants = getNativeVariants(productDetails, productDetails.product);
  const hasAvailableVariants = getHasAvailableVariants(nativeVariants);

  React.useEffect(() => {
    setActiveVariant(getInitialActiveVariant());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variantIdSearchParam]);

  const setActiveMedia = (media: AnyMedia) => {
    const mediaIndex = medias.findIndex(({ src, variantId }) => src === media.src && variantId === media.variantId);

    if (mediaIndex !== -1) {
      setActiveMediaIndex(mediaIndex);
    }
  };

  const handleProductLightboxMediaChange = React.useCallback(
    (mediaIndex: number) => {
      const medium = medias[mediaIndex];

      if (!isDefined(medium)) {
        return;
      }

      setActiveMediaIndex(mediaIndex);
      const { productId, variantId } = medium;

      if (activeProductId !== productId) {
        return;
      }
      const newVariant = variants.find(variant => variant.variant === variantId);

      if (!isDefined(newVariant)) {
        return;
      }

      setActiveVariant(newVariant);
      navigate(
        getProductUrl({
          productId: activeProductId,
          variantId: newVariant.variant,
        }),
        { replace: true },
      );
    },
    [activeProductId, getProductUrl, medias, navigate, variants],
  );

  return (
    <ProductDetailsVariantContext.Provider
      value={{
        activeMediaIndex,
        activeProductId,
        activeVariant,
        fullImages,
        getProductUrl,
        handleProductLightboxMediaChange,
        hasAvailableVariants,
        images,
        medias,
        nativeVariants,
        productDetails,
        setActiveMedia,
        standardRelationByStock,
        uniqByProductVariants,
        variants,
      }}
    >
      {children}
    </ProductDetailsVariantContext.Provider>
  );
};
