import cx from 'classnames';

import Icon, { IconColor, IconType } from '../../../various/Icon';

import styles from './ManualLayoutSelector.module.scss';

interface Props {
  onClick: () => void;
  isSelected: boolean;
  areAllRequiredElementsSelected: boolean;
  classNames?: string;
  testId?: string;
}

export const ManualLayoutSelector = ({ onClick, isSelected, classNames, areAllRequiredElementsSelected, testId }: Props) => {
  const buttonClassNames = cx(classNames, {
    [styles.selected]: isSelected,
    [styles.areAllRequiredElementsSelected]: areAllRequiredElementsSelected,
  });

  const isButtonDisabled = areAllRequiredElementsSelected && !isSelected;

  return (
    <button onClick={onClick} className={buttonClassNames} disabled={isButtonDisabled} data-testid={testId}>
      <span className={styles.selector}>
        {isSelected && <Icon className={styles.icon} type={IconType.Accept} color={IconColor.White} />}
      </span>
    </button>
  );
};
