import { Preset, UnitsDistribution } from '../../../../typings';
import { isDefined } from '../../utils/is';
import { isEmpty } from '../../utils/isEmpty';
import { sumPresetsResults } from '../presets';

import { calculateDistributionBase } from './unitsDistribution';

interface RedistributionParameters extends UnitsDistribution.SingleDistributionParameters {
  distributionResult?: Preset.Item[];
}

const applyStockRedistribution = ({
  availableQuantities,
  distributionQuantities,
  requestedValue,
  distributionResult,
}: RedistributionParameters): RedistributionParameters => {
  const initialDistribution = calculateDistributionBase({
    availableQuantities,
    distributionQuantities,
    requestedValue,
  });

  const { availableQuantitiesLeft, distributedQuantities, unitsToRedistribute } = initialDistribution.distributionQuantities.reduce(
    (acc, item) => {
      const stock = availableQuantities[item.sizeId];

      if (!isDefined(stock)) {
        return acc;
      }

      const quantity = Math.min(item.quantity, stock);
      const quantityDiff = item.quantity - quantity;

      return {
        availableQuantitiesLeft: {
          ...acc.availableQuantitiesLeft,
          [item.sizeId]: stock - quantity,
        },
        distributedQuantities: [...acc.distributedQuantities, { ...item, quantity }],
        unitsToRedistribute: acc.unitsToRedistribute + quantityDiff,
      };
    },
    {
      availableQuantitiesLeft: availableQuantities,
      distributedQuantities: [],
      unitsToRedistribute: 0,
    },
  );

  const canRedistribute = Object.values(availableQuantitiesLeft).some(quantity => quantity > 0);
  const currentDistributionResult =
    isDefined(distributionResult) ? sumPresetsResults([distributionResult, distributedQuantities]) : distributedQuantities;

  if (!canRedistribute || unitsToRedistribute === 0) {
    return {
      availableQuantities: availableQuantitiesLeft,
      distributionQuantities: distributedQuantities,
      distributionResult: currentDistributionResult,
      requestedValue: unitsToRedistribute,
    };
  }

  const { percentageToRedistribute, sizesWithUnits } = distributionQuantities.reduce(
    (acc, item) => {
      const hasUnits = (availableQuantitiesLeft[item.sizeId] ?? 0) > 0;

      if (hasUnits && item.quantity > 0) {
        return {
          ...acc,
          sizesWithUnits: acc.sizesWithUnits + 1,
        };
      }

      return {
        ...acc,
        percentageToRedistribute: acc.percentageToRedistribute + item.quantity,
      };
    },
    { percentageToRedistribute: 0, sizesWithUnits: 0 },
  );

  const newDistributionCurve = distributionQuantities.map(item => {
    const hasUnits = (availableQuantitiesLeft[item.sizeId] ?? 0) > 0;

    if (!hasUnits || item.quantity === 0) {
      return {
        ...item,
        quantity: 0,
      };
    }

    return {
      ...item,
      quantity: item.quantity + percentageToRedistribute / sizesWithUnits,
    };
  });

  return applyStockRedistribution({
    availableQuantities: availableQuantitiesLeft,
    distributionQuantities: newDistributionCurve,
    distributionResult: currentDistributionResult,
    requestedValue: unitsToRedistribute,
  });
};

export const distributeToOtherAvailable = ({ availableQuantities, distributions }: UnitsDistribution.DistributionSetParameters) => {
  const distributionResults = distributions.reduce((acc, { distributionQuantities, requestedValue }, index) => {
    const previousResult = acc[index - 1] ?? { availableQuantities: {} };

    return [
      ...acc,
      applyStockRedistribution({
        availableQuantities: !isEmpty(previousResult.availableQuantities) ? previousResult.availableQuantities : availableQuantities,
        distributionQuantities,
        requestedValue,
      }),
    ];
  }, []);

  return sumPresetsResults(distributionResults.map(({ distributionResult }) => distributionResult).filter(isDefined));
};
