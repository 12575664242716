/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 14;
const ORIGINAL_HEIGHT = 12;
const PROPORTION = ORIGINAL_HEIGHT / ORIGINAL_WIDTH;
const ORIGINAL_COLOR = '#5D5D5D';

export const ListView = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => {
  return (
    <svg width={width} height={width * PROPORTION} viewBox="0 0 14 12" fill={color}>
      <path d="M1.55405 4.9C0.970811 4.9 0.5 5.39133 0.5 6C0.5 6.60867 0.970811 7.1 1.55405 7.1C2.1373 7.1 2.60811 6.60867 2.60811 6C2.60811 5.39133 2.1373 4.9 1.55405 4.9ZM1.55405 0.5C0.970811 0.5 0.5 0.991333 0.5 1.6C0.5 2.20867 0.970811 2.7 1.55405 2.7C2.1373 2.7 2.60811 2.20867 2.60811 1.6C2.60811 0.991333 2.1373 0.5 1.55405 0.5ZM1.55405 9.3C0.970811 9.3 0.5 9.79867 0.5 10.4C0.5 11.0013 0.977838 11.5 1.55405 11.5C2.13027 11.5 2.60811 11.0013 2.60811 10.4C2.60811 9.79867 2.1373 9.3 1.55405 9.3ZM4.36486 11.1333H12.7973C13.1838 11.1333 13.5 10.8033 13.5 10.4C13.5 9.99667 13.1838 9.66667 12.7973 9.66667H4.36486C3.97838 9.66667 3.66216 9.99667 3.66216 10.4C3.66216 10.8033 3.97838 11.1333 4.36486 11.1333ZM4.36486 6.73333H12.7973C13.1838 6.73333 13.5 6.40333 13.5 6C13.5 5.59667 13.1838 5.26667 12.7973 5.26667H4.36486C3.97838 5.26667 3.66216 5.59667 3.66216 6C3.66216 6.40333 3.97838 6.73333 4.36486 6.73333ZM3.66216 1.6C3.66216 2.00333 3.97838 2.33333 4.36486 2.33333H12.7973C13.1838 2.33333 13.5 2.00333 13.5 1.6C13.5 1.19667 13.1838 0.866667 12.7973 0.866667H4.36486C3.97838 0.866667 3.66216 1.19667 3.66216 1.6Z" />
    </svg>
  );
};
