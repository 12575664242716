import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { replaceCustomStyles, resetDeletedTextStyles, translateDeletedTextStylesRequest } from '../../../ducks/cms';
import { useCustomStyles } from '../../../utils/hooks/cms/useCustomStyles';
import { isEmpty } from '../../../utils/isEmpty';
import { ModalShell } from '../../various/ModalShell';

import { MissingCustomStylesForm } from './MissingCustomStylesForm';
import styles from './MissingCustomStylesModal.module.scss';

export const MissingCustomStylesModal = () => {
  const { t } = useTranslation(['cms', 'validation']);
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = React.useState(false);
  const { allStyles, isOnlyTextStylesMissing, isOnlyButtonStylesMissing, missingStyles } = useCustomStyles();

  React.useEffect(() => {
    setIsOpen(!isEmpty(missingStyles));

    if (!isEmpty(missingStyles)) {
      dispatch(translateDeletedTextStylesRequest(missingStyles.map(style => style.name)));
    }
  }, [dispatch, missingStyles]);

  const defaultValues = React.useMemo(() => {
    return missingStyles.reduce<Record<string, Nullable<string>>>(
      (acc, current) => ({
        ...acc,
        [current.name]: null,
      }),
      {},
    );
  }, [missingStyles]);

  const closeModal = React.useCallback(() => {
    setIsOpen(false);
    dispatch(resetDeletedTextStyles());
  }, [dispatch]);

  const handleSubmit = React.useCallback(
    (replaceMap: Record<string, string>) => {
      const payload = Object.entries(replaceMap).reduce((acc, [removedCustomStyle, customStyle]) => {
        return { ...acc, [removedCustomStyle]: allStyles[customStyle] };
      }, {});

      dispatch(replaceCustomStyles(payload));
      closeModal();
    },
    [allStyles, closeModal, dispatch],
  );

  const modalTitle = React.useMemo(() => {
    if (isOnlyTextStylesMissing) {
      return t('cms:missing_text_styles');
    }

    return isOnlyButtonStylesMissing ? t('cms:missing_button_styles') : t('cms:missing_styles');
  }, [isOnlyTextStylesMissing, isOnlyButtonStylesMissing, t]);

  const removedStyleMessage = React.useMemo(() => {
    if (isOnlyTextStylesMissing) {
      return t('cms:text_styles_removed');
    }

    return isOnlyButtonStylesMissing ? t('cms:button_styles_removed') : t('cms:styles_removed');
  }, [isOnlyTextStylesMissing, isOnlyButtonStylesMissing, t]);

  if (isEmpty(missingStyles)) {
    return null;
  }

  return (
    <ModalShell
      onClose={() => null}
      isOpen={isOpen}
      title={modalTitle}
      hideCloseIcon
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
    >
      <p className={styles.message}>{removedStyleMessage}</p>
      <MissingCustomStylesForm defaultValues={defaultValues} onCancel={closeModal} onSubmit={handleSubmit} />
    </ModalShell>
  );
};
