import { saveAs } from 'file-saver';
import { combineEpics } from 'redux-observable';
import { from } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import {
  downloadOrderConfirmation,
  downloadOrderConfirmationFailed,
  downloadOrderConfirmationSuccess,
  downloadOrderCsv,
  downloadOrderCsvFailure,
  downloadOrderCsvSuccess,
  downloadOrderExcel,
  downloadOrderExcelFailure,
  downloadOrderExcelSuccess,
  downloadProductImages,
  downloadProductImagesFailure,
  downloadProductImagesSuccess,
  downloadProductInfoExcel,
  downloadProductInfoExcelFailure,
  downloadProductInfoExcelSuccess,
} from '../ducks/documents';
import { productImagesLongPolling } from '../logic/productImages';
import { downloadURI } from '../services/download';
import { isDefined } from '../utils/is';
import { mapResponse } from '../utils/operators/mapResponse';

const downloadOrderConfirmationEpic: AppEpic = (action$, _, { documentsRepository }) => {
  return action$.pipe(
    filter(isActionOf(downloadOrderConfirmation)),
    mergeMap(async action => documentsRepository.downloadOrderConfirmation(action.payload)),
    mapResponse(res => {
      saveAs(res.data, 'order_confirmation');

      return downloadOrderConfirmationSuccess(res.data);
    }, downloadOrderConfirmationFailed),
  );
};

const downloadOrderExcelEpic: AppEpic = (action$, _, { documentsRepository }) => {
  return action$.pipe(
    filter(isActionOf(downloadOrderExcel)),
    mergeMap(action =>
      from(documentsRepository.downloadOrderAsExcell(action.payload.orderId)).pipe(
        mapResponse(res => {
          saveAs(res.data, `Selection_${action.payload.orderNumber}.xls`);

          return downloadOrderExcelSuccess(res.data);
        }, downloadOrderExcelFailure),
      ),
    ),
  );
};

const downloadProductInfoExcelEpic: AppEpic = (action$, _, { documentsRepository }) => {
  return action$.pipe(
    filter(isActionOf(downloadProductInfoExcel)),
    mergeMap(action =>
      from(documentsRepository.downloadProductInfoAsExcell(action.payload.orderId)).pipe(
        mapResponse(res => {
          saveAs(res.data, `Product_Information_${action.payload.orderNumber}.xls`);

          return downloadProductInfoExcelSuccess(res.data);
        }, downloadProductInfoExcelFailure),
      ),
    ),
  );
};

const downloadOrderCsvEpic: AppEpic = (action$, _, { documentsRepository }) => {
  return action$.pipe(
    filter(isActionOf(downloadOrderCsv)),
    mergeMap(action =>
      from(documentsRepository.downloadOrderAsCsv(action.payload.orderId)).pipe(
        mapResponse(res => {
          saveAs(res.data, `Selection_${action.payload.orderNumber}`);

          return downloadOrderCsvSuccess(res.data);
        }, downloadOrderCsvFailure),
      ),
    ),
  );
};

const downloadProductImagesEpic: AppEpic = (action$, _, { documentsRepository }) => {
  return action$.pipe(
    filter(isActionOf(downloadProductImages)),
    map(action => action.payload),
    mergeMap(async payload => documentsRepository.downloadProductImages(payload)),
    mergeMap(res => productImagesLongPolling(res.data.link as string)),
    map(link => {
      if (isDefined(link)) {
        downloadURI(link, 'productImages');

        return downloadProductImagesSuccess();
      }

      return downloadProductImagesFailure();
    }),
  );
};

export const documentsEpic = combineEpics(
  downloadOrderConfirmationEpic,
  downloadOrderCsvEpic,
  downloadOrderExcelEpic,
  downloadProductImagesEpic,
  downloadProductInfoExcelEpic,
);
