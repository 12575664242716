/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 24;

export const Swap = ({ width = ORIGINAL_WIDTH, color = 'currentColor' }: IconComponentProps) => {
  return (
    <svg width={width} height={width} viewBox="0 0 20 20" fill="none">
      <path
        d="M16.784 4.077v7.583a1.167 1.167 0 01-1.166 1.167H7.691l.755.754a.584.584 0 01-.826.825l-1.75-1.75a.584.584 0 010-.825l1.75-1.75a.584.584 0 01.826.825l-.755.754h7.925V4.077H7.451v.583a.583.583 0 01-1.167 0v-.583A1.167 1.167 0 017.451 2.91h8.167a1.167 1.167 0 011.166 1.167zm-4.083 10.5a.583.583 0 00-.584.583v.583H3.951V8.16h7.925l-.755.754a.584.584 0 10.826.825l1.75-1.75a.583.583 0 000-.825l-1.75-1.75a.584.584 0 00-.825.825l.754.754H3.951A1.167 1.167 0 002.784 8.16v7.583a1.167 1.167 0 001.167 1.167h8.167a1.167 1.167 0 001.166-1.167v-.583a.583.583 0 00-.583-.583z"
        fill={color}
      />
    </svg>
  );
};
