import { Cms } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { INPUT_URL_MAX_LENGTH } from '../../../constants/limits';
import { getFullPlayerUrl, getSourceByUrl } from '../../../logic/cms/player';
import { useValidatedForm } from '../../../utils/hooks/useValidatedForm';
import { isDefined } from '../../../utils/is';
import { getUrlSchema } from '../../../utils/schemas';
import { getSecondsFromHHMMSS, toHHMMSS } from '../../../utils/time';
import { ContentSwitcher } from '../../various/ContentSwitcher';
import { Form, FormButtons, FormGrid, InputField, SwitchField } from '../../various/Form';
import { IconType } from '../../various/Icon';
import { IframePreview } from '../../various/IframePreview';
import { Button } from '../../various/NewButton';

import styles from './EmbedForm.module.scss';

const SOURCE_TABS = [
  { icon: IconType.EmbedUrl, value: 'URL' },
  { icon: IconType.Vimeo, value: 'Vimeo' },
  { icon: IconType.YouTube, value: 'YouTube' },
];

const DEFAULT_VALUES = {
  autoplay: false,
  loop: false,
  mute: false,
  start: toHHMMSS(0),
  url: '',
};

interface EmbedFormProps {
  onSubmit: (data: Cms.EmbedPayload) => void;
  onCancel: () => void;
  embedSettings?: Cms.EmbedSettings;
  setSource: (source: Cms.EmbedSource) => void;
  source: Cms.EmbedSource;
}

export const EmbedForm = (props: EmbedFormProps) => {
  const { source, onCancel, embedSettings, setSource, onSubmit } = props;
  const { t } = useTranslation(['cms', 'common', 'validation']);

  const isEditMode = isDefined(embedSettings);
  const isPlayer = source !== 'URL';

  const defaultValues = React.useMemo(() => {
    const { autoplay, loop, mute, start } = embedSettings?.playerData ?? {};

    return {
      autoplay: autoplay ?? false,
      loop: loop ?? false,
      mute: mute ?? false,
      start: toHHMMSS(start ?? 0),
      url: embedSettings?.url ?? '',
    };
  }, [embedSettings]);

  const validationSchema: yup.ObjectSchema<Cms.EmbedPayload> = yup.object({
    autoplay: yup.bool().required(),
    loop: yup.bool().required(),
    mute: yup.bool().required(),
    start: yup.string().defined(),
    url: getUrlSchema({ allowedProtocols: ['http:', 'https:', 'ftp:', 'ftps:'], isSameHostNameCheck: true }),
  });

  const formMethods = useValidatedForm({ defaultValues, validationSchema });
  const { register, watch, reset, setValue } = formMethods;
  const url = watch('url');

  const handleSourceChange = (newSource: Cms.EmbedSource) => {
    setSource(newSource);

    if (isEditMode && getSourceByUrl(embedSettings.url) === newSource) {
      return reset(defaultValues);
    }

    reset(DEFAULT_VALUES);
  };

  const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSource = getSourceByUrl(event.target.value);

    if (isDefined(newSource)) {
      setSource(newSource);
    }
  };

  const handleStartAtBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    const seconds = Math.max(0, getSecondsFromHHMMSS(event.target.value));
    setValue('start', toHHMMSS(seconds));
  };

  const previewUrl = React.useMemo(() => {
    const isValid = validationSchema.isValidSync({ url });

    if (!isValid) {
      return;
    }

    if (isPlayer) {
      return getFullPlayerUrl(url, source);
    }

    return url;
  }, [isPlayer, source, url, validationSchema]);

  return (
    <Form formMethods={formMethods} onSubmit={onSubmit}>
      <ContentSwitcher
        groupName="embedSource"
        keepEvenWidth={false}
        selectedValue={source}
        size="large"
        tabs={SOURCE_TABS}
        variant="secondary"
        className={styles.tabs}
        onChange={handleSourceChange}
      />
      <FormGrid>
        <InputField
          label={t('common:url')}
          helpMessage={t('cms:embed_help')}
          placeholder={t('cms:enter_embed_url')}
          isRequired
          maxLength={INPUT_URL_MAX_LENGTH}
          {...register('url', { onChange: handleUrlChange })}
        />
        <IframePreview src={previewUrl}>{t('cms:embed_preview')}</IframePreview>
        {isPlayer && (
          <>
            <InputField label={t('cms:start_at')} {...register('start', { onBlur: handleStartAtBlur })} />
            <div>
              <SwitchField
                label={t('cms:autoplay')}
                helpMessage={t('cms:autoplay_help')}
                orientation="horizontal"
                {...register('autoplay')}
              />
              <SwitchField label={t('cms:loop')} orientation="horizontal" {...register('loop')} />
              <SwitchField label={t('cms:mute')} orientation="horizontal" {...register('mute')} />
            </div>
          </>
        )}
      </FormGrid>
      <FormButtons showDivider>
        <Button size="large" variant="ghost" color="dark" onClick={onCancel}>
          {t('common:cancel')}
        </Button>
        <Button type="submit" size="large">
          {isEditMode ? t('common:save_changes') : t('common:add')}
        </Button>
      </FormButtons>
    </Form>
  );
};
