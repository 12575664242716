import cx from 'classnames';
import React from 'react';

import universalStyles from '../../../../../css/utilities/universal.module.scss';
import Icon, { IconColor, IconType } from '../../Icon';

import styles from './Pills.module.scss';

interface Props {
  value?: string;
  isChecked?: boolean;
  disabled?: boolean;
  onBlur?: () => void;
  onChange?: (isChecked: boolean, value?: string) => void;
}

export const Pill = ({ value, isChecked = false, disabled = false, children, onBlur, onChange }: React.WithChildren<Props>) => {
  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(event.target.checked, value);
    },
    [onChange, value],
  );

  const icon =
    isChecked ?
      <Icon type={IconType.Success} color={IconColor.Blue} size={10} />
    : <Icon type={IconType.Plus} size={12} className={styles.plusIcon} />;

  return (
    <>
      <input
        type="checkbox"
        id={value}
        value={value}
        disabled={disabled}
        className={cx(universalStyles.srOnly, styles.input)}
        checked={isChecked}
        onBlur={onBlur}
        onChange={handleChange}
      />
      <label htmlFor={value} className={styles.pill}>
        {icon}
        {children}
        {isChecked && !disabled && <Icon type={IconType.Cross} color={IconColor.Dark} size={8} />}
      </label>
    </>
  );
};
