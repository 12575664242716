/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;
const ORIGINAL_COLOR = '#848484';

export const Account = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => {
  return (
    <svg width={width} height={width} viewBox="0 0 20 20">
      <path
        d="M8.83334 16C12.7271 16 14.2 15.79 14.2583 15.775C14.3896 15.76 14.4917 15.67 14.5792 15.55C14.6521 15.43 14.6813 15.28 14.6521 15.145C14.4042 14.02 13.7188 13.075 12.7125 12.445C12.4646 12.295 12.1583 12.175 11.8375 12.04C11.2688 11.815 10.6271 11.545 10.5104 11.155C10.4229 10.84 10.6563 10.36 10.8896 10.015C11.9688 8.305 12.1583 6.7 11.4438 5.485C10.9042 4.555 9.92709 4 8.83334 4C7.75418 4 6.7771 4.555 6.22293 5.485C5.52293 6.7 5.71251 8.305 6.79168 10.03C7.01043 10.36 7.25835 10.84 7.15626 11.155C7.0396 11.545 6.39793 11.815 5.82918 12.04C5.50834 12.175 5.2021 12.295 4.95418 12.445C3.96251 13.075 3.24793 14.02 3.01459 15.145C2.98543 15.28 3.01459 15.43 3.08751 15.55C3.16043 15.67 3.29168 15.76 3.42293 15.775C3.48126 15.79 4.93959 16 8.83334 16ZM13.2667 6.4V7.36H17V6.4H13.2667ZM17 9.28V8.32H13.2667V9.28H17Z"
        fill={color}
      />
    </svg>
  );
};
