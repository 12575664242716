import { Cms } from '@typings';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { LANGUAGE_NAME_MAP } from '../../../../constants/translation';
import { useTranslationsConfig } from '../../../../utils/hooks';
import { isEmpty } from '../../../../utils/isEmpty';
import { FormAlert } from '../../../various/Form';

export const PageFormLanguagesAlert = () => {
  const { t } = useTranslation(['cms']);
  const { availableLanguages } = useTranslationsConfig();

  const languages = useWatch<Cms.PageDTO, 'availableLanguages'>({ name: 'availableLanguages' });

  const missingLanguagesLabels = React.useMemo(() => {
    const missingLanguagesCodes = languages?.filter(code => {
      return availableLanguages.every(language => language.code !== code);
    });

    return missingLanguagesCodes?.map(code => LANGUAGE_NAME_MAP[code]).join(', ') ?? [];
  }, [languages, availableLanguages]);

  if (isEmpty(missingLanguagesLabels)) {
    return null;
  }

  return <FormAlert type="warning" message={t('cms:missing_languages_warning', { languages: missingLanguagesLabels })} />;
};
