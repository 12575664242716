import { Cms } from '@typings';

import { DEFAULT_CONTENT_ALIGNMENT } from '../../constants/cms';
import { assignIdsToParts } from '../../logic/cms/assignIdsToParts';

import { ButtonStyles } from './buttonStyles';

export const EMPTY_BUTTON_CONTENT = '<span>Label</span>';

export const placeholderButtonPart: Cms.ContentBlockButtonPart = {
  desktop: {
    justifySelf: 'center',
  },
  general: {
    buttonStyle: ButtonStyles.FilledPrimary,
    content: EMPTY_BUTTON_CONTENT,
  },
  type: 'button',
};

export const buttonTemplate = (): Cms.ContentBlockText => ({
  ...DEFAULT_CONTENT_ALIGNMENT,
  ...assignIdsToParts([placeholderButtonPart]),
});
