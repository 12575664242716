import type { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import type { CSSProperties } from 'react';

import { Item } from '../Item';

interface Props {
  id: UniqueIdentifier;
  renderItem: (property: UniqueIdentifier) => JSX.Element | null;
  showHandle?: boolean;
  isHandleDisabled?: boolean;
}

export const SortableItem = ({ id, showHandle, renderItem, isHandleDisabled }: Props) => {
  const { setNodeRef, isDragging, transform, transition, attributes, listeners } = useSortable({
    id,
  });

  const style: CSSProperties = {
    position: 'relative',
    transform: CSS.Translate.toString(transform),
    transition,
    zIndex: isDragging ? 1 : 0,
  };

  return (
    <Item
      ref={setNodeRef}
      itemId={id}
      showHandle={showHandle}
      renderItem={renderItem}
      isHandleDisabled={isHandleDisabled}
      style={style}
      {...attributes}
      {...listeners}
    />
  );
};
