import { without } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Translations } from '../../../../../../typings';
import { getAvailablePageLanguageCodes, getLocalizableCmsBlocks } from '../../../../ducks';
import { getAllCmsLocalizations } from '../../../../ducks/cms/localizations';
import { getIsBlockFullyLocalized } from '../../../../logic/pages';
import { useTranslationsConfig } from '../../../../utils/hooks';
import { isDefined } from '../../../../utils/is';
import { isEmpty } from '../../../../utils/isEmpty';
import { IconType } from '../../../various/Icon';
import { TabList } from '../../../various/Tabs';
import { EditorLanguageContext } from '../../context/EditorLanguageContext';

import styles from './EditorLanguageSwitcher.module.scss';

export const EditorLanguageSwitcher = () => {
  const { supportedLanguages, defaultLanguages } = useTranslationsConfig();
  const { editorLanguage, handleEditorLanguageChange } = React.useContext(EditorLanguageContext);
  const { t } = useTranslation(['cms']);

  const pageLanguageCodes = useSelector(getAvailablePageLanguageCodes);
  const localizableBlocks = useSelector(getLocalizableCmsBlocks);
  const allLocalizations = useSelector(getAllCmsLocalizations);

  const defaultLanguageCodes = defaultLanguages.map(({ code }) => code);
  const optionalLanguageCodes = without(defaultLanguageCodes, pageLanguageCodes);

  const hasLanguageAllBlocksFullyLocalized = React.useMemo(() => {
    return optionalLanguageCodes.reduce((acc, cur) => {
      const languageBlocks = allLocalizations[cur]?.blocks;

      const hasAllBlocksLocalized =
        isDefined(languageBlocks) &&
        localizableBlocks.every(localizableBlock => {
          const currentBlock = languageBlocks[localizableBlock.id];

          return isDefined(currentBlock) && getIsBlockFullyLocalized(localizableBlock, currentBlock);
        });

      return {
        ...acc,
        [cur]: hasAllBlocksLocalized,
      };
    }, {}) as Record<Translations.SupportedLanguagesCodes, boolean>;
  }, [allLocalizations, localizableBlocks, optionalLanguageCodes]);

  const getLocalizationStatusIcon = (language: Translations.SupportedLanguagesCodes) => {
    if (isEmpty(localizableBlocks) || hasLanguageAllBlocksFullyLocalized[language]) {
      return {};
    }

    return {
      alertIcon: IconType.TranslationWarning,
      alertTooltip: t('cms:missing_language_localization'),
    };
  };

  const defaultLanguageTabs = defaultLanguages.map(({ code, label }) => ({
    icon: IconType.Layout,
    iconTooltip: t('cms:layout_edit_tooltip'),
    id: code,
    label,
  }));

  const localizableLanguagesTabs = supportedLanguages
    .map(({ code, label }) => {
      const isPageLanguage = optionalLanguageCodes.includes(code);

      if (!isPageLanguage) {
        return undefined;
      }

      return {
        id: code,
        label,
        ...getLocalizationStatusIcon(code),
      };
    })
    .filter(isDefined);

  const allLanguagesTabs = [...defaultLanguageTabs, ...localizableLanguagesTabs];
  const hasMultipleLanguages = allLanguagesTabs.length > 1;

  if (!hasMultipleLanguages) {
    return null;
  }

  return (
    <div className={styles.languageSwitcher}>
      <TabList
        onChange={handleEditorLanguageChange}
        selectedId={editorLanguage}
        tablistName={t('cms:page_editing_language')}
        tabs={allLanguagesTabs}
        className={styles.tablist}
      />
    </div>
  );
};
