import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { fontWeights } from '../../../../cmsTemplates';
import {
  getCustomStyleErrors,
  getIsTextStyleEditorVisible,
  getTextStyleEditorMode,
  getTextStyleElementStyles,
  getTextStyles,
} from '../../../../ducks/cms';
import { useStyleEditor } from '../../../../utils/hooks/cms/useStyleEditor';
import { isDefined } from '../../../../utils/is';
import { isEmpty } from '../../../../utils/isEmpty';
import { Input } from '../../../various/Fields/Input';
import { FormButtons } from '../../../various/Form';
import { Button } from '../../../various/NewButton';
import {
  AlignmentSwitch,
  ColorEditor,
  FontSelect,
  FontStyleSwitch,
  FontWeightSelect,
  SizeSelect,
  TextStyleSlider,
  TextTransformSwitch,
} from '../../TextTools';
import styles from '../CustomStyleEditor.module.scss';
import { InputRow, SwitchesRow } from '../FieldRow';

export const TextStyleEditorContent = () => {
  const isEditorVisible = useSelector(getIsTextStyleEditorVisible);
  const textStyle = useSelector(getTextStyleElementStyles);
  const allTextStyles = useSelector(getTextStyles);
  const textStyleErrors = useSelector(getCustomStyleErrors);
  const mode = useSelector(getTextStyleEditorMode);
  const { t } = useTranslation(['cms', 'common']);

  const {
    availableFontWeights,
    closeEditor,
    handleCreateCustomStyle,
    handleEditCustomStyle,
    isModifyingCustomStyle,
    name,
    nameError,
    onNameChange,
    setFontFamily,
    setNameError,
    setStyleProperty,
  } = useStyleEditor({
    allStyles: allTextStyles,
    customStyle: textStyle,
    isEditorVisible,
    mode,
  });

  React.useEffect(() => setNameError(textStyleErrors), [textStyleErrors, setNameError]);

  const handleNameExistsFailure = React.useCallback(() => {
    setNameError(t('cms:text_style_name_exists'));
  }, [setNameError, t]);

  const onCreateTextStyle = React.useCallback(() => {
    handleCreateCustomStyle({
      onNameExistsFailure: handleNameExistsFailure,
    });
  }, [handleCreateCustomStyle, handleNameExistsFailure]);

  const onEditTextStyle = React.useCallback(() => {
    handleEditCustomStyle({
      onNameExistsFailure: handleNameExistsFailure,
    });
  }, [handleEditCustomStyle, handleNameExistsFailure]);

  const onSave = React.useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!isDefined(textStyle)) {
        return;
      }

      if (isEmpty(name)) {
        setNameError(t('cms:text_style_name_is_empty'));

        return;
      }

      const action = mode === 'edit' ? onEditTextStyle : onCreateTextStyle;
      action();
    },
    [textStyle, name, mode, onEditTextStyle, onCreateTextStyle, setNameError, t],
  );

  if (!isDefined(textStyle)) {
    return null;
  }

  const { properties } = textStyle;

  const filteredFontWeights = fontWeights.filter(fontWeight => availableFontWeights.includes(fontWeight.value));
  const fontWeightsOptions = !isEmpty(filteredFontWeights) ? filteredFontWeights : fontWeights;

  return (
    <>
      <p className={styles.message}>{t('cms:text_style_editor_description')}</p>
      <form onSubmit={onSave}>
        <fieldset disabled={isModifyingCustomStyle}>
          <InputRow label={t('common:name')} error={nameError}>
            <Input size="small" value={name} className={styles.input} data-testid="textStyleEditorName" onChange={onNameChange} />
          </InputRow>
          <InputRow label={t('cms:text_style_form.font')} preventLabelFocus>
            <FontSelect value={properties.default.fontFamily} onChange={setFontFamily} disabled={isModifyingCustomStyle} />
          </InputRow>
          <InputRow label={t('cms:text_style_form.weight')} preventLabelFocus>
            <FontWeightSelect
              fontWeights={fontWeightsOptions}
              value={properties.default.fontWeight}
              onChange={setStyleProperty('fontWeight')}
              disabled={isModifyingCustomStyle}
            />
          </InputRow>
          <InputRow label={t('cms:text_style_form.size')} preventLabelFocus>
            <SizeSelect
              value={properties.default.fontSize}
              onChange={setStyleProperty('fontSize')}
              className={styles.sizeSelect}
              disabled={isModifyingCustomStyle}
              dataTestId="textStyleEditorSize"
            />
          </InputRow>
          <SwitchesRow>
            <TextTransformSwitch value={properties.default.textTransform} onChange={setStyleProperty('textTransform')} />
            <FontStyleSwitch value={properties.default.fontStyle} onChange={setStyleProperty('fontStyle')} />
          </SwitchesRow>
          <SwitchesRow>
            <AlignmentSwitch
              value={properties.default.textAlign}
              onChange={setStyleProperty('textAlign')}
              className={styles.alignmentSwitch}
              switcherType="text"
            />
            <ColorEditor value={properties.default.color} onChange={setStyleProperty('color')} className={styles.colorEditor} />
          </SwitchesRow>
          <SwitchesRow>
            <TextStyleSlider
              title={t('cms:text_style_form.letter_spacing')}
              min={-10}
              max={10}
              step={0.025}
              value={properties.default.letterSpacing}
              disabled={isModifyingCustomStyle}
              dataTestId="textStyleEditorLetterSpacing"
              onChange={setStyleProperty('letterSpacing')}
            />
          </SwitchesRow>
          <SwitchesRow>
            <TextStyleSlider
              title={t('cms:text_style_form.line_height')}
              min={1}
              max={10}
              step={0.01}
              value={properties.default.lineHeight}
              disabled={isModifyingCustomStyle}
              dataTestId="textStyleEditorLineHeight"
              onChange={setStyleProperty('lineHeight')}
            />
          </SwitchesRow>
          <FormButtons>
            <Button disabled={isModifyingCustomStyle} variant="ghost" color="dark" onClick={closeEditor}>
              {t('common:cancel')}
            </Button>
            <Button type="submit" data-testid="textStyleEditorSave" isLoading={isModifyingCustomStyle}>
              {t('common:save')}
            </Button>
          </FormButtons>
        </fieldset>
      </form>
    </>
  );
};
