import React from 'react';

import { isDefined } from '../is';

export const useExpand = <T extends HTMLElement>({
  initialOpen,
  dependencies = [],
}: {
  initialOpen: boolean;
  dependencies?: Unrestricted[];
}) => {
  const [isOpen, setOpen] = React.useState(initialOpen);
  const [elementHeight, setElementHeight] = React.useState(0);
  const toggle = React.useCallback(() => setOpen(!isOpen), [isOpen]);

  const wrapperRef = React.useRef<T>(null);
  React.useEffect(() => {
    setElementHeight(isDefined(wrapperRef.current) ? wrapperRef.current.offsetHeight : 0);
  }, [wrapperRef.current, wrapperRef.current?.offsetHeight, ...dependencies]);

  const style = React.useMemo(
    () => (isOpen ? { height: elementHeight, overflow: 'hidden' as const } : { height: 0, overflow: 'hidden' as const }),
    [isOpen, elementHeight],
  );

  return { isOpen, style, toggle, wrapperRef };
};
