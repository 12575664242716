import cx from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getHasOrderProducts, getIsLoadingOrderDetails, pushEvent } from '../../../ducks';
import { compiledPaths } from '../../../paths';
import { getNavigationButtonsClickedEvent, NavigateTo } from '../../../utils/analytics/events';
import { useAllProductsLink } from '../../../utils/hooks';
import { useAmplitude } from '../../../utils/hooks/useAmplitude';
import { isDefined } from '../../../utils/is';
import Button from '../../various/Button/Button';

import styles from './CallToAction.module.scss';

interface ActionsProps {
  isPageVersion: boolean;
  isCheckoutVersion: boolean;
  isProductsVersion: boolean;
  isUnitsVersion: boolean;
  orderId: string | undefined;
  isOpen: boolean;
  checkoutButtonText?: string | JSX.Element;
  isSubmitting?: boolean;
  isDisabled?: boolean;
}

interface BackButtonProps {
  to: string | undefined;
  disabled: boolean;
}

const BackButton = ({ to, disabled }: BackButtonProps) => {
  const { t } = useTranslation(['common']);
  const dispatch = useDispatch();

  return (
    <Button
      to={to}
      disabled={disabled}
      variant={['button', 'transparent', 'standard', 'big']}
      className={cx(styles.hideOnMobile, styles.ctaButton)}
      onClick={() => dispatch(pushEvent(getNavigationButtonsClickedEvent('back')))}
    >
      {t('common:back')}
    </Button>
  );
};

export const Actions = ({
  orderId,
  isPageVersion,
  isProductsVersion,
  isUnitsVersion,
  isCheckoutVersion,
  checkoutButtonText,
  isSubmitting,
  isDisabled,
}: ActionsProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'orders']);
  const isAnyProductsAdded = useSelector(getHasOrderProducts);
  const isLoadingOrderDetails = useSelector(getIsLoadingOrderDetails);
  const isLoading = isLoadingOrderDetails || !isDefined(orderId);

  const { trackEvent } = useAmplitude();
  const allProductsLink = useAllProductsLink(orderId);

  const orderDetailsUrl = isDefined(orderId) ? compiledPaths.ORDER_DETAILS({ id: orderId }) : undefined;

  const unitsUrl = isDefined(orderId) ? compiledPaths.CHECKOUT_ORDER({ id: orderId }) : undefined;

  const isPrimaryDisabled = isDisabled || isLoading;
  const isPageAndEmptySelection = isPageVersion && !isAnyProductsAdded;
  const nextButtonText =
    isPageAndEmptySelection ?
      t('common:shop_products')
    : <Trans t={t} i18nKey="common:go_to_selection">
        <span className={styles.reviewText}>Go to</span> selection
      </Trans>;
  const nextButtonUrl = isPageAndEmptySelection ? allProductsLink : orderDetailsUrl;

  const handleTrackingEvent = (navigateTo: NavigateTo) => {
    dispatch(pushEvent(getNavigationButtonsClickedEvent(navigateTo)));
  };

  const handleNavigateToCheckout = () => {
    handleTrackingEvent('checkout');
    trackEvent({ name: 'bottomBar.button.navigatetocheckout.click' });
  };

  return (
    <div className={styles.actions}>
      {(isPageVersion || isProductsVersion) && (
        <Button
          to={nextButtonUrl}
          variant={['button', 'blue', 'standard', 'big']}
          disabled={isPrimaryDisabled}
          className={styles.ctaButton}
          onClick={() => handleTrackingEvent('mySelection')}
        >
          {nextButtonText}
        </Button>
      )}
      {isUnitsVersion && (
        <>
          <BackButton to={allProductsLink} disabled={isLoading} />
          <Button
            to={unitsUrl}
            variant={['button', 'blue', 'standard', 'big']}
            disabled={isPrimaryDisabled}
            className={styles.ctaButton}
            onClick={handleNavigateToCheckout}
          >
            <Trans t={t} i18nKey="common:review_and_checkout">
              <span className={styles.reviewText}>Review +</span>Checkout
            </Trans>
          </Button>
        </>
      )}
      {isCheckoutVersion && (
        <>
          <BackButton to={orderDetailsUrl} disabled={isLoading} />
          <Button
            variant={['button', 'standard', 'big', 'orange']}
            isLoading={isSubmitting}
            disabled={isPrimaryDisabled}
            data-testid="checkoutButton"
            truncateText
            className={cx(styles.checkoutButton, styles.ctaButton)}
            type="submit"
            form="checkoutForm"
          >
            {checkoutButtonText}
          </Button>
        </>
      )}
    </div>
  );
};
