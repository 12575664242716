import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Cms } from '@typings';
import { useTranslation } from 'react-i18next';

import { addToast } from '../../../components/various/Toasts';
import { deleteCustomLayout } from '../../customLayoutsRepository';

import { CUSTOM_LAYOUTS_QUERY_KEY } from './useCustomLayoutsList';

export const useDeleteCustomLayout = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(['cms']);

  const handleMutate = (customLayoutId: string) => {
    queryClient.setQueriesData<Cms.CustomLayoutData[]>({ queryKey: [CUSTOM_LAYOUTS_QUERY_KEY] }, (layouts = []) => {
      return layouts.filter(layout => layout.id !== customLayoutId);
    });
  };

  const handleSettled = () => {
    queryClient.invalidateQueries({ queryKey: [CUSTOM_LAYOUTS_QUERY_KEY] });
  };

  return useMutation({
    mutationFn: async (customLayoutId: string) => deleteCustomLayout(customLayoutId),
    onError: () => addToast(t('cms:custom_layouts_deletion_fail')),
    onMutate: handleMutate,
    onSettled: handleSettled,
    onSuccess: async () => {
      addToast(t('cms:custom_layouts_delete_success'));
    },
  });
};
