import { Addresses } from '@typings';

export const SHIPPING_ADDRESS_DEFAULT_VALUES: Addresses.ShippingAddressDTO = {
  address1: '',
  address2: '',
  city: '',
  company: '',
  contactPerson: '',
  country: null,
  email: '',
  phoneNumber: '',
  state: null,
  zipCode: '',
};

export const SHIPPING_ADDRESS_FIELDS = Object.keys(SHIPPING_ADDRESS_DEFAULT_VALUES) as (keyof Addresses.ShippingAddressDTO)[];

export const INVOICING_ADDRESS_DEFAULT_VALUES: Addresses.BillingDTO = {
  address1: '',
  address2: '',
  city: '',
  company: '',
  contactPerson: '',
  country: null,
  email: '',
  phoneNumber: '',
  state: '',
  vatNumber: '',
  zipCode: '',
};

export const INVOICING_ADDRESS_FIELDS = Object.keys(INVOICING_ADDRESS_DEFAULT_VALUES) as (keyof Addresses.BillingDTO)[];
