import { ActionHandler, Id } from '@typings';
import { createReducer } from 'typesafe-actions';

import { update } from '../../../utils/update';
import { resetCms } from '../general';

import * as actions from './actions';

interface State {
  isLoading: boolean;
  slug: string | null;
  homepageByBuyer: Record<Id, Nullable<string>>;
}

const initialState: State = {
  homepageByBuyer: {},
  isLoading: false,
  slug: null,
};

const handleResetHomepage: ActionHandler<State, typeof resetCms> = () => initialState;

const handleHomepageFetchRequest: ActionHandler<State, typeof actions.fetchHomepageRequest> = state =>
  update(state, {
    isLoading: true,
  });

const handleHomepageFetchSuccess: ActionHandler<State, typeof actions.fetchHomepageSuccess> = (state, action) =>
  update(state, {
    isLoading: false,
    slug: action.payload,
  });

const handleHomepageFetchFailure: ActionHandler<State, typeof actions.fetchHomepageFailure> = state =>
  update(state, {
    isLoading: false,
  });

const handleHomepageByBuyerSuccess: ActionHandler<State, typeof actions.fetchHomePageByBuyerSuccess> = (state, action) =>
  update(state, {
    homepageByBuyer: update(state.homepageByBuyer, {
      [action.payload.buyerId]: action.payload.slug,
    }),
  });

const handlePurgeHomepageByBuyer: ActionHandler<State, typeof actions.purgeHomepageByBuyer> = state =>
  update(state, {
    homepageByBuyer: {},
  });

export default createReducer<State, AppAction>(initialState)
  .handleAction(actions.fetchHomepageRequest, handleHomepageFetchRequest)
  .handleAction(actions.fetchHomepageSuccess, handleHomepageFetchSuccess)
  .handleAction(actions.fetchHomepageFailure, handleHomepageFetchFailure)
  .handleAction(actions.fetchHomePageByBuyerSuccess, handleHomepageByBuyerSuccess)
  .handleAction(actions.purgeHomepageByBuyer, handlePurgeHomepageByBuyer)
  .handleAction(resetCms, handleResetHomepage);
