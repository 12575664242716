import cx from 'classnames';
import React from 'react';

import sortableStyles from '../../../components/various/Sortable/SortableTree/SortableItem/SortableItem.module.scss';

import styles from './ListItem.module.scss';

interface Props {
  action?: React.ReactNode;
  center?: boolean;
}

export const ListItemHead = ({ children, action, center = false }: React.WithChildren<Props>) => {
  return (
    <div className={cx(styles.listItemHead, { [styles.center]: center })}>
      <div className={styles.pageInfo}>{children}</div>
      <div className={cx(styles.action, sortableStyles.actionButton)}>{action}</div>
    </div>
  );
};
