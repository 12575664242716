import { Order } from '@typings';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getOpenSelections, getOrderDetails } from '../../ducks';
import { isDefined } from '../is';

export const useAvailableOrderDetails = () => {
  const { id } = useParams<{ id: string }>();
  const orderDetails = useSelector(getOrderDetails) as Partial<Order.Single>;

  const selections = useSelector(getOpenSelections);

  const fallbackOrder = selections.find(({ order }) => order === id);

  const { order, buyer, account } = orderDetails;

  const orderData = isDefined(order?.order) ? order : fallbackOrder;

  return React.useMemo(
    () => ({
      accountId: account?.account ?? fallbackOrder?.account,
      accountName: account?.name ?? fallbackOrder?.accountName ?? '',
      buyerFirstName: buyer?.firstName ?? fallbackOrder?.buyerFirstName ?? '',
      buyerId: buyer?.buyer ?? fallbackOrder?.buyer,
      buyerLastName: buyer?.lastName ?? fallbackOrder?.buyerLastName ?? '',
      deliveryWindowsIds: orderData?.deliveryWindows,
      hasAnyOrderData: isDefined(orderData),
      orderDate: orderData?.orderDate,
      orderId: orderData?.order ?? '',
      orderName: orderData?.name ?? '',
      orderNumber: orderData?.orderNumber ?? '',
      orderStatus: orderData?.status,
      orderTotals: orderData?.totals,
      poNumber: orderData?.poNumber,
    }),
    [account, buyer, fallbackOrder, orderData],
  );
};
