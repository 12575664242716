import { API, Checkout } from '@typings';
import { HttpStatusCode } from 'axios';
import i18next from 'i18next';
import { push } from 'redux-first-history';

import { addToast } from '../../components/various/Toasts';
import { checkoutOrderFailure, checkoutOrderSplitSuccess, checkoutOrderSuccess, checkoutOrderUnexpectedFailure } from '../../ducks';
import { paths } from '../../paths';
import { getIsSingleCheckout } from '../../utils/guards';

export const handleCheckoutSuccess = (response: Responses.OrderCheckout, originalOrderId: string) => {
  if (getIsSingleCheckout(response)) {
    return checkoutOrderSuccess(response);
  }

  return checkoutOrderSplitSuccess({
    currency: response.currency,
    orders: response.orders,
    originalOrderId,
  });
};

export const handleCheckoutFailure = (error: API.FailedResponse<Checkout.Failure | string>) => {
  if (error.statusCode === HttpStatusCode.NotFound) {
    return push({ pathname: paths.PAGE_NOT_FOUND });
  }

  if (typeof error.data === 'string') {
    addToast(`${i18next.t('genericErrors:unexpected_error')}: ${error.data}`);

    return checkoutOrderUnexpectedFailure();
  }

  return checkoutOrderFailure(error.data);
};
