import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { authoriseAccessRequest } from '../ducks';
import { paths } from '../paths';
import { getToken } from '../utils/auth';
import { isDefined } from '../utils/is';

import { StartPageLayout } from './layout/StartPageLayout';
import { FormButtons } from './various/Form';
import { Button } from './various/NewButton';
import { App } from './App';

export const NoMatch = () => {
  const { t } = useTranslation(['common']);
  const token = getToken();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (isDefined(token)) {
      dispatch(authoriseAccessRequest());
    }
  }, []);

  const noMatchPage = (
    <StartPageLayout title={t('common:page_not_found')} text={t('common:page_not_found_description')}>
      <FormButtons direction="column">
        <Button as={Link} to={paths.ROOT} size="large" color="dark" isFluid>
          {t('common:home_page')}
        </Button>
      </FormButtons>
    </StartPageLayout>
  );

  if (!isDefined(token)) {
    return noMatchPage;
  }

  return <App hideHeader>{noMatchPage}</App>;
};
