import { Addresses } from '@typings';
import { useTranslation } from 'react-i18next';

import { getAddressLine } from '../../../utils/getAddressLine';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import Heading from '../Heading';
import { Skeleton } from '../Skeleton';
import { SkeletonLayout } from '../SkeletonLayout';

import styles from './ReceiptAddress.module.scss';

interface Props {
  address: Addresses.Basic | Empty.Object;
  title: string;
  isLoading?: boolean;
}

interface DisplayAddress {
  address: string;
  city: string;
  company: string;
  country: string;
  state: string;
  zipCode: string;
}

export const ReceiptAddress = ({ address, title, isLoading = false }: Props) => {
  const { t } = useTranslation(['common']);

  const copy: DisplayAddress = {
    address: t('common:address'),
    city: t('common:city'),
    company: t('common:company'),
    country: t('common:country'),
    state: t('common:state'),
    zipCode: t('common:zip_code'),
  };

  const displayAddress: DisplayAddress = {
    address: getAddressLine(address.address1, address.address2),
    city: address.city,
    company: address.company,
    country: address.countryName,
    state: address.stateName ?? address.state,
    zipCode: address.zipCode,
  };

  return (
    <div>
      <Heading title={title} className={styles.receiptAddresses} />
      {Object.keys(copy).map(item => {
        const value = displayAddress[item as keyof DisplayAddress];
        const hasValue = isDefined(value) && !isEmpty(value);

        if (!hasValue && !isLoading) {
          return null;
        }

        return (
          <div className={styles.addressRow} key={copy[item as keyof DisplayAddress]}>
            <span className={styles.label}>{copy[item as keyof DisplayAddress]}:</span>
            <SkeletonLayout isLoading={isLoading} skeleton={<Skeleton type="text" size="medium" width={60} />}>
              <span data-sentry-mask>{displayAddress[item as keyof DisplayAddress]}</span>
            </SkeletonLayout>
          </div>
        );
      })}
    </div>
  );
};
