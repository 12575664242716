import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { DEFAULT_ITEMS_COUNT_PER_REQUEST } from '../../../constants/limits';
import { getSkipFirstForListing } from '../../../utils/getSkipFirstForListing';
import { getInvoicesList } from '../../invoicesRepository';

interface Props {
  page: number;
}

export const useInvoiceList = ({ page }: Props) => {
  const { t } = useTranslation(['invoices']);
  const skipFirst = getSkipFirstForListing(page);

  return useQuery({
    meta: {
      errorMessage: t('invoices:invoices_fetch_fail'),
    },
    placeholderData: keepPreviousData,
    queryFn: async () => getInvoicesList({ limit: DEFAULT_ITEMS_COUNT_PER_REQUEST, skipFirst }),
    queryKey: ['invoiceList', { skipFirst }],
    refetchOnWindowFocus: true,
  });
};
