import { ActionHandler } from '@typings';
import { createReducer } from 'typesafe-actions';

import { getErrorMessages } from '../../utils/errors';
import { update } from '../../utils/update';

import * as actions from './actions';

export interface SelectionImportReducer {
  errors: string[];
  hasCompleted: boolean;
  hasFailed: boolean;
  isLoading: boolean;
  warning: string;
  wasSelectionClosed: boolean;
  wasSelectionRemoved: boolean;
}

const initialState: SelectionImportReducer = {
  errors: [],
  hasCompleted: false,
  hasFailed: false,
  isLoading: false,
  warning: '',
  wasSelectionClosed: false,
  wasSelectionRemoved: false,
};

const handleSelectionImportRequest: ActionHandler<SelectionImportReducer, typeof actions.selectionImportRequest> = state =>
  update(state, { isLoading: true });

const handleSelectionImportSuccess: ActionHandler<SelectionImportReducer, typeof actions.selectionImportSuccess> = (state, action) => {
  const { result } = action.payload;

  return update(state, { hasCompleted: true, isLoading: false, warning: result ?? '' });
};

const handleSelectionImportFailure: ActionHandler<SelectionImportReducer, typeof actions.selectionImportFailure> = (state, action) => {
  const { errors, wasSelectionClosed = false, wasSelectionRemoved = false } = action.payload;

  return update(state, {
    errors: getErrorMessages(errors),
    hasFailed: true,
    isLoading: false,
    wasSelectionClosed,
    wasSelectionRemoved,
  });
};

const handleResetSelectionImport: ActionHandler<SelectionImportReducer, typeof actions.resetSelectionImport> = () => {
  return initialState;
};

export default createReducer<SelectionImportReducer, AppAction>(initialState)
  .handleAction(actions.selectionImportRequest, handleSelectionImportRequest)
  .handleAction(actions.selectionImportSuccess, handleSelectionImportSuccess)
  .handleAction(actions.selectionImportFailure, handleSelectionImportFailure)
  .handleAction(actions.resetSelectionImport, handleResetSelectionImport);
