import cx from 'classnames';
import React from 'react';

import { Key } from '../../../utils/keys';
import Icon, { IconColor, IconType } from '../../various/Icon';

import styles from './ShowMoreBox.module.scss';

interface Props {
  title: string;
  hint?: string;
}

const OPEN_ROTATION = 180;

export const ShowMoreBox = ({ title, hint, children }: React.WithChildren<Props>) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggle = () => {
    setIsOpen(open => !open);
  };

  const toggleOnEnter = (event: React.KeyboardEvent) => {
    if (event.code === Key.ENTER) {
      toggle();
    }
  };

  return (
    <>
      <button
        type="button"
        className={styles.rowHeading}
        onClick={toggle}
        aria-expanded={isOpen}
        onKeyDown={toggleOnEnter}
        data-testid="showMoreBox"
      >
        <span>
          {title}
          <span className={styles.moreFieldsHint}>{hint}</span>
        </span>
        <span>
          <Icon type={IconType.ArrowBlackDown} color={IconColor.Medium} rotation={isOpen ? OPEN_ROTATION : 0} />
        </span>
      </button>
      <div className={cx(styles.container, { [styles.expanded]: isOpen })}>{children}</div>
    </>
  );
};
