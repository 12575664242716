import * as Sentry from '@sentry/react';
import { detect } from 'detect-browser';

import { isDefined } from './is';

const minFirefoxVersion = 80;
const minSafariVersion = 14.1;
const minChromeVersion = 90;
const minEdgeVersion = 90;
const minOperaVersion = 90;

export const getIsUnsupportedBrowser = () => {
  try {
    const browser = detect();
    if (!browser || !isDefined(browser.name)) {
      return false;
    }

    switch (browser.name) {
      case 'chromium-webview':
      case 'edge-chromium':
      case 'chrome':
        return parseInt(browser.version, 10) < minChromeVersion;
      case 'ios':
      case 'ios-webview':
      case 'safari':
        return parseFloat(browser.version) < minSafariVersion;
      case 'firefox':
        return parseInt(browser.version, 10) < minFirefoxVersion;
      case 'edge':
        return parseInt(browser.version, 10) < minEdgeVersion;
      case 'opera':
        return parseInt(browser.version, 10) < minOperaVersion;
      default:
        return false;
    }
  } catch (error) {
    Sentry.captureException(error);

    return false;
  }
};
