/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 12;
const ORIGINAL_COLOR = '#000000';

const Checkout = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => {
  return (
    <svg width={width} height={width} viewBox="0 0 12 13" fill="none">
      <path
        d="M11.1576 3.96823H10.3034C10.3034 1.77762 8.37182 0 6.00347 0C3.63511 0 1.71325 1.77762 1.71325 3.96823H0.849381C0.31553 3.96823 -0.0824318 4.41713 0.014632 4.90193L1.61618 12.3715C1.69384 12.7396 2.04327 13 2.45093 13H9.5463C9.95396 13 10.2937 12.7396 10.381 12.3715L11.9826 4.90193C12.0894 4.42611 11.6914 3.96823 11.1576 3.96823ZM6.00347 1.79558C7.30412 1.79558 8.35241 2.77417 8.35241 3.96823H3.65452C3.65452 2.77417 4.70281 1.79558 6.00347 1.79558Z"
        fill={color}
      />
    </svg>
  );
};

export { Checkout };
