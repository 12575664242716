/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;

export const Warning = ({ width = ORIGINAL_WIDTH, color = '#D87B46' }: IconComponentProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox="0 0 20 20" fill={color}>
      <path d="M9.99992 13.9373C10.4026 13.9373 10.7291 13.6109 10.7291 13.2082C10.7291 12.8055 10.4026 12.479 9.99992 12.479C9.59721 12.479 9.27075 12.8055 9.27075 13.2082C9.27075 13.6109 9.59721 13.9373 9.99992 13.9373Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 4.35484C6.88226 4.35484 4.35484 6.88226 4.35484 10C4.35484 13.1177 6.88226 15.6452 10 15.6452C13.1177 15.6452 15.6452 13.1177 15.6452 10C15.6452 6.88226 13.1177 4.35484 10 4.35484ZM3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10ZM10 6.16129C10.3741 6.16129 10.6774 6.46458 10.6774 6.83871V10.5269C10.6774 10.901 10.3741 11.2043 10 11.2043C9.62587 11.2043 9.32258 10.901 9.32258 10.5269V6.83871C9.32258 6.46458 9.62587 6.16129 10 6.16129Z"
      />
    </svg>
  );
};
