import { Addresses, Form, Radio } from '@typings';
import React from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getOrderDetails, pushEvent, updateTotalsByShippingAddress } from '../../../ducks';
import { getCountryByShippingAddressId, getShippingAddressId } from '../../../logic/Orders';
import { getShippingAddressSelectedEvent } from '../../../utils/analytics/events';
import { isTemporaryShipping } from '../../../utils/guards';
import { useConfirmationGuard } from '../../../utils/hooks';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { omit } from '../../../utils/omit';
import { ShippingAddressRadioGroup } from '../../various/Fields/ShippingAddressRadioGroup';
import { FieldWrapper } from '../../various/Form/FormField/FieldParts';

import { ShippingAddressBody } from './ShippingAddressBody';
import { ShippingAddressHeader } from './ShippingAddressHeader';

type RadioGroupProps<T extends string> = Omit<
  Radio.Group<T>,
  'value' | 'orientation' | 'describedById' | 'onChange' | 'onBlur' | 'options'
>;

interface Props<T extends string> extends RadioGroupProps<T>, Form.FieldProps {
  options: (Addresses.Shipping | Addresses.TemporaryShipping)[];
  canAddAddress?: boolean;
  showAddAddress?: boolean;
}

const EMPTY_COUNTRY_PLACEHOLDER = '-';

export const ShippingAddressRadioGroupField = <T extends string>(props: Props<T>) => {
  const { description, helpMessage, label, name, isRequired = false, orientation, options, ...rest } = props;

  const { t } = useTranslation(['common', 'confirmationConfig']);
  const errorId = React.useId();
  const describedById = React.useId();
  const orderDetails = useSelector(getOrderDetails);
  const confirmationGuard = useConfirmationGuard();
  const dispatch = useDispatch();

  const { field } = useController({ name });

  const changeShippingAddress = (event: string) => {
    field.onChange(event);
    dispatch(pushEvent(getShippingAddressSelectedEvent('existing')));
    const shippingAddress = getShippingAddressId(orderDetails.account?.shippingAddresses, event);

    if (!isDefined(shippingAddress)) {
      return;
    }

    dispatch(
      updateTotalsByShippingAddress(
        isTemporaryShipping(shippingAddress) ?
          { shippingAddress: omit(shippingAddress, ['isTemporary', 'shippingAddress']) }
        : { shippingAddressId: event },
      ),
    );
  };

  const handleChange = React.useCallback(
    (event: string) => {
      const candidateCountryByShippingAddressId = getCountryByShippingAddressId(orderDetails.account?.shippingAddresses, event);
      const candidateCountryCode =
        isDefined(candidateCountryByShippingAddressId) && !isEmpty(candidateCountryByShippingAddressId) ?
          candidateCountryByShippingAddressId
        : EMPTY_COUNTRY_PLACEHOLDER;
      const currentCountryByShippingAddressId = getCountryByShippingAddressId(orderDetails.account?.shippingAddresses, field.value);
      const currentCountryCode =
        isDefined(currentCountryByShippingAddressId) && !isEmpty(currentCountryByShippingAddressId) ?
          currentCountryByShippingAddressId
        : EMPTY_COUNTRY_PLACEHOLDER;

      if (candidateCountryCode === currentCountryCode) {
        changeShippingAddress(event);
        field.onChange(event);

        return;
      }

      confirmationGuard(() => ({
        onOk: () => changeShippingAddress(event),
        ...t('confirmationConfig:change_shipping_country_modal', {
          candidateCountryCode,
          currentCountryCode,
          returnObjects: true,
        }),
        okText: t('common:change'),
      }))();
    },
    [confirmationGuard, field, orderDetails.account?.shippingAddresses, t],
  );

  const optionsToShow = React.useMemo(() => {
    return options.filter(isDefined).map(shippingAddress => {
      return {
        body: <ShippingAddressBody shippingAddress={shippingAddress} />,
        header: <ShippingAddressHeader shippingAddress={shippingAddress} />,
        value: shippingAddress.shippingAddress,
        ...shippingAddress,
      };
    });
  }, [options]);

  return (
    <FieldWrapper
      as="span"
      description={description}
      describedById={describedById}
      helpMessage={helpMessage}
      errorId={errorId}
      isRequired={isRequired}
      label={label}
      orientation={orientation}
    >
      <ShippingAddressRadioGroup
        ref={field.ref}
        options={optionsToShow}
        value={field.value}
        isRequired={isRequired}
        describedById={isDefined(description) ? describedById : undefined}
        onChange={handleChange}
        {...rest}
      />
    </FieldWrapper>
  );
};
