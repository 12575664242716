import { TooltipProps } from '@typings';
import cx from 'classnames';
import React from 'react';
import { Arrow, mergeRefs, useHover, useLayer } from 'react-laag';
import { CSSTransition } from 'react-transition-group';

import { useIsTouchScreen } from '../../../utils/hooks/useIsTouchScreen';
import { isDefined } from '../../../utils/is';

import styles from './Tooltip.module.scss';
import { Trigger } from './Trigger';

const ENTER_TIMEOUT = 300;
const EXIT_TIMEOUT = 100;

const DEFAULT_DELAY = 100;
const DEFAULT_ARROW_SIZE = 5;

export const Tooltip = ({
  children,
  content,
  overlayClassName,
  visible,
  container,
  placement = 'top-center',
  mouseEnterDelay = DEFAULT_DELAY,
  mouseLeaveDelay = DEFAULT_DELAY,
  arrowSize = DEFAULT_ARROW_SIZE,
  hideArrow = false,
  autoHide = true,
  autoPlacement = true,
  disableOnTouchScreen = false,
  isInteractive = true,
  onTooltipHide,
  overflowContainer = true,
  onParentClose,
}: TooltipProps) => {
  const layerRef = React.useRef<HTMLDivElement>(null);
  const [shouldRenderLayer, setShouldRenderLayer] = React.useState(true);
  const [isOver, hoverProps] = useHover({
    delayEnter: mouseEnterDelay,
    delayLeave: mouseLeaveDelay,
  });
  const isTouchScreen = useIsTouchScreen();
  const isDisabled = isTouchScreen && disableOnTouchScreen;
  const isOpen = visible ?? (!isDisabled && isOver);

  const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
    auto: autoPlacement,
    container,
    isOpen,
    onDisappear: () => autoHide && setShouldRenderLayer(false),
    onParentClose,
    overflowContainer,
    placement,
    triggerOffset: 10,
  });

  React.useEffect(() => {
    setShouldRenderLayer(true);

    if (!isOpen) {
      setTimeout(() => {
        onTooltipHide?.();
      }, EXIT_TIMEOUT + 1);
    }
  }, [isOpen]);

  if (!isDefined(content)) {
    return <>{children}</>;
  }

  return (
    <>
      <Trigger triggerProps={triggerProps} hoverProps={hoverProps} layerRef={layerRef}>
        {children}
      </Trigger>
      {shouldRenderLayer &&
        renderLayer(
          <CSSTransition
            in={isOpen}
            timeout={{ enter: ENTER_TIMEOUT, exit: EXIT_TIMEOUT }}
            unmountOnExit
            classNames={{
              enter: styles.tooltipEnter,
              enterActive: styles.tooltipEnterActive,
              exit: styles.tooltipExit,
              exitActive: styles.tooltipExitActive,
            }}
          >
            <div
              {...layerProps}
              ref={mergeRefs(layerProps.ref, layerRef)}
              onMouseLeave={hoverProps.onMouseLeave}
              className={cx(overlayClassName, styles.tooltipBox, { [styles.nonInteractive]: !isInteractive })}
            >
              {content}
              {!hideArrow && <Arrow {...arrowProps} backgroundColor="rgba(0, 0, 0, 0.75)" size={arrowSize} />}
            </div>
          </CSSTransition>,
        )}
    </>
  );
};
