import { LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import cx from 'classnames';
import React from 'react';

import { ButtonWrapper } from './ButtonWrapper';
import styles from './ContentEditor.module.scss';
import { ContentEditorPlugin } from './ContentEditorPlugin';
import { Placeholder } from './Placeholder';

interface BaseProps extends React.WithChildren {
  onFocus: () => void;
  content: string;
  namespace: string;
  onTab: (event: React.KeyboardEvent<HTMLDivElement>) => boolean;
}

interface TextEditorProps extends BaseProps {
  contentType: 'text';
}

interface ButtonEditorProps extends BaseProps {
  contentType: 'button';
  classNames: string;
  style: React.CSSProperties;
  shouldFocus: boolean;
}

type Props = TextEditorProps | ButtonEditorProps;

export const ContentEditor = (props: Props) => {
  const { onFocus, content, namespace, onTab, contentType, children } = props;
  const initialConfig = {
    namespace,
    nodes: [LinkNode, ListNode, ListItemNode],
    onError(error: Error) {
      throw error;
    },
  };

  const editorRoot = React.useMemo(
    () => (
      <>
        <div className={styles.root}>
          <RichTextPlugin contentEditable={<ContentEditable className={cx(styles.editorInput)} />} placeholder={<Placeholder />} />
          <HistoryPlugin />
          <LinkPlugin />
          <ListPlugin />
          <ContentEditorPlugin contentType={contentType} onFocus={onFocus} content={content} onTab={onTab} />
        </div>
        {children}
      </>
    ),
    [children, content, contentType, onFocus, onTab],
  );

  return (
    <LexicalComposer initialConfig={initialConfig}>
      {contentType === 'button' ?
        <ButtonWrapper classNames={props.classNames} style={props.style} shouldFocus={props.shouldFocus}>
          {editorRoot}
        </ButtonWrapper>
      : <>{editorRoot}</>}
    </LexicalComposer>
  );
};
