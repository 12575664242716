import { Cms } from '@typings';
import { nanoid } from 'nanoid';

const DEFAULT_CONTENT_BLOCK_SETTINGS: Cms.ContentBlock['settings'] = {};

const DEFAULT_PRODUCT_BLOCK_SETTINGS: Cms.ProductBlock['settings'] = {
  products: [],
};

export const insertTemplate = (model: Cms.BlockModel[]): Cms.AnyBlock[] => {
  const groupId = nanoid();

  return model.map(block => {
    const { blockType } = block;

    const blockBase = {
      ...block,
      groupId,
      id: nanoid(),
    };

    if (blockType === 'content') {
      return {
        ...blockBase,
        blockType,
        settings: DEFAULT_CONTENT_BLOCK_SETTINGS,
      };
    }

    return {
      ...blockBase,
      blockType,
      settings: DEFAULT_PRODUCT_BLOCK_SETTINGS,
    };
  });
};
