import { Toast } from '@typings';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { isDefined } from '../../../utils/is';

import { ActionType } from './state/actions';
import { dispatch, useStore } from './state/store';
import { dismissToast } from './toast';

const GUTTER = 8;

export const useToaster = (toastOptions?: Toast.Options) => {
  const location = useLocation();

  const { toasts, pausedAt } = useStore(toastOptions);

  const getTimeouts = React.useCallback(() => {
    const now = Date.now();

    return toasts.map(toast => {
      if (toast.duration === 0) {
        return undefined;
      }

      const durationLeft = (toast.duration ?? 0) + toast.pauseDuration - (now - toast.createdAt);

      if (durationLeft < 0) {
        if (toast.visible) {
          dismissToast(toast.id);
        }

        return undefined;
      }

      return setTimeout(() => dismissToast(toast.id), durationLeft);
    });
  }, [toasts]);

  React.useEffect(() => {
    toasts.forEach(({ getShouldCloseOnLocation, id }) => {
      if (!isDefined(getShouldCloseOnLocation) || !getShouldCloseOnLocation(location.pathname)) {
        return;
      }

      dismissToast(id);
    });
  }, [location]);

  React.useEffect(() => {
    if (isDefined(pausedAt)) {
      return;
    }

    const timeouts = getTimeouts();

    return () => {
      timeouts.forEach(timeout => timeout && clearTimeout(timeout));
    };
  }, [toasts, pausedAt]);

  const endPause = React.useCallback(() => {
    if (!isDefined(pausedAt)) {
      return;
    }

    dispatch({ time: Date.now(), type: ActionType.END_PAUSE });
  }, [pausedAt]);

  const calculateOffset = React.useCallback(
    (toast: Toast, defaultPosition?: Toast.Position) => {
      const relevantToasts = toasts.filter(toastFromState => {
        const samePosition = (toastFromState.position ?? defaultPosition) === (toast.position ?? defaultPosition);

        return samePosition && toastFromState.height;
      });
      const toastIndex = relevantToasts.findIndex(relevantToast => relevantToast.id === toast.id);
      const toastsBefore = relevantToasts.filter((relevantToast, index) => index < toastIndex && relevantToast.visible).length;

      return relevantToasts
        .filter(relevantToast => relevantToast.visible)
        .slice(...[toastsBefore + 1])
        .reduce((acc, currentToast) => acc + (currentToast.height ?? 0) + GUTTER, 0);
    },
    [toasts],
  );

  const startPause = React.useCallback(() => {
    dispatch({
      time: Date.now(),
      type: ActionType.START_PAUSE,
    });
  }, []);

  const updateHeight = React.useCallback((toastId: string, height: number) => {
    dispatch({
      toast: { height, id: toastId },
      type: ActionType.UPDATE_TOAST,
    });
  }, []);

  return {
    handlers: {
      calculateOffset,
      endPause,
      startPause,
      updateHeight,
    },
    toasts,
  };
};
