import { Invoice } from '@typings';

import { showroomApi } from '../services/api';

export const downloadOrderConfirmation = async (orderId: string) =>
  showroomApi.GET('/orders/:orderId/download-pdf')({
    pathParams: {
      orderId,
    },
    responseType: 'blob',
  });

export const downloadOrderInvoice = async ({ orderId, invoiceId }: { orderId: string; invoiceId: string }) =>
  showroomApi.GET('/orders/:orderId/invoices/:invoiceId/download')({
    pathParams: {
      invoiceId,
      orderId,
    },
    responseType: 'blob',
  });

export const downloadInvoice = async ({ invoiceId, invoiceHash }: Invoice.InvoiceIdentifiers) =>
  showroomApi.GET('/invoice/:invoiceId/:invoiceHash/download')({
    pathParams: {
      invoiceHash,
      invoiceId,
    },
    responseType: 'blob',
  });

export const downloadProductImages = async ({ orderId }: { orderId: string }) =>
  showroomApi.GET('/orders/:orderId/images')({
    pathParams: {
      orderId,
    },
    responseType: 'json',
  });

export const downloadOrderAsExcell = async (orderId: string) =>
  showroomApi.GET('/orders/:orderId/download-excel')({
    pathParams: {
      orderId,
    },
    responseType: 'blob',
  });

export const downloadProductInfoAsExcell = async (orderId: string) =>
  showroomApi.GET('/orders/:orderId/download-detailed-spreadsheet')({
    pathParams: {
      orderId,
    },
    responseType: 'blob',
  });

export const downloadOrderAsCsv = async (orderId: string) =>
  showroomApi.GET('/orders/:orderId/download-csv')({
    pathParams: {
      orderId,
    },
    responseType: 'blob',
  });

export const generateLinesheet = async (data: Requests.Linesheet) => {
  return showroomApi.QUERY_POST('/line-sheet/download')({ data });
};

export const fetchLinesheetTemplates = async () => showroomApi.QUERY_GET('/linesheet/settings')({});
