import { useTranslation } from 'react-i18next';

import { User } from '../../../../typings';
import { Input } from '../various/Fields/Input';
import { FieldWrapper, FormGrid } from '../various/Form';
import Heading from '../various/Heading';

import styles from './Form.module.scss';

interface Props {
  user: User | Empty.Object;
}

export const UserInfo = ({ user }: Props) => {
  const { t } = useTranslation(['common']);

  return (
    <section>
      <Heading title={t('common:about_me')} className={styles.heading} variant={['h2', 'black']} />
      <FormGrid cols={2} colGap="xlarge">
        <FieldWrapper label={t('common:full_name')}>
          <Input size="large" value={user.name} isDisabled />
        </FieldWrapper>
        <FieldWrapper label={t('common:email')}>
          <Input size="large" value={user.email} isDisabled />
        </FieldWrapper>
      </FormGrid>
    </section>
  );
};
