import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { uploadMedia } from '../../../services';
import { useValidatedForm } from '../../../utils/hooks/useValidatedForm';
import { isDefined } from '../../../utils/is';
import { FilePickerField, Form, FormButtons } from '../../various/Form';
import { Button } from '../../various/NewButton';

import { MediaForm } from './types';

interface FormFields {
  file: Nullable<File>;
}

const defaultValues: FormFields = {
  file: null,
};

export const FileForm = (props: MediaForm) => {
  const { isSubmitting, setIsSubmitting, onCancel, onSubmit } = props;
  const { t } = useTranslation(['cms', 'common', 'validation']);

  const validationSchema: yup.ObjectSchema<FormFields> = yup.object({
    file: yup.mixed<File>().required(t('validation:file_hint')),
  });

  const formMethods = useValidatedForm<FormFields>({ defaultValues, validationSchema });
  const { setError } = formMethods;

  const handleSubmit = React.useCallback(
    (values: FormFields) => {
      if (!isDefined(values.file)) {
        return;
      }

      setIsSubmitting(true);
      uploadMedia({ file: values.file })
        .then(response => onSubmit(response))
        .catch(() => setError('file', { message: t('cms:upload_failed') }))
        .finally(() => setIsSubmitting(false));
    },
    [onSubmit, setError, setIsSubmitting, t],
  );

  return (
    <Form formMethods={formMethods} onSubmit={handleSubmit}>
      <FilePickerField name="file" size="large" fitPreview isDisabled={isSubmitting} />
      <FormButtons showDivider>
        <Button size="large" variant="ghost" color="dark" disabled={isSubmitting} onClick={onCancel}>
          {t('common:cancel')}
        </Button>
        <Button size="large" type="submit" isLoading={isSubmitting}>
          {t('cms:upload')}
        </Button>
      </FormButtons>
    </Form>
  );
};
