import { useContentSetContext } from '../../../../../cms/context/ContentSetContext';

import styles from './TextOverlay.module.scss';

export const TextOverlay = () => {
  const { activePartPosition } = useContentSetContext();

  const { left, top, right, bottom } = activePartPosition;

  const clipPathStyle = {
    clipPath: `polygon(
      -1% -1%,
      -1% 101%,
      ${left}px 101%,
      ${left}px ${top}px,
      ${right}px ${top}px,
      ${right}px ${bottom}px,
      ${left}px ${bottom}px,
      ${left}px 101%,
      101% 101%,
      101% -1%
    )`,
  };

  return <div className={styles.textOverlay} style={{ ...clipPathStyle }} />;
};
