import cx from 'classnames';
import React from 'react';

import { InputVariant } from '../../../../../typings';
import { INPUT_MAX_LENGTH } from '../../../constants/limits';
import { ConditionalWrapping } from '../../../HOC/ConditionalWrapping';

import styles from './Input.module.scss';

export type InputProps = {
  variant?: ReadonlyArray<InputVariant>;
  value?: number | string | string[];
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  className?: string;
  wrapperClassName?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  React.useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

  const { type = 'text', variant = [], className, wrapperClassName, value, ...restProps } = props;

  const wrapperClasses = cx(
    {
      [styles.noBorder]: variant.includes('orange'),
    },
    wrapperClassName,
  );

  const inputClasses = cx(
    {
      [styles.orange]: variant.includes('orange'),
      [styles.dropdown]: variant.includes('dropdown'),
    },
    className,
  );

  const renderWithWrapper = React.useCallback(
    (children: React.ReactElement) => <div className={wrapperClasses}>{children}</div>,
    [wrapperClasses],
  );

  return (
    <>
      <ConditionalWrapping condition={Boolean(wrapperClasses)} render={renderWithWrapper}>
        <input type={type} className={inputClasses} ref={inputRef} maxLength={INPUT_MAX_LENGTH} value={value} {...restProps} />
      </ConditionalWrapping>
    </>
  );
});
