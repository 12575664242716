import { Checkboxes, Filter, Filters } from '@typings';
import { without } from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getProductsAttributes, updateFilters } from '../../../ducks/products';
import { getSelectedValues, updateCheckboxTree } from '../../../logic/checkboxTree';
import { mapFilterOptionsToCheckboxOptions } from '../../../logic/filters';
import { isDefined } from '../../../utils/is';

import { ClearFilterButton } from './ClearFilterButton';
import styles from './FiltersBar.module.scss';

interface Props {
  filters: Filters;
}

export const AppliedFilters = ({ filters }: Props) => {
  const dispatch = useDispatch();
  const attributes = useSelector(getProductsAttributes);
  const selectedAttributeGroups = React.useMemo(() => {
    const filterKeys = Object.keys(filters);

    return attributes.filter(({ field, isRadio }) => filterKeys.includes(field) && !isRadio);
  }, [filters, attributes]);

  const getActiveFiltersInGroup = React.useCallback(
    (group: Filter.Field<string>) => {
      return group.values.filter(value => filters[group.field]?.includes(value.filterValue));
    },
    [filters],
  );

  const attributesFilters = React.useMemo(
    () =>
      selectedAttributeGroups.reduce<{ [attribute: string]: Filter.Option<string>[] }>((acc, group) => {
        return { ...acc, [group.field]: getActiveFiltersInGroup(group) };
      }, {}),
    [getActiveFiltersInGroup, selectedAttributeGroups],
  );

  const selectedAvailabilityFilter = React.useMemo(() => {
    const onlyAvailableData = attributes.find(({ field }) => field === 'onlyAvailable');

    return onlyAvailableData?.values.find(({ filterValue }) => filterValue === filters.onlyAvailable);
  }, [attributes, filters.onlyAvailable]);

  const clearIsStockFilter = React.useCallback(() => {
    dispatch(updateFilters({ filters: { onlyAvailable: 'no' }, preserveCurrent: true }));
  }, [dispatch]);

  const getAllOptionsForAttribute = React.useCallback(
    (attribute: string) => attributes.find(attrib => attrib.field === attribute)?.values ?? [],
    [attributes],
  );

  const clearAttributeFilter = (filterValue: string, attribute: string) => () => {
    const allOptions = mapFilterOptionsToCheckboxOptions(getAllOptionsForAttribute(attribute));
    const hasNested = allOptions.some(option => isDefined(option.parentValue));

    if (hasNested) {
      const baseState = attributesFilters[attribute]?.reduce<Checkboxes.OptionsState>(
        (acc, option) => ({
          ...acc,
          [option.value]: 'checked',
        }),
        {},
      );

      const updatedNestedFilters = updateCheckboxTree(allOptions)(filterValue, 'unchecked', baseState);

      dispatch(
        updateFilters({
          filters: { [attribute]: getSelectedValues(updatedNestedFilters) },
          preserveCurrent: true,
        }),
      );

      return;
    }

    dispatch(
      updateFilters({
        filters: { [attribute]: without([filterValue], [...(filters[attribute] ?? [])]) },
        preserveCurrent: true,
      }),
    );
  };

  return (
    <>
      {isDefined(selectedAvailabilityFilter) && selectedAvailabilityFilter.filterValue !== 'no' && (
        <ClearFilterButton name={selectedAvailabilityFilter.data} onClick={clearIsStockFilter} className={styles.clearFilterButton} />
      )}
      {Object.entries(attributesFilters).map(([key, value]) =>
        value.map(element => (
          <ClearFilterButton
            key={element.value}
            name={element.data}
            swatchData={element.swatch}
            onClick={clearAttributeFilter(element.filterValue, key)}
            className={styles.clearFilterButton}
          />
        )),
      )}
    </>
  );
};
