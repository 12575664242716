import { ConfirmationGuard } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useModalVisibility } from '../../../utils/hooks';
import { Modal, ModalHeading, ModalSheet, ModalSize } from '../ModalElements';

import { ConfirmationButtons } from './ConfirmationButtons';
import { ConfirmationGuardContext } from './ConfirmationGuardContext';
import styles from './ConfirmationModal.module.scss';

export const ConfirmationModal = () => {
  const { t } = useTranslation(['common']);
  const { isModalVisible, hideModal } = useModalVisibility('ConfirmationModal');
  const { config: contextConfig } = React.useContext(ConfirmationGuardContext);
  const [isLoading, setIsLoading] = React.useState(false);

  const defaultConfigValues: Partial<ConfirmationGuard.Config> = React.useMemo(() => {
    if (contextConfig.type === 'warning') {
      return {
        okText: t('common:ok'),
        okType: 'info',
      };
    }

    return {
      cancelText: t('common:cancel'),
      okText: t('common:delete'),
      okType: 'danger',
      type: 'confirm',
    };
  }, [contextConfig.type, t]);

  const config: ConfirmationGuard.Config = React.useMemo(
    () => ({
      ...defaultConfigValues,
      ...contextConfig,
    }),
    [defaultConfigValues, contextConfig],
  );

  const handleRequestClose = React.useCallback(
    (event?: React.UIEvent) => {
      config.onRequestClose?.(event);
      hideModal();
    },
    [config, hideModal],
  );

  React.useEffect(() => {
    if (!isModalVisible) {
      setIsLoading(false);
    }
  }, [isModalVisible, setIsLoading]);

  return (
    <Modal
      isOpen={isModalVisible}
      size={ModalSize.Small}
      onRequestClose={handleRequestClose}
      contentClassName={styles.modal}
      shouldCloseOnEsc={!isLoading}
      shouldCloseOnOverlayClick={!isLoading}
      hideCloseIcon
    >
      <ModalHeading smallPadding>{config.title}</ModalHeading>
      <ModalSheet equalPadding>
        <div className={styles.content} data-testid="confirmationModalContent">
          {config.content}
        </div>
        <ConfirmationButtons config={config} isLoading={isLoading} setIsLoading={setIsLoading} />
      </ModalSheet>
    </Modal>
  );
};
