import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getOrderDetails } from '../../../ducks';
import { getIsProductsGroupedByDelwin, toggleProductsGroupedByDelwin } from '../../../ducks/ui';
import { isEmpty } from '../../../utils/isEmpty';
import { BottomWrapper } from '../../various/BottomWrapper';
import { PageTitle } from '../../various/PageTitle';
import { CallToAction } from '../CallToAction';
import { OrderProducts } from '../OrderProducts';
import { OrderTopBar } from '../OrderTopBar';
import UnitsActions from '../UnitsActions';

const OrderDetails = () => {
  const dispatch = useDispatch();
  const [sortBySKU, setSortBySKU] = React.useState(false);
  const groupByDelwin = useSelector(getIsProductsGroupedByDelwin);
  const orderDetails = useSelector(getOrderDetails);
  const { t } = useTranslation(['common', 'orders', 'selections']);

  const handleToggleGrouping = React.useCallback(() => {
    dispatch(toggleProductsGroupedByDelwin());
  }, []);

  const handleSortBySku = React.useCallback(() => {
    setSortBySKU(!sortBySKU);
  }, [sortBySKU]);

  const hasProducts = !isEmpty(orderDetails.order.products);
  const title = `${t('selections:selection_number', {
    orderNumber: orderDetails.order.orderNumber,
  })} - ${t('selections:my_selection')}`;

  return (
    <PageTitle title={title}>
      <OrderTopBar>
        <UnitsActions
          groupByDelwin={groupByDelwin}
          handleSortBySku={handleSortBySku}
          handleToggleGrouping={handleToggleGrouping}
          sortBySKU={sortBySKU}
        />
      </OrderTopBar>

      <OrderProducts groupByDelwin={groupByDelwin} sortBySKU={sortBySKU} />
      <BottomWrapper>
        <CallToAction callToActionVersion="units" isDisabled={!hasProducts} />
      </BottomWrapper>
    </PageTitle>
  );
};

export default OrderDetails;
