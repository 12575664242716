import { API } from '@typings';
import { AnyAction } from 'redux';
import { isObservable, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { logoutRequest } from '../../ducks/user';
import { getToken } from '../auth';
import { handleResponseStatus } from '../handleResponseStatus';
import { isDefined } from '../is';
import { isResourceForbidden } from '../isResourceForbidden';

type CallbackResult = AnyAction | AnyAction[] | Observable<AnyAction>;

const normalizeActions = (callback: () => CallbackResult) => {
  const result = callback();

  if (isObservable(result) || Array.isArray(result)) {
    return result;
  }

  return [result];
};

export const mapResponse =
  <T, K>(
    successCallback: (response: API.SuccessResponse<T>) => CallbackResult,
    failureCallback: (response: API.FailedResponse<K>) => CallbackResult,
  ) =>
  (stream$: Observable<API.SuccessResponse<T> | API.FailedResponse<K>>) => {
    return stream$.pipe(
      mergeMap(response =>
        handleResponseStatus(response)(
          () => normalizeActions(() => successCallback(response as API.SuccessResponse<T>)),
          () =>
            isResourceForbidden(response as API.FailedResponse<K>) && isDefined(getToken()) ?
              [logoutRequest()]
            : normalizeActions(() => failureCallback(response as API.FailedResponse<K>)),
        ),
      ),
    );
  };
