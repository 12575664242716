import React from 'react';
import { TriggerProps, UseHoverProps } from 'react-laag';

interface Props {
  triggerProps: TriggerProps;
  hoverProps: UseHoverProps;
  layerRef: React.RefObject<HTMLDivElement>;
}

export const Trigger = ({ triggerProps, hoverProps, layerRef, children }: React.WithChildren<Props>) => {
  const onMouseLeave = React.useCallback(
    (event: React.MouseEvent<HTMLSpanElement>) => {
      const { relatedTarget } = event;
      if (!(relatedTarget instanceof Node && layerRef.current?.contains(relatedTarget))) {
        hoverProps.onMouseLeave(event);
      }
    },
    [layerRef.current, hoverProps.onMouseLeave],
  );

  if (React.isValidElement(children)) {
    return React.cloneElement(children as React.ReactElement, {
      ...triggerProps,
      ...hoverProps,
      onMouseLeave,
    });
  }

  return (
    <span {...triggerProps} {...hoverProps} onMouseLeave={onMouseLeave}>
      {children}
    </span>
  );
};
