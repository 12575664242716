/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;
const ORIGINAL_COLOR = '#848484';

export const Reset = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => (
  <svg width={width} height={width} viewBox="0 0 20 20" fill="none">
    <path
      d="M5.05754 5.05625C6.32708 3.7875 8.06942 3 10.0044 3C13.8743 3 17 6.1325 17 10C17 13.8675 13.8743 17 10.0044 17C6.73859 17 4.01563 14.7688 3.2364 11.75H5.05754C5.77549 13.7887 7.7192 15.25 10.0044 15.25C12.9024 15.25 15.2577 12.8962 15.2577 10C15.2577 7.10375 12.9024 4.75 10.0044 4.75C8.55097 4.75 7.25516 5.35375 6.30957 6.3075L9.12883 9.125H3V3L5.05754 5.05625Z"
      fill={color}
    />
  </svg>
);
