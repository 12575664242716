import { Linesheet } from '@typings';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getIsSeller } from '../../../ducks';
import { useLinesheetTemplates } from '../../../services/hooks/linesheet/useLinesheetTemplates';
import { isDefined } from '../../../utils/is';
import { Option } from '../../various/Fields/Select';
import { Switch } from '../../various/Fields/Switch';
import { FieldDescription, FieldLabel, FieldWrapper, FormGrid, FormGridPlaceholder, SelectField } from '../../various/Form';
import { MaxAttributeWarning, ProductAttributesSortList } from '../../various/ProductAttributesSortList';
import { ShowMoreBox } from '../../various/ShowMoreBox/ShowMoreBox';

import styles from './DownloadLinesheet.module.scss';

interface Props {
  selectedTemplate?: Linesheet.TemplateData;
}

export const DownloadLinesheetOptions = ({ selectedTemplate }: Props) => {
  const { t } = useTranslation(['linesheets', 'common']);
  const isSeller = useSelector(getIsSeller);

  const { data } = useLinesheetTemplates();

  const { setValue, register, getValues } = useFormContext<Linesheet.OrderFormData>();
  const sortBy = useWatch<Linesheet.OrderFormData, 'sortBy'>({ name: 'sortBy' });
  const showPrice = useWatch<Linesheet.OrderFormData, 'showCustomerPrice'>({ name: 'showCustomerPrice' });
  const { field: attributesField } = useController<Linesheet.OrderFormData, 'attributes'>({ name: 'attributes' });

  const defaultSortBy = data?.dictionary.sortBy[0]?.key;
  const shouldDisableThenSortBy = sortBy === defaultSortBy;

  const handleSortByChange = (value: string) => {
    if (value === defaultSortBy || value === getValues('thenSortBy')) {
      setValue('thenSortBy', 'none');
    }
  };

  const showCustomerPriceHint =
    isSeller ?
      `${t('linesheets:show_buyer_price.hint')} ${t('linesheets:show_buyer_price.hint_seller')}`
    : t('linesheets:show_buyer_price.hint');

  return (
    <div>
      <ShowMoreBox
        title={t('linesheets:show_additional_options')}
        hint={isSeller ? t('linesheets:more_fields_order_seller') : t('linesheets:more_fields_order_buyer')}
      >
        <FormGrid>
          <FormGrid cols={2} rowGap="small">
            <FieldLabel label={t('linesheets:show_buyer_price.label')} className={styles.switchField}>
              <Switch size="large" checked={showPrice} describedById="show-product-price-description" {...register('showCustomerPrice')} />
            </FieldLabel>
            <FieldDescription id="show-product-price-description">{showCustomerPriceHint}</FieldDescription>
          </FormGrid>
          {isSeller && (
            <FieldWrapper as="span" label={t('linesheets:product_attributes')}>
              <ProductAttributesSortList
                attributeIds={attributesField.value}
                allAttributes={data?.dictionary.attributes ?? []}
                onChange={attributesField.onChange}
              />
              {isDefined(selectedTemplate) && (
                <MaxAttributeWarning
                  layoutType={selectedTemplate.layoutType}
                  linesheetName={selectedTemplate.name}
                  numberOfItems={attributesField.value?.length ?? 0}
                />
              )}
            </FieldWrapper>
          )}
          <FormGrid cols={2}>
            <SelectField name="groupBy" label={t('linesheets:choose_group_by.label')} placeholder={t('common:none')}>
              {data?.dictionary.groupBy.map(({ key, value }) => (
                <Option key={key} value={key}>
                  {value}
                </Option>
              ))}
            </SelectField>
            <FormGridPlaceholder />
            <SelectField
              name="sortBy"
              label={t('linesheets:choose_sort_by.label')}
              placeholder={t('common:none')}
              onChange={handleSortByChange}
            >
              {data?.dictionary.sortBy.map(({ key, value }) => (
                <Option key={key} value={key}>
                  {value}
                </Option>
              ))}
            </SelectField>
            <SelectField
              name="thenSortBy"
              dataTestId="thenSortBySelect"
              isDisabled={shouldDisableThenSortBy}
              label={t('linesheets:then_sort_by')}
              placeholder={t('common:none')}
            >
              <Option key="none" value="none">
                {t('common:none')}
              </Option>
              {data?.dictionary.thenSortBy
                .filter(option => option.key !== sortBy)
                .map(({ key, value }) => (
                  <Option key={key} value={key}>
                    {value}
                  </Option>
                ))}
            </SelectField>
          </FormGrid>
        </FormGrid>
      </ShowMoreBox>
    </div>
  );
};
