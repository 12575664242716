import { Order } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getFailedProducts, getStockTypeLabels, showModal } from '../../../ducks';
import { isDefined, isNull } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { ProductsEtaContext } from '../../products/ProductsEta';
import { Alert } from '../../various/Alert';
import { AlertAction } from '../../various/Alert/AlertAction';
import { CancelledProductsAlert } from '../CancelledProductsAlert';
import { ExpiredDelwinAlert } from '../ExpiredDelwinAlert';
import { MissingProductsModal } from '../MissingProductsModal';

import styles from './OrderProducts.module.scss';

interface Props {
  orderDetails: Order.Single;
  isMixedAvailabilityAlertVisible: boolean;
}

export const OrderProductsAlerts = ({ orderDetails, isMixedAvailabilityAlertVisible }: Props) => {
  const { t } = useTranslation(['common', 'products']);
  const dispatch = useDispatch();

  const failedProductsData = useSelector(getFailedProducts);
  const stockTypeLabels = useSelector(getStockTypeLabels);

  const { hasVariantsWithEta } = React.useContext(ProductsEtaContext);

  const showMissingProductsDetails = React.useCallback(() => {
    dispatch(showModal('MissingProductsModal'));
  }, [dispatch]);

  const cancelledProducts = orderDetails.order.products.filter(product => product.isCancelled);
  const hasCancelledProducts = !isEmpty(cancelledProducts);
  const shouldShowMissingProductsAlert =
    !isNull(failedProductsData) && isDefined(failedProductsData.failedProducts) && !isEmpty(failedProductsData.failedProducts);

  return (
    <div className={styles.alertContainer}>
      {shouldShowMissingProductsAlert && (
        <Alert
          type="warning"
          className={styles.alert}
          message={
            <>
              {t('products:missing_products')} <AlertAction onClick={showMissingProductsDetails}>{t('common:show_details')}</AlertAction>
              <MissingProductsModal failedProductsData={failedProductsData} />
            </>
          }
        />
      )}
      <ExpiredDelwinAlert order={orderDetails.order} className={styles.alert} />
      {hasCancelledProducts && <CancelledProductsAlert className={styles.alert} orderProducts={cancelledProducts} />}
      {hasVariantsWithEta && <Alert type="info" message={t('products:variants_availability_info')} className={styles.alert} />}
      {isMixedAvailabilityAlertVisible && (
        <Alert message={t('products:mixed_availability', stockTypeLabels)} type="info" className={styles.alert} />
      )}
    </div>
  );
};
