import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { Cms } from '@typings';
import {
  $createParagraphNode,
  $getRoot,
  $isParagraphNode,
  $setSelection,
  BLUR_COMMAND,
  COMMAND_PRIORITY_EDITOR,
  COMMAND_PRIORITY_HIGH,
  CommandListenerPriority,
  FOCUS_COMMAND,
  KEY_ESCAPE_COMMAND,
  KEY_TAB_COMMAND,
} from 'lexical';
import { CommandListener, LexicalCommand } from 'lexical/LexicalEditor';
import React from 'react';

import { ContentBlockContext } from '../context/ContentBlockContext';

interface Props {
  onFocus: () => void;
  content: string;
  onTab: (event: React.KeyboardEvent<HTMLDivElement>) => boolean;
  contentType: Cms.ContentPartType;
}

export const ContentEditorPlugin = ({ onFocus, content, onTab, contentType }: Props) => {
  const [editor] = useLexicalComposerContext();
  const { handleUpdateText } = React.useContext(ContentBlockContext);

  const getGeneratedHtmlString = React.useCallback(() => {
    const htmlString = $generateHtmlFromNodes(editor, null);

    return contentType === 'button' ? htmlString.replaceAll(/<p>|<\/p>/g, '') : htmlString;
  }, [contentType, editor]);

  const updateBlockText = React.useCallback(() => {
    const newContent = getGeneratedHtmlString();
    handleUpdateText(newContent);

    return true;
  }, [getGeneratedHtmlString, handleUpdateText]);

  const handleFocus = React.useCallback(() => {
    onFocus();

    return true;
  }, [onFocus]);

  const handleEscape = React.useCallback(() => {
    editor.blur();

    return true;
  }, [editor]);

  const updateRootContent = React.useCallback(() => {
    editor.update(() => {
      const htmlString = getGeneratedHtmlString();
      const shouldUpdate = htmlString !== content;

      if (!shouldUpdate) {
        return;
      }

      $setSelection(null);
      const parser = new DOMParser();
      const dom = parser.parseFromString(content, 'text/html');
      const nodes = $generateNodesFromDOM(editor, dom);
      const root = $getRoot();
      const childrenCount = root.getChildren().length;

      // Wrap nodes without paragraph parent inside paragraph
      if (!$isParagraphNode(nodes[0])) {
        const paragraphNode = $createParagraphNode();
        paragraphNode.splice(0, 1, nodes);
        root.splice(0, childrenCount, [paragraphNode]);

        return;
      }

      root.splice(0, childrenCount, nodes);
    });
  }, [content, editor, getGeneratedHtmlString]);

  React.useEffect(() => {
    updateRootContent();
  }, [updateRootContent]);

  useRegisterCommand(BLUR_COMMAND, updateBlockText);
  useRegisterCommand(KEY_ESCAPE_COMMAND, handleEscape);
  useRegisterCommand(KEY_TAB_COMMAND, onTab, COMMAND_PRIORITY_HIGH);
  useRegisterCommand(FOCUS_COMMAND, handleFocus);

  return null;
};

const useRegisterCommand = (command: LexicalCommand<unknown>, listener: CommandListener<unknown>, priority?: CommandListenerPriority) => {
  const [editor] = useLexicalComposerContext();

  React.useEffect(() => {
    return editor.registerCommand(command, listener, priority ?? COMMAND_PRIORITY_EDITOR);
  }, [command, editor, listener, priority]);
};
