import { createSelector } from 'reselect';

import { Country } from '../../../../typings';

export const getCountries = (state: Store) => state.countries;

export const getCountryByCountryCode = (countryCode: string) =>
  createSelector(
    getCountries,
    countries => countries[countryCode] || ({ country: '', name: countryCode, states: undefined } satisfies Country),
  );
