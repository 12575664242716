import { PopoverMenuItems } from '@typings';
import cx from 'classnames';
import React from 'react';
import { DisappearType, TriggerProps as TriggerPropsType, useLayer } from 'react-laag';

import { isDefined } from '../../../utils/is';
import Icon, { IconType } from '../Icon';
import { Tooltip } from '../Tooltip';

import { MenuContainer } from './MenuElements/MenuContainer';
import { MenuItems } from './MenuElements/MenuItems';
import styles from './PopoverMenu.module.scss';

interface Props {
  items: PopoverMenuItems.AnyMenuItem[];
  size?: 'small';
  groupLabel?: string;
  classNameRegular?: string;
  classNameExpanded?: string;
  containerClassName?: string;
  isDisabled?: boolean;
  showTooltip?: boolean;
  overflowContainer?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  testId?: string;
  targetOffset?: number;
  name: string;
  icon?: IconType;
  children?: React.ReactNode | ((props: TriggerProps) => JSX.Element);
}

export interface TriggerProps extends TriggerPropsType {
  onClick: () => void;
}

export const PopoverMenu = (props: Props) => {
  const {
    items,
    size,
    name,
    classNameRegular,
    classNameExpanded,
    containerClassName,
    icon = IconType.MoreVertical,
    isDisabled = false,
    showTooltip = false,
    overflowContainer = false,
    onOpen,
    onClose,
    testId,
    targetOffset,
    children,
    groupLabel,
  } = props;

  const [isOpen, setIsOpen] = React.useState(false);

  const handleToggleMenu = React.useCallback(() => {
    isOpen ? onClose?.() : onOpen?.();

    setIsOpen(opened => !opened);
  }, [setIsOpen, isOpen, onOpen, onClose]);

  const closeMenu = React.useCallback(() => {
    setIsOpen(false);
    onClose?.();
  }, [setIsOpen, onClose]);

  const handleDisappear = (type: DisappearType) => {
    if (type === 'full') {
      closeMenu();
    }
  };

  const { renderLayer, layerProps, triggerProps } = useLayer({
    auto: true,
    isOpen,
    onDisappear: handleDisappear,
    onOutsideClick: closeMenu,
    overflowContainer,
    placement: 'bottom-end',
    possiblePlacements: ['bottom-start', 'bottom-end', 'top-start', 'top-end'],
    triggerOffset: targetOffset,
  });

  const buttonClassNames = cx(styles.triggerMenu, classNameRegular, isDefined(classNameExpanded) && { [classNameExpanded]: isOpen });

  const tooltipContent = showTooltip && !isDisabled ? name : undefined;

  const tooltipVisibility = isOpen ? false : undefined;

  const sizeClassNames = cx({ [styles.sizeSmall]: size === 'small' });
  const containerClassNames = cx(styles.menuContainer, containerClassName, sizeClassNames);

  return (
    <>
      <Tooltip content={tooltipContent} visible={tooltipVisibility} isInteractive={false} disableOnTouchScreen={!isDisabled}>
        <div className={styles.triggerMenuWrapper}>
          {typeof children === 'function' ?
            children({ onClick: handleToggleMenu, ...triggerProps })
          : <button
              className={buttonClassNames}
              onClick={handleToggleMenu}
              disabled={isDisabled}
              data-testid={testId}
              aria-label={name}
              {...triggerProps}
          >
              {children ?? <Icon type={icon} />}
            </button>
          }
        </div>
      </Tooltip>
      {isOpen &&
        renderLayer(
          <MenuContainer layerProps={layerProps} className={containerClassNames} groupLabel={groupLabel}>
            <MenuItems items={items} closeMenu={closeMenu} containerClassName={cx(containerClassName, sizeClassNames)} />
          </MenuContainer>,
        )}
    </>
  );
};
