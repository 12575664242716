import { Cms } from '@typings';
import { nanoid } from 'nanoid';
import { omit } from 'ramda';

export const assignIdsToParts = (parts: Cms.ContentBlockPart[]) => {
  const partsWithIds = parts.map(part => ({
    ...part,
    id: nanoid(),
  }));

  const partsOrder = partsWithIds.map(part => part.id);

  const partsRecords = partsWithIds.reduce((acc, cur) => {
    return {
      ...acc,
      [cur.id]: omit(['id'], cur),
    };
  }, {});

  return {
    parts: partsRecords,
    partsOrder,
  };
};
