/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 7;
const ORIGINAL_HEIGHT = 5;
const ORIGINAL_COLOR = '#4A4A4C';
const PROPORTION = ORIGINAL_HEIGHT / ORIGINAL_WIDTH;

export const ArrowSmallUp = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => {
  const strokeWidth = ORIGINAL_WIDTH / width;

  return (
    <svg width={width} height={width * PROPORTION} version="1.1" viewBox="0 0 7 5">
      <g stroke="none" strokeWidth={strokeWidth} fill="none" fillRule="evenodd">
        <polygon
          fill={color}
          transform="translate(3.500000, 2.500000) scale(1, 1) translate(-3.500000, -2.500000) "
          points="3.5 0 7 5 0 5"
        />
      </g>
    </svg>
  );
};
