import { Product } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { getVariantsThumbnailsLabel } from '../../../../ducks';
import { useIsOverflowing } from '../../../../utils/hooks/useIsOverflowing';
import { isDefined } from '../../../../utils/is';
import { isEmpty } from '../../../../utils/isEmpty';
import { MaybeTooltip } from '../../../various/MaybeTooltip';

import styles from './VariantLabel.module.scss';

interface Props {
  size?: 'small' | 'regular';
  variant: Product.Standard;
}

export const VariantLabel = React.memo(({ size = 'regular', variant }: Props) => {
  const variantsThumbnailsLabel = useSelector(getVariantsThumbnailsLabel);

  const ref = React.useRef<HTMLDivElement>(null);
  const isOverflowing = useIsOverflowing(ref, { widthOnly: true });

  if (variantsThumbnailsLabel === 'none') {
    return;
  }

  const label = variant[variantsThumbnailsLabel];

  if (!isDefined(label) || isEmpty(label)) {
    return;
  }

  return (
    <MaybeTooltip content={isOverflowing ? label : undefined} disableOnTouchScreen>
      <div ref={ref} className={cx(styles.label, { [styles.sizeSmall]: size === 'small' })}>
        {label}
      </div>
    </MaybeTooltip>
  );
});
