import { Product, Variants } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { itemsToChart } from '../../../ducks/helpers';
import { Header, ListItem } from '../../various/List';
import { ItemsQuantityCell } from '../../various/List/ItemsQuantityCell';
import { ProductName } from '../../various/List/ProductName';

export type Data = Product.Standard & { shippedItems: Variants.Item[] };

export const useShipmentProductsModel = () => {
  const { t } = useTranslation(['common', 'products']);

  return React.useMemo(
    () =>
      new Map<Header, ListItem<Data>>()
        .set(t('products:product_name'), props => <ProductName {...props} />)
        .set(t('products:variant_name'), ({ variantName }) => <td>{variantName}</td>)
        .set(t('common:art_no', { artNo: '' }), ({ sku }) => <td>{sku}</td>)
        .set(t('common:quantity'), ({ itemTable, shippedItems }) => {
          const quantity = shippedItems.reduce((sum, item) => sum + item.quantity, 0);
          const table = itemsToChart(shippedItems, itemTable);

          return <ItemsQuantityCell quantity={quantity} table={table} />;
        }),
    [t],
  );
};
