import { Cms, Id } from '@typings';
import { pathOr, range } from 'ramda';
import React from 'react';

import { MIN_BLOCK_HEIGHT, PRODUCT_PADDING } from '../../../constants/cms';
import { getHasProductBlocksPerRow } from '../../../logic/cms/structuralBlocks';
import { getIsProductBlock } from '../../../logic/pages';
import { EditorUIContext } from '../../cms/context/EditorUIContext';
import { SizeContext } from '../../cms/context/SizeContext';

interface Context {
  offset: number;
  group: Cms.ResizableGroup;
  setOffset: (offset: number) => void;
  resize: (block: Cms.ContentBlock, height: number) => number;
  isResizing: boolean;
  setIsResizing: (isResizing: boolean) => void;
}

const InitialContext = {
  group: {},
  isResizing: false,
  offset: 0,
  resize: (_: Cms.ContentBlock, __: number) => 0,
  setIsResizing: (_: boolean) => null,
  setOffset: (_: number) => null,
};

export const GroupResizerContext = React.createContext<Context>(InitialContext);

interface Props {
  blocks: Cms.AnyBlock[];
  resizableBlocksIds?: Set<string>;
}

export const GroupResizeContextProvider = ({ blocks, children, resizableBlocksIds = new Set() }: React.WithChildren<Props>) => {
  const { screenType } = React.useContext(EditorUIContext);
  const { rowHeight } = React.useContext(SizeContext);
  const [offset, setOffset] = React.useState(0);
  const [group, setGroup] = React.useState<Cms.ResizableGroup>({});
  const [isResizing, setIsResizing] = React.useState<boolean>(false);

  const hasProductBlocksPerRow = React.useMemo(() => getHasProductBlocksPerRow(blocks, screenType), [blocks, screenType]);

  const calculateMinHeight = React.useCallback(
    (currentBlock: Cms.ContentBlock) => {
      const { startY, spanY } = currentBlock.position[screenType];

      return range(startY, startY + spanY).reduce((acc, row) => {
        const minHeight = hasProductBlocksPerRow[row] ? rowHeight : MIN_BLOCK_HEIGHT;

        if (row !== startY) {
          return acc + PRODUCT_PADDING + minHeight;
        }

        return acc + minHeight;
      }, 0);
    },
    [hasProductBlocksPerRow, screenType, rowHeight],
  );

  React.useEffect(() => {
    setGroup(
      blocks.reduce<Cms.ResizableGroup>((acc, block) => {
        if (getIsProductBlock(block)) {
          return acc;
        }

        const minHeight = calculateMinHeight(block);

        return {
          ...acc,
          [block.id]: {
            blockId: block.id,
            minHeight,
            updatePath: ['height', screenType],
            value: minHeight,
          },
        };
      }, {}),
    );
  }, [screenType, calculateMinHeight, blocks]);

  const updateBlock = (blockId: Id, blockData: Partial<Cms.ResizableBlock>) => {
    setGroup(
      // eslint-disable-next-line functional/immutable-data
      Object.assign(group, {
        [blockId]: Object.assign({}, group[blockId], { blockId, ...blockData }),
      }),
    );
  };

  const getOffsetMultiplier = (block: Cms.ContentBlock) => {
    const { startY, spanY } = block.position[screenType];

    const spanYContentBlock = range(startY, startY + spanY).reduce((acc, row) => (hasProductBlocksPerRow[row] ? acc : acc + 1), 0);
    const spanYContentTotal = Object.values(hasProductBlocksPerRow).reduce((acc, hasProducts) => acc + (hasProducts ? 0 : 1), 0);

    if (spanYContentTotal === 0) {
      return 1;
    }

    return spanYContentBlock / spanYContentTotal;
  };

  const resize = (block: Cms.ContentBlock, height: number) => {
    if (!resizableBlocksIds.has(block.id)) {
      return height;
    }

    if (offset === 0) {
      updateBlock(block.id, { value: height });

      return height;
    }

    const minHeight = pathOr(MIN_BLOCK_HEIGHT, [block.id, 'minHeight'], group);
    const resultHeight = Math.max(height + offset * getOffsetMultiplier(block), minHeight);
    updateBlock(block.id, { value: resultHeight });

    return resultHeight;
  };

  return (
    <GroupResizerContext.Provider value={{ group, isResizing, offset, resize, setIsResizing, setOffset }}>
      {children}
    </GroupResizerContext.Provider>
  );
};
