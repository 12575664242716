import React from 'react';

interface Context {
  isHoverToolsHidden: boolean;
  setIsHoverToolsHidden: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialContext: Context = {
  isHoverToolsHidden: false,
  setIsHoverToolsHidden: () => null,
};

export const HoverToolsContext = React.createContext<Context>(initialContext);

export const HoverToolsContextProvider = ({ children }: React.WithChildren) => {
  const [isHoverToolsHidden, setIsHoverToolsHidden] = React.useState(false);
  const value = React.useMemo(
    () => ({
      isHoverToolsHidden,
      setIsHoverToolsHidden,
    }),
    [isHoverToolsHidden],
  );

  return <HoverToolsContext.Provider value={value}>{children}</HoverToolsContext.Provider>;
};
