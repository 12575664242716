import { PillOptions } from '@typings';
import { without } from 'ramda';
import React from 'react';

import { Pill } from './Pill';
import styles from './Pills.module.scss';

interface Props {
  value: string[];
  options: PillOptions[];
  describedById?: string;
  labelledById?: string;
  onBlur?: () => void;
  onChange: (value: string[]) => void;
}

export const Pills = ({ value, options, describedById, labelledById, onBlur, onChange }: Props) => {
  const handleChange = React.useCallback(
    (isChecked: boolean, checkedValue: string) => {
      onChange(isChecked ? [...value, checkedValue] : without([checkedValue], value));
    },
    [onChange, value],
  );

  return (
    <div role="group" aria-describedby={describedById} aria-labelledby={labelledById} className={styles.wrapper}>
      {options.map(option => (
        <Pill
          key={option.value}
          isChecked={value.includes(option.value)}
          disabled={option.disabled}
          value={option.value}
          onBlur={onBlur}
          onChange={handleChange}
        >
          {option.label}
        </Pill>
      ))}
    </div>
  );
};
