import i18next from 'i18next';
import { replace } from 'redux-first-history';
import { combineEpics } from 'redux-observable';
import { from, of } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { addToast } from '../../components/various/Toasts';
import { getUserAccess } from '../../ducks';
import {
  fetchHomePageByBuyerFailure,
  fetchHomePageByBuyerRequest,
  fetchHomePageByBuyerSuccess,
  fetchHomepageFailure,
  fetchHomepageRequest,
  fetchHomepageSuccess,
  purgeHomepageByBuyer,
  setHomePageFailure,
  setHomePageRequest,
  setHomePageSuccess,
} from '../../ducks/cms';
import { compiledPaths } from '../../paths';
import { mapResponse } from '../../utils/operators/mapResponse';

const fetchHomepageEpic: AppEpic = (action$, _, { homepage }) =>
  action$.pipe(
    filter(isActionOf(fetchHomepageRequest)),
    mergeMap(async () => homepage.getSlug()),
    mapResponse(
      res => fetchHomepageSuccess(res.data.slug),
      () => {
        addToast(i18next.t('cms:fetch_homepage_fail'));

        return fetchHomepageFailure();
      },
    ),
  );

const setHomepageEpic: AppEpic = (action$, _, { homepage }) =>
  action$.pipe(
    filter(isActionOf(setHomePageRequest)),
    mergeMap(action =>
      from(homepage.setSlug(action.payload)).pipe(
        mapResponse(
          () => {
            addToast(i18next.t('settings:homepage_updated'));

            return [setHomePageSuccess(), fetchHomepageSuccess(action.payload), purgeHomepageByBuyer()];
          },
          () => {
            addToast(i18next.t('settings:navigation_update_fail'));

            return setHomePageFailure();
          },
        ),
      ),
    ),
  );

const fetchHomepageByBuyerEpic: AppEpic = (action$, state$, { homepage }) =>
  action$.pipe(
    filter(isActionOf(fetchHomePageByBuyerRequest)),
    map(action => action.payload),
    mergeMap(buyerId =>
      of(getUserAccess(state$.value) === 'buyer' ? undefined : buyerId).pipe(
        mergeMap(async id => homepage.getSlugForBuyer(id)),
        mapResponse(
          response => fetchHomePageByBuyerSuccess({ buyerId, slug: response.data.slug }),
          () => [fetchHomePageByBuyerFailure(), replace({ pathname: compiledPaths.PRODUCTS_ORDER({}) })],
        ),
      ),
    ),
  );

export const homepageEpic = combineEpics(fetchHomepageEpic, setHomepageEpic, fetchHomepageByBuyerEpic);
