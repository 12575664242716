import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { compiledPaths } from '../../../paths';
import { FailedCheckoutContent } from '../checkoutFailure/FailedCheckoutContent';
import { Info } from '../checkoutFailure/Info';

export const CheckoutPaymentFailure = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation(['checkoutMessages']);

  return (
    <FailedCheckoutContent title={t('checkoutMessages:payment_failure.header')} checkoutUrl={compiledPaths.CHECKOUT_ORDER({ id })}>
      <Info header={t('checkoutMessages:payment_failure.details')} />
    </FailedCheckoutContent>
  );
};
