import cx from 'classnames';
import React from 'react';

import styles from './Checkbox.module.scss';

interface Props {
  id?: string;
  name?: string;
  value?: string;
  checked?: boolean;
  disabled?: boolean;
  indeterminate?: boolean;
  isRequired?: boolean;
  isInvalid?: boolean;
  className?: string;
  inputClassName?: string;
  dataTestId?: string;
  errorMessageId?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
}

export const Checkbox = React.forwardRef<HTMLInputElement, React.WithOptionalChildren<Props>>(
  (
    {
      children,
      errorMessageId,
      indeterminate,
      onChange,
      checked = false,
      isInvalid = false,
      isRequired = false,
      inputClassName,
      disabled,
      className,
      dataTestId,
      ...rest
    },
    ref,
  ) => {
    const [isChecked, setIsChecked] = React.useState(checked);

    React.useEffect(() => {
      setIsChecked(checked);
    }, [checked]);

    const handleChange = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(event.target.checked);
        onChange?.(event);
      },
      [onChange],
    );

    const labelClassNames = cx(styles.wrapper, className, {
      [styles.disabled]: disabled,
      [styles.indeterminate]: indeterminate,
      [styles.invalid]: isInvalid,
    });

    const inputClassNames = cx(styles.input, inputClassName);

    return (
      <label className={labelClassNames} data-testid={dataTestId}>
        <input
          ref={ref}
          type="checkbox"
          className={inputClassNames}
          checked={isChecked}
          required={isRequired}
          disabled={disabled}
          aria-invalid={isInvalid}
          aria-errormessage={errorMessageId}
          onChange={handleChange}
          {...rest}
        />
        {children}
      </label>
    );
  },
);
