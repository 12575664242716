import { useTranslation } from 'react-i18next';

import { getSafeInnerHTML } from '../../../utils/getSafeInnerHtml';
import { useModalVisibility } from '../../../utils/hooks';
import { ModalShell } from '../../various/ModalShell';

import styles from './CheckoutTermsModal.module.scss';

interface Props {
  terms: string;
}

export const CheckoutTermsModal = ({ terms }: Props) => {
  const { t } = useTranslation(['checkout']);

  const { isModalVisible, hideModal } = useModalVisibility('CheckoutTermsModal');

  return (
    <ModalShell isOpen={isModalVisible} onClose={hideModal} title={t('checkout:terms_and_conditions')}>
      <div className={styles.innerHTML} dangerouslySetInnerHTML={getSafeInnerHTML(terms)} />
    </ModalShell>
  );
};
