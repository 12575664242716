import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getIsSeller } from '../../../../ducks';
import { FormButtons } from '../../../various/Form';
import { Button } from '../../../various/NewButton';

import styles from './NoLinesheetsMessage.module.scss';

interface Props {
  onOk?: () => void;
}

export const NoLinesheetsMessage = ({ onOk }: Props) => {
  const { t } = useTranslation(['linesheets', 'common']);
  const isSeller = useSelector(getIsSeller);

  return (
    <div className={styles.container}>
      <span className={styles.message}>
        {isSeller ? t('linesheets:no_linesheet_templates_seller') : t('linesheets:no_linesheet_templates_buyer')}
      </span>
      <FormButtons showDivider>
        <Button onClick={onOk}>{t('common:ok')}</Button>
      </FormButtons>
    </div>
  );
};
