import { Cms, CmsBlocks } from '@typings';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getHasMissingProducts, getSectionIdByGroupId } from '../../../../ducks';
import { PopoverWarning } from '../../../cms/PopoverWarning';

export const BlockWarnings = ({ parameters, isManualLayoutEditing }: CmsBlocks.BlockProps<Cms.ProductBlock>) => {
  const { t } = useTranslation(['cms']);
  const { products } = parameters.settings;

  const sectionId = useSelector(getSectionIdByGroupId(parameters.groupId));
  const hasMissingProducts = useSelector(getHasMissingProducts(products, sectionId ?? null));

  if (isManualLayoutEditing || !hasMissingProducts) {
    return null;
  }

  const warnings = [
    {
      message: t('cms:missing_products_warning'),
    },
  ];

  return <PopoverWarning warnings={warnings} />;
};
