import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import DefaultLoader from '../components/various/loaders/DefaultLoader';
import {
  createSelection,
  getAreSelectionsLoading,
  getDefaultStockType,
  getHasLoadedInitialSelections,
  getIsLoadingAuthoriseAccess,
  getIsOrderSettingsFormDisabled,
  getOpenOrClosedOrder,
  getOpenSelections,
  getUserAccess,
  redirectedUserToLatestSelection,
} from '../ducks';
import { paths } from '../paths';
import { isDefined } from '../utils/is';
import { isEmpty } from '../utils/isEmpty';

export const GenericLinkHandler = <T extends object>(TargetComponent: React.ComponentType<T>) => {
  function GenericLinkHandlerComponent(props: T) {
    const dispatch = useDispatch();
    const { pathname, search } = useLocation();
    const { id } = useParams<{ id?: string }>();
    const openSelections = useSelector(getOpenSelections);
    const isLoadingSelections = useSelector(getAreSelectionsLoading);
    const currentOrderData = useSelector(getOpenOrClosedOrder);
    const isBuyer = useSelector(getUserAccess) === 'buyer';
    const isCreatingSelection = useSelector(getIsOrderSettingsFormDisabled);
    const isAuthoriseAccessLoading = useSelector(getIsLoadingAuthoriseAccess);
    const initialStockType = useSelector(getDefaultStockType);
    const hasLoadedInitialSelections = useSelector(getHasLoadedInitialSelections);

    const currentOrderId = currentOrderData.order;

    const hasActiveOrder = isDefined(currentOrderId) && ['open', 'checkoutRequested'].includes(currentOrderData.status);
    const hasOpenSelections = !isEmpty(openSelections);
    const trimmedPathname = pathname.replace(/\/+$/, '');
    // prettier-ignore
    const isBuyerWithoutOrder =
      isBuyer &&
      !isDefined(currentOrderId) &&
      !hasOpenSelections &&
      !isLoadingSelections &&
      !isCreatingSelection;

    const hasIdInUrl = isDefined(id);
    const shouldShowLoader = isAuthoriseAccessLoading || isLoadingSelections || isBuyerWithoutOrder || isCreatingSelection;
    const shouldRedirectUserToLatestSelection = hasOpenSelections && !isDefined(currentOrderId) && !hasIdInUrl && !shouldShowLoader;

    React.useEffect(() => {
      if (shouldRedirectUserToLatestSelection) {
        dispatch(redirectedUserToLatestSelection());
      }
    }, [dispatch, shouldRedirectUserToLatestSelection]);

    React.useEffect(() => {
      if (isBuyerWithoutOrder && !hasIdInUrl && hasLoadedInitialSelections) {
        dispatch(createSelection({ initialStockType, name: '' }));
      }
    }, [dispatch, isBuyerWithoutOrder, hasIdInUrl, initialStockType, hasLoadedInitialSelections]);

    if (hasIdInUrl) {
      return <TargetComponent {...props} />;
    }

    if (shouldShowLoader) {
      return <DefaultLoader />;
    }

    const urlPath =
      hasActiveOrder ? `${trimmedPathname}/${currentOrderId}${search}`
      : hasOpenSelections ? `${trimmedPathname}/${openSelections[0]?.order}${search}`
      : paths.SELECTIONS;

    return <Navigate to={urlPath} replace />;
  }

  return GenericLinkHandlerComponent;
};
