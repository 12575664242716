import { Switcher } from '@typings';
import cx from 'classnames';
import React from 'react';
import { PlacementType } from 'react-laag/dist/PlacementType';

import styles from './ContentSwitcher.module.scss';
import { Tab } from './Tab';

interface Props<T, OnChangeType> {
  tabs: Switcher.Tab<T>[];
  groupName: string;
  selectedValue: T;
  onChange: OnChangeType;
  variant?: Switcher.Variant;
  size?: Switcher.Size;
  keepEvenWidth?: boolean;
  showTooltip?: boolean;
  tooltipPlacement?: PlacementType;
  isDisabled?: boolean;
  className?: string;
  dataTestId?: string;
}

export const ContentSwitcher = <T extends string | undefined, OnChangeType extends (selectedValue: T) => void>({
  tabs,
  groupName,
  selectedValue,
  onChange,
  variant = 'primary',
  size = 'medium',
  keepEvenWidth = true,
  showTooltip,
  tooltipPlacement,
  isDisabled = false,
  className,
  dataTestId,
}: Props<T, OnChangeType>) => {
  const handleTabChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value as T);
    },
    [onChange],
  );

  return (
    <div
      role="tablist"
      aria-label={groupName}
      data-testid={dataTestId}
      className={cx(
        styles.contentSwitcher,
        {
          [styles.primary]: variant === 'primary',
          [styles.secondary]: variant === 'secondary',
          [styles.small]: size === 'small',
          [styles.medium]: size === 'medium',
          [styles.large]: size === 'large',
          [styles.evenWidth]: keepEvenWidth,
          [styles.showTooltip]: showTooltip,
          [styles.disabled]: isDisabled,
        },
        className,
      )}
    >
      {tabs.map(tab => {
        return (
          <Tab
            key={tab.value}
            tab={tab}
            groupName={groupName}
            isSelected={selectedValue === tab.value}
            onChange={handleTabChange}
            showTooltip={showTooltip}
            tooltipPlacement={tooltipPlacement}
            isDisabled={isDisabled}
          />
        );
      })}
    </div>
  );
};
