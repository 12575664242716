import { Cms, Id, Translations } from '@typings';
import { without } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getHasUnsavedLayoutChanges, getIsLanguagePublishedWithoutChanges } from '../../../../../ducks';
import { getIsAnyLanguagePublished, getIsPublished } from '../../../../../logic/pages';
import { usePagePublicationActions } from '../../../../../utils/hooks';
import { isDefined } from '../../../../../utils/is';
import { isEmpty } from '../../../../../utils/isEmpty';
import Button from '../../../../various/Button';
import { EditorLanguageContext } from '../../../context/EditorLanguageContext';

import styles from './MultiLanguageButton.module.scss';
import { PublishLanguageItem } from './PublishLanguageItem';

interface Props {
  closeMenu: (force?: boolean) => void;
  pageLanguages: Cms.PageLanguage[];
  pageId: Id;
}

export const PublishLanguagesPopup = ({ closeMenu, pageLanguages, pageId }: Props) => {
  const { t } = useTranslation(['common', 'cms']);
  const { defaultLanguage } = React.useContext(EditorLanguageContext);
  const isDefaultLanguagePublishedWithoutChanges = useSelector(getIsLanguagePublishedWithoutChanges(defaultLanguage));
  const hasUnsavedLayoutChanges = useSelector(getHasUnsavedLayoutChanges);
  const [checkedLanguages, setCheckedLanguages] = React.useState<Translations.SupportedLanguagesCodes[]>([]);

  const isAnyLanguagePublished = getIsAnyLanguagePublished(pageLanguages);
  const isAnySelectedLanguagePublished = checkedLanguages.some(languageCode => {
    const languageDetails = pageLanguages.find(details => details.code === languageCode);

    return isDefined(languageDetails) && getIsPublished(languageDetails.status);
  });

  const isLayoutPublicationRequired = !isDefaultLanguagePublishedWithoutChanges || hasUnsavedLayoutChanges;
  const shouldRequireDefaultLanguage = isLayoutPublicationRequired && !isEmpty(without([defaultLanguage], checkedLanguages));

  const publishButtonName =
    (isEmpty(checkedLanguages) && isAnyLanguagePublished) || isAnySelectedLanguagePublished ? t('common:update') : t('cms:publish');

  const handleSelectLanguage = React.useCallback((language: Translations.SupportedLanguagesCodes, isChecked: boolean) => {
    setCheckedLanguages(current => {
      return isChecked ? [...current, language] : without([language], current);
    });
  }, []);

  const handleForceCloseMenu = React.useCallback(() => closeMenu(true), [closeMenu]);

  const { handlePublishPage } = usePagePublicationActions({
    closeMenu: handleForceCloseMenu,
    hasModifiedLayout: shouldRequireDefaultLanguage,
    languages: checkedLanguages,
    pageId,
  });

  React.useEffect(() => {
    if (shouldRequireDefaultLanguage) {
      return setCheckedLanguages(current => [...current, defaultLanguage]);
    }
  }, [defaultLanguage, shouldRequireDefaultLanguage]);

  return (
    <>
      <ul>
        {pageLanguages.map(({ code, status, lastModified, lastPublished }) => {
          const isChecked = checkedLanguages.includes(code);
          const isDisabled = code === defaultLanguage && shouldRequireDefaultLanguage;

          return (
            <PublishLanguageItem
              key={code}
              language={code}
              status={status}
              isDisabled={isDisabled}
              lastModified={lastModified}
              lastPublished={lastPublished}
              isChecked={isChecked}
              onChange={handleSelectLanguage}
              closeMenu={handleForceCloseMenu}
              pageId={pageId}
            />
          );
        })}
      </ul>
      <div className={styles.buttonsRow}>
        <Button variant={['button', 'transparent', 'small']} onClick={handleForceCloseMenu}>
          {t('common:cancel')}
        </Button>
        <Button variant={['button', 'blue', 'small']} onClick={handlePublishPage} disabled={isEmpty(checkedLanguages)}>
          {publishButtonName}
        </Button>
      </div>
    </>
  );
};
