import { isDefined } from './is';

const DIVISOR = 1024;

export const bytesToHumanReadable = (bytes: Maybe<number>) => {
  if (bytes === 0 || !isDefined(bytes)) {
    return '0 Bytes';
  }

  const units = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const index = Math.floor(Math.log(bytes) / Math.log(DIVISOR));
  const convertedValue = parseFloat((bytes / Math.pow(DIVISOR, index)).toFixed(2));

  return `${convertedValue} ${units[index]}`;
};
