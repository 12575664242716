import { Image } from '@typings';

import { isDefined } from '../../../../../../utils/is';
import { isEmpty } from '../../../../../../utils/isEmpty';

import styles from './BadgeFilterName.module.scss';

interface Props {
  name: string;
  image?: Image;
  imagePlacement?: 'after' | 'before';
}

export const BadgeFilterName = ({ name, image, imagePlacement }: Props) => {
  if (!isDefined(image)) {
    return <>{name}</>;
  }

  const badgeImage = <img src={image.url} width={image.width} height={image.height} className={styles.image} alt="" />;

  return (
    <div className={styles.name}>
      {imagePlacement === 'before' && badgeImage}
      {!isEmpty(name) && <span>{name}</span>}
      {imagePlacement === 'after' && badgeImage}
    </div>
  );
};
