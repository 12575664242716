import React from 'react';
import { useSearchParams } from 'react-router-dom';

const DEFAULT_PAGE = 1;
const PROPERTY = 'page';

export const useUrlPagination = (property = PROPERTY) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const propertyValue = Number(searchParams.get(property)) || DEFAULT_PAGE;

  const setPagination = React.useCallback(
    (newState: number) => {
      setSearchParams(previousParams => {
        const newParams = new URLSearchParams(previousParams);

        if (newState > DEFAULT_PAGE) {
          newParams.set(property, newState.toString());
        } else {
          newParams.delete(property);
        }

        return newParams;
      });
    },
    [property, setSearchParams],
  );

  return [propertyValue, setPagination] as const;
};
