import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { Cms } from '@typings';
import { $getSelection, $isRangeSelection, ElementNode, RangeSelection, TextNode } from 'lexical';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getSelectedNode } from '../../../../../../logic/cms/lexical';
import { isDefined } from '../../../../../../utils/is';
import { isEmpty } from '../../../../../../utils/isEmpty';
import { TextLinkModal } from '../../../../../cms/TextLink/TextLinkModal';

import { TextLinkMarker } from './TextLinkMarker';

export const TextLinkMarkerPlugin = () => {
  const [editor] = useLexicalComposerContext();
  const { t } = useTranslation(['cms']);
  const [isLink, setIsLink] = React.useState(false);
  const [linkUrl, setLinkUrl] = React.useState('');
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isNewTab, setIsNewTab] = React.useState(false);
  const [selection, setSelection] = React.useState<Nullable<RangeSelection>>(null);
  const noTextSelected = selection?.anchor.offset === selection?.focus.offset;

  const resetLinkSettings = React.useCallback(() => {
    setLinkUrl('');
    setIsNewTab(false);
  }, []);

  React.useEffect(() => {
    if (!isLink) {
      resetLinkSettings();
    }
  }, [isLink, resetLinkSettings]);

  const removeLink = React.useCallback(() => {
    resetLinkSettings();
    editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
  }, [editor, resetLinkSettings]);

  const updateLinkData = React.useCallback((node: TextNode | ElementNode) => {
    const parent = node.getParent();

    if (!$isLinkNode(parent) && !$isLinkNode(node)) {
      setIsLink(false);

      return;
    }

    setIsLink(true);
    if ($isLinkNode(parent)) {
      setLinkUrl(parent.getURL());
      setIsNewTab(parent.getTarget() === '_blank');
    }
  }, []);

  const updatePopup = React.useCallback(() => {
    editor.getEditorState().read(() => {
      const newSelection = $getSelection();

      if (!$isRangeSelection(newSelection)) {
        setSelection(null);

        return;
      }

      const node = getSelectedNode(newSelection);
      updateLinkData(node);
      setSelection(newSelection);
    });
  }, [editor, updateLinkData]);

  React.useEffect(() => {
    return editor.registerUpdateListener(() => {
      updatePopup();
    });
  }, [editor, updatePopup]);

  const hideModal = React.useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  const selectLinkBeforeInsert = React.useCallback(() => {
    editor.update(() => {
      if (!isDefined(selection)) {
        return;
      }

      const node = getSelectedNode(selection);

      if (noTextSelected) {
        node.select(0);

        return;
      }

      node.select(selection.anchor.offset, selection.focus.offset);
    });
  }, [editor, selection, noTextSelected]);

  const insertLink = React.useCallback(
    ({ newTab, url }: Cms.LinkSettings) => {
      selectLinkBeforeInsert();
      const payload = {
        // Attributes are removed when set to null
        rel: newTab ? 'noopener noreferrer' : null,
        target: newTab ? '_blank' : null,
        url,
      };

      editor.dispatchCommand(
        TOGGLE_LINK_COMMAND,
        payload as {
          url: string;
          target?: string;
          rel?: string;
        },
      );
    },
    [editor, selectLinkBeforeInsert],
  );

  const handleSubmit = React.useCallback(
    (settings: Cms.LinkSettings) => {
      isEmpty(settings.url) ? removeLink() : insertLink(settings);
      hideModal();
    },
    [hideModal, insertLink, removeLink],
  );

  const openModal = React.useCallback(() => {
    setIsModalOpen(true);
  }, [setIsModalOpen]);

  const addEditActionName = isEmpty(linkUrl) ? t('cms:add_link') : t('cms:change_link');
  const isLinkMarkerVisible = !noTextSelected || isLink;

  return (
    <>
      {isLinkMarkerVisible && <TextLinkMarker isLink={isLink} linkUrl={linkUrl} openModal={openModal} removeLink={removeLink} />}
      <TextLinkModal
        title={addEditActionName}
        isOpen={isModalOpen}
        onClose={hideModal}
        onSubmit={handleSubmit}
        defaultValues={{
          newTab: isNewTab,
          url: linkUrl,
        }}
      />
    </>
  );
};
