import React from 'react';

import { EditorUIContext } from '../../../components/cms/context/EditorUIContext';
import { SizeContext } from '../../../components/cms/context/SizeContext';
import { FULL_WIDTH_BLOCK_SPAN, TOTAL_HORIZONTAL_BLOCK_PADDING } from '../../../constants/cms';

export const useBlockWidth = (spanX: number) => {
  const { screenType } = React.useContext(EditorUIContext);
  const { currentSectionWidth } = React.useContext(SizeContext);

  const isFullBlock = spanX === FULL_WIDTH_BLOCK_SPAN;
  const canShowHalfBlocks = screenType === 'desktop';

  if (isFullBlock || !canShowHalfBlocks) {
    return currentSectionWidth - TOTAL_HORIZONTAL_BLOCK_PADDING;
  }

  return currentSectionWidth / 2 - TOTAL_HORIZONTAL_BLOCK_PADDING;
};
