import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { Linesheet } from '../../../../../typings';
import Icon, { IconType } from '../../../components/various/Icon';
import { addToast, dismissToast } from '../../../components/various/Toasts';
import { getDownloadLinesheetGeneratedEvent } from '../../../utils/analytics/events';
import { useAmplitude } from '../../../utils/hooks/useAmplitude';
import { isDefined } from '../../../utils/is';
import { generateLinesheet } from '../../documentsRepository';
import { downloadURI } from '../../download';
import { pushEventGTM } from '../../gtm';

const NOTIFICATION_KEY = 'generateLinesheetNotification';
const WARNING_KEY = 'generateLinesheetWarning';

export const useLinesheetDownload = () => {
  const { t } = useTranslation(['linesheets']);

  const { trackEvent } = useAmplitude();

  const mutationFn = async (values: Requests.Linesheet) => {
    const eventPayload = {
      showCustomerPrice: values.showCustomerPriceFlag,
      showStock: values.showStock,
      skipOutOfStock: values.skipOutOfStock,
      template: values.template,
    };

    pushEventGTM(getDownloadLinesheetGeneratedEvent(eventPayload));

    return generateLinesheet(values);
  };

  const handleMutate = ({ offset }: Requests.Linesheet) => {
    if (isDefined(offset)) {
      return;
    }

    addToast(t('linesheets:generating_linesheet'), {
      description: t('linesheets:generate_linesheet_hint', { orderNumberText: '' }),
      duration: 0,
      icon: <Icon type={IconType.Spinner} />,
      id: NOTIFICATION_KEY,
      onClose: () => dismissToast(NOTIFICATION_KEY),
      position: 'bottom-left',
    });
  };

  const handleError = () => {
    addToast(t('linesheets:generate_linesheet_fail'), {
      description: t('linesheets:generate_linesheet_fail_hint', { orderNumberText: '' }),
      duration: 0,
      icon: <Icon type={IconType.Alert} />,
      id: NOTIFICATION_KEY,
      onClose: () => dismissToast(NOTIFICATION_KEY),
      position: 'bottom-left',
    });
  };

  const handleMultipleFilesSuccess = (response: Linesheet.MultipleFilesDownloadResponse, values: Requests.Linesheet) => {
    const { filesCount, fileProductsCount, nextBatchAt, url } = response;

    if (!isDefined(values.offset)) {
      const actions = [
        {
          label: 'Download',
          onClick: () => {
            addToast(t('linesheets:generating_linesheet_multiple_files', { current: 2, total: filesCount }), { id: NOTIFICATION_KEY });
            downloadURI(url, 'linesheet');
            trackEvent({ name: 'linesheetsPage.notification.multipleFiles.download', properties: { productsCount: fileProductsCount } });
            mutation.mutate({ ...values, offset: nextBatchAt });
          },
        },
        {
          label: 'Cancel',
          onClick: () => {
            dismissToast(NOTIFICATION_KEY);
            addToast(t('linesheets:download_cancelled'));
            trackEvent({ name: 'linesheetsPage.notification.multipleFiles.cancel', properties: { productsCount: fileProductsCount } });
          },
        },
      ];

      addToast(t('linesheets:multiple_files_warning.message'), {
        actions,
        description: t('linesheets:multiple_files_warning.description', { filesCount }),
        duration: 0,
        id: WARNING_KEY,
        onClose: () => dismissToast(WARNING_KEY),
        position: 'bottom-left',
      });

      trackEvent({ name: 'linesheetsPage.notification.multipleFiles.view', properties: { productsCount: fileProductsCount } });

      return;
    }

    const fileIndex = values.offset / fileProductsCount + 1;

    downloadURI(url, 'linesheet');
    addToast(t('linesheets:generating_linesheet_multiple_files', { current: fileIndex + 1, total: filesCount }), { id: NOTIFICATION_KEY });
    mutation.mutate({ ...values, offset: nextBatchAt });
  };

  const handleSuccess = (response: Linesheet.DownloadResponse, values: Requests.Linesheet) => {
    if (!response.success) {
      handleError();

      return;
    }

    if ('filesCount' in response && response.filesCount > 1) {
      handleMultipleFilesSuccess(response, values);

      return;
    }

    downloadURI(response.url, 'linesheet');
    dismissToast(NOTIFICATION_KEY);
  };

  const mutation = useMutation({
    mutationFn,
    onError: handleError,
    onMutate: handleMutate,
    onSuccess: handleSuccess,
  });

  return mutation;
};
