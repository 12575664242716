import { AccountMarket, Cms, PriceList, Translations } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { LANGUAGE_NAME_MAP } from '../../../constants/translation';
import { getIsLanguageSupported } from '../../../logic/translation/localization/getIsLanguageSupported';
import { isDefined } from '../../../utils/is';
import Button from '../../various/Button';
import { RadioGroup } from '../../various/Fields/RadioGroup';
import { LanguageFlag } from '../LanguageFlag';

import styles from './SegmentationSwitcher.module.scss';

interface Props {
  markets: AccountMarket[];
  priceLists: PriceList[];
  currentSegmentation: {
    marketId: Nullable<string>;
    pricelistId: Nullable<string>;
  };
  segmentationChange: (marketId: string, pricelistId: string, language?: Translations.SupportedLanguagesCodes) => void;
  handleClose: () => void;
  languages?: Cms.PageLanguage[];
  currentLanguage: Translations.SupportedLanguagesCodes;
}

export const SegmentationPopup = ({
  markets,
  currentSegmentation,
  segmentationChange,
  handleClose,
  priceLists,
  languages,
  currentLanguage,
}: Props) => {
  const [marketId, setMarketId] = React.useState(currentSegmentation.marketId);
  const [languageCode, setLanguageCode] = React.useState(currentLanguage);
  const [pricelistId, setPricelistId] = React.useState(currentSegmentation.pricelistId);
  const { t } = useTranslation(['cms', 'common']);

  const handleSegmentationChange = React.useCallback(() => {
    if (!isDefined(marketId) || !isDefined(pricelistId)) {
      return;
    }

    segmentationChange(marketId, pricelistId, languageCode);
  }, [marketId, pricelistId, segmentationChange, languageCode]);

  if (!isDefined(marketId) || !isDefined(pricelistId) || !isDefined(languages) || !isDefined(languageCode)) {
    return null;
  }

  const getLanguageName = (code: Translations.SupportedLanguagesCodes) => (
    <span className={styles.name}>
      <span>{LANGUAGE_NAME_MAP[code]}</span>
      {getIsLanguageSupported(code) && <LanguageFlag code={code} />}
    </span>
  );

  const columns = [
    {
      header: t('common:language_other'),
      name: 'language',
      onChange: setLanguageCode,
      options: languages.map(({ code }) => ({ name: getLanguageName(code), value: code })),
      value: languageCode,
    },
    {
      header: t('cms:market_other'),
      name: 'market',
      onChange: setMarketId,
      options: markets.map(({ id, name }) => ({ name, value: id })),
      value: marketId,
    },
    {
      header: t('cms:pricelists'),
      name: 'pricelist',
      onChange: setPricelistId,
      options: priceLists.map(({ id, name }) => ({ name, value: id })),
      value: pricelistId,
    },
  ];

  return (
    <div>
      <div className={styles.listsContainer}>
        {columns.map(({ header, ...radioGroupProps }) => (
          <div key={header} className={styles.list}>
            <span className={styles.header}>{header}</span>
            <RadioGroup size="small" {...radioGroupProps} />
          </div>
        ))}
      </div>
      <div className={styles.formActions}>
        <Button variant={['button', 'transparent', 'small']} onClick={handleClose}>
          {t('common:cancel')}
        </Button>
        <Button variant={['button', 'small', 'blue']} onClick={handleSegmentationChange}>
          {t('common:preview')}
        </Button>
      </div>
    </div>
  );
};
