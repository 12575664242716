import { Overview } from '@typings';

// eslint-disable-next-line @microsoft/sdl/no-insecure-url
const NAMESPACE_URI = 'http://www.w3.org/2000/svg';
export const PIE_CHART_SIZE = 400;

export const getSegmentWidth = (node: Overview.PieChartNode) => {
  return ((node.y0 + node.y1) / 2) * (node.x1 - node.x0);
};

export const truncateTextByOneChar = (text: string) => {
  if (text === '...') {
    return '';
  }

  const firstSplit = text.split('...')[0] ?? '';

  return `${text.substring(0, firstSplit.length - 1)}...`;
};

export const getIsSoloCategory = (node: Overview.PieChartNode): boolean => {
  if (node.parent?.children?.length === 1) {
    if (node.depth > 1) {
      return getIsSoloCategory(node.parent);
    }

    return true;
  }

  return false;
};

export const getSVGHelpers = (textScale: number) => {
  const svg = document.createElementNS(NAMESPACE_URI, 'svg');
  const group = document.createElementNS(NAMESPACE_URI, 'g');
  const primaryTextSpan = document.createElementNS(NAMESPACE_URI, 'text');
  const percentageTextSpan = document.createElementNS(NAMESPACE_URI, 'text');
  const divider = document.createElementNS(NAMESPACE_URI, 'text');
  const secondaryTextSpan = document.createElementNS(NAMESPACE_URI, 'text');

  group.setAttribute('transform', `scale(${textScale})`);
  group.setAttribute('font-size', '13');

  const appendSVGHelpers = () => {
    svg.appendChild(group);
    document.body.appendChild(svg);
    group.appendChild(percentageTextSpan);
    group.appendChild(primaryTextSpan);
    group.appendChild(divider);
    group.appendChild(secondaryTextSpan);
  };

  const removeSVGHelpers = () => document.body.removeChild(svg);

  return {
    appendSVGHelpers,
    divider,
    percentageTextSpan,
    primaryTextSpan,
    removeSVGHelpers,
    secondaryTextSpan,
  };
};
