import { createColumnHelper, getCoreRowModel } from '@tanstack/react-table';
import { Invoice } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getCalculatedInvoiceStatus, getIsInvoiceStandard } from '../../logic/invoices';
import { compiledPaths } from '../../paths';
import { useInvoiceDownload } from '../../services/hooks/invoice/useInvoiceDownload';
import { useInvoiceList } from '../../services/hooks/invoice/useInvoiceList';
import { usePageScrolling, useUrlPagination } from '../../utils/hooks';
import { usePaginationHandler } from '../../utils/hooks/table/usePaginationHandler';
import { useTable } from '../../utils/hooks/table/useTable';
import { useTableColumnsConfiguration } from '../../utils/hooks/table/useTableColumnsConfiguration';
import { isDefined } from '../../utils/is';
import { isEmpty } from '../../utils/isEmpty';
import { renderSentryMaskedText } from '../../utils/monitoring';
import { renderStandardCell } from '../../utils/table';
import Button from '../various/Button';
import { EmptyState } from '../various/EmptyState';
import { IconType } from '../various/Icon';
import { Link } from '../various/Link';
import { PageTitle } from '../various/PageTitle';
import { InvoiceStatusLabel } from '../various/StatusLabel';
import { Table } from '../various/Table';
import { TableControls } from '../various/TableControls';
import { TopBar } from '../various/TopBar';
import { Wrapper, WrapperSize } from '../various/Wrapper';

import styles from './Invoices.module.scss';

const columnHelper = createColumnHelper<Invoice.AnyInvoice>();

export const Invoices = () => {
  const { t } = useTranslation(['common', 'invoices', 'totals', 'orders']);
  const { columnsVisibility } = useTableColumnsConfiguration({ tableName: 'invoicesTable' });
  const [page, setPage] = useUrlPagination();

  const { pagination, handlePaginationChange } = usePaginationHandler({ onPageChange: setPage, page });

  const { scrollToTopDelay } = usePageScrolling();
  const { data, isLoading, isPlaceholderData } = useInvoiceList({ page });
  const { downloadInvoice } = useInvoiceDownload();
  const invoices = React.useMemo(() => data?.invoices ?? [], [data]);
  const invoicesTotalCount = data?.invoicesTotalCount ?? 0;
  const noInvoices = isDefined(invoices) && isEmpty(invoices) && !isLoading;

  React.useEffect(() => {
    scrollToTopDelay();
  }, [invoices, scrollToTopDelay]);

  const onInvoiceDownload = React.useCallback(
    (invoice: Invoice.Standard) => () => {
      downloadInvoice({ invoiceHash: invoice.invoiceHash, invoiceId: invoice.invoice, invoiceNumber: invoice.invoiceNumber });
    },
    [downloadInvoice],
  );

  const renderOrderLink = ({ order: orderId, orderNumber }: Invoice.Standard) => {
    if (!isDefined(orderId)) {
      return '-';
    }

    const path = compiledPaths.CHECKOUT_SUCCESS({ id: orderId });

    return (
      <Link variant="blue" to={path}>
        {orderNumber}
      </Link>
    );
  };

  const renderStatus = (invoice: Invoice.AnyInvoice) => {
    return <InvoiceStatusLabel status={getCalculatedInvoiceStatus(invoice)} />;
  };

  const renderPayButton = React.useCallback(
    ({ invoice: invoiceId, isPayable, invoiceHash }: Invoice.Standard) => {
      if (!isPayable) {
        return null;
      }

      const path = compiledPaths.INVOICE_DETAILS({ invoiceHash, invoiceId });

      return (
        <Button variant={['button', 'small', 'blue']} to={path}>
          {t('invoices:pay_invoice')}
        </Button>
      );
    },
    [t],
  );

  const renderDownloadButton = React.useCallback(
    (invoice: Invoice.Standard) => {
      return (
        <Button
          variant={['button', 'small']}
          icon={IconType.DownloadCloud}
          className={styles.downloadButton}
          aria-label={t('invoices:download_invoice')}
          onClick={onInvoiceDownload(invoice)}
        />
      );
    },
    [t, onInvoiceDownload],
  );

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('invoiceNumber', {
        cell: renderStandardCell,
        enableHiding: false,
        header: t('invoices:invoice_no'),
      }),
      columnHelper.accessor('orderNumber', {
        cell: info => (getIsInvoiceStandard(info.row.original) ? renderOrderLink(info.row.original) : '-'),
        enableHiding: false,
        header: t('orders:order_no'),
      }),
      columnHelper.accessor('status', {
        cell: info => renderStatus(info.row.original),
        enableHiding: false,
        header: t('common:status'),
      }),
      columnHelper.accessor('address', {
        cell: info => (getIsInvoiceStandard(info.row.original) ? renderSentryMaskedText(info.row.original.address) : null),
        header: t('invoices:invoice_address'),
      }),
      columnHelper.accessor('createdAt', {
        cell: renderStandardCell,
        header: t('common:created'),
        meta: {
          noWrap: true,
        },
      }),
      columnHelper.accessor('dueDate', {
        cell: renderStandardCell,
        header: t('common:due_date'),
        meta: {
          noWrap: true,
        },
      }),
      columnHelper.accessor('grandTotalWithoutTax', {
        cell: renderStandardCell,
        header: t('totals:total_no_tax'),
      }),
      columnHelper.accessor('grandTotal', {
        cell: renderStandardCell,
        header: t('totals:total_with_tax'),
      }),
      columnHelper.accessor('totalDue', {
        cell: renderStandardCell,
        header: t('totals:total_due_with_tax'),
      }),
      columnHelper.display({
        cell: info => (getIsInvoiceStandard(info.row.original) ? renderPayButton(info.row.original) : null),
        enableHiding: false,
        id: 'pay',
        meta: {
          skeletonType: 'button',
        },
      }),
      columnHelper.display({
        cell: info => (getIsInvoiceStandard(info.row.original) ? renderDownloadButton(info.row.original) : null),
        enableHiding: false,
        id: 'download',
        meta: {
          skeletonType: 'button',
        },
      }),
    ],
    [t, renderPayButton, renderDownloadButton],
  );

  const shouldShowLoader = isLoading || isPlaceholderData;
  const table = useTable(
    {
      columns,
      data: invoices,
      enableSorting: false,
      getCoreRowModel: getCoreRowModel(),
      manualPagination: true,
      onPaginationChange: handlePaginationChange,
      state: {
        pagination,
      },
    },
    columnsVisibility,
  );

  return (
    <PageTitle title={t('common:invoice_other')}>
      <TopBar title={t('common:invoice_other')} details={t('common:result_count', { count: invoicesTotalCount })} />
      <Wrapper size={WrapperSize.XXLARGE}>
        {noInvoices ?
          <EmptyState dataTestId="invoiceListEmptyState" title={t('invoices:no_invoices')} />
        : <TableControls table={table} columnsConfiguration={columnsVisibility} tableName="invoicesTable" />}
        {(shouldShowLoader || !isEmpty(invoices)) && (
          <Table
            table={table}
            isLoading={isLoading}
            dataTestId="invoiceList"
            pagination={{ current: page, hideOnSinglePage: true, total: invoicesTotalCount }}
          />
        )}
      </Wrapper>
    </PageTitle>
  );
};
