import React from 'react';

const SCROLL_TOP_DELAY = 100;

export const usePageScrolling = () => {
  const setPageScrollable = React.useCallback((isScrollable: boolean) => {
    // eslint-disable-next-line functional/immutable-data
    document.body.style.overflow = isScrollable ? '' : 'hidden';
  }, []);

  const scrollToTop = React.useCallback(() => {
    window.scroll({ behavior: 'smooth', top: 0 });
  }, []);

  const scrollToTopDelay = React.useCallback(() => {
    setTimeout(() => {
      window.scroll({ behavior: 'smooth', top: 0 });
    }, SCROLL_TOP_DELAY);
  }, []);

  return {
    scrollToTop,
    scrollToTopDelay,
    setPageScrollable,
  };
};
