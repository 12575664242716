import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;

export const TrashWithDetails = ({ width = ORIGINAL_WIDTH }: IconComponentProps) => (
  <svg width={width} height={width} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 3.34783H19M6.47826 6.47826V15.4783M10 6.47826V15.4783M13.5217 6.08696V15.087M3.34783 3.34783H16.6522V19H3.34783V3.34783ZM6.47826 1H13.5217V3.34783H6.47826V1Z"
      fill="none"
      stroke="#231f1f"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
