import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ContentBlockContext } from '../../../components/cms/context/ContentBlockContext';
import { IconType } from '../../../components/various/Icon';
import { setCurrentBlock, showModal } from '../../../ducks';

interface Props {
  areToolsDisabled: boolean;
}

export const useTextTools = ({ areToolsDisabled }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['cms']);
  const { addButtonPart, addTextPart, blockId, deleteBlockElement, deleteButtonParts, deleteTextParts, hasButtons, hasText } =
    React.useContext(ContentBlockContext);

  const setTextTemplate = React.useCallback(() => {
    dispatch(setCurrentBlock(blockId));
    dispatch(showModal('AddTextTemplateModal'));
  }, [blockId, dispatch]);

  const handleDeleteText = React.useCallback(() => deleteBlockElement(['text']), [deleteBlockElement]);

  return {
    icon: IconType.Add,
    isDisabled: areToolsDisabled,
    items: [
      {
        icon: IconType.Text,
        items: [
          {
            icon: IconType.TextAdd,
            key: 'addTextPart',
            label: t('cms:add_text_part'),
            onClick: addTextPart,
            testId: 'addTextPart',
          },
          {
            icon: IconType.Text,
            key: 'addTextTemplate',
            label: t(`cms:${!hasText ? 'add' : 'change'}_text_template`),
            onClick: setTextTemplate,
            testId: `${!hasText ? 'add' : 'change'}TextTemplate`,
          },
          {
            icon: IconType.Trash,
            isDisabled: !hasText,
            key: 'deleteAllText',
            label: t('cms:delete_text'),
            onClick: deleteTextParts,
            testId: 'deleteAllText',
          },
        ],
        key: 'textToolsParent',
        label: t('cms:text'),
        testId: 'textToolsParent',
      },
      {
        icon: IconType.Button,
        items: [
          {
            icon: IconType.Button,
            key: 'addButton',
            label: t('cms:add_button'),
            onClick: addButtonPart,
            testId: 'addButton',
          },
          {
            icon: IconType.Trash,
            isDisabled: !hasButtons,
            key: 'deleteButton',
            label: t('cms:delete_buttons'),
            onClick: deleteButtonParts,
            testId: 'deleteButtons',
          },
        ],
        key: 'buttonToolsParent',
        label: t('cms:button'),
        testId: 'buttonToolsParent',
      },
      {
        key: 'deleteSeparator',
      },
      {
        icon: IconType.Trash,
        isDisabled: !hasText && !hasButtons,
        key: 'deleteMediaItems',
        label: t('cms:delete_items'),
        onClick: handleDeleteText,
      },
    ],
    key: 'textTools',
    label: t('cms:text_tools'),
    testId: 'textTools',
  };
};
