import React from 'react';
import { useTranslation } from 'react-i18next';

import { isDefined } from '../../../utils/is';
import Button from '../../various/Button';
import Heading from '../../various/Heading';
import { Wrapper, WrapperSize } from '../../various/Wrapper';

import styles from './CheckoutFailure.module.scss';

interface Props {
  title: string;
  checkoutUrl: Maybe<string>;
}

export const FailedCheckoutContent = ({ checkoutUrl, children, title }: React.WithChildren<Props>) => {
  const { t } = useTranslation(['checkout']);

  return (
    <Wrapper size={WrapperSize.LARGE}>
      <Heading variant={['black', 'h2']} title={title} />
      {children}
      {isDefined(checkoutUrl) && (
        <Button className={styles.checkoutBottom} variant={['button', 'medium', 'transparent']} to={checkoutUrl}>
          {t('checkout:back_to_checkout')}
        </Button>
      )}
    </Wrapper>
  );
};
