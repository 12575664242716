import { Translations } from '@centra';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getAvailablePageLanguageCodes, getIsLoadingPageMeta } from '../../../ducks';
import { getIsLanguageSupported } from '../../../logic/translation/localization/getIsLanguageSupported';
import { useSearchParamByName } from '../../../utils/hooks/useSearchParamByName';

const DEFAULT_LANGUAGE = 'en';

interface Context {
  editorLanguage: Translations.SupportedLanguagesCodes;
  defaultLanguage: typeof DEFAULT_LANGUAGE;
  isDefaultLanguage: boolean;
  handleEditorLanguageChange: (language: Translations.SupportedLanguagesCodes) => void;
}

const initialContext: Context = {
  defaultLanguage: DEFAULT_LANGUAGE,
  editorLanguage: DEFAULT_LANGUAGE,
  handleEditorLanguageChange: (_: Translations.SupportedLanguagesCodes) => null,
  isDefaultLanguage: true,
};

export const EditorLanguageContext = React.createContext<Context>(initialContext);

export const EditorLanguageContextProvider = ({ children }: React.WithChildren) => {
  const navigate = useNavigate();
  const langFromQuery = useSearchParamByName('lang') ?? '';
  const pageLanguageCodes = useSelector(getAvailablePageLanguageCodes);
  const isLoadingPageMeta = useSelector(getIsLoadingPageMeta);

  const isLanguageInPage = getIsLanguageSupported(langFromQuery) && (isLoadingPageMeta || pageLanguageCodes.includes(langFromQuery));

  const editorLanguage = isLanguageInPage ? langFromQuery : DEFAULT_LANGUAGE;

  const isDefaultLanguage = editorLanguage === DEFAULT_LANGUAGE;

  const handleEditorLanguageChange = React.useCallback(
    (language: Translations.SupportedLanguagesCodes) => {
      const url = new URL(location.href);

      url.searchParams.set('lang', language);
      navigate({ search: url.search }, { replace: true });
    },
    [navigate],
  );

  React.useEffect(() => {
    if (editorLanguage === langFromQuery) {
      return;
    }

    handleEditorLanguageChange(editorLanguage);
  }, [editorLanguage, handleEditorLanguageChange, isLanguageInPage, isLoadingPageMeta, langFromQuery]);

  return (
    <EditorLanguageContext.Provider
      value={{
        defaultLanguage: DEFAULT_LANGUAGE,
        editorLanguage,
        handleEditorLanguageChange,
        isDefaultLanguage,
      }}
    >
      {children}
    </EditorLanguageContext.Provider>
  );
};
