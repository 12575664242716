import { Cms, Id } from '@typings';
import React from 'react';

interface CmsSelectors {
  getSections: (store: Store) => Cms.Section[];
  getGroupById: (id: Id) => (store: Store) => Nullable<Cms.Group>;
  getBlockById: (id: Nullable<Id>) => (store: Store) => Nullable<Cms.AnyBlock>;
  getBlocksByIds: (blockIds: Nullable<string>[]) => (store: Store) => Cms.AnyBlock[];
}

const throwError = () => {
  throw new Error('No SelectorsContext available');
};

export const SelectorsContext = React.createContext<CmsSelectors>({
  getBlockById: throwError,
  getBlocksByIds: throwError,
  getGroupById: throwError,
  getSections: throwError,
});
