import { Modals } from '@typings';
import { createSelector } from 'reselect';

import { getIsOrderClosed } from '../../logic/Orders';
import { getOrderDetails } from '../order';
import { getIsLoggedIn } from '../user';

export const getIsModalVisible = (modalName: Modals.Type) => (store: Store) => !!store.ui.visibleModals.includes(modalName);

export const getIsMenuOpened = (state: Store): boolean => state.ui.isMenuOpened;
export const getIsFilterPanelPinned = (state: Store) => state.ui.isFilterPanelPinned;
export const getIsUserOffline = (state: Store): boolean => state.ui.isOffline;

export const getIsSavingAnything = (state: Store): boolean => state.ui.isSaving;

export const getIsOrderSettingsFormDisabled = (state: Store): boolean => state.ui.isOrderSettingsFormDisabled;

export const getIsProductsGridView = (state: Store) => state.ui.isProductsGridView;
export const getIsProductsGroupedByDelwin = (state: Store) => state.ui.isProductsGroupedByDelwin;

export const getNavigationItems = (state: Store) => state.ui.userNavigation;

export const getUserNavigation = createSelector(
  getNavigationItems,
  getOrderDetails,
  getIsLoggedIn,
  (navigationItems, order, isLoggedIn) => {
    const isOrderClosed = getIsOrderClosed(order.order);

    if (isOrderClosed && !isLoggedIn) {
      return [];
    }

    return navigationItems;
  },
);

export const getIsSoftLogoutMode = (state: Store) => state.ui.isSoftLogoutMode;

export const getShouldShowMoreShippingAddresses = (state: Store) => state.ui.shouldShowMoreShippingAddresses;

export const getLanguageToSet = (state: Store) => state.ui.languageToSet;

export const getShouldShowEtaOnMobile = (state: Store) => state.ui.shouldShowEtaOnMobile;
export const getShouldShowEtaOnDesktop = (state: Store) => state.ui.shouldShowEtaOnDesktop;

export const getIsMatrixTotalsColumnPinned = (state: Store) => state.ui.isMatrixTotalsColumnPinned;
