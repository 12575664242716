import cx from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { getScannedBarcode } from '../../../../../ducks';
import { MovementOffset, useMovement } from '../../../../../utils/hooks';
import { isDefined } from '../../../../../utils/is';
import { ProductDetailsModalContext } from '../../ProductDetailsModal';
import { ProductInfoAddButton } from '../../ProductInfoAddButton';

import styles from './Drawer.module.scss';

const MIN_VISIBILITY_OFFSET = 180;
const MIN_TOP_GAP = 60;
const MAX_BUTTON_CLICK_OFFSET = 3;
const AUTO_EXPAND_COLLAPSE_DRAWER_OFFSET = 40;
const AUTO_EXPAND_DELAY = 300;

interface Props {
  drawerTopOffset: Maybe<number>;
}

export const Drawer = ({ drawerTopOffset, children }: React.WithChildren<Props>) => {
  const drawerHandleRef = React.useRef<HTMLButtonElement>(null);
  const { drawerScrollRef } = React.useContext(ProductDetailsModalContext);
  const scannedBarcode = useSelector(getScannedBarcode);

  const [isCollapsed, setIsCollapsed] = React.useState(true);
  const [dragPosition, setDragPosition] = React.useState<number | null>(null);
  const isDragging = isDefined(dragPosition);
  const isScrollable = !isCollapsed || isDefined(scannedBarcode);

  const drawerCollapsedPosition = React.useMemo(() => {
    if (!isDefined(drawerTopOffset)) {
      return 0;
    }

    return Math.min(0, window.innerHeight - drawerTopOffset - MIN_VISIBILITY_OFFSET);
  }, [drawerTopOffset]);

  const drawerExpandedPosition = MIN_TOP_GAP - (drawerTopOffset ?? 0);

  const drawerTargetPosition = isCollapsed ? drawerCollapsedPosition : drawerExpandedPosition;

  const drawerPosition = isDragging ? dragPosition : drawerTargetPosition;

  const drawerStyles = {
    height: `calc(100% - ${MIN_TOP_GAP}px)`,
    transform: `translateY(${drawerPosition}px)`,
  };

  const toggleLabel = isCollapsed ? 'Expand drawer' : 'Collapse drawer';

  const scrollDrawerToTop = () => {
    drawerScrollRef.current?.scrollTo({ top: 0 });
  };

  const toggleDrawer = () =>
    setIsCollapsed(collapsed => {
      if (!collapsed) {
        scrollDrawerToTop();
      }

      return !collapsed;
    });

  const handleDragEnd = React.useCallback(
    ({ yOffset }: MovementOffset, event: MouseEvent | TouchEvent) => {
      setDragPosition(null);

      if (Math.abs(yOffset) > MAX_BUTTON_CLICK_OFFSET) {
        event.preventDefault();
      }

      if (Math.abs(yOffset) < AUTO_EXPAND_COLLAPSE_DRAWER_OFFSET) {
        return;
      }

      if (yOffset < 0 && isCollapsed) {
        setIsCollapsed(false);

        return;
      }

      if (yOffset > 0 && !isCollapsed) {
        setIsCollapsed(true);
        scrollDrawerToTop();
      }
    },
    [isCollapsed],
  );

  const handleDrag = React.useCallback(
    ({ yOffset }: MovementOffset) => {
      const isDraggingOver = (yOffset > 0 && isCollapsed) || (yOffset < 0 && !isCollapsed);

      if (!isDraggingOver) {
        setDragPosition(drawerTargetPosition + yOffset);
      }
    },
    [drawerTargetPosition, isCollapsed],
  );

  useMovement(drawerHandleRef, {
    onEndMovement: handleDragEnd,
    onMovement: handleDrag,
    useTouch: true,
  });

  React.useEffect(() => {
    if (isDefined(scannedBarcode)) {
      setTimeout(() => {
        setIsCollapsed(false);
      }, AUTO_EXPAND_DELAY);
    }
  }, [scannedBarcode]);

  return (
    <>
      <div className={cx(styles.drawer, { [styles.animable]: !isDragging, [styles.scrollable]: isScrollable })} style={drawerStyles}>
        <button
          data-testid="productDrawerToggle"
          ref={drawerHandleRef}
          className={styles.toggleDrawer}
          onClick={toggleDrawer}
          aria-label={toggleLabel}
        />
        <div ref={drawerScrollRef} className={styles.drawerScroll}>
          {children}
        </div>
      </div>
      <ProductInfoAddButton className={styles.addProductButton} />
    </>
  );
};
