import React from 'react';

import { isDefined } from '../is';

import { useResizeObserver } from './useResizeObserver';

type Direction = 'top' | 'bottom' | 'left' | 'right';

interface Props {
  childRef: React.RefObject<HTMLElement>;
  wrapperRef: React.RefObject<HTMLElement>;
  direction: Direction;
  isDisabled?: boolean;
}

export const useOverflowingDistance = ({ childRef, wrapperRef, direction, isDisabled }: Props, dependencies: unknown[] = []) => {
  const [overflowingDistance, setOverflowingDistance] = React.useState<number>(0);

  const setDistance = React.useCallback(() => {
    if (isDisabled) {
      return;
    }

    const child = childRef.current;
    const wrapper = wrapperRef.current;

    if (!isDefined(child) || !isDefined(wrapper)) {
      return;
    }

    const childBox = child.getBoundingClientRect();
    const wrapperBox = wrapper.getBoundingClientRect();
    setOverflowingDistance(childBox[direction] - wrapperBox[direction]);
  }, [isDisabled, childRef, wrapperRef, direction]);

  React.useEffect(setDistance, [setDistance, ...dependencies]);
  useResizeObserver(wrapperRef, setDistance);

  return overflowingDistance;
};
