import { BarcodeScanner } from '@typings';
import React from 'react';

import { createCanvasCaptureToDataUrl } from '../../../utils/createCanvasCaptureToDataUrl';
import { getIsValidScan } from '../../../utils/getIsValidScan';
import { useSetInterval } from '../../../utils/hooks/useSetInterval';
import { isDefined } from '../../../utils/is';

import { getWorkerInstance } from './wasmWorker';

export const useScanWithWasm = ({ videoRef, setScannedCode }: BarcodeScanner.ScanProps) => {
  const workerInstance = getWorkerInstance();
  const isScanning = React.useRef(false);
  const [isScannerPaused, setIsScannerPaused] = React.useState(false);
  const [isWasmLoaded, setIsWasmLoaded] = React.useState(false);

  const canvasEl = React.useMemo(() => document.createElement('canvas'), []);

  React.useEffect(() => {
    const onMessage = (event: MessageEvent<BarcodeScanner.Message>) => {
      if (event.data.type === 'loaded') {
        setIsWasmLoaded(true);

        return;
      }

      if (event.data.type === 'scanResult') {
        if (isDefined(event.data.payload) && getIsValidScan(event.data.payload)) {
          setIsScannerPaused(true);
          setScannedCode(event.data.payload);

          return;
        }

        isScanning.current = false;
      }
    };

    workerInstance.addEventListener('message', onMessage);

    return () => workerInstance.removeEventListener('message', onMessage);
  }, [setScannedCode]);

  React.useEffect(() => {
    const statusCheckMessage: BarcodeScanner.Message = { type: 'statusCheck' };
    workerInstance.postMessage(statusCheckMessage);
  }, []);

  const scan = React.useCallback(() => {
    const sourceEl = videoRef.current;
    const isSourceElReady = sourceEl?.readyState === HTMLMediaElement.HAVE_ENOUGH_DATA;

    if (!isDefined(canvasEl) || !isDefined(sourceEl) || !isSourceElReady || isScanning.current || !isWasmLoaded) {
      return;
    }

    const dataToUrl = createCanvasCaptureToDataUrl({ canvasEl, sourceEl });

    if (isDefined(dataToUrl)) {
      isScanning.current = true;
      const scanMessage: BarcodeScanner.Message = { payload: dataToUrl, type: 'scanInput' };
      workerInstance.postMessage(scanMessage);
    }
  }, [canvasEl, isWasmLoaded, videoRef]);

  const activateScanner = React.useCallback(() => {
    setIsScannerPaused(false);
    isScanning.current = false;
  }, []);

  useSetInterval({ callback: scan, isPaused: isScannerPaused });

  return { activateScanner, isLoadingScanner: !isWasmLoaded };
};
