import { DeliveryWindow, FailedProducts, Product } from '@typings';
import { prop, uniqBy } from 'ramda';
import { useSelector } from 'react-redux';

import { getActiveDeliveryWindowsIdsForOrderBuyer, getAllDeliveryWindows } from '../../../ducks';
import { getIsExpired } from '../../../logic/deliveryWindows';
import { getRelatedProducts } from '../../../logic/products';
import { ListItem } from '../List';

import { FailedProductsCard } from './FailedProductsCard';
import styles from './FailedProductsList.module.scss';

interface Props {
  products: Record<Product.Id, Product.Full>;
  failedProducts: FailedProducts.Any[];
  model: Map<string, ListItem<FailedProducts.FailedProduct | FailedProducts.FailedProductWithoutData>>;
  productsContainerClassname?: string;
  tableStyles?: string;
  isCancelled?: boolean;
}

export const FailedProductsList = ({
  products,
  failedProducts,
  productsContainerClassname,
  model,
  tableStyles,
  isCancelled = false,
}: Props) => {
  const allDeliveryWindows = useSelector(getAllDeliveryWindows);
  const activeDeliveryWindowsIds = useSelector(getActiveDeliveryWindowsIdsForOrderBuyer);

  const getDeliveryWindowData = (id: number) => {
    return allDeliveryWindows.find(delwin => id.toString() === delwin.deliveryWindow) as DeliveryWindow.Mixed;
  };

  const getAllFailedProductsFromDelwin = (id: number) => {
    return failedProducts.filter(product => product.deliveryWindow === id);
  };

  const getVariantsByDelwin = (id: number) => {
    const allFailedProductsFromDelwin = getAllFailedProductsFromDelwin(id);

    return allFailedProductsFromDelwin.map(failedProduct => {
      const productData = Object.values(products)
        .reduce((acc: Product.Standard[], productInfo) => acc.concat([productInfo, ...getRelatedProducts(productInfo)]), [])
        .find(variant => variant.centraVariant === failedProduct.variantId.toString());

      return {
        ...productData,
        failedProductData: failedProduct,
      };
    });
  };

  const uniqByDelwinFailedProducts = uniqBy(prop('deliveryWindow'), failedProducts);

  return (
    <div className={styles.tableWrapper}>
      {uniqByDelwinFailedProducts.map(({ deliveryWindow }) => {
        const delwinData = getDeliveryWindowData(deliveryWindow);
        const variants = getVariantsByDelwin(deliveryWindow) as (
          | FailedProducts.FailedProduct
          | FailedProducts.FailedProductWithoutData<FailedProducts.ItemsMissing>
        )[];
        const isExpired = getIsExpired(delwinData, activeDeliveryWindowsIds);

        return (
          <FailedProductsCard
            key={deliveryWindow}
            variants={variants}
            isExpired={isExpired}
            isCancelled={isCancelled}
            delwinData={delwinData}
            model={model}
            tableStyles={tableStyles}
            productsContainerClassname={productsContainerClassname}
          />
        );
      })}
    </div>
  );
};
