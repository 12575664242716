import { useTranslation } from 'react-i18next';

import Heading from '../Heading';
import { Button } from '../NewButton';

import styles from './SectionHeading.module.scss';
import { SectionHeadingWrapper } from './SectionHeadingWrapper';

interface Props {
  isEditing: boolean;
  isLoading: boolean;
  heading: string;
  isDisabled?: boolean;
  onCancel?: () => void;
  onEdit?: () => void;
}

export const SectionFormHeading = ({ isEditing, isLoading, isDisabled, heading, onCancel, onEdit }: Props) => {
  const { t } = useTranslation(['common']);

  return (
    <SectionHeadingWrapper>
      <Heading title={heading} className={styles.heading} variant={['h2', 'black']} />
      {isEditing ?
        <div className={styles.submitButtons}>
          <Button size="large" color="dark" variant="ghost" disabled={isLoading} onClick={onCancel}>
            {t('common:cancel')}
          </Button>
          <Button type="submit" size="large" isLoading={isLoading}>
            {t('common:save_changes')}
          </Button>
        </div>
      : <Button size="large" disabled={isDisabled} onClick={onEdit}>
          {t('common:edit')}
        </Button>
      }
    </SectionHeadingWrapper>
  );
};
