import { useTranslation } from 'react-i18next';

import { Cms } from '../../../../../../typings';
import { Option, Select } from '../../../various/Fields/Select';

import styles from './WidthSelect.module.scss';

interface Props {
  value: string;
  onChange: (value: Cms.ButtonWidth) => void;
  disabled?: boolean;
}

export const WidthSelect = ({ value, onChange, disabled = false }: Props) => {
  const { t } = useTranslation(['cms']);

  return (
    <div className={styles.widthSelect}>
      <Select size="small" value={value} dataTestId="buttonStyleEditorWidth" isDisabled={disabled} onChange={onChange}>
        <Option value="auto">{t('cms:width_fit')}</Option>
        <Option value="100%">{t('cms:width_fill')}</Option>
      </Select>
    </div>
  );
};
