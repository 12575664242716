import { Id } from '@typings';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';

import { fetchHomePageByBuyerRequest, fetchOrderRequest, getBuyerIdFromCurrentOrder, getHomepageByBuyerId, getOrderId } from '../../ducks';
import { compiledPaths } from '../../paths';
import { useAllProductsLink } from '../../utils/hooks';
import { isDefined } from '../../utils/is';
import { isEmpty } from '../../utils/isEmpty';
import { OrderTopBar } from '../orders/OrderTopBar';
import { SkeletonLayout } from '../various/SkeletonLayout';
import { PageSkeleton } from '../various/SkeletonLayout/layouts/PageSkeleton';
import { Wrapper, WrapperSize } from '../various/Wrapper';

export const Home = () => {
  const { id } = useParams<{ id?: Id }>();
  const currentOrder = useSelector(getOrderId);
  const buyerIdFromCurrentOrder = useSelector(getBuyerIdFromCurrentOrder);
  const homepageSlug = useSelector(getHomepageByBuyerId(buyerIdFromCurrentOrder));
  const allProductsLink = useAllProductsLink(currentOrder);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (isDefined(currentOrder) || !isDefined(id)) {
      return;
    }

    dispatch(fetchOrderRequest(id));
  }, [id]);

  React.useEffect(() => {
    if (isEmpty(buyerIdFromCurrentOrder)) {
      return;
    }

    dispatch(fetchHomePageByBuyerRequest(buyerIdFromCurrentOrder));
  }, [buyerIdFromCurrentOrder]);

  if (homepageSlug === undefined) {
    return (
      <>
        <OrderTopBar />
        <Wrapper size={WrapperSize.XLARGE}>
          <SkeletonLayout isLoading skeleton={<PageSkeleton />} />
        </Wrapper>
      </>
    );
  }

  if (homepageSlug === null) {
    return <Navigate to={allProductsLink} replace />;
  }

  return <Navigate to={compiledPaths.PAGES({ pageSlug: homepageSlug })} replace />;
};
