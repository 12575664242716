import accounts from '../../../translations/de/accounts.json';
import buyers from '../../../translations/de/buyers.json';
import cancelled from '../../../translations/de/cancelled.json';
import checkout from '../../../translations/de/checkout.json';
import checkoutMessages from '../../../translations/de/checkoutMessages.json';
import cms from '../../../translations/de/cms.json';
import cmsConfirmationConfig from '../../../translations/de/cmsConfirmationConfig.json';
import common from '../../../translations/de/common.json';
import configErrors from '../../../translations/de/configErrors.json';
import confirmationConfig from '../../../translations/de/confirmationConfig.json';
import dates from '../../../translations/de/dates.json';
import expiredDelwins from '../../../translations/de/expiredDelwins.json';
import genericErrors from '../../../translations/de/genericErrors.json';
import invoices from '../../../translations/de/invoices.json';
import linesheets from '../../../translations/de/linesheets.json';
import login from '../../../translations/de/login.json';
import lookbook from '../../../translations/de/lookbook.json';
import menu from '../../../translations/de/menu.json';
import onboarding from '../../../translations/de/onboarding.json';
import orders from '../../../translations/de/orders.json';
import pagination from '../../../translations/de/pagination.json';
import passwordManagement from '../../../translations/de/passwordManagement.json';
import payments from '../../../translations/de/payments.json';
import products from '../../../translations/de/products.json';
import selectionOverview from '../../../translations/de/selectionOverview.json';
import selections from '../../../translations/de/selections.json';
import settings from '../../../translations/de/settings.json';
import shipping from '../../../translations/de/shipping.json';
import totals from '../../../translations/de/totals.json';
import translations from '../../../translations/de/translations.json';
import validation from '../../../translations/de/validation.json';

export const de = {
  accounts,
  buyers,
  cancelled,
  checkout,
  checkoutMessages,
  cms,
  cmsConfirmationConfig,
  common,
  configErrors,
  confirmationConfig,
  dates,
  expiredDelwins,
  genericErrors,
  invoices,
  linesheets,
  login,
  lookbook,
  menu,
  onboarding,
  orders,
  pagination,
  passwordManagement,
  payments,
  products,
  selectionOverview,
  selections,
  settings,
  shipping,
  totals,
  translations,
  validation,
} as const;
