import { DatePicker } from '@typings';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';

type Props = Pick<DatePicker.DefaultProps, 'isPastDisabled' | 'shouldShowDot'> & {
  max: DatePicker.SingleValue;
  min: DatePicker.SingleValue;
};

interface Context {
  isDateView: boolean;
  isMonthView: boolean;
  isYearView: boolean;
  max: DatePicker.SingleValue;
  min: DatePicker.SingleValue;
  setView: React.Dispatch<React.SetStateAction<DatePicker.View>>;
  shouldDisable: (date: Dayjs) => boolean;
  shouldShowDot?: (date: Dayjs, view?: DatePicker.View) => boolean;
  view: DatePicker.View;
}

const initialContext: Context = {
  isDateView: true,
  isMonthView: false,
  isYearView: false,
  max: null,
  min: null,
  setView: () => null,
  shouldDisable: () => false,
  view: 'date',
};

export const DatePickerSettingsContext = React.createContext<Context>(initialContext);

export const DatePickerSettingsContextProvider = ({ children, max, min, isPastDisabled, shouldShowDot }: React.WithChildren<Props>) => {
  const [view, setView] = React.useState<DatePicker.View>('date');

  const shouldDisable = React.useCallback(
    (date: Dayjs) => {
      const isPast = date.isBefore(dayjs(), view);
      const isBetweenMinMax = !date.isBefore(min, view) && !date.isAfter(max, view);

      return (isPastDisabled && isPast) || !isBetweenMinMax;
    },
    [isPastDisabled, max, min, view],
  );

  return (
    <DatePickerSettingsContext.Provider
      value={{
        isDateView: view === 'date',
        isMonthView: view === 'month',
        isYearView: view === 'year',
        max,
        min,
        setView,
        shouldDisable,
        shouldShowDot,
        view,
      }}
    >
      {children}
    </DatePickerSettingsContext.Provider>
  );
};
