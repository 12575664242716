import React from 'react';

export const getChildrenText = (children: React.ReactNode): string => {
  return React.Children.toArray(children).reduce((acc, child) => {
    if (typeof child === 'string' || typeof child === 'number') {
      return `${acc}${child}`;
    }

    if (!React.isValidElement(child)) {
      return acc;
    }

    return `${acc}${getChildrenText(child.props.children)}`;
  }, '') as string;
};
