import cx from 'classnames';

import { Table } from '../../../ducks/helpers';
import Chart from '../Chart';
import Icon, { IconType } from '../Icon';
import { Popover } from '../Popover';

import styles from './ItemsQuantity.module.scss';

interface Props {
  quantity: number;
  table: Table;
  tooltipClassName?: string;
}

const ICON_OFFSET = 15;

export const ItemsQuantity = ({ quantity, table, tooltipClassName }: Props) => {
  return (
    <span className={styles.quantity}>
      <span className={styles.quantityAmount}>{quantity}</span>
      <span className={cx(styles.priceTag, tooltipClassName)}>
        <Popover triggerOffset={ICON_OFFSET} placement="top-end" content={<Chart table={table} />}>
          <Icon type={IconType.PriceTag} />
        </Popover>
      </span>
    </span>
  );
};
