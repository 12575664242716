import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchConfigurationRequest, getConfigErrorMessage, getIsConfigLoaded } from '../ducks/config';
import { getIsUserOffline } from '../ducks/ui';
import { isDefined } from '../utils/is';

import { ErrorPage } from './errorPage/errorPage';
import { UnsupportedBrowserNotifications } from './various/Message';
import { ProductImageMessage } from './various/Message/ProductImageMessage';
import Overlay from './various/Overlay/Overlay';

export const Template = ({ children }: React.WithChildren) => {
  const dispatch = useDispatch();
  const isOffline = useSelector(getIsUserOffline);
  const isConfigLoaded = useSelector(getIsConfigLoaded);
  const configurationErrorMessage = useSelector(getConfigErrorMessage);

  React.useEffect(() => {
    dispatch(fetchConfigurationRequest());
  }, [dispatch]);

  return (
    <>
      <Overlay visible={isOffline} />
      <ProductImageMessage />
      <UnsupportedBrowserNotifications />
      {isConfigLoaded && !isDefined(configurationErrorMessage) && children}
      {isDefined(configurationErrorMessage) && <ErrorPage message={configurationErrorMessage} />}
    </>
  );
};
