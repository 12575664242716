import cx from 'classnames';

import Button from '../../various/Button';

import styles from './ActionButton.module.scss';

interface Props {
  isHidden?: boolean;
  isDisabled?: boolean;
  title: string;
  onClick: () => void;
  className?: string;
}

export const ActionButton = ({ onClick, isHidden, title, isDisabled, className }: Props) => {
  return (
    <div className={cx(styles.wrapper, className, { [styles.hidden]: isHidden })}>
      <Button title={title} onClick={onClick} variant={['button', 'toolBarButton']} disabled={isDisabled || isHidden} />
    </div>
  );
};
