import { TooltipProps } from '@typings';

import { isDefined } from '../../../utils/is';
import { Tooltip } from '../Tooltip';

const DEFAULT_MOUSE_ENTER_DELAY = 500;

interface Props extends Pick<TooltipProps, 'placement' | 'disableOnTouchScreen' | 'mouseEnterDelay' | 'children'> {
  content?: React.ReactNode;
}

export const MaybeTooltip = ({
  children,
  content,
  placement,
  disableOnTouchScreen,
  mouseEnterDelay = DEFAULT_MOUSE_ENTER_DELAY,
}: Props) => {
  if (isDefined(content) && content !== false) {
    return (
      <Tooltip content={content} placement={placement} mouseEnterDelay={mouseEnterDelay} disableOnTouchScreen={disableOnTouchScreen}>
        {children}
      </Tooltip>
    );
  }

  return <>{children}</>;
};
