import { Product } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { MATRIX_MOBILE_BREAKPOINT } from '../../../../constants/breakpoints';
import {
  getActiveDeliveryWindowsIdsForOrderBuyer,
  getAllDeliveryWindows,
  getFilteredDelwinIds,
  getOrderDetails,
  setEtaVisibilityOnDesktop,
} from '../../../../ducks';
import { getSortedDeliveryWindowsIds } from '../../../../logic/deliveryWindows';
import { getOrderDelwinIds } from '../../../../logic/Orders';
import { useDelwinIdsWithCurrentStockType, useExpand, useScreenWidth } from '../../../../utils/hooks';
import { isDefined } from '../../../../utils/is';
import { isEmpty } from '../../../../utils/isEmpty';
import { DelwinNameAndDate } from '../../../various/DelwinNameAndDate';
import { ProductMatrixSectionGeneral } from '../ProductMatrixSectionGeneral';

import styles from './FilteredProductMatrixSection.module.scss';

interface Props {
  variants: (Product.Full | Product.Standard)[];
  isReadOnly?: boolean;
}

export const FilteredProductMatrixSection = ({ variants, isReadOnly }: Props) => {
  const [firstVariant] = variants;
  const allDeliveryWindows = useSelector(getAllDeliveryWindows);
  const { isOpen, toggle } = useExpand<HTMLDivElement>({ initialOpen: false });
  const orderDetails = useSelector(getOrderDetails);
  const activeDeliveryWindowsIds = useSelector(getActiveDeliveryWindowsIdsForOrderBuyer);
  const { t } = useTranslation(['products']);
  const dispatch = useDispatch();
  const isMobile = useScreenWidth() <= MATRIX_MOBILE_BREAKPOINT;

  const matrixProps = {
    isReadOnly,
    variants,
  };

  const delwinIds = React.useMemo(() => {
    if (!isDefined(firstVariant)) {
      return [];
    }

    const orderDelwinIds = getOrderDelwinIds(variants, orderDetails, firstVariant.product);

    return getSortedDeliveryWindowsIds(orderDelwinIds, activeDeliveryWindowsIds);
  }, [variants, orderDetails, firstVariant, activeDeliveryWindowsIds]);

  const filteredDelwinIds = useSelector(getFilteredDelwinIds);

  const delwinToShowIds = useDelwinIdsWithCurrentStockType(
    isEmpty(filteredDelwinIds) ? delwinIds : delwinIds.filter(delwinId => filteredDelwinIds.includes(delwinId)),
  );

  const otherDelwinIds = delwinIds.filter(id => !delwinToShowIds.includes(id));

  const otherDelwins = otherDelwinIds.reduce((acc, id) => {
    const delwinById = allDeliveryWindows.find(delwin => id.toString() === delwin.deliveryWindow);

    return isDefined(delwinById) ? [...acc, delwinById] : [...acc];
  }, []);

  const isProductPage = location.pathname.indexOf('/products/') !== -1;

  const handleMouseLeave = React.useCallback(() => {
    if (isMobile) {
      return;
    }

    dispatch(setEtaVisibilityOnDesktop(false));
  }, [dispatch, isMobile]);

  if (!isProductPage) {
    return (
      <div onMouseLeave={handleMouseLeave}>
        <ProductMatrixSectionGeneral {...matrixProps} showOnlyDeliveryWindows={delwinIds} canScollToEtaMatrix />
      </div>
    );
  }

  return (
    <div className={styles.filteredDelwinsWrapper} onMouseLeave={handleMouseLeave}>
      <ProductMatrixSectionGeneral {...matrixProps} showOnlyDeliveryWindows={delwinToShowIds} canScollToEtaMatrix />
      {otherDelwinIds.length > 0 && (
        <>
          <button
            className={cx(styles.otherDelwinsToggle, {
              [styles.openedList]: isOpen,
            })}
            onClick={toggle}
          >
            <span className={styles.delwinList}>
              <span className={styles.text}>
                {t('products:available_in_other_delwins', {
                  count: otherDelwinIds.length,
                })}
              </span>
              <span>
                {otherDelwins.map(delwin => (
                  <DelwinNameAndDate deliveryWindow={delwin} key={delwin.deliveryWindow} iconClassName={styles.dateIcon} insertCommas />
                ))}
              </span>
            </span>
          </button>
          {isOpen && <ProductMatrixSectionGeneral {...matrixProps} showOnlyDeliveryWindows={otherDelwinIds} />}
        </>
      )}
    </div>
  );
};
