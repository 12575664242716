import React from 'react';

interface Props {
  children?: React.ReactNode;
  skeleton: React.ReactElement;
  isLoading: boolean;
  repeatLayout?: number;
}

export const SkeletonLayout = ({ children, skeleton, isLoading, repeatLayout = 1 }: Props) => {
  if (isLoading) {
    return <>{Array.from({ length: repeatLayout }, (_, index) => React.cloneElement(skeleton, { key: index }))}</>;
  }

  return children;
};
