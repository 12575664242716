import { Modals, Navigation, Translations } from '@typings';
import { createAction } from 'typesafe-actions';

export const hideModal = createAction('ui/HIDE_MODAL')<Modals.Type>();
export const showModal = createAction('ui/SHOW_MODAL')<Modals.Type>();

export const toggleMenu = createAction('ui/MENU_TOGGLE')<boolean | void>();

export const setFilterPanelPinned = createAction('ui/SET_FILTER_PANEL_PINNED')<boolean>();

export const toggleNetworkConnectivity = createAction('ui/NETWORK_CONNECTIVITY_TOGGLE')<{
  isOffline: boolean;
}>();

export const setProductsGridView = createAction('ui/SET_PRODUCTS_GRID_VIEW')();
export const setProductsListView = createAction('ui/SET_PRODUCTS_LIST_VIEW')();

export const toggleProductsGroupedByDelwin = createAction('ui/PRODUCTS_GROUPED_BY_DELWIN_TOGGLE')();

export const switchDisplayedProduct = createAction('ui/SWITCH_DISPLAYED_PRODUCT')<'next' | 'prev'>();

export const fetchUserNavigationSuccess = createAction('cms/USER_NAVIGATION_FETCH_SUCCESS')<Navigation.UserMenuItem[]>();
export const fetchUserNavigationFailure = createAction('cms/USER_NAVIGATION_FETCH_FAILURE')();

export const setSoftLogoutMode = createAction('ui/SET_SOFT_LOGOUT_MODE')();

export const setShouldShowMoreShippingAddresses = createAction('order/SET_SHOULD_SHOW_MORE_SHIPPING_ADDRESSES')<boolean>();
export const resetAppState = createAction('ui/RESET_APP_STATE')();
export const requestLanguageChange = createAction('ui/REQUEST_LANGUAGE_CHANGE')<Translations.SupportedLanguagesCodes>();

export const toggleEtaVisibilityOnMobile = createAction('ui/TOGGLE_ETA_VISIBILITY_ON_MOBILE')();
export const setEtaVisibilityOnMobile = createAction('ui/SET_ETA_VISIBILITY_ON_MOBILE')<boolean>();
export const setEtaVisibilityOnDesktop = createAction('ui/SET_ETA_VISIBILITY_ON_DESKTOP')<boolean>();

export const setMatrixTotalsColumnPinned = createAction('ui/SET_MATRIX_TOTALS_COLUMN_PINNED')<boolean>();

export const updateUserNavigation = createAction('ui/UPDATE_USER_NAVIGATION')();
