import { Product } from '@typings';
import cx from 'classnames';
import React from 'react';

import { useIsOverflowing } from '../../../utils/hooks/useIsOverflowing';
import { Tooltip } from '../../various/Tooltip';

import Price from './Price';
import styles from './Product.module.scss';

const MOUSE_ENTER_DELAY = 0.2;
const MOUSE_LEAVE_DELAY = 0;

interface Props {
  variant: Product.Standard;
}

export const ProductInfo = ({ variant }: Props) => {
  const nameRef = React.useRef<HTMLHeadingElement>(null);
  const isNameOverflowing = useIsOverflowing(nameRef);
  const { name } = variant;

  const Name = (
    <h3 ref={nameRef} className={cx(styles.title, { [styles.overflowing]: isNameOverflowing })}>
      {name}
    </h3>
  );

  return (
    <>
      {isNameOverflowing ?
        <Tooltip
          overlayClassName={styles.productNameTooltip}
          content={name}
          disableOnTouchScreen
          mouseEnterDelay={MOUSE_ENTER_DELAY}
          mouseLeaveDelay={MOUSE_LEAVE_DELAY}
        >
          {Name}
        </Tooltip>
      : Name}
      <div>
        <Price productInfo={variant} />
      </div>
    </>
  );
};
