import { Overview } from '@typings';
import React from 'react';
import { useSelector } from 'react-redux';

import { getOrderDetails, getOrderVariants } from '../../../ducks';
import { getOrderedVariantsTotals, getVariantsByDeliveryWindow } from '../../../logic/selectionOverview';

interface Props {
  shouldShowWithUnits: boolean;
  deliveryWindowBase: Overview.DeliveryWindowBase;
}

export const useSelectionOverviewVariants = ({ shouldShowWithUnits, deliveryWindowBase }: Props) => {
  const orderDetails = useSelector(getOrderDetails);
  const orderedVariants = orderDetails.order.products;
  const variantsInOrder = useSelector(getOrderVariants);

  const variantsInDeliveryWindow = React.useMemo(
    () => getVariantsByDeliveryWindow(variantsInOrder, deliveryWindowBase),
    [deliveryWindowBase, variantsInOrder],
  );

  const variantsUnits = React.useMemo(
    () =>
      getOrderedVariantsTotals({
        deliveryWindowBase,
        orderedVariants,
      }),
    [orderedVariants, deliveryWindowBase],
  );

  const overviewVariants = React.useMemo(() => {
    const items = variantsInDeliveryWindow.map(variantRecord => {
      const { variant: id, product: productId } = variantRecord;
      const { totalPrice, totalUnits } = variantsUnits[id] ?? { totalPrice: 0, totalUnits: 0 };

      return {
        ...variantRecord,
        id,
        productId,
        totalPrice,
        totalUnits,
      };
    });

    return shouldShowWithUnits ? items.filter(({ totalUnits }) => totalUnits) : items;
  }, [variantsInDeliveryWindow, variantsUnits, shouldShowWithUnits]);

  return {
    overviewVariants: overviewVariants satisfies Overview.Variant[],
  };
};
