import { Payments } from '@typings';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getOrderDetails, getPaymentTerm, pushEvent, setActiveOrderPaymentMethod } from '../../../ducks';
import { getPaymentMethodsByMode } from '../../../logic/payments';
import { getPaymentMethodSelectedEvent } from '../../../utils/analytics/events';
import { isEmpty } from '../../../utils/isEmpty';
import { RadioGroupField } from '../Form/FormField/RadioGroupField';
import { LogoType } from '../Logo';

import { PaymentMethodLabel } from './PaymentMethodLabel';
import styles from './PaymentMethods.module.scss';

interface Props {
  className?: string;
}

const PAYMENT_METHOD_ICON: Record<Payments.PaymentMethodType, Maybe<LogoType>> = {
  pay_later: null,
  paypal_wholesale: LogoType.PayPal,
  stripe_checkout: LogoType.Stripe,
  stripe_checkout_wholesale: LogoType.Stripe,
};

export const PaymentMethodsField = ({ className }: Props) => {
  const dispatch = useDispatch();
  const paymentTerm = useSelector(getPaymentTerm);

  const orderDetails = useSelector(getOrderDetails);
  const paymentMethods = getPaymentMethodsByMode(paymentTerm, orderDetails.order.paymentMethods ?? []);

  const options = React.useMemo(
    () =>
      paymentMethods.map(method => ({
        name: <PaymentMethodLabel iconType={PAYMENT_METHOD_ICON[method.paymentMethodType]} label={method.name} />,
        value: method.paymentMethod,
      })),
    [paymentMethods],
  );

  if (isEmpty(paymentMethods)) {
    return null;
  }

  const handleChange = (value: string) => {
    dispatch(pushEvent(getPaymentMethodSelectedEvent(value)));
    dispatch(setActiveOrderPaymentMethod(value));
  };

  return (
    <RadioGroupField className={className} itemClassName={styles.item} name="paymentMethod" options={options} onChange={handleChange} />
  );
};
