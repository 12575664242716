import { Badges, Filters, Media, Product } from '@typings';
import { path } from 'ramda';
import { Option } from 'space-lift';

import { getHasRelatedProducts } from '../../utils/guards';
import { getProductImageOrPlaceholderBySize } from '../helpers/images';

const getGallerySlide = (product: Product.Standard, defaultImageSize: Media.Size): Product.GallerySlide => {
  const { product: productId, variant: variantId } = product;

  return {
    id: `${productId}-${variantId}`,
    image: getProductImageOrPlaceholderBySize(product, defaultImageSize),
    isAvailable: getProductAvailability(product),
    swatchData: product.sh_swatch,
    variant: product,
  };
};

export const getGallerySlides = (product: Product.Standard, defaultImageSize: Media.Size): Product.GallerySlide[] => {
  return getProductFamily(product).map(item => getGallerySlide(item, defaultImageSize));
};

export const getCustomBadges = ({ sh_custom_badge = [] }: Product.Standard): Badges.CustomBadge[] => {
  return sh_custom_badge.map(({ background_color: backgroundColor, text_color: textColor, ...rest }) => ({
    backgroundColor,
    textColor,
    ...rest,
  }));
};

export const getIsDefaultBadge = (badge: Badges.AnyBadge): badge is Badges.DefaultBadge => {
  return 'badgeType' in badge;
};

export function getProductAvailability(product: Product.Standard | Product.Full): boolean {
  const availableByDeliveryWindowPrimaryVariant = 'availableByDeliveryWindow' in product ? product.availableByDeliveryWindow : undefined;

  /*
   * Either the API didn't send `availableByDeliveryWindow`
   * or the user is browsing lookbooks. Fall back to `true`.
   */
  if (availableByDeliveryWindowPrimaryVariant === undefined) {
    return true;
  }

  return Object.values(availableByDeliveryWindowPrimaryVariant).includes(true);
}

/**
 * Returns a flat list of product and all its variants.
 */
export function getProductFamily(product: Product.Standard): (Product.Standard | Product.Full)[] {
  return [product, ...Option<Product.Full[]>(path(['relatedProducts', 'variants'], product)).getOrElse([])];
}

export function getHasAvailableVariants(products: (Product.Standard | Product.Full)[]): boolean {
  return products.map(getProductAvailability).some(availability => availability);
}

export function parseObjectValuesToArray(query: Filters): any {
  const SINGLE_VALUE_FILTERS = ['onlyAvailable', 'search', 'stockType', 'etaFrom', 'etaTo'];
  const isNotSingleValue = ([key]: [string, any]) => !SINGLE_VALUE_FILTERS.includes(key);
  const isTruthy = ([_, value]: [string, any]) => Boolean(value);
  const isNotArray = ([_, value]: [string, any]) => !Array.isArray(value);
  // eslint-disable-next-line @typescript-eslint/ban-types
  const merge = (acc: object, [key, value]: [string, any]) => ({
    ...acc,
    [key]: [value],
  });
  const parsedObject = Object.entries(query)
    .filter(isNotSingleValue)
    .filter(isTruthy)
    .filter(isNotArray)
    .reduce<Partial<Filters>>(merge, {});

  return { ...query, ...parsedObject };
}

export const getNativeVariants = (productDetails: Product.Standard | Product.Full, productId: string) => {
  if (!getHasRelatedProducts(productDetails)) {
    return [];
  }

  return [productDetails, ...productDetails.relatedProducts.variants].filter(relatedProduct => relatedProduct.product === productId);
};
