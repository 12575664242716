import { Id } from '@typings';
import React from 'react';
import { useSelector } from 'react-redux';

import { getCmsBlocksByIds, getCmsGroupById } from '../../../ducks/cms';

export const useGetBlocksByGroupId = (groupId: Nullable<Id>) => {
  const group = useSelector(getCmsGroupById(groupId));
  const blockIds = React.useMemo(() => group?.blocks ?? [], [group?.blocks]);
  const blocksSelector = React.useMemo(() => getCmsBlocksByIds(blockIds), [blockIds]);

  return useSelector(blocksSelector);
};
