import { Cms } from '@typings';
import { range } from 'ramda';

import { DESKTOP_PRODUCTS_PER_ROW } from '../../constants/cms';
import { isEmpty } from '../../utils/isEmpty';
import { getIsProductBlock } from '../pages';

export const getRowsRange = (blocks: Cms.AnyBlock[] | Cms.CustomBlockModel[], screenType: Cms.ScreenType) => {
  const maxEndY = Math.max(...blocks.map(block => block.position[screenType].startY + block.position[screenType].spanY));

  return range(1, maxEndY);
};

export const getHasProductBlocksPerRow = (blocks: Cms.AnyBlock[], screenType: Cms.ScreenType) => {
  return getRowsRange(blocks, screenType).reduce<Record<number, boolean>>((acc, row) => {
    const hasProductBlocks = blocks.some(block => {
      const { startY, spanY } = block.position[screenType];

      return getIsProductBlock(block) && startY <= row && row < startY + spanY;
    });

    return { ...acc, [row]: hasProductBlocks };
  }, {});
};

export const getResizableBlocksIds = (blocks: Cms.AnyBlock[], screenType: Cms.ScreenType) => {
  const productBlocks = blocks.filter(getIsProductBlock);

  if (isEmpty(productBlocks)) {
    return new Set(blocks.map((block: Cms.ContentBlock) => block.id));
  }

  const hasProductBlocksPerRow = getHasProductBlocksPerRow(blocks, screenType);

  const resizableBlocks = blocks.filter(block => {
    if (getIsProductBlock(block)) {
      return false;
    }

    const positionData = block.position[screenType];

    return range(positionData.startY, positionData.startY + positionData.spanY).some(row => !hasProductBlocksPerRow[row]);
  });

  return new Set(resizableBlocks.map((block: Cms.ContentBlock) => block.id));
};

export const isFullWidthBlock = (block: Cms.AnyBlock) => {
  const { spanX } = block.position.desktop;

  return spanX === DESKTOP_PRODUCTS_PER_ROW;
};

export const isLeftSideBlock = (block: Cms.AnyBlock) => {
  return !isFullWidthBlock(block) && block.position.desktop.startX === 1;
};
