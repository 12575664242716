import { isNull } from './is';

export const getAddressLine = (address1: Nullable<string>, address2: Nullable<string>) => {
  if (isNull(address1) && isNull(address2)) {
    return '';
  }

  if (isNull(address1) || isNull(address2)) {
    return isNull(address1) ? `${address2}` : `${address1}`;
  }

  return `${address1} ${address2}`;
};
