import { Filter, Filters } from '@typings';
import { createAction } from 'typesafe-actions';

export const setLookbookAttributes = createAction('lookbook/LOOKBOOK_ATTRIBUTES_SET')<Filter.Field<string>[]>();

export const updateLookbookFilters = createAction('lookbook/LOOKBOOK_FILTERS_UPDATE')<Partial<Filters>>();
export const resetLookbookFilters = createAction('lookbook/LOOKBOOK_FILTERS_RESET')();

export const setLookbookToken = createAction('lookbook/SET_LOOKBOOK_TOKEN')<Responses.SingleLookbook>();
export const setExpandedLookbookFilters = createAction('lookbook/SET_EXPANDED_FILTERS')<Record<string, boolean>>();
