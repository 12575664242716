import { Tables } from '@typings';

import { ButtonSkeleton } from '../../../Skeleton/ButtonSkeleton';
import { InputSkeleton } from '../../../Skeleton/InputSkeleton/InputSkeleton';

import { ActionsSkeleton } from './ActionsSkeleton';
import { TextSkeleton } from './TextSkeleton';

interface Props {
  type: Tables.SkeletonType | undefined;
  isRightAligned: boolean;
}

export const TableSkeleton = ({ type, isRightAligned }: Props) => {
  if (type === 'input') {
    return <InputSkeleton type="input" />;
  }
  if (type === 'button') {
    return <ButtonSkeleton type="button" />;
  }

  if (type === 'actions') {
    return <ActionsSkeleton />;
  }

  if (type === 'multi') {
    return <TextSkeleton isMultiLine isRightAligned={isRightAligned} />;
  }

  return <TextSkeleton isRightAligned={isRightAligned} />;
};
