import { Cms, Translations } from '@typings';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { composeEditorPreviewPath } from '../../../utils/composeEditorPreviewPath';
import { useTranslationsConfig } from '../../../utils/hooks';
import { isDefined } from '../../../utils/is';
import Icon from '../../various/Icon';
import { MaybeLink } from '../../various/MaybeLink';
import { MaybeTooltip } from '../../various/MaybeTooltip';

import styles from './LanguageFlag.module.scss';

interface Props {
  code: Translations.SupportedLanguagesCodes;
  status?: Cms.PageStatus;
  pageId?: string;
  size?: number;
}

export const LanguageFlag = ({ code, status, pageId, size }: Props) => {
  const { t } = useTranslation(['cms']);
  const { supportedLanguages } = useTranslationsConfig();

  const statusLabelMap = {
    published: t('cms:published'),
    published_with_changes: t('cms:unpublished_changes'),
    unpublished: t('cms:unpublished'),
  } as const;

  const languageDetails = supportedLanguages.find(details => details.code === code);

  if (!isDefined(languageDetails)) {
    return null;
  }

  return (
    <MaybeLink
      title={t('cms:page_preview_with_language', { language: languageDetails.label, status: isDefined(status) && statusLabelMap[status] })}
      to={isDefined(pageId) ? composeEditorPreviewPath({ lang: code, pageId, pathType: 'preview' }) : undefined}
      className={styles.languageFlagLink}
      variant="blue"
    >
      <MaybeTooltip
        content={
          isDefined(status) ?
            <div className={styles.tooltipContent}>
              <div className={styles.tooltipLanguage}>{languageDetails.label}</div>
              <div className={styles.tooltipStatus}>{statusLabelMap[status]}</div>
            </div>
          : undefined
        }
      >
        <div className={styles.flag}>
          <Icon
            type={languageDetails.flagIcon}
            className={cx({
              [styles.withChanges]: status === 'published_with_changes',
              [styles.unpublished]: status === 'unpublished',
            })}
            size={size}
          />
          {status === 'published_with_changes' && <span className={styles.changesIndicator} />}
        </div>
      </MaybeTooltip>
    </MaybeLink>
  );
};
