import cx from 'classnames';
import React from 'react';

import styles from './Notification.module.scss';

interface Props {
  className?: string;
  dataTestId?: string;
}

export const Notification = ({ children, className, dataTestId = 'notification' }: React.WithOptionalChildren<Props>) => (
  <div data-testid={dataTestId} className={cx(styles.notification, className)}>
    {children}
  </div>
);
