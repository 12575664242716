const enum AllProductsTrackingEventWithParameters {
  ADDED_PRODUCT_POSITION_WITH_SEARCH_QUERY = 'all-products-added-product-position-with-search-query',
}

export const getAddedProductPositionWithSearchQueryEvent = (addedProductPosition: number) => ({
  addedProductPosition,
  event: AllProductsTrackingEventWithParameters.ADDED_PRODUCT_POSITION_WITH_SEARCH_QUERY,
});

export const enum AllProductsTrackingEvent {
  ADD_TO_SELECTION_CLICKED = 'all-products-add-to-selection-clicked',
}
