export const getIsLoadingCmsProducts = (state: Store) => state.cms.products.isLoading;

export const getIsLoadingMoreCmsProductsFromSearch = (state: Store) => state.cms.products.isLoadingMoreFromSearch;

export const getIsLoadingCmsProductsFromSearch = (state: Store) => state.cms.products.isLoadingFromSearch;

export const getCmsProducts = (state: Store) => state.cms.products.allProducts;

export const getCmsProductCount = (state: Store) => state.cms.products.productCount;

export const getCmsProductsOrder = (state: Store) => state.cms.products.productsOrder;
