import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getIsLoadingOrderDetails, getOrderDetails } from '../../../ducks/order';
import { useModalVisibility } from '../../../utils/hooks';
import { IconType } from '../../various/Icon';
import { IconButton } from '../../various/IconButton/IconButton';
import { DownloadLinesheetModal } from '../DownloadLinesheetModal';

export const DownloadLinesheet = () => {
  const { showModal } = useModalVisibility('DownloadLinesheetModal');
  const { order } = useSelector(getOrderDetails);
  const { t } = useTranslation(['common']);
  const isLoadingOrderDetails = useSelector(getIsLoadingOrderDetails);

  return (
    <>
      <IconButton
        icon={IconType.DownloadLinesheet}
        name={t('common:download_linesheet')}
        showTooltip
        variant="secondary"
        tooltipPlacement="bottom-center"
        onClick={showModal}
        dataTestId="downloadLinesheet"
        disabled={isLoadingOrderDetails}
      />
      <DownloadLinesheetModal orderId={order.order} orderNumber={order.orderNumber} location="select units" downloadOnlySelected={false} />
    </>
  );
};
