import { Cms, Id, Position } from '@typings';
import { assocPath } from 'ramda';
import React from 'react';

import { getClampedMousePosition, mousePositionToRatio, removeEmptyHotspots } from '../../../logic/cms/hotspot';
import { getScreenRelativeStyles } from '../../../logic/pages';
import { useSpecifiedDeviceWidth } from '../../../utils/hooks';
import { isDefined } from '../../../utils/is';

import { EditorUIContext } from './EditorUIContext';

interface Props {
  initialHotspots?: Cms.Hotspots;
}

type PointsRecords = Record<Id, Cms.HotspotPoint>;

interface HotspotMovementStartData {
  hotspotId: Id;
  background: Cms.ContentBlockBackground;
  mousePosition: Position;
}

interface Context {
  activeHotspotId: Nullable<Id>;
  addHotspot: (hotspotObject: PointsRecords) => void;
  hotspots: PointsRecords;
  setActiveHotspot: (hotspotId: Id | null, clearEmpty?: boolean) => void;
  setHotspotProduct: (hotspotId: Id, productId: Id | null) => void;
  setHotspotPosition: (hotspotId: Id, mousePosition: Position) => void;
  isMovingHotspot: boolean;
  hotspotEditorRef: React.MutableRefObject<HTMLDivElement | null>;
  startHotspotMovement: (movementData: HotspotMovementStartData) => void;
  endHotspotMovement: () => void;
  setIsProductsTooltipVisible: (isVisible: boolean) => void;
  isProductsTooltipVisible: boolean;
}

const initialContext: Context = {
  activeHotspotId: null,
  addHotspot: () => null,
  endHotspotMovement: () => null,
  hotspotEditorRef: { current: null },
  hotspots: {},
  isMovingHotspot: false,
  isProductsTooltipVisible: false,
  setActiveHotspot: () => null,
  setHotspotPosition: () => null,
  setHotspotProduct: () => null,
  setIsProductsTooltipVisible: () => null,
  startHotspotMovement: () => null,
};

export const HotspotsContext = React.createContext<Context>(initialContext);

export const HotspotsContextProvider = ({ children, initialHotspots }: React.WithChildren<Props>) => {
  const screenWidth = useSpecifiedDeviceWidth();
  const { screenType } = React.useContext(EditorUIContext);

  const hotspotEditorRef = React.useRef<HTMLDivElement | null>(null);
  const hotspotsRecords: PointsRecords =
    initialHotspots?.points.reduce((acc, cur) => {
      return {
        ...acc,
        [cur.general.id]: cur,
      };
    }, {}) ?? {};

  const [hotspots, setHotspots] = React.useState(hotspotsRecords);
  const [isMovingHotspot, setIsMovingHotspot] = React.useState(false);
  const [activeHotspotId, setActiveHotspotId] = React.useState<Id | null>(null);
  const [isProductsTooltipVisible, setIsProductsTooltipVisible] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (isMovingHotspot) {
      setActiveHotspotId(null);

      return;
    }

    const emptyHotspot = Object.values(hotspots).find(hotspot => !isDefined(hotspot.general.productId));

    if (isDefined(emptyHotspot)) {
      setActiveHotspotId(emptyHotspot.general.id);
    }
  }, [isMovingHotspot, hotspots]);

  const setActiveHotspot = React.useCallback(
    (hotspotId: Id | null, clearEmpty: boolean = true) => {
      setActiveHotspotId(hotspotId);

      if (clearEmpty) {
        setHotspots(currentHotspots => ({
          ...removeEmptyHotspots(currentHotspots),
        }));
      }
    },
    [setHotspots],
  );

  const setHotspotProduct = React.useCallback(
    (hotspotId: Id, productId: Id | null) => {
      setHotspots(currentHotspots => {
        const newHotspot = assocPath(['general', 'productId'], productId, currentHotspots[hotspotId]);

        if (!isDefined(newHotspot)) {
          return {
            ...currentHotspots,
          };
        }

        return {
          ...currentHotspots,
          [hotspotId]: newHotspot,
        };
      });
    },
    [setHotspots],
  );

  const addHotspot = React.useCallback(
    (hotspotObject: PointsRecords) => {
      setHotspots(currentHotspots => ({
        ...removeEmptyHotspots(currentHotspots),
        ...hotspotObject,
      }));
    },
    [setHotspots],
  );

  const startHotspotMovement = React.useCallback(
    ({ hotspotId, background, mousePosition }: HotspotMovementStartData) => {
      if (!isDefined(hotspotEditorRef.current)) {
        return;
      }

      const { offsetHeight, offsetWidth } = hotspotEditorRef.current;

      const offset = mousePositionToRatio(hotspotEditorRef, mousePosition);
      const screenRelative = getScreenRelativeStyles(screenWidth, background);
      const hostpotData = hotspots[hotspotId];

      if (!isDefined(hostpotData)) {
        return;
      }

      const updatedGeneral = {
        ...hostpotData.general,
        originalBlockSize: {
          height: offsetHeight,
          width: offsetWidth,
        },
        originalScreenRelative: screenRelative,
      };

      setHotspots(currentHotspots => ({
        ...currentHotspots,
        [hotspotId]: {
          ...hostpotData,
          general: updatedGeneral,
          [screenType]: offset,
        },
      }));
    },
    [setHotspots, hotspots, screenType, hotspotEditorRef, screenWidth],
  );

  const setHotspotPosition = React.useCallback(
    (hotspotId: Id, mousePosition: Position) => {
      if (!isDefined(hotspotEditorRef.current)) {
        return;
      }

      const rect = hotspotEditorRef.current.getBoundingClientRect();
      const clampedPosition = getClampedMousePosition(rect, mousePosition);
      const offset = mousePositionToRatio(hotspotEditorRef, clampedPosition);

      setIsMovingHotspot(true);
      setHotspots(currentHotspots => {
        const modifiedHotspot = assocPath([screenType], offset, currentHotspots[hotspotId]);

        if (!isDefined(modifiedHotspot)) {
          return {
            ...currentHotspots,
          };
        }

        return {
          ...currentHotspots,
          [hotspotId]: modifiedHotspot,
        };
      });
    },
    [setHotspots, screenType, hotspotEditorRef],
  );

  const endHotspotMovement = React.useCallback(() => {
    setIsMovingHotspot(false);
  }, []);

  return (
    <HotspotsContext.Provider
      value={{
        activeHotspotId,
        addHotspot,
        endHotspotMovement,
        hotspotEditorRef,
        hotspots,
        isMovingHotspot,
        isProductsTooltipVisible,
        setActiveHotspot,
        setHotspotPosition,
        setHotspotProduct,
        setIsProductsTooltipVisible,
        startHotspotMovement,
      }}
    >
      {children}
    </HotspotsContext.Provider>
  );
};
