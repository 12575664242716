import { SwatchData } from '@typings';

import { isDefined } from './is';
import { isEmpty } from './isEmpty';

export const getBackgroundBySwatch = (swatchData: SwatchData) => {
  if (isDefined(swatchData.image) && isDefined(swatchData.image.url) && !isEmpty(swatchData.image.url)) {
    return `url("${swatchData.image.url}") no-repeat center center / contain`;
  }

  return swatchData.hex;
};
