import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;

export const CopyOutline = ({ width = ORIGINAL_WIDTH, color = 'currentColor' }: IconComponentProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width={width} height={width} fill={color}>
      <path
        fillRule="evenodd"
        d="M5.3 3.677c0-.374.302-.677.677-.677h10.346c.374 0 .677.303.677.677v10.347a.677.677 0 0 1-1.355 0v-9.67H5.977a.677.677 0 0 1-.678-.677ZM3 5.977c0-.375.303-.678.677-.678h10.347c.374 0 .677.303.677.678v10.346a.677.677 0 0 1-.678.677H3.678A.677.677 0 0 1 3 16.323V5.977Zm1.355.677v8.991h8.991V6.654H4.355Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
