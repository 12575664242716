import { Id } from '@typings';

import { getIsFolderMenuItem, getIsLinkMenuItem, getIsPageMenuItem } from '../../../../../logic/pages';
import { isDefined } from '../../../../../utils/is';
import { useMenuSectionContext } from '../../context/MenuSectionContext';

import { FolderMenuItem } from './FolderMenuItem';
import { LinkMenuItem } from './LinkMenuItem';
import { PageMenuItem } from './PageMenuItem';

interface Props {
  onRemoveMenuItem: (id: Id) => void;
  onLabelChange: (value: string, id: Id) => void;
  onMarketsChange: (value: string[], id: Id) => void;
  onToggleNewTab: (value: boolean, id: Id) => void;
  onToggleOpen: (id: Id) => void;
  isOpen: boolean;
  id: Id;
}

export const AddedMenuItem = ({ onRemoveMenuItem, onLabelChange, onToggleNewTab, onMarketsChange, isOpen, onToggleOpen, id }: Props) => {
  const { itemRecords } = useMenuSectionContext();

  const item = itemRecords[id];

  if (!isDefined(item)) {
    return null;
  }

  if (getIsPageMenuItem(item)) {
    return (
      <PageMenuItem
        item={item}
        onRemoveMenuItem={onRemoveMenuItem}
        onLabelChange={onLabelChange}
        onToggleNewTab={onToggleNewTab}
        onToggleOpen={onToggleOpen}
        isOpen={isOpen}
      />
    );
  }

  if (getIsLinkMenuItem(item)) {
    return (
      <LinkMenuItem
        item={item}
        onRemoveMenuItem={onRemoveMenuItem}
        onLabelChange={onLabelChange}
        onToggleNewTab={onToggleNewTab}
        onMarketsChange={onMarketsChange}
        onToggleOpen={onToggleOpen}
        isOpen={isOpen}
      />
    );
  }

  if (getIsFolderMenuItem(item)) {
    return (
      <FolderMenuItem
        item={item}
        onRemoveMenuItem={onRemoveMenuItem}
        onLabelChange={onLabelChange}
        onToggleOpen={onToggleOpen}
        isOpen={isOpen}
      />
    );
  }

  return null;
};
