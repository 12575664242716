/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;

export const Cut = ({ width = ORIGINAL_WIDTH, color = 'currentColor' }: IconComponentProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox="0 0 20 20" fill={color}>
    <path d="M16.674 13.237 7.893 7.228a2.511 2.511 0 0 0-.437-2.285 2.5 2.5 0 1 0-.412 3.523L8.92 9.75l-1.875 1.285a2.5 2.5 0 1 0 .849 1.235l2.357-1.612 5.576 3.816a.75.75 0 0 0 .848-1.238Zm-10.467.471a1 1 0 1 1-1.414-1.415 1 1 0 0 1 1.414 1.415ZM4.793 7.206a1 1 0 1 1 1.414-1.414 1 1 0 0 1-1.414 1.414Zm7.051 1.453a.749.749 0 0 1 .196-1.043l3.786-2.59a.75.75 0 0 1 .848 1.237l-3.787 2.592a.75.75 0 0 1-1.043-.196Z" />
  </svg>
);
