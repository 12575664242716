import { prop, uniqBy } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Autocomplete, Option } from '../../../various/Fields/Select';
import { useModalContext } from '../../../various/ModalElements/context/ModalContext';
import { OverviewField } from '../OverviewField';
import { SelectionOverviewContext } from '../SelectionOverviewContext';

export const DimensionSelectors = () => {
  const {
    overviewAttributes,
    primaryDimensionKey,
    secondaryDimensionKey,
    setSecondaryDimensionKey,
    setPrimaryDimensionKey,
    deselectAllRowFilterOptions,
  } = React.useContext(SelectionOverviewContext);
  const { containerRef } = useModalContext();

  const { t } = useTranslation(['common', 'selectionOverview']);

  const dimensionOptions = React.useMemo(() => {
    const options = Object.values(overviewAttributes).map(attribute => ({
      label: attribute.title,
      value: attribute.uri,
    }));

    return [...uniqBy(prop('value'), options)].sort((a, b) => a.label.localeCompare(b.label));
  }, [overviewAttributes]);

  const secondaryDimensionOptions = React.useMemo(() => {
    return [...dimensionOptions.filter(({ value }) => value !== primaryDimensionKey && value !== 'categoryPathIds')];
  }, [primaryDimensionKey, dimensionOptions]);

  const handleSetPrimaryDimension = React.useCallback(
    (value: string) => {
      if (value === secondaryDimensionKey) {
        setSecondaryDimensionKey(null);
      }
      setPrimaryDimensionKey(value);
      deselectAllRowFilterOptions();
    },
    [secondaryDimensionKey, setPrimaryDimensionKey, deselectAllRowFilterOptions, setSecondaryDimensionKey],
  );

  return (
    <>
      <OverviewField title={t('selectionOverview:primary_dimension')} domId="overviewPrimaryDimension">
        <Autocomplete
          name="overviewPrimaryDimension"
          size="small"
          containerRef={containerRef}
          value={primaryDimensionKey}
          onChange={handleSetPrimaryDimension}
        >
          {dimensionOptions.map(({ label, value }) => (
            <Option key={value} value={value}>
              {label}
            </Option>
          ))}
        </Autocomplete>
      </OverviewField>
      <OverviewField title={t('selectionOverview:secondary_dimension')} domId="overviewSecondaryDimension">
        <Autocomplete
          size="small"
          name="overviewSecondaryDimension"
          value={secondaryDimensionKey}
          placeholder={t('selectionOverview:secondary_dimension')}
          containerRef={containerRef}
          allowClear
          onChange={setSecondaryDimensionKey}
        >
          {secondaryDimensionOptions.map(({ label, value }) => (
            <Option key={value} value={value}>
              {label}
            </Option>
          ))}
        </Autocomplete>
      </OverviewField>
    </>
  );
};
