import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useMenuSectionContext } from '../../settings/MenuSection/context/MenuSectionContext';
import Icon, { IconColor, IconType } from '../Icon';

import styles from './ExpandableContainer.module.scss';
import { ExpandableElement } from './ExpandableElement';

interface Props {
  id: string;
  title: React.ReactNode;
  isOpen: boolean;
  toggleOpen?: (id: string) => void;
}

export const ExpandableContainer = ({ id, title, children, toggleOpen, isOpen }: React.WithChildren<Props>) => {
  const { isInEditMode, areInputsDisabled } = useMenuSectionContext();
  const { t } = useTranslation(['settings', 'common']);

  const handleToggle = React.useCallback(() => {
    toggleOpen?.(id);
  }, [toggleOpen, id]);

  return (
    <div
      className={cx(styles.expandableContainer, {
        [styles.disabled]: !isInEditMode,
        [styles.disabledHover]: areInputsDisabled,
      })}
    >
      <button
        type="button"
        className={cx(styles.title, styles.toggleExpanded, {
          [styles.expanded]: isOpen,
        })}
        aria-label={t('common:toggle_item', { item: title })}
        aria-expanded={isOpen}
        aria-controls={id}
        onClick={handleToggle}
      >
        <span className={styles.titleContent}>{title}</span>
        <Icon type={IconType.ArrowDown} color={IconColor.Gray} rotation={isOpen ? 180 : 0} />
      </button>
      <ExpandableElement id={id} isOpen={isOpen}>
        <div>{children}</div>
      </ExpandableElement>
    </div>
  );
};
