import React from 'react';

import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { Popover } from '../Popover';

import styles from './ImageHoverEnlarge.module.scss';

interface Props {
  src: string | undefined;
}

export const ImageHoverEnlarge = ({ src, children }: React.WithChildren<Props>) => {
  if (!isDefined(src) || isEmpty(src)) {
    return <>{children}</>;
  }

  return (
    <Popover
      placement="right-center"
      className={styles.popover}
      triggerClassName={styles.popoverTrigger}
      content={<img alt="" role="presentation" className={styles.image} src={src} />}
      isContentHoverable={false}
    >
      {children}
    </Popover>
  );
};
