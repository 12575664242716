import { useSelector } from 'react-redux';

import { getProductListingAttributes } from '../../../../../ducks';
import { getDefaultImageAspectRatio } from '../../../../../ducks/helpers';
import { isEmpty } from '../../../../../utils/isEmpty';
import { Skeleton } from '../../../Skeleton';

import styles from './ProductCardSkeleton.module.scss';

const MAX_NUMBER_OF_ATTRIBUTES = 3;

export const ProductCardSkeleton = () => {
  const imageAspectRatio = useSelector(getDefaultImageAspectRatio);
  const listingAttributes = useSelector(getProductListingAttributes);

  const hasListingAttributes = !isEmpty(listingAttributes);
  const attributesCount = Math.min(listingAttributes.length, MAX_NUMBER_OF_ATTRIBUTES);

  return (
    <div>
      <Skeleton type="rectangle" aspectRatio={imageAspectRatio} className={styles.imageSkeleton} />
      <Skeleton type="text" rows={2} size="large" gap="medium" lastWidth={60} containerClassName={styles.titleSkeleton} />
      {hasListingAttributes && <Skeleton type="text" rows={attributesCount} size="small" gap="medium" lastWidth={60} />}
    </div>
  );
};
