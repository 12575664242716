import { API } from '@typings';

export const ACCEPT_HEADER_BASE = 'application/json, text/plain;';

export const CONTENT_TYPE_HEADER = 'application/json';
export const CONTENT_TYPE_HEADER_FORM_DATA = 'multipart/form-data;';

export const ResponseStatus: API.ResponseStatus = {
  FAILURE: 'FAILURE',
  SUCCESS: 'SUCCESS',
};
