import { createColumnHelper, getCoreRowModel } from '@tanstack/react-table';
import { Invoice } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { composeFullProductName } from '../../../logic/invoices';
import { useTable } from '../../../utils/hooks/table/useTable';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { renderStandardCell } from '../../../utils/table';
import { Table } from '../../various/Table';

import styles from './InvoiceSummary.module.scss';

interface Props {
  invoice: Invoice.Full;
}

const columnHelper = createColumnHelper<Invoice.InvoiceItem>();

export const InvoiceSummary = ({ invoice }: Props) => {
  const { t } = useTranslation(['common', 'invoices', 'totals']);

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: info => {
          const invoiceItem = info.row.original;

          return (
            <div>
              {composeFullProductName(invoiceItem)}
              {isDefined(invoiceItem.SKU) && !isEmpty(invoiceItem.SKU) && (
                <div className={styles.productSKU}>{t('common:art_no', { artNo: invoiceItem.SKU })}</div>
              )}
            </div>
          );
        },
        header: t('common:name'),
      }),
      columnHelper.accessor('quantity', {
        cell: renderStandardCell,
        header: t('common:quantity'),
      }),
      columnHelper.accessor('unitPrice', {
        cell: renderStandardCell,
        header: t('invoices:units_price'),
      }),
      columnHelper.accessor('subtotal', {
        cell: renderStandardCell,
        header: t('totals:subtotal'),
      }),
    ],
    [t],
  );

  const tableData: Invoice.InvoiceItem[] = React.useMemo(() => {
    const { invoiceItems } = invoice;
    const quantitySum = invoiceItems.reduce((acc, cur) => acc + cur.quantity, 0);
    const invoicesItemsData = invoiceItems.map(item => ({
      ...item,
      key: item.name,
    }));

    return [
      ...invoicesItemsData,
      {
        SKU: '',
        key: 'subtotal',
        name: t('totals:subtotal'),
        quantity: quantitySum,
        size: '',
        subtotal: invoice.subtotal,
        unitPrice: '',
        unitsPrice: '',
        variantName: '',
      },
    ];
  }, [invoice, t]);

  const table = useTable({
    columns,
    data: tableData,
    enableSorting: false,
    getCoreRowModel: getCoreRowModel(),
  });

  return <Table dataTestId="invoice-summary" className={styles.invoiceSummaryTable} table={table} />;
};
