import { Product } from '@typings';
import React from 'react';
import { useSelector } from 'react-redux';

import { getIsProductsGridView, getProductsArray } from '../../../ducks';
import { GetProductUrl } from '../../../logic/products';
import { SkeletonLayout } from '../../various/SkeletonLayout';
import { ProductSkeleton } from '../../various/SkeletonLayout/layouts/ProductSkeleton';
import { ProductsEtaContextProvider } from '../ProductsEta';

import { LoadMoreProducts } from './LoadMoreProducts';
import { ProductsListItem } from './ProductsListItem';
import { ProductsView } from './ProductsView';

interface Props {
  isLookbook?: boolean;
  totalItems: number;
  pageCount: number;
  getProductUrl: GetProductUrl;
  orderedProducts: Product.Full[];
  isLoadingProducts: boolean;
  isLoadingMoreProducts: boolean;
  onLoadMore: VoidFunction;
}

const ProductsListComponent = (props: Props) => {
  const {
    isLookbook = false,
    isLoadingProducts,
    isLoadingMoreProducts,
    orderedProducts,
    getProductUrl,
    onLoadMore,
    totalItems,
    pageCount,
  } = props;
  const allProducts = useSelector(getProductsArray);
  const isProductsGridView = useSelector(getIsProductsGridView);

  const showGridView = isLookbook ? true : isProductsGridView;

  return (
    <ProductsEtaContextProvider products={allProducts}>
      <ProductsView isGridView={showGridView}>
        <SkeletonLayout skeleton={<ProductSkeleton isGridView={showGridView} />} repeatLayout={12} isLoading={isLoadingProducts}>
          {orderedProducts.map((product, index) => (
            <ProductsListItem
              isFirst={index === 0}
              isLookbook={isLookbook}
              product={product}
              getProductUrl={getProductUrl}
              showGridView={showGridView}
              key={product.product}
            />
          ))}
        </SkeletonLayout>
        <LoadMoreProducts
          isGridView={showGridView}
          isLoadingProducts={isLoadingProducts}
          isLoadingMoreProducts={isLoadingMoreProducts}
          onLoadMore={onLoadMore}
          totalItems={totalItems}
          pageCount={pageCount}
          loadedCount={orderedProducts.length}
        />
      </ProductsView>
    </ProductsEtaContextProvider>
  );
};

export const ProductsList = React.memo(ProductsListComponent);
