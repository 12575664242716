import { Translations } from '@typings/@centra';
import dayjs from 'dayjs';
import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { TRANSLATION_RESOURCES } from '../constants/translation';
import { showroomApi } from '../services';
import { formatLocaleDate, formatLocaleDateAndTime } from '../utils/dates';
import { isDefined } from '../utils/is';

// dayjs en locale is loaded automatically
import 'dayjs/locale/sv';
import 'dayjs/locale/de';
import 'dayjs/locale/fr';

i18n.on('languageChanged', () => {
  const [language] = i18n.languages;

  if (!isDefined(language)) {
    return;
  }

  showroomApi.updateHeaders({ 'Accept-Language': language });

  dayjs.locale(language);
  document.documentElement.setAttribute('lang', language);
});

const addFormatters = () => {
  i18n.services.formatter?.add('date', (date, locale) => formatLocaleDate(date, locale as Translations.SupportedLanguagesCodes));
  i18n.services.formatter?.add('dateAndTime', (date, locale) =>
    formatLocaleDateAndTime(date, locale as Translations.SupportedLanguagesCodes),
  );
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init(
    {
      detection: {
        caches: ['localStorage'],
        order: ['localStorage', 'navigator'],
      },
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false,
      },
      load: 'languageOnly',
      nonExplicitSupportedLngs: true,
      resources: TRANSLATION_RESOURCES,
      supportedLngs: Object.keys(TRANSLATION_RESOURCES),
    },
    addFormatters,
  );

export default i18n;
