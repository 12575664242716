import { Filter, Filters, Sorting } from '@typings';
import dayjs from 'dayjs';
import { stringify } from 'qs';
import { omit } from 'ramda';
import { Option } from 'space-lift';

import { getIsValidDateString } from '../utils/dates';
import { getQueryParams } from '../utils/getQueryParams';
import { isDefined } from '../utils/is';
import { isEmpty } from '../utils/isEmpty';

import { getIsValidStockType } from './stockType';

const FILTER_PART_DELIMITER = '+';
const FILTER_ATTRIBUTE_DELIMITER = ':';
const FILTER_VALUE_DELIMITER = ',';

export const filtersDecoder = (input: string) => input;

export const encodeSearchString = (currentQuery: Record<string, string>, filters: Partial<Filters>) => {
  const filtersString = Object.keys(filters)
    .map(key => {
      if (!isDefined(filters[key]) || isEmpty(filters[key] as string)) {
        // eslint-disable-next-line array-callback-return
        return;
      }

      const filterValue =
        Array.isArray(filters[key]) ?
          (filters[key] as string[]).map(value => encodeURIComponent(value)).join(FILTER_VALUE_DELIMITER)
        : encodeURIComponent(filters[key] as string);

      return `${encodeURIComponent(key)}${FILTER_ATTRIBUTE_DELIMITER}${filterValue}`;
    })
    .filter(isDefined)
    .join(FILTER_PART_DELIMITER);

  return stringify(
    {
      ...currentQuery,
      ...(!isEmpty(filtersString) ? { filters: filtersString } : {}),
    },
    { arrayFormat: 'comma', encode: false },
  );
};

export const decodeSearchString = (query: string | undefined) => {
  if (!isDefined(query)) {
    return {};
  }

  const filters = Option(query)
    .map(queryString => getQueryParams(queryString, { decoder: filtersDecoder, ignoreQueryPrefix: true }))
    .map(queryString => queryString.filters)
    .getOrElse('');

  if (isEmpty(filters)) {
    return filters;
  }

  return filters.split(FILTER_PART_DELIMITER).reduce((acc, cur) => {
    const [parameter, valueString] = cur.split(FILTER_ATTRIBUTE_DELIMITER);
    const values = isDefined(valueString) ? valueString.split(FILTER_VALUE_DELIMITER) : [''];

    if (!isDefined(parameter) || !isDefined(values[0])) {
      return acc;
    }

    return {
      ...acc,
      ...{
        [decodeURIComponent(parameter)]: values.length > 1 ? values.map(value => decodeURIComponent(value)) : decodeURIComponent(values[0]),
      },
    };
  }, {});
};

export const filterLookbookAttributes = (attribute: Filter.Field<string>) => {
  const lookbookHidden = ['deliveryWindows', 'onlyAvailable'];

  return !lookbookHidden.includes(attribute.field);
};

export const getOnlyNeccessaryFilters = (filters: Partial<Filters>): Partial<Filters> => {
  const { stockType, onlyAvailable } = filters;

  const hasStockType = isDefined(stockType);
  const shouldOmitStockType = hasStockType && !getIsValidStockType(filters.stockType);
  const shouldOmitOnlyAvailable = onlyAvailable === 'no';

  const keysToOmit = [...(shouldOmitOnlyAvailable ? ['onlyAvailable'] : []), ...(shouldOmitStockType ? ['stockType'] : [])];

  return omit(keysToOmit, filters);
};

export const getValidatedProductSorting = (sorting: string | undefined, sortingOptions: Sorting.ProductSortingOptions) => {
  const isValidSorting = isDefined(sorting) && sortingOptions.fields.some(({ field }) => field === sorting);

  return isValidSorting ? sorting : sortingOptions.default;
};

export const getValidatedFilters = (
  filters: Partial<Filters>,
  configurationFields: Filter.FieldNameSet[],
  isStockTypeSeparationEnabled: boolean,
) => {
  const areEtaDatesValid =
    getIsValidDateString(filters.etaFrom) && getIsValidDateString(filters.etaTo) && dayjs(filters.etaTo).isAfter(filters.etaFrom);
  const hasValidArrivingSoon = filters.onlyAvailable === 'arrivingSoon' && areEtaDatesValid;

  const validFilterFields = [
    'search',
    ...configurationFields.map(({ field }) => field),
    ...(isStockTypeSeparationEnabled ? ['stockType'] : []),
    ...(hasValidArrivingSoon ? ['etaFrom', 'etaTo'] : []),
  ];

  return Object.entries(filters).reduce<Partial<Filters>>((acc, [key, value]) => {
    if (validFilterFields.includes(key)) {
      return {
        ...acc,
        [key]: value,
      };
    }

    return acc;
  }, {});
};

export const mapFilterOptionsToCheckboxOptions = (fieldOptions: Filter.Option<string>[]) => {
  return fieldOptions.map(({ data, filterValue, parent }) => ({
    name: data,
    parentValue: parent,
    value: filterValue,
  }));
};
