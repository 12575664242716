import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Addresses, Id } from '@typings';
import { useTranslation } from 'react-i18next';

import { addToast } from '../../../components/various/Toasts';
import { updateInvoicingAddress } from '../../accountRepository';

interface MutationVariables {
  accountId: Id;
  data: Addresses.BillingDTO;
}

export const useUpdateInvoiceAddress = () => {
  const { t } = useTranslation(['invoices']);
  const queryClient = useQueryClient();

  const mutationFn = async ({ accountId, data }: MutationVariables) => {
    return updateInvoicingAddress(accountId, data);
  };

  const handleSuccess = (_: Addresses.Billing, { accountId }: MutationVariables) => {
    addToast(t('invoices:invoice_address_updated'));
    queryClient.invalidateQueries({ queryKey: ['accountDetails', { accountId }] });
  };

  const handleError = () => {
    addToast(t('invoices:invoice_address_update_failure'));
  };

  return useMutation({
    mutationFn,
    onError: handleError,
    onSuccess: handleSuccess,
  });
};
