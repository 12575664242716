import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getUser, pushEvent } from '../../../ducks';
import { getIsViewer } from '../../../logic/User';
import { CommonTrackingEvent } from '../../../utils/analytics/events';
import { copyToClipboard } from '../../../utils/clipboard';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { Popover } from '../../various/Popover';
import { Input } from '../Fields/Input';
import { IconType } from '../Icon';

import { IconButton } from './IconButton';
import styles from './ShareButton.module.scss';

interface Props {
  tooltipText: string;
  shareURL: string;
  message: string;
  popoverOverflowContainer?: boolean;
  className?: string;
}

export const ShareButton = ({ shareURL, tooltipText, message, className, popoverOverflowContainer = true }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common']);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const isViewer = getIsViewer(useSelector(getUser));
  const [isPopoverVisible, setIsPopoverVisible] = React.useState(false);

  const handleCopy = React.useCallback(() => {
    copyToClipboard(shareURL);
    dispatch(pushEvent({ event: CommonTrackingEvent.COPY_SHAREABLE_LINK_CLICKED }));
    setIsPopoverVisible(true);
    requestAnimationFrame(() => {
      inputRef.current?.focus();
      inputRef.current?.select();
    });
  }, [dispatch, shareURL]);

  if (isViewer || isEmpty(shareURL)) {
    return null;
  }

  return (
    <span className={className}>
      <Popover
        visible={isPopoverVisible}
        onVisibleChange={setIsPopoverVisible}
        placement="bottom-start"
        overflowContainer={popoverOverflowContainer}
        className={styles.sharePopover}
        content={
          <>
            <div className={styles.copiedInfo}>{t('common:link_copied_message')}</div>
            <div className={styles.message}>{isDefined(message) && message}</div>
            <Input ref={inputRef} size="small" isReadonly value={shareURL} />
          </>
        }
      >
        <IconButton name={tooltipText} icon={IconType.Link} variant="secondary-light" showTooltip onClick={handleCopy} />
      </Popover>
    </span>
  );
};
