import * as yup from 'yup';

import { NAME_ALLOWED_CHARACTERS } from '../../constants/regExp';
import i18n from '../../translation/i18n';

export const getContactPersonSchema = () =>
  yup
    .string()
    .defined()
    .matches(NAME_ALLOWED_CHARACTERS, {
      excludeEmptyString: true,
      message: i18n.t('validation:valid_name_hint'),
    });
