import { skipToken, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { isDefined } from '../../../utils/is';
import { fetchShippingAddresses } from '../../accountRepository';

interface Props {
  accountId: string | undefined;
}

export const useShippingAddresses = ({ accountId }: Props) => {
  const { t } = useTranslation(['shipping']);

  return useQuery({
    meta: {
      errorMessage: t('shipping:shipping_addresses_fetch_failure'),
    },
    queryFn: isDefined(accountId) ? async () => fetchShippingAddresses(accountId) : skipToken,
    queryKey: ['shippingAddresses', { accountId }],
  });
};
