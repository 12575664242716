import { useScreenWidth } from './useScreenWidth';

const PRODUCTS_PER_WIDTH = {
  0: 1,
  375: 2,
  720: 3,
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  1000: 4,
  1400: 5,
  1600: 6,
};

export const useProductsInRow = () => {
  const screenWidth = useScreenWidth();

  const productsCount = Object.entries(PRODUCTS_PER_WIDTH)
    .filter(([width]) => screenWidth >= parseInt(width))
    .map(([_, productsInRow]) => productsInRow);

  return Math.max(...productsCount);
};
