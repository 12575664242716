/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;
const ORIGINAL_COLOR = '#848484';

export const NoCamera = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => {
  return (
    <svg width={width} height={width} viewBox="0 0 48 48">
      <path
        d="m44 39.65-3-2.95V13.35h-8.75L28.6 9h-9.2l-2.8 3.3-2.1-2.1L18 6h12l3.65 4.35H41q1.15 0 2.075.925Q44 12.2 44 13.35ZM32.3 28 22.2 17.9q2.15-.35 4.225.175 2.075.525 3.625 2.075 1.55 1.55 2.1 3.625T32.3 28Zm-9.35-.75Zm5.85-2.75ZM10.35 10.35l3 3H7V39h27.7L1.3 5.6l2.15-2.15L43.8 43.8l-2.15 2.15L37.7 42H7q-1.2 0-2.1-.9Q4 40.2 4 39V13.35q0-1.2.9-2.1.9-.9 2.1-.9Zm7.75 9.85 2.15 2.15q-.85.8-1.275 1.775-.425.975-.425 2.125 0 2.25 1.6 3.85T24 31.7q1.1 0 2.1-.45T27.9 30l2.15 2.15q-1.2 1.25-2.775 1.9-1.575.65-3.275.65-3.5 0-5.975-2.475-2.475-2.475-2.475-5.975 0-1.7.65-3.275t1.9-2.775Z"
        fill={color}
      />
    </svg>
  );
};
