import { Product } from '@typings';
import { uniq } from 'ramda';

import { getVariantAttributeValue } from '../../../logic/products';
import { isDefined } from '../../is';

interface Props {
  productConfiguration: Product.ProductConfiguration | undefined;
  variants: Product.Standard[];
}

export const useProductConfiguration = ({ productConfiguration, variants }: Props) => {
  const attributesOrder = isDefined(productConfiguration) ? [productConfiguration.primary, ...(productConfiguration.secondary ?? [])] : [];

  const configurationAttributeTypes = attributesOrder.map(key => {
    const name = productConfiguration?.names[key] ?? '-';
    const attributesDetails = uniq<Product.AttributeDetails | undefined>(
      variants.map((variant: Product.Standard & { [key: string]: Product.AttributeDetails }) => variant[key]),
    )
      .filter(attributeValue => typeof attributeValue === 'object')
      .filter(isDefined);

    return { attributesDetails, key, name };
  });

  const configurationAttributeValuesPerVariant = variants.map(variant =>
    configurationAttributeTypes.map(({ key }) => getVariantAttributeValue(variant, key)),
  );

  return {
    configurationAttributeTypes,
    configurationAttributeValuesPerVariant,
  };
};
