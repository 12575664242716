import { Selections } from '@typings';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getOrderId } from '../../../ducks';
import { useShareSelection } from '../../../services/hooks/selection/useShareSelection';
import { useModalVisibility } from '../../../utils/hooks';
import { useAmplitude } from '../../../utils/hooks/useAmplitude';
import { ModalSize } from '../../various/ModalElements';
import { ModalShell } from '../../various/ModalShell';

import { SelectionShareForm } from './SelectionShareForm';
import { SelectionShareSuccess } from './SelectionShareSuccess';

export const SelectionShareModal = () => {
  const { t } = useTranslation(['selections', 'checkout', 'common']);
  const selectionId = useSelector(getOrderId);

  const { mutate, data, isPending, isSuccess, reset } = useShareSelection();
  const { isModalVisible, hideModal } = useModalVisibility('SelectionShareModal');
  const { trackEvent } = useAmplitude();

  const handleSubmit = (values: Selections.Share) => {
    mutate(
      { data: values, selectionId },
      {
        onSuccess: () => {
          trackEvent({ name: 'shareSelection.input.email.add' });
        },
      },
    );
  };

  return (
    <ModalShell
      isOpen={isModalVisible}
      title={t('selections:share_selection')}
      onClose={hideModal}
      size={ModalSize.Medium}
      modalClosedCallback={reset}
    >
      {isSuccess ?
        <SelectionShareSuccess emails={data.share} onConfirm={hideModal} />
      : <SelectionShareForm selectionId={selectionId} isPending={isPending} onCancel={hideModal} onSubmit={handleSubmit} />}
    </ModalShell>
  );
};
