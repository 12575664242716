import { Product } from '@typings';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getDefaultImageSize } from '../../../../../../../ducks';
import { getDefaultImageAspectRatio, getProductImageOrPlaceholderBySize } from '../../../../../../../ducks/helpers';
import { GetProductUrl } from '../../../../../../../logic/products';
import { CustomImage } from '../../../../../../various/CustomImage';
import { useProductDetailsVariantContext } from '../../../../context/ProductDetailsVariantContext';
import { VariantLabel } from '../../../../VariantLabel';

import styles from './NativeVariants.module.scss';

interface Props {
  variants: Product.Standard[];
  getProductUrl: GetProductUrl;
}

export const NativeVariants = ({ variants, getProductUrl }: Props) => {
  const { activeVariant, setActiveMedia } = useProductDetailsVariantContext();
  const imageSize = useSelector(getDefaultImageSize);
  const aspectRatio = useSelector(getDefaultImageAspectRatio);

  return (
    <ul className={styles.nativeVariants}>
      {variants.map(variant => {
        const image = getProductImageOrPlaceholderBySize(variant, imageSize);
        const isActive = activeVariant?.variant === variant.variant;

        return (
          <li key={image.variantId} className={styles.productVariant}>
            <Link
              to={getProductUrl({ productId: variant.product, variantId: variant.variant })}
              onClick={() => setActiveMedia(image)}
              replace
            >
              <div
                className={cx(styles.imageWrapper, {
                  [styles.active]: isActive,
                })}
              >
                <CustomImage aspectRatio={aspectRatio} rounded src={image.src} />
              </div>
              <VariantLabel size="small" variant={variant} />
            </Link>
          </li>
        );
      })}
    </ul>
  );
};
