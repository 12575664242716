/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 14;
const ORIGINAL_HEIGHT = 16;
const PROPORTION = ORIGINAL_HEIGHT / ORIGINAL_WIDTH;
const ORIGINAL_COLOR = '#5c5c5c';

export const Tools = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => {
  return (
    <svg width={width} height={width * PROPORTION} viewBox="0 0 14 16">
      <path
        fill={color}
        d="M12.8167 0.566666C12.7333 0.533333 10.8 0 7 0C3.23333 0 0.8 0.533333 0.7 0.549999C0.333333 0.633332 0.0666667 0.966666 0.0666667 1.33333V14.6667C0.0666667 15.05 0.333333 15.3667 0.7 15.45C0.8 15.4667 3.23333 16 7 16C7.41667 16 7.55 16 7.76667 15.9833L8.06667 15.9667C9.56667 15.9667 13.4 12.15 13.4 10.6667V1.33333C13.4 0.983333 13.1667 0.666666 12.8167 0.566666ZM6.75 8.66667C6.65 8.66667 6.55 8.63333 6.46667 8.56667C6.4 8.51667 4.35 7.18333 3.16667 5.46667C3.06667 5.33333 3.06667 5.16667 3.15 5.03333C3.21667 4.88333 3.36667 4.8 3.53333 4.8H5.4C5.56667 3.85 5.7 3.13333 5.86667 2.88333C6.01667 2.65 6.3 2.5 6.75 2.5C7.2 2.5 7.48333 2.65 7.63333 2.88333C7.76667 3.1 7.9 3.85 8.06667 4.8H9.93333C10.1 4.8 10.25 4.88333 10.3167 5.03333C10.4 5.16667 10.3833 5.33333 10.3 5.46667C9.11667 7.18333 7.1 8.51667 7.03333 8.56667C6.95 8.63333 6.85 8.66667 6.75 8.66667ZM7.53333 14.4V11.7333C7.53333 10.9167 8.31667 10.1333 9.13333 10.1333H11.8C11.8 10.7167 11.3167 11.2 10.7333 11.2H9.13333C8.9 11.2 8.6 11.5 8.6 11.7333V13.3333C8.6 13.9167 8.06667 14.4 7.53333 14.4Z"
      />
    </svg>
  );
};
