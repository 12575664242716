import { combineEpics } from 'redux-observable';
import { filter, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { fetchCountriesFailed, fetchCountriesRequest, fetchCountriesSuccess } from '../ducks/countries';
import { mapResponse } from '../utils/operators/mapResponse';

const getCountriesEpic: AppEpic = (action$, _, { countriesRepository }) =>
  action$.pipe(
    filter(isActionOf(fetchCountriesRequest)),
    mergeMap(async () => countriesRepository.getCountries()),
    mapResponse(
      response => fetchCountriesSuccess(response.data),
      () => fetchCountriesFailed(),
    ),
  );

export const countriesEpic = combineEpics(getCountriesEpic);
