import { Cms } from '@typings';
import { useTranslation } from 'react-i18next';

import Icon, { IconType } from '../../various/Icon';

import styles from './SegmentationInfo.module.scss';

interface Props {
  pageMeta: Cms.Page;
}

export const SegmentationInfo = ({ pageMeta }: Props) => {
  const { t } = useTranslation(['cms']);
  const { markets } = pageMeta;
  const marketsNumber = markets.length;

  return (
    <div className={styles.wrapper}>
      <Icon type={IconType.Market} />
      <span className={styles.markets}>{`${marketsNumber} ${t('cms:market', { count: marketsNumber })}`}</span>

      <div className={styles.popup}>
        <div className={styles.list}>
          <ul>
            {markets.map(({ id, name }) => (
              <li key={id}>{name}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
