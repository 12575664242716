import { useQuery } from '@tanstack/react-query';
import { Cms } from '@typings';
import { useTranslation } from 'react-i18next';

import { getPages } from '../../pagesRepository';

interface Props extends Cms.PageFilterData {
  refetchOnMount?: boolean;
}

export const PAGES_QUERY_KEY = 'pages';

export const usePages = ({ search, refetchOnMount = true }: Props) => {
  const { t } = useTranslation(['cms']);

  return useQuery({
    meta: {
      errorMessage: t('cms:fetch_pages_fail'),
    },
    queryFn: async () => getPages({ search }),
    queryKey: [PAGES_QUERY_KEY, { search }],
    refetchOnMount,
  });
};
