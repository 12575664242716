import { CmsV0 } from '@centra/cms/legacy/v0';
import { Cms } from '@typings';
import { pipe } from 'ramda';

import { setVersion } from '../sharedTransformationFunctions/setVersion';

import { convertBlocksPositionToGrid } from './transformationFunctions/convertBlocksPositionToGrid';
import { convertGroupBlocksOrder } from './transformationFunctions/convertGroupBlocksOrder';
import { convertLegacyTextParts } from './transformationFunctions/convertLegacyTextParts';
import { extractEnglishLanguageContent } from './transformationFunctions/extractEnglishLanguageContent';

export const v0 = (response: CmsV0.SectionsResponse): Cms.SectionsResponse => {
  return pipe(
    convertLegacyTextParts,
    extractEnglishLanguageContent,
    convertBlocksPositionToGrid,
    convertGroupBlocksOrder,
    setVersion(1),
  )(response);
};
