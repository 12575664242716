import { Navigation } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { getIsMenuItemActive, getIsUserFolderMenuItem } from '../../../logic/navigation';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { NavigationItem } from '../../navigation/NavigationItem';

import { NavigationDropdown } from './NavigationDropdown';
import styles from './TopMenu.module.scss';

interface Props {
  visibleItems: Navigation.UserMenuItem[];
  navigationItems: Navigation.UserMenuItem[];
}

export const MoreFolder = ({ visibleItems, navigationItems }: Props) => {
  const { t } = useTranslation(['common']);
  const { pathname } = useLocation();

  const dropdownItems = React.useMemo(() => {
    if (visibleItems.length === navigationItems.length) {
      return [];
    }

    return navigationItems.slice(visibleItems.length, navigationItems.length);
  }, [navigationItems, visibleItems]);

  if (isEmpty(dropdownItems)) {
    return null;
  }

  const hasActivePageChild = dropdownItems.some(item => {
    const isActivePage = item.type === 'page' && getIsMenuItemActive(`/pages/${item.url}`, pathname);

    if (!isDefined(item.children)) {
      return false;
    }

    return isActivePage || item.children.some(child => getIsMenuItemActive(`/pages/${child.url}`, pathname));
  });

  const more: Navigation.UserFolderMenuItem = {
    children: dropdownItems,
    label: t('common:more'),
    openNewTab: false,
    type: 'folder',
    url: null,
  };

  return (
    <NavigationDropdown
      activeClassName={styles.active}
      linkClassName={styles.link}
      isFolder
      dropdownItems={dropdownItems.map(item => {
        const isFolder = getIsUserFolderMenuItem(item);

        return (
          <li key={item.label}>
            <NavigationItem item={item} activeClassName={styles.active} linkClassName={cx(styles.link, { [styles.folder]: isFolder })} />
            {isDefined(item.children) && !isEmpty(item.children) && (
              <ul className={styles.innerMenu}>
                {item.children.map(el => (
                  <li key={el.label}>
                    <NavigationItem item={el} activeClassName={styles.active} linkClassName={styles.link} />
                  </li>
                ))}
              </ul>
            )}
          </li>
        );
      })}
    >
      <NavigationItem
        active={hasActivePageChild}
        item={more}
        hasChildren
        linkClassName={cx(styles.link, styles.folder)}
        activeClassName={styles.active}
      />
    </NavigationDropdown>
  );
};
