import React from 'react';
import { useSelector } from 'react-redux';

import {
  MAX_DESKTOP_SECTION_WIDTH,
  PRODUCT_DESCRIPTION_HEIGHT,
  PRODUCT_MARGIN,
  PRODUCT_PADDING,
  TOTAL_HORIZONTAL_SECTION_PADDING,
} from '../../../constants/cms';
import { getDefaultImageAspectRatio } from '../../../ducks/helpers';
import { calculateProductsPerRow } from '../../../logic/pages';
import { useScreenWidth, useSpecifiedDeviceWidth } from '../../../utils/hooks';

interface Props {
  useDeviceSwitcher?: boolean;
}

interface Context {
  currentSectionWidth: number;
  rowHeight: number;
  screenWidth: number;
}

const initialContext: Context = {
  currentSectionWidth: 0,
  rowHeight: 0,
  screenWidth: 0,
};

export const SizeContext = React.createContext<Context>(initialContext);

export const SizeContextProvider = ({ children }: React.WithChildren<Props>) => {
  const [rowHeight, setRowHeight] = React.useState(0);
  const specifiedDeviceWidth = useSpecifiedDeviceWidth();
  const screenWidth = useScreenWidth();

  const imageAspect = useSelector(getDefaultImageAspectRatio);
  const currentSectionWidth = Math.min(MAX_DESKTOP_SECTION_WIDTH, screenWidth - TOTAL_HORIZONTAL_SECTION_PADDING);

  React.useEffect(() => {
    const productsPerRow = calculateProductsPerRow(specifiedDeviceWidth);

    const productWidthPercent = 100 / productsPerRow / 100;
    const sectionWidth = Math.min(MAX_DESKTOP_SECTION_WIDTH, specifiedDeviceWidth - TOTAL_HORIZONTAL_SECTION_PADDING);

    const productWidth = sectionWidth * productWidthPercent - PRODUCT_PADDING;
    const imageHeight = productWidth * imageAspect;

    setRowHeight(imageHeight + PRODUCT_DESCRIPTION_HEIGHT + PRODUCT_MARGIN);
  }, [imageAspect, specifiedDeviceWidth]);

  return (
    <SizeContext.Provider
      value={{
        currentSectionWidth,
        rowHeight,
        screenWidth,
      }}
    >
      {children}
    </SizeContext.Provider>
  );
};
