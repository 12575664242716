/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 14;

export const Success = ({ width = ORIGINAL_WIDTH }: IconComponentProps) => {
  return (
    <svg width={width} height={width} viewBox="0 0 14 14">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 7C0 3.136 3.136 0 7 0C10.864 0 14 3.136 14 7C14 10.864 10.864 14 7 14C3.136 14 0 10.864 0 7ZM3.18309 7.81964L5.43035 10.0669C5.67448 10.311 6.07511 10.311 6.31298 10.0669L11.0642 5.31573C11.3083 5.0716 11.3083 4.67723 11.0642 4.4331C10.82 4.18897 10.4257 4.18897 10.1815 4.4331L5.8748 8.73983L4.06572 6.93701C3.82159 6.69288 3.42722 6.69288 3.18309 6.93701C3.06587 7.05396 3 7.21274 3 7.37833C3 7.54391 3.06587 7.70269 3.18309 7.81964Z"
        fill="#4EA14E"
      />
    </svg>
  );
};
