import { Id } from '@typings';
import { createAction } from 'typesafe-actions';

import { CmsProductsPayload } from '../../../services/productsRepository';

export const fetchCmsProductsRequest = createAction('cms/PRODUCTS_FETCH_REQUEST')<{
  skipFirst: number;
  marketIds?: Id[];
  search?: string;
  products?: Id[];
  limit?: number;
}>();

export const fetchCmsProductsSuccess = createAction('cms/PRODUCTS_FETCH_SUCCESS')<Responses.CmsProducts>();
export const fetchCmsProductsFailure = createAction('cms/PRODUCTS_FETCH_FAILURE')();

export const loadMoreCmsProductsRequest = createAction('cms/PRODUCTS_LOAD_MORE_REQUEST')<{
  marketIds: Id[];
  skipFirst: number;
  search?: string;
}>();

export const fetchCmsProductsForPreviewRequest = createAction('cms/PRODUCTS_FOR_PREVIEW_FETCH_REQUEST')<
  Omit<CmsProductsPayload, 'search'>
>();

export const fetchCmsProductsForPreviewSuccess = createAction('cms/PRODUCTS_FOR_PREVIEW_FETCH_SUCCESS')<Responses.CmsProducts>();

export const loadMoreCmsProductsSuccess = createAction('cms/PRODUCTS_LOAD_MORE_SUCCESS')<Responses.CmsProducts>();
export const loadMoreCmsProductsFailure = createAction('cms/PRODUCTS_LOAD_MORE_FAILURE')();

export const searchProductsRequest = createAction('cms/SEARCH_PRODUCTS_REQUEST')<{
  marketIds: Id[];
  search?: string;
}>();

export const searchProductsSuccess = createAction('cms/SEARCH_PRODUCTS_SUCCESS')<Responses.CmsProducts>();
