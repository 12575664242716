import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from '../Table.module.scss';

interface ExpanderCellProps {
  onExpand?: VoidFunction;
  className?: string;
  expanded?: boolean;
}

export const ExpanderCell = ({ onExpand, className, expanded }: ExpanderCellProps) => {
  const { t } = useTranslation(['common']);
  const classNames = cx(styles.expandIcon, className, {
    [styles.expandIconCollapsed]: !expanded,
    [styles.expandIconExpanded]: expanded,
  });

  return <button tabIndex={0} className={classNames} aria-label={t('common:expand_row')} onClick={onExpand} />;
};
