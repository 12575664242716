/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 23;

export const Help = ({ width = ORIGINAL_WIDTH }: IconComponentProps) => (
  <svg width={width} height={width} viewBox="0 0 23 23">
    <g stroke="none" strokeWidth="1">
      <g transform="translate(-955.000000, -655.000000)">
        <path d="M966.5,655 C972.848958,655 978,660.151042 978,666.5 C978,672.848958 972.848958,678 966.5,678 C960.151042,678 955,672.848958 955,666.5 C955,660.151042 960.151042,655 966.5,655 Z M965.875598,670 L967.430622,670 C967.861244,670 968.196172,669.609756 968.196172,669.170732 C968.339713,668.512195 968.866029,667.97561 969.344498,667.560976 C970.397129,666.585366 971.138756,665.829268 971.449761,665.243902 C971.784689,664.682927 972,664.073171 972,663.414634 C972,662.268293 971.497608,661.146341 970.588517,660.268293 C969.703349,659.414634 968.507177,659 967,659 C964.272727,659 962.598086,660.97561 962.07177,662.682927 C962.07177,662.707317 962.023923,662.829268 962.023923,662.829268 C962.023923,662.902439 962,662.95122 962,663.02439 C962,663.463415 962.334928,663.756098 962.76555,663.756098 L964.368421,663.756098 C964.727273,663.756098 965.038278,663.536585 965.110048,663.170732 C965.301435,662.439024 966.114833,661.609756 967.119617,661.609756 C967.741627,661.609756 968.244019,661.829268 968.602871,662.170732 C968.961722,662.536585 969.129187,662.829268 969.129187,663.317073 C969.129187,663.682927 969.057416,664.02439 968.818182,664.317073 C967.69378,665.756098 965.157895,666.804878 965.086124,669.219512 C965.086124,669.658537 965.444976,670 965.875598,670 Z M966.5,675 C967.30137,675 968,674.30137 968,673.5 C968,672.69863 967.30137,672 966.5,672 C965.69863,672 965,672.69863 965,673.5 C965,674.30137 965.69863,675 966.5,675 Z" />
      </g>
    </g>
  </svg>
);
