import cx from 'classnames';
import React from 'react';

import universalStyles from '../../../../css/utilities/universal.module.scss';
import Icon, { IconType } from '../Icon';

import styles from './IconRadioGroup.module.scss';

interface Props {
  onChange: (value: string | number) => void;
  selectedValue: string | number;
  groupName: string;
  options: IconOption[];
  className?: string;
  dataTestId?: string;
}

export interface IconOption {
  icon: IconType;
  value: string | number;
  name?: string;
}

export const IconRadioGroup = ({ onChange, selectedValue, options, groupName, className, dataTestId }: Props) => {
  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value);
    },
    [onChange],
  );

  return (
    <fieldset>
      <legend className={universalStyles.srOnly}>{groupName}</legend>
      <div role="radiogroup" className={cx(styles.radioGroup, className)} data-testid={dataTestId}>
        {options.map(option => {
          const checked = selectedValue === option.value;

          return (
            <label key={option.value}>
              <input
                type="radio"
                name={groupName}
                value={option.value}
                checked={checked}
                aria-checked={checked}
                onChange={handleChange}
                className={cx(universalStyles.srOnly, styles.input)}
              />
              <div className={styles.radio}>
                <Icon type={option.icon} />
              </div>
              <span className={universalStyles.srOnly}>{option.name ?? option.value}</span>
            </label>
          );
        })}
      </div>
    </fieldset>
  );
};
