import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 5;
const ORIGINAL_HEIGHT = 6;
const ORIGINAL_COLOR = '#D3D4D5';
const PROPORTION = ORIGINAL_HEIGHT / ORIGINAL_WIDTH;

export const ArrowThinRight = ({ width = 6, color = ORIGINAL_COLOR }: IconComponentProps): JSX.Element => {
  const strokeWidth = ORIGINAL_WIDTH / width;

  return (
    <svg width={width} height={width * PROPORTION} version="1.1" viewBox="0 0 5 8">
      <g stroke="none" strokeWidth={strokeWidth} fill="none" fillRule="evenodd">
        <path d="M1,0 L3.945,3.749 L1,7.498" stroke={color} />
      </g>
    </svg>
  );
};
