import { Cms, CmsBlocks } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { getCmsSectionById, getManualLayoutEditorBlockTypeToSelect, getSectionIdByGroupId } from '../../../ducks';
import { getCmsProducts } from '../../../ducks/cms/products';
import { getCurrentPriceList } from '../../../ducks/pricelists';
import { getProducts } from '../../../ducks/products';
import { getProductBlockHeight, getScreenRelativeStyles } from '../../../logic/pages';
import { convertCmsProductToStandard, getUrlForProductAndVariant } from '../../../logic/products';
import { useIsInViewMode, useSpecifiedDeviceWidth } from '../../../utils/hooks';
import { isDefined } from '../../../utils/is';
import { isEveryElementInArray } from '../../../utils/isEveryElementInArray';
import { ManualProductLayoutSelector, ProductSelectorDisabledOverlay } from '../../cms/ManualLayoutEditor/ManualLayoutSelector';
import { Product } from '../../products/Product';

import styles from './productBlock.module.scss';

export const createProductBlock =
  (extensions?: CmsBlocks.BlockExtensions<Cms.ProductBlock>) => (props: CmsBlocks.BlockProps<Cms.ProductBlock>) => {
    const { parameters, rowHeight, isManualLayoutEditing } = props;
    const { position, groupId, settings, id } = parameters;
    const { products: productsList } = settings;

    const screenWidth = useSpecifiedDeviceWidth();
    const allProducts = useSelector(getProducts);
    const cmsProducts = useSelector(getCmsProducts);
    const currentPriceList = useSelector(getCurrentPriceList);
    const isInEditor = useIsInViewMode('editor');
    const isInPreview = useIsInViewMode('preview');
    const isInCms = isInEditor || isInPreview;
    const sectionId = useSelector(getSectionIdByGroupId(groupId));
    const section = useSelector(getCmsSectionById(sectionId ?? null));
    const blockTypeToSelect = useSelector(getManualLayoutEditorBlockTypeToSelect);
    const screenRelativePosition = getScreenRelativeStyles(screenWidth, position);

    const calculatedHeight = isInEditor ? getProductBlockHeight(rowHeight, screenRelativePosition) : 'auto';
    const blockHeight = typeof calculatedHeight === 'number' ? Math.round(calculatedHeight) : calculatedHeight;

    const products = React.useMemo(() => {
      return isInCms ? convertCmsProductToStandard(cmsProducts, currentPriceList) : allProducts;
    }, [isInCms, cmsProducts, currentPriceList, allProducts]);

    const shouldSelectProducts = blockTypeToSelect === 'product';
    const productsWrapperStyles = {
      gridTemplateColumns: `repeat(${screenRelativePosition.spanX}, minmax(0, 1fr))`,
      height: blockHeight,
    };

    return (
      <>
        <div className={styles.productsWrapper} style={productsWrapperStyles}>
          {isManualLayoutEditing && !shouldSelectProducts && <ProductSelectorDisabledOverlay />}
          {productsList.map(product => {
            const isInCorrectMarket =
              isDefined(section) &&
              isEveryElementInArray(
                section.visibleFor.marketIds,
                cmsProducts[product]?.markets.map(market => market.toString()),
              );

            const productData = products[product];

            if (!isDefined(productData)) {
              return null;
            }

            const classNames = cx(styles.product, {
              [styles.linksDisabled]: isInCms,
              [styles.invalid]: isInEditor && !isInCorrectMarket,
              [styles.selectableProduct]: isManualLayoutEditing,
            });

            const productStyles = { height: rowHeight };

            const productElement = (
              <Product
                key={product}
                className={classNames}
                style={productStyles}
                productInfo={productData}
                shouldHideButtons={isInCms}
                isHoverable={!isInEditor}
                getProductUrl={getUrlForProductAndVariant}
              />
            );

            if (isManualLayoutEditing) {
              return (
                <div key={product} style={productStyles} className={styles.manualLayoutEditingWrapper}>
                  {shouldSelectProducts && <ManualProductLayoutSelector blockId={id} productId={product} />}
                  {productElement}
                </div>
              );
            }

            return productElement;
          })}
        </div>
        {isDefined(extensions) && Object.entries(extensions).map(([name, Extension]) => <Extension key={name} {...props} />)}
      </>
    );
  };
