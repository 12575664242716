import cx from 'classnames';

import styles from './FormFieldset.module.scss';

interface Props {
  className?: string;
  isDisabled?: boolean;
}

export const FormFieldset = ({ children, className, isDisabled }: React.PropsWithChildren<Props>) => {
  return (
    <fieldset className={cx(styles.fieldset, className)} disabled={isDisabled}>
      {children}
    </fieldset>
  );
};
