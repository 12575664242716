import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { getMarkets } from '../../../../../ducks';
import { useValidatedForm } from '../../../../../utils/hooks/useValidatedForm';
import { isEmpty } from '../../../../../utils/isEmpty';
import { Checkbox } from '../../../../various/Fields/Checkbox';
import { FieldError, Form, FormButtons, FormGrid } from '../../../../various/Form';
import Icon, { IconColor, IconType } from '../../../../various/Icon';
import { Button } from '../../../../various/NewButton';
import { SeparatorItem } from '../../../../various/SeparatorItem';

import styles from './MarketSelectForm.module.scss';

interface FormData {
  markets: string[];
}

interface Props {
  onClose: VoidFunction;
  selectedMarkets: string[];
  onSubmit: (value: string[]) => void;
}

const ERROR_MESSAGE_ID = 'markets-error';

export const MarketSelectForm = ({ onClose, selectedMarkets, onSubmit }: Props) => {
  const { t } = useTranslation(['common', 'settings', 'validation']);
  const allMarkets = useSelector(getMarkets);
  const validationSchema: yup.ObjectSchema<FormData> = yup.object({
    markets: yup.array(yup.string().defined()).min(1, t('validation:min_one_market_hint')).required(),
  });

  const defaultValues: FormData = {
    markets: selectedMarkets,
  };

  const formMethods = useValidatedForm<FormData>({
    defaultValues,
    validationSchema,
  });

  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = formMethods;

  const handleSubmit = (value: FormData) => {
    onSubmit(value.markets);
  };

  const markets = watch('markets');

  const handleToggleAll = () => {
    if (markets.length === allMarkets.length) {
      setValue('markets', [], { shouldTouch: true, shouldValidate: true });

      return;
    }

    setValue(
      'markets',
      allMarkets.map(market => market.id),
      { shouldTouch: true, shouldValidate: true },
    );
  };

  const errorMessage = errors.markets?.message as string | undefined;

  return (
    <Form formMethods={formMethods} onSubmit={handleSubmit}>
      <div className={styles.form}>
        <div className={styles.header}>
          <p className={styles.title}>{t('settings:market_select_title')}</p>
          <button type="button" aria-label={t('common:close')} className={cx(styles.close)} onClick={onClose}>
            <Icon type={IconType.Close} color={IconColor.Gray} size={20} />
          </button>
        </div>
        <div className={styles.topCheckbox}>
          <Checkbox
            errorMessageId={ERROR_MESSAGE_ID}
            onChange={handleToggleAll}
            value="all"
            checked={markets.length === allMarkets.length}
            indeterminate={!isEmpty(markets) && markets.length < allMarkets.length}
          >
            <SeparatorItem title={`${markets.length} Selected`} classNames={styles.topCheckboxLabel} />
          </Checkbox>
        </div>
        <div className={styles.checkboxGrid}>
          <FormGrid cols={2} rowGap="small">
            {allMarkets.map((market, index) => {
              return (
                <Checkbox key={`${index}-${market.id}`} {...register('markets')} value={market.id} checked={markets.includes(market.id)}>
                  {market.name}
                </Checkbox>
              );
            })}
          </FormGrid>
        </div>
        <div className={styles.errorWrapper}>
          <FieldError id={ERROR_MESSAGE_ID}>{errorMessage}</FieldError>
        </div>
        <div className={styles.footer}>
          <FormButtons topSpacing="small">
            <Button size="small" variant="ghost" color="dark" onClick={onClose}>
              {t('common:cancel')}
            </Button>
            <Button size="small" type="submit">
              {t('common:save')}
            </Button>
          </FormButtons>
        </div>
      </div>
    </Form>
  );
};
