import { createColumnHelper, getCoreRowModel, getSortedRowModel } from '@tanstack/react-table';
import { Navigation, Translations } from '@typings';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getCmsNavigationItems, getHasCmsNavigationFailed } from '../../../ducks';
import { flattenNavigationItems } from '../../../logic/navigation';
import { useTranslations } from '../../../services/hooks/translations/useTranslations';
import { useTranslationsConfig } from '../../../utils/hooks';
import { useTable } from '../../../utils/hooks/table/useTable';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { Input } from '../../various/Fields/Input';
import { Table } from '../../various/Table';

import { NavigationItemIcon } from './NavigationItemIcon';
import styles from './TranslationsContent.module.scss';

interface Props {
  isLoading: boolean;
}

interface TableRow extends Partial<Translations.AllLanguagesTranslations> {
  id: string;
  type: Navigation.AnyMenuItemType;
}

const columnHelper = createColumnHelper<TableRow>();

export const NavigationItemsTable = ({ isLoading }: Props) => {
  const { t } = useTranslation(['common', 'translations']);
  const { additionalLanguages } = useTranslationsConfig();
  const navigationItems = useSelector(getCmsNavigationItems);
  const hasCMSFetchNavigationFailed = useSelector(getHasCmsNavigationFailed);

  const flattenedNavigationItems = React.useMemo(() => flattenNavigationItems(navigationItems), [navigationItems]);
  const hasNavigationItems = !isEmpty(navigationItems);
  const hasContent = hasNavigationItems && !isLoading;

  const { data: translations } = useTranslations();

  const { register } = useFormContext<Translations.TranslationsDTOAdditional>();

  const getItemTranslations = React.useCallback(
    ({ id, type }: Navigation.AnyMenuItem) => {
      if (!isDefined(translations)) {
        return null;
      }

      const translationsForId = translations.navigationItems[id];

      return {
        id,
        type,
        ...translationsForId,
      };
    },
    [translations],
  );

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('en', {
        cell: info => (
          <span className={styles.field}>
            <NavigationItemIcon type={info.row.original.type} />
            {info.getValue()}
          </span>
        ),
        header: t('common:field'),
        meta: {
          width: 210,
        },
      }),
      ...additionalLanguages.map(({ code, label }) =>
        columnHelper.accessor(code, {
          cell: info => {
            const { en, id } = info.row.original;

            return (
              <label aria-label={t('translations:translation_for', { code, label: en })}>
                <Input {...register(`navigationItems.${id}.${code}`)} size="large" placeholder={en} />
              </label>
            );
          },
          enableSorting: false,
          header: label,
          meta: {
            skeletonType: 'input',
            width: 235,
          },
        }),
      ),
    ],
    [t, additionalLanguages, register],
  );

  const dataSource = React.useMemo(
    () => flattenedNavigationItems.map(getItemTranslations).filter(isDefined),
    [flattenedNavigationItems, getItemTranslations],
  );

  const table = useTable({
    columns,
    data: dataSource,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (hasCMSFetchNavigationFailed) {
    return null;
  }

  return (
    <>
      <h3 className={styles.header}>{t('translations:navigation_menu_items')}</h3>
      {!hasContent && !isLoading && <span className={styles.infoText}>{t('translations:no_navigation_items')}</span>}
      {(hasContent || isLoading) && <Table table={table} fixedLayout stickyColumn isLoading={isLoading} skeletonRows={2} />}
    </>
  );
};
