export const getToken = (): string | null => {
  return localStorage.getItem('token');
};

export const getAuthHeader = (base: string, token: string | null) => {
  return token === null ?
      { Accept: base }
    : {
        Accept: `${base} api-token:${token}`,
      };
};
