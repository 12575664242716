import { Cms } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { clamp } from '../../../../utils/clamp';
import { isDefined } from '../../../../utils/is';
import { TextEllipsis } from '../../../various/TextEllipsis';
import { StyleSelectContext } from '../../context/StyleSelectContext';

import styles from './ContentPartStyleSelect.module.scss';
import { DeleteStyleButton } from './DeleteStyleButton';

const MAX_TOP_POSITION = 120;

interface Props {
  partStyle: Cms.ContentPartStyle;
  onApply: () => void;
  onEdit: () => void;
  scrollPosition: number;
  focusActiveChild: () => void;
}

export const SubMenu = ({ partStyle, onApply, scrollPosition, onEdit, focusActiveChild }: Props) => {
  const { t } = useTranslation(['cms']);
  const { isTextStyle, setActiveSubOption, activeSubOption } = React.useContext(StyleSelectContext);

  const menuElementRef = React.useCallback(
    (menuElement: Nullable<HTMLDivElement>) => {
      if (!isDefined(menuElement) || !isDefined(menuElement.parentElement)) {
        return;
      }

      const top = clamp(0, MAX_TOP_POSITION)(menuElement.parentElement.offsetTop - scrollPosition);
      // eslint-disable-next-line functional/immutable-data
      menuElement.style.top = `${top}px`;
    },
    [scrollPosition],
  );

  const onApplyHandler = React.useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      onApply();
    },
    [onApply],
  );

  const onEditHandler = React.useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      onEdit();
    },
    [onEdit],
  );

  const activateOption = (index: number) => () => setActiveSubOption(index);
  const deactivateOption = (index: number) => () => setActiveSubOption(option => (option === index ? null : option));

  return (
    <div className={cx(styles.listHolder, styles.subMenu)} ref={menuElementRef}>
      <div className={styles.subMenuHolder}>
        <ul className={styles.list} role="listbox">
          <TextEllipsis
            as="li"
            className={cx(styles.option, {
              [styles.active]: activeSubOption === 0,
            })}
            role="option"
            aria-selected={false}
            onClick={onApplyHandler}
            onMouseEnter={activateOption(0)}
            onMouseLeave={deactivateOption(0)}
            data-testid="applyCustomStyle"
          >
            {t('cms:apply_custom_style', { customStyleName: partStyle.name })}
          </TextEllipsis>
          <TextEllipsis
            as="li"
            className={cx(styles.option, {
              [styles.active]: activeSubOption === 1,
            })}
            role="option"
            aria-selected={false}
            onClick={onEditHandler}
            onMouseEnter={activateOption(1)}
            onMouseLeave={deactivateOption(1)}
            data-testid="editCustomStyle"
          >
            {isTextStyle ? t('cms:edit_text_style') : t('cms:edit_button_style')}
          </TextEllipsis>
        </ul>
        <DeleteStyleButton partStyle={partStyle} isFocused={activeSubOption === 2} onBlur={focusActiveChild} />
      </div>
    </div>
  );
};
