import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Heading from '../various/Heading';
import Icon, { IconType } from '../various/Icon';

import styles from './somethingWentWrong.module.scss';

export const SomethingWentWrong = () => {
  const { t } = useTranslation(['common', 'genericErrors']);
  const [isAnimate, setIsAnimate] = React.useState(false);

  const reloadHandler = React.useCallback(() => {
    location.reload();
    setIsAnimate(true);
  }, []);

  return (
    <div className={styles.wrapper}>
      <Heading title={t('common:something_went_wrong')} variant={['big', 'black']} />
      <button className={styles.button} onClick={reloadHandler}>
        <Icon type={IconType.Reload} className={cx({ [styles.animate]: isAnimate })} />
        <span className={styles.text}>{t('genericErrors:refresh_page')}</span>
      </button>
    </div>
  );
};
