import { Product } from '@typings';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getProductInfo } from '../../../../ducks';
import { createProductImageSelector, getDefaultImageAspectRatio } from '../../../../ducks/helpers';
import { ProductMatrixSectionGeneral } from '../../../products/ProductMatrix/ProductMatrixSectionGeneral';
import { CustomImage } from '../../../various/CustomImage';

import styles from './GrayedOutProduct.module.scss';

interface Props {
  delwinId: string;
  product: Product.Full;
  variants: Product.Standard[];
}

const getMedia = createProductImageSelector(getProductInfo);

export const GrayedOutProduct = ({ product, delwinId, variants }: Props) => {
  const { product: productId, name, productSku: artNo } = product;

  const { t } = useTranslation(['cancelled', 'common']);

  const aspectRatio = useSelector(getDefaultImageAspectRatio);
  const { src: pictureSrc } = useSelector((state: Store) => getMedia(state, productId));

  return (
    <div className={cx(styles.container, styles.transparent)}>
      <div className={styles.infoGrid}>
        <div className={styles.image}>
          <CustomImage enlargeOnHover aspectRatio={aspectRatio} src={pictureSrc || ''} />
        </div>

        <div className={styles.info}>
          <h3 className={styles.title}>{name}</h3>

          <span className={styles.sku}>{t('common:art_no', { artNo })}</span>
        </div>

        <span className={styles.warning}>{t('cancelled:product_cancelled')}</span>
      </div>

      <div className={styles.matrixSection}>
        <ProductMatrixSectionGeneral variants={variants} isReadOnly showOnlyDeliveryWindows={[delwinId]} showCancelled />
      </div>
    </div>
  );
};
