import React from 'react';

import { isDefined } from '../../../utils/is';
import { Modal, ModalCloseButton, ModalHeading, ModalSheet, ModalSize } from '../ModalElements';

interface Props {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  className?: string;
  innerClassName?: string;
  headingClassName?: string;
  size?: ModalSize;
  equalPadding?: boolean;
  shouldReturnFocusAfterClose?: boolean;
  modalClosedCallback?: () => void;
  hideCloseIcon?: boolean;
  shouldCloseOnEsc?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  shouldCloseOnLocationChange?: boolean;
  centerModal?: boolean;
}

export const ModalShell = ({
  title,
  isOpen,
  onClose,
  innerClassName,
  headingClassName,
  size = ModalSize.Large,
  equalPadding,
  modalClosedCallback,
  hideCloseIcon = false,
  children,
  shouldCloseOnEsc,
  shouldCloseOnOverlayClick,
  shouldReturnFocusAfterClose,
  shouldCloseOnLocationChange,
  centerModal = true,
}: React.WithChildren<Props>) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onClose}
    size={size}
    modalClosedCallback={modalClosedCallback}
    shouldCloseOnEsc={shouldCloseOnEsc}
    shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    hideCloseIcon={hideCloseIcon}
    shouldCloseOnLocationChange={shouldCloseOnLocationChange}
    shouldReturnFocusAfterClose={shouldReturnFocusAfterClose}
    centerModal={centerModal}
  >
    <ModalHeading smallPadding={equalPadding} className={headingClassName}>
      {title}
    </ModalHeading>
    <ModalSheet contentLabel={title} equalPadding={equalPadding} className={innerClassName}>
      {isDefined(children) && children}
    </ModalSheet>
    {!hideCloseIcon && <ModalCloseButton />}
  </Modal>
);
