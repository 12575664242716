import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { fetchDeliveryWindowsByBuyerIdRequest } from '../../ducks';
import { createSelection } from '../../ducks/order';
import { getIsSeller, getUser } from '../../ducks/user';
import { useModalVisibility } from '../../utils/hooks';
import { isDefined } from '../../utils/is';
import { ModalSize } from '../various/ModalElements';
import { ModalShell } from '../various/ModalShell';

import { OrderSettingsForm } from './OrderSettingsForm';

interface SubmitProps {
  name: string;
  buyer?: string;
}

export const CreateOrderModal = React.memo(() => {
  const { t } = useTranslation(['common', 'selections']);
  const dispatch = useDispatch();
  const { isModalVisible, hideModal } = useModalVisibility('CreateOrderModal');
  const user = useSelector(getUser);
  const isSeller = useSelector(getIsSeller);

  const submit = React.useCallback(
    ({ name, buyer }: SubmitProps) => {
      const currentBuyer = isSeller ? buyer : user.buyer;

      isDefined(currentBuyer) && dispatch(fetchDeliveryWindowsByBuyerIdRequest({ buyerId: currentBuyer }));

      const buyerKey = isSeller ? { buyer } : {};

      dispatch(
        createSelection({
          name,
          redirectPath: 'HOME',
          ...buyerKey,
        }),
      );
    },
    [dispatch, isSeller, user.buyer],
  );

  return (
    <ModalShell isOpen={isModalVisible} onClose={hideModal} size={ModalSize.Medium} title={t('selections:create_selection')}>
      <OrderSettingsForm submitTitle={t('common:create')} onClose={hideModal} onSubmit={submit} />
    </ModalShell>
  );
});
