import { useTranslation } from 'react-i18next';

import Icon, { IconType } from '../../Icon';
import styles from '../ScannerContent.module.scss';

export const PreparingScanner = () => {
  const { t } = useTranslation(['common']);

  return (
    <div className={styles.message}>
      <div className={styles.loading}>
        <Icon type={IconType.Spinner} />
        {t('common:preparing_scanner')}
      </div>
    </div>
  );
};
