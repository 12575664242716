/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;
const ORIGINAL_COLOR = '#848484';

export const Reposition = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => (
  <svg width={width} height={width} viewBox="0 0 20 20" fill="none">
    <path
      fill={color}
      d="M9.16341 14.5239H8.08043C7.85982 14.5239 7.74808 14.7903 7.89993 14.9479L9.82237 16.9248C9.91978 17.0251 10.0831 17.0251 10.1805 16.9248L12.1029 14.9479C12.2576 14.7903 12.1459 14.5239 11.9224 14.5239H10.8395V10.8366H14.5268V11.9196C14.5268 12.1402 14.7932 12.2519 14.9508 12.1001L16.9277 10.1776C17.0279 10.0802 17.0279 9.91691 16.9277 9.8195L14.9508 7.89706C14.7932 7.74235 14.5268 7.85409 14.5268 8.07756V9.16054H10.8366V5.47611H11.9196C12.1402 5.47611 12.2519 5.20966 12.1001 5.05208L10.1776 3.07521C10.0802 2.97493 9.91691 2.97493 9.8195 3.07521L7.89706 5.05208C7.74235 5.20966 7.85409 5.47611 8.07756 5.47611H9.16054V9.16341H5.47611V8.08043C5.47611 7.85982 5.20966 7.74808 5.05208 7.89993L3.07521 9.82237C2.97493 9.91978 2.97493 10.0831 3.07521 10.1805L5.05208 12.1029C5.20966 12.2576 5.47611 12.1459 5.47611 11.9224V10.8395H9.16341V14.5239Z"
    />
  </svg>
);
