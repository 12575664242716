import { Id } from '@typings';

const getHasDefaultProperty = (object: Record<string, unknown>) => 'default' in object;

export function listToRecord<T extends Record<K, Id>, K extends keyof T>(list: T[], idKey: K): Record<Id, T> {
  return list.reduce<Record<string, T>>((map, object) => ({ ...map, [object[idKey]]: object }), {});
}

export function listStylesToRecord<T extends Record<K, Id> & { properties: Record<string, unknown> }, K extends keyof T>(
  list: T[],
  idKey: K,
): Record<Id, T> {
  return list.reduce<Record<string, T>>((map, object) => {
    const hasDefault = getHasDefaultProperty(object.properties);
    const currentValue = hasDefault ? object : { ...object, properties: { default: object.properties } };

    return { ...map, [object[idKey]]: currentValue };
  }, {});
}

export function toIdList<T extends Record<K, Id>, K extends keyof T>(list: T[], idKey: K): Id[] {
  return list.map(element => element[idKey]);
}
