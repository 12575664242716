import { useSelector } from 'react-redux';

import { getIsLoadingOrderDetails } from '../../../../../ducks';
import { Skeleton } from '../../../../various/Skeleton';
import { SkeletonLayout } from '../../../../various/SkeletonLayout';

import styles from './ContentRow.module.scss';

interface Props {
  label: string;
  value?: string | boolean;
}

export const ContentRow = ({ label, value }: Props) => {
  const isLoadingOrderDetails = useSelector(getIsLoadingOrderDetails);

  return (
    <div className={styles.gridRow}>
      <div className={styles.label}>{label}:</div>
      <SkeletonLayout isLoading={isLoadingOrderDetails} skeleton={<Skeleton type="text" size="medium" width={60} />}>
        <div data-sentry-mask>{value ?? ''}</div>
      </SkeletonLayout>
    </div>
  );
};
