import Icon, { IconColor, IconType } from '../Icon/Icon';

interface Props {
  className?: string;
  style?: React.CSSProperties;
  rotation?: number;
  size?: number;
  type: 'up' | 'down';
}

export const Trend = ({ type, ...rest }: Props) => {
  return (
    <Icon
      type={IconType.ArrowDown}
      color={type === 'up' ? IconColor.Green : IconColor.RomanRed}
      rotation={type === 'up' ? 180 : 0}
      {...rest}
    />
  );
};
