import i18next from 'i18next';
import { combineEpics } from 'redux-observable';
import { filter, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { addToast } from '../components/various/Toasts';
import { fetchPricelistsFailure, fetchPricelistsRequest, fetchPricelistsSuccess } from '../ducks';
import { mapResponse } from '../utils/operators/mapResponse';

const fetchPricelistsEpic: AppEpic = (action$, _, { pricelistRepository }) =>
  action$.pipe(
    filter(isActionOf(fetchPricelistsRequest)),
    mergeMap(pricelistRepository.getPricelists),
    mapResponse(
      res => fetchPricelistsSuccess(res.data),
      () => {
        addToast(i18next.t('cms:fetch_pricelists_fail'));

        return fetchPricelistsFailure();
      },
    ),
  );

export const pricelistsEpic = combineEpics(fetchPricelistsEpic);
