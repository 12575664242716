/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;
const ORIGINAL_COLOR = '#848484';

export const Revert = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => {
  return (
    <svg width={width} height={width} viewBox="0 0 20 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5842 4.51489C11.4991 4.06571 10.3052 3.94843 9.15352 4.17789C8.00181 4.40734 6.94403 4.97323 6.11398 5.80395L6.11298 5.80495L4.81444 7.09967H6.64726C7.06148 7.09967 7.39726 7.43546 7.39726 7.84967C7.39726 8.26389 7.06148 8.59967 6.64726 8.59967H3.0015C2.99538 8.59968 2.98927 8.59962 2.98315 8.59948C2.89786 8.5976 2.81608 8.58147 2.74009 8.55339C2.6411 8.5169 2.54828 8.45884 2.46889 8.37922C2.37749 8.28756 2.31473 8.17816 2.28058 8.06215C2.27057 8.02816 2.26302 7.9936 2.25793 7.95876C2.25218 7.91948 2.24956 7.87983 2.25006 7.84022V4.20247C2.25006 3.78826 2.58585 3.45247 3.00006 3.45247C3.41427 3.45247 3.75006 3.78826 3.75006 4.20247V6.04272L5.0535 4.74311C6.09318 3.70282 7.418 2.99418 8.86043 2.7068C10.3031 2.41937 11.7987 2.56628 13.1579 3.12896C14.5171 3.69163 15.6789 4.64479 16.4963 5.86785C17.3137 7.09092 17.75 8.52894 17.75 10C17.75 11.4711 17.3137 12.9091 16.4963 14.1322C15.6789 15.3552 14.5171 16.3084 13.1579 16.871C11.7987 17.4337 10.3031 17.5806 8.86043 17.2932C7.41772 17.0058 6.09267 16.2969 5.05289 15.2563C4.76011 14.9633 4.76031 14.4884 5.05332 14.1956C5.34633 13.9028 5.82121 13.903 6.11398 14.1961C6.94403 15.0268 8.00181 15.5927 9.15352 15.8221C10.3052 16.0516 11.4991 15.9343 12.5842 15.4851C13.6692 15.0359 14.5966 14.275 15.2492 13.2987C15.9017 12.3223 16.25 11.1743 16.25 10C16.25 8.82566 15.9017 7.6777 15.2492 6.70134C14.5966 5.72497 13.6692 4.96407 12.5842 4.51489Z"
        fill={color}
      />
    </svg>
  );
};
