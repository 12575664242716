import cx from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getIsCurrentBlockSelected, getIsCustomStyleEditorVisibleInBlock, hideAllToolbars, updateBlockSettings } from '../../../ducks';
import { ContentPosition } from '../../../logic/cms/contentSet';
import { MovementOffset } from '../../../utils/hooks';
import { useContentSetAdjustor } from '../../../utils/hooks/cms/useContentSetAdjustor';
import { isDefined } from '../../../utils/is';
import Icon, { IconType } from '../../various/Icon';
import { Mover } from '../../various/Mover';
import { Resizer, ResizerTooltip } from '../../various/Resizer';
import { useContentSetContext } from '../context/ContentSetContext';
import { EditorLanguageContext } from '../context/EditorLanguageContext';
import { EditorUIContext } from '../context/EditorUIContext';
import { GroupResizerContext } from '../context/GroupResizerContext';
import { HoverToolsContext } from '../context/HoverToolsContext';

import { AddContentPart } from './AddContentPart';
import styles from './ContentSetAdjustor.module.scss';

interface Props {
  blockId: Nullable<string>;
  adjustableElement: React.MutableRefObject<Nullable<HTMLDivElement>>;
  initialPosition: ContentPosition;
  visible?: boolean;
}

export const ContentSetAdjustor = ({ blockId, adjustableElement, initialPosition, visible = false }: Props) => {
  const dispatch = useDispatch();
  const isCurrentBlockSelected = useSelector(getIsCurrentBlockSelected(blockId));
  const isCustomStyleEditorVisible = useSelector(getIsCustomStyleEditorVisibleInBlock(blockId));

  const { screenType } = React.useContext(EditorUIContext);
  const { isDefaultLanguage } = React.useContext(EditorLanguageContext);
  const { isResizing: isResizingGroup } = React.useContext(GroupResizerContext);

  const [tooltipValue, setTooltipValue] = React.useState<string | undefined>(undefined);

  const { isInteracting, isMoving, isResizing, shouldShowSnapGuides, setIsMoving, setIsResizing } = useContentSetContext();
  const { positionRef, onEndMovement, onEndResizing, onMovement, onResizing, onStartMovement, onStartResizing } = useContentSetAdjustor(
    adjustableElement,
    initialPosition,
  );

  const { setIsHoverToolsHidden } = React.useContext(HoverToolsContext);

  const showTooltip = React.useCallback(() => {
    setTooltipValue(`${positionRef.current.width}%`);
  }, [positionRef]);

  const hideTooltip = React.useCallback(() => {
    setTooltipValue(undefined);
  }, []);

  const onStartInteraction = React.useCallback(() => {
    setIsHoverToolsHidden(true);
    dispatch(hideAllToolbars());
  }, [dispatch, setIsHoverToolsHidden]);

  const onEndInteracting = React.useCallback(() => {
    setIsHoverToolsHidden(false);
    hideTooltip();
  }, [hideTooltip, setIsHoverToolsHidden]);

  const saveBlockSettings = React.useCallback(() => {
    if (!isDefined(blockId)) {
      return;
    }

    const value = {
      horizontalPosition: positionRef.current.left,
      verticalPosition: positionRef.current.top,
      width: positionRef.current.width,
    };

    dispatch(
      updateBlockSettings({
        blockId,
        updatePath: ['text', screenType],
        value,
      }),
    );
  }, [blockId, dispatch, positionRef, screenType]);

  const handleStartMovement = React.useCallback(() => {
    onStartInteraction();
    onStartMovement();
    setIsMoving(true);
  }, [onStartInteraction, onStartMovement, setIsMoving]);

  const handleEndMovement = React.useCallback(
    (offset: MovementOffset) => {
      onEndInteracting();
      onEndMovement(offset);
      saveBlockSettings();
      setIsMoving(false);
    },
    [onEndInteracting, onEndMovement, saveBlockSettings, setIsMoving],
  );

  const handleStartResizing = React.useCallback(() => {
    onStartInteraction();
    onStartResizing();
    setIsResizing(true);
  }, [onStartInteraction, onStartResizing, setIsResizing]);

  const handleResizing = React.useCallback(
    (xOffset: number) => {
      const { width } = onResizing(xOffset);

      const formattedWidth = `${parseFloat(width.toFixed(2))}%`;
      setTooltipValue(formattedWidth);
    },
    [onResizing],
  );

  const handleEndResizing = React.useCallback(
    (xOffset: number) => {
      onEndInteracting();
      onEndResizing(xOffset);
      saveBlockSettings();
      setIsResizing(false);
    },
    [onEndInteracting, onEndResizing, saveBlockSettings, setIsResizing],
  );

  React.useEffect(() => {
    return () => {
      if (isResizingGroup) {
        saveBlockSettings();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResizingGroup]);

  const areContentSetToolsVisible = (visible && !isCurrentBlockSelected) || isInteracting;
  const isCurrentBlockActive = visible || isCurrentBlockSelected || isInteracting;
  const isAddContentPartButtonVisible = isCurrentBlockActive && screenType === 'desktop' && !isCustomStyleEditorVisible;

  const classes = cx(styles.adjustor, {
    [styles.visible]: areContentSetToolsVisible,
  });

  if (!isDefaultLanguage) {
    return null;
  }

  return (
    <div className={classes}>
      {!isMoving && !shouldShowSnapGuides && (
        <div className={styles.adjustorTool} onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
          <Resizer
            onResizing={handleResizing}
            onResizeEnd={handleEndResizing}
            onResizeStart={handleStartResizing}
            isActive={visible || isInteracting}
          />
          {isDefined(tooltipValue) && <ResizerTooltip className={styles.tooltip} value={tooltipValue} unit="" />}
        </div>
      )}
      {!isResizing && (
        <Mover
          onMovement={onMovement}
          onEndMovement={handleEndMovement}
          onStartMovement={handleStartMovement}
          isActive={visible || isInteracting}
          className={styles.adjustorTool}
        />
      )}
      {(isMoving || shouldShowSnapGuides) && <Icon type={IconType.Cancel} size={20} className={styles.centerIcon} />}
      {!isInteracting && !shouldShowSnapGuides && (
        <AddContentPart className={cx({ [styles.transparent]: !areContentSetToolsVisible })} isVisible={isAddContentPartButtonVisible} />
      )}
    </div>
  );
};
