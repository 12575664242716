import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Linesheet } from '../../../../../typings';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { FieldWrapper } from '../../various/Form';
import { LayoutSample } from '../../various/LayoutSample';
import { LinesheetTemplatesSkeleton } from '../../various/SkeletonLayout/layouts/LinesheetTemplatesSkeleton';

interface Props {
  templates: Record<string, Linesheet.TemplateData>;
  isLoading: boolean;
}

export const DownloadLinesheetTemplates = ({ templates, isLoading }: Props) => {
  const { t } = useTranslation(['linesheets']);

  const { register, setValue } = useFormContext<Linesheet.OrderFormData>();
  const templateId = useWatch<Linesheet.OrderFormData, 'linesheetTemplate'>({ name: 'linesheetTemplate' });

  React.useEffect(() => {
    const template = templates[templateId];

    if (!isDefined(template)) {
      return;
    }

    const { sortBy, thenSortBy, groupBy, attributes } = template;

    setValue('attributes', attributes);
    setValue('groupBy', groupBy ?? null);
    setValue('sortBy', sortBy ?? null);
    setValue('thenSortBy', thenSortBy ?? 'none');
  }, [setValue, templateId, templates]);

  if (isEmpty(templates) && isLoading) {
    return (
      <FieldWrapper as="span" label={t('linesheets:template')}>
        <LinesheetTemplatesSkeleton />
      </FieldWrapper>
    );
  }

  return (
    <FieldWrapper as="span" label={t('linesheets:template')}>
      <LayoutSample.RadioGroup>
        {Object.values(templates).map(template => (
          <LayoutSample.Radio
            {...register('linesheetTemplate')}
            key={template.id}
            value={template.id}
            type={template.layoutType}
            coverUrl={template.coverUrl}
            templateName={template.name}
          />
        ))}
      </LayoutSample.RadioGroup>
    </FieldWrapper>
  );
};
