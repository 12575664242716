import { FilePicker as FilePickerNs } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { mergeRefs } from 'react-laag';

import universalStyles from '../../../../../css/utilities/universal.module.scss';
import { bytesToHumanReadable } from '../../../../utils/bytesToHumanReadable';
import { handleSpaceAndEnter } from '../../../../utils/handleEnterKey';
import { isDefined } from '../../../../utils/is';
import { isEmpty } from '../../../../utils/isEmpty';
import { IconType } from '../../Icon';
import { Button } from '../../NewButton';
import { Skeleton } from '../../Skeleton';

import styles from './FilePicker.module.scss';
import { FilePreview } from './FilePreview';
import { Message } from './Message';

const DEFAULT_ACCEPT_FILES = ['image/jpg', 'image/png', 'image/jpeg'];
const DEFAULT_MAX_FILE_SIZE = 10485760;

export const FilePicker = React.forwardRef<HTMLInputElement, FilePickerNs.Props>(
  (
    {
      accept = DEFAULT_ACCEPT_FILES,
      describedById,
      errorMessageId,
      file,
      fitPreview = false,
      isDisabled = false,
      isInvalid = false,
      isLoading = false,
      maxFileSize = DEFAULT_MAX_FILE_SIZE,
      name,
      onBlur,
      onChange,
      onError,
      size = 'regular',
    },
    ref,
  ) => {
    const { t } = useTranslation(['validation', 'common']);
    const inputRef = React.useRef<HTMLInputElement | null>(null);
    const [isDragOver, setIsDragOver] = React.useState(false);

    const getValidationMessage = React.useCallback(
      (files: FileList) => {
        const firstFile = files[0] as File | undefined;

        const errors: [boolean, string][] = [
          [isEmpty(files), t('validation:not_a_file_hint')],
          [files.length > 1, t('validation:single_file_select_hint')],
          [isDefined(firstFile) && !accept.includes(firstFile.type), t('validation:file_format_not_supported_hint')],
          [
            isDefined(firstFile) && isDefined(maxFileSize) && firstFile.size >= maxFileSize,
            t('validation:file_size_limit_hint', {
              size: bytesToHumanReadable(maxFileSize),
            }),
          ],
        ];

        return errors.find(([isTrue]) => isTrue)?.[1] ?? null;
      },
      [accept, maxFileSize, t],
    );

    const setFile = React.useCallback(
      (files: FileList) => {
        const validationResult = getValidationMessage(files);
        const selectedFile = files[0];

        !isDefined(validationResult) && isDefined(selectedFile) && onChange?.(selectedFile);
        isDefined(validationResult) && onError?.(validationResult);
      },
      [getValidationMessage, onChange, onError],
    );

    const handleChange = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target;

        if (isDefined(files) && !isEmpty(files)) {
          setFile(files);
        }
      },
      [setFile],
    );

    const handleDrop = React.useCallback(
      (event: React.DragEvent) => {
        if (isDisabled) {
          return;
        }

        event.preventDefault();
        setFile(event.dataTransfer.files);
        setIsDragOver(false);
      },
      [isDisabled, setFile],
    );

    const handleDragOver = React.useCallback((event: React.DragEvent) => {
      event.preventDefault();
    }, []);

    const handleDragEnter = React.useCallback(
      (event: React.DragEvent) => {
        if (isDisabled) {
          return;
        }

        event.preventDefault();
        setIsDragOver(true);
      },
      [isDisabled],
    );

    const handleDragLeave = React.useCallback(() => {
      setIsDragOver(false);
    }, [setIsDragOver]);

    const handleRemoveFile = React.useCallback(
      (event: React.UIEvent) => {
        event.preventDefault();

        if (isDisabled) {
          return;
        }

        if (isDefined(inputRef.current)) {
          Object.assign(inputRef.current, { value: '' });
        }

        onChange?.(null);
      },
      [isDisabled, onChange],
    );

    const hasFile = isDefined(file);

    const containerClassName = cx(styles.container, {
      [styles.invalid]: isInvalid,
      [styles.disabled]: isDisabled,
      [styles.dragOver]: isDragOver,
      [styles[`${size}Size`]]: size,
    });

    const isImageOnly = accept.every(type => type.includes('image/'));

    if (isLoading) {
      return <Skeleton type="rectangle" className={cx(styles.container, styles.loading, { [styles[`${size}Size`]]: size })} />;
    }

    return (
      <label
        className={containerClassName}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
      >
        <input
          type="file"
          ref={mergeRefs(inputRef, ref)}
          aria-invalid={isInvalid}
          aria-describedby={describedById}
          aria-errormessage={errorMessageId}
          accept={accept.join(',')}
          className={universalStyles.srOnly}
          name={name}
          disabled={isDisabled}
          onBlur={onBlur}
          onChange={handleChange}
        />
        <FilePreview file={file} fitPreview={fitPreview} />
        <Message hasFile={hasFile} disabled={isDisabled} isImageOnly={isImageOnly} />
        {hasFile && (
          <Button
            as="div"
            size="mini"
            color="danger"
            variant="flat"
            tabIndex={0}
            disabled={isDisabled}
            className={styles.removeButton}
            icon={IconType.TrashLinear}
            aria-label={t('common:remove')}
            onClick={handleRemoveFile}
            onKeyDown={handleSpaceAndEnter(handleRemoveFile)}
          />
        )}
      </label>
    );
  },
);
