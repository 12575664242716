import {
  useMatrixDistributionContext,
  useMatrixPrepackContext,
  useMatrixQuantitiesContext,
  useMatrixTotalsContext,
} from '../../../products/ProductMatrix/context';
import { RowSummaryCell } from '../MatrixCells';
import { TotalsSummary } from '../MatrixContents/TotalsSummary';

export const TotalsSummaryFooterCell = () => {
  const { totalPricesSum, totalQuantitiesSum } = useMatrixTotalsContext();
  const { cellQuantitiesMap } = useMatrixQuantitiesContext();
  const { visiblePrepacksLength } = useMatrixPrepackContext();
  const { distributionsOffset } = useMatrixDistributionContext();

  const inputColumnsCount = cellQuantitiesMap[0]?.length ?? 0;
  const position = {
    x: visiblePrepacksLength + distributionsOffset + inputColumnsCount,
    y: cellQuantitiesMap.length,
  };

  return (
    <RowSummaryCell position={position}>
      <TotalsSummary totalPrice={totalPricesSum} totalQuantity={totalQuantitiesSum} isHighlighted />
    </RowSummaryCell>
  );
};
