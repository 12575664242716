import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getProductsPerRequest, loadMoreCmsProductsRequest, searchProductsRequest } from '../../../ducks';
import { isDefined } from '../../is';
import { isEmpty } from '../../isEmpty';

import { useSectionMarkets } from './useSectionMarkets';

export const useSearchProducts = () => {
  const dispatch = useDispatch();
  const productsPerRequest = useSelector(getProductsPerRequest);
  const [searchPhrase, setSearchPhrase] = React.useState('');
  const [skipFirst, setSkipFirst] = React.useState(0);
  const { allAssignedMarketsIds } = useSectionMarkets();

  const loadMoreProducts = React.useCallback(() => {
    if (!isDefined(allAssignedMarketsIds) || isEmpty(allAssignedMarketsIds)) {
      return;
    }

    const offset = skipFirst + productsPerRequest;
    dispatch(
      loadMoreCmsProductsRequest({
        marketIds: allAssignedMarketsIds,
        search: searchPhrase,
        skipFirst: offset,
      }),
    );
    setSkipFirst(offset);
  }, [allAssignedMarketsIds, searchPhrase, skipFirst]);

  React.useEffect(() => {
    if (!isDefined(allAssignedMarketsIds) || isEmpty(allAssignedMarketsIds)) {
      return;
    }

    dispatch(
      searchProductsRequest({
        marketIds: allAssignedMarketsIds,
        search: searchPhrase,
      }),
    );
    resetSkipFirst();
  }, [searchPhrase, allAssignedMarketsIds]);

  const resetSkipFirst = React.useCallback(() => {
    setSkipFirst(0);
  }, [setSkipFirst]);

  const updateSearchPhrase = React.useCallback(
    (phrase: string) => {
      setSearchPhrase(phrase);
    },
    [setSearchPhrase],
  );

  return {
    loadMoreProducts,
    resetSkipFirst,
    searchPhrase,
    updateSearchPhrase,
  };
};
