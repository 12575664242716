import cx from 'classnames';
import React from 'react';

import { useResizeObserver } from '../../../utils/hooks';
import { isDefined } from '../../../utils/is';

import styles from './RevealPopup.module.scss';

interface Props {
  overflowOffset: number;
  containerClassName?: string;
}

export const RevealPopup = ({ overflowOffset, children, containerClassName }: React.WithChildren<Props>) => {
  const [isOverflowing, setIsOverflowing] = React.useState(false);
  const [overflowingCount, setOverflowingCount] = React.useState(0);
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const wrapperRef = React.useRef<HTMLDivElement | null>(null);

  const getIsOverflowing = React.useCallback(() => {
    if (!isDefined(wrapperRef.current) || !isDefined(containerRef.current)) {
      return false;
    }

    return wrapperRef.current.offsetHeight > containerRef.current.offsetHeight + overflowOffset;
  }, [overflowOffset]);

  const wrapperChildren = Array.from(wrapperRef.current?.children ?? []) as HTMLElement[];

  const count = React.useCallback(() => {
    if (!isDefined(wrapperRef.current) || !isDefined(containerRef.current)) {
      return 0;
    }

    const parentHeight = containerRef.current.offsetHeight;

    return wrapperChildren.filter(node => {
      return node.offsetTop > parentHeight - overflowOffset;
    }).length;
  }, [overflowOffset, wrapperChildren]);

  React.useEffect(() => {
    setIsOverflowing(getIsOverflowing());
    setOverflowingCount(count());
  }, [count, getIsOverflowing]);

  useResizeObserver(wrapperRef, () => {
    setIsOverflowing(getIsOverflowing());
    setOverflowingCount(count());
  });

  return (
    <div className={cx([styles.revealPopupContainer, containerClassName, isOverflowing && styles.overflowing])} ref={containerRef}>
      <div className={styles.revealPopupWrapper} ref={wrapperRef}>
        {children}
      </div>
      {overflowingCount > 0 && <div className={styles.overflowingCounter}>+ {overflowingCount}</div>}
    </div>
  );
};
