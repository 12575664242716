import { Id, Translations } from '@typings';

import { compiledPaths } from '../paths';

import { isDefined } from './is';

const PATH_TYPE = {
  editor: 'editor',
  preview: 'preview',
} as const;

interface Props {
  pageId: Id;
  pathType: keyof typeof PATH_TYPE;
  lang?: Translations.SupportedLanguagesCodes;
}
export const composeEditorPreviewPath = ({ pageId, lang, pathType }: Props) => {
  const previewPath = compiledPaths.CMS_PREVIEW({ pageId });
  const editorPath = compiledPaths.CMS_EDITOR({ pageId });

  const basePath = pathType === PATH_TYPE.editor ? editorPath : previewPath;

  return !isDefined(lang) ? basePath : `${basePath}?lang=${lang}`;
};
