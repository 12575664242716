import { createColumnHelper, getCoreRowModel, getSortedRowModel } from '@tanstack/react-table';
import { Order } from '@typings';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getAllDeliveryWindows } from '../../../ducks/deliveryWindows';
import { getCheckoutSplitData, getIsLoadingOrderDetails, setIsRequestingSplitOrder } from '../../../ducks/order';
import { getOrderPathByStatus, getPrepaymentFromOrder } from '../../../logic/Orders';
import { formatPriceWithCurrencyAffix } from '../../../logic/price';
import { useUrlOrderIdMatch } from '../../../utils/hooks';
import { useTable } from '../../../utils/hooks/table/useTable';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { renderStandardCell } from '../../../utils/table';
import { DelwinNameAndDate } from '../../various/DelwinNameAndDate';
import Heading from '../../various/Heading';
import { Link } from '../../various/Link';
import { Table } from '../../various/Table';
import { Wrapper, WrapperSize } from '../../various/Wrapper';

import styles from './CheckoutSplitSuccess.module.scss';
import { StatusColumn } from './StatusColumn';

interface TableRow {
  deliveryWindowsIds: string[];
  key: string;
  orderId: string;
  orderNumber: string;
  status: string;
  total: string;
  prepaymentAmount: string;
  units: number;
}

const columnHelper = createColumnHelper<TableRow>();

export const CheckoutSplitSuccess = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'checkout', 'checkoutMessages', 'totals', 'orders']);
  const allDeliveryWindows = useSelector(getAllDeliveryWindows);
  const { currency, orders } = useSelector(getCheckoutSplitData);
  const isLoadingOrderDetails = useSelector(getIsLoadingOrderDetails);
  const { isMatchingSplitOrderId } = useUrlOrderIdMatch();

  // Temporary fix till link endpoint returns currency object
  const priceFormatter = isDefined(currency) ? formatPriceWithCurrencyAffix(currency) : (value: number) => value.toString();

  React.useEffect(() => {
    if (isMatchingSplitOrderId) {
      dispatch(setIsRequestingSplitOrder(false));
    }
  }, [dispatch, isMatchingSplitOrderId]);

  const renderOrderNumberCell = React.useCallback((record: TableRow) => {
    if (!isDefined(record.orderId)) {
      return '';
    }

    const path = getOrderPathByStatus(record.orderId, record.status as Order.Status);

    return (
      <Link variant="blue" to={path}>
        {record.orderNumber}
      </Link>
    );
  }, []);

  const renderDeliveryWindowIdCell = React.useCallback(
    (record: TableRow) => {
      const deliveryWindowsData = allDeliveryWindows.filter(deliveryWindow =>
        record.deliveryWindowsIds.includes(deliveryWindow.deliveryWindow),
      );

      if (isEmpty(deliveryWindowsData)) {
        return null;
      }

      return deliveryWindowsData.map(deliveryWindowData => (
        <DelwinNameAndDate key={deliveryWindowData.deliveryWindow} deliveryWindow={deliveryWindowData} />
      ));
    },
    [allDeliveryWindows],
  );

  const orderRowsData: TableRow[] = React.useMemo(
    () =>
      orders.map(order => ({
        deliveryWindowsIds: order.deliveryWindows,
        key: order.order,
        orderId: order.order,
        orderNumber: `${order.orderNumber} ${order.name}`,
        prepaymentAmount: getPrepaymentFromOrder(order)?.amount ?? '',
        status: order.status,
        total: order.totals.grandTotalWithoutTax,
        units: order.totals.itemsCount,
      })),
    [orders],
  );

  const ordersTotals = React.useMemo(() => {
    const emptyTotals = { prepaymentAmount: 0, total: 0, units: 0 };

    if (isEmpty(orders)) {
      return emptyTotals;
    }

    return (orders as Tuple<Order.Essentials | Order.Closed>).reduce(
      (acc, order) => ({
        prepaymentAmount: acc.prepaymentAmount + (getPrepaymentFromOrder(order)?.amountAsNumber ?? 0),
        total: acc.total + order.totals.grandTotalWithoutTaxAsNumber,
        units: acc.units + order.totals.itemsCount,
      }),
      emptyTotals,
    );
  }, [orders]);

  const hasPrepaidOrders = ordersTotals.prepaymentAmount > 0;
  const arePrepaymentsActive = !isEmpty(orders) && orders.some(order => isDefined(order.totals.prepaymentPriceAsNumber));

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('orderNumber', {
        cell: info => renderOrderNumberCell(info.row.original),
        footer: t('totals:total_one'),
        header: t('orders:order_number', { orderNumber: '' }),
        meta: {
          footerCellClassName: styles.totalHeader,
          footerCellColspan: 2,
        },
        sortingFn: 'alphanumeric',
      }),
      columnHelper.accessor('deliveryWindowsIds', {
        cell: info => renderDeliveryWindowIdCell(info.row.original),
        enableSorting: false,
        header: t('common:delivery_window_other'),
      }),
      columnHelper.accessor('units', {
        cell: renderStandardCell,
        footer: () => ordersTotals.units,
        header: t('common:unit_other'),
        meta: {
          align: 'right',
        },
      }),
      columnHelper.accessor('total', {
        cell: renderStandardCell,
        footer: () => priceFormatter(ordersTotals.total),
        header: t('totals:total_no_tax'),
        meta: {
          align: 'right',
        },
        sortingFn: 'alphanumeric',
      }),
      ...(arePrepaymentsActive ?
        [
          columnHelper.accessor('prepaymentAmount', {
            cell: renderStandardCell,
            footer: () => priceFormatter(ordersTotals.prepaymentAmount),
            header: t('checkout:prepayment_split_by_order'),
            meta: {
              align: 'right',
            },
          }),
        ]
      : []),
      columnHelper.accessor('status', {
        cell: info => <StatusColumn status={info.getValue()} />,
        footer: () => null,
        header: t('common:status'),
      }),
    ],
    [t, arePrepaymentsActive, renderOrderNumberCell, renderDeliveryWindowIdCell, ordersTotals],
  );

  const table = useTable({
    columns,
    data: orderRowsData,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <Wrapper size={WrapperSize.LARGE}>
      <section className={styles.description}>
        <Heading title={t('checkoutMessages:order_has_been_split.header')} />
        <p>
          <Trans t={t} i18nKey="checkoutMessages:order_has_been_split.details">
            Based on showroom settings the order has been <strong>split into separate orders</strong>.<br />
            You can view order summary by navigating to one of the orders from the table below.
          </Trans>
        </p>
        {hasPrepaidOrders && (
          <p>
            <strong>{t('checkout:prepayment_divided')}</strong>
          </p>
        )}
      </section>

      <section>
        <Table
          className={styles.splitTable}
          table={table}
          isLoading={isLoadingOrderDetails}
          skeletonRows={2}
          noDataMessage={t('checkout:no_orders')}
        />
      </section>
    </Wrapper>
  );
};
