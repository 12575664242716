import { Cms } from '@typings';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { INPUT_URL_MAX_LENGTH } from '../../../constants/limits';
import { useValidatedForm } from '../../../utils/hooks/useValidatedForm';
import { isEmpty } from '../../../utils/isEmpty';
import { getUrlSchema } from '../../../utils/schemas';
import { Form, FormButtons, FormGrid, InputField, SwitchField } from '../../various/Form';
import { Button } from '../../various/NewButton';

interface Props {
  defaultValues: Cms.LinkSettings;
  onCancel: () => void;
  onSubmit: (settings: Cms.LinkSettings) => void;
}

export const LinkForm = ({ defaultValues, onCancel, onSubmit }: Props) => {
  const { t } = useTranslation(['common', 'cms', 'validation']);

  const isEditMode = !isEmpty(defaultValues.url);

  const validationSchema: yup.ObjectSchema<Cms.LinkSettings> = yup.object({
    newTab: yup.bool().required(),
    url: getUrlSchema({ hasConfidentialIdCheck: true, isCmsEditorCheck: true }),
  });

  const formMethods = useValidatedForm({ defaultValues, validationSchema });
  const { register } = formMethods;

  return (
    <Form formMethods={formMethods} onSubmit={onSubmit}>
      <FormGrid>
        <InputField
          type="url"
          label={t('common:url')}
          isRequired
          dataTestId="linkUrlInput"
          maxLength={INPUT_URL_MAX_LENGTH}
          {...register('url')}
        />
        <SwitchField label={t('common:open_in_new_tab')} orientation="auto" {...register('newTab')} />
      </FormGrid>
      <FormButtons showDivider>
        <Button size="large" variant="ghost" color="dark" onClick={onCancel}>
          {t('common:cancel')}
        </Button>
        <Button type="submit" size="large">
          {isEditMode ? t('common:save_changes') : t('cms:add_link')}
        </Button>
      </FormButtons>
    </Form>
  );
};
