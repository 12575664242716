import { hotjar } from 'react-hotjar';
import { combineEpics } from 'redux-observable';
import { filter, ignoreElements, map, tap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { getGATrackingId, getHotjarSiteId } from '../ducks/config';
import { initHotjar, pushEvent } from '../ducks/track';
import { getIsUserAdmin, getUserAccess } from '../ducks/user';
import { isDefined } from '../utils/is';

const pushEventEpic: AppEpic = (action$, state$, { pushEventGTM }) =>
  action$.pipe(
    filter(isActionOf(pushEvent)),
    map(action => ({
      ...action.payload,
      clientTrackingId: getGATrackingId(state$.value),
      visitorType: getIsUserAdmin(state$.value) ? 'admin' : getUserAccess(state$.value),
    })),
    tap(pushEventGTM),
    ignoreElements(),
  );

const initHotjarEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(initHotjar)),
    map(_ => getHotjarSiteId(state$.value)),
    filter(isDefined),
    map(siteId => hotjar.initialize(siteId)),
    ignoreElements(),
  );

export const trackEpic = combineEpics(pushEventEpic, initHotjarEpic);
