import cx from 'classnames';
import React from 'react';

import { isDefined } from '../../../../utils/is';
import Icon, { IconColor, IconType } from '../../Icon';
import { Tooltip } from '../../Tooltip';
import styles from '../PopoverMenu.module.scss';

interface Props {
  icon?: IconType;
  isParent?: boolean;
  onClick?: () => void;
  isDisabled?: boolean;
  testId?: string;
  disabledTooltip?: string;
  isSelected?: boolean;
  buttonClassName?: string;
}

export const MenuButton = (props: React.WithChildren<Props>) => {
  const {
    icon,
    children,
    onClick,
    testId,
    disabledTooltip,
    buttonClassName,
    isParent = false,
    isDisabled = false,
    isSelected = false,
  } = props;

  const iconColor = isDisabled ? IconColor.Faded : IconColor.Gray;

  const tooltipContent = isDisabled ? disabledTooltip : undefined;

  return (
    <Tooltip content={tooltipContent} autoHide={false} disableOnTouchScreen={!isDisabled}>
      <div className={styles.menuButtonWrapper}>
        <button data-testid={testId} className={cx(styles.menuButton, buttonClassName)} onClick={onClick} disabled={isDisabled}>
          {isDefined(icon) && <Icon className={styles.icon} type={icon} color={iconColor} />}
          {children}
          {isParent && <Icon className={styles.rightIcon} type={IconType.ArrowSharp} rotation={-90} color={iconColor} />}
          {isSelected && <Icon className={styles.rightIcon} type={IconType.Accept} color={IconColor.Blue} />}
        </button>
      </div>
    </Tooltip>
  );
};
