import React from 'react';

import { Key } from '../../../utils/keys';

import styles from './FocusTrap.module.scss';

interface FocusTrapProps {
  shouldReturnFocus?: boolean;
}

export const FocusTrap = ({ children, shouldReturnFocus = true }: React.WithChildren<FocusTrapProps>) => {
  const focusTrapStart = React.useRef<HTMLDivElement | null>(null);
  const focusTrapEnd = React.useRef<HTMLDivElement | null>(null);
  const initialActiveElementRef = React.useRef<Element | null>(null);

  const handleTabPress = React.useCallback(
    ({ code, shiftKey }: KeyboardEvent) => {
      if (code !== Key.TAB) {
        return;
      }

      const { activeElement } = document;
      const isFirstActive = activeElement === focusTrapStart.current;
      const isLastActive = activeElement === focusTrapEnd.current;

      if (shiftKey && isFirstActive) {
        focusTrapEnd.current?.focus();
      }

      if (!shiftKey && isLastActive) {
        focusTrapStart.current?.focus();
      }
    },
    [focusTrapStart, focusTrapEnd],
  );

  React.useLayoutEffect(() => {
    initialActiveElementRef.current = document.activeElement;
    focusTrapStart.current?.focus({ preventScroll: true });

    return () => {
      if (shouldReturnFocus && initialActiveElementRef.current instanceof HTMLElement) {
        initialActiveElementRef.current.focus();
      }
    };
  }, [shouldReturnFocus]);

  React.useEffect(() => {
    window.addEventListener('keydown', handleTabPress);

    return () => {
      window.removeEventListener('keydown', handleTabPress);
    };
  }, []);

  return (
    <>
      <div
        className={styles.focusTrap}
        aria-hidden="true"
        /* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */
        tabIndex={0}
        ref={focusTrapStart}
      />
      {children}
      <div
        className={styles.focusTrap}
        aria-hidden="true"
        /* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */
        tabIndex={0}
        ref={focusTrapEnd}
      />
    </>
  );
};
