import { Skeleton as SkeletonNS } from '@typings';

import { InputSkeleton } from './InputSkeleton/InputSkeleton';
import { ButtonSkeleton } from './ButtonSkeleton';
import { RectangleSkeleton } from './RectangleSkeleton';
import { TextSkeleton } from './TextSkeleton';

export const Skeleton = (props: SkeletonNS.AnySkeleton) => {
  if (props.type === 'button') {
    return <ButtonSkeleton {...props} />;
  }

  if (props.type === 'input') {
    return <InputSkeleton {...props} />;
  }

  if (props.type === 'rectangle') {
    return <RectangleSkeleton {...props} />;
  }

  return <TextSkeleton {...props} />;
};
