import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;
const ORIGINAL_COLOR = '#848484';

export const TextCenter = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => (
  <svg width={width} height={width} viewBox="0 0 20 20">
    <g fill={color}>
      <path d="m17.077 5.84613h-14.15393c-.49228 0-.92307-.43073-.92307-.92306 0-.49234.43079-.92307.92307-.92307h14.15383c.4924 0 .9231.43079.9231.92307 0 .49227-.4307.92306-.923.92306z" />
      <path d="m14.8566 11.3845h-9.25315c-.32182 0-.60345-.4308-.60345-.9231 0-.49234.28163-.92307.60345-.92307h9.25305c.3219 0 .6035.43079.6035.92307 0 .4923-.2816.9231-.6034.9231z" />
      <path d="m17.077 16.923h-14.15393c-.49228 0-.92307-.4307-.92307-.923 0-.4924.43079-.9231.92307-.9231h14.15383c.4924 0 .9231.4308.9231.9231 0 .4922-.4307.923-.923.923z" />
    </g>
  </svg>
);
