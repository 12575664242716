import { stringify } from 'qs';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { getIsLoggedIn, getIsSoftLogoutMode } from '../ducks';
import { filtersDecoder } from '../logic/filters';
import { getQueryParams } from '../utils/getQueryParams';
import { isDefined } from '../utils/is';

export const SharedTokenContext = React.createContext<Nullable<string>>(null);

export const useSharedToken = () => React.useContext(SharedTokenContext);

export const SharedTokenProvider = ({ children }: React.PropsWithChildren) => {
  const isSoftLogoutMode = useSelector(getIsSoftLogoutMode);
  const isUserLoggedIn = useSelector(getIsLoggedIn);

  const location = useLocation();
  const navigate = useNavigate();

  const [sharedToken, setSharedToken] = React.useState<Nullable<string>>(null);

  const shouldRememberSharedToken = !isUserLoggedIn || isSoftLogoutMode;

  React.useEffect(() => {
    if (!shouldRememberSharedToken) {
      return;
    }

    const queryParams = getQueryParams(location.search, {
      decoder: filtersDecoder,
      ignoreQueryPrefix: true,
    });

    if (isDefined(queryParams.sharedToken) && !isDefined(sharedToken)) {
      setSharedToken(queryParams.sharedToken);

      return;
    }

    if (isDefined(sharedToken) && !isDefined(queryParams.sharedToken)) {
      const search = stringify({ ...queryParams, sharedToken }, { addQueryPrefix: true, encode: false });

      navigate({ search }, { replace: true });
    }
  }, [shouldRememberSharedToken, location, navigate, sharedToken]);

  return <SharedTokenContext.Provider value={sharedToken}>{children}</SharedTokenContext.Provider>;
};
