import { DatePicker, Dates, Order, Translations } from '@typings';
import dayjs, { Dayjs, OpUnitType } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import i18next from 'i18next';
import { range } from 'ramda';

import { MONTHS_IN_YEAR } from '../constants/dates';

import { isDefined, isNull } from './is';

dayjs.extend(customParseFormat);

const DAYS_IN_WEEK = 7;
const SUNDAY_INDEX = 6;
const CALENDAR_WEEKS_TO_SHOW = 6;
const CALENDAR_YEARS_TO_SHOW = 12;
const ONE_SECOND_IN_MILLISECONDS = 1000;
const MINUTE_IN_MILLISECONDS = 60 * ONE_SECOND_IN_MILLISECONDS;
const HOUR_IN_MILLISECONDS = 3600 * ONE_SECOND_IN_MILLISECONDS;
const HALF_DAY_IN_MILLISECONDS = 12 * HOUR_IN_MILLISECONDS;

export const getHumanReadableTimePeriod = (separator: string) => (format: Dates.Format) => (dates: Dates.DateAsSQLString[]) =>
  dates.map(formatDate(format)).join(separator);

const getValidDate = (formattedDate: string) => {
  return formattedDate === 'Invalid DateTime' ? '-' : formattedDate;
};

export const formatDate =
  (format: Dates.Format) =>
  (date: Dates.DateAsSQLString): string => {
    const formattedDate = dayjs(date).format(format);

    return getValidDate(formattedDate);
  };

export const formatLocaleDate = (date: Dates.DateAsSQLString, locale: Translations.SupportedLanguagesCodes = 'en'): string => {
  const formatter = new Intl.DateTimeFormat(locale, { day: '2-digit', month: 'short', year: 'numeric' });

  return formatter.format(dayjs(date).toDate());
};

export const formatLocaleDateAndTime = (date: Dates.DateAsSQLString, locale: Translations.SupportedLanguagesCodes = 'en'): string => {
  const formatter = new Intl.DateTimeFormat(locale, {
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    month: 'short',
    second: '2-digit',
    year: 'numeric',
  });

  return formatter.format(dayjs(date).toDate());
};

export const getOrderDisplayDate = (order: Order.Essentials): string | null | undefined => {
  if (['open', 'checkoutRequested'].includes(order.status)) {
    return order.createdDate;
  }

  return order.orderDate;
};

export const getRelativeTimeFormatter =
  (language: Translations.SupportedLanguagesCodes, dateFormat: Dates.Format = 'YYYY-MM-DD, HH:mm:ss') =>
  (date: Dates.DateAsSQLString | undefined) => {
    if (!isDefined(date)) {
      return '';
    }

    const currentDate = new Date();
    const dateToCompare = dayjs(date).toDate();

    const relativeTimeDifference = dateToCompare.getTime() - currentDate.getTime();
    const absTimeDifference = Math.abs(relativeTimeDifference);

    if (absTimeDifference < MINUTE_IN_MILLISECONDS) {
      const lessThanMinuteKey = relativeTimeDifference > 0 ? 'common:in_less_than_minute' : 'common:less_than_minute_ago';

      return i18next.t(lessThanMinuteKey);
    }

    const formatter = new Intl.RelativeTimeFormat(language, { numeric: 'always', style: 'long' });

    if (absTimeDifference <= HOUR_IN_MILLISECONDS) {
      return formatter.format(Math.round(relativeTimeDifference / MINUTE_IN_MILLISECONDS), 'minute');
    }

    if (absTimeDifference <= HALF_DAY_IN_MILLISECONDS) {
      return formatter.format(Math.round(relativeTimeDifference / HOUR_IN_MILLISECONDS), 'hour');
    }

    return formatDate(dateFormat)(date);
  };

export const getDateRangeFormatter = (language: Translations.SupportedLanguagesCodes) => (dateRange: DatePicker.Value) => {
  const [startDate, endDate] = [dateRange].flat();

  if (!isDefined(startDate)) {
    return '';
  }

  const formatter = new Intl.DateTimeFormat(language, { day: 'numeric', month: 'short', year: 'numeric' });

  if (!isDefined(endDate)) {
    return formatter.format(startDate.toDate());
  }

  return formatter.formatRange(startDate.toDate(), endDate.toDate());
};

export const getCalendarDates = (month: number, year: number, weeksToShow = CALENDAR_WEEKS_TO_SHOW) => {
  const firstDay = dayjs().date(1).month(month).year(year);
  const prevMonthDay = firstDay.clone().subtract(1, 'month');
  const nextMonthDay = firstDay.clone().add(1, 'month');

  const daysInMonth = firstDay.daysInMonth();
  const daysInPrevMonth = prevMonthDay.daysInMonth();

  const prevMonthDaysToAdd = (firstDay.day() + SUNDAY_INDEX) % DAYS_IN_WEEK;
  const nextMonthDaysToAdd = DAYS_IN_WEEK * weeksToShow - daysInMonth - prevMonthDaysToAdd;

  const currMonthDates = range(0, daysInMonth).map(day => firstDay.clone().add(day, 'day'));
  const prevMonthDates = range(daysInPrevMonth - prevMonthDaysToAdd, daysInPrevMonth).map(day => prevMonthDay.clone().add(day, 'day'));
  const nextMonthDates = range(0, nextMonthDaysToAdd).map(day => nextMonthDay.clone().add(day, 'day'));

  return [...prevMonthDates, ...currMonthDates, ...nextMonthDates];
};

export const getCalendarMonthDates = (currentYear: number) => {
  return range(0, MONTHS_IN_YEAR).map(monthIndex => dayjs().month(monthIndex).year(currentYear));
};

export const getCalendarYearDates = (currentYear: number, yearsToShow = CALENDAR_YEARS_TO_SHOW) => {
  const prevYearsToShow = currentYear % yearsToShow;
  const nextYearsToShow = yearsToShow - prevYearsToShow;

  const years = [
    ...range(currentYear - prevYearsToShow, currentYear),
    currentYear,
    ...range(currentYear + 1, currentYear + nextYearsToShow),
  ];

  return years.map(year => dayjs().year(year));
};

export const getIsDateBetween = (date: Dayjs, [startDate, endDate]: DatePicker.RangeValue, unit: OpUnitType = 'millisecond') => {
  if (isNull(startDate) || isNull(endDate)) {
    return false;
  }

  return !date.isBefore(startDate, unit) && !date.isAfter(endDate, unit);
};

export const getIsValidDateString = (dateString: string | undefined, format: Dates.Format = 'YYYY-MM-DD') => {
  return dayjs(dateString, format, true).isValid();
};

export const getDateOrNull = <T extends string | number | null>(candidate: T): Nullable<dayjs.Dayjs> => {
  if (typeof candidate === 'string' || typeof candidate === 'number') {
    return dayjs(candidate);
  }

  return null;
};
