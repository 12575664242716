import { Cms, Id } from '@typings';
import { nanoid } from 'nanoid';

import { isDefined } from '../../utils/is';
import { isEmpty } from '../../utils/isEmpty';
import { omit } from '../../utils/omit';
import { getIsContentBlock, getIsProductBlock } from '../pages';

const DEFAULT_CONTENT_BLOCK_SETTINGS: Cms.ContentBlock['settings'] = {};
const DEFAULT_PRODUCT_BLOCK_SETTINGS: Cms.ProductBlock['settings'] = {
  products: [],
};

const getTemplateIndexBasedOnType = (blockType: Cms.BlockType, templates: Cms.BlockModel[]) => {
  return templates.findIndex(template => template.blockType === blockType);
};

const getFilledBlockIndexBasedOnType = (blockType: Cms.BlockType, blocks: Cms.AnyBlock[]) => {
  return blocks.findIndex(block => block.blockType === blockType && !hasEmptyBlocks([block]));
};

export const getCanBeMirrorred = (model: Cms.BlockModel[], blocks: Cms.AnyBlock[]) => {
  return (
    blocks.every(block => model.some(({ blockType }) => blockType === block.blockType)) &&
    model.every(template => blocks.some(({ blockType }) => blockType === template.blockType))
  );
};

export const hasEmptyBlocks = (blocks: Cms.AnyBlock[]) =>
  blocks.every(block => (getIsContentBlock(block) ? isEmpty(omit(block.settings, ['height'])) : isEmpty(block.settings.products)));

export const getMirrorredBlocksWithSpanYPriority = (blocks: Cms.AnyBlock[], model: Cms.BlockModel[]): Cms.AnyBlock[] => {
  const filteredModel = [...model];

  return blocks.map(block => {
    const newTemplateIndex = filteredModel.findIndex(template => {
      const hasSameSpanY = template.position.desktop.spanY === block.position.desktop.spanY;

      return template.blockType === block.blockType && hasSameSpanY;
    });

    const index = newTemplateIndex === -1 ? getTemplateIndexBasedOnType(block.blockType, filteredModel) : newTemplateIndex;
    const position = filteredModel[index]?.position ?? block.position;
    // eslint-disable-next-line functional/immutable-data
    filteredModel.splice(index, 1);

    return { ...block, position };
  });
};

export const getBlocksWithInsertedTemplates = (blocks: Cms.AnyBlock[], model: Cms.BlockModel[], groupId: Id): Cms.AnyBlock[] => {
  const filteredBlocks = [...blocks];

  return model.map(modelEl => {
    const getUpdatedBlock = (): Cms.AnyBlock => {
      const index = getFilledBlockIndexBasedOnType(modelEl.blockType, filteredBlocks);
      const block = filteredBlocks[index];
      if (isDefined(block)) {
        // eslint-disable-next-line functional/immutable-data
        filteredBlocks.splice(index, 1);

        return block;
      }

      return createBlockFromModel(modelEl, groupId);
    };

    const updatedBlock = getUpdatedBlock();

    if (getIsContentBlock(updatedBlock)) {
      return {
        ...updatedBlock,
        position: modelEl.position,
        settings: { ...updatedBlock.settings, height: undefined },
      };
    }

    return { ...updatedBlock, position: modelEl.position };
  });
};

export const createBlockFromModel = (blockModel: Cms.BlockModel, groupId: Id, settings?: Cms.AnyBlock['settings']): Cms.AnyBlock => {
  const blockBase = {
    ...blockModel,
    groupId,
    id: nanoid(),
  };

  return blockModel.blockType === 'content' ?
      {
        ...blockBase,
        blockType: 'content',
        settings: (settings as Maybe<Cms.ContentBlock['settings']>) ?? DEFAULT_CONTENT_BLOCK_SETTINGS,
      }
    : {
        ...blockBase,
        blockType: 'product',
        settings: (settings as Maybe<Cms.ProductBlock['settings']>) ?? DEFAULT_PRODUCT_BLOCK_SETTINGS,
      };
};

export const getExistingProductsCountInBlocks = (blocks: Cms.AnyBlock[]) => {
  const existingProductBlock = blocks.find(block => getIsProductBlock(block)) as Cms.ProductBlock | undefined;

  if (!isDefined(existingProductBlock)) {
    return 0;
  }

  return existingProductBlock.settings.products.length;
};

export const getProductSlotsCountInModels = (models: Cms.BlockModel[]) => {
  const productBlockModel = models.find(model => model.blockType === 'product');

  if (!isDefined(productBlockModel)) {
    return 0;
  }

  return productBlockModel.position.desktop.spanX * productBlockModel.position.desktop.spanY;
};

export const getCanReplaceProducts = (blocks: Cms.AnyBlock[], models: Cms.BlockModel[]) => {
  const existingProductsCount = getExistingProductsCountInBlocks(blocks);
  const newProductsCount = getProductSlotsCountInModels(models);

  return newProductsCount === 0 || existingProductsCount <= newProductsCount;
};

export const getModelsTypes = (models: Cms.BlockModel[]) => {
  return models.map(model => model.blockType);
};

export const getMachingBlockTypes = (blocks: Cms.AnyBlock[], models: Cms.BlockModel[]) => {
  const modelsTypes = getModelsTypes(models);

  return blocks.filter(block => modelsTypes.includes(block.blockType));
};

export const getContentModelsCount = (models: Cms.BlockModel[]) => models.filter(model => model.blockType === 'content').length;

export const getProductModelsCount = (models: Cms.BlockModel[]) => models.filter(model => model.blockType === 'product').length;

export const getCanReplaceContents = (matchingBlocks: Cms.AnyBlock[], models: Cms.BlockModel[]) => {
  const emptyContentBlocksCount = matchingBlocks.filter(
    block => getIsContentBlock(block) && !isEmpty(omit(block.settings, ['height'])),
  ).length;

  const contentModelsCount = getContentModelsCount(models);

  return emptyContentBlocksCount <= contentModelsCount;
};

const getProductBlocksCount = (blocks: Cms.AnyBlock[]) => {
  return blocks.filter(block => getIsProductBlock(block)).length;
};

const getFilledContentBlocksCount = (blocks: Cms.AnyBlock[]) => {
  return blocks.filter(block => getIsContentBlock(block) && !isEmpty(omit(block.settings, ['height']))).length;
};

export const getShouldShowProductsLoseWarning = (blocks: Cms.AnyBlock[], models: Cms.BlockModel[]) => {
  const productModelsCount = getProductModelsCount(models);
  const contentModelsCount = getContentModelsCount(models);
  const filledContentBlocksCount = getFilledContentBlocksCount(blocks);
  const productsCount = getExistingProductsCountInBlocks(blocks);

  const hasProductsAdded = productsCount > 0;
  const willTriggerComplexCase = filledContentBlocksCount > contentModelsCount;

  return hasProductsAdded && productModelsCount === 0 && !willTriggerComplexCase;
};

export const getShouldShowContentLoseWarning = (blocks: Cms.AnyBlock[], models: Cms.BlockModel[]) => {
  const productBlocksCount = getProductBlocksCount(blocks);
  const contentModelsCount = getContentModelsCount(models);
  const filledContentBlocksCount = getFilledContentBlocksCount(blocks);
  const productModelsCount = getProductModelsCount(models);

  const hasFilledContentBlocks = filledContentBlocksCount > 0;
  const willTriggerComplexCase = productBlocksCount > productModelsCount;

  return hasFilledContentBlocks && contentModelsCount === 0 && !willTriggerComplexCase;
};
