import { Checkboxes } from '@typings';

import { isDefined } from '../utils/is';

const updateParent =
  (options: Checkboxes.Option[]) =>
  (changedValue: string, baseState: Checkboxes.OptionsState): Checkboxes.OptionsState => {
    const currentOption = options.find(option => option.value === changedValue);
    const parentOption = options.find(option => option.value === currentOption?.parentValue);

    if (!isDefined(currentOption) || !isDefined(parentOption)) {
      return baseState;
    }

    const nestedOptions = options.filter(option => option.parentValue === parentOption.value);
    const parentOptionUpdater = updateParent(options);

    const allNestedChecked = nestedOptions.every(option => baseState[option.value] === 'checked');
    if (allNestedChecked) {
      return parentOptionUpdater(parentOption.value, {
        ...baseState,
        [parentOption.value]: 'checked',
      });
    }

    const allNestedUnchecked = nestedOptions.every(
      option => baseState[option.value] === 'unchecked' || !isDefined(baseState[option.value]),
    );
    if (allNestedUnchecked) {
      return parentOptionUpdater(parentOption.value, {
        ...baseState,
        [parentOption.value]: 'unchecked',
      });
    }

    return parentOptionUpdater(parentOption.value, {
      ...baseState,
      [parentOption.value]: 'indeterminate',
    });
  };

export const updateCheckboxTree =
  (options: Checkboxes.Option[]) =>
  (changedValue: string, newState: Checkboxes.CheckboxState, baseState?: Checkboxes.OptionsState): Checkboxes.OptionsState => {
    const updatedState = options
      .filter(option => option.parentValue === changedValue)
      .reduce(
        (acc, cur) => ({
          ...acc,
          [cur.value]: newState,
          ...updateCheckboxTree(options)(cur.value, newState),
        }),
        baseState ?? {},
      );

    return updateParent(options)(changedValue, {
      ...updatedState,
      [changedValue]: newState,
    });
  };

export const getSelectedValues = (state: Checkboxes.OptionsState) =>
  Object.entries(state)
    .filter(([_, optionState]) => optionState === 'checked')
    .map(([value]) => value);
