import { AnyMedia, Product } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getDefaultImageAspectRatio } from '../../../../ducks/helpers';
import { isImageMedia, isVideoMedia } from '../../../../services/media';
import { isDefined } from '../../../../utils/is';
import { CustomImage } from '../../../various/CustomImage';
import { ProductBadges } from '../../ProductBadges';
import { ProductDetailsLayoutContext } from '../context/ProductDetailsLayoutContext';
import { useProductDetailsVariantContext } from '../context/ProductDetailsVariantContext';

import styles from './ProductLightbox.module.scss';

interface Props {
  activeVariant: Product.Standard | undefined;
  media: AnyMedia | undefined;
  autoPlayVideo?: boolean;
  onClick: () => void;
  isGallery?: boolean;
}

export const ProductMedia = ({ activeVariant, media, autoPlayVideo = true, onClick, isGallery = false }: Props) => {
  const { t } = useTranslation(['products']);
  const aspectRatio = useSelector(getDefaultImageAspectRatio);
  const { hasMobileDetailsLayout } = React.useContext(ProductDetailsLayoutContext);
  const { variants, hasAvailableVariants } = useProductDetailsVariantContext();

  const variant = variants.find(({ variant: variantId }) => variantId === media?.variantId);
  const badgesVariant = isGallery ? variant : activeVariant;

  return (
    <div className={cx(styles.main, { [styles.fullWidth]: hasMobileDetailsLayout })}>
      {isImageMedia(media) && <CustomImage src={media.src} onClick={onClick} aspectRatio={aspectRatio} errorStyle={styles.errorMessage} />}
      {isVideoMedia(media) && (
        <div className={styles.videoWrapper} style={{ aspectRatio: `1 / ${aspectRatio}` }}>
          {/* eslint-disable-next-line @microsoft/sdl/react-iframe-missing-sandbox */}
          <iframe
            title={t('products:product_video')}
            src={`https://player.vimeo.com/video/${media.src}?autoplay=${autoPlayVideo}&title=0&byline=0&portrait=0`}
            className={styles.iframe}
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
            sandbox="allow-scripts allow-same-origin allow-presentation"
          />
        </div>
      )}
      {isDefined(badgesVariant) && (
        <div className={styles.badgesContainer}>
          <ProductBadges hasAvailableVariants={hasAvailableVariants} variant={badgesVariant} />
        </div>
      )}
    </div>
  );
};
