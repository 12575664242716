/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 24;
const ORIGINAL_COLOR = '#FFFFFF';

export const Inventory = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox="0 0 24 24">
      <path
        fill={color}
        fillRule="evenodd"
        d="M4.199 6.583c-.04 0-.101.038-.101.12V8.69c0 .082.061.12.1.12h15.603c.04 0 .101-.038.101-.12V6.704c0-.083-.061-.12-.1-.12H4.198ZM19.7 9.893h.101C20.48 9.894 21 9.34 21 8.69V6.704c0-.649-.52-1.204-1.199-1.204H4.2C3.52 5.5 3 6.055 3 6.704V8.69c0 .649.52 1.204 1.199 1.204H4.3v7.402c0 .314.123.619.346.845.223.228.53.359.853.359h13.002c.324 0 .63-.131.854-.359.223-.226.345-.53.345-.845V9.894Zm-1.098 0H5.398v7.403c0 .038.015.07.036.091.02.021.044.03.065.03h13.002a.092.092 0 0 0 .066-.03.13.13 0 0 0 .035-.09V9.893Zm-9.101 2.769c0-.3.246-.542.549-.542h3.9c.303 0 .549.243.549.542 0 .3-.246.542-.549.542h-3.9a.545.545 0 0 1-.55-.542Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
