/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 19;
const ORIGINAL_COLOR = '#7599A7';

export const Multiples = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => {
  return (
    <svg width={width} height={width} viewBox="0 0 19 19">
      <rect y="3" width="16" height="16" rx="3" fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 2.12854C3.27579 2.04495 3.56838 2 3.87146 2H13.8715C15.5283 2 16.8715 3.34315 16.8715 5V15C16.8715 15.3031 16.8265 15.5957 16.7429 15.8715C17.9748 15.4981 18.8715 14.3538 18.8715 13V3C18.8715 1.34315 17.5283 0 15.8715 0H5.87146C4.5177 0 3.37336 0.896691 3 2.12854Z"
        fill={color}
      />
    </svg>
  );
};
