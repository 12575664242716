import { Settings } from '@typings';

import { CONTENT_TYPE_HEADER_FORM_DATA } from '../constants/api';

import { hostApi, showroomApi } from './api';

export const fetchConfiguration = async (query?: { ignoreCache: boolean }) => showroomApi.GET('/configuration')({ query });

export const uploadFile = async ({ fileId, file }: Settings.FilePayload) => {
  const formData = new FormData();
  formData.append('fileId', fileId);
  formData.append('file', file as unknown as File);

  return showroomApi.QUERY_POST('/configuration/upload')({
    data: formData,
    headers: {
      'Content-Type': CONTENT_TYPE_HEADER_FORM_DATA,
    },
  });
};

export const deleteFile = async (fileId: Settings.FileId) =>
  showroomApi.QUERY_DELETE('/configuration/:fileId')({
    pathParams: { fileId },
  });

export const fetchVersion = async () => hostApi.GET('/version')({});
