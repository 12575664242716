/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 17;
const ORIGINAL_HEIGHT = 17;
const PROPORTION = ORIGINAL_HEIGHT / ORIGINAL_WIDTH;
const ORIGINAL_COLOR = '#5c5c5c';

export const Notepad = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => {
  return (
    <svg width={width} height={width * PROPORTION} viewBox="0 0 17 17">
      <path
        d="M13.6333 1.96667L12.5667 0.766665C12.3167 0.499999 11.9 0.449999 11.6 0.65L10.1333 1.68333L8.65 0.666666C8.4 0.499999 8.06667 0.499999 7.81667 0.666666L6.35 1.68333L4.88333 0.65C4.56667 0.449999 4.16667 0.499999 3.91667 0.766665L2.86667 1.96667C2.73333 2.11667 2.66667 2.3 2.66667 2.48333V14.6833C2.66667 15.0333 2.9 15.3333 3.23333 15.4167C3.33333 15.4333 5.5 15.9667 8.21667 15.9667C10.9333 15.9667 13.1667 15.4333 13.25 15.4167C13.6 15.3333 13.8667 15.0333 13.8667 14.6833V2.48333C13.8667 2.3 13.7667 2.11667 13.6333 1.96667ZM5.33333 5.86667V4.8H11.2V5.86667H5.33333ZM5.33333 8V6.93333H11.2V8H5.33333ZM5.33333 10.1333V9.06667H11.2V10.1333H5.33333ZM5.33333 12.2667V11.2H11.2V12.2667H5.33333Z"
        fill={color}
      />
    </svg>
  );
};
