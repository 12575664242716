import cx from 'classnames';
import React from 'react';

import styles from './CheckoutSection.module.scss';

interface Props {
  isBorderless?: boolean;
}

export const CheckoutSection = ({ children, isBorderless = false }: React.WithChildren<Props>) => {
  return <section className={cx(styles.section, { [styles.borderless]: isBorderless })}>{children}</section>;
};
