/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;
const ORIGINAL_COLOR = '#848484';

export const AlignRight = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => (
  <svg width={width} height={width} viewBox="0 0 20 20">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2221 3.92817L15.222 16.0719C15.222 16.5669 15.642 17 16.1219 17C16.6019 17 17.0219 16.5669 17.0219 16.0718L17.022 3.92817C17.022 3.43317 16.602 3 16.122 3C15.642 3 15.2221 3.43317 15.2221 3.92817ZM11.111 7.70876C11.111 7.61763 11.1551 7.55459 11.243 7.51986C11.331 7.48507 11.412 7.49602 11.4862 7.55245L14.1528 9.83076C14.1991 9.87412 14.2222 9.92399 14.2222 9.98048C14.2222 10.0413 14.1991 10.0934 14.1528 10.1368L11.4862 12.4476C11.412 12.504 11.331 12.5149 11.2431 12.4802C11.1551 12.4412 11.1111 12.3782 11.1111 12.2914V10.8333H3.22205C3.1572 10.8333 3.10393 10.8137 3.06232 10.7747C3.02065 10.7357 2.99985 10.6858 2.99985 10.625V9.37515C2.99985 9.3143 3.02065 9.26434 3.06232 9.22547C3.10393 9.18646 3.1572 9.1669 3.22205 9.1669H11.111V7.70876Z"
      fill={color}
    />
  </svg>
);
