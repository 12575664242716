import { Addresses } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getInitialSelectedShippingAddressId } from '../../../ducks';
import { getIsAccountEditingEnabled, getIsShippingAddressAvailableInCheckout } from '../../../ducks/config';
import { getShouldShowMoreShippingAddresses, setShouldShowMoreShippingAddresses } from '../../../ducks/ui';
import { getUser } from '../../../ducks/user';
import { getIsSeller } from '../../../logic/User';
import { useShippingCountries } from '../../../services/hooks/account/useShippingCountries';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import Heading from '../../various/Heading';
import { Notification } from '../../various/Notification/Notification';
import { Skeleton } from '../../various/Skeleton';
import { SkeletonLayout } from '../../various/SkeletonLayout';

import styles from './shippingAddresses.module.scss';
import { ShippingAddressRadioGroupField } from './ShippingAddressRadioGroupField';

interface Props {
  shippingAddresses: Addresses.MixedShipping[] | undefined;
  isLoading: boolean;
}

const NUMBER_OF_CARDS_TO_SHOW = 2;

export const ShippingAddresses = ({ shippingAddresses = [], isLoading }: Props) => {
  const { t } = useTranslation(['shipping', 'common']);

  const dispatch = useDispatch();
  const isAccountEditingEnabled = useSelector(getIsAccountEditingEnabled);
  const isShippingAddressAvailableInCheckout = useSelector(getIsShippingAddressAvailableInCheckout);
  const initialSelectedShippingAddressId = useSelector(getInitialSelectedShippingAddressId);

  const user = useSelector(getUser);
  const shouldShowMoreShippingAddresses = useSelector(getShouldShowMoreShippingAddresses);
  const { data: shippingCountries } = useShippingCountries();

  const handleToggleShowMore = () => {
    dispatch(setShouldShowMoreShippingAddresses(!shouldShowMoreShippingAddresses));
  };

  const areShippingCountriesAvailable = isDefined(shippingCountries) && !isEmpty(shippingCountries);
  const hasShippingAddresses = !isEmpty(shippingAddresses);

  const canEditAddress = isAccountEditingEnabled || isShippingAddressAvailableInCheckout || hasShippingAddresses;
  const areShippingAddressesAvailable = isShippingAddressAvailableInCheckout || hasShippingAddresses;
  const areAddressesExist = hasShippingAddresses || areShippingCountriesAvailable;

  const canAddAddress =
    ((isAccountEditingEnabled && getIsSeller(user)) || isShippingAddressAvailableInCheckout) && areShippingCountriesAvailable;

  const canAccessOrEditAddress = getIsSeller(user) ? canEditAddress : areShippingAddressesAvailable;
  const shouldShowMessage = (!canAccessOrEditAddress || !areAddressesExist) && !isLoading;

  const allAddresses = React.useMemo(() => {
    const initialSelectedAddress = shippingAddresses.find(({ shippingAddress }) => shippingAddress === initialSelectedShippingAddressId);

    return [initialSelectedAddress, ...shippingAddresses.filter(address => address !== initialSelectedAddress)].filter(isDefined);
  }, [shippingAddresses, initialSelectedShippingAddressId]);

  const addressesAlwaysVisible = React.useMemo(() => allAddresses.slice(0, NUMBER_OF_CARDS_TO_SHOW), [allAddresses]);
  const addressesToShow = React.useMemo(
    () => (shouldShowMoreShippingAddresses ? allAddresses : addressesAlwaysVisible),
    [addressesAlwaysVisible, allAddresses, shouldShowMoreShippingAddresses],
  );

  const totalCardCount = shippingAddresses.length + (canAddAddress ? 1 : 0);
  const shouldDisplayShowMore = totalCardCount > NUMBER_OF_CARDS_TO_SHOW;
  const showAddAddress = !shouldDisplayShowMore || shouldShowMoreShippingAddresses;

  const {
    fieldState: { error },
  } = useController({ name: 'shippingAddressId' });

  const errorMessage = error?.message as string | undefined;

  return (
    <div>
      <div className={styles.shippingAddressesHeading}>
        <Heading variant={['h2', 'blue']} title={t('shipping:shipping_address_one')} />
      </div>

      <div
        className={cx({
          [styles.cardsWrapper]: isLoading,
        })}
      >
        <SkeletonLayout
          skeleton={<Skeleton type="rectangle" className={styles.shippingAddressCardSkeleton} />}
          repeatLayout={NUMBER_OF_CARDS_TO_SHOW}
          isLoading={isLoading || !isDefined(shippingAddresses)}
        >
          <ShippingAddressRadioGroupField
            name="shippingAddressId"
            options={addressesToShow}
            className={styles.cardsWrapper}
            canAddAddress={canAddAddress}
            showAddAddress={showAddAddress}
          />

          {shouldDisplayShowMore && (
            <div className={styles.showMoreWrapper}>
              <button type="button" className={styles.showMoreButton} onClick={handleToggleShowMore}>
                {shouldShowMoreShippingAddresses ? t('common:show_less') : t('common:show_more')}
              </button>
            </div>
          )}
        </SkeletonLayout>
      </div>
      {isDefined(errorMessage) && <div className={styles.errorMessage}>{errorMessage}</div>}

      {shouldShowMessage && (
        <Notification>{getIsSeller(user) ? t('shipping:no_shipping_addresses') : t('shipping:no_shipping_addresses_buyer')}</Notification>
      )}
    </div>
  );
};
