import { useTranslation } from 'react-i18next';

import { isDefined } from '../../../../../utils/is';
import { Trend } from '../../../Trend/Trend';

import styles from './QuantityCellInfo.module.scss';

interface Props {
  removedQuantity: number | undefined;
  addedQuantity: number | undefined;
}

export const TrendTooltipLine = ({ removedQuantity, addedQuantity }: Props) => {
  const { t } = useTranslation(['common']);

  return (
    <div className={styles.tooltipLine}>
      <Trend className={styles.icon} size={20} type={isDefined(removedQuantity) ? 'down' : 'up'} />
      <span>
        {isDefined(removedQuantity) && t('common:units_removed', { amount: removedQuantity })}
        {isDefined(addedQuantity) && t('common:units_added', { amount: addedQuantity })}
      </span>
    </div>
  );
};
