import { Cms } from '@typings';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getCmsSectionById, getCurrentSectionId, updateSectionVisibility } from '../../../ducks';
import { useModalVisibility } from '../../../utils/hooks';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { ModalShell } from '../../various/ModalShell';

import { SectionVisibilityForm } from './SectionVisibilityForm';

export const SectionVisibilityModal = () => {
  const dispatch = useDispatch();
  const currentSectionId = useSelector(getCurrentSectionId);
  const sectionData = useSelector(getCmsSectionById(currentSectionId));
  const { t } = useTranslation(['cms', 'common', 'validation']);
  const { isModalVisible, hideModal } = useModalVisibility('SectionVisibilityModal');

  if (!isDefined(sectionData)) {
    return null;
  }

  const initialMarketIds =
    isDefined(sectionData.visibleFor.marketIds) && !isEmpty(sectionData.visibleFor.marketIds) ? sectionData.visibleFor.marketIds : [];

  const defaultValues = {
    isHidden: sectionData.visibleFor.isHidden,
    marketIds: initialMarketIds,
    screen: sectionData.visibleFor.screen,
  };

  const handleSubmit = (visibility: Cms.SectionVisibility) => {
    if (!isDefined(currentSectionId)) {
      return;
    }

    dispatch(updateSectionVisibility({ sectionId: currentSectionId, visibility }));
    hideModal();
  };

  return (
    <ModalShell shouldCloseOnLocationChange={false} isOpen={isModalVisible} onClose={hideModal} title={t('cms:section_visibility')}>
      <SectionVisibilityForm defaultValues={defaultValues} onCancel={hideModal} onSubmit={handleSubmit} />
    </ModalShell>
  );
};
