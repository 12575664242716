import { API, Cms } from '@typings';
import i18next from 'i18next';
import { combineEpics } from 'redux-observable';
import { EMPTY, from } from 'rxjs';
import { distinctUntilChanged, filter, ignoreElements, map, mergeMap, tap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { addToast } from '../../components/various/Toasts';
import {
  applyButtonStyle,
  applyEditedCustomStyle,
  applyTextStyle,
  closeButtonStyleEditor,
  closeTextStyleEditor,
  createButtonStyleFailure,
  createButtonStyleRequest,
  createButtonStyleSuccess,
  createTextStyleFailure,
  createTextStyleRequest,
  createTextStyleSuccess,
  deleteCustomStyleRequest,
  deleteTextStyleSuccess,
  editButtonStyleFailure,
  editButtonStyleRequest,
  editButtonStyleSuccess,
  editTextStyleFailure,
  editTextStyleRequest,
  editTextStyleSuccess,
  fetchCustomStylesFailure,
  fetchCustomStylesRequest,
  fetchCustomStylesSuccess,
  getButtonStyles,
  getCustomFonts,
  getTextStyles,
  resetCurentBlock,
  translateDeletedTextStylesFailure,
  translateDeletedTextStylesRequest,
  translateDeletedTextStylesSuccess,
} from '../../ducks';
import { updateButtonStylesTag, updateFontTags, updateTextStylesTag } from '../../logic/cms/styles';
import { getCustomStyleErrors } from '../../utils/getCustomStyleErrorsMessage';
import { isDefined } from '../../utils/is';
import { mapResponse } from '../../utils/operators/mapResponse';

const fetchTextStylesEpic: AppEpic = (action$, _, { customStylesRepository }) =>
  action$.pipe(
    filter(isActionOf(fetchCustomStylesRequest)),
    mergeMap(async () => customStylesRepository.fetchCustomStyles()),
    mapResponse(
      res => fetchCustomStylesSuccess(res.data),
      () => fetchCustomStylesFailure(),
    ),
  );

const updateTextStylesTagEpic: AppEpic = (_, store$) =>
  store$.pipe(
    map(getTextStyles),
    distinctUntilChanged(),
    tap(updateTextStylesTag),
    tap((textStyles: Record<string, Cms.TextStyle>) => {
      const customFonts = getCustomFonts(store$.value);
      updateFontTags(textStyles, customFonts);
    }),
    ignoreElements(),
  );

const updateButtonStylesTagEpic: AppEpic = (_, store$) =>
  store$.pipe(
    map(getButtonStyles),
    distinctUntilChanged(),
    tap(updateButtonStylesTag),
    tap((buttonStyles: Record<string, Cms.ButtonStyle>) => {
      const customFonts = getCustomFonts(store$.value);
      updateFontTags(buttonStyles, customFonts);
    }),
    ignoreElements(),
  );

const createTextStyleEpic: AppEpic = (action$, _, { customStylesRepository }) =>
  action$.pipe(
    filter(isActionOf(createTextStyleRequest)),
    mergeMap(({ payload: { textStyle, block, partIndex } }) =>
      from(customStylesRepository.createTextStyle(textStyle)).pipe(
        mapResponse(
          ({ data }) => {
            const [firstTextStyle] = data;

            if (!isDefined(firstTextStyle)) {
              addToast(i18next.t('cms:create_text_style_fail'));

              return [closeTextStyleEditor()];
            }

            addToast(
              i18next.t('cms:text_style_created', {
                textStyleName: firstTextStyle.name,
              }),
            );

            return [
              createTextStyleSuccess(firstTextStyle),
              applyTextStyle({ block, partIndex, textStyle: firstTextStyle }),
              closeTextStyleEditor(),
            ];
          },
          error => {
            addToast(i18next.t('cms:create_text_style_fail'));

            return createTextStyleFailure(getCustomStyleErrors(error));
          },
        ),
      ),
    ),
  );

const createButtonStyleEpic: AppEpic = (action$, _, { customStylesRepository }) =>
  action$.pipe(
    filter(isActionOf(createButtonStyleRequest)),
    mergeMap(({ payload: { buttonStyle, block, partIndex } }) =>
      from(customStylesRepository.createButtonStyle(buttonStyle)).pipe(
        mapResponse(
          ({ data }) => {
            const firstButtonStyle = data[0] as Cms.ButtonStyle;

            addToast(
              i18next.t('cms:button_style_created', {
                buttonStyleName: firstButtonStyle.name,
              }),
            );

            return [
              createButtonStyleSuccess(firstButtonStyle),
              applyButtonStyle({ block, buttonStyle: firstButtonStyle, partIndex }),
              closeButtonStyleEditor(),
            ];
          },
          error => {
            addToast(i18next.t('cms:create_button_style_fail'));

            return createButtonStyleFailure(getCustomStyleErrors(error));
          },
        ),
      ),
    ),
  );

const deleteTextStyleEpic: AppEpic = (action$, _, { customStylesRepository }) =>
  action$.pipe(
    filter(isActionOf(deleteCustomStyleRequest)),
    mergeMap(({ payload: { id, name } }) =>
      from(customStylesRepository.deleteCustomStyle(id)).pipe(
        mapResponse(
          () => {
            addToast(i18next.t('cms:text_style_deleted', { textStyleName: name }));

            return [deleteTextStyleSuccess(id), resetCurentBlock()];
          },
          () => {
            addToast(i18next.t('cms:delete_text_style_fail', { textStyleName: name }));

            return EMPTY;
          },
        ),
      ),
    ),
  );

const editTextStyleEpic: AppEpic = (action$, _, { customStylesRepository }) =>
  action$.pipe(
    filter(isActionOf(editTextStyleRequest)),
    mergeMap(({ payload }) =>
      from(
        customStylesRepository.editCustomStyle({
          customStyle: payload.textStyle,
          id: payload.id,
        }),
      ).pipe(
        mapResponse(
          ({ data }: API.SuccessResponse<Cms.TextStyle>) => {
            addToast(i18next.t('cms:text_style_updated', { textStyleName: data.name }));

            return [editTextStyleSuccess(data), applyEditedCustomStyle(data), closeTextStyleEditor()];
          },
          error => {
            addToast(i18next.t('cms:update_text_style_fail'));

            return editTextStyleFailure(getCustomStyleErrors(error));
          },
        ),
      ),
    ),
  );

const editButtonStyleEpic: AppEpic = (action$, _, { customStylesRepository }) =>
  action$.pipe(
    filter(isActionOf(editButtonStyleRequest)),
    mergeMap(({ payload }) =>
      from(
        customStylesRepository.editCustomStyle({
          customStyle: payload.buttonStyle,
          id: payload.id,
        }),
      ).pipe(
        mapResponse(
          ({ data }: API.SuccessResponse<Cms.ButtonStyle>) => {
            addToast(
              i18next.t('cms:button_style_updated', {
                buttonStyleName: data.name,
              }),
            );

            return [editButtonStyleSuccess(data), applyEditedCustomStyle(data), closeButtonStyleEditor()];
          },
          error => {
            addToast(i18next.t('cms:update_button_style_fail'));

            return editButtonStyleFailure(getCustomStyleErrors(error));
          },
        ),
      ),
    ),
  );

const translateDeletedTextStylesEpic: AppEpic = (action$, _, { customStylesRepository }) =>
  action$.pipe(
    filter(isActionOf(translateDeletedTextStylesRequest)),
    mergeMap(action =>
      from(customStylesRepository.translateDeletedCustomStyles(action.payload)).pipe(
        mapResponse(
          res => translateDeletedTextStylesSuccess(res.data),
          () => translateDeletedTextStylesFailure(),
        ),
      ),
    ),
  );

export const customStylesEpic = combineEpics(
  createTextStyleEpic,
  createButtonStyleEpic,
  deleteTextStyleEpic,
  editTextStyleEpic,
  editButtonStyleEpic,
  fetchTextStylesEpic,
  updateTextStylesTagEpic,
  updateButtonStylesTagEpic,
  translateDeletedTextStylesEpic,
);
