import { isDefined } from '../../../utils/is';

import styles from './DataPreview.module.scss';

interface Props<T, K> {
  data: T;
  labels: [K, string][];
}

export const DataPreview = <T extends Record<K, string | number>, K extends keyof T>(props: Props<T, K>) => (
  <table className={styles.table}>
    <tbody>
      {props.labels.map(
        ([key, label], idx) =>
          isDefined(props.data[key]) && (
            <tr key={idx}>
              <th className={styles.header}>{label}</th>
              <td data-sentry-mask>{props.data[key] === '' ? '-' : props.data[key]}</td>
            </tr>
          ),
      )}
    </tbody>
  </table>
);
