import { Cms } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DisappearType, useHover, useLayer } from 'react-laag';
import { useSelector } from 'react-redux';

import { getIsPublishing } from '../../../../../ducks';
import { getIsAnyLanguagePublished } from '../../../../../logic/pages';
import Button from '../../../../various/Button';
import Icon, { IconType } from '../../../../various/Icon';
import { MenuContainer } from '../../../../various/PopoverMenu/MenuElements/MenuContainer';

import styles from './MultiLanguageButton.module.scss';
import { PublishLanguagesPopup } from './PublishLanguagesPopup';

interface Props {
  pageMeta: Cms.Page;
}

export const MultiLanguageButton = ({ pageMeta }: Props) => {
  const { t } = useTranslation(['cmsConfirmationConfig', 'cms', 'common']);
  const [isOpen, setIsOpen] = React.useState(false);
  const [hasCursor, hoverProps] = useHover();

  const pageLanguages = pageMeta.languages ?? [];
  const isPublishing = useSelector(getIsPublishing);

  const iconRotation = isOpen ? 180 : 0;

  const closeMenu = React.useCallback(
    (force?: boolean) => {
      if (!hasCursor || force) {
        setIsOpen(false);
      }
    },
    [hasCursor],
  );

  const handleTogglePublishOptions = React.useCallback(() => {
    setIsOpen(current => !current);
  }, [setIsOpen]);

  const handleDisappear = (type: DisappearType) => {
    if (type === 'full') {
      closeMenu(true);
    }
  };

  const { renderLayer, layerProps, triggerProps } = useLayer({
    auto: true,
    isOpen,
    onDisappear: handleDisappear,
    onOutsideClick: closeMenu,
    overflowContainer: false,
    placement: 'bottom-end',
    possiblePlacements: ['bottom-start', 'bottom-end', 'top-start', 'top-end'],
  });

  const publishButtonName = getIsAnyLanguagePublished(pageLanguages) ? t('common:update') : t('cms:publish');

  return (
    <>
      <Button
        variant={['button', 'small', 'blue']}
        isLoading={isPublishing}
        isActive={isOpen}
        onClick={handleTogglePublishOptions}
        {...triggerProps}
      >
        {publishButtonName}
        <Icon className={styles.publishArrow} type={IconType.ArrowRounded} rotation={iconRotation} />
      </Button>
      {isOpen &&
        renderLayer(
          <MenuContainer
            layerProps={layerProps}
            hoverProps={hoverProps}
            groupLabel={t('cms:languages_to_publish')}
            className={styles.popupContainer}
          >
            <PublishLanguagesPopup closeMenu={closeMenu} pageLanguages={pageLanguages} pageId={pageMeta.id} />
          </MenuContainer>,
        )}
    </>
  );
};
