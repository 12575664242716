import { Cms } from '@typings';
import { omit } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ButtonStyles } from '../../../cmsTemplates';
import { getCmsBlockById, getCurrentBlockId, getCurrentItem } from '../../../ducks';
import { isButtonPart } from '../../../logic/cms/styles';
import { getContentBlockPart, getScreenRelativeStyles } from '../../../logic/pages';
import { useSpecifiedDeviceWidth } from '../../../utils/hooks';
import { useContentPartToolbar } from '../../../utils/hooks/cms/useContentPartToolbar';
import { isDefined } from '../../../utils/is';
import { EditorUIContext } from '../context/EditorUIContext';
import { AlignmentSwitch, ButtonPartStyleSelect, RemoveButton } from '../TextTools';
import { Toolbar } from '../Toolbar';
import { AcceptDiscard } from '../Toolbar/Tools/AcceptDiscard';

const DEFAULT_BUTTON_SETTINGS: Cms.LocalButtonChanges & {
  buttonStyle: string;
} = {
  buttonStyle: ButtonStyles.FilledPrimary,
  justifySelf: 'center',
};

const TOOLBAR_TYPE = 'Button';
const TOOLBAR_POSITION_OFFSET = 20;

export const ButtonToolbar = () => {
  const [settings, setSettings] = React.useState(DEFAULT_BUTTON_SETTINGS);
  const currentBlockId = useSelector(getCurrentBlockId);
  const { screenType } = React.useContext(EditorUIContext);
  const screenWidth = useSpecifiedDeviceWidth();
  const currentItem = useSelector(getCurrentItem);
  const { t } = useTranslation(['cms']);
  const block = useSelector(getCmsBlockById(currentBlockId));
  const { closeToolbar, onStyleChange, removeCurrentPart, updateContentSettings } = useContentPartToolbar(TOOLBAR_TYPE);

  React.useLayoutEffect(() => {
    const contentPart = getContentBlockPart(block, currentItem.index);

    if (!isDefined(contentPart) || !isButtonPart(contentPart)) {
      return;
    }

    setSettings({
      ...contentPart.general,
      ...getScreenRelativeStyles(screenWidth, contentPart),
    });
  }, [block, currentItem, screenWidth]);

  const onAlignmentChange = React.useCallback(
    (alignment: string) => {
      updateContentSettings([screenType, 'justifySelf'], alignment);
    },
    [updateContentSettings, screenType],
  );

  const handleRemoveButton = React.useCallback(() => {
    removeCurrentPart();
  }, [removeCurrentPart]);

  const handleCloseToolbar = React.useCallback(
    (event?: MouseEvent) => {
      closeToolbar(event);
    },
    [closeToolbar],
  );

  const tools = React.useMemo(() => {
    const isDesktop = screenType === 'desktop';

    const buttonPartStyleSelect = (
      <ButtonPartStyleSelect
        key="button_style_select"
        currentStyle={settings.buttonStyle}
        localChanges={omit(['buttonstyle'], settings)}
        onChange={onStyleChange}
      />
    );

    return [
      ...(!isDesktop ? [] : [buttonPartStyleSelect]),
      <AlignmentSwitch key="alignment_switch" value={settings.justifySelf} onChange={onAlignmentChange} switcherType="button" />,
      ...(!isDesktop ? [] : [<RemoveButton key="remove_button" onClick={handleRemoveButton} />]),
      <AcceptDiscard key="acceptdiscard" acceptAction={handleCloseToolbar} />,
    ];
  }, [screenType, settings, onStyleChange, onAlignmentChange, handleRemoveButton, handleCloseToolbar]);

  return (
    <Toolbar
      type={TOOLBAR_TYPE}
      title={t('cms:button')}
      tools={tools}
      placement="top-center"
      offset={TOOLBAR_POSITION_OFFSET}
      roundedBottomCorners
      onClose={handleCloseToolbar}
    />
  );
};
