import { Cms } from '@typings';
import i18next from 'i18next';
import { uniq } from 'ramda';
import { combineEpics } from 'redux-observable';
import { EMPTY } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { insertTemplate } from '../../cmsTemplates';
import { addToast } from '../../components/various/Toasts';
import {
  addGroupFromTemplate,
  automaticGroupChangeRequest,
  automaticGroupChangeSuccess,
  getCmsBlockById,
  getCmsGroupById,
  getCmsSections,
  getPageMetaData,
  groupCreated,
  groupRemoved,
  manualLayoutEditingRequest,
  pasteSection,
  removeGroup,
  removeSection,
  sectionRemoved,
  updateAllSectionsMarkets,
  validateSectionsMarkets,
} from '../../ducks';
import { removeLocalizedBlocks } from '../../ducks/cms/localizations';
import {
  createBlockFromModel,
  getBlocksWithInsertedTemplates,
  getCanBeMirrorred,
  getCanReplaceContents,
  getCanReplaceProducts,
  getMachingBlockTypes,
  getMirrorredBlocksWithSpanYPriority,
  hasEmptyBlocks,
} from '../../logic/cms/changeGroup';
import { isDefined } from '../../utils/is';

const addGroupFromTemplateEpic: AppEpic = action$ =>
  action$.pipe(
    filter(isActionOf(addGroupFromTemplate)),
    map(action => action.payload),
    map(payload => ({
      blocksGroup: insertTemplate(payload.model),
      insertAfter: payload.insertAfter,
      sectionId: payload.sectionId,
    })),
    map(payload => groupCreated(payload)),
  );

const automaticGroupChangeEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(automaticGroupChangeRequest)),
    map(action => action.payload),
    map(payload => {
      const state = state$.value;
      const group = getCmsGroupById(payload.groupId)(state);
      const blocks = group?.blocks.map(id => getCmsBlockById(id)(state)).filter(isDefined) ?? [];

      return { ...payload, blocks };
    }),
    mergeMap(({ blocks, groupId, models, sectionId }) => {
      const canReplaceProducts = getCanReplaceProducts(blocks, models);
      const matchingBlocks = getMachingBlockTypes(blocks, models);

      const autoChangeRules = [
        {
          condition: () => hasEmptyBlocks(blocks),
          output: () => models.map(model => createBlockFromModel(model, groupId)),
        },
        {
          condition: () => models.length === 1 && canReplaceProducts && matchingBlocks.length <= 1,
          output: () => {
            const canKeepSettings = matchingBlocks.length === 1;
            const firstModel = models[0] as Cms.BlockModel;

            return [createBlockFromModel(firstModel, groupId, canKeepSettings ? matchingBlocks[0]?.settings : undefined)];
          },
        },
        {
          condition: () => models.length === blocks.length && canReplaceProducts && getCanBeMirrorred(models, blocks),
          output: () => getMirrorredBlocksWithSpanYPriority(blocks, models),
        },
        {
          condition: () => {
            const canReplaceContents = getCanReplaceContents(matchingBlocks, models);

            return canReplaceContents && canReplaceProducts;
          },
          output: () => getBlocksWithInsertedTemplates(blocks, models, groupId),
        },
      ];

      const validAutoRule = autoChangeRules.find(({ condition }) => condition());

      return isDefined(validAutoRule) ?
          [
            automaticGroupChangeSuccess({
              blocks: validAutoRule.output(),
              groupId,
              sectionId,
            }),
          ]
        : [manualLayoutEditingRequest({ blocks, groupId, models, sectionId })];
    }),
  );

const removeGroupEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(removeGroup)),
    map(action => action.payload),
    map(({ sectionId, groupId }) => ({
      blockIds: getCmsGroupById(groupId)(state$.value)?.blocks ?? [],
      groupId,
      sectionId,
    })),
    mergeMap(ids => [groupRemoved(ids), removeLocalizedBlocks({ blockIds: ids.blockIds })]),
  );

const removeSectionEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(removeSection)),
    map(action => action.payload),
    map(sectionId => {
      const state = state$.value;
      const sectionData = getCmsSections(state).find(section => section.id === sectionId);

      if (!isDefined(sectionData)) {
        return;
      }

      const blockIds = sectionData.groupsOrder.flatMap(id => getCmsGroupById(id)(state)?.blocks ?? []);

      return { blockIds, groupIds: sectionData.groupsOrder, sectionId };
    }),
    mergeMap(ids => {
      if (isDefined(ids)) {
        return [sectionRemoved(ids), removeLocalizedBlocks({ blockIds: ids.blockIds })];
      }

      addToast(i18next.t('cms:no_section_to_remove'));

      return EMPTY;
    }),
  );

const validateMarketsEpic: AppEpic = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(validateSectionsMarkets)),
    map(action => action.payload.pageMarketIds),
    map(pageMarketIds => ({
      pageMarketIds,
      sectionsMarketIds: uniq(
        getCmsSections(store$.value)
          .flatMap(section => section.visibleFor.marketIds)
          .filter(isDefined),
      ),
    })),
    filter(({ pageMarketIds, sectionsMarketIds }) => !sectionsMarketIds.every(market => pageMarketIds.includes(market))),
    map(({ pageMarketIds }) => {
      addToast(i18next.t('cms:section_visibility_auto_update'), {
        duration: 10000,
      });

      return updateAllSectionsMarkets({ pageMarketIds });
    }),
  );

export const pasteSectionEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(pasteSection)),
    map(() => getPageMetaData(state$.value)?.markets.map(({ id }) => id)),
    filter(isDefined),
    map(pageMarketIds => validateSectionsMarkets({ pageMarketIds })),
  );

export const sectionsEpic = combineEpics(
  addGroupFromTemplateEpic,
  automaticGroupChangeEpic,
  removeGroupEpic,
  removeSectionEpic,
  validateMarketsEpic,
  pasteSectionEpic,
);
