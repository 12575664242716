import { flexRender, Table } from '@tanstack/react-table';
import cx from 'classnames';

import styles from '../../Table.module.scss';

import { TableSorter } from './TableSorter';

export interface TableHeaderProps<T> {
  table: Table<T>;
  className?: string;
  dataTestId?: string;
}

export const TableHeader = <T,>({ table, className }: TableHeaderProps<T>) => {
  return (
    <thead>
      {table.getHeaderGroups().map(headerGroup => (
        <tr className={styles.header} key={headerGroup.id}>
          {headerGroup.headers.map(columnHeader => {
            const { column } = columnHeader;
            const { meta, header } = column.columnDef;
            const sortOrder = column.getIsSorted();

            return (
              <th
                onClick={column.getToggleSortingHandler()}
                className={cx(
                  className,
                  styles.headerCell,
                  {
                    [styles.hasSorter]: column.getCanSort(),
                    [styles.sorted]: !!sortOrder,
                  },
                  meta?.align && styles[meta.align],
                  meta?.headerClassName,
                )}
                key={columnHeader.id}
              >
                <div className={styles.headerCellContainer}>
                  <div className={styles.headerTitle}>
                    {columnHeader.isPlaceholder ? null : flexRender(header, columnHeader.getContext())}
                  </div>
                  {column.getCanSort() && <TableSorter sortOrder={sortOrder !== false ? sortOrder : null} />}
                </div>
              </th>
            );
          })}
        </tr>
      ))}
    </thead>
  );
};
