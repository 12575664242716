import { Product } from '@typings';
import cx from 'classnames';
import { useSelector } from 'react-redux';

import { getCountries, getProductDetailsAttributes } from '../../../ducks';
import { applyCountryNameToAttribute, getCustomAttributesWithValue } from '../../../logic/products';

import { AttributeRow } from './AttributeRow';
import styles from './ProductAttributes.module.scss';

interface Props {
  product: Product.Standard;
  className?: string;
}

export const ProductAttributes = ({ product, className }: Props) => {
  const attributes = useSelector(getProductDetailsAttributes);
  const countries = useSelector(getCountries);
  const attributesWithValue = applyCountryNameToAttribute(getCustomAttributesWithValue(attributes, product), countries);

  const halfLength = Math.round(attributesWithValue.length / 2);
  const columns = [attributesWithValue.slice(0, halfLength), attributesWithValue.slice(halfLength, attributesWithValue.length)];

  return (
    <dl className={cx(styles.attributesContainer, className)}>
      {columns.map((column, idx) => (
        <div className={styles.attributesColumn} key={idx}>
          {column.map(({ name, value, key }) => (
            <AttributeRow name={name} value={value} key={key} />
          ))}
        </div>
      ))}
    </dl>
  );
};
