import { Product } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getCmsProducts, getDefaultImageSize, getProductsCountInBlock } from '../../../ducks';
import { localProductsSearch } from '../../../logic/products';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import Icon, { IconType } from '../../various/Icon';
import sortableStyles from '../../various/Sortable/SortableList/SortableList.module.scss';
import { ProductsList } from '../ProductsList/ProductsList';

import { GenericProduct } from './GenericProduct';

const LOCAL_SEARCH_KEYS: TypedProps<Required<Product.Base>, Nullable<string>>[] = ['name', 'sku', 'brandName'];

interface Props {
  selectedProducts: string[];
  onSelectedProducts: (productIds: string[]) => void;
  searchAddedPhrase: string;
  onSearchAddedPhrase: (text: string) => void;
}

export const AddedProductsSelectors = ({ selectedProducts, onSelectedProducts, searchAddedPhrase, onSearchAddedPhrase }: Props) => {
  const { t } = useTranslation(['cms']);
  const imageSize = useSelector(getDefaultImageSize);
  const cmsProducts = useSelector(getCmsProducts);
  const productsCount = useSelector(getProductsCountInBlock);

  const handleRemoveProduct = React.useCallback(
    (productId: string) => (_: React.MouseEvent) => {
      onSelectedProducts(selectedProducts.filter(item => item !== productId));
    },
    [selectedProducts],
  );

  const handleSortEnd = React.useCallback((sortedArray: string[]) => onSelectedProducts(sortedArray), []);

  const renderAddedProduct = React.useCallback(
    (id: string) => (
      <GenericProduct
        cmsProducts={cmsProducts}
        imageSize={imageSize}
        id={id}
        action={
          <button className={sortableStyles.actionButton} onClick={handleRemoveProduct(id)}>
            <Icon type={IconType.Trash} />
          </button>
        }
      />
    ),
    [cmsProducts, imageSize, handleRemoveProduct],
  );

  const filteredAddedProducts = React.useMemo(
    () =>
      isEmpty(searchAddedPhrase) ? selectedProducts : (
        selectedProducts.filter(item => {
          const productData = cmsProducts[item];

          if (!isDefined(productData)) {
            return false;
          }

          const filterProductsBySearchPhrase = localProductsSearch(productData, LOCAL_SEARCH_KEYS);

          return filterProductsBySearchPhrase(searchAddedPhrase);
        })
      ),
    [selectedProducts, searchAddedPhrase, cmsProducts],
  );

  return (
    <ProductsList
      listText={t('cms:added_product_count', {
        count: selectedProducts.length,
        totalCount: productsCount,
      })}
      items={filteredAddedProducts}
      renderFunction={renderAddedProduct}
      handleSortEnd={handleSortEnd}
      searchPhrase={searchAddedPhrase}
      onSearchChange={onSearchAddedPhrase}
    />
  );
};
