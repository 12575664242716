import { Navigation } from '@typings';

import { compiledPaths } from '../paths';
import { isDefined } from '../utils/is';
import { isEmpty } from '../utils/isEmpty';

const PAGES_URL = '/pages/';

export const getIsUserFolderMenuItem = (item: Navigation.UserMenuItem): item is Navigation.UserFolderMenuItem => item.type === 'folder';

export const getIsMenuItemActive = (link: string, pathname: string) => {
  const matchesExactLink = link === pathname;
  const matchesProductsPath = link === compiledPaths.PRODUCTS_ORDER({}) && pathname.includes(compiledPaths.PRODUCTS_ORDER({}));
  const matchesPagePath = link.includes(PAGES_URL) && pathname.includes(`${link}/`);

  return matchesExactLink || matchesProductsPath || matchesPagePath;
};

export const getHasActivePageChild = (item: Navigation.UserMenuItem, pathname: string) => {
  if (!isDefined(item.children) || isEmpty(item.children)) {
    return false;
  }

  return item.children.some(child => {
    if (child.type !== 'page') {
      return false;
    }

    const targetUrl = `${PAGES_URL}${child.url}`;

    return getIsMenuItemActive(targetUrl, pathname);
  });
};

export const flattenNavigationItems = (items: Navigation.AnyMenuItem[]) => items.flatMap(item => [item, ...(item.children ?? [])]);
