/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_SIZE = 20;
const ORIGINAL_COLOR = '#737373';

export const MoveDown = ({ width = ORIGINAL_SIZE, color = ORIGINAL_COLOR }: IconComponentProps) => {
  return (
    <svg width={width} height={width} viewBox="0 0 20 20" fill="none">
      <path
        d="M14.6327 8.16942C14.5137 8.05644 14.3725 8 14.2095 8H5.78207C5.61893 8 5.4779 8.05644 5.35874 8.16942C5.23958 8.28252 5.18005 8.41624 5.18005 8.57082C5.18005 8.72537 5.23958 8.85908 5.35874 8.97209L9.57246 12.9672C9.69175 13.0802 9.83278 13.1368 9.99579 13.1368C10.1588 13.1368 10.3 13.0802 10.419 12.9672L14.6327 8.97206C14.7518 8.85908 14.8115 8.72537 14.8115 8.57078C14.8115 8.41624 14.7518 8.28252 14.6327 8.16942Z"
        fill={color}
      />
    </svg>
  );
};
