import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { isDefined } from '../../../../utils/is';
import { ShareButton } from '../../../various/IconButton/ShareButton';
import { ClearFilterButton } from '../ClearFilterButton';
import { FiltersPanelSelectorsContext } from '../FiltersPanel/context/FiltersPanelSelectorsContext';

import styles from './FiltersBarHeader.module.scss';

interface Props {
  label: string;
  productsCount: number;
  shareUrl?: string;
  showFilterDetails?: boolean;
  onClearFilters: () => void;
}

export const FiltersBarHeader = ({ label, productsCount, shareUrl, showFilterDetails = false, onClearFilters }: Props) => {
  const { t } = useTranslation(['common', 'products']);

  const { getHasActiveFilters } = React.useContext(FiltersPanelSelectorsContext);
  const hasAnyActiveFilters = useSelector(getHasActiveFilters);

  const filterDetails = (
    <>
      <span className={styles.results}>({productsCount})</span>
      {isDefined(shareUrl) && (
        <ShareButton
          message={t('products:share_button_message')}
          tooltipText={t('products:copy_shareable_link')}
          shareURL={shareUrl}
          className={styles.shareButton}
        />
      )}
      {hasAnyActiveFilters && <ClearFilterButton name={t('common:clear')} appearance="clearAll" onClick={onClearFilters} />}
    </>
  );

  return (
    <h2 className={styles.filtersTitle}>
      {label}
      {showFilterDetails && filterDetails}
    </h2>
  );
};
