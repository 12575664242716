import { DeliveryWindow } from '@typings';
import cx from 'classnames';
import React from 'react';

import { getHumanTimePeriod, isRunning } from '../../../logic/deliveryWindows';
import { isNull } from '../../../utils/is';
import Icon, { IconType } from '../Icon';
import { Tooltip } from '../Tooltip';

import styles from './DelwinNameAndDate.module.scss';
import { TooltipContent } from './TooltipContent';

interface Props {
  deliveryWindow: DeliveryWindow.Mixed;
  insertCommas?: boolean;
  iconClassName?: string;
  shouldStretch?: boolean;
}

const preventDefault = (evt: React.UIEvent) => {
  evt.stopPropagation();
  evt.preventDefault();
};

export const DelwinNameAndDate = ({ deliveryWindow, insertCommas = false, iconClassName, shouldStretch }: Props) => {
  const { deliveryPeriod } = getHumanTimePeriod(deliveryWindow);

  const icon = React.useMemo(() => {
    if (isRunning(deliveryWindow) || isNull(deliveryPeriod)) {
      return deliveryWindow.stockType === 'stock' ? IconType.DelwinStock : IconType.DelwinPreorder;
    }

    return deliveryWindow.stockType === 'stock' ? IconType.DelwinStockWithDates : IconType.DelwinPreorderWithDates;
  }, [deliveryPeriod, deliveryWindow]);

  const handleOnParentClose = () => undefined;

  return (
    <span
      className={cx(styles.delwinWrapper, {
        [styles.withCommas]: insertCommas,
        [styles.stretch]: shouldStretch,
      })}
    >
      <span>{deliveryWindow.name}</span>
      <Tooltip content={<TooltipContent deliveryWindow={deliveryWindow} />} onParentClose={handleOnParentClose}>
        <div className={cx(styles.icon, iconClassName)} role="presentation" onClick={preventDefault}>
          <Icon type={icon} />
        </div>
      </Tooltip>
    </span>
  );
};
