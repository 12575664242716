import { Buyer, Id } from '@typings';
import { pick } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getIsBuyerEditingEnabled } from '../../../../ducks/config';
import { useCreateBuyer } from '../../../../services/hooks/account/useCreateBuyer';
import { useUpdateBuyer } from '../../../../services/hooks/account/useUpdateBuyer';
import { useModalVisibility } from '../../../../utils/hooks';
import { isDefined } from '../../../../utils/is';
import Heading from '../../../various/Heading';
import { ModalShell } from '../../../various/ModalShell';
import { Button } from '../../../various/NewButton';
import { SectionHeadingWrapper } from '../../../various/SectionHeading';

import { BuyerForm } from './BuyerForm';
import { BuyersTable } from './BuyersTable';

interface Props {
  accountId: Id;
  buyers: Record<string, Buyer> | undefined;
  isLoading?: boolean;
}

export const Buyers = ({ accountId, buyers = {}, isLoading }: Props) => {
  const { t } = useTranslation(['common', 'buyers', 'validation']);

  const isBuyerEditingEnabled = useSelector(getIsBuyerEditingEnabled);

  const {
    isModalVisible: isCreateModalVisible,
    hideModal: hideCreateModal,
    showModal: showCreateModal,
  } = useModalVisibility('CreateBuyerModal');

  const {
    isModalVisible: isUpdateModalVisible,
    hideModal: hideUpdateModal,
    showModal: showUpdateModal,
  } = useModalVisibility('UpdateBuyerModal');

  const [editedBuyerId, setEditedBuyerId] = React.useState('');

  const { mutate: createBuyer, isPending: isCreatingBuyer } = useCreateBuyer();
  const { mutate: updateBuyer, isPending: isUpdatingBuyer } = useUpdateBuyer();

  const handleEditBuyer = (buyerId: string) => {
    setEditedBuyerId(buyerId);
    showUpdateModal();
  };

  const handleUpdateBuyerSubmit = (data: Buyer.BuyerDTO) => {
    updateBuyer(
      { accountId, buyerId: editedBuyerId, data },
      {
        onSuccess: hideUpdateModal,
      },
    );
  };

  const handleCreateBuyerSubmit = (data: Buyer.BuyerDTO) => {
    createBuyer(
      { accountId, data },
      {
        onSuccess: hideCreateModal,
      },
    );
  };

  const editedBuyerDefaultValues = React.useMemo(() => {
    const buyerData = buyers[editedBuyerId];

    if (!isDefined(buyerData)) {
      return;
    }

    return {
      ...pick(['email', 'firstName', 'lastName'], buyerData),
      mobileNumber: buyerData.mobileNumber ?? '',
      phoneNumber: buyerData.phoneNumber ?? '',
    };
  }, [buyers, editedBuyerId]);

  return (
    <>
      <SectionHeadingWrapper>
        <Heading title={t('buyers:buyer_other')} variant={['h2', 'black']} />
        {isBuyerEditingEnabled && (
          <>
            <Button size="large" onClick={showCreateModal}>
              {t('buyers:add_buyer')}
            </Button>
            <ModalShell title={t('buyers:add_buyer')} isOpen={isCreateModalVisible} onClose={hideCreateModal}>
              <BuyerForm isLoading={isCreatingBuyer} onCancel={hideCreateModal} onSubmit={handleCreateBuyerSubmit} />
            </ModalShell>
            <ModalShell title={t('buyers:update_buyer')} isOpen={isUpdateModalVisible} onClose={hideUpdateModal}>
              <BuyerForm
                defaultValues={editedBuyerDefaultValues}
                isLoading={isUpdatingBuyer}
                onCancel={hideUpdateModal}
                onSubmit={handleUpdateBuyerSubmit}
              />
            </ModalShell>
          </>
        )}
      </SectionHeadingWrapper>
      <BuyersTable accountId={accountId} buyers={buyers} isLoading={isLoading} onEditRow={handleEditBuyer} />
    </>
  );
};
