import { Cms } from '@typings';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getPageMetaData } from '../../../../ducks';
import { getPublishedPagesWithPartStyle } from '../../../../logic/pages';
import { usePages } from '../../../../services/hooks/pages/usePages';
import { isEmpty } from '../../../../utils/isEmpty';
import { Autocomplete, Option } from '../../../various/Fields/Select';
import { FieldLabel } from '../../../various/Form';
import { Link } from '../../../various/Link';

import styles from './ContentPartStyleSelect.module.scss';

interface Props {
  partStyle: Cms.ContentPartStyle;
  isPartStyleCanBeReplaced: boolean;
  selectedPartStyle: string;
  onChange: (value: string) => void;
  isTextStyle: boolean;
  partStyles: Record<string, Cms.ContentPartStyle>;
}

export const ConfirmDeleteStyleContent = ({
  partStyle,
  isPartStyleCanBeReplaced,
  selectedPartStyle,
  onChange,
  isTextStyle,
  partStyles,
}: Props) => {
  const { t } = useTranslation(['cms']);
  const { data: pages = [] } = usePages({ search: '' });
  const currentPage = useSelector(getPageMetaData);
  const pagesWithPartStyle = getPublishedPagesWithPartStyle(pages, partStyle.id, isTextStyle).filter(({ id }) => id !== currentPage?.id);
  const existingPartStyles = Object.values(partStyles).filter(item => item.className !== partStyle.className);

  return (
    <>
      <p>
        {isTextStyle ?
          <Trans t={t} i18nKey="cms:text_style_delete_warning">
            This action will delete text style from <strong>all CMS pages</strong>. If this text style is used on the page, it has to be
            replaced with one of the existing text styles.
          </Trans>
        : <Trans t={t} i18nKey="cms:button_style_delete_warning">
            This action will delete button style from <strong>all CMS pages</strong>. If this button style is used on the page, it has to be
            replaced with one of the existing button styles.
          </Trans>
        }

        <br />
        {!isEmpty(pagesWithPartStyle) && (
          <span className={styles.pagesWithTextStyleMessage}>{t(isTextStyle ? 'cms:text_style_used' : 'cms:button_style_used')}</span>
        )}
      </p>
      <ul>
        {pagesWithPartStyle.map(page => (
          <li key={page.id}>
            <Link target="_blank" variant="blue" to={`/cms/${page.id}`}>
              {page.name}
            </Link>
          </li>
        ))}
      </ul>
      {isPartStyleCanBeReplaced && (
        <div className={styles.replaceWithBlock}>
          <FieldLabel label={t('cms:replace_with')}>
            <Autocomplete dataTestId="replacePartStyleSelect" value={selectedPartStyle} size="large" overflowContainer onChange={onChange}>
              {existingPartStyles.map(style => (
                <Option key={style.className} value={style.className}>
                  {style.name}
                </Option>
              ))}
            </Autocomplete>
          </FieldLabel>
        </div>
      )}
    </>
  );
};
