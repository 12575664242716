import { useController, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Linesheet } from '../../../../../typings';
import { useLinesheetTemplates } from '../../../services/hooks/linesheet/useLinesheetTemplates';
import { isDefined } from '../../../utils/is';
import { Option } from '../../various/Fields/Select';
import { FieldWrapper, FormGrid, SelectField, SwitchField } from '../../various/Form';
import { MaxAttributeWarning, ProductAttributesSortList } from '../../various/ProductAttributesSortList';
import { ShowMoreBox } from '../../various/ShowMoreBox/ShowMoreBox';

interface Props {
  selectedTemplate?: Linesheet.TemplateData;
}

export const LinesheetGeneratorOptions = ({ selectedTemplate }: Props) => {
  const { t } = useTranslation(['linesheets', 'common']);
  const { data } = useLinesheetTemplates();

  const { register, setValue, getValues } = useFormContext<Linesheet.FormData>();
  const sortBy = useWatch<Linesheet.FormData, 'sortBy'>({ name: 'sortBy' });
  const { field: attributesField } = useController<Linesheet.FormData, 'attributes'>({ name: 'attributes' });

  const defaultSortBy = data?.dictionary.sortBy[0]?.key;
  const shouldDisableThenSortBy = sortBy === defaultSortBy;

  const handleSortByChange = (value: string) => {
    if (value === defaultSortBy || value === getValues('thenSortBy')) {
      setValue('thenSortBy', 'none');
    }
  };

  return (
    <div>
      <ShowMoreBox title={t('linesheets:show_additional_options')} hint={t('linesheets:more_fields')}>
        <FormGrid rowGap="large">
          <SwitchField
            orientation="horizontal"
            label={t('linesheets:skip_out_of_stock.label')}
            description={t('linesheets:skip_out_of_stock.hint')}
            {...register('skipOutOfStock')}
          />
          <SwitchField
            orientation="horizontal"
            label={t('linesheets:show_stock_values.label')}
            description={t('linesheets:show_stock_values.hint')}
            {...register('showStockValues')}
          />
          <SwitchField
            orientation="horizontal"
            label={t('linesheets:show_buyer_price.label')}
            description={`${t('linesheets:show_buyer_price.hint')} ${t('linesheets:show_buyer_price.hint_seller')}`}
            {...register('showCustomerPrice')}
          />
          <FieldWrapper as="span" orientation="horizontal" label={t('linesheets:product_attributes')}>
            <ProductAttributesSortList
              attributeIds={attributesField.value}
              allAttributes={data?.dictionary.attributes ?? []}
              onChange={attributesField.onChange}
            />
            {isDefined(selectedTemplate) && (
              <MaxAttributeWarning
                layoutType={selectedTemplate.layoutType}
                linesheetName={selectedTemplate.name}
                numberOfItems={attributesField.value?.length ?? 0}
              />
            )}
          </FieldWrapper>
          <SelectField
            name="groupBy"
            orientation="horizontal"
            description={t('linesheets:choose_group_by.hint')}
            label={t('linesheets:choose_group_by.label')}
            placeholder={t('common:none')}
          >
            {data?.dictionary.groupBy.map(({ key, value }) => (
              <Option key={key} value={key}>
                {value}
              </Option>
            ))}
          </SelectField>
          <SelectField
            name="sortBy"
            orientation="horizontal"
            description={t('linesheets:choose_sort_by.hint')}
            label={t('linesheets:choose_sort_by.label')}
            placeholder={t('common:none')}
            onChange={handleSortByChange}
          >
            {data?.dictionary.sortBy.map(({ key, value }) => (
              <Option key={key} value={key}>
                {value}
              </Option>
            ))}
          </SelectField>
          <SelectField
            name="thenSortBy"
            orientation="horizontal"
            description=""
            isDisabled={shouldDisableThenSortBy}
            label={t('linesheets:then_sort_by')}
            placeholder={t('common:none')}
          >
            <Option key="none" value="none">
              {t('common:none')}
            </Option>
            {data?.dictionary.thenSortBy
              .filter(option => option.key !== sortBy)
              .map(({ key, value }) => (
                <Option key={key} value={key}>
                  {value}
                </Option>
              ))}
          </SelectField>
        </FormGrid>
      </ShowMoreBox>
    </div>
  );
};
