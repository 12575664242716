import { Id } from '@typings';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { copyToNewSelectionRequest, getIsSeller, pushEvent } from '../../../ducks';
import { CommonTrackingEvent } from '../../../utils/analytics/events';
import { useModalVisibility } from '../../../utils/hooks';
import { ModalSize } from '../../various/ModalElements';
import { ModalShell } from '../../various/ModalShell';
import { OrderSettingsForm } from '../OrderSettingsForm';

interface Props {
  orderId: Id;
  orderNumber: string;
  defaultBuyer?: string;
  onSubmit?: () => void;
}

export const CopyToNewSelectionModal = ({ orderId, defaultBuyer, orderNumber, onSubmit }: Props) => {
  const dispatch = useDispatch();
  const { isModalVisible, hideModal } = useModalVisibility('CopyToNewSelectionModal');
  const isSeller = useSelector(getIsSeller);
  const { t } = useTranslation(['common', 'selections']);

  const handleSubmit = ({ name, buyer }: { name: string; buyer?: string }) => {
    dispatch(pushEvent({ event: CommonTrackingEvent.COPY_TO_NEW_SELECTION }));
    const payload = {
      name,
      orderId,
      orderNumber,
      ...(isSeller ? { buyer } : {}),
    };
    dispatch(copyToNewSelectionRequest(payload));
    onSubmit?.();
  };

  return (
    <ModalShell title={t('selections:copy_to_new_selection')} isOpen={isModalVisible} onClose={hideModal} size={ModalSize.Medium}>
      <OrderSettingsForm
        submitTitle={t('common:continue')}
        initialData={{ buyer: defaultBuyer }}
        onClose={hideModal}
        onSubmit={handleSubmit}
      />
    </ModalShell>
  );
};
