import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Popover } from '../../../various/Popover/Popover';
import { ColorPicker } from '../../ColorPicker';

import styles from './ColorEditor.module.scss';

interface Props {
  disableAlpha?: boolean;
  value: string;
  onChange: (color: string) => void;
  onOpenEditor?: () => void;
  onCloseEditor?: () => void;
  className?: string;
}

export const ColorEditor = React.memo(({ disableAlpha = true, value, onChange, onCloseEditor, onOpenEditor, className }: Props) => {
  const { t } = useTranslation(['cms']);

  const [isPickerVisible, setIsPickerVisible] = React.useState(false);

  React.useEffect(() => {
    isPickerVisible ? onOpenEditor?.() : onCloseEditor?.();
  }, [isPickerVisible]);

  const setColorHandler = React.useCallback((hex: string) => onChange(hex), [onChange]);

  const togglePicker = React.useCallback(() => {
    setIsPickerVisible(isVisible => !isVisible);
  }, []);

  return (
    <div className={cx(styles.colorEditor, className)}>
      <Popover
        visible={isPickerVisible}
        placement="bottom-start"
        onVisibleChange={setIsPickerVisible}
        className={styles.popover}
        triggerClassName={styles.trigger}
        content={<ColorPicker color={value} onChangeComplete={setColorHandler} isVisible={isPickerVisible} disableAlpha={disableAlpha} />}
      >
        <button
          className={styles.button}
          style={{ background: value }}
          onClick={togglePicker}
          aria-label={t('cms:change_color')}
          type="button"
        />
        <div className={styles.buttonPlaceholder} />
      </Popover>
      <span style={{ width: `${value.length}ch` }} className={styles.colorValue}>
        {value}
      </span>
    </div>
  );
});
