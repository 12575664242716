import { Cms } from '@typings';
import { mapObjIndexed, pick } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { textTemplates } from '../../../cmsTemplates';
import { EMPTY_TEXT_CONTENT } from '../../../constants/cms';
import { getCmsBlockById, getCurrentBlockId, updateBlockSettings } from '../../../ducks';
import { updateLocalizedBlockSettings } from '../../../ducks/cms/localizations';
import { getIsContentBlock } from '../../../logic/pages';
import { useModalVisibility } from '../../../utils/hooks';
import { isDefined } from '../../../utils/is';
import { ModalSize } from '../../various/ModalElements';
import { ModalShell } from '../../various/ModalShell';
import { EditorLanguageContext } from '../context/EditorLanguageContext';

import styles from './AddTextTemplateModal.module.scss';
import { TextThumbnail } from './TextThumbnail';

export const getHasTextSettings = (block: Cms.AnyBlock | null): block is Cms.ContentBlock & Required<{ settings: { text: string } }> =>
  isDefined(block) && getIsContentBlock(block) && isDefined(block.settings.text);

export const AddTextTemplateModal = () => {
  const { defaultLanguage } = React.useContext(EditorLanguageContext);
  const dispatch = useDispatch();
  const blockId = useSelector(getCurrentBlockId);
  const block = useSelector(getCmsBlockById(blockId));

  const textSettings = getHasTextSettings(block) ? block.settings.text : undefined;
  const { t } = useTranslation(['cms']);
  const { isModalVisible, hideModal } = useModalVisibility('AddTextTemplateModal');
  const actionName = t(!isDefined(textSettings) ? 'cms:add_text_template' : 'cms:change_text_template');

  const handleAddTemplate = React.useCallback(
    (textTemplate: Cms.ContentBlockText) => {
      if (!isDefined(blockId)) {
        return;
      }

      const { parts, desktop, partsOrder } = textTemplate;

      const partsForBlock = mapObjIndexed(part => ({ ...part, general: { ...part.general, content: EMPTY_TEXT_CONTENT } }), parts);

      const textTemplateForLocalizations = {
        parts: mapObjIndexed(part => ({ general: pick(['content'], part.general) }), parts),
      };

      dispatch(
        updateBlockSettings({
          blockId,
          updatePath: ['text'],
          value: { desktop, parts: partsForBlock, partsOrder },
        }),
      );

      dispatch(
        updateLocalizedBlockSettings({
          blockId,
          language: defaultLanguage,
          updatePath: ['text'],
          value: textTemplateForLocalizations,
        }),
      );

      hideModal();
    },
    [blockId, defaultLanguage, dispatch, hideModal],
  );

  return (
    <ModalShell isOpen={isModalVisible} onClose={hideModal} title={actionName} size={ModalSize.XLarge}>
      <div data-testid="addTextTemplateModal" className={styles.modalContent}>
        {textTemplates().map((model, idx) => (
          <TextThumbnail key={idx} text={model} handleAddTemplate={handleAddTemplate} blockId={blockId} />
        ))}
      </div>
    </ModalShell>
  );
};
