import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SettingsDTO } from '@typings';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { addToast } from '../../../components/various/Toasts';
import { updateUserNavigation } from '../../../ducks';
import { editNavigationItems } from '../../settingsRepository';

import { SETTINGS_QUERY_KEY } from './useSettings';

export const useEditNavigationItems = () => {
  const { t } = useTranslation(['settings', 'cms']);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleSuccess = (data: SettingsDTO) => {
    addToast(t('settings:navigation_updated'));
    dispatch(updateUserNavigation());
    queryClient.setQueryData([SETTINGS_QUERY_KEY], data);
  };

  const handleError = () => {
    addToast(t('cms:update_navigation_fail'));
  };

  return useMutation({
    mutationFn: editNavigationItems,
    onError: handleError,
    onSuccess: handleSuccess,
  });
};
