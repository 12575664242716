/* eslint-disable max-len */
import { IconComponentProps } from '@typings';
const ORIGINAL_WIDTH = 8;
const ORIGINAL_HEIGHT = 12;
const ORIGINAL_COLOR = '#848484';
const PROPORTION = ORIGINAL_HEIGHT / ORIGINAL_WIDTH;

export const Reorder = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => (
  <svg width={width} height={width * PROPORTION} viewBox="0 0 8 12" fill="none">
    <path
      d="M3 10.5C3 11.325 2.325 12 1.5 12C0.675 12 0 11.325 0 10.5C0 9.675 0.675 9 1.5 9C2.325 9 3 9.675 3 10.5ZM1.5 4.5C0.675 4.5 0 5.175 0 6C0 6.825 0.675 7.5 1.5 7.5C2.325 7.5 3 6.825 3 6C3 5.175 2.325 4.5 1.5 4.5ZM1.5 0C0.675 0 0 0.675 0 1.5C0 2.325 0.675 3 1.5 3C2.325 3 3 2.325 3 1.5C3 0.675 2.325 0 1.5 0ZM6 3C6.825 3 7.5 2.325 7.5 1.5C7.5 0.675 6.825 0 6 0C5.175 0 4.5 0.675 4.5 1.5C4.5 2.325 5.175 3 6 3ZM6 4.5C5.175 4.5 4.5 5.175 4.5 6C4.5 6.825 5.175 7.5 6 7.5C6.825 7.5 7.5 6.825 7.5 6C7.5 5.175 6.825 4.5 6 4.5ZM6 9C5.175 9 4.5 9.675 4.5 10.5C4.5 11.325 5.175 12 6 12C6.825 12 7.5 11.325 7.5 10.5C7.5 9.675 6.825 9 6 9Z"
      fill={color}
    />
  </svg>
);
