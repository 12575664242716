import { useTranslation } from 'react-i18next';

import Loader from '../loaders/DefaultLoader';
import { Wrapper, WrapperSize } from '../Wrapper';

import styles from './ProcessingPayment.module.scss';

export const ProcessingPayment = () => {
  const { t } = useTranslation(['invoices']);

  return (
    <Wrapper size={WrapperSize.LARGE} className={styles.wrapper}>
      <div>
        <Loader versionSmall />
        {t('invoices:payment_being_processed')}
      </div>
    </Wrapper>
  );
};
