import { ActionHandler, Dates, Filter, Filters, Product, Sorting } from '@typings';
import { omit, without } from 'ramda';
import { update } from 'space-lift';
import { createReducer } from 'typesafe-actions';

import { isDefined } from '../../utils/is';
import { isEmpty } from '../../utils/isEmpty';
import { merge, update as updated } from '../../utils/update';
import { getFlattenedField } from '../helpers';
import {
  addAllProductsToOrderFailure,
  addAllProductsToOrderRequest,
  addAllProductsToOrderSuccess,
  addProductsToOrder,
  addProductsToOrderFailure,
  addProductsToOrderSuccess,
  copyToNewSelectionSuccess,
  createSelection,
  fetchOrderRequest,
  removeProductsFromOrder,
  removeProductsFromOrderFailure,
  removeProductsFromOrderSuccess,
  resetOrderData,
  selectWorkingOrder,
} from '../order';
import { fetchPageBySlugSuccess } from '../pages';

import * as actions from './actions';

export interface ProductsReducer {
  attributes?: Filter.Field<string>[];
  isLoadingProducts: boolean;
  isLoadingMoreProducts: boolean;
  pageCount: number;
  totalItems: number;
  products: Record<string, Product.Full>;
  productsEtaDates: Dates.DateAsSQLString[];
  productsOrder: string[];
  variantId: null;
  productVariantImages: Record<string, unknown>;
  filters: Filters;
  hasLoadedInitialFilters: boolean;
  sorting: Nullable<Sorting.FieldValue>;
  loadingProducts: Product.Id[];
  expandedFilters: {
    firstFilter: string | undefined;
    states: Record<string, boolean>;
  };
}

const initialState: ProductsReducer = {
  expandedFilters: {
    firstFilter: undefined,
    states: {},
  },
  filters: {
    buyer: '',
    categories: [],
    deliveryWindows: [],
    onlyAvailable: 'no',
    search: '',
  },
  hasLoadedInitialFilters: false,
  isLoadingMoreProducts: false,
  isLoadingProducts: false,
  loadingProducts: [],
  pageCount: 1,
  productVariantImages: {},
  products: {},
  productsEtaDates: [],
  productsOrder: [],
  sorting: null,
  totalItems: 0,
  variantId: null,
};

export { initialState as initialProductsState };

const handleLoadProductsRequest: ActionHandler<ProductsReducer, typeof actions.loadProductsRequest> = state =>
  updated(state, {
    isLoadingProducts: true,
  });

const handleLoadProductsSuccess: ActionHandler<ProductsReducer, typeof actions.loadProductsSuccess> = (state, action) => {
  const { products, productsOrder, productCount, filter } = action.payload;
  const validFilters = action.payload.filter.filter(filterItem => !isEmpty(filterItem.values)).map(filterItem => filterItem.field);
  const [firstValidFilter] = validFilters;
  const { firstFilter, states } = state.expandedFilters;

  const validCurrentFilters = Object.fromEntries(Object.entries(states).filter(([filterState]) => validFilters.includes(filterState)));
  const hasDifferentFirstFilters = firstValidFilter !== firstFilter;

  return updated(state, {
    attributes: filter.map(getFlattenedField),
    expandedFilters:
      hasDifferentFirstFilters ?
        {
          firstFilter: firstValidFilter,
          states: {
            ...validCurrentFilters,
            [firstValidFilter]: true,
          },
        }
      : state.expandedFilters,
    hasLoadedInitialFilters: true,
    isLoadingProducts: false,
    loadingProducts: [],
    pageCount: 1,
    products: { ...state.products, ...products },
    productsOrder,
    totalItems: productCount,
  });
};

const handleLoadMoreProductsSuccess: ActionHandler<ProductsReducer, typeof actions.loadMoreProductsSuccess> = (state, action) => {
  const { products, productsOrder, productCount } = action.payload;

  return updated(state, {
    isLoadingMoreProducts: false,
    isLoadingProducts: false,
    loadingProducts: [],
    pageCount: state.pageCount + 1,
    products: updated(state.products, products),
    productsOrder: merge(state.productsOrder, productsOrder),
    totalItems: productCount,
  });
};

const handleLoadMoreProductsFailure: ActionHandler<ProductsReducer, typeof actions.loadMoreProductsFailure> = state => {
  return updated(state, {
    isLoadingMoreProducts: false,
  });
};

const handleLoadProductsFailure = (state: ProductsReducer): ProductsReducer =>
  updated(state, {
    filters: initialState.filters,
    isLoadingProducts: false,
    loadingProducts: [],
    products: {},
    productsOrder: [],
    totalItems: 0,
  });

const handleSetProductsEtaDates: ActionHandler<ProductsReducer, typeof actions.setProductsEtaDates> = (state, action) => {
  return updated(state, {
    productsEtaDates: action.payload,
  });
};

const handleSetProductSortingValue: ActionHandler<ProductsReducer, typeof actions.setProductSortingValue> = (state, action) => {
  return updated(state, {
    isLoadingProducts: true,
    sorting: action.payload,
  });
};

const handleSetProductSortingDefaultValue: ActionHandler<ProductsReducer, typeof actions.setProductSortingDefaultValue> = (
  state,
  action,
) => {
  return updated(state, {
    sorting: action.payload,
  });
};

const handleUpdateFilters: ActionHandler<ProductsReducer, typeof actions.updateFilters> = (state, { payload }) => {
  const { filters, preserveCurrent } = payload;
  const updatedFilters = preserveCurrent ? { ...state.filters, ...filters } : { ...initialState.filters, ...filters };

  return update(state, {
    filters: updatedFilters as Filters,
    isLoadingProducts: true,
    pageCount: 1,
  });
};

const handleLoadMoreProductsRequest = (state: ProductsReducer): ProductsReducer =>
  updated(state, {
    isLoadingMoreProducts: true,
  });

const handleSetInitialFilters: ActionHandler<ProductsReducer, typeof actions.setInitialFilters> = (state, action) => {
  const { payload } = action;
  const filters = updated(initialState.filters, payload);

  return updated(state, {
    filters,
    hasLoadedInitialFilters: false,
  });
};

const handleProductLoadingFailed: ActionHandler<
  ProductsReducer,
  typeof addProductsToOrderFailure | typeof removeProductsFromOrderFailure
> = (state, action) => {
  const { products } = action.payload;

  return updated(state, {
    loadingProducts: without(products, state.loadingProducts),
  });
};

const handleSetEtaFilter: ActionHandler<ProductsReducer, typeof actions.setEtaFilter> = (state, action) => {
  const { etaFrom, etaTo } = action.payload;

  return updated(state, {
    filters:
      isDefined(etaFrom) && isDefined(etaTo) ?
        updated(state.filters, { etaFrom, etaTo })
      : (omit(['etaFrom', 'etaTo'], state.filters) as Filters),
  });
};

const handleResetFilters = (state: ProductsReducer): ProductsReducer => {
  const { stockType } = state.filters;

  return updated(state, {
    filters: {
      ...initialState.filters,
      ...(isDefined(stockType) ? { stockType } : {}),
    },
    isLoadingProducts: true,
    pageCount: 1,
  });
};

const handleAddProductsToOrder: ActionHandler<ProductsReducer, typeof addProductsToOrder> = (state, action) => {
  const { products } = action.payload;

  return updated(state, {
    loadingProducts: [...state.loadingProducts, ...products],
  });
};

const handleAddProductsToOrderSuccess: ActionHandler<ProductsReducer, typeof addProductsToOrderSuccess> = (state, action) => {
  const { variantsAdded, variantsNotAdded } = action.payload;

  const modifiedProducts =
    variantsAdded.length > 0 ? variantsAdded.map(added => added.product) : variantsNotAdded.map(notAdded => notAdded.product);

  return updated(state, {
    loadingProducts: without(modifiedProducts, state.loadingProducts),
  });
};

const handleAddAllProductsToOrderRequest: ActionHandler<ProductsReducer, typeof addAllProductsToOrderRequest> = (state, action) => {
  const { products } = action.payload;

  return updated(state, {
    loadingProducts: [...state.loadingProducts, ...products],
  });
};

const handleAddAllProductsToOrderSuccess: ActionHandler<ProductsReducer, typeof addAllProductsToOrderSuccess> = state => {
  return updated(state, { loadingProducts: [] });
};

const handleAddAllProductsToOrderFailure: ActionHandler<ProductsReducer, typeof addAllProductsToOrderFailure> = state => {
  return updated(state, { loadingProducts: [] });
};

const handleRemoveProductsFromOrder: ActionHandler<ProductsReducer, typeof removeProductsFromOrder> = (state, action) => {
  const { products } = action.payload;

  return updated(state, {
    loadingProducts: [...state.loadingProducts, ...products],
  });
};

const handleRemoveProductsFromOrderSuccess: ActionHandler<ProductsReducer, typeof removeProductsFromOrderSuccess> = (state, action) => {
  const { variantsRemoved } = action.payload;
  const productIds = variantsRemoved.map(({ product }) => product);

  return updated(state, {
    loadingProducts: without(productIds, state.loadingProducts),
  });
};

const handleResetProducts = (state: ProductsReducer): ProductsReducer =>
  update(state, {
    products: {},
    productsOrder: [],
  });

const handlePageFetchSuccess: ActionHandler<ProductsReducer, typeof fetchPageBySlugSuccess> = (state, action) =>
  update(state, {
    products: { ...state.products, ...action.payload.products },
  });

const handleFetchOrderRequest: ActionHandler<ProductsReducer, typeof fetchOrderRequest> = state =>
  update(state, {
    hasLoadedInitialFilters: false,
    isLoadingProducts: true,
  });

const handleSetExpandedFilters: ActionHandler<ProductsReducer, typeof actions.setExpandedProductFilters> = (state, action) => {
  if (isEmpty(action.payload)) {
    return update(state, {
      expandedFilters: initialState.expandedFilters,
    });
  }

  return update(state, {
    expandedFilters: update(state.expandedFilters, {
      states: action.payload,
    }),
  });
};

export default createReducer<ProductsReducer, AppAction>(initialState)
  .handleAction(actions.loadProductsRequest, handleLoadProductsRequest)
  .handleAction(actions.loadProductsSuccess, handleLoadProductsSuccess)
  .handleAction(actions.loadProductsFailure, handleLoadProductsFailure)
  .handleAction(actions.loadMoreProductsRequest, handleLoadMoreProductsRequest)
  .handleAction(actions.loadMoreProductsSuccess, handleLoadMoreProductsSuccess)
  .handleAction(actions.loadMoreProductsFailure, handleLoadMoreProductsFailure)
  .handleAction(actions.setEtaFilter, handleSetEtaFilter)
  .handleAction(actions.resetFilters, handleResetFilters)
  .handleAction(actions.setInitialFilters, handleSetInitialFilters)
  .handleAction(actions.setProductsEtaDates, handleSetProductsEtaDates)
  .handleAction(actions.updateFilters, handleUpdateFilters)
  .handleAction(actions.setExpandedProductFilters, handleSetExpandedFilters)
  .handleAction(actions.setProductSortingValue, handleSetProductSortingValue)
  .handleAction(actions.setProductSortingDefaultValue, handleSetProductSortingDefaultValue)
  .handleAction(addProductsToOrder, handleAddProductsToOrder)
  .handleAction(addProductsToOrderSuccess, handleAddProductsToOrderSuccess)
  .handleAction(addProductsToOrderFailure, handleProductLoadingFailed)
  .handleAction(addAllProductsToOrderRequest, handleAddAllProductsToOrderRequest)
  .handleAction(addAllProductsToOrderSuccess, handleAddAllProductsToOrderSuccess)
  .handleAction(addAllProductsToOrderFailure, handleAddAllProductsToOrderFailure)
  .handleAction(removeProductsFromOrder, handleRemoveProductsFromOrder)
  .handleAction(removeProductsFromOrderSuccess, handleRemoveProductsFromOrderSuccess)
  .handleAction(removeProductsFromOrderFailure, handleProductLoadingFailed)
  .handleAction(selectWorkingOrder, handleResetProducts)
  .handleAction(createSelection, handleResetProducts)
  .handleAction(copyToNewSelectionSuccess, handleResetProducts)
  .handleAction(resetOrderData, handleResetProducts)
  .handleAction(fetchPageBySlugSuccess, handlePageFetchSuccess)
  .handleAction(fetchOrderRequest, handleFetchOrderRequest);
