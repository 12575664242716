import { clamp } from 'ramda';
import React from 'react';

import { useClickOutside } from '../../../../utils/hooks';
import { isDefined } from '../../../../utils/is';
import { isEmpty } from '../../../../utils/isEmpty';
import { Input } from '../../../various/Fields/Input';
import { Slider } from '../../../various/Slider';

import styles from './OpacitySlider.module.scss';

interface Props {
  opacity: number;
  onChange: (value: number) => void;
  onOpenSlider?: () => void;
  onCloseSlider?: () => void;
}

const getValidOpacity = (value: string): number => {
  const parsedValue = parseInt(value, 10);

  if (isNaN(parsedValue)) {
    return 0;
  }

  return clamp(0, OPACITY_FACTOR, parsedValue);
};

const getDisplayValue = (value: string): string => {
  if (isEmpty(value)) {
    return value;
  }

  return getValidOpacity(value).toString();
};

const OPACITY_FACTOR = 100;

export const OpacitySlider = ({ opacity, onChange, onOpenSlider, onCloseSlider }: Props) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [sliderValue, setSliderValue] = React.useState(opacity * OPACITY_FACTOR);
  const [displayValue, setDisplayValue] = React.useState<string | number>(opacity * OPACITY_FACTOR);
  const [isSliderVisible, setIsSliderVisible] = React.useState(false);

  React.useEffect(() => {
    const value = Math.round(opacity * OPACITY_FACTOR);
    setSliderValue(value);
  }, [opacity]);

  const onSliderChange = React.useCallback(
    (value: number) => {
      setSliderValue(value);
      setDisplayValue(value.toString());
      onChange(value / OPACITY_FACTOR);
    },
    [onChange],
  );

  const showSlider = React.useCallback(() => {
    setIsSliderVisible(true);
    if (isDefined(onOpenSlider)) {
      onOpenSlider();
    }
  }, [onOpenSlider]);

  const closeSlider = React.useCallback(() => {
    setIsSliderVisible(false);
    if (isDefined(onCloseSlider)) {
      onCloseSlider();
    }
  }, [onCloseSlider]);

  useClickOutside({ element: ref.current, enabled: isSliderVisible, handler: closeSlider });

  const handleBlur = () => {
    setDisplayValue(sliderValue);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = getValidOpacity(event.target.value);

    setSliderValue(getValidOpacity(event.target.value));
    setDisplayValue(getDisplayValue(event.target.value));
    onChange(value / OPACITY_FACTOR);
  };

  return (
    <div className={styles.opacitySlider} ref={ref}>
      <label>
        <Input
          size="small"
          type="number"
          postfix="%"
          min={0}
          max={100}
          className={styles.input}
          placeholder={sliderValue.toString()}
          aria-label="Opacity"
          value={displayValue}
          onClick={showSlider}
          onBlur={handleBlur}
          onChange={handleInputChange}
        />
      </label>
      {isSliderVisible && <Slider className={styles.slider} onChange={onSliderChange} value={sliderValue} />}
    </div>
  );
};
