import { Id } from '@typings';
import { createAction } from 'typesafe-actions';

export const fetchHomepageRequest = createAction('cms/FETCH_HOMEPAGE_REQUEST')();
export const fetchHomepageSuccess = createAction('cms/FETCH_HOMEPAGE_SUCCESS')<Nullable<string>>();
export const fetchHomepageFailure = createAction('cms/FETCH_HOMEPAGE_FAILURE')();

export const setHomePageRequest = createAction('cms/SET_HOMEPAGE_REQUEST')<Nullable<string>>();
export const setHomePageSuccess = createAction('cms/SET_HOMEPAGE_SUCCESS')();
export const setHomePageFailure = createAction('cms/SET_HOMEPAGE_FAILURE')();

export const fetchHomePageByBuyerRequest = createAction('cms/FETCH_HOMEPAGE_BY_BUYER_REQUEST')<Id>();

export const fetchHomePageByBuyerSuccess = createAction('cms/FETCH_HOMEPAGE_BY_BUYER_SUCCESS')<{
  slug: Nullable<string>;
  buyerId: Id;
}>();

export const fetchHomePageByBuyerFailure = createAction('cms/FETCH_HOMEPAGE_BY_BUYER_FAILURE')();

export const purgeHomepageByBuyer = createAction('cms/PURGE_HOMEPAGE_BY_BUYER')();
