import { Overview } from '@typings';
import { equals, groupBy, prop, uniqWith } from 'ramda';

import { isDefined } from '../../utils/is';
import { isEmpty } from '../../utils/isEmpty';

import { isVariantRow } from './selectionOverview';

const getListingRowTotals = (relatedVariants: Overview.Variant[]) =>
  relatedVariants.reduce(
    ({ totalPrice, totalUnits }, item) => ({
      totalPrice: totalPrice + item.totalPrice,
      totalUnits: totalUnits + item.totalUnits,
    }),
    { totalPrice: 0, totalUnits: 0 },
  );

const getProductCount = (variants: Overview.Variant[]) => Object.keys(groupBy(prop('productId'), variants)).length;

const sortTreeByUnits = (tree: Overview.Row[]): Overview.Row[] => {
  return [...tree]
    .sort((a, b) => b.totalUnits - a.totalUnits)
    .map(row => (isVariantRow(row) ? row : { ...row, children: sortTreeByUnits(row.children) }));
};

export const generateAllRowLevels = (primaryDimensionValues: Overview.DimensionValues[]) => {
  const allLevels = primaryDimensionValues.flatMap(dimensionValue => {
    if (Array.isArray(dimensionValue.key)) {
      return dimensionValue.key.map((_, idx, array) => {
        const key = array.slice(0, idx + 1);

        return {
          key,
          name: dimensionValue.name[key.length - 1] ?? '',
        };
      });
    }

    return dimensionValue;
  });

  return uniqWith<Overview.DimensionValues, Overview.DimensionValues>(equals, allLevels);
};

const groupVariants = (dimensionUri: keyof Overview.Variant, variants: Overview.Variant[]) => {
  const getGroupName = (variant: Overview.Variant) => variant[dimensionUri]?.toString() ?? '';

  return groupBy(getGroupName, variants);
};

export const getListingRowIds = (dimension: string | string[]) => {
  if (Array.isArray(dimension)) {
    const { length } = dimension;

    return {
      parentRowId: dimension[length - 2],
      rowId: dimension[length - 1] ?? '',
    };
  }

  return {
    rowId: dimension,
  };
};

const createVariantRow = (dimensionVariants: Overview.Variant[]): Overview.VariantRow[] => {
  return dimensionVariants.map(({ variant, totalPrice, totalUnits }) => ({
    isVariantRow: true,
    key: variant,
    totalPrice,
    totalUnits,
  }));
};

const createListingRows = (
  primaryDimensionValues: Overview.DimensionValues[],
  groupedVariants: Record<string, Overview.Variant[]>,
  secondaryDimensionName?: string,
): Overview.ListingRow[] => {
  return primaryDimensionValues.map(dimensionValue => {
    const { key, name } = dimensionValue;

    const dimensionVariants = groupedVariants[key.toString()] ?? [];
    const variantRows = createVariantRow(dimensionVariants);
    const rowIds = getListingRowIds(key);
    const isTopLevelRow = !isDefined(rowIds.parentRowId);

    return {
      children: variantRows,
      isVariantRow: false,
      key: `${key.toString()}${secondaryDimensionName}`,
      primaryDimension: name.toString(),
      productsCount: getProductCount(dimensionVariants),
      rowIdPath: key,
      secondaryDimension: isTopLevelRow ? secondaryDimensionName : undefined,
      variantsCount: variantRows.length,
      ...rowIds,
      ...getListingRowTotals(dimensionVariants),
    };
  });
};

const getNestedRowsTotals = (rows: Overview.ListingRow[], rowId: string) => {
  const childRows = rows.filter(subRow => {
    return Array.isArray(subRow.rowIdPath) ? subRow.rowIdPath.includes(rowId) : subRow.rowIdPath === rowId;
  });

  return childRows.reduce(
    (acc, cur) => {
      return {
        productsCount: acc.productsCount + cur.productsCount,
        totalPrice: acc.totalPrice + cur.totalPrice,
        totalUnits: acc.totalUnits + cur.totalUnits,
        variantsCount: acc.variantsCount + cur.variantsCount,
      };
    },
    {
      productsCount: 0,
      totalPrice: 0,
      totalUnits: 0,
      variantsCount: 0,
    },
  );
};

const nestRows = (rows: Overview.ListingRow[], rowId?: string): Overview.Row[] => {
  return rows
    .filter(row => row.parentRowId === rowId)
    .map(row => ({
      ...row,
      ...getNestedRowsTotals(rows, row.rowId),
      children: [...row.children, ...nestRows(rows, row.rowId)],
    }));
};

const cleanValue = (value: Unrestricted | Unrestricted[]): string | string[] => {
  if (!isDefined(value) || isEmpty(value)) {
    return '';
  }

  if (Array.isArray(value)) {
    return value.map(item => item.toString());
  }

  return value.toString();
};

export const getDimensionValues = (variants: Overview.Variant[], dimension: Overview.Dimension | undefined) => {
  if (!isDefined(dimension)) {
    return [];
  }

  const dimensionValues = variants.map<Overview.DimensionValues>(variant => ({
    key: cleanValue(variant[dimension.uri]),
    name: cleanValue(variant[dimension.name]),
  }));

  return uniqWith<Overview.DimensionValues, Overview.DimensionValues>(equals, dimensionValues);
};

export const buildTree = ({
  variants,
  primaryDimension,
  secondaryDimension,
}: {
  variants: Overview.Variant[];
  primaryDimension: Overview.Dimension;
  secondaryDimension?: Overview.Dimension;
}): Overview.Row[] => {
  const variantsBySecondary = isDefined(secondaryDimension) ? groupVariants(secondaryDimension.uri, variants) : { default: variants };

  const listingRowsBySecondary = Object.values(variantsBySecondary).map(secondaryVariants => {
    const primaryDimensionValues = getDimensionValues(secondaryVariants, primaryDimension);

    if (isEmpty(primaryDimensionValues)) {
      return [];
    }

    const allLevels = generateAllRowLevels(primaryDimensionValues);
    const groupedVariants = groupVariants(primaryDimension.uri, secondaryVariants);
    const [firstVariantFromSecondary] = secondaryVariants;

    const secondaryDimensionName =
      isDefined(firstVariantFromSecondary) && isDefined(secondaryDimension) ?
        firstVariantFromSecondary[secondaryDimension.name]?.toString() ?? ''
      : undefined;

    return createListingRows(allLevels, groupedVariants, secondaryDimensionName);
  });

  const tree = listingRowsBySecondary.flatMap(listingRows => nestRows(listingRows));

  return sortTreeByUnits(tree);
};
