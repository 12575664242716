import { OnChangeFn, PaginationState } from '@tanstack/react-table';
import React from 'react';

import { DEFAULT_ITEMS_COUNT_PER_REQUEST } from '../../../constants/limits';
import { getUpdatedStateValue } from '../../table';

interface Props {
  page: number;
  onPageChange: (newPage: number) => void;
}

export const usePaginationHandler = ({ page, onPageChange }: Props) => {
  const pagination = React.useMemo(
    () => ({
      pageIndex: page,
      pageSize: DEFAULT_ITEMS_COUNT_PER_REQUEST,
    }),
    [page],
  );

  const handlePaginationChange: OnChangeFn<PaginationState> = updaterOrValue => {
    const newPagination = getUpdatedStateValue(updaterOrValue, pagination);

    onPageChange(newPagination.pageIndex);
  };

  return {
    handlePaginationChange,
    pagination,
  };
};
