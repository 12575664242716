import React from 'react';

import { Preset } from '../../../../../typings';
import { useMatrixDeliveryWindowContext, useMatrixSettingsContext } from '../../../components/products/ProductMatrix/context';
import { getUniqPrepacks } from '../../../logic/presets';
import { isEmpty } from '../../isEmpty';

import { useDistributionsMap } from './useDistributionsMap';
import { usePrepacksMap } from './usePrepacksMap';

export const useMatrixPresets = () => {
  const { isDeliveryWindowExpired } = useMatrixDeliveryWindowContext();
  const { isReadOnly, isLookbook } = useMatrixSettingsContext();

  const prepacksMap = usePrepacksMap();
  const distributionsMap = useDistributionsMap();

  const uniquePrepacks = getUniqPrepacks(prepacksMap);
  const areAllPrepacksEnforced = Object.values(prepacksMap).every(({ isEnforced }) => isEnforced);

  const hasPrepacks = !isEmpty(uniquePrepacks);
  const hasDistributions = React.useMemo(() => {
    return Object.values(distributionsMap).some(distributions => !isEmpty(distributions));
  }, [distributionsMap]);

  const shouldHidePresets = isReadOnly || isLookbook || isDeliveryWindowExpired || areAllPrepacksEnforced;
  const shouldHideDistribution = shouldHidePresets || !hasDistributions;
  const shouldHidePrepackSwitch = shouldHidePresets || !hasPrepacks;

  const productDistributions = Object.values(distributionsMap).reduce<Record<string, Preset.Item[]>>((acc, presets) => {
    return {
      ...acc,
      ...presets.reduce((presetsAcc, preset) => {
        return {
          ...presetsAcc,
          [preset.id]: preset.items,
        };
      }, {}),
    };
  }, {});

  return {
    distributionsMap,
    hasPresetsControls: (hasPrepacks && !shouldHidePrepackSwitch) || !shouldHideDistribution,
    prepacksMap,
    productDistributions,
    shouldHideDistribution,
    shouldHidePrepackSwitch,
    uniquePrepacks,
  };
};
