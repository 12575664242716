import React from 'react';

import { MissingCountryStateAlert, UnpaidInvoicesAlert } from '../GeneralAlertMessages';

import styles from './BottomWrapper.module.scss';

export const BottomWrapper = ({ children }: React.WithChildren) => {
  return (
    <div className={styles.bottomWrapper}>
      <div className={styles.alerts}>
        <UnpaidInvoicesAlert />
        <MissingCountryStateAlert />
      </div>
      {children}
    </div>
  );
};
