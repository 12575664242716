import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHover, useLayer } from 'react-laag';

import Icon, { IconType } from '../../various/Icon';
import { Label } from '../../various/PopoverMenu/MenuElements/Label';
import { MenuButton } from '../../various/PopoverMenu/MenuElements/MenuButton';
import popoverMenuStyles from '../../various/PopoverMenu/PopoverMenu.module.scss';
import { ContentBlockContext } from '../context/ContentBlockContext';

import styles from './AddContentPart.module.scss';

interface Props {
  isVisible?: boolean;
  className?: string;
}

const HOVER_DELAY = 100;

export const AddContentPart = ({ isVisible = false, className }: Props) => {
  const { t } = useTranslation(['cms']);
  const { addButtonPart, addTextPart, setIsAddPartPopupVisible } = React.useContext(ContentBlockContext);
  const [isOver, hoverProps] = useHover({
    delayEnter: HOVER_DELAY,
    delayLeave: HOVER_DELAY,
    hideOnScroll: false,
  });
  const { renderLayer, layerProps, triggerProps } = useLayer({
    auto: true,
    isOpen: isOver,
    placement: 'bottom-start',
    triggerOffset: 2,
  });

  React.useEffect(() => setIsAddPartPopupVisible(isOver), [isOver, setIsAddPartPopupVisible]);

  return (
    <>
      <div
        className={cx(styles.addContentPart, className, {
          [styles.visible]: isVisible,
        })}
        {...triggerProps}
        {...hoverProps}
      >
        <Icon type={IconType.AddFilled} className={styles.button} />
      </div>
      {renderLayer(
        <>
          {isOver && (
            <ul {...layerProps} {...hoverProps} className={styles.popup}>
              <li className={popoverMenuStyles.menuItem}>
                <MenuButton onClick={addTextPart} icon={IconType.Text}>
                  <Label>{t('cms:text_part')}</Label>
                </MenuButton>
              </li>
              <li className={popoverMenuStyles.menuItem}>
                <MenuButton onClick={addButtonPart} icon={IconType.Button}>
                  <Label>{t('cms:button')}</Label>
                </MenuButton>
              </li>
            </ul>
          )}
        </>,
      )}
    </>
  );
};
