/* eslint-disable max-len */

import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;
const ORIGINAL_HEIGHT = 20;
const ORIGINAL_COLOR = '#848484';
const PROPORTION = ORIGINAL_HEIGHT / ORIGINAL_WIDTH;

export const Upload = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => (
  <svg width={width} height={width * PROPORTION} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.4444 14.6667L15.4444 10.7778C15.4444 10.35 15.7944 10 16.2222 10C16.65 10 17 10.35 17 10.7778L17 15.4444C17 16.3 16.3 17 15.4444 17L4.55556 17C3.7 17 3 16.3 3 15.4444L3 10.7778C3 10.35 3.35 10 3.77778 10C4.20556 10 4.55556 10.35 4.55556 10.7778L4.55556 14.6667C4.55556 15.0944 4.90556 15.4444 5.33333 15.4444L14.6667 15.4444C15.0944 15.4444 15.4444 15.0944 15.4444 14.6667ZM7.87081 8.04548L9.30515 6.60845L9.30515 13.2356C9.30515 13.656 9.64847 14 10.0681 14C10.4877 14 10.831 13.656 10.831 13.2356L10.831 6.60845L12.273 8.04548C12.5705 8.34359 13.0512 8.34359 13.3487 8.04548C13.4916 7.90267 13.5719 7.70878 13.5719 7.50659C13.5719 7.3044 13.4916 7.11052 13.3488 6.96771L10.6098 4.22358C10.3122 3.92547 9.83158 3.92547 9.53403 4.22358L6.79506 6.9677C6.49751 7.26581 6.49751 7.74737 6.79506 8.04548C7.09261 8.34359 7.57326 8.34359 7.87081 8.04548Z"
      fill={color}
    />
  </svg>
);
