/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;
const ORIGINAL_COLOR = '#848484';

export const Unpublish = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => (
  <svg width={width} height={width} viewBox="0 0 20 20" fill="none">
    <path
      d="M10 5C6.69152 5 3.84005 6.95033 2.53017 9.76479C2.48994 9.85008 2.48994 9.94985 2.53017 10.0367C3.84005 12.8512 6.69154 14.8015 10 14.8015C13.3085 14.8015 16.16 12.8512 17.4698 10.0367C17.5101 9.95146 17.5101 9.85169 17.4698 9.76479C16.16 6.95033 13.3085 5 10 5ZM10 13.2825C8.13174 13.2825 6.61911 11.7682 6.61911 9.90157C6.61911 8.03331 8.13335 6.52068 10 6.52068C11.8683 6.52068 13.3809 8.03492 13.3809 9.90157C13.3809 11.7682 11.8667 13.2825 10 13.2825Z"
      fill={color}
    />
    <path
      d="M10.0003 12.0641C11.1956 12.0641 12.1646 11.0951 12.1646 9.89971C12.1646 8.70437 11.1956 7.73535 10.0003 7.73535C8.80495 7.73535 7.83594 8.70437 7.83594 9.89971C7.83594 11.0951 8.80495 12.0641 10.0003 12.0641Z"
      fill={color}
    />
    <path d="M2.5 3.5L16.5 17.5" stroke="#848484" strokeWidth="1.5" />
    <path d="M3.5 2.5L17.5 16.5" stroke="white" strokeWidth="1.5" />
  </svg>
);
