import { Cms, Id } from '@typings';

import { getIsProductBlock } from '../../../logic/pages';
import { isDefined } from '../../../utils/is';
import { getCmsBlockById } from '../blocks';

export const getToolbarVisibility = (store: Store) => store.cms.ui.toolbarVisibility;
export const getCurrentItem = (store: Store) => store.cms.ui.currentItem;
export const getCurrentSectionId = (store: Store) => store.cms.ui.currentSectionId;
export const getCurrentBlockId = (store: Store) => store.cms.ui.currentBlockId;
export const getCurrentGroupId = (store: Store) => store.cms.ui.currentGroupId;
export const getIsTextStyleEditorVisible = (store: Store) => store.cms.ui.textStyleEditor.isVisible;
export const getTextStyleElementStyles = (store: Store) => store.cms.ui.textStyleEditor.elementStyles;
export const getTextStyleEditorMode = (store: Store) => store.cms.ui.textStyleEditor.mode;
export const getIsButtonStyleEditorVisible = (store: Store) => store.cms.ui.buttonStyleEditor.isVisible;
export const getButtonStyleElementStyles = (store: Store) => store.cms.ui.buttonStyleEditor.elementStyles;
export const getButtonStyleEditorMode = (store: Store) => store.cms.ui.buttonStyleEditor.mode;
export const getManualLayoutEditorModels = (store: Store) => store.cms.ui.manualLayoutEditor.models;
export const getManualLayoutEditorBlockTypeToSelect = (store: Store) => store.cms.ui.manualLayoutEditor.blockTypeToSelect;
export const getIsHoverTabOpen = (store: Store) => store.cms.ui.isHoverTabOpen;

export const getIsToolbarVisible = (toolbarName: Cms.ToolbarType) => (store: Store) => !!getToolbarVisibility(store)[toolbarName];

export const getIsCurrentGroup = (groupId: Maybe<Id>) => (store: Store) => isDefined(groupId) && getCurrentGroupId(store) === groupId;

export const getIsCurrentBlock = (blockId: Nullable<Id>) => (store: Store) => isDefined(blockId) && getCurrentBlockId(store) === blockId;

export const getCurrentItemInBlock = (blockId: Nullable<Id>) => (store: Store) =>
  getIsCurrentBlock(blockId)(store) ? store.cms.ui.currentItem : null;

export const getIsCurrentItem = (blockId: Nullable<Id>, itemIndex: number) => (store: Store) =>
  getIsCurrentBlock(blockId)(store) && getCurrentItem(store).index === itemIndex;

export const getIsCurrentBlockSelected = (blockId: Nullable<Id>) => (store: Store) =>
  getIsCurrentBlock(blockId)(store) && isDefined(store.cms.ui.currentItem.index);

export const getIsToolbarVisibleInBlock = (blockId: Nullable<Id>, toolbarName: Cms.ToolbarType) => (store: Store) =>
  getIsCurrentBlock(blockId)(store) && !!getToolbarVisibility(store)[toolbarName];

export const getIsRepositioningImageInBlock = (blockId: Nullable<Id>) => (store: Store) =>
  getIsCurrentBlock(blockId)(store) && store.cms.ui.isRepositioningImage;

export const getIsHotspotEditingInBlock = (blockId: Nullable<Id>) => (store: Store) =>
  getIsCurrentBlock(blockId)(store) && store.cms.ui.isHotspotEditing;

export const getIsTextStyleEditorVisibleInBlock = (blockId: Nullable<Id>) => (store: Store) =>
  getIsCurrentBlock(blockId)(store) && store.cms.ui.textStyleEditor.isVisible;

export const getIsButtonStyleEditorVisibleInBlock = (blockId: Nullable<Id>) => (store: Store) =>
  getIsCurrentBlock(blockId)(store) && store.cms.ui.buttonStyleEditor.isVisible;

export const getIsCustomStyleEditorVisibleInBlock = (blockId: Nullable<Id>) => (store: Store) =>
  getIsCurrentBlock(blockId)(store) && (store.cms.ui.textStyleEditor.isVisible || store.cms.ui.buttonStyleEditor.isVisible);

export const getIsManualLayoutEditorEnabledInGroup = (groupId: Maybe<Id>) => (store: Store) =>
  getIsCurrentGroup(groupId)(store) && store.cms.ui.manualLayoutEditor.isEnabled;

export const getTextStyleElementStylesInBlock = (blockId: Nullable<Id>) => (store: Store) =>
  getIsCurrentBlock(blockId)(store) ? store.cms.ui.textStyleEditor.elementStyles : null;

export const getButtonStyleElementStylesInBlock = (blockId: Nullable<Id>) => (store: Store) =>
  getIsCurrentBlock(blockId)(store) ? store.cms.ui.buttonStyleEditor.elementStyles : null;

export const getProductsCountInBlock = (store: Store) => {
  const currentBlock = getCurrentBlockId(store);
  const blockParameters = getCmsBlockById(currentBlock)(store);

  if (!isDefined(blockParameters) || !isDefined(blockParameters.position) || !getIsProductBlock(blockParameters)) {
    return 0;
  }

  return blockParameters.position.desktop.spanX * blockParameters.position.desktop.spanY;
};

export const getCurrentCustomTemplateId = (store: Store) => store.cms.ui.currentCustomTemplateId;
