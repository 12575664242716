import React from 'react';
import { useTranslation } from 'react-i18next';

import { BREAKPOINTS } from '../../../constants/breakpoints';
import { useScreenWidth } from '../../../utils/hooks';
import { IconType } from '../../various/Icon';
import { IconButton } from '../../various/IconButton/IconButton';
import { Popover } from '../../various/Popover';

import styles from './WhatsNew.module.scss';
import { WhatsNewContent } from './WhatsNewContent';

export const WhatsNew = () => {
  const { t } = useTranslation(['common']);
  const screenWidth = useScreenWidth();
  const isOnMobile = screenWidth < BREAKPOINTS.MEDIUM;
  const [isPopoverVisible, setIsPopoverVisible] = React.useState<boolean>(false);

  const togglePopover = React.useCallback(() => {
    setIsPopoverVisible(visible => !visible);
  }, []);

  const openButton = (
    <IconButton
      icon={IconType.Gift}
      name={t('common:whats_new')}
      variant="secondary"
      showTooltip
      highlighted={isPopoverVisible}
      tooltipPlacement="bottom-center"
      onClick={togglePopover}
    />
  );

  if (isOnMobile) {
    return (
      <div className={styles.whatsNewWrapper}>
        {openButton}
        {isPopoverVisible && (
          <div className={styles.mobileWrapper}>
            <div className={styles.mobileTopBar}>
              <h1 className={styles.mobileTitle}>{t('common:whats_new')}</h1>
              <IconButton icon={IconType.Close} name="close" variant="secondary" onClick={togglePopover} className={styles.mobileIcon} />
            </div>
            <WhatsNewContent />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={styles.whatsNewWrapper}>
      <Popover
        visible={isPopoverVisible}
        onVisibleChange={setIsPopoverVisible}
        placement="top-end"
        content={<WhatsNewContent />}
        className={styles.popover}
      >
        {openButton}
      </Popover>
    </div>
  );
};
