import React from 'react';

import { itemsToChart } from '../../../../ducks/helpers';
import { getPrepackIdsFromMap } from '../../../../logic/presets';
import { isDefined } from '../../../../utils/is';
import { useMatrixPrepackContext, useMatrixVariantsContext } from '../../../products/ProductMatrix/context';
import MeasurementChart from '../../Chart';
import { Help } from '../../Help';
import { RegularHeaderCell } from '../MatrixCells';

import styles from './MatrixHead.module.scss';

export const PrepacksHeadCells = React.memo(() => {
  const { prepacksMap, uniquePrepacks, shouldHidePrepackSwitchColumn, visiblePrepacksLength } = useMatrixPrepackContext();
  const { variants } = useMatrixVariantsContext();

  if (visiblePrepacksLength === 0) {
    return null;
  }

  const prepackHeaders = uniquePrepacks.flatMap(prepack => {
    const variantWithPrepack = variants.find(({ variant }) => getPrepackIdsFromMap(prepacksMap, variant).includes(prepack.id));
    const prepackDataSource = variantWithPrepack ?? variants[0];

    if (!isDefined(prepackDataSource)) {
      return [];
    }

    const { itemTable } = prepackDataSource;

    return [
      {
        itemTable,
        prepack,
      },
    ];
  });

  return (
    <>
      {!shouldHidePrepackSwitchColumn && <RegularHeaderCell className={styles.headPrepackCell} />}
      {prepackHeaders.map(({ itemTable, prepack }) => {
        return (
          <RegularHeaderCell className={styles.headPrepackCell} key={prepack.id}>
            {prepack.name}
            <Help placement="top-end">
              <div>{prepack.name}</div>
              <MeasurementChart table={itemsToChart(prepack.items, itemTable)} />
            </Help>
          </RegularHeaderCell>
        );
      })}
    </>
  );
});
