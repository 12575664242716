import cx from 'classnames';
import React from 'react';

import styles from './MatrixCells.module.scss';

interface Props {
  rightAligned?: boolean;
  className?: string;
  isSelected?: boolean;
  isFocusTarget?: boolean;
  dataTestId?: string;
  style?: React.CSSProperties;
}

export const RegularSummaryCell = React.forwardRef<HTMLDivElement, React.WithOptionalChildren<Props>>((props, ref) => {
  const { children, rightAligned, className, isSelected, isFocusTarget, dataTestId, style } = props;
  const classNames = cx(
    styles.regularSummaryCell,
    {
      [styles.rightAligned]: rightAligned,
      [styles.selected]: isSelected,
    },
    className,
  );

  return (
    <th data-testid={dataTestId} className={classNames} style={style}>
      {/* tabIndex is neccessary to allow proper keyboard navigation */}
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
      <div ref={ref} tabIndex={0} data-focus-target={isFocusTarget}>
        {children}
      </div>
    </th>
  );
});
