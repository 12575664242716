import i18next from 'i18next';
import { combineEpics } from 'redux-observable';
import { filter, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { addToast } from '../../components/various/Toasts';
import { fetchCmsNavigationFailure, fetchCmsNavigationRequest, fetchCmsNavigationSuccess } from '../../ducks';
import { mapResponse } from '../../utils/operators/mapResponse';

const fetchNavigation: AppEpic = (action$, _, { navigationRepository }) =>
  action$.pipe(
    filter(isActionOf(fetchCmsNavigationRequest)),
    mergeMap(async () => navigationRepository.fetchCmsNavigation()),
    mapResponse(
      res => fetchCmsNavigationSuccess(res.data),
      () => {
        addToast(i18next.t('cms:fetch_navigation_fail'));

        return fetchCmsNavigationFailure();
      },
    ),
  );

export const navigationEpic = combineEpics(fetchNavigation);
