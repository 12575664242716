import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Cms, Id } from '@typings';
import { useTranslation } from 'react-i18next';

import { addToast } from '../../../components/various/Toasts';
import { duplicatePage } from '../../pagesRepository';

import { PAGES_QUERY_KEY } from './usePages';

interface MutationVariables {
  pageId: Id;
}

export const useDuplicatePage = () => {
  const { t } = useTranslation(['cms']);
  const queryClient = useQueryClient();

  const mutationFn = async ({ pageId }: MutationVariables) => {
    return duplicatePage(pageId);
  };

  const handleSuccess = (page: Cms.Page) => {
    addToast(t('cms:page_created', { pageName: page.name }));
    queryClient.setQueriesData<Cms.Page[]>({ queryKey: [PAGES_QUERY_KEY] }, (pages = []) => [page, ...pages]);
    queryClient.invalidateQueries({ queryKey: [PAGES_QUERY_KEY] });
  };

  const handleError = () => {
    addToast(t('cms:duplicate_page_fail'));
  };

  return useMutation({
    mutationFn,
    onError: handleError,
    onSuccess: handleSuccess,
  });
};
