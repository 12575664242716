import { Id } from '@typings';
import { HttpStatusCode } from 'axios';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { getIsLoggedIn } from '../../ducks';
import { resetLookbookFilters, setLookbookAttributes } from '../../ducks/lookbook';
import { getOrderDetails } from '../../ducks/order';
import { getUrlForLookbookProduct, ProductUrlProps } from '../../logic/products';
import { paths } from '../../paths';
import { useLookbookProducts } from '../../services/hooks/lookbook/useLookbookProducts';
import { isDefined } from '../../utils/is';
import { isEmpty } from '../../utils/isEmpty';
import { LookbookFiltersPanel } from '../products/FiltersBar/FiltersPanel';
import { NoResults } from '../products/NoResults';
import { ProductsList } from '../products/ProductsList';
import { SomethingWentWrong } from '../somethingWentWrong';
import Button from '../various/Button';
import { Notification } from '../various/Notification';
import { PageTitle } from '../various/PageTitle';
import { Wrapper, WrapperSize } from '../various/Wrapper';

import styles from './LookbookProducts.module.scss';

export const LookbookProductsPage = () => {
  const { lookbookId } = useParams<{ lookbookId: string }>();
  const { t } = useTranslation(['common', 'products', 'accounts', 'selections']);
  const navigate = useNavigate();
  const params = useParams<{ id: Id; lookbookId: Id }>();
  const dispatch = useDispatch();
  const isUserLoggedIn = useSelector(getIsLoggedIn);
  const orderDetails = useSelector(getOrderDetails);
  const {
    fetchNextPage,
    isFetching,
    isLoading,
    isPlaceholderData,
    isFetchingNextPage: isLoadingMoreProducts,
    data,
    isError,
    error,
  } = useLookbookProducts(lookbookId);

  const isLoadingProducts = (isFetching && isPlaceholderData) || isLoading;

  React.useEffect(() => {
    if (isDefined(error) && error.response?.status === HttpStatusCode.NotFound) {
      navigate(paths.PAGE_NOT_FOUND, { replace: true });
    }
  }, [error]);

  React.useEffect(() => {
    dispatch(resetLookbookFilters());
  }, [lookbookId]);

  const { productAttributes, pageCount, orderedProducts, totalItems } = React.useMemo(
    () => ({
      orderedProducts: data?.orderedProducts ?? [],
      pageCount: data?.pageCount ?? 0,
      productAttributes: data?.attributes ?? [],
      totalItems: data?.totalItems ?? 0,
    }),
    [data],
  );

  const hasProducts = !isEmpty(orderedProducts);

  React.useEffect(() => {
    if (!isEmpty(productAttributes)) {
      dispatch(setLookbookAttributes(productAttributes));
    }
  }, [dispatch, productAttributes]);

  const handleGetProductUrl = React.useCallback(
    (urlParams: ProductUrlProps) => getUrlForLookbookProduct(urlParams)(params.lookbookId),
    [params.lookbookId],
  );

  if (isEmpty(orderDetails.account)) {
    const redirectPath = isUserLoggedIn ? paths.ROOT : paths.LOGIN;

    return (
      <Notification>
        {t('accounts:account_not_active')}
        <Button variant={['button']} to={redirectPath}>
          {t('common:home_page')}
        </Button>
      </Notification>
    );
  }

  const shouldShowNoResults = !hasProducts && !isLoadingProducts;

  if (isError) {
    return <SomethingWentWrong />;
  }

  return (
    <PageTitle title={t('products:product_other')}>
      <div className={styles.filtersAndProductsContainer}>
        <LookbookFiltersPanel isLoadingProducts={isLoadingProducts} totalItems={totalItems} />
        <Wrapper size={WrapperSize.FULL} className={cx(styles.wrapper, { [styles.noProducts]: !hasProducts })}>
          {shouldShowNoResults ?
            <NoResults />
          : <ProductsList
              isLookbook
              isLoadingProducts={isLoadingProducts}
              orderedProducts={orderedProducts}
              isLoadingMoreProducts={isLoadingMoreProducts}
              onLoadMore={fetchNextPage}
              getProductUrl={handleGetProductUrl}
              totalItems={totalItems}
              pageCount={pageCount}
          />
          }
        </Wrapper>
      </div>
    </PageTitle>
  );
};
