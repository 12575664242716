export const getFileType = (file: Maybe<File>) => {
  if (file?.type?.includes('image')) {
    return 'image';
  }
  if (file?.type?.includes('video')) {
    return 'video';
  }

  return 'unknown';
};
