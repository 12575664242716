import { Cms } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { createEditorWindowName } from '../../../logic/cms/preview';
import { getIsPublished, getSharePageData } from '../../../logic/pages';
import { composeEditorPreviewPath } from '../../../utils/composeEditorPreviewPath';
import { isDefined } from '../../../utils/is';
import { SharePageModal } from '../../pagesList/SharePageModal';
import Button from '../../various/Button';
import { ArrowThinLeft, IconType } from '../../various/Icon';
import { IconButton } from '../../various/IconButton/IconButton';
import { TextEllipsis } from '../../various/TextEllipsis';
import { Tooltip } from '../../various/Tooltip';
import { EditorLanguageContext } from '../context/EditorLanguageContext';
import { EditorWidthSwitcher } from '../EditorWidthSwitcher';
import { SegmentationSwitcher } from '../segmentationSwitcher';

import styles from './TopBar.module.scss';

interface Props {
  pageMeta: Cms.Page;
  currentSegmentation: {
    marketId: Nullable<string>;
    pricelistId: Nullable<string>;
  };
  segmentationChange: (marketId: string, pricelistId: string) => void;
}

export const PreviewTopBar = ({ pageMeta, currentSegmentation, segmentationChange }: Props) => {
  const { editorLanguage } = React.useContext(EditorLanguageContext);
  const { t } = useTranslation(['cms', 'common']);
  const { markets, name, id, status, languages } = pageMeta;
  const isPublished = getIsPublished(status);

  const [isShareModalOpen, setShareModalOpen] = React.useState(false);

  const handleOpenEditor = React.useCallback(() => {
    const urlToOpen = composeEditorPreviewPath({ lang: editorLanguage, pageId: id, pathType: 'editor' });

    const windowName = createEditorWindowName(id);
    const windowRef = window.open('', windowName);
    const isUrlOfWindowRefSame = `${windowRef?.location.pathname}${windowRef?.location.search}` === urlToOpen;

    if (windowRef && isUrlOfWindowRefSame) {
      return windowRef.focus();
    }

    window.open(urlToOpen, windowName);
  }, [id, editorLanguage]);

  const handleHideShareModal = React.useCallback(() => {
    setShareModalOpen(false);
  }, []);

  const showShareModal = React.useCallback(() => {
    setShareModalOpen(true);
  }, []);

  const pageToShare = getSharePageData(pageMeta);

  return (
    <>
      <div className={styles.topBar}>
        <div className={styles.info}>
          <Button variant={['button', 'extraSmall']} className={styles.backButton} onClick={handleOpenEditor}>
            <ArrowThinLeft />
            <span className={styles.returnButton}>{t('common:back')}</span>
          </Button>

          <div className={styles.previewStatus}>
            <Tooltip content={name} placement="bottom-center">
              <span className={styles.viewName}>{t('common:preview')}</span>
              <TextEllipsis lines={2} noWrapText={false} className={styles.pageTitle}>
                {name}
              </TextEllipsis>
            </Tooltip>
          </div>

          <SegmentationSwitcher
            markets={markets}
            currentSegmentation={currentSegmentation}
            segmentationChange={segmentationChange}
            languages={languages}
          />
        </div>

        <EditorWidthSwitcher className={styles.previewDeviceSwitcher} />

        <div className={styles.actionButtons}>
          {isPublished && (
            <IconButton
              icon={IconType.Link}
              name={t('cms:get_shareable_link')}
              variant="secondary-light"
              className={styles.shareButton}
              onClick={showShareModal}
              showTooltip
            />
          )}
        </div>
      </div>

      {isDefined(pageToShare) && <SharePageModal isOpen={isShareModalOpen} onClose={handleHideShareModal} {...pageToShare} />}
    </>
  );
};
