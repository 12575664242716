import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getIsSelectionImportLoading, resetSelectionImport } from '../../../ducks';
import { useModalVisibility } from '../../../utils/hooks';
import { ModalSize } from '../../various/ModalElements';
import { ModalShell } from '../../various/ModalShell';

import { SelectionImportContent } from './SelectionImportContent';
import styles from './SelectionImportModal.module.scss';

export const SelectionImportModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'selections', 'validation']);
  const { isModalVisible, hideModal } = useModalVisibility('SelectionImportModal');
  const isImportLoading = useSelector(getIsSelectionImportLoading);

  React.useEffect(() => {
    return () => {
      dispatch(resetSelectionImport());
    };
  }, [dispatch]);

  const handleModalClosed = () => {
    dispatch(resetSelectionImport());
  };

  return (
    <ModalShell
      title={t('selections:import_selection_csv')}
      isOpen={isModalVisible}
      size={ModalSize.Medium}
      shouldCloseOnEsc={!isImportLoading}
      shouldCloseOnOverlayClick={!isImportLoading}
      hideCloseIcon={isImportLoading}
      onClose={hideModal}
      modalClosedCallback={handleModalClosed}
    >
      <div className={styles.wrapper}>
        <SelectionImportContent />
      </div>
    </ModalShell>
  );
};
