import { SliderProps } from '@typings';
import cx from 'classnames';
import React from 'react';

import styles from './Slider.module.scss';

const DEFAULT_MAX = 100;
const DEFAULT_MIN = 0;
const DEFAULT_STEP = 1;

export const Slider = (props: SliderProps) => {
  const { value, onChange, className, disabled, min = DEFAULT_MIN, max = DEFAULT_MAX, step = DEFAULT_STEP } = props;

  const style = React.useMemo(() => ({ backgroundSize: `${((value - min) / (max - min)) * 100}% 100%` }), [max, min, value]);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(Number(event.target.value));
    },
    [onChange],
  );

  return (
    <input
      type="range"
      min={min}
      max={max}
      step={step}
      disabled={disabled}
      className={cx(styles.slider, className)}
      onChange={handleChange}
      style={style}
      value={value}
    />
  );
};
