import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { handleSpaceAndEnter } from '../../../utils/handleEnterKey';
import { useIsTouchScreen } from '../../../utils/hooks/useIsTouchScreen';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { loadImage } from '../../../utils/loadImage';
import { ImageHoverEnlarge } from '../ImageHoverEnlarge/ImageHoverEnlarge';

import styles from './CustomImage.module.scss';
import { Placeholder } from './Placeholder';

interface Props {
  src?: string;
  aspectRatio: number;
  errorStyle?: string;
  placeholderClassName?: string;
  rounded?: boolean;
  size?: 'auto' | 'cover' | 'contain';
  enlargeOnHover?: boolean;
  onClick?: () => void;
  isSourceReady?: boolean;
}

const enum Status {
  Loading,
  Success,
  Error,
  Missing,
}

export const CustomImage = React.memo(
  ({
    src,
    placeholderClassName,
    aspectRatio,
    enlargeOnHover,
    errorStyle,
    rounded,
    size = 'contain',
    onClick,
    isSourceReady = true,
  }: Props) => {
    const { t } = useTranslation(['common']);
    const isTouchScreen = useIsTouchScreen();
    const [source, setSource] = React.useState('');
    const [status, setStatus] = React.useState(Status.Loading);

    React.useEffect(() => {
      if (!isSourceReady) {
        return;
      }

      if (!isDefined(src) || isEmpty(src)) {
        setStatus(Status.Missing);

        return;
      }

      const handleLoad = () => {
        setSource(src);
        setStatus(Status.Success);
        loadImageSubscription.unsubscribe();
      };

      const handleError = () => {
        setStatus(Status.Error);
        loadImageSubscription.unsubscribe();
      };

      const loadImageSubscription = loadImage(src).subscribe({
        complete: handleLoad,
        error: handleError,
      });

      return () => loadImageSubscription.unsubscribe();
    }, [src, isSourceReady]);

    const role = isDefined(onClick) ? 'button' : 'img';

    const placeholderClassNames = cx(placeholderClassName, { [styles.rounded]: rounded });

    switch (status) {
      case Status.Loading:
        return <Placeholder className={placeholderClassNames} aspectRatio={aspectRatio} />;
      case Status.Success: {
        const Image = (
          <Placeholder className={placeholderClassNames} aspectRatio={aspectRatio}>
            <div
              role={role}
              className={styles.imageHolder}
              style={{
                backgroundImage: `url(${source})`,
                backgroundSize: size,
              }}
              onClick={onClick}
              onKeyDown={handleSpaceAndEnter(onClick)}
            />
          </Placeholder>
        );

        return isDefined(enlargeOnHover) && !isTouchScreen ? <ImageHoverEnlarge src={source}>{Image}</ImageHoverEnlarge> : Image;
      }
      case Status.Error:
      case Status.Missing:
        return (
          <Placeholder className={placeholderClassNames} aspectRatio={aspectRatio} role={role} onClick={onClick}>
            <div className={cx(styles.errorHolder, errorStyle)}>
              {status === Status.Missing ? t('common:no_image') : t('common:display_image_fail')}
            </div>
          </Placeholder>
        );
      default:
        throw new Error('Incorrect status.');
    }
  },
);
