/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;
const ORIGINAL_COLOR = '#848484';

export const Drop = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => {
  return (
    <svg width={width} height={width} viewBox="0 0 20 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5361 2.73222L14.2791 6.65442C15.4587 7.89301 16.0485 9.54446 16.0485 11.1324C16.0485 12.7203 15.4587 14.3956 14.2791 15.6342C13.0995 16.8728 11.5493 17.5 9.99922 17.5C8.44909 17.5 6.89897 16.8728 5.71936 15.6342C4.53975 14.3956 3.94995 12.7203 3.94995 11.1324C3.94995 9.54446 4.53975 7.89301 5.71936 6.65442L9.46991 2.73222C9.61118 2.58355 9.80298 2.5 10.003 2.5C10.203 2.5 10.3948 2.58355 10.5361 2.73222ZM6.79301 7.92503C5.93099 8.82221 5.46974 9.83055 5.46217 11.4185H14.5361C14.5285 9.83055 14.0673 8.85397 13.2052 7.96473L9.99912 4.48715L6.79301 7.92503Z"
        fill={color}
      />
    </svg>
  );
};
