import { getIsFeatureEnabled } from '../../../utils/getIsFeatureEnabled';

import { EditorLanguageSwitcher } from './EditorLanguageSwitcher';
import styles from './TranslationsToolbar.module.scss';
import { UploadDownloadTranslationsLazy } from './UploadDownloadTranslations';

export const TranslationsToolbar = () => {
  const isIsImportExportEnabled = getIsFeatureEnabled('ENABLE_CMS_IMPORT_EXPORT');

  return (
    <div className={styles.container}>
      <EditorLanguageSwitcher />
      {isIsImportExportEnabled && <UploadDownloadTranslationsLazy />}
    </div>
  );
};
