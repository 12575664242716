import React from 'react';
import { useInView } from 'react-intersection-observer';

interface Props {
  estimatedHeight: number | string;
  forceVisible?: boolean;
  wrapperClassName?: string;
  triggerOnce?: boolean;
  root?: Element | null;
  rootMargin?: string;
}

export const LazyRender = ({
  estimatedHeight,
  forceVisible = false,
  wrapperClassName,
  triggerOnce = false,
  children,
  root = null,
  rootMargin,
}: React.WithChildren<Props>) => {
  const [ref, inView] = useInView({
    root,
    rootMargin,
    triggerOnce,
  });

  const wrapperStyle = React.useMemo(
    () => ({
      height: estimatedHeight,
    }),
    [estimatedHeight],
  );

  const style = React.useMemo(
    () => ({
      height: estimatedHeight,
      width: '100px',
    }),
    [estimatedHeight],
  );

  return (
    <div className={wrapperClassName} style={wrapperStyle} ref={ref}>
      {inView || forceVisible ? children : <div style={style} />}
    </div>
  );
};
