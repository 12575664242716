import i18next from 'i18next';
import { uniq } from 'ramda';
import { matchPath } from 'react-router-dom';
import { push } from 'redux-first-history';
import { combineEpics } from 'redux-observable';
import { from, merge } from 'rxjs';
import { exhaustMap, filter, ignoreElements, map, mergeAll, mergeMap, tap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { addToast } from '../components/various/Toasts';
import {
  addProductByBarcodeFailure,
  addProductByBarcodeSoldOutFailure,
  addProductByBarcodeSuccess,
  addProductsToOrder,
  addProductsToOrderFailure,
  addProductsToOrderSuccess,
  addVoucherFailure,
  addVoucherRequest,
  addVoucherSuccess,
  checkoutOrderSplitSuccess,
  checkoutOrderSuccess,
  clearProductDetailsData,
  copyToNewSelectionFailure,
  copyToNewSelectionRequest,
  copyToNewSelectionSuccess,
  createSelection,
  fetchCountriesRequest,
  fetchDeliveryWindowsRequest,
  fetchOrderFailure,
  fetchOrderRequest,
  fetchOrderSuccess,
  getCurrentPageSlug,
  getIsLoggedIn,
  getIsSeller,
  getLocation,
  getOrderDetails,
  getOrderId,
  getSharedLinksEnabled,
  hideModal,
  loadTermsFailure,
  loadTermsSuccess,
  removeProductsFromOrder,
  removeProductsFromOrderFailure,
  removeProductsFromOrderSuccess,
  removeVoucherFailure,
  removeVoucherRequest,
  removeVoucherSuccess,
  resetCheckoutForm,
  selectWorkingOrder,
  setDeliveryWindows,
  setItemsRequest,
  setMultipleItemsRequest,
  setUserAsViewer,
  sharedSelectionFailure,
  sharedSelectionRequest,
  sharedSelectionSuccess,
  showTerms,
  updateCheckoutForm,
} from '../ducks';
import { getPathName } from '../ducks/routing';
import { getIsOrderSplit } from '../logic/Orders';
import { compiledPaths, paths, VALID_ORDER_PATHS } from '../paths';
import { ORDERS_QUERY_KEY } from '../services/hooks/orders/useOrdersList';
import { buildTransactionData } from '../utils/analytics/buildTransactionData';
import { getError } from '../utils/errors';
import { getQueryParams } from '../utils/getQueryParams';
import { handleResponseStatus } from '../utils/handleResponseStatus';
import { isDefined } from '../utils/is';
import { omit } from '../utils/omit';
import { mapResponse } from '../utils/operators/mapResponse';

const selectWorkingOrderEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(selectWorkingOrder)),
    map(action => {
      const locationPathname = getPathName(state$.value);
      if (locationPathname.includes('orders')) {
        return push({ pathname: compiledPaths.ORDER_DETAILS({ id: action.payload }) });
      }

      if (locationPathname.includes('products')) {
        return push({ pathname: compiledPaths.PRODUCTS_ORDER({ id: action.payload }) }, { prevPath: locationPathname });
      }

      if (locationPathname.includes('checkout')) {
        return push({ pathname: compiledPaths.CHECKOUT_ORDER({ id: action.payload }) });
      }

      if (locationPathname.includes('pages')) {
        const pageSlug = getCurrentPageSlug(state$.value);

        return push({ pathname: compiledPaths.PAGES({ id: action.payload, pageSlug }) });
      }

      return action;
    }),
  );

const pushTransactionDataEpic: AppEpic = (action$, store$, { pushEventGTM }) =>
  merge(
    action$.pipe(
      filter(isActionOf(checkoutOrderSplitSuccess)),
      map(action => action.payload),
    ),
    action$.pipe(
      filter(isActionOf(checkoutOrderSuccess)),
      map(action => action.payload),
    ),
  ).pipe(
    map(({ orders }) => buildTransactionData(orders, getOrderDetails(store$.value))),
    filter(isDefined),
    mergeAll(),
    tap(pushEventGTM),
    ignoreElements(),
  );

const copyToNewSelectionEpic: AppEpic = (action$, _, { ordersRepository }) =>
  action$.pipe(
    filter(isActionOf(copyToNewSelectionRequest)),
    map(action => action.payload),
    mergeMap(data =>
      from(ordersRepository.copyToNewSelectionRequest(data)).pipe(
        mapResponse(
          ({ data: { newOrderId, failedProducts, products } }) => {
            const { orderNumber } = data;
            addToast(i18next.t('selections:order_copied_hint', { orderNumber }), {
              description: i18next.t('selections:not_empty_order_copied_hint', { orderNumber }),
              duration: 10000,
              position: 'bottom-left',
            });

            return [
              copyToNewSelectionSuccess({
                failedProducts: {
                  failedProducts,
                  products,
                },
              }),
              hideModal('CopyToNewSelectionModal'),
              push({ pathname: compiledPaths.ORDER_DETAILS({ id: newOrderId }) }),
            ];
          },
          () => {
            addToast(i18next.t('selections:copy_selection_fail'));

            return copyToNewSelectionFailure();
          },
        ),
      ),
    ),
  );

const resetCheckoutFormEpic: AppEpic = action$ =>
  action$.pipe(
    filter(isActionOf([checkoutOrderSuccess, checkoutOrderSplitSuccess, copyToNewSelectionRequest, createSelection, selectWorkingOrder])),
    map(() => resetCheckoutForm()),
  );

const resetTermsInCheckoutFormEpic: AppEpic = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf([addProductsToOrder, setItemsRequest, setMultipleItemsRequest])),
    filter(() => isDefined(state$.value.order.checkoutForm) && !getIsSeller(state$.value)),
    map(() => updateCheckoutForm({ ...state$.value.order.checkoutForm, isTermsAndConditionsChecked: false })),
  );
};

const fetchOrderEpic: AppEpic = (action$, store$, { selectionRepository }) =>
  action$.pipe(
    filter(isActionOf(fetchOrderRequest)),
    map(action => action.payload),
    exhaustMap(orderId =>
      from(selectionRepository.loadOrder(orderId)).pipe(
        mapResponse(
          ({ data }) => {
            const isSplitOrder = getIsOrderSplit(data);

            const orderPayload =
              isSplitOrder ?
                {
                  ...data,
                  originalOrderId: orderId,
                }
              : data;

            const mixedDeliveryWindows = isSplitOrder ? [] : data.deliveryWindows ?? [];

            return [fetchOrderSuccess(orderPayload), setDeliveryWindows(mixedDeliveryWindows), resetCheckoutForm()];
          },
          () => {
            const areSharedLinksEnabled = getSharedLinksEnabled(store$.value);
            const { sharedToken } = getQueryParams<{ sharedToken?: string }>(window.location.search);

            if (areSharedLinksEnabled && isDefined(sharedToken)) {
              return sharedSelectionRequest({ orderId, sharedToken });
            }

            const redirectPath = getIsLoggedIn(store$.value) ? paths.PAGE_NOT_FOUND : paths.LOGIN;

            return [fetchOrderFailure(), push({ pathname: redirectPath }, { from: getLocation(store$.value) })];
          },
        ),
      ),
    ),
  );

const verifySharedSelectionEpic: AppEpic = (action$, _, { selectionRepository }) =>
  action$.pipe(
    filter(isActionOf(sharedSelectionRequest)),
    map(action => action.payload),
    mergeMap(async ({ orderId }) => selectionRepository.getOrderFromLink(orderId)),
    mergeMap(response =>
      handleResponseStatus(response)(
        ({ data }) => {
          const mixedDeliveryWindows = data.deliveryWindows ?? [];

          return [
            sharedSelectionSuccess(data),
            fetchOrderSuccess(omit(data, ['token'])),
            setDeliveryWindows(mixedDeliveryWindows),
            fetchDeliveryWindowsRequest(),
            fetchCountriesRequest(),
          ];
        },
        () => {
          return [push({ pathname: paths.LINK_EXPIRED }), sharedSelectionFailure()];
        },
      ),
    ),
  );

const addProductsToOrderEpic: AppEpic = (action$, store$, { ordersRepository }) =>
  action$.pipe(
    filter(isActionOf(addProductsToOrder)),
    map(action => action.payload),
    map(({ isAddingByBarcode, products, deliveryWindows }) => ({
      deliveryWindows,
      isAddingByBarcode,
      orderId: getOrderId(store$.value),
      products,
    })),
    mergeMap(({ isAddingByBarcode, orderId, products, deliveryWindows }) =>
      from(ordersRepository.addProductsToOrder(orderId, { deliveryWindows, products })).pipe(
        mapResponse(
          res => {
            const addingByBarcodeAction = isAddingByBarcode ? [addProductByBarcodeSuccess()] : [];

            return [addProductsToOrderSuccess(res.data), ...addingByBarcodeAction];
          },
          () => {
            const addingByBarcodeAction = isAddingByBarcode ? [addProductByBarcodeFailure()] : [];

            return [addProductsToOrderFailure({ products }), ...addingByBarcodeAction];
          },
        ),
      ),
    ),
  );

const addProductByBarcodeEpic: AppEpic = action$ =>
  action$.pipe(
    filter(isActionOf([addProductByBarcodeSuccess, addProductByBarcodeFailure, addProductByBarcodeSoldOutFailure])),
    map(() => clearProductDetailsData()),
  );

const removeProductsFromOrderEpic: AppEpic = (action$, store$, { ordersRepository }) =>
  action$.pipe(
    filter(isActionOf(removeProductsFromOrder)),
    map(action => action.payload),
    map(({ products, deliveryWindows, variants }) => ({
      orderId: getOrderId(store$.value),
      payload: {
        ...(isDefined(deliveryWindows) && { deliveryWindows }),
        ...(isDefined(variants) && { variants }),
        products,
      },
    })),
    mergeMap(({ orderId, payload }) =>
      from(ordersRepository.removeProductsFromOrder(orderId, payload)).pipe(
        mapResponse(
          res => {
            const removedProductsCount = uniq(res.data.variantsRemoved.map(({ product }) => product)).length;

            addToast(i18next.t('selections:product_removed_from_selection', { count: removedProductsCount }));

            return removeProductsFromOrderSuccess(res.data);
          },
          () => {
            addToast(i18next.t('products:remove_products_fail'));

            return removeProductsFromOrderFailure({ products: payload.products });
          },
        ),
      ),
    ),
  );

const addVoucherEpic: AppEpic = (action$, store$, { ordersRepository }) =>
  action$.pipe(
    filter(isActionOf(addVoucherRequest)),
    map(action => action.payload),
    map(voucher => ({
      orderId: getOrderId(store$.value),
      voucher,
    })),
    mergeMap(async ({ orderId, voucher }) => ordersRepository.addVoucher(orderId, voucher)),
    mapResponse(
      res => addVoucherSuccess(res.data),
      error => addVoucherFailure(getError(error)),
    ),
  );

const removeVoucherEpic: AppEpic = (action$, store$, { ordersRepository }) =>
  action$.pipe(
    filter(isActionOf(removeVoucherRequest)),
    map(action => action.payload),
    map(voucher => ({
      orderId: getOrderId(store$.value),
      voucher,
    })),
    mergeMap(async ({ orderId, voucher }) => ordersRepository.removeVoucher(orderId, voucher)),
    mapResponse(
      res => removeVoucherSuccess(res.data),
      () => removeVoucherFailure(),
    ),
  );

const showTermsAndConditionsEpic: AppEpic = (action$, _, { ordersRepository }) =>
  action$.pipe(
    filter(isActionOf(showTerms)),
    mergeMap(async () => ordersRepository.getTermsAndConditions()),
    mapResponse(
      res => loadTermsSuccess(res.data),
      () => loadTermsFailure(),
    ),
  );

const fetchOrderForViewerEpic: AppEpic = action$ =>
  action$.pipe(
    filter(isActionOf(setUserAsViewer)),
    map(() => VALID_ORDER_PATHS.find(path => matchPath({ end: false, path }, window.location.pathname))),
    filter(isDefined),
    map(path => matchPath({ end: false, path }, window.location.pathname)?.params.id),
    filter(isDefined),
    map(id => fetchOrderRequest(id)),
  );

const invalidateOrdersListEpic: AppEpic = (action$, _, { queryClient }) =>
  action$.pipe(
    filter(isActionOf([checkoutOrderSuccess, checkoutOrderSplitSuccess])),
    tap(async () => queryClient.resetQueries({ queryKey: [ORDERS_QUERY_KEY] })),
    ignoreElements(),
  );

export const orderEpic = combineEpics(
  addProductByBarcodeEpic,
  selectWorkingOrderEpic,
  pushTransactionDataEpic,
  copyToNewSelectionEpic,
  fetchOrderEpic,
  addProductsToOrderEpic,
  removeProductsFromOrderEpic,
  addVoucherEpic,
  removeVoucherEpic,
  showTermsAndConditionsEpic,
  verifySharedSelectionEpic,
  fetchOrderForViewerEpic,
  invalidateOrdersListEpic,
  resetCheckoutFormEpic,
  resetTermsInCheckoutFormEpic,
);
