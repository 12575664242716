import { Selections } from '@typings';
import i18next from 'i18next';
import { omit } from 'ramda';
import { push } from 'redux-first-history';
import { combineEpics } from 'redux-observable';
import { from } from 'rxjs';
import { buffer, debounceTime, filter, ignoreElements, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { addToast } from '../components/various/Toasts';
import { DEFAULT_ITEMS_COUNT_PER_REQUEST } from '../constants/limits';
import { getIsDeliveryWindowsLoading, getValidDefaultStockTypeByBuyerId, setDeliveryWindows, setInitialFilters } from '../ducks';
import { getIsStockTypeSeparationEnabled } from '../ducks/config';
import {
  checkoutOrderSplitSuccess,
  checkoutOrderSuccess,
  copyToNewSelectionSuccess,
  createSelection,
  createSelectionFailure,
  createSelectionSuccess,
  editSelection,
  editSelectionFailure,
  editSelectionSuccess,
  getOrderId,
  setItemsFailure,
  setItemsRequest,
  setItemsSuccess,
  setMultipleItemsRequest,
} from '../ducks/order';
import {
  fetchMoreOrdersFailure,
  fetchMoreOrdersRequest,
  fetchMoreOrdersSuccess,
  fetchOrdersFailure,
  fetchOrdersRequest,
  fetchOrdersSuccess,
  getSelections,
} from '../ducks/selections';
import { getSelectedBuyerId } from '../ducks/user';
import { encodeSearchString } from '../logic/filters';
import { compiledPaths } from '../paths';
import { SELECTIONS_QUERY_KEY } from '../services/hooks/orders/useSelectionsList';
import { isDefined } from '../utils/is';
import { holdWhile } from '../utils/operators/holdWhile';
import { mapResponse } from '../utils/operators/mapResponse';

const SET_ITEMS_DEBOUNCE_TIME = 2000;

const getRequestData = (store: Store, params: Selections.DefaultRequestParams): Selections.FilterData => {
  const buyer = getSelectedBuyerId(store) || undefined;
  const { search = '', statuses = [], sortOrder = [], skipFirst = 0 } = params;

  return {
    buyer,
    limit: DEFAULT_ITEMS_COUNT_PER_REQUEST,
    search,
    skipFirst,
    sortOrder,
    statuses,
  };
};

const loadOrdersEpic: AppEpic = (action$, state$, { selectionRepository }) =>
  action$.pipe(
    filter(isActionOf([copyToNewSelectionSuccess, editSelectionSuccess, fetchOrdersRequest])),
    map(() => getRequestData(state$.value, { statuses: ['open', 'checkoutRequested'] })),
    mergeMap(async data => selectionRepository.loadOrders(data)),
    mapResponse(
      response => {
        const mixedDeliveryWindows = response.data.deliveryWindows ?? [];

        return [fetchOrdersSuccess(response.data), setDeliveryWindows(mixedDeliveryWindows)];
      },
      () => fetchOrdersFailure(),
    ),
  );

const fetchMoreOrdersEpic: AppEpic = (action$, state$, { selectionRepository }) =>
  action$.pipe(
    filter(isActionOf(fetchMoreOrdersRequest)),
    map(() => getSelections(state$.value).length),
    map(skipFirst => getRequestData(state$.value, { skipFirst, statuses: ['open', 'checkoutRequested'] })),
    mergeMap(async data => selectionRepository.loadOrders(data)),
    mapResponse(
      response => {
        const mixedDeliveryWindows = response.data.deliveryWindows ?? [];

        return [fetchMoreOrdersSuccess(response.data), setDeliveryWindows(mixedDeliveryWindows)];
      },
      () => fetchMoreOrdersFailure(),
    ),
  );

const createSelectionEpic: AppEpic = (action$, state$, { selectionRepository }) =>
  action$.pipe(
    filter(isActionOf(createSelection)),
    map(action => action.payload),
    holdWhile(state$, state => (getIsStockTypeSeparationEnabled(state) ? !getIsDeliveryWindowsLoading(state) : true)),
    mergeMap(({ name, buyer, initialStockType, redirectPath }) =>
      from(selectionRepository.createSelection({ buyer, name })).pipe(
        mapResponse(
          res => {
            const compiledRedirectPath = isDefined(redirectPath) ? compiledPaths[redirectPath]({ id: res.data.order.order }) : undefined;

            if (isDefined(initialStockType)) {
              addToast(i18next.t('selections:new_selection_created'), { position: 'bottom-left' });
            }

            const stockType = initialStockType ?? getValidDefaultStockTypeByBuyerId(res.data.buyer.buyer)(state$.value);

            return [
              setInitialFilters({ stockType }),
              createSelectionSuccess(res.data),
              ...(isDefined(compiledRedirectPath) ?
                [push({ pathname: compiledRedirectPath, search: encodeSearchString({}, { stockType }) })]
              : []),
            ];
          },
          () => createSelectionFailure(),
        ),
      ),
    ),
  );

const editSelectionEpic: AppEpic = (action$, _, { selectionRepository }) =>
  action$.pipe(
    filter(isActionOf(editSelection)),
    map(action => action.payload),
    mergeMap(async ({ orderId, name }) => selectionRepository.editSelection(orderId, name)),
    mapResponse(
      res => editSelectionSuccess(res.data),
      () => editSelectionFailure(),
    ),
  );

const selectionItemsEpic: AppEpic = (action$, store$, { ordersRepository }) =>
  action$.pipe(
    filter(isActionOf([setItemsRequest, setMultipleItemsRequest])),
    map(action => (isActionOf(setMultipleItemsRequest)(action) ? action.payload.items : [action.payload])),
    buffer(action$.pipe(filter(isActionOf([setItemsRequest, setMultipleItemsRequest])), debounceTime(SET_ITEMS_DEBOUNCE_TIME))),
    map(items => ({
      items: items.flat().map(item => omit(['product'], item)),
      orderId: getOrderId(store$.value),
    })),
    switchMap(({ orderId, items }) =>
      from(ordersRepository.setQuantityForMultipleItems(orderId, items)).pipe(
        mapResponse(
          res => setItemsSuccess(res.data),
          () => setItemsFailure(),
        ),
        takeUntil(action$.pipe(filter(isActionOf([setItemsRequest, setMultipleItemsRequest])))),
      ),
    ),
  );

const invalidateSelectionListEpic: AppEpic = (action$, _, { queryClient }) =>
  action$.pipe(
    filter(
      isActionOf([
        copyToNewSelectionSuccess,
        editSelectionSuccess,
        createSelectionSuccess,
        checkoutOrderSuccess,
        checkoutOrderSplitSuccess,
      ]),
    ),
    tap(async () => queryClient.resetQueries({ queryKey: [SELECTIONS_QUERY_KEY] })),
    ignoreElements(),
  );

export const selectionEpic = combineEpics(
  loadOrdersEpic,
  fetchMoreOrdersEpic,
  createSelectionEpic,
  editSelectionEpic,
  invalidateSelectionListEpic,
  selectionItemsEpic,
);
