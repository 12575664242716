import cx from 'classnames';

import { getPercentageFromTotal } from '../../../../logic/selectionOverview';

import styles from './BarChart.module.scss';

interface Props {
  totalCount: number;
  rowCount: number;
  isVariantBar: boolean;
}

export const BarChart = ({ totalCount, rowCount, isVariantBar }: Props) => {
  const isBarEmpty = rowCount === 0;
  const percent = totalCount === 0 ? 0 : getPercentageFromTotal(rowCount, totalCount);

  const barStyles = { width: `${percent}%` };

  return (
    <div
      className={cx(styles.barWrapper, {
        [styles.emptyBar]: isBarEmpty,
        [styles.variantBar]: isVariantBar,
      })}
    >
      <div className={styles.bar} style={barStyles}></div>
      <div className={styles.barValue}>{percent}%</div>
    </div>
  );
};
