import { Cms, Id } from '@typings';
import React from 'react';

import { getSharePageData } from '../../../logic/pages';
import { isDefined } from '../../is';

export const useSharePage = (pages: Nullable<Cms.Page[]>) => {
  const [pageId, setPageId] = React.useState<Nullable<Id>>(null);
  const isSharePageOpen = isDefined(pageId);

  const closeSharePage = () => setPageId(null);
  const pageData = pages?.find(page => page.id === pageId);
  const pageToShare = getSharePageData(pageData);

  return { closeSharePage, isSharePageOpen, openSharePage: setPageId, pageToShare };
};
