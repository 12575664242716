import { nanoid } from 'nanoid';
import { Cms } from 'typings';

export const enum TextStyles {
  Paragraph = 'cms-paragraph',
  Preheading = 'cms-preheading',
  PrimaryHeading = 'cms-primaryHeading',
  Quote = 'cms-quote',
}

const TEXT_STYLE_COMMON: Pick<Cms.TextStyleProperties, 'color' | 'fontFamily' | 'textAlign' | 'fontStyle'> = {
  color: '#000000',
  fontFamily: 'proxima-nova',
  fontStyle: 'normal',
  textAlign: 'center',
};

export const textStylesConfig: Record<TextStyles, Cms.TextStyle> = {
  [TextStyles.Paragraph]: {
    className: TextStyles.Paragraph,
    id: nanoid(),
    isInternal: true,
    name: 'Paragraph',
    properties: {
      default: {
        ...TEXT_STYLE_COMMON,
        fontSize: 26,
        fontWeight: 400,
        letterSpacing: 0.225,
        lineHeight: 1.31,
        textTransform: 'none',
      },
    },
  },
  [TextStyles.Preheading]: {
    className: TextStyles.Preheading,
    id: nanoid(),
    isInternal: true,
    name: 'Preheading',
    properties: {
      default: {
        ...TEXT_STYLE_COMMON,
        fontSize: 18,
        fontWeight: 700,
        letterSpacing: 1,
        lineHeight: 1.28,
        textTransform: 'uppercase',
      },
    },
  },
  [TextStyles.PrimaryHeading]: {
    className: TextStyles.PrimaryHeading,
    id: nanoid(),
    isInternal: true,
    name: 'Primary Heading',
    properties: {
      default: {
        ...TEXT_STYLE_COMMON,
        fontSize: 42,
        fontWeight: 400,
        letterSpacing: 1,
        lineHeight: 1.29,
        textTransform: 'uppercase',
      },
    },
  },
  [TextStyles.Quote]: {
    className: TextStyles.Quote,
    id: nanoid(),
    isInternal: true,
    name: 'Quote',
    properties: {
      default: {
        ...TEXT_STYLE_COMMON,
        fontSize: 60,
        fontStyle: 'italic',
        fontWeight: 400,
        letterSpacing: 0.225,
        lineHeight: 1.13,
        textTransform: 'none',
      },
    },
  },
};
