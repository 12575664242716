import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;

export const DoubleCheck = ({ width = ORIGINAL_WIDTH, color }: IconComponentProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width={width} height={width} fill={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.051 5.81a.625.625 0 0 1-.004.884l-7.572 7.5a.625.625 0 0 1-.88 0L.81 10.444a.625.625 0 1 1 .88-.888l3.346 3.314 7.131-7.064a.625.625 0 0 1 .884.004Zm6.143 0a.625.625 0 0 1-.004.884l-7.571 7.5a.625.625 0 0 1-.88 0L8.726 12.2a.625.625 0 1 1 .88-.888l1.573 1.558 7.131-7.064a.625.625 0 0 1 .884.004Z"
      />
    </svg>
  );
};
