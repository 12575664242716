import cx from 'classnames';
import React from 'react';

import { handleSpaceAndEnter } from '../../../utils/handleEnterKey';

import styles from './CustomImage.module.scss';

interface Props {
  aspectRatio: number;
  className?: string;
  placeholderContentClassName?: string;
  onClick?: () => void;
  role?: string;
}

export const Placeholder = React.memo(
  ({ aspectRatio, className, placeholderContentClassName, children, onClick, role }: React.WithOptionalChildren<Props>) => {
    const style = React.useMemo(() => ({ paddingTop: `calc(${aspectRatio} * 100%)` }), [aspectRatio]);

    return (
      <div
        onClick={onClick}
        onKeyDown={handleSpaceAndEnter(onClick)}
        role={role}
        className={cx(styles.placeholder, className)}
        style={style}
      >
        <div className={cx(styles.placeholderContent, placeholderContentClassName)}>{children}</div>
      </div>
    );
  },
);
