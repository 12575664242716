import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Cms } from '@typings';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { addToast } from '../../../components/various/Toasts';
import { compiledPaths } from '../../../paths';
import { createPage } from '../../pagesRepository';

import { PAGES_QUERY_KEY } from './usePages';

export const useCreatePage = () => {
  const { t } = useTranslation(['cms']);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const mutationFn = async (data: Cms.CreatePageDTO) => {
    return createPage(data);
  };

  const handleSuccess = (page: Cms.Page) => {
    navigate({ pathname: compiledPaths.CMS_EDITOR({ pageId: page.id }) });
    queryClient.resetQueries({ queryKey: [PAGES_QUERY_KEY] });
  };

  const handleError = () => {
    addToast(t('cms:create_page_fail'));
  };

  return useMutation({
    mutationFn,
    onError: handleError,
    onSuccess: handleSuccess,
  });
};
