import { isDefined } from './is';

const CANVAS_HEIGHT_RATIO = 0.75;
const CANVAS_WIDTH = 800;
const CANVAS_HEIGHT = CANVAS_WIDTH * CANVAS_HEIGHT_RATIO;

interface Props {
  canvasEl: HTMLCanvasElement;
  sourceEl: HTMLVideoElement;
}
export const createCanvasCaptureToDataUrl = ({ canvasEl, sourceEl }: Props) => {
  // eslint-disable-next-line functional/immutable-data
  canvasEl.width = CANVAS_WIDTH;
  // eslint-disable-next-line functional/immutable-data
  canvasEl.height = CANVAS_HEIGHT;
  const context = canvasEl.getContext('2d');
  if (!isDefined(context)) {
    return;
  }

  context.drawImage(sourceEl, 0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);

  return canvasEl.toDataURL();
};
