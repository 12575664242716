import cx from 'classnames';

import styles from './SnapGuides.module.scss';

interface Props {
  value: number;
  placement?: 'before' | 'after';
}

export const Tooltip = ({ value, placement = 'after' }: Props) => {
  return <div className={cx(styles.tooltip, { [styles[placement]]: placement })}>{value}px</div>;
};
