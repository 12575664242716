import { Cms } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getIsPublishing } from '../../../../../ducks';
import { getHasUnpublishedChanges, getIsPublished } from '../../../../../logic/pages';
import { usePagePublicationActions } from '../../../../../utils/hooks';
import Button from '../../../../various/Button';
import { IconType } from '../../../../various/Icon';
import { PopoverMenu, TriggerProps } from '../../../../various/PopoverMenu';
import { EditorLanguageContext } from '../../../context/EditorLanguageContext';

import styles from './SingleLanguageButton.module.scss';

interface Props {
  pageMeta: Cms.Page;
}

export const SingleLanguageButton = ({ pageMeta }: Props) => {
  const { status, lastPublishedDate, id } = pageMeta;

  const { t } = useTranslation(['cmsConfirmationConfig', 'cms', 'common']);
  const [arePublishOptionsVisible, setArePublishOptionsVisible] = React.useState(false);
  const { defaultLanguage } = React.useContext(EditorLanguageContext);

  const handleSetPublishOptionsVisibility = React.useCallback((isOpen: boolean) => () => setArePublishOptionsVisible(isOpen), []);

  const { secondaryMenuActions, handlePublishPage, handleUnpublishPage } = usePagePublicationActions({
    closeMenu: handleSetPublishOptionsVisibility(false),
    languages: [defaultLanguage],
    lastPublishedDate,
    pageId: id,
    status,
  });

  const isPublished = getIsPublished(status);
  const isPublishing = useSelector(getIsPublishing);
  const hasUnpublishedChanges = getHasUnpublishedChanges(status);

  if (isPublished && !hasUnpublishedChanges) {
    return (
      <Button variant={['button', 'transparent', 'small']} isLoading={isPublishing} onClick={handleUnpublishPage}>
        {t('cmsConfirmationConfig:unpublish_page.okText')}
      </Button>
    );
  }

  const iconRotation = arePublishOptionsVisible ? 180 : 0;

  const publishButtonName = isPublished ? t('common:update') : t('cms:publish');

  return (
    <div className={styles.publishButtonWrapper}>
      <Button
        variant={['button', 'small', 'blue']}
        isLoading={isPublishing}
        className={cx({
          [styles.hasMoreOptions]: isPublished,
        })}
        onClick={handlePublishPage}
        isActive={arePublishOptionsVisible}
      >
        {publishButtonName}
      </Button>
      {isPublished && (
        <PopoverMenu
          items={secondaryMenuActions}
          name={t('cms:more_publish_options')}
          onOpen={handleSetPublishOptionsVisibility(true)}
          onClose={handleSetPublishOptionsVisibility(false)}
          containerClassName={styles.publishOptions}
        >
          {(props: TriggerProps) => (
            <Button
              variant={['button', 'small', 'blue']}
              className={styles.publishButtonArrow}
              icon={IconType.ArrowRounded}
              iconRotation={iconRotation}
              aria-label={t('cms:more_publish_options')}
              isActive={arePublishOptionsVisible}
              {...props}
            />
          )}
        </PopoverMenu>
      )}
    </div>
  );
};
