import React from 'react';
import { useTranslation } from 'react-i18next';

import { isEmpty } from '../../../utils/isEmpty';
import { Switch } from '../../various/Fields/Switch';
import { HotspotsContext } from '../context/HotspotsContext';

import styles from './HotspotEditor.module.scss';

export const PreviewProductsSwitch = () => {
  const { t } = useTranslation(['cms']);
  const { hotspots, isProductsTooltipVisible, setIsProductsTooltipVisible } = React.useContext(HotspotsContext);

  return (
    <div className={styles.productSwitch}>
      <Switch
        label={t('cms:preview_products')}
        checked={isProductsTooltipVisible}
        onValueChange={setIsProductsTooltipVisible}
        defaultChecked={isProductsTooltipVisible}
        disabled={isEmpty(hotspots)}
      />
      <span className={styles.productSwitchLabel}>{t('cms:preview_products')}</span>
    </div>
  );
};
