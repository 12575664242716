import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getProductsOrder, pushEvent } from '../../../ducks';
import { getProductSearchQuery } from '../../../logic/products';
import { AllProductsTrackingEvent, getAddedProductPositionWithSearchQueryEvent } from '../../../utils/analytics/events';
import { useIsScreenSize } from '../../../utils/hooks';
import { useAmplitude } from '../../../utils/hooks/useAmplitude';
import { useIsTouchScreen } from '../../../utils/hooks/useIsTouchScreen';
import { useSearchParamByName } from '../../../utils/hooks/useSearchParamByName';
import { useToggleInOrder } from '../../../utils/hooks/useToggleInOrder';
import { IconType } from '../../various/Icon';
import { Button } from '../../various/NewButton';
import { ButtonGroup } from '../../various/NewButton/ButtonGroup';
import { AddProductButton } from '../AddProductButton';

import { useProductContext } from './context/ProductContext';
import styles from './Product.module.scss';

interface Props {
  isActive: boolean;
  productId: string;
}

const MOBILE_BREAKPOINT = 520;

export const ProductButtons = ({ isActive, productId }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'products', 'selections']);
  const { hasHoverOrFocus } = useProductContext();
  const { isLoading, isRemoving, isAdding, isInOrder, hasUnits, toggleInOrder } = useToggleInOrder({ productId });
  const hasSearchQuery = useSearchParamByName('filters')?.includes('search');
  const productsOrder = useSelector(getProductsOrder);

  const navigate = useNavigate();
  const isMobile = useIsScreenSize(MOBILE_BREAKPOINT);
  const isTouchScreen = useIsTouchScreen();
  const { trackEvent } = useAmplitude();

  const handleProductTracking = () => {
    trackEvent({ name: 'listing.button.addtoselection.click' });

    if (hasSearchQuery) {
      const productIndex = productsOrder.findIndex(product => product === productId) + 1;
      dispatch(pushEvent(getAddedProductPositionWithSearchQueryEvent(productIndex)));
    }

    dispatch(pushEvent({ event: AllProductsTrackingEvent.ADD_TO_SELECTION_CLICKED }));
  };

  const handleClick = () => {
    if (!isInOrder) {
      handleProductTracking();
      toggleInOrder();

      return;
    }

    navigate({ search: getProductSearchQuery({ productId }) });
  };

  const buttonCopy = React.useMemo(() => {
    if (isAdding) {
      return t('common:adding');
    }

    if (isRemoving) {
      return t('common:removing');
    }

    if (!isInOrder) {
      return t('selections:add_to_selection');
    }

    return isMobile ? t('products:add_quantity') : t('products:add_quantities');
  }, [isAdding, isInOrder, isMobile, isRemoving, t]);

  const isInOrderWithoutUnits = isInOrder && !hasUnits;
  const isInOrderOrLoading = isInOrder || isLoading;

  const shouldShowCompactButton = isInOrder && !isLoading;
  const shouldShowMainButton = (isTouchScreen || isLoading || hasHoverOrFocus) && (!hasUnits || isLoading);
  const shouldShowMainButtonLabel = isTouchScreen ? isInOrderWithoutUnits || (hasUnits && !isLoading) : hasHoverOrFocus;

  const isWrapperVisible = isTouchScreen || hasHoverOrFocus || isInOrderOrLoading;
  const isWrapperFluid = isTouchScreen ? isInOrderWithoutUnits : (hasHoverOrFocus || !isInOrderOrLoading) && (!hasUnits || isLoading);

  const classNames = cx(styles.buttons, {
    [styles.visible]: isWrapperVisible,
    [styles.fluid]: isWrapperFluid,
  });

  const buttonIcon = !isInOrder ? IconType.Basket : undefined;

  const buttonSize = isMobile ? 'small' : 'regular';

  const tabIndex = isActive ? 0 : -1;

  return (
    <ButtonGroup variant="shadow" className={classNames}>
      {shouldShowMainButton && (
        <Button
          isFluid
          isLoading={isLoading}
          size={buttonSize}
          tabIndex={tabIndex}
          icon={buttonIcon}
          color="dark"
          variant="ghost"
          data-testid="addProductButton"
          onClick={handleClick}
        >
          {shouldShowMainButtonLabel && buttonCopy}
        </Button>
      )}
      {shouldShowCompactButton && <AddProductButton productId={productId} size={buttonSize} tabIndex={tabIndex} />}
    </ButtonGroup>
  );
};
