import { useTranslation } from 'react-i18next';

import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';

interface Props {
  text: string | undefined;
}

export const MaybeText = ({ text }: Props) => {
  const { t } = useTranslation(['common']);

  return isDefined(text) && !isEmpty(text) ? <>{text}</> : <i>{t('common:none')}</i>;
};
