import { Input as InputNs } from '@typings';
import cx from 'classnames';
import React from 'react';
import { mergeRefs } from 'react-laag';

import { INPUT_MAX_LENGTH } from '../../../../constants/limits';
import { isDefined } from '../../../../utils/is';
import { isEmpty } from '../../../../utils/isEmpty';
import { Key } from '../../../../utils/keys';
import Icon from '../../Icon';
import { Skeleton } from '../../Skeleton';

import styles from './Input.module.scss';
import { InputClearButton } from './InputClearButton';
import { InputCopyButton } from './InputCopyButton';

const programmaticValueChange = (inputRef: React.RefObject<HTMLInputElement>, newValue: string) => {
  const propertyDescriptor = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value');

  if (!isDefined(propertyDescriptor) || !isDefined(inputRef.current)) {
    return;
  }

  const nativeInputValueSetter = propertyDescriptor.set;
  nativeInputValueSetter?.call(inputRef.current, newValue);

  const changeEvent = new Event('input', { bubbles: true });
  inputRef.current.dispatchEvent(changeEvent);
};

export const Input = React.forwardRef<HTMLInputElement, InputNs.Props>((props, ref) => {
  const {
    className,
    copyButtonRef,
    dataTestId,
    describedById,
    errorMessageId,
    isClearable = false,
    isCopyable = false,
    isDisabled,
    isInvalid = false,
    isLoading = false,
    isReadonly = false,
    isRequired = false,
    icon,
    postfix,
    size = 'regular',
    type = 'text',
    value,
    onCopy,
    ...rest
  } = props;

  const internalRef = React.useRef<HTMLInputElement | null>(null);

  const hasValue = isDefined(value) && !isEmpty(value.toString());

  const handleClear = () => {
    programmaticValueChange(internalRef, '');
    internalRef.current?.focus();
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (!hasValue) {
      return;
    }

    if (event.code === Key.ESCAPE) {
      event.stopPropagation();

      programmaticValueChange(internalRef, '');
    }
  };

  const classNames = cx(styles.inputWrapper, className, {
    [styles.sizeMini]: size === 'mini',
    [styles.sizeSmall]: size === 'small',
    [styles.sizeLarge]: size === 'large',
    [styles.invalid]: isInvalid,
    [styles.disabled]: isDisabled,
  });

  if (isLoading) {
    return <Skeleton type="input" />;
  }

  return (
    <div className={classNames}>
      {isDefined(icon) && <Icon type={icon} className={styles.icon} />}
      <input
        ref={mergeRefs(internalRef, ref)}
        className={styles.input}
        type={type}
        aria-invalid={isInvalid}
        aria-describedby={describedById}
        aria-errormessage={errorMessageId}
        maxLength={INPUT_MAX_LENGTH}
        disabled={isDisabled}
        readOnly={isReadonly}
        data-testid={dataTestId}
        required={isRequired}
        value={value}
        {...(isClearable && { onKeyDown: handleKeyDown })}
        {...rest}
      />
      {isDefined(postfix) && <div className={styles.postfix}>{postfix}</div>}
      {isClearable && <InputClearButton isVisible={hasValue} onClick={handleClear} />}
      {isCopyable && <InputCopyButton ref={copyButtonRef} inputRef={internalRef} onClick={onCopy} />}
    </div>
  );
});
