import { DeliveryWindow, Product } from '@typings';
import { prop, uniq, uniqBy } from 'ramda';
import React from 'react';

import { ProductsEtaContext } from '../../components/products/ProductsEta';
import { formatLocaleDate } from '../../utils/dates';
import { useTranslationsConfig } from '../../utils/hooks';
import { isDefined } from '../../utils/is';
import { isEmpty } from '../../utils/isEmpty';

const FALLBACK_LEGEND_COLOR = '#000000';

export const useEtaLegend = (variants?: Product.Standard[], deliveryWindowId?: DeliveryWindow.Id) => {
  const { allItemsEtaPerDelwin, legendColorPerEta, uniqueEtaDates: allUniqueEtaDates } = React.useContext(ProductsEtaContext);
  const { currentLanguageCode } = useTranslationsConfig();

  const uniqueEtaDates = React.useMemo(() => {
    if (!isDefined(deliveryWindowId) || !isDefined(variants)) {
      return allUniqueEtaDates;
    }

    const etaPerDelwin = allItemsEtaPerDelwin[deliveryWindowId] ?? {};
    const itemIds = variants.flatMap(variant => variant.items.flatMap(item => item.item));
    const itemIdsWithEta = Object.keys(etaPerDelwin).filter(itemId => itemIds.includes(itemId));

    return uniq(
      itemIdsWithEta.flatMap(itemId => {
        const itemEtasPerDelwin = etaPerDelwin[itemId] ?? [];

        if (typeof itemEtasPerDelwin === 'string') {
          return itemEtasPerDelwin;
        }

        const [earliestDate] = itemEtasPerDelwin;

        return earliestDate ?? [];
      }),
    );
  }, [deliveryWindowId, variants, allItemsEtaPerDelwin, allUniqueEtaDates]);

  const legendItems = React.useMemo(() => {
    const items = [...uniqueEtaDates].sort().map(eta => ({
      color: legendColorPerEta[eta] ?? FALLBACK_LEGEND_COLOR,
      label: formatLocaleDate(eta, currentLanguageCode),
      value: eta,
    }));

    return uniqBy(prop('color'), items);
  }, [currentLanguageCode, uniqueEtaDates, legendColorPerEta]);

  return {
    hasItemsWithEta: !isEmpty(uniqueEtaDates),
    legendItems,
  };
};
