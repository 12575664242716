import { Cms } from '@centra';
import { ConfirmationGuard } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ButtonStyles, TextStyles } from '../../../cmsTemplates';
import { ConfirmDeleteStyleContent } from '../../../components/cms/TextTools/ContentPartStyleSelect/ConfirmDeleteStyleContent';
import { deleteCustomStyleRequest, getCmsBlocks, replaceCustomStyles, resetCurentBlock } from '../../../ducks';
import { getIsButtonPartStyle, isStyleUsingOnPage } from '../../../logic/cms/styles';
import { useConfirmationGuard } from '../../../utils/hooks/useConfirmationGuard';
import { isDefined } from '../../../utils/is';

export const useDeleteStyle = (allPartStyles: Record<string, Cms.ContentPartStyle>) => {
  const dispatch = useDispatch();
  const [partStyleToDelete, setPartStyleToDelete] = React.useState<Cms.ContentPartStyle>();
  const isTextStyle = isDefined(partStyleToDelete) ? !getIsButtonPartStyle(partStyleToDelete) : true;
  const defaultStyle = isTextStyle ? TextStyles.Paragraph : ButtonStyles.FilledPrimary;
  const [confirmationModal, setConfirmationModal] = React.useState<Nullable<ConfirmationGuard.Function>>(null);
  const blocks = useSelector(getCmsBlocks);
  const [selectedPartStyle, setSelectedPartStyle] = React.useState<string>(defaultStyle);
  const confirmationGuard = useConfirmationGuard();
  const { t } = useTranslation(['cms']);

  React.useEffect(() => setSelectedPartStyle(defaultStyle), [defaultStyle]);

  const isPartStyleCanBeReplaced = React.useMemo(() => {
    return isDefined(partStyleToDelete) && isStyleUsingOnPage(blocks, partStyleToDelete);
  }, [blocks, partStyleToDelete]);

  const setConfirmationModalData = () => {
    setConfirmationModal(confirmationGuard(() => confirmationModalConfig));
  };

  const onChange = React.useCallback((value: string) => setSelectedPartStyle(value), []);

  const confirmAction = React.useCallback(() => {
    if (!isDefined(partStyleToDelete)) {
      return;
    }

    const { id, name, className } = partStyleToDelete;
    dispatch(deleteCustomStyleRequest({ id, name }));
    if (isPartStyleCanBeReplaced) {
      const partClassName = allPartStyles[selectedPartStyle];
      if (!isDefined(partClassName)) {
        return;
      }
      dispatch(replaceCustomStyles({ [className]: partClassName }));
    }
  }, [partStyleToDelete, dispatch, isPartStyleCanBeReplaced, allPartStyles, selectedPartStyle]);

  const textTitle = t(isPartStyleCanBeReplaced ? 'cms:delete_replace_text_style' : 'cms:delete_text_style');
  const buttonTitle = t(isPartStyleCanBeReplaced ? 'cms:delete_replace_button_style' : 'cms:delete_button_style');
  const modalTitleAndButtonText = isTextStyle ? textTitle : buttonTitle;

  const confirmationModalContent = React.useMemo(() => {
    if (!isDefined(partStyleToDelete)) {
      return null;
    }

    return (
      <ConfirmDeleteStyleContent
        partStyle={partStyleToDelete}
        isPartStyleCanBeReplaced={isPartStyleCanBeReplaced}
        onChange={onChange}
        selectedPartStyle={selectedPartStyle}
        isTextStyle={isTextStyle}
        partStyles={allPartStyles}
      />
    );
  }, [partStyleToDelete, isPartStyleCanBeReplaced, onChange, selectedPartStyle, isTextStyle, allPartStyles]);

  const confirmationModalConfig: ConfirmationGuard.Config = React.useMemo(
    () => ({
      content: confirmationModalContent,
      okText: modalTitleAndButtonText,
      onOk: confirmAction,
      onRequestClose: () => dispatch(resetCurentBlock()),
      title: modalTitleAndButtonText,
    }),
    [confirmationModalContent, modalTitleAndButtonText, confirmAction, dispatch],
  );

  React.useEffect(() => {
    confirmationModal?.update(confirmationModalConfig);
  }, [confirmationModalConfig, confirmationModal]);

  return {
    setConfirmationModalData,
    setPartStyleToDelete,
  };
};
