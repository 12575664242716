/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;

export const PlusCircle = ({ width = ORIGINAL_WIDTH, color = 'currentColor' }: IconComponentProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox="0 0 20 20" fill={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 4.355a5.645 5.645 0 1 0 0 11.29 5.645 5.645 0 0 0 0-11.29ZM3 10a7 7 0 1 1 14 0 7 7 0 0 1-14 0Zm7-3.387c.374 0 .677.303.677.677v2.033h2.033a.677.677 0 0 1 0 1.354h-2.033v2.033a.677.677 0 0 1-1.354 0v-2.033H7.29a.677.677 0 0 1 0-1.354h2.033V7.29c0-.374.303-.677.677-.677Z"
      />
    </svg>
  );
};
