import cx from 'classnames';
import React from 'react';

import { ButtonVariant } from '../../../../../typings';
import { isDefined } from '../../../utils/is';
import Icon, { IconType } from '../Icon';
import { Link } from '../Link';
import { TextEllipsis } from '../TextEllipsis';

import styles from './Button.module.scss';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: (event: React.SyntheticEvent<EventTarget>) => any;
  className?: string;
  icon?: IconType;
  iconRotation?: number;
  isLoading?: boolean;
  loadingMessage?: string;
  title?: string;
  to?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  variant: ReadonlyArray<ButtonVariant>;
  dataTestId?: string | undefined;
  truncateText?: boolean;
  withDisabledText?: boolean;
  isActive?: boolean;
  iconSize?: number;
}

const Button = React.forwardRef<HTMLButtonElement, React.WithOptionalChildren<Props>>(
  (
    {
      onClick,
      className,
      icon,
      isLoading,
      title,
      type = 'button',
      loadingMessage,
      disabled,
      withDisabledText,
      style,
      variant = [],
      to,
      children,
      dataTestId,
      truncateText = false,
      iconRotation,
      isActive,
      iconSize,
      ...props
    },
    ref,
  ) => {
    const classNames = cx({
      [styles.button]: variant.includes('button'),
      [styles.small]: variant.includes('small'),
      [styles.extraSmall]: variant.includes('extraSmall'),
      [styles.medium]: variant.includes('medium'),
      [styles.big]: variant.includes('big'),
      [styles.standard]: variant.includes('standard'),
      [styles.blue]: variant.includes('blue'),
      [styles.black]: variant.includes('black'),
      [styles.white]: variant.includes('white'),
      [styles.orange]: variant.includes('orange'),
      [styles.red]: variant.includes('red'),
      [styles.transparent]: variant.includes('transparent'),
      [styles.simpleOrangeLink]: variant.includes('simpleOrangeLink'),
      [styles.simpleOrangeButton]: variant.includes('simpleOrangeButton'),
      [styles.isDisabled]: disabled || variant.includes('withDisabledText'),
      [styles.isDisabledText]: variant.includes('withDisabledText'),
      [styles.units]: variant.includes('units'),
      [styles.toolBarButton]: variant.includes('toolBarButton'),
      [styles.ghostPrimary]: variant.includes('ghostPrimary'),
      [styles.ghostSecondary]: variant.includes('ghostSecondary'),
      [styles.active]: isActive,
    });

    const buttonText = React.useMemo(() => {
      if (!isDefined(title) && !isDefined(children) && !isLoading) {
        return null;
      }

      const buttonTitle = isLoading && isDefined(loadingMessage) ? loadingMessage : title;

      return truncateText ?
          <TextEllipsis as="div">
            {buttonTitle}
            {children}
          </TextEllipsis>
        : <span>
            {buttonTitle}
            {children}
          </span>;
    }, [children, isLoading, loadingMessage, title, truncateText]);

    if (isDefined(to) && !disabled) {
      return (
        <Link onClick={onClick} to={to} className={cx(className, classNames)} style={style}>
          {children}
        </Link>
      );
    }

    return (
      <button
        type={type}
        onClick={onClick}
        ref={ref}
        className={cx(className, classNames)}
        disabled={isLoading || disabled}
        style={style}
        data-testid={dataTestId}
        {...props}
      >
        {isDefined(icon) && <Icon className={styles.icon} type={icon} rotation={iconRotation} size={iconSize} />}
        {buttonText}
        {isLoading && <Icon type={IconType.Spinner} className={styles.spinner} />}
      </button>
    );
  },
);

export default Button;
