import { Cms } from '@typings';

import { ContentSetContextProvider } from '../../../../../cms/context/ContentSetContext';
import { editorComponentSwitch } from '../../../../../cms/editorComponentSwitch';

import { EditorContentSet } from './EditorContentSet';
import { StandardContentSet } from './StandardContentSet';

interface Props {
  groupId: Maybe<string>;
  blockId: Nullable<string>;
  parameters: Cms.ContentBlockText;
}

const EditorComponent = (props: Props) => {
  return (
    <ContentSetContextProvider parameters={props.parameters}>
      <EditorContentSet {...props} />
    </ContentSetContextProvider>
  );
};

export const ContentSet = editorComponentSwitch({
  editorComponent: EditorComponent,
  standardComponent: StandardContentSet,
});
