import { Cms } from '@typings';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { getPageMetaData } from '../../../ducks';
import { useValidatedForm } from '../../../utils/hooks/useValidatedForm';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { toCheckboxOption } from '../../../utils/toCheckboxOption';
import { CheckboxGroupField, Form, FormButtons, FormGrid, SwitchField } from '../../various/Form';
import Icon, { IconType } from '../../various/Icon';
import { Button } from '../../various/NewButton';

import styles from './SectionVisibilityModal.module.scss';

interface Props {
  defaultValues: Cms.SectionVisibility;
  onCancel: () => void;
  onSubmit: (values: Cms.SectionVisibility) => void;
}

export const SectionVisibilityForm = ({ defaultValues, onCancel, onSubmit }: Props) => {
  const { t } = useTranslation(['common', 'cms', 'validation']);
  const pageMeta = useSelector(getPageMetaData);

  const pageMarketsOptions = isDefined(pageMeta) ? toCheckboxOption(pageMeta.markets, { label: 'name', value: 'id' }) : [];

  const screenOptions = [
    {
      label: (
        <span className={styles.screenOption}>
          {t('cms:devices.desktop')}
          <Icon size={16} type={IconType.DeviceDesktop} />
        </span>
      ),
      value: 'desktop',
    },
    {
      label: (
        <span className={styles.screenOption}>
          {t('cms:devices.tablet')}
          <Icon size={12} type={IconType.DeviceTablet} />
        </span>
      ),
      value: 'tablet',
    },
    {
      label: (
        <span className={styles.screenOption}>
          {t('cms:devices.mobile')}
          <Icon size={10} type={IconType.DeviceMobile} />
        </span>
      ),
      value: 'mobile',
    },
  ];

  const validationSchema: yup.ObjectSchema<Cms.SectionVisibility> = yup.object({
    isHidden: yup.bool().required(),
    marketIds: yup.array(yup.string().defined()).min(1, t('validation:min_one_market_hint')),
    screen: yup.array(yup.string<Cms.ScreenType>().defined()).min(1, t('validation:min_one_breakpoint_hint')).required(),
  });

  const formMethods = useValidatedForm({ defaultValues, validationSchema });
  const { register } = formMethods;

  return (
    <Form formMethods={formMethods} onSubmit={onSubmit}>
      <FormGrid>
        <SwitchField label={t('cms:hide_section')} orientation="auto" {...register('isHidden')} />
        {!isEmpty(pageMarketsOptions) && (
          <CheckboxGroupField name="marketIds" label={t('cms:market_visibility')} isRequired options={pageMarketsOptions} />
        )}
        <CheckboxGroupField name="screen" label={t('cms:device_visibility')} isRequired options={screenOptions} />
      </FormGrid>
      <FormButtons showDivider>
        <Button size="large" variant="ghost" color="dark" onClick={onCancel}>
          {t('common:cancel')}
        </Button>
        <Button type="submit" size="large">
          {t('common:save_changes')}
        </Button>
      </FormButtons>
    </Form>
  );
};
