import { t } from 'i18next';

import Heading from '../../various/Heading';
import { Button } from '../../various/NewButton';

import styles from './LinesheetGenerator.module.scss';

interface Props {
  isPending: boolean;
}

export const LinesheetGeneratorHeader = ({ isPending }: Props) => {
  return (
    <div className={styles.wrapper}>
      <Heading className={styles.heading} title={t('linesheets:linesheet_generator')} variant={['h2', 'black']} />
      <Button type="submit" size="large" isLoading={isPending}>
        {t('linesheets:generate_linesheet')}
      </Button>
    </div>
  );
};
