import { ConfirmationGuard } from '@typings';
import React from 'react';

interface Context {
  config: ConfirmationGuard.Config;
  setConfig: React.Dispatch<React.SetStateAction<ConfirmationGuard.Config>>;
}

const initialContext: Context = {
  config: {
    cancelText: '',
    content: '',
    okText: '',
    onOk: () => null,
    onRequestClose: () => null,
    title: '',
    type: 'confirm',
  },
  setConfig: () => null,
};

export const ConfirmationGuardContext = React.createContext<Context>(initialContext);

export const ConfirmationGuardContextProvider = ({ children }: React.WithChildren) => {
  const [config, setConfig] = React.useState(initialContext.config);

  return (
    <ConfirmationGuardContext.Provider
      value={{
        config,
        setConfig,
      }}
    >
      {children}
    </ConfirmationGuardContext.Provider>
  );
};
