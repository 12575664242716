import React from 'react';

interface Props {
  hasCursor: boolean;
  handleClose: () => void;
}

export const useCloseMenu = ({ hasCursor, handleClose }: Props) => {
  const handleClick = React.useCallback(() => {
    !hasCursor && handleClose();
  }, [hasCursor, handleClose]);

  React.useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => document.removeEventListener('click', handleClick);
  }, [handleClick]);
};
