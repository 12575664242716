import { Addresses } from '@typings';
import { pick } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { SHIPPING_ADDRESS_DEFAULT_VALUES, SHIPPING_ADDRESS_FIELDS } from '../../../constants/address';
import { getCountries } from '../../../ducks';
import { useValidatedForm } from '../../../utils/hooks/useValidatedForm';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { getStateSchema } from '../../../utils/schemas';
import { Option } from '../../various/Fields/Select';
import { AutocompleteField, Form, FormButtons, FormFieldset, FormGrid } from '../../various/Form';
import { Button } from '../../various/NewButton';

interface Props {
  selectedAddress?: Addresses.Shipping;
  isLoading: boolean;
  onCancel: () => void;
  onSubmit: (values: Addresses.ShippingAddressDTO) => void;
}

export const AddShippingCountryForm = ({ selectedAddress, isLoading, onCancel, onSubmit }: Props) => {
  const countries = useSelector(getCountries);
  const { t } = useTranslation(['common', 'validation', 'shipping']);

  const defaultValues: Addresses.ShippingAddressDTO = {
    ...SHIPPING_ADDRESS_DEFAULT_VALUES,
    ...(isDefined(selectedAddress) && pick(SHIPPING_ADDRESS_FIELDS, selectedAddress)),
    country: selectedAddress?.country || null,
    state: selectedAddress?.state || null,
  };

  const validationSchema: yup.ObjectSchema<Pick<Addresses.ShippingAddressDTO, 'country' | 'state'>> = yup.object({
    country: yup.string().required(t('validation:country_hint')),
    state: getStateSchema(countries),
  });

  const formMethods = useValidatedForm<Addresses.ShippingAddressDTO>({ defaultValues, validationSchema });
  const { setValue, watch } = formMethods;
  const selectedCountry = watch('country');

  const handleCountryChange = () => {
    setValue('state', null);
  };

  const countryOptions = React.useMemo(() => {
    return Object.values(countries).map(({ country, name }) => ({ country, name }));
  }, [countries]);

  const stateOptions = React.useMemo(() => {
    if (!isDefined(selectedCountry)) {
      return [];
    }

    return Object.values(countries[selectedCountry]?.states ?? {});
  }, [countries, selectedCountry]);

  return (
    <Form formMethods={formMethods} onSubmit={onSubmit}>
      <FormFieldset isDisabled={isLoading}>
        <FormGrid>
          <AutocompleteField name="country" label={t('common:country')} overflowContainer isRequired onChange={handleCountryChange}>
            {countryOptions.map(({ country, name }) => (
              <Option key={country} value={country}>
                {name}
              </Option>
            ))}
          </AutocompleteField>
          {!isEmpty(stateOptions) && (
            <AutocompleteField name="state" label={t('common:state')} overflowContainer isRequired>
              {stateOptions.map(({ state, name }) => (
                <Option key={state} value={state}>
                  {name}
                </Option>
              ))}
            </AutocompleteField>
          )}
        </FormGrid>
        <FormButtons showDivider>
          <Button size="large" variant="ghost" color="dark" onClick={onCancel}>
            {t('common:cancel')}
          </Button>
          <Button type="submit" size="large" isLoading={isLoading}>
            {t('common:save')}
          </Button>
        </FormButtons>
      </FormFieldset>
    </Form>
  );
};
