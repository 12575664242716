import { Form } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useController, UseFormRegisterReturn, useFormState } from 'react-hook-form';

import { isDefined } from '../../../../utils/is';
import { Switch } from '../../Fields/Switch';

import { FieldWrapper } from './FieldParts';
import styles from './FormField.module.scss';

type SwitchProps = Omit<Partial<UseFormRegisterReturn>, 'ref'>;

interface Props extends SwitchProps, Form.FieldProps {
  name: string;
}

export const SwitchField = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { label, name, isRequired, description, helpMessage, orientation, ...rest } = props;

  const errorId = React.useId();
  const describedById = React.useId();

  const {
    field: { value },
  } = useController({ name });
  const { errors } = useFormState({ name });

  const errorMessage = errors[name]?.message as string | undefined;

  return (
    <FieldWrapper
      description={description}
      describedById={describedById}
      helpMessage={helpMessage}
      errorId={errorId}
      errorMessage={errorMessage}
      isRequired={isRequired}
      label={label}
      orientation={orientation}
    >
      <span className={cx(styles.switch, { [styles.horizontal]: orientation === 'horizontal' })}>
        <Switch
          ref={ref}
          {...rest}
          describedById={isDefined(description) ? describedById : undefined}
          errorMessageId={isDefined(errorMessage) ? errorId : undefined}
          checked={value}
          name={name}
          size="large"
        />
      </span>
    </FieldWrapper>
  );
});
