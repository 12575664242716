import cx from 'classnames';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getMarkets } from '../../../ducks';
import { fetchHomepageRequest, getHomepageSlug, getIsHomepageLoading, setHomePageRequest } from '../../../ducks/cms';
import { getPagesMapBySlug, getUniqMarketsNames, getUniqSlugs, isPublishedPage, isPublishedWithChanges } from '../../../logic/pages';
import { usePages } from '../../../services/hooks/pages/usePages';
import { isEmpty } from '../../../utils/isEmpty';
import { Alert } from '../../various/Alert';
import { Option } from '../../various/Fields/Select';
import { AutocompleteField, FieldWrapper, Form, FormGrid } from '../../various/Form';
import Icon, { IconColor, IconType } from '../../various/Icon';
import { SectionFormHeading } from '../../various/SectionHeading';
import { TextEllipsis } from '../../various/TextEllipsis';
import { PreviewListItem } from '../PreviewListItem/PreviewListItem';

import styles from './HomePageSection.module.scss';

interface FormData {
  slug: string;
}

export const HomePageSection = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'settings', 'products']);

  const isHomePageLoading = useSelector(getIsHomepageLoading);
  const homepageSlug = useSelector(getHomepageSlug);

  const [isEditing, setIsEditing] = React.useState(false);

  const { data: pagesList = [] } = usePages({ refetchOnMount: false, search: '' });
  const publishedPages = React.useMemo(() => pagesList.filter(page => isPublishedPage(page) || isPublishedWithChanges(page)), [pagesList]);
  const allMarkets = useSelector(getMarkets);

  const formMethods = useForm<FormData>();
  const { watch, setValue } = formMethods;

  const selectedSlug = watch('slug');

  const slugs = React.useMemo(() => getUniqSlugs(publishedPages), [publishedPages]);
  const selectedPages = getPagesMapBySlug(selectedSlug)(publishedPages);
  const selectedPagesIds = isEmpty(selectedSlug) ? [] : selectedPages.map(page => page.id);

  React.useEffect(() => {
    dispatch(fetchHomepageRequest());
  }, [dispatch]);

  React.useEffect(() => {
    setValue('slug', homepageSlug ?? '');
  }, [homepageSlug, setValue]);

  const defaultPageMarkets = React.useMemo(() => {
    if (isEmpty(selectedSlug)) {
      return allMarkets;
    }

    const selectedPagesMarkets = getUniqMarketsNames(selectedPages);

    return allMarkets.filter(market => !selectedPagesMarkets.includes(market.name));
  }, [selectedPages, allMarkets, selectedSlug]);

  const handleCancel = React.useCallback(() => {
    setValue('slug', homepageSlug ?? '');
    setIsEditing(false);
  }, [homepageSlug]);

  const handleSubmit = React.useCallback(
    ({ slug }: FormData) => {
      setIsEditing(false);
      dispatch(setHomePageRequest(isEmpty(slug) ? null : slug));
    },
    [dispatch],
  );

  const handleEnableEditing = React.useCallback(() => setIsEditing(!isEditing), [isEditing]);

  const handleSlugChange = React.useCallback((slug: string) => setValue('slug', slug ?? ''), [setValue]);

  return (
    <section className={cx({ [styles.disabledSection]: !isEditing })}>
      <Form formMethods={formMethods} onSubmit={handleSubmit}>
        <SectionFormHeading
          heading={t('common:home_page')}
          isLoading={isHomePageLoading}
          isDisabled={isHomePageLoading}
          isEditing={isEditing}
          onCancel={handleCancel}
          onEdit={handleEnableEditing}
        />
        <p className={styles.description}>{t('settings:home_page_help')}</p>
        <FormGrid rowGap="large">
          <AutocompleteField
            isDisabled={!isEditing}
            size="large"
            placeholder={t('settings:select_uri')}
            onChange={handleSlugChange}
            orientation="horizontal"
            name="slug"
            description={t('settings:page_slug_description')}
            label={t('settings:home_page_uri')}
            allowClear
          >
            {slugs.map(slug => (
              <Option key={slug} value={slug}>{`/${slug}`}</Option>
            ))}
          </AutocompleteField>

          <FieldWrapper as="span" label={t('settings:published_pages')} orientation="horizontal" description="">
            {isEmpty(pagesList) && <div className={styles.wrapper} />}
            {!isEmpty(pagesList) &&
              (isEmpty(selectedPagesIds) ?
                <Alert message={t('settings:no_published_page')} type="error" />
              : <div className={styles.wrapper}>
                  {selectedPages.map(page => (
                    <PreviewListItem
                      key={`preview-${page.id}`}
                      name={page.name}
                      slug={page.slug}
                      id={page.id}
                      markets={page.markets}
                      isDisabled={!isEditing}
                    />
                  ))}
                </div>)}
          </FieldWrapper>

          <FieldWrapper
            as="span"
            label={t('settings:default_home_page')}
            orientation="horizontal"
            description=""
            fieldClassName={styles.lastField}
          >
            <div className={styles.productsPageInfo}>
              {!isEmpty(pagesList) && (
                <>
                  <span className={styles.title}>
                    <Icon color={IconColor.DarkGray} type={IconType.Products} />
                    {t('products:all_products')}
                  </span>

                  <TextEllipsis as="div" className={styles.marketInfo}>
                    {`${t('common:market')}: ${defaultPageMarkets.map(market => market.name).join(', ')}`}
                  </TextEllipsis>
                </>
              )}
            </div>
          </FieldWrapper>
        </FormGrid>
      </Form>
    </section>
  );
};
