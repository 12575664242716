import { Addresses, Buyer, CreateAccountDTO } from '@typings';

import { unAuthenticatedPaths } from '../paths';
import { showroomApi } from '../services/api';

export const fetchAccountDetails = async (accountId: string) => {
  return showroomApi.QUERY_GET('/accounts/:accountId')({ pathParams: { accountId } });
};

export const addShippingCountry = async (data: { country: string; state: Nullable<string> }) => {
  return showroomApi.QUERY_PUT('/account/shipping-address')({ data });
};

export const fetchShippingAddresses = async (accountId: string) => {
  return showroomApi.QUERY_GET('/accounts/:accountId/shipping-addresses')({
    pathParams: { accountId },
  });
};

export const updateShippingAddress = async (accountId: string, shippingAddressId: string, shippingAddress: Requests.ShippingAddress) => {
  return showroomApi.QUERY_PUT('/accounts/:accountId/shipping-addresses/:shippingAddressId')({
    data: shippingAddress,
    pathParams: { accountId, shippingAddressId },
  });
};

export const deleteShippingAddress = async (accountId: string, shippingAddressId: string) => {
  return showroomApi.QUERY_DELETE('/accounts/:accountId/shipping-addresses/:shippingAddressId')({
    pathParams: { accountId, shippingAddressId },
  });
};

export const createShippingAddress = async (accountId: string, shippingAddress: Addresses.ShippingAddressDTO) => {
  return showroomApi.QUERY_POST('/accounts/:accountId/shipping-addresses')({
    data: shippingAddress,
    pathParams: { accountId },
  });
};

export const updateInvoicingAddress = async (accountId: string, invoicingAddress: Addresses.BillingDTO) => {
  return showroomApi.QUERY_PUT('/accounts/:accountId/invoice-address')({
    data: invoicingAddress,
    pathParams: { accountId },
  });
};

export const deleteBuyer = async (accountId: string, buyerId: string) => {
  return showroomApi.QUERY_DELETE('/accounts/:accountId/buyers/:buyerId')({
    pathParams: { accountId, buyerId },
  });
};

export const updateBuyer = async (accountId: string, buyerId: string, buyer: Buyer.BuyerDTO) => {
  return showroomApi.QUERY_PUT('/accounts/:accountId/buyers/:buyerId')({
    data: buyer,
    pathParams: { accountId, buyerId },
  });
};

export const createBuyer = async (accountId: string, buyer: Buyer.BuyerDTO) => {
  return showroomApi.QUERY_POST('/accounts/:accountId/buyers')({
    data: {
      ...buyer,
      linkUri: unAuthenticatedPaths.WELCOME,
    },
    pathParams: { accountId },
  });
};

export const createAccount = async (data: CreateAccountDTO) => showroomApi.QUERY_POST('/accounts')({ data });
