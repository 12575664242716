export const MAX_PERCENT = 100;

export const INPUT_MAX_LENGTH = 200;
export const INPUT_URL_MAX_LENGTH = 2048;
export const TEXTAREA_MAX_LENGTH = 500;

export const ALLOWED_TAGS = [
  'b',
  'br',
  'strong',
  'em',
  'u',
  'strike',
  'ul',
  'ol',
  'li',
  'h1',
  'h2',
  'h3',
  'h4',
  'a',
  'img',
  'sup',
  'p',
  'kbd',
];

export const ALLOWED_ATTRS = ['src', 'href', 'target'];

export const DEFAULT_ITEMS_COUNT_PER_REQUEST = 50;
export const DEFAULT_PRODUCTS_PER_REQUEST = 24;
