import { pipe } from 'ramda';

import { UnitsDistribution } from '../../../../typings';
import { isDefined } from '../../utils/is';
import { isEmpty } from '../../utils/isEmpty';
import { sumPresetsResults } from '../presets';

import { calculateDistributionBase } from './unitsDistribution';

const applyStockCorrection = (parameters: UnitsDistribution.SingleDistributionParameters) => {
  const { distributionQuantities, availableQuantities } = parameters;

  return distributionQuantities.reduce(
    (acc, item) => {
      const stock = availableQuantities[item.sizeId];

      if (!isDefined(stock)) {
        return acc;
      }

      const quantity = Math.min(item.quantity, stock);

      return {
        availableQuantitiesLeft: {
          ...acc.availableQuantitiesLeft,
          [item.sizeId]: stock - quantity,
        },
        distributionResult: [...acc.distributionResult, { ...item, quantity }],
      };
    },
    {
      availableQuantitiesLeft: availableQuantities,
      distributionResult: [],
    },
  );
};

export const distributeToMaximumAvailable = ({ availableQuantities, distributions }: UnitsDistribution.DistributionSetParameters) => {
  const distributionResults = distributions.reduce((acc, { distributionQuantities, requestedValue }, index) => {
    const { availableQuantitiesLeft } = acc[index - 1] ?? { availableQuantitiesLeft: [] };
    const calculateDistribution = pipe(calculateDistributionBase, applyStockCorrection);

    return [
      ...acc,
      calculateDistribution({
        availableQuantities: !isEmpty(availableQuantitiesLeft) ? availableQuantitiesLeft : availableQuantities,
        distributionQuantities,
        requestedValue,
      }),
    ];
  }, []);

  return sumPresetsResults(distributionResults.map(({ distributionResult }) => distributionResult));
};
