import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;

export const Arrows = ({ width = ORIGINAL_WIDTH, color = 'currentColor' }: IconComponentProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox="0 0 20 20" fill={color}>
      <path d="M6.149 6.868A.511.511 0 0 0 6.5 7h7a.512.512 0 0 0 .352-.132.406.406 0 0 0 .148-.312c0-.12-.05-.225-.148-.313l-3.5-3.111A.512.512 0 0 0 10 3a.512.512 0 0 0-.352.132l-3.5 3.111A.406.406 0 0 0 6 6.556c0 .12.05.224.149.312ZM13.851 13.132A.511.511 0 0 0 13.5 13h-7a.512.512 0 0 0-.352.132.406.406 0 0 0-.148.313c0 .12.05.224.148.312l3.5 3.111c.1.088.217.132.352.132a.511.511 0 0 0 .351-.132l3.5-3.111a.406.406 0 0 0 .149-.313c0-.12-.05-.224-.149-.312Z" />
    </svg>
  );
};
