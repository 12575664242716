import { Cms } from '@typings';

import { ModalSize } from '../../various/ModalElements';
import { ModalShell } from '../../various/ModalShell';
import { LinkForm } from '../LinkForm';

interface Props {
  isOpen: boolean;
  title: string;
  onClose: () => void;
  onSubmit: (settings: Cms.LinkSettings) => void;
  defaultValues: {
    newTab: boolean;
    url: string;
  };
}

export const TextLinkModal = ({ isOpen, onClose, onSubmit, defaultValues, title }: Props) => {
  return (
    <ModalShell size={ModalSize.Medium} title={title} isOpen={isOpen} onClose={onClose} shouldReturnFocusAfterClose={false}>
      <LinkForm defaultValues={defaultValues} onCancel={onClose} onSubmit={onSubmit} />
    </ModalShell>
  );
};
