import { ActionHandler, Cms, Id } from '@typings';
import { createReducer } from 'typesafe-actions';

import { getCanReplaceProducts } from '../../../logic/cms/changeGroup';
import { update } from '../../../utils/update';
import { resetCms } from '../general';
import { automaticGroupChangeSuccess, manualLayoutEditingDiscard, manualLayoutEditingRequest, manualLayoutEditingSuccess } from '../groups';
import { groupCreated } from '../sections';

import {
  closeButtonStyleEditor,
  closeTextStyleEditor,
  hideAllToolbars,
  hideToolbar,
  openButtonStyleEditor,
  openTextStyleEditor,
  resetCurentBlock,
  setButtonStyleEditorStyles,
  setCurrentBlock,
  setCurrentCustomTemplateId,
  setCurrentGroup,
  setCurrentItem,
  setCurrentSection,
  setIsHotspotEditing,
  setIsHoverTabOpen,
  setIsRepositioningImage,
  setTextStyleEditorStyles,
  showToolbar,
} from './actions';

interface State {
  buttonStyleEditor: {
    isVisible: boolean;
    elementStyles: Nullable<Cms.ButtonStyle>;
    mode: Cms.CustomStyleEditorMode;
  };
  isHoverTabOpen: boolean;
  currentItem: Cms.EditableItem;
  isRepositioningImage: boolean;
  isHotspotEditing: boolean;
  toolbarVisibility: Record<string, boolean>;
  currentBlockId: Nullable<Id>;
  currentGroupId: Nullable<Id>;
  currentSectionId: Nullable<Id>;
  currentCustomTemplateId: Nullable<Id>;
  manualLayoutEditor: {
    blockTypeToSelect: Nullable<'product' | 'content'>;
    isEnabled: boolean;
    models: Cms.BlockModel[];
  };
  textStyleEditor: {
    isVisible: boolean;
    elementStyles: Nullable<Cms.TextStyle>;
    mode: Cms.CustomStyleEditorMode;
  };
}

const initialState: State = {
  buttonStyleEditor: {
    elementStyles: null,
    isVisible: false,
    mode: 'create',
  },
  currentBlockId: null,
  currentCustomTemplateId: null,
  currentGroupId: null,
  currentItem: { element: null },
  currentSectionId: null,
  isHotspotEditing: false,
  isHoverTabOpen: false,
  isRepositioningImage: false,
  manualLayoutEditor: {
    blockTypeToSelect: null,
    isEnabled: false,
    models: [],
  },
  textStyleEditor: {
    elementStyles: null,
    isVisible: false,
    mode: 'create',
  },
  toolbarVisibility: {},
};

const handleHideToolbar: ActionHandler<State, typeof hideToolbar> = (state, action) =>
  update(state, { toolbarVisibility: { [action.payload]: false } });

const handleHideAllToolbars: ActionHandler<State, typeof hideAllToolbars> = state =>
  update(state, { currentItem: initialState.currentItem, toolbarVisibility: {} });

const handleAutomaticGroupChangeSuccess: ActionHandler<State, typeof automaticGroupChangeSuccess> = state =>
  update(state, { currentGroupId: null, currentSectionId: null });

const handleGroupCreated: ActionHandler<State, typeof groupCreated> = state =>
  update(state, { currentGroupId: null, currentSectionId: null });

const handleResetCurentBlock: ActionHandler<State, typeof resetCurentBlock> = state =>
  update(state, { currentBlockId: initialState.currentBlockId, currentItem: initialState.currentItem });

const handleShowToolbar: ActionHandler<State, typeof showToolbar> = (state, action) =>
  update(state, { toolbarVisibility: { [action.payload]: true } });

const handleSetCurrentItem: ActionHandler<State, typeof setCurrentItem> = (state, action) => update(state, { currentItem: action.payload });

const handleSetCurrentBlock: ActionHandler<State, typeof setCurrentBlock> = (state, action) =>
  update(state, { currentBlockId: action.payload });

const handleSetCurrentGroup: ActionHandler<State, typeof setCurrentGroup> = (state, action) =>
  update(state, { currentGroupId: action.payload });

const handleSetCurrentSection: ActionHandler<State, typeof setCurrentSection> = (state, action) =>
  update(state, { currentSectionId: action.payload });

const handleSetIsRepositioningImage: ActionHandler<State, typeof setIsRepositioningImage> = (state, action) =>
  update(state, { isRepositioningImage: action.payload });

const handleSetIsHotspotEditing: ActionHandler<State, typeof setIsHotspotEditing> = (state, action) =>
  update(state, { isHotspotEditing: action.payload });

const handleManualLayoutEditingRequest: ActionHandler<State, typeof manualLayoutEditingRequest> = (state, action) => {
  const { models, blocks } = action.payload;

  const areProductsToSelect = !getCanReplaceProducts(blocks, models);
  const blockTypeToSelect = areProductsToSelect ? 'product' : 'content';

  return update(state, {
    manualLayoutEditor: {
      blockTypeToSelect,
      isEnabled: true,
      models,
    },
  });
};

const handleResetManualLayoutEditor: ActionHandler<State, typeof manualLayoutEditingDiscard | typeof manualLayoutEditingSuccess> = state =>
  update(state, {
    currentGroupId: null,
    currentSectionId: null,
    manualLayoutEditor: {
      blockTypeToSelect: null,
      isEnabled: false,
      models: [],
    },
  });

const handleOpenTextStyleEditor: ActionHandler<State, typeof openTextStyleEditor> = (state, { payload: { elementStyles, mode } }) =>
  update(state, { textStyleEditor: { elementStyles, isVisible: true, mode } });

const handleOpenButtonStyleEditor: ActionHandler<State, typeof openButtonStyleEditor> = (state, { payload: { elementStyles, mode } }) =>
  update(state, { buttonStyleEditor: { elementStyles, isVisible: true, mode } });

const handleSetIsHoverTabOpen: ActionHandler<State, typeof setIsHoverTabOpen> = (state, { payload }) =>
  update(state, { isHoverTabOpen: payload });

const handleCloseTextStyleEditor: ActionHandler<State, typeof closeTextStyleEditor> = state =>
  update(state, {
    currentBlockId: initialState.currentBlockId,
    currentItem: initialState.currentItem,
    textStyleEditor: initialState.textStyleEditor,
  });

const handleCloseButtonStyleEditor: ActionHandler<State, typeof closeButtonStyleEditor> = state =>
  update(state, {
    buttonStyleEditor: initialState.buttonStyleEditor,
    currentBlockId: initialState.currentBlockId,
    currentItem: initialState.currentItem,
  });

const handleSetTextStyleEditorStyles: ActionHandler<State, typeof setTextStyleEditorStyles> = (state, { payload }) =>
  update(state, { textStyleEditor: update(state.textStyleEditor, { elementStyles: payload }) });

const handleSetButtonStyleEditorStyles: ActionHandler<State, typeof setButtonStyleEditorStyles> = (state, { payload }) =>
  update(state, { buttonStyleEditor: update(state.buttonStyleEditor, { elementStyles: payload }) });

const handleResetCmsUI: ActionHandler<State, typeof resetCms> = () => initialState;

const handleSetCurrentCustomTemplateId: ActionHandler<State, typeof setCurrentCustomTemplateId> = (state, { payload }) =>
  update(state, { currentCustomTemplateId: payload });

export default createReducer<State, AppAction>(initialState)
  .handleAction(hideToolbar, handleHideToolbar)
  .handleAction(hideAllToolbars, handleHideAllToolbars)
  .handleAction(showToolbar, handleShowToolbar)
  .handleAction(setCurrentItem, handleSetCurrentItem)
  .handleAction(setCurrentBlock, handleSetCurrentBlock)
  .handleAction(setCurrentGroup, handleSetCurrentGroup)
  .handleAction(setCurrentSection, handleSetCurrentSection)
  .handleAction(setIsRepositioningImage, handleSetIsRepositioningImage)
  .handleAction(setIsHotspotEditing, handleSetIsHotspotEditing)
  .handleAction(resetCurentBlock, handleResetCurentBlock)
  .handleAction(openTextStyleEditor, handleOpenTextStyleEditor)
  .handleAction(openButtonStyleEditor, handleOpenButtonStyleEditor)
  .handleAction(setIsHoverTabOpen, handleSetIsHoverTabOpen)
  .handleAction(closeTextStyleEditor, handleCloseTextStyleEditor)
  .handleAction(closeButtonStyleEditor, handleCloseButtonStyleEditor)
  .handleAction(setTextStyleEditorStyles, handleSetTextStyleEditorStyles)
  .handleAction(setButtonStyleEditorStyles, handleSetButtonStyleEditorStyles)
  .handleAction(resetCms, handleResetCmsUI)
  .handleAction(automaticGroupChangeSuccess, handleAutomaticGroupChangeSuccess)
  .handleAction(manualLayoutEditingRequest, handleManualLayoutEditingRequest)
  .handleAction(manualLayoutEditingDiscard, handleResetManualLayoutEditor)
  .handleAction(manualLayoutEditingSuccess, handleResetManualLayoutEditor)
  .handleAction(setCurrentCustomTemplateId, handleSetCurrentCustomTemplateId)
  .handleAction(groupCreated, handleGroupCreated);
