import { editorComponentSwitch } from '../../cms/editorComponentSwitch';

import { BlockWarnings } from './BlockWarnings';
import { createProductBlock } from './ProductBlock';
import { ProductBlockTools } from './ProductBlockTools';

export const ProductBlock = editorComponentSwitch({
  editorComponent: createProductBlock({
    tools: ProductBlockTools,
    warnings: BlockWarnings,
  }),
  standardComponent: createProductBlock(),
});
