import cx from 'classnames';

import Icon, { IconType } from '../../Icon';

import styles from './LoadMore.module.scss';

interface Props {
  isLoading: boolean;
  title: string;
}

const LoadMore = ({ isLoading, title }: Props) => {
  return (
    <div className={styles.loadMore}>
      <div className={cx(styles.message, { [styles.visible]: isLoading })}>
        <Icon type={IconType.Spinner} className={styles.spinner} /> <br /> {title}
      </div>
    </div>
  );
};

export default LoadMore;
