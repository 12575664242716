import { SliderProps } from '@typings';

import { Slider } from '../../../various/Slider';

import styles from './TextStyleSlider.module.scss';

interface Props extends SliderProps {
  title: string;
  dataTestId?: string;
}

export const TextStyleSlider = ({ title, dataTestId, ...sliderProps }: Props) => {
  const { value, onChange, min, max, disabled, step } = sliderProps;

  return (
    <div className={styles.textStyleSlider} data-testid={dataTestId}>
      <div className={styles.header}>
        <span>{title}</span>
        <span>{value}</span>
      </div>
      <Slider className={styles.slider} min={min} max={max} disabled={disabled} step={step} onChange={onChange} value={value} />
    </div>
  );
};
