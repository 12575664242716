import { Id } from '@typings';
import React from 'react';
import { useSelector } from 'react-redux';

import { EditorLanguageContext } from '../../../components/cms/context/EditorLanguageContext';
import { SelectorsContext } from '../../../components/cms/context/SelectorsContext';
import { getCmsBlockLocalizationById } from '../../../ducks/cms/localizations';
import { applyBlockLocalization } from '../../../logic/cms/applyBlockLocalization';

interface Props {
  blockId: Nullable<Id>;
}

export const useLocalizedBlock = ({ blockId }: Props) => {
  const { getBlockById } = React.useContext(SelectorsContext);
  const { editorLanguage, defaultLanguage } = React.useContext(EditorLanguageContext);

  const defaultBlockData = useSelector(getBlockById(blockId));
  const defaultLanguageLocalization = useSelector(getCmsBlockLocalizationById(defaultLanguage, blockId));
  const editorLanguageLocalization = useSelector(getCmsBlockLocalizationById(editorLanguage, blockId));

  return React.useMemo(() => {
    const localizedDefaultBlockData = applyBlockLocalization(defaultBlockData, defaultLanguageLocalization);

    if (editorLanguage === defaultLanguage) {
      return localizedDefaultBlockData;
    }

    return applyBlockLocalization(localizedDefaultBlockData, editorLanguageLocalization);
  }, [defaultBlockData, defaultLanguage, defaultLanguageLocalization, editorLanguage, editorLanguageLocalization]);
};
