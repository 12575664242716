import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getOrderDetails } from '../../../ducks/order';
import { Summaries } from '../partials/summaries';

import { Actions } from './Actions';
import styles from './CallToAction.module.scss';

interface Props {
  callToActionVersion: 'checkout' | 'products' | 'units' | 'page';
  checkoutButtonText?: string | JSX.Element;
  isSubmitting?: boolean;
  isDisabled?: boolean;
}

export const CallToAction = ({ callToActionVersion, ...callToActionProps }: Props) => {
  const { t } = useTranslation(['common']);
  const orderDetails = useSelector(getOrderDetails);
  const { order: orderId, products } = orderDetails.order;
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleOpen = React.useCallback(() => setIsOpen(!isOpen), [isOpen]);

  const className = cx(styles.callToAction, {
    [styles.open]: isOpen,
  });

  return (
    <div className={className}>
      <Summaries products={products} isOpen={isOpen} />
      <Actions
        isPageVersion={callToActionVersion === 'page'}
        isProductsVersion={callToActionVersion === 'products'}
        isUnitsVersion={callToActionVersion === 'units'}
        isCheckoutVersion={callToActionVersion === 'checkout'}
        orderId={orderId}
        isOpen={isOpen}
        {...callToActionProps}
      />
      <button
        aria-label={t('common:expand_menu')}
        aria-expanded={isOpen}
        onClick={toggleOpen}
        className={cx(styles.expand, styles.ctaButton)}
      />
    </div>
  );
};
