import { useTranslation } from 'react-i18next';

import { isDefined } from '../../../../../utils/is';
import Button from '../../../../various/Button';
import toolbarStyles from '../../Toolbar.module.scss';

interface Props {
  acceptAction: () => void;
  discardAction?: () => void;
  testId?: string;
}

export const AcceptDiscard = ({ acceptAction, discardAction, testId = 'closeToolbar' }: Props) => {
  const { t } = useTranslation(['common']);

  return (
    <div className={toolbarStyles.buttonSet}>
      {isDefined(discardAction) && <Button title={t('common:cancel')} onClick={discardAction} variant={['button', 'ghostSecondary']} />}
      <Button title={t('common:apply')} onClick={acceptAction} variant={['button', 'ghostPrimary']} dataTestId={testId} />
    </div>
  );
};
