import { Cms } from '@typings';
import React from 'react';
import { useSelector } from 'react-redux';

import { getIsLoadingPage } from '../../../ducks';
import { SelectorsContext } from '../../cms/context/SelectorsContext';
import { SkeletonLayout } from '../../various/SkeletonLayout';
import { PageSkeleton } from '../../various/SkeletonLayout/layouts/PageSkeleton';
import { Wrapper, WrapperSize } from '../../various/Wrapper';
import { Section } from '../section';

import styles from './page.module.scss';

export const PageContent = () => {
  const { getSections } = React.useContext(SelectorsContext);
  const sections = useSelector(getSections);
  const isLoadingPage = useSelector(getIsLoadingPage);

  return (
    <Wrapper size={WrapperSize.XLARGE} className={styles.page}>
      <SkeletonLayout isLoading={isLoadingPage} skeleton={<PageSkeleton />}>
        {sections.map((section: Cms.Section) => (
          <Section sectionData={section} key={section.id} />
        ))}
      </SkeletonLayout>
    </Wrapper>
  );
};
