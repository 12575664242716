import cx from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { getCmsSections, getIsCustomStylesLoading, getIsLoadingPageData } from '../../../ducks';
import { isEmpty } from '../../../utils/isEmpty';
import DefaultLoader from '../../various/loaders/DefaultLoader';
import { Wrapper, WrapperSize } from '../../various/Wrapper';
import { BackgroundToolbar } from '../BackgroundToolbar';
import { ButtonToolbar } from '../ButtonToolbar';
import { EditorLanguageContext } from '../context/EditorLanguageContext';
import { EditorUIContext } from '../context/EditorUIContext';
import { ButtonStyleEditor, TextStyleEditor } from '../customStyleEditors';
import { OverlayToolbar } from '../OverlayToolbar';
import { SectionButtons } from '../SectionButtons';
import { TextToolbar } from '../TextToolbar';
import { TranslationsToolbar } from '../translationsToolbar/TranslationsToolbar';

import { EditorSection } from './EditorSection';
import styles from './SectionsWrapper.module.scss';

import '../Cms.scss';

export const SectionsWrapper = () => {
  const { isDefaultLanguage } = React.useContext(EditorLanguageContext);
  const isLoadingPageMeta = useSelector(getIsLoadingPageData);
  const isCustomStylesLoading = useSelector(getIsCustomStylesLoading);
  const sections = useSelector(getCmsSections);
  const { responsiveClassNames } = React.useContext(EditorUIContext);

  const classNames = cx(styles.sectionsWrapper, responsiveClassNames);

  return (
    <Wrapper size={WrapperSize.FULL} className={styles.mainWrapper}>
      {isLoadingPageMeta || isCustomStylesLoading ?
        <DefaultLoader />
      : <>
          <TranslationsToolbar />
          <TextToolbar />
          <ButtonToolbar />
          <OverlayToolbar />
          <BackgroundToolbar />
          <TextStyleEditor />
          <ButtonStyleEditor />
          <div data-testid="sectionsWrapper" className={classNames}>
            {sections.map((section, index) => (
              <EditorSection key={section.id} index={index} section={section} />
            ))}
            {isDefaultLanguage && isEmpty(sections) && <SectionButtons />}
          </div>
        </>
      }
    </Wrapper>
  );
};
