import cx from 'classnames';

import styles from './ReceiptRow.module.scss';

interface Props {
  delwinId?: string;
  className?: string;
}

export const ReceiptRow = ({ delwinId, children, className }: React.WithChildren<Props>) => {
  return (
    <div className={cx(styles.row, className)} data-delwin-id={delwinId}>
      {children}
    </div>
  );
};
