import cx from 'classnames';

import styles from './ProductLineContainer.module.scss';

interface Props {
  children: React.ReactNode;
  isLoading?: boolean;
}

export const ProductLineContainer = ({ children, isLoading = false }: Props) => {
  return (
    <div className={cx(styles.lineContainer, { [styles.loading]: isLoading })} {...(isLoading ? { inert: '' } : undefined)}>
      {children}
    </div>
  );
};
