import { ActionHandler } from '@typings';
import { createReducer } from 'typesafe-actions';

import { update } from '../../utils/update';

import { requestInvoicePayment, requestInvoicePaymentFailure, requestOrderPayment, requestOrderPaymentFailure } from './actions';

export interface PaymentsReducer {
  isRequestingPayment: boolean;
}

const initialState: PaymentsReducer = {
  isRequestingPayment: false,
};

type RequestPaymentAction = typeof requestInvoicePayment | typeof requestOrderPayment;
type RequestPaymentFailureAction = typeof requestInvoicePaymentFailure | typeof requestOrderPaymentFailure;

const handleRequestPayment: ActionHandler<PaymentsReducer, RequestPaymentAction> = state =>
  update(state, {
    isRequestingPayment: true,
  });

const handleRequestPaymentFailure: ActionHandler<PaymentsReducer, RequestPaymentFailureAction> = state =>
  update(state, {
    isRequestingPayment: false,
  });

export default createReducer<PaymentsReducer, AppAction>(initialState)
  .handleAction(requestInvoicePayment, handleRequestPayment)
  .handleAction(requestInvoicePaymentFailure, handleRequestPaymentFailure)
  .handleAction(requestOrderPayment, handleRequestPayment)
  .handleAction(requestOrderPaymentFailure, handleRequestPaymentFailure);
