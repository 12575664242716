import { Checkboxes } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { pushEvent } from '../../../../../../ducks';
import { DelwinFilterTrackingEvent } from '../../../../../../utils/analytics/events';
import { useIsTouchScreen } from '../../../../../../utils/hooks/useIsTouchScreen';
import { isDefined } from '../../../../../../utils/is';
import Button from '../../../../../various/Button';
import { DelwinNameAndDate } from '../../../../../various/DelwinNameAndDate';
import { Checkbox } from '../../../../../various/Fields/Checkbox';
import { IconType } from '../../../../../various/Icon';
import { SeparatorItem } from '../../../../../various/SeparatorItem';
import { useDeliveryWindowFilterContext } from '../DeliveryWindowFilterContext';
import styles from '../FiltersDeliveryWindows.module.scss';

interface DeliveryWindowFilterGroupTouchPanelProps {
  optionsState: Checkboxes.OptionsState;
  toggleOptionState: (changedValue: string) => void;
}

export const DeliveryWindowFilterGroupTouchPanel = ({ optionsState, toggleOptionState }: DeliveryWindowFilterGroupTouchPanelProps) => {
  const dispatch = useDispatch();
  const { activeGroup, activeGroupClosing, closeGroup, isTopLevelOptionsOpened } = useDeliveryWindowFilterContext();
  const isTouchScreen = useIsTouchScreen();
  const shouldHideGroupOptions = !isDefined(activeGroup) && !isDefined(activeGroupClosing);
  const { t } = useTranslation(['common']);
  const groupId = activeGroup?.groupId ?? activeGroupClosing?.groupId;

  const activeGroupEntity = activeGroup ?? activeGroupClosing;
  const groupOptionState = isDefined(activeGroupEntity) ? optionsState[activeGroupEntity.groupId] : '';

  const groupName = isDefined(activeGroupEntity) ? activeGroupEntity.name : '';

  React.useEffect(() => {
    if (!isTouchScreen && isDefined(activeGroup)) {
      closeGroup();
    }
  }, [activeGroup, closeGroup, isTouchScreen]);

  const handleGroupChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      toggleOptionState(event.target.value);
      dispatch(pushEvent({ event: DelwinFilterTrackingEvent.GROUP_FILTER_USED }));
    },
    [dispatch, toggleOptionState],
  );

  const handleDeliveryWindowChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      toggleOptionState(event.target.value);
      dispatch(pushEvent({ event: DelwinFilterTrackingEvent.DELWIN_FILTER_USED }));
    },
    [dispatch, toggleOptionState],
  );

  if (!isTouchScreen) {
    return null;
  }

  return (
    <ul className={cx(styles.optionsList, styles.group, { [styles.hidden]: shouldHideGroupOptions })}>
      <Button
        icon={IconType.ArrowLeft}
        iconSize={12}
        variant={['button', 'extraSmall']}
        onClick={closeGroup}
        className={styles.backButton}
        disabled={isTopLevelOptionsOpened}
      >
        <span>{t('common:back')}</span>
      </Button>
      <li className={styles.listItem}>
        <Checkbox
          onChange={handleGroupChange}
          value={groupId}
          checked={groupOptionState === 'checked'}
          indeterminate={groupOptionState === 'indeterminate'}
          className={styles.checkboxWrapper}
        >
          <SeparatorItem title={groupName} classNames={styles.minHeight} />
        </Checkbox>
      </li>
      {isDefined(activeGroupEntity) &&
        activeGroupEntity.items.map(deliveryWindow => (
          <li className={styles.listItem} key={`touch-panel-delivery-window-${deliveryWindow.deliveryWindow}`}>
            <Checkbox
              onChange={handleDeliveryWindowChange}
              value={deliveryWindow.deliveryWindow}
              checked={optionsState[deliveryWindow.deliveryWindow] === 'checked'}
              indeterminate={optionsState[deliveryWindow.deliveryWindow] === 'indeterminate'}
              className={styles.checkboxWrapper}
            >
              <DelwinNameAndDate deliveryWindow={deliveryWindow} shouldStretch />
            </Checkbox>
          </li>
        ))}
    </ul>
  );
};
