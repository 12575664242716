import { Currency, Preset, Product } from '@typings';
import { useSelector } from 'react-redux';

import { getOrderCurrency } from '../../../../../../../ducks';

interface Props {
  distributions: Preset[];
  variant: Product.Standard;
}

export interface PresetsListData {
  id: string;
  preset: Preset;
  variant: Product.Standard;
  currency: Currency;
}

export const usePresetsListData = ({ distributions, variant }: Props) => {
  const currency = useSelector(getOrderCurrency);

  const presetsListData: PresetsListData[] = distributions.map(preset => {
    return {
      currency,
      id: preset.id,
      preset,
      variant,
    };
  });

  return { presetsListData };
};
