import { skipToken, useQuery } from '@tanstack/react-query';
import { DeliveryWindow } from '@typings';

import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { fetchCollections } from '../../collectionsRepository';

interface Props {
  buyerId: Maybe<string>;
  deliveryWindowIds: DeliveryWindow.Id[];
}

export const useCollections = ({ buyerId, deliveryWindowIds }: Props) => {
  return useQuery({
    queryFn: isDefined(buyerId) && !isEmpty(deliveryWindowIds) ? async () => fetchCollections(buyerId, deliveryWindowIds) : skipToken,
    queryKey: ['collections', { buyerId, deliveryWindowIds }],
  });
};
