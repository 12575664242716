import { skipToken, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { Product, ShowroomApiResponse } from '../../../../../typings';
import { isDefined } from '../../../utils/is';
import { fetchSingleLookbookProduct } from '../../productsRepository';

import { useLookbookLogin } from './useLookbookLogin';

export const LOOKBOOK_PRODUCT_QUERY_KEY = 'LOOKBOOK_PRODUCT_QUERY_KEY';

export const useLookbookProduct = (productId: string | undefined, lookbookId: string | undefined) => {
  const { data } = useLookbookLogin(lookbookId);
  const token = data?.token;

  const isEnabled = isDefined(token) && isDefined(productId) && isDefined(lookbookId);

  return useQuery<ShowroomApiResponse & Product.SingleProductDTO, AxiosError, Product.Full>({
    enabled: isEnabled,
    queryFn: isEnabled ? async () => fetchSingleLookbookProduct(productId, token) : skipToken,
    queryKey: [LOOKBOOK_PRODUCT_QUERY_KEY, { lookbookId, productId }],
    select: response => response.product,
  });
};
