import { Skeleton } from '../../../Skeleton/Skeleton';

import styles from './PageSkeleton.module.scss';

export const PageSkeleton = () => {
  return (
    <div className={styles.container}>
      <Skeleton type="rectangle" />
      <Skeleton type="rectangle" />
    </div>
  );
};
