import { Variants } from '@typings';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { addToast } from '../../components/various/Toasts';
import { getConfiguration } from '../../ducks';

export const useUnitsValidator = () => {
  const { t } = useTranslation(['validation']);
  const { backordersEnabled, stockNumbersVisible } = useSelector(getConfiguration);

  return ({ stock, minimumQuantity, multipleOf }: { stock: Variants.Stock; minimumQuantity: number; multipleOf: number }) =>
    (value: number) => {
      if (stockNumbersVisible && !backordersEnabled && typeof stock === 'number' && value > stock) {
        const correctedValue = stock % multipleOf === 0 ? stock : stock - (stock % multipleOf);

        addToast(t('validation:over_stock_hint', { stockQuantity: correctedValue }));

        return correctedValue;
      }

      const closestMultipleOfValue = Math.ceil(value / multipleOf) * multipleOf;

      if (closestMultipleOfValue !== value) {
        addToast(
          t('validation:multiple_of_hint', {
            correctedValue: closestMultipleOfValue,
            multipleOf,
            originalValue: value,
          }),
        );

        return closestMultipleOfValue;
      }

      if (value < minimumQuantity && value !== 0) {
        addToast(t('validation:minimum_quantity_hint', { minimumQuantity }));

        return minimumQuantity;
      }

      return value;
    };
};
