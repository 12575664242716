import cx from 'classnames';
import React from 'react';

import { MovementOffset, useMovement } from '../../../utils/hooks';
import Icon, { IconType } from '../../various/Icon';

import styles from './Mover.module.scss';

interface Props {
  isActive?: boolean;
  onStartMovement?: (startPosition: MovementOffset, event: MouseEvent | TouchEvent) => void;
  onMovement: (offset: MovementOffset, event: MouseEvent | TouchEvent) => void;
  onEndMovement?: (offset: MovementOffset, event: MouseEvent | TouchEvent) => void;
  className?: string;
}

export const Mover = React.memo(({ onMovement, onStartMovement, onEndMovement, isActive, className }: Props) => {
  const moveButton = React.useRef<HTMLButtonElement | null>(null);

  useMovement(
    moveButton,
    {
      onEndMovement,
      onMovement,
      onStartMovement,
    },
    isActive,
  );

  return (
    <button type="button" className={cx(styles.mover, className)} ref={moveButton}>
      <Icon type={IconType.Move} className={styles.moveIcon} />
    </button>
  );
});
