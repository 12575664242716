import { Addresses } from '@typings';
import React from 'react';
import { useSelector } from 'react-redux';

import { getCountryByCountryCode } from '../../../ducks/countries';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';

import styles from './shippingAddresses.module.scss';

interface Props {
  shippingAddress: Addresses.MixedShipping;
}

const showData = (data?: Nullable<string>) => isDefined(data) && !isEmpty(data) && <span>{data}</span>;

export const ShippingAddressBody = ({ shippingAddress }: Props) => {
  const country = isDefined(shippingAddress.country) ? shippingAddress.country : '';
  const shippingCountryCodeSelector = React.useMemo(() => getCountryByCountryCode(country), [country]);
  const shippingCountry = useSelector(shippingCountryCodeSelector);

  const stateName = isDefined(shippingAddress.state) ? shippingCountry.states?.[shippingAddress.state]?.name ?? '' : '';

  return (
    <div data-sentry-mask className={styles.cardBody}>
      <span>
        {showData(shippingAddress.address1)}
        {showData(shippingAddress.address2)}
      </span>
      <span>
        {showData(shippingAddress.zipCode)}
        {showData(shippingAddress.city)}
      </span>
      <span>
        {showData(stateName)}
        {showData(shippingCountry.name)}
      </span>
      <span className={styles.contactPerson}>
        {showData(shippingAddress.contactPerson)}
        {showData(shippingAddress.phoneNumber)}
      </span>
    </div>
  );
};
