const enum TranslationsTrackingEventWithParameters {
  LANGUAGE_APPLIED = 'translations-language-applied',
  LANGUAGE_SWITCHER_CHANGED = 'translations-language-switcher-changed',
}

export const enum TranslationsTrackingEvent {
  TRANSLATABLE_FIELDS_MODIFIED = 'translations-translatable-fields-modified',
}

interface LanguageAppliedEvent {
  appliedLanguage: string;
  availableLanguages: string[];
  browserLanguage: string;
}

export const getLanguageAppliedEvent = ({ appliedLanguage, availableLanguages, browserLanguage }: LanguageAppliedEvent) => ({
  appliedLanguage,
  availableLanguages,
  browserLanguage,
  event: TranslationsTrackingEventWithParameters.LANGUAGE_APPLIED,
});

export const getLanguageSwitcherChangedEvent = (selectedLanguage: string) => ({
  event: TranslationsTrackingEventWithParameters.LANGUAGE_SWITCHER_CHANGED,
  selectedLanguage,
});
