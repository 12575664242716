import { Cms } from '@typings';

import { isDefined } from '../../../../utils/is';

import { MultiLanguageButton } from './MultiLanguageButton';
import { SingleLanguageButton } from './SingleLanguageButton';

interface Props {
  pageMeta: Cms.Page;
}

export const PublishButton = ({ pageMeta }: Props) => {
  const isMultiLanguage = isDefined(pageMeta.availableLanguages) && pageMeta.availableLanguages.length > 1;

  return isMultiLanguage ? <MultiLanguageButton pageMeta={pageMeta} /> : <SingleLanguageButton pageMeta={pageMeta} />;
};
