import { PopoverMenuItems } from '@typings';
import React from 'react';

import { isDefined } from '../../../../utils/is';
import { Link } from '../../../various/Link';
import Icon, { IconColor } from '../../Icon';
import styles from '../PopoverMenu.module.scss';

import { Label } from './Label';

interface Props {
  item: PopoverMenuItems.LinkItem;
  closeMenu: () => void;
}

export const LinkItem = ({ item, closeMenu }: Props) => {
  const { to, icon, label, testId, subLabel, description } = item;

  const handleClick = React.useCallback(() => {
    closeMenu();
  }, [closeMenu]);

  return (
    <li className={styles.menuItem}>
      <Link to={to} onClick={handleClick} test-id={testId} className={styles.menuButton}>
        {isDefined(icon) && <Icon className={styles.icon} type={icon} color={IconColor.Gray} />}
        <Label description={description} subLabel={subLabel}>
          {label}
        </Label>
      </Link>
    </li>
  );
};
