import React from 'react';

import { useDebouncedCallback, useResizeObserver } from '../hooks';
import { isDefined } from '../is';

interface Options {
  widthOnly?: boolean;
  heightOnly?: boolean;
}

export const useIsOverflowing = (ref: React.RefObject<HTMLElement>, options?: Options) => {
  const [isOverflowing, setIsOverflowing] = React.useState(false);

  const updateOverflowingState = () => {
    if (!isDefined(ref.current)) {
      return;
    }

    const isWidthOverflowing = !options?.heightOnly && ref.current.scrollWidth > ref.current.offsetWidth;
    const isHeightOverflowing = !options?.widthOnly && ref.current.scrollHeight > ref.current.offsetHeight;

    setIsOverflowing(isWidthOverflowing || isHeightOverflowing);
  };

  const updateOverflowingStateDebounced = useDebouncedCallback(updateOverflowingState);

  useResizeObserver(ref, updateOverflowingStateDebounced);

  return isOverflowing;
};
