import React from 'react';
import { useSelector } from 'react-redux';

import { getCmsSectionById, getCurrentSectionId, getMarkets } from '../../../ducks';
import { isDefined } from '../../is';

export const useSectionMarkets = () => {
  const currentSectionId = useSelector(getCurrentSectionId);
  const section = useSelector(getCmsSectionById(currentSectionId));
  const allMarkets = useSelector(getMarkets);

  const allAssignedMarketsIds = React.useMemo(() => {
    return section?.visibleFor.marketIds;
  }, [section]);

  const availableMarkets = React.useMemo(() => {
    if (!isDefined(allAssignedMarketsIds)) {
      return [];
    }

    return allMarkets.filter(market => allAssignedMarketsIds.includes(market.id));
  }, [allAssignedMarketsIds, allMarkets]);

  const availableMarketsIds = React.useMemo(() => availableMarkets.map(market => market.id), [availableMarkets]);
  const availableMarketsNames = React.useMemo(() => availableMarkets.map(market => market.name), [availableMarkets]);

  return { allAssignedMarketsIds, availableMarketsIds, availableMarketsNames };
};
