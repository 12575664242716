import cx from 'classnames';

import { FormDivider } from '../FormDivider';

import styles from './FormButtons.module.scss';

interface Props {
  direction?: 'row' | 'column';
  showDivider?: boolean;
  topSpacing?: 'small' | 'regular';
}

export const FormButtons = ({ children, direction = 'row', showDivider = false, topSpacing = 'regular' }: React.WithChildren<Props>) => {
  const classNames = cx(styles.buttons, {
    [styles.directionColumn]: direction === 'column',
    [styles.topSpacingSmall]: topSpacing === 'small',
    [styles.topSpacingRegular]: topSpacing === 'regular',
  });

  return (
    <>
      {showDivider && <FormDivider className={styles.divider} />}
      <div className={classNames}>{children}</div>
    </>
  );
};
