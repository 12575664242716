import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getCheckoutFields } from '../../../ducks/config';
import { getIsOrderCheckoutSuccess, getOrderDetails } from '../../../ducks/order';
import { getIsOrderClosed } from '../../../logic/Orders';
import { useAvailableOrderDetails } from '../../../utils/hooks/useAvailableOrderDetails';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { OrderTopBar } from '../../orders/OrderTopBar';
import Heading from '../../various/Heading';
import { PageTitle } from '../../various/PageTitle';
import { Wrapper, WrapperSize } from '../../various/Wrapper';
import { CheckoutSection } from '../CheckoutSection';
import { AttachmentsSection } from '../CheckoutSuccess/AttachmentsSection';
import { CheckoutInformation } from '../CheckoutSuccess/CheckoutInformation';
import { PaymentsList } from '../CheckoutSuccess/PaymentsList';
import { ShipmentCard } from '../CheckoutSuccess/ShipmentCard';
import { SplitAlert } from '../SplitAlert';

import styles from './CheckoutSingleSuccess.module.scss';

export const CheckoutSingleSuccess = () => {
  const { t } = useTranslation(['checkout', 'common']);
  const checkoutFields = useSelector(getCheckoutFields);
  const isOrderCheckoutSuccess = useSelector(getIsOrderCheckoutSuccess);
  const orderDetails = useSelector(getOrderDetails);
  const { order } = orderDetails;
  const { orderNumber } = useAvailableOrderDetails();
  const hasClosedOrder = isDefined(order.order) && getIsOrderClosed(order);

  return (
    <PageTitle title={t('checkout:success_page_title', { orderNumber })}>
      <OrderTopBar />
      <Wrapper size={WrapperSize.LARGE}>
        <SplitAlert className={styles.alert} messageType="general" order={order} />
        {isOrderCheckoutSuccess && (
          <CheckoutSection isBorderless>
            <Heading title={t('checkout:thank_you_order')} />
            <p>
              {orderDetails.order.status === 'checkoutRequested' && (
                <Trans t={t} i18nKey="checkout:success_checkout_requested">
                  The order has a status <strong>checkout requested</strong>. Review your order summary below.
                  <br />
                  Please note that as long as the seller doesn't confirm the order, you can always go back and modify it.
                </Trans>
              )}
              {orderDetails.order.status === 'pending' && (
                <Trans t={t} i18nKey="checkout:success_pending">
                  The order has a status <strong>pending</strong>. Review your order summary below.
                  <br />
                  It is not possible to make changes to this order anymore.
                </Trans>
              )}
            </p>
          </CheckoutSection>
        )}
        <CheckoutInformation checkoutFields={checkoutFields} order={order} products={orderDetails.products} />

        {hasClosedOrder && !isEmpty(order.shipments) && (
          <CheckoutSection>
            <Heading title={t('common:shipment_other')} />
            {order.shipments.map(shipment => (
              <ShipmentCard key={shipment.shipmentId} shipment={shipment} />
            ))}
          </CheckoutSection>
        )}

        <PaymentsList order={order} />

        {hasClosedOrder && <AttachmentsSection attachments={order.attachments} />}
      </Wrapper>
    </PageTitle>
  );
};
