import { DeliveryWindow } from '@typings';
import { omit } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  createSelection,
  getBuyerIdFromCurrentOrder,
  getDefaultStockType,
  getIsLoggedIn,
  getIsSeller,
  getIsStockSwitchNotificationEnabled,
  getStockTypeFilter,
  getStockTypeLabels,
  pushEvent,
  updateFilters,
} from '../../../../ducks';
import { StockTypeSeparationTrackingEvent } from '../../../../utils/analytics/events';
import { useConfirmationGuard, useHasProductsByStockType } from '../../../../utils/hooks';
import { isDefined } from '../../../../utils/is';
import { ContentSwitcher } from '../../../various/ContentSwitcher';

import styles from './StockSwitcher.module.scss';

export const StockSwitcher = ({ children }: React.WithChildren) => {
  const { t } = useTranslation(['common', 'confirmationConfig']);
  const dispatch = useDispatch();
  const confirmationGuard = useConfirmationGuard();
  const buyerId = useSelector(getBuyerIdFromCurrentOrder);
  const isSeller = useSelector(getIsSeller);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const isStockSwitchNotificationEnabled = useSelector(getIsStockSwitchNotificationEnabled);
  const defaultStockType = useSelector(getDefaultStockType);
  const stockLabels = useSelector(getStockTypeLabels);
  const currentStockType = useSelector(getStockTypeFilter) ?? defaultStockType ?? 'stock';

  const hasProductsByStockType = useHasProductsByStockType();
  const hasAddedProducts = hasProductsByStockType.preorder || hasProductsByStockType.stock;

  const shouldSwitchWithNotification = isStockSwitchNotificationEnabled && hasAddedProducts;

  const tabs = [
    {
      dataTestId: 'stockContentTab',
      title: stockLabels.stock,
      value: 'stock',
    },
    {
      dataTestId: 'preorderContentTab',
      title: stockLabels.preorder,
      value: 'preorder',
    },
  ];

  const orderedTabs = defaultStockType === 'stock' ? tabs : [...tabs].reverse();

  const setStockType = React.useCallback(
    (stockType: DeliveryWindow.StockType) => {
      dispatch(
        updateFilters({
          filters: { stockType },
          preserveCurrent: false,
        }),
      );
    },
    [dispatch],
  );

  const createNewSelection = React.useCallback(
    (stockType: DeliveryWindow.StockType) => {
      const buyerKey = isSeller ? { buyer: buyerId } : {};

      dispatch(
        createSelection({
          initialStockType: stockType,
          name: '',
          redirectPath: 'PRODUCTS_ORDER',
          ...buyerKey,
        }),
      );
    },
    [dispatch, buyerId, isSeller],
  );

  const stockChangeConfirmation = React.useCallback(
    (stockType: DeliveryWindow.StockType) => {
      dispatch(pushEvent({ event: StockTypeSeparationTrackingEvent.NOTIFICATION_RENDERED }));

      const stockName = stockLabels[stockType];
      const confirmationTexts = t('confirmationConfig:change_stock_type', { returnObjects: true, stockName });

      const extraButtonText = (
        <>
          <span className={styles.additionalButtonFull}>{confirmationTexts.extraButtonText.full}</span>
          <span className={styles.additionalButtonShort}>{confirmationTexts.extraButtonText.short}</span>
        </>
      );

      const content = (
        <div className={styles.notificationContent}>
          <p>
            {confirmationTexts.content.common.about_to_shop} {confirmationTexts.content.stockDescription[stockType]}
          </p>
          <p>{isLoggedIn ? confirmationTexts.content.common.create_new_selection : confirmationTexts.content.common.log_in}</p>
        </div>
      );

      return confirmationGuard(() => ({
        content,
        extraButtonText,
        loadingOnOk: true,
        okDisabled: !isLoggedIn,
        okType: 'info',
        onExtraButton: () => {
          dispatch(pushEvent({ event: StockTypeSeparationTrackingEvent.SHOP_ANYWAY_CHOSEN }));
          setStockType(stockType);
        },
        onOk: () => {
          dispatch(pushEvent({ event: StockTypeSeparationTrackingEvent.CREATE_NEW_SELECTION_CHOSEN }));
          createNewSelection(stockType);
        },
        onRequestClose: event => {
          // If `event` is undefined it means that the modal is closing because of either "ok" or "extra" button is clicked
          // and we don't want to send analytics event,
          // If `event` is defined it means that the modal is closing because either "cancel" button was clicked, ESC key was hit, or outside click was made
          // and we want to send analytics event.
          if (isDefined(event)) {
            dispatch(pushEvent({ event: StockTypeSeparationTrackingEvent.NOTIFICATION_DISMISSED }));
          }
        },
        type: 'confirm',
        ...omit(['content', 'extraButtonText'], confirmationTexts),
      }))();
    },
    [dispatch, stockLabels, t, isLoggedIn, confirmationGuard, setStockType, createNewSelection],
  );

  const handleStockChange = React.useCallback(
    (stockType: DeliveryWindow.StockType) => {
      dispatch(pushEvent({ event: StockTypeSeparationTrackingEvent.SWITCHER_USED }));

      const isSwitchingBack = hasProductsByStockType[stockType];

      if (!shouldSwitchWithNotification || isSwitchingBack) {
        return setStockType(stockType);
      }

      return stockChangeConfirmation(stockType);
    },
    [dispatch, hasProductsByStockType, shouldSwitchWithNotification, stockChangeConfirmation, setStockType],
  );

  React.useEffect(() => {
    dispatch(pushEvent({ event: StockTypeSeparationTrackingEvent.SWITCHER_RENDERED }));
  }, [dispatch]);

  return (
    <div className={styles.stockSwitcher}>
      {children}
      <ContentSwitcher
        groupName={t('common:stock_type')}
        tabs={orderedTabs}
        selectedValue={currentStockType}
        onChange={handleStockChange}
        dataTestId="stockContentSwitcher"
      />
    </div>
  );
};
