import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch, useNavigate } from 'react-router-dom';

import DefaultLoader from '../components/various/loaders/DefaultLoader';
import {
  getHasDeliveryWindowsByBuyerIdLoaded,
  getIsLoggedIn,
  getProductsOrder,
  getSharedLinksEnabled,
  loadProductsRequest,
} from '../ducks';
import { fetchOrderRequest, getBuyerIdFromCurrentOrder, getIsLoadingOrderDetails, resetOrderData } from '../ducks/order';
import { compiledPaths } from '../paths';
import { useIsViewingOrder, useUrlOrderIdMatch } from '../utils/hooks';
import { isDefined } from '../utils/is';
import { isEmpty } from '../utils/isEmpty';

export const SelectionLoader = <T extends object>(Component: React.ComponentType<T>) =>
  React.memo((props: T) => {
    const isLoadingOrderDetails = useSelector(getIsLoadingOrderDetails);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const productsOrder = useSelector(getProductsOrder);
    const orderBuyerId = useSelector(getBuyerIdFromCurrentOrder);
    const hasDeliveryWindowsForBuyerLoaded = useSelector(getHasDeliveryWindowsByBuyerIdLoaded(orderBuyerId));
    const [shouldFetchProducts, setShouldFetchProducts] = React.useState(false);
    const hasComparedLocalId = React.useRef(false);
    const isLoggedIn = useSelector(getIsLoggedIn);
    const areSharedLinksEnabled = useSelector(getSharedLinksEnabled);

    const { isMatchingAnyId, urlId, hasOrderUrlIdChanged, hasOrderChanged, singleOrderId } = useUrlOrderIdMatch();
    const isViewingOrder = useIsViewingOrder();

    const shouldInitiallyLoadProducts = isLoadingOrderDetails || (hasOrderUrlIdChanged && isEmpty(productsOrder));
    const shouldLoadOrder = !hasComparedLocalId.current ? !isMatchingAnyId : hasOrderChanged;
    const isCheckoutSuccess = !!useMatch(compiledPaths.CHECKOUT_SUCCESS({ id: urlId }))?.pattern.end;

    const fetchOrderOnIdChange = React.useCallback(() => {
      if (shouldLoadOrder && !isLoadingOrderDetails && isDefined(urlId)) {
        dispatch(fetchOrderRequest(urlId));
        setShouldFetchProducts(true);
      }

      hasComparedLocalId.current = true;
    }, [shouldLoadOrder, isLoadingOrderDetails, dispatch, urlId]);

    const enableProductsLoadingForInitialOrderLoaded = React.useCallback(() => {
      if (shouldInitiallyLoadProducts) {
        setShouldFetchProducts(true);
      }
    }, [shouldInitiallyLoadProducts]);

    const fetchProductsForSelection = React.useCallback(() => {
      if (shouldFetchProducts && !isLoadingOrderDetails && hasDeliveryWindowsForBuyerLoaded) {
        !isViewingOrder && dispatch(loadProductsRequest());
        setShouldFetchProducts(false);
      }
    }, [shouldFetchProducts, isLoadingOrderDetails, hasDeliveryWindowsForBuyerLoaded, isViewingOrder, dispatch]);

    const redirectCheckedOutOrder = React.useCallback(() => {
      if (!isCheckoutSuccess && isMatchingAnyId && isViewingOrder) {
        navigate(compiledPaths.CHECKOUT_SUCCESS({ id: urlId }), { replace: true });
      }
    }, [isViewingOrder, isMatchingAnyId, navigate, urlId, isCheckoutSuccess]);

    React.useEffect(fetchOrderOnIdChange, [fetchOrderOnIdChange]);
    React.useEffect(enableProductsLoadingForInitialOrderLoaded, [enableProductsLoadingForInitialOrderLoaded]);
    React.useEffect(fetchProductsForSelection, [fetchProductsForSelection]);
    React.useEffect(redirectCheckedOutOrder, [redirectCheckedOutOrder]);

    React.useEffect(() => {
      if (hasOrderUrlIdChanged && hasOrderChanged && isDefined(singleOrderId)) {
        dispatch(resetOrderData());
      }
    }, [dispatch, hasOrderChanged, hasOrderUrlIdChanged, singleOrderId]);

    return areSharedLinksEnabled || isLoggedIn ? <Component {...props} /> : <DefaultLoader overlay />;
  });
