import { Access } from '@typings';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { App } from '../components/App';
import { authoriseAccessRequest, getIsUserKnown, getUser } from '../ducks';
import { paths } from '../paths';
import { isDefined } from '../utils/is';

interface Props {
  children: React.ReactNode;
  hideHeader?: boolean;
  fallbackPath?: string;
  limitAccess?: (Access | 'admin')[];
}

const AuthenticatedPathComponent = ({ limitAccess, fallbackPath, children, hideHeader }: Props) => {
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const userRole = user.isAdmin ? 'admin' : user.access;

  React.useEffect(() => {
    dispatch(authoriseAccessRequest());
  }, []);

  if (getIsUserKnown(user) && isDefined(limitAccess) && !limitAccess.includes(userRole)) {
    const to = isDefined(fallbackPath) ? fallbackPath : paths.PAGE_NOT_FOUND;

    return <Navigate replace to={to} />;
  }

  return <App hideHeader={hideHeader}>{children}</App>;
};

export const AuthenticatedPath = React.memo(AuthenticatedPathComponent);
