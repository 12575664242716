import { useTranslation } from 'react-i18next';

import Icon, { IconColor, IconType } from '../../Icon';
import styles from '../ScannerContent.module.scss';

export const NoSupport = () => {
  const { t } = useTranslation(['common']);

  return (
    <div className={styles.message}>
      <Icon type={IconType.NoCamera} color={IconColor.Faded} size={48} />
      <p>{t('common:scanning_not_supported')}</p>
    </div>
  );
};
