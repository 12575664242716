/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 12;
const ORIGINAL_COLOR = '#000000';

export const Selection = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => {
  return (
    <svg width={width} height={width} viewBox="0 0 12 12" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0C0.895431 0 0 0.895431 0 2V10C0 11.1046 0.895431 12 2 12H10C11.1046 12 12 11.1046 12 10V2C12 0.895431 11.1046 0 10 0H2ZM4.36413 9.09373C4.60092 9.33051 4.98342 9.33051 5.22021 9.09373L9.82241 4.48546C10.0592 4.24867 10.0592 3.86616 9.82241 3.62937C9.58562 3.39259 9.20312 3.39259 8.96633 3.62937L4.78913 7.80657L3.04054 6.05797C2.80375 5.82119 2.42125 5.82119 2.18446 6.05797C1.94767 6.29476 1.94767 6.67727 2.18446 6.91406L4.36413 9.09373Z"
        fill={color}
      />
    </svg>
  );
};
