import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { DEFAULT_ITEMS_COUNT_PER_REQUEST } from '../../../constants/limits';
import { getSkipFirstForListing } from '../../../utils/getSkipFirstForListing';
import { getAccounts } from '../../accountsRepository';

interface Props {
  page: number;
  search?: string;
  limit?: number;
}

export const ACCOUNTS_QUERY_KEY = 'accounts';

export const useAccounts = ({ page, search, limit = DEFAULT_ITEMS_COUNT_PER_REQUEST }: Props) => {
  const { t } = useTranslation(['accounts']);

  return useQuery({
    meta: {
      errorMessage: t('accounts:accounts_load_failure'),
    },
    queryFn: async () => getAccounts(limit, getSkipFirstForListing(page, limit), search),
    queryKey: [ACCOUNTS_QUERY_KEY, { limit, page, search }],
  });
};
