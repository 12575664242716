import { Preset, Product } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PlacementType } from 'react-laag/dist/PlacementType';

import { BREAKPOINTS } from '../../../../../constants/breakpoints';
import { useScreenWidth } from '../../../../../utils/hooks';
import { useMatrixDistributionContext } from '../../../../products/ProductMatrix/context';
import { ControlsButton } from '../../../../products/ProductMatrix/MatrixOverflow/ControlsButton/ControlsButton';
import { IconType } from '../../../Icon/Icon';
import { Popover } from '../../../Popover';

import { PresetsPopoverContent } from './components/PresetsPopoverContent';
import styles from './PresetsPopover.module.scss';

const MOBILE_TRIGGER_OFFSET = -14;
interface Props {
  variant: Product.Standard;
  deliveryWindowId: string;
  distributions: Preset[];
  shouldShowLabel?: boolean;
  disabled?: boolean;
  isFocusTarget?: boolean;
}

export const PresetsPopover = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const screenWidth = useScreenWidth();
  const { variant, deliveryWindowId, distributions, disabled, isFocusTarget, shouldShowLabel } = props;
  const { isDistributionPopupOpen, distributionVariant, openDistributionPopup, closeDistributionPopup } = useMatrixDistributionContext();

  const buttonRef = !isDistributionPopupOpen ? ref : undefined;
  const { t } = useTranslation(['orders']);

  const handleSetVisible = React.useCallback(
    (isVisible: boolean) => {
      if (isVisible) {
        openDistributionPopup(variant);

        return;
      }
      closeDistributionPopup();
    },
    [closeDistributionPopup, openDistributionPopup, variant],
  );

  const handleToggleIsVisible = React.useCallback(() => {
    handleSetVisible(!isDistributionPopupOpen);
  }, [handleSetVisible, isDistributionPopupOpen]);

  const isMobile = screenWidth <= BREAKPOINTS.SMALL;
  const triggerOffset = isMobile ? MOBILE_TRIGGER_OFFSET : undefined;
  const placements: PlacementType[] | undefined = isMobile ? ['top-center'] : undefined;

  return (
    <Popover
      visible={isDistributionPopupOpen && distributionVariant?.variant === variant.variant}
      onVisibleChange={handleSetVisible}
      placement="top-center"
      className={styles.popover}
      triggerOffset={triggerOffset}
      possiblePlacements={placements}
      content={
        <PresetsPopoverContent
          variant={variant}
          close={closeDistributionPopup}
          distributions={distributions}
          deliveryWindowId={deliveryWindowId}
        />
      }
    >
      <ControlsButton
        shouldShowLabel={shouldShowLabel}
        disabled={disabled}
        isFocusTarget={isFocusTarget}
        ref={buttonRef}
        iconType={IconType.PlusCircle}
        label={t('orders:size_distribution')}
        onClick={handleToggleIsVisible}
        testId={`addDistribution-${variant.variant}`}
      />
    </Popover>
  );
});
