import { AccountMarket, Cms, Translations } from '@typings';
import React from 'react';
import { useSelector } from 'react-redux';

import { CMS_SCREENS_SIZES } from '../../../constants/cms';
import { getPricelists } from '../../../ducks';
import { useIsScreenSize } from '../../../utils/hooks';
import { isDefined } from '../../../utils/is';
import { Cog } from '../../various/Icon/Icons/cms/Cog';
import { Popover } from '../../various/Popover';
import { TextEllipsis } from '../../various/TextEllipsis';
import { EditorLanguageContext } from '../context/EditorLanguageContext';
import { LanguageFlag } from '../LanguageFlag';

import { SegmentationPopup } from './SegmentationPopup';
import styles from './SegmentationSwitcher.module.scss';

interface Props {
  markets: AccountMarket[];
  currentSegmentation: {
    marketId: Nullable<string>;
    pricelistId: Nullable<string>;
  };
  segmentationChange: (marketId: string, pricelistId: string, language?: Translations.SupportedLanguagesCodes) => void;
  languages?: Cms.PageLanguage[];
}

export const SegmentationSwitcher = ({ markets, currentSegmentation, segmentationChange, languages }: Props) => {
  const { editorLanguage } = React.useContext(EditorLanguageContext);
  const [isVisible, setVisible] = React.useState(false);
  const closePopup = React.useCallback(() => setVisible(false), []);
  const togglePopup = React.useCallback(() => setVisible(visible => !visible), []);
  const pricelists = useSelector(getPricelists);
  const handleApplySegmentation = React.useCallback(
    (marketId: string, pricelistId: string, language?: Translations.SupportedLanguagesCodes) => {
      segmentationChange(marketId, pricelistId, language);
      closePopup();
    },
    [segmentationChange],
  );

  const isMobile = useIsScreenSize(CMS_SCREENS_SIZES.mobile);

  const popoverStyles = isMobile ? styles.popoverOnMobile : styles.popover;

  return (
    <div className={styles.wrapper}>
      <LanguageFlag size={17} code={editorLanguage} />

      <TextEllipsis className={styles.segmentation}>
        <span className={styles.market}>
          {isDefined(markets) ? markets.find(market => market.id === currentSegmentation.marketId)?.name : null}
        </span>

        <span>{isDefined(pricelists) ? pricelists.find(pricelist => pricelist.id === currentSegmentation.pricelistId)?.name : null}</span>
      </TextEllipsis>

      <Popover
        visible={isVisible}
        onVisibleChange={setVisible}
        placement={isMobile ? 'bottom-end' : 'bottom-center'}
        className={popoverStyles}
        content={
          <SegmentationPopup
            priceLists={pricelists}
            markets={markets}
            currentSegmentation={currentSegmentation}
            segmentationChange={handleApplySegmentation}
            handleClose={closePopup}
            languages={languages}
            currentLanguage={editorLanguage}
          />
        }
      >
        <button className={styles.cog} onClick={togglePopup}>
          <Cog />
        </button>
      </Popover>
    </div>
  );
};
