import { Id, Matrices } from '@typings';
import cx from 'classnames';

import { useMatrixLayout } from '../../../../utils/hooks/matrix/useMatrixLayout';
import { isDefined } from '../../../../utils/is';
import { isEmpty } from '../../../../utils/isEmpty';
import { useMatrixPrepackContext, useMatrixSettingsContext, useMatrixVariantsContext } from '../../../products/ProductMatrix/context';
import { DistributionCell } from '../MatrixCells';

import { InputBodyCells } from './InputBodyCells';
import styles from './MatrixBody.module.scss';
import { PrepacksBodyCells } from './PrepacksBodyCells';
import { TotalsSummaryBodyCell } from './TotalsSummaryBodyCell';
import { TwoDimensionalBodyCell } from './TwoDimensionalBodyCell';
import { VariantBodyCells } from './VariantBodyCells';

interface Props {
  variantId: Id;
  rowIndex: number;
  rowQuantities: Matrices.CellQuantities[];
}

export const MatrixBodyRow = ({ variantId, rowIndex, rowQuantities }: Props) => {
  const { isMobile, isLookbook } = useMatrixSettingsContext();
  const { variants, cancelledVariantsIds } = useMatrixVariantsContext();
  const { visiblePrepacksLength, prepacksQuantitiesInVariants } = useMatrixPrepackContext();
  const { isTwoDimensional } = useMatrixLayout();
  const rowVariant = variants.find(variant => variant.variant === variantId);
  const prepacksQuantities = prepacksQuantitiesInVariants[variantId] ?? {};
  const hasAppliedPrepacks = isDefined(prepacksQuantities) && !isEmpty(Object.keys(prepacksQuantities));

  if (!isDefined(rowVariant)) {
    return null;
  }

  const isFirstRow = rowIndex === 0;
  const shouldShowHeadings = !isMobile && (!isTwoDimensional || isFirstRow);

  const headingsRowSpan = !isTwoDimensional ? undefined : rowVariant.itemTable.y.length;

  const isVariantCancelled = cancelledVariantsIds.includes(rowVariant.variant);

  return (
    <tr data-testid="matrixBodyRow" className={cx({ [styles.cancelledVariant]: isVariantCancelled })}>
      {shouldShowHeadings && <VariantBodyCells variant={rowVariant} rowIndex={rowIndex} rowSpan={headingsRowSpan} />}
      <PrepacksBodyCells
        variant={rowVariant}
        rowSpan={headingsRowSpan}
        rowIndex={rowIndex}
        prepacksQuantities={prepacksQuantities}
        isCancelled={isVariantCancelled}
      />
      <DistributionCell
        variant={rowVariant}
        position={{ x: visiblePrepacksLength, y: rowIndex }}
        rowSpan={headingsRowSpan}
        rowIndex={rowIndex}
      />
      {isTwoDimensional && <TwoDimensionalBodyCell variant={rowVariant} rowIndex={rowIndex} />}
      <InputBodyCells
        variant={rowVariant}
        rowIndex={rowIndex}
        rowQuantities={rowQuantities}
        hasAppliedPrepacks={hasAppliedPrepacks}
        isCancelled={isVariantCancelled}
      />
      {!isLookbook && <TotalsSummaryBodyCell rowQuantities={rowQuantities} rowIndex={rowIndex} />}
    </tr>
  );
};
