import { UnitsDistribution } from "../../../../typings";
import { isDefined } from "../../utils/is";
import { sumPresetsResults } from "../presets";

import { calculateDistributionBase } from "./unitsDistribution";

export const distributeToIdeal = ({ distributions }: Pick<UnitsDistribution.DistributionSetParameters, 'distributions'>) => {
  const availableQuantities = distributions[0]?.distributionQuantities.reduce((acc, item) => ({ ...acc, [item.sizeId]: Infinity }), {});

  if (!isDefined(availableQuantities)) {
    return [];
  };

  const distributionResult = distributions.map(({ distributionQuantities, requestedValue }) => {
    return calculateDistributionBase({
        availableQuantities,
        distributionQuantities,
        requestedValue,
      });
  });

  return sumPresetsResults(distributionResult.map(result => result.distributionQuantities));
}
