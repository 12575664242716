import { Navigation } from '@typings';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import universalStyles from '../../../../css/utilities/universal.module.scss';
import Icon, { IconColor, IconType } from '../../various/Icon';
import { Tooltip } from '../../various/Tooltip';

import styles from './TranslationsContent.module.scss';

interface Props {
  type: Navigation.AnyMenuItemType;
}

export const NavigationItemIcon = ({ type }: Props) => {
  const { t } = useTranslation(['common']);

  const iconMap: Record<Navigation.AnyMenuItemType, { icon: IconType; name: string; className?: string }> = {
    folder: {
      icon: IconType.Folder,
      name: t('common:folder'),
    },
    link: {
      icon: IconType.Link,
      name: t('common:link'),
    },
    page: {
      className: styles.pageIcon,
      icon: IconType.OrderDetails,
      name: t('common:page_one'),
    },
  };

  const { icon, name, className } = iconMap[type];

  return (
    <Tooltip content={<span className={universalStyles.capitalize}>{name}</span>}>
      <span className={styles.iconWrapper}>
        <Icon className={cx(styles.navigationItemIcon, className)} type={icon} color={IconColor.Dark} />
      </span>
    </Tooltip>
  );
};
