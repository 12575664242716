import * as amplitude from '@amplitude/analytics-browser';
import i18next from 'i18next';
import { omit } from 'ramda';
import { matchPath } from 'react-router-dom';
import { push } from 'redux-first-history';
import { combineEpics } from 'redux-observable';
import { EMPTY } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { addToast } from '../components/various/Toasts';
import { clearToasts } from '../components/various/Toasts/toast';
import {
  fetchConfigurationSuccess,
  fetchLoginSuccess,
  fetchOrderSuccess,
  fetchUserNavigationFailure,
  fetchUserNavigationSuccess,
  fetchUserSuccess,
  getBuyerIdFromCurrentOrder,
  getIsLoggedInAsThisBuyer,
  getIsUserAdmin,
  getIsUserDefined,
  getOrderDetails,
  getOrderProducts,
  getProductFilters,
  getProductsOrder,
  getProductsTotalItems,
  getSelectionById,
  getUserAccess,
  loadMoreProductsRequest,
  publishPageChangeSuccess,
  pushEvent,
  redirectedUserToLatestSelection,
  requestLanguageChange,
  setProductsGridView,
  setProductsListView,
  showModal,
  switchDisplayedProduct,
  updateUserNavigation,
} from '../ducks';
import { encodeSearchString } from '../logic/filters';
import productsLogic from '../logic/products';
import { paths } from '../paths';
import { store } from '../store';
import { getLanguageSwitcherChangedEvent } from '../utils/analytics/events';
import { getQueryParams } from '../utils/getQueryParams';
import { isDefined } from '../utils/is';
import { isEmpty } from '../utils/isEmpty';
import { mapResponse } from '../utils/operators/mapResponse';

const PRELOAD_PRODUCTS_BEFORE_LAST = 2;
const RECENT_SELECTION_LOCATION_PATHS = [paths.PRODUCTS_ORDER, paths.HOME, paths.ORDER_DETAILS, paths.CHECKOUT_ORDER, paths.PAGES];

const shouldLoadMoreProducts = (requestedIndex: number, state: Store) => {
  const totalItems = getProductsTotalItems(state);
  const productsOrder = getProductsOrder(state);

  return productsOrder.length !== totalItems && requestedIndex >= productsOrder.length - PRELOAD_PRODUCTS_BEFORE_LAST;
};

const switchDisplayedProductEpic: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(switchDisplayedProduct)),
    map(action => {
      const { location } = state$.value.router;

      if (!location) {
        return push({ pathname: paths.PAGE_NOT_FOUND });
      }

      const query = getQueryParams(location.search);
      const queryWithoutVariantId = omit(['showVariant'], query);
      const productsSource = getProductsOrder(state$.value);
      const requestedIndex = productsLogic.getRequestedModalNavigationIndex(
        queryWithoutVariantId.showProduct ?? '',
        productsSource,
        action.payload,
      );
      const newId = productsLogic.getRequestedProductId(requestedIndex, productsSource);

      if (!isDefined(newId)) {
        return push({ pathname: paths.PAGE_NOT_FOUND });
      }

      const newQuery = encodeSearchString({ ...queryWithoutVariantId, showProduct: newId }, getProductFilters(state$.value));

      if (shouldLoadMoreProducts(requestedIndex, state$.value)) {
        store.dispatch(loadMoreProductsRequest());
      }

      return push({ pathname: location.pathname, search: newQuery });
    }),
  );

const redirectedToLatestOrderNotificationEpic: AppEpic = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(redirectedUserToLatestSelection)),
    switchMap(() =>
      action$.pipe(
        filter(isActionOf(fetchOrderSuccess)),
        take(1),
        map(() => getOrderProducts(store$.value).length),
        filter(productsCount => productsCount > 0),
        mergeMap(() => {
          const isUserAdmin = getIsUserAdmin(store$.value);
          const userAccess = getUserAccess(store$.value);
          const userType = isUserAdmin ? 'admin' : userAccess;
          const isLoggedInAsThisBuyer = getIsLoggedInAsThisBuyer(store$.value);
          const { buyer, order } = getOrderDetails(store$.value);
          const buyerFullName = `${buyer.firstName} ${buyer.lastName}`.trim();
          const selection = getSelectionById(store$.value, order.order);
          const orderModifiedAt = order.lastModifiedDate ? order.lastModifiedDate : selection?.createdDate;

          const notificationData = { buyerFullName, orderModifiedAt };

          const description =
            isLoggedInAsThisBuyer ?
              i18next.t('selections:recent_selection_hint')
            : i18next.t('selections:recent_selection_hint_another_buyer', notificationData);

          const maybeBuyerFullName = !isLoggedInAsThisBuyer ? ` (${buyerFullName})` : '';

          const getShouldCloseOnLocation = (locationPathname: string) => {
            const currentPath = RECENT_SELECTION_LOCATION_PATHS.find(path => matchPath(path, locationPathname));

            if (!isDefined(currentPath)) {
              return true;
            }

            const matchingLocation = matchPath(currentPath, locationPathname);
            const locationOrderId = matchingLocation?.params.id;
            const isOtherLocation = !matchingLocation?.pattern.end;
            const isOtherOrderId = isDefined(order.order) && isDefined(locationOrderId) && locationOrderId !== order.order;

            return isOtherLocation || isOtherOrderId;
          };

          amplitude.identify(new amplitude.Identify().set('userType', userType));
          amplitude.track('notification.recentselection.view');

          addToast(`${i18next.t('selections:recent_selection_loaded')}${maybeBuyerFullName}`, {
            actions: [
              {
                action: showModal('CreateOrderModal'),
                label: i18next.t('selections:create_new_selection'),
                onClick: () => {
                  amplitude.track('notification.button.createnew.click');
                },
              },
              {
                label: i18next.t('selections:see_all_selections'),
                onClick: () => {
                  amplitude.track('notification.button.seeall.click');
                },
                to: paths.SELECTIONS,
              },
            ],
            dataTestId: 'recentSelectionLoadedToast',
            description,
            duration: 0,
            getShouldCloseOnLocation,
            onClose: () => {
              amplitude.track('notification.link.close.click');
            },
            position: 'bottom-left',
          });

          return EMPTY;
        }),
      ),
    ),
  );

const fetchUserNavigationOnOrderChangeEpic: AppEpic = (_, store$, { navigationRepository }) =>
  store$.pipe(
    filter(() => getIsUserDefined(store$.value)),
    map(getBuyerIdFromCurrentOrder),
    distinctUntilChanged(),
    filter(buyerId => !isEmpty(buyerId)),
    filter(() => getUserAccess(store$.value) !== 'buyer'),
    map(buyerId => (getUserAccess(store$.value) === 'viewer' ? undefined : buyerId)),
    mergeMap(async buyerId => navigationRepository.fetchCmsBuyerNavigation(buyerId)),
    mapResponse(
      res => fetchUserNavigationSuccess(res.data.items),
      () => fetchUserNavigationFailure(),
    ),
  );

const fetchInitialNavigationForBuyerEpic: AppEpic = (action$, _, { navigationRepository }) =>
  action$.pipe(
    filter(isActionOf([fetchUserSuccess, fetchLoginSuccess])),
    filter(({ payload }) => payload.user.access === 'buyer'),
    mergeMap(async () => navigationRepository.fetchCmsBuyerNavigation()),
    mapResponse(
      res => fetchUserNavigationSuccess(res.data.items),
      () => fetchUserNavigationFailure(),
    ),
  );

const updateNavigationEpic: AppEpic = (action$, store$, { navigationRepository }) =>
  action$.pipe(
    filter(isActionOf([updateUserNavigation, publishPageChangeSuccess])),
    filter(() => getUserAccess(store$.value) !== 'buyer'),
    map(() => getBuyerIdFromCurrentOrder(store$.value)),
    filter(buyerId => !isEmpty(buyerId)),
    mergeMap(async buyerId => navigationRepository.fetchCmsBuyerNavigation(buyerId)),
    mapResponse(
      res => fetchUserNavigationSuccess(res.data.items),
      () => fetchUserNavigationFailure(),
    ),
  );

const requestLanguageChangeEpic: AppEpic = action$ =>
  action$.pipe(
    filter(isActionOf(requestLanguageChange)),
    tap(() => clearToasts()),
    map(action => action.payload),
    map(language => pushEvent(getLanguageSwitcherChangedEvent(language))),
  );

const setAllProductsViewLayoutEpic: AppEpic = action$ =>
  action$.pipe(
    filter(isActionOf(fetchConfigurationSuccess)),
    map(({ payload }) => (payload.allProductsViewLayout === 'list' ? setProductsListView() : setProductsGridView())),
  );

export const uiEpic = combineEpics(
  fetchInitialNavigationForBuyerEpic,
  fetchUserNavigationOnOrderChangeEpic,
  redirectedToLatestOrderNotificationEpic,
  switchDisplayedProductEpic,
  updateNavigationEpic,
  setAllProductsViewLayoutEpic,
  requestLanguageChangeEpic,
);
