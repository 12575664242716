import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { LoginCredentials } from '../../../../../typings';
import { TOKEN_KEY } from '../../../constants/localStorage';
import { fetchLoginSuccess, resetOrderData } from '../../../ducks';
import { login } from '../../auth';
import persistentStorage from '../../persistentStorage';

export const useLogin = () => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: async ({ email, password }: LoginCredentials) => login(email, password),
    onMutate: () => {
      dispatch(resetOrderData());
    },
    onSuccess: async data => {
      persistentStorage.setItem(TOKEN_KEY, data.token);
      dispatch(fetchLoginSuccess(data));
    },
  });
};
