import { Id } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { compiledPaths } from '../../../paths';
import { isDefined } from '../../../utils/is';
import Icon, { IconColor, IconType } from '../../various/Icon';
import { Link } from '../../various/Link';
import { TextEllipsis } from '../../various/TextEllipsis';

import styles from './ListItem.module.scss';
interface Props {
  name: React.ReactNode;
  slug?: string;
  pageId?: Nullable<Id>;
  isDisabled?: boolean;
  isHomepage?: boolean;
  isFolder?: boolean;
  isLink?: boolean;
  url?: string;
}

export const ListItemTitle = ({ name, isDisabled, pageId, slug, isHomepage, isFolder, isLink, url }: Props) => {
  const { t } = useTranslation(['common']);

  return (
    <>
      <TextEllipsis as="div" noWrapText={false} className={styles.nameContainer}>
        <TextEllipsis className={cx(styles.name, { [styles.disabled]: isDisabled })}>{name}</TextEllipsis>
        {isDefined(pageId) && (
          <Link variant="blue" target="_blank" to={compiledPaths.CMS_PREVIEW({ pageId })} className={styles.link}>
            <TextEllipsis noWrapText={false} aria-label={t('common:preview')}>
              {`/${slug}`}
            </TextEllipsis>
          </Link>
        )}
        {isDefined(pageId) && !isHomepage && <Icon type={IconType.OrderDetails} color={IconColor.Gray} size={12} />}
        {isHomepage && <Icon type={IconType.Home} color={IconColor.Gray} />}
        {isFolder && <Icon type={IconType.Folder} color={IconColor.Gray} />}
        {isLink && <Icon type={IconType.Link} color={IconColor.Gray} />}
      </TextEllipsis>
      {isDefined(url) && (
        <Link variant="blue" external target="_blank" to={url} className={styles.link}>
          {url}
        </Link>
      )}
    </>
  );
};
