import { Cms } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { automaticGroupChangeRequest, getCurrentGroupId, getCurrentSectionId } from '../../../ducks';
import { getShouldShowContentLoseWarning, getShouldShowProductsLoseWarning } from '../../../logic/cms/changeGroup';
import { useGetBlocksByGroupId, useModalVisibility } from '../../../utils/hooks';
import { useConfirmationGuard } from '../../../utils/hooks/useConfirmationGuard';
import { isDefined, isNull } from '../../../utils/is';

import { TemplateModal } from './TemplateModal';

export const ChangeTemplateModal = () => {
  const dispatch = useDispatch();
  const sectionId = useSelector(getCurrentSectionId);
  const groupId = useSelector(getCurrentGroupId);
  const blocks = useGetBlocksByGroupId(groupId);
  const { isModalVisible, hideModal } = useModalVisibility('ChangeTemplateModal');
  const { t } = useTranslation(['cmsConfirmationConfig']);
  const confirmationGuard = useConfirmationGuard();

  const handleChangeTemplate = React.useCallback(
    (models: Cms.BlockModel[]) => {
      if (!isDefined(sectionId) || isNull(groupId)) {
        return;
      }
      hideModal();

      const shouldShowProductsLoseWarning = getShouldShowProductsLoseWarning(blocks, models);
      const shouldShowContentLoseWarning = getShouldShowContentLoseWarning(blocks, models);

      const shouldShowAnyContentTypeLoseWarning = shouldShowProductsLoseWarning || shouldShowContentLoseWarning;
      const changeGroupAction = () => dispatch(automaticGroupChangeRequest({ groupId, models, sectionId }));

      if (shouldShowAnyContentTypeLoseWarning) {
        const configKey =
          shouldShowProductsLoseWarning ?
            'cmsConfirmationConfig:layout_change_product_lose'
          : 'cmsConfirmationConfig:layout_change_content_lose';

        confirmationGuard(() => ({
          onOk: () => changeGroupAction(),
          ...t(configKey, { returnObjects: true }),
        }))();

        return;
      }

      changeGroupAction();
    },
    [sectionId, groupId, blocks, t, confirmationGuard],
  );

  return (
    <TemplateModal
      titleKey="cms:change_layout"
      isModalVisible={isModalVisible}
      hideModal={hideModal}
      handleClickTemplate={handleChangeTemplate}
    />
  );
};
