import { Table } from '@tanstack/react-table';
import { ColumnsConfiguration, TablesIdentifier } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Icon, { IconType } from '../Icon';

import { ColumnItem } from './ColumnItem';
import styles from './TableControls.module.scss';

interface Props<T> {
  table: Table<T>;
  columnsConfiguration: ColumnsConfiguration;
  tableName: TablesIdentifier;
  style?: React.CSSProperties;
  className?: string;
  onChange?: (columnId: string, isVisible: boolean) => void;
}

export const TableControls = <T,>({ table, columnsConfiguration, tableName, style, className, onChange }: Props<T>) => {
  const { t } = useTranslation(['common']);
  const [isDropdownOpen, setIsDropdownOpen] = React.useState<boolean>(false);
  const allTableColumns = table.getAllLeafColumns();
  const editableColumns = React.useMemo(() => allTableColumns.filter(column => column.columnDef.enableHiding !== false), [allTableColumns]);
  const toggleDropdown = React.useCallback(() => {
    setIsDropdownOpen(!isDropdownOpen);
  }, [isDropdownOpen]);

  const tableControlsClasses = cx({
    [styles.tableControls]: true,
    [styles.isOpen]: isDropdownOpen,
    className,
  });

  const visibleColumns = React.useMemo(
    () =>
      editableColumns.filter(column => {
        return columnsConfiguration[column.id]?.isVisible ?? false;
      }),
    [editableColumns, columnsConfiguration],
  );

  return (
    <div className={tableControlsClasses} style={style}>
      <button
        className={styles.controlButton}
        role="combobox"
        aria-expanded={isDropdownOpen}
        aria-controls="tableControlsList"
        onClick={toggleDropdown}
      >
        {t('common:column_other')}{' '}
        <span className={styles.columnNumber}>
          ({visibleColumns.length}/{editableColumns.length})
        </span>
        <Icon type={IconType.ArrowBlackDown} size={20} className={styles.arrowIcon} />
      </button>
      {isDropdownOpen && (
        <span>
          <button className={styles.clickOutside} onClick={toggleDropdown} aria-label={t('common:close')} />
          <ul id="tableControlsList">
            {editableColumns.map(column => {
              return <ColumnItem key={column.id} column={column} tableName={tableName} onChange={onChange} />;
            })}
          </ul>
        </span>
      )}
    </div>
  );
};
