import { Cms, Id, Product } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getHotspotPosition } from '../../../../../logic/cms/hotspot';
import { MovementOffset, useMovement } from '../../../../../utils/hooks';
import { isDefined } from '../../../../../utils/is';
import { HotspotsContext } from '../../../../cms/context/HotspotsContext';
import { ProductPicker } from '../../../../cms/HotspotEditor/ProductPicker';
import Icon, { IconColor, IconType } from '../../../../various/Icon';
import { Tooltip } from '../../../../various/Tooltip';

import styles from './Hotspots.module.scss';
import { HotspotTooltipContent } from './HotspotTooltipContent';

interface Props {
  hotspot: Cms.HotspotPoint;
  blockSize: Cms.MediumSize;
  background: Cms.ContentBlockBackground;
  naturalImage: Cms.MediumSize;
  screenWidth: number;
  products: Record<Id, Product.Standard | Product.CmsProduct>;
  blockId: string;
  hasInactiveProduct: boolean;
}

export const EditorHotspotItem = ({ hotspot, blockSize, background, naturalImage, screenWidth, products, hasInactiveProduct }: Props) => {
  const moveButtonRef = React.useRef<HTMLButtonElement>(null);
  const { t } = useTranslation(['cms']);
  const {
    activeHotspotId,
    setActiveHotspot,
    setHotspotProduct,
    setHotspotPosition,
    endHotspotMovement,
    isMovingHotspot,
    isProductsTooltipVisible,
    startHotspotMovement,
  } = React.useContext(HotspotsContext);
  const { id } = hotspot.general;
  const isActiveHotspot = React.useMemo(() => activeHotspotId === id, [activeHotspotId, id]);
  const isTooltipVisible = React.useMemo(() => {
    if (isMovingHotspot) {
      return false;
    }

    const isActiveOrUndefined = isActiveHotspot ? true : undefined;

    return isProductsTooltipVisible ? true : isActiveOrUndefined;
  }, [isMovingHotspot, isProductsTooltipVisible, isActiveHotspot]);

  const { left, top } = getHotspotPosition({
    background,
    blockSize,
    hotspotPoint: hotspot,
    naturalImage,
    screenWidth,
  });

  const { productId } = hotspot.general;
  const productInfo = isDefined(productId) ? (products[productId] as Product.Standard) : undefined;

  const handleOnClick = React.useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();

      if (isActiveHotspot) {
        return;
      }

      setActiveHotspot(id);
    },
    [id, setActiveHotspot, isActiveHotspot],
  );

  const updatePosition = React.useCallback(({ clientX, clientY }: MovementOffset) => {
    setHotspotPosition(id, { x: clientX, y: clientY });
  }, []);

  const startMovement = React.useCallback(
    ({ clientX, clientY }: MovementOffset) => {
      if (isDefined(productId)) {
        setActiveHotspot(null, true);
      }

      startHotspotMovement({
        background,
        hotspotId: id,
        mousePosition: { x: clientX, y: clientY },
      });
    },
    [productId, id, background],
  );

  useMovement(moveButtonRef, {
    onEndMovement: endHotspotMovement,
    onMovement: updatePosition,
    onStartMovement: startMovement,
  });

  const handleSetProduct = React.useCallback(
    (newProductId: Id | null) => {
      setHotspotProduct(id, newProductId);
    },
    [setHotspotProduct, id],
  );

  const tooltipContent =
    isActiveHotspot ?
      <ProductPicker selectedId={productId} updateProductId={handleSetProduct} />
    : <HotspotTooltipContent productInfo={productInfo} productId={productId} />;

  return (
    <Tooltip
      content={tooltipContent}
      placement={isActiveHotspot ? 'bottom-start' : 'bottom-center'}
      visible={isTooltipVisible}
      overlayClassName={cx({ [styles.selectorTooltip]: isActiveHotspot })}
      hideArrow={isActiveHotspot}
      autoHide={false}
      overflowContainer={isActiveHotspot}
      autoPlacement={isActiveHotspot}
    >
      <div style={{ left, top }} className={styles.hotspotWrapper}>
        <button
          className={cx({ [styles.inactiveProduct]: hasInactiveProduct }, styles.hotspot, styles.editor)}
          aria-label={t('cms:move_hotspot')}
          ref={moveButtonRef}
        />
        <button
          className={cx(styles.editButton, {
            [styles.hidden]: isActiveHotspot || isMovingHotspot,
          })}
          aria-label={t('cms:edit_hotspot')}
          onClick={handleOnClick}
          data-testid={`hotspotEdit-${id}`}
        >
          <Icon type={IconType.Edit} color={IconColor.White} />
        </button>
      </div>
    </Tooltip>
  );
};
