import i18next from 'i18next';
import { combineEpics } from 'redux-observable';
import { filter, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { addToast } from '../components/various/Toasts';
import { fetchMarketsFailure, fetchMarketsRequest, fetchMarketsSuccess } from '../ducks';
import { mapResponse } from '../utils/operators/mapResponse';

const fetchMarketsEpic: AppEpic = (action$, _, { marketsRepository }) =>
  action$.pipe(
    filter(isActionOf(fetchMarketsRequest)),
    mergeMap(marketsRepository.getMarkets),
    mapResponse(
      res => fetchMarketsSuccess(res.data),
      () => {
        addToast(i18next.t('genericErrors:fetch_markets_fail'));

        return fetchMarketsFailure();
      },
    ),
  );

export const marketsEpic = combineEpics(fetchMarketsEpic);
