import { Product as ProductType } from '@typings';
import React from 'react';

import { GetProductUrl } from '../../../logic/products';
import { Product } from '../Product';
import { ProductLine } from '../ProductLine';

interface Props {
  showGridView: boolean;
  product: ProductType.Full;
  isLookbook: boolean;
  isFirst: boolean;
  getProductUrl: GetProductUrl;
}

const ProductsListItemComponent = ({ showGridView, product, isLookbook, getProductUrl, isFirst }: Props) => {
  if (showGridView) {
    return <Product productInfo={product} getProductUrl={getProductUrl} shouldHideButtons={isLookbook} />;
  }

  return <ProductLine product={product} showHelp={isFirst} />;
};

export const ProductsListItem = React.memo(ProductsListItemComponent);
