import { Translations } from '@typings';
import { prop, sortBy } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { DEFAULT_LANGUAGES_CODES, FALLBACK_LANGUAGE_CODE, LANGUAGE_FLAG_ICON_MAP, LANGUAGE_NAME_MAP } from '../../../constants/translation';
import { getAdditionalLanguagesCodes } from '../../../ducks';

const codeToLanguageDetails = <T extends Translations.SupportedLanguagesCodes>(code: T): Translations.LanguageDetails<T> => ({
  code,
  flagIcon: LANGUAGE_FLAG_ICON_MAP[code],
  label: LANGUAGE_NAME_MAP[code],
});

export const useTranslationsConfig = () => {
  const { i18n } = useTranslation();
  const additionalLanguagesCodes = useSelector(getAdditionalLanguagesCodes) ?? [];

  // The i18n.language may (depending on browser) return the full locale language e.g. en-US, which we do not want.
  // First element of i18n.languages provides the correct language according to the configuration of i18next.
  const [firstBrowserLanguageCode] = i18n.languages as Translations.SupportedLanguagesCodes[];
  const currentLanguageCode = firstBrowserLanguageCode ?? FALLBACK_LANGUAGE_CODE;
  const supportedLanguagesCodes = React.useMemo(() => Object.keys(LANGUAGE_NAME_MAP) as Translations.SupportedLanguagesCodes[], []);

  const defaultLanguages = React.useMemo(() => DEFAULT_LANGUAGES_CODES.map(codeToLanguageDetails), []);

  const additionalLanguages = React.useMemo(
    () => [...additionalLanguagesCodes].sort().map(codeToLanguageDetails),
    [additionalLanguagesCodes],
  );

  const supportedLanguages = React.useMemo(() => [...supportedLanguagesCodes].sort().map(codeToLanguageDetails), [supportedLanguagesCodes]);

  const availableLanguages = React.useMemo(
    () => sortBy(prop('code'), [...defaultLanguages, ...additionalLanguages]),
    [defaultLanguages, additionalLanguages],
  );
  const areMultipleLanguagesAvailable = availableLanguages.length > 1;

  const getIsDefaultLanguage = React.useCallback(
    (code: string) => {
      return defaultLanguages.findIndex(language => language.code === code) > -1;
    },
    [defaultLanguages],
  );

  return {
    additionalLanguages,
    areMultipleLanguagesAvailable,
    availableLanguages,
    currentLanguageCode,
    defaultLanguages,
    getIsDefaultLanguage,
    supportedLanguages,
  };
};
