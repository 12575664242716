import React from 'react';

import { useIsReorderingContent } from '../../../../../utils/hooks';
import { LazyRender } from '../../../../various/LazyRender';

interface Props {
  className?: string;
  style?: React.CSSProperties;
  src: string;
}

const VideoBackground = ({ className, style, src }: Props) => {
  const isReorderingContent = useIsReorderingContent();

  const videoElement = <video className={className} style={style} src={src} autoPlay loop muted controls />;

  if (isReorderingContent) {
    return videoElement;
  }

  return (
    <LazyRender estimatedHeight="100%" triggerOnce>
      {videoElement}
    </LazyRender>
  );
};

export default VideoBackground;
