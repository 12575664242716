import { Translations } from '@typings';

import { IconType } from '../components/various/Icon';
import { de } from '../translation/resources/de';
import { en } from '../translation/resources/en';
import { fr } from '../translation/resources/fr';
import { sv } from '../translation/resources/sv';

export const TRANSLATION_RESOURCES = {
  de,
  en,
  fr,
  sv,
} as const;

export const FALLBACK_LANGUAGE_CODE: Translations.SupportedLanguagesCodes = 'en';

export const DEFAULT_LANGUAGES_CODES: Translations.DefaultLanguageCode[] = [FALLBACK_LANGUAGE_CODE];

export const LANGUAGE_NAME_MAP = {
  de: 'Deutsch',
  en: 'English',
  fr: 'Français',
  sv: 'Svenska',
} as const;

export const LANGUAGE_FLAG_ICON_MAP = {
  de: IconType.FlagDe,
  en: IconType.FlagEn,
  fr: IconType.FlagFr,
  sv: IconType.FlagSv,
} as const;
