import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import { $getSelection, COMMAND_PRIORITY_LOW, SELECTION_CHANGE_COMMAND } from 'lexical';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { isDefined } from '../../../../../../utils/is';
import { isEmpty } from '../../../../../../utils/isEmpty';
import { LinkMarkerActions } from '../../../../../cms/TextLink/LinkMarkerActions';
import linkStyles from '../../../../../cms/TextLink/TextLink.module.scss';
import { IconType } from '../../../../../various/Icon';
import { TextEllipsis } from '../../../../../various/TextEllipsis';

const MARKER_OFFSET = {
  left: 5,
  top: -8,
};

interface Props {
  isLink: boolean;
  linkUrl: string;
  openModal: () => void;
  removeLink: () => void;
}

export const TextLinkMarker = ({ isLink, linkUrl, openModal, removeLink }: Props) => {
  const { t } = useTranslation(['cms']);
  const [editor] = useLexicalComposerContext();
  const ref = React.useRef<HTMLDivElement>(null);
  const [selectionPosition, setSelectionPosition] = React.useState<Nullable<{ left: number; top: number }>>(null);

  const updateLinkMarker = React.useCallback(() => {
    const selection = $getSelection();

    const popupCharStylesEditorElem = ref.current;
    const nativeSelection = window.getSelection();

    if (!isDefined(popupCharStylesEditorElem)) {
      return;
    }

    const rootElement = editor.getRootElement();
    if (
      isDefined(selection) &&
      isDefined(nativeSelection) &&
      isDefined(rootElement) &&
      ((rootElement.contains(nativeSelection.anchorNode) && !nativeSelection.isCollapsed) || isLink)
    ) {
      const domRange = nativeSelection.getRangeAt(0);
      const rootElementRect = rootElement.getBoundingClientRect();
      const rect = domRange.getBoundingClientRect();

      setSelectionPosition({
        left: rect.left + MARKER_OFFSET.left - rootElementRect.left + rect.width,
        top: rect.top + MARKER_OFFSET.top - rootElementRect.top,
      });
    }
  }, [editor, isLink]);

  React.useEffect(() => {
    editor.getEditorState().read(() => {
      updateLinkMarker();
    });

    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateLinkMarker();
        });
      }),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateLinkMarker();

          return false;
        },
        COMMAND_PRIORITY_LOW,
      ),
    );
  }, [editor, updateLinkMarker]);

  const addEditActionName = isEmpty(linkUrl) ? t('cms:add_link') : t('cms:change_link');

  const actions = [
    {
      actionName: addEditActionName,
      dataTestId: 'addLink',
      hidden: false,
      iconType: !isLink ? IconType.Link : IconType.Edit,
      onClick: openModal,
    },
    {
      actionName: t('cms:unlink'),
      hidden: !isLink,
      iconType: IconType.Unlink,
      onClick: removeLink,
    },
  ];

  return (
    <div ref={ref}>
      {selectionPosition && (
        <div style={{ ...selectionPosition }} className={linkStyles.linkMarker}>
          {isLink && (
            <TextEllipsis as="div" className={linkStyles.linkText}>
              {linkUrl}
            </TextEllipsis>
          )}
          <LinkMarkerActions actions={actions} />
        </div>
      )}
    </div>
  );
};
