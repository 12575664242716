import { Id, Navigation } from '@typings';
import { uniq } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getMarkets } from '../../../../../ducks';
import { isDefined } from '../../../../../utils/is';
import { isEmpty } from '../../../../../utils/isEmpty';
import { ExpandableElement } from '../../../../various/ExpandableContainer/ExpandableElement';
import { Input } from '../../../../various/Fields/Input';
import { FieldLabel } from '../../../../various/Form';
import { ListItem } from '../../../ListItem';
import { ListItemHead } from '../../../ListItem/ListItemHead';
import { ListItemTitle } from '../../../ListItem/ListItemTitle';
import { MenuItemMarkets } from '../../../MenuItemMarkets';
import { useMenuSectionContext } from '../../context/MenuSectionContext';

import styles from './AddedMenuItem.module.scss';
import { AddedMenuItemActions } from './AddedMenuItemActions';

interface Props {
  onRemoveMenuItem: (id: Id) => void;
  onLabelChange: (value: string, id: Id) => void;
  onToggleOpen: (id: Id) => void;
  isOpen: boolean;
  item: Navigation.FolderMenuItem;
}

export const FolderMenuItem = ({ onRemoveMenuItem, onLabelChange, isOpen, onToggleOpen, item }: Props) => {
  const { t } = useTranslation(['menu', 'settings']);

  const { areInputsDisabled } = useMenuSectionContext();

  const handleLabelChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onLabelChange(event.target.value, item.id);
    },
    [onLabelChange, item.id],
  );

  const { getItemMarketsIds } = useMenuSectionContext();
  const allMarkets = useSelector(getMarkets);

  const handleRemoveMenuItem = React.useCallback(() => {
    onRemoveMenuItem(item.id);
  }, [onRemoveMenuItem, item.id]);

  const handleToggleOpen = React.useCallback(() => {
    onToggleOpen(item.id);
  }, [item.id]);

  if (!isDefined(item)) {
    return null;
  }

  const childItems = item.children ?? [];
  const childMarkets = uniq(childItems.flatMap(getItemMarketsIds))
    .map(marketId => allMarkets.find(market => market.id === marketId)?.name)
    .filter(isDefined);

  return (
    <ListItem isOpen={isOpen}>
      <ListItemHead
        action={
          <AddedMenuItemActions
            label={item.label}
            id={item.id}
            handleRemoveMenuItem={handleRemoveMenuItem}
            handleToggleOpen={handleToggleOpen}
            isOpen={isOpen}
          />
        }
      >
        <ListItemTitle isFolder name={item.label} />
        {isDefined(childMarkets) && <MenuItemMarkets markets={childMarkets} emptyMarketsInfo={t('menu:by_nested_items')} />}
      </ListItemHead>
      <ExpandableElement id={`menuItemOptions-${item.id}`} isOpen={isOpen}>
        <div className={styles.nameInput}>
          <FieldLabel size="regular" label={t('settings:navigation_label')} isRequired>
            <Input
              value={item.label}
              size="regular"
              isInvalid={isEmpty(item.label)}
              isDisabled={areInputsDisabled}
              onChange={handleLabelChange}
            />
          </FieldLabel>
        </div>
      </ExpandableElement>
    </ListItem>
  );
};
