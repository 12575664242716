import Icon, { IconType } from '../../../various/Icon';

import styles from './RemoveButton.module.scss';

interface Props {
  onClick: () => void;
}

export const RemoveButton = ({ onClick }: Props) => (
  <button className={styles.removeButton} onClick={onClick} data-testid="removeButton">
    <Icon type={IconType.Trash} />
  </button>
);
