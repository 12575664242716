/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 72;
const ORIGINAL_HEIGHT = 65;
const PROPORTION = ORIGINAL_HEIGHT / ORIGINAL_WIDTH;
const ORIGINAL_COLOR = '#d8d8d8';

export const Synced = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => {
  return (
    <svg width={width} height={width * PROPORTION} viewBox="0 0 72 65" version="1.1">
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Desktop-HD" transform="translate(-986.000000, -225.000000)" fill={color}>
          <path
            d="M1049.75022,251.925095 C1054.70024,256.050109 1058.00025,262.125131 1058.00025,267.975151 C1058.00025,277.500185 1049.45022,287.47522 1038.72519,288.750224 C1033.17517,289.350226 1027.55015,289.575227 1021.92513,289.575227 C1016.37511,289.575227 1010.75009,289.350226 1005.20007,288.750224 C994.47503,287.47522 986,277.500185 986,267.975151 C986,262.275131 989.075011,256.27511 993.800027,252.150095 C993.725027,251.475093 993.650027,250.800091 993.650027,250.125088 C993.650027,242.325061 999.950049,236.025039 1007.75008,236.025039 C1008.42508,236.025039 1009.10008,236.100039 1009.77508,236.175039 C1013.6001,229.875017 1021.17512,225 1029.05015,225 C1041.12519,225 1050.20023,235.500037 1050.20023,247.575079 C1050.20023,249.075085 1050.05023,250.50009 1049.75022,251.925095 Z M1019.2813,277.202834 C1020.63131,277.127833 1021.83131,276.527831 1022.73132,275.552828 L1040.28138,255.977759 C1042.00638,253.952752 1041.78138,250.952741 1039.83138,249.227735 C1037.80637,247.427729 1034.80636,247.65273 1033.00635,249.602737 L1018.9063,265.577793 L1012.23128,258.752769 C1010.35627,256.877762 1007.28126,256.877762 1005.40625,258.752769 C1003.53125,260.627775 1003.53125,263.627786 1005.40625,265.502792 L1015.75629,275.777829 C1016.65629,276.677832 1017.8563,277.202834 1019.1313,277.202834 L1019.2813,277.202834 Z"
            id="Combined-Shape"
          />
        </g>
      </g>
    </svg>
  );
};
