import cx from 'classnames';
import React from 'react';
import { useHover } from 'react-laag';
import { useDispatch, useSelector } from 'react-redux';

import { Checkboxes, DeliveryWindow } from '../../../../../../../../typings';
import { DEFAULT_HOVER_DELAY } from '../../../../../../constants/delays';
import { getIsFilterPanelPinned, pushEvent } from '../../../../../../ducks';
import { DelwinFilterTrackingEvent } from '../../../../../../utils/analytics/events';
import { useIsTouchScreen } from '../../../../../../utils/hooks/useIsTouchScreen';
import { isDefined } from '../../../../../../utils/is';
import { DelwinNameAndDate } from '../../../../../various/DelwinNameAndDate';
import { Checkbox } from '../../../../../various/Fields/Checkbox';
import Icon, { IconType } from '../../../../../various/Icon';
import { Popover } from '../../../../../various/Popover';
import { useDeliveryWindowFilterContext } from '../DeliveryWindowFilterContext';
import styles from '../FiltersDeliveryWindows.module.scss';

import { CheckboxGroupOptions } from './CheckboxGroupOptions';

interface DeliveryWindowFilterGroupProps {
  group: DeliveryWindow.DeliveryWindowFilterGroup;
  optionsState: Checkboxes.OptionsState;
  toggleOptionState: (changedValue: string) => void;
}

export const DeliveryWindowFilterGroup = ({ group, optionsState, toggleOptionState }: DeliveryWindowFilterGroupProps) => {
  const { groupId } = group;
  const optionState = optionsState[groupId];
  const dispatch = useDispatch();
  const { openedPopupId, setOpenedPopupId, openGroup } = useDeliveryWindowFilterContext();
  const [hasClickedInside, setHasClickedInside] = React.useState(false);
  const isFilterPanelPinned = useSelector(getIsFilterPanelPinned);
  const [isOver, hoverProps] = useHover({
    delayEnter: DEFAULT_HOVER_DELAY,
    delayLeave: DEFAULT_HOVER_DELAY,
    hideOnScroll: false,
  });
  const isTouchScreen = useIsTouchScreen();

  React.useEffect(() => {
    const hasMatchingId = isDefined(openedPopupId) && groupId === openedPopupId;

    if (isOver && !hasMatchingId) {
      setOpenedPopupId(null);
    }
  }, [groupId, isOver, openedPopupId, setOpenedPopupId]);

  React.useEffect(() => {
    if (isFilterPanelPinned && hasClickedInside && groupId !== openedPopupId) {
      setOpenedPopupId(groupId);
      setHasClickedInside(false);
    }
  }, [hasClickedInside, isFilterPanelPinned, openedPopupId, groupId, setOpenedPopupId]);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      toggleOptionState(event.target.value);

      if (!isFilterPanelPinned || isTouchScreen) {
        return;
      }

      setHasClickedInside(true);
      setOpenedPopupId(groupId);
    },
    [groupId, isFilterPanelPinned, isTouchScreen, setOpenedPopupId, toggleOptionState],
  );

  const handleGroupChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleChange(event);
      dispatch(pushEvent({ event: DelwinFilterTrackingEvent.GROUP_FILTER_USED }));
    },
    [dispatch, handleChange],
  );

  const handleDeliveryWindowChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleChange(event);
      dispatch(pushEvent({ event: DelwinFilterTrackingEvent.DELWIN_FILTER_USED }));
    },
    [dispatch, handleChange],
  );

  const handleClose = React.useCallback(() => {
    setHasClickedInside(false);
    setOpenedPopupId(null);
  }, [setOpenedPopupId]);

  const childOptions = React.useMemo(
    () =>
      group.items.map(deliveryWindow => ({
        name: <DelwinNameAndDate deliveryWindow={deliveryWindow} shouldStretch />,
        value: deliveryWindow.deliveryWindow,
      })),
    [group],
  );

  const isVisible = openedPopupId === groupId || isOver;

  if (isTouchScreen) {
    return (
      <Checkbox
        onChange={handleGroupChange}
        value={groupId}
        checked={optionState === 'checked'}
        indeterminate={optionState === 'indeterminate'}
        className={styles.checkboxWrapper}
      >
        <button className={styles.optionButton} onClick={() => openGroup(group)}>
          {group.name}
          <Icon type={IconType.ArrowDown} rotation={-90} size={16} />
        </button>
      </Checkbox>
    );
  }

  return (
    <Popover
      content={
        <CheckboxGroupOptions
          onChange={handleDeliveryWindowChange}
          optionsState={optionsState}
          optionChildren={childOptions}
          hoverProps={hoverProps}
          onClickOutside={handleClose}
        />
      }
      triggerOffset={0}
      placement="right-start"
      possiblePlacements={['bottom-end']}
      visible={isVisible}
      className={styles.popover}
      triggerClassName={styles.popoverTrigger}
      containerOffset={140}
      onVisibleChange={handleClose}
      shouldReturnFocusAfterClose={false}
    >
      <div className={styles.triggerOffsetPadding} {...hoverProps}>
        <Checkbox
          onChange={handleGroupChange}
          value={groupId}
          checked={optionState === 'checked'}
          indeterminate={optionState === 'indeterminate'}
          className={cx(styles.checkboxWrapper, { [styles.active]: isVisible })}
        >
          <span className={cx(styles.checkboxGroupLabel)}>
            {group.name}
            <Icon type={IconType.ArrowDown} rotation={-90} className={styles.checkboxIcon} size={16} />
          </span>
        </Checkbox>
      </div>
    </Popover>
  );
};
