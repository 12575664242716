import { Cms, Translations } from '@typings';
import { createSelector } from 'reselect';

import { EMPTY_ARRAY } from '../../../constants/empty';
import { getHasUnpublishedChanges, getIsPublished } from '../../../logic/pages';
import { isDefined } from '../../../utils/is';

export const getPageMetaData = (store: Store) => store.cms.pageMeta.data;

export const getAvailablePageLanguageCodes = (store: Store) => store.cms.pageMeta.data?.availableLanguages ?? EMPTY_ARRAY;

export const getPageLanguages = (store: Store) => store.cms.pageMeta.data?.languages ?? EMPTY_ARRAY;
export const getIsLanguagePublishedWithoutChanges = (languageCode: Translations.SupportedLanguagesCodes) =>
  createSelector(getPageLanguages, (pageLanguages: Cms.PageLanguage[]) => {
    const languageStatus = pageLanguages.find(language => language.code === languageCode)?.status;

    return getIsPublished(languageStatus) && !getHasUnpublishedChanges(languageStatus);
  });

export const getIsLoadingPageMeta = (store: Store) => !isDefined(store.cms.pageMeta.data);
