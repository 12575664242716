import { Id, Product } from '@typings';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { useIsInViewMode, useProductPriceComponents } from '../../../../../utils/hooks';
import { isDefined } from '../../../../../utils/is';

import styles from './Hotspots.module.scss';

interface Props {
  productInfo: Product.Standard | undefined;
  productId: Nullable<Id>;
}

export const HotspotTooltipContent = ({ productInfo, productId }: Props) => {
  const { t } = useTranslation(['cms']);
  const isInEditor = useIsInViewMode('editor');

  const { formattedPrice } = useProductPriceComponents(productInfo);

  if (!isDefined(productInfo)) {
    return <>{t('cms:missing_product_with_id', { id: productId })}</>;
  }

  return (
    <div className={cx(styles.hotspotTooltip, { [styles.clickable]: !isInEditor })}>
      {productInfo.name}
      {isDefined(formattedPrice) && <span>{formattedPrice}</span>}
    </div>
  );
};
