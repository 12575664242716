import cx from 'classnames';
import React from 'react';

import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { IconType } from '../Icon';
import Icon from '../Icon/Icon';

import styles from './Button.module.scss';

export type ButtonSize = 'mini' | 'small' | 'regular' | 'large';
export type ButtonColor = 'primary' | 'dark' | 'success' | 'danger';
export type ButtonVariant = 'solid' | 'shadow' | 'ghost' | 'flat' | 'bordered';

const ICON_SIZES = {
  large: 20,
  mini: 16,
  regular: 20,
  small: 20,
};

interface ButtonProps<T extends React.ElementType> {
  as?: T;
  children?: string | false;
  color?: ButtonColor;
  disabled?: boolean;
  icon?: IconType;
  isFluid?: boolean;
  isLoading?: boolean;
  size?: ButtonSize;
  variant?: ButtonVariant;
}

export type NewButtonProps<T extends React.ElementType> = ButtonProps<T> & Omit<React.ComponentPropsWithoutRef<T>, keyof ButtonProps<T>>;

export const Button = React.forwardRef(
  <T extends React.ElementType = 'button'>(props: NewButtonProps<T>, ref: React.ForwardedRef<HTMLButtonElement>) => {
    const {
      as: Component = 'button',
      children,
      className,
      color = 'primary',
      disabled,
      icon,
      isFluid,
      isLoading,
      size = 'regular',
      variant = 'solid',
      ...rest
    } = props;

    const hasIcon = isDefined(icon) || isLoading;
    const hasChildren = isDefined(children) && children !== false && !isEmpty(children);
    const isIconOnly = hasIcon && !hasChildren;
    const iconSize = ICON_SIZES[size];

    const classNames = cx(styles.button, className, {
      [styles.sizeMini]: size === 'mini',
      [styles.sizeSmall]: size === 'small',
      [styles.sizeLarge]: size === 'large',
      [styles[`${color}Color`]]: color,
      [styles[`${variant}Variant`]]: variant,
      [styles.fluid]: isFluid,
      [styles.iconOnly]: isIconOnly,
      [styles.disabled]: disabled,
      [styles.loading]: isLoading,
    });

    const buttonProps =
      Component === 'button' ?
        {
          disabled: disabled || isLoading,
          type: 'button' as const,
        }
      : {};

    return (
      <Component ref={ref} className={classNames} {...buttonProps} {...rest}>
        {hasChildren && <span className={styles.label}>{children}</span>}
        {icon && !isLoading && <Icon type={icon} size={iconSize} className={styles.icon} />}
        {isLoading && <Icon size={iconSize} type={IconType.Spinner} />}
      </Component>
    );
  },
);
