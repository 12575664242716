import { AxiosError } from 'axios';

import { isDefined } from './is';

export const getError = (error: { data: string | Responses.Errors }): Responses.Errors => {
  return typeof error.data === 'string' ? { errors: { error: error.data } } : error.data;
};

export const getErrorMessages = (errors: Responses.Errors['errors']) => (typeof errors === 'string' ? [errors] : Object.values(errors));

export const getResponseErrors = (error: AxiosError<Responses.Errors>, defaultErrorMessage?: string) => {
  const errors = error.response?.data.errors;

  if (!isDefined(errors)) {
    return [defaultErrorMessage ?? error.message];
  }

  return getErrorMessages(errors);
};
