import { API } from '@typings';
import { HttpStatusCode } from 'axios';
import i18next from 'i18next';

import { selectionImportFailure } from '../../ducks';
import { getError } from '../../utils/errors';

export const handleSelectionImportFailure = (error: API.FailedResponse<string>) => {
  if (error.statusCode === HttpStatusCode.MovedPermanently) {
    return selectionImportFailure({ errors: i18next.t('selections:selection_was_closed'), wasSelectionClosed: true });
  }

  if (error.statusCode === HttpStatusCode.NotFound) {
    return selectionImportFailure({ errors: i18next.t('selections:selection_not_found'), wasSelectionRemoved: true });
  }

  return selectionImportFailure(getError(error));
};
