import { AccountMarket, Id } from '@typings';
import cx from 'classnames';
import { identity, sortBy } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { compiledPaths } from '../../../paths';
import Icon, { IconType } from '../../various/Icon';
import { Link } from '../../various/Link';
import { TextEllipsis } from '../../various/TextEllipsis';

import styles from './PreviewListItem.module.scss';

interface Props {
  name: string;
  slug: string;
  markets: AccountMarket[];
  id: Id;
  isDisabled?: boolean;
}

export const PreviewListItem = (props: Props) => {
  const { name, slug, markets, id, isDisabled = false } = props;
  const { t } = useTranslation(['common']);
  const marketNames = React.useMemo(() => markets.map(market => market.name), [markets]);

  return (
    <div className={styles.listItem}>
      <div className={styles.listItemHead}>
        <div className={styles.pageInfo}>
          <TextEllipsis as="div" noWrapText={false} className={styles.nameContainer}>
            <TextEllipsis className={cx(styles.name, { [styles.disabled]: isDisabled })}>{name}</TextEllipsis>
            <Link variant="blue" target="_blank" to={compiledPaths.CMS_PREVIEW({ pageId: id })}>
              <TextEllipsis noWrapText={false} aria-label={t('common:preview')}>
                {`/${slug}`}
              </TextEllipsis>
            </Link>
          </TextEllipsis>
          <TextEllipsis as="div" className={styles.marketInfo}>
            <span>{t('common:market')}: </span>
            {sortBy(identity, marketNames).join(', ')}
          </TextEllipsis>
        </div>
        <div>
          <Link target="_blank" rel="noopener noreferrer" aria-label={t('common:preview')} to={compiledPaths.CMS_PREVIEW({ pageId: id })}>
            <Icon type={IconType.Visibility} />
          </Link>
        </div>
      </div>
    </div>
  );
};
