import { Form, Textarea as TextareaNs } from '@typings';
import React from 'react';
import { UseFormRegisterReturn, useFormState } from 'react-hook-form';

import { isDefined } from '../../../../utils/is';
import { Textarea } from '../../Fields/Textarea';

import { FieldWrapper } from './FieldParts';

type TextareaProps = Omit<Partial<UseFormRegisterReturn>, 'ref'> & TextareaNs.Props;

interface Props extends TextareaProps, Form.FieldProps {
  name: string;
}

export const TextareaField = React.forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
  const { label, name, helpMessage, isRequired, placeholder, orientation, description, ...rest } = props;

  const errorId = React.useId();
  const describedById = React.useId();

  const { errors } = useFormState({ name });
  const errorMessage = errors[name]?.message as string | undefined;

  return (
    <FieldWrapper
      description={description}
      describedById={describedById}
      helpMessage={helpMessage}
      errorId={errorId}
      errorMessage={errorMessage}
      isRequired={isRequired}
      label={label}
      orientation={orientation}
    >
      <Textarea
        ref={ref}
        {...rest}
        describedById={isDefined(description) ? describedById : undefined}
        errorMessageId={isDefined(errorMessage) ? errorId : undefined}
        isInvalid={isDefined(errorMessage)}
        name={name}
        placeholder={placeholder ?? label}
        isRequired={isRequired}
      />
    </FieldWrapper>
  );
});
