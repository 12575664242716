/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;

export const Home = ({ width = ORIGINAL_WIDTH, color = 'currentColor' }: IconComponentProps) => (
  <svg width={width} height={width} viewBox="0 0 20 20" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 4.416L4.845 9.353v6.292h2.893v-3.23c0-.319.12-.63.342-.863.223-.235.532-.373.86-.373h2.12c.328 0 .637.138.86.373.222.234.342.544.342.863v3.23h2.893V9.353L10 4.416zm-.822-1.083C9.398 3.123 9.691 3 10 3a1.185 1.185 0 01.822.334l5.297 5.072.009.01a1.258 1.258 0 01.372.883v6.465c0 .318-.12.63-.342.863a1.186 1.186 0 01-.86.373h-3.179c-.329 0-.638-.138-.86-.373a1.254 1.254 0 01-.342-.863v-3.23H9.083v3.23c0 .318-.12.63-.341.863A1.186 1.186 0 017.88 17H4.702c-.329 0-.638-.138-.86-.373a1.254 1.254 0 01-.342-.863V9.3a1.288 1.288 0 01.372-.884l.01-.009 5.295-5.072h.001z"
      fill={color}
    />
  </svg>
);
