import { Cms } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useToolbarVisibility } from '../../../../utils/hooks';
import { TextEllipsis } from '../../../various/TextEllipsis';
import { StyleSelectContext } from '../../context/StyleSelectContext';

import styles from './ContentPartStyleSelect.module.scss';

interface Props {
  partStyle: Cms.ContentPartStyle;
  isFocused?: boolean;
  onBlur?: () => void;
}

export const DeleteStyleButton = ({ partStyle, isFocused, onBlur }: Props) => {
  const { isTextStyle, setConfirmationModalData, setPartStyleToDelete } = React.useContext(StyleSelectContext);
  const toolbarName = isTextStyle ? 'Text' : 'Button';
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const { hideToolbar } = useToolbarVisibility(toolbarName);
  const { t } = useTranslation(['cms']);

  const deleteButtonText = t(isTextStyle ? 'cms:delete_text_style' : 'cms:delete_button_style');

  const handleDeleteTextStyle = React.useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      hideToolbar();
      setPartStyleToDelete(partStyle);
      setConfirmationModalData();
    },
    [hideToolbar, setPartStyleToDelete, partStyle, setConfirmationModalData],
  );

  React.useEffect(() => {
    if (isFocused) {
      buttonRef.current?.focus();
    } else {
      onBlur?.();
    }
  }, [isFocused]);

  return (
    <div role="menuitem">
      <button
        ref={buttonRef}
        tabIndex={-1}
        className={styles.deleteTextStyleButton}
        onClick={handleDeleteTextStyle}
        data-testid="deleteStyleButton"
      >
        <TextEllipsis as="div">{deleteButtonText}</TextEllipsis>
      </button>
    </div>
  );
};
