import { Cms, Id } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { getIsCurrentGroup, getIsManualLayoutEditorEnabledInGroup } from '../../../ducks/cms/ui';
import { getBlockGridPlacement } from '../../../logic/cms/styles';
import { getIsProductBlock,getScreenRelativeStyles } from '../../../logic/pages';
import { useIsInViewMode, useSpecifiedDeviceWidth } from '../../../utils/hooks';
import { useLocalizedBlock } from '../../../utils/hooks/cms/useLocalizedBlock';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { SizeContext } from '../../cms/context/SizeContext';
import { ContentBlock } from '../contentBlock';
import { ProductBlock } from '../productBlock';

import styles from './genericBlock.module.scss';

interface Props {
  blockId: Id;
  className?: string;
  style?: Record<string, unknown>;
}

interface BlockContentProps {
  parameters: Cms.AnyBlock;
  rowHeight: number;
  isManualLayoutEditing: boolean;
}

const BlockContent = React.memo((props: BlockContentProps) => {
  const { parameters, ...baseProps } = props;

  switch (parameters.blockType) {
    case 'content':
      return <ContentBlock parameters={parameters} {...baseProps} />;
    case 'product':
      return <ProductBlock parameters={parameters} {...baseProps} />;
    default:
      return null;
  }
});

export const GenericBlock = ({ blockId, className, style }: Props) => {
  const blockData = useLocalizedBlock({ blockId });
  const isManualLayoutEditorEnabled = useSelector(getIsManualLayoutEditorEnabledInGroup(blockData?.groupId));
  const isCurrentGroup = useSelector(getIsCurrentGroup(blockData?.groupId));
  const isEditorView = useIsInViewMode('editor');
  const { rowHeight } = React.useContext(SizeContext);
  const screenWidth = useSpecifiedDeviceWidth();
  const isManualLayoutEditing = isManualLayoutEditorEnabled && isCurrentGroup;
  const blockDataPosition = blockData?.position;

  const ownStyle = React.useMemo(() => {
    if (!isDefined(blockDataPosition)) {
      return null;
    }

    const position = getScreenRelativeStyles(screenWidth, blockDataPosition);

    return getBlockGridPlacement(position);
  }, [blockDataPosition, screenWidth]);

  if (!isDefined(blockData)) {
    return null;
  }

  const classNames = cx(styles.genericBlock, className, {
    [styles.empty]: !isEditorView && getIsProductBlock(blockData) && isEmpty(blockData.settings.products),
  });

  return (
    <div className={classNames} style={{ ...ownStyle, ...style }}>
      <BlockContent isManualLayoutEditing={isManualLayoutEditing} parameters={blockData} rowHeight={rowHeight} />
    </div>
  );
};
