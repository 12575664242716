import React from 'react';

import { Portal } from '../../components/various/Portal';

export const useStickyMouseTooltip = ({ className }: { className: string }) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const [position, setPosition] = React.useState({ left: 0, top: 0 });
  const [text, setText] = React.useState<React.ReactNode>();

  const handleMouseOut = React.useCallback(() => {
    setIsVisible(false);
  }, []);

  const handleMouseMove = React.useCallback((event: React.MouseEvent) => {
    setPosition({ left: Number(event.pageX) + 10, top: event.pageY - 25 });
  }, []);

  const handleMouseOver = React.useCallback(
    (newText: React.ReactNode) => () => {
      setIsVisible(true);
      setText(newText);
    },
    [],
  );

  const Tooltip = () => (
    <Portal>
      <div
        className={className}
        style={{
          left: position.left,
          top: position.top,
          visibility: isVisible ? 'visible' : 'hidden',
        }}
      >
        {text}
      </div>
    </Portal>
  );

  return { Tooltip, handleMouseMove, handleMouseOut, handleMouseOver };
};
