import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;
const ORIGINAL_COLOR = '#848484';

export const TextRight = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => (
  <svg width={width} height={width} viewBox="0 0 20 20">
    <g fill={color}>
      <path d="m17.077 11.3847h-8.61536c-.49233 0-.92307-.4308-.92307-.9231 0-.49229.43079-.92303.92307-.92303h8.61536c.4924 0 .9231.43079.9231.92303 0 .4923-.4308.9231-.9231.9231z" />
      <path d="m17.0769 5.84613h-14.15384c-.49227 0-.92306-.43074-.92306-.92307s.43079-.92306.92306-.92306h14.15384c.4923 0 .923.43079.923.92306 0 .49228-.4307.92307-.923.92307z" />
      <path d="m17.0769 16.923h-14.15384c-.49227 0-.92306-.4307-.92306-.923 0-.4924.43079-.9231.92306-.9231h14.15384c.4923 0 .923.4308.923.9231 0 .4922-.4307.923-.923.923z" />
    </g>
  </svg>
);
