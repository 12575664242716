/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;
const ORIGINAL_COLOR = '#848484';

export const Accept = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => (
  <svg width={width} height={width} viewBox="0 0 20 20" fill="none">
    <path
      d="M15.7179 5.10982L15.7179 5.10982L7.41859 13.1821L4.28215 10.1315C4.28215 10.1315 4.28214 10.1314 4.28214 10.1314C3.96026 9.81834 3.43981 9.81839 3.11789 10.1314L3.11789 10.1314C2.79403 10.4464 2.79404 10.9585 3.11788 11.2735L6.83645 14.8903C7.15828 15.2034 7.67907 15.2031 8.00072 14.8903L16.8821 6.25189C17.206 5.93693 17.2059 5.4248 16.8821 5.10982C16.5603 4.79672 16.0398 4.79673 15.7179 5.10982Z"
      fill={color}
      stroke={color}
      strokeWidth="0.25"
    />
  </svg>
);
