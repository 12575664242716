import { Cms, Translations } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { URL_ALLOWED_SYMBOLS } from '../../../constants/regExp';
import { getMarkets } from '../../../ducks';
import { useTranslationsConfig } from '../../../utils/hooks';
import { useValidatedForm } from '../../../utils/hooks/useValidatedForm';
import { isDefined } from '../../../utils/is';
import { stringToValidUrl } from '../../../utils/stringToValidUrl';
import { toCheckboxOption } from '../../../utils/toCheckboxOption';
import { CheckboxGroupField, FieldWrapper, Form, FormButtons, FormFieldset, FormGrid, InputField, TextareaField } from '../../various/Form';
import { Button } from '../../various/NewButton';

import { PageFormLanguages } from './PageFormLanguages';

interface Props {
  defaultValues?: Cms.PageDTO;
  isLoading?: boolean;
  pageLanguages?: Cms.PageLanguage[];
  onCancel: () => void;
  onSubmit: (values: Cms.PageDTO) => void;
}

export const PageForm = ({ defaultValues, isLoading = false, pageLanguages, onCancel, onSubmit }: Props) => {
  const { t } = useTranslation(['common', 'cms', 'validation']);
  const markets = useSelector(getMarkets);
  const { supportedLanguages, defaultLanguages, getIsDefaultLanguage } = useTranslationsConfig();

  const isEditMode = isDefined(defaultValues);

  const marketsOptions = React.useMemo(() => {
    return toCheckboxOption(markets, { label: 'name', value: 'id' });
  }, [markets]);

  const languagesOptions = React.useMemo(() => {
    const sortedSupportedLanguages = [...supportedLanguages].sort(
      (a, b) => Number(getIsDefaultLanguage(b.code)) - Number(getIsDefaultLanguage(a.code)),
    );

    return sortedSupportedLanguages.map(({ code, label }) => ({
      disabled: getIsDefaultLanguage(code),
      label,
      value: code,
    }));
  }, [supportedLanguages, getIsDefaultLanguage]);

  const validationSchema: yup.ObjectSchema<Cms.PageDTO> = yup.object({
    availableLanguages: yup.array(yup.string<Translations.SupportedLanguagesCodes>().defined()).required(),
    description: yup.string().defined(),
    marketIds: yup.array(yup.string().defined()).min(1, t('validation:min_one_market_hint')).required(),
    name: yup.string().required(t('validation:page_title_hint')),
    slug: yup.string().required(t('validation:page_uri_hint')).matches(URL_ALLOWED_SYMBOLS, t('validation:valid_uri_hint')),
  });

  const formMethods = useValidatedForm<Cms.PageDTO>({
    defaultValues: defaultValues ?? {
      availableLanguages: defaultLanguages.map(({ code }) => code),
      description: '',
      marketIds: markets.map(market => market.id),
      name: '',
      slug: '',
    },
    validationSchema,
  });

  const { register, setValue, getFieldState } = formMethods;

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { isDirty, error } = getFieldState('slug');

    if (!isDirty) {
      setValue('slug', stringToValidUrl(event.target.value), { shouldValidate: isDefined(error) });
    }
  };

  return (
    <Form formMethods={formMethods} onSubmit={onSubmit}>
      <FormFieldset isDisabled={isLoading}>
        <FormGrid>
          <InputField label={t('common:title')} isRequired {...register('name', { onChange: handleNameChange })} />
          <InputField label={t('common:uri')} isRequired {...register('slug')} />
          <TextareaField label={t('common:description')} {...register('description')} />
          <CheckboxGroupField label={t('cms:market_visibility')} name="marketIds" options={marketsOptions} isRequired />
          <FieldWrapper as="span" label={t('common:language_other')}>
            <PageFormLanguages isEditMode={isEditMode} options={languagesOptions} pageLanguages={pageLanguages} />
          </FieldWrapper>
        </FormGrid>
      </FormFieldset>
      <FormButtons showDivider>
        <Button size="large" variant="ghost" color="dark" disabled={isLoading} onClick={onCancel}>
          {t('common:cancel')}
        </Button>
        <Button type="submit" size="large" isLoading={isLoading}>
          {isEditMode ? t('common:save') : t('common:create')}
        </Button>
      </FormButtons>
    </Form>
  );
};
