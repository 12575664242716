import React from 'react';

import { isDefined } from '../../../utils/is';

import styles from './Info.module.scss';

interface Props {
  header: React.ReactNode;
  details?: React.ReactNode;
}

export const Info = ({ header, details }: Props) => {
  return (
    <div className={styles.info}>
      <p>{header}</p>
      {isDefined(details) && <p className={styles.infoDetails}>{details}</p>}
    </div>
  );
};
