import { Cms } from '@typings';

export const enum FontWeights {
  LIGHT = 300,
  REGULAR = 400,
  MEDIUM = 500,
  BOLD = 700,
}

export const fontWeights = [
  {
    title: 'Light',
    value: FontWeights.LIGHT,
  },
  {
    title: 'Regular',
    value: FontWeights.REGULAR,
  },
  {
    title: 'Medium',
    value: FontWeights.MEDIUM,
  },
  {
    title: 'Bold',
    value: FontWeights.BOLD,
  },
];

export const enum Fonts {
  Anton = 'Anton',
  Bitter = 'Bitter',
  Comfortaa = 'Comfortaa',
  EBGaramond = 'EB Garamond',
  HelveticaArial = 'Helvetica, Arial',
  Inconsolata = 'Inconsolata',
  Lato = 'Lato',
  Lora = 'Lora',
  Merriweather = 'Merriweather',
  Montserrat = 'Montserrat',
  NanumMyeongjo = 'Nanum Myeongjo',
  OpenSans = 'Open Sans',
  Oswald = 'Oswald',
  Poppins = 'Poppins',
  PTSerif = 'PT Serif',
  PlayfairDisplay = 'Playfair Display',
  ProximaNova = 'proxima-nova',
  Roboto = 'Roboto',
  TimesNewRoman = 'Times New Roman',
}

export const customStyleFonts: Record<string, Cms.CustomStyleFont> = {
  [Fonts.Anton]: {
    fontWeights: [FontWeights.REGULAR],
    title: 'Anton',
    url: 'https://fonts.googleapis.com/css?family=Anton:400',
  },
  [Fonts.Bitter]: {
    fontWeights: [FontWeights.REGULAR, FontWeights.BOLD],
    title: 'Bitter',
    url: 'https://fonts.googleapis.com/css?family=Bitter:400,700',
  },
  [Fonts.Comfortaa]: {
    fontWeights: [FontWeights.LIGHT, FontWeights.REGULAR, FontWeights.MEDIUM, FontWeights.BOLD],
    title: 'Comfortaa',
    url: 'https://fonts.googleapis.com/css?family=Comfortaa:300,400,500,700',
  },
  [Fonts.EBGaramond]: {
    fontWeights: [FontWeights.REGULAR, FontWeights.MEDIUM, FontWeights.BOLD],
    title: 'EB Garamond',
    url: 'https://fonts.googleapis.com/css?family=EB+Garamond:400,500,700',
  },
  [Fonts.HelveticaArial]: {
    fontWeights: [FontWeights.LIGHT, FontWeights.REGULAR, FontWeights.MEDIUM, FontWeights.BOLD],
    title: 'Helvetica/Arial',
  },
  [Fonts.Inconsolata]: {
    fontWeights: [FontWeights.LIGHT, FontWeights.REGULAR, FontWeights.MEDIUM, FontWeights.BOLD],
    title: 'Inconsolata',
    url: 'https://fonts.googleapis.com/css?family=Inconsolata:300,400,500,700',
  },
  [Fonts.Lato]: {
    fontWeights: [FontWeights.LIGHT, FontWeights.REGULAR, FontWeights.BOLD],
    title: 'Lato',
    url: 'https://fonts.googleapis.com/css?family=Lato:300,400,700',
  },
  [Fonts.Lora]: {
    fontWeights: [FontWeights.MEDIUM, FontWeights.REGULAR, FontWeights.BOLD],
    title: 'Lora',
    url: 'https://fonts.googleapis.com/css?family=Lora:400,500,700',
  },
  [Fonts.Merriweather]: {
    fontWeights: [FontWeights.LIGHT, FontWeights.REGULAR, FontWeights.BOLD],
    title: 'Merriweather',
    url: 'https://fonts.googleapis.com/css?family=Merriweather:300,400,700',
  },
  [Fonts.Montserrat]: {
    fontWeights: [FontWeights.LIGHT, FontWeights.REGULAR, FontWeights.MEDIUM, FontWeights.BOLD],
    title: 'Montserrat',
    url: 'https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700',
  },
  [Fonts.NanumMyeongjo]: {
    fontWeights: [FontWeights.REGULAR, FontWeights.BOLD],
    title: 'Nanum Myeongjo',
    url: 'https://fonts.googleapis.com/css?family=Nanum+Myeongjo:400,700',
  },
  [Fonts.OpenSans]: {
    fontWeights: [FontWeights.LIGHT, FontWeights.REGULAR, FontWeights.BOLD],
    title: 'Open Sans',
    url: 'https://fonts.googleapis.com/css?family=Open+Sans:300,400,700',
  },
  [Fonts.Oswald]: {
    fontWeights: [FontWeights.LIGHT, FontWeights.REGULAR, FontWeights.MEDIUM, FontWeights.BOLD],
    title: 'Oswald',
    url: 'https://fonts.googleapis.com/css?family=Oswald:300,400,500,700',
  },
  [Fonts.PlayfairDisplay]: {
    fontWeights: [FontWeights.REGULAR, FontWeights.MEDIUM, FontWeights.BOLD],
    title: 'Playfair Display',
    url: 'https://fonts.googleapis.com/css?family=Playfair+Display:400,500,700',
  },
  [Fonts.Poppins]: {
    fontWeights: [FontWeights.LIGHT, FontWeights.REGULAR, FontWeights.MEDIUM, FontWeights.BOLD],
    title: 'Poppins',
    url: 'https://fonts.googleapis.com/css?family=Poppins:300,400,500,700',
  },
  [Fonts.ProximaNova]: {
    fontWeights: [FontWeights.LIGHT, FontWeights.REGULAR, FontWeights.MEDIUM, FontWeights.BOLD],
    title: 'Proxima Nova',
  },
  [Fonts.PTSerif]: {
    fontWeights: [FontWeights.REGULAR, FontWeights.BOLD],
    title: 'PT Serif',
    url: 'https://fonts.googleapis.com/css?family=PT+Serif:400,700',
  },
  [Fonts.Roboto]: {
    fontWeights: [FontWeights.LIGHT, FontWeights.REGULAR, FontWeights.MEDIUM, FontWeights.BOLD],
    title: 'Roboto',
    url: 'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700',
  },
  [Fonts.TimesNewRoman]: {
    fontWeights: [FontWeights.LIGHT, FontWeights.REGULAR, FontWeights.MEDIUM, FontWeights.BOLD],
    title: 'Times New Roman',
  },
};
