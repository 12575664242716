import { Key } from './keys';

const createKeyHandler =
  (keys: Key[]) =>
  (handler?: React.KeyboardEventHandler) =>
  (event: React.KeyboardEvent): void => {
    if ((Object.values(keys) as string[]).includes(event.code)) {
      event.preventDefault();

      return handler?.(event);
    }
  };

export const handleSpaceAndEnter =
  (handler?: React.KeyboardEventHandler) =>
  (event: React.KeyboardEvent): void => {
    return createKeyHandler([Key.SPACE, Key.ENTER])(handler)(event);
  };
