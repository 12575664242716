/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;
const ORIGINAL_COLOR = 'currentColor';

export const ChevronDown = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => {
  return (
    <svg width={width} height={width} viewBox="0 0 20 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.191 7.203a.626.626 0 01.922 0L10 11.329l3.887-4.126a.626.626 0 01.922 0 .723.723 0 010 .979l-4.348 4.615a.626.626 0 01-.922 0L5.19 8.182a.723.723 0 010-.98z"
        fill={color}
      />
    </svg>
  );
};
