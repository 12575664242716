import { ActionHandler, Order } from '@typings';
import { createReducer } from 'typesafe-actions';

import { merge, update as updated } from '../../utils/update';
import { createSelectionSuccess } from '../order';

import {
  fetchMoreOrdersFailure,
  fetchMoreOrdersRequest,
  fetchMoreOrdersSuccess,
  fetchOrdersFailure,
  fetchOrdersRequest,
  fetchOrdersSuccess,
} from './actions';

export interface OrdersReducer {
  stages: {
    seller: Order.Stage[];
    buyer: Order.Stage[];
  };
  orders: {
    data: Order.Essentials[];
    hasLoadedInitial: boolean;
    isLoading: boolean;
    isLoadingMore: boolean;
    isFailed: boolean;
    orderCount: number;
  };
}

const sellerStages: Order.Stage[] = [
  { i18nKey: 'orders:stage_all', value: '' },
  { i18nKey: 'orders:stage_open', value: 'open' },
  { i18nKey: 'orders:stage_checkout_requested', value: 'checkoutRequested' },
  { i18nKey: 'orders:stage_pending', value: 'pending' },
  { i18nKey: 'orders:stage_in_progress', value: 'inProgress' },
  { i18nKey: 'orders:stage_completed', value: 'completed' },
  { i18nKey: 'orders:stage_cancelled', value: 'cancelled' },
];

const buyerStages: Order.Stage[] = [
  { i18nKey: 'orders:stage_all', value: '' },
  { i18nKey: 'orders:stage_open', value: 'open' },
  { i18nKey: 'orders:stage_checkout_requested', value: 'checkoutRequested' },
  { i18nKey: 'orders:stage_unconfirmed', value: 'pending' },
  { i18nKey: 'orders:stage_confirmed', value: 'inProgress' },
  { i18nKey: 'orders:stage_completed', value: 'completed' },
  { i18nKey: 'orders:stage_cancelled', value: 'cancelled' },
];

const initialState: OrdersReducer = {
  orders: {
    data: [],
    hasLoadedInitial: false,
    isFailed: false,
    isLoading: false,
    isLoadingMore: false,
    orderCount: 0,
  },
  stages: {
    buyer: buyerStages,
    seller: sellerStages,
  },
};

const handleSetOrdersRequest = (state: OrdersReducer) => {
  return updated(state, {
    orders: updated(state.orders, {
      isLoading: true,
    }),
  });
};

const handleSetOrdersSuccess: ActionHandler<OrdersReducer, typeof fetchOrdersSuccess> = (state, action) => {
  const { orders, orderCount } = action.payload!;

  return updated(state, {
    orders: {
      data: orders,
      hasLoadedInitial: true,
      isFailed: false,
      isLoading: false,
      isLoadingMore: false,
      orderCount,
    },
  });
};

const handleSetOrdersFailure = (state: OrdersReducer): OrdersReducer => {
  return updated(state, {
    orders: updated(state.orders, {
      isFailed: true,
      isLoading: false,
      isLoadingMore: false,
    }),
  });
};

function handleFetchMoreOrdersRequest(state: OrdersReducer): OrdersReducer {
  return updated(state, {
    orders: updated(state.orders, {
      isLoadingMore: true,
    }),
  });
}

const handleFetchMoreOrdersSuccess: ActionHandler<OrdersReducer, typeof fetchMoreOrdersSuccess> = (state, action) => {
  const { orders, orderCount } = action.payload!;

  return updated(state, {
    orders: updated(state.orders, {
      data: merge(state.orders.data, orders),
      isLoadingMore: false,
      orderCount,
    }),
  });
};

function handleFetchMoreOrdersFailure(state: OrdersReducer): OrdersReducer {
  return updated(state, {
    orders: updated(state.orders, {
      isLoadingMore: false,
    }),
  });
}

const handleCreateSelectionSuccess: ActionHandler<OrdersReducer, typeof createSelectionSuccess> = (state, action) => {
  const { order, account, buyer } = action.payload!;

  const orderEssentials: Order.Essentials = {
    ...order,
    account: account.name,
    accountName: account.name,
    buyer: buyer.buyer,
    buyerEmail: buyer.email,
    buyerFirstName: buyer.firstName,
    buyerLastName: buyer.lastName,
  };

  return updated(state, {
    orders: updated(state.orders, {
      data: [orderEssentials, ...state.orders.data],
    }),
  });
};

export default createReducer<OrdersReducer, AppAction>(initialState)
  .handleAction(fetchMoreOrdersRequest, handleFetchMoreOrdersRequest)
  .handleAction(fetchMoreOrdersSuccess, handleFetchMoreOrdersSuccess)
  .handleAction(fetchMoreOrdersFailure, handleFetchMoreOrdersFailure)
  .handleAction(fetchOrdersRequest, handleSetOrdersRequest)
  .handleAction(fetchOrdersSuccess, handleSetOrdersSuccess)
  .handleAction(fetchOrdersFailure, handleSetOrdersFailure)
  .handleAction(createSelectionSuccess, handleCreateSelectionSuccess);
