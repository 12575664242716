import { Selects } from '@typings';

import { useSelectContext } from '../../../components/various/Fields/Select/context/SelectContext';
import { isDefined } from '../../is';
import { isAlphanumericKey, Key } from '../../keys';

interface Props {
  options: Selects.Option[];
}

export const useSelectKeyboardHandler = ({ options }: Props) => {
  const { isOpen, open, close, toggleIsOpen, activePosition, focusedPosition, focusOption, handleSelect } = useSelectContext();

  const handleAlphanumericKey = (event: React.KeyboardEvent) => {
    const key = event.key.toLowerCase();
    const currentPosition = focusedPosition ?? -1;

    const newPosition = [...options, ...options].findIndex(
      (option, index) => index > currentPosition && option.label.toLowerCase().startsWith(key),
    );

    if (newPosition >= 0) {
      focusOption(newPosition % options.length);
    }
  };

  const handleArrowUpKey = (event: React.KeyboardEvent) => {
    event.preventDefault();

    if (!isOpen) {
      return open();
    }

    if (!isDefined(activePosition)) {
      return;
    }

    const newPosition = Math.max(0, activePosition - 1);
    focusOption(newPosition);
  };

  const handleArrowDownKey = (event: React.KeyboardEvent) => {
    event.preventDefault();

    if (!isOpen) {
      return open();
    }

    if (!isDefined(activePosition)) {
      return focusOption(0);
    }

    const newPosition = Math.min(options.length - 1, activePosition + 1);
    focusOption(newPosition);
  };

  const handleSpaceAndEnterKeys = (event: React.KeyboardEvent) => {
    event.preventDefault();

    if (!isOpen || !isDefined(activePosition)) {
      return toggleIsOpen();
    }

    const focusedValue = options[activePosition]?.value;

    if (isDefined(focusedValue)) {
      handleSelect(focusedValue);
    }
  };

  const handleEscapeKey = (event: React.KeyboardEvent) => {
    if (isOpen) {
      event.stopPropagation();
      close();
    }
  };

  const handleTabKey = () => {
    close();
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (isAlphanumericKey(event.key)) {
      return handleAlphanumericKey(event);
    }

    const handlers: Record<string, Maybe<(event: React.KeyboardEvent) => void>> = {
      [Key.UP]: handleArrowUpKey,
      [Key.DOWN]: handleArrowDownKey,
      [Key.SPACE]: handleSpaceAndEnterKeys,
      [Key.ENTER]: handleSpaceAndEnterKeys,
      [Key.ESCAPE]: handleEscapeKey,
      [Key.TAB]: handleTabKey,
    };

    handlers[event.code]?.(event);
  };

  return {
    handleKeyDown,
  };
};
