import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Option } from 'space-lift';

import { getCmsBlockById, getCurrentBlockId, updateBlockSettings } from '../../../ducks';
import { getIsContentBlock,isBlockOverlayDefined } from '../../../logic/pages';
import { useToolbarVisibility } from '../../../utils/hooks';
import { isDefined } from '../../../utils/is';
import { ColorEditor, OpacitySlider, RemoveButton } from '../TextTools';
import { Toolbar } from '../Toolbar';
import { AcceptDiscard } from '../Toolbar/Tools/AcceptDiscard';

const TOOLBAR_TYPE = 'Overlay';
const DEFAULT_OVERLAY_SETTINGS = {
  color: '#ffffff',
  opacity: 0.3,
};

export const OverlayToolbar = () => {
  const dispatch = useDispatch();
  const currentBlock = useSelector(getCurrentBlockId);
  const [toolbarLocked, setToolbarLocked] = React.useState(false);
  const { isToolbarVisible, hideToolbar } = useToolbarVisibility(TOOLBAR_TYPE);
  const block = useSelector(getCmsBlockById(currentBlock));
  const { t } = useTranslation(['cms']);

  const updateBlockOverlay = React.useCallback(
    (overlay?: { color: string; opacity: number }) => {
      if (!isDefined(currentBlock)) {
        return;
      }
      const value = isDefined(overlay) ? { general: overlay } : undefined;
      dispatch(
        updateBlockSettings({
          blockId: currentBlock,
          updatePath: ['overlay'],
          value,
        }),
      );
    },
    [currentBlock, dispatch],
  );

  React.useEffect(() => {
    if ((isDefined(block) && isBlockOverlayDefined(block)) || !isToolbarVisible) {
      return;
    }

    return updateBlockOverlay(DEFAULT_OVERLAY_SETTINGS);
  }, [block, isToolbarVisible, updateBlockOverlay]);

  const overlaySettings = React.useMemo(
    () =>
      Option(block)
        .filter(getIsContentBlock)
        .map(({ settings }) => settings.overlay)
        .filter(isDefined)
        .map(overlay => overlay.general)
        .getOrElse(DEFAULT_OVERLAY_SETTINGS),
    [isToolbarVisible, block],
  );

  const onColorChange = React.useCallback(
    (color: string) => updateBlockOverlay(Object.assign({}, overlaySettings, { color })),
    [updateBlockOverlay, overlaySettings],
  );

  const onOpacityChange = React.useCallback(
    (opacity: number) => {
      if (overlaySettings.opacity === opacity) {
        return;
      }

      updateBlockOverlay(Object.assign({}, overlaySettings, { opacity }));
    },
    [updateBlockOverlay, overlaySettings],
  );

  const onRemove = React.useCallback(() => {
    updateBlockOverlay();
    hideToolbar();
  }, [updateBlockOverlay, hideToolbar]);

  const handleSetToolbarLocked = React.useCallback(
    (isLocked: boolean) => () => {
      setToolbarLocked(isLocked);
    },
    [setToolbarLocked],
  );

  return (
    <Toolbar
      type={TOOLBAR_TYPE}
      title={t('cms:overlay')}
      tools={[
        <ColorEditor
          key="color_editor"
          value={overlaySettings.color}
          onChange={onColorChange}
          onOpenEditor={handleSetToolbarLocked(true)}
          onCloseEditor={handleSetToolbarLocked(false)}
        />,
        <OpacitySlider
          key="opacity_select"
          opacity={overlaySettings.opacity}
          onChange={onOpacityChange}
          onOpenSlider={handleSetToolbarLocked(true)}
          onCloseSlider={handleSetToolbarLocked(false)}
        />,
        <RemoveButton key="remove_button" onClick={onRemove} />,
        <AcceptDiscard key="acceptdiscard" acceptAction={hideToolbar} />,
      ]}
      isLockedVisible={toolbarLocked}
    />
  );
};
