import { useTranslation } from 'react-i18next';

import { isDefined } from '../../../utils/is';
import Heading from '../Heading';
import { Button } from '../NewButton';

import styles from './SectionHeading.module.scss';
import { SectionHeadingWrapper } from './SectionHeadingWrapper';

interface Props {
  sectionName?: string;
  isEditing: boolean;
  isSubmitDisabled?: boolean;
  isEditingDisabled?: boolean;
  submitName?: string;
  editName?: string;
  loadingMessage?: string;
  isLoading?: boolean;
  handleEnableEditing: () => void;
  handleCancel: () => void;
  handleSubmit: () => void;
}

export const EditableSectionHeading = ({
  sectionName,
  isEditing,
  isSubmitDisabled = false,
  isEditingDisabled = false,
  submitName,
  editName,
  loadingMessage,
  isLoading,
  handleCancel,
  handleEnableEditing,
  handleSubmit,
}: Props) => {
  const { t } = useTranslation(['common']);

  return (
    <SectionHeadingWrapper>
      {isDefined(sectionName) && <Heading className={styles.heading} title={sectionName} variant={['h2', 'black']} />}
      {!isEditing && (
        <Button size="large" onClick={handleEnableEditing} disabled={isEditingDisabled}>
          {editName ?? t('common:edit')}
        </Button>
      )}
      {isEditing && (
        <div className={styles.submitButtons}>
          <Button size="large" color="dark" variant="ghost" disabled={isLoading} onClick={handleCancel}>
            {t('common:cancel')}
          </Button>
          <Button size="large" onClick={handleSubmit} disabled={isSubmitDisabled} loadingMessage={loadingMessage} isLoading={isLoading}>
            {submitName ?? t('common:save_changes')}
          </Button>
        </div>
      )}
    </SectionHeadingWrapper>
  );
};
