/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;
const ORIGINAL_COLOR = '#D6D6D6';

export const Overview = ({ width = ORIGINAL_WIDTH }: IconComponentProps) => {
  return (
    <svg width={width} height={width} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 16.5V3.5H3V16.5H6ZM17 8.5V16.5H14V8.5H17ZM11.5 11.5V16.5H8.5V11.5H11.5Z"
        fill={ORIGINAL_COLOR}
      />
    </svg>
  );
};
