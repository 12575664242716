const REMOVE_TIMEOUT = 500;

export const hideLoader = () => {
  const loaderPage = document.getElementById('loader');
  if (loaderPage !== null) {
    loaderPage.classList.add('hidden');
    setTimeout(() => {
      loaderPage.remove();
    }, REMOVE_TIMEOUT);
  }
};
