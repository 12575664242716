import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHover } from 'react-laag';
import { useDispatch } from 'react-redux';

import { updateMultipleBlocksSettings } from '../../../ducks';
import { useMovement } from '../../../utils/hooks';
import { omit } from '../../../utils/omit';
import { GroupResizerContext } from '../../cms/context/GroupResizerContext';
import { IconType } from '../../various/Icon';
import { Button } from '../../various/NewButton';
import { ResizerTooltip } from '../../various/Resizer';
import { EditorUIContext } from '../context/EditorUIContext';

import styles from './GroupResizer.module.scss';

interface Props {
  parentRef: React.RefObject<HTMLDivElement>;
  resizableBlocksIds: Set<string>;
  className?: string;
}

export const GroupResizer = React.memo(({ parentRef, resizableBlocksIds, className }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common']);

  const buttonRef = React.useRef<HTMLButtonElement | null>(null);
  const { screenType } = React.useContext(EditorUIContext);
  const { setOffset, group, setIsResizing, isResizing } = React.useContext(GroupResizerContext);

  const isMobileScreenType = screenType === 'mobile';
  const [isOver, hoverProps] = useHover();

  const handleStartMovement = React.useCallback(() => {
    setIsResizing(true);
  }, [setIsResizing]);

  const handleMovement = React.useCallback(
    (offset: { xOffset: number; yOffset: number }) => {
      setOffset(offset.yOffset);
    },
    [setOffset],
  );

  const handleEndMovement = React.useCallback(
    (offset: { xOffset: number; yOffset: number }) => {
      setIsResizing(false);

      if (offset.yOffset === 0) {
        return;
      }

      const payload = Object.values(group)
        .filter(block => resizableBlocksIds.has(block.blockId))
        .map(block => omit(block, ['minHeight']));
      dispatch(updateMultipleBlocksSettings(payload));
      setOffset(0);
    },
    [dispatch, group, resizableBlocksIds, setIsResizing, setOffset],
  );

  const blockHeight = (parentRef.current?.offsetParent as Maybe<HTMLElement>)?.offsetHeight ?? 0;

  useMovement(buttonRef, {
    onEndMovement: handleEndMovement,
    onMovement: handleMovement,
    onStartMovement: handleStartMovement,
  });

  const shouldShowTooltip = (blockHeight > 0 && isResizing) || isOver;

  return (
    <>
      <Button
        ref={buttonRef}
        size="mini"
        variant="bordered"
        className={cx(styles.button, className)}
        icon={IconType.Arrows}
        {...hoverProps}
      >
        {!isMobileScreenType && t('common:resize')}
      </Button>
      {shouldShowTooltip && <ResizerTooltip className={styles.tooltip} value={blockHeight} />}
    </>
  );
});
