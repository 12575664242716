import { createRoot } from 'react-dom/client';

import { Main } from './js/Main';
import { store } from './js/store';
import { detectOfflineState } from './js/utils/connectivity';
import { isDefined } from './js/utils/is';

import './css/main.scss';
import './js/translation/i18n';
import './js/initialization';

detectOfflineState(store.dispatch);

const container = document.getElementById('app');

if (isDefined(container)) {
  const root = createRoot(container);

  root.render(<Main />);
}
