import { Cms, Product } from '@typings';

import { isDefined } from '../../utils/is';
import { isEveryElementInArray } from '../../utils/isEveryElementInArray';
import { isCmsProduct } from '../products';

export const areProductsInMarketsMatch = ({
  products,
  section,
  productId,
}: {
  products: Record<string, Product.Standard | Product.CmsProduct>;
  section: Cms.Section | undefined;
  productId: string;
}) => {
  const product = products[productId];

  if (!isDefined(product) || !isCmsProduct(product)) {
    return true;
  }

  const marketsFromProduct = product.markets;
  const markets = marketsFromProduct.map(marketId => marketId.toString());

  return isEveryElementInArray(section?.visibleFor.marketIds, markets);
};
