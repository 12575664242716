import { HttpStatusCode } from 'axios';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import ProductsLogic from '../../../../logic/products';
import { compiledPaths } from '../../../../paths';
import { useLookbookProduct } from '../../../../services/hooks/lookbook/useLookbookProduct';
import { isDefined } from '../../../../utils/is';
import { isEmpty } from '../../../../utils/isEmpty';
import { SomethingWentWrong } from '../../../somethingWentWrong';
import DefaultLoader from '../../../various/loaders/DefaultLoader';
import { PageTitle } from '../../../various/PageTitle';
import { TopBar } from '../../../various/TopBar';
import { Wrapper, WrapperSize } from '../../../various/Wrapper';
import { MatrixHelpIcon } from '../../ProductMatrix/MatrixHelp';
import { ProductMatrixSectionGeneral } from '../../ProductMatrix/ProductMatrixSectionGeneral';
import { ProductDetailsLayout } from '../ProductDetailsLayout';

type Params = {
  lookbookId: string;
  productId: string;
};

export const LookbookProductDetailsPage = () => {
  const { t } = useTranslation(['common', 'products']);
  const navigate = useNavigate();
  const { productId, lookbookId } = useParams<Params>();
  const { data: productDetails, isError, error } = useLookbookProduct(productId, lookbookId);
  const variants = React.useMemo(
    () => (isDefined(productDetails) ? ProductsLogic.getAllOrderedVariants(productDetails) : []),
    [productDetails],
  );

  const handleNavigateBack = React.useCallback(() => {
    navigate(compiledPaths.LOOKBOOK_SINGLE({ lookbookId }));
  }, [lookbookId, navigate]);

  React.useEffect(() => {
    if (isDefined(error) && error.response?.status === HttpStatusCode.NotFound) {
      navigate(compiledPaths.PAGE_NOT_FOUND({}));
    }
  }, [error, navigate]);

  if (isError) {
    return <SomethingWentWrong />;
  }

  if (!isDefined(productDetails) || isEmpty(productDetails.itemTable) || productId !== productDetails.product) {
    return <DefaultLoader fullHeight />;
  }

  return (
    <PageTitle title={productDetails.name}>
      <>
        <TopBar
          title={t('common:lookbook')}
          subtitle={productDetails.name}
          details={t('common:art_no', { artNo: productDetails.productSku })}
          onClick={handleNavigateBack}
        />
        <Wrapper size={WrapperSize.LARGE}>
          <ProductDetailsLayout productDetails={productDetails}>
            {!isEmpty(productDetails.deliveryWindows) && <MatrixHelpIcon />}
            <ProductMatrixSectionGeneral
              isLookbook
              showOnlyDeliveryWindows={productDetails.deliveryWindows}
              variants={variants}
              isReadOnly
            />
          </ProductDetailsLayout>
        </Wrapper>
      </>
    </PageTitle>
  );
};
