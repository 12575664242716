import { Order } from '@typings';
import { useTranslation } from 'react-i18next';

import { isEmpty } from '../../../../utils/isEmpty';
import Heading from '../../../various/Heading';
import Icon, { IconType } from '../../../various/Icon';
import { Link } from '../../../various/Link';
import { TextEllipsis } from '../../../various/TextEllipsis';
import { Tooltip } from '../../../various/Tooltip/Tooltip';
import { CheckoutSection } from '../../CheckoutSection';

import styles from './AttachmentsSection.module.scss';

interface Props {
  attachments: Order.Attachment[];
}

export const AttachmentsSection = ({ attachments }: Props) => {
  const { t } = useTranslation(['checkout', 'common']);

  if (isEmpty(attachments)) {
    return null;
  }

  return (
    <CheckoutSection>
      <Heading variant={['blue', 'h2']} title={t('checkout:attachments')} />
      <div className={styles.attachmentsWrapper}>
        {attachments.map(({ content, createdAt }, idx) => (
          <div className={styles.attachment} key={idx}>
            <Icon type={IconType.Attachment} />
            <div className={styles.content}>
              <span className={styles.nameWrapper}>
                <Tooltip
                  content={
                    <>
                      <span className={styles.tooltipTitle}>{content.name}</span>
                      <br />
                      {t('common:added')}: {createdAt}
                    </>
                  }
                >
                  <span className={styles.name}>
                    <TextEllipsis>{content.name}</TextEllipsis>
                  </span>
                </Tooltip>
              </span>
              <Link variant="blue" external target="_blank" to={content.url}>
                {t('common:preview')}
              </Link>
            </div>
          </div>
        ))}
      </div>
    </CheckoutSection>
  );
};
