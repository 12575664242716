import { createBrowserHistory } from 'history';
import { assocPath, path } from 'ramda';
import { combineReducers } from 'redux';
import { createReduxHistoryContext } from 'redux-first-history';
import { isActionOf } from 'typesafe-actions';

import { isDefined } from '../utils/is';

import order from './order/index';
import barcodeScanner from './barcodeScanner';
import cms from './cms';
import config from './config';
import countries from './countries';
import deliveryWindows from './deliveryWindows';
import documents from './documents';
import lookbook from './lookbook';
import markets from './markets';
import pages from './pages';
import payments from './payments';
import presets from './presets';
import pricelists from './pricelists';
import productDetails from './productDetails';
import products from './products';
import selectionImport from './selectionImport';
import selections from './selections';
import ui, { resetAppState } from './ui';
import user, { logoutRequest } from './user';

export const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});

const appReducer = combineReducers<Store>({
  barcodeScanner,
  cms,
  config,
  countries,
  deliveryWindows,
  documents,
  lookbook,
  markets,
  order,
  pages,
  payments,
  presets,
  pricelists,
  productDetails,
  products,
  router: routerReducer,
  selectionImport,
  selections,
  ui,
  user,
});

const KEEP_ON_LOGOUT = ['config', 'presets', 'ui.isProductsGridView'];
// preserve router to prevent errors from react-router; preserve user to make sure that anonymous user can access shared link
const KEEP_ON_RESET = ['router', 'user', 'ui.isProductsGridView'];

const purgeState = (state: Store, action: AppAction, keysToKeep: string[]) => {
  const cleanState = appReducer(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    Object.keys(state).reduce<Store>((acc, cur) => ({ ...acc, [cur]: undefined }), {} as Store),
    action,
  );

  return keysToKeep.reduce((acc, dottedPath) => {
    const arrayPath = dottedPath.split('.');

    return assocPath(arrayPath, path(arrayPath, state), acc);
  }, cleanState);
};

export default (state: Store | undefined, action: AppAction) => {
  if (isDefined(state) && isActionOf(logoutRequest, action)) {
    return purgeState(state, action, KEEP_ON_LOGOUT);
  }

  if (isDefined(state) && isActionOf(resetAppState, action)) {
    return purgeState(state, action, KEEP_ON_RESET);
  }

  return appReducer(state, action);
};
