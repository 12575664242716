import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  fetchPageBySlugRequest,
  getAccessToken,
  getBuyerIdFromCurrentOrder,
  getCurrentPageSlug,
  getOrderNumber,
  getPageData,
  getViewBlockById,
  getViewBlocksByIds,
  getViewGroupById,
  getViewSections,
} from '../../../ducks';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { SelectorsContext } from '../../cms/context/SelectorsContext';
import { SizeContextProvider } from '../../cms/context/SizeContext';
import { CallToAction } from '../../orders/CallToAction';
import { OrderTopBar } from '../../orders/OrderTopBar';
import { BottomWrapper } from '../../various/BottomWrapper';
import { PageTitle } from '../../various/PageTitle';

import { PageContent } from './PageContent';

const selectorsContextValue = {
  getBlockById: getViewBlockById,
  getBlocksByIds: getViewBlocksByIds,
  getGroupById: getViewGroupById,
  getSections: getViewSections,
};

export const Page = () => {
  const { t } = useTranslation(['selections']);
  const dispatch = useDispatch();
  const token = useSelector(getAccessToken);
  const { pageSlug } = useParams<{ pageSlug: string }>();
  const currentPageSlug = useSelector(getCurrentPageSlug);
  const orderNumber = useSelector(getOrderNumber);
  const buyerId = useSelector(getBuyerIdFromCurrentOrder);
  const pageData = useSelector(getPageData);

  const shouldFetchPage = (pageSlug !== currentPageSlug || isEmpty(pageData.sections)) && isDefined(pageSlug) && isDefined(token);

  React.useEffect(() => {
    if (shouldFetchPage && !isEmpty(buyerId)) {
      dispatch(fetchPageBySlugRequest(pageSlug));
    }
  }, [pageSlug, shouldFetchPage, dispatch, buyerId]);

  const pageTitle = `${t('selections:selection_number', { orderNumber })} - ${pageData.pageTitle}`;

  return (
    <PageTitle title={pageTitle}>
      <SizeContextProvider>
        <SelectorsContext.Provider value={selectorsContextValue}>
          <OrderTopBar />
          <PageContent />
          <BottomWrapper>
            <CallToAction callToActionVersion="page" />
          </BottomWrapper>
        </SelectorsContext.Provider>
      </SizeContextProvider>
    </PageTitle>
  );
};
