/* eslint-disable max-len */

import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;
const ORIGINAL_COLOR = '#848484';

export const ArrowSharp = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps): JSX.Element => (
  <svg width={width} height={width} viewBox="0 0 20 20" fill="none">
    <path d="M10 13L14 8L6 8L10 13Z" fill={color} />
  </svg>
);
