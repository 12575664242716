import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getIsMenuOpened, toggleMenu } from '../../../ducks/ui';
import Icon, { IconType } from '../../various/Icon';

import styles from './MenuToggle.module.scss';

interface Props {
  isAnonymous: boolean;
  className?: string;
}

export const MenuToggle = ({ isAnonymous, className }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common']);
  const isMenuOpened = useSelector(getIsMenuOpened);

  const onToggleMenu = React.useCallback(() => dispatch(toggleMenu()), []);

  return (
    <button
      className={cx(styles.topHamburger, className, { [styles.isAnonymous]: isAnonymous })}
      aria-expanded={isMenuOpened}
      onClick={onToggleMenu}
      aria-label={t('common:toggle_main_menu')}
    >
      <div className={styles.menuToggle}>
        {isMenuOpened ?
          <Icon type={IconType.CloseHamburger} size={16} />
        : <Icon type={IconType.Hamburger} />}
      </div>
    </button>
  );
};
