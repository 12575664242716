import { Cms } from '@typings';
import { createAction } from 'typesafe-actions';

export const hideToolbar = createAction('cms/HIDE_TOOLBAR')<Cms.ToolbarType>();
export const hideAllToolbars = createAction('cms/HIDE_ALL_TOOLBARS')();
export const resetCurentBlock = createAction('cms/RESET_CURRENT_BLOCK')();
export const showToolbar = createAction('cms/SHOW_TOOLBAR')<Cms.ToolbarType>();
export const setCurrentItem = createAction('cms/SET_EDITABLE_ITEM')<Cms.EditableItem>();
export const setCurrentBlock = createAction('cms/SET_CURRENT_BLOCK')<Nullable<string>>();
export const setCurrentGroup = createAction('cms/SET_CURRENT_GROUP')<Nullable<string>>();
export const setCurrentSection = createAction('cms/SET_CURRENT_SECTION')<string>();
export const setIsRepositioningImage = createAction('cms/SET_IS_REPOSITION_IMAGE')<boolean>();
export const setIsHotspotEditing = createAction('cms/SET_IS_HOTSPOT_EDITING')<boolean>();

export const openTextStyleEditor = createAction('cms/OPEN_TEXT_STYLE_EDITOR')<{
  elementStyles: Cms.TextStyle;
  mode: Cms.CustomStyleEditorMode;
}>();
export const openButtonStyleEditor = createAction('cms/OPEN_BUTTON_STYLE_EDITOR')<{
  elementStyles: Cms.ButtonStyle;
  mode: Cms.CustomStyleEditorMode;
}>();

export const setIsHoverTabOpen = createAction('cms/SET_IS_HOVER_TAB_OPEN')<boolean>();

export const closeTextStyleEditor = createAction('cms/CLOSE_TEXT_STYLE_EDITOR')();
export const closeButtonStyleEditor = createAction('cms/CLOSE_BUTTON_STYLE_EDITOR')();

export const setTextStyleEditorStyles = createAction('cms/SET_TEXT_STYLE_EDITOR_STYLES')<Cms.TextStyle>();
export const setButtonStyleEditorStyles = createAction('cms/SET_BUTTON_STYLE_EDITOR_STYLES')<Cms.ButtonStyle>();
export const setCurrentCustomTemplateId = createAction('cms/SET_CURRENT_CUSTOM_TEMPLATE_ID')<string | null>();
