import { Payments } from '@typings';
import React from 'react';

import { isDefined } from '../../../utils/is';
import { RadioGroup } from '../Fields/RadioGroup';
import { LogoType } from '../Logo';

import { PaymentMethodLabel } from './PaymentMethodLabel';
import styles from './PaymentMethods.module.scss';

interface Props {
  selected: Nullable<string>;
  className?: string;
  methods: Payments.PaymentMethod[];
  onChange: (method: string) => void;
}

const PAYMENT_METHOD_ICON: Record<Payments.PaymentMethodType, Maybe<LogoType>> = {
  pay_later: null,
  paypal_wholesale: LogoType.PayPal,
  stripe_checkout: LogoType.Stripe,
  stripe_checkout_wholesale: LogoType.Stripe,
};

export const PaymentMethods = ({ selected, className, methods, onChange }: Props) => {
  const [firstMethod] = methods;

  const options = React.useMemo(
    () =>
      methods.map(method => ({
        name: <PaymentMethodLabel iconType={PAYMENT_METHOD_ICON[method.paymentMethodType]} label={method.name} />,
        value: method.paymentMethod,
      })),
    [methods],
  );

  if (!isDefined(firstMethod)) {
    return null;
  }

  return (
    <RadioGroup
      className={className}
      itemClassName={styles.item}
      name="paymentMethods"
      options={options}
      onChange={onChange}
      value={selected ?? firstMethod.paymentMethod}
    />
  );
};
