import { createAction } from 'typesafe-actions';

export const downloadOrderConfirmation = createAction('documents/ORDER_CONFIRMATION_DOWNLOAD_REQUEST')<string>();

export const downloadOrderConfirmationSuccess = createAction(
  'documents/ORDER_CONFIRMATION_DOWNLOAD_SUCCESS',
)<Responses.DownloadOrderConfirmation>();

export const downloadOrderConfirmationFailed = createAction('documents/ORDER_CONFIRMATION_DOWNLOAD_FAILURE')();

export const downloadOrderExcel = createAction('documents/ORDER_DOWNLOAD_EXCEL_REQUEST')<{
  orderId: string;
  orderNumber: string;
}>();

export const downloadOrderExcelSuccess = createAction('documents/ORDER_DOWNLOAD_EXCEL_SUCCESS')<Responses.DownloadOrderExcel>();

export const downloadOrderExcelFailure = createAction('documents/ORDER_DOWNLOAD_EXCEL_FAILURE')();

export const downloadProductInfoExcel = createAction('documents/PRODUCT_INFO_DOWNLOAD_EXCEL_REQUEST')<{
  orderId: string;
  orderNumber: string;
}>();

export const downloadProductInfoExcelSuccess = createAction(
  'documents/PRODUCT_INFO_DOWNLOAD_EXCEL_SUCCESS',
)<Responses.DownloadProductInfoExcel>();

export const downloadProductInfoExcelFailure = createAction('documents/PRODUCT_INFO_DOWNLOAD_EXCEL_FAILURE')();

export const downloadOrderCsv = createAction('documents/ORDER_DOWNLOAD_CSV_REQUEST')<{
  orderId: string;
  orderNumber: string;
}>();

export const downloadOrderCsvSuccess = createAction('documents/ORDER_DOWNLOAD_CSV_SUCCESS')<Responses.DownloadOrderCsv>();

export const downloadOrderCsvFailure = createAction('documents/ORDER_DOWNLOAD_CSV_FAILURE')();

export const downloadProductImages = createAction('documents/ORDER_PRODUCT_IMAGES_REQUEST')<{
  orderId: string;
  orderNumber: string;
}>();

export const downloadProductImagesSuccess = createAction('documents/ORDER_PRODUCT_IMAGES_SUCCESS')();

export const downloadProductImagesFailure = createAction('documents/ORDER_PRODUCT_IMAGES_FAILURE')();
