import {
  useMatrixDistributionContext,
  useMatrixPrepackContext,
  useMatrixQuantitiesContext,
  useMatrixTotalsContext,
} from '../../../products/ProductMatrix/context';
import { ColumnSummaryCell } from '../MatrixCells';

export const InputFooterCells = () => {
  const { cellQuantitiesMap } = useMatrixQuantitiesContext();
  const { visiblePrepacksLength } = useMatrixPrepackContext();
  const { columnQuantitiesSum } = useMatrixTotalsContext();
  const { distributionsOffset } = useMatrixDistributionContext();

  return (
    <>
      {columnQuantitiesSum.map(([size, quantity], idx) => {
        const position = {
          x: idx + visiblePrepacksLength + distributionsOffset,
          y: cellQuantitiesMap.length,
        };

        return (
          <ColumnSummaryCell key={size} position={position}>
            {quantity}
          </ColumnSummaryCell>
        );
      })}
    </>
  );
};
