import { FilePicker as FilePickerNs, Form } from '@typings';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { isDefined } from '../../../../utils/is';
import { FilePicker } from '../../Fields/FilePicker';

import { FieldWrapper } from './FieldParts';

type FilePickerProps = Omit<FilePickerNs.Props, 'file' | 'onBlur' | 'onChange' | 'onError'>;

interface Props extends FilePickerProps, Form.FieldProps {
  name: string;
}

export const FilePickerField = (props: Props) => {
  const { description, helpMessage, label, name, isRequired = false, orientation, ...rest } = props;

  const errorId = React.useId();
  const describedById = React.useId();

  const {
    field,
    formState: { errors },
  } = useController({ name });
  const { setError } = useFormContext();

  const errorMessage = errors[name]?.message as string | undefined;

  return (
    <FieldWrapper
      description={description}
      describedById={describedById}
      helpMessage={helpMessage}
      errorId={errorId}
      errorMessage={errorMessage}
      isRequired={isRequired}
      label={label}
      orientation={orientation}
    >
      <FilePicker
        {...rest}
        ref={field.ref}
        file={field.value}
        name={name}
        isInvalid={isDefined(errorMessage)}
        describedById={isDefined(description) ? describedById : undefined}
        errorMessageId={isDefined(errorMessage) ? errorId : undefined}
        onChange={field.onChange}
        onBlur={field.onBlur}
        onError={message => setError(name, { message })}
      />
    </FieldWrapper>
  );
};
