import { AttributeDescription, AttributesSource, Checkout, Media, ShowroomApiResponse, ShowroomConfiguration, Versions } from '@typings';
import i18next from 'i18next';
import { createSelector } from 'reselect';

import { isDefined } from '../../utils/is';
import { isEmpty } from '../../utils/isEmpty';

/**
 * Returns the main part of the reducer. Try to use
 * more specific selectors instead of this one.
 */
export const getConfiguration = (state: Store): Omit<ShowroomConfiguration.FullConfiguration, keyof ShowroomApiResponse> =>
  state.config.data;

export const getIsLookbookEnabled = (state: Store) => state.config.data.lookbookEnabled;

/**
 * URI of the image used on the login page.
 */
export const getBackgroundImage = (state: Store): string => state.config.data.backgroundImage;

/**
 * Whether a user with buyer access can submit the order himself (`false`)
 * or needs a sales representative to accept the order first (`true`).
 */
export const getIsBuyerCheckout = (state: Store): boolean => state.config.data.buyerCheckout;

/**
 * Returns checkout fields based on the user status.
 */
export const getCheckoutFields = (state: Store): Checkout.Requirements =>
  state.user.isLoggedIn ? state.config.data.checkoutFields : state.config.data.checkoutFieldsForSharedLinks;

export const getIsShippingAddressAvailableInCheckout = createSelector(
  getCheckoutFields,
  (checkoutFields: Checkout.Requirements) => 'shippingAddress' in checkoutFields,
);

export const getShippingAddressFields = createSelector(
  getCheckoutFields,
  (checkoutFields: Checkout.Requirements) => checkoutFields.shippingAddress?.contents ?? null,
);

export const getGATrackingId = (state: Store): Nullable<string> => state.config.data.gaTrackingId;

export const getHotjarSiteId = (state: Store): Nullable<string> => state.config.data.hotjarSiteId;

/**
 * Size of the images on products page.
 */
export const getDefaultImageSize = (state: Store): Media.Size => state.config.data.defaultImageSize;

/**
 * Returns exact size in pixels and image file extension
 * for all image sizes supported by the application.
 */
export const getImageSizes = (state: Store): Media.Specification[] => state.config.data.imageSizes;

/**
 * How many products should be fetched in one batch.
 */
export const getProductsPerRequest = (state: Store) => state.config.data.productsPerRequest;

/**
 * Whether orders can be shared via email.
 */
export const getSharedLinksEnabled = (state: Store) => state.config.data.sharedLinksEnabled;

/**
 * Returns `true` when all client-specific settings are known.
 */
export const getIsConfigLoaded = (state: Store) => state.config.isConfigLoaded;

export const getConfigErrorMessage = (state: Store): string | undefined => state.config.error;

/**
 * Returns `false` when disabled in Centra Showroom plugin.
 */
export const getIsAccountEditingEnabled = (state: Store): boolean => state.config.data.accountEditing;
export const getIsBuyerEditingEnabled = (state: Store): boolean => state.config.data.buyerEditing;

export const getCompanyName = (state: Store): string => state.config.data.companyName;
export const getLoginPageMessage = (state: Store) => state.config.data.loginPageMessage;
export const getDarkLogoImage = (state: Store): string => state.config.data.logoImageDark;

/**
 * Gets the list of attributes that can be used to filter products.
 */
export const getFilterFields = (state: Store) => state.config.data.filterFields;

/**
 * Returns a Map which, when given an attribute's internal id,
 * returns display name for this attribute. For example:
 *
 *     const map = getAttributeNameMap(state);
 *     map.get("fit_group"); // => "Fit Group"
 */
export const getAttributeNameMap = createSelector(
  getFilterFields,
  fields => new Map<string, string>(fields.map(field => Object.values(field) as [string, string])),
);

export const getApiVersion = (state: Store) => {
  const config = getConfiguration(state);
  if ('version' in config) {
    const { major, minor, patch } = config.version;

    return `${major}.${minor}.${patch}`;
  }

  return undefined;
};

export const getVersions = createSelector(
  getApiVersion,
  (apiVersion): Versions => ({
    api: apiVersion,
    app: window.__SHOWROOM_SETTINGS__.APP_VERSION,
  }),
);

export const getBuyerMinimumPasswordLength = (store: Store) => store.config.data.buyerMinimumPasswordLength;

export const getCustomFonts = (store: Store) => store.config.data.customFonts;

export const getIsAppReady = (store: Store) =>
  store.config.isConfigLoaded && isDefined(store.user.user.data) && 'access' in store.user.user.data;

export const getProductDetailsAttributes = (store: Store) => store.config.data.productAttributes.details;
export const getProductListingAttributes = (store: Store) => store.config.data.productAttributes.listing;

export const getProductAttributes = (source: AttributesSource) => (store: Store) =>
  source === 'details' ? getProductDetailsAttributes(store) : getProductListingAttributes(store);

export const isProductAttributeEnabled = (source: AttributesSource, attribute: string) =>
  createSelector(getProductAttributes(source), (attributes: AttributeDescription[]) => attributes.some(({ key }) => key === attribute));

export const getProductSortingOptions = (store: Store) => store.config.data.productSorting;

export const getProductSortingDefaultValue = (store: Store) => getProductSortingOptions(store).default;

export const getProductSortingFields = (store: Store) => getProductSortingOptions(store).fields;

export const getIsProductSortingEnabled = (store: Store) => !isEmpty(getProductSortingFields(store));

export const getAreBackordersEnabled = (store: Store) => store.config.data.backordersEnabled;

export const getIsCheckoutBlockedOnUnpaidInvoices = (store: Store) => store.config.data.blockCheckoutOnUnpaid;

export const getUnpaidInvoicesNotificationVisibility = (state: Store) => state.config.data.unpaidInvoicesNotificationVisibility;
export const getIsStockTypeSeparationEnabled = (store: Store) => isDefined(store.config.data.stockTypeSeparation);

export const getActiveFilterKeys = createSelector(
  [getFilterFields, getIsStockTypeSeparationEnabled],
  (filterFields, isStockSeparationEnabled) => {
    const configFilterFields = filterFields.map(filterField => filterField.field);
    const stockTypeFilter = isStockSeparationEnabled ? ['stockType'] : [];

    const localFilters = ['search', 'etaFrom', 'etaTo', ...stockTypeFilter];

    // include search key that we don't get from config and filters for Centra older than 3.13
    return [...configFilterFields, ...localFilters];
  },
);

const getUserDefinedStockTypeLabels = (store: Store) => store.config.data.stockTypeSeparation?.stockTypeLabels;

export const getStockTypeLabels = createSelector(getUserDefinedStockTypeLabels, userDefinedLabels => {
  if (isDefined(userDefinedLabels)) {
    return userDefinedLabels;
  }

  return {
    preorder: i18next.t('common:preorder'),
    stock: i18next.t('common:stock'),
  };
});

export const getDefaultStockType = (store: Store) => store.config.data.stockTypeSeparation?.defaultStockType;

export const getIsStockSwitchNotificationEnabled = (store: Store) =>
  store.config.data.stockTypeSeparation?.showStockSwitchNotification ?? false;

export const getIsHideSwitchForSingleStockTypeEnabled = (store: Store) =>
  store.config.data.stockTypeSeparation?.hideSwitchForSingleStockType ?? false;

export const getAdditionalLanguagesCodes = (store: Store) => store.config.data.additionalLanguages;

export const getSoldOutLabel = (store: Store) => store.config.data.soldOutLabel;

export const getIsBarcodeScannerEnabled = (store: Store) => store.config.data.showBarcodeScanner;

export const getIsCsvImportEnabled = (store: Store) => store.config.data.isCsvImportEnabled ?? false;

export const getVariantsThumbnailsLabel = (store: Store) => store.config.data.variantsThumbnailsLabel ?? 'none';
