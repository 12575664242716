import { Id, Product } from '@centra';
import cx from 'classnames';
import { prop, uniqBy } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ETA_TOOLTIP_MOUSE_ENTER_DELAY } from '../../../constants/delays';
import { getHasAvailableVariants, getNativeVariants } from '../../../ducks/helpers';
import { getOrderCurrency, getOrderDetails } from '../../../ducks/order';
import { getDeliveryWindowIdsFromVariants } from '../../../logic/deliveryWindows';
import { calculateTotalProductAmount, getTotalProductPrice, getTotalVariantQuantityByProduct } from '../../../logic/Orders';
import { formatPriceWithCurrencyAffix } from '../../../logic/price';
import ProductsLogic, { getUrlForProductAndVariant } from '../../../logic/products';
import { useProductBadges } from '../../../utils/hooks/useProductBadges';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { AddProductButton } from '../../products/AddProductButton';
import Price from '../../products/Product/Price';
import { ProductAttributes } from '../../products/ProductAttributes';
import productBadgesStyles from '../../products/ProductBadges/ProductBadges.module.scss';
import { Badge } from '../Badge';
import { CustomImage } from '../CustomImage';
import { DelwinExpiredMessage } from '../DelwinExpiredMessage/DelwinExpiredMessage';
import Heading from '../Heading';
import { MaybeLink } from '../MaybeLink';
import { ProductSummary } from '../ProductSummary/ProductSummary';

import styles from './ProductBaseInfo.module.scss';
import { ProductBaseInfoLayout } from './ProductBaseInfoLayout';

interface SummaryValues {
  variantsCount: number;
  unitsCount: number;
  price: number;
}

interface Props {
  productInfo: Product.Standard;
  imageAspectRatio: number;
  imageSrc: string;
  showAddButton?: boolean;
  overrideSummaryValues?: SummaryValues;
  isProductEditable?: boolean;
  isCancelled?: boolean;
  isCheckoutSuccess?: boolean;
  expiredMessage?: string;
  delwinIds?: Id[];
  showBadges?: boolean;
}

export const ProductBaseInfo = ({
  productInfo,
  imageAspectRatio,
  imageSrc,
  overrideSummaryValues,
  showAddButton = false,
  isProductEditable = true,
  isCancelled,
  isCheckoutSuccess,
  expiredMessage = '',
  delwinIds = [],
  showBadges = false,
}: Props) => {
  const productId = productInfo.product;
  const variantId = productInfo.variant;

  const { t } = useTranslation(['common', 'products', 'cancelled']);
  const orderDetails = useSelector(getOrderDetails);
  const currency = useSelector(getOrderCurrency);

  const nativeVariants = getNativeVariants(productInfo, productId);
  const hasAvailableVariants = getHasAvailableVariants([productInfo, ...nativeVariants]);
  const { defaultBadges } = useProductBadges({
    delwinIds,
    isAvailable: hasAvailableVariants,
    variant: productInfo,
  });

  const orderProducts = React.useMemo(() => {
    return !isEmpty(delwinIds) ?
        orderDetails.order.products.filter(product => delwinIds.includes(product.deliveryWindow))
      : orderDetails.order.products;
  }, [delwinIds, orderDetails.order.products]);

  const summaryValues: SummaryValues = overrideSummaryValues ?? {
    price: getTotalProductPrice(productInfo.product, orderProducts),
    unitsCount: calculateTotalProductAmount(productId, orderProducts),
    variantsCount: getTotalVariantQuantityByProduct(productId, orderProducts),
  };

  const variants = ProductsLogic.getAllOrderedVariants(productInfo);
  const delwinsFromVariants = getDeliveryWindowIdsFromVariants(variants);
  const isAllExpired = isEmpty(delwinsFromVariants);
  const link = (isCheckoutSuccess || isProductEditable) && !isAllExpired ? getUrlForProductAndVariant({ productId, variantId }) : undefined;

  const cancelledVariants = React.useMemo(() => {
    return orderProducts.filter(product => product.isCancelled && product.product === productId);
  }, [orderProducts, productId]);

  const cancelledVariantCount = uniqBy(prop('variant'), cancelledVariants).length;

  const inactiveStyle = React.useMemo(
    () => ({
      [styles.inactiveProduct]: !isCheckoutSuccess && (!isProductEditable || isCancelled),
      [styles.isAllExpired]: isAllExpired,
    }),
    [isProductEditable, isAllExpired, isCancelled, isCheckoutSuccess],
  );

  const soldOutBadge = defaultBadges.find(({ badgeType }) => badgeType === 'soldOut');
  const etaBadge = defaultBadges.find(({ badgeType }) => badgeType === 'eta');
  const isSoldOut = isDefined(soldOutBadge);

  return (
    <ProductBaseInfoLayout hasAddButton={showAddButton}>
      {showAddButton &&
        (isSoldOut ?
          <div className={styles.addButtonPlaceholder} />
        : <AddProductButton className={styles.addButton} productId={productId} />)}
      <div className={cx(styles.imageWrapper, inactiveStyle)}>
        <div className={styles.image}>
          <MaybeLink to={link} tabIndex={-1}>
            <CustomImage enlargeOnHover aspectRatio={imageAspectRatio} src={imageSrc} rounded />
          </MaybeLink>
        </div>
      </div>
      <div className={cx(styles.description, inactiveStyle)}>
        {(isSoldOut || isDefined(etaBadge)) && showBadges && (
          <div className={productBadgesStyles.badgeWrapper}>
            {isSoldOut && (
              <Badge
                className={cx(productBadgesStyles.badge, {
                  [productBadgesStyles.soldOutWithEtaBadge]: isDefined(etaBadge),
                  [productBadgesStyles.rounded]: !isDefined(etaBadge),
                  [productBadgesStyles.roundedStart]: isDefined(etaBadge),
                })}
                backgroundColor={soldOutBadge.backgroundColor}
                textColor={soldOutBadge.textColor}
              >
                {soldOutBadge.name}
              </Badge>
            )}

            {isDefined(etaBadge) && (
              <Badge
                className={cx(productBadgesStyles.badge, productBadgesStyles.etaBadge, { [productBadgesStyles.rounded]: !isSoldOut })}
                backgroundColor={etaBadge.backgroundColor}
                textColor={etaBadge.textColor}
                icon={etaBadge.icon}
                tooltip={etaBadge.tooltip}
                mouseEnterDelay={ETA_TOOLTIP_MOUSE_ENTER_DELAY}
              >
                {etaBadge.name}
              </Badge>
            )}
          </div>
        )}
        <MaybeLink className={styles.descriptionLink} to={link}>
          <Heading title={productInfo.name} variant={['h3']} className={styles.heading} />
        </MaybeLink>
        <Price productInfo={productInfo} />
      </div>
      {!isCheckoutSuccess && (
        <div className={styles.statusWrapper}>
          {isCancelled ?
            <span className={styles.notEditableStatus}>{t('cancelled:product_cancelled')}</span>
          : cancelledVariantCount > 0 && (
              <span className={styles.notEditableStatus}>{t('cancelled:variants_cancelled', { count: cancelledVariantCount })}</span>
            )
          }
          {!isProductEditable && <span className={styles.notEditableStatus}>{t('products:product_not_editable')}</span>}
          {!isEmpty(expiredMessage) && <DelwinExpiredMessage expiredMessage={expiredMessage} className={styles.expiredMessageWrapper} />}
        </div>
      )}
      <ProductAttributes className={cx(styles.attributes, inactiveStyle)} product={productInfo} />
      <ProductSummary
        variantsCount={summaryValues.variantsCount}
        unitsCount={summaryValues.unitsCount}
        price={formatPriceWithCurrencyAffix(currency)(summaryValues.price)}
        className={cx(styles.summaries, inactiveStyle)}
      />
    </ProductBaseInfoLayout>
  );
};
