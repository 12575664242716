import cx from 'classnames';
import React from 'react';

import { useMovement } from '../../../utils/hooks';
import { isDefined } from '../../../utils/is';
import Icon, { IconType } from '../../various/Icon';

import styles from './Resizer.module.scss';

export const enum Direction {
  TOP = 'top',
  RIGHT = 'right',
  LEFT = 'left',
  BOTTOM = 'bottom',
}

interface Props {
  isActive?: boolean;
  onResizeStart?: () => void;
  onResizing: (value: number) => void;
  onResizeEnd?: (value?: number) => void;
  position?: Direction;
  className?: string;
}

export const Resizer = React.memo(({ onResizing, onResizeStart, onResizeEnd, isActive, position = Direction.RIGHT, className }: Props) => {
  const resizeButton = React.useRef<HTMLButtonElement | null>(null);

  const isVertical = position === Direction.TOP || position === Direction.BOTTOM;
  const onMovement = React.useCallback(
    (offset: { xOffset: number; yOffset: number }) => {
      onResizing(isVertical ? offset.yOffset : offset.xOffset);
    },
    [isVertical, onResizing],
  );

  const onEndMovement = React.useCallback(
    (offset: { xOffset: number; yOffset: number }) => {
      if (!isDefined(onResizeEnd)) {
        return;
      }

      onResizeEnd(isVertical ? offset.yOffset : offset.xOffset);
    },
    [onResizeEnd, isVertical],
  );

  useMovement(
    resizeButton,
    {
      onEndMovement,
      onMovement,
      onStartMovement: onResizeStart,
    },
    isActive,
  );

  const classes = React.useMemo(
    () =>
      cx(styles.resizer, className, {
        [styles.top]: position === 'top',
        [styles.right]: position === 'right',
        [styles.left]: position === 'left',
        [styles.bottom]: position === 'bottom',
      }),
    [className, position],
  );

  return (
    <button type="button" className={classes} ref={resizeButton}>
      <Icon type={IconType.Resize} className={styles.resizeIcon} />
    </button>
  );
});
