import { Access, Filters, Id, Sorting, Translations } from '@typings';

import { ACCEPT_HEADER_BASE, CONTENT_TYPE_HEADER } from '../constants/api';
import { DEFAULT_PRODUCTS_PER_REQUEST } from '../constants/limits';
import { showroomApi } from '../services/api';
import { getAuthHeader } from '../utils/auth';
import { isDefined } from '../utils/is';

interface Pagination {
  limit?: number;
  offset: number;
}

interface UserData {
  access: Access;
  forBuyer?: Id;
}

const getUserInfo = (userData: UserData) => (userData.access === 'seller' ? { buyer: userData.forBuyer } : {});

export const fetchLookbookProducts = async (filters: Partial<Filters>, pagination: Pagination, token: string) => {
  return showroomApi.QUERY_POST('/products')({
    data: {
      allVariants: true,
      limit: pagination.limit || DEFAULT_PRODUCTS_PER_REQUEST, // limit 0 should return default
      lookbook: null,
      skipFirst: pagination.offset,
      ...filters,
    },
    headers: {
      ...getAuthHeader(ACCEPT_HEADER_BASE, token),
      'Content-Type': CONTENT_TYPE_HEADER,
    },
  });
};

interface ProductsByFilterPayload {
  filters: Partial<Filters>;
  pagination: Pagination;
  sorting: Sorting.FieldValue;
  userData: UserData;
}

export const fetchProductsByFilters = async ({ filters, pagination, sorting, userData }: ProductsByFilterPayload) => {
  return showroomApi.POST('/products-listing')({
    data: {
      allVariants: true,
      limit: pagination.limit || DEFAULT_PRODUCTS_PER_REQUEST, // limit 0 should return default
      skipFirst: pagination.offset,
      sort: sorting,
      ...filters,
      ...getUserInfo(userData),
    },
  });
};

export const fetchProductsEtaDates = async (filters: Partial<Filters>, userData: UserData) => {
  return showroomApi.POST('/products-listing/etas')({
    data: {
      ...filters,
      ...getUserInfo(userData),
    },
  });
};

export const fetchProductsByIds = async (products: Id[], buyer?: Id) =>
  showroomApi.POST('/products')({
    data: {
      allVariants: true,
      buyer,
      limit: 0,
      products,
      skipFirst: 0,
    },
  });

export const fetchSingleProduct = async (productId: Id, deliveryWindows: Id[], userData?: UserData) =>
  showroomApi.POST('products/:id')({
    data: {
      ...(userData ? getUserInfo(userData) : {}),
      deliveryWindows,
    },
    pathParams: { id: productId },
  });

export const fetchSingleProductByBarcode = async (productBarcode: string, deliveryWindows: Id[], userData?: UserData) =>
  showroomApi.POST('product-by-barcode/:productBarcode')({
    data: {
      ...(userData ? getUserInfo(userData) : {}),
      deliveryWindows,
    },
    pathParams: { productBarcode },
  });

export const fetchSingleLookbookProduct = async (productId: Id, token: string) =>
  showroomApi.QUERY_POST('products/:id')({
    data: {},
    headers: {
      ...getAuthHeader(ACCEPT_HEADER_BASE, token),
      'Content-Type': CONTENT_TYPE_HEADER,
    },
    pathParams: { id: productId },
  });

export interface CmsProductsPayload {
  marketIds?: string[];
  search?: string;
  skipFirst: number;
  limit: number;
  products?: Id[];
  editorLanguage?: Translations.SupportedLanguagesCodes;
}

export const fetchCmsProducts = async (params: CmsProductsPayload) => {
  const { editorLanguage, ...restParams } = params;

  return showroomApi.POST('/cms/products')({
    data: {
      allVariants: true,
      ...restParams,
    },
    ...(isDefined(editorLanguage) && {
      headers: {
        'Accept-Language': editorLanguage,
      },
    }),
  });
};
