import { useTranslation } from 'react-i18next';

import matrixImage from '../../../../../assets/images/matrix.jpg';
import matrixMobileImage from '../../../../../assets/images/matrix-mobile.jpg';
import { MATRIX_MOBILE_BREAKPOINT } from '../../../../constants/breakpoints';
import { getSafeInnerHTML } from '../../../../utils/getSafeInnerHtml';
import { useIsScreenSize, useModalVisibility } from '../../../../utils/hooks';
import { ModalShell } from '../../../various/ModalShell';
import { Unicode } from '../../../various/Unicode/Unicode';

import styles from './MatrixHelp.module.scss';

export const MatrixHelpModal = () => {
  const { t } = useTranslation(['confirmationConfig']);
  const { isModalVisible, hideModal } = useModalVisibility('MatrixHelpModal');
  const isMobile = useIsScreenSize(MATRIX_MOBILE_BREAKPOINT);
  const sizeTablesInfo = getSafeInnerHTML(t('confirmationConfig:size_tables.content', { key: Unicode.CommandKey }));

  const imageSrc = isMobile ? matrixMobileImage : matrixImage;

  return (
    <ModalShell isOpen={isModalVisible} onClose={hideModal} title={t('confirmationConfig:size_tables.title')} className={styles.wideModal}>
      <p dangerouslySetInnerHTML={sizeTablesInfo} />
      <br />
      <img role="presentation" className={styles.modalImage} src={imageSrc} alt="Size table" />
    </ModalShell>
  );
};
