import cx from 'classnames';
import React from 'react';
import { Link as LinkRouter, LinkProps } from 'react-router-dom';

import styles from './Link.module.scss';

type VariantType = 'blue' | 'black' | 'white';

type InternalLinkProps = LinkProps & { external?: false };

export type ExternalLinkProps = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> & {
  external: true;
  to?: string;
};

type Props = (InternalLinkProps | ExternalLinkProps) & {
  variant?: VariantType;
};

export const Link = React.forwardRef<HTMLAnchorElement, Props>(({ variant, children, to, external, className, ...restProps }, ref) => {
  const sharedProps = {
    className: cx(className, styles.focusVisible, {
      [styles.blue]: variant === 'blue',
      [styles.black]: variant === 'black',
      [styles.white]: variant === 'white',
    }),
    rel: external ? 'noopener noreferrer' : undefined,
    ...restProps,
  };

  return external ?
      <a href={to} {...sharedProps} ref={ref}>
        {children}
      </a>
    : <LinkRouter to={to} {...sharedProps} ref={ref}>
        {children}
      </LinkRouter>;
});
