import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';

interface Props<T extends FieldValues> {
  formMethods: UseFormReturn<T>;
  onSubmit: (data: T) => void;
  className?: string;
  id?: string;
  onInvalidSubmit?: () => void;
}

export const Form = <T extends FieldValues = FieldValues>({
  children,
  formMethods,
  onSubmit,
  className,
  id,
  onInvalidSubmit,
}: React.WithChildren<Props<T>>) => {
  const { handleSubmit } = formMethods;

  const onInvalid = () => {
    onInvalidSubmit?.();

    const oldScrollBehavior = document.documentElement.style.getPropertyValue('scroll-behavior');
    document.documentElement.style.setProperty('scroll-behavior', 'smooth');

    requestAnimationFrame(() => {
      document.documentElement.style.setProperty('scroll-behavior', oldScrollBehavior);
    });
  };

  const handleSubmitForm = (event: React.BaseSyntheticEvent) => {
    event.stopPropagation();

    if (formMethods.formState.isSubmitting) {
      return;
    }

    handleSubmit(onSubmit, onInvalid)(event);
  };

  return (
    <FormProvider {...formMethods}>
      <form id={id} noValidate onSubmit={handleSubmitForm} className={className}>
        {children}
      </form>
    </FormProvider>
  );
};
