import { AttributesSource, DeliveryWindow, Product } from '@typings';
import React from 'react';
import { useSelector } from 'react-redux';

import { getActiveDeliveryWindowsIdsForOrderBuyer } from '../../../ducks';
import { getOrderDetails } from '../../../ducks/order';
import { getSortedDeliveryWindowsIds } from '../../../logic/deliveryWindows';
import { getOrderDelwinIds } from '../../../logic/Orders';
import { getOrderedProducts } from '../../../logic/products';
import { switchProductUnit } from '../../../logic/unitsSwitch';
import { useUnitSwitcher } from '../../../utils/hooks/unitSwitcher/useUnitSwitcher';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { getHasValidConfigurator, sortVariantsByAttributes } from '../../../utils/matrix';
import { ProductsEtaContext } from '../ProductsEta';

import { MatrixSettingsContextProvider } from './context';
import { ProductMatrixSection } from './ProductMatrixSection';

interface Props {
  canScollToEtaMatrix?: boolean;
  variants: (Product.Full | Product.Standard)[];
  isLookbook?: boolean;
  isReadOnly?: boolean;
  showOnlyDeliveryWindows?: string[] | number[];
  attributesSource?: AttributesSource;
  showCancelled?: boolean;
}

export const ProductMatrixSectionGeneral = ({
  attributesSource,
  canScollToEtaMatrix,
  variants,
  isLookbook,
  isReadOnly,
  showOnlyDeliveryWindows,
  showCancelled = false,
}: Props) => {
  const activeDeliveryWindowsIds = useSelector(getActiveDeliveryWindowsIdsForOrderBuyer);
  const orderDetails = useSelector(getOrderDetails);
  const { currentItemsEtaPerDelwin } = React.useContext(ProductsEtaContext);

  const { selectedUnits } = useUnitSwitcher();

  const variantsWithMappedUnits = React.useMemo(
    () => variants.map(variant => switchProductUnit(variant, selectedUnits)),
    [variants, selectedUnits],
  );

  const [firstVariant] = variantsWithMappedUnits;

  const validDeliveryWindowIds = showOnlyDeliveryWindows?.map(delwinId => delwinId.toString());

  const sortedDeliveryWindowIds = React.useMemo(() => {
    if (isDefined(validDeliveryWindowIds)) {
      return getSortedDeliveryWindowsIds(validDeliveryWindowIds, activeDeliveryWindowsIds);
    }

    const orderDelwinIds = getOrderDelwinIds(variants, orderDetails, firstVariant?.product ?? '');

    return getSortedDeliveryWindowsIds(orderDelwinIds, activeDeliveryWindowsIds);
  }, [variants, orderDetails, firstVariant?.product, activeDeliveryWindowsIds, validDeliveryWindowIds]);

  const productConfiguration = firstVariant?.configuration;

  const sortedVariants =
    getHasValidConfigurator(productConfiguration) ?
      sortVariantsByAttributes(variantsWithMappedUnits, [productConfiguration.primary, ...productConfiguration.secondary])
    : variantsWithMappedUnits;

  const getVariantsByDeliveryWindow = (deliveryWindowId: DeliveryWindow.Id) => {
    return sortedVariants.filter(variant => {
      const isInOrder = getOrderedProducts(orderDetails).some(
        product =>
          product.deliveryWindow === deliveryWindowId && product.product === variant.product && product.variant === variant.variant,
      );

      const delwins = variant.deliveryWindows.map(id => id.toString()); // Ensure id is a string - API issue with id type

      return isInOrder || delwins.includes(deliveryWindowId);
    });
  };

  const settingsProps = {
    attributesSource,
    isReadOnly,
    productConfiguration,
    showCancelled,
  };

  if (isLookbook && isDefined(validDeliveryWindowIds) && !isEmpty(validDeliveryWindowIds)) {
    const [firstDelwinId] = validDeliveryWindowIds;

    if (!isDefined(firstDelwinId)) {
      return null;
    }

    const variantsInDelwin = getVariantsByDeliveryWindow(firstDelwinId);

    return (
      <MatrixSettingsContextProvider {...settingsProps} isLookbook>
        <ProductMatrixSection deliveryWindowId={firstDelwinId} variants={variantsInDelwin} isFirstDelWin />
      </MatrixSettingsContextProvider>
    );
  }

  const getHasEtaByDelWinId = (delwinId: string) => {
    const etas = currentItemsEtaPerDelwin[delwinId];

    return (
      isDefined(etas) &&
      !isEmpty(etas) &&
      getVariantsByDeliveryWindow(delwinId).some(variant => variant.items.some(item => isDefined(etas[item.item])))
    );
  };

  const getIsFirstEtaDelwin = (delwinId: string) => {
    const firstEtaDelwinId = canScollToEtaMatrix ? sortedDeliveryWindowIds.find(getHasEtaByDelWinId) : undefined;

    return firstEtaDelwinId === delwinId;
  };

  return (
    <MatrixSettingsContextProvider {...settingsProps}>
      {sortedDeliveryWindowIds.map(delwinId => {
        const etas = currentItemsEtaPerDelwin[delwinId] ?? {};
        const isFirstEtaDelwin = !isEmpty(etas) ? getIsFirstEtaDelwin(delwinId) : undefined;

        return (
          <ProductMatrixSection
            key={delwinId}
            isFirstEtaDelwin={isFirstEtaDelwin}
            isFirstDelWin={delwinId === sortedDeliveryWindowIds[0]}
            deliveryWindowId={delwinId}
            variants={getVariantsByDeliveryWindow(delwinId)}
          />
        );
      })}
    </MatrixSettingsContextProvider>
  );
};
