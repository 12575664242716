import i18next from 'i18next';
import { combineEpics } from 'redux-observable';
import { filter, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { addToast } from '../components/various/Toasts';
import {
  changeAccountPasswordFailure,
  changeAccountPasswordRequest,
  changeAccountPasswordSuccess,
  changePasswordFailure,
  changePasswordRequest,
  changePasswordSuccess,
  fetchUserRequest,
  resetPasswordFailure,
  resetPasswordRequest,
  resetPasswordSuccess,
  updateBuyersDataFailure,
  updateBuyersDataRequest,
  updateBuyersDataSuccess,
} from '../ducks/user';
import { mapResponse } from '../utils/operators/mapResponse';

const resetPasswordEpic: AppEpic = (action$, _, { userRepository }) =>
  action$.pipe(
    filter(isActionOf(resetPasswordRequest)),
    mergeMap(async action => userRepository.resetPassword(action.payload.email)),
    mapResponse(
      () => resetPasswordSuccess(),
      () => resetPasswordFailure(),
    ),
  );

const changePasswordEpic: AppEpic = (action$, _, { userRepository }) =>
  action$.pipe(
    filter(isActionOf(changePasswordRequest)),
    mergeMap(async ({ payload: { i, id, newPassword } }) => userRepository.changePassword(i, id, newPassword)),
    mapResponse(
      () => changePasswordSuccess(),
      error => changePasswordFailure(error.data),
    ),
  );

const changeAccountPasswordEpic: AppEpic = (action$, _, { userRepository }) =>
  action$.pipe(
    filter(isActionOf(changeAccountPasswordRequest)),
    mergeMap(async ({ payload: { currentPassword, newPassword } }) => userRepository.changeAccountPassword(currentPassword, newPassword)),
    mapResponse(
      response => {
        addToast(i18next.t('passwordManagement:password_changed'));

        return changeAccountPasswordSuccess(response.data);
      },
      () => changeAccountPasswordFailure(),
    ),
  );

const changeBuyerDataEpic: AppEpic = (action$, _, { userRepository }) =>
  action$.pipe(
    filter(isActionOf(updateBuyersDataRequest)),
    mergeMap(async ({ payload }) => userRepository.changeBuyersData(payload)),
    mapResponse(
      () => {
        addToast(i18next.t('accounts:about_me_update_success_message'));

        return [updateBuyersDataSuccess(), fetchUserRequest()];
      },
      () => {
        addToast(i18next.t('accounts:about_me_update_failure_message'));

        return updateBuyersDataFailure();
      },
    ),
  );

export const userEpic = combineEpics(resetPasswordEpic, changePasswordEpic, changeAccountPasswordEpic, changeBuyerDataEpic);
