/* eslint-disable jsx-a11y/label-has-associated-control */
import { Id, Linesheet } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { isNull } from '../../../utils/is';

import { GridTemplate } from './GridTemplate';
import styles from './LayoutSample.module.scss';
import { ListTemplate } from './ListTemplate';

interface TemplateMeta {
  itemsPerPage: number;
  placeholder: React.ReactNode;
}

export const enum LayoutType {
  GRID_9 = 'landscape-9',
  LIST_4 = 'portrait-4',
}

const TemplatesValues: Record<Linesheet.Template, TemplateMeta> = Object.freeze({
  [LayoutType.GRID_9]: {
    itemsPerPage: 9,
    placeholder: <GridTemplate />,
  },
  [LayoutType.LIST_4]: {
    itemsPerPage: 4,
    placeholder: <ListTemplate />,
  },
});

interface RadioProps {
  type: Linesheet.Template;
  name: string;
  value: Id;
  templateName: string;
  coverUrl: Nullable<string>;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface RadioGroupProps {
  className?: string;
}

const Radio = React.forwardRef<HTMLInputElement, RadioProps>(({ type, name, value, templateName, coverUrl, onChange }, ref) => {
  const { itemsPerPage, placeholder } = TemplatesValues[type];
  const { t } = useTranslation(['linesheets']);
  const id = React.useId();

  const renderCover = React.useCallback(() => {
    if (!isNull(coverUrl)) {
      return <img className={styles.coverImage} alt={templateName} src={coverUrl} />;
    }

    return placeholder;
  }, [coverUrl, placeholder, templateName]);

  return (
    <>
      <input ref={ref} id={id} className={styles.input} type="radio" name={name} value={value} onChange={onChange} />
      <label className={styles.label} htmlFor={id}>
        <div className={styles.holder}>
          <div className={styles.layout}>{renderCover()}</div>
          <div className={styles.title}>
            {templateName}
            <span>{t('linesheets:products_per_page', { count: itemsPerPage })}</span>
          </div>
        </div>
      </label>
    </>
  );
});

const RadioGroup = ({ children, className }: React.WithChildren<RadioGroupProps>) => (
  <div className={cx(styles.group, className)} role="radiogroup">
    {children}
  </div>
);

export const LayoutSample = {
  Radio,
  RadioGroup,
};
