import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  createSelection,
  fetchOrderRequest,
  getBuyerIdFromCurrentOrder,
  getIsSeller,
  getOrderId,
  getSelectionImportErrors,
  getWasSelectionClosedDuringImport,
  getWasSelectionRemovedDuringImport,
  resetSelectionImport,
} from '../../../ducks';
import { compiledPaths } from '../../../paths';
import { useModalVisibility } from '../../../utils/hooks';
import { Alert } from '../../various/Alert';
import { FormButtons } from '../../various/Form';
import Icon, { IconColor, IconType } from '../../various/Icon';
import { Button } from '../../various/NewButton';

import styles from './SelectionImportModal.module.scss';

export const SelectionImportFailure = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'orders', 'selections']);
  const { hideModal } = useModalVisibility('SelectionImportModal');

  const buyerId = useSelector(getBuyerIdFromCurrentOrder);
  const isSeller = useSelector(getIsSeller);
  const orderId = useSelector(getOrderId);
  const errors = useSelector(getSelectionImportErrors);
  const wasSelectionClosed = useSelector(getWasSelectionClosedDuringImport);
  const wasSelectionRemoved = useSelector(getWasSelectionRemovedDuringImport);

  const handleTryAgain = React.useCallback(() => {
    dispatch(resetSelectionImport());
  }, [dispatch]);

  const handleSeeOrder = React.useCallback(() => {
    dispatch(fetchOrderRequest(orderId));
  }, [dispatch, orderId]);

  const handleSeeAllSelections = React.useCallback(() => {
    navigate(compiledPaths.SELECTIONS({}));
  }, [navigate]);

  const handleCreateNewSelection = React.useCallback(() => {
    const buyerKey = isSeller ? { buyer: buyerId } : {};

    dispatch(createSelection({ name: '', redirectPath: 'PRODUCTS_ORDER', ...buyerKey }));
  }, [buyerId, dispatch, isSeller]);

  const actions = React.useMemo(() => {
    if (wasSelectionClosed) {
      return [
        {
          label: t('orders:see_order'),
          onClick: handleSeeOrder,
        },
        {
          label: t('selections:create_new_selection'),
          onClick: handleCreateNewSelection,
        },
      ] as const;
    }

    if (wasSelectionRemoved) {
      return [
        {
          label: t('selections:see_all_selections'),
          onClick: handleSeeAllSelections,
        },
        {
          label: t('selections:create_new_selection'),
          onClick: handleCreateNewSelection,
        },
      ] as const;
    }

    return [
      {
        label: t('common:cancel'),
        onClick: hideModal,
      },
      {
        label: t('common:try_again'),
        onClick: handleTryAgain,
      },
    ] as const;
  }, [
    handleCreateNewSelection,
    handleSeeAllSelections,
    handleSeeOrder,
    handleTryAgain,
    hideModal,
    t,
    wasSelectionClosed,
    wasSelectionRemoved,
  ]);

  const [secondaryAction, primaryAction] = actions;

  return (
    <>
      <div className={cx(styles.importStatus, styles.error)}>
        <Icon type={IconType.Cross} color={IconColor.Red} className={styles.icon} size={32} />
        <div>{t('common:import_failed')}</div>
      </div>
      {errors.map((error, index) => (
        <Alert key={index} type="error" message={error} className={styles.alert} />
      ))}
      <FormButtons>
        <Button size="large" variant="ghost" color="dark" onClick={secondaryAction.onClick}>
          {secondaryAction.label}
        </Button>
        <Button size="large" onClick={primaryAction.onClick}>
          {primaryAction.label}
        </Button>
      </FormButtons>
    </>
  );
};
