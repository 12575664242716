import { Radio, UnitsDistribution } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useMatrixDistributionContext } from '../../../../../../../products/ProductMatrix/context';
import { Form } from '../../../../../../Form';
import { RadioGroupField } from '../../../../../../Form/FormField/RadioGroupField';
import { Button } from '../../../../../../NewButton';
import { AddDistributionButton } from '../../AddDistributionButton';

import styles from './Forms.module.scss';

interface Props {
  onSubmit: () => void;
  onModeChange: (mode: UnitsDistribution.Mode) => void;
}

export const ModeForm = ({ onSubmit, onModeChange }: Props) => {
  const { modeFormMethods, setDistributionResult, setDistributionStage } = useMatrixDistributionContext();
  const { t } = useTranslation(['common', 'orders']);

  const handleBack = React.useCallback(() => {
    modeFormMethods.reset();
    setDistributionResult({});
    setDistributionStage('preview');
  }, [modeFormMethods, setDistributionResult, setDistributionStage]);

  const options: Radio.Option<UnitsDistribution.Mode>[] = [
    { name: t('orders:distribution_reduce'), value: 'maxAvailable' },
    { name: t('orders:distribution_redistribute'), value: 'redistribute' },
  ];

  return (
    <Form className={styles.form} formMethods={modeFormMethods} onSubmit={onSubmit}>
      <div className={styles.modeLabel}>{t('orders:distribution_mode_select')}</div>
      <RadioGroupField
        name="mode"
        options={options}
        orientation="vertical"
        groupOrientation="vertical"
        itemClassName={styles.modeItem}
        className={styles.radioGroup}
        onChange={onModeChange}
      />
      <div className={styles.formButtonsContainer}>
        <Button size="small" variant="ghost" color="dark" onClick={handleBack}>
          {t('common:back')}
        </Button>
        <AddDistributionButton />
      </div>
    </Form>
  );
};
