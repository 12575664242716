import React from 'react';

import { useIsTouchScreen } from './useIsTouchScreen';
import { MovementOffset, useMovement } from './useMovement';

interface Props {
  activeIndex: number;
  dotSize: number;
  totalCount: number;
  visibleCount: number;
  onChange: (index: number) => void;
  orientation: 'horizontal' | 'vertical';
}

export const useGalleryDots = ({ activeIndex = 0, dotSize, totalCount, visibleCount, onChange, orientation }: Props) => {
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const indexBeforeDrag = React.useRef(0);
  const isTouchScreen = useIsTouchScreen();
  const isHorizontal = orientation === 'horizontal';

  const handleDragStart = React.useCallback(
    (_: MovementOffset, event: TouchEvent | MouseEvent) => {
      event.stopImmediatePropagation();
      indexBeforeDrag.current = activeIndex;
    },
    [activeIndex],
  );

  const handleDrag = React.useCallback(
    ({ xOffset, yOffset }: MovementOffset) => {
      const indexXOffset = xOffset > 0 ? Math.floor(xOffset / dotSize) : Math.ceil(xOffset / dotSize);

      const indexYOffset = yOffset > 0 ? Math.floor(yOffset / dotSize) : Math.ceil(yOffset / dotSize);

      const indexOffset = isHorizontal ? indexXOffset : indexYOffset;

      const newIndex = Math.max(0, Math.min(totalCount - 1, indexBeforeDrag.current + indexOffset));
      onChange(newIndex);
    },
    [dotSize, isHorizontal, onChange, totalCount],
  );

  useMovement(
    wrapperRef,
    {
      onMovement: handleDrag,
      onStartMovement: handleDragStart,
      useTouch: true,
    },
    isTouchScreen,
  );

  const dotsStyles = React.useMemo(() => {
    if (visibleCount < 1 || !isTouchScreen) {
      return;
    }

    const visibleOffsetCount = activeIndex - Math.floor(visibleCount / 2);
    const hiddenCount = totalCount - visibleCount;
    const offsetCount = Math.max(0, Math.min(visibleOffsetCount, hiddenCount));

    if (isHorizontal) {
      return {
        maxWidth: visibleCount * dotSize,
        transform: `translateX(${dotSize * -offsetCount}px)`,
      };
    }

    return {
      maxHeight: visibleCount * dotSize,
      transform: `translateY(${dotSize * -offsetCount}px)`,
    };
  }, [activeIndex, dotSize, isHorizontal, isTouchScreen, totalCount, visibleCount]);

  return { dotsStyles, wrapperRef };
};
