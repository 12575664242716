import { Cms, Id } from '@centra';
import React from 'react';

interface Context {
  manualLayoutChangeData: Record<Id, Cms.AnyBlock>;
  setManualLayoutChangeData: React.Dispatch<React.SetStateAction<Record<string, Cms.AnyBlock>>>;
}

const initialContext: Context = {
  manualLayoutChangeData: {},
  setManualLayoutChangeData: () => null,
};

export const ManualLayoutEditorContext = React.createContext<Context>(initialContext);

export const ManualLayoutEditorContextProvider = ({ children }: React.WithChildren) => {
  const [manualLayoutChangeData, setManualLayoutChangeData] = React.useState<Record<Id, Cms.AnyBlock>>({});

  return (
    <ManualLayoutEditorContext.Provider
      value={{
        manualLayoutChangeData,
        setManualLayoutChangeData,
      }}
    >
      {children}
    </ManualLayoutEditorContext.Provider>
  );
};
