import { useIsMutating } from '@tanstack/react-query';
import { createColumnHelper, getCoreRowModel, OnChangeFn, SortingState } from '@tanstack/react-table';
import { DeliveryWindow, Order } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getIsSeller } from '../../../../ducks/user';
import { getIsOrderEditable } from '../../../../logic/Orders';
import { compiledPaths } from '../../../../paths';
import { DELETE_SELECTIONS_MUTATION_KEY } from '../../../../services/hooks/orders/useDeleteSelections';
import { getFieldBySortKey, SortOrder } from '../../../../utils/hooks';
import { usePaginationHandler } from '../../../../utils/hooks/table/usePaginationHandler';
import { useTable } from '../../../../utils/hooks/table/useTable';
import { useTableColumnsConfiguration } from '../../../../utils/hooks/table/useTableColumnsConfiguration';
import { useAmplitude } from '../../../../utils/hooks/useAmplitude';
import { isDefined } from '../../../../utils/is';
import { renderSentryMaskedText } from '../../../../utils/monitoring';
import { ACTION_COLUMN_WIDTH, getUpdatedStateValue, renderMaskedCell, renderStandardCell } from '../../../../utils/table';
import { Link } from '../../../various/Link';
import { Table } from '../../../various/Table';
import { TableControls } from '../../../various/TableControls';
import { CopyToNewSelectionModal } from '../../CopyToNewSelectionModal';
import { DownloadLinesheetModal } from '../../DownloadLinesheetModal';

import { DeliveryWindowsColumn } from './DeliveryWindowsColumn';
import { OrderActionsColumn } from './OrderActionsColumn';
import { OrderNumberColumn } from './OrderNumberColumn';
import styles from './OrdersListTable.module.scss';
import { StatusColumn } from './StatusColumn';

const columnHelper = createColumnHelper<Order.Essentials>();

interface Props {
  sortOrder: SortOrder;
  deliveryWindows: DeliveryWindow.Mixed[];
  handlePageChange: (newPage: number) => void;
  handleSortChange: (newSorter: SortOrder) => void;
  page: number;
  ordersCount: number | undefined;
  orders: Order.Essentials[] | undefined;
  isLoadingList: boolean;
}

export const OrdersListTable = React.memo(
  ({ sortOrder = [], deliveryWindows, handlePageChange, handleSortChange, page, ordersCount, orders = [], isLoadingList }: Props) => {
    const { t } = useTranslation(['common', 'orders', 'totals', 'buyers', 'shipping', 'selections', 'accounts']);
    const [order, setOrder] = React.useState<Nullable<Order.Essentials>>(null);
    const { columnsVisibility } = useTableColumnsConfiguration({ tableName: 'ordersTable' });
    const { trackEvent } = useAmplitude();

    const [sorting, setSorting] = React.useState<SortingState>(
      sortOrder.map(sortItem => ({ desc: sortItem.order === 'desc', id: getFieldBySortKey(sortItem.field) })),
    );

    const handleSortingChange: OnChangeFn<SortingState> = updaterOrValue => {
      const newSorting = getUpdatedStateValue(updaterOrValue, sorting);

      handleSortChange(newSorting.map(sortItem => ({ field: sortItem.id, order: sortItem.desc ? 'desc' : 'asc' })));
      setSorting(updaterOrValue);
    };

    const handleControlsChange = (column: string, isChecked: boolean) => {
      if (!isChecked) {
        trackEvent({ name: 'ordersTable.multiselect.column.unchecked', properties: { column } });
      }
    };

    const { pagination, handlePaginationChange } = usePaginationHandler({ onPageChange: handlePageChange, page });

    const isDeletingAnySelection = useIsMutating({ mutationKey: [DELETE_SELECTIONS_MUTATION_KEY] }) > 0;
    const downloadOnlySelected = isDefined(order) ? !getIsOrderEditable(order.status) : true;
    const isSeller = useSelector(getIsSeller);

    const getDeliveryWindowByIds = React.useCallback(
      (deliveryWindowsIds: DeliveryWindow.Id[]) => {
        return deliveryWindowsIds
          .map(delwinId => deliveryWindows.find(({ deliveryWindow }) => delwinId === deliveryWindow))
          .filter(isDefined);
      },
      [deliveryWindows],
    );

    const columns = React.useMemo(() => {
      return [
        columnHelper.accessor('orderNumber', {
          cell: info => <OrderNumberColumn order={info.row.original} />,
          enableHiding: false,
          header: t('orders:order_no'),
        }),
        columnHelper.accessor('accountName', {
          cell: info => {
            const orderRow = info.row.original;
            const path = compiledPaths.ACCOUNT_DETAILS({ accountId: orderRow.account });

            const handleClick = () => {
              trackEvent({ name: 'ordersTable.link.accountName.click' });
            };

            return isSeller ?
                <Link variant="blue" to={path} data-sentry-mask onClick={handleClick}>
                  {renderSentryMaskedText(info.getValue())}
                </Link>
              : renderSentryMaskedText(orderRow.accountName);
          },
          header: t('accounts:account_one'),
          id: 'account',
        }),
        columnHelper.accessor('buyer', {
          cell: info => {
            const orderRow = info.row.original;

            return <span data-sentry-mask>{renderSentryMaskedText(`${orderRow.buyerFirstName} ${orderRow.buyerLastName}`)}</span>;
          },
          header: t('buyers:buyer_one'),
          id: 'buyer',
        }),
        columnHelper.accessor('poNumber', {
          cell: renderStandardCell,
          header: t('common:po_no'),
        }),
        columnHelper.accessor('shippingCountry', {
          cell: renderMaskedCell,
          enableSorting: false,
          header: t('shipping:shipping_country'),
        }),
        columnHelper.accessor('deliveryWindows', {
          cell: info => {
            const orderdeliveryWindows = info.row.original.deliveryWindows;

            return <DeliveryWindowsColumn deliveryWindows={getDeliveryWindowByIds(orderdeliveryWindows)} />;
          },
          enableSorting: false,
          header: t('common:delivery_window_one'),
          id: 'delivery',
          meta: {
            noWrap: true,
          },
        }),
        columnHelper.accessor('totals', {
          cell: info => info.getValue().itemsCount,
          header: t('common:unit_other'),
          id: 'itemsCount',
          meta: {
            align: 'right',
            noWrap: true,
          },
        }),
        columnHelper.accessor('totals', {
          cell: info => info.getValue().grandTotalWithoutTax,
          header: t('totals:total_no_tax'),
          id: 'total',
          meta: {
            align: 'right',
            noWrap: true,
          },
        }),
        columnHelper.accessor('status', {
          cell: info => {
            return <StatusColumn status={info.getValue()} record={info.row.original} />;
          },
          enableSorting: false,
          header: t('common:status'),
          meta: {
            align: 'right',
            noWrap: true,
            skeletonType: 'multi',
          },
        }),
        columnHelper.display({
          cell: info => {
            return <OrderActionsColumn order={info.row.original} setOrder={setOrder} />;
          },
          enableHiding: false,
          id: 'order',
          meta: {
            skeletonType: 'actions',
            width: ACTION_COLUMN_WIDTH,
          },
        }),
      ];
    }, [t, isSeller, trackEvent, getDeliveryWindowByIds]);

    const orderId = order?.order;
    const orderNumber = order?.orderNumber;
    const buyer = order?.buyer;
    const table = useTable(
      {
        columns,
        data: orders,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: true,
        manualSorting: true,
        onPaginationChange: handlePaginationChange,
        onSortingChange: handleSortingChange,
        state: {
          pagination,
          sorting,
        },
      },
      columnsVisibility,
    );

    return (
      <>
        <TableControls
          table={table}
          className={styles.tableControls}
          columnsConfiguration={columnsVisibility}
          tableName="ordersTable"
          onChange={handleControlsChange}
        />
        <Table
          dataTestId="ordersList"
          isLoading={isLoadingList || isDeletingAnySelection}
          table={table}
          pagination={{
            current: pagination.pageIndex,
            total: ordersCount,
          }}
        />

        {isDefined(orderId) && isDefined(orderNumber) && (
          <>
            <DownloadLinesheetModal
              location="order table"
              orderId={orderId}
              orderNumber={orderNumber}
              downloadOnlySelected={downloadOnlySelected}
            />
            <CopyToNewSelectionModal orderId={orderId} orderNumber={orderNumber} defaultBuyer={buyer} />
          </>
        )}
      </>
    );
  },
);
