import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getIsSavingAnything } from '../../../ducks';
import { getIsAnyProductLoading } from '../../../ducks/products';
import { useRemoveAllProducts } from '../../../utils/hooks';
import { ActionButton } from '../../products/ActionButton';

interface Props {
  className?: string;
  removeAction?: () => void;
  isDisabled?: boolean;
}

export const ClearAllButton = ({ className, removeAction, isDisabled = false }: Props) => {
  const isSaving = useSelector((state: Store) => getIsSavingAnything(state) || getIsAnyProductLoading(state));
  const { canRemoveAllProducts, removeAllProducts } = useRemoveAllProducts();
  const { t } = useTranslation(['common']);

  if (!canRemoveAllProducts) {
    return null;
  }

  return (
    <ActionButton
      title={t('common:clear_all')}
      onClick={removeAction || removeAllProducts}
      isDisabled={isSaving || isDisabled}
      className={className}
    />
  );
};
