import { Cms } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconType } from '../../../various/Icon';
import { IconOption, IconRadioGroup } from '../../../various/IconRadioGroup';

const enum FontStyle {
  NORMAL = 'normal',
  ITALIC = 'italic',
}
interface Props {
  value: Cms.FontStyle;
  onChange: (value: Cms.FontStyle) => void;
}

export const FontStyleSwitch = ({ value = FontStyle.NORMAL, onChange }: Props) => {
  const changeHandler = React.useCallback((fontStyle: Cms.FontStyle) => onChange(fontStyle), [value, onChange]);
  const { t } = useTranslation(['cms']);

  const options: IconOption[] = [
    {
      icon: IconType.Dash,
      name: t('cms:normal'),
      value: FontStyle.NORMAL,
    },
    {
      icon: IconType.Italic,
      name: t('cms:italic'),
      value: FontStyle.ITALIC,
    },
  ];

  return (
    <IconRadioGroup
      groupName={t('cms:font_style')}
      selectedValue={value}
      options={options}
      dataTestId="textStyleEditorFontStyle"
      onChange={changeHandler}
    />
  );
};
