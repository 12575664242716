import { nanoid } from 'nanoid';
import { mapObjIndexed } from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EditorUIContext } from '../../../components/cms/context/EditorUIContext';
import { getAvailablePageLanguageCodes, getCmsBlocks, getCmsGroups, getPageMetaData, pasteGroup, removeGroup } from '../../../ducks';
import { getAllCmsLocalizations } from '../../../ducks/cms/localizations';
import { getBlocksWithNewPartsIdsAndPartsDictionary, getCopiedLocalizations, mapToNewIds } from '../../../logic/cms/copyPaste';
import { isDefined } from '../../is';
import { listToRecord } from '../../normalize';

import { useCmsClipboard } from './useCmsClipboard';

export const useGroupCopyPaste = () => {
  const dispatch = useDispatch();

  const pageMeta = useSelector(getPageMetaData);
  const allGroups = useSelector(getCmsGroups);
  const allBlocks = useSelector(getCmsBlocks);
  const allLocalizations = useSelector(getAllCmsLocalizations);
  const pageLanguages = useSelector(getAvailablePageLanguageCodes);

  const { screenType } = React.useContext(EditorUIContext);
  const { cmsClipboard, hasGroupInClipboard, setCmsClipboard } = useCmsClipboard();

  const copyGroup = (groupId: string) => {
    const group = allGroups[groupId];

    if (!isDefined(group)) {
      return;
    }

    const blocks = group.blocks.map(blockId => allBlocks[blockId]).filter(isDefined);
    const localizations = mapObjIndexed(
      translations => ({
        blocks: Object.entries(translations.blocks).reduce(
          (acc, [blockId, block]) => ({
            ...acc,
            ...(group.blocks.includes(blockId) ? { [blockId]: block } : {}),
          }),
          {},
        ),
      }),
      allLocalizations,
    );

    const groupNode = document.getElementById(groupId);
    const preview = {
      content: {
        __html: groupNode?.outerHTML ?? '',
      },
      dimensions: {
        height: groupNode?.offsetHeight ?? 0,
        width: groupNode?.offsetWidth ?? 0,
      },
      screenType,
    };

    setCmsClipboard({
      blocks,
      groups: [group],
      localizations,
      preview,
    });
  };

  const cutGroup = (groupId: string, sectionId: string) => {
    copyGroup(groupId);
    dispatch(removeGroup({ groupId, sectionId }));
  };

  const pasteCopiedGroup = (insertAt: number, sectionId?: string) => {
    if (!isDefined(cmsClipboard)) {
      return;
    }

    const { groups: copiedGroups, blocks: copiedBlocks, localizations: copiedBlocksLocalizations } = cmsClipboard;

    const [copiedGroup] = copiedGroups;
    const [blocksWithNewIds, blocksDictionary] = mapToNewIds(copiedBlocks);
    const [blocksWithNewPartIds, partsDictionary] = getBlocksWithNewPartsIdsAndPartsDictionary(blocksWithNewIds);

    const groupId = nanoid();
    const blocks = blocksWithNewPartIds.map(block => ({ ...block, groupId }));
    const group = {
      blocks: copiedGroup?.blocks.map(blockId => blocksDictionary[blockId]).filter(isDefined) ?? [],
      id: groupId,
    };
    const localizations = getCopiedLocalizations({
      blocks: copiedBlocks,
      blocksDictionary,
      languages: pageLanguages,
      localizations: copiedBlocksLocalizations,
      partsDictionary,
    });

    const markets = pageMeta?.markets.map(data => data.id) ?? [];

    dispatch(
      pasteGroup({
        blocks: listToRecord(blocks, 'id'),
        group,
        insertAt,
        localizations,
        ...(isDefined(sectionId) ? { sectionId } : { markets }),
      }),
    );
  };

  return {
    cmsClipboard,
    copyGroup,
    cutGroup,
    hasGroupInClipboard,
    pasteCopiedGroup,
    setCmsClipboard,
  };
};
