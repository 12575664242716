import { Cms, Id } from '@typings';
import { createAction } from 'typesafe-actions';

export const removeGroup = createAction('cms/REMOVE_GROUP')<{
  groupId: Id;
  sectionId: Id;
}>();
export const groupRemoved = createAction('cms/GROUP_REMOVED')<{
  groupId: Id;
  sectionId: Id;
  blockIds: Id[];
}>();
export const pasteGroup = createAction('cms/PASTE_GROUP')<Cms.PasteGroupPayload>();

export const automaticGroupChangeRequest = createAction('cms/AUTOMATIC_GROUP_CHANGE_REQUEST')<{
  models: Cms.BlockModel[];
  sectionId: Id;
  groupId: Id;
}>();

export const automaticGroupChangeSuccess = createAction('cms/AUTOMATIC_GROUP_CHANGE_SUCCESS')<{
  blocks: Cms.AnyBlock[];
  groupId: Id;
  sectionId: Id;
}>();

export const manualLayoutEditingRequest = createAction('cms/MANUAL_LAYOUT_EDITING_REQUEST')<{
  blocks: Cms.AnyBlock[];
  models: Cms.BlockModel[];
  sectionId: Id;
  groupId: Id;
}>();

export const manualLayoutEditingDiscard = createAction('cms/MANUAL_LAYOUT_EDITING_DISCARD')();

export const manualLayoutEditingSuccess = createAction('cms/MANUAL_LAYOUT_EDITING_SUCCESS')<{
  blocks: Cms.AnyBlock[];
  groupId: Id;
  sectionId: Id;
}>();
