import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Cms } from '@typings';
import { useTranslation } from 'react-i18next';

import { addToast } from '../../../components/various/Toasts';
import { createCustomLayout } from '../../customLayoutsRepository';

import { CUSTOM_LAYOUTS_QUERY_KEY } from './useCustomLayoutsList';

export const useCreateCustomLayout = () => {
  const { t } = useTranslation(['cms']);
  const queryClient = useQueryClient();

  const handleError = () => {
    addToast(t('cms:custom_layouts_creation_fail'));
  };

  const handleSuccess = (createdLayout: Cms.CustomLayoutData) => {
    addToast(t('cms:custom_layouts_edit_success'));

    queryClient.setQueriesData<Cms.CustomLayoutData[]>({ queryKey: [CUSTOM_LAYOUTS_QUERY_KEY] }, (layouts = []) => [
      createdLayout,
      ...layouts,
    ]);

    queryClient.invalidateQueries({ queryKey: [CUSTOM_LAYOUTS_QUERY_KEY] });
  };

  return useMutation({
    mutationFn: createCustomLayout,
    onError: handleError,
    onSuccess: handleSuccess,
  });
};
