import { TooltipProps } from '@typings';
import cx from 'classnames';

import Icon, { IconColor, IconType } from '../Icon';
import { Tooltip } from '../Tooltip';

import styles from './HelpTooltip.module.scss';

interface Props extends Omit<TooltipProps, 'children'> {
  iconSize?: number;
  iconClassName?: string;
}

const DEFAULT_ICON_SIZE = 20;

export const HelpTooltip = ({ content, iconClassName, iconSize = DEFAULT_ICON_SIZE, ...props }: Props) => {
  return (
    <Tooltip content={content} {...props}>
      <span className={cx(styles.icon, iconClassName)}>
        <Icon type={IconType.HelpOutline} color={IconColor.Gray} size={iconSize} />
      </span>
    </Tooltip>
  );
};
