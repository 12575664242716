import { Cms } from '@typings';
import { useTranslation } from 'react-i18next';

import universalStyles from '../../../../../css/utilities/universal.module.scss';
import { PixelField } from '../../TextTools';

import styles from './PaddingFieldset.module.scss';

interface Props {
  properties: Cms.ButtonStyleProperties;
  onChange: Cms.SetStyleProperty<Cms.ButtonStyleProperties>;
  minValue?: number;
  maxValue?: number;
  disabled?: boolean;
}

export const PaddingFieldset = ({ properties, onChange, minValue, maxValue, disabled = false }: Props) => {
  const { t } = useTranslation(['cms']);

  return (
    <div className={styles.container}>
      <label>
        <span className={universalStyles.srOnly}>{t('cms:button_padding_top')}</span>
        <PixelField
          value={properties.paddingTop}
          onChange={onChange('paddingTop')}
          minValue={minValue}
          maxValue={maxValue}
          disabled={disabled}
          testId="buttonPaddingTop"
        />
      </label>

      <div className={styles.middleRow}>
        <label>
          <span className={universalStyles.srOnly}>{t('cms:button_padding_left')}</span>
          <PixelField
            value={properties.paddingLeft}
            onChange={onChange('paddingLeft')}
            minValue={minValue}
            maxValue={maxValue}
            disabled={disabled}
            testId="buttonPaddingLeft"
          />
        </label>

        <div className={styles.element} />

        <label>
          <span className={universalStyles.srOnly}>{t('cms:button_padding_right')}</span>
          <PixelField
            value={properties.paddingRight}
            onChange={onChange('paddingRight')}
            minValue={minValue}
            maxValue={maxValue}
            disabled={disabled}
            testId="buttonPaddingRight"
          />
        </label>
      </div>

      <label>
        <span className={universalStyles.srOnly}>{t('cms:button_padding_bottom')}</span>
        <PixelField
          value={properties.paddingBottom}
          onChange={onChange('paddingBottom')}
          minValue={minValue}
          maxValue={maxValue}
          disabled={disabled}
          testId="buttonPaddingBottom"
        />
      </label>
    </div>
  );
};
