/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 16;

export const EditLinear = ({ width = ORIGINAL_WIDTH, color = 'currentColor' }: IconComponentProps) => {
  return (
    <svg width={width} height={width} viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.615 3.484a.901.901 0 00-.637.264l-.466.465 1.275 1.274.465-.465a.9.9 0 00-.292-1.47.901.901 0 00-.345-.068zm-.595 2.77L9.746 4.98l-5.554 5.553-.478 1.753 1.753-.478 5.553-5.554zm-.165-3.703a1.985 1.985 0 012.164 3.237L6.13 12.676a.542.542 0 01-.24.14l-2.806.765a.542.542 0 01-.666-.666l.765-2.806a.542.542 0 01.14-.24l6.887-6.888c.185-.184.404-.33.644-.43z"
        fill={color}
      />
    </svg>
  );
};
