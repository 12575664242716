import { useMatrixVariantsContext } from '../../../components/products/ProductMatrix/context';
import { getIsTwoDimensional } from '../../../logic/sizeCharts';
import { isDefined } from '../../is';

export const useMatrixLayout = () => {
  const { variants } = useMatrixVariantsContext();
  const [firstVariant] = variants;

  const hasMultipleColumns = variants.some(variant => variant.itemTable.x.length > 1);
  const isTwoDimensional = isDefined(firstVariant) && getIsTwoDimensional(firstVariant.itemTable);
  const hasMultipleRows = variants.length > 1 || (firstVariant?.itemTable.y.length ?? 0) > 1;

  return {
    hasMultipleColumns,
    hasMultipleRows,
    isTwoDimensional,
  };
};
