import cx from 'classnames';

import { Table } from '../../../ducks/helpers';

import { Cell } from './Cell';
import styles from './Chart.module.scss';

interface Props {
  table: Table;
  className?: string;
}

const Chart = ({ table, className }: Props) => {
  const { headers, body } = table;

  return (
    <table className={cx(styles.chart, className)}>
      <thead>
        <tr>
          {headers.map(cell => (
            <Cell th={cell.th} content={cell.content} key={cell.key} />
          ))}
        </tr>
      </thead>
      <tbody>
        {body.map(row => (
          <tr key={row.map(cell => cell.key).join('')}>
            {row.map(cell => (
              <Cell th={cell.th} content={cell.content} key={cell.key} />
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Chart;
