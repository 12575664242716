/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;
const ORIGINAL_COLOR = '#848484';

export const Layout = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => (
  <svg width={width} height={width} viewBox="0 0 20 20" fill="none">
    <path
      d="M3 15.5V4.5H17V15.5H3ZM15.95 9.48438V5.53125H4.05V9.48438H15.95ZM8.3025 14.4688H15.95V10.5156H8.3025V14.4688ZM4.05 14.4688H7.2525V10.5156H4.05V14.4688Z"
      fill={color}
    />
  </svg>
);
