import React from 'react';

/**
 * Hook that returns whether the cursor is in an element's bounds.
 * To be used when we don't want to block hover from other elements. Otherwise use `react-laag:useHover`.
 */
export const useIsOver = () => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [isOver, setIsOver] = React.useState(false);

  React.useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      if (!ref.current) {
        return;
      }

      const { left, top, right, bottom } = ref.current.getBoundingClientRect();
      const { clientX, clientY } = event;

      setIsOver(clientX > left && clientX < right && clientY > top && clientY < bottom);
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, [ref]);

  React.useEffect(() => {
    if (!isOver) {
      return;
    }

    const handleScroll = () => {
      setIsOver(false);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isOver]);

  return {
    isOver,
    ref,
  };
};
