import { Switcher } from '@typings';
import cx from 'classnames';
import React from 'react';
import { PlacementType } from 'react-laag/dist/PlacementType';

import universalStyles from '../../../../css/utilities/universal.module.scss';
import { isDefined } from '../../../utils/is';
import Icon, { IconColor } from '../Icon';
import { MaybeTooltip } from '../MaybeTooltip';
import { TextEllipsis } from '../TextEllipsis';

import styles from './ContentSwitcher.module.scss';

type Props<T> = {
  tab: Switcher.Tab<T>;
  groupName: string;
  isSelected: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  showTooltip?: boolean;
  tooltipPlacement?: PlacementType;
  isDisabled?: boolean;
};

export const Tab = <T extends string | undefined>({
  tab,
  groupName,
  isSelected,
  onChange,
  showTooltip = false,
  tooltipPlacement = 'bottom-center',
  isDisabled,
}: Props<T>) => {
  const { value, title, icon, iconSize, className, dataTestId } = tab;
  const id = `${groupName}${value}`;

  return (
    <MaybeTooltip content={showTooltip ? title : undefined} placement={tooltipPlacement}>
      <div className={cx(styles.tab, { [styles.selected]: isSelected }, className)} data-testid={dataTestId}>
        <input
          id={id}
          role="tab"
          name={groupName}
          value={value}
          checked={isSelected}
          type="radio"
          className={universalStyles.srOnly}
          onChange={onChange}
          disabled={isDisabled}
          aria-selected={isSelected}
        />
        <label className={cx(styles.label, { [styles.iconWithText]: isDefined(icon) && isDefined(title) && !showTooltip })} htmlFor={id}>
          {isDefined(icon) && <Icon type={icon} color={isSelected ? IconColor.Dark : IconColor.Default} size={iconSize} />}

          <TextEllipsis className={cx({ [universalStyles.srOnly]: showTooltip })}>{title}</TextEllipsis>
        </label>
      </div>
    </MaybeTooltip>
  );
};
