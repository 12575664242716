import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { addToast } from '../../../components/various/Toasts';
import { compiledPaths } from '../../../paths';
import { createLookbook } from '../../lookbookRepository';

export const useCreateLookbook = () => {
  const { t } = useTranslation(['lookbook']);
  const navigate = useNavigate();

  const handleSuccess = ({ lookbook }: { lookbook: string }) => {
    navigate(compiledPaths.LOOKBOOK_SINGLE({ lookbookId: lookbook }));
  };

  const handleError = () => {
    addToast(t('lookbook:lookbook_creation_fail'));
  };

  return useMutation({
    mutationFn: createLookbook,
    onError: handleError,
    onSuccess: handleSuccess,
  });
};
