import { PopoverMenuItems } from '@typings';
import React from 'react';
import { TriggerProps, useLayer } from 'react-laag';

import { SelectableItem } from '../PopoverMenu/MenuElements/SelectableItem';
import popoverMenuStyles from '../PopoverMenu/PopoverMenu.module.scss';

const TRIGGER_OFFSET = 4;

export type RenderTrigger = (triggerProps: TriggerProps & { onClick: () => void }, isOpen: boolean) => React.ReactNode;

interface Props {
  value: string;
  options: PopoverMenuItems.ClickableItem[];
  renderTrigger: RenderTrigger;
  onSelect: (value: string) => void;
}

export const DropdownSelect = ({ renderTrigger, value, options, onSelect }: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleToggleMenu = () => {
    setIsOpen(opened => !opened);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const { renderLayer, layerProps, triggerProps } = useLayer({
    auto: true,
    isOpen,
    onOutsideClick: closeMenu,
    overflowContainer: true,
    placement: 'bottom-end',
    possiblePlacements: ['bottom-end'],
    triggerOffset: TRIGGER_OFFSET,
  });

  return (
    <>
      {renderTrigger({ onClick: handleToggleMenu, ...triggerProps }, isOpen)}
      {isOpen &&
        renderLayer(
          <ul className={popoverMenuStyles.menuContainer} {...layerProps}>
            {options.map(({ key, label }) => (
              <SelectableItem key={key} item={{ key, label, onClick: closeMenu }} isSelected={value === key} onSelect={onSelect} />
            ))}
          </ul>,
        )}
    </>
  );
};
