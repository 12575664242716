import { PopoverMenuItems } from '@typings';
import React from 'react';

import { isDefined } from '../../../../utils/is';
import { SeparatorItem } from '../../SeparatorItem';

import { ClickableItem } from './ClickableItem';
import { LinkItem } from './LinkItem';
import { ParentItem } from './ParentItem';

interface Props {
  items: PopoverMenuItems.AnyMenuItem[];
  closeMenu: () => void;
  containerClassName?: string;
}

export const isParentMenuItem = (
  candidate: PopoverMenuItems.AnyMenuItem,
): candidate is PopoverMenuItems.ParentItem | PopoverMenuItems.ParentSelectableItem =>
  isDefined((candidate as PopoverMenuItems.ParentItem).items);

export const isClickableMenuItem = (candidate: PopoverMenuItems.AnyMenuItem): candidate is PopoverMenuItems.ClickableItem =>
  isDefined((candidate as PopoverMenuItems.ClickableItem).onClick) || isDefined((candidate as PopoverMenuItems.ClickableItem).label);

export const isLinkMenuItem = (candidate: PopoverMenuItems.AnyMenuItem): candidate is PopoverMenuItems.LinkItem =>
  isDefined((candidate as PopoverMenuItems.LinkItem).to);

export const MenuItems = ({ items, closeMenu, containerClassName }: Props) => {
  const [activeParentItemKey, setActiveParentItemKey] = React.useState<number | string | null>(null);

  const handleToggleOpen = (itemKey: number | string) => () => {
    setActiveParentItemKey(activeParentItemKey === itemKey ? null : itemKey);
  };

  return (
    <>
      {items.map(item => {
        if (isParentMenuItem(item)) {
          return (
            <ParentItem
              key={item.key}
              item={item}
              isOpen={item.key === activeParentItemKey}
              closeMenu={closeMenu}
              onToggleOpen={handleToggleOpen(item.key)}
              containerClassName={containerClassName}
            />
          );
        }

        if (isLinkMenuItem(item)) {
          return <LinkItem key={item.key} item={item} closeMenu={closeMenu} />;
        }

        if (isClickableMenuItem(item)) {
          return <ClickableItem key={item.key} item={item} closeMenu={closeMenu} />;
        }

        return <SeparatorItem key={item.key} title={item.separatorLabel} />;
      })}
    </>
  );
};
