import { Tables } from '@typings';
import cx from 'classnames';

import { ArrowSmallDown, ArrowSmallUp } from '../../../Icon';
import styles from '../../Table.module.scss';

interface Props {
  sortOrder?: Tables.SortOrder | null;
}

export const TableSorter = ({ sortOrder }: Props) => {
  const upArrowClasses = cx(styles.sorterIcon, {
    [styles.activeSorter]: sortOrder === 'asc',
  });
  const downArrowClasses = cx(styles.sorterIcon, {
    [styles.activeSorter]: sortOrder === 'desc',
  });

  return (
    <div className={styles.sorter}>
      <span className={upArrowClasses}>
        <ArrowSmallUp color="currentColor" />
      </span>
      <span className={downArrowClasses}>
        <ArrowSmallDown color="currentColor" />
      </span>
    </div>
  );
};
