/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 14;

export const PieChart = ({ width = ORIGINAL_WIDTH }: IconComponentProps) => {
  return (
    <svg height={width} width={ORIGINAL_WIDTH} viewBox="0 0 14 14" fill="#848484">
      <path d="M7.5 0v6.5l6.5-.588A7.376 7.376 0 0 0 7.5 0zM8.5 7.51l3.902 3.99A7.286 7.286 0 0 0 14 7l-5.5.51z" />
      <path d="M6.549 7.472a.695.695 0 0 1-.133-.196c-.01-.021-.017-.042-.024-.064a.681.681 0 0 1-.04-.194c0-.014-.007-.026-.007-.04V0C2.476.383-.35 3.819.035 7.675c.384 3.856 3.83 6.673 7.7 6.29a7.052 7.052 0 0 0 3.765-1.554L6.552 7.478l-.003-.006z" />
    </svg>
  );
};
