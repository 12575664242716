import { useTranslation } from 'react-i18next';

import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { Input } from '../../various/Fields/Input';
import { IconType } from '../../various/Icon';
import { Notification } from '../../various/Notification/Notification';
import { Sortable } from '../../various/Sortable';

import styles from './ProductsList.module.scss';

interface Props {
  listText: JSX.Element | string;
  disableSort?: boolean;
  searchPhrase: string;
  items: (string | number)[];
  renderFunction: (property: string | number) => JSX.Element;
  onSearchChange: (value: string) => void;
  handleSortEnd: (sortedArray: (string | number)[]) => void;
}

export const ProductsList = ({
  listText,
  searchPhrase,
  onSearchChange,
  disableSort = false,
  items,
  renderFunction,
  handleSortEnd,
}: Props) => {
  const { t } = useTranslation(['cms', 'common']);
  const noSearchResults = isEmpty(items) && !isEmpty(searchPhrase);

  const isSortDisabled = disableSort || !isDefined(searchPhrase) || !isEmpty(searchPhrase);

  return (
    <div className={styles.listWrapper}>
      <p className={styles.listText}>{listText}</p>
      <label aria-label={t('cms:search_for_products')} className={styles.search}>
        <Input
          icon={IconType.Search}
          placeholder={t('cms:search_for_products')}
          isClearable
          value={searchPhrase}
          onChange={event => onSearchChange(event.target.value)}
        />
      </label>
      {noSearchResults ?
        <Notification>{t('common:no_results')}</Notification>
      : <Sortable isSortDisabled={isSortDisabled} renderItem={renderFunction} handleSortEnd={handleSortEnd} items={items} />}
    </div>
  );
};
