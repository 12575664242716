import React from 'react';

import styles from './OverviewField.module.scss';

interface Props {
  domId: string;
  title: string;
}

export const OverviewField = ({ title, domId, children }: React.PropsWithChildren<Props>) => {
  return (
    <div className={styles.container}>
      <label className={styles.label} htmlFor={domId}>
        {title}
      </label>
      <div className={styles.field}>{children}</div>
    </div>
  );
};
