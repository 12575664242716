import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Addresses, Id } from '@typings';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

import { addToast } from '../../../components/various/Toasts';
import { getResponseErrors } from '../../../utils/errors';
import { createShippingAddress } from '../../accountRepository';

interface MutationVariables {
  accountId: Id;
  data: Addresses.ShippingAddressDTO;
}

export const useCreateShippingAddress = () => {
  const { t } = useTranslation(['shipping']);
  const queryClient = useQueryClient();

  const mutationFn = async ({ accountId, data }: MutationVariables) => {
    return createShippingAddress(accountId, data);
  };

  const handleSuccess = (_: Addresses.Shipping, { accountId }: MutationVariables) => {
    addToast(t('shipping:shipping_address_added'));
    queryClient.invalidateQueries({ queryKey: ['shippingAddresses', { accountId }] });
  };

  const handleError = (error: AxiosError<Responses.Errors>) => {
    const [errorMessage] = getResponseErrors(error, t('shipping:shipping_address_addition_fail'));
    addToast(errorMessage);
  };

  return useMutation({
    mutationFn,
    onError: handleError,
    onSuccess: handleSuccess,
  });
};
