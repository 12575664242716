import React from 'react';
import Lightbox from 'react-18-image-lightbox';
import { useDispatch, useSelector } from 'react-redux';

import { pushEvent } from '../../../../ducks';
import { getDefaultImageAspectRatio } from '../../../../ducks/helpers';
import { ProductDetailsModalTrackingEvent } from '../../../../utils/analytics/events';
import { isDefined } from '../../../../utils/is';
import { isEmpty } from '../../../../utils/isEmpty';
import { ProductDetailsLayoutContext } from '../context/ProductDetailsLayoutContext';
import { useProductDetailsVariantContext } from '../context/ProductDetailsVariantContext';
import { Gallery } from '../ProductDetailsLayout/Gallery';

import styles from './ProductLightbox.module.scss';
import { ProductMedia } from './ProductMedia';
import { ProductMediaThumbnails } from './ProductMediaThumbnails';

import 'react-18-image-lightbox/style.css';

const PLACEHOLDER_SRC = '/no-image-placeholder.png';

export const ProductLightbox = () => {
  const aspectRatio = useSelector(getDefaultImageAspectRatio);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = React.useState(false);

  const { activeVariant, activeMediaIndex, images, fullImages, medias, handleProductLightboxMediaChange } =
    useProductDetailsVariantContext();
  const { hasMobileDetailsLayout } = React.useContext(ProductDetailsLayoutContext);

  const openLightBox = () => {
    dispatch(pushEvent({ event: ProductDetailsModalTrackingEvent.OPEN_IMAGE_CLICKED }));
    setIsOpen(true);
  };

  const closeLightBox = () => setIsOpen(false);

  const handlePrevious = () => {
    const previousMediaIndex = (activeMediaIndex + (images.length - 1)) % images.length;
    handleProductLightboxMediaChange(previousMediaIndex);
  };

  const handleNext = () => {
    const nextMediaIndex = (activeMediaIndex + 1) % images.length;
    handleProductLightboxMediaChange(nextMediaIndex);
  };

  const handleMediaIndexChange = (mediaIndex: number) => {
    handleProductLightboxMediaChange(mediaIndex);
  };

  const getImageSrcByIndex = React.useCallback(
    (index: number) => {
      const image = fullImages[(index + images.length) % images.length];
      const hasImage = isDefined(image) && !isEmpty(image.src);

      return image?.isPlaceholder || !hasImage ? `${PLACEHOLDER_SRC}?key=${(index + 1) % images.length}` : image.src;
    },
    [fullImages, images],
  );

  return (
    <>
      <div className={styles.lightbox}>
        {hasMobileDetailsLayout ?
          <Gallery activeIndex={activeMediaIndex} aspectRatio={`1 / ${aspectRatio}`} onChange={handleMediaIndexChange}>
            {medias.map((media, index) => (
              <ProductMedia
                activeVariant={activeVariant}
                key={`${media.variantId}-${index}`}
                media={media}
                autoPlayVideo={index === activeMediaIndex}
                onClick={openLightBox}
                isGallery
              />
            ))}
          </Gallery>
        : <>
            <ProductMedia activeVariant={activeVariant} media={medias[activeMediaIndex] ?? medias[0]} onClick={openLightBox} />
            <ProductMediaThumbnails />
          </>
        }
      </div>
      {isOpen && (
        <Lightbox
          mainSrc={getImageSrcByIndex(activeMediaIndex)}
          nextSrc={getImageSrcByIndex(activeMediaIndex + 1)}
          prevSrc={getImageSrcByIndex(activeMediaIndex - 1)}
          reactModalProps={{ ariaHideApp: false }}
          imageCaption={fullImages[activeMediaIndex]?.title}
          onCloseRequest={closeLightBox}
          onMovePrevRequest={handlePrevious}
          onMoveNextRequest={handleNext}
          animationOnKeyInput
        />
      )}
    </>
  );
};
