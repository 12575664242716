import * as yup from 'yup';

import { VALID_EMAIL } from '../../constants/regExp';

yup.addMethod(yup.string, 'email', function (message: string) {
  return this.matches(VALID_EMAIL, {
    excludeEmptyString: true,
    message,
  });
});
