import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getStagesByUserType } from '../../ducks/selections';

export const useStageName = (stageId: string): string => {
  const { t } = useTranslation(['orders']);
  const stages = useSelector(getStagesByUserType);

  const stage = stages.find(item => item.value === stageId);

  return stage !== undefined ? t(stage.i18nKey) : '';
};
