import cx from 'classnames';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { DatePickerSelectionContext, DatePickerSettingsContext } from '../context';

import { DatePickerClearButton } from './DatePickerClearButton';
import styles from './DatePickerControls.module.scss';

interface Props {
  shouldShowClear: boolean;
  shouldShowToday: boolean;
}

export const DatePickerFooter = ({ shouldShowClear, shouldShowToday }: Props) => {
  const { t } = useTranslation(['dates']);
  const { isDateView, shouldDisable } = React.useContext(DatePickerSettingsContext);
  const { startDate, handleSelect } = React.useContext(DatePickerSelectionContext);

  const selectToday = React.useCallback(() => {
    handleSelect(dayjs());
  }, [handleSelect]);

  const isTodayButtonDisabled = React.useMemo(() => {
    const today = dayjs();

    const isTodayDisabled = shouldDisable(today);
    const isTodaySelected = startDate?.isSame(today, 'date');

    return isTodayDisabled || isTodaySelected;
  }, [shouldDisable, startDate]);

  if (!isDateView || (!shouldShowClear && !shouldShowToday)) {
    return null;
  }

  return (
    <div className={styles.footer}>
      {shouldShowToday && (
        <button type="button" className={cx(styles.button, styles.colorBlue)} disabled={isTodayButtonDisabled} onClick={selectToday}>
          {t('dates:today')}
        </button>
      )}
      {shouldShowClear && <DatePickerClearButton />}
    </div>
  );
};
