import { isEmpty } from '../../../utils/isEmpty';

export const getHasUnsavedChanges = (store: Store) => {
  return !isEmpty(store.cms.status.modifiedLanguages);
};

export const getHasUnsavedLayoutChanges = (store: Store) => store.cms.status.hasUnsavedLayoutChanges;
export const getModifiedLanguages = (store: Store) => store.cms.status.modifiedLanguages;

export const getIsLoadingPageData = (store: Store) => store.cms.status.isLoadingPageData;
