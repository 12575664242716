import { Product } from '@typings';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { useProductPriceComponents } from '../../../utils/hooks';
import { isDefined } from '../../../utils/is';

import styles from './Product.module.scss';

type Props = {
  productInfo: Product.Standard;
};

const Price = ({ productInfo }: Props) => {
  const { t } = useTranslation(['products']);

  const { formattedPrice, hasConsistentDiscountPercent, highestDiscountPercent, isOnSale } = useProductPriceComponents(productInfo);

  if (!isDefined(formattedPrice)) {
    return null;
  }

  const { priceBeforeDiscount } = productInfo;

  const isDiscounted = highestDiscountPercent > 0;
  const classes = cx({
    [styles.price]: true,
    [styles.priceHasDiscount]: isOnSale,
  });

  if (isDiscounted) {
    return (
      <>
        <div className={classes}>
          {formattedPrice}
          <span className={styles.textMuted}>
            {' '}
            / {t(hasConsistentDiscountPercent ? 'products:percent_off' : 'products:up_to_percent_off', { percent: highestDiscountPercent })}
          </span>
        </div>
        {hasConsistentDiscountPercent && <div className={styles.priceOld}>{priceBeforeDiscount}</div>}
      </>
    );
  }

  return <div className={classes}>{formattedPrice}</div>;
};

export default Price;
