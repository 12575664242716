import { useInfiniteQuery, useQuery, useQueryClient } from '@tanstack/react-query';
import { omit } from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';

import { DEFAULT_ITEMS_COUNT_PER_REQUEST } from '../../../constants/limits';
import { getIsSeller } from '../../../ducks';
import { getBuyer, getBuyers } from '../../../services/accountsRepository';
import { isDefined } from '../../is';
import { useDebouncedCallback } from '../useDebouncedCallback';

const DEBOUNCE_TIME = 500;
const BUYER_SELECT_QUERY_KEY = 'buyer-select';

export const useBuyerSelect = (initialBuyer?: string) => {
  const isUserSeller = useSelector(getIsSeller);
  const queryClient = useQueryClient();
  const [search, setSearch] = React.useState('');

  const { data, isFetching: isFetchingInitialBuyer } = useQuery({
    enabled: isUserSeller,
    queryFn: isDefined(initialBuyer) ? async () => getBuyer(initialBuyer) : undefined,
    queryKey: ['buyer', initialBuyer],
  });

  const fetchBuyers = async ({ pageParam }: { pageParam: number }) => {
    return getBuyers(DEFAULT_ITEMS_COUNT_PER_REQUEST, (pageParam - 1) * DEFAULT_ITEMS_COUNT_PER_REQUEST, search);
  };

  const {
    data: buyersPages,
    fetchNextPage,
    hasNextPage,
    isFetching: isFetchingBuyers,
  } = useInfiniteQuery({
    enabled: isUserSeller && (!isDefined(initialBuyer) || !isFetchingInitialBuyer),
    getNextPageParam: (lastPage, pages) =>
      !isDefined(lastPage) || lastPage.offset + lastPage.limit >= lastPage.total ? undefined : pages.length + 1,
    initialPageParam: 1,
    queryFn: fetchBuyers,
    queryKey: [BUYER_SELECT_QUERY_KEY, { search }],
  });

  const buyers = React.useMemo(() => {
    if (!isDefined(buyersPages?.pages)) {
      return [];
    }

    return buyersPages.pages.flatMap(page => page.data);
  }, [buyersPages]);

  const handleSearchChange = useDebouncedCallback((value: string) => {
    queryClient.removeQueries({ queryKey: [BUYER_SELECT_QUERY_KEY] });
    queryClient.setQueryData([BUYER_SELECT_QUERY_KEY, { search: value }], { pageParams: [], pages: [] });
    setSearch(value);
  }, DEBOUNCE_TIME);

  const handleLoadMore = React.useCallback(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage]);

  const defaultOption = React.useMemo(() => {
    if (!isDefined(data)) {
      return null;
    }

    return {
      id: data.buyer,
      ...omit(['buyer'], data),
    };
  }, [data]);

  const options = React.useMemo(() => {
    if (!isDefined(defaultOption) || isDefined(buyersPages) || isDefined(buyersPages)) {
      return buyers;
    }

    return [...buyers, defaultOption];
  }, [buyers, buyersPages, defaultOption]);

  React.useEffect(() => {
    return () => {
      queryClient.removeQueries({ queryKey: [BUYER_SELECT_QUERY_KEY] });
    };
  }, [queryClient]);

  return {
    defaultOption,
    handleLoadMore,
    handleSearchChange,
    isLoading: isFetchingInitialBuyer || isFetchingBuyers,
    options,
  };
};
