import * as Sentry from '@sentry/react';
export * from './sentry';

// eslint-disable-next-line no-warning-comments
// Logs error in console and send a report to Sentry.
export const report = (message: string): void => {
  if (Sentry.getClient()) {
    // eslint-disable-next-line no-console
    console.error(message);
    Sentry.captureException(message);
  }
};
