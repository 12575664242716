import { isDefined } from '../utils/is';

export const getSortByTuple = (sortBy: Maybe<string>, thenSortBy: Maybe<string>) => {
  if (!isDefined(sortBy)) {
    return [];
  }

  if (!isDefined(thenSortBy) || thenSortBy === 'none') {
    return [sortBy] as [string];
  }

  return [sortBy, thenSortBy] as [string, string];
};
