import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getOrderCurrency } from '../../../../../ducks';
import { formatPriceWithCurrencyAffix } from '../../../../../logic/price';

import styles from './TotalsSummary.module.scss';

interface Props {
  totalPrice: number;
  totalQuantity: number;
  isHighlighted?: boolean;
}

export const TotalsSummary = ({ totalPrice, totalQuantity, isHighlighted = false }: Props) => {
  const { t } = useTranslation(['common']);
  const currency = useSelector(getOrderCurrency);

  return (
    <div className={cx(styles.summary, { [styles.highlighted]: isHighlighted })}>
      <span>{formatPriceWithCurrencyAffix(currency)(totalPrice)}</span>
      <span className={styles.totalQty}>
        {totalQuantity} {t('common:item', { count: totalQuantity })}
      </span>
    </div>
  );
};
