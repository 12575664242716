import { UniqueIdentifier } from '@dnd-kit/core';
import { intersection, isEmpty } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useMenuSectionContext } from '../../../components/settings/MenuSection/context/MenuSectionContext';
import { NestValidationResult } from '../../../components/various/Sortable/SortableTree';
import { getIsFolderMenuItem, getIsLinkMenuItem, getIsPageAvailable, getIsPageMenuItem, getPublishedPageData } from '../../../logic/pages';
import { isDefined } from '../../is';

export const useMenuValidation = () => {
  const { t } = useTranslation(['settings']);
  const { itemRecords, pagesRecords, getItemMarketsIds } = useMenuSectionContext();
  const canNest = React.useCallback(
    (activeItemId: UniqueIdentifier, parentId: UniqueIdentifier | null): NestValidationResult => {
      if (!parentId) {
        return {
          isValid: true,
        };
      }
      const activeItem = itemRecords[activeItemId]!;
      const parentItem = itemRecords[parentId]!;
      const isActiveItemFolder = getIsFolderMenuItem(activeItem);
      const isItemHasChildren = isDefined(activeItem.children) && !isEmpty(activeItem.children);

      if (isActiveItemFolder || isItemHasChildren) {
        return {
          isValid: false,
          message: t('settings:cant_nest_folder'),
        };
      }

      const isParentLink = getIsLinkMenuItem(parentItem);
      if (isParentLink) {
        return {
          isValid: false,
          message: t('settings:cant_nest_link'),
        };
      }
      const parentPage = getPublishedPageData(pagesRecords[parentItem.pageId]);
      const isParentUnavailablePage = !getIsPageAvailable(parentPage);
      const isParentToPage = getIsPageMenuItem(parentItem);

      if (isParentToPage && isParentUnavailablePage) {
        return {
          isValid: false,
          message: t('settings:cant_nest_in_missing_page'),
        };
      }

      const activePage = getPublishedPageData(pagesRecords[activeItem.pageId]);
      const isActiveLink = getIsLinkMenuItem(activeItem);
      const isActiveUnavailablePage = !getIsPageAvailable(activePage);

      if (!isActiveLink && isActiveUnavailablePage) {
        return {
          isValid: false,
          message: t('settings:cant_nest_missing_page'),
        };
      }

      const parentMarkets = getItemMarketsIds(parentItem);
      const activeMarkets = getItemMarketsIds(activeItem);
      const isParentToFolder = getIsFolderMenuItem(parentItem);

      if (!isParentToFolder && intersection(parentMarkets, activeMarkets).length <= 0) {
        return {
          isValid: false,
          message: t('settings:no_common_markets'),
        };
      }

      return { isValid: true };
    },
    [itemRecords, pagesRecords, getItemMarketsIds, t],
  );

  return { canNest };
};
