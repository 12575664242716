import cx from 'classnames';
import React from 'react';

import Icon, { IconColor, IconType } from '../Icon';
import { Popover } from '../Popover';

import styles from './Help.module.scss';

const ICON_OFFSET = 15;

export const Help = ({ children, className, ...tooltipProps }: Omit<React.ComponentProps<typeof Popover>, 'content'>) => (
  <span className={styles.wrapper}>
    <Popover {...tooltipProps} content={children} className={cx(styles.popover, className)} triggerOffset={ICON_OFFSET}>
      <Icon color={IconColor.Gray} type={IconType.HelpOutline} size={16} />
    </Popover>
  </span>
);
