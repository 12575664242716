import { identity, sortBy } from 'ramda';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getMarkets } from '../../../ducks';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import Icon, { IconType } from '../../various/Icon';
import { TextEllipsis } from '../../various/TextEllipsis';

import styles from './MenuItemMarkets.module.scss';

interface Props {
  markets: string[];
  isMissingMarket?: boolean;
  emptyMarketsInfo?: string;
}

export const MenuItemMarkets = ({ markets, isMissingMarket, emptyMarketsInfo }: Props) => {
  const { t } = useTranslation(['common', 'settings']);
  const allMarkets = useSelector(getMarkets);

  return (
    isDefined(markets) && (
      <TextEllipsis as="div" className={styles.marketInfo}>
        <span className={styles.marketLabel}>{t('common:market')}: </span>
        {isMissingMarket && <Icon type={IconType.Warning} className={styles.missingMarketIcon} />}
        <span>{markets.length === allMarkets.length ? t('settings:all_markets') : sortBy(identity, markets).join(', ')}</span>
        {isEmpty(markets) && <span className={styles.emptyMarkets}>{emptyMarketsInfo}</span>}
      </TextEllipsis>
    )
  );
};
