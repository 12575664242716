import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { addToast } from '../../../components/various/Toasts';
import { deleteLookbook } from '../../lookbookRepository';

import { LOOKBOOKS_LIST_QUERY_KEY } from './useLookbooksList';

export const useDeleteLookbook = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(['lookbook']);

  return useMutation({
    mutationFn: async (lookbookId: string) => deleteLookbook(lookbookId),
    onError: () => addToast(t('lookbook:lookbook_deletion_fail')),
    onSuccess: async () => queryClient.resetQueries({ queryKey: [LOOKBOOKS_LIST_QUERY_KEY] }),
  });
};
