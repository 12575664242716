/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const WIDTH = 20;
const ORIGINAL_COLOR = '#B284B9';

export const Move = ({ width = WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} fill="none">
    <rect width={width} height={width} fill={color} rx={width / 2} />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M10 4a.58.58 0 0 1 .464.232l1.162 1.549a.58.58 0 0 1-.465.929h-.58v1.742a.58.58 0 1 1-1.162 0V6.71h-.58a.58.58 0 0 1-.465-.93l1.161-1.548A.58.58 0 0 1 10 4ZM6.389 8.32a.58.58 0 0 1 .32.519v.58h1.743a.58.58 0 1 1 0 1.162H6.71v.58a.58.58 0 0 1-.93.465l-1.548-1.162a.58.58 0 0 1 0-.929l1.549-1.16a.58.58 0 0 1 .608-.056Zm7.222 0a.58.58 0 0 1 .608.054l1.549 1.161a.58.58 0 0 1 0 .93l-1.549 1.16a.58.58 0 0 1-.929-.464v-.58h-1.742a.58.58 0 1 1 0-1.162h1.742v-.58a.58.58 0 0 1 .321-.52ZM10 10.967c.32 0 .58.26.58.58v1.742h.581a.58.58 0 0 1 .465.93l-1.162 1.548a.58.58 0 0 1-.929 0l-1.16-1.549a.58.58 0 0 1 .464-.929h.58v-1.742c0-.32.26-.58.581-.58Z"
      clipRule="evenodd"
    />
  </svg>
);
