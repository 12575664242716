import { Cms, Id, Product } from '@typings';
import cx from 'classnames';

import { getHotspotPosition, getIsOutOfBoundaries } from '../../../../../logic/cms/hotspot';
import { getUrlForProductAndVariant } from '../../../../../logic/products';
import { useIsInViewMode } from '../../../../../utils/hooks';
import { isDefined } from '../../../../../utils/is';
import { MaybeLink } from '../../../../various/MaybeLink';
import { Tooltip } from '../../../../various/Tooltip';

import styles from './Hotspots.module.scss';
import { HotspotTooltipContent } from './HotspotTooltipContent';

interface Props {
  hotspot: Cms.HotspotPoint;
  blockSize: Cms.MediumSize;
  background: Cms.ContentBlockBackground;
  naturalImage: Cms.MediumSize;
  screenWidth: number;
  products: Record<Id, Product.Standard | Product.CmsProduct>;
  blockId: string;
  hasInactiveProduct: boolean;
}

export const StandardHotspotItem = ({ hotspot, blockSize, background, naturalImage, screenWidth, products, hasInactiveProduct }: Props) => {
  const { left, top } = getHotspotPosition({
    background,
    blockSize,
    hotspotPoint: hotspot,
    naturalImage,
    screenWidth,
  });

  const { productId } = hotspot.general;
  const isOutOfBoundaries = getIsOutOfBoundaries({ blockSize, left, top });
  const productInfo = isDefined(productId) ? (products[productId] as Product.Standard) : undefined;

  const isInPreview = useIsInViewMode('preview');
  const isInEditor = useIsInViewMode('editor');

  if (isOutOfBoundaries || (!isInEditor && !isDefined(productInfo))) {
    return null;
  }

  const link =
    isInPreview || isInEditor || !isDefined(productInfo) ? undefined : getUrlForProductAndVariant({ productId: productInfo.product });

  const hotspotClassNames = cx(
    {
      [styles.inactiveProduct]: hasInactiveProduct,
      [styles.inEditorView]: isInEditor,
    },
    styles.hotspot,
  );

  return (
    <div className={styles.hotspotWrapper} style={{ left, top }}>
      <MaybeLink to={link} variant="blue">
        <Tooltip
          content={<HotspotTooltipContent productInfo={productInfo} productId={productId} />}
          placement="bottom-start"
          overlayClassName={styles.overlay}
          disableOnTouchScreen={isDefined(link)}
        >
          <span className={hotspotClassNames} />
        </Tooltip>
      </MaybeLink>
    </div>
  );
};
