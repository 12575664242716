import { editorComponentSwitch } from '../../cms/editorComponentSwitch';

import { createSection } from './createSection';
import { SectionTools } from './SectionTools';
import { SectionWarnings } from './SectionWarnings';

export const Section = editorComponentSwitch({
  editorComponent: createSection({
    tools: SectionTools,
    warnings: SectionWarnings,
  }),
  standardComponent: createSection(),
});
