import { Addresses, Country } from '@typings';
import React from 'react';
import { useSelector } from 'react-redux';

import { getCountryByCountryCode } from '../../../ducks';
import { getOrderDetails } from '../../../ducks/order';
import { isTemporaryShipping } from '../../../utils/guards';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { AddShippingCountryModal } from '../../products/AddShippingCountryModal';
import { Alert } from '../Alert';

import { DefaultShippingAddressMissingAlert } from './DefaultShippingAddressMissingAlert';

const getIsMissingCountryOrState = (defaultShippingAddress: Addresses.MixedShipping | undefined, shippingCountry: Country) => {
  if (!isDefined(defaultShippingAddress)) {
    return true;
  }

  const isStateMissing =
    isDefined(shippingCountry.states) &&
    (!isDefined(defaultShippingAddress.state) || !isDefined(shippingCountry.states[defaultShippingAddress.state]?.state));

  const isCountryMissing = !isDefined(defaultShippingAddress.country) || isEmpty(defaultShippingAddress.country);

  return isCountryMissing || isStateMissing;
};

export const MissingCountryStateAlert = () => {
  const orderDetails = useSelector(getOrderDetails);
  const defaultShippingAddress = orderDetails?.account?.shippingAddresses?.[0];
  const countryCodeSelector = React.useMemo(
    () => getCountryByCountryCode(defaultShippingAddress?.country ?? ''),
    [defaultShippingAddress?.country],
  );
  const shippingCountry = useSelector(countryCodeSelector);

  const isMissingCountryOrState = getIsMissingCountryOrState(defaultShippingAddress, shippingCountry);
  const isTemporary = isDefined(defaultShippingAddress) && isTemporaryShipping(defaultShippingAddress);

  if (!isMissingCountryOrState || !isDefined(orderDetails.account) || isTemporary) {
    return null;
  }

  return (
    <>
      <Alert message={<DefaultShippingAddressMissingAlert />} type="warning" noRoundCorners />
      <AddShippingCountryModal accountId={orderDetails.account.account} selectedAddress={defaultShippingAddress} />
    </>
  );
};
