import { Cms, Id } from '@centra';
import { omit } from 'ramda';

import { listToRecord } from '../../utils/normalize';

import { convertPageToLatestVersion } from './convertPageToLatestVersion/convertPageToLatestVersion';

const getBlocksFromSections = (sections: Cms.SectionsDTO) =>
  listToRecord(
    sections.flatMap(section => section.blocks),
    'id',
  );

const getGroupsFromSections = (sections: Cms.SectionsDTO) => {
  const groups = sections.map(section => section.groups);

  return Object.assign({}, ...groups) as Record<Id, Cms.Group>;
};

const getNormalizedSections = (sections: Cms.SectionsDTO) => sections.map(omit(['groups', 'blocks']));

export const getNormalizedPage = (response: Cms.SectionsResponseAnyVersion) => {
  const { localizations, sections } = convertPageToLatestVersion(response);

  const blocks = getBlocksFromSections(sections);
  const groups = getGroupsFromSections(sections);
  const normalizedSections = getNormalizedSections(sections);

  return {
    blocks,
    groups,
    localizations,
    sections: normalizedSections,
  };
};
