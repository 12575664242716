import { Cms } from '@typings';
import { nanoid } from 'nanoid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { setIsHotspotEditing, updateBlockSettings } from '../../../ducks';
import { mousePositionToRatio, removeEmptyHotspots } from '../../../logic/cms/hotspot';
import { getScreenRelativeStyles } from '../../../logic/pages';
import { useResizeObserver, useSpecifiedDeviceWidth } from '../../../utils/hooks';
import { isDefined, isNull } from '../../../utils/is';
import { Hotspots } from '../../pages/contentBlock/parts/Hotspots';
import { FocusTrap } from '../../various/FocusTrap';
import { HotspotsContext, HotspotsContextProvider } from '../context/HotspotsContext';
import { ImageSizeContext } from '../context/ImageSizeContext';
import { PreparingMessage } from '../MediaPositioner/PreparingMessage';
import { ScrollOverlay } from '../ScrollOverlay';
import { ToolbarBase } from '../Toolbar/ToolbarBase';
import { AcceptDiscard } from '../Toolbar/Tools/AcceptDiscard';

import styles from './HotspotEditor.module.scss';
import { PreviewProductsSwitch } from './PreviewProductsSwitch';

interface Props {
  initialHotspots?: Cms.Hotspots;
  blockId: string;
  background: Cms.ContentBlockBackground;
  groupId: string | undefined;
}

const HotspotEditorComponent = ({ initialHotspots, blockId, background, groupId }: Props) => {
  const { t } = useTranslation(['cms']);
  const dispatch = useDispatch();
  const screenWidth = useSpecifiedDeviceWidth();
  const { imageSize } = React.useContext(ImageSizeContext);

  const { hotspots, addHotspot, activeHotspotId, setActiveHotspot, isMovingHotspot, hotspotEditorRef } = React.useContext(HotspotsContext);
  const [naturalImage, setNaturalImage] = React.useState(initialHotspots?.general.naturalImage ?? { height: 0, width: 0 });
  const [blockSize, setBlockSize] = React.useState<Cms.MediumSize>();

  const hotspotsData = React.useMemo(
    () => ({
      general: {
        naturalImage,
      },
      points: Object.values(hotspots),
    }),
    [hotspots, naturalImage],
  );

  useResizeObserver(hotspotEditorRef, () => {
    !isNull(hotspotEditorRef.current) &&
      setBlockSize({
        height: hotspotEditorRef.current.offsetHeight,
        width: hotspotEditorRef.current.offsetWidth,
      });
  });

  const discardChanges = React.useCallback(() => {
    dispatch(setIsHotspotEditing(false));
  }, []);

  const acceptChanges = React.useCallback(() => {
    const validHotspots = Object.values(removeEmptyHotspots(hotspots));

    const saveData =
      validHotspots.length > 0 ?
        {
          general: {
            naturalImage,
          },
          points: validHotspots,
        }
      : undefined;

    dispatch(
      updateBlockSettings({
        blockId,
        updatePath: ['hotspots'],
        value: saveData,
      }),
    );

    dispatch(setIsHotspotEditing(false));
  }, [hotspots, naturalImage, dispatch, blockId]);

  const createNewHotspotObject = React.useCallback(
    (id: string, event: React.MouseEvent) => {
      if (!isDefined(hotspotEditorRef.current)) {
        return null;
      }

      const screenRelative = getScreenRelativeStyles(screenWidth, background);
      const { offsetWidth, offsetHeight } = hotspotEditorRef.current;
      const ratio = mousePositionToRatio(hotspotEditorRef, { x: event.clientX, y: event.clientY });

      return {
        [id]: {
          desktop: ratio,
          general: {
            id,
            originalBlockSize: {
              height: offsetHeight,
              width: offsetWidth,
            },
            originalScreenRelative: screenRelative,
            productId: null,
          },
        },
      };
    },
    [hotspotEditorRef, background, screenWidth],
  );

  const updateNaturalImage = React.useCallback(() => {
    if (!isDefined(imageSize)) {
      return;
    }

    setNaturalImage({
      height: imageSize.height,
      width: imageSize.width,
    });
  }, [imageSize]);

  const handleClick = React.useCallback(
    (event: React.MouseEvent) => {
      const isClickingOnHotspot = event.target !== hotspotEditorRef.current;

      if (isDefined(activeHotspotId) && !isClickingOnHotspot) {
        setActiveHotspot(null);

        return;
      }

      if (isClickingOnHotspot || isMovingHotspot) {
        return;
      }

      const id = nanoid();
      const newPoint = createNewHotspotObject(id, event);

      if (!isDefined(newPoint)) {
        return;
      }

      updateNaturalImage();
      addHotspot(newPoint);
      setActiveHotspot(id, false);
    },
    [setNaturalImage, hotspotEditorRef, setActiveHotspot, updateNaturalImage, createNewHotspotObject, activeHotspotId],
  );
  const isLoadingMedium = !isDefined(imageSize) || (imageSize.height === 0 && imageSize.width === 0);

  return (
    <>
      <ScrollOverlay className={styles.scrollOverlay} />
      <div role="presentation" className={styles.hotspotEditor} ref={hotspotEditorRef} onClick={handleClick} data-testid="hotspotEditor">
        <FocusTrap>
          {isLoadingMedium && <PreparingMessage onClose={discardChanges} />}
          <ToolbarBase
            title={t('cms:hotspot_toolbar_title')}
            tools={[
              <PreviewProductsSwitch key="previewproductsswitch" />,
              <AcceptDiscard
                key="acceptdiscard"
                discardAction={discardChanges}
                acceptAction={acceptChanges}
                testId="closeHotspotToolbar"
              />,
            ]}
          />
          {!isLoadingMedium && isDefined(hotspots) && isDefined(blockSize) && (
            <Hotspots
              blockId={blockId}
              groupId={groupId}
              background={background}
              hotspots={hotspotsData}
              blockSize={blockSize}
              isInHotspotTool
            />
          )}
        </FocusTrap>
      </div>
    </>
  );
};

export const HotspotEditor = (props: Props) => {
  return (
    <HotspotsContextProvider initialHotspots={props.initialHotspots}>
      <HotspotEditorComponent {...props} />
    </HotspotsContextProvider>
  );
};
