import { Currency, Order, Product } from '@typings';
import { pipe } from 'ramda';
import { Option } from 'space-lift';

import { getHasRelatedProducts } from '../utils/guards';
import { isDefined } from '../utils/is';
import { isEmpty } from '../utils/isEmpty';

import { isVariantRelation } from './merchandise';
import { getHasDeliveryWindowSpecificPrices } from './products';

const getSystemDecimalPoint = () => {
  const testNumber = 1.1;

  return testNumber.toLocaleString().substring(1, 2);
};

export const formatDecimals = (currency: Currency) => (price: number) => price.toFixed(currency.decimalDigits).toString();

export const formatPriceAffix = (currency: Currency) => (price: string) =>
  !isEmpty(currency.prefix) || !isEmpty(currency.suffix) ? `${currency.prefix}${price}${currency.suffix}` : `${price} ${currency.currency}`;

export const formatDecimalPoint = (currency: Currency) => (price: string) => price.replace(getSystemDecimalPoint(), currency.decimalPoint);

export const formatPriceWithCurrencyAffix = (currency: Currency) =>
  pipe(formatDecimals(currency), formatDecimalPoint(currency), formatPriceAffix(currency));

export const formatPrice = (currency: Currency) => pipe(formatDecimals(currency), formatDecimalPoint(currency));

export const getTotalPrice = (product: Product.Standard, currency: Currency) => (count: number) =>
  Option(product.priceAsNumber)
    .map(priceEach => priceEach * count)
    .map(formatPrice(currency))
    .getOrElse(formatPrice(currency)(0));

interface Prices {
  price: string;
  priceAsNumber: number;
  priceBeforeDiscount: string;
  priceBeforeDiscountAsNumber: number;
}

const getPrices =
  (orderProducts: Order.Product[]) =>
  (productInfo: Product.Standard): Prices => {
    const prod = orderProducts.find(product => product.variant === productInfo.variant && productInfo.product === product.product);

    return {
      price: prod ? prod.priceEach : '0',
      priceAsNumber: prod ? prod.priceEachAsNumber : 0,
      priceBeforeDiscount: prod ? prod.priceEachBeforeDiscount : '0',
      priceBeforeDiscountAsNumber: prod ? prod.priceEachBeforeDiscountAsNumber : 0,
    };
  };

export const applyPrices =
  (orderProducts: Order.Product[]) =>
  <T extends Product.Standard>(productInfo: T): T => {
    if (getHasRelatedProducts(productInfo)) {
      const variants = productInfo.relatedProducts.variants.map(variant =>
        isVariantRelation(variant) ? applyPrices(orderProducts)(variant) : variant,
      );

      return {
        ...productInfo,
        ...getPrices(orderProducts)(productInfo),
        relatedProducts: { variants },
      };
    }

    return { ...productInfo, ...getPrices(orderProducts)(productInfo) };
  };

export const getDeliveryWindowSpecificPriceComponents = (product: Product.Standard, deliveryWindowsIds: string[]) => {
  const deliveryWindowSpecificPriceComponents =
    getHasDeliveryWindowSpecificPrices(product) ?
      Object.values(product.deliveryWindowSpecificPrices)
        .filter(delwin => deliveryWindowsIds.includes(delwin.deliveryWindow.toString()))
        .map(({ priceAsNumber, discountPercent, showAsOnSale }) => ({
          discountPercent,
          isOnSale: getIsOnSale(showAsOnSale, discountPercent),
          priceAsNumber,
        }))
    : [];

  return {
    discountPercents: deliveryWindowSpecificPriceComponents.map(({ discountPercent }) => discountPercent),
    isAnyOnSale: deliveryWindowSpecificPriceComponents.some(({ isOnSale }) => isOnSale),
    prices: deliveryWindowSpecificPriceComponents.map(({ priceAsNumber }) => priceAsNumber),
  };
};

export const getHasConsistentPrice = (product: Product.Standard, prices: number[]) => {
  return prices.every(price => price === product.priceAsNumber);
};

export const getIsOnSale = (showAsOnSale: boolean | undefined, discountPercent?: number) =>
  showAsOnSale !== false && isDefined(discountPercent) && discountPercent > 0;

export const getDiscountPercentInfo = (discounts: number[]) => {
  return {
    hasConsistentDiscountPercent: discounts.every(discount => discount === discounts[0]),
    highestDiscountPercent: Math.max(...discounts),
  };
};
