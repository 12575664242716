import axios from 'axios';

import { showroomApi } from './api';

const IMAGE_WIDTH = 1350;

const objectToFormData = (object: Record<string, any>) =>
  Object.keys(object).reduce((acc, cur) => {
    acc.append(cur, object[cur]);

    return acc;
  }, new FormData());

export const uploadMedia = async ({ file }: { file: File }): Promise<{ url: string; original: string; type: string }> => {
  const uploadPolicy = await showroomApi.POST('/cms/media')({
    data: { mime: file.type },
  });

  if (uploadPolicy.status === 'FAILURE') {
    return Promise.reject({ error: 'Unable to upload file' });
  }

  const formData = objectToFormData(uploadPolicy.data.formInputs);
  formData.append('file', file);

  const imageUrl = uploadPolicy.data.dynamicKey.replace(/{W}x{H}/, `${IMAGE_WIDTH}x0`);
  const originalUrl = `${uploadPolicy.data.formAttributes.action}/${uploadPolicy.data.formInputs.key}`;

  return axios({
    data: formData,
    method: uploadPolicy.data.formAttributes.method,
    url: uploadPolicy.data.formAttributes.action,
  })
    .then(async () => {
      return Promise.resolve({
        original: originalUrl,
        type: 'image',
        url: imageUrl,
      });
    })
    .catch(async () => {
      return Promise.reject({ error: 'Unable to upload image' });
    });
};
