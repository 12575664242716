import React from 'react';
import { flushSync } from 'react-dom';

import { useResizeObserver, useThrottledCallback } from '../../../../utils/hooks';
import { useGalleryDots } from '../../../../utils/hooks/useGalleryDots';
import { useIsTouchScreen } from '../../../../utils/hooks/useIsTouchScreen';
import { Key } from '../../../../utils/keys';
import { useProductContext } from '../context/ProductContext';

import { GalleryDot } from './GalleryDot';
import styles from './GalleryDots.module.scss';

const AVAILABLE_POSITIONS = 8;
const DOT_WIDTH = 24;
const DOT_WIDTH_ON_TOUCH = 32;
const DOTS_PADDING = 12;

export const GalleryDots = () => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const activeDotRef = React.useRef<HTMLButtonElement>(null);
  const { activeSlideIndex, gallerySlides, setActiveSlideIndex } = useProductContext();
  const totalCount = gallerySlides.length;
  const [visibleDotsCount, setVisibleDotsCount] = React.useState(totalCount);

  const isTouchScreen = useIsTouchScreen();

  const dotWidth = isTouchScreen ? DOT_WIDTH_ON_TOUCH : DOT_WIDTH;

  React.useEffect(() => {
    const scrollLeft = containerRef.current?.scrollLeft ?? 0;
    const shouldScrollToStart = !isTouchScreen && activeSlideIndex === 0 && scrollLeft > DOT_WIDTH;

    if (shouldScrollToStart) {
      containerRef.current?.scrollTo({ left: 0 });
    }
  }, [activeSlideIndex, isTouchScreen]);

  const { dotsStyles, wrapperRef } = useGalleryDots({
    activeIndex: activeSlideIndex,
    dotSize: dotWidth,
    onChange: setActiveSlideIndex,
    orientation: 'horizontal',
    totalCount,
    visibleCount: visibleDotsCount,
  });

  const calculateFittingDots = useThrottledCallback((entries: [ResizeObserverEntry]) => {
    const [{ target }] = entries;

    const availableSpace = target.clientWidth - DOTS_PADDING;
    const dotsCount = Math.floor(availableSpace / dotWidth);
    const validatedDotsCount = Math.min(dotsCount, AVAILABLE_POSITIONS);

    const shouldReserveSpaceForMoreLabel = validatedDotsCount < totalCount;
    const fittingDotsCount = shouldReserveSpaceForMoreLabel ? validatedDotsCount - 1 : validatedDotsCount;

    setVisibleDotsCount(fittingDotsCount);
  });

  useResizeObserver(wrapperRef, calculateFittingDots);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key !== Key.LEFT && event.key !== Key.RIGHT) {
      return;
    }

    const index = event.key === Key.RIGHT ? Math.min(activeSlideIndex + 1, totalCount - 1) : Math.max(activeSlideIndex - 1, 0);

    flushSync(() => {
      setActiveSlideIndex(index);
    });

    activeDotRef.current?.focus();
  };

  const handleMouseOver = (index: number) => () => {
    setActiveSlideIndex(index);
  };

  const getDotSize = (index: number) => {
    if (!isTouchScreen) {
      return 'regular';
    }

    const offset = Math.floor(visibleDotsCount / 2);
    const firstVisibleIndex = Math.min(hiddenDotsCount, Math.max(0, activeSlideIndex - offset));
    const lastVisibleIndex = firstVisibleIndex + visibleDotsCount - 1;

    const isFirstVisibleDot = index === firstVisibleIndex && index !== 0;
    const isLastVisibleDot = index === lastVisibleIndex && index !== totalCount - 1;

    return isFirstVisibleDot || isLastVisibleDot ? 'small' : 'regular';
  };

  const dotsContainerStyles = React.useMemo(() => {
    if (visibleDotsCount < 1) {
      return;
    }

    return {
      maxWidth: visibleDotsCount * dotWidth,
      overflow: isTouchScreen ? 'hidden' : 'auto',
    };
  }, [dotWidth, visibleDotsCount, isTouchScreen]);

  const shouldRenderDots = totalCount > 1;
  const hiddenDotsCount = totalCount - visibleDotsCount;

  return (
    <div ref={wrapperRef} className={styles.dotsWrapper}>
      {shouldRenderDots && (
        <div ref={containerRef} className={styles.dotsContainer} style={dotsContainerStyles}>
          <div className={styles.dots} style={dotsStyles}>
            {gallerySlides.map((slide, index) => (
              <GalleryDot
                key={slide.id}
                ref={activeDotRef}
                isActive={activeSlideIndex === index}
                isAvailable={slide.isAvailable}
                swatchData={slide.swatchData}
                size={getDotSize(index)}
                style={{ width: dotWidth }}
                onKeyDown={handleKeyDown}
                onMouseOver={handleMouseOver(index)}
                onFocus={handleMouseOver(index)}
              />
            ))}
          </div>
        </div>
      )}
      {hiddenDotsCount > 0 && <span className={styles.more}>+{hiddenDotsCount}</span>}
    </div>
  );
};
