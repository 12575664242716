import { Product } from '@typings';

import ProductsLogic from '../../logic/products';
import { MatrixHelpIcon } from '../products/ProductMatrix/MatrixHelp';
import { ProductMatrixSectionGeneral } from '../products/ProductMatrix/ProductMatrixSectionGeneral';

interface Props {
  delwinIds?: string[];
  product: Product.Full;
  showHelp?: boolean;
}

export const OrderProductExpanded = ({ delwinIds, product, showHelp }: Props) => {
  const variants = ProductsLogic.getAllOrderedVariants(product);

  return (
    <>
      {showHelp && <MatrixHelpIcon />}
      <ProductMatrixSectionGeneral showOnlyDeliveryWindows={delwinIds} variants={variants} attributesSource="listing" />
    </>
  );
};
