import { Id, Order } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { selectWorkingOrder } from '../../../ducks/order';
import { fetchMoreOrdersRequest, getAreSelectionsLoading, getIsLoadingMore, hasLoadedAll } from '../../../ducks/selections';
import { getAccountNameOrFullName } from '../../../utils/getAccountNameOrFullName';
import { useClickOutside, useModalVisibility, useOnEscHandler } from '../../../utils/hooks';
import { useAmplitude } from '../../../utils/hooks/useAmplitude';
import { isEmpty } from '../../../utils/isEmpty';
import Button from '../../various/Button';
import { IconType } from '../../various/Icon';
import { LazyList } from '../../various/LazyList';
import LoadMore from '../../various/loaders/LoadMore';
import { TextEllipsis } from '../../various/TextEllipsis';
import { Unicode } from '../../various/Unicode/Unicode';

import styles from './OrderSelectPopup.module.scss';

export const ORDER_SELECT_ID = 'orderSelect';

interface Props {
  onClose: VoidFunction;
  orders: Order.Essentials[];
  currentWorkingOrder: Order.Open;
  trigger: HTMLButtonElement | null;
}
export const OrderSelectPopup = ({ orders, currentWorkingOrder, onClose, trigger }: Props) => {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();
  const allLoaded = useSelector(hasLoadedAll);
  const isLoading = useSelector(getAreSelectionsLoading);
  const isLoadingMore = useSelector(getIsLoadingMore);
  const isLoadingSelections = isLoading || isLoadingMore;
  const { t } = useTranslation(['common', 'orders', 'selections']);
  const { showModal } = useModalVisibility('CreateOrderModal');
  const { trackEvent } = useAmplitude();

  const selectOrder = (id: Id) => () => {
    onClose();
    dispatch(selectWorkingOrder(id));
    trackEvent({ name: 'bottomBar.select.selection.click' });
  };

  const handleCreateSelectionClick = () => {
    showModal();
    trackEvent({ name: 'bottomBar.button.createselection.click' });
  };

  const fetchMoreOrders = React.useCallback(() => dispatch(fetchMoreOrdersRequest()), [dispatch]);

  const shouldDisplayOrderList = !isEmpty(orders) || isLoadingSelections;
  const initialLoad = isEmpty(orders) && isLoading;

  useOnEscHandler(document.documentElement, onClose, shouldDisplayOrderList);
  useClickOutside({ element: ref.current, handler: onClose, omit: [trigger] });

  return (
    <div className={styles.orderSelectPopup} ref={ref} id={ORDER_SELECT_ID}>
      {initialLoad ?
        <LoadMore isLoading title={t('selections:loading_selections')} />
      : <div className={styles.inner}>
          {shouldDisplayOrderList ?
            <LazyList
              items={orders}
              isLoading={isLoadingSelections}
              allLoaded={allLoaded}
              onLoadMore={fetchMoreOrders}
              loaderMessage={t('selections:loading_selections')}
              rootMargin="300px"
            >
              {order => {
                const isCurrentWorkingOrder = order.order === currentWorkingOrder.order;
                const classes = cx(styles.item, {
                  [styles.active]: isCurrentWorkingOrder,
                });

                return (
                  <button key={order.order} onClick={selectOrder(order.order)} className={classes}>
                    <TextEllipsis as="div" noWrapText={false}>
                      #{order.orderNumber} {order.name}
                      <span className={styles.account} data-sentry-mask>
                        {getAccountNameOrFullName(order.accountName, order.buyerFirstName, order.buyerLastName)}
                      </span>
                    </TextEllipsis>
                    {isCurrentWorkingOrder && <div>{Unicode.HeavyCheckMark}</div>}
                  </button>
                );
              }}
            </LazyList>
          : <div className={styles.item}>{t('orders:no_open_orders_message')}</div>}
        </div>
      }
      <div className={styles.button}>
        <Button
          title={t('selections:create_selection')}
          data-testid="orderPopupCreateSelectionButton"
          variant={['button', 'black']}
          className={styles.callToActionButton}
          icon={IconType.PlusCircle}
          onClick={handleCreateSelectionClick}
        />
      </div>
    </div>
  );
};
