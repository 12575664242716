import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

import { getResetPassword, resetPasswordRequest } from '../../../ducks';
import { paths } from '../../../paths';
import { useValidatedForm } from '../../../utils/hooks/useValidatedForm';
import { Form, FormAlert, FormButtons, FormGrid, InputField } from '../../various/Form';
import { Button } from '../../various/NewButton';

interface FormData {
  email: string;
}

export const ForgotPasswordForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'login', 'passwordManagement', 'validation']);
  const { isLoading, isFailed } = useSelector(getResetPassword);

  const validationSchema: yup.ObjectSchema<FormData> = yup.object({
    email: yup.string().defined().email(t('validation:invalid_email_hint')).required(t('validation:email_address_hint')),
  });

  const formMethods = useValidatedForm<FormData>({
    validationSchema,
  });

  const { register } = formMethods;

  const handleSubmit = (values: FormData) => {
    dispatch(resetPasswordRequest(values));
  };

  return (
    <Form formMethods={formMethods} onSubmit={handleSubmit}>
      <FormGrid>
        <InputField type="email" label={t('common:email')} {...register('email')} />
      </FormGrid>
      {isFailed && <FormAlert message={t('passwordManagement:reset_password_error')} />}
      <FormButtons direction="column">
        <Button type="submit" isLoading={isLoading} size="large" color="dark" isFluid>
          {t('passwordManagement:reset_password')}
        </Button>
        <Button as={Link} to={paths.LOGIN} size="large" color="dark" variant="ghost" isFluid>
          {t('login:sign_in')}
        </Button>
      </FormButtons>
    </Form>
  );
};
