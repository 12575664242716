import { Tabs as TabsTypes } from '@typings';

import { TabList } from './TabList';

interface Props<T extends string> {
  tabSets: TabsTypes.TabSet[];
  selectedId: T;
  tablistName: string;
  onChange: (id: T) => void;
  size?: TabsTypes.TabListSize;
}

export const Tabs = <T extends string>({ tabSets, selectedId, tablistName, onChange, size }: Props<T>) => {
  const tabs = tabSets.map(tabSet => tabSet.tab);
  const contents = tabSets.reduce<Record<string, JSX.Element>>(
    (acc, cur) => ({
      ...acc,
      [cur.tab.id]: cur.content,
    }),
    {},
  );

  return (
    <div>
      <TabList onChange={onChange} selectedId={selectedId} tablistName={tablistName} tabs={tabs} size={size} />

      {contents[selectedId]}
    </div>
  );
};
