import { Cms } from '@centra';
import React from 'react';
import { useSelector } from 'react-redux';

import { getButtonStyles, getTextStyles } from '../../../ducks';
import { useDeleteStyle } from '../../../utils/hooks';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';

interface Props {
  stylesType: Cms.StyleType;
  currentStyle: string;
}

interface Context {
  SUBMENU_MAX_INDEX: number;
  activeOption: Nullable<number>;
  activeSubOption: Nullable<number>;
  currentStyle: string;
  exitMenu: () => void;
  exitSubMenu: () => void;
  filteredPartStyles: Cms.ContentPartStyle[];
  hideDropdown: () => void;
  isActive: boolean;
  isLastOptionActive: boolean;
  isTextStyle: boolean;
  partStyle: string;
  partStyles: Record<string, Cms.ContentPartStyle>;
  scrollElement: React.RefObject<HTMLUListElement>;
  searchValue: string;
  setActiveOption: React.Dispatch<React.SetStateAction<Nullable<number>>>;
  setActiveSubOption: React.Dispatch<React.SetStateAction<Nullable<number>>>;
  setPartStyle: React.Dispatch<React.SetStateAction<string>>;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  showDropdown: () => void;
  textStyleName: string;
  toggleDropdown: () => void;
  setConfirmationModalData: () => void;
  setPartStyleToDelete: (partStyle: Cms.ContentPartStyle) => void;
}

const SUBMENU_MAX_INDEX = 2;

const initialContext: Context = {
  SUBMENU_MAX_INDEX,
  activeOption: null,
  activeSubOption: null,
  currentStyle: '',
  exitMenu: () => null,
  exitSubMenu: () => null,
  filteredPartStyles: [],
  hideDropdown: () => null,
  isActive: false,
  isLastOptionActive: false,
  isTextStyle: true,
  partStyle: '',
  partStyles: {},
  scrollElement: { current: null },
  searchValue: '',
  setActiveOption: () => null,
  setActiveSubOption: () => null,
  setConfirmationModalData: () => null,
  setPartStyle: () => null,
  setPartStyleToDelete: () => null,
  setSearchValue: () => null,
  showDropdown: () => null,
  textStyleName: '',
  toggleDropdown: () => null,
};

export const StyleSelectContext = React.createContext<Context>(initialContext);

export const StyleSelectContextProvider = ({ children, stylesType, currentStyle }: React.WithChildren<Props>) => {
  const isTextStyle = stylesType !== 'button';
  const scrollElement = React.useRef<HTMLUListElement>(null);

  const [partStyle, setPartStyle] = React.useState<string>('');
  const [isActive, setIsActive] = React.useState<boolean>(false);
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [activeOption, setActiveOption] = React.useState<Nullable<number>>(null);
  const [activeSubOption, setActiveSubOption] = React.useState<Nullable<number>>(null);

  const textStyles = useSelector(getTextStyles);
  const buttonStyles = useSelector(getButtonStyles);
  const partStyles = isTextStyle ? textStyles : buttonStyles;
  const { setConfirmationModalData, setPartStyleToDelete } = useDeleteStyle(partStyles);
  const textStyleName = React.useMemo(() => {
    if (isEmpty(partStyles) || !isDefined(partStyles[currentStyle])) {
      return '';
    }

    return partStyles[currentStyle]?.name ?? '';
  }, [currentStyle, partStyles]);
  const filteredPartStyles = React.useMemo(
    () =>
      isEmpty(searchValue) ?
        Object.values(partStyles)
      : Object.values(partStyles).filter(({ name }) => name.toLowerCase().includes(searchValue.toLowerCase())),
    [searchValue, partStyles],
  );
  const isLastOptionActive = activeOption === filteredPartStyles.length;

  const showDropdown = React.useCallback(() => setIsActive(true), []);
  const hideDropdown = React.useCallback(() => setIsActive(false), []);
  const toggleDropdown = React.useCallback(() => setIsActive(active => !active), []);
  const exitMenu = () => setActiveOption(null);
  const exitSubMenu = () => setActiveSubOption(null);

  React.useEffect(() => {
    if (isActive) {
      setSearchValue('');
      setPartStyle('');

      return;
    }
    setPartStyle(textStyleName);
  }, [currentStyle, isActive, textStyleName]);

  return (
    <StyleSelectContext.Provider
      value={{
        SUBMENU_MAX_INDEX,
        activeOption,
        activeSubOption,
        currentStyle,
        exitMenu,
        exitSubMenu,
        filteredPartStyles,
        hideDropdown,
        isActive,
        isLastOptionActive,
        isTextStyle,
        partStyle,
        partStyles,
        scrollElement,
        searchValue,
        setActiveOption,
        setActiveSubOption,
        setConfirmationModalData,
        setPartStyle,
        setPartStyleToDelete,
        setSearchValue,
        showDropdown,
        textStyleName,
        toggleDropdown,
      }}
    >
      {children}
    </StyleSelectContext.Provider>
  );
};
