import { DeliveryWindow } from '@typings';

export function getDelwinNames(
  deliveryWindowList: ReadonlyArray<DeliveryWindow.Basic>,
  orderDeliveryWindowIdsList: ReadonlyArray<string>,
): string {
  return deliveryWindowList
    .filter(({ deliveryWindow: deliveryWindowId }) => orderDeliveryWindowIdsList.includes(deliveryWindowId))
    .map(deliveryWindow => deliveryWindow.name)
    .join(', ');
}

export default getDelwinNames;
