import { isDefined } from './is';

export const embedPaymentScript = (formHtml: string) => {
  const scriptHolder = document.createElement('div');
  // eslint-disable-next-line functional/immutable-data
  Object.assign(scriptHolder, { innerHTML: formHtml });
  document.body.appendChild(scriptHolder);
  const script = scriptHolder.querySelector('script');
  if (isDefined(script)) {
    // eslint-disable-next-line no-eval
    eval(script.innerHTML);
  }
};
