import { Overview } from '@typings';
import * as d3 from 'd3';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getMergedVariants, getPercentageFromTotal, isVariantRow, PIE_CHART_SIZE as SIZE } from '../../../../logic/selectionOverview';
import { useStickyMouseTooltip } from '../../../../utils/hooks';
import { isNull } from '../../../../utils/is';

import styles from './PieChart.module.scss';
import { Segment } from './Segment';
import { TextGroup } from './TextGroup';

interface Props {
  data: Overview.Row[];
  totalCount: number;
  percentageBase: Overview.PercentageBase;
  expandedRowKeys: string[];
}

const PERCENTAGE_SOURCE = {
  total: 'totalPrice',
  units: 'totalUnits',
};

export const PieChart = ({ data, totalCount, percentageBase, expandedRowKeys }: Props) => {
  const svgRef = React.useRef<SVGSVGElement>(null);
  const { t } = useTranslation(['products', 'selectionOverview']);
  const [viewBox, setViewBox] = React.useState('0,0,0,0');
  const [root, setRoot] = React.useState<Overview.PieChartNode[]>([]);
  const { Tooltip, handleMouseOut, handleMouseMove, handleMouseOver } = useStickyMouseTooltip({
    className: styles.tooltip,
  });

  const valueKey = PERCENTAGE_SOURCE[percentageBase] as 'totalPrice' | 'totalUnits';

  const chartData: Overview.ListingRow = React.useMemo(() => {
    return {
      children: data,
      isVariantRow: false,
      key: 'root',
      primaryDimension: '',
      productsCount: 0,
      rowId: 'root',
      rowIdPath: 'root',
      secondaryDimension: '',
      totalPrice: 0,
      totalUnits: 0,
      variantsCount: 0,
    };
  }, [data]);

  React.useLayoutEffect(() => {
    setViewBox(getAutoBox());
  }, [root]);

  const hasNestedCategory = React.useCallback(
    (rowData: Overview.ListingRow) => {
      return rowData.children.filter(child => !isVariantRow(child) && child[valueKey]).length > 0;
    },
    [valueKey],
  );

  const getHierarchyChildren = React.useCallback(
    (rowData: Overview.ListingRow) => {
      const isRoot = rowData.key === 'root';

      if ((isRoot || expandedRowKeys.includes(rowData.key)) && !isVariantRow(rowData)) {
        const mergedVariants = hasNestedCategory(rowData) ? [getMergedVariants(rowData, t)] : [];
        const dataWithMergedVariants = [...rowData.children, ...mergedVariants];

        return dataWithMergedVariants.filter(child => child[valueKey] && !isVariantRow(child)) as Overview.ListingRow[];
      }

      return undefined;
    },
    [hasNestedCategory, expandedRowKeys, valueKey, t],
  );

  const getPartition = React.useCallback(
    (rawData: Overview.ListingRow) => {
      const hierarchy = d3.hierarchy<Overview.ListingRow>(rawData, getHierarchyChildren).sum((rowData: Overview.ListingRow) => {
        return expandedRowKeys.includes(rowData.key) && hasNestedCategory(rowData) ? 0 : rowData[valueKey];
      });

      return d3.partition<Overview.ListingRow>().size([2 * Math.PI, SIZE / 2])(hierarchy);
    },
    [getHierarchyChildren, expandedRowKeys, hasNestedCategory, valueKey],
  );

  React.useLayoutEffect(() => {
    setRoot(
      getPartition(chartData)
        .descendants()
        .filter(d => d.depth),
    );
  }, [getPartition, chartData]);

  const getAutoBox = React.useCallback(() => {
    if (isNull(svgRef.current)) {
      return '0,0,0,0';
    }

    const { x, y, width, height } = svgRef.current.getBBox();

    return [x, y, width, height].toString();
  }, [svgRef]);

  const handleGroupClick = React.useCallback((event: React.MouseEvent<SVGSVGElement>) => {
    event.stopPropagation();
  }, []);

  const getPercentage = React.useCallback(
    (node: Overview.PieChartNode) => {
      return `${getPercentageFromTotal(node.data[valueKey], totalCount)}%`;
    },
    [valueKey, totalCount],
  );

  const color = React.useMemo(() => {
    const primaryCategoriesWithValues = data.filter(rowData => rowData[valueKey]);

    return d3.scaleOrdinal(d3.quantize(d3.interpolateRainbow, primaryCategoriesWithValues.length + 1));
  }, [data, valueKey]);

  const noUnits = root.length < 1;

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.sticky}>
          {noUnits ?
            <span>{t('selectionOverview:no_units_chart')}</span>
          : <svg width={SIZE} height={SIZE} viewBox={viewBox} ref={svgRef}>
              <g pointerEvents="visible" onClick={handleGroupClick} stroke="white">
                {root.map(node => (
                  <Segment
                    key={node.data.key}
                    color={color}
                    node={node}
                    valueKey={valueKey}
                    totalCount={totalCount}
                    getPercentage={getPercentage}
                    handleMouseMove={handleMouseMove}
                    handleMouseOut={handleMouseOut}
                    handleMouseOver={handleMouseOver}
                  />
                ))}
              </g>
              <TextGroup root={root} svgRef={svgRef} getPercentage={getPercentage} />
            </svg>
          }
        </div>
      </div>
      <Tooltip />
    </>
  );
};
