import { Product } from '@typings';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getCurrentPriceList, getOrderCurrency, getPricelists } from '../../ducks';
import {
  formatPriceWithCurrencyAffix,
  getDeliveryWindowSpecificPriceComponents,
  getDiscountPercentInfo,
  getHasConsistentPrice,
  getIsOnSale,
} from '../../logic/price';
import { isDefined } from '../is';
import { isEmpty } from '../isEmpty';

import { useDelwinIdsWithCurrentStockType } from './useDelwinIdsWithCurrentStockType';

export const useProductPriceComponents = (product: Product.Standard | undefined) => {
  const { t } = useTranslation(['products']);
  const priceLists = useSelector(getPricelists);
  const currentPriceList = useSelector(getCurrentPriceList);
  const orderCurrency = useSelector(getOrderCurrency);
  const currentStockTypeDeliveryWindowsIds = useDelwinIdsWithCurrentStockType();
  const productPrice = product?.priceAsNumber;

  if (!isDefined(product) || !isDefined(productPrice)) {
    return {
      formattedPrice: undefined,
      hasConsistentDiscountPercent: false,
      highestDiscountPercent: 0,
      isOnSale: false,
    };
  }

  const deliveryWindowSpecificPriceComponents = getDeliveryWindowSpecificPriceComponents(product, currentStockTypeDeliveryWindowsIds);
  const isProductOnSale = getIsOnSale(product.showAsOnSale, product.discountPercent);

  const prices = [productPrice, ...deliveryWindowSpecificPriceComponents.prices];
  const hasConsistentPrice = getHasConsistentPrice(product, prices);

  const discounts = [product.discountPercent ?? 0, ...deliveryWindowSpecificPriceComponents.discountPercents];
  const { highestDiscountPercent, hasConsistentDiscountPercent } = getDiscountPercentInfo(discounts);

  const priceListCurrency = priceLists.find(priceList => priceList.id === currentPriceList);
  const currency = isEmpty(orderCurrency.currency) && isDefined(priceListCurrency) ? priceListCurrency.currency : orderCurrency;

  const lowestPrice = Math.min(...prices);
  const formattedLowestPrice = formatPriceWithCurrencyAffix(currency)(lowestPrice);

  const formattedPrice = hasConsistentPrice ? formattedLowestPrice : `${t('products:from')} ${formattedLowestPrice}`;

  return {
    formattedPrice,
    hasConsistentDiscountPercent,
    highestDiscountPercent,
    isOnSale: deliveryWindowSpecificPriceComponents.isAnyOnSale || isProductOnSale,
  };
};
