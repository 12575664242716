import { Filter, Filters } from '@typings';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { pushEvent } from '../../../../../ducks';
import { getSelectedCategoriesTrackPayload } from '../../../../../utils/analytics/events';
import { isDefined } from '../../../../../utils/is';
import { isEmpty } from '../../../../../utils/isEmpty';
import { FiltersCheckboxGroup, FiltersRadioGroup } from '../../FiltersGroup';
import { FilterGroupContainer } from '../../FiltersGroup/FilterGroupContainer';
import { DeliveryWindowFilterContextProvider, FiltersDeliveryWindows } from '../../FiltersGroup/FiltersDeliveryWindows';
import { FiltersSwatchesGroup } from '../../FiltersGroup/FiltersSwatchesGroup';
import { FiltersPanelSelectorsContext } from '../context/FiltersPanelSelectorsContext';

const DELIVERY_WINDOW_FILTER_NAME = 'deliveryWindows';

interface Props {
  attribute: Filter.Field<string>;
  filters: Partial<Filters>;
  onFilterChange: (fieldName: string, selectedValues: string | string[]) => void;
  onToggleFilter: (filterField: string, isExpanded: boolean) => void;
}

export const FiltersPanelGroup = ({ attribute, filters, onFilterChange, onToggleFilter }: Props) => {
  const dispatch = useDispatch();
  const { isRadio, field, values } = attribute;
  const isSwatchGroup = field === 'swatch.desc' || field === 'sh_swatch.desc';
  const activeFilters = filters[field] ?? [];

  const { getExpandedFilters } = React.useContext(FiltersPanelSelectorsContext);
  const expandedFilters = useSelector(getExpandedFilters);
  const isExpanded = expandedFilters[field] ?? false;
  const filterValues = filters[field];
  const selectedCount = isDefined(filterValues) && Array.isArray(filterValues) ? filterValues.length : 0;

  const handleSelectedValueChange = React.useCallback(
    (selectedValues: string | string[]) => {
      onFilterChange(field, selectedValues);
    },
    [onFilterChange, field],
  );

  const handleCheckboxGroupValueChange = React.useCallback(
    (selectedValues: string[]) => {
      onFilterChange(field, selectedValues);

      if (field === 'categories') {
        const trackPayload = getSelectedCategoriesTrackPayload(values, selectedValues);

        isDefined(trackPayload) && dispatch(pushEvent(trackPayload));
      }
    },
    [field, onFilterChange, values, dispatch],
  );

  const handleToggle = React.useCallback(() => {
    onToggleFilter(field, isExpanded);
  }, [field, isExpanded, onToggleFilter]);

  const selectedOptions = Array.isArray(activeFilters) ? activeFilters : [activeFilters];

  if (isEmpty(values)) {
    return null;
  }

  if (isRadio) {
    return (
      <FilterGroupContainer isExpanded={isExpanded} selectedCount={selectedCount} onToggleExpanded={handleToggle} filterField={field}>
        <FiltersRadioGroup
          fieldOptions={values}
          selectedOption={selectedOptions[0]}
          filterField={field}
          key={field}
          onSelectedValueChange={handleSelectedValueChange}
        />
      </FilterGroupContainer>
    );
  }

  if (isSwatchGroup) {
    return (
      <FilterGroupContainer isExpanded={isExpanded} selectedCount={selectedCount} onToggleExpanded={handleToggle} filterField={field}>
        <FiltersSwatchesGroup
          fieldOptions={values}
          filterField={field}
          selectedOptions={selectedOptions}
          key={field}
          onSelectedOptionsChange={handleSelectedValueChange}
        />
      </FilterGroupContainer>
    );
  }

  if (field === DELIVERY_WINDOW_FILTER_NAME) {
    return (
      <FilterGroupContainer isExpanded={isExpanded} selectedCount={selectedCount} onToggleExpanded={handleToggle} filterField={field}>
        <DeliveryWindowFilterContextProvider key={field}>
          <FiltersDeliveryWindows selectedOptions={selectedOptions} onSelectedOptionsChange={handleSelectedValueChange} />
        </DeliveryWindowFilterContextProvider>
      </FilterGroupContainer>
    );
  }

  return (
    <FilterGroupContainer isExpanded={isExpanded} selectedCount={selectedCount} onToggleExpanded={handleToggle} filterField={field}>
      <FiltersCheckboxGroup
        fieldOptions={values}
        filterField={field}
        selectedOptions={selectedOptions}
        key={field}
        onSelectedOptionsChange={handleCheckboxGroupValueChange}
      />
    </FilterGroupContainer>
  );
};
