import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  fetchCmsProductsRequest,
  fetchCustomStylesRequest,
  fetchEditorPageDataRequest,
  fetchMarketsRequest,
  getCmsBlockById,
  getCmsBlocks,
  getCmsBlocksByIds,
  getCmsGroupById,
  getCmsSections,
  getCustomFonts,
  getPageMetaData,
  resetCms,
} from '../../ducks';
import { addAllTextStyleFonts, addCustomFonts } from '../../logic/cms/styles';
import { getUniquePageProducts, isDesktopWidth } from '../../logic/pages';
import { useScreenWidth } from '../../utils/hooks';
import { isDefined } from '../../utils/is';
import { isEmpty } from '../../utils/isEmpty';

import { ClipboardPreview } from './Clipboard/ClipboardPreview';
import { EditorLanguageContextProvider } from './context/EditorLanguageContext';
import { EditorUIContextProvider } from './context/EditorUIContext';
import { SelectorsContext } from './context/SelectorsContext';
import { SizeContextProvider } from './context/SizeContext';
import { CustomTemplateEditorModal } from './CustomTemplateEditorModal/CustomTemplateEditorModal';
import { AddProductsModal } from './addProductsModal';
import { AddTextTemplateModal } from './addTextTemplateModal';
import { EmbedModal } from './EmbedModal';
import { LayoutEditInfo } from './LayoutEditInfo';
import { LinkModal } from './linkModal';
import { MissingCustomStylesModal } from './MissingCustomStylesModal';
import { MobileNotification } from './mobileNotification';
import { ReorderContentModal } from './ReorderContentModal';
import { SectionsWrapper } from './sectionsWrapper';
import { SectionVisibilityModal } from './SectionVisibilityModal';
import { AddTemplateModal, ChangeTemplateModal } from './templateModal';
import { EditorTopBar } from './topBar';
import { UploadMediaModal } from './uploadMediaModal';

const selectorsContextValue = {
  getBlockById: getCmsBlockById,
  getBlocksByIds: getCmsBlocksByIds,
  getGroupById: getCmsGroupById,
  getSections: getCmsSections,
};

export const Cms = () => {
  const { pageId } = useParams<{ pageId: string }>();
  const isDesktop = isDesktopWidth(useScreenWidth());
  const dispatch = useDispatch();
  const blocks = useSelector(getCmsBlocks);
  const pageMeta = useSelector(getPageMetaData);
  const customFonts = useSelector(getCustomFonts);

  const markets = isDefined(pageMeta) ? pageMeta.markets : null;

  React.useEffect(() => {
    dispatch(fetchCustomStylesRequest());
    dispatch(fetchMarketsRequest());
    addCustomFonts(customFonts);
    addAllTextStyleFonts();

    return () => {
      dispatch(resetCms());
      // eslint-disable-next-line functional/immutable-data
      Object.assign(window, { name: undefined });
    };
  }, []);

  React.useEffect(() => {
    if (isDefined(pageId)) {
      dispatch(fetchEditorPageDataRequest(pageId));
    }
  }, [pageId]);

  React.useEffect(() => {
    if (!isDefined(markets)) {
      return;
    }

    const productsIds = getUniquePageProducts(blocks);
    if (isEmpty(productsIds)) {
      return;
    }
    dispatch(
      fetchCmsProductsRequest({
        limit: 0,
        products: productsIds,
        skipFirst: 0,
      }),
    );
  }, [markets]);

  const content = React.useMemo(() => {
    if (!isDesktop) {
      return <MobileNotification />;
    }

    return (
      <>
        <EditorTopBar />
        <AddTemplateModal />
        <AddTextTemplateModal />
        <ChangeTemplateModal />
        <SectionVisibilityModal />
        <AddProductsModal />
        <UploadMediaModal />
        <LinkModal />
        <EmbedModal />
        <MissingCustomStylesModal />
        <ReorderContentModal />
        <LayoutEditInfo />
        <SectionsWrapper />
        <CustomTemplateEditorModal />
        <ClipboardPreview />
      </>
    );
  }, [isDesktop]);

  return React.useMemo(
    () => (
      <SelectorsContext.Provider value={selectorsContextValue}>
        <EditorUIContextProvider viewMode="editor">
          <EditorLanguageContextProvider>
            <SizeContextProvider>{content}</SizeContextProvider>
          </EditorLanguageContextProvider>
        </EditorUIContextProvider>
      </SelectorsContext.Provider>
    ),
    [content],
  );
};
