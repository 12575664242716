import { Shipment } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getOrderVariants } from '../../../ducks';
import { getShippedProducts } from '../../../logic/products';
import { getShippedProductsQuantity } from '../../../logic/shipment';
import { getAddressLine } from '../../../utils/getAddressLine';
import { useExpand } from '../../../utils/hooks';
import { isDefined } from '../../../utils/is';
import { Card } from '../../various/Card';
import { ExpandButton } from '../../various/ExpandButton';
import Heading from '../../various/Heading';
import { List } from '../../various/List';
import { MaybeLink } from '../../various/MaybeLink';
import { ShipmentStatusLabel } from '../../various/StatusLabel';

import styles from './CheckoutCards.module.scss';
import { Data, useShipmentProductsModel } from './ShipmentProductsModel';

interface Props {
  shipment: Shipment;
}

export const ShipmentCard = React.memo<Props>(({ shipment }) => {
  const { shipmentId, shippingAddress, status, sentDate, shippingMethod, tracking, trackingUrl, products } = shipment;
  const orderVariants = useSelector(getOrderVariants);
  const { t } = useTranslation(['common', 'checkout', 'shipping']);
  const model = useShipmentProductsModel();

  const { isOpen, style, toggle, wrapperRef } = useExpand<HTMLDivElement>({
    initialOpen: false,
  });

  const shipmentProducts: Data[] = React.useMemo(() => {
    const items = getShippedProducts(products);

    return orderVariants
      .map(product => ({
        ...product,
        shippedItems: items[`${product.product}-${product.variant}`] ?? [],
      }))
      .filter(product => isDefined(product.shippedItems));
  }, [products, orderVariants]);

  const address = [getAddressLine(shippingAddress.address1, shippingAddress.address2), shippingAddress.city, shippingAddress.countryName];

  const trackingValue = React.useMemo(
    () => (
      <MaybeLink external to={trackingUrl ?? undefined} target="_blank" rel="noopener noreferrer" variant="blue">
        {tracking}
      </MaybeLink>
    ),
    [tracking, trackingUrl],
  );

  const calculatedStatus = status === 'shipped' ? 'shipped' : 'not shipped';
  const statusText = status === 'shipped' ? t('shipping:shipped') : t('shipping:not_shipped');

  return (
    <Card.Wrapper>
      <Card.Group className={styles.shippingInfo}>
        <Card.Title>
          <Heading className={styles.id} variant={['h3']} title={t('common:shipment_number', { shipmentNumber: shipmentId })} />
          <ShipmentStatusLabel status={calculatedStatus} text={statusText} />
        </Card.Title>
        <Card.DescriptionList>
          <Card.Description className={Card.styles.row1col2} name={t('common:quantity')} value={getShippedProductsQuantity(shipment)} />
          <Card.Description className={Card.styles.row1col3} name={t('common:sent')} value={sentDate} />
          <Card.Description className={Card.styles.row2col1} name={t('common:contact_person')} value={shippingAddress.contactPerson} />
          <Card.Description className={Card.styles.row2col2} name={t('common:invoice_one')} value="-" />
          <Card.Description className={Card.styles.row3col1} name={t('common:address')} value={address} />
          <Card.Description className={Card.styles.row2col3} name={t('common:carrier')} value={shippingMethod.carrier} />
          <Card.Description className={Card.styles.row3col3} name={t('common:tracking_id')} value={trackingValue} />
        </Card.DescriptionList>
        <ExpandButton onClick={toggle} isOpen={isOpen} />
      </Card.Group>
      <div className={styles.productWrapper} style={style}>
        <Card.Group wrapperRef={wrapperRef} className={styles.tableWrapper}>
          <List className={styles.table} keyProperty="variant" data={shipmentProducts} model={model} />
        </Card.Group>
      </div>
    </Card.Wrapper>
  );
});
