import { Product } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getActiveDeliveryWindowsIdsForOrderBuyer } from '../../../../../ducks';
import { getOrderDetails } from '../../../../../ducks/order';
import { getIsExpired } from '../../../../../logic/deliveryWindows';
import { isMultivariantRelation } from '../../../../../logic/merchandise';
import { getDeliveryWindowsExpiredMessage, getDeliveryWindowsFromProducts, getIsOrderEditable } from '../../../../../logic/Orders';
import ProductsLogic, { GetProductUrl } from '../../../../../logic/products';
import { ensureKey, isDefined } from '../../../../../utils/is';
import { isEmpty } from '../../../../../utils/isEmpty';
import { DelwinExpiredMessage } from '../../../../various/DelwinExpiredMessage';
import { FilteredProductMatrixSection } from '../../../ProductMatrix/FilteredProductMatrixSection/FilteredProductMatrixSection';
import { MatrixHelpIcon } from '../../../ProductMatrix/MatrixHelp';
import { RelatedProducts } from '../../../RelatedProducts';
import { UnitSwitcher } from '../../../UnitSwitcher';
import { ProductDetailsLayout } from '../../ProductDetailsLayout';

import styles from './ProductDetailsModalContent.module.scss';

interface Props {
  productDetails: Product.Full & Product.StandardRelation;
  getProductUrl: GetProductUrl;
}

export const ProductDetailsModalContent = ({ productDetails, getProductUrl }: Props) => {
  const { t } = useTranslation(['expiredDelwins']);
  const activeDeliveryWindowsIds = useSelector(getActiveDeliveryWindowsIdsForOrderBuyer);
  const orderDetails = useSelector(getOrderDetails);

  const getExpiredDelwins = () => {
    const { product } = productDetails;
    const products = orderDetails.order.products.filter(orderProduct => orderProduct.product === product);
    const orderDelwins = getDeliveryWindowsFromProducts(products, orderDetails.deliveryWindows);

    return orderDelwins.filter(delwin => getIsExpired(delwin, activeDeliveryWindowsIds));
  };

  const variants = React.useMemo(
    () => (isEmpty(productDetails) ? [] : ProductsLogic.getAllOrderedVariants(productDetails)),
    [productDetails],
  );

  const filteredVariants = React.useMemo(
    () => variants.filter(variant => variant.product === productDetails.product || isMultivariantRelation(variant)),
    [productDetails.product, variants],
  );

  const expiredOrderDelwins = isDefined(productDetails.product) ? getExpiredDelwins() : [];
  const expiredMessage = getDeliveryWindowsExpiredMessage(expiredOrderDelwins, t);

  return (
    <div className={styles.modalWrapper} data-testid="productDetailsModal">
      <ProductDetailsLayout productDetails={productDetails}>
        <MatrixHelpIcon />
        {ensureKey(productDetails, 'tableMappings') && (
          <div className={styles.switcher}>
            <UnitSwitcher size="small" tableMappings={productDetails.tableMappings} defaultUnit={productDetails.defaultLocalizedChart} />
          </div>
        )}
        {!isEmpty(expiredMessage) && <DelwinExpiredMessage expiredMessage={expiredMessage} className={styles.expiredMessageWrapper} />}
        <FilteredProductMatrixSection variants={filteredVariants} isReadOnly={!getIsOrderEditable(orderDetails.order.status)} />
        <RelatedProducts getProductUrl={getProductUrl} />
      </ProductDetailsLayout>
    </div>
  );
};
