import React from 'react';

import { isDefined } from '../../../../utils/is';
import { useMatrixQuantitiesContext } from '../../../products/ProductMatrix/context';
import { MatrixFooter } from '../MartixFooter';

import { MatrixBodyRow } from './MatrixBodyRow';

export const MatrixBody = React.memo(() => {
  const { cellQuantitiesMap } = useMatrixQuantitiesContext();

  return (
    <tbody>
      {cellQuantitiesMap.map((row, idx) => {
        const [firstRow] = row;

        if (!isDefined(firstRow)) {
          return null;
        }

        const { itemId, variantId } = firstRow;

        const key = `${variantId}-${itemId}`;

        return <MatrixBodyRow key={key} variantId={variantId} rowQuantities={row} rowIndex={idx} />;
      })}
      {/* footer placed inside body to fix copying */}
      <MatrixFooter />
    </tbody>
  );
});
