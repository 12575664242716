/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 19;
const ORIGINAL_HEIGHT = 16;
const PROPORTION = ORIGINAL_HEIGHT / ORIGINAL_WIDTH;

export const Chart = ({ width = ORIGINAL_WIDTH }: IconComponentProps) => {
  return (
    <svg width={width} height={width * PROPORTION} viewBox="0 0 24 24">
      <path d="M0 22.989v-12.978h1.989v5.971h1.97v-5.971h2.073v1.99h1.948v-1.99h2.012v3.98h1.99v-3.98h2.031v1.948h1.949v-1.948h2.073v3.98h1.948v-3.98h2.032v5.971h1.906v-5.971h2.095v1.948h1.97v-1.948h2.053v3.98h1.886v-3.98h2.095v1.948h1.927v-1.948h2.053v3.98h1.97v-3.98h2.032v12.979h-42.002z" />
    </svg>
  );
};
