import { Cms } from '@typings';
import { mergeWith, zipWith } from 'ramda';

import { isDefined, isNull } from '../../utils/is';
import { isEmpty } from '../../utils/isEmpty';
import { report } from '../../utils/monitoring';

export const localizationMerger = (left: unknown, right: unknown): unknown => {
  if (Array.isArray(left) && Array.isArray(right)) {
    return zipWith(localizationMerger, left, right);
  }

  if (isNull(left) || isNull(right)) {
    return right;
  }

  if (typeof left === 'object' && typeof right === 'object') {
    return mergeWith(localizationMerger, left, right);
  }

  return right;
};

export const applyBlockLocalization = (
  defaultBlockData: Nullable<Cms.AnyBlock>,
  localizedBlockData: Cms.LocalizedBlockSettings,
): Nullable<Cms.AnyBlock> => {
  if (!isDefined(defaultBlockData) || isEmpty(localizedBlockData.settings)) {
    return defaultBlockData;
  }

  if (isEmpty(defaultBlockData.settings) && !isEmpty(localizedBlockData.settings)) {
    report(`Block ${defaultBlockData.id} is empty, and there are localizations for this block in the page data, indicating data mismatch.`);

    return defaultBlockData;
  }

  return mergeWith(localizationMerger, defaultBlockData, localizedBlockData);
};
