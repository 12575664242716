import { createAction } from 'typesafe-actions';

import {
  AllProductsTrackingEvent,
  BarcodeScannerTrackingEvent,
  CheckoutTrackingEvent,
  CommonTrackingEvent,
  DelwinFilterTrackingEvent,
  DownloadItemTrackingEvent,
  getAddedProductPositionWithSearchQueryEvent,
  getCheckoutStepsClickedEvent,
  getDownloadButtonChoiceSelectedEvent,
  getDownloadLinesheetGeneratedEvent,
  getDownloadLinesheetOrderGeneratedEvent,
  getFiltersPanelPinnedEvent,
  getGroupsRenderedEvent,
  getLanguageAppliedEvent,
  getLanguageSwitcherChangedEvent,
  getNavigationButtonsClickedEvent,
  getPaymentMethodSelectedEvent,
  getProductListingSwitcherClickedEvent,
  getSelectedCategoriesTrackPayload,
  getShippingAddressSelectedEvent,
  getSortBySelectedEvent,
  MatrixTrackingEvent,
  MySelectionPageTrackingEvent,
  ProductDetailsModalTrackingEvent,
  StockTypeSeparationTrackingEvent,
  TopBarTrackingEvent,
  TranslationsTrackingEvent,
} from '../../utils/analytics/events';

type Tracking =
  | {
      event:
        | AllProductsTrackingEvent
        | BarcodeScannerTrackingEvent
        | CheckoutTrackingEvent
        | CommonTrackingEvent
        | DelwinFilterTrackingEvent
        | DownloadItemTrackingEvent
        | MatrixTrackingEvent
        | MySelectionPageTrackingEvent
        | ProductDetailsModalTrackingEvent
        | StockTypeSeparationTrackingEvent
        | TopBarTrackingEvent
        | TranslationsTrackingEvent;
      [parameter: string]: unknown;
    }
  | ReturnType<
      | typeof getAddedProductPositionWithSearchQueryEvent
      | typeof getDownloadButtonChoiceSelectedEvent
      | typeof getShippingAddressSelectedEvent
      | typeof getPaymentMethodSelectedEvent
      | typeof getNavigationButtonsClickedEvent
      | typeof getDownloadLinesheetGeneratedEvent
      | typeof getDownloadLinesheetOrderGeneratedEvent
      | typeof getFiltersPanelPinnedEvent
      | typeof getSelectedCategoriesTrackPayload
      | typeof getProductListingSwitcherClickedEvent
      | typeof getSortBySelectedEvent
      | typeof getCheckoutStepsClickedEvent
      | typeof getGroupsRenderedEvent
      | typeof getLanguageAppliedEvent
      | typeof getLanguageSwitcherChangedEvent
    >;

export const pushEvent = createAction('track/PUSH_EVENT')<Tracking>();
export const initHotjar = createAction('track/INIT_HOTJAR')();
