import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Sorting } from '../../../../../typings';
import { getCurrentOrDefaultProductSortingValue, getProductSortingFields } from '../../../ducks';
import { Option, Select } from '../../various/Fields/Select';

import styles from './ProductSorting.module.scss';

interface Props {
  onSelect: (value: Sorting.FieldValue) => void;
}

export const ProductSorting = ({ onSelect }: Props) => {
  const { t } = useTranslation(['common']);
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const sortingFields = useSelector(getProductSortingFields);
  const sortingValue = useSelector(getCurrentOrDefaultProductSortingValue);

  return (
    <div ref={wrapperRef} className={styles.select}>
      <Select
        size="small"
        value={sortingValue}
        placeholder={t('common:sort_by')}
        containerRef={wrapperRef}
        overflowContainer
        isScrollable={false}
        shouldMatchTriggerWidth={false}
        onChange={onSelect}
      >
        {sortingFields.map(({ field, name }) => (
          <Option
            key={field}
            value={field}
            selectedLabel={sortingValue === 'default' ? t('common:sort_by') : `${t('common:sort_by')} (${name})`}
          >
            {name}
          </Option>
        ))}
      </Select>
    </div>
  );
};
