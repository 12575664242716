import { ProductCardSkeleton } from '../ProductCardSkeleton';
import { ProductLineSkeleton } from '../ProductLineSkeleton';

interface Props {
  isGridView: boolean;
}

export const ProductSkeleton = ({ isGridView }: Props) => {
  return isGridView ? <ProductCardSkeleton /> : <ProductLineSkeleton hasAddButton />;
};
