import {
  defaultDropAnimation,
  DndContext,
  DragEndEvent,
  DragMoveEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  DropAnimation,
  MeasuringStrategy,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FlattenedItem, SortableNestItems } from '@typings/components/sortableTree';
import { clone } from 'ramda';
import React from 'react';
import { createPortal } from 'react-dom';

import { useAccessibility } from '../../../../utils/hooks/sortable/useAccessibility';
import { isDefined } from '../../../../utils/is';
import { isEmpty } from '../../../../utils/isEmpty';
import { addToast } from '../../Toasts';

import { ListScroller } from './ListScroller/ListScroller';
import { SortableItem } from './SortableItem';
import { buildTree, flattenTree, getProjection, removeChildrenOf } from './utilities';

export type NestValidationResult = { message?: string; isValid: boolean };
interface Props {
  items: SortableNestItems;
  renderNestPlaceholder: (id: UniqueIdentifier) => JSX.Element | null;
  renderItem: (id: UniqueIdentifier) => JSX.Element | null;
  onSortStart?: () => void;
  onSortEnd: (items: SortableNestItems) => void;
  isDisabled?: boolean;
  indentationWidth?: number;
  maxDepth?: number;
  scrollToDirection: { to: 'bottom' | 'top' } | null;
  validateNesting?: (activeItemId: UniqueIdentifier, parentId: UniqueIdentifier | null) => NestValidationResult;
}

const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
};
const DROP_ANIMATION_OFFSET = 5;
const dropAnimationConfig: DropAnimation = {
  easing: 'ease-out',
  keyframes({ transform }) {
    return [
      { opacity: 1, transform: CSS.Transform.toString(transform.initial) },
      {
        opacity: 0,
        transform: CSS.Transform.toString({
          ...transform.final,
          x: transform.final.x + DROP_ANIMATION_OFFSET,
          y: transform.final.y + DROP_ANIMATION_OFFSET,
        }),
      },
    ];
  },
  sideEffects({ active }) {
    active.node.animate([{ opacity: 0 }, { opacity: 1 }], {
      duration: defaultDropAnimation.duration,
      easing: defaultDropAnimation.easing,
    });
  },
};

const DEFAULT_INDENTATION_WIDTH = 20;
export const SortableTree = ({
  items,
  indentationWidth = DEFAULT_INDENTATION_WIDTH,
  maxDepth,
  isDisabled,
  renderItem,
  renderNestPlaceholder,
  onSortStart,
  validateNesting,
  scrollToDirection,
  onSortEnd,
}: Props) => {
  const [activeId, setActiveId] = React.useState<UniqueIdentifier | null>(null);
  const [overId, setOverId] = React.useState<UniqueIdentifier | null>(null);
  const [offsetLeft, setOffsetLeft] = React.useState(0);

  const flattenedItems = React.useMemo(() => {
    const flattenedTree = flattenTree(items);
    const collapsedItemIds = flattenedTree.filter(item => item.collapsed && !isEmpty(item.children)).map(item => item.id);

    return removeChildrenOf(flattenedTree, isDefined(activeId) ? [activeId, ...collapsedItemIds] : collapsedItemIds);
  }, [activeId, items]);

  const projected =
    isDefined(activeId) && isDefined(overId) ?
      getProjection({
        activeId,
        dragOffset: offsetLeft,
        indentationWidth,
        items: flattenedItems,
        maxAllowedDepth: maxDepth,
        overId,
      })
    : null;

  const { announcements, setCurrentAccessibilityPosition, resetAccessibilityPosition } = useAccessibility(projected, items);
  const canNestActiveItem = React.useMemo(
    () => (validateNesting && isDefined(activeId) ? validateNesting(activeId, projected?.parentId ?? null) : { isValid: true }),
    [validateNesting, activeId, projected?.parentId],
  );
  const showValidationNotification = React.useCallback(() => {
    const { message } = canNestActiveItem;
    if (isDefined(message)) {
      addToast(message);
    }
  }, [canNestActiveItem]);

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const sortedIds = React.useMemo(() => flattenedItems.map(({ id }) => id), [flattenedItems]);
  const activeItem = isDefined(activeId) ? flattenedItems.find(({ id }) => id === activeId) : null;

  const handleDragStart = ({ active }: DragStartEvent) => {
    setActiveId(active.id);
    setOverId(active.id);

    const draggedItem = flattenedItems.find(({ id }) => id === activeId);

    if (draggedItem) {
      setCurrentAccessibilityPosition({
        overId: active.id,
        parentId: draggedItem.parentId,
      });
    }
    onSortStart?.();
    document.body.style.setProperty('cursor', 'grabbing');
  };

  const handleDragMove = ({ delta }: DragMoveEvent) => {
    setOffsetLeft(delta.x);
  };

  const handleDragOver = ({ over }: DragOverEvent) => {
    setOverId(over?.id ?? null);
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    resetState();
    if (!canNestActiveItem.isValid) {
      showValidationNotification();
    }

    const isInDroppableArea = over?.id !== 'top-droppable' && over?.id !== 'bottom-droppable';

    if (isInDroppableArea && canNestActiveItem.isValid && projected && over) {
      const { depth, parentId } = projected;
      const clonedItems: FlattenedItem[] = clone(flattenTree(items));
      const overIndex = clonedItems.findIndex(({ id }) => id === over.id);
      const activeIndex = clonedItems.findIndex(({ id }) => id === active.id);
      const activeTreeItem = clonedItems[activeIndex];

      if (!isDefined(activeTreeItem)) {
        return;
      }

      // eslint-disable-next-line functional/immutable-data
      clonedItems[activeIndex] = { ...activeTreeItem, depth, parentId };

      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);
      const newItems = buildTree(sortedItems);
      onSortEnd(newItems);
    }
  };

  const handleDragCancel = () => {
    resetState();
  };

  const resetState = () => {
    setOverId(null);
    setActiveId(null);
    setOffsetLeft(0);
    resetAccessibilityPosition();

    document.body.style.setProperty('cursor', '');
  };

  return (
    <DndContext
      accessibility={{ announcements }}
      sensors={sensors}
      autoScroll
      measuring={measuring}
      onDragStart={handleDragStart}
      onDragMove={handleDragMove}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <ListScroller scrollToDirection={scrollToDirection}>
        <SortableContext items={sortedIds} strategy={verticalListSortingStrategy}>
          {flattenedItems.map(({ id, depth }) => (
            <SortableItem
              key={id}
              id={id}
              render={renderItem}
              renderNestPlaceholder={renderNestPlaceholder}
              disableSelection={isDisabled}
              disableInteraction={isDisabled}
              value={id}
              depth={id === activeId && isDefined(projected) ? projected.depth : depth}
              indentationWidth={indentationWidth}
              highlightParent={id === projected?.parentId}
            />
          ))}
          {createPortal(
            <DragOverlay dropAnimation={dropAnimationConfig}>
              {isDefined(activeId) && isDefined(activeItem) && (
                <SortableItem
                  id={activeId}
                  disableInteraction={isDisabled}
                  disableSelection={isDisabled}
                  depth={activeItem.depth}
                  isDragOverlay
                  render={renderItem}
                  renderNestPlaceholder={renderNestPlaceholder}
                  childCount={activeItem.children.length}
                  value={activeId.toString()}
                  indentationWidth={indentationWidth}
                  cannotDrop={!canNestActiveItem.isValid}
                />
              )}
            </DragOverlay>,
            document.body,
          )}
        </SortableContext>
      </ListScroller>
    </DndContext>
  );
};
