import { AnyAction } from 'redux';
import { isActionOf } from 'typesafe-actions';

import * as blocksActions from '../../ducks/cms/blocks/actions';
import { applyButtonStyle, applyTextStyle, editButtonStyleSuccess, editTextStyleSuccess } from '../../ducks/cms/customStyles/actions';
import * as groupsActions from '../../ducks/cms/groups/actions';
import * as localizationsActions from '../../ducks/cms/localizations/actions';
import * as sectionsActions from '../../ducks/cms/sections/actions';

const customStylesActions = [applyButtonStyle, applyTextStyle, editButtonStyleSuccess, editTextStyleSuccess];

const layoutActions = [blocksActions, groupsActions, sectionsActions, customStylesActions].flatMap(Object.values);

export const getIsLayoutAction = (action: AnyAction) => {
  return isActionOf(layoutActions)(action);
};

export const getIsLocalizationsAction = (action: AnyAction) => {
  return isActionOf(Object.values(localizationsActions))(action);
};
