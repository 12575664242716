import { Navigation } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useHover, useLayer } from 'react-laag';

import universalStyles from '../../../../css/utilities/universal.module.scss';
import { useOnEscHandler } from '../../../utils/hooks';
import { useIsTouchScreen } from '../../../utils/hooks/useIsTouchScreen';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { FocusTrap } from '../../various/FocusTrap';
import { ArrowBlackDown } from '../../various/Icon/Icons/ArrowBlackDown';

import styles from './NavigationDropdown.module.scss';

interface Props extends Navigation.ItemStyles {
  dropdownItems: React.ReactNode[];
  isFolder?: boolean;
  moreLabel?: string;
}

const HOVER_DELAY = 100;

export const NavigationDropdown = ({ dropdownItems, children, moreLabel, isFolder = false }: React.WithChildren<Props>) => {
  const moreButtonRef = React.useRef<HTMLButtonElement>(null);
  const [minWidth, setMinWidth] = React.useState<string>();
  const [isOpen, setIsOpen] = React.useState(false);
  const isTouchScreen = useIsTouchScreen();

  const [isOver, hoverProps] = useHover({
    delayEnter: HOVER_DELAY,
    delayLeave: HOVER_DELAY,
    hideOnScroll: false,
  });

  const isDropdownOpen = isOpen || isOver;

  const moreButtonClassNames = cx(styles.expandButton, {
    [styles.dropdownOpened]: isDropdownOpen,
  });

  const handleToggleDropdown = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      const isKeyboard = event.detail === 0;

      if (!isKeyboard && !isTouchScreen) {
        return;
      }

      setIsOpen(current => !current);
    },
    [setIsOpen],
  );

  const handleCloseDropdown = React.useCallback(() => {
    setIsOpen(false);
    moreButtonRef.current?.focus();
  }, [setIsOpen]);

  React.useEffect(() => {
    setIsOpen(false);
  }, [isTouchScreen, setIsOpen]);

  useOnEscHandler(document.documentElement, handleCloseDropdown, isDropdownOpen);

  const { renderLayer, layerProps, triggerProps } = useLayer({
    auto: true,
    isOpen: isDropdownOpen,
    onOutsideClick: handleCloseDropdown,
    placement: 'bottom-start',
    triggerOffset: 2,
  });

  const wrapperRef = React.useCallback((wrapperEl: HTMLDivElement) => {
    if (!isDefined(wrapperEl)) {
      return;
    }

    setMinWidth(`${wrapperEl.offsetWidth}px`);
  }, []);

  if (isEmpty(dropdownItems)) {
    return null;
  }

  return (
    <li>
      <div ref={wrapperRef} {...hoverProps}>
        <div className={styles.triggerMenuWrapper} {...triggerProps}>
          {!isFolder && children}
          <button ref={moreButtonRef} className={moreButtonClassNames} onClick={handleToggleDropdown}>
            {isFolder && children}
            {!isFolder && isDefined(moreLabel) && <span className={universalStyles.srOnly}>{moreLabel}</span>}
            <ArrowBlackDown width={10} />
          </button>
        </div>
      </div>
      {renderLayer(
        <>
          {isDropdownOpen && (
            <FocusTrap>
              <ul className={styles.menuContainer} {...layerProps} {...hoverProps} style={{ ...layerProps.style, minWidth }}>
                {dropdownItems}
              </ul>
            </FocusTrap>
          )}
        </>,
      )}
    </li>
  );
};
