import React from 'react';

import { getIsSafari } from '../../../utils/getIsSafari';

import styles from './ReorderContentModal.module.scss';

interface Props {
  id: string;
}

const PREVIEW_WIDTH = 74;

export const GroupPreview = React.memo(({ id }: Props) => {
  const groupNode = document.getElementById(id);

  const groupNodeSize = {
    height: groupNode?.offsetHeight ?? 0,
    width: groupNode?.offsetWidth ?? 0,
  };

  const previewContent = {
    __html: groupNode?.outerHTML ?? '',
  };

  const scaleStyle =
    getIsSafari() ?
      {
        transform: `scale(${PREVIEW_WIDTH / groupNodeSize.width})`,
        transformOrigin: '0 0',
      }
    : {};

  const previewContentContainerStyle = {
    height: groupNodeSize.height,
    width: groupNodeSize.width,
    ...scaleStyle,
  };

  const preventKeyEvents = React.useCallback((event: React.KeyboardEvent<SVGForeignObjectElement>) => event.preventDefault(), []);

  return (
    <div className={styles.group}>
      <svg viewBox={`0 0 ${groupNodeSize.width} ${groupNodeSize.height}`}>
        <foreignObject className={styles.preview} onKeyDown={preventKeyEvents}>
          <div style={previewContentContainerStyle} dangerouslySetInnerHTML={previewContent} />
        </foreignObject>
      </svg>
    </div>
  );
});
