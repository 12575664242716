import { combineEpics } from 'redux-observable';
import { EMPTY } from 'rxjs';
import { debounceTime, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import {
  fetchCmsProductsFailure,
  fetchCmsProductsForPreviewRequest,
  fetchCmsProductsForPreviewSuccess,
  fetchCmsProductsRequest,
  fetchCmsProductsSuccess,
  getCmsProducts,
  getProductsPerRequest,
  loadMoreCmsProductsFailure,
  loadMoreCmsProductsRequest,
  loadMoreCmsProductsSuccess,
  pasteGroup,
  pasteSection,
  searchProductsRequest,
  searchProductsSuccess,
} from '../../ducks';
import { getUniquePageProducts } from '../../logic/pages';
import { isDefined } from '../../utils/is';
import { isEmpty } from '../../utils/isEmpty';
import { mapResponse } from '../../utils/operators/mapResponse';

const DEBOUNCE_TIME = 350;

const fetchProducts: AppEpic = (action$, state$, { productsRepository }) =>
  action$.pipe(
    filter(isActionOf(fetchCmsProductsRequest)),
    map(action => action.payload),
    mergeMap(async payload => {
      const limitOffset = isDefined(payload.limit) ? payload.limit : getProductsPerRequest(state$.value);

      return { ...payload, limit: limitOffset };
    }),
    mergeMap(async requestData => productsRepository.fetchCmsProducts(requestData)),
    mapResponse(
      res => fetchCmsProductsSuccess(res.data),
      () => fetchCmsProductsFailure(),
    ),
  );

const fetchProductsForPreview: AppEpic = (action$, _, { productsRepository }) =>
  action$.pipe(
    filter(isActionOf(fetchCmsProductsForPreviewRequest)),
    map(action => action.payload),
    mergeMap(async requestData => productsRepository.fetchCmsProducts(requestData)),
    mapResponse(
      res => fetchCmsProductsForPreviewSuccess(res.data),
      () => fetchCmsProductsFailure(),
    ),
  );

const searchProducts: AppEpic = (action$, state$, { productsRepository }) =>
  action$.pipe(
    filter(isActionOf(searchProductsRequest)),
    debounceTime(DEBOUNCE_TIME),
    map(action => action.payload),
    switchMap(async payload => {
      const limit = getProductsPerRequest(state$.value);

      return productsRepository.fetchCmsProducts({
        ...payload,
        limit,
        skipFirst: 0,
      });
    }),
    mapResponse(
      res => searchProductsSuccess(res.data),
      () => fetchCmsProductsFailure(),
    ),
  );

const loadMoreProducts: AppEpic = (action$, state$, { productsRepository }) =>
  action$.pipe(
    filter(isActionOf(loadMoreCmsProductsRequest)),
    map(action => action.payload),
    mergeMap(async payload => {
      const limit = getProductsPerRequest(state$.value);

      return productsRepository.fetchCmsProducts({
        ...payload,
        limit,
      });
    }),
    mapResponse(
      res => loadMoreCmsProductsSuccess(res.data),
      () => loadMoreCmsProductsFailure(),
    ),
  );

const refreshProducts: AppEpic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([pasteGroup, pasteSection])),
    map(action => action.payload.blocks),
    mergeMap(copiedBlocks => {
      const cmsProducts = getCmsProducts(state$.value);
      const copiedProductIds = getUniquePageProducts(copiedBlocks);
      const missingProductIds = copiedProductIds.filter(id => !isDefined(cmsProducts[id]));

      if (isEmpty(missingProductIds)) {
        return EMPTY;
      }

      return [
        fetchCmsProductsRequest({
          limit: 0,
          products: missingProductIds,
          skipFirst: 0,
        }),
      ];
    }),
  );

export const productsEpic = combineEpics(loadMoreProducts, fetchProducts, searchProducts, fetchProductsForPreview, refreshProducts);
