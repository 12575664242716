import cx from 'classnames';
import React from 'react';

import styles from './MatrixCells.module.scss';

interface Props {
  className?: string;
  rowSpan?: number;
  style?: React.CSSProperties;
}

export const RegularHeaderCell = ({ children, className, rowSpan, style }: React.WithOptionalChildren<Props>) => {
  const classNames = cx(styles.regularCell, className);

  return (
    <th data-testid="matrixHeadCell" rowSpan={rowSpan} className={classNames} style={style}>
      {children}
    </th>
  );
};
