/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_SIZE = 16;
const ORIGINAL_COLOR = '#BACCD3';

export const PriceTag = ({ width = ORIGINAL_SIZE, color = ORIGINAL_COLOR }: IconComponentProps) => {
  return (
    <svg width={width} height={width} viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.72797 1.71967L1.72797 7.71967C1.48797 7.95967 1.33463 8.29967 1.33463 8.66634C1.33463 9.03301 1.4813 9.36634 1.72797 9.60634L6.39464 14.273C6.63463 14.5197 6.96797 14.6663 7.33464 14.6663C7.7013 14.6663 8.03464 14.5197 8.27464 14.2797L14.2746 8.27967C14.5213 8.03301 14.668 7.69967 14.668 7.33301V2.66634C14.668 1.93301 14.068 1.33301 13.3346 1.33301H8.66797C8.3013 1.33301 7.96797 1.47967 7.72797 1.71967ZM13.3346 3.66634C13.3346 4.21967 12.888 4.66634 12.3346 4.66634C11.7813 4.66634 11.3346 4.21967 11.3346 3.66634C11.3346 3.11301 11.7813 2.66634 12.3346 2.66634C12.888 2.66634 13.3346 3.11301 13.3346 3.66634Z"
        fill={color}
      />
    </svg>
  );
};
