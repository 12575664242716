import { Addresses, Id } from '@typings';
import { pick } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { INVOICING_ADDRESS_FIELDS } from '../../../../constants/address';
import { getCountries } from '../../../../ducks';
import { getIsAccountEditingEnabled } from '../../../../ducks/config';
import { useUpdateInvoiceAddress } from '../../../../services/hooks/account/useUpdateInvoiceAddress';
import { useValidatedForm } from '../../../../utils/hooks/useValidatedForm';
import { isDefined } from '../../../../utils/is';
import { isEmpty } from '../../../../utils/isEmpty';
import { getContactPersonSchema, getPhoneSchema, getStateSchema } from '../../../../utils/schemas';
import { Option } from '../../../various/Fields/Select';
import { AutocompleteField, Form, FormFieldset, FormGrid, InputField } from '../../../various/Form';
import Heading from '../../../various/Heading';
import { SectionFormHeading, SectionHeadingWrapper } from '../../../various/SectionHeading';

interface Props {
  accountId: Id;
  address: Addresses.Billing;
}

export const InvoicingAddressForm = ({ accountId, address }: Props) => {
  const { t } = useTranslation(['common', 'validation']);
  const countries = useSelector(getCountries);
  const isAccountEditingEnabled = useSelector(getIsAccountEditingEnabled);

  const [isEditing, setIsEditing] = React.useState(false);

  const { mutate, isPending } = useUpdateInvoiceAddress();

  const defaultValues: Addresses.BillingDTO = React.useMemo(() => {
    return {
      ...pick(INVOICING_ADDRESS_FIELDS, address),
      address1: address.address1 ?? '',
      address2: address.address2 ?? '',
      country: address.country || null,
      // API doesn't return VAT number in account billing address (WS-4342)
      vatNumber: address.vatNumber ?? '',
    };
  }, [address]);

  const validationSchema: yup.ObjectSchema<Addresses.BillingDTO> = yup.object({
    address1: yup.string().required(t('validation:address_hint')),
    address2: yup.string().defined(),
    city: yup.string().required(t('validation:city_hint')),
    company: yup.string().required(t('validation:company_name_hint')),
    contactPerson: getContactPersonSchema(),
    country: yup.string().required(t('validation:country_hint')),
    email: yup.string().defined().email(t('validation:invalid_email_hint')),
    phoneNumber: getPhoneSchema(),
    state: getStateSchema(countries),
    vatNumber: yup.string().required(t('validation:vat_number_hint')),
    zipCode: yup.string().required(t('validation:zip_code_hint')),
  });

  const formMethods = useValidatedForm<Addresses.BillingDTO>({ defaultValues, validationSchema });
  const { register, reset, watch } = formMethods;

  React.useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const selectedCountry = watch('country');

  const countryOptions = React.useMemo(() => {
    return Object.values(countries).map(({ country, name }) => ({ country, name }));
  }, [countries]);

  const stateOptions = React.useMemo(() => {
    if (!isDefined(selectedCountry) || !isDefined(countries)) {
      return [];
    }

    return Object.values(countries[selectedCountry]?.states ?? {});
  }, [countries, selectedCountry]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    reset();
    setIsEditing(false);
  };

  const handleSubmit = (data: Addresses.BillingDTO) => {
    mutate(
      { accountId, data },
      {
        onSuccess: () => {
          setIsEditing(false);
        },
      },
    );
  };

  return (
    <Form formMethods={formMethods} onSubmit={handleSubmit}>
      {isAccountEditingEnabled ?
        <SectionFormHeading
          heading={t('common:invoicing')}
          isEditing={isEditing}
          isLoading={isPending}
          onCancel={handleCancel}
          onEdit={handleEdit}
        />
      : <SectionHeadingWrapper>
          <Heading title={t('common:invoicing')} variant={['h2', 'black']} />
        </SectionHeadingWrapper>
      }
      <FormFieldset isDisabled={!isEditing || isPending}>
        <FormGrid cols={2} colGap="xlarge">
          <InputField label={t('common:company_name')} isRequired {...register('company')} />
          <InputField label={t('common:contact_person')} {...register('contactPerson')} />
          <InputField label={t('common:email')} type="email" {...register('email')} />
          <InputField label={t('common:phone_number')} type="tel" {...register('phoneNumber')} />
          <InputField label={t('common:address')} isRequired {...register('address1')} />
          <InputField label={t('common:address_continued')} {...register('address2')} />
          <InputField label={t('common:city')} isRequired {...register('city')} />
          <InputField label={t('common:zip_code')} isRequired {...register('zipCode')} />
          <AutocompleteField name="country" label={t('common:country')} isRequired isDisabled={!isEditing}>
            {countryOptions.map(({ country, name }) => (
              <Option key={country} value={country}>
                {name}
              </Option>
            ))}
          </AutocompleteField>
          {!isEmpty(stateOptions) && isDefined(stateOptions) && (
            <AutocompleteField name="state" label={t('common:state')} isRequired>
              {stateOptions.map(({ state, name }) => (
                <Option key={state} value={state}>
                  {name}
                </Option>
              ))}
            </AutocompleteField>
          )}
          <InputField label={t('common:vat_number')} isRequired {...register('vatNumber')} />
        </FormGrid>
      </FormFieldset>
    </Form>
  );
};
