/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;
const DEAFAULT_COLOR = '#848484';

export const Market = ({ color = DEAFAULT_COLOR, width = ORIGINAL_WIDTH }: IconComponentProps) => {
  return (
    <svg width={width} height={width} fill="none">
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M4.583 10.79v4.663a.547.547 0 0 0 .542.545h9.75a.54.54 0 0 0 .542-.545v-4.664M7.833 7.816v1.091m0 0A2.19 2.19 0 0 1 7.2 10.45a2.159 2.159 0 0 1-3.064 0A2.19 2.19 0 0 1 3.5 8.907v-1.09m4.333 1.09c0 .579.228 1.134.635 1.543a2.159 2.159 0 0 0 3.064 0 2.19 2.19 0 0 0 .634-1.543m0-1.09v1.09m0 0c0 .579.229 1.134.635 1.543a2.159 2.159 0 0 0 3.064 0 2.19 2.19 0 0 0 .635-1.543v-1.09M4.99 4h10.02a.546.546 0 0 1 .522.395l.968 3.423h-13l.968-3.423A.552.552 0 0 1 4.99 4Z"
      />
    </svg>
  );
};
