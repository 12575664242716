import { ActionHandler, Cms } from '@typings';
import { omit } from 'ramda';
import { createReducer } from 'typesafe-actions';

import { fetchEditorPageDataSuccess, refreshPageDataSuccess } from '../../../ducks/pages';
import { update } from '../../../utils/update';
import { resetCms, setPreviewData } from '../general';

import { setPageMeta } from './actions';

type State = {
  data: Nullable<Cms.Page>;
};

const initialState: State = {
  data: null,
};

const handleResetPageMeta: ActionHandler<State, typeof resetCms> = () => initialState;

const handleFetchEditorPageDataSuccess: ActionHandler<State, typeof fetchEditorPageDataSuccess> = (state, action) =>
  update(state, {
    data: omit(['sections', 'groups', 'blocks'], action.payload.data),
  });

const handleRefreshPageDataSuccess: ActionHandler<State, typeof refreshPageDataSuccess> = (state, action) =>
  update(state, {
    data: omit(['sections', 'groups', 'blocks'], action.payload),
  });

const handleSetPreviewData: ActionHandler<State, typeof setPreviewData> = (state, action) =>
  update(state, {
    data: omit(['sections', 'groups', 'blocks'], action.payload.data),
  });

const handleSetPageMeta: ActionHandler<State, typeof setPageMeta> = (state, action) =>
  update(state, {
    data: action.payload,
  });

export default createReducer<State, AppAction>(initialState)
  .handleAction(fetchEditorPageDataSuccess, handleFetchEditorPageDataSuccess)
  .handleAction(refreshPageDataSuccess, handleRefreshPageDataSuccess)
  .handleAction(setPreviewData, handleSetPreviewData)
  .handleAction(resetCms, handleResetPageMeta)
  .handleAction(setPageMeta, handleSetPageMeta);
