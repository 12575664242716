import { Id } from '@typings';
import { Cms } from '@typings/@centra';
import React from 'react';
import { useSelector } from 'react-redux';

import { getManualLayoutEditorBlockTypeToSelect, getManualLayoutEditorModels } from '../../../../ducks/cms/ui';
import { getProductSlotsCountInModels } from '../../../../logic/cms/changeGroup';
import { isDefined, isNull } from '../../../../utils/is';
import { ManualLayoutEditorContext } from '../../context/ManualLayoutEditorContext';
import { SelectorsContext } from '../../context/SelectorsContext';

import { ManualLayoutSelector } from './ManualLayoutSelector';
import styles from './ManualLayoutSelector.module.scss';

interface Props {
  blockId: Id;
  productId: string;
}

export const ManualProductLayoutSelector = ({ blockId, productId }: Props) => {
  const { setManualLayoutChangeData, manualLayoutChangeData } = React.useContext(ManualLayoutEditorContext);
  const { getBlockById } = React.useContext(SelectorsContext);
  const models = useSelector(getManualLayoutEditorModels);
  const blockData = useSelector(getBlockById(blockId));
  const productSlotsCount = getProductSlotsCountInModels(models);
  const blockTypeToSelect = useSelector(getManualLayoutEditorBlockTypeToSelect);
  const [productsData] = Object.values(manualLayoutChangeData) as Cms.ProductBlock[];

  const areAllRequiredElementsSelected = React.useMemo(() => {
    if (!isDefined(productsData)) {
      return false;
    }

    return productsData.settings.products.length === productSlotsCount;
  }, [productSlotsCount, productsData]);

  const isDisabled = React.useMemo(() => {
    if (!isDefined(blockData)) {
      return false;
    }

    return blockTypeToSelect !== blockData.blockType;
  }, [blockTypeToSelect, blockData]);

  const isSelected = React.useMemo(() => {
    if (!isDefined(productsData)) {
      return false;
    }

    return productsData.settings.products.includes(productId);
  }, [productId, productsData]);

  const onClick = () => {
    if (isDisabled || isNull(blockData)) {
      return;
    }

    if (isSelected && isDefined(productsData)) {
      setManualLayoutChangeData(data => {
        const updatedProducts = productsData.settings.products.filter(id => id !== productId);
        const updatedBlockData = {
          ...blockData,
          settings: { ...blockData.settings, products: updatedProducts },
        };

        return { ...data, [blockId]: updatedBlockData as Cms.ProductBlock };
      });

      return;
    }

    if (areAllRequiredElementsSelected) {
      return;
    }

    setManualLayoutChangeData(data => {
      const updatedProducts = isDefined(productsData) ? [...productsData.settings.products, productId] : [productId];
      const updatedBlockData = {
        ...blockData,
        settings: { ...blockData.settings, products: updatedProducts },
      };

      return { ...data, [blockId]: updatedBlockData as Cms.ProductBlock };
    });
  };

  return (
    <ManualLayoutSelector
      areAllRequiredElementsSelected={areAllRequiredElementsSelected}
      classNames={styles.productWrapper}
      onClick={onClick}
      isSelected={isSelected}
      testId="manualProductLayoutSelector"
    />
  );
};
