import { AccountMarket, PriceList } from '@typings';
import { useTranslation } from 'react-i18next';

import { Input } from '../../../various/Fields/Input';
import { FieldWrapper, FormGrid } from '../../../various/Form';
import Heading from '../../../various/Heading';
import { SectionHeadingWrapper } from '../../../various/SectionHeading';

import styles from './AccountInformation.module.scss';

interface Props {
  name: string;
  pricelist: PriceList | undefined;
  market: AccountMarket | undefined;
  isLoading?: boolean;
}

export const AccountInformation = ({ name, pricelist, market, isLoading = false }: Props) => {
  const { t } = useTranslation(['accounts', 'common']);

  return (
    <>
      <SectionHeadingWrapper>
        <Heading title={t('accounts:account_information')} variant={['h2', 'black']} className={styles.heading} />
      </SectionHeadingWrapper>
      <FormGrid cols={2} colGap="xlarge">
        <FieldWrapper label={t('accounts:account_name')}>
          <Input size="large" placeholder={t('accounts:account_name')} value={name} isLoading={isLoading} isDisabled />
        </FieldWrapper>
        <FieldWrapper label={t('common:market')}>
          <Input size="large" placeholder={t('common:market')} value={market?.name ?? ''} isLoading={isLoading} isDisabled />
        </FieldWrapper>
        <FieldWrapper label={t('common:pricelist')}>
          <Input size="large" placeholder={t('common:pricelist')} value={pricelist?.name ?? ''} isLoading={isLoading} isDisabled />
        </FieldWrapper>
      </FormGrid>
    </>
  );
};
