import cx from 'classnames';
import React from 'react';
import { useHover } from 'react-laag';
import { ArrowProps } from 'react-laag/dist/Arrow';
import { PlacementType } from 'react-laag/dist/PlacementType';

import { usePopover } from '../../../utils/hooks/usePopover';
import { isDefined } from '../../../utils/is';
import { FocusTrap } from '../../various/FocusTrap';

import styles from './Popover.module.scss';
import { PopoverSheet } from './PopoverSheet';

const HOVER_DELAY = 100;
const DEFAULT_TRIGGER_OFFSET = 10;
const DEFAULT_POSSIBLE_PLACEMENTS: PlacementType[] = ['top-start', 'top-center', 'top-end', 'bottom-start', 'bottom-center', 'bottom-end'];

interface Props {
  arrowProps?: ArrowProps;
  className?: string;
  content: React.ReactNode;
  isContentHoverable?: boolean;
  overflowContainer?: boolean;
  placement?: PlacementType;
  possiblePlacements?: PlacementType[];
  shouldReturnFocusAfterClose?: boolean;
  snap?: boolean;
  triggerClassName?: string;
  triggerOffset?: number;
  visible?: boolean;
  onVisibleChange?: (visible: boolean) => void;
  containerOffset?: number;
  onParentClose?: () => void;
}

export const Popover = ({
  children,
  className,
  content,
  isContentHoverable = true,
  overflowContainer,
  placement = DEFAULT_POSSIBLE_PLACEMENTS[0],
  possiblePlacements = DEFAULT_POSSIBLE_PLACEMENTS,
  shouldReturnFocusAfterClose = true,
  snap = false,
  triggerClassName,
  triggerOffset = DEFAULT_TRIGGER_OFFSET,
  visible,
  onVisibleChange,
  containerOffset,
  onParentClose,
}: React.WithChildren<Props>) => {
  const [isOver, hoverProps] = useHover({
    delayEnter: HOVER_DELAY,
    delayLeave: HOVER_DELAY,
    hideOnScroll: false,
  });

  const isHoverable = !isDefined(visible);
  const isOpen = visible ?? isOver;

  const { layerProps, triggerProps, layerSide, renderLayer } = usePopover({
    escapeHandlerEnabled: !!visible,
    layerProps: {
      containerOffset,
      isOpen,
      onParentClose,
      overflowContainer,
      placement,
      possiblePlacements,
      snap,
      triggerOffset,
    },
    onVisibleChange,
  });

  return (
    <>
      <span className={cx(styles.trigger, triggerClassName)} {...triggerProps} {...(isHoverable && hoverProps)}>
        {children}
      </span>
      {renderLayer(
        <PopoverSheet
          {...layerProps}
          {...(isHoverable && isContentHoverable && hoverProps)}
          isOpen={isOpen}
          className={className}
          layerSide={layerSide}
        >
          <FocusTrap shouldReturnFocus={shouldReturnFocusAfterClose}>{content}</FocusTrap>
        </PopoverSheet>,
      )}
    </>
  );
};
