import { Cms } from '@centra';

const oneRowNo1: Cms.BlockModel[] = [
  {
    blockType: 'content',
    position: {
      desktop: {
        spanX: 6,
        spanY: 1,
        startX: 1,
        startY: 1,
      },
      mobile: {
        spanX: 2,
        spanY: 1,
        startX: 1,
        startY: 1,
      },
      tablet: {
        spanX: 3,
        spanY: 1,
        startX: 1,
        startY: 1,
      },
    },
  },
];

const oneRowNo2: Cms.BlockModel[] = [
  {
    blockType: 'product',
    position: {
      desktop: {
        spanX: 6,
        spanY: 1,
        startX: 1,
        startY: 1,
      },
      mobile: {
        spanX: 2,
        spanY: 3,
        startX: 1,
        startY: 1,
      },
      tablet: {
        spanX: 3,
        spanY: 2,
        startX: 1,
        startY: 1,
      },
    },
  },
];

const oneRowNo3: Cms.BlockModel[] = [
  {
    blockType: 'content',
    position: {
      desktop: {
        spanX: 3,
        spanY: 1,
        startX: 1,
        startY: 1,
      },
      mobile: {
        spanX: 2,
        spanY: 2,
        startX: 1,
        startY: 1,
      },
      tablet: {
        spanX: 3,
        spanY: 1,
        startX: 1,
        startY: 1,
      },
    },
  },
  {
    blockType: 'product',
    position: {
      desktop: {
        spanX: 3,
        spanY: 1,
        startX: 4,
        startY: 1,
      },
      mobile: {
        spanX: 2,
        spanY: 2,
        startX: 1,
        startY: 3,
      },
      tablet: {
        spanX: 3,
        spanY: 1,
        startX: 1,
        startY: 2,
      },
    },
  },
];

const oneRowNo4: Cms.BlockModel[] = [
  {
    blockType: 'product',
    position: {
      desktop: {
        spanX: 3,
        spanY: 1,
        startX: 1,
        startY: 1,
      },
      mobile: {
        spanX: 2,
        spanY: 2,
        startX: 1,
        startY: 1,
      },
      tablet: {
        spanX: 3,
        spanY: 1,
        startX: 1,
        startY: 1,
      },
    },
  },
  {
    blockType: 'content',
    position: {
      desktop: {
        spanX: 3,
        spanY: 1,
        startX: 4,
        startY: 1,
      },
      mobile: {
        spanX: 2,
        spanY: 2,
        startX: 1,
        startY: 3,
      },
      tablet: {
        spanX: 3,
        spanY: 1,
        startX: 1,
        startY: 2,
      },
    },
  },
];

const oneRowNo5: Cms.BlockModel[] = [
  {
    blockType: 'content',
    position: {
      desktop: {
        spanX: 3,
        spanY: 1,
        startX: 1,
        startY: 1,
      },
      mobile: {
        spanX: 2,
        spanY: 1,
        startX: 1,
        startY: 1,
      },
      tablet: {
        spanX: 3,
        spanY: 1,
        startX: 1,
        startY: 1,
      },
    },
  },
  {
    blockType: 'content',
    position: {
      desktop: {
        spanX: 3,
        spanY: 1,
        startX: 4,
        startY: 1,
      },
      mobile: {
        spanX: 2,
        spanY: 1,
        startX: 1,
        startY: 2,
      },
      tablet: {
        spanX: 3,
        spanY: 1,
        startX: 1,
        startY: 2,
      },
    },
  },
];

const twoRowsNo1: Cms.BlockModel[] = [
  {
    blockType: 'content',
    position: {
      desktop: {
        spanX: 6,
        spanY: 2,
        startX: 1,
        startY: 1,
      },
      mobile: {
        spanX: 2,
        spanY: 2,
        startX: 1,
        startY: 1,
      },
      tablet: {
        spanX: 3,
        spanY: 2,
        startX: 1,
        startY: 1,
      },
    },
  },
];

const twoRowsNo2: Cms.BlockModel[] = [
  {
    blockType: 'product',
    position: {
      desktop: {
        spanX: 6,
        spanY: 2,
        startX: 1,
        startY: 1,
      },
      mobile: {
        spanX: 2,
        spanY: 6,
        startX: 1,
        startY: 1,
      },
      tablet: {
        spanX: 3,
        spanY: 4,
        startX: 1,
        startY: 1,
      },
    },
  },
];

const twoRowsNo3: Cms.BlockModel[] = [
  {
    blockType: 'content',
    position: {
      desktop: {
        spanX: 3,
        spanY: 2,
        startX: 1,
        startY: 1,
      },
      mobile: {
        spanX: 2,
        spanY: 2,
        startX: 1,
        startY: 1,
      },
      tablet: {
        spanX: 3,
        spanY: 2,
        startX: 1,
        startY: 1,
      },
    },
  },
  {
    blockType: 'product',
    position: {
      desktop: {
        spanX: 3,
        spanY: 2,
        startX: 4,
        startY: 1,
      },
      mobile: {
        spanX: 2,
        spanY: 3,
        startX: 1,
        startY: 3,
      },
      tablet: {
        spanX: 3,
        spanY: 2,
        startX: 1,
        startY: 3,
      },
    },
  },
];

const twoRowsNo4: Cms.BlockModel[] = [
  {
    blockType: 'product',
    position: {
      desktop: {
        spanX: 3,
        spanY: 2,
        startX: 1,
        startY: 1,
      },
      mobile: {
        spanX: 2,
        spanY: 3,
        startX: 1,
        startY: 1,
      },
      tablet: {
        spanX: 3,
        spanY: 2,
        startX: 1,
        startY: 1,
      },
    },
  },
  {
    blockType: 'content',
    position: {
      desktop: {
        spanX: 3,
        spanY: 2,
        startX: 4,
        startY: 1,
      },
      mobile: {
        spanX: 2,
        spanY: 2,
        startX: 1,
        startY: 4,
      },
      tablet: {
        spanX: 3,
        spanY: 2,
        startX: 1,
        startY: 3,
      },
    },
  },
];

const twoRowsNo5: Cms.BlockModel[] = [
  {
    blockType: 'content',
    position: {
      desktop: {
        spanX: 3,
        spanY: 2,
        startX: 1,
        startY: 1,
      },
      mobile: {
        spanX: 2,
        spanY: 2,
        startX: 1,
        startY: 1,
      },
      tablet: {
        spanX: 3,
        spanY: 2,
        startX: 1,
        startY: 1,
      },
    },
  },
  {
    blockType: 'content',
    position: {
      desktop: {
        spanX: 3,
        spanY: 2,
        startX: 4,
        startY: 1,
      },
      mobile: {
        spanX: 2,
        spanY: 2,
        startX: 1,
        startY: 3,
      },
      tablet: {
        spanX: 3,
        spanY: 2,
        startX: 1,
        startY: 3,
      },
    },
  },
];

const twoRowsNo6: Cms.BlockModel[] = [
  {
    blockType: 'content',
    position: {
      desktop: {
        spanX: 3,
        spanY: 2,
        startX: 1,
        startY: 1,
      },
      mobile: {
        spanX: 2,
        spanY: 2,
        startX: 1,
        startY: 1,
      },
      tablet: {
        spanX: 3,
        spanY: 2,
        startX: 1,
        startY: 1,
      },
    },
  },
  {
    blockType: 'content',
    position: {
      desktop: {
        spanX: 3,
        spanY: 1,
        startX: 4,
        startY: 1,
      },
      mobile: {
        spanX: 2,
        spanY: 1,
        startX: 1,
        startY: 3,
      },
      tablet: {
        spanX: 3,
        spanY: 1,
        startX: 1,
        startY: 3,
      },
    },
  },
  {
    blockType: 'content',
    position: {
      desktop: {
        spanX: 3,
        spanY: 1,
        startX: 4,
        startY: 2,
      },
      mobile: {
        spanX: 2,
        spanY: 1,
        startX: 1,
        startY: 4,
      },
      tablet: {
        spanX: 3,
        spanY: 1,
        startX: 1,
        startY: 4,
      },
    },
  },
];

const twoRowsNo7: Cms.BlockModel[] = [
  {
    blockType: 'content',
    position: {
      desktop: {
        spanX: 3,
        spanY: 1,
        startX: 1,
        startY: 1,
      },
      mobile: {
        spanX: 2,
        spanY: 1,
        startX: 1,
        startY: 1,
      },
      tablet: {
        spanX: 3,
        spanY: 1,
        startX: 1,
        startY: 1,
      },
    },
  },
  {
    blockType: 'content',
    position: {
      desktop: {
        spanX: 3,
        spanY: 1,
        startX: 1,
        startY: 2,
      },
      mobile: {
        spanX: 2,
        spanY: 1,
        startX: 1,
        startY: 2,
      },
      tablet: {
        spanX: 3,
        spanY: 1,
        startX: 1,
        startY: 2,
      },
    },
  },
  {
    blockType: 'content',
    position: {
      desktop: {
        spanX: 3,
        spanY: 2,
        startX: 4,
        startY: 1,
      },
      mobile: {
        spanX: 2,
        spanY: 2,
        startX: 1,
        startY: 3,
      },
      tablet: {
        spanX: 3,
        spanY: 2,
        startX: 1,
        startY: 3,
      },
    },
  },
];

const twoRowsNo8: Cms.BlockModel[] = [
  {
    blockType: 'content',
    position: {
      desktop: {
        spanX: 3,
        spanY: 2,
        startX: 1,
        startY: 1,
      },
      mobile: {
        spanX: 2,
        spanY: 2,
        startX: 1,
        startY: 1,
      },
      tablet: {
        spanX: 3,
        spanY: 2,
        startX: 1,
        startY: 1,
      },
    },
  },
  {
    blockType: 'content',
    position: {
      desktop: {
        spanX: 3,
        spanY: 1,
        startX: 4,
        startY: 1,
      },
      mobile: {
        spanX: 2,
        spanY: 1,
        startX: 1,
        startY: 3,
      },
      tablet: {
        spanX: 3,
        spanY: 1,
        startX: 1,
        startY: 3,
      },
    },
  },
  {
    blockType: 'product',
    position: {
      desktop: {
        spanX: 3,
        spanY: 1,
        startX: 4,
        startY: 2,
      },
      mobile: {
        spanX: 2,
        spanY: 1,
        startX: 1,
        startY: 4,
      },
      tablet: {
        spanX: 3,
        spanY: 1,
        startX: 1,
        startY: 4,
      },
    },
  },
];

const twoRowsNo9: Cms.BlockModel[] = [
  {
    blockType: 'content',
    position: {
      desktop: {
        spanX: 3,
        spanY: 2,
        startX: 1,
        startY: 1,
      },
      mobile: {
        spanX: 2,
        spanY: 2,
        startX: 1,
        startY: 1,
      },
      tablet: {
        spanX: 3,
        spanY: 2,
        startX: 1,
        startY: 1,
      },
    },
  },
  {
    blockType: 'product',
    position: {
      desktop: {
        spanX: 3,
        spanY: 1,
        startX: 4,
        startY: 1,
      },
      mobile: {
        spanX: 2,
        spanY: 1,
        startX: 1,
        startY: 3,
      },
      tablet: {
        spanX: 3,
        spanY: 1,
        startX: 1,
        startY: 3,
      },
    },
  },
  {
    blockType: 'content',
    position: {
      desktop: {
        spanX: 3,
        spanY: 1,
        startX: 4,
        startY: 2,
      },
      mobile: {
        spanX: 2,
        spanY: 1,
        startX: 1,
        startY: 4,
      },
      tablet: {
        spanX: 3,
        spanY: 1,
        startX: 1,
        startY: 4,
      },
    },
  },
];

const twoRowsNo10: Cms.BlockModel[] = [
  {
    blockType: 'content',
    position: {
      desktop: {
        spanX: 3,
        spanY: 1,
        startX: 1,
        startY: 1,
      },
      mobile: {
        spanX: 2,
        spanY: 1,
        startX: 1,
        startY: 1,
      },
      tablet: {
        spanX: 3,
        spanY: 1,
        startX: 1,
        startY: 1,
      },
    },
  },
  {
    blockType: 'product',
    position: {
      desktop: {
        spanX: 3,
        spanY: 1,
        startX: 1,
        startY: 2,
      },
      mobile: {
        spanX: 2,
        spanY: 2,
        startX: 1,
        startY: 3,
      },
      tablet: {
        spanX: 3,
        spanY: 1,
        startX: 1,
        startY: 3,
      },
    },
  },
  {
    blockType: 'content',
    position: {
      desktop: {
        spanX: 3,
        spanY: 2,
        startX: 4,
        startY: 1,
      },
      mobile: {
        spanX: 2,
        spanY: 2,
        startX: 1,
        startY: 5,
      },
      tablet: {
        spanX: 3,
        spanY: 2,
        startX: 1,
        startY: 4,
      },
    },
  },
];

const twoRowsNo11: Cms.BlockModel[] = [
  {
    blockType: 'product',
    position: {
      desktop: {
        spanX: 3,
        spanY: 1,
        startX: 1,
        startY: 1,
      },
      mobile: {
        spanX: 2,
        spanY: 2,
        startX: 1,
        startY: 1,
      },
      tablet: {
        spanX: 3,
        spanY: 1,
        startX: 1,
        startY: 1,
      },
    },
  },
  {
    blockType: 'content',
    position: {
      desktop: {
        spanX: 3,
        spanY: 1,
        startX: 1,
        startY: 2,
      },
      mobile: {
        spanX: 2,
        spanY: 1,
        startX: 1,
        startY: 3,
      },
      tablet: {
        spanX: 3,
        spanY: 1,
        startX: 1,
        startY: 2,
      },
    },
  },
  {
    blockType: 'content',
    position: {
      desktop: {
        spanX: 3,
        spanY: 2,
        startX: 4,
        startY: 1,
      },
      mobile: {
        spanX: 2,
        spanY: 2,
        startX: 1,
        startY: 4,
      },
      tablet: {
        spanX: 3,
        spanY: 2,
        startX: 1,
        startY: 3,
      },
    },
  },
];

export const sectionCategories = [
  {
    i18nKey: 'cms:product_block_other',
    templates: [[oneRowNo2], [twoRowsNo2]],
  },
  {
    i18nKey: 'cms:content_block_other',
    templates: [
      [oneRowNo1, oneRowNo5],
      [twoRowsNo1, twoRowsNo5, twoRowsNo7, twoRowsNo6],
    ],
  },
  {
    i18nKey: 'cms:mixed_block_other',
    templates: [
      [oneRowNo4, oneRowNo3],
      [twoRowsNo4, twoRowsNo3, twoRowsNo10, twoRowsNo11, twoRowsNo9, twoRowsNo8],
    ],
  },
] as const;
