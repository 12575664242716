import { Tables } from '@typings';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { isDefined } from '../is';

const PARAM_FALLBACK = undefined;
const PROPERTY = 'sort';

export type SortOrder = { field: string; order: Tables.SortOrder }[] | typeof PARAM_FALLBACK;

const SORT_KEYS = ['orderNumber', 'accountName', 'buyerName', 'poNumber', 'totalPrice', 'units'];
const SORT_ORDERS = ['asc', 'desc'];
const SORT_KEYS_MAP: Record<string, Maybe<string>> = {
  account: 'accountName',
  buyer: 'buyerName',
  itemsCount: 'units',
  orderNumber: 'orderNumber',
  poNumber: 'poNumber',
  total: 'totalPrice',
};

const isSortValid = (field: string, order: string) => {
  return SORT_KEYS.includes(field) && SORT_ORDERS.includes(order);
};

const getFormattedSortOrder = (newState: SortOrder) => {
  if (!isDefined(newState) || !isDefined(newState[0])) {
    return PARAM_FALLBACK;
  }

  const fieldName = SORT_KEYS_MAP[newState[0].field];
  const [{ order }] = newState;

  if (!isDefined(fieldName)) {
    return PARAM_FALLBACK;
  }

  return `${fieldName}.${order}`;
};

export const getFieldBySortKey = (sortKey: string) => {
  return Object.keys(SORT_KEYS_MAP).find(key => SORT_KEYS_MAP[key] === sortKey) ?? '';
};

export const useOrdersSorting = (property = PROPERTY) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamValue = searchParams.get(property) ?? PARAM_FALLBACK;

  const propertyValue = React.useMemo(() => {
    const sortValue = searchParamValue?.split('.');
    const [field, order] = sortValue ?? [];

    return isDefined(field) && isDefined(order) && isSortValid(field, order) ?
        [{ field, order: order as Tables.SortOrder }]
      : PARAM_FALLBACK;
  }, [searchParamValue]);

  const setSortOrder = React.useCallback(
    (newSortOrder: SortOrder) => {
      const sort = getFormattedSortOrder(newSortOrder);

      setSearchParams(previousParams => {
        const newParams = new URLSearchParams(previousParams);

        if (isDefined(sort)) {
          newParams.set(property, sort);
        } else {
          newParams.delete(property);
        }

        return newParams;
      });
    },
    [property, setSearchParams],
  );

  return [propertyValue, setSortOrder] as const;
};
