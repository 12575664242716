import { isDefined, isNull } from '../utils/is';
import { storage } from '../utils/persistence';

export const parseItem = (item: Nullable<string>) => {
  if (isNull(item)) {
    return null;
  }

  try {
    return JSON.parse(item);
  } catch {
    return null;
  }
};

const transformItems = (items: Record<string, Nullable<string>>, transformer: (value: Nullable<string>) => Nullable<string>) => {
  const { token, ...rest } = items;

  return {
    ...Object.entries(rest)
      .map<[string, Nullable<string>]>(([key, value]) => [key, transformer(value)])
      .reduce<Record<string, Nullable<string>>>((cumulus, [key, value]) => ({ ...cumulus, [key]: value }), {}),
    token,
  };
};

const getData = () => {
  const token = storage.getItem('token');
  const columns = storage.getItem('columns');

  return {
    columns,
    token,
  };
};

const getItem = (item: string) => {
  return storage.getItem(item);
};

/**
 *
 */
const setData = (items: Record<string, string>) => {
  for (const [key, value] of Object.entries(items)) {
    storage.setItem(key, value);
  }
};

/**
 *
 */
const clear = () => {
  storage.clear();
};

const clearExcept = (persistedKeys: string[]) => {
  const persistedData: [string, Nullable<string>][] = persistedKeys.map(key => {
    return [key, storage.getItem(key)];
  });

  storage.clear();

  persistedData.forEach(([key, data]) => {
    if (isDefined(data)) {
      storage.setItem(key, data);
    }
  });
};

const removeItem = (item: string) => {
  storage.removeItem(item);
};

const setItem = (key: string, item: string) => {
  storage.setItem(key, item);
};

export default {
  clear,
  clearExcept,
  getData,
  getItem,
  parseItem,
  removeItem,
  setData,
  setItem,
  transformItems,
};
