import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import Icon, { IconColor, IconType } from '../../Icon/Icon';
import { useModalContext } from '../context/ModalContext';

import styles from './ModalCloseButton.module.scss';

interface ModalCloseButtonProps {
  dataTestId?: string;
}

export const ModalCloseButton = ({ dataTestId = 'closeIcon' }: ModalCloseButtonProps) => {
  const { t } = useTranslation(['common']);
  const { onClose } = useModalContext();

  return (
    <button aria-label={t('common:close_modal')} className={cx(styles.close)} onClick={onClose} data-testid={dataTestId}>
      <Icon type={IconType.Cancel} color={IconColor.Gray} className={styles.closeIcon} />
    </button>
  );
};
