import { Cms } from '@typings';
import React from 'react';

import { isDefined } from '../../../utils/is';

import styles from './MediaPositioner.module.scss';

interface Props {
  mediumType: Cms.MediaType;
  mediumSrc: string;
  imageDimensions: Cms.ImageDimensions;
  mediumWrapperRef?: React.MutableRefObject<HTMLDivElement | null>;
  updateMediumSize?: (size: Cms.MediumSize) => void;
}

export const Medium = ({ mediumWrapperRef, mediumSrc, mediumType, imageDimensions, updateMediumSize }: Props) => {
  const mediumRef = React.useRef<HTMLImageElement | HTMLVideoElement | null>(null);

  const setSize = React.useCallback(
    (event: React.SyntheticEvent) => {
      if (!isDefined(updateMediumSize) || !isDefined(mediumRef.current)) {
        return;
      }

      const { naturalHeight, naturalWidth } = event.target as HTMLImageElement;
      const { videoHeight, videoWidth } = event.target as HTMLVideoElement;
      updateMediumSize({
        height: naturalHeight || videoHeight,
        width: naturalWidth || videoWidth,
      });
    },
    [updateMediumSize, mediumRef],
  );

  return (
    <div ref={mediumWrapperRef} style={{ ...imageDimensions }} className={styles.medium}>
      {mediumType === 'image' ?
        <img ref={mediumRef as React.MutableRefObject<HTMLImageElement>} src={mediumSrc} alt="" role="presentation" onLoad={setSize} />
      : <video ref={mediumRef as React.MutableRefObject<HTMLVideoElement>} src={mediumSrc} loop muted autoPlay onLoadedMetadata={setSize} />
      }
    </div>
  );
};
