/**
 * Type guard factory used for runtime type checking.
 *
 * @see https://github.com/HyphnKnight/ts-is
 */
export type EnsureKey<T extends { [prop in K]?: unknown }, K extends keyof T> = T & Record<K, Pick<T, K>>;

export const is =
  <T>(validatorFn: (candidate: Unrestricted) => boolean) =>
  (candidate: Unrestricted): candidate is T =>
    validatorFn(candidate);

export function isDefined<T>(value: Maybe<T>): value is T {
  return value !== undefined && value !== null;
}

export function isNull(candidate: Unrestricted): candidate is null {
  return candidate === null;
}

export const ensureKey = <T extends { [prop in K]?: unknown }, K extends keyof T>(candidate: T, key: K): candidate is EnsureKey<T, K> => {
  return key in candidate && isDefined(candidate[key]);
};

export function isError(candidate: Unrestricted): candidate is Error {
  return candidate instanceof Error;
}
