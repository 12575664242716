import { Invoice } from '@typings';
import { pick } from 'ramda';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useInvoiceDetails } from '../../../services/hooks/invoice/useInvoiceDetails';
import { isDefined } from '../../../utils/is';
import DefaultLoader from '../../various/loaders/DefaultLoader';
import { Notification } from '../../various/Notification/Notification';
import { Wrapper, WrapperSize } from '../../various/Wrapper';
import { InvoiceInfo } from '../InvoiceInfo';
import { InvoicePaymentInfo } from '../InvoicePaymentInfo';

// This page forces pending state due to inability to get it from the backend.
export const InvoicePendingPage = () => {
  const { invoiceId, invoiceHash } = useParams<Invoice.InvoiceIdentifiers>();
  const { data, isLoading } = useInvoiceDetails({ invoiceHash, invoiceId });
  const { t } = useTranslation(['invoices']);

  if (!isDefined(data) || !isDefined(invoiceId) || !isDefined(invoiceHash)) {
    return (
      <Wrapper size={WrapperSize.LARGE}>
        {isLoading ?
          <DefaultLoader overlay />
        : <Notification>
            <p>{t('invoices:failed_to_load_invoice')}</p>
          </Notification>
        }
      </Wrapper>
    );
  }

  const invoice: Invoice.Base = {
    ...pick(['createdAt', 'dueDate', 'invoice', 'invoiceNumber'], data),
    status: 'Pending',
  };

  return (
    <Wrapper size={WrapperSize.LARGE}>
      <InvoicePaymentInfo invoice={invoice} />
      <section>
        <InvoiceInfo invoice={invoice} invoiceIdentifiers={{ invoiceHash, invoiceId }} />
      </section>
    </Wrapper>
  );
};
