import { Translations, User } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { logoutRequest as logout, requestLanguageChange } from '../../../ducks';
import { getIsAdmin, getIsBuyer, getIsSeller } from '../../../logic/User';
import { paths } from '../../../paths';
import { useTranslationsConfig } from '../../../utils/hooks';
import { isEmpty } from '../../../utils/isEmpty';
import { renderSentryMaskedText } from '../../../utils/monitoring';
import Icon, { IconType } from '../../various/Icon';
import { PopoverMenu } from '../../various/PopoverMenu';
import { Tooltip } from '../../various/Tooltip';

import styles from './AccountDropdown.module.scss';

const INITIALS_LIMIT = 2;
const ARROW_ROTATION = 180;

interface Props {
  user: User;
}

export const AccountDropdown = ({ user }: Props) => {
  const dispatch = useDispatch();
  const { currentLanguageCode, availableLanguages, areMultipleLanguagesAvailable } = useTranslationsConfig();
  const { t } = useTranslation(['common', 'accounts', 'login']);
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

  const handleLogout = React.useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const changeLanguage = React.useCallback(
    (lang: Translations.SupportedLanguagesCodes) => dispatch(requestLanguageChange(lang)),
    [dispatch],
  );

  const handleDropdownClick = React.useCallback(
    (isOpen: boolean) => () => {
      setIsDropdownOpen(isOpen);
    },
    [setIsDropdownOpen],
  );

  const initialsSource = !isEmpty(user.name) ? user.name : user.email;

  const initials = initialsSource
    .split(' ')
    .slice(0, INITIALS_LIMIT)
    .map(word => word[0])
    .join('')
    .toUpperCase();

  const initialsClassName = cx(styles.initials, {
    [styles.admin]: getIsAdmin(user),
    [styles.seller]: getIsSeller(user) && !getIsAdmin(user),
    [styles.buyer]: getIsBuyer(user),
  });

  const languages = React.useMemo(
    () => ({
      icon: IconType.Language,
      items: availableLanguages.map(lang => ({ key: lang.code, label: lang.label })),
      key: 'language',
      label: t('common:language_one'),
      onChange: changeLanguage,
      selectedKey: currentLanguageCode,
    }),
    [changeLanguage, currentLanguageCode, availableLanguages, t],
  );

  const menuItems = React.useMemo(
    () => [
      {
        icon: IconType.Account,
        key: 'account',
        label: t('accounts:my_account'),
        subLabel: !isEmpty(user.name) ? renderSentryMaskedText(user.name) : undefined,
        to: paths.ACCOUNT,
      },
      ...(areMultipleLanguagesAvailable ? [languages] : []),
      {
        key: 'logout-separator',
      },
      {
        icon: IconType.Logout,
        key: 'logout',
        label: t('login:log_out'),
        onClick: handleLogout,
      },
    ],
    [handleLogout, languages, areMultipleLanguagesAvailable, t, user.name],
  );

  const tooltipVisibility = isDropdownOpen ? false : undefined;

  return (
    <PopoverMenu
      items={menuItems}
      name="Account"
      targetOffset={4}
      overflowContainer
      containerClassName={styles.popover}
      onOpen={handleDropdownClick(true)}
      onClose={handleDropdownClick(false)}
    >
      <div className={styles.accountDropdown}>
        <Tooltip
          visible={tooltipVisibility}
          placement="bottom-end"
          disableOnTouchScreen
          content={
            <div data-sentry-mask>
              {user.access !== 'seller' && !isEmpty(user.account) && (
                <>
                  {user.account}
                  <br />
                </>
              )}
              {!isEmpty(user.name) && (
                <>
                  {user.name}
                  <br />
                </>
              )}
              {user.email}
            </div>
          }
        >
          <div data-sentry-mask className={initialsClassName}>
            {initials}
          </div>
        </Tooltip>
        <Icon type={IconType.ArrowSharp} className={styles.arrow} rotation={isDropdownOpen ? ARROW_ROTATION : 0} />
      </div>
    </PopoverMenu>
  );
};
