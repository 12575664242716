import { Order } from '@typings';

export const buildTransactionData = (orders: Order.Essentials[] | Order.Closed[], orderData: Order.Single) => {
  const { products } = orderData;
  if (Array.isArray(products)) {
    return;
  }

  return orders.map(order => {
    const ga4Items = orderData.order.products.map(({ product, priceEachAsNumber, items }) => {
      const quantity = items.reduce((acc, cur) => acc + cur.quantity, 0);
      const sku = products[product]?.sku ?? '';
      const name = products[product]?.name ?? '';

      return {
        currency: order.currency,
        item_id: sku,
        item_name: name,
        price: priceEachAsNumber,
        quantity,
      };
    });

    const legacyItems = ga4Items.map(ga4Item => ({
      name: ga4Item.item_name,
      price: ga4Item.price,
      quantity: ga4Item.quantity,
      sku: ga4Item.item_id,
    }));

    const legacyUniversalAnalyticsData = {
      currencyCode: order.currency,
      transactionId: order.order,
      transactionProducts: legacyItems,
      transactionTax: order.totals.grandTotalPriceTaxAsNumber,
      transactionTotal: order.totals.grandTotalWithoutTaxAsNumber,
    };

    const ga4EcommerceData = {
      ecommerce: {
        currency: order.currency,
        items: ga4Items,
        tax: order.totals.grandTotalPriceTaxAsNumber,
        transaction_id: order.order,
        value: order.totals.grandTotalWithoutTaxAsNumber,
      },
    };

    return {
      event: 'checkout order',
      ...legacyUniversalAnalyticsData,
      ...ga4EcommerceData,
    };
  });
};
