import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 28;
const ORIGINAL_HEIGHT = 16;
const PROPORTION = ORIGINAL_HEIGHT / ORIGINAL_WIDTH;
const ORIGINAL_COLOR = '#262626';

export const Hamburger = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => {
  return (
    <svg width={width} height={width * PROPORTION} viewBox="0 0 28 16" xmlns="http://www.w3.org/2000/svg">
      <path d="m0 0v1h28v-1zm0 9h28v-1h-28zm28 7v-1h-28v1z" fill={color} fillRule="evenodd" />
    </svg>
  );
};
