/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 17;
const ORIGINAL_HEIGHT = 16;
const PROPORTION = ORIGINAL_HEIGHT / ORIGINAL_WIDTH;

export const Page = ({ width = ORIGINAL_WIDTH }: IconComponentProps) => {
  return (
    <svg width={width} height={width * PROPORTION} viewBox="0 0 30 35">
      <g stroke="none">
        <path d="M28.224 1.23958C28.0417 1.16667 23.8125 0 15.5 0C7.26042 0 1.9375 1.16667 1.71875 1.20312C0.916667 1.38541 0.333333 2.07812 0.333333 2.91667V32.0833C0.333333 32.8854 0.916667 33.6146 1.71875 33.7969C1.9375 33.8333 7.26042 35 15.5 35C16.4115 35 16.7031 35 17.1771 34.9635L17.8698 34.9271C21.1146 34.9271 29.5 26.5781 29.5 23.3333V2.91667C29.5 2.15104 28.9896 1.45833 28.224 1.23958ZM6.16667 9.33333V7H23.6667V9.33333H6.16667ZM6.16667 14V11.6667H23.6667V14H6.16667ZM6.16667 18.6667V16.3333H23.6667V18.6667H6.16667ZM16.6667 31.5V25.6667C16.6667 23.8802 18.3802 22.1667 20.1667 22.1667H26C26 23.4427 24.9427 24.5 23.6667 24.5H20.1667C19.6563 24.5 19 25.1562 19 25.6667V29.1667C19 30.4427 17.8333 31.5 16.6667 31.5Z" />
      </g>
    </svg>
  );
};
