import { Product } from '@typings';
import { createAction } from 'typesafe-actions';

export const loadSingleProductRequest = createAction('productDetails/SINGLE_PRODUCT_LOAD_REQUEST')<{
  productId: Product.Id;
}>();

export const loadSingleProductSuccess = createAction('productDetails/SINGLE_PRODUCT_LOAD_SUCCESS')<
  Responses.Product & { isLookbook: boolean; scannedBarcode?: string }
>();

export const loadSingleProductFailure = createAction('productDetails/SINGLE_PRODUCT_LOAD_FAILURE')();

export const loadRelatedProductDetailsSuccess = createAction('productDetails/RELATED_PRODUCTS_LOAD_DETAILS_SUCCESS')<Product.Full[]>();

export const loadRelatedProductDetailsFailure = createAction('productDetails/RELATED_PRODUCTS_LOAD_DETAILS_FAILURE')();

export const resetAllProductDetailsData = createAction('productDetails/RESET_ALL_PRODUCT_DETAILS_DATA')();
export const clearProductDetailsData = createAction('productDetails/CLEAR_PRODUCT_DETAILS_DATA')();
