import { StringSchema } from 'yup';
import * as yup from 'yup';

import { CountriesReducer } from '../../ducks';
import i18n from '../../translation/i18n';
import { isDefined } from '../../utils/is';

export const getStateSchema = (countries: CountriesReducer) =>
  yup
    .string()
    .defined()
    .when('country', ([country]: string[], schema: StringSchema) => {
      const shouldRequire = isDefined(country) && isDefined(countries[country]?.states);

      if (!shouldRequire) {
        return schema.nullable();
      }

      return schema.required(i18n.t('validation:state_hint'));
    });
