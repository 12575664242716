import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getIsAccountEditingEnabled, getIsLoggedIn } from '../../../ducks';
import { useModalVisibility } from '../../../utils/hooks';
import { AlertAction } from '../Alert/AlertAction';

export const DefaultShippingAddressMissingAlert = () => {
  const { t } = useTranslation(['shipping', 'common']);
  const { showModal } = useModalVisibility('AddShippingCountryModal');
  const isUserLoggedIn = useSelector(getIsLoggedIn);
  const isAccountEditingEnabled = useSelector(getIsAccountEditingEnabled);

  return (
    <>
      {t('shipping:shipping_country_state_missing')}{' '}
      {isAccountEditingEnabled && isUserLoggedIn ?
        <AlertAction onClick={showModal}>{t('shipping:add_country_or_state')}</AlertAction>
      : t('common:contact_the_seller')}
    </>
  );
};
