/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;
const ORIGINAL_COLOR = '#848484';

export const EmbedUrl = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox="0 0 20 20" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0379 4.53991C12.3895 4.66448 12.5708 5.04315 12.443 5.3857L8.83008 15.0654C8.70222 15.4079 8.31354 15.5846 7.96194 15.4601C7.61034 15.3355 7.42895 14.9569 7.55681 14.6143L11.1697 4.93462C11.2976 4.59206 11.6862 4.41535 12.0379 4.53991ZM6.9075 7.37753C7.14702 7.65754 7.10818 8.0737 6.82077 8.30705L4.73558 9.99997L6.82077 11.6929C7.10818 11.9262 7.14702 12.3424 6.9075 12.6224C6.66799 12.9024 6.24084 12.9402 5.95342 12.7069L3.24375 10.507C3.0893 10.3816 3 10.1958 3 9.99997C3 9.8041 3.0893 9.61835 3.24375 9.49296L5.95342 7.29303C6.24084 7.05968 6.66799 7.09752 6.9075 7.37753ZM13.0925 7.37753C13.332 7.09752 13.7592 7.05968 14.0466 7.29303L16.7563 9.49296C16.9107 9.61835 17 9.8041 17 9.99997C17 10.1958 16.9107 10.3816 16.7563 10.507L14.0466 12.7069C13.7592 12.9402 13.332 12.9024 13.0925 12.6224C12.853 12.3424 12.8918 11.9262 13.1792 11.6929L15.2644 9.99997L13.1792 8.30705C12.8918 8.0737 12.853 7.65754 13.0925 7.37753Z"
      fill={color}
    />
  </svg>
);
