/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 6;
const ORIGINAL_HEIGHT = 25;
const ORIGINAL_COLOR = '#888A8D';
const PROPORTION = ORIGINAL_HEIGHT / ORIGINAL_WIDTH;

export const MoreVertical = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => {
  return (
    <svg width={width} height={width * PROPORTION} viewBox="0 0 6 25">
      <g transform="rotate(-90 12.5 12.5)" fillRule="nonzero" fill={color}>
        <circle cx="12.5" cy="3" r="3" />
        <ellipse cx="22" cy="3" rx="3" ry="3" />
        <circle cx="3.5" cy="3" r="3" />
      </g>
    </svg>
  );
};
