import { Cms, Translations } from '@typings';
import { useSelector } from 'react-redux';

import { CMS_PREVIEW_DATA_KEY } from '../../../constants/localStorage';
import { getCmsBlocks, getCmsGroups, getCmsSections, getPageMetaData } from '../../../ducks';
import { getAllCmsLocalizations } from '../../../ducks/cms/localizations';
import { createEditorWindowName } from '../../../logic/cms/preview';
import { composeEditorPreviewPath } from '../../composeEditorPreviewPath';
import { isDefined } from '../../is';
import { useLocalStorage } from '../useLocalStorage';

export const usePagePreview = () => {
  const pageMeta = useSelector(getPageMetaData);
  const sections = useSelector(getCmsSections);
  const groups = useSelector(getCmsGroups);
  const blocks = useSelector(getCmsBlocks);
  const localizations = useSelector(getAllCmsLocalizations);

  const [previewData, setPreviewData] = useLocalStorage<Nullable<Cms.FullPageData>>({
    asJson: true,
    defaultValue: null,
    key: CMS_PREVIEW_DATA_KEY,
  });

  const navigateToPreview = (lang: Translations.SupportedLanguagesCodes) => {
    if (!isDefined(pageMeta)) {
      return;
    }

    const pageId = pageMeta.id;
    const pagePreviewData = {
      ...pageMeta,
      blocks,
      groups,
      lang,
      localizations,
      sections,
    };

    setPreviewData(pagePreviewData);

    // eslint-disable-next-line functional/immutable-data
    window.name = createEditorWindowName(pageId);
    window.open(composeEditorPreviewPath({ lang, pageId, pathType: 'preview' }), '_newtab');
  };

  return {
    navigateToPreview,
    previewData,
    setPreviewData,
  };
};
