import React from 'react';

const TOUCH_SCREEN_QUERY = '(pointer: coarse)';

const getIsTouchScreen = () => {
  return window.matchMedia(TOUCH_SCREEN_QUERY).matches;
};

export const useIsTouchScreen = () => {
  const [isTouchScreen, setIsTouchScreen] = React.useState(getIsTouchScreen);

  React.useEffect(() => {
    const matchMedia = window.matchMedia(TOUCH_SCREEN_QUERY);

    const handleChange = (event: MediaQueryListEvent) => {
      setIsTouchScreen(event.matches);
    };

    matchMedia.addEventListener('change', handleChange);

    return () => {
      matchMedia.removeEventListener('change', handleChange);
    };
  }, []);

  return isTouchScreen;
};
