import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { compiledPaths } from '../../../paths';
import { useAccountDetails } from '../../../services/hooks/account/useAccountDetails';
import { useShippingAddresses } from '../../../services/hooks/account/useShippingAddresses';
import { isDefined } from '../../../utils/is';
import { renderSentryMaskedText } from '../../../utils/monitoring';
import { PageTitle } from '../../various/PageTitle';
import { TopBar } from '../../various/TopBar';
import { Wrapper, WrapperSize } from '../../various/Wrapper';

import { AccountInformation } from './AccountInformation';
import { Buyers } from './Buyers';
import { InvoicingAddressForm } from './InvoicingAddressForm';
import { ShippingAddresses } from './ShippingAddresses';

export const AccountDetails = () => {
  const { t } = useTranslation(['common', 'accounts', 'validation', 'buyers']);
  const { accountId } = useParams<{ accountId: string }>();
  const navigate = useNavigate();

  const { data: accountDetails, isLoading: isLoadingAccount, isError } = useAccountDetails({ accountId });
  const { data: shippingAddresses, isLoading: isLoadingShippingAddresses } = useShippingAddresses({ accountId });

  React.useEffect(() => {
    if (isError) {
      navigate(compiledPaths.ACCOUNTS({}));
    }
  }, [isError, navigate]);

  const goBackToAccounts = () => {
    navigate(compiledPaths.ACCOUNTS({}));
  };

  const buyersCount = Object.keys(accountDetails?.buyers ?? {}).length;

  if (!isDefined(accountId)) {
    return null;
  }

  const accountName = accountDetails?.name ?? '';

  return (
    <PageTitle title={`${t('accounts:account_other')} - ${accountName}`}>
      <TopBar
        title={t('accounts:account_other')}
        onClick={goBackToAccounts}
        subtitle={renderSentryMaskedText(accountName)}
        details={`${buyersCount} ${t('buyers:buyer', { count: buyersCount })}`}
      />

      <Wrapper size={WrapperSize.LARGE}>
        <section>
          <AccountInformation
            name={accountName}
            market={accountDetails?.market}
            pricelist={accountDetails?.pricelist}
            isLoading={isLoadingAccount}
          />
        </section>

        <section>
          <Buyers accountId={accountId} buyers={accountDetails?.buyers} isLoading={isLoadingAccount} />
        </section>

        <section>
          <ShippingAddresses accountId={accountId} shippingAddresses={shippingAddresses} isLoading={isLoadingShippingAddresses} />
        </section>

        {isDefined(accountDetails) && (
          <section>
            <InvoicingAddressForm accountId={accountId} address={accountDetails.billingAddress} />
          </section>
        )}
      </Wrapper>
    </PageTitle>
  );
};
