import { isDefined } from '../../../utils/is';
import { Logo, LogoType } from '../Logo';

import styles from './PaymentMethods.module.scss';

interface Props {
  label: string;
  iconType: Maybe<LogoType>;
}

export const PaymentMethodLabel = ({ label, iconType }: Props) => {
  return (
    <div className={styles.label}>
      <span>{label}</span>
      {isDefined(iconType) && <Logo type={iconType} className={styles.logoIcon} />}
    </div>
  );
};
