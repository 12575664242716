/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;
const ORIGINAL_COLOR = '#848484';

export const AlignCenter = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => (
  <svg width={width} height={width} viewBox="0 0 20 20">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.1001 3.92817L9.1 16.0719C9.1 16.5669 9.52 17 9.99995 17C10.4799 17 10.8999 16.5669 10.8999 16.0718L10.9 3.92817C10.9 3.43317 10.4801 3 10 3C9.52004 3 9.1001 3.43317 9.1001 3.92817ZM14.868 12.4801C14.9559 12.4454 15 12.3824 15 12.2912V10.8331H16.7778C16.8427 10.8331 16.8959 10.8135 16.9375 10.7745C16.9792 10.7357 17 10.6857 17 10.6249V9.37501C17 9.31421 16.9792 9.26434 16.9375 9.22529C16.8959 9.18628 16.8427 9.16669 16.7778 9.16669H14.9999V7.70858C14.9999 7.6218 14.9559 7.55885 14.8679 7.51979C14.78 7.48511 14.699 7.49601 14.6247 7.55242L11.9581 9.86325C11.9119 9.90663 11.8888 9.95871 11.8888 10.0195C11.8888 10.076 11.9119 10.1259 11.9581 10.1692L14.6247 12.4476C14.699 12.504 14.78 12.5149 14.868 12.4801ZM5.13203 7.51986C5.04412 7.55459 5 7.61763 5 7.70876V9.1669H3.22219C3.15735 9.1669 3.10408 9.18646 3.06246 9.22547C3.0208 9.26434 3 9.3143 3 9.37515V10.625C3 10.6858 3.0208 10.7357 3.06246 10.7747C3.10408 10.8137 3.15735 10.8333 3.22219 10.8333H5.0001V12.2914C5.0001 12.3782 5.04412 12.4412 5.13212 12.4802C5.22 12.5149 5.301 12.504 5.37526 12.4476L8.04186 10.1368C8.08812 10.0934 8.11121 10.0413 8.11121 9.98048C8.11121 9.92399 8.08815 9.87412 8.04186 9.83076L5.37526 7.55244C5.301 7.49602 5.22 7.48507 5.13203 7.51986Z"
      fill={color}
    />
  </svg>
);
