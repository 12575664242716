import { Id } from '@typings';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { requestOrderPaymentResultLongPolling } from '../../../ducks';
import { isDefined } from '../../../utils/is';
import { ProcessingPayment } from '../../various/ProcessingPayment';

export const PaymentResult = () => {
  const { id } = useParams<{ id: Id }>();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (isDefined(id)) {
      dispatch(requestOrderPaymentResultLongPolling(id));
    }
  }, [dispatch, id]);

  return <ProcessingPayment />;
};
