import { Overview } from '@typings/@centra';
import { useTranslation } from 'react-i18next';

import { ContentSwitcher } from '../../../various/ContentSwitcher';
import { IconType } from '../../../various/Icon';

import styles from './OverviewTable.module.scss';

interface Props {
  isPieChart: boolean;
  setChartType: (type: Overview.ChartType) => void;
  percentageBase: Overview.PercentageBase;
}

export const PercentageColumnTitle = ({ isPieChart, setChartType, percentageBase }: Props) => {
  const { t } = useTranslation(['common', 'totals', 'selectionOverview']);

  const tabs = [
    {
      icon: IconType.PieChart,
      title: t('selectionOverview:pie_chart'),
      value: 'pie',
    },
    {
      icon: IconType.BarChart,
      title: t('selectionOverview:bar_chart'),
      value: 'bar',
    },
  ];

  return (
    <div className={styles.percentageColumnTitle}>
      {t('selectionOverview:percent_of', {
        of: percentageBase === 'units' ? t('common:unit_other') : t('totals:total_one'),
      })}
      <ContentSwitcher
        groupName={t('selectionOverview:chart_view')}
        tabs={tabs}
        selectedValue={isPieChart ? 'pie' : 'bar'}
        onChange={setChartType}
        showTooltip
        variant="secondary"
      />
    </div>
  );
};
