import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import universalStyles from '../../../../../css/utilities/universal.module.scss';

import styles from './Onboarding.module.scss';

interface Props {
  items: unknown[];
  current: number;
  setCurrentPage: (index: number) => void;
}

export const Steps = ({ items, current, setCurrentPage }: Props) => {
  const { t } = useTranslation(['onboarding']);

  const handleSetPage = React.useCallback(
    (index: number) => () => {
      setCurrentPage(index);
    },
    [setCurrentPage],
  );

  return (
    <ul className={styles.stepsContainer}>
      {items.map((_, idx) => (
        <li key={idx}>
          <button className={cx(styles.step, { [styles.active]: idx === current })} onClick={handleSetPage(idx)}>
            <span className={universalStyles.srOnly}>{t('onboarding:step', { id: idx })}</span>
          </button>
        </li>
      ))}
    </ul>
  );
};
