import { useTranslation } from 'react-i18next';

import { useModalVisibility } from '../../utils/hooks';
import { ModalSize } from '../various/ModalElements';
import { ModalShell } from '../various/ModalShell';

import { CreateLookbookForm } from './CreateLookbookForm';

export const CreateLookbookModal = () => {
  const { t } = useTranslation(['common', 'validation', 'lookbook']);
  const { isModalVisible, hideModal } = useModalVisibility('CreateLookbookModal');

  return (
    <ModalShell size={ModalSize.Medium} title={t('lookbook:create_lookbook')} isOpen={isModalVisible} onClose={hideModal}>
      <CreateLookbookForm />
    </ModalShell>
  );
};
