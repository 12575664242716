import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { addSection, getPageMetaData } from '../../../ducks';
import { useGroupCopyPaste } from '../../../utils/hooks/cms/useGroupCopyPaste';
import { useSectionCopyPaste } from '../../../utils/hooks/cms/useSectionCopyPaste';
import { useIsOver } from '../../../utils/hooks/useIsOver';
import { isDefined } from '../../../utils/is';
import { IconType } from '../../various/Icon';
import { Button } from '../../various/NewButton';

import styles from './SectionButtons.module.scss';

interface Props {
  isHidden?: boolean;
  sectionIndex?: number;
}

export const SectionButtons = ({ isHidden, sectionIndex }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['cms', 'common']);
  const pageMeta = useSelector(getPageMetaData);

  const { ref, isOver } = useIsOver();

  const { hasGroupInClipboard, pasteCopiedGroup } = useGroupCopyPaste();
  const { hasSectionInClipboard, pasteCopiedSection } = useSectionCopyPaste();

  const isEmptyPage = !isDefined(sectionIndex);

  const handleAddSection = () => {
    const pageMarkets = pageMeta?.markets.map(data => data.id) ?? [];

    dispatch(
      addSection({
        insertAt: sectionIndex ?? 0,
        markets: pageMarkets,
      }),
    );
  };

  const handlePasteSection = () => {
    if (hasGroupInClipboard) {
      pasteCopiedGroup(0);
    }

    pasteCopiedSection(sectionIndex ?? 0);
  };

  const addButtonVariant = React.useMemo(() => {
    if (isEmptyPage) {
      return hasSectionInClipboard || hasGroupInClipboard ? 'bordered' : 'solid';
    }

    if (isOver) {
      return 'bordered';
    }

    return 'ghost';
  }, [hasGroupInClipboard, hasSectionInClipboard, isEmptyPage, isOver]);

  const pasteButtonVariant = React.useMemo(() => {
    return isOver || isEmptyPage ? 'solid' : 'ghost';
  }, [isEmptyPage, isOver]);

  const shouldShowPasteButton = hasSectionInClipboard || (isEmptyPage && hasGroupInClipboard);

  const classNames = cx(styles.wrapper, {
    [styles.hidden]: isHidden,
    [styles.empty]: isEmptyPage,
    [styles.hovering]: !isEmptyPage && isOver,
  });

  return (
    <div className={classNames}>
      <div />
      <Button
        size="mini"
        data-testid="addSectionButton"
        className={styles.button}
        icon={IconType.PlusCircle}
        variant={addButtonVariant}
        onClick={handleAddSection}
      >
        {t('cms:add_section')}
      </Button>
      {shouldShowPasteButton && (
        <Button
          size="mini"
          data-testid="pasteSectionButton"
          className={styles.button}
          icon={IconType.Paste}
          variant={pasteButtonVariant}
          onClick={handlePasteSection}
        >
          {t('common:paste')}
        </Button>
      )}
      <div ref={ref} className={styles.hoverArea} />
      <div className={styles.separatorWrapper}>
        <div className={styles.separator} />
      </div>
    </div>
  );
};
