import { Id } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { convertToTableFormat, isValidMeasurementChart } from '../../../../ducks/helpers';
import { setEtaVisibilityOnDesktop, setEtaVisibilityOnMobile } from '../../../../ducks/ui';
import { getUrlForLookbookProduct, getUrlForProductAndVariant, ProductUrlProps } from '../../../../logic/products';
import { createAbsoluteUrl } from '../../../../utils/createAbsoluteUrl';
import { getSafeInnerHTML } from '../../../../utils/getSafeInnerHtml';
import { useIsLookbook } from '../../../../utils/hooks/useIsLookbook';
import { isDefined } from '../../../../utils/is';
import { Alert } from '../../../various/Alert';
import { AlertAction } from '../../../various/Alert/AlertAction';
import MeasurementChart from '../../../various/Chart';
import Icon, { IconColor, IconType } from '../../../various/Icon';
import { ShareButton } from '../../../various/IconButton/ShareButton';
import { Popover } from '../../../various/Popover';
import Price from '../../Product/Price';
import { ProductAttributes } from '../../ProductAttributes';
import { ProductsEtaContext } from '../../ProductsEta';
import { ProductDetailsLayoutContext } from '../context/ProductDetailsLayoutContext';
import { useProductDetailsVariantContext } from '../context/ProductDetailsVariantContext';
import { ProductDetailsModalContext } from '../ProductDetailsModal';
import { ProductInfoAddButton } from '../ProductInfoAddButton';

import styles from './ProductDetailsInfo.module.scss';
import { VariantsThumbnailsNavigation } from './VariantsThumbnailsNavigation';

export const ProductDetailsInfo = () => {
  const { activeVariant, productDetails } = useProductDetailsVariantContext();
  const { hasMobileMatrixLayout, hasMobileDetailsLayout } = React.useContext(ProductDetailsLayoutContext);
  const { t } = useTranslation(['common', 'products', 'selections']);
  const isLookbook = useIsLookbook();
  const params = useParams<{ lookbookId: Id }>();

  const {
    product: productId,
    variant: variantId,
    name: variantName,
    excerpt: variantExcerpt,
    description: variantDescription,
  } = activeVariant ?? {
    description: '',
    excerpt: '',
    name: '',
    product: undefined,
    variant: undefined,
  };

  const { hasVariantsWithEta } = React.useContext(ProductsEtaContext);
  const dispatch = useDispatch();
  const { setShouldScrollToEtaMatrix } = React.useContext(ProductDetailsModalContext);

  const handleScrollToMatrixWrapper = React.useCallback(() => {
    setShouldScrollToEtaMatrix(true);

    hasMobileMatrixLayout ? dispatch(setEtaVisibilityOnMobile(true)) : dispatch(setEtaVisibilityOnDesktop(true));
  }, [dispatch, hasMobileMatrixLayout, setShouldScrollToEtaMatrix]);

  const measurementChartTable = React.useMemo(
    () => (isValidMeasurementChart(productDetails.measurementChart) ? convertToTableFormat(productDetails.measurementChart) : null),
    [productDetails],
  );

  const shouldShowMeasurementChartTable = isDefined(measurementChartTable);

  const handleGetProductUrl = React.useCallback(
    (urlParams: ProductUrlProps) =>
      isLookbook ? getUrlForLookbookProduct(urlParams)(params.lookbookId) : getUrlForProductAndVariant(urlParams),
    [isLookbook, params.lookbookId],
  );

  const shareableUrl =
    isDefined(productId) && isDefined(variantId) ?
      createAbsoluteUrl('products', {
        showProduct: productId,
        showVariant: variantId,
      })
    : '';

  return (
    <div className={styles.productDetailsInfo}>
      <div className={styles.productHeading}>
        <div>
          <h3 className={styles.productName}>{variantName}</h3>
          {!isLookbook && isDefined(activeVariant) && (
            <div className={styles.productPrice}>
              <Price productInfo={activeVariant} />
            </div>
          )}
        </div>
        <ShareButton
          tooltipText={t('products:copy_shareable_link')}
          shareURL={shareableUrl}
          message={t('products:share_button_message')}
          popoverOverflowContainer={false}
        />
      </div>
      {isDefined(activeVariant) && <ProductAttributes product={activeVariant} />}
      <VariantsThumbnailsNavigation getProductUrl={handleGetProductUrl} />
      <div className={styles.productDescription}>
        <div className={styles.text}>
          <p dangerouslySetInnerHTML={getSafeInnerHTML(variantExcerpt)} />
          <p dangerouslySetInnerHTML={getSafeInnerHTML(variantDescription)} />
        </div>
      </div>

      {shouldShowMeasurementChartTable && (
        <div className={styles.measureChart}>
          <Popover
            className={styles.popover}
            overflowContainer={false}
            placement="bottom-start"
            possiblePlacements={['bottom-start', 'bottom-center']}
            content={<MeasurementChart table={measurementChartTable} />}
            snap
          >
            <Icon type={IconType.Chart} color={IconColor.Blue} className={styles.chartIcon} /> {t('products:measurement_chart')}
          </Popover>
        </div>
      )}
      {!hasMobileDetailsLayout && (
        <div className={styles.buttonsRow}>
          <ProductInfoAddButton />
        </div>
      )}
      {hasVariantsWithEta && (
        <Alert
          type="info"
          message={
            <>
              {t('products:variants_availability_info')}&nbsp;
              <AlertAction onClick={handleScrollToMatrixWrapper}>{t('products:see_etas')}</AlertAction>
            </>
          }
          className={styles.alert}
        />
      )}
    </div>
  );
};
