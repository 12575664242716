import React from 'react';
import { DisappearType, useLayer, UseLayerOptions } from 'react-laag';

import { useOnEscHandler } from './useOnEscHandler';

interface Props {
  layerProps: UseLayerOptions;
  escapeHandlerEnabled: boolean;
  onVisibleChange?: (visible: boolean) => void;
}

export const usePopover = ({ layerProps, onVisibleChange, escapeHandlerEnabled }: Props) => {
  const {
    isOpen,
    overflowContainer,
    placement,
    possiblePlacements,
    snap = false,
    triggerOffset,
    containerOffset,
    onParentClose,
  } = layerProps;

  const handleClose = React.useCallback(() => {
    onVisibleChange?.(false);
  }, [onVisibleChange]);

  const handleDisappear = React.useCallback(
    (type: DisappearType) => {
      if (type === 'full') {
        handleClose();
      }
    },
    [handleClose],
  );

  const layer = useLayer({
    auto: true,
    containerOffset,
    isOpen,
    onDisappear: handleDisappear,
    onOutsideClick: handleClose,
    onParentClose,
    overflowContainer,
    placement,
    possiblePlacements,
    snap,
    triggerOffset,
  });

  useOnEscHandler(document.documentElement, handleClose, escapeHandlerEnabled);

  return layer;
};
