/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;

export const TrashLinear = ({ width = ORIGINAL_WIDTH, color }: IconComponentProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox="0 0 20 20">
      <path
        d="M4.857 15.833c0 .917.772 1.667 1.714 1.667h6.858c.942 0 1.714-.75 1.714-1.667v-10H4.857v10ZM6.571 7.5h6.858v8.333H6.57V7.5ZM13 3.333l-.857-.833H7.857L7 3.333H4V5h12V3.333h-3Z"
        fill={color}
      />
    </svg>
  );
};
