import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getIsLoadingOrderDetails } from '../../../ducks';
import { getUser } from '../../../ducks/user';
import { getIsOrderEditable } from '../../../logic/Orders';
import { useAvailableOrderDetails } from '../../../utils/hooks/useAvailableOrderDetails';
import { isDefined } from '../../../utils/is';
import Heading from '../../various/Heading';
import { Skeleton } from '../../various/Skeleton';
import { SkeletonLayout } from '../../various/SkeletonLayout';

import styles from './CheckoutSummary.module.scss';
import { CheckoutSummaryActions } from './CheckoutSummaryActions';
import { OrderInformation } from './OrderInformation';

export const CheckoutSummary = () => {
  const user = useSelector(getUser);
  const { t } = useTranslation(['orders', 'selections']);
  const { orderStatus, orderNumber } = useAvailableOrderDetails();
  const isLoadingOrderDetails = useSelector(getIsLoadingOrderDetails);

  const isOrderEditable = isDefined(orderStatus) && getIsOrderEditable(orderStatus);

  const orderTitle = t(isOrderEditable ? 'selections:selection_number' : 'orders:order_number', {
    orderNumber,
  });

  return (
    <>
      <div className={styles.checkoutHead}>
        <SkeletonLayout
          isLoading={!isDefined(orderStatus)}
          skeleton={<Skeleton type="text" size="xlarge" itemClassName={styles.headerSkeleton} />}
        >
          <Heading title={orderTitle} className={styles.header} />
        </SkeletonLayout>
        <CheckoutSummaryActions user={user} isLoadingOrder={isLoadingOrderDetails} />
      </div>
      <OrderInformation user={user} isLoadingOrder={isLoadingOrderDetails} />
    </>
  );
};
