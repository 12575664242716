import { Order } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { pushEvent } from '../../../ducks';
import {
  downloadOrderConfirmation,
  downloadOrderCsv,
  downloadOrderExcel,
  downloadProductImages,
  downloadProductInfoExcel,
  getIsDownloadingOrder,
  getOrderConfirmationDownloadStatus,
} from '../../../ducks/documents';
import { getIsOrderClosed } from '../../../logic/Orders';
import { DownloadChoice, getDownloadButtonChoiceSelectedEvent } from '../../../utils/analytics/events';
import { useModalVisibility } from '../../../utils/hooks';
import { DownloadLinesheetModal } from '../../orders/DownloadLinesheetModal';
import Button from '../../various/Button';
import Icon, { IconColor, IconType } from '../../various/Icon';
import { PopoverMenu, TriggerProps } from '../../various/PopoverMenu';

import styles from './DownloadActions.module.scss';

interface Props {
  order: Order.Open | Order.Closed;
  isLoadingOrder: boolean;
}

export const DownloadActions = ({ order, isLoadingOrder }: Props) => {
  const dispatch = useDispatch();
  const isDownloadingOrder = useSelector(getIsDownloadingOrder);
  const { isDownloading } = useSelector(getOrderConfirmationDownloadStatus);
  const { t } = useTranslation(['checkout', 'common']);
  const { showModal } = useModalVisibility('DownloadLinesheetModal');

  const { order: orderId, orderNumber, status } = order;

  const handleDownloadOrderExcel = React.useCallback(() => {
    dispatch(pushEvent(getDownloadButtonChoiceSelectedEvent(DownloadChoice.OrderDetailsXls)));
    dispatch(
      downloadOrderExcel({
        orderId,
        orderNumber,
      }),
    );
  }, [dispatch, orderId, orderNumber]);

  const handleDownloadProductImages = React.useCallback(() => {
    dispatch(pushEvent(getDownloadButtonChoiceSelectedEvent(DownloadChoice.ProductImagesZip)));
    dispatch(downloadProductImages({ orderId, orderNumber }));
  }, [dispatch, orderId, orderNumber]);

  const handleDownloadOrderCsv = React.useCallback(() => {
    dispatch(pushEvent(getDownloadButtonChoiceSelectedEvent(DownloadChoice.OrderDetailsCsv)));
    dispatch(downloadOrderCsv({ orderId, orderNumber }));
  }, [dispatch, orderId, orderNumber]);

  const handleDownloadOrderConfirmation = React.useCallback(() => {
    dispatch(pushEvent(getDownloadButtonChoiceSelectedEvent(DownloadChoice.OrderConfirmationPdf)));
    dispatch(downloadOrderConfirmation(orderId));
  }, [dispatch, orderId]);

  const handleDownloadProductInfoExcel = React.useCallback(() => {
    dispatch(pushEvent(getDownloadButtonChoiceSelectedEvent(DownloadChoice.ProductInfoXls)));
    dispatch(
      downloadProductInfoExcel({
        orderId,
        orderNumber,
      }),
    );
  }, [dispatch, orderId, orderNumber]);

  const handleShowModal = React.useCallback(() => {
    dispatch(pushEvent(getDownloadButtonChoiceSelectedEvent(DownloadChoice.LinesheetPdf)));
    showModal();
  }, [dispatch, showModal]);

  const enableConfirmDownloadButton = status === 'inProgress' || status === 'completed';
  const isOrderClosed = getIsOrderClosed(order);
  const isDownloadingAnyDocument = isDownloadingOrder || isDownloading;

  const actions = [
    {
      isVisible: true,
      key: 'orderDetailsXls',
      label: t('checkout:download.order_details', { extension: 'xls' }),
      onClick: handleDownloadOrderExcel,
    },
    {
      isVisible: isOrderClosed,
      key: 'orderDetailsCsv',
      label: t('checkout:download.order_details', { extension: 'csv' }),
      onClick: handleDownloadOrderCsv,
    },
    {
      isVisible: enableConfirmDownloadButton,
      key: 'orderConfirmationPdf',
      label: t('checkout:download.order_confirmation', { extension: 'pdf' }),
      onClick: handleDownloadOrderConfirmation,
    },
    {
      isVisible: true,
      key: 'linesheetPdf',
      label: t('checkout:download.linesheet', { extension: 'pdf' }),
      onClick: handleShowModal,
    },
    {
      isVisible: true,
      key: 'productInfoXls',
      label: t('checkout:download.product_info', { extension: 'xls' }),
      onClick: handleDownloadProductInfoExcel,
    },
    {
      isVisible: isOrderClosed,
      key: 'productImagesZip',
      label: t('checkout:download.product_images', { extension: 'zip' }),
      onClick: handleDownloadProductImages,
    },
  ];

  const visibleActions = actions.filter(({ isVisible }) => isVisible);

  return (
    <>
      <PopoverMenu items={visibleActions} name={t('common:download')}>
        {(props: TriggerProps) => (
          <Button
            isLoading={isDownloadingAnyDocument}
            disabled={isLoadingOrder}
            variant={['button', 'small', 'transparent']}
            icon={IconType.DownloadCloud}
            {...props}
          >
            {t('common:download')}
            <Icon className={styles.downloadActionsArrowDown} type={IconType.ArrowRounded} color={IconColor.Gray} />
          </Button>
        )}
      </PopoverMenu>
      <DownloadLinesheetModal orderId={order.order} orderNumber={order.orderNumber} location="order details" />
    </>
  );
};
