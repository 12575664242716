import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { closeTextStyleEditor, getIsTextStyleEditorVisible, getTextStyleEditorMode } from '../../../../ducks';
import { EditorDialog } from '../EditorDialog';

import { TextStyleEditorContent } from './TextStyleEditorContent';

export const TextStyleEditor = () => {
  const { t } = useTranslation(['cms']);
  const dispatch = useDispatch();
  const isEditorVisible = useSelector(getIsTextStyleEditorVisible);
  const mode = useSelector(getTextStyleEditorMode);

  const handleClose = () => {
    dispatch(closeTextStyleEditor());
  };

  const title = mode === 'edit' ? t('cms:edit_text_style') : t('cms:create_new_text_style');

  return (
    <EditorDialog isVisible={isEditorVisible} id="textStyleEditorHeading" title={title} onClose={handleClose}>
      <TextStyleEditorContent />
    </EditorDialog>
  );
};
