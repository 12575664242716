import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { setCurrentSection, showModal } from '../../../ducks';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { EditorLanguageContext } from '../../cms/context/EditorLanguageContext';
import { Alert } from '../../various/Alert';
import { AlertAction } from '../../various/Alert/AlertAction';

import { Props } from './createSection';
import styles from './section.module.scss';

export const SectionWarnings = ({ sectionData }: Props) => {
  const { t } = useTranslation(['cms']);
  const dispatch = useDispatch();
  const { marketIds, isHidden } = sectionData.visibleFor;
  const hasNoMarkets = !isDefined(marketIds) || (isDefined(marketIds) && isEmpty(marketIds));
  const { editorLanguage, defaultLanguage, handleEditorLanguageChange } = React.useContext(EditorLanguageContext);

  const handleShowVisibilityModal = () => {
    if (editorLanguage !== defaultLanguage) {
      handleEditorLanguageChange(defaultLanguage);
    }

    dispatch(setCurrentSection(sectionData.id));
    dispatch(showModal('SectionVisibilityModal'));
  };

  return (
    <div className={styles.alertsContainer}>
      {hasNoMarkets && (
        <Alert
          className={styles.alert}
          message={
            <Trans t={t} i18nKey="cms:no_markets">
              This section visibility is not set to any markets and will not be displayed on published page.
              <AlertAction onClick={handleShowVisibilityModal}>Change section visibility</AlertAction>
            </Trans>
          }
        />
      )}
      {isHidden && (
        <Alert
          className={styles.alert}
          type="info"
          message={
            <Trans
              t={t}
              i18nKey="cms:hidden_section_info"
              defaults={
                'This section is hidden for the users. To make it visible, <alert>open section visibility</alert> and untoggle "Hide section"'
              }
              components={{
                alert: (
                  <AlertAction onClick={handleShowVisibilityModal}>
                    open <b>Section visibility</b> settings
                  </AlertAction>
                ),
              }}
            />
          }
        />
      )}
    </div>
  );
};
