import { isDefined } from './is';

type Resolver<T extends string> = (url: string) => Promise<T>;

const createMediaResolver =
  <T extends string>(...resolvers: Resolver<T>[]) =>
  async (url: string): Promise<T> => {
    const types = await Promise.all(resolvers.map(async resolver => resolver(url).catch(() => undefined)));
    const mediaType = types.find(isDefined);

    if (!isDefined(mediaType)) {
      throw new Error('Did not resolved file type');
    }

    return mediaType;
  };

const imageResolver: Resolver<'image'> = async (url: string) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    // eslint-disable-next-line functional/immutable-data
    Object.assign(image, {
      onerror: () => reject(),
      onload: () => resolve('image'),
      src: url,
    });
  });

const videoResolver: Resolver<'video'> = async (url: string) =>
  new Promise((resolve, reject) => {
    const video = document.createElement('video');
    // eslint-disable-next-line functional/immutable-data
    Object.assign(video, {
      onerror: () => reject(),
      onloadeddata: () => resolve('video'),
      src: url,
    });
  });

// make sure that order of resolvers remains as 1.`videoResolver` and  2.`imageResolver`, because of Safari strange behaviour
// when you will try to use imageResolver for video, Safari will execute onload callback, not onerror callback as Chrome and Firefox do
// and mediaResolver thinks that video has type `image`
export const getMediaType = createMediaResolver(videoResolver, imageResolver);
