import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getSharedLinksEnabled } from '../../../ducks';
import { useSelectionShareToken } from '../../../services/hooks/selection/useSelectionShareToken';
import { createAbsoluteUrl } from '../../../utils/createAbsoluteUrl';
import { useAmplitude } from '../../../utils/hooks/useAmplitude';
import { isDefined } from '../../../utils/is';
import { Input } from '../../various/Fields/Input';
import Icon, { IconType } from '../../various/Icon';

import styles from './SelectionShareModal.module.scss';

interface Props {
  sharePath: string;
  selectionId: string;
}

export const SelectionShareLinkInput = ({ sharePath, selectionId }: Props) => {
  const { t } = useTranslation(['common', 'selections']);
  const isShareable = useSelector(getSharedLinksEnabled);

  const copyButtonRef = React.useRef<HTMLDivElement>(null);

  const { data, mutate, isPending } = useSelectionShareToken();
  const { trackEvent } = useAmplitude();

  const handleGenerateClick = () => {
    mutate(selectionId, {
      onSuccess: () => {
        requestAnimationFrame(() => {
          copyButtonRef.current?.click();
        });
      },
    });
  };

  const handleSharePathCopy = () => {
    trackEvent({ name: 'shareSelection.input.shareablelink.click' });
  };

  if (!isShareable) {
    return (
      <Input
        value={createAbsoluteUrl([sharePath, selectionId])}
        size="large"
        isReadonly
        isCopyable
        onFocus={event => event.target.select()}
        onCopy={handleSharePathCopy}
      />
    );
  }

  const hasSharedToken = isDefined(data?.sharedToken);
  const value = hasSharedToken ? createAbsoluteUrl([sharePath, selectionId], { sharedToken: data.sharedToken }) : '';

  return (
    <div className={styles.linkInputWrapper}>
      <Input
        size="large"
        value={value}
        placeholder={t('selections:generate_shareable_link')}
        isReadonly
        copyButtonRef={copyButtonRef}
        isCopyable={hasSharedToken}
        onFocus={event => event.target.select()}
        onCopy={handleSharePathCopy}
      />
      {!hasSharedToken && (
        <button type="button" disabled={isPending} className={styles.generateButton} onClick={handleGenerateClick}>
          <Icon type={IconType.Reload} className={cx({ [styles.spinner]: isPending })} size={14} />
          {isPending ? t('common:generating') : t('common:generate')}
        </button>
      )}
    </div>
  );
};
