import { Skeleton } from '../../../Skeleton/Skeleton';

import { FilterGroupSetSkeleton } from './FilterGroupSetSkeleton';
import styles from './FiltersPanelGroupSkeleton.module.scss';

// eslint-disable-next-line no-magic-numbers
const ROWS = [3, 0, 0, 0, 0];

export const FiltersPanelGroupSkeleton = () => {
  return (
    <div className={styles.filtersPanelGroupSkeleton}>
      <Skeleton containerClassName={styles.topSection} type="text" size="xlarge" rows={2} gap="large" width={60} lastWidth={100} />
      {ROWS.map((rows, idx) => (
        <FilterGroupSetSkeleton key={idx} rows={rows} />
      ))}
    </div>
  );
};
