import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getChangePassword, getCompanyName } from '../../../ducks';
import { paths } from '../../../paths';
import { StartPageLayout } from '../../layout/StartPageLayout';
import { FormButtons } from '../../various/Form';
import { Button } from '../../various/NewButton';
import { SetPasswordForm } from '../WelcomePage/SetPasswordForm';

export const ChangePasswordPage = () => {
  const { t } = useTranslation(['passwordManagement', 'login']);
  const companyName = useSelector(getCompanyName);
  const {
    data: { hasChanged },
  } = useSelector(getChangePassword);

  const title = hasChanged ? t('passwordManagement:password_reset') : t('passwordManagement:reset_password_for_showroom', { companyName });

  const text = hasChanged ? t('passwordManagement:reset_password_success', { companyName }) : undefined;

  return (
    <StartPageLayout title={title} text={text}>
      {hasChanged ?
        <FormButtons direction="column">
          <Button as={Link} to={paths.LOGIN} size="large" color="dark" isFluid>
            {t('login:sign_in')}
          </Button>
        </FormButtons>
      : <SetPasswordForm submitText={t('passwordManagement:reset_password')} />}
    </StartPageLayout>
  );
};
