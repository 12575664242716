import cx from 'classnames';
import React from 'react';

import { useIsOverflowing } from '../../../utils/hooks/useIsOverflowing';

import styles from './ProductAttributes.module.scss';

interface Props {
  name: string;
  value: string;
}

export const AttributeRow = ({ name, value }: Props) => {
  const ref = React.useRef<HTMLElement>(null);
  const isOverflowing = useIsOverflowing(ref);

  return (
    <div className={styles.fallbackWrapper}>
      <dt ref={ref} className={cx({ [styles.isOverflowing]: isOverflowing })}>
        <span>{name}:</span>
      </dt>
      <dd>{value}</dd>
    </div>
  );
};
