import { useCallback, useMemo } from 'react';

interface Props {
  current: number;
  pageCount: number;
  total: number;
  siblingCount: number;
}

const FIRST_PAGE = 1;
const SECOND_PAGE = 2;
const OTHER_PAGINATION_ELEMENTS_COUNT = 5; // firstPage + lastPage + currentPage + 2*DOTS

export const LEFT_DOTS = -1;
export const RIGHT_DOTS = -2;

const range = (start: number, end: number) => {
  const length = end - start + 1;

  return Array.from({ length }, (_, index) => {
    const pageNumber = index + start;

    return { page: pageNumber, value: pageNumber };
  });
};

export const usePagination = ({ current, pageCount, siblingCount, total }: Props) => {
  const pageShift: number = useMemo(() => FIRST_PAGE + siblingCount + siblingCount, [siblingCount]);
  const getPageToGoNumber = useCallback(
    (pageValue: number) => {
      switch (pageValue) {
        case LEFT_DOTS: {
          const pagesToGo = current - pageShift;

          return pagesToGo < FIRST_PAGE ? current - siblingCount - 1 : pagesToGo;
        }
        case RIGHT_DOTS: {
          const pagesToGo = current + pageShift;

          return pagesToGo > pageCount ? current + siblingCount + 1 : pagesToGo;
        }
        default:
          return pageValue;
      }
    },
    [current, pageShift, pageCount, siblingCount],
  );

  return useMemo(() => {
    const totalPageNumbers = siblingCount + OTHER_PAGINATION_ELEMENTS_COUNT;
    const rangeItemCount = 1 + siblingCount + siblingCount; // Active page + left siblings + right siblings
    const firstPageObject = { page: FIRST_PAGE, value: FIRST_PAGE };
    const lastPageObject = { page: pageCount, value: pageCount };
    const leftDotsObject = {
      page: getPageToGoNumber(LEFT_DOTS),
      value: LEFT_DOTS,
    };
    const rightDotsObject = {
      page: getPageToGoNumber(RIGHT_DOTS),
      value: RIGHT_DOTS,
    };

    if (totalPageNumbers >= pageCount) {
      return range(1, pageCount);
    }
    const leftSiblingIndex = Math.max(current - siblingCount, 1);
    const rightSiblingIndex = Math.min(current + siblingCount, pageCount);

    const shouldShowLeftDots = leftSiblingIndex > SECOND_PAGE;
    const shouldShowRightDots = rightSiblingIndex < pageCount - FIRST_PAGE;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftRange = range(1, rangeItemCount);

      return [...leftRange, rightDotsObject, lastPageObject];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightRange = range(pageCount - rangeItemCount + 1, pageCount);

      return [firstPageObject, leftDotsObject, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);

      return [firstPageObject, leftDotsObject, ...middleRange, rightDotsObject, lastPageObject];
    }

    return [];
  }, [current, siblingCount, total]);
};
