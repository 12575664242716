import { Selects } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getChildrenByComponent } from '../../../../utils/getChildrenByComponent';
import { getChildrenText } from '../../../../utils/getChildrenText';
import { isDefined } from '../../../../utils/is';
import { isEmpty } from '../../../../utils/isEmpty';

import { SelectContextProvider, useSelectContext } from './context/SelectContext';
import { Option } from './Option';
import { Options, OPTIONS_FADE_DURATION } from './Options';
import { SelectInput } from './SelectTrigger';

type Props = Pick<
  Selects.AutocompleteProps,
  'allowCustomValues' | 'inputMode' | 'children' | 'onSearchChange' | 'validateCustomValue' | 'isScrollable'
>;

const AutocompleteComponent = (props: Props) => {
  const { allowCustomValues = false, isScrollable, children, inputMode, onSearchChange, validateCustomValue } = props;
  const { t } = useTranslation(['common']);
  const { selection, isOpen, isMultiple, hoverOption } = useSelectContext();
  const [selectionLabels, setSelectionLabels] = React.useState<Record<string, Maybe<string>>>({});
  const [search, setSearch] = React.useState('');

  React.useEffect(() => {
    if (isOpen || isEmpty(search)) {
      return;
    }

    const timeout = setTimeout(() => {
      handleSearchChange('');
    }, OPTIONS_FADE_DURATION);

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleSearchChange = (query: string) => {
    setSearch(query);
    onSearchChange?.(query);
    hoverOption(0);
  };

  const validChildren = getChildrenByComponent(children, Option);

  React.useEffect(() => {
    const newSelectionLabels = validChildren.reduce((acc, child) => {
      if ([selection].flat().includes(child.props.value)) {
        return {
          ...acc,
          [child.props.value]: renderValue(child.props.value),
        };
      }

      return acc;
    }, {});

    setSelectionLabels(labels => ({ ...labels, ...newSelectionLabels }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection]);

  const renderValue = (value: Selects.NonNullableValue) => {
    const optionChildren = validChildren.find(child => child.props.value === value)?.props.children;

    if (isDefined(optionChildren)) {
      return getChildrenText(optionChildren);
    }

    return selectionLabels[value] ?? value.toString();
  };

  const filteredChildren =
    isDefined(onSearchChange) ? validChildren : (
      validChildren.filter((child: React.ReactElement) => child.props.children?.toString().toLowerCase().includes(search.toLowerCase()))
    );

  const indexedChildren = filteredChildren.map((child, index) => React.cloneElement(child, { index }));

  const searchAsOptionValue = inputMode === 'numeric' ? Number(search) : search;

  const shouldAddSearchAsOption = React.useMemo(() => {
    const isSearchEmpty = isEmpty(search.trim());
    const isSearchAlreadySelected = [selection].flat().includes(searchAsOptionValue);
    const isSearchAlreadyInOptions = filteredChildren.some(child => child.props.value === searchAsOptionValue);
    const isValueValid = !isDefined(validateCustomValue) || validateCustomValue(searchAsOptionValue);

    return allowCustomValues && !isSearchEmpty && !isSearchAlreadySelected && !isSearchAlreadyInOptions && isValueValid;
  }, [allowCustomValues, filteredChildren, search, searchAsOptionValue, selection, validateCustomValue]);

  const options = React.useMemo(() => {
    const filteredOptions = filteredChildren.map((child: React.ReactElement) => ({
      label: child.props.children,
      value: child.props.value,
    }));

    if (shouldAddSearchAsOption) {
      return [
        ...filteredOptions,
        {
          label: t('common:add_value', { value: search }),
          value: searchAsOptionValue,
        },
      ];
    }

    return filteredOptions;
  }, [filteredChildren, search, searchAsOptionValue, shouldAddSearchAsOption, t]);

  const handleSearchOptionClick = () => {
    if (isMultiple) {
      setSearch('');
      onSearchChange?.('');
    }
  };

  return (
    <Options
      trigger={
        <SelectInput
          options={options}
          search={search}
          inputMode={inputMode}
          renderValue={renderValue}
          onSearchChange={handleSearchChange}
        />
      }
      isScrollable={isScrollable}
      shouldMatchTriggerWidth={!allowCustomValues}
      shouldShowEmptyState={!allowCustomValues}
    >
      {indexedChildren}
      {shouldAddSearchAsOption && (
        <Option value={searchAsOptionValue} index={filteredChildren.length} onClick={handleSearchOptionClick}>
          {t('common:add_value', { value: search })}
        </Option>
      )}
    </Options>
  );
};

export const Autocomplete = React.memo(<T extends Selects.Value>(props: Selects.AutocompleteProps<T>) => {
  const { allowCustomValues, children, inputMode, onSearchChange, validateCustomValue, isScrollable, ...contextProps } = props;

  return (
    <SelectContextProvider {...contextProps}>
      <AutocompleteComponent
        allowCustomValues={allowCustomValues}
        validateCustomValue={validateCustomValue}
        isScrollable={isScrollable}
        inputMode={inputMode}
        onSearchChange={onSearchChange}
      >
        {children}
      </AutocompleteComponent>
    </SelectContextProvider>
  );
});
