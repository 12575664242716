import { Order } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  getActiveDeliveryWindowsIdsForOrderBuyer,
  getAllDeliveryWindows,
  getIsDeliveryWindowsLoading,
  getOrderDeliveryWindowsIds,
  removeProductsFromOrder,
} from '../../../ducks';
import { getDeliveryWindowsByIds, getIsExpired } from '../../../logic/deliveryWindows';
import {
  getDeliveryWindowNamesCombined,
  getIsOrderEditable,
  getProductsToRemoveByDeliveryWindows,
  getUnitsCountInDelwin,
} from '../../../logic/Orders';
import { useConfirmationGuard } from '../../../utils/hooks/useConfirmationGuard';
import { Alert } from '../../various/Alert';
import { AlertAction } from '../../various/Alert/AlertAction';

interface Props {
  order: Order.Open;
  className?: string;
}

export const ExpiredDelwinAlert = ({ order, className }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['confirmationConfig', 'checkout', 'expiredDelwins']);
  const confirmationGuard = useConfirmationGuard();
  const orderDeliveryWindows = useSelector(getOrderDeliveryWindowsIds);
  const allDeliveryWindows = useSelector(getAllDeliveryWindows);
  const activeDeliveryWindowsIds = useSelector(getActiveDeliveryWindowsIdsForOrderBuyer);
  const isEditableOrder = getIsOrderEditable(order.status);
  const isLoadingDeliveryWindows = useSelector(getIsDeliveryWindowsLoading);

  const expiredDeliveryWindows = React.useMemo(() => {
    if (!isEditableOrder) {
      return [];
    }

    const deliveryWindows = getDeliveryWindowsByIds(allDeliveryWindows, orderDeliveryWindows);

    return deliveryWindows.filter(
      delwin => getIsExpired(delwin, activeDeliveryWindowsIds) && getUnitsCountInDelwin(delwin.deliveryWindow, order.products) > 0,
    );
  }, [isEditableOrder, activeDeliveryWindowsIds, orderDeliveryWindows, allDeliveryWindows, order]);

  const combinedNames = React.useMemo(() => getDeliveryWindowNamesCombined(expiredDeliveryWindows), [expiredDeliveryWindows]);

  const removeProducts = confirmationGuard(() => ({
    onOk: () => {
      const deliveryWindowsIds = expiredDeliveryWindows.map(({ deliveryWindow }) => deliveryWindow);
      const products = getProductsToRemoveByDeliveryWindows(order, deliveryWindowsIds);

      dispatch(removeProductsFromOrder({ deliveryWindows: deliveryWindowsIds, products }));
    },
    ...t('confirmationConfig:remove_products_by_delwin', {
      names: combinedNames,
      returnObjects: true,
    }),
  }));

  const amountOfExpiredDeliveryWindows = expiredDeliveryWindows.length;

  const alertMessage = React.useMemo(() => {
    return t('expiredDelwins:expired_delivery_window_warning', {
      count: amountOfExpiredDeliveryWindows,
      expiredNames: combinedNames,
    });
  }, [expiredDeliveryWindows, combinedNames, t]);

  if (amountOfExpiredDeliveryWindows === 0 || isLoadingDeliveryWindows) {
    return null;
  }

  return (
    <Alert
      type="warning"
      className={className}
      message={
        <>
          {alertMessage}
          <AlertAction onClick={removeProducts}>{t('confirmationConfig:remove_products_by_delwin.title')}</AlertAction>
        </>
      }
    />
  );
};
