import { Linesheet } from '@typings';
import { Trans, useTranslation } from 'react-i18next';

import { Alert } from '../Alert';

import styles from './ProductAttributesSortList.module.scss';

const maxItemsPerLayout: Record<Linesheet.Template, number> = {
  'landscape-9': 4,
  'portrait-4': 8,
};

interface Props {
  layoutType: Linesheet.Template;
  numberOfItems: number;
  linesheetName: string;
}

export const MaxAttributeWarning = ({ numberOfItems, linesheetName, layoutType }: Props) => {
  const { t } = useTranslation(['linesheets']);
  const maxItems = maxItemsPerLayout[layoutType];

  if (numberOfItems <= maxItems) {
    return null;
  }

  return (
    <Alert
      message={
        <Trans t={t} i18nKey="linesheets:max_attribute_warning" values={{ count: maxItems, name: linesheetName }}>
          For the <span className={styles.linesheetNameAlert}>LINESHEET NAME</span> template, it is recommended to display up to MAX_COUNT
          product attributes, so that they fit on a page.
        </Trans>
      }
      className={styles.alert}
      type="warning"
      dataTestId="maxAttributeWarning"
    />
  );
};
