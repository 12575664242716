import { Cms } from '@typings';

import { isDefined } from '../../utils/is';
import { urlRegex, vimeoRegex, youTubeRegex } from '../../utils/playerRegexes';

type PlayerSource = 'YouTube' | 'Vimeo';

const Player = {
  Vimeo: {
    playerUrl: 'https://player.vimeo.com/video',
    regExp: vimeoRegex,
  },
  YouTube: {
    playerUrl: 'https://www.youtube.com/embed',
    regExp: youTubeRegex,
  },
};

export const getPlayerUrl = (source: PlayerSource) => {
  return Player[source].playerUrl;
};

export const getVideoId = (url: string, source: PlayerSource) => {
  const { regExp } = Player[source];

  const match = url.match(regExp);
  const id = match?.[1];

  return isDefined(id) ? id : '';
};

export const getFullPlayerUrl = (url: string, source: PlayerSource) => {
  const playerUrl = getPlayerUrl(source);
  const videoId = getVideoId(url, source);

  return `${playerUrl}/${videoId}`;
};

export const getSourceByUrl = (url: string): Nullable<Cms.EmbedSource> => {
  if (url.match(youTubeRegex)) {
    return 'YouTube';
  }

  if (url.match(vimeoRegex)) {
    return 'Vimeo';
  }

  if (url.match(urlRegex)) {
    return 'URL';
  }

  return null;
};
