import { Cms } from '@centra';
import React from 'react';

import { getResponsiveClassNames } from '../../../logic/cms/editor';

interface Props {
  viewMode: Cms.ViewModeType;
}

interface Context {
  responsiveClassNames: { wrapperDesktop: boolean; wrapperMobile: boolean; wrapperTablet: boolean };
  screenType: Cms.ScreenType;
  viewMode?: Cms.ViewModeType;
  setScreenType: (screenType: Cms.ScreenType) => void;
}

const initialContext: Context = {
  responsiveClassNames: { wrapperDesktop: false, wrapperMobile: false, wrapperTablet: false },
  screenType: 'desktop',
  setScreenType: (_: Cms.ScreenType) => null,
  viewMode: 'presentation',
};

export const EditorUIContext = React.createContext<Context>(initialContext);

export const EditorUIContextProvider = ({ children, viewMode }: React.WithChildren<Props>) => {
  const [screenType, setScreenType] = React.useState<Cms.ScreenType>('desktop');

  const responsiveClassNames = getResponsiveClassNames(screenType);

  return (
    <EditorUIContext.Provider
      value={{
        responsiveClassNames,
        screenType,
        setScreenType,
        viewMode,
      }}
    >
      {children}
    </EditorUIContext.Provider>
  );
};
