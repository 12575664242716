import { FailedProducts, Product } from '@typings';
import { useTranslation } from 'react-i18next';

import { useModalVisibility } from '../../../utils/hooks';
import { FailedProductsList } from '../../various/FailedProductsList';
import { useMissingProductsModel } from '../../various/FailedProductsRowModels';
import { ModalShell } from '../../various/ModalShell';

import styles from './MissingProductsModal.module.scss';

interface Props {
  failedProductsData: {
    products: Record<Product.Id, Product.Full>;
    failedProducts: FailedProducts.ItemsMissing[];
  };
}

export const MissingProductsModal = ({ failedProductsData }: Props) => {
  const { t } = useTranslation(['products']);
  const { isModalVisible, hideModal } = useModalVisibility('MissingProductsModal');
  const missingProductsModel = useMissingProductsModel();

  return (
    <ModalShell title={t('products:products_not_available')} isOpen={isModalVisible} onClose={hideModal} className={styles.wideModal}>
      <p>{t('products:products_not_included_in_selection')}</p>
      <FailedProductsList
        model={missingProductsModel}
        products={failedProductsData.products}
        failedProducts={failedProductsData.failedProducts}
      />
    </ModalShell>
  );
};
