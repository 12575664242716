import { CmsGroups } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getCmsSectionById, moveGroup, removeGroup, setCurrentGroup, setCurrentSection, showModal } from '../../../ducks';
import { useGroupCopyPaste } from '../../../utils/hooks/cms/useGroupCopyPaste';
import { useConfirmationGuard } from '../../../utils/hooks/useConfirmationGuard';
import { isDefined } from '../../../utils/is';
import { IconType } from '../../various/Icon';
import { EditorUIContext } from '../context/EditorUIContext';
import { HoverTools } from '../hoverTools';

export const GroupTools = ({ groupId, sectionId }: CmsGroups.GroupProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['cms', 'cmsConfirmationConfig']);
  const { screenType } = React.useContext(EditorUIContext);
  const sectionData = useSelector(getCmsSectionById(sectionId));
  const groupsOrder = isDefined(sectionData) ? sectionData.groupsOrder : undefined;

  const confirmationGuard = useConfirmationGuard();
  const { copyGroup, cutGroup } = useGroupCopyPaste();

  const handleCopyGroup = React.useCallback(() => {
    copyGroup(groupId);
  }, [copyGroup, groupId]);

  const handleCutGroup = React.useCallback(() => {
    cutGroup(groupId, sectionId);
  }, [cutGroup, groupId, sectionId]);

  const handleChangeGroup = React.useCallback(() => {
    dispatch(setCurrentSection(sectionId));
    dispatch(setCurrentGroup(groupId));
    dispatch(showModal('ChangeTemplateModal'));
  }, [dispatch, sectionId, groupId]);

  const handleDeleteGroup = confirmationGuard(() => ({
    onOk: () => dispatch(removeGroup({ groupId, sectionId })),
    ...t('cmsConfirmationConfig:delete_layout', { returnObjects: true }),
  }));

  const handleMoveGroupUp = React.useCallback(() => {
    dispatch(moveGroup({ groupId, offset: -1, sectionId }));
  }, [dispatch, sectionId, groupId]);

  const handleMoveGroupDown = React.useCallback(() => {
    dispatch(moveGroup({ groupId, offset: 1, sectionId }));
  }, [dispatch, sectionId, groupId]);

  return (
    <HoverTools
      name={t('cms:layout')}
      elements={[
        {
          icon: IconType.CopyOutline,
          key: 'copy',
          label: t('cms:copy_layout'),
          onClick: handleCopyGroup,
          testId: 'copyLayoutButton',
        },
        {
          icon: IconType.Cut,
          key: 'cut',
          label: t('cms:cut_layout'),
          onClick: handleCutGroup,
          testId: 'cutLayoutButton',
        },
        {
          hideExpandIndicator: true,
          icon: IconType.MoreHorizontal,
          items: [
            {
              icon: IconType.ReplaceHorizontal,
              isDisabled: screenType !== 'desktop',
              key: 'change',
              label: t('cms:change_layout'),
              onClick: handleChangeGroup,
              testId: 'changeLayoutButton',
            },
            {
              icon: IconType.MoveUp,
              isDisabled: isDefined(groupsOrder) && groupsOrder[0] === groupId,
              key: 'moveUp',
              label: t('cms:move_layout_up'),
              onClick: handleMoveGroupUp,
              testId: 'moveLayoutUpButton',
            },
            {
              icon: IconType.MoveDown,
              isDisabled: isDefined(groupsOrder) && groupsOrder[groupsOrder.length - 1] === groupId,
              key: 'moveDown',
              label: t('cms:move_layout_down'),
              onClick: handleMoveGroupDown,
              testId: 'moveLayoutDownButton',
            },
            {
              icon: IconType.Trash,
              key: 'remove',
              label: t('cms:delete_layout'),
              onClick: handleDeleteGroup,
              testId: 'deleteLayoutButton',
            },
          ],
          key: 'moreGroupTools',
          label: t('cms:more_layout_tools'),
          testId: 'layoutTools',
        },
      ]}
    />
  );
};
