import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Cms, Id } from '@typings';
import { useTranslation } from 'react-i18next';

import { addToast } from '../../../components/various/Toasts';
import { editPage } from '../../pagesRepository';

import { PAGES_QUERY_KEY } from './usePages';

interface MutationVariables {
  pageId: Id;
  data: Cms.PageDTO;
}

export const useEditPage = () => {
  const { t } = useTranslation(['cms']);
  const queryClient = useQueryClient();

  const mutationFn = async ({ pageId, data }: MutationVariables) => {
    return editPage(pageId, data);
  };

  const handleSuccess = (updatedPage: Cms.Page, { pageId }: MutationVariables) => {
    addToast(t('cms:page_settings_updated'));

    queryClient.setQueriesData<Cms.Page[]>({ queryKey: [PAGES_QUERY_KEY] }, (pages = []) => {
      return pages.map(page => (page.id === pageId ? updatedPage : page));
    });
    queryClient.invalidateQueries({ queryKey: [PAGES_QUERY_KEY] });
  };

  const handleError = () => {
    addToast(t('cms:edit_page_fail'));
  };

  return useMutation({
    mutationFn,
    onError: handleError,
    onSuccess: handleSuccess,
  });
};
