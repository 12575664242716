import { ActionHandler } from '@typings';
import { createReducer } from 'typesafe-actions';

import { omit } from '../../utils/omit';
import { update } from '../../utils/update';
import { loadSingleProductFailure } from '../productDetails';

import {
  addProductByBarcodeFailure,
  addProductByBarcodeSoldOutFailure,
  addProductByBarcodeSuccess,
  loadSingleProductByBarcodeRequest,
  resetBarcodeScannerData,
  resetScannedBarcode,
  toggleShouldAddToSelection,
} from './actions';

export interface BarcodeScannerReducer {
  hasAddedProductByBarcode: boolean;
  hasAddingByBarcodeFailed: boolean;
  isAddingProductByBarcode: boolean;
  isProductByBarcodeSoldOut: boolean;
  scannedBarcode: string | null;
  shouldAddToSelection: boolean;
}

const initialState: BarcodeScannerReducer = {
  hasAddedProductByBarcode: false,
  hasAddingByBarcodeFailed: false,
  isAddingProductByBarcode: false,
  isProductByBarcodeSoldOut: false,
  scannedBarcode: null,
  shouldAddToSelection: false,
};

const handleLoadSingleProductByBarcodeRequest: ActionHandler<BarcodeScannerReducer, typeof loadSingleProductByBarcodeRequest> = (
  state,
  action,
) => {
  return update(state, {
    hasAddedProductByBarcode: false,
    hasAddingByBarcodeFailed: false,
    isAddingProductByBarcode: state.shouldAddToSelection,
    isProductByBarcodeSoldOut: false,
    scannedBarcode: action.payload.productBarcode,
  });
};

const handleLoadSingleProductByBarcodeFailure: ActionHandler<BarcodeScannerReducer, typeof loadSingleProductFailure> = state => {
  return update(state, {
    isAddingProductByBarcode: false,
    scannedBarcode: null,
  });
};

const handleAddProductByBarcodeSuccess: ActionHandler<BarcodeScannerReducer, typeof addProductByBarcodeSuccess> = state => {
  return update(state, {
    hasAddedProductByBarcode: true,
    isAddingProductByBarcode: false,
    scannedBarcode: null,
  });
};

const handleProductByBarcodeSoldOutFailure: ActionHandler<BarcodeScannerReducer, typeof addProductByBarcodeSoldOutFailure> = state => {
  return update(state, {
    isAddingProductByBarcode: false,
    isProductByBarcodeSoldOut: true,
  });
};

const handleAddProductByBarcodeFailure: ActionHandler<BarcodeScannerReducer, typeof addProductByBarcodeFailure> = state => {
  return update(state, {
    hasAddingByBarcodeFailed: true,
    isAddingProductByBarcode: false,
  });
};

const handleResetScannedBarcode: ActionHandler<BarcodeScannerReducer, typeof resetScannedBarcode> = state => {
  return update(state, {
    scannedBarcode: null,
  });
};

const handleResetBarcodeScannerData: ActionHandler<BarcodeScannerReducer, typeof resetBarcodeScannerData> = state => {
  const stateToReset = omit(initialState, ['shouldAddToSelection']);

  return update(state, stateToReset);
};

const handleToggleShouldAddToSelection: ActionHandler<BarcodeScannerReducer, typeof toggleShouldAddToSelection> = (state, action) => {
  return update(state, {
    shouldAddToSelection: action.payload,
  });
};

export default createReducer<BarcodeScannerReducer, AppAction>(initialState)
  .handleAction(loadSingleProductByBarcodeRequest, handleLoadSingleProductByBarcodeRequest)
  .handleAction(loadSingleProductFailure, handleLoadSingleProductByBarcodeFailure)
  .handleAction(addProductByBarcodeSuccess, handleAddProductByBarcodeSuccess)
  .handleAction(addProductByBarcodeFailure, handleAddProductByBarcodeFailure)
  .handleAction(addProductByBarcodeSoldOutFailure, handleProductByBarcodeSoldOutFailure)
  .handleAction(toggleShouldAddToSelection, handleToggleShouldAddToSelection)
  .handleAction(resetBarcodeScannerData, handleResetBarcodeScannerData)
  .handleAction(resetScannedBarcode, handleResetScannedBarcode);
