import { ActionHandler } from '@typings';
import { createReducer } from 'typesafe-actions';

import { update } from '../../utils/update';

import {
  downloadOrderConfirmation,
  downloadOrderConfirmationFailed,
  downloadOrderConfirmationSuccess,
  downloadOrderCsv,
  downloadOrderCsvFailure,
  downloadOrderCsvSuccess,
  downloadOrderExcel,
  downloadOrderExcelFailure,
  downloadOrderExcelSuccess,
  downloadProductImages,
  downloadProductImagesFailure,
  downloadProductImagesSuccess,
  downloadProductInfoExcel,
  downloadProductInfoExcelFailure,
  downloadProductInfoExcelSuccess,
} from './actions';

export interface DocumentsReducer {
  orderConfirmation: {
    isDownloading: boolean;
    isFailed: boolean;
  };
  productImages: {
    isDownloading: boolean;
    isFailed: boolean;
    orderNumber: Nullable<string>;
  };
  order: {
    isDownloading: boolean;
    isFailed: boolean;
  };
}

const initialState: DocumentsReducer = {
  order: {
    isDownloading: false,
    isFailed: false,
  },
  orderConfirmation: {
    isDownloading: false,
    isFailed: false,
  },
  productImages: {
    isDownloading: false,
    isFailed: false,
    orderNumber: null,
  },
};

function handleDownloadOrderConfirmation(state: DocumentsReducer): DocumentsReducer {
  return update(state, {
    orderConfirmation: {
      isDownloading: true,
      isFailed: false,
    },
  });
}

function handleDownloadOrderConfirmationSuccess(state: DocumentsReducer): DocumentsReducer {
  return update(state, {
    orderConfirmation: {
      isDownloading: false,
      isFailed: false,
    },
  });
}

function handleDownloadOrderConfirmationFailure(state: DocumentsReducer): DocumentsReducer {
  return update(state, {
    orderConfirmation: {
      isDownloading: false,
      isFailed: true,
    },
  });
}

function handleDownloadOrder(state: DocumentsReducer): DocumentsReducer {
  return update(state, {
    order: {
      isDownloading: true,
      isFailed: false,
    },
  });
}

function handleDownloadOrderSuccess(state: DocumentsReducer): DocumentsReducer {
  return update(state, {
    order: {
      isDownloading: false,
      isFailed: false,
    },
  });
}

function handleDownloadOrderFailure(state: DocumentsReducer): DocumentsReducer {
  return update(state, {
    order: {
      isDownloading: false,
      isFailed: true,
    },
  });
}

const handleDownloadProductImages: ActionHandler<DocumentsReducer, typeof downloadProductImages> = (state, action) => {
  return update(state, {
    productImages: update(state.productImages, {
      isDownloading: true,
      isFailed: false,
      orderNumber: action.payload.orderNumber,
    }),
  });
};

function handleDownloadProductImagesFailure(state: DocumentsReducer): DocumentsReducer {
  return update(state, {
    productImages: update(state.productImages, {
      isDownloading: false,
      isFailed: true,
    }),
  });
}

function handleDownloadProductImagesSuccess(state: DocumentsReducer): DocumentsReducer {
  return update(state, {
    productImages: update(state.productImages, {
      isDownloading: false,
    }),
  });
}

export default createReducer<DocumentsReducer, AppAction>(initialState)
  .handleAction(downloadOrderConfirmation, handleDownloadOrderConfirmation)
  .handleAction(downloadOrderConfirmationSuccess, handleDownloadOrderConfirmationSuccess)
  .handleAction(downloadOrderConfirmationFailed, handleDownloadOrderConfirmationFailure)
  .handleAction(downloadOrderCsv, handleDownloadOrder)
  .handleAction(downloadOrderCsvSuccess, handleDownloadOrderSuccess)
  .handleAction(downloadOrderCsvFailure, handleDownloadOrderFailure)
  .handleAction(downloadOrderExcel, handleDownloadOrder)
  .handleAction(downloadOrderExcelSuccess, handleDownloadOrderSuccess)
  .handleAction(downloadOrderExcelFailure, handleDownloadOrderFailure)
  .handleAction(downloadProductInfoExcel, handleDownloadOrder)
  .handleAction(downloadProductInfoExcelSuccess, handleDownloadOrderSuccess)
  .handleAction(downloadProductInfoExcelFailure, handleDownloadOrderFailure)
  .handleAction(downloadProductImages, handleDownloadProductImages)
  .handleAction(downloadProductImagesFailure, handleDownloadProductImagesFailure)
  .handleAction(downloadProductImagesSuccess, handleDownloadProductImagesSuccess);
