import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 19;
const ORIGINAL_HEIGHT = 14;
const PROPORTAION = ORIGINAL_HEIGHT / ORIGINAL_WIDTH;

export const FlagFr = ({ width = ORIGINAL_WIDTH }: IconComponentProps) => {
  return (
    <svg width={width} height={width * PROPORTAION} viewBox="0 0 19 14" fill="none" style={{ borderRadius: '3px' }}>
      <path fillRule="evenodd" clipRule="evenodd" d="M-1.52588e-05 0.5H19V14.5H-1.52588e-05V0.5Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M-1.52588e-05 0.5H6.33233V14.5H-1.52588e-05V0.5Z" fill="#002654" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.6676 0.5H19V14.5H12.6676V0.5Z" fill="#CE1126" />
    </svg>
  );
};
