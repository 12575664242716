import { Cms } from '@typings';

import { isDefined } from '../../../utils/is';

import { v0 } from './compatibilityTransformations/v0';

const TRANSFORMATIONS_MAP: Record<number, (response: Cms.SectionsResponseAnyVersion) => Cms.SectionsResponseAnyVersion> = {
  0: v0,
};

export const latestPageStructureVersion = Math.max(...Object.keys(TRANSFORMATIONS_MAP).map(Number)) + 1;

export const convertPageToLatestVersion = (sectionsResponse: Cms.SectionsResponseAnyVersion): Cms.SectionsResponse => {
  const currentVersion = sectionsResponse.version ?? 0;
  const transformationFunctions = TRANSFORMATIONS_MAP[currentVersion];

  if (currentVersion >= latestPageStructureVersion || !isDefined(transformationFunctions)) {
    return sectionsResponse as Cms.SectionsResponse;
  }

  return convertPageToLatestVersion(transformationFunctions(sectionsResponse));
};
