import { Cms, Id, Navigation, Translations } from '@centra';
import { nanoid } from 'nanoid';
import { flatten, groupBy, map, pathSatisfies, pick, pipe, prop, uniq, values } from 'ramda';

import {
  CMS_SCREENS_SIZES,
  DEFAULT_CONTENT_ALIGNMENT,
  DESKTOP_PRODUCTS_PER_ROW,
  MOBILE_PRODUCTS_PER_ROW,
  PRODUCT_PADDING,
  TABLET_PRODUCTS_PER_ROW,
} from '../constants/cms';
import { isDefined } from '../utils/is';
import { isEmpty } from '../utils/isEmpty';
import { listToRecord } from '../utils/normalize';
import { getSafeUrl } from '../utils/safeUrl';

import { latestPageStructureVersion } from './cms/convertPageToLatestVersion';
import { getTextPartsOrderForBlock } from './cms/getTextPartsOrderForBlock';

export const isDesktopWidth = (screenWidth: number) => screenWidth > CMS_SCREENS_SIZES.tablet;
export const isTabletWidth = (screenWidth: number) => screenWidth <= CMS_SCREENS_SIZES.tablet && screenWidth > CMS_SCREENS_SIZES.mobile;

export const getScreenType = (screenWidth: number) => {
  if (isDesktopWidth(screenWidth)) {
    return 'desktop';
  }

  if (isTabletWidth(screenWidth)) {
    return 'tablet';
  }

  return 'mobile';
};

export const getScreenRelativeStyles = <T>(screenWidth: number, properties: Cms.ScreenRelative<T>): T => {
  const screenType = getScreenType(screenWidth);

  if (screenType === 'tablet') {
    if (typeof properties.tablet === 'object') {
      return { ...properties.desktop, ...properties.tablet };
    }

    return properties.tablet || properties.desktop;
  }

  if (screenType === 'mobile') {
    if (typeof properties.mobile === 'object') {
      return {
        ...properties.desktop,
        ...properties.tablet,
        ...properties.mobile,
      };
    }

    return properties.mobile || properties.desktop;
  }

  return properties.desktop;
};

export const getContentSetStyles = (screenWidth: number, parameters: Cms.ScreenRelative<Cms.ContentBlockTextScreenRelativeProps>) => {
  const screenRelative = getScreenRelativeStyles(screenWidth, parameters);

  return {
    left: `${screenRelative.horizontalPosition ?? DEFAULT_CONTENT_ALIGNMENT.desktop.horizontalPosition}%`,
    top: `${screenRelative.verticalPosition}%`,
    width: `${screenRelative.width}%`,
  };
};

export const getIsProductBlock = (block: Maybe<Cms.AnyBlock | Cms.CustomBlockModel>): block is Cms.ProductBlock =>
  block?.blockType === 'product';

export const getIsContentBlock = (block: Maybe<Cms.AnyBlock | Cms.CustomBlockModel>): block is Cms.ContentBlock =>
  block?.blockType === 'content';

export const getIsUrlExternal = (url: string) => !getIsUrlSameOrigin(url);

export const getIsUrlSameOrigin = (url: string) => {
  const currentOrigin = window.location.origin;
  const linkOrigin = getSafeUrl(url)?.origin;

  return currentOrigin === linkOrigin;
};

export const getInternalLinkForSameOrigin = (url: string) => {
  if (!getIsUrlSameOrigin(url)) {
    return url;
  }

  const link = new URL(url);

  return `${link.pathname}${link.search}`;
};

export const createEmptySection = (pageMarkets: string[]): Cms.Section => ({
  groupsOrder: [],
  id: nanoid(),
  visibleFor: {
    isHidden: false,
    marketIds: pageMarkets,
    screen: ['desktop', 'tablet', 'mobile'],
  },
});

export const calculateProductsPerRow = (screenWidth: number) =>
  isDesktopWidth(screenWidth) ? DESKTOP_PRODUCTS_PER_ROW
  : isTabletWidth(screenWidth) ? TABLET_PRODUCTS_PER_ROW
  : MOBILE_PRODUCTS_PER_ROW;

export const getProductBlockHeight = (rowHeight: number, position: Cms.BlockPositionRelativeProps) => {
  return rowHeight * position.spanY + PRODUCT_PADDING * (position.spanY - 1);
};

const PUBLISHED = 'published';
const WITH_CHANGES = 'published_with_changes';

const publishedStatuses = [PUBLISHED, WITH_CHANGES];

export const getIsPublished = (status: Maybe<string>) => {
  return isDefined(status) && publishedStatuses.includes(status);
};

export const getHasUnpublishedChanges = (status: Maybe<string>) => isDefined(status) && status === WITH_CHANGES;

export const getContentBlockPart = (block: Nullable<Cms.AnyBlock>, partIndex?: number) => {
  if (!isDefined(block) || !getIsContentBlock(block) || !isDefined(partIndex)) {
    return;
  }

  const partId = getTextPartsOrderForBlock(block)[partIndex];

  if (!isDefined(partId)) {
    return;
  }

  return block.settings.text?.parts[partId];
};

export const getUniquePageProducts = (blocks: Record<Id, Cms.AnyBlock>) => {
  const productBlockProducts = Object.values(blocks)
    .filter(getIsProductBlock)
    .flatMap(block => block.settings.products);

  const contentBlockProducts = Object.values(blocks)
    .filter(getIsContentBlock)
    .map(block => block.settings.hotspots)
    .flatMap(hotspot => hotspot?.points.flatMap(point => point.general.productId))
    .filter(isDefined);

  const allProducts = [...productBlockProducts, ...contentBlockProducts];

  return [...new Set(allProducts)];
};

export const isBlockMediaDefined = (block: Cms.AnyBlock | undefined) => {
  if (!isDefined(block) || !getIsContentBlock(block)) {
    return false;
  }
  const media = block.settings.background?.general.media;

  return isDefined(media);
};

export const isBlockColorDefined = (block: Cms.AnyBlock | undefined) => {
  if (!isDefined(block) || !getIsContentBlock(block)) {
    return false;
  }

  return isDefined(block.settings.background?.general.color);
};

export const isBlockOverlayDefined = (block: Cms.AnyBlock | undefined) =>
  isDefined(block) && getIsContentBlock(block) && isDefined(block.settings.overlay);

interface SaveObjectProps {
  sections: Cms.Section[];
  groups: Record<string, Cms.Group>;
  blocks: Record<string, Cms.AnyBlock>;
  localizations: Cms.BlockLocalizations;
  modifiedLanguages: Translations.SupportedLanguagesCodes[];
  pageLanguageCodes: Translations.SupportedLanguagesCodes[];
}

export const buildSaveObject = ({ sections, groups, blocks, localizations, modifiedLanguages, pageLanguageCodes }: SaveObjectProps) => {
  const combinedSections = sections.map(section => {
    const sectionGroups = section.groupsOrder.map(groupId => groups[groupId]).filter(isDefined);
    const sectionBlocks = sectionGroups.flatMap(group => group.blocks.map(blockId => blocks[blockId])).filter(isDefined);

    return {
      ...section,
      blocks: sectionBlocks,
      groups: listToRecord(sectionGroups, 'id'),
    };
  });

  return {
    localizations: pick(pageLanguageCodes, localizations),
    modifiedLanguages,
    sections: combinedSections,
    version: latestPageStructureVersion,
  };
};

export const getPageIdsGroupedBySlug = pipe((pages: Cms.Page[]) => groupBy(prop('slug'), pages), values, flatten, map(prop('id')));

export const getIsPageMenuItem = (item: Navigation.AnyMenuItem): item is Navigation.PageMenuItem => item.type === 'page';

export const getIsLinkMenuItem = (item: Navigation.AnyMenuItem): item is Navigation.LinkMenuItem => item.type === 'link';

export const getIsFolderMenuItem = (item: Navigation.AnyMenuItem): item is Navigation.FolderMenuItem => item.type === 'folder';

export const getHasCurrentMarket = (item: Navigation.AnyMenuItem, market: Id) =>
  (getIsPageMenuItem(item) || getIsLinkMenuItem(item)) && item.markets?.includes(market);

export const isHiddenInEditorForScreen = (visibility: Cms.ScreenType[], currentScreen: Cms.ScreenType) =>
  !visibility.includes(currentScreen);

export const isPublishedPage = (page: Cms.Page) => page.status === 'published';

export const isPublishedWithChanges = (page: Cms.Page) => page.status === 'published_with_changes';

export const isPageSnapshot = (page: Cms.Page | Cms.PageSnapshot): page is Cms.PageSnapshot => page.status === 'snapshot';

export const getIsPageAvailable = (page: Nullable<Cms.Page | Cms.PageSnapshot>): page is Cms.PageSnapshot | Cms.Page =>
  isDefined(page) && (isPageSnapshot(page) || isPublishedPage(page));

export const getPublishedPageData = (page: Cms.Page | undefined) => {
  if (!isDefined(page)) {
    return null;
  }

  if (isPublishedWithChanges(page)) {
    return page.snapshot;
  }

  return page;
};

export const getUniqSlugs = (pages: Cms.Page[]) => uniq(pages.map(page => page.slug));

export const getPagesMapBySlug = (slug: string) =>
  pipe(
    (pages: Cms.Page[]) => groupBy(prop('slug'), pages),

    (pagesMap: Record<string, Cms.Page[]>) => pagesMap[slug] ?? [],
  );

export const getUniqMarketsNames = (pages: Cms.Page[]) => uniq(pages.flatMap(page => page.markets).map(market => market.name));

interface BasePage {
  isHomepage: boolean;
  label: string;
  url: string;
}

export const getPagesListForOrderShare = (
  navigationItems: Navigation.UserMenuItem[],
  homepageSlug: Maybe<string>,
  basePages: BasePage[],
): BasePage[] => {
  const pages: BasePage[] = navigationItems
    .filter(page => page.type === 'page')
    .map(({ label, url }) => ({
      isHomepage: false,
      label,
      url: url!,
    }));
  const navigationPages = pages.reduce(
    (
      acc: {
        homepage: BasePage;
        pages: BasePage[];
      },
      cur,
    ) => (cur.url !== homepageSlug ? { ...acc, pages: [...acc.pages, cur] } : { ...acc, homepage: { ...cur, isHomepage: true } }),
    {
      // eslint-disable-next-line no-warning-comments
      // TODO check if using null here might be better
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, no-warning-comments
      homepage: {} as BasePage,
      pages: [],
    },
  );

  return [...(isEmpty(navigationPages.homepage) ? [] : [navigationPages.homepage]), ...basePages, ...navigationPages.pages];
};

export const getShareOrderPath = (selectedPage: string) => {
  const INTERNAL_PATHS = ['products', 'orders', 'checkout'];

  if (INTERNAL_PATHS.includes(selectedPage)) {
    return `${selectedPage}`;
  }

  return `pages/${selectedPage}`;
};

export const getSharePageData = (pageData: Cms.Page | undefined) => {
  return isDefined(pageData) ?
      {
        ...pick(['name', 'markets', 'slug'], pageData.snapshot || pageData),
        id: pageData.id,
      }
    : null;
};

export const getPublishedPagesWithPartStyle = (pages: Cms.Page[], partStyleId: string, isTextStyle = true) => {
  return pages.filter(page => {
    const styles = isTextStyle ? page.snapshot?.textStyles : page.snapshot?.buttonStyles;

    return isDefined(styles) && styles.map(style => style.id).includes(partStyleId);
  });
};

export const getIsBlockLocalizable = (block: Cms.AnyBlock): block is Cms.ContentBlock => {
  const localizableSettings = ['text', 'link'];

  return (
    getIsContentBlock(block) && Object.entries(block.settings).some(([key, value]) => localizableSettings.includes(key) && isDefined(value))
  );
};

export const getIsBlockFullyLocalized = (originalParameters: Cms.AnyBlock, localizedParameters: Cms.LocalizedBlockSettings) => {
  if (!getIsContentBlock(originalParameters)) {
    return true;
  }

  const getIsParameterLocalized = (parameterPath: string[]) => {
    if (!pathSatisfies(isDefined, parameterPath, originalParameters)) {
      return true;
    }

    return pathSatisfies(isDefined, parameterPath, localizedParameters);
  };

  const textPartsIds = originalParameters.settings.text?.partsOrder ?? [];

  const hasLocalizedLink = getIsParameterLocalized(['settings', 'link']);
  const hasLocalizedContent = textPartsIds.every(partId => getIsParameterLocalized(['settings', 'text', 'parts', partId]));

  return hasLocalizedLink && hasLocalizedContent;
};

export const composeStatusUpdate = (
  languages: Translations.SupportedLanguagesCodes[],
  status: Omit<Cms.PageStatus, 'published_with_changes'>,
) => {
  return languages.reduce<Cms.UpdateStatusDTO>((acc, cur) => {
    return {
      ...acc,
      [cur]: status,
    };
  }, {});
};

export const getIsAnyLanguagePublished = (pageLanguages: Maybe<Cms.PageLanguage[]>) => {
  if (!isDefined(pageLanguages)) {
    return false;
  }

  return pageLanguages.some(language => getIsPublished(language.status));
};
