import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;

export const Cancel = ({ width = ORIGINAL_WIDTH, color = 'currentColor' }: IconComponentProps) => {
  return (
    <svg width={width} height={width} viewBox="0 0 20 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.198 3.198a.677.677 0 01.958 0L10 9.042l5.844-5.844a.677.677 0 11.958.958L10.958 10l5.844 5.844a.677.677 0 01-.958.958L10 10.958l-5.844 5.844a.678.678 0 01-.958-.958L9.042 10 3.198 4.156a.677.677 0 010-.958z"
        fill={color}
      />
    </svg>
  );
};
