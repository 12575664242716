import { Dates, DeliveryWindow, ItemsEtaPerDelwin, Product } from '@centra';
import { interpolateRainbow, quantize } from 'd3';
import { uniq } from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';

import { getActiveDeliveryWindowsIdsForOrderBuyer, getFilteredDelwinIds } from '../../../../ducks';
import { getHasEtaInSelectedDeliveryWindows, getItemsEtaPerDelwin } from '../../../../logic/eta';
import { useDelwinIdsWithCurrentStockType } from '../../../../utils/hooks';
import { isEmpty } from '../../../../utils/isEmpty';
import { ProductsEtaModal } from '../ProductsEtaModal';

interface Props {
  products: Product.Standard[];
  isAllProductsPage?: boolean;
  delwinIds?: DeliveryWindow.Id[];
}

interface Context {
  hasVariantsWithEta: boolean;
  currentItemsEtaPerDelwin: ItemsEtaPerDelwin;
  allItemsEtaPerDelwin: ItemsEtaPerDelwin;
  legendColorPerEta: Record<Dates.DateAsSQLString, string>;
  isProductsEtaModalVisible: boolean;
  setIsProductsEtaModalVisible: (isVisible: boolean) => void;
  uniqueEtaDates: Dates.DateAsSQLString[];
  currentStockTypeDelwinIds: DeliveryWindow.Id[];
}

const initialContext = {
  allItemsEtaPerDelwin: {},
  currentItemsEtaPerDelwin: {},
  currentStockTypeDelwinIds: [],
  hasVariantsWithEta: false,
  isAllProductsPage: false,
  isProductsEtaModalVisible: false,
  legendColorPerEta: {},
  setIsProductsEtaModalVisible: () => null,
  uniqueEtaDates: [],
};

export const ProductsEtaContext = React.createContext<Context>(initialContext);

export const ProductsEtaContextProvider = ({ products, children, isAllProductsPage = false, delwinIds }: React.WithChildren<Props>) => {
  const activeDeliveryWindowsIds = useSelector(getActiveDeliveryWindowsIdsForOrderBuyer);
  const filteredDelwinIds = useSelector(getFilteredDelwinIds);

  const [isProductsEtaModalVisible, setIsProductsEtaModalVisible] = React.useState(false);

  const delwinIdsWithCurrentStockType = useDelwinIdsWithCurrentStockType(
    isEmpty(filteredDelwinIds) ? activeDeliveryWindowsIds : filteredDelwinIds,
  );

  const currentStockTypeDelwinIds = delwinIds ?? (isAllProductsPage ? delwinIdsWithCurrentStockType : activeDeliveryWindowsIds);

  const hasEtaInSelectedDeliveryWindows = React.useMemo(
    () => getHasEtaInSelectedDeliveryWindows(currentStockTypeDelwinIds, products),
    [products, currentStockTypeDelwinIds],
  );

  const currentItemsEtaPerDelwin = React.useMemo(
    () => getItemsEtaPerDelwin(currentStockTypeDelwinIds, products),
    [currentStockTypeDelwinIds, products],
  );

  const allItemsEtaPerDelwin = React.useMemo(
    () => getItemsEtaPerDelwin(activeDeliveryWindowsIds, products),
    [activeDeliveryWindowsIds, products],
  );

  const uniqueEtaDates = React.useMemo(
    () => uniq(Object.values(allItemsEtaPerDelwin).flatMap(etasPerItem => Object.values(etasPerItem).flat())),
    [allItemsEtaPerDelwin],
  );

  const uniqueEtaDatesByDay = React.useMemo(
    () => uniq(uniqueEtaDates.map(eta => eta.slice(0, 10))) as Dates.DateAsHyphenSeparatedString[],
    [uniqueEtaDates],
  );

  const legendPalette = React.useMemo(() => quantize(interpolateRainbow, uniqueEtaDatesByDay.length + 1), [uniqueEtaDatesByDay]);

  const legendColorPerEta: Record<Dates.DateAsSQLString, string> = React.useMemo(
    () =>
      uniqueEtaDates.reduce((acc, eta) => {
        const index = uniqueEtaDatesByDay.findIndex(etaDate => eta.startsWith(etaDate));

        return {
          ...acc,
          [eta]: legendPalette[index],
        };
      }, {}),
    [legendPalette, uniqueEtaDates, uniqueEtaDatesByDay],
  );

  return (
    <ProductsEtaContext.Provider
      value={{
        allItemsEtaPerDelwin,
        currentItemsEtaPerDelwin,
        currentStockTypeDelwinIds,
        hasVariantsWithEta: hasEtaInSelectedDeliveryWindows,
        isProductsEtaModalVisible,
        legendColorPerEta,
        setIsProductsEtaModalVisible,
        uniqueEtaDates,
      }}
    >
      {children}
      <ProductsEtaModal />
    </ProductsEtaContext.Provider>
  );
};
