import { API, Invoice, Order, Payments } from '@typings';
import i18next from 'i18next';
import { timer } from 'rxjs';
import { filter, mergeMap, takeWhile } from 'rxjs/operators';

import { paymentsRepository, selectionRepository } from '../services';
import { isEmpty } from '../utils/isEmpty';

import { getIsOrderClosed, getIsOrderSplit } from './Orders';

const POLLING_INTERVAL = 3000;
const POLLING_ATTEMPTS = 20;

const shouldPollInvoice = (response: API.SuccessResponse<Invoice.PaymentResult> | API.FailedResponse<unknown>, index: number) => {
  const isSuccessResponse = response.status === 'SUCCESS';
  const isTimeout = index === POLLING_ATTEMPTS;

  return (!isSuccessResponse || response.data.status !== 'PAID') && !isTimeout;
};

export const invoicePaymentResultLongPolling = (identifiers: Invoice.InvoiceIdentifiers) =>
  timer(0, POLLING_INTERVAL).pipe(
    mergeMap(async () => paymentsRepository.getInvoicePaymentResult(identifiers)),
    takeWhile(shouldPollInvoice, true),
    filter((response, index) => !shouldPollInvoice(response, index)),
  );

const shouldPollOrder = (response: API.SuccessResponse<Order.Single | Order.Split> | API.FailedResponse<unknown>, index: number) => {
  const isSuccessResponse = response.status === 'SUCCESS';
  const isTimeout = index === POLLING_ATTEMPTS;
  const isOrderCreated = isSuccessResponse && (getIsOrderSplit(response.data) || getIsOrderClosed(response.data.order));

  return !isOrderCreated && !isTimeout;
};

export const orderPaymentResultLongPolling = (orderId: Order.Id) =>
  timer(0, POLLING_INTERVAL).pipe(
    mergeMap(async () => selectionRepository.loadOrder(orderId)),
    takeWhile(shouldPollOrder, true),
    filter((response, index) => !shouldPollOrder(response, index)),
  );

export const getPaymentMethodsByMode = (
  paymentTerm: Maybe<Order.PaymentTerm>,
  orderPaymentMethods: Payments.PaymentMethod[],
): Payments.PaymentMethod[] => {
  const paymentMode = paymentTerm?.paymentMode ?? 'disabled';

  if (paymentMode === 'disabled' || isEmpty(orderPaymentMethods)) {
    return [];
  }

  if (paymentMode === 'required') {
    return orderPaymentMethods;
  }

  return [
    {
      name: i18next.t('payments:pay_later_within_days', { count: paymentTerm?.dueDays }),
      paymentMethod: 'pay_later',
      paymentMethodType: 'pay_later',
    },
    ...orderPaymentMethods,
  ];
};
