import cx from 'classnames';
import { ParseKeys } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { isDefined } from '../../../utils/is';

import styles from './StatusLabel.module.scss';

type StatusMapping = Partial<Record<string, { color: string; i18nKey: ParseKeys<['invoices', 'checkout', 'shipping', 'payments']> }>>;

interface Props {
  status: string;
  text?: string;
}

const createStatusLabel =
  (mapping: StatusMapping, dataTestId: string) =>
  ({ status, text }: Props) => {
    const { t } = useTranslation(['invoices', 'checkout', 'shipping', 'payments']);

    const label = React.useMemo(() => {
      const statusData = mapping[status];

      if (isDefined(statusData)) {
        return t(statusData.i18nKey) as string;
      }

      return '';
    }, [status, t]);

    return (
      <span data-testid={dataTestId} className={cx(styles.container, mapping[status]?.color)}>
        {text ?? label}
      </span>
    );
  };

export const InvoiceStatusLabel = createStatusLabel(
  {
    'Not paid': { color: styles.orange, i18nKey: 'invoices:not_paid' },
    Overdue: { color: styles.red, i18nKey: 'invoices:overdue' },
    Paid: { color: styles.green, i18nKey: 'invoices:paid' },
  },
  'invoice-status-label',
);

export const PaymentStatusLabel = createStatusLabel(
  {
    failed: { color: styles.red, i18nKey: 'payments:failed' },
    processing: { color: styles.normal, i18nKey: 'payments:processing' },
    success: { color: styles.green, i18nKey: 'payments:paid' },
  },
  'payment-status-label',
);

export const ShipmentStatusLabel = createStatusLabel(
  {
    'not shipped': { color: styles.orange, i18nKey: 'shipping:not_shipped' },
    shipped: { color: styles.green, i18nKey: 'shipping:shipped' },
  },
  'shipment-status-label',
);
