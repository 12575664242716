/* eslint-disable max-len */

import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;
const ORIGINAL_COLOR = '#848484';

export const Unlink = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => (
  <svg width={width} height={width} viewBox="0 0 20 20" fill="none">
    <path
      d="m14.546 6.578-1.87 1.867a.25.25 0 0 0-.054.273c.206.508.312 1.05.31 1.6v.1a.25.25 0 0 0 .427.177l2.625-2.625A3.5 3.5 0 0 0 13.502 2v.001c-.898 0-1.795.34-2.479 1.025l-2.622 2.62a.25.25 0 0 0 .182.427h.093a4.2 4.2 0 0 1 1.597.312.25.25 0 0 0 .273-.055l1.882-1.88.014-.014h.002l.056-.053a1.5 1.5 0 0 1 2.05 2.19l-.004.005ZM4.457 12.424l1.87-1.867a.25.25 0 0 0 .055-.273 4.207 4.207 0 0 1-.31-1.6v-.1a.25.25 0 0 0-.428-.177L3.02 11.032a3.5 3.5 0 0 0 2.482 5.97l.001-.001c.897 0 1.794-.34 2.478-1.025l2.622-2.62a.25.25 0 0 0-.183-.427h-.092a4.204 4.204 0 0 1-1.597-.312.25.25 0 0 0-.273.055l-1.882 1.88-.015.014a1.5 1.5 0 0 1-2.106-2.137l.003-.005Zm11.27 4.44 1.132-1.132a.2.2 0 0 0 0-.282L3.556 2.147a.2.2 0 0 0-.283 0l-1.13 1.131a.2.2 0 0 0 0 .283l13.301 13.303a.2.2 0 0 0 .283 0Z"
      fill={color}
    />
  </svg>
);
