import React from 'react';

import Icon, { IconType } from '../Icon';
import { Tooltip } from '../Tooltip';

import styles from './Feature.module.scss';

interface MarkProps {
  icon: IconType;
  isActive: boolean;
  title: string;
}

const iconsStyle = {
  width: 16,
};

export const Feature = (props: React.WithChildren) => <div className={styles.feature}>{props.children}</div>;

const getPopupContainer = (el: HTMLDivElement | null) => el?.parentElement?.parentElement ?? el;

export const Mark = ({ icon, isActive, title }: MarkProps) => {
  const markRef = React.useRef<HTMLDivElement>(null);

  const popupContainer = React.useMemo(() => {
    return getPopupContainer(markRef.current);
  }, [markRef]);

  if (!isActive) {
    return null;
  }

  return (
    <Tooltip content={title} container={popupContainer ?? undefined}>
      <div ref={markRef} className={styles.mark}>
        <Icon style={iconsStyle} type={icon} />
      </div>
    </Tooltip>
  );
};
