import cx from 'classnames';
import { useSelector } from 'react-redux';

import { getIsSavingAnything } from '../../../../ducks/ui';
import Icon, { IconType } from '../../../various/Icon';

import styles from './SyncLoader.module.scss';

export const SyncLoader = () => {
  const isSyncing = useSelector(getIsSavingAnything);

  const spinnerClasses = cx(styles.spinner, {
    [styles.fadeIn]: isSyncing,
    [styles.fadeOut]: !isSyncing,
  });

  const syncedClasses = cx(styles.synced, {
    [styles.fadeIn]: !isSyncing,
    [styles.fadeOut]: isSyncing,
  });

  const animatedIconClasses = cx(styles.animatedIcon, {
    [styles.fadeOutAll]: !isSyncing,
    [styles.fadeInAll]: isSyncing,
  });

  return (
    <div className={animatedIconClasses}>
      <Icon type={IconType.Spinner} className={spinnerClasses} />
      <Icon type={IconType.Synced} className={syncedClasses} />
    </div>
  );
};
