/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;

export const DelwinStock = ({ width = ORIGINAL_WIDTH }: IconComponentProps) => {
  return (
    <svg width={width} height={width} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7646 4.51966C9.72434 4.30367 8.64598 4.41407 7.66594 4.83688C6.6859 5.25969 5.8482 5.97592 5.25882 6.89497C4.66943 7.81402 4.35484 8.89459 4.35484 10C4.35484 11.1054 4.66943 12.186 5.25882 13.105C5.8482 14.0241 6.6859 14.7403 7.66594 15.1631C8.64598 15.5859 9.72434 15.6963 10.7646 15.4803C11.8048 15.2644 12.7603 14.7317 13.51 13.9497C13.7744 13.6739 14.2034 13.6737 14.468 13.9493C14.7327 14.2249 14.7328 14.6719 14.4684 14.9477C13.5292 15.9273 12.3324 16.5945 11.0293 16.8651C9.72622 17.1356 8.37539 16.9973 7.14772 16.4677C5.92005 15.9381 4.87069 15.0408 4.13238 13.8896C3.39408 12.7383 3 11.3847 3 10C3 8.61529 3.39408 7.26168 4.13238 6.11042C4.87069 4.95915 5.92005 4.06194 7.14772 3.5323C8.37539 3.00265 9.72622 2.86436 11.0293 3.13492C12.3322 3.40543 13.5288 4.07248 14.4679 5.05171C14.468 5.0519 14.4682 5.05209 14.4684 5.05228L15.6452 6.27504V4.54279C15.6452 4.15289 15.9484 3.83682 16.3226 3.83682C16.6967 3.83682 17 4.15289 17 4.54279V7.9759C17 8.36579 16.6967 8.68187 16.3226 8.68187H13.0283C12.6542 8.68187 12.3509 8.36579 12.3509 7.9759C12.3509 7.586 12.6542 7.26992 13.0283 7.26992H14.6837L13.5109 6.0512L13.51 6.05026C12.7603 5.26831 11.8048 4.73564 10.7646 4.51966Z"
        fill="#848484"
      />
    </svg>
  );
};
