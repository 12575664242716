import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  OnChangeFn,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';
import { Cms, Id } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { DEFAULT_ITEMS_COUNT_PER_REQUEST } from '../../../constants/limits';
import { unpublishPageRequest } from '../../../ducks/pages';
import { languagesSorter } from '../../../logic/cms/pagesList';
import { useDeletePage } from '../../../services/hooks/pages/useDeletePage';
import { useDuplicatePage } from '../../../services/hooks/pages/useDuplicatePage';
import { composeEditorPreviewPath } from '../../../utils/composeEditorPreviewPath';
import { useModalVisibility, usePageScrolling } from '../../../utils/hooks';
import { useTable } from '../../../utils/hooks/table/useTable';
import { useTableColumnsConfiguration } from '../../../utils/hooks/table/useTableColumnsConfiguration';
import { useConfirmationGuard } from '../../../utils/hooks/useConfirmationGuard';
import { useUrlSort } from '../../../utils/hooks/useUrlSort';
import { isEmpty } from '../../../utils/isEmpty';
import { ACTION_COLUMN_WIDTH, getUpdatedStateValue, renderStandardCell } from '../../../utils/table';
import { EditorLanguageContext } from '../../cms/context/EditorLanguageContext';
import { ListFilter } from '../../ListFilter';
import { IconType } from '../../various/Icon';
import { PopoverMenu } from '../../various/PopoverMenu';
import { Table } from '../../various/Table';
import { TableControls } from '../../various/TableControls';
import { Wrapper, WrapperSize } from '../../various/Wrapper';
import { DescriptionCell, LanguagesCell, MarketsCell, SlugCell, TitleCell } from '../cells';
import { PagesListFallback } from '../PagesListFallback';

import styles from './PagesTable.module.scss';

interface Props {
  dataSource: Cms.Page[];
  statusFilter: Cms.PageStatus | '';
  isLoading: boolean;
  setStatusFilter: (filter: Cms.PageStatus) => void;
  setEditingPage: (id: Id) => void;
  openSharePage: (id: Id) => void;
}

const columnHelper = createColumnHelper<Cms.Page>();

export const PagesTable = ({ statusFilter, isLoading, setStatusFilter, dataSource, setEditingPage, openSharePage }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(['confirmationConfig', 'common', 'cms']);

  const { editorLanguage } = React.useContext(EditorLanguageContext);
  const { columnsVisibility } = useTableColumnsConfiguration({ tableName: 'pages' });
  const { mutate: deletePage } = useDeletePage();
  const { mutate: duplicatePage, isPending: isDuplicatingPage } = useDuplicatePage();
  const [sort, setSort] = useUrlSort();
  const [sorting, setSorting] = React.useState<SortingState>(sort !== null ? [sort] : []);
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: DEFAULT_ITEMS_COUNT_PER_REQUEST,
  });
  const { scrollToTopDelay } = usePageScrolling();

  React.useEffect(() => {
    scrollToTopDelay();
  }, [pagination]);

  const handleSortingChange: OnChangeFn<SortingState> = updaterOrValue => {
    const newSorting = getUpdatedStateValue(updaterOrValue, sorting);

    setSort(newSorting[0] ?? null);
    setSorting(updaterOrValue);
  };

  const { showModal: showSettingsModal } = useModalVisibility('EditPageFormModal');

  const confirmationGuard = useConfirmationGuard();

  const openPageSettings = React.useCallback(
    (id: string) => {
      showSettingsModal();
      setEditingPage(id);
    },
    [setEditingPage, showSettingsModal],
  );

  const handleDeletePage = React.useCallback(
    confirmationGuard((id: Id) => ({
      ...t('confirmationConfig:delete_page', { returnObjects: true }),
      okText: t('confirmationConfig:delete_page.title'),
      onOk: () => deletePage({ pageId: id }),
    })),
    [t],
  );

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: info => <TitleCell text={info.getValue()} row={info.row.original} />,
        enableHiding: false,
        header: t('common:title'),
      }),
      columnHelper.accessor('slug', {
        cell: info => <SlugCell slug={info.getValue()} row={info.row.original} />,
        enableHiding: false,
        header: t('common:uri'),
      }),
      columnHelper.accessor('languages', {
        cell: info => <LanguagesCell languages={info.getValue() || []} row={info.row.original} filter={statusFilter} />,
        header: t('common:language_other'),
        meta: {
          width: 180,
        },
        sortingFn: (rowA, rowB) => languagesSorter(rowA.original, rowB.original),
      }),
      columnHelper.accessor('markets', {
        cell: info => <MarketsCell marketsData={info.getValue()} row={info.row.original} />,
        header: t('cms:market_other'),
      }),
      columnHelper.accessor('createdDate', {
        cell: renderStandardCell,
        header: t('cms:created_at'),
        meta: {
          skeletonType: 'multi',
        },
      }),
      columnHelper.accessor('lastModifiedDate', {
        cell: renderStandardCell,
        header: t('cms:last_modified_at'),
        meta: {
          skeletonType: 'multi',
        },
      }),
      columnHelper.accessor('lastPublishedDate', {
        cell: renderStandardCell,
        header: t('cms:last_published_at'),
        meta: {
          skeletonType: 'multi',
        },
      }),
      columnHelper.accessor('description', {
        cell: info => <DescriptionCell description={info.getValue()} row={info.row.original} />,
        header: t('common:description'),
        meta: {
          skeletonType: 'multi',
        },
      }),
      columnHelper.display({
        cell: info => {
          const row = info.row.original;
          const { id } = row;

          return (
            <PopoverMenu
              overflowContainer
              items={[
                {
                  icon: IconType.Edit,
                  key: 'edit',
                  label: t('cms:edit_content'),
                  onClick: () => navigate(composeEditorPreviewPath({ lang: editorLanguage, pageId: id, pathType: 'editor' })),
                },
                {
                  icon: IconType.Eye,
                  key: 'preview',
                  label: t('cms:preview_page'),
                  onClick: () =>
                    window.open(composeEditorPreviewPath({ lang: editorLanguage, pageId: id, pathType: 'preview' }), '_newtab'),
                },
                {
                  icon: IconType.Cog,
                  key: 'setting',
                  label: t('cms:page_settings'),
                  onClick: () => openPageSettings(id),
                },
                {
                  icon: IconType.Duplicate,
                  key: 'duplicate',
                  label: t('cms:duplicate_page'),
                  onClick: () => duplicatePage({ pageId: id }),
                },
                {
                  icon: IconType.Unpublish,
                  isDisabled: row.status === 'unpublished',
                  key: 'unpublish',
                  label: t('cms:unpublish'),
                  onClick: () => dispatch(unpublishPageRequest({ id, languages: row.availableLanguages ?? [] })),
                },
                {
                  icon: IconType.Link,
                  isDisabled: row.status === 'unpublished',
                  key: 'share',
                  label: t('cms:get_shareable_link'),
                  onClick: () => openSharePage(id),
                },
                {
                  icon: IconType.Trash,
                  key: 'delete',
                  label: t('cms:delete_page'),
                  onClick: () => handleDeletePage(id),
                },
              ]}
              name="Page options"
            />
          );
        },
        enableHiding: false,
        id: 'actions',
        meta: {
          skeletonType: 'actions',
          width: ACTION_COLUMN_WIDTH,
        },
      }),
    ],
    [t, statusFilter, navigate, editorLanguage, openPageSettings, duplicatePage, dispatch, openSharePage, handleDeletePage],
  );

  const filters = React.useMemo<{ name: string; value: Cms.PageStatus | '' }[]>(
    () => [
      {
        name: t('common:all'),
        value: '',
      },
      {
        name: t('cms:published'),
        value: 'published',
      },
      {
        name: t('cms:unpublished_changes'),
        value: 'published_with_changes',
      },
      {
        name: t('cms:unpublished'),
        value: 'unpublished',
      },
    ],
    [t],
  );

  const shouldShowPages = isLoading || !isEmpty(dataSource);
  const table = useTable(
    {
      autoResetPageIndex: false,
      columns,
      data: dataSource,
      getCoreRowModel: getCoreRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      getSortedRowModel: getSortedRowModel(),
      onPaginationChange: setPagination,
      onSortingChange: handleSortingChange,
      state: {
        pagination,
        sorting,
      },
    },
    columnsVisibility,
  );

  return (
    <Wrapper size={WrapperSize.XXLARGE}>
      <div className={styles.filtersWrapper}>
        <ListFilter
          className={styles.statusFilters}
          name="pageStatus"
          title={t('cms:select_page_status')}
          onChange={setStatusFilter}
          selected={statusFilter}
          filters={filters}
        />
        <TableControls table={table} columnsConfiguration={columnsVisibility} tableName="pages" />
      </div>
      {shouldShowPages ?
        <Table
          table={table}
          isLoading={isLoading || isDuplicatingPage}
          pagination={{ current: pagination.pageIndex + 1, hideOnSinglePage: true, total: dataSource.length }}
        />
      : <PagesListFallback />}
    </Wrapper>
  );
};
