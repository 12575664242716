import React from 'react';

import { isDefined } from '../is';

export const useOnValueChange = <T>(value: T, callback: () => void) => {
  const currentValue = React.useRef(value);

  React.useEffect(() => {
    if (isDefined(value) && currentValue.current !== value) {
      callback();
      currentValue.current = value;
    }
  }, [value]);
};
