import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getShouldShowEtaOnMobile, toggleEtaVisibilityOnMobile } from '../../../../ducks';
import { IconType } from '../../../various/Icon';
import { IconButton } from '../../../various/IconButton/IconButton';
import { ProductsEtaContext } from '../../ProductsEta';

import styles from './MatrixHeader.module.scss';

export const MatrixHeaderEtaActions = () => {
  const { t } = useTranslation(['common']);
  const dispatch = useDispatch();
  const shouldShowEtaOnMobile = useSelector(getShouldShowEtaOnMobile);
  const { setIsProductsEtaModalVisible } = React.useContext(ProductsEtaContext);

  const handleAvailabilityButtonClick = React.useCallback(() => {
    dispatch(toggleEtaVisibilityOnMobile());
  }, [dispatch]);

  const showEtaModal = React.useCallback(() => {
    setIsProductsEtaModalVisible(true);
  }, [setIsProductsEtaModalVisible]);

  return (
    <div className={styles.actions}>
      <button type="button" className={styles.availabilityButton} onClick={handleAvailabilityButtonClick}>
        {shouldShowEtaOnMobile ? t('common:hide_availability') : t('common:show_availability')}
      </button>
      <IconButton name="ETA legend" icon={IconType.Calendar} iconSize={20} variant="secondary" size="small" onClick={showEtaModal} />
    </div>
  );
};
