export const dispatchStorageEvent = (key: Maybe<string>, newValue: Maybe<string>, oldValue: Maybe<string>) => {
  const storageEvent = new StorageEvent('storage', {
    key,
    newValue,
    oldValue,
    url: window.location.href,
  });

  window.dispatchEvent(storageEvent);
};
