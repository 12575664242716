import cx from 'classnames';
import { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import { IconType } from '../../../../../../various/Icon';
import { HoverTools } from '../../../../../hoverTools';
import hoverToolsStyles from '../../../../../hoverTools/HoverTools.module.scss';

import styles from './BlockButtonWithHoverTools.module.scss';

interface Props extends HTMLAttributes<HTMLButtonElement> {
  shouldShowActions: boolean;
  isProductBlock: boolean;
  isContentBlock: boolean;
  handleSwitchType: () => void;
  handleRemoveBlock: () => void;
}

export const BlockButtonWithHoverTools = ({
  shouldShowActions,
  isProductBlock,
  isContentBlock,
  handleSwitchType,
  handleRemoveBlock,
  ...restProps
}: Props) => {
  const { t } = useTranslation(['cms']);
  const blockHandleButton = <button type="button" className={styles.blockHandle} {...restProps} />;

  if (!shouldShowActions) {
    return blockHandleButton;
  }

  return (
    <HoverTools
      className={cx(styles.hoverTools, {
        [hoverToolsStyles.product]: isProductBlock,
        [hoverToolsStyles.content]: isContentBlock,
      })}
      elements={[
        {
          icon: IconType.Swap,
          key: 'chooseProducts',
          label: t('cms:switch_block_type'),
          onClick: handleSwitchType,
          testId: 'switchType',
        },
        {
          icon: IconType.TrashLinear,
          key: 'chooseProducts',
          label: t('cms:remove_block'),
          onClick: handleRemoveBlock,
          testId: 'removeBlock',
        },
      ]}
    >
      {blockHandleButton}
    </HoverTools>
  );
};
