import { Alerts } from '@typings';
import { useTranslation } from 'react-i18next';

import { Alert } from '../../various/Alert';
import Icon, { IconType } from '../../various/Icon';
import { Popover } from '../../various/Popover';

import styles from './PopoverWarning.module.scss';

interface Props {
  warnings: {
    message: string;
    type?: Alerts.AlertType;
  }[];
}

export const PopoverWarning = ({ warnings }: Props) => {
  const warningsCount = warnings.length;
  const { t } = useTranslation(['common']);

  if (warningsCount === 0) {
    return null;
  }

  const content = (
    <div>
      {warnings.map(warning => (
        <Alert key={warning.message} className={styles.alert} type={warning.type} message={warning.message} />
      ))}
    </div>
  );

  return (
    <div className={styles.warningContainer}>
      <Popover
        shouldReturnFocusAfterClose={false}
        className={styles.popover}
        content={content}
        placement="top-start"
        possiblePlacements={['bottom-end', 'bottom-start', 'top-end', 'top-start']}
      >
        <div className={styles.iconContainer}>
          <Icon type={IconType.Warning} />
          <span>
            {t('common:warning_other')} ({warningsCount})
          </span>
        </div>
      </Popover>
    </div>
  );
};
