import React from 'react';

import { isDefined } from '../../../utils/is';
import Icon, { IconType } from '../Icon';

import styles from './EmptyState.module.scss';

interface Props {
  icon?: IconType;
  title: string;
  subtitle?: string;
  dataTestId?: string;
}

export const EmptyState = ({ dataTestId, icon = IconType.Inventory, title, subtitle, children }: React.WithOptionalChildren<Props>) => {
  return (
    <div data-testid={dataTestId} className={styles.wrapper}>
      <div className={styles.iconWrapper}>
        <Icon type={icon} size={44} />
      </div>
      <h2 className={styles.title}>{title}</h2>
      {isDefined(subtitle) && <p className={styles.subtitle}>{subtitle}</p>}
      {isDefined(children) && <div className={styles.actions}>{children}</div>}
    </div>
  );
};
