import cx from 'classnames';
import React from 'react';

import { useIsScrollingDown } from '../../../utils/hooks/useIsScrollingDown';
import Icon, { IconColor, IconType } from '../Icon';

import styles from './FloatingButton.module.scss';

interface Props {
  onClick: () => void;
  label?: string;
  dataTestId?: string;
}

export const FloatingButton = ({ onClick, label, dataTestId }: Props) => {
  const ref = React.useRef<HTMLButtonElement>(null);
  const [isFocused, setIsFocused] = React.useState(false);

  const isScrollingDown = useIsScrollingDown();

  const shouldShowLabel = !isScrollingDown || isFocused;

  return (
    <button
      ref={ref}
      className={styles.button}
      onBlur={() => setIsFocused(false)}
      onClick={onClick}
      onFocus={() => setIsFocused(true)}
      onMouseEnter={() => setIsFocused(true)}
      onMouseLeave={() => setIsFocused(false)}
      data-testid={dataTestId}
    >
      <div
        className={cx(styles.labelWrapper, {
          [styles.isOver]: shouldShowLabel,
        })}
      >
        <span
          className={cx(styles.label, {
            [styles.isOver]: shouldShowLabel,
          })}
        >
          {label}
        </span>
      </div>

      <Icon type={IconType.Plus} color={IconColor.White} size={32} />
    </button>
  );
};
