import { DatePicker } from '@typings';
import { Dayjs } from 'dayjs';
import React from 'react';

import { isNull } from '../../../../utils/is';

import { DatePickerNavigationContext } from './DatePickerNavigationContext';

interface Props {
  value: DatePicker.Value;
  onChange: (value: DatePicker.Value, shouldCloseCalendar?: boolean) => void;
}

interface Context {
  endDate: DatePicker.SingleValue;
  endDatePreview: DatePicker.SingleValue;
  handleSelect: (date: Dayjs) => void;
  isRangeSelection: boolean;
  resetSelection: () => void;
  setEndDate: (date: DatePicker.SingleValue) => void;
  setEndDatePreview: (date: DatePicker.SingleValue) => void;
  setStartDate: (date: DatePicker.SingleValue) => void;
  startDate: DatePicker.SingleValue;
}

const initialContext: Context = {
  endDate: null,
  endDatePreview: null,
  handleSelect: () => null,
  isRangeSelection: false,
  resetSelection: () => null,
  setEndDate: () => null,
  setEndDatePreview: () => null,
  setStartDate: () => null,
  startDate: null,
};

export const DatePickerSelectionContext = React.createContext<Context>(initialContext);

export const DatePickerSelectionContextProvider = ({ children, value, onChange }: React.WithChildren<Props>) => {
  const { setVisibleDate } = React.useContext(DatePickerNavigationContext);

  const [selection, setSelection] = React.useState(value);
  const [endDatePreview, setEndDatePreview] = React.useState<DatePicker.SingleValue>(null);

  const isRangeSelection = Array.isArray(selection);

  React.useEffect(() => {
    setSelection(value);
  }, [value]);

  const handleSelect = React.useCallback(
    (date: Dayjs) => {
      setVisibleDate(date);
      setEndDatePreview(null);

      if (!isRangeSelection) {
        setSelection(date);
        onChange(date, true);

        return;
      }

      const [start, end] = selection;
      const shouldUpdateStart = isNull(start) || !isNull(end) || date.isBefore(start);
      const newSelection: DatePicker.RangeValue = shouldUpdateStart ? [date, null] : [start, date];

      setSelection(newSelection);
      onChange(newSelection, !shouldUpdateStart);
    },
    [isRangeSelection, onChange, selection, setVisibleDate],
  );

  const setStartDate = React.useCallback(
    (date: DatePicker.SingleValue) => {
      if (!isNull(date)) {
        setVisibleDate(date);
      }

      if (!isRangeSelection) {
        setSelection(date);
        onChange(date);

        return;
      }

      const [, end] = selection;
      setSelection([date, end]);
      onChange([date, end]);
    },
    [isRangeSelection, onChange, selection, setVisibleDate],
  );

  const setEndDate = React.useCallback(
    (date: DatePicker.SingleValue) => {
      if (!isRangeSelection) {
        return;
      }

      if (!isNull(date)) {
        setVisibleDate(date);
      }

      const [start] = selection;
      setSelection([start, date]);
      onChange([start, date]);
    },
    [isRangeSelection, onChange, selection, setVisibleDate],
  );

  const emptySelection: DatePicker.EmptyValue = React.useMemo(() => {
    return isRangeSelection ? [null, null] : null;
  }, [isRangeSelection]);

  const resetSelection = React.useCallback(() => {
    setSelection(emptySelection);
    onChange(emptySelection);
  }, [emptySelection, onChange]);

  const [startDate = null, endDate = null] = [selection].flat();

  return (
    <DatePickerSelectionContext.Provider
      value={{
        endDate,
        endDatePreview,
        handleSelect,
        isRangeSelection,
        resetSelection,
        setEndDate,
        setEndDatePreview,
        setStartDate,
        startDate,
      }}
    >
      {children}
    </DatePickerSelectionContext.Provider>
  );
};
