import { Position } from '@typings';
import React from 'react';

import { useMatrixFocus } from './useMatrixFocus';
import { useMatrixSelect } from './useMatrixSelect';

export const useMatrixNavigation = (cellPosition: Position) => {
  const [focusableElement, setFocusableElement] = React.useState<Nullable<HTMLElement>>(null);
  const { isFocused, isFocusTarget } = useMatrixFocus(cellPosition, {
    current: focusableElement,
  });
  const { isSelected } = useMatrixSelect(cellPosition, {
    current: focusableElement,
  });

  return {
    isFocusTarget,
    isFocused,
    isSelected,
    setFocusableElement,
  };
};
