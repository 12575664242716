import { SwatchData } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { getAreBackordersEnabled } from '../../../../ducks';
import { getBackgroundBySwatch } from '../../../../utils/getBackgroundBySwatch';
import { isDefined } from '../../../../utils/is';

import styles from './GalleryDots.module.scss';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isActive: boolean;
  isAvailable: boolean;
  size?: 'small' | 'regular';
  swatchData?: SwatchData;
}

export const GalleryDot = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { isActive, isAvailable, size = 'regular', swatchData, ...rest } = props;
  const areBackordersEnabled = useSelector(getAreBackordersEnabled);

  const dotClassNames = cx(styles.dot, {
    [styles.dotActive]: isActive,
    [styles.dotSmall]: size === 'small',
    [styles.dotCrossed]: !isAvailable && !areBackordersEnabled,
  });

  const dotStyles =
    isDefined(swatchData) ?
      {
        background: getBackgroundBySwatch(swatchData),
        backgroundSize: 'cover',
      }
    : undefined;

  const buttonRef = isActive ? ref : undefined;

  const tabIndex = isActive ? 0 : -1;

  return (
    <button {...rest} ref={buttonRef} tabIndex={tabIndex} type="button" className={styles.dotButton}>
      <div className={dotClassNames} style={dotStyles} />
    </button>
  );
});
