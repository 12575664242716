/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;

export const Paste = ({ width = ORIGINAL_WIDTH, color = 'currentColor' }: IconComponentProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox="0 0 20 20" fill={color}>
      <path d="M15.6 9.3V5.1c0-.772-.628-1.4-1.4-1.4h-2.1a.7.7 0 0 0-.7-.7H7.2a.7.7 0 0 0-.7.7H4.4c-.772 0-1.4.628-1.4 1.4v9.1c0 .772.628 1.4 1.4 1.4h4.9c0 .772.628 1.4 1.4 1.4h4.9c.772 0 1.4-.628 1.4-1.4v-4.9c0-.772-.628-1.4-1.4-1.4Zm-6.3 1.4v3.5H4.4V5.1h2.1v1.4h5.6V5.1h2.1v4.2h-3.5c-.772 0-1.4.628-1.4 1.4Zm1.4 4.9v-4.9h4.9v4.9h-4.9Z" />
    </svg>
  );
};
