import { ActionHandler, DeliveryWindow, Id } from '@typings';
import { createReducer } from 'typesafe-actions';

import { isDeliveryWindowFilterGroup } from '../../logic/deliveryWindows';
import { isDefined } from '../../utils/is';
import { listToRecord } from '../../utils/normalize';
import { update } from '../../utils/update';
import { fetchOrderSuccess } from '../order';

import * as actions from './actions';

export interface DeliveryWindowsReducer {
  all: Record<DeliveryWindow.Id, DeliveryWindow.Mixed>;
  deliveryWindowsOrder: DeliveryWindow.DeliveryWindowsOrder;
  isLoading: boolean;
  isLoadingByBuyer: boolean;
  byBuyerId: Record<Id, DeliveryWindow.Id[]>;
}

const initialState: DeliveryWindowsReducer = {
  all: {},
  byBuyerId: {},
  deliveryWindowsOrder: [],
  isLoading: false,
  isLoadingByBuyer: false,
};

const handleFetchDeliveryWindowsRequest = (state: DeliveryWindowsReducer): DeliveryWindowsReducer =>
  update(state, {
    isLoading: true,
  });

const handleFetchDeliveryWindowsSuccess: ActionHandler<DeliveryWindowsReducer, typeof actions.fetchDeliveryWindowsSuccess> = (
  state,
  action,
) => {
  const deliveryWindowsOrder =
    isDefined(action.payload.deliveryWindowsOrder) ?
      action.payload.deliveryWindowsOrder.map(item => {
        const isGroup = isDeliveryWindowFilterGroup(item);

        if (!isGroup) {
          return item;
        }

        return { ...item, groupId: `groupId-${item.groupId}` };
      })
    : [];

  return update(state, {
    all:
      isDefined(action.payload.deliveryWindows) ?
        { ...state.all, ...listToRecord(action.payload.deliveryWindows, 'deliveryWindow') }
      : state.all,
    deliveryWindowsOrder,
    isLoading: false,
  });
};

const handleFetchDeliveryWindowsFailure = (state: DeliveryWindowsReducer): DeliveryWindowsReducer =>
  update(state, {
    isLoading: false,
  });

const handleFetchDeliveryWindowsByBuyerIdRequest = (state: DeliveryWindowsReducer): DeliveryWindowsReducer =>
  update(state, {
    isLoadingByBuyer: true,
  });

const handleFetchDeliveryWindowsByBuyerIdSuccess: ActionHandler<
  DeliveryWindowsReducer,
  typeof actions.fetchDeliveryWindowsByBuyerIdSuccess
> = (state, action) =>
  update(state, {
    byBuyerId: update(state.byBuyerId, {
      [action.payload.buyerId]: action.payload.ids,
    }),
    isLoadingByBuyer: false,
  });

const handleFetchDeliveryWindowsByBuyerIdFailure = (state: DeliveryWindowsReducer): DeliveryWindowsReducer =>
  update(state, {
    isLoadingByBuyer: false,
  });

const handleSetDeliveryWindows: ActionHandler<DeliveryWindowsReducer, typeof actions.setDeliveryWindows> = (state, action) =>
  update(state, {
    all: isDefined(action.payload) ? { ...state.all, ...listToRecord(action.payload, 'deliveryWindow') } : state.all,
  });

const handleFetchOrderSuccess: ActionHandler<DeliveryWindowsReducer, typeof fetchOrderSuccess> = (state, action) =>
  update(state, {
    all:
      isDefined(action.payload.deliveryWindows) ?
        {
          ...state.all,
          ...listToRecord(action.payload.deliveryWindows, 'deliveryWindow'),
        }
      : state.all,
  });

export default createReducer<DeliveryWindowsReducer, AppAction>(initialState)
  .handleAction(actions.fetchDeliveryWindowsRequest, handleFetchDeliveryWindowsRequest)
  .handleAction(actions.fetchDeliveryWindowsSuccess, handleFetchDeliveryWindowsSuccess)
  .handleAction(actions.fetchDeliveryWindowsFailure, handleFetchDeliveryWindowsFailure)
  .handleAction(actions.fetchDeliveryWindowsByBuyerIdRequest, handleFetchDeliveryWindowsByBuyerIdRequest)
  .handleAction(actions.fetchDeliveryWindowsByBuyerIdSuccess, handleFetchDeliveryWindowsByBuyerIdSuccess)
  .handleAction(actions.fetchDeliveryWindowsByBuyerIdFailure, handleFetchDeliveryWindowsByBuyerIdFailure)
  .handleAction(actions.setDeliveryWindows, handleSetDeliveryWindows)
  .handleAction(fetchOrderSuccess, handleFetchOrderSuccess);
