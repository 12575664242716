import React from 'react';

import { useDebouncedCallback, useResizeObserver } from '../../../../../utils/hooks';
import { useMatrixColumnsContext } from '../../context';
import { DistributionControls } from '../DistributionControls';
import { TotalsToggle } from '../TotalsToggle';

import styles from './ControlsHeader.module.scss';

const MIN_CONTROLS_SPACING = 28;

const getOffsetWidth = (element: React.MutableRefObject<HTMLElement | null>) => element.current?.offsetWidth ?? 0;

export const ControlsHeader = () => {
  const { canPinColumns } = useMatrixColumnsContext();

  const headerRef = React.useRef<HTMLDivElement>(null);
  const distributionsRef = React.useRef<HTMLDivElement>(null);
  const totalsToggleRef = React.useRef<HTMLButtonElement>(null);

  const maxTotalsToggleWidth = React.useRef(getOffsetWidth(totalsToggleRef));
  const maxDistributionsWidth = React.useRef(getOffsetWidth(distributionsRef));
  const [headerWidth, setHeaderWidth] = React.useState(getOffsetWidth(headerRef));

  const [isTotalsLabelCollapsed, setIsTotalsLabelCollapsed] = React.useState(false);
  const [isDistributionsLabelHidden, setIsDistributionsLabelHidden] = React.useState(false);

  const updateOverflow = React.useCallback(() => {
    setHeaderWidth(getOffsetWidth(headerRef));
  }, []);

  useResizeObserver(headerRef, useDebouncedCallback(updateOverflow));

  React.useLayoutEffect(() => {
    if (!canPinColumns) {
      setIsDistributionsLabelHidden(false);
      setIsTotalsLabelCollapsed(false);

      return;
    }

    const currentTotalsToggleWidth = getOffsetWidth(totalsToggleRef);
    const currentDistributionsWidth = getOffsetWidth(distributionsRef);

    maxTotalsToggleWidth.current = Math.max(maxTotalsToggleWidth.current, currentTotalsToggleWidth);
    maxDistributionsWidth.current = Math.max(maxDistributionsWidth.current, currentDistributionsWidth);

    const controlsFittingBase = headerWidth - maxDistributionsWidth.current;

    const areControlsFittingWithExpandedTotals = controlsFittingBase - maxTotalsToggleWidth.current > MIN_CONTROLS_SPACING;
    const areControlsFittingWithCollapsedTotals = controlsFittingBase - currentTotalsToggleWidth > MIN_CONTROLS_SPACING;

    setIsTotalsLabelCollapsed(!areControlsFittingWithExpandedTotals);
    setIsDistributionsLabelHidden(!areControlsFittingWithCollapsedTotals && isTotalsLabelCollapsed);
  }, [headerWidth, canPinColumns, isTotalsLabelCollapsed]);

  return (
    <div ref={headerRef} className={styles.controlsHeader}>
      <DistributionControls isDistributionsLabelHidden={isDistributionsLabelHidden} ref={distributionsRef} />
      <TotalsToggle isLabelCollapsed={isTotalsLabelCollapsed} ref={totalsToggleRef} />
    </div>
  );
};
