import {
  closestCorners,
  defaultDropAnimationSideEffects,
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  DropAnimation,
  KeyboardSensor,
  MeasuringConfiguration,
  MeasuringStrategy,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { Cms } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { getCurrentCustomTemplateId } from '../../../../ducks';
import {
  addNewBlock,
  areBlocksDefaultSize,
  areBlocksInTheSameOrder,
  getFillerGrid,
  removeBlock,
  sortCustomBlocks,
  updateBlock,
} from '../../../../logic/cms/customLayouts';
import { usePreviousValue } from '../../../../utils/hooks';
import { useCustomTemplates } from '../../../../utils/hooks/cms/useCustomTemplates';
import { useValidatedForm } from '../../../../utils/hooks/useValidatedForm';
import { isDefined } from '../../../../utils/is';
import { isEmpty } from '../../../../utils/isEmpty';
import { Form, FormButtons, InputField } from '../../../various/Form';
import { Button } from '../../../various/NewButton';
import { DeviceSwitcher } from '../../DeviceSwitcher';

import { AddBlockButton } from './Buttons/AddBlockButton';
import { SortableTemplateEditorBlock } from './TemplateEditorBlock/SortableTemplateEditorBlock';
import { TemplateEditorBlock } from './TemplateEditorBlock/TemplateEditorBlock';
import styles from './CustomTemplateEditor.module.scss';

interface Props {
  onClose: () => void;
}

const measuring: MeasuringConfiguration = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
};

const dropAnimation: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({}),
};

export const CustomTemplateEditor = ({ onClose }: Props) => {
  const { t } = useTranslation(['common', 'validation', 'cms']);
  const [activeDragId, setActiveDragId] = React.useState<UniqueIdentifier | null>(null);
  const [activeResizeBlock, setActiveResizeBlock] = React.useState<Cms.CustomBlockModel | null>(null);
  const previousActiveId = usePreviousValue(activeDragId);
  const [overId, setOverId] = React.useState<UniqueIdentifier | null>(null);
  const [fillerBlocks, setFillerBlocks] = React.useState<Cms.CustomBlockModel[]>([]);
  const currentCustomTemplateId = useSelector(getCurrentCustomTemplateId);
  const { customTemplates, addCustomTemplate, updateCustomTemplate, isCreateLoading, isUpdateLoading } = useCustomTemplates();
  const existingTemplate = customTemplates.find(template => template.id === currentCustomTemplateId);
  const existingTemplateBlocks = existingTemplate?.templateBlocks;
  const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates }));
  const [templateBlocks, setTemplateBlocks] = React.useState<Cms.CustomBlockModel[]>(existingTemplateBlocks ?? []);
  const sortedDesktopBlocks = React.useMemo(() => [...templateBlocks].sort(sortCustomBlocks('desktop')), [templateBlocks]);
  const sortedTabletBlocks = React.useMemo(() => [...templateBlocks].sort(sortCustomBlocks('tablet')), [templateBlocks]);
  const sortedMobileBlocks = React.useMemo(() => [...templateBlocks].sort(sortCustomBlocks('mobile')), [templateBlocks]);
  const touchedTablet = React.useMemo(
    () => !areBlocksInTheSameOrder(sortedDesktopBlocks, sortedTabletBlocks) || !areBlocksDefaultSize(sortedDesktopBlocks, 'tablet'),
    [sortedDesktopBlocks, sortedTabletBlocks],
  );

  const touchedMobile = React.useMemo(
    () => !areBlocksInTheSameOrder(sortedDesktopBlocks, sortedMobileBlocks) || !areBlocksDefaultSize(sortedDesktopBlocks, 'mobile'),
    [sortedDesktopBlocks, sortedMobileBlocks],
  );

  const validationSchema: yup.ObjectSchema<Partial<Cms.CustomTemplateFormData>> = yup.object({
    name: yup.string().trim().required(t('validation:template_name_hint')),
  });

  const formMethods = useValidatedForm({
    defaultValues: {
      name: existingTemplate?.name ?? '',
    },
    validationSchema,
  });

  const { register } = formMethods;
  const activeIndex = isDefined(activeDragId) ? templateBlocks.findIndex(item => item.id === activeDragId) : -1;
  const activeBlock = templateBlocks[activeIndex] ?? activeResizeBlock;

  const [screenType, setScreenType] = React.useState<Cms.ScreenType>('desktop');
  const blocksToRender = React.useMemo(() => [...templateBlocks, ...fillerBlocks], [templateBlocks, fillerBlocks]);
  const handleSaveTemplate = (value: Cms.CustomTemplateFormData) => {
    if (isDefined(currentCustomTemplateId)) {
      updateCustomTemplate({ id: currentCustomTemplateId, name: value.name, templateBlocks }, onClose);

      return;
    }
    addCustomTemplate({ name: value.name, templateBlocks }, onClose);
  };
  const handleAddBlock = (blockType: Cms.BlockType) => {
    setTemplateBlocks(blocks => addNewBlock(blocks, blockType));
  };
  const getUpdatedBlock = React.useCallback(
    (overBlockId: UniqueIdentifier | null): Cms.CustomBlockModel | null => {
      if (!isDefined(overBlockId) || !isDefined(activeBlock) || activeBlock.id === overBlockId) {
        return null;
      }

      const overIndex = templateBlocks.findIndex(item => item.id === overBlockId);
      const overFillerIndex = fillerBlocks.findIndex(item => item.id === overBlockId);
      const isOverOtherItem = overIndex > -1;
      const overItem = isOverOtherItem ? templateBlocks[overIndex] : fillerBlocks[overFillerIndex];
      if (!isDefined(overItem)) {
        return null;
      }

      return {
        ...activeBlock,
        position: {
          ...activeBlock.position,
          [screenType]: {
            ...activeBlock.position[screenType],
            startX: overItem.position[screenType].startX,
            startY: overItem.position[screenType].startY,
          },
        },
      };
    },
    [activeBlock, fillerBlocks, screenType, templateBlocks],
  );

  const projected = React.useMemo(() => {
    if (isDefined(previousActiveId) && isDefined(overId) && isDefined(activeBlock) && activeBlock.id !== overId) {
      const updatedBlock = getUpdatedBlock(overId);

      if (isDefined(updatedBlock)) {
        const projection = updateBlock({
          blocks: templateBlocks,
          collapseInitially: true,
          screenType,
          touchedMobile,
          touchedTablet,
          updatedBlock,
        });

        return [...projection, ...fillerBlocks];
      }
    }

    if (isDefined(activeResizeBlock)) {
      return updateBlock({
        blocks: templateBlocks,
        collapseInitially: false,
        screenType,
        touchedMobile,
        touchedTablet,
        updatedBlock: activeResizeBlock,
      });
    }

    return blocksToRender;
  }, [
    previousActiveId,
    overId,
    activeBlock,
    activeResizeBlock,
    blocksToRender,
    getUpdatedBlock,
    templateBlocks,
    screenType,
    touchedMobile,
    touchedTablet,
    fillerBlocks,
  ]);

  const handleRemoveBlock = React.useCallback(
    (index: number) => {
      setTemplateBlocks(blocks => removeBlock(blocks, index));
    },
    [setTemplateBlocks],
  );

  const handleUpdateBlock = (_: number, updatedBlock: Cms.CustomBlockModel) => {
    setTemplateBlocks(blocks => updateBlock({ blocks, collapseInitially: false, screenType, touchedMobile, touchedTablet, updatedBlock }));
  };

  const gridClassName = cx(styles.grid, {
    [styles.mobile]: screenType === 'mobile',
    [styles.tablet]: screenType === 'tablet',
    [styles.desktop]: screenType === 'desktop',
  });

  const handleDragStart = React.useCallback(
    ({ active }: DragStartEvent) => {
      setActiveDragId(active.id);
      const activeDragItem = isDefined(active.id) ? templateBlocks.find(item => item.id === active.id) : null;
      if (activeDragItem) {
        setFillerBlocks(getFillerGrid(templateBlocks, screenType, activeDragItem));
      }
    },
    [screenType, templateBlocks],
  );

  const handleDragEnd = React.useCallback(
    ({ over }: DragEndEvent) => {
      const updatedBlock = getUpdatedBlock(over?.id ?? null);
      if (isDefined(updatedBlock)) {
        setTemplateBlocks(blocks =>
          updateBlock({ blocks, collapseInitially: true, screenType, touchedMobile, touchedTablet, updatedBlock }),
        );
      }
      setFillerBlocks([]);
      setActiveDragId(null);
    },
    [getUpdatedBlock, screenType, touchedMobile, touchedTablet],
  );

  const handleDragOver = React.useCallback(({ over }: DragOverEvent) => {
    setOverId(over?.id ?? null);
  }, []);

  const handleDragCancel = () => {
    setActiveDragId(null);
    setFillerBlocks([]);
  };

  const handleResizeEnd = React.useCallback(() => {
    setActiveResizeBlock(null);
  }, []);

  const handleResizeStart = React.useCallback((block: Cms.CustomBlockModel) => {
    setActiveResizeBlock(block);
  }, []);

  const handleResizeUpdate = (dimensions: Cms.BlockPositionRelativeProps) => {
    setActiveResizeBlock(block =>
      block ?
        {
          ...block,
          position: {
            ...block.position,
            [screenType]: { ...block.position[screenType], ...dimensions },
          },
        }
      : null,
    );
  };
  const isSaving = isDefined(existingTemplate) ? isUpdateLoading : isCreateLoading;

  return (
    <Form formMethods={formMethods} onSubmit={handleSaveTemplate}>
      <div className={styles.nameInput}>
        <InputField label={t('common:name')} isRequired {...register('name')} isDisabled={isSaving} />
      </div>
      <DeviceSwitcher className={styles.deviceSwitcher} groupName="Template screen width" value={screenType} onChange={setScreenType} />
      <div className={styles.container}>
        <div className={gridClassName}>
          {isEmpty(blocksToRender) ?
            <div className={styles.emptyPlaceholder}>
              <span>{t('cms:custom_layouts_add_first_block')}</span>
            </div>
          : <DndContext
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
              onDragCancel={handleDragCancel}
              onDragOver={handleDragOver}
              sensors={sensors}
              measuring={measuring}
              collisionDetection={closestCorners}
              key={screenType}
          >
              <SortableContext items={blocksToRender} disabled={isSaving}>
                {blocksToRender.map((block, idx) => (
                  <SortableTemplateEditorBlock
                    screenType={screenType}
                    key={block.id}
                    block={block}
                    index={idx}
                    disabled={isSaving}
                    projectedBlock={projected.find(projectedBlock => projectedBlock.id === block.id)}
                    updateBlock={handleUpdateBlock}
                    removeBlock={handleRemoveBlock}
                    activeResizeBlock={activeResizeBlock}
                    onResizeUpdate={handleResizeUpdate}
                    onResizeStart={handleResizeStart}
                    onResizeEnd={handleResizeEnd}
                    activeIndex={activeIndex}
                  />
                ))}
              </SortableContext>
              <DragOverlay dropAnimation={dropAnimation}>
                {isDefined(activeBlock) && (
                  <TemplateEditorBlock
                    block={activeBlock}
                    screenType={screenType}
                    key={activeBlock.id}
                    index={activeIndex}
                    isClone
                    updateBlock={handleUpdateBlock}
                    removeBlock={handleRemoveBlock}
                  />
                )}
              </DragOverlay>
            </DndContext>
          }
        </div>
        <div className={styles.addBlock}>
          <AddBlockButton disabled={isSaving} onClick={() => handleAddBlock('content')} type="content" />
          <AddBlockButton disabled={isSaving} onClick={() => handleAddBlock('product')} type="product" />
        </div>
      </div>
      <FormButtons showDivider>
        <Button onClick={onClose} variant="ghost" color="dark" disabled={isSaving}>
          {t('common:cancel')}
        </Button>
        <Button type="submit" color="primary" disabled={isEmpty(templateBlocks)} isLoading={isSaving}>
          {isDefined(existingTemplate) ? t('common:update') : t('common:create')}
        </Button>
      </FormButtons>
    </Form>
  );
};
