import { DatePicker as DatePickerType, Filters } from '@typings';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getProductFilters, getProductsEtaDates, setEtaFilter } from '../../../../../../ducks';
import { getDateRangeFormatter } from '../../../../../../utils/dates';
import { useTranslationsConfig } from '../../../../../../utils/hooks';
import { isDefined } from '../../../../../../utils/is';
import { DatePicker } from '../../../../../various/DatePicker';

import styles from './EtaFilter.module.module.scss';

interface Props {
  title: string;
}

export const EtaFilter = ({ title }: Props) => {
  const dispatch = useDispatch();
  const { etaFrom, etaTo, onlyAvailable } = useSelector(getProductFilters);
  const productsEtaDates = useSelector(getProductsEtaDates);
  const { currentLanguageCode } = useTranslationsConfig();

  const hasAppliedArrivingSoonFilter = onlyAvailable === 'arrivingSoon';
  const hasAppliedEtaFilter = isDefined(etaFrom) && isDefined(etaTo);

  const resetEtaFilter = React.useCallback(() => {
    dispatch(setEtaFilter({}));
  }, [dispatch]);

  React.useEffect(() => {
    if (hasAppliedEtaFilter && !hasAppliedArrivingSoonFilter) {
      resetEtaFilter();
    }
  }, [resetEtaFilter, hasAppliedEtaFilter, hasAppliedArrivingSoonFilter]);

  const datePickerValue: DatePickerType.Value = React.useMemo(() => {
    if (!hasAppliedEtaFilter) {
      return [null, null];
    }

    return [dayjs(etaFrom), dayjs(etaTo)];
  }, [etaFrom, etaTo, hasAppliedEtaFilter]);

  const shouldShowDot = React.useCallback(
    (date: Dayjs, view: DatePickerType.View) => {
      return productsEtaDates.some(etaDate => date.isSame(etaDate, view));
    },
    [productsEtaDates],
  );

  const handleDatePickerChange = React.useCallback(
    ([from, to]: DatePickerType.RangeValue) => {
      if (isDefined(from) && !isDefined(to)) {
        return;
      }

      dispatch(
        setEtaFilter({
          etaFrom: from?.format('YYYY-MM-DD') as Filters['etaFrom'],
          etaTo: to?.format('YYYY-MM-DD') as Filters['etaTo'],
        }),
      );
    },
    [dispatch],
  );

  if (!hasAppliedArrivingSoonFilter) {
    return <>{title}</>;
  }

  return (
    <>
      <span className={styles.title}>{title}</span>
      <DatePicker
        isClearable
        value={datePickerValue}
        triggerFormatter={getDateRangeFormatter(currentLanguageCode)}
        shouldShowDot={shouldShowDot}
        onChange={handleDatePickerChange}
      />
    </>
  );
};
