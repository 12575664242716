/* eslint-disable max-len */

import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 16;
const ORIGINAL_HEIGHT = 20;
const PROPORTION = ORIGINAL_HEIGHT / ORIGINAL_WIDTH;
const DEAFAULT_COLOR = '#5C5C5C';

export const DeviceTablet = ({ color = DEAFAULT_COLOR, width = ORIGINAL_WIDTH }: IconComponentProps) => {
  return (
    <svg width={width} height={width * PROPORTION} viewBox="0 0 16 20" fill="none">
      <path
        d="M2.105.773c-.188.024-.507.12-.664.2a2.477 2.477 0 0 0-.281.179 2.187 2.187 0 0 0-.857 1.29c-.048.203-.05.489-.05 7.558s.002 7.355.05 7.558c.123.527.412.961.857 1.29.21.155.36.229.645.318l.235.074 5.8.008c3.622.004 5.88-.003 6.013-.019a2.118 2.118 0 0 0 1.666-1.189c.236-.482.216.278.216-8.026 0-8.314.019-7.577-.216-8.054A2.128 2.128 0 0 0 13.881.773C13.66.744 2.327.744 2.105.773m11.676 1.508a.647.647 0 0 1 .375.29c.068.105.071.125.08.556l.008.446H1.733v-.301c0-.615.117-.863.459-.982.135-.046.392-.048 5.796-.049 4.992-.001 5.672.004 5.793.04M14.24 10v4.933l-6.247-.006-6.246-.007-.007-4.893c-.004-2.692-.001-4.909.006-4.927.01-.027 1.285-.033 6.253-.033h6.241V10m-.004 6.873c-.009.431-.012.451-.08.556a.984.984 0 0 1-.155.179c-.191.161.173.152-6.001.152s-5.81.009-6.001-.152c-.202-.17-.266-.38-.266-.88v-.301h12.511l-.008.446"
        fillRule="evenodd"
        fill={color}
      />
    </svg>
  );
};
