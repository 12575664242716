import { Cms } from '@typings';
import { useTranslation } from 'react-i18next';

import { fontWeights } from '../../../../cmsTemplates';
import { isEmpty } from '../../../../utils/isEmpty';
import {
  AlignmentSwitch,
  ColorEditor,
  FontSelect,
  FontStyleSwitch,
  FontWeightSelect,
  SizeSelect,
  TextStyleSlider,
  TextTransformSwitch,
} from '../../TextTools';
import styles from '../CustomStyleEditor.module.scss';
import { InputRow, SwitchesRow } from '../FieldRow';

type Props = {
  buttonStyle: Cms.ButtonStyle;
  availableFontWeights: number[];
  setFontFamily: (value: string) => void;
  setStyleProperty: Cms.SetStyleProperty<Cms.ButtonStyleProperties>;
  isModifyingButtonStyle: boolean;
};

export const ButtonStyleTypographyTab = ({
  buttonStyle,
  availableFontWeights,
  setFontFamily,
  setStyleProperty,
  isModifyingButtonStyle,
}: Props) => {
  const { fontFamily, fontWeight, fontStyle, fontSize, textTransform, textAlign, color, letterSpacing, lineHeight } =
    buttonStyle.properties.default;
  const { t } = useTranslation(['cms']);

  const filteredFontWeights = fontWeights.filter(({ value }) => availableFontWeights.includes(value));
  const fontWeightsOptions = !isEmpty(filteredFontWeights) ? filteredFontWeights : fontWeights;

  return (
    <div>
      <InputRow label={t('cms:text_style_form.font')} preventLabelFocus>
        <FontSelect value={fontFamily} onChange={setFontFamily} disabled={isModifyingButtonStyle} />
      </InputRow>
      <InputRow label={t('cms:text_style_form.weight')} preventLabelFocus>
        <FontWeightSelect
          fontWeights={fontWeightsOptions}
          value={fontWeight}
          onChange={setStyleProperty('fontWeight')}
          disabled={isModifyingButtonStyle}
        />
      </InputRow>
      <InputRow label={t('cms:text_style_form.size')} preventLabelFocus>
        <SizeSelect
          value={fontSize}
          onChange={setStyleProperty('fontSize')}
          className={styles.sizeSelect}
          disabled={isModifyingButtonStyle}
          dataTestId="buttonStyleEditorSize"
        />
      </InputRow>
      <SwitchesRow>
        <TextTransformSwitch value={textTransform} onChange={setStyleProperty('textTransform')} />
        <FontStyleSwitch value={fontStyle} onChange={setStyleProperty('fontStyle')} />
      </SwitchesRow>
      <SwitchesRow>
        <AlignmentSwitch
          value={textAlign}
          onChange={setStyleProperty('textAlign')}
          className={styles.alignmentSwitch}
          switcherType="text"
        />
        <ColorEditor value={color} onChange={setStyleProperty('color')} className={styles.colorEditor} disableAlpha={false} />
      </SwitchesRow>
      <SwitchesRow>
        <TextStyleSlider
          title={t('cms:text_style_form.letter_spacing')}
          min={-10}
          max={10}
          step={0.025}
          value={letterSpacing}
          disabled={isModifyingButtonStyle}
          dataTestId="buttonStyleEditorLetterSpacing"
          onChange={setStyleProperty('letterSpacing')}
        />
      </SwitchesRow>
      <SwitchesRow>
        <TextStyleSlider
          title={t('cms:text_style_form.line_height')}
          min={1}
          max={10}
          step={0.01}
          value={lineHeight}
          disabled={isModifyingButtonStyle}
          dataTestId="buttonStyleEditorLineHeight"
          onChange={setStyleProperty('lineHeight')}
        />
      </SwitchesRow>
    </div>
  );
};
