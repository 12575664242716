import React from 'react';
import { useSelector } from 'react-redux';

import { GroupContext } from '../../../components/cms/blockGroup/GroupContext';
import { getCurrentSectionId } from '../../../ducks';
import { useModalVisibility } from '../useModalVisibility';

export const useIsReorderingContent = () => {
  const sectionId = useSelector(getCurrentSectionId);
  const { sectionId: groupSectionId } = React.useContext(GroupContext);
  const isReorderContentModalVisible = useModalVisibility('ReorderContentModal').isModalVisible;

  return isReorderContentModalVisible && sectionId === groupSectionId;
};
