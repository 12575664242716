import { Addresses, CheckoutForm } from '@typings';
import { nanoid } from 'nanoid';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { addShippingAddress, getOrderAccountId, pushEvent, updateTotalsByShippingAddress } from '../../../../ducks';
import { useCreateShippingAddress } from '../../../../services/hooks/account/useCreateShippingAddress';
import { getShippingAddressSelectedEvent } from '../../../../utils/analytics/events';
import { useModalVisibility } from '../../../../utils/hooks';
import Icon, { IconColor, IconType } from '../../../various/Icon';
import { ModalShell } from '../../../various/ModalShell';

import styles from './AddAddressButton.module.scss';
import { AddAddressForm } from './AddAddressForm';

interface Props {
  selectedAddress?: Addresses.MixedShipping;
}

export const AddAddressButton = ({ selectedAddress }: Props) => {
  const { t } = useTranslation(['common', 'shipping']);
  const dispatch = useDispatch();
  const orderAccountId = useSelector(getOrderAccountId);
  const { isModalVisible, showModal, hideModal } = useModalVisibility('CreateShippingAddressModal');

  const { setValue } = useFormContext<CheckoutForm>();

  const { mutate: createShippingAddress, isPending } = useCreateShippingAddress();

  const handleSubmit = ({
    addAddressToAccount,
    ...shippingAddressData
  }: Addresses.ShippingAddressDTO & { country: string; addAddressToAccount: boolean }) => {
    dispatch(pushEvent(getShippingAddressSelectedEvent('new')));

    if (!addAddressToAccount) {
      hideModal();
      dispatch(updateTotalsByShippingAddress({ shippingAddress: shippingAddressData }));

      const temporaryShippingAddress: Addresses.TemporaryShipping = {
        ...shippingAddressData,
        isTemporary: true,
        shippingAddress: nanoid(),
      };

      dispatch(addShippingAddress(temporaryShippingAddress));
      setValue('shippingAddressId', temporaryShippingAddress.shippingAddress, { shouldDirty: true, shouldValidate: true });

      return;
    }

    createShippingAddress(
      { accountId: orderAccountId, data: shippingAddressData },
      {
        onSuccess: resData => {
          dispatch(addShippingAddress(resData));
          dispatch(updateTotalsByShippingAddress({ shippingAddressId: resData.shippingAddress }));
          setValue('shippingAddressId', resData.shippingAddress, { shouldValidate: true });
          hideModal();
        },
      },
    );
  };

  return (
    <>
      <button type="button" className={styles.addAddressButton} onClick={showModal}>
        <Icon size={26} type={IconType.PlusCircle} color={IconColor.DarkGray} />
        <span>{t('common:other_address')}</span>
      </button>
      <ModalShell title={t('shipping:add')} isOpen={isModalVisible} onClose={hideModal}>
        <AddAddressForm
          defaultCountry={selectedAddress?.country ?? null}
          defaultState={selectedAddress?.state ?? null}
          isLoading={isPending}
          onCancel={hideModal}
          onSubmit={handleSubmit}
        />
      </ModalShell>
    </>
  );
};
