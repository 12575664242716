import { WhatsNew } from '@typings';
import { useTranslation } from 'react-i18next';

import { MonthContent } from './MonthContent';
import styles from './WhatsNew.module.scss';
import whatsNewContent from './whatsNewContent.json';

export const WhatsNewContent = () => {
  const { t } = useTranslation(['common']);
  const whatsNewData: WhatsNew.ArticleSet[] = whatsNewContent;

  return (
    <section className={styles.contentContainer}>
      <h1 className={styles.popoverTitle}>{t('common:whats_new')}</h1>
      {whatsNewData.map(({ month, year, articles }) => {
        const key = `${year}+${month}`;

        return <MonthContent key={key} month={month} year={year} articles={articles} />;
      })}
    </section>
  );
};
