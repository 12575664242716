import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Option } from 'space-lift';

import {
  getCmsProductCount,
  getCmsProducts,
  getDefaultImageSize,
  getMarkets,
  getProductsCountInBlock,
  getProductsPerRequest,
  loadMoreCmsProductsRequest,
} from '../../../ducks';
import { useAvailableCmsProducts } from '../../../utils/hooks';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { LazyProductsList } from '../ProductsList/LazyProductsList';

import styles from './AddProductsModal.module.scss';
import { AvailableProductsTooltip } from './AvailableProductsTooltip';
import { GenericProduct } from './GenericProduct';

interface Props {
  selectedProducts: string[];
  onSelectedProducts: (productIds: string[]) => void;
  onSearchAllPhrase: (text: string) => void;
  searchAllPhrase: string;
  skipFirst: number;
  onSkipFirst: (offset: number) => void;
  onSearchAddedPhrase: (text: string) => void;
  marketIds: string[] | undefined;
}

export const AvailableProductsSelector = ({
  selectedProducts,
  onSelectedProducts,
  onSearchAllPhrase,
  searchAllPhrase,
  skipFirst,
  onSkipFirst,
  marketIds,
}: Props) => {
  const { t } = useTranslation(['cms', 'common']);
  const dispatch = useDispatch();
  const imageSize = useSelector(getDefaultImageSize);
  const cmsProducts = useSelector(getCmsProducts);
  const allProductsCount = useSelector(getCmsProductCount);
  const productsPerRequest = useSelector(getProductsPerRequest);
  const availableMarkets = useSelector(getMarkets);
  const availableProducts = useAvailableCmsProducts();
  const productsCount = useSelector(getProductsCountInBlock);

  const maxProductsAdded = selectedProducts.length === productsCount;

  const handleAddProduct = React.useCallback(
    (productId: string) => (_: React.MouseEvent) => {
      onSelectedProducts([...selectedProducts, productId]);
    },
    [selectedProducts],
  );

  const productsNotAdded = React.useMemo(() => {
    return Option(availableProducts)
      .map(products => products.map(({ product }) => product))
      .map(productsIds => productsIds.filter(id => !selectedProducts.includes(id)))
      .getOrElse([]);
  }, [availableProducts, selectedProducts]);

  const marketsNames = React.useMemo(() => {
    if (!isDefined(marketIds)) {
      return [];
    }

    return availableMarkets.filter(market => marketIds.includes(market.id)).map(market => market.name);
  }, [marketIds, availableMarkets]);

  const loadMoreProducts = React.useCallback(() => {
    if (!isDefined(marketIds) || isEmpty(marketIds)) {
      return;
    }

    const offset = skipFirst + productsPerRequest;
    dispatch(
      loadMoreCmsProductsRequest({
        marketIds,
        search: searchAllPhrase,
        skipFirst: offset,
      }),
    );
    onSkipFirst(offset);
  }, [marketIds, searchAllPhrase, skipFirst, productsPerRequest]);

  const renderAllProduct = React.useCallback(
    (id: string) => (
      <GenericProduct
        cmsProducts={cmsProducts}
        imageSize={imageSize}
        id={id}
        action={
          maxProductsAdded ? null : (
            <button className={styles.actionButton} data-testid="addProduct" onClick={handleAddProduct(id)}>
              {t('common:add')}
            </button>
          )
        }
      />
    ),
    [cmsProducts, imageSize, maxProductsAdded, handleAddProduct, t],
  );

  return (
    <LazyProductsList
      listText={
        <>
          {t('cms:available_products')} <AvailableProductsTooltip />
        </>
      }
      items={productsNotAdded}
      allLoaded={allProductsCount === availableProducts.length}
      renderFunction={renderAllProduct}
      onSearchChange={onSearchAllPhrase}
      loadMore={loadMoreProducts}
      searchPhrase={searchAllPhrase}
      markets={marketsNames}
    />
  );
};
