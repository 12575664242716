import { showroomApi } from '../services/api';

export const getAccounts = async (limit: number, offset: number, search?: string) => {
  return showroomApi.QUERY_POST('/accounts-list')({
    data: { limit, offset, search },
  });
};

export const getBuyers = async (limit: number, offset: number, search?: string) => {
  return showroomApi.QUERY_POST('/buyers-list')({ data: { limit, offset, search } });
};

export const getBuyer = async (buyerId: string) => {
  return showroomApi.QUERY_GET('/buyer/:buyerId')({ pathParams: { buyerId } });
};
