import { Form, Radio } from '@typings';
import React from 'react';
import { useController, useFormState } from 'react-hook-form';

import { isDefined } from '../../../../utils/is';
import { RadioGroup } from '../../Fields/RadioGroup';

import { FieldWrapper } from './FieldParts';
import styles from './FormField.module.scss';

type RadioGroupProps<T extends string> = Omit<Radio.Group<T>, 'value' | 'orientation' | 'describedById' | 'onChange' | 'onBlur'>;

interface Props<T extends string> extends RadioGroupProps<T>, Form.FieldProps {
  groupOrientation?: Radio.Orientation;
  onChange?: (value: T) => void;
}

export const RadioGroupField = <T extends string>(props: Props<T>) => {
  const { description, helpMessage, label, name, isRequired = false, orientation, options, groupOrientation, onChange, ...rest } = props;

  const errorId = React.useId();
  const describedById = React.useId();

  const { field } = useController({ name });
  const { errors } = useFormState({ name });

  const errorMessage = errors[name]?.message as string | undefined;

  const handleChange = (value: T) => {
    onChange?.(value);
    field.onChange(value);
  };

  return (
    <FieldWrapper
      as="span"
      description={description}
      describedById={describedById}
      helpMessage={helpMessage}
      errorId={errorId}
      errorMessage={errorMessage}
      isRequired={isRequired}
      label={label}
      orientation={orientation}
      fieldClassName={styles.radioGroup}
    >
      <RadioGroup
        {...rest}
        name={name}
        orientation={groupOrientation}
        options={options}
        value={field.value}
        isRequired={isRequired}
        describedById={isDefined(description) ? describedById : undefined}
        onChange={handleChange}
        onBlur={field.onBlur}
      />
    </FieldWrapper>
  );
};
