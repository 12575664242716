import { Cms } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Input } from '../../../various/Fields/Input';
import { AlignmentSwitch, ColorEditor, PaddingFieldset, PixelField, WidthSelect } from '../../TextTools';
import styles from '../CustomStyleEditor.module.scss';
import { InputRow, SwitchesRow } from '../FieldRow';

type Props = {
  buttonStyle: Cms.ButtonStyle;
  name: string;
  nameError: Nullable<string>;
  onNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setStyleProperty: Cms.SetStyleProperty<Cms.ButtonStyleProperties>;
  isModifyingButtonStyle: boolean;
};

export const ButtonStyleGeneralTab = ({ buttonStyle, name, nameError, onNameChange, setStyleProperty, isModifyingButtonStyle }: Props) => {
  const { t } = useTranslation(['common', 'cms']);

  const { properties } = buttonStyle;

  return (
    <div>
      <InputRow label={t('common:name')} error={nameError}>
        <Input
          size="small"
          value={name}
          className={styles.input}
          data-testid="buttonStyleEditorName"
          isDisabled={isModifyingButtonStyle}
          onChange={onNameChange}
        />
      </InputRow>

      <InputRow label={t('cms:background')} preventLabelFocus>
        <ColorEditor
          value={properties.default.backgroundColor}
          onChange={setStyleProperty('backgroundColor')}
          className={styles.colorEditor}
          disableAlpha={false}
        />
      </InputRow>

      <InputRow label={t('cms:border')}>
        <PixelField
          value={properties.default.borderWidth}
          onChange={setStyleProperty('borderWidth')}
          minValue={0}
          maxValue={50}
          disabled={isModifyingButtonStyle}
          className={styles.buttonBorderWidth}
          testId="buttonBorderWidth"
        />
        <ColorEditor
          value={properties.default.borderColor}
          onChange={setStyleProperty('borderColor')}
          className={styles.colorEditor}
          disableAlpha={false}
        />
      </InputRow>

      <InputRow label={t('cms:corner_radius')}>
        <PixelField
          value={properties.default.borderRadius}
          onChange={setStyleProperty('borderRadius')}
          minValue={0}
          maxValue={250}
          disabled={isModifyingButtonStyle}
          testId="buttonBorderRadius"
        />
      </InputRow>

      <PaddingFieldset
        properties={properties.default}
        onChange={setStyleProperty}
        minValue={0}
        maxValue={250}
        disabled={isModifyingButtonStyle}
      />

      <InputRow label={t('cms:width')} preventLabelFocus>
        <WidthSelect value={properties.default.width} onChange={setStyleProperty('width')} disabled={isModifyingButtonStyle} />
      </InputRow>

      <SwitchesRow>
        <span>{t('cms:alignment')}</span>
        <AlignmentSwitch
          key="alignment_switch"
          value={properties.default.justifySelf}
          onChange={setStyleProperty('justifySelf')}
          switcherType="button"
          className={styles.alignmentSwitch}
        />
      </SwitchesRow>
    </div>
  );
};
