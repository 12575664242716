import { Alerts } from '@typings';

import { isDefined } from '../../../../utils/is';
import { Alert } from '../../Alert';

import styles from './FormAlert.module.scss';

interface Props {
  type?: Alerts.AlertType;
  message: Maybe<string>;
}

export const FormAlert = ({ type = 'error', message }: Props) => {
  if (!isDefined(message)) {
    return null;
  }

  return <Alert type={type} className={styles.alert} message={message} />;
};
