import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { Cross } from '../../various/Icon/Icons/Cross';

import styles from './RoundCloseButton.module.scss';

interface Props {
  onClick: () => void;
  className?: string;
  dataTestId?: string;
}

export const RoundCloseButton = ({ className, onClick, dataTestId }: Props) => {
  const { t } = useTranslation(['common']);

  return (
    <button className={cx(styles.button, className)} aria-label={t('common:close')} onClick={onClick} data-testid={dataTestId}>
      <Cross width={6} />
    </button>
  );
};
