import cx from 'classnames';

import { Portal } from '../Portal';

import styles from './Toaster.module.scss';
import { ToastWrapper } from './ToastWrapper';
import { useToaster } from './useToaster';

export const Toaster = () => {
  const { toasts, handlers } = useToaster();

  return (
    <Portal>
      <div className={cx(styles.container)} onMouseEnter={handlers.startPause} onMouseLeave={handlers.endPause}>
        {toasts.map(toast => (
          <ToastWrapper key={toast.id} toast={toast} />
        ))}
      </div>
    </Portal>
  );
};
