import cx from 'classnames';

import { Skeleton } from '../../../../various/Skeleton';

import styles from './StandardItem.module.scss';

interface Props {
  isLoading?: boolean;
  className?: string;
  label: string;
  value: React.ReactNode;
}

export const StandardItem = ({ isLoading = false, label, value, className, children }: React.WithOptionalChildren<Props>) => {
  return (
    <div className={cx(styles.row, className)}>
      <dt>{label}</dt>
      <dd>
        {isLoading ?
          <Skeleton type="text" size="medium" itemClassName={styles.valueSkeleton} />
        : value}
      </dd>
      {children}
    </div>
  );
};
