import { Cms } from '@typings';
import React from 'react';

import { GroupResizerContext } from '../../../components/cms/context/GroupResizerContext';
import { PRODUCT_PADDING } from '../../../constants/cms';
import { getScreenRelativeStyles, getScreenType } from '../../../logic/pages';
import { isDefined } from '../../../utils/is';

import { useIsInViewMode } from './useIsInViewMode';
import { useSpecifiedDeviceWidth } from './useSpecifiedDeviceWidth';

interface Props {
  blockSettings: Cms.ContentBlock;
  rowHeight: number;
  shouldApplyResize: boolean;
}

export const useResizeBlockHeight = ({ blockSettings, rowHeight, shouldApplyResize }: Props) => {
  const { resize } = React.useContext(GroupResizerContext);
  const screenWidth = useSpecifiedDeviceWidth();
  const isEditorView = useIsInViewMode('editor');
  const screenType = getScreenType(screenWidth);
  const {
    settings: { height },
    position,
  } = blockSettings;

  const { spanY } = getScreenRelativeStyles(screenWidth, position);

  const screenRelativeHeight = isDefined(height) && height[screenType];
  const autoHeight = spanY * rowHeight + PRODUCT_PADDING * (spanY - 1);

  const calculatedHeight = !screenRelativeHeight || screenRelativeHeight === 'auto' ? autoHeight : screenRelativeHeight;

  if (isEditorView && shouldApplyResize) {
    return resize(blockSettings, calculatedHeight);
  }

  return calculatedHeight;
};
