import React from 'react';

import { isDefined } from '../../../utils/is';
import { Link } from '../../various/Link';

type LinkProps = React.ComponentProps<typeof Link>;
type Props = Omit<LinkProps, 'to'> & { to: LinkProps['to'] | undefined };

export const MaybeLink = ({ children, variant, ...props }: Props) => {
  if (!isDefined(props.to)) {
    return <>{children}</>;
  }

  return (
    <Link variant={variant} {...(props as LinkProps)}>
      {children}
    </Link>
  );
};
