import { Invoice } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import universalStyles from '../../../../css/utilities/universal.module.scss';
import { getIsInvoiceFull } from '../../../logic/invoices';
import { useInvoiceDetails } from '../../../services/hooks/invoice/useInvoiceDetails';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import Heading from '../../various/Heading';
import DefaultLoader from '../../various/loaders/DefaultLoader';
import { Notification } from '../../various/Notification/Notification';
import { ReceiptAddress } from '../../various/ReceiptAddress';
import { Wrapper, WrapperSize } from '../../various/Wrapper';
import { InvoiceInfo } from '../InvoiceInfo';
import { InvoicePaymentForm } from '../InvoicePaymentForm';
import { InvoicePaymentInfo } from '../InvoicePaymentInfo';
import { InvoiceSummary } from '../InvoiceSummary';

import styles from './InvoiceDetailsPage.module.scss';

export const InvoiceDetailsPage = () => {
  const { invoiceId, invoiceHash } = useParams<Invoice.InvoiceIdentifiers>();
  const { data: invoice, isLoading } = useInvoiceDetails({ invoiceHash, invoiceId });
  const { t } = useTranslation(['common', 'invoices', 'totals', 'shipping', 'payments']);

  const invoiceIdentifiers = React.useMemo(() => {
    if (isDefined(invoiceId) && isDefined(invoiceHash)) {
      return { invoiceHash, invoiceId };
    }

    return null;
  }, [invoiceId, invoiceHash]);

  if (!isDefined(invoice) || !isDefined(invoiceIdentifiers)) {
    return (
      <Wrapper size={WrapperSize.LARGE}>
        {isLoading ?
          <DefaultLoader overlay />
        : <Notification>
            <p>{t('invoices:failed_to_load_invoice')}</p>
          </Notification>
        }
      </Wrapper>
    );
  }

  return (
    <Wrapper size={WrapperSize.LARGE}>
      {!getIsInvoiceFull(invoice) && <InvoicePaymentInfo invoice={invoice} />}
      <div className={styles.invoiceDetailsPage}>
        <section>
          <InvoiceInfo invoice={invoice} invoiceIdentifiers={invoiceIdentifiers} />
        </section>
        {getIsInvoiceFull(invoice) && (
          <>
            <section className={styles.invoiceSummaryHolder}>
              <InvoiceSummary invoice={invoice} />
            </section>

            <section>
              <div className={universalStyles.twoColumnsGrid}>
                <div className={styles.shippingAddressHolder}>
                  {isDefined(invoice.shippingAddress) && (
                    <ReceiptAddress address={invoice.shippingAddress} title={t('shipping:shipping_address_one')} />
                  )}
                </div>

                <div className={styles.taxesBlock}>
                  {isDefined(invoice.shippingCost) && (
                    <div className={cx(styles.row, styles.withBorder)}>
                      <span>{t('shipping:shipping_cost')}</span>
                      <span>{invoice.shippingCost}</span>
                    </div>
                  )}

                  {invoice.discountAsANumber > 0 && (
                    <div className={cx(styles.row, styles.withBorder)}>
                      <span>{t('common:discount')}</span>
                      <span>-{invoice.discount}</span>
                    </div>
                  )}

                  <div className={cx(styles.row, styles.withBorder, styles.primary)}>
                    <span>{t('totals:total_no_tax')}</span>
                    <span>{invoice.grandTotalWithoutTax}</span>
                  </div>

                  {invoice.taxes.map(({ taxPercent, totalPriceTax }, index) => (
                    <div className={cx(styles.row, styles.withBorder)} key={`tax-${index}`}>
                      <span>{t('common:tax_percent', { taxPercent })}</span>
                      <span>{totalPriceTax}</span>
                    </div>
                  ))}

                  <div className={cx(styles.row, styles.withBorder)}>
                    <span>{t('totals:total_with_tax')}</span>
                    <span>{invoice.grandTotal}</span>
                  </div>

                  {invoice.prepaidAsANumber > 0 && (
                    <div className={cx(styles.row, styles.withBorder)}>
                      <span>{t('invoices:prepaid_amount')}</span>
                      <span>-{invoice.prepaid}</span>
                    </div>
                  )}

                  <div className={cx(styles.row, styles.primary, styles.summary)}>
                    <span>{t('totals:total_due_with_tax')}</span>
                    <span>{invoice.totalDue}</span>
                  </div>
                </div>
              </div>
            </section>

            <section>
              <div className={universalStyles.twoColumnsGrid}>
                <div className={styles.paymentBlock}>
                  <Heading title={t('payments:payment_methods')} className={styles.heading} />
                  {!isEmpty(invoice.paymentMethods) ?
                    <InvoicePaymentForm paymentMethods={invoice.paymentMethods} invoiceIdentifiers={invoiceIdentifiers} />
                  : <p>{t('invoices:no_payment_method_available')}</p>}
                </div>
              </div>
            </section>
          </>
        )}
      </div>
    </Wrapper>
  );
};
