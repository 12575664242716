import { Cms, CmsBlocks } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { setCurrentBlock, setCurrentItem, showModal, showToolbar, updateBlockSettings } from '../../../ducks';
import { updateLocalizedBlockSettings } from '../../../ducks/cms/localizations';
import { useConfirmationGuard, useMediaTools, useTextTools } from '../../../utils/hooks';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { isSafeUrl } from '../../../utils/safeUrl';
import { ContentBlockContext } from '../../cms/context/ContentBlockContext';
import { EditorLanguageContext } from '../../cms/context/EditorLanguageContext';
import { HoverTools } from '../../cms/hoverTools';
import hoverToolsStyles from '../../cms/hoverTools/HoverTools.module.scss';
import { Alert } from '../../various/Alert';
import { Feature, Mark } from '../../various/Feature';
import { IconType } from '../../various/Icon';

import styles from './contentBlock.module.scss';

export const ContentBlockTools = ({ parameters }: CmsBlocks.BlockProps<Cms.ContentBlock>) => {
  const { link, embed, height } = parameters.settings;
  const { isDefaultLanguage } = React.useContext(EditorLanguageContext);
  const { blockId, deleteBlockElement, hasBackground, hasEmbed, hasHotspots, hasLink, hasMedia, hasOverlay, isBlockEmpty, setParameters } =
    React.useContext(ContentBlockContext);
  const dispatch = useDispatch();
  const { t } = useTranslation(['cms', 'cmsConfirmationConfig', 'common', 'validation']);
  const ref = React.useRef<HTMLDivElement | null>(null);
  const [warningMessage, setWarningMessage] = React.useState('');
  const confirmationGuard = useConfirmationGuard();

  React.useEffect(() => {
    setParameters(parameters);
  }, [parameters, setParameters]);

  const validateUrl = (url: string, message: string) => {
    if (!isSafeUrl(url)) {
      setWarningMessage(message);
    } else {
      setWarningMessage('');
    }
  };

  React.useEffect(() => {
    if (!isDefined(embed)) {
      return;
    }

    const { url } = embed;
    validateUrl(url, t('validation:embed_hint'));
  }, [embed, t]);

  React.useEffect(() => {
    if (!isDefined(link)) {
      return;
    }

    const { url } = link;
    validateUrl(url, t('validation:link_hint'));
  }, [link, t]);

  const handleDeleteLink = React.useCallback(() => deleteBlockElement(['link']), [deleteBlockElement]);

  const handleDeleteEmbed = confirmationGuard(() => ({
    onOk: () => {
      deleteBlockElement(['embed']);
    },
    ...t('cmsConfirmationConfig:delete_embed', { returnObjects: true }),
    okText: t('common:delete'),
  }));

  const clearBlock = () => {
    const updatePayload = {
      blockId,
      updatePath: [],
      value: {},
    };

    dispatch(
      updateBlockSettings({
        ...updatePayload,
        value: { height },
      }),
    );

    dispatch(updateLocalizedBlockSettings(updatePayload));
  };

  const handleBackgroundDelete = () => {
    const settingsPath = hasMedia ? ['background', 'general', 'color'] : ['background'];

    dispatch(
      updateBlockSettings({
        blockId,
        updatePath: settingsPath,
        value: undefined,
      }),
    );
  };

  const handleOverlayDelete = () => {
    dispatch(
      updateBlockSettings({
        blockId,
        updatePath: ['overlay'],
        value: undefined,
      }),
    );
  };

  const handleClearBlock = confirmationGuard(() => ({
    onOk: clearBlock,
    ...t('cmsConfirmationConfig:clear_block', { returnObjects: true }),
    okText: t('common:clear'),
  }));

  const showEmbedModal = () => {
    dispatch(setCurrentBlock(blockId));
    dispatch(showModal('EmbedModal'));
  };

  const clearBlockBeforeAddingEmbed = confirmationGuard(() => ({
    onOk: () => {
      clearBlock();
      showEmbedModal();
    },
    ...t('cmsConfirmationConfig:add_embed', { returnObjects: true }),
    okText: t('common:continue'),
  }));

  const showLinkModal = React.useCallback(() => {
    dispatch(setCurrentBlock(blockId));
    dispatch(showModal('LinkModal'));
  }, [blockId, dispatch]);

  const clearHotspotsBeforeAddingLink = confirmationGuard(() => ({
    onOk: () => {
      deleteBlockElement(['hotspots']);
      showLinkModal();
    },
    ...t('cmsConfirmationConfig:add_link', { returnObjects: true }),
    okText: t('common:clear'),
  }));

  const handleAddLink = hasHotspots ? clearHotspotsBeforeAddingLink : showLinkModal;

  const handleAddEmbed = !isBlockEmpty && !hasEmbed ? clearBlockBeforeAddingEmbed : showEmbedModal;

  const handleShowToolbar = React.useCallback(
    (name: Cms.ToolbarType) => {
      if (!isDefined(ref.current)) {
        return;
      }

      dispatch(setCurrentBlock(blockId));
      dispatch(showToolbar(name));
      dispatch(setCurrentItem({ element: ref }));
    },
    [dispatch, blockId],
  );

  const handleShowBackground = React.useCallback(() => handleShowToolbar('Background'), [handleShowToolbar]);

  const handleShowOverlayToolbar = React.useCallback(() => handleShowToolbar('Overlay'), [handleShowToolbar]);

  const areNonEmbedToolsDisabled = hasEmbed || !isDefaultLanguage;
  const areAllToolsDisabled = !isDefaultLanguage && (hasEmbed || !hasLink);

  const textTools = useTextTools({ areToolsDisabled: areNonEmbedToolsDisabled });
  const mediaTools = useMediaTools({ areToolsDisabled: areNonEmbedToolsDisabled });

  const elements = [
    { ...textTools },
    { ...mediaTools },
    {
      icon: IconType.Overlay,
      isDisabled: areNonEmbedToolsDisabled,
      items: [
        {
          icon: IconType.Overlay,
          key: 'addOverlay',
          label: t(`cms:${hasOverlay ? 'edit' : 'add'}_overlay`),
          onClick: handleShowOverlayToolbar,
        },
        {
          icon: IconType.Trash,
          isDisabled: !hasOverlay,
          key: 'deleteOverlay',
          label: t('cms:delete_overlay'),
          onClick: handleOverlayDelete,
        },
      ],
      key: 'overlayTools',
      label: t('cms:overlay_tools'),
    },
    {
      icon: IconType.Drop,
      isDisabled: areNonEmbedToolsDisabled,
      items: [
        {
          icon: IconType.Drop,
          key: 'addBackground',
          label: t(`cms:${hasBackground ? 'edit' : 'add'}_background`),
          onClick: handleShowBackground,
        },
        {
          icon: IconType.Trash,
          isDisabled: !hasBackground,
          key: 'deleteBackground',
          label: t('cms:delete_background'),
          onClick: handleBackgroundDelete,
        },
      ],
      key: 'backgroundTools',
      label: t('cms:background_tools'),
    },
    {
      icon: IconType.Link,
      isDisabled: hasEmbed,
      items: [
        {
          icon: IconType.Link,
          key: 'addLink',
          label: t(`cms:${hasLink ? 'change' : 'add'}_link`),
          onClick: handleAddLink,
        },
        {
          icon: IconType.Trash,
          isDisabled: !hasLink || !isDefaultLanguage,
          key: 'deleteLink',
          label: t('cms:delete_link'),
          onClick: handleDeleteLink,
        },
      ],
      key: 'linkTools',
      label: t('cms:link_tools'),
    },
    {
      icon: IconType.Embed,
      isDisabled: !isDefaultLanguage,
      items: [
        {
          icon: IconType.Embed,
          key: 'addEmbed',
          label: t(`cms:${hasEmbed ? 'edit' : 'add'}_embed`),
          onClick: handleAddEmbed,
        },
        {
          icon: IconType.Trash,
          isDisabled: !hasEmbed,
          key: 'deleteEmbed',
          label: t('cms:delete_embed'),
          onClick: handleDeleteEmbed,
        },
      ],
      key: 'embedTools',
      label: t('cms:embed_tools'),
    },
    {
      icon: IconType.Clear,
      isDisabled: isBlockEmpty || !isDefaultLanguage,
      key: 'clearBlock',
      label: t('cms:clear_block'),
      onClick: handleClearBlock,
      testId: 'clearBlock',
    },
  ];

  return (
    <>
      {!isEmpty(warningMessage) && <Alert displayOverContent message={warningMessage} />}
      {isBlockEmpty && <div className={styles.placeholder} />}
      <Feature>
        <Mark title={t('cms:background_applied')} isActive={hasBackground} icon={IconType.Drop} />
        <Mark title={t('cms:overlay_applied')} isActive={hasOverlay} icon={IconType.Overlay} />
        <Mark title={t('cms:link_added')} isActive={hasLink} icon={IconType.Link} />
        <Mark title={t('cms:hotspots_added')} isActive={hasHotspots} icon={IconType.Hotspot} />
      </Feature>
      <HoverTools
        name={t('cms:content_block_one')}
        className={hoverToolsStyles.content}
        elementRef={ref}
        elements={elements}
        hideForTranslation={areAllToolsDisabled}
      />
    </>
  );
};
