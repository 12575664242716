import { Id, Linesheet } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getBuyerIdFromCurrentOrder, getIsSeller } from '../../../ducks';
import { getSortByTuple } from '../../../logic/documents';
import { useOrderLinesheetDownload } from '../../../services/hooks/linesheet/useOrderLinesheetDownload';
import { useModalVisibility } from '../../../utils/hooks';
import { ModalSize } from '../../various/ModalElements';
import { ModalShell } from '../../various/ModalShell';

import { DownloadLinesheetForm } from './DownloadLinesheetForm';

interface Props {
  location: string;
  downloadOnlySelected?: boolean;
  orderId: Id;
  orderNumber: Id;
}

export const DownloadLinesheetModal = ({ location, orderId, orderNumber, downloadOnlySelected = true }: Props) => {
  const { t } = useTranslation(['linesheets']);
  const buyerId = useSelector(getBuyerIdFromCurrentOrder);
  const isSeller = useSelector(getIsSeller);

  const { isModalVisible, hideModal } = useModalVisibility('DownloadLinesheetModal');
  const { mutate, isPending } = useOrderLinesheetDownload({ orderNumber });

  const generateLinesheet = React.useCallback(
    (values: Linesheet.OrderFormData) => {
      const { attributes, groupBy, linesheetProducts, linesheetTemplate, sortBy, thenSortBy, showCustomerPrice } = values;
      const onlySelected = downloadOnlySelected || linesheetProducts === 'selected';

      hideModal();
      mutate({
        buyer: isSeller ? buyerId : undefined,
        location,
        onlySelected,
        order: orderId,
        orderNumber,
        showCustomerPriceFlag: showCustomerPrice,
        template: linesheetTemplate,
        templateDO: {
          groupBy,
          productAttributes: attributes,
          sortBy: getSortByTuple(sortBy, thenSortBy),
        },
      });
    },
    [downloadOnlySelected, mutate, isSeller, buyerId, location, orderId, orderNumber, hideModal],
  );

  return (
    <ModalShell title={t('linesheets:generate_linesheet')} isOpen={isModalVisible} onClose={hideModal} size={ModalSize.Large}>
      <DownloadLinesheetForm
        downloadOnlySelected={downloadOnlySelected}
        isPending={isPending}
        onCancel={hideModal}
        onSubmit={generateLinesheet}
      />
    </ModalShell>
  );
};
