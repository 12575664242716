import { useTranslation } from 'react-i18next';

import Heading from '../../various/Heading';

import styles from './NoLinesheetsMessage.module.scss';

export const NoLinesheetsMessage = () => {
  const { t } = useTranslation(['linesheets']);

  return (
    <>
      <Heading title={t('linesheets:linesheet_generator')} variant={['h2', 'black']} />
      <span className={styles.message}>{t('linesheets:no_linesheet_templates_seller')}</span>
    </>
  );
};
