import { Product } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getOrderCurrency } from '../../../../../../../ducks';
import { getTotalPrice } from '../../../../../../../logic/price';
import { PresetsListData } from '../PresetsPopoverContent/usePresetsListData';

import { AdderCell, PresetNameCell, PriceCell, TotalPresetsCount, TotalPresetsPrice } from './PresetsListElements';

interface Props {
  variant: Product.Standard;
}

export const usePresetListElements = ({ variant }: Props) => {
  const { t } = useTranslation(['common', 'orders', 'totals']);
  const currency = useSelector(getOrderCurrency);

  const model = React.useMemo(
    () =>
      new Map<string, React.ComponentType<PresetsListData>>([
        [
          t('orders:distribution'),
          modelProps => <PresetNameCell name={modelProps.preset.name} variant={modelProps.variant} preset={modelProps.preset} />,
        ],
        [t('common:item_other'), modelProps => <AdderCell id={modelProps.preset.id} name={modelProps.preset.name} />],
        [
          `${t('common:price')} [${currency.currency}]`,
          modelProps => <PriceCell getTotalPrice={getTotalPrice(variant, currency)} cellId={modelProps.id} />,
        ],
      ]),
    [currency, t, variant],
  );

  const footer = React.useMemo(
    () =>
      new Map<string, React.ComponentType<{ data: PresetsListData[] }>>([
        [t('totals:total_other'), () => <td>{t('totals:total_one')}</td>],
        [t('common:count'), () => <TotalPresetsCount />],
        [
          t('common:price'),
          () => {
            return <TotalPresetsPrice getTotalPrice={getTotalPrice(variant, currency)} />;
          },
        ],
      ]),
    [currency, t, variant],
  );

  return { footer, model };
};
