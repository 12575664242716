import { Invoice } from '@typings';
import { Trans, useTranslation } from 'react-i18next';

import Heading from '../../various/Heading';

import styles from './InvoicePaymentInfo.module.scss';

interface Props {
  invoice: Invoice.Base;
}

export const InvoicePaymentInfo = ({ invoice }: Props) => {
  const { t } = useTranslation(['invoices']);

  const isInvoicePaid = invoice.status === 'Paid';
  const headingText = t(isInvoicePaid ? 'invoices:thanks_for_payment' : 'invoices:processing_payment');

  const description =
    isInvoicePaid ?
      <>
        {t('invoices:payment_successful', {
          invoiceNumber: invoice.invoiceNumber,
        })}
      </>
    : <Trans t={t} i18nKey="invoices:payment_pending" values={{ invoiceNumber: invoice.invoiceNumber }}>
        Payment for the invoice #NUM has status <b>pending</b>. When the payment process is completed, the invoice status will change.
      </Trans>;

  return (
    <>
      <div className={styles.info}>
        <Heading title={headingText} />
        {description}
      </div>
    </>
  );
};
