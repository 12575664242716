import { Form } from '@typings';
import cx from 'classnames';

import styles from './FormGrid.module.scss';

export const FormGrid = ({ children, className, cols = 1, colGap = 'medium', rowGap = 'medium' }: React.WithChildren<Form.GridProps>) => {
  const classNames = cx(styles.fields, styles[`cols${cols}`], styles[`${colGap}ColGap`], styles[`${rowGap}RowGap`], className);

  return <div className={classNames}>{children}</div>;
};
