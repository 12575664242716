import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 19;
const ORIGINAL_HEIGHT = 14;
const PROPORTAION = ORIGINAL_HEIGHT / ORIGINAL_WIDTH;

export const FlagSv = ({ width = ORIGINAL_WIDTH }: IconComponentProps) => {
  return (
    <svg width={width} height={width * PROPORTAION} viewBox="0 0 19 14" fill="none" style={{ borderRadius: '3px' }}>
      <path d="M0 0H19V14H0V0Z" fill="#066AA7" />
      <path d="M0 5.6H19V8.4H0V5.6Z" fill="#FECC00" />
      <path d="M5.225 0H8.075V14H5.225V0Z" fill="#FECC00" />
    </svg>
  );
};
