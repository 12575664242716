import * as Sentry from '@sentry/react';
import { PaginationProps } from '@typings';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { DEFAULT_ITEMS_COUNT_PER_REQUEST } from '../../../constants/limits';
import { useAmplitude } from '../../../utils/hooks/useAmplitude';

import { Pager } from './components/Pager';
import { LEFT_DOTS, RIGHT_DOTS, usePagination } from './hooks/usePagination';
import styles from './Pagination.module.scss';

const SINGLE_PAGE = 1;
const SECOND_PAGE = 2;
const DEFAULT_CURRENT = 1;
const DEFAULT_SIBLING_COUNT = 2;

const Pagination = ({
  current = DEFAULT_CURRENT,
  total = 0,
  hideOnSinglePage = false,
  siblingCount = DEFAULT_SIBLING_COUNT,
  onChange,
  className,
}: PaginationProps) => {
  const { t } = useTranslation(['pagination']);
  const currentPage = Math.max(current, DEFAULT_CURRENT);

  const pageCount = Math.ceil(total / DEFAULT_ITEMS_COUNT_PER_REQUEST);
  const isNextDisabled = currentPage >= pageCount;
  const isPrevDisabled = currentPage < SECOND_PAGE;
  const isArrowVisible = pageCount > SINGLE_PAGE;
  const isHidden = hideOnSinglePage && total <= DEFAULT_ITEMS_COUNT_PER_REQUEST;

  const paginationRange = usePagination({
    current: currentPage,
    pageCount,
    siblingCount,
    total,
  });

  const { trackEvent } = useAmplitude();

  const handlePrevArrowClick = (page: number) => {
    onChange?.(page);
    Sentry.setTag('pagination_used', 'prev');
    trackEvent({ name: 'pagination.button.prev.click' });
  };

  const handleNextArrowClick = (page: number) => {
    onChange?.(page);
    Sentry.setTag('pagination_used', 'next');
    trackEvent({ name: 'pagination.button.next.click' });
  };

  const handleClick = (type: 'dots' | 'page') => (page: number) => {
    onChange?.(page);
    Sentry.setTag('pagination_used', type);
    trackEvent({ name: `pagination.button.${type}.click` });
  };

  if (isHidden) {
    return null;
  }

  return (
    <ul className={classNames(styles.pagination, className)}>
      {isArrowVisible && (
        <Pager
          title={t('pagination:previous_page')}
          type="prev"
          page={currentPage - 1}
          className={classNames(styles.arrows, {
            [styles.disabled]: isPrevDisabled,
          })}
          onClick={handlePrevArrowClick}
          disabled={isPrevDisabled}
        />
      )}

      {paginationRange.map(({ value, page }, index) => {
        const classes = classNames({ [styles.active]: page === currentPage });
        const type = value === LEFT_DOTS || value === RIGHT_DOTS ? 'dots' : 'page';

        return (
          <Pager
            key={`page_${index}`}
            title={t('pagination:go_to_page', { number: page })}
            type={type}
            page={page}
            value={value}
            className={classes}
            onClick={handleClick(type)}
          />
        );
      })}

      {isArrowVisible && (
        <Pager
          title={t('pagination:next_page')}
          type="next"
          page={currentPage + 1}
          className={classNames(styles.arrows, {
            [styles.disabled]: isNextDisabled,
          })}
          disabled={isNextDisabled}
          onClick={handleNextArrowClick}
        />
      )}
    </ul>
  );
};

export default React.memo(Pagination);
