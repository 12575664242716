/// <reference types="@welldone-software/why-did-you-render" />
import React from 'react';

if (window.__SHOWROOM_SETTINGS__.NODE_ENV === 'development' && window.__SHOWROOM_SETTINGS__.ENABLE_WHY_DID_YOU_RENDER === 'true') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {
    collapseGroups: true,
    exclude: /^Connect/,
    trackAllPureComponents: true,
  });
}
