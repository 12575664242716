import { Selects } from '@typings';
import cx from 'classnames';
import React from 'react';

import { isDefined } from '../../../../../utils/is';
import Icon, { IconColor, IconType } from '../../../Icon';
import { TextEllipsis } from '../../../TextEllipsis';
import { useSelectContext } from '../context/SelectContext';

import styles from './Option.module.scss';

const ICON_SIZE: Record<Selects.Size, number> = {
  large: 20,
  regular: 20,
  small: 16,
};

interface Props {
  children: React.ReactNode;
  value: Selects.NonNullableValue;
  selectedLabel?: string;
  index?: number;
  onClick?: () => void;
}

export const Option = ({ children, value, index, onClick }: React.PropsWithChildren<Props>) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const { focusedPosition, hoveredPosition, size, selection, optionsId, hoverOption, handleSelect } = useSelectContext();

  const isFocused = focusedPosition === index;
  const isHovered = hoveredPosition === index;
  const isSelected = [selection].flat().includes(value);
  const isLastSelected = [selection].flat().slice(-1)[0] === value;
  const shouldHoverOption = isLastSelected && !isDefined(hoveredPosition);

  React.useEffect(() => {
    if (isHovered) {
      ref.current?.scrollIntoView({ block: 'nearest' });
    }
  }, [isHovered]);

  React.useEffect(() => {
    if (shouldHoverOption && isDefined(index)) {
      hoverOption(index);
    }
  }, [hoverOption, index, shouldHoverOption]);

  const handleClick = () => {
    handleSelect(value);
    onClick?.();
  };

  const handleMouseMove = () => {
    if (isDefined(index)) {
      hoverOption(index);
    }
  };

  const handleMouseDown = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const classNames = cx(styles.option, {
    [styles.focused]: isFocused,
    [styles.hovered]: isHovered,
    [styles.selected]: isSelected,
    [styles.sizeSmall]: size === 'small',
    [styles.sizeLarge]: size === 'large',
  });

  return (
    // Keyboard navigation is handled by parent component
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      id={`${optionsId}-${value}`}
      ref={ref}
      tabIndex={-1}
      role="option"
      aria-selected={isSelected}
      className={classNames}
      onMouseMove={handleMouseMove}
      onMouseDown={handleMouseDown}
      onClick={handleClick}
    >
      <TextEllipsis>{children}</TextEllipsis>
      {isSelected && <Icon type={IconType.Check} size={ICON_SIZE[size]} color={IconColor.DarkBlue} />}
    </div>
  );
};
