import { Id } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { requestOrderPaymentResult } from '../../../ducks';
import { isDefined } from '../../../utils/is';
import Heading from '../../various/Heading';
import { Wrapper, WrapperSize } from '../../various/Wrapper';

import styles from './PaymentPending.module.scss';

export const PaymentPending = () => {
  const { id } = useParams<{ id: Id }>();
  const { t } = useTranslation(['payments']);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (isDefined(id)) {
      dispatch(requestOrderPaymentResult(id));
    }
  }, [dispatch, id]);

  return (
    <Wrapper size={WrapperSize.LARGE}>
      <Heading variant={['black', 'h2']} title={t('payments:payment_pending')} />
      <p className={styles.content}>{t('payments:payment_pending_hint')}</p>
    </Wrapper>
  );
};
