import { Cms } from '@centra';
import React from 'react';

type BackgroundImageSize = Cms.BlockAdditionalData['backgroundNaturalImageSize'];

interface Context {
  imageSize: Maybe<BackgroundImageSize>;
  setImageSize: React.Dispatch<React.SetStateAction<BackgroundImageSize>>;
}

const initialContext: Context = {
  imageSize: undefined,
  setImageSize: () => null,
};

export const ImageSizeContext = React.createContext<Context>(initialContext);

export const ImageSizeContextProvider = ({ children }: React.WithChildren) => {
  const [imageSize, setImageSize] = React.useState<BackgroundImageSize>();

  const value = React.useMemo(
    () => ({
      imageSize,
      setImageSize,
    }),
    [imageSize],
  );

  return <ImageSizeContext.Provider value={value}>{children}</ImageSizeContext.Provider>;
};
