/* eslint-disable max-len */

import { IconComponentProps } from '@typings';

const ORIGINAL_SIZE = 20;
const ORIGINAL_COLOR = '#848484';

export const Media = ({ width = ORIGINAL_SIZE, color = ORIGINAL_COLOR }: IconComponentProps) => (
  <svg width={width} height={width} viewBox="0 0 20 20" fill="none">
    <path
      d="M17 15.4444V4.55556C17 3.7 16.3 3 15.4444 3H4.55556C3.7 3 3 3.7 3 4.55556V15.4444C3 16.3 3.7 17 4.55556 17H15.4444C16.3 17 17 16.3 17 15.4444ZM7.27778 11.1667L9.22222 13.5L11.9444 10L15.4444 14.6667H4.55556L7.27778 11.1667Z"
      fill={color}
    />
  </svg>
);
