import React from 'react';

interface Props<T, U> {
  condition: boolean;
  render: (children: React.ReactElement<T> | null) => React.ReactElement<U> | null;
  children: React.ReactElement<T> | null;
}

export const ConditionalWrapping = <T, U>({ render, children, condition }: Props<T, U>) => {
  return condition ? render(children) : children;
};
