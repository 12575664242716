import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getCurrentBuyer, getIsLoggedIn, getUser } from '../../ducks/user';
import { getIsBuyer } from '../../logic/User';
import { FormDivider } from '../various/Form';
import { PageTitle } from '../various/PageTitle';
import { TopBar } from '../various/TopBar';
import { Wrapper, WrapperSize } from '../various/Wrapper';

import { BuyerForm } from './BuyerForm';
import { ChangePasswordForm } from './ChangePasswordForm';
import { UserInfo } from './UserInfo';

export const Account = () => {
  const { t } = useTranslation(['accounts']);
  const buyer = useSelector(getCurrentBuyer);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const user = useSelector(getUser);
  const isBuyer = getIsBuyer(user);

  return (
    <PageTitle title={t('accounts:my_account')}>
      <TopBar title={t('accounts:my_account')} />
      {isLoggedIn && (
        <Wrapper size={WrapperSize.MEDIUM}>
          {isBuyer ?
            <BuyerForm buyer={buyer} />
          : <UserInfo user={user} />}
          <FormDivider />
          <ChangePasswordForm />
        </Wrapper>
      )}
    </PageTitle>
  );
};
