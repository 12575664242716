import { Cms, Id } from '@typings';
import { createAction } from 'typesafe-actions';

type CreateTextStyleRequestPayload = {
  textStyle: Cms.TextStyleBasic;
  partIndex: number;
  block: Cms.AnyBlock;
};

type CreateButtonStyleRequestPayload = {
  buttonStyle: Cms.ButtonStyleBasic;
  partIndex: number;
  block: Cms.AnyBlock;
};

type EditTextStyleRequestPayload = {
  id: Id;
  textStyle: Cms.TextStyleBasic;
};

type EditButtonStyleRequestPayload = {
  id: Id;
  buttonStyle: Cms.ButtonStyleBasic;
};

type ApplyTextStylePayload = {
  textStyle: Cms.TextStyle;
  partIndex: number;
  block: Cms.AnyBlock;
};

type ApplyButtonStylePayload = {
  buttonStyle: Cms.ButtonStyle;
  partIndex: number;
  block: Cms.AnyBlock;
};

export const fetchCustomStylesRequest = createAction('cms/FETCH_CUSTOM_STYLES_REQUEST')();
export const fetchCustomStylesSuccess = createAction('cms/FETCH_CUSTOM_STYLES_SUCCESS')<Responses.CmsCustomStyles>();
export const fetchCustomStylesFailure = createAction('cms/FETCH_CUSTOM_STYLES_FAILURE')();

export const createTextStyleRequest = createAction('cms/CREATE_TEXT_STYLES_REQUEST')<CreateTextStyleRequestPayload>();
export const createTextStyleFailure = createAction('cms/CREATE_TEXT_STYLES_FAILURE')<Nullable<string>>();
export const createTextStyleSuccess = createAction('cms/CREATE_TEXT_STYLES_SUCCESS')<Cms.TextStyle>();

export const createButtonStyleRequest = createAction('cms/CREATE_BUTTON_STYLES_REQUEST')<CreateButtonStyleRequestPayload>();
export const createButtonStyleFailure = createAction('cms/CREATE_BUTTON_STYLES_FAILURE')<Nullable<string>>();
export const createButtonStyleSuccess = createAction('cms/CREATE_BUTTON_STYLES_SUCCESS')<Cms.ButtonStyle>();

export const editTextStyleRequest = createAction('cms/EDIT_TEXT_STYLES_REQUEST')<EditTextStyleRequestPayload>();
export const editTextStyleSuccess = createAction('cms/EDIT_TEXT_STYLES_SUCCESS')<Cms.TextStyle>();
export const editTextStyleFailure = createAction('cms/EDIT_TEXT_STYLES_FAILURE')<Nullable<string>>();

export const editButtonStyleRequest = createAction('cms/EDIT_BUTTON_STYLES_REQUEST')<EditButtonStyleRequestPayload>();
export const editButtonStyleSuccess = createAction('cms/EDIT_BUTTON_STYLES_SUCCESS')<Cms.ButtonStyle>();
export const editButtonStyleFailure = createAction('cms/EDIT_BUTTON_STYLES_FAILURE')<Nullable<string>>();

export const applyTextStyle = createAction('cms/APPLY_TEXT_STYLES')<ApplyTextStylePayload>();
export const applyButtonStyle = createAction('cms/APPLY_BUTTON_STYLES')<ApplyButtonStylePayload>();

export const applyEditedCustomStyle = createAction('cms/APPLY_EDITED_CUSTOM_STYLE')<Cms.ContentPartStyle>();

export const deleteCustomStyleRequest = createAction('cms/DELETE_TEXT_STYLE_REQUEST')<{
  id: Id;
  name: string;
}>();
export const deleteTextStyleSuccess = createAction('cms/DELETE_TEXT_STYLE_SUCCESS')<Id>();

export const translateDeletedTextStylesRequest = createAction('cms/TRANSLATE_DELETED_TEXT_STYLES_REQUEST')<string[]>();

export const translateDeletedTextStylesSuccess = createAction('cms/TRANSLATE_DELETED_TEXT_STYLES_SUCCESS')<
  { name: string; className: string }[]
>();

export const translateDeletedTextStylesFailure = createAction('cms/TRANSLATE_DELETED_TEXT_STYLES_FAILURE')();
export const resetDeletedTextStyles = createAction('cms/RESET_DELETED_TEXT_STYLES')();
