import { ActionHandler, Product } from '@typings';
import { createReducer } from 'typesafe-actions';

import { update } from '../../utils/update';
import { loadSingleProductByBarcodeRequest } from '../barcodeScanner';

import {
  clearProductDetailsData,
  loadRelatedProductDetailsSuccess,
  loadSingleProductFailure,
  loadSingleProductRequest,
  loadSingleProductSuccess,
  resetAllProductDetailsData,
} from './actions';

export interface ProductDetailsReducer {
  data: (Product.Full & Product.StandardRelation) | Empty.Object;
  hasProductDetailsFailed: boolean;
  isLoadingProductDetails: boolean;
}

const initialState: ProductDetailsReducer = {
  data: {},
  hasProductDetailsFailed: false,
  isLoadingProductDetails: false,
};

const handleLoadSingleProductRequest: ActionHandler<
  ProductDetailsReducer,
  typeof loadSingleProductRequest | typeof loadSingleProductByBarcodeRequest
> = state => {
  return update(state, {
    hasProductDetailsFailed: false,
    isLoadingProductDetails: true,
  });
};

const handleLoadSingleProductSuccess: ActionHandler<ProductDetailsReducer, typeof loadSingleProductSuccess> = (state, action) => {
  return update(state, {
    data: action.payload.product,
    isLoadingProductDetails: false,
  });
};

const handleLoadSingleProductFailure: ActionHandler<ProductDetailsReducer, typeof loadSingleProductFailure> = state => {
  return update(state, {
    hasProductDetailsFailed: true,
    isLoadingProductDetails: false,
  });
};

const handleLoadRelatedProductsDetailsSuccess: ActionHandler<ProductDetailsReducer, typeof loadRelatedProductDetailsSuccess> = (
  state,
  action,
) =>
  update(state, {
    data: update(state.data, { standardRelation: action.payload }),
  });

const handleResetAllProductDetailsData: ActionHandler<ProductDetailsReducer, typeof resetAllProductDetailsData> = state => {
  return update(state, initialState);
};

const handleClearProductDetails: ActionHandler<ProductDetailsReducer, typeof clearProductDetailsData> = state => {
  return update(state, {
    data: {},
  });
};

export default createReducer<ProductDetailsReducer, AppAction>(initialState)
  .handleAction(loadSingleProductRequest, handleLoadSingleProductRequest)
  .handleAction(loadSingleProductByBarcodeRequest, handleLoadSingleProductRequest)
  .handleAction(loadSingleProductSuccess, handleLoadSingleProductSuccess)
  .handleAction(loadSingleProductFailure, handleLoadSingleProductFailure)
  .handleAction(loadRelatedProductDetailsSuccess, handleLoadRelatedProductsDetailsSuccess)
  .handleAction(resetAllProductDetailsData, handleResetAllProductDetailsData)
  .handleAction(clearProductDetailsData, handleClearProductDetails);
