/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 67;
const ORIGINAL_HEIGHT = 28;
const PROPORTION = ORIGINAL_HEIGHT / ORIGINAL_WIDTH;

export const StripeLogo = ({ width = ORIGINAL_WIDTH }: IconComponentProps) => {
  return (
    <svg width={width} height={width * PROPORTION} viewBox="0 0 67 28" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.8161 14.4616C66.8161 9.73963 64.5211 6.01367 60.1346 6.01367C55.7296 6.01367 53.0645 9.73963 53.0645 14.4247C53.0645 19.9768 56.2109 22.7805 60.7269 22.7805C62.9294 22.7805 64.5951 22.2825 65.8537 21.5815V17.8925C64.5951 18.5196 63.1515 18.907 61.3191 18.907C59.5238 18.907 57.9321 18.2798 57.7285 16.1033H66.7791C66.7791 15.8635 66.8161 14.9043 66.8161 14.4616ZM57.673 12.7093C57.673 10.625 58.9501 9.75807 60.1161 9.75807C61.2451 9.75807 62.4482 10.625 62.4482 12.7093H57.673Z"
        fill="#6772E5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.9186 6.01367C44.1048 6.01367 42.9387 6.86216 42.2909 7.45241L42.0503 6.3088H37.9785V27.816L42.6056 26.8384L42.6241 21.6184C43.2904 22.098 44.2713 22.7805 45.9001 22.7805C49.213 22.7805 52.2299 20.1243 52.2299 14.2772C52.2114 8.92803 49.1575 6.01367 45.9186 6.01367ZM44.8081 18.7225C43.7161 18.7225 43.0683 18.3352 42.6241 17.8556L42.6056 11.0124C43.0868 10.4774 43.7531 10.1085 44.8081 10.1085C46.4923 10.1085 47.6583 11.99 47.6583 14.4063C47.6583 16.878 46.5108 18.7225 44.8081 18.7225Z"
        fill="#6772E5"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M36.2589 3.92897V0.18457L31.6133 1.16217V4.92502L36.2589 3.92897Z" fill="#6772E5" />
      <path d="M36.2589 6.32715H31.6133V22.4668H36.2589V6.32715Z" fill="#6772E5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.6338 7.69233L26.3376 6.32738H22.3398V22.467H26.9669V11.529C28.0589 10.1087 29.9097 10.3669 30.4835 10.5698V6.32738C29.8912 6.10604 27.7258 5.70024 26.6338 7.69233Z"
        fill="#6772E5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3802 2.32422L12.8642 3.28338L12.8457 18.0581C12.8457 20.788 14.9001 22.7985 17.6393 22.7985C19.157 22.7985 20.2675 22.5218 20.8783 22.1898V18.4454C20.286 18.6852 17.3617 19.5337 17.3617 16.8038V10.2557H20.8783V6.32685H17.3617L17.3802 2.32422Z"
        fill="#6772E5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.86814 11.0124C4.86814 10.293 5.46041 10.0163 6.44135 10.0163C7.84798 10.0163 9.62477 10.4405 11.0314 11.1968V6.86216C9.49521 6.25346 7.97753 6.01367 6.44135 6.01367C2.68417 6.01367 0.185547 7.96888 0.185547 11.2337C0.185547 16.3246 7.21869 15.513 7.21869 17.708C7.21869 18.5565 6.47836 18.8332 5.4419 18.8332C3.90571 18.8332 1.94383 18.206 0.389138 17.3575V21.7475C2.11041 22.4853 3.85019 22.7989 5.4419 22.7989C9.29162 22.7989 11.9383 20.899 11.9383 17.5973C11.9198 12.1006 4.86814 13.0782 4.86814 11.0124Z"
        fill="#6772E5"
      />
    </svg>
  );
};
