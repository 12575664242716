/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_SIZE = 20;
const ORIGINAL_COLOR = '#737373';

export const MoveUp = ({ width = ORIGINAL_SIZE, color = ORIGINAL_COLOR }: IconComponentProps) => {
  return (
    <svg width={width} height={width} viewBox="0 0 20 20" fill="none">
      <path
        d="M5.36726 11.8306C5.48632 11.9436 5.62745 12 5.79052 12L14.2179 12C14.3811 12 14.5221 11.9436 14.6413 11.8306C14.7604 11.7175 14.8199 11.5838 14.8199 11.4292C14.8199 11.2746 14.7604 11.1409 14.6413 11.0279L10.4275 7.03276C10.3082 6.91978 10.1672 6.86322 10.0042 6.86322C9.84121 6.86322 9.70004 6.91978 9.58098 7.03276L5.36726 11.0279C5.24824 11.1409 5.18848 11.2746 5.18848 11.4292C5.18848 11.5838 5.24824 11.7175 5.36726 11.8306Z"
        fill={color}
      />
    </svg>
  );
};
