import { Id, Matrices, Prepack, Preset } from '@typings';
import React from 'react';
import { useSelector } from 'react-redux';

import { getOrderDetails } from '../../../../ducks';
import { getVariantsIdsWithPrepacksAdded } from '../../../../logic/presets';
import { useMatrixPresets } from '../../../../utils/hooks/matrix/useMatrixPresets';
import { usePrepacksQuantities } from '../../../../utils/hooks/matrix/usePrepacksQuantities';
import { isDefined } from '../../../../utils/is';
import { isEmpty } from '../../../../utils/isEmpty';

import { useMatrixDeliveryWindowContext } from './MatrixDeliveryWindowContext';
import { useMatrixSettingsContext } from './MatrixSettingsContext';
import { useMatrixVariantsContext } from './MatrixVariantsContext';

interface Context {
  shouldHidePrepackSwitch: boolean;
  shouldHidePrepackSwitchColumn: boolean;
  uniquePrepacks: Preset[];
  prepacksMap: Prepack.Map;
  prepacksQuantitiesInVariants: Matrices.PrepackQuantities;
  setPrepacksState: (variantId: Id, isActive: boolean) => void;
  activePrepacks: Record<string, boolean>;
  variantsWithDisabledPrepacks: string[];
  variantsWithEnforcedPrepacks: string[];
  visiblePrepacksLength: number;
}

export const useMatrixPrepackContext = (): Context => {
  const context = React.useContext(MatrixPrepackContext);

  if (!isDefined(context)) {
    throw new Error('MatrixPrepackContext can not be used outside the scope of MatrixPrepackContextProvider');
  }

  return context;
};

export const MatrixPrepackContext = React.createContext<Context | null>(null);

export const MatrixPrepackContextProvider = ({ children }: React.WithChildren) => {
  const { variants } = useMatrixVariantsContext();
  const { deliveryWindowId } = useMatrixDeliveryWindowContext();

  const { isMobile } = useMatrixSettingsContext();
  const { prepacksMap, uniquePrepacks, shouldHidePrepackSwitch } = useMatrixPresets();
  const orderDetails = useSelector(getOrderDetails);

  const prepacksQuantitiesInVariants = usePrepacksQuantities();

  const variantsWithDisabledPrepacks = React.useMemo(
    () =>
      Object.entries(prepacksMap)
        .filter(([_, prepack]) => isEmpty(prepack.prepacks))
        .map(([key]) => key),
    [prepacksMap],
  );

  const variantsWithEnforcedPrepacks = React.useMemo(
    () =>
      Object.entries(prepacksMap)
        .filter(([_, prepack]) => prepack.isEnforced)
        .map(([key]) => key),
    [prepacksMap],
  );

  const initialActivePrepacks = React.useMemo(() => {
    const variantsWithQuantities = getVariantsIdsWithPrepacksAdded(variants, deliveryWindowId, orderDetails);

    return [...variantsWithQuantities, ...variantsWithEnforcedPrepacks].reduce(
      (acc, variantId) => ({
        ...acc,
        [variantId]: true,
      }),
      {},
    );
  }, [deliveryWindowId, orderDetails, variants, variantsWithEnforcedPrepacks]);

  const [activePrepacks, setActivePrepacks] = React.useState<Record<string, boolean>>(initialActivePrepacks);

  const setPrepacksState = React.useCallback(
    (variantId: Id, isActive: boolean) => {
      if (variantsWithEnforcedPrepacks.includes(variantId)) {
        return;
      }

      setActivePrepacks(currentPrepacks => ({
        ...currentPrepacks,
        [variantId]: isActive,
      }));
    },
    [setActivePrepacks, variantsWithEnforcedPrepacks],
  );

  const firstVariantId = variants[0]?.variant;
  const isActivePrepack = isDefined(firstVariantId) && activePrepacks[firstVariantId];
  const visiblePrepacksLength = isMobile && !isActivePrepack ? 0 : uniquePrepacks.length;

  return (
    <MatrixPrepackContext.Provider
      value={{
        activePrepacks,
        prepacksMap,
        prepacksQuantitiesInVariants,
        setPrepacksState,
        shouldHidePrepackSwitch,
        shouldHidePrepackSwitchColumn: isMobile || shouldHidePrepackSwitch,
        uniquePrepacks,
        variantsWithDisabledPrepacks,
        variantsWithEnforcedPrepacks,
        visiblePrepacksLength,
      }}
    >
      {children}
    </MatrixPrepackContext.Provider>
  );
};
