import { Tabs } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import universalStyles from '../../../../../css/utilities/universal.module.scss';
import { isDefined } from '../../../../utils/is';
import Icon, { IconColor } from '../../Icon';
import { MaybeTooltip } from '../../MaybeTooltip';

import styles from './TabList.module.scss';

interface Props {
  tabParameters: Tabs.Tab;
  isSelected: boolean;
  tablistName: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Tab = (props: Props) => {
  const { t } = useTranslation(['common']);

  const { tabParameters, isSelected, tablistName, onChange } = props;
  const { label, id, extraInfo, icon, iconTooltip, alertIcon, alertTooltip, testId } = tabParameters;

  const iconColor = isSelected ? IconColor.DarkBlue : IconColor.Gray;

  const hasAnyTooltip = isDefined(iconTooltip) || isDefined(alertTooltip);

  const htmlId = `${tablistName}-${id}`;

  const textContent = (
    <>
      {label}
      {isDefined(extraInfo) && <span> ({extraInfo})</span>}
    </>
  );

  return (
    <div role="tab" aria-selected={isSelected} className={cx(styles.tab, { [styles.selected]: isSelected })}>
      <input
        id={htmlId}
        name={tablistName}
        value={id}
        checked={isSelected}
        type="radio"
        className={universalStyles.srOnly}
        onChange={onChange}
      />
      <label className={styles.label} htmlFor={htmlId} data-testid={testId}>
        {isDefined(icon) && (
          <MaybeTooltip content={iconTooltip}>
            <div className={styles.iconContainer}>
              <Icon type={icon} color={iconColor} />
            </div>
          </MaybeTooltip>
        )}
        <div className={styles.labelTextContainer}>
          <span className={styles.labelText}>{textContent}</span>
          <span aria-hidden className={styles.labelTextSpace}>
            {textContent}
          </span>
        </div>
        {isDefined(alertIcon) && (
          <MaybeTooltip content={alertTooltip}>
            <div className={styles.iconContainer}>
              <Icon type={alertIcon} />
            </div>
          </MaybeTooltip>
        )}
      </label>
      {hasAnyTooltip && (
        <span className={universalStyles.srOnly}>
          {iconTooltip}{' '}
          {isDefined(alertTooltip) && (
            <strong>
              {t('common:warning')} {alertTooltip}
            </strong>
          )}
        </span>
      )}
    </div>
  );
};
