import { Checkboxes } from '@typings';

import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { Checkbox } from '../Fields/Checkbox/Checkbox';
import { TextEllipsis } from '../TextEllipsis';

import styles from './CheckboxGroup.module.scss';

interface Props {
  options: Checkboxes.Option[];
  value?: string;
  optionsState: Checkboxes.OptionsState;
  onChange: (clickedValue: string) => void;
  noWrapText?: boolean;
}

export const CheckboxGroup = ({ options, value, onChange, optionsState, noWrapText }: Props) => {
  const groupOptions =
    isDefined(value) ?
      options.filter(nestedOption => nestedOption.parentValue === value)
    : options.filter(option => !isDefined(option.parentValue));

  if (isEmpty(groupOptions)) {
    return null;
  }

  return (
    <ul className={styles.list}>
      {groupOptions.map(option => {
        const optionState = optionsState[option.value];

        return (
          <li key={option.value}>
            <Checkbox
              onChange={event => onChange(event.target.value)}
              value={option.value}
              checked={optionState === 'checked'}
              indeterminate={optionState === 'indeterminate'}
            >
              <TextEllipsis noWrapText={noWrapText}>{option.name}</TextEllipsis>
            </Checkbox>
            <CheckboxGroup
              key={`${option.value}-parent`}
              options={options}
              value={option.value}
              onChange={onChange}
              optionsState={optionsState}
            />
          </li>
        );
      })}
    </ul>
  );
};
