import cx from 'classnames';
import React from 'react';
import { PlacementType } from 'react-laag/dist/PlacementType';

import { isDefined } from '../../../utils/is';
import Icon, { IconType } from '../Icon';
import { Popover } from '../Popover';
import { TextEllipsis } from '../TextEllipsis';

import styles from './Dropdown.module.scss';

interface Props {
  title: string;
  selected?: string;
  allowClear?: boolean;
  visible: boolean;
  onVisibilityChange: (visible: boolean) => void;
  onClear?: () => void;
  placement?: PlacementType;
}

export const Dropdown = ({
  title,
  selected,
  allowClear = true,
  children,
  visible = false,
  onClear,
  onVisibilityChange,
  placement = 'bottom-end',
}: React.WithChildren<Props>) => {
  const handleClick = React.useCallback(() => {
    onVisibilityChange(!visible);
  }, [visible, onVisibilityChange]);

  const isClearButtonVisible = isDefined(selected) && allowClear;

  return (
    <Popover
      visible={visible}
      content={children}
      className={styles.dropdown}
      triggerClassName={cx(styles.trigger, { [styles.active]: visible })}
      placement={placement}
      onVisibleChange={onVisibilityChange}
    >
      <div className={styles.buttonsContainer}>
        <button type="button" className={styles.button} onClick={handleClick}>
          <span>{title}</span>
          {isDefined(selected) && (
            <span className={styles.selected}>
              &nbsp;(<TextEllipsis>{selected}</TextEllipsis>)
            </span>
          )}
          {!isClearButtonVisible && <Icon type={IconType.ArrowBlackDown} className={styles.icon} />}
        </button>
        {isClearButtonVisible && (
          <button type="button" className={styles.button} onClick={onClear}>
            <Icon type={IconType.Cross} size={10} className={styles.icon} />
          </button>
        )}
      </div>
    </Popover>
  );
};
