import { DeliveryWindow, Product } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import {
  clearFailedProducts,
  getAllDeliveryWindows,
  getDefaultStockType,
  getFailedProducts,
  getIsDeliveryWindowsLoading,
  getIsLoadingOrderDetails,
  getIsStockTypeSeparationEnabled,
  getOrderDetails,
  getStockTypeFilter,
  getStockTypeLabels,
} from '../../../ducks';
import { getDeliveryWindowsFromProducts, getIsProductInOrderByDelwinIds, getUniqProductIds } from '../../../logic/Orders';
import { getOrderedProductsById } from '../../../logic/products';
import { useAllProductsLink, usePageScrolling } from '../../../utils/hooks';
import { isDefined, isNull } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { alphabeticSortByKey } from '../../../utils/objectSort';
import { ProductsEtaContextProvider } from '../../products/ProductsEta';
import { EmptyState } from '../../various/EmptyState';
import Heading from '../../various/Heading';
import { Button } from '../../various/NewButton';
import { Skeleton } from '../../various/Skeleton';
import { SkeletonLayout } from '../../various/SkeletonLayout';
import { ProductLineSkeleton } from '../../various/SkeletonLayout/layouts/ProductLineSkeleton';
import { Wrapper, WrapperSize } from '../../various/Wrapper';
import DeletedProducts from '../DeletedProducts';
import { OrderDelwin } from '../OrderDelwin';
import OrderProduct from '../OrderProduct';

import styles from './OrderProducts.module.scss';
import { OrderProductsAlerts } from './OrderProductsAlerts';

interface Props {
  groupByDelwin: boolean;
  sortBySKU: boolean;
}

const SKELETONS_COUNT = 6;

const OrderProductsComponent = ({ groupByDelwin, sortBySKU }: Props) => {
  const { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'orders', 'products']);
  const failedProductsData = useSelector(getFailedProducts);
  const allDelwins = useSelector(getAllDeliveryWindows);
  const orderDetails = useSelector(getOrderDetails);
  const isLoadingDeliveryWindows = useSelector(getIsDeliveryWindowsLoading);
  const isStockTypeSeparationEnabled = useSelector(getIsStockTypeSeparationEnabled);
  const stockTypeLabels = useSelector(getStockTypeLabels);
  const defaultStockType = useSelector(getDefaultStockType);
  const stockTypeFilter = useSelector(getStockTypeFilter);
  const isLoadingOrder = useSelector(getIsLoadingOrderDetails);

  const isLoading = isLoadingDeliveryWindows || isLoadingOrder;

  const productsOrder = getUniqProductIds(orderDetails);
  const productsInfo = Array.isArray(orderDetails.products) ? {} : orderDetails.products;
  const sortedProducts =
    sortBySKU ?
      alphabeticSortByKey(Object.values(productsInfo), 'sku')
    : productsOrder.map(productId => productsInfo[productId]).filter(isDefined);

  const { scrollToTop } = usePageScrolling();
  const allProductsLink = useAllProductsLink(id);

  const hasProducts = !isLoading && !isEmpty(productsInfo);
  const delwinsForOrder = React.useMemo(
    () => getDeliveryWindowsFromProducts(orderDetails.order.products, allDelwins),
    [orderDetails, allDelwins],
  );
  const orderDelwinIds = React.useMemo(() => delwinsForOrder.map(delwin => delwin.deliveryWindow), [delwinsForOrder]);
  const hasDeletedProducts = isDefined(orderDetails.order.modifications) && isDefined(orderDetails.order.modifications.deletedProducts);

  React.useEffect(() => {
    return () => {
      if (!isNull(failedProductsData)) {
        dispatch(clearFailedProducts());
      }
    };
  }, []);

  React.useEffect(() => {
    scrollToTop();
  }, [sortBySKU, groupByDelwin, scrollToTop]);

  const getProductsAndDelwinIdsByStockType = (stockType: DeliveryWindow.StockType) => {
    const delwinIdsForStockType = delwinsForOrder.filter(delwin => delwin.stockType === stockType).map(delwin => delwin.deliveryWindow);

    const productsForStockType = sortedProducts.filter(product =>
      getIsProductInOrderByDelwinIds(product.product, delwinIdsForStockType, orderDetails.order.products),
    );

    return {
      delwinIds: delwinIdsForStockType,
      products: productsForStockType,
    };
  };

  type StockTypeGroup = {
    delwinIds: string[];
    label: string | undefined;
    products: Product.Standard[];
    type: DeliveryWindow.StockType;
  };

  const stockTypeStockGroup: StockTypeGroup = {
    label: stockTypeLabels.stock,
    type: 'stock',
    ...getProductsAndDelwinIdsByStockType('stock'),
  };
  const stockTypePreorderGroup: StockTypeGroup = {
    label: stockTypeLabels.preorder,
    type: 'preorder',
    ...getProductsAndDelwinIdsByStockType('preorder'),
  };

  const stockTypeGroupsToDisplay = (
    (stockTypeFilter ?? defaultStockType) === 'stock' ?
      [stockTypeStockGroup, stockTypePreorderGroup]
    : [stockTypePreorderGroup, stockTypeStockGroup]).filter(group => group.products.length);

  const isMixedAvailabilityAlertVisible = stockTypeGroupsToDisplay.length > 1;
  const renderProductsList = () => {
    if (groupByDelwin) {
      return delwinsForOrder.map(delwin => <OrderDelwin key={delwin.deliveryWindow} delwin={delwin} sortBySKU={sortBySKU} />);
    }

    if (isStockTypeSeparationEnabled && !sortBySKU) {
      return (
        <>
          {stockTypeGroupsToDisplay.map(group => (
            <React.Fragment key={group.type}>
              <div className={styles.stockTypeHeader}>
                <Heading title={t('products:stock_type_header', { count: group.products.length, group_label: group.label })} />
              </div>
              {group.products.map((product, idx) => {
                const orderedProducts = getOrderedProductsById(orderDetails, product.product);
                const orderedProductsDelwinIds = group.delwinIds.filter(delwinId =>
                  orderedProducts.some(({ deliveryWindow }) => deliveryWindow === delwinId),
                );

                return (
                  <OrderProduct
                    key={product.product}
                    productId={product.product}
                    showHelp={idx === 0}
                    delwinIds={orderedProductsDelwinIds}
                    stockTypeLabel={group.label}
                  />
                );
              })}
            </React.Fragment>
          ))}
        </>
      );
    }

    return sortedProducts.map((product, idx) => <OrderProduct key={product.product} productId={product.product} showHelp={idx === 0} />);
  };

  return (
    <Wrapper size={WrapperSize.FULL} className={styles.productsWrapper}>
      {isLoading && isStockTypeSeparationEnabled && (
        <div className={styles.stockTypeHeader}>
          <Skeleton type="text" size="xlarge" itemClassName={styles.stockTypeHeaderSkeleton} />
        </div>
      )}
      <SkeletonLayout skeleton={<ProductLineSkeleton />} isLoading={isLoading} repeatLayout={SKELETONS_COUNT}>
        <ProductsEtaContextProvider products={sortedProducts} delwinIds={orderDelwinIds}>
          <OrderProductsAlerts orderDetails={orderDetails} isMixedAvailabilityAlertVisible={isMixedAvailabilityAlertVisible} />
          {!hasProducts && (
            <EmptyState title={t('orders:no_products.title')} subtitle={t('orders:no_products.subtitle')}>
              <Button as={Link} to={allProductsLink} variant="bordered" color="dark">
                {t('products:select_products')}
              </Button>
            </EmptyState>
          )}
          {hasDeletedProducts && isDefined(orderDetails.order.deletedProducts) && (
            <DeletedProducts allProducts={orderDetails.products} deletedProducts={orderDetails.order.deletedProducts} />
          )}
          {renderProductsList()}
        </ProductsEtaContextProvider>
      </SkeletonLayout>
    </Wrapper>
  );
};

export const OrderProducts = React.memo(OrderProductsComponent);
