import { DatePicker } from '@typings';
import cx from 'classnames';
import { Dayjs } from 'dayjs';
import React from 'react';

import { getIsDateBetween } from '../../../../utils/dates';
import { isNull } from '../../../../utils/is';
import { DatePickerNavigationContext, DatePickerSelectionContext, DatePickerSettingsContext } from '../context';

import styles from './DatePickerCalendar.module.scss';

interface Props {
  date: Dayjs;
  shouldFocus?: boolean;
}

const CELL_FORMAT_MAP: Record<DatePicker.View, string> = {
  date: 'D',
  month: 'MMM',
  year: 'YYYY',
};

export const DatePickerCalendarCell = ({ date, shouldFocus }: Props) => {
  const cellRef = React.useRef<HTMLButtonElement>(null);

  const { view, isDateView, isYearView, setView, shouldDisable, shouldShowDot } = React.useContext(DatePickerSettingsContext);
  const { visibleDate, setVisibleDate } = React.useContext(DatePickerNavigationContext);
  const { endDatePreview, startDate, endDate, isRangeSelection, handleSelect, setEndDatePreview } =
    React.useContext(DatePickerSelectionContext);

  const isVisibleDate = date.isSame(visibleDate, view);

  React.useEffect(() => {
    if (isVisibleDate && shouldFocus) {
      cellRef.current?.focus();
    }
  }, [isVisibleDate]);

  const isDisabled = shouldDisable(date);

  const isSelected = React.useMemo(() => {
    return date.isSame(startDate, view) || date.isSame(endDate, view);
  }, [date, endDate, startDate, view]);

  const isOutOfView = React.useMemo(() => {
    return isDateView && !date.isSame(visibleDate, 'month');
  }, [date, isDateView, visibleDate]);

  const handleClick = React.useCallback(() => {
    if (isDisabled) {
      return;
    }

    if (isDateView) {
      handleSelect(date);
    }

    setVisibleDate(date);
    setView(isYearView ? 'month' : 'date');
  }, [isDisabled, isDateView, setVisibleDate, date, setView, isYearView, handleSelect]);

  const shouldAddListeners = isRangeSelection && !isNull(startDate) && isNull(endDate);
  const rangeListeners = {
    onFocus: () => setEndDatePreview(date),
    onMouseEnter: () => setEndDatePreview(date),
    onMouseLeave: () => setEndDatePreview(null),
  };

  const isStartDate = date.isSame(startDate, view);
  const isEndDate = date.isSame(endDate, view);
  const isEndDatePreview = date.isSame(endDatePreview, view);
  const isInRange = getIsDateBetween(date, [startDate, endDate], view);
  const isInPreviewRange = getIsDateBetween(date, [startDate, endDatePreview], view);

  const cellLabel = date.format(CELL_FORMAT_MAP[view]);
  const classNames = cx(styles.cellWrapper, {
    [styles.grayOut]: isOutOfView || isDisabled,
    [styles.disabled]: isDisabled,
    [styles.selected]: isSelected,
    [styles.rangeStart]: isStartDate,
    [styles.rangeEnd]: isEndDate || isEndDatePreview,
    [styles.inRange]: isInRange,
    [styles.inPreviewRange]: isInPreviewRange,
    [styles.withDot]: shouldShowDot?.(date, view),
  });

  return (
    <button
      ref={cellRef}
      type="button"
      role="gridcell"
      aria-selected={isSelected}
      aria-disabled={isDisabled}
      aria-label={date.format('DD MMM YYYY')}
      tabIndex={isVisibleDate ? 0 : -1}
      className={classNames}
      onClick={handleClick}
      {...(shouldAddListeners && rangeListeners)}
    >
      <div className={styles.cell}>{cellLabel}</div>
    </button>
  );
};
