import { Picture, Video } from '@typings';

import { is, isDefined } from '../utils/is';

export const isImageMedia = is<Picture>(candidate => 'src' in candidate && candidate.type !== 'video');
export const isVideoMedia = is<Video>(candidate => candidate.type === 'video');

const requestHandler = async <T>(request: Promise<T>): Promise<[T | null, unknown]> => {
  try {
    const data = await request;

    return [data, null] as [T, unknown];
  } catch (error) {
    return [null, error];
  }
};

export const fetchThumbnailUrl = async (src: string) => {
  const videoUrl = `https://vimeo.com/${src}`;
  const request = fetch(`https://vimeo.com/api/oembed.json?url=${videoUrl}`);

  const [response, error] = await requestHandler(request);

  if (isDefined(error) || !isDefined(response) || !response.ok) {
    return '';
  }

  const data: { thumbnail_url?: string } = await response.json();

  if (!isDefined(data.thumbnail_url)) {
    return '';
  }

  const [thumbnailWithoutSize] = data.thumbnail_url.split('_');

  return `${thumbnailWithoutSize}_200`;
};
