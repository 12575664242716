/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;
const ORIGINAL_COLOR = '#848484';

export const Chevron = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => {
  return (
    <svg viewBox="0 0 20 20" width={width} height={width} fill={color}>
      <path
        fillRule="evenodd"
        d="M9.53884 7.20277C9.79353 6.93241 10.2065 6.93241 10.4612 7.20277L14.809 11.8182C15.0637 12.0885 15.0637 12.5269 14.809 12.7972C14.5543 13.0676 14.1414 13.0676 13.8867 12.7972L10 8.67138L6.11333 12.7972C5.85864 13.0676 5.44571 13.0676 5.19102 12.7972C4.93633 12.5269 4.93633 12.0885 5.19102 11.8182L9.53884 7.20277Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
