import { useTranslation } from 'react-i18next';

import { GetProductUrl } from '../../../logic/products';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { Product } from '../Product';
import { useProductDetailsVariantContext } from '../product-details/context/ProductDetailsVariantContext';
import { ProductsView } from '../ProductsList/ProductsView';

import styles from './RelatedProducts.module.scss';

interface Props {
  getProductUrl: GetProductUrl;
}

export const RelatedProducts = ({ getProductUrl }: Props) => {
  const { t } = useTranslation(['products']);

  const { standardRelationByStock } = useProductDetailsVariantContext();

  if (!isDefined(standardRelationByStock) || isEmpty(standardRelationByStock)) {
    return null;
  }

  return (
    <div className={styles.relatedProductsContainer}>
      <h2>{t('products:related_products')}</h2>
      <ProductsView isGridView className={styles.relatedProductsGrid}>
        {standardRelationByStock.map(product => (
          <Product key={`${product.product}${product.variant}`} productInfo={product} shouldHideButtons getProductUrl={getProductUrl} />
        ))}
      </ProductsView>
    </div>
  );
};
