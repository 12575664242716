import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { getIsOrderSettingsFormDisabled } from '../../../ducks/ui';
import { getIsSeller, getUser } from '../../../ducks/user';
import { getAccountNameWithId, getBuyerFullName } from '../../../logic/accounts';
import { useBuyerSelect } from '../../../utils/hooks/selection/useBuyerSelect';
import { useValidatedForm } from '../../../utils/hooks/useValidatedForm';
import { isDefined } from '../../../utils/is';
import { Option } from '../../various/Fields/Select';
import { AutocompleteField, Form, FormButtons, FormFieldset, FormGrid, InputField } from '../../various/Form';
import { Button } from '../../various/NewButton';

import styles from './OrderSettingsForm.module.scss';

interface FormData {
  buyer: string;
  name: string;
}

interface Props {
  submitTitle: string;
  disableBuyerSelect?: boolean;
  initialData?: Partial<FormData>;
  onClose: () => void;
  onSubmit: (data: FormData) => void;
}

export const OrderSettingsForm = ({ submitTitle, initialData, onClose, onSubmit, disableBuyerSelect }: Props) => {
  const { t } = useTranslation(['common', 'selections', 'validation', 'buyers']);
  const user = useSelector(getUser);
  const isUserSeller = useSelector(getIsSeller);
  const isFormDisabled = useSelector(getIsOrderSettingsFormDisabled);

  const { defaultOption, handleLoadMore, handleSearchChange, isLoading: isLoadingBuyers, options } = useBuyerSelect(initialData?.buyer);

  const defaultValues: FormData = {
    buyer: isUserSeller ? '' : user.buyer,
    name: initialData?.name ?? '',
  };

  const validationSchema: yup.ObjectSchema<FormData> = yup.object({
    buyer: yup.string().required(t('validation:select_buyer_hint')),
    name: yup.string().defined(),
  });

  const formMethods = useValidatedForm<FormData>({ defaultValues, validationSchema });
  const { register, setValue } = formMethods;

  React.useEffect(() => {
    if (isDefined(defaultOption)) {
      setValue('buyer', defaultOption.id);
    }
  }, [defaultOption, setValue]);

  const shouldShowBuyerSelect = isUserSeller && !disableBuyerSelect;

  return (
    <Form formMethods={formMethods} onSubmit={onSubmit}>
      <FormFieldset isDisabled={isFormDisabled}>
        <FormGrid>
          {shouldShowBuyerSelect && (
            <AutocompleteField
              label={t('buyers:buyer_one')}
              isLoading={isLoadingBuyers}
              isRequired
              name="buyer"
              dataTestId="buyerSelect"
              placeholder={t('buyers:select_buyer')}
              onSearchChange={handleSearchChange}
              onLoadMore={handleLoadMore}
            >
              {options.map(buyer => (
                <Option key={buyer.id} value={buyer.id}>
                  <span data-sentry-mask="true">
                    {getBuyerFullName(buyer)} <span className={styles.buyerName}>({getAccountNameWithId(buyer)})</span>
                  </span>
                </Option>
              ))}
            </AutocompleteField>
          )}
          <InputField
            label={t('common:title')}
            placeholder={t('selections:name_selection')}
            dataTestId="selectionNameInput"
            {...register('name')}
          />
        </FormGrid>
        <FormButtons showDivider>
          <Button size="large" color="dark" variant="ghost" disabled={isFormDisabled} onClick={onClose}>
            {t('common:cancel')}
          </Button>
          <Button type="submit" size="large" data-testid="createSelectionModalButton" isLoading={isFormDisabled}>
            {submitTitle}
          </Button>
        </FormButtons>
      </FormFieldset>
    </Form>
  );
};
