import cx from 'classnames';
import { pipe } from 'ramda';
import React from 'react';

import { isDefined } from '../../../../utils/is';
import { Input } from '../../../various/Fields/Input';

import styles from './PixelField.module.scss';

interface Props {
  value: number;
  onChange: (value: number) => void;
  minValue?: number;
  maxValue?: number;
  disabled?: boolean;
  className?: string;
  testId?: string;
}

export const PixelField = ({ value, onChange, minValue, maxValue, disabled = false, className, testId }: Props) => {
  const [displayedValue, setDisplayedValue] = React.useState(value.toString());

  const processValue: (rawValue: string) => number = React.useMemo(
    () =>
      pipe(
        Number,
        Math.round,
        val => (isNaN(val) ? 0 : val),
        val => (isDefined(maxValue) ? Math.min(val, maxValue) : val),
        val => (isDefined(minValue) ? Math.max(val, minValue) : val),
      ),
    [maxValue, minValue],
  );

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const rawValue = event.target.value;
      const processedValue = processValue(rawValue);

      setDisplayedValue(rawValue);
      onChange(processedValue);
    },
    [onChange, processValue],
  );

  const handleBlur = React.useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const rawValue = event.target.value;
      const processedValue = processValue(rawValue);

      setDisplayedValue(processedValue.toString());
      onChange(processedValue);
    },
    [onChange, processValue],
  );

  return (
    <div className={cx(styles.container, className)}>
      <Input
        size="small"
        type="number"
        postfix="px"
        min={minValue}
        max={maxValue}
        isDisabled={disabled}
        className={styles.input}
        value={displayedValue}
        onChange={handleChange}
        onBlur={handleBlur}
        dataTestId={testId}
      />
    </div>
  );
};
