import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { addToast } from '../../../components/various/Toasts';
import { getSelectionShareToken } from '../../selectionRepository';

export const useSelectionShareToken = () => {
  const { t } = useTranslation(['selections']);

  return useMutation({
    mutationFn: getSelectionShareToken,
    mutationKey: ['selectionShareToken'],
    onError: () => {
      addToast(t('selections:generate_shareable_link_failure'));
    },
  });
};
