import React from 'react';

export const useContainsActiveElement = <T extends HTMLElement>() => {
  const containerRef = React.useRef<T>(null);
  const [hasActiveElement, setHasActiveElement] = React.useState(false);

  const updateHasActiveElement = () => {
    setHasActiveElement(true);
  };

  const unsetHasActiveElement = (event: FocusEvent) => {
    const target = event.relatedTarget as HTMLElement;

    if (!containerRef.current?.contains(target)) {
      setHasActiveElement(false);
    }
  };

  React.useEffect(() => {
    const containerElement = containerRef.current;

    containerElement?.addEventListener('focusin', updateHasActiveElement);
    containerElement?.addEventListener('focusout', unsetHasActiveElement);

    return () => {
      containerElement?.removeEventListener('focusin', updateHasActiveElement);
      containerElement?.removeEventListener('focusout', unsetHasActiveElement);
    };
  }, []);

  return {
    containerRef,
    hasActiveElement,
  };
};
