import { Tables } from '@typings';
import cx from 'classnames';
import { WithChildren } from 'react';

import styles from '../Table.module.scss';

export const TableCell = <T,>({ columnMeta, children, colSpan }: WithChildren<Tables.TableCellProps<T>>) => {
  return (
    <td
      className={cx(
        styles.tableCell,
        columnMeta?.align && styles[columnMeta.align],
        {
          [styles.noWrap]: !!columnMeta?.noWrap,
        },
        columnMeta?.cellClassName,
      )}
      rowSpan={columnMeta?.rowSpan}
      colSpan={colSpan}
    >
      {children}
    </td>
  );
};
