import { Linesheet } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { isEmpty } from '../../../utils/isEmpty';
import Icon, { IconType } from '../Icon';
import { Sortable } from '../Sortable';
import sortableStyles from '../Sortable/SortableList/SortableList.module.scss';
import { TextEllipsis } from '../TextEllipsis';

import styles from './ProductAttributesSortList.module.scss';

interface Props {
  allAttributes: Linesheet.DictionaryItem[];
  attributeIds: string[];
  onChange: (attributeIds: string[]) => void;
}

export const ProductAttributesSortList = ({ allAttributes, attributeIds, onChange }: Props) => {
  const { t } = useTranslation(['linesheets', 'common']);

  const availableAttributes = allAttributes.map(attribute => attribute.key).filter(key => !attributeIds.includes(key));

  const getAttributeName = React.useCallback(
    (key: string) => {
      return allAttributes.find(attr => attr.key === key)?.value ?? '';
    },
    [allAttributes],
  );

  const handleAddAttribute = React.useCallback(
    (attribute: string) => () => {
      onChange([...attributeIds, attribute]);
    },
    [attributeIds, onChange],
  );

  const handleAddAllAttributes = React.useCallback(() => {
    onChange([...attributeIds, ...availableAttributes]);
  }, [attributeIds, availableAttributes, onChange]);

  const renderAvailableAttributes = React.useCallback(
    (key: string) => {
      return (
        <div className={styles.availableAttribute}>
          <TextEllipsis noWrapText={false} className={styles.attribute}>
            {getAttributeName(key)}
          </TextEllipsis>
          <button className={sortableStyles.actionButton} onClick={handleAddAttribute(key)} type="button">
            {t('common:add')}
          </button>
        </div>
      );
    },
    [getAttributeName, handleAddAttribute, t],
  );

  const handleRemoveAttribute = React.useCallback(
    (attribute: string) => () => {
      const newAttributes = attributeIds.filter(addedAttribute => addedAttribute !== attribute);

      onChange(newAttributes);
    },
    [attributeIds, onChange],
  );

  const renderAddedAttributes = React.useCallback(
    (key: string) => {
      const name = getAttributeName(key);

      return (
        <div className={styles.availableAttribute}>
          <TextEllipsis noWrapText={false} className={styles.attribute}>
            {name}
          </TextEllipsis>
          <button
            className={sortableStyles.actionButton}
            onClick={handleRemoveAttribute(key)}
            aria-label={t('linesheets:remove_attribute', { name })}
            type="button"
            data-testid={`remove${name}`}
          >
            <Icon type={IconType.Trash} />
          </button>
        </div>
      );
    },
    [getAttributeName, handleRemoveAttribute, t],
  );

  const handleSortEnd = React.useCallback(
    (sortedArray: string[]) => {
      onChange(sortedArray);
    },
    [onChange],
  );

  return (
    <div className={styles.container} data-testid="attributesContainer">
      <div className={styles.attributesContainer}>
        <div className={styles.title}>
          {t('linesheets:available_attributes')}
          <div className={styles.addAllWrapper}>
            <button
              className={cx(styles.addAll, { [styles.isHidden]: isEmpty(availableAttributes) })}
              onClick={handleAddAllAttributes}
              type="button"
              data-testid="addAll"
            >
              {t('linesheets:add_all')}
            </button>
          </div>
        </div>

        <Sortable
          items={availableAttributes}
          renderItem={renderAvailableAttributes}
          isSortDisabled
          className={styles.sortableListWrapper}
        />
      </div>
      <div className={styles.attributesContainer}>
        <div className={styles.title}>{t('linesheets:added_attributes')}</div>
        <Sortable
          items={attributeIds}
          renderItem={renderAddedAttributes}
          handleSortEnd={handleSortEnd}
          className={styles.sortableListWrapper}
        />
      </div>
    </div>
  );
};
