import { PopoverMenuItems } from '@typings';
import React from 'react';
import { LayerProps, UseHoverProps } from 'react-laag';

import { ClickableItem } from './ClickableItem';
import { MenuContainer } from './MenuContainer';
import { SelectableItem } from './SelectableItem';

interface Props {
  layerProps: LayerProps;
  hoverProps?: UseHoverProps;
  closeMenu: () => void;
  containerClassName?: string;
  items: PopoverMenuItems.ClickableItem[];
  groupLabel?: string;
  areItemsSelectable?: boolean;
  onSelect?: (key: string | number) => void;
  selectedKey?: string | number;
}

export const NestedItems = (props: Props) => {
  const {
    selectedKey,
    items,
    groupLabel,
    layerProps,
    hoverProps,
    closeMenu,
    containerClassName,
    onSelect,
    areItemsSelectable = false,
  } = props;

  const handleSelect = React.useCallback(
    (key: string | number) => {
      closeMenu();
      onSelect?.(key);
    },
    [closeMenu, onSelect],
  );

  return (
    <MenuContainer layerProps={layerProps} hoverProps={hoverProps} className={containerClassName} groupLabel={groupLabel}>
      <ul>
        {items.map(nested =>
          areItemsSelectable ?
            <SelectableItem key={nested.key} item={nested} isSelected={selectedKey === nested.key} onSelect={handleSelect} />
          : <ClickableItem key={nested.key} item={nested} closeMenu={closeMenu} />,
        )}
      </ul>
    </MenuContainer>
  );
};
