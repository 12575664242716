/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 21;
const ORIGINAL_HEIGHT = 20;
const ORIGINAL_COLOR = '#848484';
const PROPORTION = ORIGINAL_HEIGHT / ORIGINAL_WIDTH;

export const ReplaceHorizontal = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => (
  <svg width={width} height={width * PROPORTION} viewBox="0 0 21 20" fill="none">
    <path
      d="M5.39216 9.82881C5.50937 9.7819 5.56819 9.69672 5.56819 9.57359L5.5682 7.60349L17.1237 7.60349C17.2101 7.60349 17.2811 7.57706 17.3366 7.52435C17.3922 7.47183 17.4199 7.40434 17.4199 7.32212L17.4199 5.63345C17.4199 5.55129 17.3922 5.48392 17.3366 5.43115C17.2811 5.37844 17.2101 5.35198 17.1237 5.35198L5.56807 5.35198L5.56807 3.38191C5.56807 3.26466 5.50937 3.17961 5.39203 3.12684C5.27486 3.07999 5.16686 3.09471 5.06785 3.17092L1.51238 6.2931C1.4507 6.35173 1.41992 6.42209 1.41992 6.50424C1.41992 6.58058 1.45067 6.64795 1.51238 6.70654L5.06785 9.78479C5.16686 9.86104 5.27486 9.87582 5.39216 9.82881Z"
      fill={color}
    />
    <path
      d="M15.1323 10.1712C15.015 10.2181 14.9562 10.3033 14.9562 10.4264L14.9562 12.3965H3.40075C3.31429 12.3965 3.24326 12.4229 3.18777 12.4756C3.13222 12.5282 3.10449 12.5957 3.10449 12.6779L3.10449 14.3666C3.10449 14.4487 3.13222 14.5161 3.18777 14.5689C3.24326 14.6216 3.31429 14.648 3.40075 14.648H14.9563V16.6181C14.9563 16.7353 15.015 16.8204 15.1324 16.8732C15.2496 16.92 15.3576 16.9053 15.4566 16.8291L19.012 13.7069C19.0737 13.6483 19.1045 13.5779 19.1045 13.4958C19.1045 13.4194 19.0737 13.3521 19.012 13.2935L15.4566 10.2152C15.3576 10.139 15.2496 10.1242 15.1323 10.1712Z"
      fill={color}
    />
  </svg>
);
