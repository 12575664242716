import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getActiveDeliveryWindowsIdsForOrderBuyer, getIsProductLoading, pushEvent } from '../../../ducks';
import { createProductImageSelector, getDefaultImageAspectRatio } from '../../../ducks/helpers';
import { getHasOptimisticUpdatesByProductId, getOrderDetails, getProductInfo, removeProductsFromOrder } from '../../../ducks/order';
import { getIsExpired } from '../../../logic/deliveryWindows';
import {
  getDeliveryWindowsExpiredMessage,
  getDeliveryWindowsFromProducts,
  getIsProductCancelled,
  getProductByProductIdVariantIdFromProducts,
  getTotalProductPriceInDelwins,
  getTotalUnitQuantityByProductAndDelwins,
  getTotalVariantQuantityByProductAndDelwins,
} from '../../../logic/Orders';
import { MySelectionPageTrackingEvent } from '../../../utils/analytics/events';
import { useConfirmationGuard } from '../../../utils/hooks/useConfirmationGuard';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import Icon, { IconType } from '../../various/Icon';
import { ProductBaseInfo } from '../../various/ProductBaseInfo';
import { ProductLineContainer } from '../../various/ProductLineContainer';
import { OrderProductExpanded } from '../OrderProductExpanded';

import styles from './OrderProduct.module.scss';

interface Props {
  delwinIds?: string[];
  productId: string;
  orderProductVersion?: 'delwin' | 'standard';
  showHelp?: boolean;
  stockTypeLabel?: string;
}

const getMedia = createProductImageSelector(getProductInfo);

export const OrderProduct = (props: Props) => {
  const { delwinIds, productId, showHelp, orderProductVersion = 'standard', stockTypeLabel } = props;
  const { t } = useTranslation(['expiredDelwins', 'confirmationConfig', 'orders', 'products']);
  const product = useSelector((state: Store) => getProductInfo(state, productId));
  const isProductLoadingSelector = React.useMemo(() => getIsProductLoading(productId), [productId]);
  const isProductLoading = useSelector(isProductLoadingSelector);
  const isMutatingUnitsSelector = React.useMemo(() => getHasOptimisticUpdatesByProductId(productId), [productId]);
  const isMutatingUnits = useSelector(isMutatingUnitsSelector);
  const orderDetails = useSelector(getOrderDetails);
  const aspectRatio = useSelector(getDefaultImageAspectRatio);
  const imageSrc = useSelector((state: Store) => getMedia(state, productId)).src;
  const activeDeliveryWindowsIds = useSelector(getActiveDeliveryWindowsIdsForOrderBuyer);
  const dispatch = useDispatch();
  const confirmationGuard = useConfirmationGuard();

  const isVersionDelwin = orderProductVersion === 'delwin';

  const confirmationData = React.useMemo(() => {
    if (isDefined(stockTypeLabel)) {
      return t('confirmationConfig:remove_product_from_order_by_stock_type', { returnObjects: true, stockName: stockTypeLabel });
    }

    return isVersionDelwin ?
        t('confirmationConfig:remove_product_from_order_by_delwin', { returnObjects: true })
      : t('confirmationConfig:remove_product_from_order', { returnObjects: true });
  }, [isVersionDelwin, stockTypeLabel, t]);

  const removeProduct = confirmationGuard(() => ({
    onOk: () => {
      dispatch(
        removeProductsFromOrder({
          deliveryWindows: delwinIds,
          products: [productId],
        }),
      );
      dispatch(pushEvent({ event: MySelectionPageTrackingEvent.REMOVE_PRODUCT_CLICKED }));
    },
    ...confirmationData,
  }));

  const orderedProduct = React.useMemo(
    () => getProductByProductIdVariantIdFromProducts(product.product, product.variant, orderDetails.order.products),
    [product, orderDetails],
  );

  const overrideSummaryValues = React.useMemo(
    () =>
      isDefined(delwinIds) && !isEmpty(delwinIds) ?
        {
          price: getTotalProductPriceInDelwins(product.product, delwinIds, orderDetails.order.products),
          unitsCount: getTotalUnitQuantityByProductAndDelwins(product.product, delwinIds, orderDetails.order.products),
          variantsCount: getTotalVariantQuantityByProductAndDelwins(product.product, delwinIds, orderDetails.order.products),
        }
      : undefined,
    [delwinIds, product, orderDetails],
  );

  const isProductCancelled = getIsProductCancelled(productId, orderDetails.order.products);

  const inactiveStyle = React.useMemo(
    () => ({
      [styles.inactiveProduct]: !orderedProduct?.isEditable || isProductCancelled,
    }),
    [orderedProduct?.isEditable, isProductCancelled],
  );

  /**
   * This is probably deleted product - should be present in `order.deletedProducts`.
   */
  if (!orderedProduct) {
    return null;
  }

  const products = orderDetails.order.products.filter(orderProduct => orderProduct.product === product.product);
  const orderDelwins = getDeliveryWindowsFromProducts(products, orderDetails.deliveryWindows);
  const expiredOrderDelwins = orderDelwins.filter(delwin => getIsExpired(delwin, activeDeliveryWindowsIds));
  const filteredExpiredDelwins =
    isDefined(delwinIds) && !isEmpty(delwinIds) ?
      expiredOrderDelwins.filter(delwin => delwinIds.includes(delwin.deliveryWindow))
    : expiredOrderDelwins;

  const expiredMessage = getDeliveryWindowsExpiredMessage(filteredExpiredDelwins, t);

  return (
    <ProductLineContainer isLoading={isProductLoading}>
      <div className={styles.top}>
        <div className={styles.topLeft}>
          <ProductBaseInfo
            imageAspectRatio={aspectRatio}
            imageSrc={imageSrc}
            productInfo={product}
            overrideSummaryValues={overrideSummaryValues}
            isProductEditable={orderedProduct.isEditable}
            isCancelled={isProductCancelled}
            expiredMessage={expiredMessage}
            delwinIds={delwinIds}
            showBadges
          />
          {orderedProduct.added && <div className={styles.modificationStatus}>{t('products:product_added')}</div>}
        </div>
        <div className={styles.topRight}>
          <div className={styles.actions}>
            {isProductLoading || isMutatingUnits ?
              <Icon type={IconType.Spinner} size={24} />
            : <button type="button" onClick={removeProduct} className={styles.trashButton}>
                <Icon
                  type={IconType.TrashWithDetails}
                  className={cx(styles.iconTrash, !orderedProduct.isEditable && styles.inactiveProduct)}
                  size={20}
                />
              </button>
            }
          </div>
        </div>
      </div>
      <div className={cx(styles.bottom, inactiveStyle)}>
        <OrderProductExpanded delwinIds={delwinIds} product={product} showHelp={showHelp} />
      </div>
    </ProductLineContainer>
  );
};

export default OrderProduct;
