import { isDefined } from './is';

export const createAbsoluteUrl = (parts: string | string[], searchParams?: Record<string, string | number>) => {
  const urlParts = Array.isArray(parts) ? parts.join('/') : parts;
  const url = new URL(urlParts, window.location.protocol + window.location.host);

  if (isDefined(searchParams)) {
    Object.entries(searchParams).forEach(([key, value]) => {
      url.searchParams.append(key, value.toString());
    });
  }

  return url.toString();
};
