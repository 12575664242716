import { Skeleton } from '@typings';

import { SkeletonBase } from '../SkeletonBase';

export const RectangleSkeleton = ({ aspectRatio, className, color }: Skeleton.RectangleSkeleton) => {
  const rectangleStyle = {
    aspectRatio: `1 / ${aspectRatio}`,
  };

  return <SkeletonBase className={className} style={rectangleStyle} color={color} />;
};
