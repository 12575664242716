import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { TemplateEditorBlock, TemplateEditorBlockProps } from './TemplateEditorBlock';

export const SortableTemplateEditorBlock = ({
  block: templateModel,
  activeIndex,
  screenType,
  ...props
}: TemplateEditorBlockProps & { activeIndex: number }) => {
  const { attributes, listeners, isSorting, isDragging, setNodeRef, transform, transition } = useSortable({
    id: templateModel.id,
  });

  return (
    <TemplateEditorBlock
      block={templateModel}
      screenType={screenType}
      key={templateModel.id}
      ref={setNodeRef}
      isDragging={isDragging}
      style={{
        transform: isSorting ? undefined : CSS.Translate.toString(transform),
        transition,
      }}
      {...props}
      {...attributes}
      {...listeners}
    />
  );
};
