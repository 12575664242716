import { Cms } from '@typings';
import cx from 'classnames';
import React from 'react';

import { isDefined } from '../../../../../../../utils/is';
import { Direction, Resizer } from '../../../../../../various/Resizer';
import { useMouseClosestSide } from '../../useMouseClosestSide';

import styles from './BlockResizer.module.scss';
import { useBlockResize } from './useBlockResize';

interface Props {
  isVisible?: boolean;
  resizableRef: React.RefObject<HTMLDivElement>;
  block: Cms.CustomBlockModel;
  index: number;
  onResizeStart?: (block: Cms.CustomBlockModel) => void;
  onResizeEnd?: VoidFunction;
  updateBlock: (index: number, templateModel: Cms.CustomBlockModel) => void;
  onResizeUpdate?: (dimensions: Cms.BlockPositionRelativeProps) => void;
  screenType: Cms.ScreenType;
}

export const BlockResizer = ({
  isVisible = true,
  resizableRef,
  onResizeStart,
  onResizeEnd,
  updateBlock,
  onResizeUpdate,
  screenType,
  block,
  index,
}: Props) => {
  const {
    currentResizer,
    handleRightResizingEnd,
    handleInitialResizing,
    handleBottomResizingEnd,
    handleBottomResizing,
    handleRightResizing,
    handleLeftResizing,
    handleLeftResizingEnd,
    handleTopResizing,
    handleTopResizingEnd,
  } = useBlockResize({
    block,
    index,
    onResizeEnd,
    onResizeStart,
    onResizeUpdate,
    resizableRef,
    screenType,
    updateBlock,
  });

  const { horizontalSide, verticalSide } = useMouseClosestSide({ ref: resizableRef });

  const isProductBlock = block.blockType === 'product';

  const isDesktop = screenType === 'desktop';
  const isResizing = isDefined(currentResizer);
  const shouldShowTopResizer = (verticalSide === 'top' && !isResizing) || currentResizer === 'top';
  const shouldShowBottomResizer = (verticalSide === 'bottom' && !isResizing) || currentResizer === 'bottom';
  const shouldShowLeftResizer = (horizontalSide === 'left' && !isResizing) || currentResizer === 'left';
  const shouldShowRightResizer = (horizontalSide === 'right' && !isResizing) || currentResizer === 'right';
  const canResizeVertically = isDesktop || !isProductBlock;

  return (
    <div
      className={cx(styles.resizers, {
        [styles.visible]: isVisible,
        [styles.productResizers]: isProductBlock,
      })}
    >
      {canResizeVertically && (
        <Resizer
          className={cx(styles.resizer, { [styles.visible]: shouldShowTopResizer })}
          position={Direction.TOP}
          onResizeStart={handleInitialResizing}
          onResizing={handleTopResizing}
          onResizeEnd={handleTopResizingEnd}
        />
      )}
      <Resizer
        className={cx(styles.resizer, { [styles.visible]: shouldShowRightResizer })}
        position={Direction.RIGHT}
        onResizeStart={handleInitialResizing}
        onResizing={handleRightResizing}
        onResizeEnd={handleRightResizingEnd}
      />
      {canResizeVertically && (
        <Resizer
          className={cx(styles.resizer, { [styles.visible]: shouldShowBottomResizer })}
          position={Direction.BOTTOM}
          onResizeStart={handleInitialResizing}
          onResizing={handleBottomResizing}
          onResizeEnd={handleBottomResizingEnd}
        />
      )}
      <Resizer
        className={cx(styles.resizer, { [styles.visible]: shouldShowLeftResizer })}
        position={Direction.LEFT}
        onResizeStart={handleInitialResizing}
        onResizing={handleLeftResizing}
        onResizeEnd={handleLeftResizingEnd}
      />
    </div>
  );
};
