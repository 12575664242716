import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { isEmpty } from '../isEmpty';

const PARAM_FALLBACK = '';

export const useUrlState = <T extends string>(property: string) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const propertyValue = searchParams.get(property) ?? PARAM_FALLBACK;

  const setParam = React.useCallback(
    (newState: T | '', { resetParams = [] as string[] } = {}) => {
      setSearchParams(previousParams => {
        const newParams = new URLSearchParams(previousParams);

        if (!isEmpty(newState)) {
          newParams.set(property, newState);
        } else {
          newParams.delete(property);
        }

        resetParams.forEach(param => newParams.delete(param));

        return newParams;
      });
    },
    [property, setSearchParams],
  );

  return [propertyValue as T | '', setParam] as const;
};
