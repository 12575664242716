import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { setMatrixTotalsColumnPinned } from '../../../../../ducks';
import { IconType } from '../../../../various/Icon';
import { useMatrixColumnsContext } from '../../context';
import { ControlsButton } from '../ControlsButton';

import styles from './TotalsToggle.module.scss';

interface Props {
  isLabelCollapsed: boolean;
}

export const TotalsToggle = React.forwardRef<HTMLButtonElement, Props>(({ isLabelCollapsed }, ref) => {
  const { isTotalsColumnPinned, canPinColumns } = useMatrixColumnsContext();
  const dispatch = useDispatch();
  const { t } = useTranslation(['totals']);

  const handleTogglePinTotals = React.useCallback(() => {
    dispatch(setMatrixTotalsColumnPinned(!isTotalsColumnPinned));
  }, [dispatch, isTotalsColumnPinned]);

  const iconType = isTotalsColumnPinned ? IconType.Unpin : IconType.Pin;

  const dynamicLabel = isTotalsColumnPinned ? t('totals:unpin_totals') : t('totals:pin_totals');

  const label = isLabelCollapsed ? t('totals:total_one') : dynamicLabel;

  if (!canPinColumns) {
    return null;
  }

  return (
    <ControlsButton
      ref={ref}
      testId="pinTotals"
      onClick={handleTogglePinTotals}
      iconType={iconType}
      label={label}
      className={styles.pinTotalsButton}
    />
  );
});
