import { Product } from '@typings';
import cx from 'classnames';
import React from 'react';

import { getUrlForLookbookVariant, getUrlForProductAndVariant } from '../../../../logic/products';
import { useScreenResize } from '../../../../utils/hooks';
import { isDefined } from '../../../../utils/is';
import { ProductDetailsLayoutContext, ProductDetailsLayoutContextProvider } from '../context/ProductDetailsLayoutContext';
import { ProductDetailsVariantContextProvider } from '../context/ProductDetailsVariantContext';
import { ProductDetailsInfo } from '../ProductDetailsInfo';
import { ProductLightbox } from '../ProductLightbox';

import { Drawer } from './Drawer';
import styles from './ProductDetailsLayout.module.scss';
import { ProductSwiper } from './ProductSwiper';

interface Props {
  productDetails: Product.Full;
}

const ProductDetailsLayoutComponent = ({ children, productDetails }: React.WithChildren<Props>) => {
  const lightboxRef = React.useRef<HTMLDivElement | null>(null);
  const { hasMobileDetailsLayout, isLookbook } = React.useContext(ProductDetailsLayoutContext);
  const [drawerOffset, setDrawerOffset] = React.useState<number | null>(null);

  const updateDrawerOffset = () => {
    if (!isDefined(lightboxRef.current)) {
      return;
    }

    setDrawerOffset(lightboxRef.current.offsetHeight);
  };

  const setLightboxRef = (ref: HTMLDivElement) => {
    lightboxRef.current = ref;
    updateDrawerOffset();
  };

  useScreenResize(updateDrawerOffset);

  const detailsContent = (
    <>
      <div className={cx(styles.description, { [styles.lookbook]: isLookbook })}>
        <ProductDetailsInfo />
      </div>
      <div className={styles.matrices}>{children}</div>
    </>
  );

  return (
    <ProductDetailsVariantContextProvider
      productDetails={productDetails}
      getProductUrl={isLookbook ? getUrlForLookbookVariant : getUrlForProductAndVariant}
    >
      <ProductSwiper lightboxRef={lightboxRef} className={styles.layoutContainer}>
        <div ref={setLightboxRef} className={styles.lightbox}>
          <ProductLightbox />
        </div>
        {hasMobileDetailsLayout && isDefined(drawerOffset) && <Drawer drawerTopOffset={drawerOffset}>{detailsContent}</Drawer>}
        {!hasMobileDetailsLayout && detailsContent}
      </ProductSwiper>
    </ProductDetailsVariantContextProvider>
  );
};

export const ProductDetailsLayout = (props: React.WithChildren<Props>) => (
  <ProductDetailsLayoutContextProvider>
    <ProductDetailsLayoutComponent {...props} />
  </ProductDetailsLayoutContextProvider>
);
