import React from 'react';

interface Props {
  initialValue?: string;
  onChangeValue?: (value: string) => void;
}

export const useSearchInput = ({ initialValue = '', onChangeValue }: Props) => {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    if (initialValue !== value) {
      setValue(initialValue);
    }
  }, [initialValue]);

  return {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
      onChangeValue?.(event.target.value);
    },
    setValue,
    value,
  };
};
