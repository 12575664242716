import { useTranslation } from 'react-i18next';

import { Cms, Translations } from '../../../../../../typings';
import { useTranslationsConfig } from '../../../../utils/hooks';
import { isDefined } from '../../../../utils/is';
import { IconType } from '../../../various/Icon';
import { Button } from '../../../various/NewButton';
import { PageStatus } from '../../PageStatus';

import styles from './PageFormLanguages.module.scss';

interface Props {
  code: Translations.SupportedLanguagesCodes;
  label: string;
  pageStatus?: Cms.PageStatus;
  onRemove: () => void;
}

export const PageFormLanguagesRow = ({ code, label, pageStatus, onRemove }: Props) => {
  const { t } = useTranslation(['common']);
  const { getIsDefaultLanguage } = useTranslationsConfig();

  const isDefaultLanguage = getIsDefaultLanguage(code);

  return (
    <div className={styles.row}>
      {label}
      {isDefined(pageStatus) && <PageStatus status={pageStatus} />}
      {!isDefaultLanguage && (
        <Button
          aria-label={t('common:remove')}
          size="small"
          variant="ghost"
          color="danger"
          icon={IconType.TrashLinear}
          onClick={onRemove}
        />
      )}
    </div>
  );
};
