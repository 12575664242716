import React from 'react';
import { UseHoverProps } from 'react-laag';

import { Checkboxes } from '../../../../../../../../typings';
import { useClickOutside } from '../../../../../../utils/hooks';
import { Checkbox } from '../../../../../various/Fields/Checkbox';
import styles from '../FiltersDeliveryWindows.module.scss';

interface Props {
  optionsState: Checkboxes.OptionsState;
  optionChildren: Checkboxes.Option[];
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  hoverProps?: UseHoverProps;
  onClickOutside: (event: MouseEvent) => void;
}

export const CheckboxGroupOptions = ({ onChange, optionsState, optionChildren, hoverProps, onClickOutside }: Props) => {
  const ref = React.useRef<HTMLUListElement>(null);

  useClickOutside({
    element: ref.current,
    handler: onClickOutside,
  });

  return (
    <ul ref={ref} className={styles.popoverList} {...hoverProps}>
      {optionChildren.map(item => {
        const childOptionState = optionsState[item.value];

        return (
          <li className={styles.listItem} key={item.value}>
            <Checkbox
              onChange={onChange}
              value={item.value}
              checked={childOptionState === 'checked'}
              indeterminate={childOptionState === 'indeterminate'}
              className={styles.checkboxWrapper}
            >
              {item.name}
            </Checkbox>
          </li>
        );
      })}
    </ul>
  );
};
