import { memo } from 'react';

import Icon, { IconType } from '../../../various/Icon';
import { LEFT_DOTS } from '../hooks/usePagination';
import styles from '../Pagination.module.scss';

import { PagerType } from './Pager';

interface Props {
  type: PagerType;
  value: Maybe<number>;
}

const ARROW_SIZE = 12;
const ROTATE_180 = 180;

const PagerValue = ({ type, value }: Props) => {
  switch (type) {
    case 'prev':
      return <Icon type={IconType.ArrowLeft} size={ARROW_SIZE} />;
    case 'next':
      return <Icon type={IconType.ArrowLeft} rotation={ROTATE_180} size={ARROW_SIZE} />;
    case 'page':
      return <>{value}</>;
    case 'dots':
      return (
        <span className={styles.jumpTo}>
          <span className={styles.dots}>•••</span>
          <Icon
            className={styles.doubleArrow}
            type={IconType.ArrowDoubleLeft}
            rotation={value === LEFT_DOTS ? 0 : ROTATE_180}
            size={ARROW_SIZE}
          />
        </span>
      );
    default:
      return null;
  }
};
export default memo(PagerValue);
