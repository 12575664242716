/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;
const ORIGINAL_COLOR = '#848484';

export const Button = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => (
  <svg width={width} height={width} viewBox="0 0 20 20" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 5C3.67157 5 3 5.67157 3 6.5V13.5C3 14.3284 3.67157 15 4.5 15H15.5C16.3284 15 17 14.3284 17 13.5V6.5C17 5.67157 16.3284 5 15.5 5H4.5ZM7 9C6.5 9 6 9.5 6 10C6 10.5 6.5 11 7 11H13C13.5 11 14 10.5 14 10C14 9.5 13.5 9 13 9H7Z"
      fill={color}
    />
  </svg>
);
