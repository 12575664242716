import { createSelector } from 'reselect';

import { isDefined } from '../../utils/is';

export const getLocation = (state: Store) => state.router.location;

export const getLocationState = createSelector(getLocation, location => location?.state as { from?: Location });

export const getPathName = (state: Store) => {
  const location = getLocation(state);

  if (!isDefined(location)) {
    return '';
  }

  return location.pathname;
};
