import { useTranslation } from 'react-i18next';

import { useModalVisibility } from '../../../utils/hooks';
import { Modal, ModalCloseButton, ModalSheet, ModalSize } from '../../various/ModalElements';

import { DimensionSelectors } from './DimensionSelectors';
import { OverviewTable } from './OverviewTable';
import { SelectionOverviewContextProvider } from './SelectionOverviewContext';
import styles from './SelectionOverviewModal.module.scss';
import { VariantsFilters } from './VariantsFilters';
import { ViewOptions } from './ViewOptions';

export const SelectionOverviewModal = () => {
  const { t } = useTranslation(['common', 'totals', 'products', 'selectionOverview']);
  const { isModalVisible, hideModal } = useModalVisibility('SelectionOverviewModal');

  return (
    <Modal isOpen={isModalVisible} size={ModalSize.XXLarge} onRequestClose={hideModal}>
      <SelectionOverviewContextProvider>
        <div className={styles.headingWrapper}>
          <h2 className={styles.heading}>{t('selectionOverview:selection_overview')}</h2>
          <div className={styles.headingRight}>
            <ViewOptions />
          </div>
        </div>
        <ModalSheet className={styles.modalContent}>
          <div className={styles.settings}>
            <div className={styles.settingsGroup}>
              <DimensionSelectors />
            </div>
            <div className={styles.settingsGroup}>
              <VariantsFilters />
            </div>
          </div>
          <OverviewTable />
        </ModalSheet>
        <ModalCloseButton dataTestId="closeSelectionOverviewModal" />
      </SelectionOverviewContextProvider>
    </Modal>
  );
};
