import { DatePicker } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { isDefined, isNull } from '../../../utils/is';
import Icon, { IconColor, IconType } from '../Icon';
import { Popover } from '../Popover';
import { Skeleton } from '../Skeleton';
import { SkeletonLayout } from '../SkeletonLayout';

import { DatePickerNavigationContextProvider, DatePickerSelectionContextProvider, DatePickerSettingsContextProvider } from './context';
import styles from './DatePicker.module.scss';
import { DatePickerCalendar } from './DatePickerCalendar';
import { DatePickerClearButton, DatePickerFooter, DatePickerHeader } from './DatePickerControls';
import { DatePickerTrigger } from './DatePickerTrigger';

const DatePickerComponent = React.forwardRef(
  <T extends DatePicker.Value>(props: DatePicker.Props<T>, ref: React.RefObject<HTMLInputElement>) => {
    const { t } = useTranslation(['dates']);
    const {
      dataTestId,
      disabled = false,
      invalid = false,
      inputDataTestId,
      isClearable = false,
      isPastDisabled = true,
      max = null,
      min = null,
      onChange,
      placeholder = t('dates:select_date'),
      shouldShowDot,
      shouldShowToday = true,
      triggerFormatter,
      size = 'regular',
      value,
      isLoading = false,
      onPopoverClose,
    } = props;

    const [isCalendarVisible, setIsCalendarVisible] = React.useState(false);

    const showCalendar = React.useCallback(() => {
      setIsCalendarVisible(true);
    }, []);

    const handleChange = (newValue: T, shouldCloseCalendar = false) => {
      onChange(newValue);

      if (shouldCloseCalendar) {
        setIsCalendarVisible(false);
      }
    };

    const handleVisibleChange = (isVisible: boolean) => {
      if (isDefined(onPopoverClose) && !isVisible) {
        onPopoverClose();
      }
      setIsCalendarVisible(isVisible);
    };

    const [startDate] = [value].flat();
    const shouldShowClearButton = isClearable && !isNull(startDate);

    return (
      <DatePickerSettingsContextProvider isPastDisabled={isPastDisabled} max={max} min={min} shouldShowDot={shouldShowDot}>
        <DatePickerNavigationContextProvider defaultVisibleDate={startDate ?? null}>
          <DatePickerSelectionContextProvider value={value ?? null} onChange={handleChange}>
            <SkeletonLayout isLoading={isLoading} skeleton={<Skeleton type="input" />}>
              <Popover
                content={
                  <div className={styles.popover}>
                    <DatePickerHeader size={size} label={placeholder} dataTestId={inputDataTestId} />
                    <DatePickerCalendar />
                    <DatePickerFooter shouldShowClear={isClearable} shouldShowToday={shouldShowToday} />
                  </div>
                }
                className={cx(styles.popoverWrapper, { [styles.sizeLarge]: size === 'large' })}
                triggerOffset={0}
                triggerClassName={styles.triggerWrapper}
                placement="bottom-start"
                possiblePlacements={['bottom-start', 'top-start']}
                visible={isCalendarVisible}
                onVisibleChange={handleVisibleChange}
              >
                <DatePickerTrigger
                  ref={ref}
                  dataTestId={dataTestId}
                  disabled={disabled}
                  invalid={invalid}
                  placeholder={placeholder}
                  formatter={triggerFormatter}
                  onClick={showCalendar}
                  size={size}
                />
                <Icon type={IconType.DelwinPreorder} color={IconColor.Medium} className={styles.triggerIcon} />
              </Popover>
              {shouldShowClearButton && (
                <div className={styles.clearButton}>
                  <DatePickerClearButton size="small" />
                </div>
              )}
            </SkeletonLayout>
          </DatePickerSelectionContextProvider>
        </DatePickerNavigationContextProvider>
      </DatePickerSettingsContextProvider>
    );
  },
);

export { DatePickerComponent as DatePicker };
