import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { DropdownCheckboxGroup } from '../../../various/Dropdown';
import { Checkbox } from '../../../various/Fields/Checkbox';
import { SelectionOverviewContext } from '../SelectionOverviewContext';

import styles from './VariantsFilters.module.scss';

export const VariantsFilters = () => {
  const {
    rowFilterOptions,
    shouldShowWithUnits,
    setShouldShowWithUnits,
    deselectAllRowFilterOptions,
    rowFilterOptionsState,
    toggleRowFilterOptionsState,
  } = React.useContext(SelectionOverviewContext);
  const { t } = useTranslation(['selectionOverview']);

  const toggleShouldShowWithUnits = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setShouldShowWithUnits(!event.target.checked);
    },
    [setShouldShowWithUnits],
  );

  return (
    <>
      <div className={styles.filtersOption}>
        <Checkbox checked={!shouldShowWithUnits} onChange={toggleShouldShowWithUnits}>
          {t('selectionOverview:show_variants_without_units')}
        </Checkbox>
      </div>
      <div className={cx(styles.filtersOption, styles.rowsFilter)}>
        <DropdownCheckboxGroup
          options={rowFilterOptions}
          title={t('selectionOverview:filter_rows')}
          onChange={toggleRowFilterOptionsState}
          optionsState={rowFilterOptionsState}
          onDeselectAll={deselectAllRowFilterOptions}
          placement="bottom-start"
          emptyMessage={t('selectionOverview:no_filter_data')}
        />
      </div>
    </>
  );
};
