import cx from 'classnames';

import { Table } from '../../../ducks/helpers';

import { ItemsQuantity } from './ItemsQuantity';

interface Props {
  quantity: number;
  table: Table;
  cellClassName?: string;
  tooltipClassName?: string;
}

export const ItemsQuantityCell = ({ cellClassName, ...itemsQuantityProps }: Props) => {
  return (
    <td className={cx(cellClassName)}>
      <ItemsQuantity {...itemsQuantityProps} />
    </td>
  );
};
