import React from 'react';
import { useSelector } from 'react-redux';

import { ArrowLeft, Filter } from '../../../../various/Icon';
import { BarcodeScannerButton } from '../../BarcodeScannerButton';
import { FiltersNumber } from '../../FiltersNumber';
import { FiltersPanelSelectorsContext } from '../context/FiltersPanelSelectorsContext';
import styles from '../FiltersPanel.module.scss';

const FILTER_ICON_SIZE = 21;
const ARROW_ICON_SIZE = 17;

interface Props {
  isFilterPanelPinned: boolean;
  isFilterPanelExpanded: boolean;
  showBarcodeScanner?: boolean;
  togglePanelPinned: () => void;
}

export const FiltersPanelButtons = ({
  isFilterPanelPinned,
  isFilterPanelExpanded,
  showBarcodeScanner = true,
  togglePanelPinned,
}: Props) => {
  const { getFilters } = React.useContext(FiltersPanelSelectorsContext);
  const filters = useSelector(getFilters);

  return (
    <div className={styles.buttonsContainer}>
      <button className={styles.filtersToggleButton} onClick={togglePanelPinned}>
        {isFilterPanelPinned ?
          <ArrowLeft color="#fff" width={ARROW_ICON_SIZE} />
        : <>
            <Filter color="#fff" width={FILTER_ICON_SIZE} />
            <FiltersNumber filters={filters} />
          </>
        }
      </button>

      {showBarcodeScanner && <BarcodeScannerButton hidden={isFilterPanelExpanded} />}
    </div>
  );
};
