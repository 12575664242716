export const getTextStyles = (state: Store) => state.cms.customStyles.textStyles;
export const getButtonStyles = (state: Store) => state.cms.customStyles.buttonStyles;

export const getIsCustomStylesLoading = (state: Store) => state.cms.customStyles.isLoading;

export const getIsCreatingTextStyle = (state: Store) => state.cms.customStyles.isCreatingTextStyle;
export const getIsCreatingButtonStyle = (state: Store) => state.cms.customStyles.isCreatingButtonStyle;

export const getIsEditingTextStyle = (state: Store) => state.cms.customStyles.isEditingTextStyle;
export const getIsEditingButtonStyle = (state: Store) => state.cms.customStyles.isEditingButtonStyle;

export const getCustomStyleErrors = (state: Store) => state.cms.customStyles.errors;
export const getMissingCustomStyles = (state: Store) => state.cms.customStyles.missingCustomStyles;
