import React from 'react';
import { useTranslation } from 'react-i18next';

import { Alert } from '../../various/Alert';
import { EditorLanguageContext } from '../context/EditorLanguageContext';

import styles from './LayoutEditInfo.module.scss';

export const LayoutEditInfo = () => {
  const { isDefaultLanguage } = React.useContext(EditorLanguageContext);
  const { t } = useTranslation(['cms']);

  if (isDefaultLanguage) {
    return null;
  }

  return (
    <div className={styles.alertContainer}>
      <Alert className={styles.alert} type="info" displayOverContent noRoundCorners message={t('cms:layout_edit_info')} />
    </div>
  );
};
