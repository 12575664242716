import { CreateAccountDTO } from '@typings';
import { AxiosError } from 'axios';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { getCountries, getMarkets, getPricelists } from '../../../ducks';
import { useCreateAccount } from '../../../services/hooks/account/useCreateAccount';
import { useValidatedForm } from '../../../utils/hooks/useValidatedForm';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { getStateSchema } from '../../../utils/schemas';
import { Option } from '../../various/Fields/Select';
import { AutocompleteField, FieldLabel, Form, FormButtons, FormFieldset, FormGrid, InputField } from '../../various/Form';
import { Button } from '../../various/NewButton';

const defaultValues: CreateAccountDTO = {
  accountName: '',
  country: null,
  email: '',
  firstName: '',
  lastName: '',
  market: null,
  pricelist: null,
  state: null,
};

interface Props {
  onCancel: () => void;
  onSuccess: () => void;
}

export const CreateAccountForm = ({ onCancel, onSuccess }: Props) => {
  const { t } = useTranslation(['accounts', 'buyers', 'common', 'shipping', 'validation']);
  const countries = useSelector(getCountries);
  const markets = useSelector(getMarkets);
  const pricelists = useSelector(getPricelists);

  const { mutate, isPending } = useCreateAccount();

  const validationSchema: yup.ObjectSchema<CreateAccountDTO> = yup.object({
    accountName: yup.string().required(t('validation:account_name_hint')),
    country: yup.string().required(t('validation:country_hint')),
    email: yup.string().email(t('validation:invalid_email_hint')).required(t('validation:buyers_email_address_hint')),
    firstName: yup.string().required(t('validation:first_name_hint')),
    lastName: yup.string().defined(),
    market: yup.string().required(t('validation:select_account_market_hint')),
    pricelist: yup.string().required(t('validation:select_account_pricelist_hint')),
    state: getStateSchema(countries),
  });

  const formMethods = useValidatedForm<CreateAccountDTO>({ defaultValues, validationSchema });
  const { register, setValue, setError, watch } = formMethods;
  const selectedCountry = watch('country');

  const handleCountryChange = () => {
    setValue('state', null);
  };

  const countryOptions = React.useMemo(() => {
    return Object.values(countries).map(({ country, name }) => ({ country, name }));
  }, [countries]);

  const stateOptions = React.useMemo(() => {
    if (!isDefined(selectedCountry)) {
      return [];
    }

    return Object.values(countries[selectedCountry]?.states ?? {});
  }, [countries, selectedCountry]);

  const handleSubmit = (data: CreateAccountDTO) => {
    mutate(data, {
      onError: (error: AxiosError<Responses.Errors>) => {
        const errors = error.response?.data.errors;

        if (typeof errors === 'object') {
          Object.entries(errors).forEach(([field, message]) => {
            setError(field as keyof CreateAccountDTO, { message });
          });
        }
      },
      onSuccess,
    });
  };

  return (
    <Form formMethods={formMethods} onSubmit={handleSubmit}>
      <FormFieldset isDisabled={isPending}>
        <FieldLabel as="span" label={t('accounts:account_one')} />
        <FormGrid cols={2}>
          <InputField label={t('common:name')} isRequired {...register('accountName')} />
          <AutocompleteField name="market" label={t('common:market')} isRequired>
            {markets.map(({ id, name }) => (
              <Option key={id} value={id}>
                {name}
              </Option>
            ))}
          </AutocompleteField>
          <AutocompleteField name="pricelist" label={t('common:pricelist')} isRequired>
            {pricelists.map(({ id, name }) => (
              <Option key={id} value={id}>
                {name}
              </Option>
            ))}
          </AutocompleteField>
        </FormGrid>
      </FormFieldset>
      <FormFieldset isDisabled={isPending}>
        <FieldLabel as="span" label={t('shipping:shipping')} />
        <FormGrid cols={2}>
          <AutocompleteField name="country" label={t('common:country')} isRequired onChange={handleCountryChange}>
            {countryOptions.map(({ country, name }) => (
              <Option key={country} value={country}>
                {name}
              </Option>
            ))}
          </AutocompleteField>
          {!isEmpty(stateOptions) && (
            <AutocompleteField name="state" label={t('common:state')} isRequired>
              {stateOptions.map(({ state, name }) => (
                <Option key={state} value={state}>
                  {name}
                </Option>
              ))}
            </AutocompleteField>
          )}
        </FormGrid>
      </FormFieldset>
      <FormFieldset isDisabled={isPending}>
        <FieldLabel as="span" label={t('buyers:buyer_one')} />
        <FormGrid cols={2}>
          <InputField label={t('common:first_name')} isRequired {...register('firstName')} />
          <InputField label={t('common:last_name')} {...register('lastName')} />
          <InputField label={t('common:email')} isRequired {...register('email')} />
        </FormGrid>
      </FormFieldset>
      <FormButtons showDivider>
        <Button size="large" variant="ghost" color="dark" disabled={isPending} onClick={onCancel}>
          {t('common:cancel')}
        </Button>
        <Button type="submit" size="large" isLoading={isPending}>
          {t('common:create')}
        </Button>
      </FormButtons>
    </Form>
  );
};
