import { Filter } from '@typings';
import { isEmpty } from 'ramda';

import { isDefined } from '../../is';

const enum FiltersPanelTrackingEventWithParameters {
  FILTERS_PANEL_PINNED = 'filters-panel-pinned',
  MULTIPLE_CATEGORIES_SELECTED = 'filters-panel-multiple-categories-selected',
  SINGLE_CATEGORY_SELECTED = 'filters-panel-single-category-selected',
}

export const getFiltersPanelPinnedEvent = (isFiltersPanelPinned: boolean) => ({
  event: FiltersPanelTrackingEventWithParameters.FILTERS_PANEL_PINNED,
  isFiltersPanelPinned,
});

export const getSelectedCategoriesTrackPayload = (categoryOptions: Filter.Option<string>[], selectedCategoryValues: string[]) => {
  const checkedCategories = categoryOptions.filter(category => selectedCategoryValues.includes(category.value));
  const selectedCategories = checkedCategories.filter(({ parent }) => !(isDefined(parent) && selectedCategoryValues.includes(parent)));

  const categories = selectedCategories.map(category => {
    const parent = isDefined(category.parent) ? categoryOptions.find(({ value }) => value === category.parent) : null;
    const grandParent = isDefined(parent?.parent) ? categoryOptions.find(({ value }) => value === parent?.parent) : null;

    return {
      grandParent: grandParent?.data ?? null,
      name: category.data,
      parent: parent?.data ?? null,
    };
  });

  if (isEmpty(categories)) {
    return;
  }

  if (categories.length === 1) {
    return {
      category: categories[0],
      event: FiltersPanelTrackingEventWithParameters.SINGLE_CATEGORY_SELECTED,
    };
  }

  return {
    categories,
    categoryCount: categories.length,
    event: FiltersPanelTrackingEventWithParameters.MULTIPLE_CATEGORIES_SELECTED,
  };
};
