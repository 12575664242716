import { Navigation } from '@typings';
import { nanoid } from 'nanoid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { useValidatedForm } from '../../../../utils/hooks/useValidatedForm';
import { Form, FormDivider, FormFieldset, FormGrid, InputField } from '../../../various/Form';
import { Button } from '../../../various/NewButton';
import { useMenuSectionContext } from '../context/MenuSectionContext';

import styles from './Forms.module.scss';

const DEFAULT_VALUES = {
  label: '',
};

interface FolderFormFields {
  label: string;
}

export const FolderForm = () => {
  const { areInputsDisabled, isInEditMode, setAddedMenuItems } = useMenuSectionContext();
  const { t } = useTranslation(['validation', 'common', 'settings']);

  const validationSchema = yup.object({
    label: yup.string().trim().required(t('validation:navigation_label_hint')),
  });

  const formMethods = useValidatedForm({ defaultValues: DEFAULT_VALUES, validationSchema });
  const { register, reset } = formMethods;

  React.useEffect(() => {
    if (!isInEditMode) {
      reset();
    }
  }, [isInEditMode, reset]);

  const handleSubmit = React.useCallback(
    (values: FolderFormFields) => {
      reset();

      const newMenuItem: Navigation.FolderMenuItem = {
        id: nanoid(),
        label: values.label,
        markets: null,
        openNewTab: false,
        pageId: null,
        type: 'folder',
        url: null,
      };

      setAddedMenuItems(currentItems => [...currentItems, newMenuItem]);
    },
    [reset, setAddedMenuItems],
  );

  return (
    <Form formMethods={formMethods} onSubmit={handleSubmit}>
      <FormFieldset className={styles.form} isDisabled={areInputsDisabled}>
        <FormGrid>
          <InputField label={t('settings:navigation_label')} isRequired {...register('label')} />
        </FormGrid>
        <FormDivider className={styles.divider} />
        <Button type="submit" className={styles.addButton}>
          {t('common:add_item')}
        </Button>
      </FormFieldset>
    </Form>
  );
};
