import { createColumnHelper, getCoreRowModel } from '@tanstack/react-table';
import { type Lookbook as LookbookType } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { getIsLookbookEnabled, showModal } from '../../ducks';
import { getMarketName } from '../../logic/lookbook';
import { compiledPaths, paths } from '../../paths';
import { useDeleteLookbook } from '../../services/hooks/lookbook/useDeleteLookbook';
import { useLookbooksList } from '../../services/hooks/lookbook/useLookbooksList';
import { copyToClipboard } from '../../utils/clipboard';
import { useTable } from '../../utils/hooks/table/useTable';
import { useTableColumnsConfiguration } from '../../utils/hooks/table/useTableColumnsConfiguration';
import { useConfirmationGuard } from '../../utils/hooks/useConfirmationGuard';
import { isEmpty } from '../../utils/isEmpty';
import { renderMaskedCell, renderStandardCell } from '../../utils/table';
import { EmptyState } from '../various/EmptyState';
import { FloatingButton } from '../various/FloatingButton/';
import { Link } from '../various/Link';
import { Button } from '../various/NewButton';
import { PageTitle } from '../various/PageTitle';
import { Table } from '../various/Table';
import tableStyles from '../various/Table/Table.module.scss';
import { TableControls } from '../various/TableControls';
import { addToast } from '../various/Toasts';
import { TopBar } from '../various/TopBar';
import { Wrapper, WrapperSize } from '../various/Wrapper';

import { CreateLookbookModal } from './CreateLookbookModal';
import styles from './Lookbook.module.scss';

type LookbookRow = Overwrite<LookbookType, { deliveryWindows: string }>;

const columnHelper = createColumnHelper<LookbookRow>();

export const Lookbook = () => {
  const { t } = useTranslation(['common', 'lookbook', 'confirmationConfig']);
  const dispatch = useDispatch();
  const isLookbookEnabled = useSelector(getIsLookbookEnabled);
  const confirmationGuard = useConfirmationGuard();
  const { columnsVisibility } = useTableColumnsConfiguration({ tableName: 'lookbook' });

  const { data, isLoading } = useLookbooksList();
  const tableData = data?.lookbooks ?? [];
  const { mutate: deleteLookbook } = useDeleteLookbook();

  const markets = React.useMemo(() => data?.markets ?? [], [data?.markets]);

  const handleCopyLinkClick = React.useCallback(
    (link: string) => {
      copyToClipboard(link);
      addToast(t('lookbook:lookbook_link_copied_message'));
    },
    [t],
  );

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('deliveryWindows', {
        cell: info => {
          const path = compiledPaths.LOOKBOOK_SINGLE({ lookbookId: info.row.original.lookbook });

          return (
            <Link variant="blue" to={path}>
              {info.getValue()}
            </Link>
          );
        },
        enableHiding: false,
        header: t('common:delivery_window_other'),
      }),
      columnHelper.accessor('email', {
        cell: renderMaskedCell,
        header: t('common:email'),
        meta: {
          noWrap: true,
        },
      }),
      columnHelper.accessor('dateCreated', {
        cell: renderStandardCell,
        header: t('common:date_created'),
      }),
      columnHelper.accessor('dateLastVisited', {
        cell: renderStandardCell,
        header: t('common:date_last_visited'),
      }),
      columnHelper.accessor('visitedCount', {
        cell: renderStandardCell,
        header: t('common:visited_count'),
        meta: {
          align: 'right',
        },
      }),
      columnHelper.accessor('market', {
        cell: info => getMarketName(markets, info.getValue()),
        header: t('common:market'),
      }),
      columnHelper.display({
        cell: ({ row }) => {
          const { lookbook } = row.original;
          const path = compiledPaths.LOOKBOOK_SINGLE({ lookbookId: lookbook });
          const link = `${window.location.origin}${path}`;
          const deleteAction = confirmationGuard(() => ({
            onOk: () => deleteLookbook(lookbook),
            ...t('confirmationConfig:delete_lookbook', { returnObjects: true }),
          }));

          return (
            <>
              <button className={tableStyles.linkButton} onClick={() => handleCopyLinkClick(link)}>
                {t('lookbook:copy_link')}
              </button>
              <span className={styles.divider}>|</span>
              <button className={tableStyles.linkButton} onClick={deleteAction}>
                {t('common:delete')}
              </button>
            </>
          );
        },
        enableHiding: false,
        header: t('common:action_other'),
        id: 'actions',
        meta: {
          width: 200,
        },
      }),
    ],
    [t, markets, confirmationGuard, deleteLookbook, handleCopyLinkClick],
  );

  const showLookbookModal = () => {
    dispatch(showModal('CreateLookbookModal'));
  };

  const shouldShowTable = isLoading || !isEmpty(tableData);
  const table = useTable(
    {
      columns,
      data: tableData,
      enableSorting: false,
      getCoreRowModel: getCoreRowModel(),
    },
    columnsVisibility,
  );

  if (!isLookbookEnabled) {
    return <Navigate to={paths.ROOT} replace />;
  }

  return (
    <PageTitle title={t('common:lookbook')}>
      <>
        <TopBar title={t('common:lookbook')} />
        <Wrapper size={WrapperSize.XXLARGE}>
          {shouldShowTable && (
            <TableControls tableName="lookbook" table={table} className={styles.tableControls} columnsConfiguration={columnsVisibility} />
          )}
          {shouldShowTable ?
            <Table table={table} isLoading={isLoading} />
          : <EmptyState title={t('lookbook:no_results.default.title')} subtitle={t('lookbook:no_results.default.subtitle')}>
              <Button variant="bordered" color="dark" onClick={showLookbookModal}>
                {t('lookbook:create_lookbook')}
              </Button>
            </EmptyState>
          }
          {!isEmpty(tableData) && <FloatingButton onClick={showLookbookModal} label={t('lookbook:create_lookbook')} />}
        </Wrapper>
        <CreateLookbookModal />
      </>
    </PageTitle>
  );
};
