import { Toast } from '@typings';
import React from 'react';

import { Action } from './actions';
import { reducer } from './reducer';

export interface State {
  toasts: Toast[];
  pausedAt: number | undefined;
}

const DEFAULT_TIMEOUT = 4000;

const listeners: Array<(state: State) => void> = [];

// eslint-disable-next-line functional/no-let
let memoryState: State = { pausedAt: undefined, toasts: [] };

export const dispatch = (action: Action) => {
  memoryState = reducer(memoryState, action);
  listeners.forEach(listener => {
    listener(memoryState);
  });
};

export const useStore = (toastOptions: Toast.Options = {}): State => {
  const [state, setState] = React.useState<State>(memoryState);

  React.useEffect(() => {
    // eslint-disable-next-line functional/immutable-data
    listeners.push(setState);

    return () => {
      const index = listeners.indexOf(setState);
      if (index > -1) {
        // eslint-disable-next-line functional/immutable-data
        listeners.splice(index, 1);
      }
    };
  }, [state]);

  const mergedToasts = state.toasts.map(toast => ({
    ...toastOptions,
    ...toast,
    duration: toast.duration ?? toastOptions.duration ?? DEFAULT_TIMEOUT,
  }));

  return {
    ...state,
    toasts: mergedToasts,
  };
};
