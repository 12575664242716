/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 32;

export const Attachment = ({ width = ORIGINAL_WIDTH }: IconComponentProps) => {
  return (
    <svg width={width} height={width} fill="none">
      <path
        d="M20.927 0H8.101a3.407 3.407 0 0 0-3.4 3.406V13H4.37A1.37 1.37 0 0 0 3 14.373v11.323a1.37 1.37 0 0 0 1.37 1.372h.332v1.526A3.406 3.406 0 0 0 8.1 32h17.501A3.406 3.406 0 0 0 29 28.594V8.061L20.927 0Zm4.675 29.914H8.101a1.32 1.32 0 0 1-1.317-1.32v-1.526h8.846A1.37 1.37 0 0 0 17 25.696V14.372A1.37 1.37 0 0 0 15.63 13H6.784V3.406a1.32 1.32 0 0 1 1.317-1.318l12.047-.013v4.463a2.36 2.36 0 0 0 2.358 2.361l4.362-.012.05 19.707c0 .728-.59 1.32-1.316 1.32Z"
        fill="#81A3AF"
      />
      <path
        d="M11.1 18.898a2.333 2.333 0 0 0-3.3 0l-2.118 2.121A2.333 2.333 0 0 0 8.98 24.32l1.75-1.75a.166.166 0 0 0-.12-.285h-.066a2.803 2.803 0 0 1-1.066-.206.167.167 0 0 0-.182.037l-1.258 1.26a1 1 0 0 1-1.415-1.416l2.128-2.126a1 1 0 0 1 1.413 0 .683.683 0 0 0 .937 0 .666.666 0 0 0 .193-.423.666.666 0 0 0-.193-.513Z"
        fill="#fff"
      />
      <path
        d="M14.317 15.683a2.333 2.333 0 0 0-3.3 0L9.27 17.43a.167.167 0 0 0 .122.285h.061c.366-.001.727.07 1.065.208a.167.167 0 0 0 .182-.037l1.255-1.253a1 1 0 0 1 1.414 1.415l-1.563 1.561-.013.015-.546.544a1 1 0 0 1-1.414 0 .683.683 0 0 0-.936 0 .667.667 0 0 0 0 .94c.193.193.418.351.666.466.035.017.07.03.105.045.035.015.072.027.107.04a1.3 1.3 0 0 0 .107.035l.098.027a2.322 2.322 0 0 0 .45.065h.127l.1-.012c.036-.002.075-.01.118-.01h.057l.114-.017.054-.01.096-.02h.019c.41-.103.783-.314 1.081-.613l2.122-2.121a2.333 2.333 0 0 0 0-3.3Z"
        fill="#fff"
      />
    </svg>
  );
};
