import { Id } from '@typings';

import { isDefined } from '../../utils/is';
import { createShallowEqualSelector } from '../utils';

export const getPageData = (store: Store) => store.pages.pageData;
export const getIsLoadingPage = (store: Store) => store.pages.isLoadingPage;
export const getCurrentPageSlug = (store: Store) => store.pages.currentPageSlug;

export const getIsPageSaved = (store: Store) => store.pages.isSaved;
export const getIsSavingPage = (store: Store) => store.pages.isSavingPage;
export const getIsPublishing = (store: Store) => store.pages.isPublishing;

export const getViewSections = (store: Store) => store.pages.pageData.sections;

const getBlocksByIds = (blockIds: Nullable<string>[]) => (store: Store) => {
  const { blocks } = store.pages.pageData;

  return blockIds.map(id => (isDefined(id) ? blocks[id] : null));
};

export const getViewBlocksByIds = (blockIds: Nullable<string>[]) =>
  createShallowEqualSelector(getBlocksByIds(blockIds), blocks => blocks.filter(isDefined));

export const getViewGroupById = (id: Id) => (store: Store) => store.pages.pageData.groups[id] ?? null;
export const getViewBlockById = (id: Id) => (store: Store) => store.pages.pageData.blocks[id] ?? null;
