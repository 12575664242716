import cx from 'classnames';

import styles from './BlockIdentifier.module.scss';

interface BlockIdentifierProps {
  indexLabel: number;
  isContent: boolean;
  isProduct: boolean;
}

export const BlockIdentifier = ({ indexLabel, isContent, isProduct }: BlockIdentifierProps) => {
  return (
    <div
      className={cx(styles.blockIdentifier, {
        [styles.content]: isContent,
        [styles.product]: isProduct,
      })}
    >
      {indexLabel}
    </div>
  );
};
