/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;
const ORIGINAL_COLOR = '#848484';

export const Calendar = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox="0 0 20 20" fill={color}>
      <path
        fillRule="evenodd"
        d="M6.182 1.614a.75.75 0 0 1 .75.75v.522h6.136v-.522a.75.75 0 0 1 1.5 0v.522h1.796c.765 0 1.386.62 1.386 1.387V17c0 .766-.62 1.386-1.386 1.386H3.636c-.765 0-1.386-.62-1.386-1.386V4.273c0-.766.62-1.387 1.386-1.387h1.796v-.522a.75.75 0 0 1 .75-.75Zm-.75 2.772H3.75v2.318h12.5V4.386h-1.682v.523a.75.75 0 0 1-1.5 0v-.523H6.932v.523a.75.75 0 0 1-1.5 0v-.523ZM16.25 8.204H3.75v8.682h12.5V8.204Zm-10.055 3.45a.75.75 0 0 1 .75-.75h.01a.75.75 0 0 1 0 1.5h-.01a.75.75 0 0 1-.75-.75Zm3.065 0a.75.75 0 0 1 .75-.75h.01a.75.75 0 0 1 0 1.5h-.01a.75.75 0 0 1-.75-.75Zm3.065 0a.75.75 0 0 1 .75-.75h.01a.75.75 0 0 1 0 1.5h-.01a.75.75 0 0 1-.75-.75Zm-6.13 3.055a.75.75 0 0 1 .75-.75h.01a.75.75 0 0 1 0 1.5h-.01a.75.75 0 0 1-.75-.75Zm3.065 0a.75.75 0 0 1 .75-.75h.01a.75.75 0 0 1 0 1.5h-.01a.75.75 0 0 1-.75-.75Zm3.065 0a.75.75 0 0 1 .75-.75h.01a.75.75 0 0 1 0 1.5h-.01a.75.75 0 0 1-.75-.75Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
