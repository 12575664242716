import { Id } from '@typings';

import { isDefined } from '../utils/is';

import { showroomApi } from './api';

export const fetchAllDeliveryWindows = async () => showroomApi.POST('/delivery-windows')({ data: {} });

export const fetchOrderSpecificDeliveryWindows = async (orderId?: Id) =>
  showroomApi.POST('/delivery-windows')({
    data: isDefined(orderId) ? { order: orderId } : {},
  });

export const fetchBuyerAndOrderSpecificDeliveryWindows = async (buyerId: Id, orderId?: Id) =>
  showroomApi.POST('/delivery-windows')({
    data: {
      buyer: buyerId,
      ...(isDefined(orderId) && { order: orderId }),
    },
  });
