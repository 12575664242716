import { Product } from '@typings';

import { RegularHeaderCell } from '../MatrixCells/RegularHeaderCell';

interface Props {
  variant: Product.Standard;
  rowIndex: number;
}

export const TwoDimensionalBodyCell = ({ variant, rowIndex }: Props) => {
  return <RegularHeaderCell>{variant.itemTable.y[rowIndex]}</RegularHeaderCell>;
};
