import React from 'react';
import { useTranslation } from 'react-i18next';

import { isDefined } from '../../../utils/is';
import { Iframe } from '../Iframe';
import { Preview } from '../Preview';

import styles from './IframePreview.module.scss';

interface Props {
  src: Maybe<string>;
}

export const IframePreview = ({ src, children }: React.WithChildren<Props>) => {
  const { t } = useTranslation(['common']);

  if (isDefined(src)) {
    return (
      <div className={styles.wrapper}>
        <Iframe title={t('common:preview')} src={src} />
      </div>
    );
  }

  return <Preview>{children}</Preview>;
};
