import { Product } from '@typings';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getOrderDetails } from '../../../../../../../ducks';
import { getAreBackordersEnabled, getDefaultImageSize } from '../../../../../../../ducks/config';
import {
  getDefaultImageAspectRatio,
  getNativeVariants,
  getProductAvailability,
  getProductImageOrPlaceholderBySize,
} from '../../../../../../../ducks/helpers';
import { getIsOrderEditable } from '../../../../../../../logic/Orders';
import { GetProductUrl } from '../../../../../../../logic/products';
import { isDefined } from '../../../../../../../utils/is';
import { CustomImage } from '../../../../../../various/CustomImage';
import { AddProductButton } from '../../../../../AddProductButton';
import { useProductDetailsVariantContext } from '../../../../context/ProductDetailsVariantContext';
import { VariantLabel } from '../../../../VariantLabel';

import styles from './RelatedVariants.module.scss';

interface Props {
  variants: Product.Standard[];
  displayMoreText: boolean;
  getProductUrl: GetProductUrl;
}

export const RelatedVariants = ({ variants, displayMoreText, getProductUrl }: Props) => {
  const { productDetails } = useProductDetailsVariantContext();
  const { t } = useTranslation(['common']);

  const aspectRatio = useSelector(getDefaultImageAspectRatio);
  const defaultImageSize = useSelector(getDefaultImageSize);
  const orderDetails = useSelector(getOrderDetails);
  const backordersEnabled = useSelector(getAreBackordersEnabled);

  return (
    <ul className={styles.relatedVariants}>
      {variants.map(variant => {
        const productId = variant.product;

        const imageSrc = getProductImageOrPlaceholderBySize(variant, defaultImageSize);
        const nativeVariants = getNativeVariants(productDetails, variant.product);
        const isAvailable = nativeVariants.some(nativeVariant => getProductAvailability(nativeVariant));

        const nativeVariantsNumber = nativeVariants.length - 1;

        const shouldShowAddButton =
          (isAvailable || backordersEnabled) && getIsOrderEditable(orderDetails.order.status) && isDefined(variant.price);
        const shouldShowMoreText = displayMoreText && nativeVariantsNumber > 0;

        return (
          <li key={productId} className={cx(styles.variant)}>
            <div
              className={cx(styles.imageWrapper, {
                [styles.active]: productDetails.product === productId,
              })}
            >
              <Link to={getProductUrl({ productId })}>
                <CustomImage aspectRatio={aspectRatio} src={imageSrc.src} rounded size="cover" />
              </Link>
              {shouldShowAddButton && (
                <div className={styles.variantCheckContainer}>
                  <AddProductButton productId={productId} size="mini" />
                </div>
              )}
            </div>
            {shouldShowMoreText ?
              <span className={styles.moreInfo}>
                +{nativeVariantsNumber} {t('common:more')}
              </span>
            : <VariantLabel variant={variant} />}
          </li>
        );
      })}
    </ul>
  );
};
