import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  getGroupsOrderBySectionId,
  getIsFirstSection,
  getIsLastSection,
  getMarkets,
  getPageMetaData,
  moveSection,
  removeSection,
  setCurrentSection,
  showModal,
} from '../../../ducks';
import { useSectionCopyPaste } from '../../../utils/hooks/cms/useSectionCopyPaste';
import { useConfirmationGuard } from '../../../utils/hooks/useConfirmationGuard';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { HoverTools } from '../../cms/hoverTools';
import Icon, { IconColor, IconType } from '../../various/Icon';
import { TextEllipsis } from '../../various/TextEllipsis';

import { Props } from './createSection';
import styles from './section.module.scss';

export const SectionTools = ({ sectionData }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['cmsConfirmationConfig', 'cms', 'common']);
  const allMarkets = useSelector(getMarkets);
  const pageMeta = useSelector(getPageMetaData);
  const { id, visibleFor } = sectionData;
  const isFirstSection = useSelector(getIsFirstSection(id));
  const isLastSection = useSelector(getIsLastSection(id));
  const groupsOrder = useSelector(getGroupsOrderBySectionId(id));
  const canReorderGroups = groupsOrder.length > 1;
  const hasGroups = !isEmpty(groupsOrder);
  const confirmationGuard = useConfirmationGuard();
  const { copySection, cutSection } = useSectionCopyPaste();

  const handleRemoveSection = confirmationGuard(() => ({
    onOk: () => dispatch(removeSection(id)),
    ...t('cmsConfirmationConfig:delete_section', { returnObjects: true }),
    okText: t('common:delete'),
  }));

  const handleShowModal = React.useCallback(() => {
    dispatch(setCurrentSection(id));
    dispatch(showModal('AddTemplateModal'));
  }, [dispatch, id]);

  const handleCopySection = React.useCallback(() => {
    copySection(id);
  }, [copySection, id]);

  const handleCutSection = React.useCallback(() => {
    cutSection(id);
  }, [cutSection, id]);

  const handleSectionVisibility = React.useCallback(() => {
    dispatch(setCurrentSection(id));
    dispatch(showModal('SectionVisibilityModal'));
  }, [dispatch, id]);

  const handleMoveUp = React.useCallback(() => {
    dispatch(moveSection({ offset: -1, sectionId: id }));
  }, [dispatch, id]);

  const handleMoveDown = React.useCallback(() => {
    dispatch(moveSection({ offset: 1, sectionId: id }));
  }, [dispatch, id]);

  const handleReorderContent = React.useCallback(() => {
    dispatch(setCurrentSection(id));
    dispatch(showModal('ReorderContentModal'));
  }, [dispatch, id]);

  const markets = isDefined(pageMeta) ? pageMeta.markets : allMarkets;

  const { isHidden, marketIds = [] } = visibleFor;

  const visibleForMarkets = React.useMemo(() => {
    return marketIds.map(marketId => markets.find(market => market.id === marketId)).filter(isDefined);
  }, [marketIds, markets]);

  const tools = [
    {
      icon: IconType.AddSquareOutline,
      key: 'addGroup',
      label: t('cms:add_layout'),
      onClick: handleShowModal,
    },
    {
      icon: IconType.CopyOutline,
      isDisabled: !hasGroups,
      key: 'copySection',
      label: t('cms:copy_section'),
      onClick: handleCopySection,
      testId: 'copySectionButton',
    },
    {
      icon: IconType.Cut,
      isDisabled: !hasGroups,
      key: 'cutSection',
      label: t('cms:cut_section'),
      onClick: handleCutSection,
      testId: 'cutSectionButton',
    },
    {
      hideExpandIndicator: true,
      icon: IconType.MoreHorizontal,
      items: [
        {
          icon: IconType.ReplaceVertical,
          isDisabled: !canReorderGroups,
          key: 'reorderContent',
          label: t('cms:reorder_section_content'),
          onClick: handleReorderContent,
        },
        {
          icon: IconType.Visibility,
          key: 'sectionVisibility',
          label: t('cms:section_visibility'),
          onClick: handleSectionVisibility,
        },
        {
          icon: IconType.MoveUp,
          isDisabled: isFirstSection,
          key: 'moveUp',
          label: t('cms:move_section_up'),
          onClick: handleMoveUp,
          testId: 'moveSectionUpButton',
        },
        {
          icon: IconType.MoveDown,
          isDisabled: isLastSection,
          key: 'moveDown',
          label: t('cms:move_section_down'),
          onClick: handleMoveDown,
          testId: 'moveSectionDownButton',
        },
        {
          icon: IconType.Trash,
          key: 'deleteSection',
          label: t('cms:delete_section'),
          onClick: handleRemoveSection,
          testId: 'deleteSectionButton',
        },
      ],
      key: 'moreSectionTools',
      label: t('cms:more_section_tools'),
      testId: 'sectionTools',
    },
  ];

  const visibilityIconType = isHidden ? IconType.EyeCrossed : IconType.Eye;

  const sectionLabel = isHidden ? t('cms:section_hidden') : t('cms:section');

  return (
    <HoverTools
      name={
        <div className={styles.description}>
          <span className={styles.sectionLabel}>{sectionLabel}</span>
          <Icon className={styles.visibilityIcon} type={visibilityIconType} color={IconColor.White} />
          <span className={styles.label}>{t('cms:market_other')}:</span>
          <TextEllipsis>{visibleForMarkets.map(market => market.name).join(', ')}</TextEllipsis>
        </div>
      }
      className={styles.hoverTools}
      elements={tools}
    />
  );
};
