import { useDroppable } from '@dnd-kit/core';
import React from 'react';

import { isDefined } from '../../../../../utils/is';

import styles from './ListScroller.module.scss';

const SCROLL_DISTANCE = 10;
interface ListScrollerProps extends React.WithChildren {
  scrollToDirection: { to: 'bottom' | 'top' } | null;
}
export const ListScroller = ({ children, scrollToDirection }: ListScrollerProps) => {
  const { isOver: isOverTop, setNodeRef: setTopRef } = useDroppable({ data: { type: 'scroller' }, id: 'top-droppable' });
  const { isOver: isOverBottom, setNodeRef: setBottomRef } = useDroppable({ data: { type: 'scroller' }, id: 'bottom-droppable' });
  const listContainerRef = React.useRef<HTMLUListElement>(null);
  const requestRef = React.useRef<number>();

  const scroll = (direction: 'up' | 'down') => () => {
    if (listContainerRef.current) {
      listContainerRef.current.scrollBy(0, direction === 'up' ? -SCROLL_DISTANCE : SCROLL_DISTANCE);
      requestRef.current = requestAnimationFrame(scroll(direction));
    }
  };

  React.useEffect(() => {
    if (!isDefined(scrollToDirection)) {
      return;
    }

    const scrollPoint = scrollToDirection.to === 'top' ? { top: 0 } : { top: listContainerRef.current?.scrollHeight };
    listContainerRef.current?.scroll({ behavior: 'smooth', ...scrollPoint });
  }, [scrollToDirection]);

  React.useEffect(() => {
    if (isOverTop) {
      requestRef.current = requestAnimationFrame(scroll('up'));
    } else if (requestRef.current) {
      cancelAnimationFrame(requestRef.current);
    }

    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, [isOverTop]);

  React.useEffect(() => {
    if (isOverBottom) {
      requestRef.current = requestAnimationFrame(scroll('down'));
    } else if (requestRef.current) {
      cancelAnimationFrame(requestRef.current);
    }

    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, [isOverBottom]);

  return (
    <div className={styles.sortableList}>
      <div className={styles.scrollerTop} ref={setTopRef} />
      <ul ref={listContainerRef} className={styles.itemList}>
        {children}
      </ul>
      <div className={styles.scrollerBottom} ref={setBottomRef} />
    </div>
  );
};
