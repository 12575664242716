/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 24;
const ORIGINAL_COLOR = '#848484';

export const Pin = ({ width = ORIGINAL_WIDTH }: IconComponentProps) => {
  return (
    <svg height={width} width={width} viewBox="0 0 24 24" fill={ORIGINAL_COLOR}>
      <path
        fillRule="evenodd"
        d="M9.86 14.34c.25.25.25.67 0 .92l-3.08 3.09a.66.66 0 0 1-.93-.93l3.08-3.08a.66.66 0 0 1 .93 0Z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M12.99 5.34a1.17 1.17 0 0 1 1.65 0l4.02 4.03a1.17 1.17 0 0 1 0 1.64m0 0-3.5 3.51c.6 1.72-.39 3.32-.91 4.02a1.17 1.17 0 0 1-1.75.12l-7.16-7.15a1.17 1.17 0 0 1 .1-1.74 4.5 4.5 0 0 1 4.04-.9l3.5-3.53m-3.62 4.83Zm4.45-3.85-3.72 3.73c-.2.2-.49.24-.73.12l-.04-.02-.18-.06a3.2 3.2 0 0 0-2.81.6l-.09-.08a.1.1 0 0 1 0 .15l.09-.07 6.98 6.98a5.6 5.6 0 0 1-.08.1l.09.07-.38.37.38-.37.42.31-.42-.32.07-.07-.08-.09c.52-.73 1.08-1.9.53-3.01a.64.64 0 0 1 .12-.73l3.72-3.74.08.07a.1.1 0 0 1 0-.14l-.08.07-3.87-3.87Zm-.07-.08a.1.1 0 0 0 .15 0l-.08.08-.07-.08Zm.07-.07.08.07-.08-.07-.37-.38.37.38Zm-.5 11.66-.08-.06a.1.1 0 0 1 .16-.01l-.07.07Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
