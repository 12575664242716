import { useSelector } from 'react-redux';

import { getDefaultImageAspectRatio } from '../../../../../ducks/helpers';
import { ProductBaseInfoLayout } from '../../../ProductBaseInfo';
import { ProductLineContainer } from '../../../ProductLineContainer';
import { Skeleton } from '../../../Skeleton/Skeleton';
import { DetailsAttributesSkeleton } from '../DetailsAttributesSkeleton';
import { MatrixSkeleton } from '../MatrixSkeleton';

import styles from './ProductLineSkeleton.module.scss';

interface Props {
  hasAddButton?: boolean;
}

export const ProductLineSkeleton = ({ hasAddButton = false }: Props) => {
  const imageAspectRatio = useSelector(getDefaultImageAspectRatio);

  return (
    <ProductLineContainer>
      <ProductBaseInfoLayout hasAddButton={hasAddButton}>
        {hasAddButton && <Skeleton type="rectangle" className={styles.addButton} />}
        <Skeleton type="rectangle" aspectRatio={imageAspectRatio} className={styles.image} />
        <div className={styles.description}>
          <Skeleton type="text" rows={2} size="large" lastWidth={60} gap="medium" containerClassName={styles.title} />
        </div>
        <DetailsAttributesSkeleton />
        <Skeleton type="text" rows={3} containerClassName={styles.summaries} />
      </ProductBaseInfoLayout>
      <MatrixSkeleton />
    </ProductLineContainer>
  );
};
