import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { getShippingCountries } from '../../countriesRepository';

export const useShippingCountries = () => {
  const { t } = useTranslation(['shipping']);

  return useQuery({
    meta: {
      errorMessage: t('shipping:fetch_shipping_countries_fail'),
    },
    queryFn: getShippingCountries,
    queryKey: ['shippingCountries'],
    refetchOnMount: false,
  });
};
