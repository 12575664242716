import React from 'react';

import { useGallery } from '../../../../../utils/hooks/useGallery';

import styles from './Gallery.module.scss';
import { GalleryDots } from './GalleryDots';

interface Props {
  activeIndex: number;
  aspectRatio: string;
  children: React.ReactElement[];
  onChange: (index: number) => void;
}

export const Gallery = ({ activeIndex, aspectRatio, children, onChange }: Props) => {
  const { handleScroll, ref } = useGallery({
    activeIndex,
    onChange,
    orientation: 'vertical',
  });

  return (
    <div className={styles.wrapper}>
      <div ref={ref} className={styles.gallery} style={{ aspectRatio }} onScroll={handleScroll}>
        {children}
      </div>
      <GalleryDots activeIndex={activeIndex} totalCount={children.length} onChange={onChange} />
    </div>
  );
};
