import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './ProductSummary.module.scss';

interface Props {
  variantsCount: string | number;
  unitsCount: string | number;
  price: string | number;
  className?: string;
}

export const ProductSummary = ({ variantsCount, unitsCount, price, className }: Props) => {
  const { t } = useTranslation(['products', 'common', 'totals']);

  return (
    <div className={cx(styles.summaries, className)}>
      <div className={styles.column}>
        <span>{variantsCount}</span>
        <div className={styles.subtle}>{t('products:variant_other')}</div>
      </div>
      <div className={styles.column}>
        <span>{unitsCount}</span>
        <div className={styles.subtle}>{t('common:unit_other')}</div>
      </div>
      <div className={styles.column}>
        <span>{price}</span>
        <div className={styles.subtle}>{t('totals:total_one')}</div>
      </div>
    </div>
  );
};
