/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;

export const Image = ({ width = ORIGINAL_WIDTH, color = 'currentColor' }: IconComponentProps) => {
  return (
    <svg width={width} height={width} viewBox="0 0 21 20" fill="none">
      <path
        d="M16.53 3.714H4.682a1.077 1.077 0 00-1.077 1.077v9.693a1.077 1.077 0 001.077 1.076H16.53a1.077 1.077 0 001.077-1.076V4.79a1.077 1.077 0 00-1.077-1.077zm0 1.077v6.916l-1.755-1.754a1.077 1.077 0 00-1.524 0L11.905 11.3 8.944 8.338a1.077 1.077 0 00-1.523 0l-2.738 2.738V4.79H16.53zM4.682 12.6l3.5-3.5 5.385 5.385H4.683v-1.885zm11.846 1.885h-1.438l-2.423-2.424 1.346-1.346 2.515 2.516v1.254zm-4.846-6.731a.807.807 0 111.615 0 .807.807 0 01-1.615 0z"
        fill={color}
      />
    </svg>
  );
};
