import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getMainVariantImagesFromStore, getNativeVariants } from '../../../../../ducks/helpers';
import { getDistinctVariantsOrMultivariants, GetProductUrl } from '../../../../../logic/products';
import { getHasRelatedProducts } from '../../../../../utils/guards';
import { useProductDetailsVariantContext } from '../../context/ProductDetailsVariantContext';

import { NativeVariants, RelatedVariants } from './components';
import styles from './VariantsThumbnailsNavigation.module.scss';

interface Props {
  getProductUrl: GetProductUrl;
}

export const VariantsThumbnailsNavigation = ({ getProductUrl }: Props) => {
  const { t } = useTranslation(['common', 'products', 'selections']);

  const { productDetails, variants, uniqByProductVariants } = useProductDetailsVariantContext();
  const variantImageSelector = React.useMemo(() => getMainVariantImagesFromStore(productDetails), [productDetails]);
  const variantImages = useSelector(variantImageSelector);

  const distinctOrMultivariants = React.useMemo(
    () => (getHasRelatedProducts(productDetails) ? getDistinctVariantsOrMultivariants([productDetails, ...variants]) : []),
    [productDetails, variants],
  );

  const variantsLength = uniqByProductVariants.length;
  const distinctOrMultivariantsLength = distinctOrMultivariants.length;

  const isSingleProduct = variantsLength === 1;
  const showDistinctVariantRelation = distinctOrMultivariantsLength > 1;
  const showNativeVariants = !showDistinctVariantRelation && variantImages.length > 1 && isSingleProduct;
  const nativeVariants = getNativeVariants(productDetails, productDetails.product);

  return (
    <div className={styles.variantsContainer}>
      {showNativeVariants && <NativeVariants variants={nativeVariants} getProductUrl={getProductUrl} />}

      {!isSingleProduct && <RelatedVariants variants={uniqByProductVariants} displayMoreText getProductUrl={getProductUrl} />}
      {showDistinctVariantRelation && (
        <>
          <h4 className={styles.distinctVariantsHeader}>{t('products:variant_other')}</h4>
          <RelatedVariants variants={distinctOrMultivariants} displayMoreText={false} getProductUrl={getProductUrl} />
        </>
      )}
    </div>
  );
};
