import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './Overlay.module.scss';

interface Props {
  visible: boolean;
}

export default function Overlay({ visible }: Props): JSX.Element {
  const { t } = useTranslation(['common']);

  const boxClassNames = classNames({
    [styles.box]: true,
    [styles.visible]: visible,
  });

  return (
    <div className={boxClassNames}>
      <span className={styles.message}>{t('common:no_internet_hint')}</span>
    </div>
  );
}
