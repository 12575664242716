import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Preset, Product } from '../../../../../../../../../typings';
import { pushEvent, removePrepacksRequest, setMultipleItemsRequest } from '../../../../../../../ducks';
import { getExtraSizeInfo } from '../../../../../../../logic/sizeCharts';
import { getUnitSwitcherName } from '../../../../../../../logic/unitsSwitch';
import { MatrixTrackingEvent } from '../../../../../../../utils/analytics/events';
import { useSelectedUnit } from '../../../../../../../utils/hooks/unitSwitcher/useSelectedUnit';
import { useVariantQuantities } from '../../../../../../../utils/hooks/useVariantQuantities';
import { isDefined } from '../../../../../../../utils/is';
import { isEmpty } from '../../../../../../../utils/isEmpty';
import { useMatrixDistributionContext, useMatrixPrepackContext } from '../../../../../../products/ProductMatrix/context';
import { addToast } from '../../../../../Toasts';

interface Props {
  distributionVariant: Product.Standard;
  deliveryWindowId: string;
}

export const useApplyDistribution = ({ deliveryWindowId, distributionVariant }: Props) => {
  const dispatch = useDispatch();
  const { closeDistributionPopup, modeFormMethods, distributionStage } = useMatrixDistributionContext();
  const { prepacksQuantitiesInVariants } = useMatrixPrepackContext();
  const { quantityAdded } = useVariantQuantities({ deliveryWindowId, variant: distributionVariant });
  const switchName = getUnitSwitcherName(distributionVariant.tableMappings ?? {});
  const { t } = useTranslation(['orders']);

  const selectedUnit = useSelectedUnit({ switchName });

  const hasPrepacksApplied = React.useMemo(() => {
    return Object.values(prepacksQuantitiesInVariants).some(prepack => (Object.values(prepack)[0] ?? 0) > 0);
  }, [prepacksQuantitiesInVariants]);

  const applyDistribution = (distributionResult: Record<string, Preset.Item>) => {
    const nonEmptyItems = Object.entries(distributionResult).filter(([_, item]) => item.quantity > 0);

    const message =
      modeFormMethods.getValues().mode === 'maxAvailable' ? t('orders:distribution_reduced') : t('orders:distribution_redistributed');

    distributionStage === 'stockCorrection' && addToast(message);
    closeDistributionPopup();

    if (!isDefined(distributionVariant) || isEmpty(nonEmptyItems)) {
      return;
    }

    const distributionForRequest = nonEmptyItems.map(distributionItem => {
      const [item, presetItem] = distributionItem;
      const quantity = (quantityAdded[presetItem.sizeId] ?? 0) + presetItem.quantity;

      return {
        deliveryWindow: deliveryWindowId,
        item,
        quantity,
        ...getExtraSizeInfo(distributionVariant, item, selectedUnit),
      };
    });

    if (hasPrepacksApplied) {
      dispatch(
        removePrepacksRequest({
          data: { deliveryWindow: deliveryWindowId, variant: distributionVariant.variant },
          product: distributionVariant.product,
        }),
      );
    }

    dispatch(
      setMultipleItemsRequest({
        items: distributionForRequest,
        product: distributionVariant.product,
      }),
    );

    dispatch(pushEvent({ event: MatrixTrackingEvent.SIZE_DISTRIBUTION_USED }));
  };

  return { applyDistribution };
};
