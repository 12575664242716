import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';

const RETRY_TIMES = 3;

export const loadImage = (src: string, retryTimes: number = RETRY_TIMES) => {
  return new Observable(observer => {
    const image = new Image();

    // eslint-disable-next-line functional/immutable-data
    Object.assign(image, {
      onerror: () => {
        observer.error();
        // eslint-disable-next-line functional/immutable-data
        Object.assign(image, { onerror: null, onload: null });
      },
      onload: () => {
        observer.next();
        observer.complete();
        // eslint-disable-next-line functional/immutable-data
        Object.assign(image, { onerror: null, onload: null });
      },
      src,
    });
  }).pipe(retry(retryTimes));
};
