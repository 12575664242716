import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getDeliveryWindowMap, getOrderDeliveryWindowsIds } from '../../../../ducks';
import { Autocomplete, Option, Select } from '../../../various/Fields/Select';
import { useModalContext } from '../../../various/ModalElements/context/ModalContext';
import { OverviewField } from '../OverviewField';
import { SelectionOverviewContext } from '../SelectionOverviewContext';

import styles from './ViewOptions.module.scss';

export const ViewOptions = () => {
  const { t } = useTranslation(['common', 'selectionOverview', 'totals']);

  const { deliveryWindowBase, percentageBase, setDeliveryWindowBase, setPercentageBase } = React.useContext(SelectionOverviewContext);
  const { containerRef } = useModalContext();

  const allDeliveryWindows = useSelector(getDeliveryWindowMap);
  const deliveryWindowsIds = useSelector(getOrderDeliveryWindowsIds);

  return (
    <div className={styles.viewOptions}>
      <OverviewField title={t('common:delivery_window_one')} domId="overviewDeliveryWindowBase">
        <Autocomplete
          name="overviewDeliveryWindowBase"
          containerRef={containerRef}
          size="small"
          value={deliveryWindowBase}
          onChange={setDeliveryWindowBase}
        >
          <Option value="all">{t('common:all')}</Option>
          {deliveryWindowsIds.map(delwinId => (
            <Option key={delwinId} value={delwinId}>
              {allDeliveryWindows[delwinId]?.name}
            </Option>
          ))}
        </Autocomplete>
      </OverviewField>
      <OverviewField title={t('selectionOverview:percent_based_on')} domId="overviewPercentageBase">
        <Select name="overviewPercentageBase" containerRef={containerRef} size="small" value={percentageBase} onChange={setPercentageBase}>
          <Option value="units">{t('common:unit_other')}</Option>
          <Option value="total">{t('totals:total_one')}</Option>
        </Select>
      </OverviewField>
    </div>
  );
};
