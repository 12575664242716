export const BREAKPOINTS = {
  LARGE: 1024,
  MEDIUM: 768,
  SMALL: 460,
  XLARGE: 1380,
};

export const HEADER_MOBILE_BREAKPOINT = BREAKPOINTS.LARGE;
export const MATRIX_MOBILE_BREAKPOINT = BREAKPOINTS.LARGE;
export const MOBILE_PDM_BREAKPOINT = BREAKPOINTS.SMALL;
