import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Addresses, Id } from '@typings';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

import { addToast } from '../../../components/various/Toasts';
import { getResponseErrors } from '../../../utils/errors';
import { updateShippingAddress } from '../../accountRepository';

interface MutationVariables {
  accountId: Id;
  shippingAddressId: Id;
  data: Addresses.ShippingAddressDTO;
}

export const useUpdateShippingAddress = () => {
  const { t } = useTranslation(['shipping']);
  const queryClient = useQueryClient();

  const mutationFn = async ({ accountId, shippingAddressId, data }: MutationVariables) => {
    return updateShippingAddress(accountId, shippingAddressId, data);
  };

  const handleSuccess = (_: Addresses.Shipping, { accountId }: MutationVariables) => {
    addToast(t('shipping:shipping_address_updated'));
    queryClient.invalidateQueries({ queryKey: ['shippingAddresses', { accountId }] });
  };

  const handleError = (error: AxiosError<Responses.Errors>) => {
    const [errorMessage] = getResponseErrors(error);
    addToast(errorMessage);
  };

  return useMutation({
    mutationFn,
    onError: handleError,
    onSuccess: handleSuccess,
  });
};
