import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { INPUT_URL_MAX_LENGTH } from '../../../constants/limits';
import { getMediaType } from '../../../utils/getMediaType';
import { useValidatedForm } from '../../../utils/hooks/useValidatedForm';
import { isDefined } from '../../../utils/is';
import { getUrlSchema } from '../../../utils/schemas';
import { Form, FormButtons, FormGrid, InputField } from '../../various/Form';
import { Button } from '../../various/NewButton';
import { Preview } from '../../various/Preview';

import { MediaForm } from './types';

type UrlMediaFormat = 'video' | 'image' | 'unknown' | '';

const KNOWN_MEDIA_FORMATS: UrlMediaFormat[] = ['video', 'image'];

interface FormData {
  type: UrlMediaFormat;
  url: string;
}

const DEFAULT_VALUES: FormData = {
  type: '',
  url: '',
};

export const UrlForm = ({ onCancel, onSubmit }: MediaForm) => {
  const { t } = useTranslation(['common', 'cms', 'validation']);

  const validationSchema: yup.ObjectSchema<FormData> = yup.object({
    type: yup.string<UrlMediaFormat>().oneOf(KNOWN_MEDIA_FORMATS, t('validation:file_format_not_supported_hint')).required(),
    url: getUrlSchema({ allowedProtocols: ['http:', 'https:', 'ftp:', 'ftps:'] }),
  });

  const formMethods = useValidatedForm({ defaultValues: DEFAULT_VALUES, validationSchema });
  const { register, watch, setValue } = formMethods;
  const [url, type] = watch(['url', 'type']);

  const { data, isLoading: isLoadingUrl } = useQuery({
    queryFn: async () => {
      try {
        await validationSchema.validateAt('url', { url });
      } catch {
        return '';
      }

      try {
        return await getMediaType(url);
      } catch {
        return 'unknown';
      }
    },
    queryKey: ['mediaResolver', url],
    retry: false,
  });

  const handleUrlChange = () => {
    setValue('type', '');
  };

  React.useEffect(() => {
    if (isDefined(data)) {
      setValue('type', data, { shouldValidate: true });
    }
  }, [setValue, data]);

  return (
    <Form formMethods={formMethods} onSubmit={onSubmit}>
      <FormGrid>
        <InputField
          label={t('common:url')}
          isRequired
          dataTestId="mediaUrlInput"
          maxLength={INPUT_URL_MAX_LENGTH}
          {...register('url', { onChange: handleUrlChange })}
        />
        <Preview src={url} type={type}>
          {isLoadingUrl ? t('cms:checking_file') : t('cms:media_preview_hint')}
        </Preview>
      </FormGrid>
      <FormButtons showDivider>
        <Button size="large" variant="ghost" color="dark" onClick={onCancel}>
          {t('common:cancel')}
        </Button>
        <Button type="submit" size="large">
          {t('cms:upload')}
        </Button>
      </FormButtons>
    </Form>
  );
};
