import React from 'react';

import { getBase64 } from '../../ducks/helpers';
import { getFileType } from '../getFileType';
import { isDefined } from '../is';

export const useFileUrl = (file: Maybe<File>) => {
  const [url, setUrl] = React.useState<Maybe<string>>();
  const fileType = getFileType(file);
  React.useEffect(() => {
    if (!isDefined(file)) {
      setUrl(undefined);

      return;
    }
    getBase64(file, setUrl);
  }, [file]);

  return [url, fileType];
};
