import { matchPath } from 'react-router-dom';

import { paths } from '../paths';
import { isDefined } from '../utils/is';
import { getSafeUrl } from '../utils/safeUrl';

const PAGE_WITH_ORDER = '/pages/:pageSlug/:id';
const HOME_WITH_ORDER = '/home/:id';
const PRODUCTS_WITH_ORDER = '/products/:id';

const URLS_WITH_IDS = [
  paths.ORDER_DETAILS,
  paths.INVOICE_DETAILS,
  paths.PROCESSING_PAYMENT,
  paths.CHECKOUT_ORDER,
  paths.CHECKOUT_SUCCESS,
  paths.ORDER_PAYMENT_FAILURE,
  paths.ORDER_PAYMENT_RESULT,
  paths.ORDER_PAYMENT_PENDING,
  PAGE_WITH_ORDER,
  HOME_WITH_ORDER,
  PRODUCTS_WITH_ORDER,
];

export const getHasConfidentialId = (url: Maybe<string>) => {
  const urlObject = getSafeUrl(url);

  if (!isDefined(urlObject)) {
    return false;
  }

  const { pathname } = urlObject;

  return URLS_WITH_IDS.some(candidate => matchPath(candidate, pathname));
};

export const getIsCmsEditor = (url: Maybe<string>) => {
  const urlObject = getSafeUrl(url);

  if (!isDefined(urlObject)) {
    return false;
  }

  const { pathname } = urlObject;

  return !!matchPath(paths.CMS_EDITOR, pathname);
};

export const getHostname = (url: Maybe<string>) => {
  const urlObject = getSafeUrl(url);

  return urlObject?.hostname ?? null;
};

export const getIsSameHostname = (url: Maybe<string>) => {
  return getHostname(url) === window.location.hostname;
};

export const getNumericValue = (value: string) => Number(value.replace(/[^0-9]/g, ''));
