import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getCompanyName, getLoginPageMessage } from '../../../ducks';
import { isEmpty } from '../../../utils/isEmpty';
import { StartPageLayout } from '../../layout/StartPageLayout';

import { LoginForm } from './LoginForm';

export const LoginPage = () => {
  const { t } = useTranslation(['common', 'login']);
  const companyName = useSelector(getCompanyName);
  const loginPageMessage = useSelector(getLoginPageMessage);
  const loginPageMessageFallback = t('login:welcome_to_showroom', { companyName });

  return (
    <StartPageLayout title={t('common:hello')} text={isEmpty(loginPageMessage) ? loginPageMessageFallback : loginPageMessage}>
      <LoginForm />
    </StartPageLayout>
  );
};
