import { Cms } from '@typings';

import { CMS_CLIPBOARD_KEY } from '../../../constants/localStorage';
import { isDefined } from '../../is';
import { useLocalStorage } from '../useLocalStorage';

export const useCmsClipboard = () => {
  const [cmsClipboard, setCmsClipboard] = useLocalStorage<Nullable<Cms.Clipboard>>({
    asJson: true,
    defaultValue: null,
    key: CMS_CLIPBOARD_KEY,
  });

  const hasSectionInClipboard = isDefined(cmsClipboard) && isDefined(cmsClipboard.section);
  const hasGroupInClipboard = isDefined(cmsClipboard) && !hasSectionInClipboard;

  return {
    cmsClipboard,
    hasGroupInClipboard,
    hasSectionInClipboard,
    setCmsClipboard,
  };
};
