/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;
const ORIGINAL_COLOR = '#848484';

export const Edit = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => (
  <svg width={width} height={width} viewBox="0 0 20 20" fill="none">
    <path
      d="M11.8274 5.00331L14.8787 8.06956L7.15493 15.8312L4.10532 12.7649L11.8274 5.00331ZM17.1941 4.2638L15.8333 2.89635C15.3074 2.36788 14.4535 2.36788 13.9258 2.89635L12.6223 4.20623L15.6736 7.27251L17.1941 5.74461C17.602 5.3347 17.602 4.67368 17.1941 4.2638ZM2.50849 17.0748C2.45296 17.3259 2.6786 17.551 2.92854 17.4899L6.32875 16.6614L3.27914 13.5952L2.50849 17.0748Z"
      fill={color}
    />
  </svg>
);
