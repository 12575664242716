/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 16;
const ORIGINAL_HEIGHT = 10;
const PROPORTION = ORIGINAL_HEIGHT / ORIGINAL_WIDTH;
const ORIGINAL_COLOR = '#848484';

export const Visibility = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => (
  <svg width={width} height={width * PROPORTION} viewBox="0 0 16 10" fill="none">
    <path
      d="M8 0C4.69152 0 1.84005 1.95033 0.530172 4.76479C0.489943 4.85008 0.489943 4.94985 0.530172 5.03674C1.84005 7.85119 4.69154 9.80154 8 9.80154C11.3085 9.80154 14.16 7.85121 15.4698 5.03674C15.5101 4.95146 15.5101 4.85169 15.4698 4.76479C14.16 1.95033 11.3085 0 8 0ZM8 8.28248C6.13174 8.28248 4.61911 6.76823 4.61911 4.90157C4.61911 3.03331 6.13335 1.52068 8 1.52068C9.86826 1.52068 11.3809 3.03492 11.3809 4.90157C11.3809 6.76823 9.86665 8.28248 8 8.28248Z"
      fill={color}
    />
    <path
      d="M8.00004 7.06432C9.19538 7.06432 10.1644 6.0953 10.1644 4.89996C10.1644 3.70461 9.19538 2.7356 8.00004 2.7356C6.80471 2.7356 5.83569 3.70461 5.83569 4.89996C5.83569 6.0953 6.80471 7.06432 8.00004 7.06432Z"
      fill={color}
    />
  </svg>
);
