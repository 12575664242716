import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  getDownloadingProductImagesOrderNumber,
  getIsDownloadingProductImages,
  getIsDownloadingProductImagesFailed,
} from '../../../ducks/documents';
import { isDefined } from '../../../utils/is';
import Icon, { IconType } from '../Icon';
import { addToast, dismissToast } from '../Toasts';

import styles from './Message.module.scss';

const NOTIFICATION_KEY = 'downloadProductImages';

export const ProductImageMessage = () => {
  const { t } = useTranslation(['common', 'products', 'orders']);
  const isDownloading = useSelector(getIsDownloadingProductImages);
  const hasFailed = useSelector(getIsDownloadingProductImagesFailed);
  const orderNumber = useSelector(getDownloadingProductImagesOrderNumber);

  const notificationContent = React.useMemo(() => {
    const orderNumberText = isDefined(orderNumber) ? t('orders:for_order', { orderNumber }) : '';

    if (isDownloading && !hasFailed) {
      return {
        description: t('products:download_images_hint', { orderNumberText }),
        icon: <Icon type={IconType.Spinner} />,
        message: t('products:preparing_file'),
      };
    }

    return {
      description: t('products:download_images_fail_hint', { orderNumberText }),
      icon: <Icon type={IconType.Alert} />,
      message: t('products:download_images_fail'),
    };
  }, [t, isDownloading, hasFailed, orderNumber]);

  React.useEffect(() => {
    if (!isDownloading && !hasFailed) {
      dismissToast(NOTIFICATION_KEY);

      return;
    }

    const { message, description, icon } = notificationContent;

    addToast(message, {
      className: styles.toastBar,
      description,
      duration: 0,
      icon,
      id: NOTIFICATION_KEY,
      position: 'bottom-left',
    });
  }, [isDownloading, hasFailed, orderNumber, notificationContent]);

  return null;
};
