import { Addresses, Radio } from '@typings';
import cx from 'classnames';
import React from 'react';

import { AddAddressButton } from '../../../orders/partials/AddAddressButton';
import radioGroupStyles from '../RadioGroup/RadioGroup.module.scss';

import styles from './ShippingAddressRadioGroup.module.scss';

interface ShippingAddressOption<T extends string = string> {
  header: React.ReactNode;
  body: React.ReactNode;
  value: T;
  disabled?: boolean;
}

interface ShippingAddressRadioGroupProps<T extends string>
  extends Omit<Radio.Group<T>, 'options' | 'itemClassName' | 'name' | 'orientation' | 'size'> {
  options: ShippingAddressOption<T>[];
  canAddAddress?: boolean;
  selectedAddress?: Addresses.MixedShipping;
  showAddAddress?: boolean;
}

export const ShippingAddressRadioGroup = React.forwardRef<HTMLInputElement, ShippingAddressRadioGroupProps<string>>((props, ref) => {
  const {
    className,
    describedById,
    disabled = false,
    isRequired,
    labelledById,
    options,
    value,
    onBlur,
    onChange,
    canAddAddress,
    selectedAddress,
    showAddAddress,
  } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <div role="radiogroup" aria-labelledby={labelledById} aria-describedby={describedById} className={className}>
      {options.map(option => {
        const isChecked = option.value === value;
        const isDisabled = disabled || option.disabled;

        return (
          <label
            key={option.value}
            className={cx(styles.card, {
              [styles.active]: option.value === value,
            })}
          >
            <div className={styles.header}>
              <input
                ref={ref}
                type="radio"
                name={option.value}
                value={option.value}
                required={isRequired}
                className={radioGroupStyles.input}
                checked={isChecked}
                disabled={isDisabled}
                onBlur={onBlur}
                onChange={handleChange}
              />
              {option.header}
            </div>
            {option.body}
          </label>
        );
      })}
      {canAddAddress && showAddAddress && <AddAddressButton selectedAddress={selectedAddress} />}
    </div>
  );
});
