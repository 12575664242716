import { Email } from '@typings';
import { useTranslation } from 'react-i18next';

import { Input } from '../../various/Fields/Input';
import { FieldWrapper, FormButtons } from '../../various/Form';
import { Button } from '../../various/NewButton';

import styles from './SelectionShareModal.module.scss';

interface Props {
  emails: Email[];
  onConfirm: () => void;
}

export const SelectionShareSuccess = ({ emails, onConfirm }: Props) => {
  const { t } = useTranslation(['selections', 'common']);

  const link = emails[0]?.link;

  return (
    <>
      <p className={styles.text}>{t('selections:share_selection_success')}</p>
      <ul className={styles.emailList} data-sentry-mask>
        {emails.map(({ email }) => (
          <li key={email}>
            <div className={styles.link}>{email}</div>
          </li>
        ))}
      </ul>
      <FieldWrapper label={t('selections:shareable_link')}>
        <Input size="large" value={link} isReadonly isCopyable onFocus={event => event.target.select()} />
      </FieldWrapper>
      <FormButtons>
        <Button size="large" onClick={onConfirm}>
          {t('common:close')}
        </Button>
      </FormButtons>
    </>
  );
};
