/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;
const ORIGINAL_COLOR = '#848484';

export const Close = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => (
  <svg width={width} height={width} viewBox="0 0 20 20" fill="none">
    <path
      d="M16.2116 3.79824C16.0274 3.61362 15.7773 3.50986 15.5165 3.50986C15.2557 3.50986 15.0056 3.61362 14.8214 3.79824L10 8.60978L5.17861 3.78838C4.9944 3.60376 4.74431 3.5 4.4835 3.5C4.2227 3.5 3.97261 3.60376 3.7884 3.78838C3.40387 4.17291 3.40387 4.79407 3.7884 5.1786L8.60978 9.99999L3.7884 14.8214C3.40387 15.2059 3.40387 15.8271 3.7884 16.2116C4.17292 16.5961 4.79408 16.5961 5.17861 16.2116L10 11.3902L14.8214 16.2116C15.2059 16.5961 15.8271 16.5961 16.2116 16.2116C16.5961 15.8271 16.5961 15.2059 16.2116 14.8214L11.3902 9.99999L16.2116 5.1786C16.5863 4.80393 16.5863 4.17291 16.2116 3.79824Z"
      fill={color}
    />
  </svg>
);
