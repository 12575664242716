import { Row, type Table as TableType } from '@tanstack/react-table';
import { PaginationProps } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { isDefined } from '../../../utils/is';
import { NoDataIcon } from '../Icon/Icons/NoDataIcon';
import Pagination from '../Pagination';

import { TableHeader } from './components/header/TableHeader';
import { TableBody } from './components/TableBody';
import { TableFooter } from './components/TableFooter';
import styles from './Table.module.scss';

export interface TableProps<T> {
  table: TableType<T>;
  className?: string;
  rowClassName?: (row: Row<T>) => string;
  onRowClick?: (row: Row<T>) => void;
  isLoading?: boolean;
  pagination?: PaginationProps;
  extraBodyRow?: () => React.ReactNode;
  dataTestId?: string;
  noDataMessage?: string;
  renderSubComponent?: (row: Row<T>) => React.ReactElement | React.ReactElement[];
  stickyColumn?: boolean;
  fixedLayout?: boolean;
  skeletonRows?: number;
}

export const Table = <T,>({
  table,
  className,
  dataTestId,
  renderSubComponent,
  rowClassName,
  isLoading = false,
  stickyColumn = false,
  fixedLayout = false,
  skeletonRows = 20,
  pagination,
  noDataMessage,
  onRowClick,
  extraBodyRow,
}: TableProps<T>) => {
  const { t } = useTranslation();
  const [wasScrolledHorizontally, setWasScrolledHorizontally] = React.useState(false);
  const handleScroll = React.useCallback((event: React.UIEvent<HTMLDivElement>) => {
    setWasScrolledHorizontally(event.currentTarget.scrollLeft > 0);
  }, []);
  const onPageChange = (newPage: number) => {
    if (table.options.manualPagination) {
      table.setPageIndex(newPage);

      return;
    }

    table.setPageIndex(newPage - 1);
  };

  return (
    <div onScroll={stickyColumn ? handleScroll : undefined} className={cx(styles.wrapper, className)}>
      <table
        data-testid={dataTestId}
        className={cx(styles.table, {
          [styles.stickyColumn]: stickyColumn,
          [styles.scrollFade]: wasScrolledHorizontally,
          [styles.fixed]: fixedLayout,
        })}
      >
        <colgroup>
          {table
            .getAllLeafColumns()
            .filter(column => column.getIsVisible())
            .map((column, index) => (
              <col key={index} style={{ width: column.columnDef.meta?.width }} />
            ))}
        </colgroup>
        <TableHeader table={table} />
        {(isLoading || table.getRowCount() !== 0) && (
          <TableBody
            table={table}
            rowClassName={rowClassName}
            renderSubComponent={renderSubComponent}
            extraBodyRow={extraBodyRow}
            onRowClick={onRowClick}
            isLoading={isLoading}
            skeletonRows={skeletonRows}
          />
        )}
        <TableFooter table={table} isLoading={isLoading} />
      </table>
      {!isLoading && table.getRowCount() === 0 && (
        <div className={styles.noData}>
          <NoDataIcon />
          <p>{isDefined(noDataMessage) ? noDataMessage : t('common:no_data', { returnObjects: false })}</p>
        </div>
      )}
      {isDefined(pagination) && <Pagination {...pagination} onChange={onPageChange} />}
    </div>
  );
};
