import { Position, Product, Range } from '@typings';
import { range, sortWith } from 'ramda';

import { getVariantAttributeValue } from '../logic/products';

import { getArrayFromQuerySelectorAll } from './getArrayFromQuerySelectorAll';
import { getHasActiveElement } from './getHasActiveElement';
import { EnsureKey, ensureKey, isDefined } from './is';
import { isTabKey, Key } from './keys';

const FOCUSABLE_ELEMENTS_SELECTOR = 'a, button:not([disabled]), input:not([disabled]), [data-focus-target="true"]:not([disabled])';
const PRODUCT_MODAL_SELECTOR = '#productDetailsModal';
const MATRICES_SELECTOR = '[data-matrix] [data-focus-target="true"]:not([disabled])';
const PRODUCT_MODAL_MATRICES_SELECTOR = `${PRODUCT_MODAL_SELECTOR} ${MATRICES_SELECTOR}`;

export const sortVariantsByAttributes = (variants: Product.Standard[], attributeKeys: string[]) => {
  const attributeValues = attributeKeys.reduce<Record<string, string[]>>((acc, key) => {
    return {
      ...acc,
      [key]: variants.map(variant => getVariantAttributeValue(variant, key)),
    };
  }, {});

  const sortFunctions = Object.entries(attributeValues).map(([key, value]) => (a: Product.Standard, b: Product.Standard) => {
    const indexA = value.indexOf(getVariantAttributeValue(a, key));
    const indexB = value.indexOf(getVariantAttributeValue(b, key));

    return indexA - indexB;
  });

  return sortWith(sortFunctions)(variants);
};

const findFocusTarget = (indexOffset: number, selector: string) => {
  if (!isDefined(document.activeElement)) {
    return;
  }

  const focusables = getArrayFromQuerySelectorAll(selector);
  const focusIndex = focusables.indexOf(document.activeElement);

  return focusables[focusIndex + indexOffset] as Maybe<HTMLElement>;
};

export const getIsAnyMatrixFocused = () => {
  const matrixContentSelector = getMatrixContentSelector();
  const matrices = getArrayFromQuerySelectorAll(matrixContentSelector);

  return matrices.some(getHasActiveElement);
};

export const exitMatrix = (indexOffset: number) => {
  const focusTarget = findFocusTarget(indexOffset, FOCUSABLE_ELEMENTS_SELECTOR);
  focusTarget?.focus();
};

const getMatrixContentSelector = () => {
  const isProductModalOpen = isDefined(document.querySelector(PRODUCT_MODAL_SELECTOR));

  return !isProductModalOpen ? MATRICES_SELECTOR : PRODUCT_MODAL_MATRICES_SELECTOR;
};

export const jumpToMatrixByTarget = (target: Maybe<HTMLElement>) => {
  const matrixContentSelector = getMatrixContentSelector();
  const matrix = target?.querySelector(matrixContentSelector) as Maybe<HTMLElement>;

  matrix?.focus({ preventScroll: true });
  matrix?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
};

export const jumpToMatrix = (indexOffset: number) => {
  const matrixContentSelector = getMatrixContentSelector();
  const focusTarget = findFocusTarget(indexOffset, matrixContentSelector);

  focusTarget?.focus({ preventScroll: true });
  focusTarget?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });

  if (focusTarget instanceof HTMLInputElement) {
    focusTarget.select();
  }
};

interface QuantityInputOptions {
  savedValue: number;
  localValue: number;
  isReadOnly: boolean;
  defaultPlaceholder: string;
  isDistributionPreview: boolean;
}

export const getQuantityInputValues = ({
  savedValue,
  localValue,
  isReadOnly,
  defaultPlaceholder,
  isDistributionPreview,
}: QuantityInputOptions) => {
  const getPlaceholderValue = () => {
    if (isDistributionPreview) {
      return '0';
    }

    return isReadOnly ? savedValue.toString() : defaultPlaceholder;
  };
  const placeholderValue = getPlaceholderValue();

  const displayValue = localValue === 0 ? '' : localValue;

  const spacerValue = savedValue === 0 ? placeholderValue : savedValue;

  return {
    displayValue,
    placeholderValue,
    spacerValue,
  };
};

export const selectedRange = (start: number, end: number) => {
  return range(Math.min(start, end), Math.max(start, end) + 1);
};

export const areDeleteKeysPressed = (code: string) => {
  return code === Key.DELETE || code === Key.BACKSPACE;
};

export const getSelectedCells = (start: Position, end: Position) => {
  const ySelectedRange = selectedRange(start.y, end.y);
  const xSelectedRange = selectedRange(start.x, end.x);

  return ySelectedRange.flatMap(column => {
    return xSelectedRange.map(row => ({ x: row, y: column }));
  });
};

export const getParsedPastedData = (clipboardData: DataTransfer) => {
  return clipboardData
    .getData('text/plain')
    .replaceAll('\r\n', '\n')
    .replaceAll('\r', '\n')
    .split('\n')
    .map(row => row.split('\t'));
};

const getLocationMap = (position: Position): Record<string, Position> => {
  return {
    [Key.RIGHT]: { ...position, x: position.x + 1 },
    [Key.LEFT]: { ...position, x: position.x - 1 },
    [Key.UP]: { ...position, y: position.y - 1 },
    [Key.DOWN]: { ...position, y: position.y + 1 },
  };
};

export const getNewLocationKeyboardNav = (event: React.KeyboardEvent | KeyboardEvent, position: Position) => {
  const locationMap = getLocationMap(position);
  const isShiftPressed = event.shiftKey;
  const tabDirection = isShiftPressed ? -1 : 1;

  return isTabKey(event.key) ? { ...position, x: position.x + tabDirection } : locationMap[event.key];
};

export const getNewEndFromPasteData = (pasteData: string[][], start: Position) => {
  const lastYPos = pasteData.length - 1;
  const lastXPos = (pasteData[lastYPos]?.length ?? 0) - 1;

  return { x: start.x + lastXPos, y: start.y + lastYPos };
};

export const getHasValidConfigurator = (
  configurator: Maybe<Product.ProductConfiguration>,
): configurator is EnsureKey<Product.ProductConfiguration, 'secondary'> => {
  return isDefined(configurator) && ensureKey(configurator, 'secondary');
};

export const getIsPositionWithinRanges = (position: Position, rowRange: Range, columnRange: Range) => {
  const { x, y } = position;
  const { min: rowMin, max: rowMax } = rowRange;
  const { min: columnMin, max: columnMax } = columnRange;

  return x >= rowMin && x <= rowMax && y >= columnMin && y <= columnMax;
};
