import { SwatchData } from '@typings';
import cx from 'classnames';
import React from 'react';

import { getBackgroundBySwatch } from '../../../../../../utils/getBackgroundBySwatch';
import Icon, { IconColor, IconType } from '../../../../../various/Icon';
import { Tooltip } from '../../../../../various/Tooltip';

import styles from './SwatchCheckbox.module.scss';

interface Props {
  isSelected: boolean;
  handleClick: (event: React.SyntheticEvent<EventTarget>) => void;
  swatchData: SwatchData;
}

export const SwatchCheckbox = ({ isSelected, handleClick, swatchData }: Props) => {
  const innerClassName = cx(styles.swatchInner, {
    [styles.selected]: isSelected,
  });

  const background = getBackgroundBySwatch(swatchData);

  return (
    <Tooltip placement="top-center" content={swatchData.desc}>
      <span className={styles.swatchCheckbox}>
        <input
          className={styles.swatchInput}
          onChange={handleClick}
          type="checkbox"
          checked={isSelected}
          aria-label={swatchData.desc}
          aria-checked={isSelected}
        />
        <span style={{ background }} className={innerClassName}>
          {isSelected && <Icon type={IconType.Check} className={styles.icon} color={IconColor.BermudaGray} />}
        </span>
      </span>
    </Tooltip>
  );
};
