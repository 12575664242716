import { Cms } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconType } from '../../../various/Icon';
import { IconRadioGroup } from '../../../various/IconRadioGroup';

import styles from './AlignmentSwitch.module.scss';

const enum Aligments {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

interface TextProps {
  switcherType: 'text';
  value: Cms.TextAlignment;
}

interface ButtonProps {
  switcherType: 'button';
  value: Cms.ButtonJustify;
}

interface BaseProps {
  onChange: (value: Cms.TextAlignment) => void;
  className?: string;
}

type Props = (TextProps | ButtonProps) & BaseProps;

const TEXT_ALIGN_OPTIONS = [
  {
    icon: IconType.TextLeft,
    value: Aligments.LEFT,
  },
  {
    icon: IconType.TextCenter,
    value: Aligments.CENTER,
  },
  {
    icon: IconType.TextRight,
    value: Aligments.RIGHT,
  },
];
const BUTTON_ALIGN_OPTIONS = [
  {
    icon: IconType.AlignLeft,
    value: Aligments.LEFT,
  },
  {
    icon: IconType.AlignCenter,
    value: Aligments.CENTER,
  },
  {
    icon: IconType.AlignRight,
    value: Aligments.RIGHT,
  },
];

export const AlignmentSwitch = ({ value, onChange, className, switcherType }: Props) => {
  const changeHandler = React.useCallback((alignment: Cms.TextAlignment) => onChange(alignment), [value, onChange]);
  const { t } = useTranslation(['cms']);
  const isText = switcherType === 'text';
  const options = isText ? TEXT_ALIGN_OPTIONS : BUTTON_ALIGN_OPTIONS;
  const name = isText ? t('cms:text_alignment') : t('cms:button_alignment');

  return (
    <IconRadioGroup
      className={cx(styles.alignmentSwitch, className)}
      groupName={name}
      selectedValue={value}
      options={options}
      dataTestId="customStyleEditorAlignment"
      onChange={changeHandler}
    />
  );
};
