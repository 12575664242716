import cx from 'classnames';
import React from 'react';

import Icon, { IconColor, IconType } from '../../../../various/Icon';

import styles from './ControlsButton.module.scss';

interface Props {
  testId?: string;
  shouldShowLabel?: boolean;
  label: string;
  disabled?: boolean;
  onClick: () => void;
  isFocusTarget?: boolean;
  iconType: IconType;
  className?: string;
}

export const ControlsButton = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { testId, shouldShowLabel = true, label, disabled = false, onClick, isFocusTarget, iconType, className } = props;

  const ariaLabel = !shouldShowLabel ? label : undefined;

  return (
    <button
      data-testid={testId}
      className={cx(styles.controlButton, className, {
        [styles.disabled]: disabled,
        [styles.iconOnly]: !shouldShowLabel,
      })}
      aria-label={ariaLabel}
      disabled={disabled}
      onClick={onClick}
      ref={ref}
      data-focus-target={isFocusTarget}
    >
      {shouldShowLabel && label}
      <Icon size={20} type={iconType} color={IconColor.Blue} />
    </button>
  );
});
