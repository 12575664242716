import { Cms } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrentBlockId } from '../../../ducks';
import { updateLocalizedBlockSettings } from '../../../ducks/cms/localizations';
import { getIsContentBlock } from '../../../logic/pages';
import { useModalVisibility } from '../../../utils/hooks';
import { useLocalizedBlock } from '../../../utils/hooks/cms/useLocalizedBlock';
import { isDefined, isNull } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { getSafeUrl } from '../../../utils/safeUrl';
import { ModalSize } from '../../various/ModalElements';
import { ModalShell } from '../../various/ModalShell';
import { EditorLanguageContext } from '../context/EditorLanguageContext';
import { LinkForm } from '../LinkForm';

const getHasLinkSettings = (block: Cms.AnyBlock | null): block is Cms.ContentBlock & Required<{ settings: { link: string } }> =>
  isDefined(block) && getIsContentBlock(block) && isDefined(block.settings.link);

export const LinkModal = () => {
  const { t } = useTranslation(['cms']);
  const { isModalVisible, hideModal } = useModalVisibility('LinkModal');
  const { editorLanguage } = React.useContext(EditorLanguageContext);
  const dispatch = useDispatch();
  const currentBlockId = useSelector(getCurrentBlockId);
  const block = useLocalizedBlock({ blockId: currentBlockId });
  const linkSettings = getHasLinkSettings(block) ? block.settings.link : undefined;
  const safeUrl = getSafeUrl(linkSettings?.url)?.href ?? null;

  const defaultValues: Cms.LinkSettings = {
    newTab: linkSettings?.newTab || false,
    url: !isNull(safeUrl) ? safeUrl : '',
  };

  const actionName = t(isEmpty(defaultValues.url) ? 'cms:add_link' : 'cms:change_link');

  const handleSubmit = React.useCallback(
    (settings: Cms.LinkSettings) => {
      if (!isDefined(currentBlockId)) {
        return;
      }

      const { url } = settings;
      const updatePayload = {
        blockId: currentBlockId,
        updatePath: ['link'],
        value: isDefined(url) && !isEmpty(url) ? settings : undefined,
      };

      hideModal();

      dispatch(
        updateLocalizedBlockSettings({
          ...updatePayload,
          language: editorLanguage,
        }),
      );
    },
    [currentBlockId, dispatch, editorLanguage, hideModal],
  );

  return (
    <ModalShell size={ModalSize.Medium} isOpen={isModalVisible} onClose={hideModal} title={actionName}>
      <LinkForm defaultValues={defaultValues} onCancel={hideModal} onSubmit={handleSubmit} />
    </ModalShell>
  );
};
