import { Cms, Id } from '@typings';
import { createAction } from 'typesafe-actions';

export const addSection = createAction('cms/ADD_SECTION')<{
  markets: Id[];
  insertAt: number;
}>();
export const addGroupFromTemplate = createAction('cms/ADD_GROUP_FROM_TEMPLATE')<{
  model: Cms.BlockModel[];
  sectionId: Id;
  insertAfter?: Nullable<Id>;
}>();
export const groupCreated = createAction('cms/GROUP_CREATED')<{
  blocksGroup: Cms.AnyBlock[];
  sectionId: Id;
  insertAfter?: Nullable<Id>;
}>();
export const removeSection = createAction('cms/REMOVE_SECTION')<Id>();
export const sectionRemoved = createAction('cms/SECTION_REMOVED')<{
  groupIds: Id[];
  sectionId: Id;
  blockIds: Id[];
}>();

export const updateSectionVisibility = createAction('cms/UPDATE_SECTION_VISIBILITY')<{
  sectionId: Id;
  visibility: Cms.SectionVisibility;
}>();

export const moveSection = createAction('cms/MOVE_SECTION')<{
  sectionId: Id;
  offset: number;
}>();
export const moveGroup = createAction('cms/MOVE_GROUP')<{
  groupId: Id;
  sectionId: Id;
  offset: number;
}>();
export const reorderGroups = createAction('cms/REORDER_GROUPS')<{
  sectionId: Id;
  groupsOrder: Id[];
}>();
export const pasteSection = createAction('cms/PASTE_SECTION')<Cms.PasteSectionPayload>();

export const updateAllSectionsMarkets = createAction('cms/UPDATE_ALL_SECTIONS_MARKETS')<{
  pageMarketIds: string[];
}>();

export const validateSectionsMarkets = createAction('cms/VALIDATE_SECTION_MARKETS')<{
  pageMarketIds: string[];
}>();
