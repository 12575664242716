import { Cms } from '@typings';
import { uniq } from 'ramda';

import { buttonStylesConfig, customStyleFonts, textStylesConfig } from '../../cmsTemplates';
import { BLOCK_SPACING, COLUMN_WIDTH, ROW_HEIGHT } from '../../constants/cms';
import { isDefined, isNull } from '../../utils/is';
import { getIsContentBlock } from '../pages';

type StylesPropertyType = 'hover' | 'default';

type CreateTextStyleRuleSet = {
  style: Cms.TextStyle;
  styleType: 'default';
};
type CreateButtonStyleRuleSet = {
  style: Cms.ButtonStyle;
  styleType: StylesPropertyType;
};
type CreateStyleRuleSet = CreateTextStyleRuleSet | CreateButtonStyleRuleSet;

const TEXT_STYLES_TAG_ID = 'cmsTextStylesTag';
const BUTTON_STYLES_TAG_ID = 'cmsButtonStylesTag';
const HOVER_PSEUDO_CLASS = ':hover';
const VALUES_IN_PX = [
  'font-size',
  'letter-spacing',
  'border-radius',
  'border-width',
  'padding-top',
  'padding-right',
  'padding-bottom',
  'padding-left',
];

export const getIsButtonPartStyle = (style: Cms.ContentPartStyle): style is Cms.ButtonStyle => 'hover' in style.properties;

const getStyleProperties = (style: Cms.ContentPartStyle, type: StylesPropertyType) => {
  const isButtonStyle = getIsButtonPartStyle(style);
  if (!isButtonStyle) {
    return style.properties.default;
  }

  if (type === 'default') {
    return style.properties.default;
  }
  const { isHoverEffectEnabled } = style.properties.hover;

  return isHoverEffectEnabled ? style.properties.hover : null;
};

const createStyleRuleSet = ({ style, styleType }: CreateStyleRuleSet) => {
  const { className } = style;
  const properties = getStyleProperties(style, styleType);

  if (!isDefined(properties)) {
    return '';
  }

  const propertiesString = Object.keys(properties)
    .filter(key => key !== 'isHoverEffectEnabled')
    .reduce((acc, key) => {
      const value = properties[key as keyof (Cms.TextStyleProperties | Cms.ButtonHoverStyleProperites)];
      const cssProperty = key.replace(/[A-Z]/g, '-$&').toLowerCase();
      const pxSuffix = VALUES_IN_PX.includes(cssProperty) ? 'px' : '';

      return acc + `${cssProperty}:${value}${pxSuffix};`;
    }, '');

  return styleType === 'default' ? `.${className}{${propertiesString}}` : `.${className}${HOVER_PSEUDO_CLASS}{${propertiesString}}`;
};

export const convertTextStylesToCss = (styles: Record<string, Cms.TextStyle>) => {
  return Object.values(styles)
    .map(style => createStyleRuleSet({ style, styleType: 'default' }))
    .join('');
};

export const convertButtonStylesToCss = (styles: Record<string, Cms.ButtonStyle>, styleType: StylesPropertyType) => {
  return Object.values(styles)
    .map(style => createStyleRuleSet({ style, styleType }))
    .join('');
};

const appendTextStyles = (element: HTMLElement, config: Record<string, Cms.TextStyle>) => {
  // eslint-disable-next-line functional/immutable-data
  Object.assign(element, {
    innerHTML: `${convertTextStylesToCss(config)}`,
  });
};

const appendButtonStyles = (element: HTMLElement, config: Record<string, Cms.ButtonStyle>) => {
  // eslint-disable-next-line functional/immutable-data
  Object.assign(element, {
    innerHTML: `${convertButtonStylesToCss(config, 'default')}${convertButtonStylesToCss(config, 'hover')}`,
  });
};

export const createTextStylesTag = () => {
  const textStylesTag = document.createElement('style');
  textStylesTag.setAttribute('id', TEXT_STYLES_TAG_ID);

  appendTextStyles(textStylesTag, textStylesConfig);
  document.head.appendChild(textStylesTag);
};

export const createButtonStylesTags = () => {
  const buttonStylesTag = document.createElement('style');
  buttonStylesTag.setAttribute('id', BUTTON_STYLES_TAG_ID);

  appendButtonStyles(buttonStylesTag, buttonStylesConfig);
  document.head.appendChild(buttonStylesTag);
};

export const updateTextStylesTag = (textStyles: Record<string, Cms.TextStyle>) => {
  const textStylesTag = document.getElementById(TEXT_STYLES_TAG_ID);

  if (!isNull(textStylesTag)) {
    appendTextStyles(textStylesTag, textStyles);
  }
};

export const updateButtonStylesTag = (buttonStyles: Record<string, Cms.ButtonStyle>) => {
  const buttonStylesTag = document.getElementById(BUTTON_STYLES_TAG_ID);

  if (!isNull(buttonStylesTag)) {
    appendButtonStyles(buttonStylesTag, buttonStyles);
  }
};

export const addAllTextStyleFonts = () => {
  Object.values(customStyleFonts).forEach(({ url }) => appendFontLink(url));
};

export const addCustomFonts = (customFonts: Cms.CustomFont[]) => {
  customFonts.forEach(({ url }) => appendFontLink(url));
};

export const updateFontTags = (textStyles: Record<string, Cms.TextStyle>, customFonts: Cms.CustomFont[]) => {
  Object.values(textStyles).forEach((textStyle: Cms.TextStyle) => {
    const { fontFamily } = textStyle.properties.default;

    if (isDefined(customStyleFonts[fontFamily])) {
      const fontUrl = customStyleFonts[fontFamily]?.url;
      appendFontLink(fontUrl);

      return;
    }

    customFonts.forEach(font => {
      if (font.fontFamily === fontFamily) {
        appendFontLink(font.url);
      }
    });
  });
};

const appendFontLink = (fontUrl: string | undefined) => {
  if (!isDefined(fontUrl)) {
    return;
  }
  const linkProps = { href: fontUrl, rel: 'stylesheet' };
  const link = Object.assign(document.createElement('link'), linkProps);

  const linkFromDocument = document.head.querySelector(`link[href='${fontUrl}']`);
  if (!isDefined(linkFromDocument)) {
    document.head.appendChild(link);
  }
};

export const doesStyleNameAlreadyExist = (customStyles: Cms.ContentPartStyle[], styleName: string) =>
  customStyles.some(({ name }) => name.toLowerCase() === styleName.toLowerCase());

export const isButtonPart = (part: Cms.ContentBlockPart | undefined): part is Cms.ContentBlockButtonPart => {
  return isDefined(part) && part.type === 'button';
};

export const getUsedCustomStyleIds = (blocks: Record<string, Cms.AnyBlock>, customStyles: Record<string, Cms.ContentPartStyle>) => {
  const classNames = Object.values(blocks)
    .flatMap(block => (!getIsContentBlock(block) ? undefined : Object.values(block.settings.text?.parts ?? {})))
    .filter(isDefined)
    .map(part => (isButtonPart(part) ? part.general.buttonStyle : part.general.textStyle))
    .filter(className => !customStyles[className]?.isInternal)
    .map(className => customStyles[className]?.id)
    .filter(isDefined);

  return uniq(classNames);
};

export const applyCustomStyleOnPart =
  (screenType: Cms.ScreenType) =>
  (part: Cms.ContentBlockPart, customStyle: Cms.ContentPartStyle): Cms.ContentBlockPart => {
    if (isButtonPart(part)) {
      const {
        className,
        properties: {
          default: { justifySelf },
        },
      } = customStyle as Cms.ButtonStyle;

      return {
        ...part,
        general: {
          ...part.general,
          buttonStyle: className,
        },
        [screenType]: {
          ...part[screenType],
          justifySelf,
        },
      };
    }

    const { className } = customStyle;
    const { fontSize, textAlign, color } = customStyle.properties.default;

    return {
      ...part,
      general: {
        ...part.general,
        textColor: color,
        textStyle: className,
      },
      [screenType]: {
        ...part[screenType],
        fontSize,
        textAlignment: textAlign,
      },
    };
  };

export const getIsMissingTextStyles = (missingStyles: Cms.MissingStyles[]) => missingStyles.some(style => style.type === 'text');

export const getIsMissingButtonStyles = (missingStyles: Cms.MissingStyles[]) => missingStyles.some(style => style.type === 'button');

export const extractMissingCustomStyles = (
  blocks: Record<string, Cms.AnyBlock>,
  allStyles: Record<string, Cms.TextStyle | Cms.ButtonStyle>,
): { name: string; type: Cms.StyleType }[] => {
  const allStylesNames = Object.keys(allStyles);

  return uniq(
    Object.values(blocks)
      .filter(getIsContentBlock)
      .flatMap(block => (isDefined(block.settings.text) ? Object.values(block.settings.text.parts) : []))
      .reduce((acc: { name: string; type: Cms.StyleType }[], current) => {
        const isButton = isButtonPart(current);
        const currentStyle = isButton ? current.general.buttonStyle : current.general.textStyle;

        if (allStylesNames.includes(currentStyle)) {
          return acc;
        }

        return [
          ...acc,
          {
            name: currentStyle,
            type: isButton ? 'button' : 'text',
          },
        ];
      }, []),
  );
};

export const isStyleUsingOnPage = (blocks: Record<string, Cms.AnyBlock>, customStyle: Cms.ContentPartStyle) =>
  Object.values(blocks)
    .filter(getIsContentBlock)
    .flatMap(block => (isDefined(block.settings.text) ? Object.values(block.settings.text.parts) : []))
    .some(part =>
      isButtonPart(part) ? part.general.buttonStyle === customStyle.className : part.general.textStyle === customStyle.className,
    );

export const getBlockGridPlacement = (blockPosition: Cms.BlockPositionRelativeProps) => {
  const { spanX, spanY, startX, startY } = blockPosition;

  return {
    gridColumn: `${startX} / span ${spanX}`,
    gridRow: `${startY} / span ${spanY}`,
  };
};

interface GetCustomTemplateStylesArgs {
  blockPosition: Cms.BlockPositionRelativeProps;
  shouldUpdateDimensions: boolean;
}

export const getCustomTemplateStyles = ({ blockPosition, shouldUpdateDimensions }: GetCustomTemplateStylesArgs) => {
  const { spanX, spanY, startX, startY } = blockPosition;

  return {
    gridColumn: `${startX} / span ${spanX}`,
    gridRow: `${startY} / span ${spanY}`,
    height: shouldUpdateDimensions ? `${spanY * ROW_HEIGHT + (spanY - 1) * BLOCK_SPACING}px` : undefined,
    width: shouldUpdateDimensions ? `${spanX * COLUMN_WIDTH + (spanX - 1) * BLOCK_SPACING}px` : undefined,
  };
};
