import { Id, Translations } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchCmsProductsForPreviewRequest,
  fetchPricelistsRequest,
  getCmsBlocks,
  getCmsSections,
  getIsLoadingCmsProducts,
  getIsLoadingPageData,
  getPageMetaData,
  getPricelists,
  setCurrentPricelist,
} from '../../../ducks';
import { getUniquePageProducts } from '../../../logic/pages';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import pageStyles from '../../pages/page/page.module.scss';
import DefaultLoader from '../../various/loaders/DefaultLoader';
import { Wrapper, WrapperSize } from '../../various/Wrapper';
import { EditorLanguageContext } from '../context/EditorLanguageContext';
import { EditorUIContext } from '../context/EditorUIContext';
import { PreviewTopBar } from '../topBar';

import styles from './CmsPreview.module.scss';
import { CmsPreviewSection } from './CmsPreviewSection';

import '../Cms.scss';

export const CmsPreviewContent = () => {
  const dispatch = useDispatch();
  const sections = useSelector(getCmsSections);
  const blocks = useSelector(getCmsBlocks);
  const pageMeta = useSelector(getPageMetaData);
  const priceLists = useSelector(getPricelists);
  const isLoadingPage = useSelector(getIsLoadingPageData);
  const isProductsLoading = useSelector(getIsLoadingCmsProducts);
  const { editorLanguage, handleEditorLanguageChange } = React.useContext(EditorLanguageContext);

  const [previewSegmentation, setPreviewSegmentation] = React.useState<{ marketId: Nullable<string>; pricelistId: Nullable<string> }>({
    marketId: null,
    pricelistId: null,
  });
  const { responsiveClassNames } = React.useContext(EditorUIContext);

  const filteredSections = React.useMemo(() => {
    const { marketId } = previewSegmentation;

    if (!isDefined(marketId)) {
      return sections;
    }

    return sections.filter(section => {
      const { marketIds, isHidden } = section.visibleFor;

      return marketIds?.includes(marketId) && !isHidden;
    });
  }, [previewSegmentation, sections]);

  const isLoadingAnyData =
    isLoadingPage || isProductsLoading || !isDefined(previewSegmentation.marketId) || isEmpty(priceLists) || !isDefined(pageMeta);

  const getProducts = React.useCallback(
    (marketId: Id) => {
      const productsIds = getUniquePageProducts(blocks);
      if (productsIds.length === 0) {
        return;
      }

      dispatch(
        fetchCmsProductsForPreviewRequest({
          editorLanguage,
          limit: 0,
          marketIds: [marketId],
          products: productsIds,
          skipFirst: 0,
        }),
      );
    },
    [blocks, dispatch, editorLanguage],
  );

  const handleSegmentationChange = React.useCallback(
    (marketId: string, pricelistId: string, language?: Translations.SupportedLanguagesCodes) => {
      setPreviewSegmentation({ marketId, pricelistId });
      dispatch(setCurrentPricelist(pricelistId));
      if (isDefined(language)) {
        handleEditorLanguageChange(language);
      }

      if (marketId === previewSegmentation.marketId) {
        return;
      }

      getProducts(marketId);
    },
    [setPreviewSegmentation, previewSegmentation],
  );

  React.useEffect(() => {
    if (!isEmpty(priceLists)) {
      return;
    }

    dispatch(fetchPricelistsRequest());
  }, []);

  React.useEffect(() => {
    if (isEmpty(priceLists) || !isDefined(pageMeta)) {
      return;
    }

    setPreviewSegmentation({
      marketId: pageMeta.markets[0]?.id ?? null,
      pricelistId: priceLists[0]?.id ?? null,
    });
  }, [priceLists, pageMeta]);

  React.useEffect(() => {
    if (isDefined(previewSegmentation.marketId)) {
      getProducts(previewSegmentation.marketId);
    }
  }, [previewSegmentation.marketId, editorLanguage, getProducts]);

  return (
    <div className={styles.previewWrapper}>
      {isDefined(pageMeta) && (
        <PreviewTopBar pageMeta={pageMeta} currentSegmentation={previewSegmentation} segmentationChange={handleSegmentationChange} />
      )}
      <Wrapper size={WrapperSize.XLARGE} className={cx(pageStyles.page, responsiveClassNames)}>
        {isLoadingAnyData ?
          <DefaultLoader />
        : <>
            {filteredSections.map(section => (
              <CmsPreviewSection section={section} key={section.id} />
            ))}
          </>
        }
      </Wrapper>
    </div>
  );
};
