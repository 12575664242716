import React from 'react';

import { isDefined } from '../../../utils/is';
import { ArrowThinLeft, ArrowThinRight } from '../Icon';

import styles from './TopBar.module.scss';

interface Props {
  title: string;
  subtitle?: React.ReactNode;
  onClick?: () => void;
  details?: React.ReactNode;
}

export const TopBar = ({ title, subtitle, details, children, onClick }: React.WithOptionalChildren<Props>) => {
  const isSubtitleOrDetails = isDefined(subtitle) || isDefined(details);

  return (
    <div className={styles.topBar}>
      <div className={styles.list}>
        <div className={styles.info}>
          {isDefined(onClick) && (
            <button className={styles.clickable} onClick={onClick}>
              <ArrowThinLeft />
              <h2 className={styles.title}>{title}</h2>
            </button>
          )}
          {!isDefined(onClick) && <h2 className={styles.title}>{title}</h2>}
          {isSubtitleOrDetails && (
            <>
              <ArrowThinRight />
              <div className={styles.details}>
                {isDefined(subtitle) && <h3 className={styles.title}>{subtitle}</h3>}
                {isDefined(details) && <span className={styles.subtitle}>{details}</span>}
              </div>
            </>
          )}
        </div>
        <div className={styles.alignRight}>{children}</div>
      </div>
    </div>
  );
};
