import React from 'react';

import { isDefined } from '../../../../utils/is';
import { isEmpty } from '../../../../utils/isEmpty';

import styles from './FieldRow.module.scss';

interface Props {
  label: string;
  error?: Maybe<string>;
  preventLabelFocus?: boolean;
}

export const InputRow = ({ label, children, error, preventLabelFocus = false }: React.WithChildren<Props>) => {
  const preventInputFocus = React.useCallback(
    (event: React.MouseEvent<HTMLLabelElement>) => {
      if (preventLabelFocus) {
        event.preventDefault();
      }
    },
    [preventLabelFocus],
  );

  const row = (
    // We only want to disable the interaction onClick, the keyboard should keep the default behaviour
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
    <label className={styles.inputRow} onClick={preventInputFocus}>
      <span>{label}</span>
      {children}
    </label>
  );

  if (!isDefined(error) || isEmpty(error)) {
    return row;
  }

  return (
    <div className={styles.wrapper}>
      {row}
      <div className={styles.error}>{error}</div>
    </div>
  );
};
