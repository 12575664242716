import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { mergeRefs, useLayer } from 'react-laag';
import { CSSTransition } from 'react-transition-group';

import { getChildrenByComponent } from '../../../../../utils/getChildrenByComponent';
import { isDefined } from '../../../../../utils/is';
import Icon, { IconColor, IconType } from '../../../Icon/Icon';
import { useSelectContext } from '../context/SelectContext';
import { Option } from '../Option';

import styles from './Options.module.scss';

export const OPTIONS_FADE_DURATION = 150;

interface Props {
  trigger: React.ReactElement;
  isScrollable?: boolean;
  shouldShowEmptyState?: boolean;
  shouldMatchTriggerWidth?: boolean;
}

export const Options = ({
  children,
  trigger,
  isScrollable = true,
  shouldMatchTriggerWidth = true,
  shouldShowEmptyState = true,
}: React.PropsWithChildren<Props>) => {
  const { t } = useTranslation(['common']);
  const { optionsId, isOpen, isLoading, size, containerRef, overflowContainer, close, onLoadMore } = useSelectContext();
  const [optionsWidth, setOptionsWidth] = React.useState(0);
  const optionsRef = React.useRef<HTMLDivElement>(null);

  const hasOptions = getChildrenByComponent(children, Option).length > 0;

  const { layerProps, triggerProps, triggerBounds, renderLayer } = useLayer({
    auto: true,
    container: containerRef?.current ?? undefined,
    isOpen,
    onDisappear: type => {
      if (type === 'full') {
        close();
      }
    },
    onOutsideClick: close,
    overflowContainer,
    placement: 'bottom-start',
    possiblePlacements: ['bottom-start', 'top-start'],
    triggerOffset: 4,
  });

  React.useEffect(() => {
    const triggerWidth = triggerBounds?.width ?? 0;

    if (triggerWidth > 0) {
      setOptionsWidth(triggerWidth);
    }
  }, [triggerBounds?.width]);

  const [loaderRef, isInView] = useInView({
    root: optionsRef.current,
    rootMargin: '50px',
    skip: !isDefined(onLoadMore),
  });

  React.useEffect(() => {
    if (!isLoading && isInView && hasOptions) {
      onLoadMore?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView]);

  const shouldShowNoResults = shouldShowEmptyState && !isLoading && !hasOptions;
  const shouldRenderLoader = isDefined(onLoadMore) || isLoading;

  const style = {
    ...layerProps.style,
    minWidth: optionsWidth,
    width: shouldMatchTriggerWidth ? optionsWidth : 'auto',
  };

  return (
    <>
      {React.cloneElement(trigger, triggerProps)}
      {renderLayer(
        <CSSTransition
          in={isOpen}
          timeout={OPTIONS_FADE_DURATION}
          unmountOnExit
          classNames={{
            enter: styles.animationEnter,
            enterActive: styles.animationEnterActive,
            exit: styles.animationExit,
            exitActive: styles.animationExitActive,
          }}
        >
          <div
            id={optionsId}
            role="listbox"
            tabIndex={-1}
            ref={mergeRefs(optionsRef, layerProps.ref)}
            style={style}
            className={cx(styles.options, { [styles.sizeSmall]: size === 'small', [styles.nonScrollable]: !isScrollable })}
          >
            {children}
            {shouldShowNoResults && (
              <div className={styles.noResults} role="presentation">
                {t('common:no_results')}
              </div>
            )}
            {shouldRenderLoader && (
              <div ref={loaderRef} className={styles.loader} role="presentation">
                {isLoading && <Icon type={IconType.Spinner} color={IconColor.Medium} />}
              </div>
            )}
          </div>
        </CSSTransition>,
      )}
    </>
  );
};
