/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 12;
const ORIGINAL_HEIGHT = 18;
const PROPORTION = ORIGINAL_HEIGHT / ORIGINAL_WIDTH;
const DEAFAULT_COLOR = '#5C5C5C';

export const DeviceMobile = ({ color = DEAFAULT_COLOR, width = ORIGINAL_WIDTH }: IconComponentProps) => {
  return (
    <svg width={width} height={width * PROPORTION} viewBox="0 0 12 18" fill="none">
      <path
        d="M2.03.274A2.066 2.066 0 0 0 .963.789a2.113 2.113 0 0 0-.523.8C.333 1.901.339 1.455.339 9c0 6.2.003 6.988.031 7.133.041.216.091.358.199.56.262.493.7.84 1.251.99l.17.047h8.02l.17-.047c.551-.15.989-.497 1.251-.99.108-.202.158-.344.199-.56.028-.145.031-.933.031-7.133s-.003-6.988-.031-7.133a1.748 1.748 0 0 0-.199-.56 1.994 1.994 0 0 0-1.241-.99l-.16-.046L6.06.268C3.877.267 2.063.269 2.03.274m7.795 1.493a.513.513 0 0 1 .291.231c.051.088.054.107.061.407l.007.315H1.816l.007-.315c.007-.3.01-.319.061-.407a.513.513 0 0 1 .283-.229c.127-.04 7.527-.041 7.658-.002M10.18 9v4.8H1.82V4.2h8.36V9m-.003 6.595c-.007.3-.01.319-.061.407a.513.513 0 0 1-.291.231c-.136.04-7.514.04-7.65 0a.513.513 0 0 1-.291-.231c-.051-.088-.054-.107-.061-.407l-.007-.315h8.368l-.007.315"
        fillRule="evenodd"
        fill={color}
      />
    </svg>
  );
};
