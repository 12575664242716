import { isNull } from './is';

export const enum Key {
  ESCAPE = 'Escape',
  TAB = 'Tab',
  ENTER = 'Enter',
  NUMPADENTER = 'NumpadEnter',
  LEFT = 'ArrowLeft',
  UP = 'ArrowUp',
  RIGHT = 'ArrowRight',
  SPACE = 'Space',
  DOWN = 'ArrowDown',
  DELETE = 'Delete',
  BACKSPACE = 'Backspace',
  HOME = 'Home',
  END = 'End',
  COMMA = ',',
}

export const isNumberKey = (key: string) => {
  const keyNumber = Number(key);

  return !(isNaN(keyNumber) || isNull(key) || key === ' ');
};

export const isAlphanumericKey = (key: string) => /^[\p{L}\p{N}]$/u.test(key);

export const isDeleteKey = (key: string) => key === Key.DELETE || key === Key.BACKSPACE;

export const isTabKey = (key: string) => key === Key.TAB;

export const isArrowKey = (key: string) => [Key.UP, Key.RIGHT, Key.DOWN, Key.LEFT].includes(key as Key);
