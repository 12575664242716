import { Navigation } from '@typings';
import { nanoid } from 'nanoid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { INPUT_URL_MAX_LENGTH } from '../../../../constants/limits';
import { getMarkets } from '../../../../ducks';
import { usePopover } from '../../../../utils/hooks/usePopover';
import { useValidatedForm } from '../../../../utils/hooks/useValidatedForm';
import { getUrlSchema } from '../../../../utils/schemas';
import { FocusTrap } from '../../../various/FocusTrap';
import { FieldLabel, Form, FormDivider, FormFieldset, FormGrid, InputField } from '../../../various/Form';
import { IconType } from '../../../various/Icon';
import { Button } from '../../../various/NewButton';
import { PopoverSheet } from '../../../various/Popover/PopoverSheet';
import { TextEllipsis } from '../../../various/TextEllipsis';
import { useMenuSectionContext } from '../context/MenuSectionContext';

import { MarketSelectForm } from './MarketSelectForm/MarketSelectForm';
import styles from './Forms.module.scss';

export interface LinkFormFields {
  label: string;
  url: string;
  markets: string[];
}

export const LinkForm = () => {
  const { areInputsDisabled, isInEditMode, setAddedMenuItems } = useMenuSectionContext();
  const allMarkets = useSelector(getMarkets);
  const { t } = useTranslation(['validation', 'common', 'settings']);
  const [isMarketSelectVisible, setIsMarketSelectVisible] = React.useState<boolean>(false);

  const validationSchema: yup.ObjectSchema<LinkFormFields> = yup.object({
    label: yup.string().trim().required(t('validation:navigation_label_hint')),
    markets: yup.array(yup.string().defined()).min(1, t('validation:min_one_market_hint')).defined(),
    url: getUrlSchema({ hasConfidentialIdCheck: true, isCmsEditorCheck: true }),
  });

  const formMethods = useValidatedForm<LinkFormFields>({
    defaultValues: {
      label: '',
      markets: allMarkets.map(market => market.id),
      url: '',
    },
    validationSchema,
  });
  const { register, reset, setValue, watch } = formMethods;
  const selectedMarketIds = watch('markets');
  const selectedMarkets = React.useMemo(
    () => allMarkets.filter(market => selectedMarketIds.includes(market.id)),
    [selectedMarketIds, allMarkets],
  );
  React.useEffect(() => {
    if (!isInEditMode) {
      reset();
    }
  }, [isInEditMode, reset]);

  const handleSubmit = React.useCallback(
    (values: LinkFormFields) => {
      reset();

      const newMenuItem: Navigation.LinkMenuItem = {
        ...values,
        id: nanoid(),
        openNewTab: false,
        pageId: null,
        type: 'link',
      };

      setAddedMenuItems(currentItems => [...currentItems, newMenuItem]);
    },
    [allMarkets, reset, setAddedMenuItems],
  );

  const { layerProps, triggerProps, layerSide, renderLayer } = usePopover({
    escapeHandlerEnabled: isMarketSelectVisible,
    layerProps: {
      isOpen: isMarketSelectVisible,
      placement: 'top-center',
      triggerOffset: 0,
    },
    onVisibleChange: setIsMarketSelectVisible,
  });

  return (
    <>
      {renderLayer(
        <PopoverSheet {...layerProps} isOpen={isMarketSelectVisible} layerSide={layerSide}>
          <FocusTrap shouldReturnFocus>
            <MarketSelectForm
              onClose={() => setIsMarketSelectVisible(false)}
              onSubmit={markets => {
                setValue('markets', markets);
                setIsMarketSelectVisible(false);
              }}
              selectedMarkets={selectedMarketIds}
            />
          </FocusTrap>
        </PopoverSheet>,
      )}

      <Form formMethods={formMethods} onSubmit={handleSubmit}>
        <FormFieldset className={styles.form} isDisabled={areInputsDisabled}>
          <FormGrid>
            <InputField type="url" label={t('common:url')} maxLength={INPUT_URL_MAX_LENGTH} isRequired {...register('url')} />
            <InputField label={t('settings:navigation_label')} isRequired {...register('label')} />
          </FormGrid>
          <div className={styles.linkMarkets}>
            <FieldLabel isRequired label="Markets" />
            <Button
              {...triggerProps}
              variant="ghost"
              icon={IconType.EditLinear}
              onClick={() => {
                setIsMarketSelectVisible(true);
              }}
            >
              {t('common:edit')}
            </Button>
          </div>
          <div className={styles.selectedMarketsContainer}>
            <TextEllipsis className={styles.selectedMarkets} noWrapText={false} lines={2}>
              {selectedMarkets.length === allMarkets.length ?
                t('settings:all_markets')
              : selectedMarkets.map(market => market.name).join(', ')}
            </TextEllipsis>
          </div>
          <FormDivider className={styles.divider} />
          <Button type="submit" className={styles.addButton}>
            {t('common:add_item')}
          </Button>
        </FormFieldset>
      </Form>
    </>
  );
};
