import { AccountMarket, Cms } from '@typings';

import { PreviouslyTableCell } from '../PreviouslyTableCell/PreviouslyTableCell';

import styles from './MarketsCell.module.scss';

interface Props {
  marketsData: AccountMarket[];
  row: Cms.Page;
}

const renderMarkets = (accountMarkets: AccountMarket[]) => (
  <>
    {accountMarkets.map(market => (
      <span key={market.id} className={styles.list}>
        {market.name}
      </span>
    ))}
  </>
);

export const MarketsCell = ({ marketsData, row }: Props) => (
  <>
    {renderMarkets(marketsData)}
    <PreviouslyTableCell property="markets" page={row} render={renderMarkets} />
  </>
);
