import { PopoverMenuItems } from '@typings';
import cx from 'classnames';
import { useLayer } from 'react-laag';

import { isDefined } from '../../../../utils/is';
import styles from '../PopoverMenu.module.scss';

import { Label } from './Label';
import { MenuButton } from './MenuButton';
import { NestedItems } from './NestedItems';

interface Props {
  item: PopoverMenuItems.ParentItem | PopoverMenuItems.ParentSelectableItem;
  isOpen: boolean;
  onToggleOpen: (isOpen: boolean) => void;
  closeMenu: () => void;
  containerClassName?: string;
}

const isParentSelectableMenuItem = (candidate: PopoverMenuItems.AnyMenuItem): candidate is PopoverMenuItems.ParentSelectableItem =>
  isDefined((candidate as PopoverMenuItems.ParentSelectableItem).selectedKey);

export const ParentItem = ({ isOpen = false, onToggleOpen, item, closeMenu, containerClassName }: Props) => {
  const { icon, label, isDisabled, testId, disabledTooltip, items, description, onChange, defaultKey, selectedKey, groupLabel } = item;

  const { renderLayer, triggerProps, layerProps } = useLayer({
    auto: true,
    isOpen,
    onParentClose: () => onToggleOpen(false),
    placement: 'right-start',
    possiblePlacements: ['right-start', 'right-center', 'right-end', 'left-start', 'left-center', 'left-end'],
    triggerOffset: 4,
  });

  const areItemsSelectable = isParentSelectableMenuItem(item);

  const selectedItem = areItemsSelectable ? items.find(nested => nested.key === item.selectedKey) : null;

  const subLabel = defaultKey !== selectedKey ? selectedItem?.label : undefined;

  return (
    <>
      <li {...triggerProps} className={cx(styles.menuItem, { [styles.open]: isOpen })}>
        <MenuButton
          icon={icon}
          isParent
          isDisabled={isDisabled}
          testId={testId}
          disabledTooltip={disabledTooltip}
          onClick={() => onToggleOpen(!isOpen)}
        >
          <Label description={description} subLabel={subLabel}>
            {label}
          </Label>
        </MenuButton>
      </li>
      {isOpen &&
        renderLayer(
          <NestedItems
            layerProps={layerProps}
            items={items}
            closeMenu={closeMenu}
            onSelect={onChange}
            groupLabel={groupLabel}
            selectedKey={selectedKey}
            containerClassName={containerClassName}
            areItemsSelectable={areItemsSelectable}
          />,
        )}
    </>
  );
};
