import { ActionHandler, Cms, Translations } from '@typings';
import { assocPath, dissocPath, mapObjIndexed, omit, pathOr } from 'ramda';
import { createReducer } from 'typesafe-actions';

import { isDefined } from '../../../utils/is';
import { isDeepEmpty } from '../../../utils/isDeepEmpty';
import { update } from '../../../utils/update';
import { editPageSuccess, fetchEditorPageDataSuccess, refreshPageDataSuccess } from '../../pages';
import { removeContentParts } from '../blocks';
import { resetCms, setPreviewData } from '../general';
import { pasteGroup } from '../groups';
import { pasteSection } from '../sections';

import { addLocalizedBlocksSettings, removeLocalizedBlocks, updateLocalizedBlockSettings } from './actions';

type State = Cms.BlockLocalizations;

const initialState: State = {};

const getDefaultState = (availableLanguages: Translations.SupportedLanguagesCodes[]) => {
  return Object.fromEntries(availableLanguages.map(code => [code, { blocks: {} }]));
};

const handleLocalizedDataSuccess: ActionHandler<State, typeof fetchEditorPageDataSuccess | typeof setPreviewData> = (_, action) => {
  const { availableLanguages, localizations } = action.payload.data;

  const defaultState = getDefaultState(availableLanguages);

  return isDefined(localizations) ? update(defaultState, localizations) : defaultState;
};

interface UpdateLanguage {
  blockId: string;
  language: Translations.SupportedLanguagesCodes;
  blockLocalizations: Cms.BlockLocalizations;
  updatePath: (string | number)[];
  value: unknown;
}

const getUpdatedLanguage = ({ blockId, language, blockLocalizations, updatePath, value }: UpdateLanguage) => {
  const currentLanguageState = blockLocalizations[language];
  const block = currentLanguageState?.blocks[blockId];

  const updatedBlock = assocPath(['settings', ...updatePath], value, block);

  if (isDeepEmpty(updatedBlock)) {
    return dissocPath(['blocks', blockId], currentLanguageState);
  }

  return assocPath(['blocks', blockId], updatedBlock, currentLanguageState);
};

const handleUpdateLocalizedBlockSetting: ActionHandler<State, typeof updateLocalizedBlockSettings> = (state, action) => {
  const { language, blockId, updatePath, value } = action.payload;

  if (!isDefined(language)) {
    return mapObjIndexed(
      (_, key: Translations.SupportedLanguagesCodes, blockLocalizations: Cms.BlockLocalizations) =>
        getUpdatedLanguage({ blockId, blockLocalizations, language: key, updatePath, value }),
      state,
    );
  }

  return update(state, {
    [language]: getUpdatedLanguage({ blockId, blockLocalizations: state, language, updatePath, value }),
  });
};

const handleAddLocalizedBlocksSetting: ActionHandler<State, typeof addLocalizedBlocksSettings> = (state, action) => {
  const { blocks, language } = action.payload;

  return update(state, {
    [language]: { blocks: { ...state[language]?.blocks, ...blocks } },
  });
};

const handleResetCms: ActionHandler<State, typeof resetCms> = () => {
  return initialState;
};

const handleRemoveBlocks: ActionHandler<State, typeof removeLocalizedBlocks> = (state, action) => {
  const { blockIds: blockIdsToRemove } = action.payload;

  return mapObjIndexed(localization => {
    return {
      ...localization,
      blocks: omit(blockIdsToRemove, localization.blocks),
    };
  }, state);
};

const handleRemoveContentParts: ActionHandler<State, typeof removeContentParts> = (state, action) => {
  const { blockId, partIds } = action.payload;

  const pathToText = ['blocks', blockId, 'settings', 'text'];
  const pathToParts = [...pathToText, 'parts'];

  const removeParts = (_: unknown, key: Translations.SupportedLanguagesCodes, obj: Cms.BlockLocalizations) => {
    const currentLanguage = obj[key];
    const currentParts = pathOr({}, pathToParts, currentLanguage);
    const updatedParts = omit(partIds, currentParts);
    const updatedLanguage =
      isDeepEmpty(updatedParts) ?
        dissocPath<{ blocks: Cms.LocalizedBlockSettingsRecord }>(pathToText, currentLanguage)
      : assocPath(pathToParts, updatedParts, currentLanguage);

    if (isDeepEmpty(updatedLanguage?.blocks[blockId])) {
      return dissocPath(['blocks', blockId], updatedLanguage);
    }

    return updatedLanguage;
  };

  return mapObjIndexed(removeParts, state);
};

const handleRefreshPageDataSuccess: ActionHandler<State, typeof refreshPageDataSuccess> = (_, action) => {
  const { availableLanguages, localizations } = action.payload;

  const defaultState = getDefaultState(availableLanguages);

  return isDefined(localizations) ? update(defaultState, localizations) : defaultState;
};

const handleEditPageSuccess: ActionHandler<State, typeof editPageSuccess> = (state, action) => {
  const { availableLanguages } = action.payload.data;

  const defaultState = getDefaultState(availableLanguages);

  return update(defaultState, state);
};

const handlePasteGroupOrSection: ActionHandler<State, typeof pasteGroup | typeof pasteSection> = (state, action) => {
  const { localizations } = action.payload;

  return mapObjIndexed((localization, language: Translations.SupportedLanguagesCodes) => {
    return {
      ...localization,
      blocks: { ...localization.blocks, ...localizations[language]?.blocks },
    };
  }, state);
};

export default createReducer<State, AppAction>(initialState)
  .handleAction(fetchEditorPageDataSuccess, handleLocalizedDataSuccess)
  .handleAction(setPreviewData, handleLocalizedDataSuccess)
  .handleAction(updateLocalizedBlockSettings, handleUpdateLocalizedBlockSetting)
  .handleAction(addLocalizedBlocksSettings, handleAddLocalizedBlocksSetting)
  .handleAction(removeLocalizedBlocks, handleRemoveBlocks)
  .handleAction(removeContentParts, handleRemoveContentParts)
  .handleAction(resetCms, handleResetCms)
  .handleAction(refreshPageDataSuccess, handleRefreshPageDataSuccess)
  .handleAction(editPageSuccess, handleEditPageSuccess)
  .handleAction(pasteGroup, handlePasteGroupOrSection)
  .handleAction(pasteSection, handlePasteGroupOrSection);
