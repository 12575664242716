import { DeliveryWindow } from '@typings';

import { DelwinNameAndDate } from '../../../various/DelwinNameAndDate';

import styles from './OrdersListTable.module.scss';

interface Props {
  deliveryWindows: DeliveryWindow.Mixed[];
}

export const DeliveryWindowsColumn = ({ deliveryWindows }: Props) => {
  return (
    <div className={styles.deliveryWindowsList}>
      {deliveryWindows.map(deliveryWindow => {
        return <DelwinNameAndDate key={deliveryWindow.deliveryWindow} deliveryWindow={deliveryWindow} />;
      })}
    </div>
  );
};
