import { Cms } from '@typings';

import { statusFilter } from '../../../../logic/cms/pagesList';
import { LanguageFlag } from '../../../cms/LanguageFlag';

import styles from './LanguagesCell.module.scss';

interface Props {
  languages: Cms.PageLanguage[];
  row: Cms.Page;
  filter: Cms.PageStatus | '';
}

export const LanguagesCell = ({ languages, row, filter }: Props) => (
  <ul className={styles.container}>
    {languages
      .filter(language => statusFilter(filter)(language.status))
      .map(({ code, status }) => (
        <li key={code}>
          <LanguageFlag code={code} status={status} pageId={row.id} />
        </li>
      ))}
  </ul>
);
