import { Order } from '@typings';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getActivePaymentMethod, getIsBuyerCheckout, getIsLoggedIn, getIsPrepaymentEnabled, getUser } from '../../../ducks';
import { getIsSeller } from '../../../logic/User';

import styles from './CheckoutButtonContent.module.scss';

interface Props {
  orderDetails: Order.Single;
}

export const CheckoutButtonContent = ({ orderDetails }: Props) => {
  const { t } = useTranslation(['checkout', 'payments']);

  const isPrepaymentEnabled = useSelector(getIsPrepaymentEnabled);
  const user = useSelector(getUser);
  const activePaymentMethod = useSelector(getActivePaymentMethod);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const isBuyerCheckout = useSelector(getIsBuyerCheckout);
  const isSeller = getIsSeller(user);

  const isPayLaterSelected = activePaymentMethod === 'pay_later';
  const canCheckout = isSeller || (isLoggedIn && isBuyerCheckout);
  const canPay = canCheckout || !isPayLaterSelected;

  if (isPrepaymentEnabled && canPay) {
    const paymentAmountText = isPayLaterSelected ? t('payments:pay_later') : orderDetails.order.totals.prepaymentPriceAsText;

    return (
      <Trans t={t} i18nKey="payments:confirm_and_pay_amount" values={{ amount: paymentAmountText }}>
        <span className={styles.confirmTextPart}>Confirm and </span>pay {paymentAmountText}
      </Trans>
    );
  }

  if (canCheckout) {
    return t('checkout:confirm_order');
  }

  if (orderDetails.order.status === 'checkoutRequested') {
    return t('checkout:update_checkout_request');
  }

  return t('checkout:request_checkout');
};
