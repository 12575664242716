/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 12;
const ORIGINAL_COLOR = '#000000';

export const Products = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => {
  return (
    <svg width={width} height={width} viewBox="0 0 12 12" fill="none">
      <path
        d="M0.24838 6.07265L5.08551 1.24259C5.81811 0.481002 6.84173 0 7.98578 0C10.2036 0 12 1.79374 12 4.00835C12 4.59958 11.8695 5.15073 11.6387 5.6618C11.4681 6.08267 11.2172 6.46347 10.876 6.80417L5.9285 11.7445C5.58729 12.0852 5.04537 12.0852 4.70416 11.7445L0.24838 7.2952C-0.0827932 6.95449 -0.0827932 6.41336 0.24838 6.07265ZM8.15639 5.24092C8.91909 5.24092 9.54129 4.61962 9.54129 3.85804C9.54129 3.09645 8.91909 2.46514 8.15639 2.46514C7.39369 2.46514 6.76145 3.08643 6.76145 3.84802C6.76145 4.6096 7.38365 5.24092 8.15639 5.24092Z"
        fill={color}
      />
    </svg>
  );
};
