import { isEmpty } from 'ramda';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  getHasActiveLookbookFilters,
  getLookbookAttributes,
  getLookbookExpandedFiltersStates,
  getLookbookFilters,
  resetLookbookFilters,
  setExpandedLookbookFilters,
  updateLookbookFilters,
} from '../../../../ducks';
import { FiltersBarHeader } from '../FiltersBarHeader';

import { FiltersPanelSelectorsContext } from './context/FiltersPanelSelectorsContext';
import styles from './FiltersPanel.module.scss';
import { FiltersPanelGroups } from './FiltersPanelGroups';
import { FiltersPanelWrapper } from './FiltersPanelWrapper';

const filtersPanelSelectors = {
  getAttributes: getLookbookAttributes,
  getExpandedFilters: getLookbookExpandedFiltersStates,
  getFilters: getLookbookFilters,
  getHasActiveFilters: getHasActiveLookbookFilters,
};

interface Props {
  isLoadingProducts: boolean;
  totalItems: number;
}

export const LookbookFiltersPanel = ({ isLoadingProducts, totalItems }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'products']);

  const expandedFilters = useSelector(getLookbookExpandedFiltersStates);
  const lookbookAttributes = useSelector(getLookbookAttributes);

  const isLoading = isLoadingProducts && isEmpty(lookbookAttributes);

  const handleFilterChange = (fieldName: string, selectedValues: string | string[]) => {
    dispatch(updateLookbookFilters({ [fieldName]: selectedValues }));
  };

  const handleToggleFilter = (fieldName: string, isExpanded: boolean) => {
    const newExpandedFilters = { ...expandedFilters, [fieldName]: !isExpanded };

    dispatch(setExpandedLookbookFilters(newExpandedFilters));
  };

  const handleClearFilters = () => {
    dispatch(resetLookbookFilters());
  };

  return (
    <FiltersPanelSelectorsContext.Provider value={filtersPanelSelectors}>
      <FiltersPanelWrapper className={styles.filtersWrapperLookbook} isLoading={isLoading}>
        <FiltersBarHeader label={t('common:filter_by')} productsCount={totalItems} showFilterDetails onClearFilters={handleClearFilters} />
        <FiltersPanelGroups onFilterChange={handleFilterChange} onToggleFilter={handleToggleFilter} />
      </FiltersPanelWrapper>
    </FiltersPanelSelectorsContext.Provider>
  );
};
