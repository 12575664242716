import { Filter } from '@typings';
import React from 'react';

import { RadioGroup } from '../../../../various/Fields/RadioGroup';

import { EtaFilter } from './EtaFilter';
import styles from './FiltersRadioGroup.module.scss';

interface Props {
  filterField: string;
  fieldOptions: Filter.Option<string>[];
  selectedOption: string | undefined;
  onSelectedValueChange: (selectedValue: string) => void;
}

export const FiltersRadioGroup = ({ filterField, fieldOptions, selectedOption, onSelectedValueChange }: Props) => {
  const options = React.useMemo(() => {
    if (filterField === 'onlyAvailable') {
      return fieldOptions.map(({ data, filterValue }) => ({
        name: filterValue === 'arrivingSoon' ? <EtaFilter title={data} /> : data,
        value: filterValue,
      }));
    }

    return fieldOptions.map(({ data, filterValue }) => ({
      name: data,
      value: filterValue,
    }));
  }, [fieldOptions, filterField]);

  const handleSetRadioFilter = (value: string) => {
    onSelectedValueChange(value);
  };

  const initialValue = options[0]?.value ?? '';

  return (
    <RadioGroup
      name={filterField}
      itemClassName={styles.radioItem}
      value={selectedOption ?? initialValue}
      options={options}
      size="small"
      onChange={handleSetRadioFilter}
    />
  );
};
