import { Id, Translations } from '@typings';
import { createAction } from 'typesafe-actions';

export const updateLocalizedBlockSettings = createAction('cms/UPDATE_LOCALIZED_BLOCK_SETTINGS')<{
  language?: Translations.SupportedLanguagesCodes;
  blockId: Id;
  updatePath: (string | number)[];
  value: unknown;
}>();

export const addLocalizedBlocksSettings = createAction('cms/ADD_LOCALIZED_BLOCKS_SETTINGS')<{
  blocks: Record<Id, unknown>;
  language: Translations.SupportedLanguagesCodes;
}>();

export const downloadTranslationsFile = createAction('cms/DOWNLOAD_TRANSLATIONS_FILE')();

export const processUploadedTranslationsFile = createAction('cms/PROCESS_UPLOADED_TRANSLATIONS_FILE')<{
  emptyCellsOverride: boolean;
  file: File;
}>();

export const removeLocalizedBlocks = createAction('cms/REMOVE_LOCALIZABLE_BLOCKS')<{
  blockIds: Id[];
}>();
