import { Selects } from '@typings';
import { without } from 'ramda';
import React from 'react';

import { isDefined } from '../../../../../utils/is';
import { OPTIONS_FADE_DURATION } from '../Options';

interface Context {
  activePosition?: Nullable<number>;
  close: () => void;
  containerRef?: React.RefObject<HTMLElement>;
  dataTestId?: string;
  describedById?: string;
  errorMessageId?: string;
  focusedPosition: Nullable<number>;
  focusOption: (value: number) => void;
  handleSelect: (value: Selects.NonNullableValue) => void;
  hoveredPosition: Nullable<number>;
  hoverOption: (value: number) => void;
  isDisabled: boolean;
  isInvalid: boolean;
  isLoading: boolean;
  isMultiple?: boolean;
  isOpen: boolean;
  onBlur?: (event: React.FocusEvent) => void;
  onLoadMore?: () => void;
  open: () => void;
  optionsId: string;
  overflowContainer: boolean;
  placeholder?: string;
  selection: Selects.Selection;
  showArrow: boolean;
  size: Selects.Size;
  toggleIsOpen: () => void;
  triggerId: string;
}

export const useSelectContext = () => {
  const context = React.useContext(SelectContext);

  if (!isDefined(context)) {
    throw new Error('You need to use useSelectContext inside of SelectContextProvider');
  }

  return context;
};

export const SelectContext = React.createContext<Context | null>(null);

export const SelectContextProvider = ({
  allowClear = false,
  children,
  containerRef,
  dataTestId,
  describedById,
  errorMessageId,
  id,
  isDisabled = false,
  isInvalid = false,
  isLoading = false,
  isMultiple,
  name,
  onBlur,
  onChange,
  onLoadMore,
  onOpen,
  overflowContainer = false,
  placeholder,
  showArrow = true,
  size = 'regular',
  value,
}: Selects.CommonProps) => {
  const reactId = React.useId();
  const triggerId = id ?? name ?? reactId;
  const optionsId = `${triggerId}-options`;

  const [selection, setSelection] = React.useState(value);
  const [isOpen, setIsOpen] = React.useState(false);
  const [focusedPosition, setFocusedPosition] = React.useState<Nullable<number>>(null);
  const [hoveredPosition, setHoveredPosition] = React.useState<Nullable<number>>(null);

  React.useEffect(() => {
    setSelection(value);
  }, [value]);

  React.useEffect(() => {
    if (isOpen) {
      return;
    }

    const timeout = setTimeout(() => {
      setFocusedPosition(null);
      setHoveredPosition(null);
    }, OPTIONS_FADE_DURATION);

    return () => {
      clearTimeout(timeout);
    };
  }, [isOpen]);

  const open = React.useCallback(() => {
    if (isOpen) {
      return;
    }

    setIsOpen(true);
    onOpen?.();
  }, [isOpen, onOpen]);

  const close = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  const toggleIsOpen = React.useCallback(() => {
    if (isDisabled) {
      return;
    }

    isOpen ? close() : open();
  }, [close, isDisabled, isOpen, open]);

  const hoverOption = React.useCallback((index: number) => {
    setHoveredPosition(index);
    setFocusedPosition(null);
  }, []);

  const focusOption = React.useCallback((index: number) => {
    setHoveredPosition(index);
    setFocusedPosition(index);
  }, []);

  const handleSelect = React.useCallback(
    (selectedValue: Selects.NonNullableValue) => {
      const isMultipleSelection = Array.isArray(selection);

      if (isMultiple && isMultipleSelection) {
        const newSelection = selection.includes(selectedValue) ? without([selectedValue], selection) : [...selection, selectedValue];

        setSelection(newSelection);
        onChange(newSelection);
      }

      if (!isMultiple && !isMultipleSelection) {
        const shouldClearSelection = selectedValue === selection && allowClear;
        const newSelection = shouldClearSelection ? null : selectedValue;

        setSelection(newSelection);
        onChange(newSelection);
      }

      if (!isMultipleSelection) {
        setIsOpen(false);
      }
    },
    [isMultiple, allowClear, onChange, selection],
  );

  return (
    <SelectContext.Provider
      value={{
        activePosition: focusedPosition ?? hoveredPosition,
        close,
        containerRef,
        dataTestId,
        describedById,
        errorMessageId,
        focusOption,
        focusedPosition,
        handleSelect,
        hoverOption,
        hoveredPosition,
        isDisabled,
        isInvalid,
        isLoading,
        isMultiple,
        isOpen,
        onBlur,
        onLoadMore,
        open,
        optionsId,
        overflowContainer,
        placeholder,
        selection,
        showArrow,
        size,
        toggleIsOpen,
        triggerId,
      }}
    >
      {children}
    </SelectContext.Provider>
  );
};
