import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import Icon, { IconType } from '../../../../various/Icon';

import styles from './AddBlockButton.module.scss';

interface Props {
  type: 'product' | 'content';
  disabled?: boolean;
  onClick?: VoidFunction;
}
export const AddBlockButton = ({ type, onClick, disabled }: Props) => {
  const { t } = useTranslation(['cms']);
  const label = t(type === 'content' ? 'cms:add_content_block' : 'cms:add_product_block');

  return (
    <button
      type="button"
      className={cx(styles.button, { [styles.product]: type === 'product', [styles.content]: type === 'content' })}
      onClick={onClick}
      disabled={disabled}
    >
      {type === 'content' ?
        <Icon type={IconType.Image} />
      : <Icon type={IconType.Tag} />}
      {label}
    </button>
  );
};
