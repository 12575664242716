/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;

export const Barcode = ({ width = ORIGINAL_WIDTH, color }: IconComponentProps) => {
  return (
    <svg width={width} height={width} viewBox="0 0 48 48" fill={color}>
      <path d="M2 42v-9.1h3V39h6.1v3Zm34.85 0v-3h6.1v-6.1h3V42Zm-29.2-5.55V11.5h4v24.95Zm6.05 0V11.5h2.1v24.95Zm6.1 0V11.5h4.15v24.95Zm6.25 0V11.5h6.05v24.95Zm8.15 0V11.5h2.1v24.95Zm4.15 0V11.5h1.9v24.95ZM2 15.1V6h9.1v3H5v6.1Zm40.95 0V9h-6.1V6h9.1v9.1Z" />
    </svg>
  );
};
