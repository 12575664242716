import { Toast } from '@typings';
import { nanoid } from 'nanoid';
import React from 'react';

import { ActionType } from './state/actions';
import { dispatch } from './state/store';

type ToastHandler = (message: React.ReactNode, options?: Toast.Options) => string;

const createToast = (message: React.ReactNode, options?: Toast.Options): Toast => ({
  ariaProps: {
    'aria-live': 'polite',
    role: 'status',
  },
  createdAt: Date.now(),
  message,
  pauseDuration: 0,
  visible: true,
  ...options,
  id: options?.id ?? nanoid(),
});

const createHandler: ToastHandler = (message, options) => {
  const toast = createToast(message, options);
  dispatch({ toast, type: ActionType.UPSERT_TOAST });

  return toast.id;
};

export const addToast = (message: React.ReactNode, options?: Toast.Options) => createHandler(message, options);
export const clearToasts = () => dispatch({ type: ActionType.CLEAR_TOASTS });

export const dismissToast = (toastId?: string) => {
  dispatch({
    toastId,
    type: ActionType.DISMISS_TOAST,
  });
};

export const removeToast = (toastId?: string) => dispatch({ toastId, type: ActionType.REMOVE_TOAST });
