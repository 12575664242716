import { useQuery } from '@tanstack/react-query';
import { Order, Selections } from '@typings';
import { useTranslation } from 'react-i18next';

import { loadOrdersQuery } from '../../selectionRepository';

export const SELECTIONS_QUERY_KEY = 'selectionsList';

interface SelectionsList {
  data: Selections.FilterData<Order.SelectionStatus[]>;
}

export const useSelectionsList = ({ data }: SelectionsList) => {
  const { t } = useTranslation(['selections']);

  return useQuery({
    meta: {
      errorMessage: t('selections:selections_fetch_failed'),
    },
    queryFn: async () => loadOrdersQuery(data),
    queryKey: [SELECTIONS_QUERY_KEY, { data }],
  });
};
