import { Id, Invoice } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getCalculatedInvoiceStatus } from '../../../logic/invoices';
import { compiledPaths } from '../../../paths';
import { isDefined } from '../../../utils/is';
import Button from '../../various/Button';
import { Card } from '../../various/Card';
import Heading from '../../various/Heading';
import { InvoiceStatusLabel } from '../../various/StatusLabel';

import styles from './CheckoutCards.module.scss';
import { InvoiceDownloadLink } from './InvoiceDownloadLink';

interface Props {
  invoice: Invoice.Standard;
  orderId: Id;
}

export const InvoiceCard = React.memo<Props>(({ invoice, orderId }) => {
  const { t } = useTranslation(['common', 'checkout', 'totals', 'invoices']);
  const { shippingIds, taxes, invoice: invoiceId, invoiceHash, isPayable } = invoice;

  const isInvoiceNotPaid = invoice.status === 'Not paid';

  const calculatedStatus = getCalculatedInvoiceStatus(invoice);

  return (
    <Card.Wrapper>
      <Card.Group>
        <Card.Title>
          <Heading className={styles.id} variant={['h3']} title={t('invoices:invoice_number', { invoiceNumber: invoice.invoiceNumber })} />
          <InvoiceStatusLabel status={calculatedStatus} />
        </Card.Title>
        <Card.DescriptionList>
          <Card.Description className={Card.styles.row1col2} name={t('common:created')} value={invoice.createdAt} />
          <Card.Description className={Card.styles.row1col3} name={t('common:due_date')} value={invoice.dueDate} />
          <Card.Description className={Card.styles.row2col1} name={t('common:company')} value={invoice.company} />
          <Card.Description
            className={Card.styles.row2col2}
            name={t('common:shipment_one')}
            value={isDefined(shippingIds) ? shippingIds : '-'}
          />
          <Card.Description className={Card.styles.row3col1} name={t('common:address')} value={invoice.address.split(',')} />
          <div className={Card.styles.row2to4col3}>
            <Card.Description
              className={cx(Card.styles.alignRight, Card.styles.verticallyCentered)}
              name={t('totals:total_no_tax')}
              value={invoice.grandTotalWithoutTax}
            />
            {taxes.map((tax, idx) => (
              <>
                <Card.Description
                  key={`taxPercent-${idx}`}
                  className={Card.styles.alignRight}
                  name={t('common:tax_percent', { taxPercent: tax.taxPercent })}
                  value={tax.totalPriceTax}
                />
              </>
            ))}
            <Card.Description
              className={cx(Card.styles.alignRight, Card.styles.verticallyCentered)}
              name={t('totals:total_with_tax')}
              value={invoice.grandTotal}
            />
            <Card.Description
              className={cx(Card.styles.alignRight, Card.styles.emphases, Card.styles.verticallyCentered)}
              name={t('totals:total_due_with_tax')}
              value={invoice.totalDue}
            />
          </div>
        </Card.DescriptionList>
        <div className={styles.download}>
          <InvoiceDownloadLink invoiceId={invoiceId} orderId={orderId} invoiceNumber={invoice.invoiceNumber} />
        </div>

        {isInvoiceNotPaid && isPayable && (
          <Button
            className={styles.paymentButton}
            variant={['button', 'small', 'blue']}
            to={compiledPaths.INVOICE_DETAILS({ invoiceHash, invoiceId })}
          >
            {t('invoices:pay_invoice')}
          </Button>
        )}
      </Card.Group>
    </Card.Wrapper>
  );
});
