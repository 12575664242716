import { Badges, DeliveryWindow, Product } from '@typings';
import { omit } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { IconType } from '../../components/various/Icon';
import { getAreBackordersEnabled, getSoldOutLabel } from '../../ducks';
import { getCustomBadges } from '../../ducks/helpers';
import { getHasEtaInSelectedDeliveryWindows } from '../../logic/eta';

import { useProductPriceComponents } from './useProductPriceComponents';

interface Props {
  variant: Product.Standard;
  isAvailable: boolean;
  delwinIds: DeliveryWindow.Id[];
}

export const useProductBadges = ({ variant, isAvailable, delwinIds }: Props) => {
  const { t } = useTranslation(['common', 'products']);

  const backordersEnabled = useSelector(getAreBackordersEnabled);
  const soldOutLabel = useSelector(getSoldOutLabel);

  const { isOnSale } = useProductPriceComponents(variant);
  const hasEtaBadge = React.useMemo(() => getHasEtaInSelectedDeliveryWindows(delwinIds, [variant]), [delwinIds, variant]);

  const isSoldOut = !isAvailable && !backordersEnabled;
  const shouldShowAsNew = variant.showAsNew ?? false;

  const soldOutBadge = {
    backgroundColor: '#d8d8d8',
    badgeType: 'soldOut' as const,
    isVisible: isSoldOut,
    name: soldOutLabel,
    textColor: '#333',
  };

  const etaBadge = {
    backgroundColor: '#3C6E73',
    badgeType: 'eta' as const,
    icon: IconType.Hourglass,
    isVisible: hasEtaBadge,
    name: '',
    textColor: '#fff',
    tooltip: t('common:eta_description'),
  };

  const saleBadge = {
    backgroundColor: '#232323',
    badgeType: 'sale' as const,
    isVisible: isOnSale,
    name: t('common:sale'),
    textColor: '#fff',
  };

  const newBadge = {
    backgroundColor: '#f8f8f8',
    badgeType: 'new' as const,
    isVisible: shouldShowAsNew,
    name: t('common:new'),
    textColor: '#232323',
  };

  const defaultBadges: Badges.DefaultBadge[] = [soldOutBadge, etaBadge, saleBadge, newBadge]
    .filter(({ isVisible }) => isVisible)
    .map(omit(['isVisible']));

  const customBadges = getCustomBadges(variant);
  const badgesCount = defaultBadges.length + customBadges.length;

  return {
    badgesCount,
    customBadges,
    defaultBadges,
    hasEtaBadge,
  };
};
