/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;
const ORIGINAL_COLOR = '#262626';

export const CloseHamburger = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => (
  <svg width={width} height={width} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m10.001 10.684-9.316 9.317-.685-.685 9.316-9.316-9.316-9.315.685-.685 9.316 9.315 9.314-9.315.685.685-9.314 9.315 9.314 9.312-.685.685z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);
