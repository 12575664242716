import { Addresses, Checkout, Product } from '@centra';

import { isDefined } from './is';

export const isMonuple = <T>(array: Monuple<T> | Tuple<T>): array is Monuple<T> => {
  return array.length === 1;
};

export const getIsSingleCheckout = (checkout: Checkout.Single | Checkout.Split): checkout is Checkout.Single => {
  return isMonuple(checkout.orders);
};

export const getHasRelatedProducts = (
  product: Product.Standard,
): product is Product.Standard & {
  relatedProducts: Product.RelatedProducts;
} => {
  return !Array.isArray(product.relatedProducts) && isDefined(product.relatedProducts?.variants);
};

export const isTemporaryShipping = (address: Addresses.MixedShipping): address is Addresses.TemporaryShipping => {
  return 'isTemporary' in address && address.isTemporary;
};
