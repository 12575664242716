import { Checkout, Id, Invoice, Payments } from '@typings';
import { createAction } from 'typesafe-actions';

export const requestInvoicePayment = createAction('payments/REQUEST_INVOICE_PAYMENT')<{
  payment: Payments.PaymentRequest;
  identifiers: Invoice.InvoiceIdentifiers;
}>();

export const requestInvoicePaymentFailure = createAction('payments/REQUEST_INVOICE_PAYMENT_FAILURE')();

export const requestInvoicePaymentResultLongPolling = createAction(
  'payments/REQUEST_INVOICE_PAYMENT_RESULT_LONG_POLLING',
)<Invoice.InvoiceIdentifiers>();

export const requestOrderPayment = createAction('payments/REQUEST_ORDER_PAYMENT')<{
  payment: Payments.PaymentRequest;
  checkoutData: Checkout.Data;
  orderId: Id;
}>();

export const requestOrderPaymentFailure = createAction('payments/REQUEST_ORDER_PAYMENT_FAILURE')();

export const requestOrderPaymentResult = createAction('payments/REQUEST_ORDER_PAYMENT_RESULT')<Id>();

export const requestOrderPaymentResultLongPolling = createAction('payments/REQUEST_ORDER_PAYMENT_LONG_POLLING')<Id>();
