import { useTranslation } from 'react-i18next';

import { useMatrixSettingsContext } from '../../../products/ProductMatrix/context';
import { RegularHeaderCell } from '../MatrixCells';
import { PinnableSummaryCell } from '../MatrixCells/PinnableSummaryCell';

import styles from './MatrixHead.module.scss';

export const TotalsSummaryHeadCell = () => {
  const { t } = useTranslation(['common', 'totals']);
  const { isLookbook } = useMatrixSettingsContext();

  if (isLookbook) {
    return null;
  }

  return (
    <PinnableSummaryCell>
      <RegularHeaderCell className={styles.headTotalCell}>{t('totals:total_one')}</RegularHeaderCell>
    </PinnableSummaryCell>
  );
};
