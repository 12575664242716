import { Checkboxes, DeliveryWindow } from '../../../../../../../../typings';
import { DelwinNameAndDate } from '../../../../../various/DelwinNameAndDate';
import { Checkbox } from '../../../../../various/Fields/Checkbox';
import styles from '../FiltersDeliveryWindows.module.scss';

interface DeliveryWindowFilterProps {
  deliveryWindow: DeliveryWindow.Mixed;
  optionsState: Checkboxes.OptionsState;
  toggleOptionState: (changedValue: string) => void;
}

export const DeliveryWindowFilter = ({ deliveryWindow, optionsState, toggleOptionState }: DeliveryWindowFilterProps) => {
  const optionState = optionsState[deliveryWindow.deliveryWindow];

  return (
    <Checkbox
      onChange={event => toggleOptionState(event.target.value)}
      value={deliveryWindow.deliveryWindow}
      checked={optionState === 'checked'}
      indeterminate={optionState === 'indeterminate'}
      className={styles.checkboxWrapper}
    >
      <DelwinNameAndDate deliveryWindow={deliveryWindow} shouldStretch />
    </Checkbox>
  );
};
