import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import {
  changeAccountPasswordRequest,
  getAccessToken,
  getBuyerMinimumPasswordLength,
  getChangeAccountPassword,
  resetChangeAccountPassword,
} from '../../ducks';
import { useValidatedForm } from '../../utils/hooks/useValidatedForm';
import { isDefined } from '../../utils/is';
import { Form, FormFieldset, FormGrid, FormGridPlaceholder, InputField } from '../various/Form';
import { SectionFormHeading } from '../various/SectionHeading';

const defaultValues = {
  currentPassword: '',
  newPassword: '',
  reNewPassword: '',
};

type FormData = typeof defaultValues;

export const ChangePasswordForm = () => {
  const { t } = useTranslation(['common', 'validation', 'passwordManagement']);
  const dispatch = useDispatch();
  const userToken = useSelector(getAccessToken);
  const minimumPasswordLength = useSelector(getBuyerMinimumPasswordLength);
  const { isLoading, isFailed, data: response } = useSelector(getChangeAccountPassword);
  const [isEditing, setIsEditing] = React.useState(false);

  const validationSchema: yup.ObjectSchema<FormData> = yup.object({
    currentPassword: yup.string().required(t('validation:password_hint')),
    newPassword: yup
      .string()
      .required(t('validation:new_password_hint'))
      .min(minimumPasswordLength, t('validation:password_length_hint', { count: minimumPasswordLength })),
    reNewPassword: yup
      .string()
      .required(t('validation:repeat_password_hint'))
      .oneOf([yup.ref('newPassword')], t('validation:password_match_hint')),
  });

  const formMethods = useValidatedForm<FormData>({
    defaultValues,
    validationSchema,
  });

  const { register, reset, setError } = formMethods;

  React.useEffect(() => {
    if (isFailed && isDefined(response.msg)) {
      setError('currentPassword', { message: response.msg }, { shouldFocus: true });
    }
  }, [isFailed, response.msg, setError]);

  React.useEffect(() => {
    if (!response.hasChanged) {
      return;
    }

    setIsEditing(false);
    dispatch(resetChangeAccountPassword());
    reset();
  }, [dispatch, reset, response]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
    reset();
  };

  const handleSubmit = ({ currentPassword, newPassword }: FormData) => {
    if (!isDefined(userToken)) {
      return;
    }

    dispatch(
      changeAccountPasswordRequest({
        currentPassword,
        newPassword,
        token: userToken,
      }),
    );
  };

  return (
    <section>
      <Form formMethods={formMethods} onSubmit={handleSubmit}>
        <SectionFormHeading
          heading={t('passwordManagement:change_password')}
          isEditing={isEditing}
          isLoading={isLoading}
          onCancel={handleCancel}
          onEdit={handleEdit}
        />
        <FormFieldset isDisabled={!isEditing || isLoading}>
          <FormGrid cols={2} colGap="xlarge">
            <InputField type="password" label={t('passwordManagement:current_password')} isRequired {...register('currentPassword')} />
            <FormGridPlaceholder />
            <InputField type="password" label={t('passwordManagement:new_password')} isRequired {...register('newPassword')} />
            <InputField type="password" label={t('passwordManagement:repeat_password')} isRequired {...register('reNewPassword')} />
          </FormGrid>
        </FormFieldset>
      </Form>
    </section>
  );
};
