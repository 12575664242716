import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  getHasUnpaidInvoices,
  getIsCheckoutBlockedOnUnpaidInvoices,
  getShouldBlockIfUnpaidInvoices,
  getUnpaidInvoicesNotificationVisibility,
  getUser,
} from '../../../ducks';
import { getIsBuyer } from '../../../logic/User';
import { paths } from '../../../paths';
import { Alert } from '../Alert';
import { AlertAction } from '../Alert/AlertAction';

export const UnpaidInvoicesAlert = () => {
  const { t } = useTranslation(['invoices']);
  const user = useSelector(getUser);

  const isBuyer = getIsBuyer(user);

  const hasUnpaidInvoices = useSelector(getHasUnpaidInvoices);
  const isCheckoutBlockedOnUnpaid = useSelector(getIsCheckoutBlockedOnUnpaidInvoices);
  const shouldBlockIfUnpaidInvoices = useSelector(getShouldBlockIfUnpaidInvoices);
  const unpaidInvoicesNotificationVisibility = useSelector(getUnpaidInvoicesNotificationVisibility);

  const isCheckoutBlocked = isCheckoutBlockedOnUnpaid && shouldBlockIfUnpaidInvoices;
  const isNotificationVisible =
    unpaidInvoicesNotificationVisibility === 'always' ||
    (shouldBlockIfUnpaidInvoices && unpaidInvoicesNotificationVisibility === 'checkout_blocked');

  const message = React.useMemo(() => {
    const buyerMessage =
      isCheckoutBlocked ?
        <Trans t={t} i18nKey="invoices:you_have_unpaid_invoices_must_pay">
          You have <b>unpaid invoices</b> which must be paid to be able to place your next order.
        </Trans>
      : <Trans t={t} i18nKey="invoices:you_have_unpaid_invoices">
          You have <b>unpaid invoices</b>.
        </Trans>;

    if (isBuyer) {
      return (
        <>
          {buyerMessage} <AlertAction to={paths.INVOICES}>{t('invoices:show_invoices')}</AlertAction>
        </>
      );
    }

    return isCheckoutBlocked ?
        <Trans t={t} i18nKey="invoices:buyer_has_unpaid_invoices_must_pay">
          <b>Unpaid invoices.</b> The buyer has unpaid invoices which must be paid to be able to place the next order.
        </Trans>
      : <Trans t={t} i18nKey="invoices:buyer_has_unpaid_invoices">
          <b>Unpaid invoices.</b> The buyer has unpaid invoices.
        </Trans>;
  }, [isBuyer, isCheckoutBlocked, t]);

  if (!hasUnpaidInvoices || !isNotificationVisible) {
    return null;
  }

  return <Alert message={message} type="error" noRoundCorners />;
};
