/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;
const ORIGINAL_COLOR = '#848484';

export const Uppercase = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => (
  <svg width={width} height={width} viewBox="0 0 20 20">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.469107 14.6126L4.01146 5.12745C4.10037 4.87974 4.2328 4.70198 4.40129 4.5873C4.56962 4.47272 4.76768 4.42499 4.98076 4.42499C5.19986 4.42499 5.40411 4.4725 5.57723 4.58669C5.75078 4.70116 5.88676 4.87852 5.97595 5.12703L9.51744 14.6377C9.56055 14.7306 9.57529 14.8104 9.57529 14.9028C9.57529 15.2219 9.33677 15.4534 9.05367 15.53C8.96889 15.5598 8.88322 15.575 8.79697 15.575C8.53235 15.575 8.29444 15.4314 8.19221 15.136L7.1812 12.3111H2.78069L1.79563 15.1213C1.74587 15.2725 1.66578 15.3838 1.56293 15.457C1.46013 15.53 1.34001 15.5611 1.21665 15.5611C1.11708 15.5611 1.03179 15.5458 0.935534 15.5166C0.645909 15.4387 0.425293 15.1755 0.425293 14.875C0.425293 14.7824 0.440024 14.7053 0.467783 14.6165L0.469107 14.6126ZM3.22545 10.9944H6.73666C6.43544 10.158 5.98165 8.95986 5.59696 7.95485C5.39683 7.43201 5.21548 6.96164 5.08416 6.62197L4.9455 6.26392C4.55746 7.38138 4.14787 8.49089 3.73863 9.59943C3.56697 10.0644 3.39537 10.5293 3.22545 10.9944Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4692 14.6126L14.0115 5.12745C14.1004 4.87974 14.2328 4.70198 14.4013 4.5873C14.5697 4.47272 14.7677 4.42499 14.9808 4.42499C15.1999 4.42499 15.4042 4.4725 15.5773 4.58669C15.7508 4.70116 15.8868 4.87852 15.976 5.12703L19.5175 14.6377C19.5606 14.7306 19.5753 14.8104 19.5753 14.9028C19.5753 15.2219 19.3368 15.4534 19.0537 15.53C18.9689 15.5598 18.8833 15.575 18.797 15.575C18.5324 15.575 18.2945 15.4314 18.1923 15.136L17.1812 12.3111H12.7807L11.7957 15.1213C11.7459 15.2725 11.6658 15.3838 11.563 15.457C11.4602 15.53 11.3401 15.5611 11.2167 15.5611C11.1171 15.5611 11.0318 15.5458 10.9356 15.5166C10.646 15.4387 10.4253 15.1755 10.4253 14.875C10.4253 14.7824 10.4401 14.7053 10.4678 14.6165L10.4692 14.6126ZM13.2255 10.9944H16.7367C16.4355 10.158 15.9817 8.95986 15.597 7.95485C15.3969 7.43201 15.2155 6.96164 15.0842 6.62197L14.9456 6.26392C14.5582 7.37939 14.1493 8.48694 13.7409 9.59351L13.7387 9.59943C13.567 10.0644 13.3954 10.5293 13.2255 10.9944Z"
      fill={color}
    />
  </svg>
);
