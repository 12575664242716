import { createButtonStylesTags, createTextStylesTag } from './logic/cms/styles';
import { initAmplitude } from './utils/analytics/amplitude';
import { initSentry } from './utils/monitoring';

import './wdyr';
import './polyfills';
import './utils/yup/yupCustomMethods';

initAmplitude();
initSentry();
createTextStylesTag();
createButtonStylesTags();
