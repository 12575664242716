import { Id } from '@typings/@centra';
import { intersection } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';

import { getActiveDeliveryWindowsIdsForOrderBuyer, getAllDeliveryWindows, getStockTypeLabels } from '../../ducks';
import { addProductsToOrder, getOrderDeliveryWindowsIds, getOrderDetails, removeProductsFromOrder } from '../../ducks/order';
import { getIsProductLoading, getStockTypeFilter } from '../../ducks/products';
import { getDeliveryWindowsIdsByStockType } from '../../logic/deliveryWindows';
import { getIsProductInOrderByDelwinIds, getTotalUnitQuantityByProductAndDelwins } from '../../logic/Orders';
import { paths } from '../../paths';
import { isDefined } from '../is';

import { useConfirmationGuard } from './useConfirmationGuard';

interface Props {
  productId: Id;
}

export const useToggleInOrder = ({ productId }: Props) => {
  const { t } = useTranslation(['confirmationConfig', 'common']);
  const dispatch = useDispatch();
  const confirmationGuard = useConfirmationGuard();
  const isAllProductsPage = useMatch(paths.PRODUCTS_ORDER)?.pattern.end ?? false;

  const isProductLoadingSelector = React.useMemo(() => getIsProductLoading(productId), [productId]);
  const isProductLoading = useSelector(isProductLoadingSelector);
  const orderDetails = useSelector(getOrderDetails);
  const stockType = useSelector(getStockTypeFilter);
  const stockTypeLabels = useSelector(getStockTypeLabels);
  const deliveryWindows = useSelector(getAllDeliveryWindows);
  const deliveryWindowsIdsByStockType = getDeliveryWindowsIdsByStockType(deliveryWindows);
  const orderDeliveryWindowsIds = useSelector(getOrderDeliveryWindowsIds);
  const buyerDeliveryWindowsIds = useSelector(getActiveDeliveryWindowsIdsForOrderBuyer);

  const deliveryWindowsIdsWithCurrentStockType =
    isAllProductsPage && isDefined(stockType) ?
      deliveryWindowsIdsByStockType[stockType]
    : Object.values(deliveryWindowsIdsByStockType).flat();

  const isInOrder = getIsProductInOrderByDelwinIds(productId, deliveryWindowsIdsWithCurrentStockType, orderDetails.order.products);
  const hasUnits =
    getTotalUnitQuantityByProductAndDelwins(productId, deliveryWindowsIdsWithCurrentStockType, orderDetails.order.products) > 0;

  const removeProductsAction = React.useCallback(() => {
    dispatch(
      removeProductsFromOrder({
        deliveryWindows: intersection(orderDeliveryWindowsIds, deliveryWindowsIdsWithCurrentStockType),
        products: [productId],
      }),
    );
  }, [deliveryWindowsIdsWithCurrentStockType, dispatch, orderDeliveryWindowsIds, productId]);

  const removeProducts = React.useCallback(() => {
    if (!hasUnits) {
      removeProductsAction();

      return;
    }

    const confirmationData =
      isAllProductsPage && isDefined(stockType) ?
        t('confirmationConfig:remove_product_from_order_by_stock_type', { returnObjects: true, stockName: stockTypeLabels[stockType] })
      : t('confirmationConfig:remove_product_from_order', { returnObjects: true });

    confirmationGuard(() => ({
      ...confirmationData,
      okText: t('common:remove'),
      onOk: removeProductsAction,
    }))();
  }, [confirmationGuard, hasUnits, isAllProductsPage, removeProductsAction, stockType, stockTypeLabels, t]);

  const toggleInOrder = React.useCallback(() => {
    if (isProductLoading) {
      return;
    }

    if (isInOrder) {
      removeProducts();

      return;
    }

    dispatch(
      addProductsToOrder({
        deliveryWindows: intersection(buyerDeliveryWindowsIds, deliveryWindowsIdsWithCurrentStockType),
        products: [productId],
      }),
    );
  }, [isProductLoading, isInOrder, dispatch, buyerDeliveryWindowsIds, productId, removeProducts, deliveryWindowsIdsWithCurrentStockType]);

  const isAdding = isProductLoading && !isInOrder;
  const isRemoving = isProductLoading && isInOrder;

  return {
    hasUnits,
    isAdding,
    isInOrder,
    isLoading: isProductLoading,
    isRemoving,
    toggleInOrder,
  };
};
