import React from 'react';

import { useIsInViewMode } from '../../../utils/hooks';

interface Options<T> {
  editorComponent: React.ComponentType<T>;
  standardComponent: React.ComponentType<T>;
}

export const editorComponentSwitch =
  <T extends Record<string, Unrestricted>>(options: Options<T>): React.ComponentType<T> =>
  (props: T) => {
    const isEditorView = useIsInViewMode('editor');
    const Component = isEditorView ? options.editorComponent : options.standardComponent;

    return <Component {...props} />;
  };
