import cx from 'classnames';
import React from 'react';

import styles from './Button.module.scss';

interface ButtonGroupProps<T extends React.ElementType> {
  as?: T;
  variant?: 'shadow';
}

type Props<T extends React.ElementType> = ButtonGroupProps<T> & Omit<React.ComponentPropsWithoutRef<T>, keyof ButtonGroupProps<T>>;

export const ButtonGroup = <T extends React.ElementType = 'div'>(props: Props<T>) => {
  const { as: Component = 'div', children, className, isFluid, variant, ...rest } = props;

  const classNames = cx(styles.group, className, {
    [styles.groupShadow]: variant === 'shadow',
  });

  const buttonProps = Component === 'button' ? { type: 'button' as const } : {};

  return (
    <Component className={classNames} {...buttonProps} {...rest}>
      {children}
    </Component>
  );
};
