import { Product } from '@typings';
import React from 'react';
import { useSelector } from 'react-redux';

import { getCountries, getProductListingAttributes } from '../../../ducks';
import { applyCountryNameToAttribute, getCustomAttributesWithValue } from '../../../logic/products';
import { useOverflowingDistance } from '../../../utils/hooks';
import { isEmpty } from '../../../utils/isEmpty';
import { TextEllipsis } from '../../various/TextEllipsis';

import { useProductContext } from './context/ProductContext';
import styles from './Product.module.scss';

const MAX_NUMBER_OF_ATTRIBUTES = 3;
const BOTTOM_MARGIN = 15;

const Attribute = ({ name, value }: { name: string; value: string }) => (
  <div className={styles.attributeLine}>
    <TextEllipsis className={styles.attributeKey}>{name}:</TextEllipsis>
    <TextEllipsis className={styles.attributeValue}>{value}</TextEllipsis>
  </div>
);

interface Props {
  variant: Product.Standard;
  shouldUpdateContainerBottomOffset: boolean;
}

export const ProductAttributes = ({ variant, shouldUpdateContainerBottomOffset }: Props) => {
  const overflowingAttributesRef = React.useRef<HTMLDivElement>(null);
  const { activeSlideIndex, containerRef, setContainerBottomOffset } = useProductContext();

  const countries = useSelector(getCountries);
  const listingAttributes = useSelector(getProductListingAttributes);

  const [visibleAttributes, overflowingAttributes] = React.useMemo(() => {
    const filteredAttributes = applyCountryNameToAttribute(getCustomAttributesWithValue(listingAttributes, variant), countries).filter(
      ({ value }) => !isEmpty(value),
    );

    return [filteredAttributes.slice(0, MAX_NUMBER_OF_ATTRIBUTES), filteredAttributes.slice(MAX_NUMBER_OF_ATTRIBUTES)];
  }, [variant, countries, listingAttributes]);

  const isOverflowing = overflowingAttributes.length > 0;

  const overflowingDistance = useOverflowingDistance(
    {
      childRef: overflowingAttributesRef,
      direction: 'bottom',
      isDisabled: !isOverflowing || !shouldUpdateContainerBottomOffset,
      wrapperRef: containerRef,
    },
    [activeSlideIndex],
  );

  React.useEffect(() => {
    if (!shouldUpdateContainerBottomOffset) {
      return;
    }

    const bottomMarginValue = overflowingDistance > 0 ? BOTTOM_MARGIN : 0;

    setContainerBottomOffset(overflowingDistance + bottomMarginValue);
  }, [overflowingDistance, isOverflowing, shouldUpdateContainerBottomOffset]);

  return (
    <>
      <div className={styles.attributesWrapper}>
        {visibleAttributes.map(({ name, value, key }) => (
          <Attribute key={key} name={name} value={value} />
        ))}
        <div className={styles.overflowingAttributes} ref={overflowingAttributesRef}>
          {overflowingAttributes.map(({ name, value, key }) => (
            <Attribute key={key} name={name} value={value} />
          ))}
        </div>
      </div>
    </>
  );
};
