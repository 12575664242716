import { Video } from '@typings';
import React from 'react';

import { fetchThumbnailUrl } from '../../services/media';
import { isDefined } from '../is';

interface Props {
  videos: Video[];
}

export const useVideoThumbnails = ({ videos }: Props) => {
  const [videoThumbnails, setVideoThumbnails] = React.useState<Record<string, string>>({});

  React.useEffect(() => {
    const fetchThumbnails = async () => {
      const data = await Promise.all(
        videos.map(async ({ src }) => {
          if (!isDefined(src)) {
            return {};
          }

          const thumbnail = await fetchThumbnailUrl(src);

          return { [src]: thumbnail };
        }),
      );

      const thumbnails = data.reduce((acc, thumbnail) => ({ ...acc, ...thumbnail }), {});
      setVideoThumbnails(thumbnails);
    };

    fetchThumbnails();
  }, []);

  return { videoThumbnails };
};
