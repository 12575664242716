import { Id, Matrices } from '@typings';
import React from 'react';
import { useSelector } from 'react-redux';

import { useMatrixDeliveryWindowContext, useMatrixVariantsContext } from '../../../components/products/ProductMatrix/context';
import { getOptimisticUpdatesMultipleProducts, getOrderDetails } from '../../../ducks';
import { getMultivariantRelatedProductIds } from '../../../logic/products';
import { isDefined } from '../../is';

export const usePrepacksQuantities = () => {
  const { deliveryWindowId } = useMatrixDeliveryWindowContext();
  const { variants } = useMatrixVariantsContext();

  const orderDetails = useSelector(getOrderDetails);

  const [firstVariant] = variants;
  const allProductIds = [firstVariant?.product, ...getMultivariantRelatedProductIds(firstVariant)].filter(isDefined);
  const optimisticUpdates = useSelector(getOptimisticUpdatesMultipleProducts(allProductIds));

  const getPrepacksQuantitiesInVariant = React.useCallback(
    (variantId: Id) => {
      return orderDetails.order.products
        .filter(product => product.variant === variantId && product.deliveryWindow === deliveryWindowId)
        .flatMap(product => product.prepacks)
        .reduce((prepackAcc, prepack) => {
          if (!isDefined(prepack)) {
            return prepackAcc;
          }
          const prepackKey = `prepack-${deliveryWindowId}-${variantId}-${prepack.prepack}`;
          const optimisticQuantity = optimisticUpdates[prepackKey];

          return {
            ...prepackAcc,
            [prepack.prepack]: optimisticQuantity ?? prepack.quantity,
          };
        }, {});
    },
    [deliveryWindowId, optimisticUpdates, orderDetails.order.products],
  );

  return React.useMemo(
    (): Matrices.PrepackQuantities =>
      variants.reduce((acc, { variant }) => {
        return {
          ...acc,
          [variant]: getPrepacksQuantitiesInVariant(variant),
        };
      }, {}),
    [getPrepacksQuantitiesInVariant, variants],
  );
};
