import { Addresses } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { SHIPPING_ADDRESS_DEFAULT_VALUES, SHIPPING_ADDRESS_FIELDS } from '../../../../constants/address';
import {
  getIsAccountEditingEnabled,
  getIsSeller,
  getIsShippingAddressAvailableInCheckout,
  getShippingAddressFields,
} from '../../../../ducks';
import { useShippingCountries } from '../../../../services/hooks/account/useShippingCountries';
import { useShippingCountryOptions } from '../../../../utils/hooks/countries/useShippingCountryOptions';
import { useValidatedForm } from '../../../../utils/hooks/useValidatedForm';
import { isDefined } from '../../../../utils/is';
import { isEmpty } from '../../../../utils/isEmpty';
import { getPhoneSchema, getStateSchema } from '../../../../utils/schemas';
import { Option } from '../../../various/Fields/Select';
import { AutocompleteField, CheckboxField, Form, FormAlert, FormButtons, FormFieldset, FormGrid, InputField } from '../../../various/Form';
import { Button } from '../../../various/NewButton';

interface FormData extends Addresses.ShippingAddressDTO {
  addAddressToAccount: boolean;
}

interface Props {
  defaultCountry?: Nullable<string>;
  defaultState?: Nullable<string>;
  isLoading: boolean;
  onCancel: () => void;
  onSubmit: (values: FormData) => void;
}

export const AddAddressForm = ({ defaultCountry, defaultState, isLoading, onCancel, onSubmit }: Props) => {
  const { t } = useTranslation(['common', 'shipping', 'validation']);
  const isSeller = useSelector(getIsSeller);
  const isAccountEditingEnabled = useSelector(getIsAccountEditingEnabled);
  const shippingAddressFields = useSelector(getShippingAddressFields);
  const isShippingAddressAvailable = useSelector(getIsShippingAddressAvailableInCheckout);

  const { data: shippingCountries, isLoading: isLoadingCountries } = useShippingCountries();

  const defaultValues = {
    ...SHIPPING_ADDRESS_DEFAULT_VALUES,
    addAddressToAccount: false,
    country: defaultCountry ?? null,
    state: defaultState ?? null,
  };

  const requiredFieldsMap = React.useMemo(() => {
    return SHIPPING_ADDRESS_FIELDS.reduce<Record<string, boolean>>(
      (acc, key) => ({
        ...acc,
        [key]: shippingAddressFields?.[key].required ?? false,
      }),
      {},
    );
  }, [shippingAddressFields]);

  const getRequiredSchema = (schema: yup.StringSchema, key: string, message: string) => {
    return requiredFieldsMap[key] ? schema.required(message) : schema;
  };

  const validationSchema: yup.ObjectSchema<Partial<FormData>> = yup.object({
    addAddressToAccount: yup.bool().required(),
    address1: getRequiredSchema(yup.string(), 'address1', t('validation:address_hint')),
    address2: getRequiredSchema(yup.string(), 'address2', t('validation:address_hint')),
    city: yup.string().required(t('validation:city_hint')),
    company: getRequiredSchema(yup.string(), 'company', t('validation:company_name_hint')),
    contactPerson: getRequiredSchema(yup.string(), 'contactPerson', t('validation:contact_person_hint')),
    country: yup.string().required(t('validation:country_hint')).nullable(),
    email: getRequiredSchema(yup.string(), 'email', t('validation:email_address_hint')).email(t('validation:invalid_email_hint')),
    phoneNumber: getPhoneSchema(requiredFieldsMap.phoneNumber),
    state: getStateSchema(shippingCountries?.countries ?? {}),
    zipCode: yup.string().required(t('validation:zip_code_hint')),
  });

  const formMethods = useValidatedForm<FormData>({ defaultValues, validationSchema });
  const { register, setValue, watch } = formMethods;
  const selectedCountry = watch('country');

  const handleChangeCountry = () => {
    setValue('state', null);
  };

  const { countryOptions, stateOptions } = useShippingCountryOptions({ country: selectedCountry });

  const differentCountryMessage =
    isDefined(selectedCountry) && selectedCountry !== defaultCountry ? t('shipping:change_shipping_country') : undefined;

  return (
    <Form formMethods={formMethods} onSubmit={onSubmit}>
      <FormFieldset isDisabled={isLoading}>
        <FormGrid>
          <FormGrid cols={2}>
            <InputField label={t('common:company_name')} isRequired={requiredFieldsMap.company} {...register('company')} />
            <InputField label={t('common:contact_person')} isRequired={requiredFieldsMap.contactPerson} {...register('contactPerson')} />
            <InputField label={t('common:email')} type="email" isRequired={requiredFieldsMap.email} {...register('email')} />
            <InputField
              label={t('common:phone_number')}
              type="tel"
              isRequired={requiredFieldsMap.phoneNumber}
              {...register('phoneNumber')}
            />
            <InputField label={t('common:address')} isRequired={requiredFieldsMap.address1} {...register('address1')} />
            <InputField label={t('common:address_continued')} isRequired={requiredFieldsMap.address2} {...register('address2')} />
            <InputField label={t('common:city')} isRequired {...register('city')} />
            <InputField label={t('common:zip_code')} isRequired {...register('zipCode')} />
            <AutocompleteField name="country" label={t('common:country')} isRequired onChange={handleChangeCountry}>
              {countryOptions.map(({ country, name }) => (
                <Option key={country} value={country}>
                  {name}
                </Option>
              ))}
            </AutocompleteField>
            {!isEmpty(stateOptions) && (
              <AutocompleteField name="state" label={t('common:state')} isLoading={isLoadingCountries} isRequired>
                {stateOptions.map(({ state, name }) => (
                  <Option key={state} value={state}>
                    {name}
                  </Option>
                ))}
              </AutocompleteField>
            )}
          </FormGrid>
          {isSeller && isAccountEditingEnabled && (
            <CheckboxField
              label={t('common:add_address_to_account')}
              disabled={!isShippingAddressAvailable}
              {...register('addAddressToAccount')}
            />
          )}
        </FormGrid>
        {isDefined(differentCountryMessage) && <FormAlert type="warning" message={differentCountryMessage} />}
        <FormButtons showDivider>
          <Button size="large" variant="ghost" color="dark" onClick={onCancel}>
            {t('common:cancel')}
          </Button>
          <Button type="submit" size="large" isLoading={isLoading}>
            {t('shipping:add')}
          </Button>
        </FormButtons>
      </FormFieldset>
    </Form>
  );
};
