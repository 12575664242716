import { Cms, Id, Product, Translations } from '@typings';
import { createAction } from 'typesafe-actions';

export const fetchPageBySlugRequest = createAction('pages/FETCH_PAGE_BY_SLUG_REQUEST')<string>();

export const fetchPageBySlugSuccess = createAction('pages/FETCH_PAGE_BY_SLUG_SUCCESS')<{
  pageData: Cms.BasePageData;
  products: Record<Product.Id, Product.Full>;
}>();

export const fetchEditorPageDataRequest = createAction('pages/FETCH_EDITOR_PAGE_DATA_REQUEST')<Id>();
export const fetchEditorPageDataSuccess = createAction('pages/FETCH_EDITOR_PAGE_DATA_SUCCESS')<{ data: Cms.FullPageData }>();
export const fetchEditorPageDataFailure = createAction('pages/FETCH_EDITOR_PAGE_DATA_FAILURE')();
export const refreshPageDataRequest = createAction('pages/REFRESH_PAGE_DATA')();
export const refreshPageDataSuccess = createAction('pages/REFRESH_PAGE_DATA_SUCCESS')<Cms.FullPageData>();

export const savePageRequest = createAction('pages/SAVE_PAGE_REQUEST')<Translations.PageIdWithLanguage>();
export const savePageSuccess = createAction('pages/SAVE_PAGE_SUCCESS')();
export const savePageFailure = createAction('pages/SAVE_PAGE_FAILURE')();

export const editPageSuccess = createAction('pages/EDIT_PAGE_SUCCESS')<{
  data: Cms.Page;
}>();

export const discardChangesRequest = createAction('pages/DISCARD_CHANGES_REQUEST')<{
  language: Translations.SupportedLanguagesCodes;
  id: Id;
}>();

export const publishPageRequest = createAction('pages/PUBLISH_PAGE')<{
  languages: Translations.SupportedLanguagesCodes[];
  id: Id;
}>();
export const unpublishPageRequest = createAction('pages/UNPUBLISH_PAGE_REQUEST')<{
  languages: Translations.SupportedLanguagesCodes[];
  id: Id;
}>();
export const publishPageChangeSuccess = createAction('pages/PUBLISH_PAGE_SUCCESS')();
export const publishPageChangeFailure = createAction('page/PUBLISH_PAGE_FAILURE')();
