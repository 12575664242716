import { Modals, Order } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { showModal } from '../../../../ducks';
import { getIsOrderEditable } from '../../../../logic/Orders';
import { useDeleteSelections } from '../../../../services/hooks/orders/useDeleteSelections';
import { useAmplitude } from '../../../../utils/hooks/useAmplitude';
import { useConfirmationGuard } from '../../../../utils/hooks/useConfirmationGuard';
import { PopoverMenu } from '../../../various/PopoverMenu';

interface Props {
  order: Order.Essentials;
  enableOrderDeletion?: boolean;
  setOrder: React.Dispatch<React.SetStateAction<Nullable<Order.Essentials>>>;
}

export const OrderActionsColumn = ({ order, enableOrderDeletion = false, setOrder }: Props) => {
  const { t } = useTranslation(['confirmationConfig', 'common', 'selections']);
  const dispatch = useDispatch();
  const confirmationGuard = useConfirmationGuard();
  const { deleteSelectionByIds } = useDeleteSelections();
  const { trackEvent } = useAmplitude();

  const handleDeleteOrder = confirmationGuard(() => ({
    onOk: () => deleteSelectionByIds.mutate([order.order]),
    ...t('confirmationConfig:delete_order', { returnObjects: true }),
  }));

  const openModal = React.useCallback(
    (selectedOrder: Order.Essentials, modal: Modals.Type) => {
      setOrder(selectedOrder);
      dispatch(showModal(modal));
    },
    [dispatch, setOrder],
  );

  const deleteOption = React.useMemo(() => {
    if (!enableOrderDeletion) {
      return [];
    }

    return [
      {
        key: 'deleteSelection',
        label: t('selections:delete_this_selection'),
        onClick: handleDeleteOrder,
      },
    ];
  }, [enableOrderDeletion, handleDeleteOrder, t]);

  const handleCopyToNewSelection = React.useCallback(() => openModal(order, 'CopyToNewSelectionModal'), [openModal, order]);

  const handleDownloadLinesheet = React.useCallback(() => {
    const isOrderEditable = getIsOrderEditable(order.status);

    trackEvent({ name: isOrderEditable ? 'selectionsTable.link.downloadLinesheet.click' : 'ordersTable.link.downloadLinesheet.click' });
    openModal(order, 'DownloadLinesheetModal');
  }, [openModal, order, trackEvent]);

  const popoverMenuItems = React.useMemo(() => {
    return [
      {
        key: 'copyToNewSelection',
        label: t('selections:copy_to_new_selection'),
        onClick: handleCopyToNewSelection,
      },
      {
        key: 'downloadLinesheet',
        label: t('common:download_linesheet'),
        onClick: handleDownloadLinesheet,
      },
      ...deleteOption,
    ];
  }, [deleteOption, t, handleCopyToNewSelection, handleDownloadLinesheet]);

  return <PopoverMenu items={popoverMenuItems} name="Selection options" overflowContainer />;
};
