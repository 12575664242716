import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { UNSUPPORTED_BROWSER_EXPIRED_DATE_KEY } from '../../../constants/localStorage';
import { getIsUnsupportedBrowser } from '../../../utils/getIsUnsupportedBrowser';
import { useLocalStorage } from '../../../utils/hooks/useLocalStorage';
import { isDefined } from '../../../utils/is';
import { addToast, dismissToast } from '../Toasts';

import styles from './Message.module.scss';

const NOTIFICATION_KEY = 'unsupportedBrowserNotification';
const UNSUPPORTED_BROWSER_EXPIRED_DATE = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);

export const NotificationsDescription = () => {
  const { t } = useTranslation(['common', 'genericErrors']);

  return (
    <Trans
      t={t}
      i18nKey="genericErrors:unsupported_browser_hint"
      defaults="We're sorry but your browser is not fully supported. To improve your Showroom experience, please try using a different browser such as <chrome/>, <firefox/>, or <safari/>."
      components={{
        chrome: (
          <a className={styles.link} href="https://www.google.com/chrome/">
            Google Chrome
          </a>
        ),
        firefox: (
          <a className={styles.link} href="https://www.mozilla.org/en-GB/firefox/">
            Firefox
          </a>
        ),
        safari: (
          <a className={styles.link} href="https://www.apple.com/safari/">
            Safari
          </a>
        ),
      }}
    />
  );
};

export const UnsupportedBrowserNotifications = () => {
  const { t } = useTranslation(['common', 'genericErrors']);

  const [lastHiddenAt, setLastHiddenAt] = useLocalStorage<Nullable<string>>({
    defaultValue: null,
    key: UNSUPPORTED_BROWSER_EXPIRED_DATE_KEY,
  });

  const isDisplayingBrowserNotification = React.useMemo(() => {
    if (!isDefined(lastHiddenAt)) {
      return getIsUnsupportedBrowser();
    }

    const lastHiddenAtTime = new Date(parseInt(lastHiddenAt, 10)).getTime();
    const expiredDateTime = UNSUPPORTED_BROWSER_EXPIRED_DATE.getTime();
    const isExpired = isNaN(lastHiddenAtTime) || lastHiddenAtTime < expiredDateTime;

    return getIsUnsupportedBrowser() && isExpired;
  }, [lastHiddenAt]);

  const closeNotification = React.useCallback(() => {
    dismissToast(NOTIFICATION_KEY);
    setLastHiddenAt(Date.now().toString());
  }, [setLastHiddenAt]);

  React.useEffect(() => {
    if (!isDisplayingBrowserNotification) {
      closeNotification();

      return;
    }

    addToast(t('genericErrors:unsupported_browser'), {
      description: <NotificationsDescription />,
      duration: 0,
      id: NOTIFICATION_KEY,
      onClose: closeNotification,
    });
  }, [isDisplayingBrowserNotification]);

  return null;
};
