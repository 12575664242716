import { Cms } from '@typings';
import { useTranslation } from 'react-i18next';

import { compiledPaths } from '../../../../paths';
import { Link } from '../../../various/Link';
import { PreviouslyTableCell } from '../PreviouslyTableCell/PreviouslyTableCell';

interface Props {
  slug: string;
  row: Cms.Page;
}

export const SlugCell = ({ slug, row }: Props) => {
  const { t } = useTranslation(['cms']);

  return (
    <>
      <Link variant="blue" title={t('cms:page_preview')} to={compiledPaths.CMS_PREVIEW({ pageId: row.id })}>
        {slug}
      </Link>
      <PreviouslyTableCell property="slug" page={row} />
    </>
  );
};
