import { getSafeInnerHTML } from './getSafeInnerHtml';
import { isDefined } from './is';

interface ReplaceWithComponentOptions {
  sourceHtml: string;
  splitRegExp: RegExp;
  matchRegExp: RegExp;
  renderFunction: (matches: string[]) => JSX.Element;
}

export const replaceHtmlWithComponent = ({ sourceHtml, splitRegExp, matchRegExp, renderFunction }: ReplaceWithComponentOptions) => {
  const elements = sourceHtml.split(splitRegExp);

  return elements.reduce((acc: JSX.Element[], cur, idx) => {
    const matched = matchRegExp.exec(cur);
    if (!isDefined(cur)) {
      return acc;
    }

    if (matched?.includes(cur)) {
      return [...acc, renderFunction(matched)];
    }

    return [...acc, <span key={idx} dangerouslySetInnerHTML={getSafeInnerHTML(cur)} />];
  }, []);
};
