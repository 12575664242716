import { Invoice } from '@typings';

import { showroomApi } from '../services/api';

export const getInvoiceDetails = async (params: Invoice.InvoiceIdentifiers) =>
  showroomApi.QUERY_GET('/invoice/:invoiceId/:invoiceHash')({
    pathParams: { ...params },
  });

export const getInvoicesList = async (data: Invoice.FilterData) => {
  return showroomApi.QUERY_POST('/invoices')({ data });
};
