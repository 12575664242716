import { CmsGroups } from '@typings';
import React from 'react';
import { useSelector } from 'react-redux';

import { getIsCurrentGroup, getIsManualLayoutEditorEnabledInGroup } from '../../../ducks/cms/ui';
import { getResizableBlocksIds } from '../../../logic/cms/structuralBlocks';
import { useIsInViewMode } from '../../../utils/hooks';
import { isDefined } from '../../../utils/is';
import { EditorUIContext } from '../../cms/context/EditorUIContext';
import { GroupResizeContextProvider } from '../../cms/context/GroupResizerContext';
import { SelectorsContext } from '../../cms/context/SelectorsContext';
import { GenericBlock } from '../../pages/genericBlock';
import { EditorLanguageContext } from '../context/EditorLanguageContext';
import { ManualLayoutEditorContextProvider } from '../context/ManualLayoutEditorContext';
import { GroupButtons } from '../GroupsButtons';
import { ManualLayoutEditor } from '../ManualLayoutEditor';

import styles from './BlockGroup.module.scss';
import { GroupContextProvider } from './GroupContext';
import { GroupGrid } from './GroupGrid';

export const createGroup = (Tools?: React.ComponentType<CmsGroups.GroupProps>) => (props: CmsGroups.GroupProps) => {
  const { getGroupById, getBlocksByIds } = React.useContext(SelectorsContext);
  const { screenType } = React.useContext(EditorUIContext);
  const { isDefaultLanguage } = React.useContext(EditorLanguageContext);
  const group = useSelector(getGroupById(props.groupId));
  const groupBlocks = React.useMemo(() => group?.blocks ?? [], [group?.blocks]);
  const blocksSelector = React.useMemo(() => getBlocksByIds(groupBlocks), [groupBlocks]);
  const blocks = useSelector(blocksSelector);
  const resizableBlocksIds = React.useMemo(() => getResizableBlocksIds(blocks, screenType), [blocks, screenType]);
  const isManualLayoutChange = useSelector(getIsManualLayoutEditorEnabledInGroup(props.groupId));
  const isCurrentGroup = useSelector(getIsCurrentGroup(props.groupId));
  const isManualLayoutChangeActive = isManualLayoutChange && isCurrentGroup;
  const isInEditor = useIsInViewMode('editor');
  const shouldShowButtons = isInEditor && isDefaultLanguage;

  return (
    <div className={styles.groupWrapper}>
      {shouldShowButtons && props.groupIndex === 0 && <GroupButtons sectionId={props.sectionId} groupIndex={0} />}
      <GroupContextProvider groupId={props.groupId} sectionId={props.sectionId}>
        <GroupResizeContextProvider blocks={blocks} resizableBlocksIds={resizableBlocksIds}>
          <GroupGrid
            blocks={blocks}
            groupId={props.groupId}
            isInEditor={isInEditor}
            isManualLayoutChangeActive={isManualLayoutChangeActive}
            screenType={screenType}
          >
            <ManualLayoutEditorContextProvider>
              {isManualLayoutChangeActive && <ManualLayoutEditor />}
              {isDefined(Tools) && <Tools {...props} />}
              {groupBlocks.map(blockId => (
                <GenericBlock key={blockId} blockId={blockId} />
              ))}
            </ManualLayoutEditorContextProvider>
          </GroupGrid>
          {shouldShowButtons && (
            <GroupButtons
              sectionId={props.sectionId}
              groupId={props.groupId}
              groupIndex={props.groupIndex + 1}
              resizableBlocksIds={resizableBlocksIds}
            />
          )}
        </GroupResizeContextProvider>
      </GroupContextProvider>
    </div>
  );
};
