import { Invoice } from '@typings';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { requestInvoicePaymentResultLongPolling } from '../../../ducks';
import { isDefined } from '../../../utils/is';
import { ProcessingPayment } from '../../various/ProcessingPayment';

export const ProcessingPaymentPage = () => {
  const { invoiceId, invoiceHash } = useParams<Invoice.InvoiceIdentifiers>();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (isDefined(invoiceId) && isDefined(invoiceHash)) {
      dispatch(requestInvoicePaymentResultLongPolling({ invoiceHash, invoiceId }));
    }
  }, [dispatch, invoiceHash, invoiceId]);

  return <ProcessingPayment />;
};
