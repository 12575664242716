import { nanoid } from 'nanoid';
import { mapObjIndexed } from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EditorUIContext } from '../../../components/cms/context/EditorUIContext';
import { getAvailablePageLanguageCodes, getCmsBlocks, getCmsGroups, getCmsSections, pasteSection, removeSection } from '../../../ducks';
import { getAllCmsLocalizations } from '../../../ducks/cms/localizations';
import { getBlocksWithNewPartsIdsAndPartsDictionary, getCopiedLocalizations, mapToNewIds } from '../../../logic/cms/copyPaste';
import { isDefined } from '../../is';
import { listToRecord } from '../../normalize';

import { useCmsClipboard } from './useCmsClipboard';

export const useSectionCopyPaste = () => {
  const dispatch = useDispatch();

  const allSections = useSelector(getCmsSections);
  const allGroups = useSelector(getCmsGroups);
  const allBlocks = useSelector(getCmsBlocks);
  const allLocalizations = useSelector(getAllCmsLocalizations);
  const pageLanguages = useSelector(getAvailablePageLanguageCodes);

  const { screenType } = React.useContext(EditorUIContext);
  const { cmsClipboard, hasSectionInClipboard, setCmsClipboard } = useCmsClipboard();

  const copySection = (sectionId: string) => {
    const section = allSections.find(({ id }) => id === sectionId);

    if (!isDefined(section)) {
      return;
    }

    const groups = section.groupsOrder.map(groupId => allGroups[groupId]).filter(isDefined);
    const blocks = groups.flatMap(group => group.blocks.map(blockId => allBlocks[blockId])).filter(isDefined);
    const localizations = mapObjIndexed(
      translations => ({
        blocks: Object.entries(translations.blocks).reduce(
          (acc, [blockId, block]) => ({
            ...acc,
            ...(groups.some(group => group?.blocks.includes(blockId)) ? { [blockId]: block } : {}),
          }),
          {},
        ),
      }),
      allLocalizations,
    );

    const sectionNode = document.getElementById(sectionId);

    const preview = {
      content: {
        __html: sectionNode?.outerHTML ?? '',
      },
      dimensions: {
        height: sectionNode?.offsetHeight ?? 0,
        width: sectionNode?.offsetWidth ?? 0,
      },
      screenType,
    };

    setCmsClipboard({
      blocks,
      groups,
      localizations,
      preview,
      section,
    });
  };

  const cutSection = (sectionId: string) => {
    copySection(sectionId);
    dispatch(removeSection(sectionId));
  };

  const pasteCopiedSection = (insertAt: number) => {
    if (!isDefined(cmsClipboard)) {
      return;
    }

    const { section: copiedSection, groups: copiedGroups, blocks: copiedBlocks, localizations: copiedBlocksLocalizations } = cmsClipboard;

    if (!isDefined(copiedSection)) {
      return;
    }

    const [groupsWithNewIds, groupDictionary] = mapToNewIds(copiedGroups);
    const [blocksWithNewIds, blocksDictionary] = mapToNewIds(copiedBlocks);
    const [blocksWithNewPartIds, partsDictionary] = getBlocksWithNewPartsIdsAndPartsDictionary(blocksWithNewIds);

    const blocks = blocksWithNewPartIds.map(block => ({
      ...block,
      groupId: isDefined(block.groupId) ? groupDictionary[block.groupId] : undefined,
    }));

    const groups = groupsWithNewIds.map(group => ({ ...group, blocks: group.blocks.map(id => blocksDictionary[id]).filter(isDefined) }));

    const section = {
      ...copiedSection,
      groupsOrder: groups.map(({ id }) => id),
      id: nanoid(),
    };

    const localizations = getCopiedLocalizations({
      blocks: copiedBlocks,
      blocksDictionary,
      languages: pageLanguages,
      localizations: copiedBlocksLocalizations,
      partsDictionary,
    });

    dispatch(
      pasteSection({
        blocks: listToRecord(blocks, 'id'),
        groups: listToRecord(groups, 'id'),
        insertAt,
        localizations,
        section,
      }),
    );
  };

  return {
    cmsClipboard,
    copySection,
    cutSection,
    hasSectionInClipboard,
    pasteCopiedSection,
    setCmsClipboard,
  };
};
