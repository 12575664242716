import { ActionHandler, Cms } from '@typings';
import { assocPath, insert, move } from 'ramda';
import { createReducer } from 'typesafe-actions';

import { createEmptySection } from '../../../logic/pages';
import { isDefined } from '../../../utils/is';
import { fetchEditorPageDataSuccess, refreshPageDataSuccess } from '../../pages';
import { resetCms, setPreviewData } from '../general';
import { groupRemoved, pasteGroup } from '../groups/actions';

import {
  addSection,
  groupCreated,
  moveGroup,
  moveSection,
  pasteSection,
  reorderGroups,
  sectionRemoved,
  updateAllSectionsMarkets,
  updateSectionVisibility,
} from './actions';

type State = Cms.Section[];

const initialState: State = [];

const handleResetSections: ActionHandler<State, typeof resetCms> = () => initialState;

const handleFetchEditorPageSuccess: ActionHandler<State, typeof fetchEditorPageDataSuccess> = (_, action) => action.payload.data.sections;

const handleSetPreviewData: ActionHandler<State, typeof setPreviewData> = (_, action) => action.payload.data.sections;

const handleAddSection: ActionHandler<State, typeof addSection> = (state, action) =>
  insert(action.payload.insertAt, createEmptySection(action.payload.markets), state);

const handleGroupCreated: ActionHandler<State, typeof groupCreated> = (state, action) => {
  const { sectionId, blocksGroup, insertAfter } = action.payload;
  const currentSection = state.find(section => section.id === sectionId);
  const groupId = blocksGroup[0]?.groupId;
  const groupIndex = isDefined(insertAfter) ? currentSection?.groupsOrder.indexOf(insertAfter) ?? -1 : -1;
  const insertAt = groupIndex + 1;

  if (!isDefined(currentSection) || !isDefined(groupId)) {
    return state;
  }

  const groupsOrder = insert(insertAt, groupId, currentSection.groupsOrder);

  return assocPath([state.indexOf(currentSection), 'groupsOrder'], groupsOrder, state);
};

const handleGroupRemoved: ActionHandler<State, typeof groupRemoved> = (state, action) => {
  const { sectionId, groupId } = action.payload;
  const currentSection = state.find(section => section.id === sectionId);

  if (!isDefined(currentSection)) {
    return state;
  }

  return assocPath(
    [state.indexOf(currentSection), 'groupsOrder'],
    currentSection.groupsOrder.filter(id => id !== groupId),
    state,
  );
};

const handleSectionRemoved: ActionHandler<State, typeof sectionRemoved> = (state, action) =>
  state.filter(section => section.id !== action.payload.sectionId);

const handleUpdateSectionVisibility: ActionHandler<State, typeof updateSectionVisibility> = (state, action) => {
  const { visibility, sectionId } = action.payload;

  return state.map((section: Cms.Section) => {
    if (section.id === sectionId) {
      return assocPath(['visibleFor'], visibility, section);
    }

    return section;
  });
};

const handleMoveSection: ActionHandler<State, typeof moveSection> = (state, action) => {
  const { offset, sectionId } = action.payload;
  const startIndex = state.findIndex(section => section.id === sectionId);

  return move(startIndex, startIndex + offset, state);
};

const handleMoveGroup: ActionHandler<State, typeof moveGroup> = (state, action) => {
  const { groupId, offset, sectionId } = action.payload;

  return state.map(section => {
    if (section.id === sectionId) {
      const startIndex = section.groupsOrder.findIndex(id => id === groupId);

      return {
        ...section,
        groupsOrder: move(startIndex, startIndex + offset, section.groupsOrder),
      };
    }

    return section;
  });
};

const handleReorderGroups: ActionHandler<State, typeof reorderGroups> = (state, action) =>
  state.map(section => (section.id === action.payload.sectionId ? { ...section, groupsOrder: action.payload.groupsOrder } : section));

const handlePasteSection: ActionHandler<State, typeof pasteSection> = (state, action) => {
  const { section, insertAt } = action.payload;

  if (!isDefined(section)) {
    return state;
  }

  return insert(insertAt, section, state);
};

const handlePasteGroup: ActionHandler<State, typeof pasteGroup> = (state, action) => {
  const { group, insertAt } = action.payload;

  if ('markets' in action.payload) {
    return insert(
      insertAt,
      {
        ...createEmptySection(action.payload.markets),
        groupsOrder: [group.id],
      },
      state,
    );
  }

  const { sectionId } = action.payload;

  return state.map(section => {
    if (section.id !== sectionId) {
      return section;
    }

    return {
      ...section,
      groupsOrder: insert(insertAt, group.id, section.groupsOrder),
    };
  });
};

const handleRefreshPageDataSuccess: ActionHandler<State, typeof refreshPageDataSuccess> = (_, action) => action.payload.sections;

const handleUpdateAllSectionsMarkets: ActionHandler<State, typeof updateAllSectionsMarkets> = (state, action) => {
  const { pageMarketIds } = action.payload;

  return state.map((section: Cms.Section) => {
    const sectionMarketsIds =
      !isDefined(section.visibleFor.marketIds) ? pageMarketIds : (
        section.visibleFor.marketIds.filter(market => {
          return pageMarketIds.find(value => market === value);
        })
      );

    return assocPath(['visibleFor', 'marketIds'], sectionMarketsIds, section);
  });
};

export default createReducer<State, AppAction>(initialState)
  .handleAction(fetchEditorPageDataSuccess, handleFetchEditorPageSuccess)
  .handleAction(setPreviewData, handleSetPreviewData)
  .handleAction(addSection, handleAddSection)
  .handleAction(groupCreated, handleGroupCreated)
  .handleAction(groupRemoved, handleGroupRemoved)
  .handleAction(sectionRemoved, handleSectionRemoved)
  .handleAction(updateSectionVisibility, handleUpdateSectionVisibility)
  .handleAction(moveSection, handleMoveSection)
  .handleAction(moveGroup, handleMoveGroup)
  .handleAction(reorderGroups, handleReorderGroups)
  .handleAction(refreshPageDataSuccess, handleRefreshPageDataSuccess)
  .handleAction(updateAllSectionsMarkets, handleUpdateAllSectionsMarkets)
  .handleAction(resetCms, handleResetSections)
  .handleAction(pasteGroup, handlePasteGroup)
  .handleAction(pasteSection, handlePasteSection);
