import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrentCustomTemplateId, setCurrentCustomTemplateId } from '../../../ducks';
import { useModalVisibility } from '../../../utils/hooks';
import { useCustomTemplates } from '../../../utils/hooks/cms/useCustomTemplates';
import { ModalShell } from '../../various/ModalShell';

import { CustomTemplateEditor } from './CustomTemplateEditor';

export const CustomTemplateEditorModal = () => {
  const { t } = useTranslation(['cms']);
  const { isModalVisible, hideModal } = useModalVisibility('CustomTemplateEditorModal');
  const currentCustomTemplateId = useSelector(getCurrentCustomTemplateId);
  const { customTemplates } = useCustomTemplates();
  const existingTemplate = customTemplates.find(template => template.id === currentCustomTemplateId);
  const dispatch = useDispatch();

  return (
    <ModalShell
      isOpen={isModalVisible}
      onClose={hideModal}
      modalClosedCallback={() => {
        dispatch(setCurrentCustomTemplateId(null));
      }}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      title={existingTemplate ? t('cms:custom_layouts_update_title') : t('cms:custom_layouts_create_title')}
      centerModal={false}
    >
      <CustomTemplateEditor onClose={hideModal} />
    </ModalShell>
  );
};
