import { CheckoutForm, Form } from '@typings';
import React from 'react';
import { FieldValues, Path, useController, useFormContext } from 'react-hook-form';

import { isDefined } from '../../../../utils/is';
import { DatePicker } from '../../../various/DatePicker';

import { FieldWrapper } from './FieldParts';
import styles from './FormField.module.scss';

interface Props<T extends FieldValues> extends Form.FieldProps, Omit<React.ComponentProps<typeof DatePicker>, 'onChange' | 'value'> {
  name: Path<T>;
}

export const DatePickerField = <T extends FieldValues>({
  label,
  name,
  isRequired,
  orientation,
  description,
  helpMessage,
  isLoading,
  ...rest
}: Props<T>) => {
  const errorId = React.useId();
  const describedById = React.useId();

  const {
    field,
    fieldState: { error },
  } = useController<T>({ name, shouldUnregister: isLoading });
  const { trigger } = useFormContext<CheckoutForm>();

  const errorMessage = error?.message;

  const onPopoverClose = () => {
    trigger(name as Path<CheckoutForm>);
  };

  return (
    <FieldWrapper
      description={description}
      describedById={describedById}
      helpMessage={helpMessage}
      errorId={errorId}
      errorMessage={errorMessage}
      isRequired={isRequired}
      label={label}
      orientation={orientation}
      fieldClassName={styles.datePickerFieldWrapper}
    >
      <DatePicker
        ref={field.ref}
        invalid={isDefined(errorMessage)}
        onChange={field.onChange}
        value={field.value}
        isLoading={isLoading}
        size="large"
        onPopoverClose={onPopoverClose}
        {...rest}
      />
    </FieldWrapper>
  );
};
