import { Form } from '@typings';
import { without } from 'ramda';
import React from 'react';
import { useController, useFormState } from 'react-hook-form';

import { isDefined } from '../../../../utils/is';
import { Checkbox } from '../../Fields/Checkbox';
import { FormGrid } from '../FormGrid';

import { FieldWrapper } from './FieldParts';
import styles from './FormField.module.scss';

interface Props extends Form.FieldProps {
  name: string;
  label: string;
  options: {
    label: React.ReactNode;
    value: string;
  }[];
}

export const CheckboxGroupField = (props: Props) => {
  const { description, helpMessage, label, name, isRequired = false, orientation, options } = props;

  const errorId = React.useId();
  const labelId = React.useId();
  const describedById = React.useId();

  const { field } = useController({ name });
  const { errors } = useFormState({ name });

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    const changedValues = checked ? [...field.value, value] : without([value], field.value);

    field.onChange(changedValues);
  };

  const errorMessage = errors[name]?.message as string | undefined;

  return (
    <FieldWrapper
      as="span"
      description={description}
      describedById={describedById}
      helpMessage={helpMessage}
      errorId={errorId}
      errorMessage={errorMessage}
      isRequired={isRequired}
      labelId={labelId}
      label={label}
      orientation={orientation}
    >
      <div role="group" aria-labelledby={labelId} aria-describedby={isDefined(description) ? describedById : undefined}>
        <FormGrid className={styles.checkboxGroup} cols={3} rowGap="small" colGap="small">
          {options.map(option => (
            <Checkbox
              key={option.value}
              value={option.value}
              name={name}
              errorMessageId={isDefined(errorMessage) ? errorId : undefined}
              isInvalid={isDefined(errorMessage)}
              isRequired={isRequired}
              checked={field.value.includes(option.value)}
              onBlur={field.onBlur}
              onChange={handleCheckboxChange}
            >
              {option.label}
            </Checkbox>
          ))}
        </FormGrid>
      </div>
    </FieldWrapper>
  );
};
