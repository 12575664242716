import { BarcodeScanner } from '@typings';
import React from 'react';

import { BARCODE_SUPPORTED_FORMATS } from '../../../constants/formats';
import { getIsValidScan } from '../../../utils/getIsValidScan';
import { useSetInterval } from '../../../utils/hooks/useSetInterval';
import { isDefined } from '../../../utils/is';

export const useScanWithBarcodeDetector = ({ videoRef, setScannedCode }: BarcodeScanner.ScanProps) => {
  const [isScannerPaused, setIsScannerPaused] = React.useState(false);

  const barcodeDetector = React.useMemo(
    () =>
      new window.BarcodeDetector({
        formats: BARCODE_SUPPORTED_FORMATS,
      }),
    [],
  );

  const scan = React.useCallback(() => {
    const sourceEl = videoRef.current;
    const isSourceElReady = sourceEl?.readyState === HTMLMediaElement.HAVE_ENOUGH_DATA;

    if (!isDefined(sourceEl) || !isSourceElReady) {
      return;
    }

    barcodeDetector
      .detect(sourceEl)
      .then((barcodes: BarcodeScanner.Barcode[]) => {
        const validBarcode = barcodes.find(barcode => getIsValidScan(barcode.rawValue));

        if (!isDefined(validBarcode)) {
          return;
        }

        setScannedCode(validBarcode.rawValue);
        setIsScannerPaused(true);
      })
      .catch((error: unknown) => {
        // eslint-disable-next-line no-console
        console.error('Barcode detection error:', error);
      });
  }, [barcodeDetector, setScannedCode, videoRef]);

  const activateScanner = React.useCallback(() => {
    setIsScannerPaused(false);
  }, []);

  useSetInterval({ callback: scan, interval: 200, isPaused: isScannerPaused });

  return { activateScanner, isLoadingScanner: false };
};
