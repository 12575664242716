import { Cms } from '@typings';
import React from 'react';

import { MAX_COLUMN_SPAN } from '../../../../../../../constants/cms';
import {
  getBlockOnBottomResizing,
  getBlockOnLeftResizing,
  getBlockOnRightResizing,
  getBlockOnTopResizing,
  getNewBlockOnLeftResizeUpdate,
  getProductsCountOnHorizonal,
  getUpdatedBlockByHeight,
  getUpdatedBlockByWidth,
} from '../../../../../../../logic/cms/customLayoutBlockResize';
import { getIsContentBlock } from '../../../../../../../logic/pages';
import { isDefined } from '../../../../../../../utils/is';
import { MouseClosestSideOptions } from '../../useMouseClosestSide';

interface Props {
  resizableRef: React.RefObject<HTMLDivElement>;
  onResizeStart?: (block: Cms.CustomBlockModel) => void;
  onResizeEnd?: VoidFunction;
  updateBlock: (index: number, templateModel: Cms.CustomBlockModel) => void;
  onResizeUpdate?: (dimensions: Partial<Cms.BlockPositionRelativeProps>) => void;
  block: Cms.CustomBlockModel;
  screenType: Cms.ScreenType;
  index: number;
}

const INITIAL_SIZE = { height: 0, width: 0, x: 0, y: 0 };

const updateElementStyles = (elementRef: React.RefObject<HTMLDivElement>, style: React.CSSProperties) => {
  setTimeout(() => {
    if (!isDefined(elementRef.current)) {
      return;
    }
    Object.assign(elementRef.current.style, style);
  }, 0);
};

export const useBlockResize = ({
  resizableRef,
  onResizeStart,
  onResizeEnd,
  updateBlock,
  onResizeUpdate,
  block,
  screenType,
  index,
}: Props) => {
  const [currentResizer, setCurrentResizer] = React.useState<MouseClosestSideOptions | null>(null);
  const isContentBlock = getIsContentBlock(block);

  const internalSizeRef = React.useRef(INITIAL_SIZE);

  const handleInitialResizing = React.useCallback(() => {
    if (!isDefined(resizableRef.current)) {
      return;
    }

    const { x, y, width, height } = resizableRef.current.getBoundingClientRect();
    internalSizeRef.current = { height, width, x, y };
    onResizeStart?.(block);
  }, [block, onResizeStart, resizableRef]);

  const handleLeftResizingOnReverse = React.useCallback(
    (dragOffset: number) => {
      if (!isDefined(resizableRef.current)) {
        return;
      }

      const { position } = block;

      const blockWidth = internalSizeRef.current.width - dragOffset;

      const { width, spanX, startX, leftDragOffset } = getBlockOnLeftResizing({
        blockWidth,
        dragOffset,
        position,
        screenType,
      });

      const productsCount = getProductsCountOnHorizonal({ block, screenType, spanX });
      const newBlock = getNewBlockOnLeftResizeUpdate({ isContentBlock, productsCount, spanX, startX });
      onResizeUpdate?.(newBlock);
      updateElementStyles(resizableRef, { left: `${leftDragOffset}px`, width: `${width}px` });
    },
    [resizableRef, block, screenType, isContentBlock, onResizeUpdate],
  );

  const handleLeftResizingEndOnReverse = React.useCallback(
    (dragOffset: number) => {
      onResizeEnd?.();
      setCurrentResizer(null);
      if (!isDefined(resizableRef.current)) {
        return;
      }

      const { spanX, startX } = getBlockOnLeftResizing({
        blockWidth: internalSizeRef.current.width - dragOffset,
        position: block.position,
        screenType,
      });

      const newBlock = getUpdatedBlockByWidth({ block, screenType, spanX, startX });
      updateBlock(index, newBlock);

      updateElementStyles(resizableRef, { left: `0`, width: `auto` });
    },
    [onResizeEnd, resizableRef, block, screenType, updateBlock, index],
  );

  const handleRightResizing = React.useCallback(
    (dragOffset: number, isOnReverse = false) => {
      !isOnReverse && setCurrentResizer('right');
      if (!isDefined(resizableRef.current)) {
        return;
      }

      const blockWidth = internalSizeRef.current.width + dragOffset;
      const { position } = block;
      const { startX: initialStartX } = position[screenType];
      const rightSidePosition = initialStartX + position[screenType].spanX - 1;

      if (rightSidePosition === MAX_COLUMN_SPAN[screenType] - 1 && dragOffset > 0) {
        return handleLeftResizingOnReverse(-dragOffset);
      }

      const { width, spanX } = getBlockOnRightResizing({
        blockWidth,
        position,
        screenType,
      });
      const productsCount = getProductsCountOnHorizonal({ block, screenType, spanX });
      const newBlock = isContentBlock ? { spanX } : { spanX: Math.min(productsCount, spanX), spanY: Math.ceil(productsCount / spanX) };
      onResizeUpdate?.(newBlock);
      updateElementStyles(resizableRef, { left: 0, width: `${width}px` });
    },
    [resizableRef, block, screenType, isContentBlock, onResizeUpdate, handleLeftResizingOnReverse],
  );

  const handleRightResizingEnd = React.useCallback(
    (dragOffset: number) => {
      onResizeEnd?.();
      setCurrentResizer(null);
      if (!isDefined(resizableRef.current)) {
        return;
      }
      const { position } = block;
      const { startX: initialStartX } = position[screenType];
      const rightSidePosition = initialStartX + position[screenType].spanX - 1;
      if (rightSidePosition === MAX_COLUMN_SPAN[screenType] - 1 && dragOffset > 0) {
        return handleLeftResizingEndOnReverse(-dragOffset);
      }

      const { spanX } = getBlockOnRightResizing({
        blockWidth: internalSizeRef.current.width + dragOffset,
        position: block.position,
        screenType,
      });
      const newBlock = getUpdatedBlockByWidth({ block, screenType, spanX });
      updateBlock(index, newBlock);
      updateElementStyles(resizableRef, { width: 'auto' });
    },
    [onResizeEnd, resizableRef, block, screenType, updateBlock, index, handleLeftResizingEndOnReverse],
  );

  const handleBottomResizing = React.useCallback(
    (dragOffset: number, isOnReverse = false) => {
      !isOnReverse && setCurrentResizer('bottom');
      if (!isDefined(resizableRef.current)) {
        return;
      }

      const blockHeight = internalSizeRef.current.height + dragOffset;
      const { height, spanY } = getBlockOnBottomResizing(blockHeight);
      onResizeUpdate?.({ spanY });
      updateElementStyles(resizableRef, { height: `${height}px` });
    },
    [resizableRef, onResizeUpdate],
  );

  const handleBottomResizingEnd = React.useCallback(
    (dragOffset: number) => {
      onResizeEnd?.();
      setCurrentResizer(null);
      if (!isDefined(resizableRef.current)) {
        return;
      }

      const blockHeight = internalSizeRef.current.height + dragOffset;
      const { spanY } = getBlockOnBottomResizing(blockHeight);
      const newBlock = getUpdatedBlockByHeight({ block, screenType, spanY });
      updateBlock(index, newBlock);
      updateElementStyles(resizableRef, { height: 'auto' });
    },
    [onResizeEnd, resizableRef, block, screenType, updateBlock, index],
  );

  const handleTopResizing = React.useCallback(
    (dragOffset: number) => {
      setCurrentResizer('top');
      if (!isDefined(resizableRef.current)) {
        return;
      }

      const { position } = block;
      const { startY: initialStartY } = position[screenType];

      if (initialStartY === 1) {
        return handleBottomResizing(-dragOffset, true);
      }

      const blockHeight = internalSizeRef.current.height - dragOffset;
      const { height, spanY, startY, topOffset } = getBlockOnTopResizing({
        blockHeight,
        dragOffset,
        position,
        screenType,
      });
      onResizeUpdate?.({ spanY, startY });
      updateElementStyles(resizableRef, { height: `${height}px`, top: `${topOffset}px` });
    },
    [resizableRef, block, screenType, onResizeUpdate, handleBottomResizing],
  );

  const handleTopResizingEnd = React.useCallback(
    (dragOffset: number) => {
      onResizeEnd?.();
      setCurrentResizer(null);
      if (!isDefined(resizableRef.current)) {
        return;
      }

      const { position } = block;
      const { startY: intialStartY } = position[screenType];

      if (intialStartY === 1) {
        return handleBottomResizingEnd(-dragOffset);
      }

      const blockHeight = internalSizeRef.current.height - dragOffset;
      const { spanY, startY } = getBlockOnTopResizing({ blockHeight, position, screenType });
      const newBlock = getUpdatedBlockByHeight({ block, screenType, spanY, startY });
      updateBlock(index, newBlock);
      updateElementStyles(resizableRef, { height: 'auto', top: '0' });
    },
    [onResizeEnd, resizableRef, block, screenType, updateBlock, index, handleBottomResizingEnd],
  );

  const handleLeftResizing = React.useCallback(
    (dragOffset: number) => {
      setCurrentResizer('left');
      if (!isDefined(resizableRef.current)) {
        return;
      }

      const { position } = block;
      const { startX: initialStartX } = position[screenType];

      if (initialStartX === 1 && dragOffset < 0) {
        return handleRightResizing(-dragOffset, true);
      }

      const blockWidth = internalSizeRef.current.width - dragOffset;

      const { width, spanX, startX, leftDragOffset } = getBlockOnLeftResizing({
        blockWidth,
        dragOffset,
        position,
        screenType,
      });

      const productsCount = getProductsCountOnHorizonal({ block, screenType, spanX });
      const newBlock = getNewBlockOnLeftResizeUpdate({ isContentBlock, productsCount, spanX, startX });
      onResizeUpdate?.(newBlock);
      updateElementStyles(resizableRef, { left: `${leftDragOffset}px`, width: `${width}px` });
    },
    [resizableRef, block, screenType, isContentBlock, onResizeUpdate, handleRightResizing],
  );

  const handleLeftResizingEnd = React.useCallback(
    (dragOffset: number) => {
      onResizeEnd?.();
      setCurrentResizer(null);
      if (!isDefined(resizableRef.current)) {
        return;
      }

      const { position } = block;
      const { startX: initialStartX } = position[screenType];

      if (initialStartX === 1 && dragOffset < 0) {
        return handleRightResizingEnd(-dragOffset);
      }

      const { spanX, startX } = getBlockOnLeftResizing({
        blockWidth: internalSizeRef.current.width - dragOffset,
        position: block.position,
        screenType,
      });
      const newBlock = getUpdatedBlockByWidth({ block, screenType, spanX, startX });
      updateBlock(index, newBlock);
      updateElementStyles(resizableRef, { left: `0`, width: `auto` });
    },
    [onResizeEnd, resizableRef, block, screenType, updateBlock, index, handleRightResizingEnd],
  );

  return {
    currentResizer,
    handleBottomResizing,
    handleBottomResizingEnd,
    handleInitialResizing,
    handleLeftResizing,
    handleLeftResizingEnd,
    handleRightResizing,
    handleRightResizingEnd,
    handleTopResizing,
    handleTopResizingEnd,
  };
};
