import { Media, Settings } from '@typings';

/**
 * Gets background image from props, generates special object imitating the File.
 */
export function getImageFromProps(image: string | null, fileId: Settings.FileId): Media.UploadFile | null {
  if (image === '' || image === null) {
    return null;
  }

  const file = new Blob([''], { type: 'text/plain' });

  // eslint-disable-next-line functional/immutable-data
  return Object.assign(file, {
    lastModified: Date.now(),
    name: fileId,
    previewUrl: image,
    webkitRelativePath: '',
  });
}

/**
 * Generates url for file thumbnail.
 * Used in file upload preview.
 */
export function getBase64(img: Blob, callback: (imageUrl: string) => any): void {
  const reader = new FileReader();
  reader.addEventListener('load', () => {
    if (typeof reader.result !== 'string') {
      throw new Error('Expected the generated file URL to be a string.');
    }

    return callback(reader.result);
  });
  reader.readAsDataURL(img);
}
