import cx from 'classnames';
import React from 'react';
import { ChromePicker, ColorResult, RGBColor } from 'react-color';

import { hexToRgba, rgbaToHex } from '../../../utils/colors';

import styles from './ColorPicker.module.scss';

interface Props {
  color: string;
  isVisible: boolean;
  onChangeComplete: (value: string) => void;
  disableAlpha?: boolean;
}

const chromePickerStyles = {
  default: {
    picker: {
      background: '#f7f3f8',
    },
  },
};

export const ColorPicker = React.memo(({ isVisible, onChangeComplete, color, disableAlpha }: Props) => {
  const [currentColor, setCurrentColor] = React.useState<RGBColor>(hexToRgba(color));

  React.useEffect(() => setCurrentColor(hexToRgba(color)), [color]);

  const cancelSelection = React.useCallback((event: MouseEvent) => {
    event.preventDefault();

    return false;
  }, []);

  const onMouseDown = React.useCallback(
    () =>
      document.body.addEventListener('selectstart', cancelSelection, {
        once: true,
      }),
    [cancelSelection],
  );

  const handleChange = React.useCallback(({ rgb }: ColorResult) => {
    setCurrentColor(rgb);
  }, []);

  const handleChangeComplete = React.useCallback(
    ({ hex, rgb }: ColorResult) => {
      onChangeComplete(rgb.a === 1 ? hex : rgbaToHex(rgb));
    },
    [onChangeComplete],
  );

  return (
    <div className={cx(styles.pickerHolder, { [styles.visible]: isVisible })} role="presentation" onMouseDown={onMouseDown}>
      <ChromePicker
        styles={chromePickerStyles}
        onChange={handleChange}
        onChangeComplete={handleChangeComplete}
        disableAlpha={disableAlpha}
        color={currentColor}
      />
    </div>
  );
});
