import { isDefined } from './is';
import { isNumberRepresentation } from './numberRepresentation';

/**
 * Returns `true` when `subject` is an object.
 * Handles false positives like arrays or functions.
 */
export const isObject = <T extends Unrestricted>(subject: T): subject is Record<keyof T, Unrestricted> => {
  return Boolean(subject) && subject === Object(subject) && !Array.isArray(subject) && !(subject instanceof Function);
};

export const alphabeticSortByKey = <T extends Record<K, React.ReactNode>, K extends string>(array: T[], key: K): T[] => {
  const areKeysOnlyNumeric = array.every(item => {
    const value = item[key]?.toString();

    return isDefined(value) && isNumberRepresentation(value);
  });

  const options = { numeric: areKeysOnlyNumeric, sensitivity: 'base' };

  return [...array].sort((a, b) => {
    const value1: React.ReactNode = a[key];
    const value2: React.ReactNode = b[key];

    if (!isDefined(value1) || !isDefined(value2)) {
      return 0;
    }

    const first = value1.toString().trim();
    const second = value2.toString().trim();
    // eslint-disable-next-line no-warning-comments
    // TODO check types

    return first.localeCompare(second, undefined, options as any);
  });
};
