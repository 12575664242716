import { stringify } from 'qs';
import { NavigateFunction } from 'react-router-dom';

import { filtersDecoder } from '../logic/filters';

import { getQueryParams } from './getQueryParams';
import { omit } from './omit';

export const closeProductDetailsModal = (search: string, navigate: NavigateFunction) => {
  const filter = getQueryParams(search, {
    decoder: filtersDecoder,
    ignoreQueryPrefix: true,
  });

  if ('showProduct' in filter || 'showVariant' in filter) {
    navigate({
      search: stringify(omit(filter, ['showProduct', 'showVariant']), { encode: false }),
    });
  }
};
