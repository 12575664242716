import cx from 'classnames';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { DatePicker } from '../../../../../../typings';
import { DATE_STRING_FORMAT } from '../../../../constants/dates';
import { getIsValidDateString } from '../../../../utils/dates';
import { isDefined, isNull } from '../../../../utils/is';
import { isEmpty } from '../../../../utils/isEmpty';
import { IconType } from '../../Icon';
import { IconButton } from '../../IconButton/IconButton';
import { DatePickerNavigationContext, DatePickerSelectionContext, DatePickerSettingsContext } from '../context';

import styles from './DatePickerControls.module.scss';

interface Props {
  label: string;
  size: DatePicker.Size;
  dataTestId?: string;
}

export const DatePickerHeader = ({ size, label, dataTestId }: Props) => {
  const { t } = useTranslation(['dates']);
  const startDateRef = React.useRef<HTMLInputElement>(null);

  const { isDateView, isYearView, setView, shouldDisable } = React.useContext(DatePickerSettingsContext);
  const { startDate, endDate, isRangeSelection, setStartDate, setEndDate } = React.useContext(DatePickerSelectionContext);
  const { calendarDates, onNextClick, onPrevClick, onSuperNextClick, onSuperPrevClick, visibleDate } =
    React.useContext(DatePickerNavigationContext);

  const [startDateString, setStartDateString] = React.useState('');
  const [endDateString, setEndDateString] = React.useState('');

  const updateStartDateString = React.useCallback(() => setStartDateString(startDate?.format(DATE_STRING_FORMAT) ?? ''), [startDate]);
  const updateEndDateString = React.useCallback(() => setEndDateString(endDate?.format(DATE_STRING_FORMAT) ?? ''), [endDate]);

  React.useEffect(updateStartDateString, [updateStartDateString]);
  React.useEffect(updateEndDateString, [updateEndDateString]);

  React.useEffect(() => {
    startDateRef.current?.focus();
  }, []);

  const handleStartDateChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const dateString = event.target.value;

      setStartDateString(dateString);

      if (isEmpty(dateString) && isNull(endDate)) {
        setStartDate(null);

        return;
      }

      const date = dayjs(dateString, DATE_STRING_FORMAT);

      if (getIsValidDateString(dateString, DATE_STRING_FORMAT) && !shouldDisable(date)) {
        setStartDate(date);
      }
    },
    [endDate, setStartDate, shouldDisable],
  );

  const handleEndDateChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const dateString = event.target.value;

      setEndDateString(dateString);

      if (isEmpty(dateString)) {
        setEndDate(null);

        return;
      }

      const date = dayjs(dateString, DATE_STRING_FORMAT);

      if (getIsValidDateString(dateString, DATE_STRING_FORMAT) && !shouldDisable(date) && !date.isBefore(startDate)) {
        setEndDate(date);
      }
    },
    [setEndDate, shouldDisable, startDate],
  );

  const setMonthView = React.useCallback(() => setView('month'), [setView]);
  const setYearView = React.useCallback(() => setView('year'), [setView]);

  const yearViewLabel = React.useMemo(() => {
    const [firstDate] = calendarDates;
    const lastDate = calendarDates[calendarDates.length - 1];

    if (!isDefined(firstDate) || !isDefined(lastDate)) {
      return '';
    }

    return `${firstDate.year()} - ${lastDate.year()}`;
  }, [calendarDates]);

  return (
    <>
      <div className={cx(styles.inputsWrapper, { [styles.sizeLarge]: size === 'large' })}>
        <input
          ref={startDateRef}
          data-testid={dataTestId}
          className={styles.input}
          value={startDateString}
          placeholder={label}
          onChange={handleStartDateChange}
          onBlur={updateStartDateString}
        />
        {isRangeSelection && !isNull(startDate) && <span className={styles.inputSeparator}>{' - '}</span>}
        {isRangeSelection && !isEmpty(startDateString) && (
          <input
            className={styles.input}
            value={endDateString}
            disabled={isNull(startDate)}
            onChange={handleEndDateChange}
            onBlur={updateEndDateString}
          />
        )}
      </div>
      <div className={styles.controls}>
        <div className={styles.buttonControls}>
          <IconButton
            icon={IconType.ArrowDoubleLeft}
            name={isYearView ? t('dates:previous_decade') : t('dates:previous_year')}
            size="small"
            variant="secondary"
            onClick={onSuperPrevClick}
          />
          {isDateView && (
            <IconButton icon={IconType.ArrowLeft} name={t('dates:previous_month')} size="small" variant="secondary" onClick={onPrevClick} />
          )}
        </div>
        <div>
          {isDateView && (
            <button type="button" className={styles.button} onClick={setMonthView}>
              {visibleDate.format('MMM')}
            </button>
          )}
          {isYearView ?
            <span>{yearViewLabel}</span>
          : <button type="button" className={styles.button} onClick={setYearView}>
              {visibleDate.format('YYYY')}
            </button>
          }
        </div>
        <div className={styles.buttonControls}>
          {isDateView && (
            <IconButton icon={IconType.ArrowLeft} name={t('dates:next_month')} size="small" variant="secondary" onClick={onNextClick} />
          )}
          <IconButton
            icon={IconType.ArrowDoubleLeft}
            name={isYearView ? t('dates:next_decade') : t('dates:next_year')}
            size="small"
            variant="secondary"
            onClick={onSuperNextClick}
          />
        </div>
      </div>
    </>
  );
};
