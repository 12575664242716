import { Matrices } from '@typings';

import { isNull } from '../../is';
import { selectedRange } from '../../matrix';

import { useGetAllCellValuesMap } from './useGetAllCellValuesMap';

export const useMatrixCopyHandler = ({ end, start }: Matrices.SelectedPosition, hasActiveElement: boolean) => {
  const allCellValuesMap = useGetAllCellValuesMap();

  return (event: React.ClipboardEvent<HTMLInputElement>) => {
    if (isNull(event.clipboardData) || !hasActiveElement) {
      return;
    }

    const startX = Math.min(start.x, end.x);
    const startY = Math.min(start.y, end.y);
    const endX = Math.max(start.x, end.x);
    const endY = Math.max(start.y, end.y);

    const columnsLength = allCellValuesMap[0]?.length ?? 0;
    const totalsOffset = columnsLength - 1 === endX + 1 ? 1 : 0;

    const ySelectedRange = selectedRange(startY, endY);
    const xSelectedRange = selectedRange(startX, endX + totalsOffset);

    const hasRange = ySelectedRange.length > 1 || xSelectedRange.length > 1;
    const hasTextSelection = event.target instanceof HTMLInputElement ? event.target.selectionStart !== event.target.selectionEnd : false;

    if (!hasRange && hasTextSelection) {
      return;
    }

    event.preventDefault();

    const text = ySelectedRange
      .map(y => {
        return xSelectedRange.map(x => allCellValuesMap[y]?.[x]).join('\t');
      })
      .join('\n');
    event.clipboardData.setData('text/plain', text);
  };
};
