import { StandardItem } from '../StandardItem';

import styles from './HighlightItem.module.scss';

interface Props {
  label: string;
  value: React.ReactNode;
  isLoading?: boolean;
}

export const HighlightItem = ({ label, value, isLoading }: Props) => {
  return <StandardItem className={styles.highlight} label={label} value={value} isLoading={isLoading} />;
};
