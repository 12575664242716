import { uniq } from 'ramda';
import { useSelector } from 'react-redux';

import {
  getAllDeliveryWindows,
  getLoadingProducts,
  getOptimisticUpdatesDeliveryWindowIds,
  getOrderProducts,
  getProducts,
} from '../../ducks';
import { getDeliveryWindowsIdsByStockType } from '../../logic/deliveryWindows';
import { isDefined } from '../is';

export const useHasProductsByStockType = () => {
  const orderProducts = useSelector(getOrderProducts);
  const deliveryWindows = useSelector(getAllDeliveryWindows);
  const productDetails = useSelector(getProducts);
  const loadingProductsIds = useSelector(getLoadingProducts);
  const optimisticUpdatesDeliveryWindowIds = useSelector(getOptimisticUpdatesDeliveryWindowIds);

  const deliveryWindowIdsByStockType = getDeliveryWindowsIdsByStockType(deliveryWindows);

  const loadingProductsDeliveryWindowIds = loadingProductsIds.flatMap(id => {
    return productDetails[id]?.deliveryWindows.toString() ?? [];
  });
  const orderProductsDeliveryWindowIds = orderProducts.map(orderProduct => orderProduct.deliveryWindow);

  const allDeliveryWindows = uniq([
    ...optimisticUpdatesDeliveryWindowIds,
    ...loadingProductsDeliveryWindowIds,
    ...orderProductsDeliveryWindowIds,
  ]);

  const hasPreorderProducts = allDeliveryWindows.some(
    deliveryWindowId => isDefined(deliveryWindowId) && deliveryWindowIdsByStockType.preorder.includes(deliveryWindowId),
  );
  const hasStockProducts = allDeliveryWindows.some(
    deliveryWindowId => isDefined(deliveryWindowId) && deliveryWindowIdsByStockType.stock.includes(deliveryWindowId),
  );

  return {
    preorder: hasPreorderProducts,
    stock: hasStockProducts,
  };
};
