import cx from 'classnames';

import styles from './ProductBaseInfoLayout.module.scss';

interface Props {
  children: React.ReactNode;
  hasAddButton?: boolean;
}

export const ProductBaseInfoLayout = ({ children, hasAddButton = false }: Props) => {
  return <div className={cx(styles.layout, { [styles.withButton]: hasAddButton })}>{children}</div>;
};
