import { editorComponentSwitch } from '../../cms/editorComponentSwitch';
import { ManualContentLayoutSelector } from '../../cms/ManualLayoutEditor/ManualLayoutSelector';

import { BlockWarnings } from './BlockWarnings';
import { createContentBlock } from './ContentBlock';
import { ContentBlockTools } from './ContentBlockTools';

export const ContentBlock = editorComponentSwitch({
  editorComponent: createContentBlock({
    manualLayoutEditor: ManualContentLayoutSelector,
    tools: ContentBlockTools,
    warnings: BlockWarnings,
  }),
  standardComponent: createContentBlock(),
});
