import { ColumnsConfiguration, TablesIdentifier } from '@typings';
import { assocPath } from 'ramda';
import React from 'react';

import { BREAKPOINTS } from '../../../constants/breakpoints';
import { LISTING_COLUMNS_CONFIG_KEY } from '../../../constants/localStorage';
import { useLocalStorage } from '../useLocalStorage';

const ORDERS_DESKTOP_CONFIG = {
  account: { isVisible: true },
  actions: { isVisible: true },
  buyer: { isVisible: true },
  delivery: { isVisible: true },
  itemsCount: { isVisible: true },
  orderNumber: { isVisible: true },
  poNumber: { isVisible: true },
  shippingCountry: { isVisible: true },
  status: { isVisible: true },
  total: { isVisible: true },
};

const ORDERS_MOBILE_CONFIG = {
  account: { isVisible: true },
  actions: { isVisible: true },
  buyer: { isVisible: true },
  delivery: { isVisible: false },
  itemsCount: { isVisible: false },
  orderNumber: { isVisible: true },
  poNumber: { isVisible: false },
  shippingCountry: { isVisible: false },
  status: { isVisible: true },
  total: { isVisible: true },
};

const ORDERS_CONFIG = window.innerWidth <= BREAKPOINTS.SMALL ? ORDERS_MOBILE_CONFIG : ORDERS_DESKTOP_CONFIG;

const PAGES_DESKTOP_CONFIG = {
  createdDate: { isVisible: true },
  description: { isVisible: false },
  languages: { isVisible: true },
  lastModifiedDate: { isVisible: true },
  lastPublishedDate: { isVisible: true },
  markets: { isVisible: true },
  pricelists: { isVisible: true },
  slug: { isVisible: true },
  status: { isVisible: true },
  title: { isVisible: true },
};

const PAGES_MOBILE_CONFIG = {
  createdDate: { isVisible: false },
  description: { isVisible: false },
  languages: { isVisible: true },
  lastModifiedDate: { isVisible: false },
  lastPublishedDate: { isVisible: false },
  markets: { isVisible: true },
  pricelists: { isVisible: false },
  slug: { isVisible: true },
  status: { isVisible: true },
  title: { isVisible: true },
};

const PAGES_CONFIG = window.innerWidth <= BREAKPOINTS.SMALL ? PAGES_MOBILE_CONFIG : PAGES_DESKTOP_CONFIG;

const INVOICES_CONFIG = {
  address: { isVisible: true },
  createdAt: { isVisible: true },
  dueDate: { isVisible: true },
  grandTotal: { isVisible: true },
  grandTotalWithoutTax: { isVisible: true },
  invoiceNumber: { isVisible: true },
  status: { isVisible: true },
  totalDue: { isVisible: true },
};

const LOOKBOOK_CONFIG = {
  actions: { isVisible: true },
  dateCreated: { isVisible: true },
  dateLastVisited: { isVisible: true },
  deliveryWindows: { isVisible: true },
  email: {
    isVisible: true,
  },
  market: {
    isVisible: true,
  },
  visitedCount: {
    isVisible: true,
  },
};

interface Props {
  tableName: TablesIdentifier;
}

export const useTableColumnsConfiguration = ({ tableName }: Props) => {
  const [columnsConfiguration, setColumnsConfiguration] = useLocalStorage<Record<TablesIdentifier, ColumnsConfiguration>>({
    asJson: true,
    defaultValue: {
      invoicesTable: INVOICES_CONFIG,
      lookbook: LOOKBOOK_CONFIG,
      ordersTable: ORDERS_CONFIG,
      pages: PAGES_CONFIG,
      selectionsTable: ORDERS_CONFIG,
    },
    key: LISTING_COLUMNS_CONFIG_KEY,
  });

  const columnsVisibility = columnsConfiguration[tableName];

  const toggleColumnVisibility = React.useCallback(
    (key: string) => {
      setColumnsConfiguration(
        assocPath([tableName, key, 'isVisible'], !columnsConfiguration[tableName][key]?.isVisible, columnsConfiguration),
      );
    },
    [columnsConfiguration, setColumnsConfiguration, tableName],
  );

  return {
    columnsVisibility,
    toggleColumnVisibility,
  };
};
