import { Order } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { setIsRequestingSplitOrder } from '../../../ducks';
import { getIsOrderClosed } from '../../../logic/Orders';
import { compiledPaths } from '../../../paths';
import { isDefined } from '../../../utils/is';
import { Alert } from '../../various/Alert';
import { AlertAction } from '../../various/Alert/AlertAction';

interface Props {
  order: Order.Closed | Order.Open;
  messageType: 'general' | 'payment';
  className?: string;
}

export const SplitAlert = ({ order, messageType, className }: Props) => {
  const { t } = useTranslation(['payments', 'checkout']);
  const dispatch = useDispatch();

  const handleSetViewSplit = React.useCallback(() => {
    dispatch(setIsRequestingSplitOrder(true));
  }, [dispatch]);

  if (!getIsOrderClosed(order) || !isDefined(order.originalOrder)) {
    return null;
  }

  const { originalOrder } = order;

  const message = messageType === 'payment' ? t('payments:payment_proportionally_split') : t('checkout:split_order_created');

  return (
    <Alert
      type="info"
      className={className}
      message={
        <>
          {message}{' '}
          <AlertAction onClick={handleSetViewSplit} to={compiledPaths.CHECKOUT_SUCCESS({ id: originalOrder })}>
            {t('checkout:view_order_split')}
          </AlertAction>
        </>
      }
    />
  );
};
