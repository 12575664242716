import { useTranslation } from 'react-i18next';

import { PageTitle } from '../various/PageTitle';
import { TopBar } from '../various/TopBar';
import { Wrapper, WrapperSize } from '../various/Wrapper';

import { LinesheetGenerator } from './LinesheetGenerator';

export const Linesheets = () => {
  const { t } = useTranslation(['common']);
  const title = t('common:linesheet_other');

  return (
    <PageTitle title={title}>
      <TopBar title={title} />
      <Wrapper size={WrapperSize.MEDIUM}>
        <LinesheetGenerator />
      </Wrapper>
    </PageTitle>
  );
};
