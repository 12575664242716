import { DeliveryWindow, Order, Product } from '@typings';
import cx from 'classnames';
import { groupBy, isEmpty } from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getDefaultImageAspectRatio, getProductImages } from '../../../ducks/helpers';
import {
  getIsProductCancelled,
  getProductByProductIdVariantIdFromProducts,
  getTotalProductPriceInDelwins,
  getTotalUnitQuantityByProductAndDelwins,
  getTotalVariantQuantityByProductAndDelwins,
} from '../../../logic/Orders';
import { applyPrices } from '../../../logic/price';
import { getOrderedProducts, getRelatedProducts } from '../../../logic/products';
import { isDefined } from '../../../utils/is';
import { ProductMatrixSectionGeneral } from '../../products/ProductMatrix/ProductMatrixSectionGeneral';
import { ProductBaseInfo } from '../../various/ProductBaseInfo';

import styles from './ProductDetailsShared.module.scss';

interface Props {
  delwinId: DeliveryWindow.Id;
  order: Order.Open;
  products: never[] | Record<Product.Id, Product.Full>;
  variantsInDelwin: Order.Product[];
  isExpired?: boolean;
}

export const ProductDetailsShared = (props: Props) => {
  const { order, variantsInDelwin, delwinId, isExpired } = props;
  const aspectRatio = useSelector(getDefaultImageAspectRatio);
  const location = useLocation();

  const products = React.useMemo(() => (Array.isArray(props.products) ? {} : props.products), [props.products]);

  const productImagesSelector = React.useMemo(() => getProductImages(products), [products]);

  const productImages = useSelector(productImagesSelector);
  const groupedVariants = groupBy((variant: Order.Product) => variant.product)(Object.values(variantsInDelwin));

  if (Array.isArray(products)) {
    return null;
  }

  return (
    <div>
      {Object.values(groupedVariants).map(variants => {
        const [firstVariant] = variants;

        if (!isDefined(firstVariant)) {
          return null;
        }

        const variantData = products[firstVariant.product];

        if (!isDefined(variantData)) {
          return null;
        }

        const productInfo = applyPrices(order.products)(variantData);
        const unitsCount = getTotalUnitQuantityByProductAndDelwins(firstVariant.product, [delwinId], order.products);

        if (unitsCount === 0) {
          return null;
        }

        const image = productImages[productInfo.product];
        const isCheckoutSuccess = location.pathname.endsWith('success');

        const overrideSummaryValues = {
          price: getTotalProductPriceInDelwins(productInfo.product, [delwinId], order.products),
          unitsCount: getTotalUnitQuantityByProductAndDelwins(productInfo.product, [delwinId], order.products),
          variantsCount: getTotalVariantQuantityByProductAndDelwins(productInfo.product, [delwinId], order.products),
        };

        const orderedProduct = getProductByProductIdVariantIdFromProducts(
          variantData.product,
          variantData.variant,
          getOrderedProducts(order),
        );

        if (!isDefined(orderedProduct)) {
          return null;
        }

        const isProductCancelled = getIsProductCancelled(orderedProduct.product, order.products);

        const inactiveStyle = {
          [styles.inactiveProduct]: !isCheckoutSuccess && (!orderedProduct.isEditable || isProductCancelled),
        };

        const fullProductVariants = [productInfo, ...getRelatedProducts(productInfo)];

        if (isEmpty(fullProductVariants)) {
          return null;
        }

        return (
          <div
            className={cx(styles.checkoutProduct, {
              [styles.expired]: isExpired,
            })}
            key={firstVariant.product}
          >
            <div className={styles.productDetails}>
              <ProductBaseInfo
                imageAspectRatio={aspectRatio}
                imageSrc={image?.src ?? ''}
                productInfo={productInfo}
                overrideSummaryValues={overrideSummaryValues}
                isProductEditable={orderedProduct.isEditable}
                isCancelled={isProductCancelled}
                isCheckoutSuccess={isCheckoutSuccess}
                delwinIds={[delwinId]}
              />
            </div>
            <div className={cx(inactiveStyle)}>
              <ProductMatrixSectionGeneral showOnlyDeliveryWindows={[delwinId]} variants={fullProductVariants} isReadOnly />
            </div>
          </div>
        );
      })}
    </div>
  );
};
