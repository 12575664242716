import { ActionHandler, Cms } from '@typings';
import { createReducer } from 'typesafe-actions';

import { buttonStylesConfig, textStylesConfig } from '../../../cmsTemplates';
import { listStylesToRecord } from '../../../utils/normalize';
import { update } from '../../../utils/update';
import { fetchPageBySlugSuccess } from '../../pages';
import { closeButtonStyleEditor, closeTextStyleEditor } from '../ui';

import {
  createButtonStyleFailure,
  createButtonStyleRequest,
  createButtonStyleSuccess,
  createTextStyleFailure,
  createTextStyleRequest,
  createTextStyleSuccess,
  deleteTextStyleSuccess,
  editButtonStyleFailure,
  editButtonStyleRequest,
  editButtonStyleSuccess,
  editTextStyleFailure,
  editTextStyleRequest,
  editTextStyleSuccess,
  fetchCustomStylesFailure,
  fetchCustomStylesRequest,
  fetchCustomStylesSuccess,
  resetDeletedTextStyles,
  translateDeletedTextStylesSuccess,
} from './actions';

interface State {
  errors: Nullable<string>;
  isCreatingButtonStyle: boolean;
  isCreatingTextStyle: boolean;
  isEditingButtonStyle: boolean;
  isEditingTextStyle: boolean;
  isLoading: boolean;
  missingCustomStyles: { className: string; name: string }[];
  textStyles: Record<string, Cms.TextStyle>;
  buttonStyles: Record<string, Cms.ButtonStyle>;
}

const initialState: State = {
  buttonStyles: buttonStylesConfig,
  errors: null,
  isCreatingButtonStyle: false,
  isCreatingTextStyle: false,
  isEditingButtonStyle: false,
  isEditingTextStyle: false,
  isLoading: false,
  missingCustomStyles: [],
  textStyles: textStylesConfig,
};

const handleFetchTextStylesRequest: ActionHandler<State, typeof fetchCustomStylesRequest> = state =>
  update(state, {
    isLoading: true,
  });

const handleFetchTextStylesFailure: ActionHandler<State, typeof fetchCustomStylesFailure> = state =>
  update(state, {
    isLoading: false,
  });

const handleFetchTextStylesSuccess: ActionHandler<State, typeof fetchCustomStylesSuccess> = (state, action) =>
  update(state, {
    buttonStyles: update(state.buttonStyles, listStylesToRecord(action.payload.buttonStyles, 'className')),
    errors: null,
    isLoading: false,
    textStyles: update(state.textStyles, listStylesToRecord(action.payload.textStyles, 'className')),
  });

const handleCreateTextStyleRequest: ActionHandler<State, typeof createTextStyleRequest> = state =>
  update(state, {
    isCreatingTextStyle: true,
  });

const handleCreateButtonStyleRequest: ActionHandler<State, typeof createButtonStyleRequest> = state =>
  update(state, {
    isCreatingButtonStyle: true,
  });

const handleCreateTextStyleSuccess: ActionHandler<State, typeof createTextStyleSuccess> = (state, action) => {
  const { className } = action.payload;

  return update(state, {
    errors: null,
    isCreatingTextStyle: false,
    textStyles: update(state.textStyles, { [className]: action.payload }),
  });
};

const handleCreateButtonStyleSuccess: ActionHandler<State, typeof createButtonStyleSuccess> = (state, action) => {
  const { className } = action.payload;

  return update(state, {
    buttonStyles: update(state.buttonStyles, { [className]: action.payload }),
    errors: null,
    isCreatingButtonStyle: false,
  });
};

const handleCreateTextStyleFailure: ActionHandler<State, typeof createTextStyleFailure> = (state, action) => {
  return update(state, {
    errors: action.payload,
    isCreatingTextStyle: false,
  });
};

const handleCreateButtonStyleFailure: ActionHandler<State, typeof createButtonStyleFailure> = (state, action) => {
  return update(state, {
    errors: action.payload,
    isCreatingButtonStyle: false,
  });
};

const filterCustomStyles = <T extends Cms.ContentPartStyle>(styles: Record<string, T>, id: string): Record<string, T> =>
  Object.fromEntries(Object.entries(styles).filter(([_, styleProperties]) => styleProperties.id !== id));

const handleDeleteTextStyleSuccess: ActionHandler<State, typeof deleteTextStyleSuccess> = (state, action) => {
  const id = action.payload;

  return update(state, {
    buttonStyles: filterCustomStyles(state.buttonStyles, id),
    textStyles: filterCustomStyles(state.textStyles, id),
  });
};

const handleFetchPageBySlugSuccess: ActionHandler<State, typeof fetchPageBySlugSuccess> = (state, action) =>
  update(state, {
    buttonStyles: update(state.buttonStyles, listStylesToRecord(action.payload.pageData.buttonStyles, 'className')),
    textStyles: update(state.textStyles, listStylesToRecord(action.payload.pageData.textStyles, 'className')),
  });

const handleEditTextStyleRequest: ActionHandler<State, typeof editTextStyleRequest> = state =>
  update(state, {
    isEditingTextStyle: true,
  });

const handleEditButtonStyleRequest: ActionHandler<State, typeof editButtonStyleRequest> = state =>
  update(state, {
    isEditingButtonStyle: true,
  });

const handleEditTextStyleSuccess: ActionHandler<State, typeof editTextStyleSuccess> = (state, action) => {
  const { id, className } = action.payload;

  const textStyles = Object.values(state.textStyles).reduce<Record<string, Cms.TextStyle>>(
    (acc, cur) => (cur.id === id ? { ...acc, [className]: action.payload } : { ...acc, [cur.className]: cur }),
    {},
  );

  return update(state, {
    errors: null,
    isEditingTextStyle: false,
    textStyles,
  });
};

const handleEditButtonStyleSuccess: ActionHandler<State, typeof editButtonStyleSuccess> = (state, action) => {
  const { id, className } = action.payload;

  const buttonStyles = Object.values(state.buttonStyles).reduce<Record<string, Cms.ButtonStyle>>(
    (acc, cur) => (cur.id === id ? { ...acc, [className]: action.payload } : { ...acc, [cur.className]: cur }),
    {},
  );

  return update(state, {
    buttonStyles,
    errors: null,
    isEditingButtonStyle: false,
  });
};

const handleEditTextStyleFailure: ActionHandler<State, typeof editTextStyleFailure> = (state, action) => {
  return update(state, {
    errors: action.payload,
    isEditingTextStyle: false,
  });
};

const handleEditButtonStyleFailure: ActionHandler<State, typeof editButtonStyleFailure> = (state, action) => {
  return update(state, {
    errors: action.payload,
    isEditingButtonStyle: false,
  });
};

const handleTranslateDeletedTextStylesSuccess: ActionHandler<State, typeof translateDeletedTextStylesSuccess> = (state, action) => {
  return update(state, {
    missingCustomStyles: action.payload,
  });
};

const handleResetDeletedTextStyles: ActionHandler<State, typeof resetDeletedTextStyles> = state => {
  return update(state, {
    missingCustomStyles: [],
  });
};

const handleResetErrors: ActionHandler<State, typeof closeButtonStyleEditor | typeof closeTextStyleEditor> = state => {
  return update(state, {
    errors: null,
  });
};

export default createReducer<State, AppAction>(initialState)
  .handleAction(fetchCustomStylesRequest, handleFetchTextStylesRequest)
  .handleAction(fetchCustomStylesSuccess, handleFetchTextStylesSuccess)
  .handleAction(fetchCustomStylesFailure, handleFetchTextStylesFailure)

  .handleAction(createTextStyleRequest, handleCreateTextStyleRequest)
  .handleAction(createTextStyleSuccess, handleCreateTextStyleSuccess)
  .handleAction(createTextStyleFailure, handleCreateTextStyleFailure)

  .handleAction(createButtonStyleRequest, handleCreateButtonStyleRequest)
  .handleAction(createButtonStyleSuccess, handleCreateButtonStyleSuccess)
  .handleAction(createButtonStyleFailure, handleCreateButtonStyleFailure)

  .handleAction(editTextStyleRequest, handleEditTextStyleRequest)
  .handleAction(editTextStyleSuccess, handleEditTextStyleSuccess)
  .handleAction(editTextStyleFailure, handleEditTextStyleFailure)

  .handleAction(editButtonStyleRequest, handleEditButtonStyleRequest)
  .handleAction(editButtonStyleSuccess, handleEditButtonStyleSuccess)
  .handleAction(editButtonStyleFailure, handleEditButtonStyleFailure)

  .handleAction(deleteTextStyleSuccess, handleDeleteTextStyleSuccess)
  .handleAction(fetchPageBySlugSuccess, handleFetchPageBySlugSuccess)
  .handleAction(translateDeletedTextStylesSuccess, handleTranslateDeletedTextStylesSuccess)
  .handleAction(resetDeletedTextStyles, handleResetDeletedTextStyles)

  .handleAction(closeButtonStyleEditor, handleResetErrors)
  .handleAction(closeTextStyleEditor, handleResetErrors);
