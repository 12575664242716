/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 17;
const ORIGINAL_HEIGHT = 14;
const PROPORTION = ORIGINAL_HEIGHT / ORIGINAL_WIDTH;

export const User = ({ width = ORIGINAL_WIDTH }: IconComponentProps) => {
  return (
    <svg width={width} height={width * PROPORTION} viewBox="0 0 17 14" version="1.1">
      <g id="Page-1" stroke="none">
        <g id="Landing---Drawer-Open-Copy" transform="translate(-27.000000, -198.000000)">
          <path
            d="M33.6979401,211.706767 C29.2479245,211.706767 27.5812519,211.473433 27.514585,211.456766 C27.3645845,211.440099 27.214584,211.340099 27.1312504,211.206765 C27.0479167,211.073432 27.0145833,210.906764 27.0479167,210.756764 C27.3145843,209.506759 28.1312539,208.456756 29.2645912,207.756753 C29.5479255,207.590086 29.8979267,207.456752 30.2645947,207.306752 C30.914597,207.056751 31.6479329,206.75675 31.7812667,206.323415 C31.8979338,205.973414 31.6145995,205.440078 31.3645986,205.07341 C30.1312609,203.156737 29.9145935,201.373397 30.7145963,200.023393 C31.3479318,198.990056 32.4646024,198.373387 33.6979401,198.373387 C34.9479445,198.373387 36.0646151,198.990056 36.6812839,200.023393 C37.4979535,201.373397 37.281286,203.156737 36.0479484,205.056744 C35.7812808,205.440078 35.5146132,205.973414 35.6146135,206.323415 C35.7479473,206.75675 36.4812832,207.056751 37.1312855,207.306752 C37.4979535,207.456752 37.8479547,207.590086 38.131289,207.756753 C39.2812931,208.456756 40.0646292,209.506759 40.3479635,210.756764 C40.3812969,210.906764 40.3479635,211.073432 40.2646299,211.206765 C40.1646295,211.340099 40.0479624,211.440099 39.8979619,211.456766 C39.831295,211.473433 38.1479558,211.706767 33.6979401,211.706767 Z M38.7646246,201.040063 L43.0313063,201.040063 L43.0313063,202.106733 L38.7646246,202.106733 L38.7646246,201.040063 Z M43.0313063,204.240074 L38.7646246,204.240074 L38.7646246,203.173404 L43.0313063,203.173404 L43.0313063,204.240074 Z"
            id="userprofile"
          />
        </g>
      </g>
    </svg>
  );
};
