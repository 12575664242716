/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;
const ORIGINAL_COLOR = '#dadada';

export const Folder = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => {
  return (
    <svg width={width} height={width} viewBox="0 0 20 20">
      <path
        d="M4.4 15.5C4.015 15.5 3.68553 15.3655 3.4116 15.0964C3.1372 14.8269 3 14.5031 3 14.125V5.875C3 5.49687 3.1372 5.17329 3.4116 4.90425C3.68553 4.63475 4.015 4.5 4.4 4.5H8.6L10 5.875H15.6C15.985 5.875 16.3147 6.00975 16.5891 6.27925C16.863 6.54829 17 6.87188 17 7.25V14.125C17 14.5031 16.863 14.8269 16.5891 15.0964C16.3147 15.3655 15.985 15.5 15.6 15.5H4.4ZM4.4 5.875V14.125H15.6V7.25H9.4225L8.0225 5.875H4.4ZM4.4 5.875V14.125V5.875Z"
        fill={color}
      />
    </svg>
  );
};
