import { Cms } from '@typings';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EditorUIContext } from '../../../components/cms/context/EditorUIContext';
import {
  getButtonStyles,
  getCmsBlockById,
  getCurrentBlockId,
  getCurrentItem,
  getTextStyles,
  removeContentParts,
  resetCurentBlock,
  setCurrentItem,
  updateBlockSettings,
} from '../../../ducks';
import { getTextPartsOrderForBlock } from '../../../logic/cms/getTextPartsOrderForBlock';
import { applyCustomStyleOnPart } from '../../../logic/cms/styles';
import { getContentBlockPart } from '../../../logic/pages';
import { isDefined } from '../../is';

import { useToolbarVisibility } from './useToolbarVisibility';

export const useContentPartToolbar = (toolbarName: Cms.ToolbarType) => {
  const dispatch = useDispatch();

  const currentItem = useSelector(getCurrentItem);
  const currentBlockId = useSelector(getCurrentBlockId);
  const block = useSelector(getCmsBlockById(currentBlockId));
  const currentPartId = isDefined(currentItem.index) ? getTextPartsOrderForBlock(block)[currentItem.index] : null;
  const textStyles = useSelector(getTextStyles);
  const buttonStyles = useSelector(getButtonStyles);
  const isButton = toolbarName === 'Button';
  const { hideToolbar } = useToolbarVisibility(toolbarName);
  const { screenType } = React.useContext(EditorUIContext);

  const updateContentSettings = React.useCallback(
    (path: string[], value: Unrestricted) => {
      if (!isDefined(currentBlockId) || !isDefined(currentPartId)) {
        return;
      }

      dispatch(
        updateBlockSettings({
          blockId: currentBlockId,
          updatePath: ['text', 'parts', currentPartId].concat(path),
          value,
        }),
      );
    },
    [currentBlockId, currentPartId, dispatch],
  );

  const onStyleChange = React.useCallback(
    (partStyle: string) => {
      const part = getContentBlockPart(block, currentItem.index);
      if (!isDefined(part) || !isDefined(currentItem.element)) {
        return;
      }
      const stylesObject = isButton ? buttonStyles : textStyles;

      const partClassName = stylesObject[partStyle];
      if (!isDefined(partClassName)) {
        return;
      }
      updateContentSettings([], applyCustomStyleOnPart(screenType)(part, partClassName));
    },
    [block, currentItem.index, currentItem.element, isButton, buttonStyles, textStyles, updateContentSettings, screenType],
  );

  const closeToolbar = React.useCallback(
    (event?: MouseEvent) => {
      const relatedTarget = event?.relatedTarget;
      const clickedOnPart = relatedTarget instanceof Node && currentItem.element?.current?.contains(relatedTarget);
      if (!clickedOnPart) {
        requestAnimationFrame(() => {
          dispatch(resetCurentBlock());
        });
      }
      hideToolbar();
    },
    [currentItem.element, dispatch, hideToolbar],
  );

  const removeCurrentPart = React.useCallback(() => {
    if (!isDefined(currentBlockId) || !isDefined(currentPartId)) {
      return;
    }

    dispatch(
      removeContentParts({
        blockId: currentBlockId,
        partIds: [currentPartId],
      }),
    );
    hideToolbar();
    dispatch(setCurrentItem({ element: null }));
  }, [currentBlockId, currentPartId, dispatch, hideToolbar]);

  return {
    closeToolbar,
    onStyleChange,
    removeCurrentPart,
    updateContentSettings,
  };
};
