import {
  useMatrixDistributionContext,
  useMatrixPrepackContext,
  useMatrixQuantitiesContext,
} from '../../../components/products/ProductMatrix/context';

import { useMatrixLayout } from './useMatrixLayout';

export const useNavigationRange = () => {
  const { cellQuantitiesMap } = useMatrixQuantitiesContext();
  const { visiblePrepacksLength, shouldHidePrepackSwitchColumn } = useMatrixPrepackContext();
  const { distributionsOffset } = useMatrixDistributionContext();
  const { isTwoDimensional, hasMultipleRows } = useMatrixLayout();

  const prepacksMinRange = shouldHidePrepackSwitchColumn ? 0 : -1;

  const totalsRowOffset = hasMultipleRows ? 0 : -1;

  const verticalLengthBase = cellQuantitiesMap.length;
  const horizontalLengthBase = cellQuantitiesMap[0]?.length ?? 0;

  const horizontalLength = horizontalLengthBase + visiblePrepacksLength + distributionsOffset;
  const verticalLength = verticalLengthBase + totalsRowOffset;

  const getColumnNavigationRange = (columnIndex: number) => {
    const isPresetColumn = columnIndex < visiblePrepacksLength + distributionsOffset;
    const is2DPresetColumn = isPresetColumn && isTwoDimensional;

    if (is2DPresetColumn) {
      return {
        max: 0,
        min: 0,
      };
    }

    if (isPresetColumn) {
      return {
        max: verticalLength - 1,
        min: 0,
      };
    }

    return {
      max: verticalLength,
      min: 0,
    };
  };

  const getRowNavigationRange = (rowIndex: number) => {
    const prepacksRange = visiblePrepacksLength > 0 ? prepacksMinRange : 0;
    const isFirstRow = rowIndex === 0;
    const isLastRow = rowIndex === verticalLength;

    if (isFirstRow) {
      return {
        max: horizontalLength,
        min: prepacksRange,
      };
    }

    if (isTwoDimensional || isLastRow) {
      return {
        max: horizontalLength,
        min: visiblePrepacksLength + distributionsOffset,
      };
    }

    return {
      max: horizontalLength,
      min: prepacksRange,
    };
  };

  return {
    getColumnNavigationRange,
    getRowNavigationRange,
  };
};
