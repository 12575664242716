import { Toast } from '@typings';

import { isDefined } from '../../../../utils/is';

import { Action, ActionType } from './actions';
import { dispatch, State } from './store';

const REMOVE_TOAST_TIMEOUT = 1000;
const TOAST_LIMIT = 6;

const toastTimeouts = new Map<Toast['id'], ReturnType<typeof setTimeout>>();

const addToRemoveQueue = (toastId: string) => {
  if (toastTimeouts.has(toastId)) {
    return;
  }

  const timeout = setTimeout(() => {
    toastTimeouts.delete(toastId);
    dispatch({
      toastId,
      type: ActionType.REMOVE_TOAST,
    });
  }, REMOVE_TOAST_TIMEOUT);

  toastTimeouts.set(toastId, timeout);
};

const clearFromRemoveQueue = (toastId: string) => {
  const timeout = toastTimeouts.get(toastId);
  if (isDefined(timeout)) {
    clearTimeout(timeout);
  }
};

const handleAddToast = (state: State, toast: Toast) => {
  return {
    ...state,
    toasts: [toast, ...state.toasts].slice(0, TOAST_LIMIT),
  };
};

const handleUpdateToast = (state: State, toast: Partial<Toast>) => {
  if (isDefined(toast.id)) {
    clearFromRemoveQueue(toast.id);
  }

  return {
    ...state,
    toasts: state.toasts.map(stateToast => (stateToast.id === toast.id ? { ...stateToast, ...toast } : stateToast)),
  };
};

const handleUpsertToast = (state: State, toast: Toast) => {
  return state.toasts.find(toastFromState => toastFromState.id === toast.id) ?
      reducer(state, { toast, type: ActionType.UPDATE_TOAST })
    : reducer(state, { toast, type: ActionType.ADD_TOAST });
};

const handleDismissToast = (state: State, toastId: string | undefined) => {
  if (isDefined(toastId)) {
    addToRemoveQueue(toastId);
  } else {
    state.toasts.forEach(toast => {
      addToRemoveQueue(toast.id);
    });
  }

  return {
    ...state,
    toasts: state.toasts.map(toast => (toast.id === toastId || !isDefined(toastId) ? { ...toast, visible: false } : toast)),
  };
};

const handleRemoveToast = (state: State, toastId: string | undefined) => {
  if (!isDefined(toastId)) {
    return {
      ...state,
      toasts: [],
    };
  }

  return {
    ...state,
    toasts: state.toasts.filter(toast => toast.id !== toastId),
  };
};

const handleStartPause = (state: State, time: number) => {
  return {
    ...state,
    pausedAt: time,
  };
};

const handleEndPause = (state: State, time: number) => {
  const diff = time - (state.pausedAt ?? 0);

  return {
    ...state,
    pausedAt: undefined,
    toasts: state.toasts.map(toast => ({
      ...toast,
      pauseDuration: toast.pauseDuration + diff,
    })),
  };
};

const handleClearToasts = () => {
  return {
    pausedAt: undefined,
    toasts: [],
  };
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.ADD_TOAST:
      return handleAddToast(state, action.toast);

    case ActionType.UPDATE_TOAST:
      return handleUpdateToast(state, action.toast);

    case ActionType.UPSERT_TOAST:
      return handleUpsertToast(state, action.toast);

    case ActionType.DISMISS_TOAST:
      return handleDismissToast(state, action.toastId);

    case ActionType.REMOVE_TOAST:
      return handleRemoveToast(state, action.toastId);

    case ActionType.START_PAUSE:
      return handleStartPause(state, action.time);

    case ActionType.END_PAUSE:
      return handleEndPause(state, action.time);

    case ActionType.CLEAR_TOASTS:
      return handleClearToasts();
    default:
      return state;
  }
};
