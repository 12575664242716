import { ActionHandler, Cms } from '@typings';
import { createReducer } from 'typesafe-actions';

import { update } from '../../utils/update';
import { fetchOrderRequest } from '../order';

import * as actions from './actions';

export interface PagesReducer {
  isLoadingPage: boolean;
  isSaved: boolean;
  isSavingPage: boolean;
  isPublishing: boolean;
  currentPageSlug: string | null;
  pageData: Cms.BasePageData;
}

const initialPageData: Cms.BasePageData = {
  blocks: {},
  buttonStyles: [],
  groups: {},
  pageTitle: '',
  sections: [],
  textStyles: [],
};

const initialState: PagesReducer = {
  currentPageSlug: null,
  isLoadingPage: false,
  isPublishing: false,
  isSaved: true,
  isSavingPage: false,
  pageData: initialPageData,
};

const handlePagesRequest: ActionHandler<PagesReducer, typeof actions.fetchPageBySlugRequest> = (state, action) =>
  update(state, { currentPageSlug: action.payload, isLoadingPage: true });

const handleFetchPagesSuccess: ActionHandler<PagesReducer, typeof actions.fetchPageBySlugSuccess> = (state, action) => {
  const { pageData } = action.payload;

  return update(state, { isLoadingPage: false, pageData });
};

const resetPageState: ActionHandler<PagesReducer, typeof fetchOrderRequest> = state => update(state, { pageData: initialState.pageData });

const handleSavePageRequest: ActionHandler<PagesReducer, typeof actions.savePageRequest> = state =>
  update(state, { isSaved: false, isSavingPage: true });

const handleSavePageSuccess: ActionHandler<PagesReducer, typeof actions.savePageSuccess> = state =>
  update(state, { isSaved: true, isSavingPage: false });

const handleSavePageFailure: ActionHandler<PagesReducer, typeof actions.savePageFailure> = state =>
  update(state, { isSaved: false, isSavingPage: false });

const handlePublishPage: ActionHandler<PagesReducer, typeof actions.publishPageRequest> = state => update(state, { isPublishing: true });

const handlePublishPageChangeSuccess: ActionHandler<PagesReducer, typeof actions.publishPageChangeSuccess> = state =>
  update(state, { isPublishing: false, pageData: initialPageData });

const handlePublishPageChangeFailure: ActionHandler<PagesReducer, typeof actions.publishPageChangeFailure> = state =>
  update(state, { isPublishing: false });

export default createReducer<PagesReducer, AppAction>(initialState)
  .handleAction(actions.fetchPageBySlugRequest, handlePagesRequest)
  .handleAction(actions.fetchPageBySlugSuccess, handleFetchPagesSuccess)
  .handleAction(fetchOrderRequest, resetPageState)
  .handleAction(actions.savePageRequest, handleSavePageRequest)
  .handleAction(actions.savePageSuccess, handleSavePageSuccess)
  .handleAction(actions.savePageFailure, handleSavePageFailure)
  .handleAction(actions.publishPageRequest, handlePublishPage)
  .handleAction(actions.publishPageChangeSuccess, handlePublishPageChangeSuccess)
  .handleAction(actions.publishPageChangeFailure, handlePublishPageChangeFailure);
