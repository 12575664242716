import { Variants } from '@typings';

/**
 * returns first number which is divisible by `multipleOf` and is equal or bigger than minimumValue
 */
const getClosestMultipleOf = <T extends number>(value: T, multipleOf: T) => {
  const remainder = value % multipleOf;
  const offset = remainder === 0 ? 0 : multipleOf - remainder;

  return value + offset;
};

interface IsItemAvailableProps {
  stock: Variants.Stock;
  itemQuantityMinimum: number;
  backordersEnabled: boolean;
  itemQuantityMultipleOf: number;
  formattedItemQuantity: string;
}

export function getIsItemAvailable(options: IsItemAvailableProps): boolean {
  const { backordersEnabled, formattedItemQuantity, itemQuantityMinimum: minRequired, itemQuantityMultipleOf: multipleOf, stock } = options;

  // if back orders are enabled the item is always available
  if (backordersEnabled) {
    return true;
  }

  // if the formated quantity was set to '' it means it's not in stock -> see formatQuantity function
  if (formattedItemQuantity === '') {
    return false;
  }

  // if minimum multiple of value is bigger than stock then the item is not available
  return !(typeof stock === 'number' && getClosestMultipleOf(minRequired, multipleOf) > stock);
}
