import cx from 'classnames';
import React from 'react';

import Icon, { IconColor, IconType } from '../Icon';

import styles from './ExpandButton.module.scss';

interface Props {
  onClick: React.MouseEventHandler;
  isOpen: boolean;
}

export const ExpandButton = ({ isOpen, onClick }: Props) => (
  <button className={cx(styles.expandButton, { [styles.open]: isOpen })} onClick={onClick}>
    <Icon className={styles.icon} type={IconType.ArrowBlackDown} color={IconColor.Medium} />
  </button>
);
