/* eslint-disable max-len */

import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;
const ORIGINAL_COLOR = '#B284B9';

export const AddFilled = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => (
  <svg width={width} height={width} viewBox="0 0 20 20" fill="none">
    <rect width={width} height={width} rx={width / 2} fill={color} />
    <path
      d="M15.1429 10.8571H10.8571V15.1429C10.8571 15.6143 10.4714 16 10 16C9.52857 16 9.14286 15.6143 9.14286 15.1429V10.8571H4.85714C4.38571 10.8571 4 10.4714 4 10C4 9.52857 4.38571 9.14286 4.85714 9.14286H9.14286V4.85714C9.14286 4.38571 9.52857 4 10 4C10.4714 4 10.8571 4.38571 10.8571 4.85714V9.14286H15.1429C15.6143 9.14286 16 9.52857 16 10C16 10.4714 15.6143 10.8571 15.1429 10.8571Z"
      fill="white"
    />
  </svg>
);
