import { Id } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getCmsContentPartsCount, getCurrentItem, moveContentPart, setCurrentItem } from '../../../../ducks/cms';
import { isDefined } from '../../../../utils/is';
import { EditorUIContext } from '../../../cms/context/EditorUIContext';
import Icon, { IconType } from '../../../various/Icon';
import styles from '../contentBlock.module.scss';

interface Props {
  blockId: Nullable<Id>;
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
  index?: number;
}

export const ContentPartMover = ({ index, blockId, containerRef }: Props) => {
  const { t } = useTranslation(['cms']);
  const { screenType } = React.useContext(EditorUIContext);
  const dispatch = useDispatch();
  const currentItem = useSelector(getCurrentItem);
  const contentPartsCount = useSelector(getCmsContentPartsCount(blockId));

  React.useEffect(() => {
    dispatch(setCurrentItem({ element: containerRef, index }));
  }, [containerRef, dispatch, index]);

  const moveText = React.useCallback(
    (offset: number) => {
      if (!isDefined(index) || !isDefined(blockId)) {
        return;
      }
      dispatch(moveContentPart({ blockId, index, offset }));
      dispatch(setCurrentItem({ ...currentItem, index: index + offset }));
    },
    [index, blockId, dispatch, currentItem],
  );

  const moveTextUp = React.useCallback(() => moveText(-1), [moveText]);
  const moveTextDown = React.useCallback(() => moveText(1), [moveText]);

  const isMoveUpDisabled = index === 0;
  const isMoveDownDisabled = contentPartsCount - 1 === index;
  const isTextMovementEnabled = screenType === 'desktop' && contentPartsCount > 1;

  if (!isTextMovementEnabled) {
    return null;
  }

  return (
    <div className={styles.textMover}>
      <button
        aria-label={t('cms:move_text_up')}
        className={cx(styles.moveTextButton, {
          [styles.disabled]: isMoveUpDisabled,
        })}
        onClick={moveTextUp}
        disabled={isMoveUpDisabled}
        data-testid="moveTextUp"
      >
        <Icon type={IconType.MoveUp} />
      </button>

      <button
        aria-label={t('cms:move_text_down')}
        className={cx(styles.moveTextButton, {
          [styles.disabled]: isMoveDownDisabled,
        })}
        onClick={moveTextDown}
        disabled={isMoveDownDisabled}
        data-testid="moveTextDown"
      >
        <Icon type={IconType.MoveDown} />
      </button>
    </div>
  );
};
