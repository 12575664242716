import React from 'react';

import { useOrdersSearchParams } from '../../components/orders/useOrdersSearchParams';
import { DEFAULT_ITEMS_COUNT_PER_REQUEST } from '../../constants/limits';
import { isDefined } from '../is';

interface Props {
  ordersCountTotal?: number;
  skipFirst: number;
}

export const useSyncPageAfterDelete = ({ ordersCountTotal, skipFirst }: Props) => {
  const { handlePageChange } = useOrdersSearchParams();

  React.useEffect(() => {
    if (!isDefined(ordersCountTotal) || skipFirst < ordersCountTotal || skipFirst === 0) {
      return;
    }

    const lastPageWithResults = Math.ceil(ordersCountTotal / DEFAULT_ITEMS_COUNT_PER_REQUEST);
    handlePageChange(lastPageWithResults);
  }, [handlePageChange, ordersCountTotal, skipFirst]);
};
