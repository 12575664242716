import * as Sentry from '@sentry/react';
import { Cms } from '@typings';
import { nanoid } from 'nanoid';
import { insert, range, remove, update } from 'ramda';

import { MAX_COLUMN_SPAN, MAX_COLUMNS } from '../../constants/cms';
import { isDefined } from '../../utils/is';
import { isEmpty } from '../../utils/isEmpty';

import { getRowsRange } from './structuralBlocks';

export function isCustomTemplate(customTemplate: Cms.CustomTemplate | Cms.BlockModel[]): customTemplate is Cms.CustomTemplate {
  return !Array.isArray(customTemplate);
}

export const findNextBlockPosition = ({
  blocks,
  newBlock,
  screenType,
  row = 1,
}: {
  blocks: Cms.CustomBlockModel[];
  newBlock: Cms.CustomBlockModel;
  screenType: Cms.ScreenType;
  row?: number;
}): { startX: number; startY: number } => {
  const currentX = blocks.reduce((acc, obj) => {
    const position = obj.position[screenType];
    if (position.startY <= row && row < position.startY + position.spanY) {
      return Math.max(acc, position.startX + position.spanX);
    }

    return acc;
  }, 1);

  const nextPositionConflicts = checkBlockForConflicts(
    blocks,
    {
      ...newBlock,
      position: { ...newBlock.position, [screenType]: { ...newBlock.position[screenType], startX: currentX, startY: row } },
    },
    screenType,
  );

  if (currentX + newBlock.position[screenType].spanX <= MAX_COLUMN_SPAN[screenType] && isEmpty(nextPositionConflicts)) {
    return { startX: currentX, startY: row };
  }

  return findNextBlockPosition({ blocks, newBlock, row: row + 1, screenType });
};

export const areOverlapping = (block1: Cms.BlockModel, block2: Cms.BlockModel, screenType: Cms.ScreenType) => {
  const { startX: startX1, startY: startY1, spanX: spanX1, spanY: spanY1 } = block1.position[screenType];
  const { startX: startX2, startY: startY2, spanX: spanX2, spanY: spanY2 } = block2.position[screenType];
  const overlapX = startX1 < startX2 + spanX2 && startX1 + spanX1 > startX2;
  const overlapY = startY1 < startY2 + spanY2 && startY1 + spanY1 > startY2;

  return overlapX && overlapY;
};

const checkBlockForConflicts = (blocks: Cms.CustomBlockModel[], blockToCheck: Cms.CustomBlockModel, screenType: Cms.ScreenType) => {
  return blocks
    .filter(block => block.id !== blockToCheck.id && areOverlapping(block, blockToCheck, screenType))
    .sort(sortCustomBlocks(screenType));
};

interface MoveBlocksArgs {
  blocks: Cms.CustomBlockModel[];
  block: Cms.CustomBlockModel;
  screenType: Cms.ScreenType;
}

const moveBlock = ({ blocks, block, screenType }: MoveBlocksArgs): Cms.CustomBlockModel[] => {
  return blocks.map(blockItem =>
    block.id === blockItem.id ?
      {
        ...block,
        position: {
          ...blockItem.position,
          [screenType]: {
            ...block.position[screenType],
          },
        },
      }
    : blockItem,
  );
};

const isValidGrid = (blocks: Cms.CustomBlockModel[], screenType: Cms.ScreenType) => {
  return !blocks.some(block => {
    const position = block.position[screenType];
    const overlapTop = position.startY < 1;
    const overlapLeft = position.startX < 1;
    const overlapRight = position.startX + position.spanX > MAX_COLUMN_SPAN[screenType];

    if (overlapTop || overlapLeft || overlapRight) {
      return true;
    }

    return blocks.some(secondBlock => secondBlock.id !== block.id && areOverlapping(block, secondBlock, screenType));
  });
};

interface UpdateBlockArgs {
  blocks: Cms.CustomBlockModel[];
  updatedBlock: Cms.CustomBlockModel;
  screenType: Cms.ScreenType;
  touchedTablet: boolean;
  touchedMobile: boolean;
  collapseInitially: boolean;
}

export const updateBlock = ({ blocks, screenType, touchedMobile, touchedTablet, updatedBlock, collapseInitially }: UpdateBlockArgs) => {
  if (screenType === 'desktop') {
    const newBlock = {
      ...updatedBlock,
      position: {
        ...updatedBlock.position,
        mobile: {
          ...updatedBlock.position.mobile,
          ...getBlockDimensionsForDevice(updatedBlock, 'mobile', touchedMobile),
        },
        tablet: {
          ...updatedBlock.position.tablet,
          ...getBlockDimensionsForDevice(updatedBlock, 'tablet', touchedTablet),
        },
      },
    };

    const desktopBlocks = [...placeBlock({ blockToPlace: newBlock, blocks, collapseInitially, screenType: 'desktop' })].sort(
      sortCustomBlocks('desktop'),
    );

    const tabletBlocks =
      touchedTablet ?
        [...placeBlock({ blockToPlace: newBlock, blocks: desktopBlocks, collapseInitially: true, screenType: 'tablet' })]
      : syncDesktopBlocksToScreenType(desktopBlocks, 'tablet');

    const mobileBlocks =
      touchedMobile ?
        [...placeBlock({ blockToPlace: newBlock, blocks: tabletBlocks, collapseInitially: true, screenType: 'mobile' })]
      : syncDesktopBlocksToScreenType(tabletBlocks, 'mobile');

    return [...mobileBlocks];
  }

  return placeBlock({ blockToPlace: updatedBlock, blocks, collapseInitially, screenType });
};

const getBlockDimensionsForDevice = (block: Cms.CustomBlockModel, screenType: Cms.ScreenType, touchedScreenType: boolean) => {
  const isProductBlock = block.blockType === 'product';
  const blockArea = block.position.desktop.spanX * block.position.desktop.spanY;

  if (isProductBlock) {
    const maxSpanX = MAX_COLUMN_SPAN[screenType];
    const blockSpanX = Math.min(blockArea, block.position[screenType].spanX, maxSpanX);

    return touchedScreenType ?
        { spanX: blockSpanX, spanY: Math.ceil(blockArea / blockSpanX) }
      : getBlockScreenTypeDefaultSize(block, screenType);
  }

  return touchedScreenType ?
      { spanX: block.position[screenType].spanX, spanY: block.position[screenType].spanY }
    : getBlockScreenTypeDefaultSize(block, screenType);
};

const INITIAL_TEMPLATE_CONTENT: Cms.BlockModel = {
  blockType: 'content',
  position: {
    desktop: {
      spanX: 6,
      spanY: 1,
      startX: 1,
      startY: 1,
    },
    mobile: {
      spanX: 2,
      spanY: 1,
      startX: 1,
      startY: 1,
    },
    tablet: {
      spanX: 3,
      spanY: 1,
      startX: 1,
      startY: 1,
    },
  },
};

const INITIAL_TEMPLATE_PRODUCT: Cms.BlockModel = {
  blockType: 'product',
  position: {
    desktop: {
      spanX: 6,
      spanY: 1,
      startX: 1,
      startY: 1,
    },
    mobile: {
      spanX: 2,
      spanY: 3,
      startX: 1,
      startY: 1,
    },
    tablet: {
      spanX: 3,
      spanY: 2,
      startX: 1,
      startY: 1,
    },
  },
};

export const addNewBlock = (blocks: Cms.CustomBlockModel[], blockType: Cms.BlockType) => {
  const initialTemplate = blockType === 'product' ? INITIAL_TEMPLATE_PRODUCT : INITIAL_TEMPLATE_CONTENT;
  const newBlock: Cms.CustomBlockModel = { ...initialTemplate, id: nanoid() };
  const nextDesktopBlockPosition = findNextBlockPosition({ blocks, newBlock, screenType: 'desktop' });
  const nextTabletBlockPosition = findNextBlockPosition({ blocks, newBlock, screenType: 'tablet' });
  const nextMobileBlockPosition = findNextBlockPosition({ blocks, newBlock, screenType: 'mobile' });
  const positionedBlock: Cms.CustomBlockModel = {
    ...newBlock,
    position: {
      desktop: {
        ...newBlock.position.desktop,
        startX: nextDesktopBlockPosition.startX,
        startY: nextDesktopBlockPosition.startY,
      },
      mobile: {
        ...newBlock.position.mobile,
        startX: nextMobileBlockPosition.startX,
        startY: nextMobileBlockPosition.startY,
      },
      tablet: {
        ...newBlock.position.tablet,
        startX: nextTabletBlockPosition.startX,
        startY: nextTabletBlockPosition.startY,
      },
    },
  };

  return [...blocks, positionedBlock];
};

export const removeBlock = (blocks: Cms.CustomBlockModel[], index: number) => {
  const updatedBlocks = remove(index, 1, blocks);

  if (isEmpty(updatedBlocks)) {
    return updatedBlocks;
  }

  const collapsedDesktop = collapseEmptyRows(updatedBlocks, 'desktop');
  const collapsedTablet = collapseEmptyRows(collapsedDesktop, 'tablet');

  return collapseEmptyRows(collapsedTablet, 'mobile');
};

interface PlaceBlockArgs {
  blocks: Cms.CustomBlockModel[];
  blockToPlace: Cms.CustomBlockModel;
  screenType: Cms.ScreenType;
  collapseInitially: boolean;
}

export const placeBlock = ({ blocks, blockToPlace, screenType, collapseInitially }: PlaceBlockArgs): Cms.CustomBlockModel[] => {
  const blockIndex = blocks.findIndex(block => block.id === blockToPlace.id);

  const updatedBlock = {
    ...blockToPlace,
    position: {
      ...blockToPlace.position,
      [screenType]: blockToPlace.position[screenType],
    },
  };
  const filteredBlocks = blocks.filter(block => blockToPlace.id !== block.id);
  const collapsedBlocks = collapseInitially ? collapseEmptyRows(filteredBlocks, screenType) : filteredBlocks;
  const initialBlocks = insert(blockIndex, updatedBlock, collapsedBlocks);

  const isValidLayoutBefore = isValidGrid(initialBlocks, screenType);
  if (isValidLayoutBefore) {
    return collapseEmptyRows(initialBlocks, screenType);
  }

  const directions = ['left', 'right', 'up', 'down'] as const;
  const movedBlocks = moveBlock({
    block: blockToPlace,
    blocks,
    screenType,
  });

  for (const direction of directions) {
    const tempBlocks = cascadeMove({ blockToPlace, blocks: movedBlocks, direction, screenType });
    if (isValidGrid(tempBlocks, screenType)) {
      return collapseEmptyRows(tempBlocks, screenType);
    }
  }

  return blocks;
};

export const areBlocksInTheSameOrder = (firstArray: Cms.CustomBlockModel[], secondArray: Cms.CustomBlockModel[]) => {
  if (firstArray.length !== secondArray.length) {
    return false;
  }

  return firstArray.every((block, index) => {
    const matchingBlock = secondArray[index];

    return isDefined(matchingBlock) && block.id === matchingBlock.id;
  });
};

export const areBlockDimmensionsEqual = (firstBlock: Cms.BlockDimensions, secondBlock: Cms.BlockDimensions) => {
  return firstBlock.spanX === secondBlock.spanX && firstBlock.spanY === secondBlock.spanY;
};

export const areBlocksDefaultSize = (blocks: Cms.CustomBlockModel[], screenType: 'tablet' | 'mobile') => {
  return blocks.every(block => {
    const defaultScreenTypeBlockDimension = getBlockScreenTypeDefaultSize(block, screenType);

    return block.position[screenType].startX === 1 && areBlockDimmensionsEqual(defaultScreenTypeBlockDimension, block.position[screenType]);
  });
};

const getBlockScreenTypeDefaultSize = (block: Cms.CustomBlockModel, screenType: Cms.ScreenType) => {
  const isProductBlock = block.blockType === 'product';
  const blockArea = block.position.desktop.spanX * block.position.desktop.spanY;
  const blockSpanX = Math.min(block.position.desktop.spanX, MAX_COLUMNS[screenType]);

  return {
    spanX: isProductBlock ? blockSpanX : MAX_COLUMNS[screenType],
    spanY: isProductBlock ? Math.ceil(blockArea / blockSpanX) : block.position.desktop.spanY,
  };
};

export const sortCustomBlocks = (screenType: Cms.ScreenType) => (a: Cms.CustomBlockModel, b: Cms.CustomBlockModel) => {
  if (a.position[screenType].startY === b.position[screenType].startY) {
    return a.position[screenType].startX - b.position[screenType].startX;
  }

  return a.position[screenType].startY - b.position[screenType].startY;
};

export const syncDesktopBlocksToScreenType = (blocks: Cms.CustomBlockModel[], screenType: Cms.ScreenType): Cms.CustomBlockModel[] => {
  return blocks.reduce((acc: Cms.CustomBlockModel[], block) => {
    const newBlockSpan = getBlockScreenTypeDefaultSize(block, screenType);
    const modifiedBlock = {
      ...block,
      position: {
        ...block.position,
        [screenType]: { ...block.position[screenType], ...newBlockSpan },
      },
    };

    const nextBlockPosition = findNextBlockPosition({ blocks: acc, newBlock: modifiedBlock, screenType });
    const blockOverride = {
      ...newBlockSpan,
      startX: nextBlockPosition.startX,
      startY: nextBlockPosition.startY,
    };

    return [
      ...acc,
      {
        ...block,
        position: {
          ...block.position,
          [screenType]: blockOverride,
        },
      },
    ];
  }, []);
};

const getNextPositionInDirection = (
  position: Cms.BlockPositionRelativeProps,
  direction: 'up' | 'down' | 'left' | 'right',
): Partial<Cms.BlockPositionRelativeProps> => {
  if (direction === 'right') {
    return { startX: position.startX + 1 };
  }
  if (direction === 'left') {
    return { startX: position.startX - 1 };
  }
  if (direction === 'up') {
    return { startY: position.startY - 1 };
  }

  return {
    startY: position.startY + 1,
  };
};

const getNextScreenTypePosition = (
  position: Required<Cms.ScreenRelative<Cms.BlockPositionRelativeProps>>,
  screenType: Cms.ScreenType,
  direction: 'up' | 'down' | 'left' | 'right',
): Required<Cms.ScreenRelative<Cms.BlockPositionRelativeProps>> => {
  return {
    ...position,
    [screenType]: {
      ...position[screenType],
      ...getNextPositionInDirection(position[screenType], direction),
    },
  };
};

interface CascadeMoveArgs {
  blocks: Cms.CustomBlockModel[];
  blockToPlace: Cms.CustomBlockModel;
  screenType: Cms.ScreenType;
  direction: 'up' | 'down' | 'left' | 'right';
  originalBlock?: Cms.CustomBlockModel;
  initialConflicts?: Cms.CustomBlockModel[];
  iteration?: number;
}
const cascadeMove = ({
  blocks,
  blockToPlace,
  screenType,
  direction,
  originalBlock,
  initialConflicts,
  iteration = 0,
}: CascadeMoveArgs): Cms.CustomBlockModel[] => {
  if (iteration > blocks.length * blocks.length) {
    return [...blocks];
  }

  const conflicts = initialConflicts ?? checkBlockForConflicts([...blocks], blockToPlace, screenType);

  const newBlocks = conflicts.reduce(
    (acc, conflict) => {
      const index = acc.findIndex(block => block.id === conflict.id);
      const nextPosition: Cms.CustomBlockModel = {
        ...conflict,
        position: { ...getNextScreenTypePosition(conflict.position, screenType, direction) },
      };

      const updatedBlocks = update(index, nextPosition, acc);

      const nextLevelConflicts = checkBlockForConflicts(updatedBlocks, nextPosition, screenType).filter(block => {
        if (originalBlock) {
          return block.id !== originalBlock.id;
        }

        return block.id !== blockToPlace.id;
      });

      return cascadeMove({
        blockToPlace: nextPosition,
        blocks: [...updatedBlocks],
        direction,
        initialConflicts: nextLevelConflicts,
        iteration: iteration + 1,
        originalBlock: originalBlock ?? blockToPlace,
        screenType,
      });
    },
    [...blocks],
  );

  const nextConflicts = checkBlockForConflicts(newBlocks, blockToPlace, screenType).filter(block => {
    if (originalBlock) {
      return block.id !== originalBlock.id;
    }

    return block.id !== blockToPlace.id;
  });

  if (nextConflicts.length > 0) {
    return cascadeMove({ blockToPlace, blocks: [...newBlocks], direction, screenType });
  }

  return newBlocks;
};

export const collapseEmptyRows = (updatedBlocks: Cms.CustomBlockModel[], screenType: Cms.ScreenType): Cms.CustomBlockModel[] => {
  const collapsedResult = getRowsRange(updatedBlocks, screenType).reduce(
    (acc: { emptyRowSpan: number; blocks: Cms.CustomBlockModel[] }, currentRow) => {
      const hasBlocks = acc.blocks.some(block => {
        return (
          block.position[screenType].startY <= currentRow &&
          currentRow < block.position[screenType].startY + block.position[screenType].spanY
        );
      });
      if (!hasBlocks) {
        return { ...acc, emptyRowSpan: acc.emptyRowSpan + 1 };
      }

      if (acc.emptyRowSpan > 0) {
        const collapsedRows = acc.blocks.map(block => {
          const shouldCollapse = block.position[screenType].startY > currentRow - acc.emptyRowSpan;

          return shouldCollapse ?
              {
                ...block,
                position: {
                  ...block.position,
                  [screenType]: { ...block.position[screenType], startY: block.position[screenType].startY - acc.emptyRowSpan },
                },
              }
            : block;
        });

        return { blocks: collapsedRows, emptyRowSpan: 0 };
      }

      return acc;
    },
    { blocks: [...updatedBlocks], emptyRowSpan: 0 },
  );

  return collapsedResult.blocks;
};

export const countEmptyRows = (blocks: Cms.CustomBlockModel[], screenType: Cms.ScreenType, rowsCount: number): number => {
  const rows = range(0, rowsCount);

  return rows.reduce((acc: number, _, index) => {
    const currentRow = index + 1;
    const hasBlocks = blocks.some(block => {
      return (
        block.position[screenType].startY <= currentRow && currentRow < block.position[screenType].startY + block.position[screenType].spanY
      );
    });

    return !hasBlocks ? acc + 1 : acc;
  }, 0);
};

export const getFillerGrid = (
  items: Cms.CustomBlockModel[],
  screenType: Cms.ScreenType,
  activeBlock: Cms.CustomBlockModel,
): Cms.CustomBlockModel[] => {
  const gridHeight = getRowsRange(items, screenType).length;
  const activeSpanX = activeBlock.position[screenType].spanX;
  const activeSpanY = activeBlock.position[screenType].spanY;
  const itemsWithoutActiveBlock = items.filter(item => item.id !== activeBlock.id);
  const emptyRowSpan = countEmptyRows(itemsWithoutActiveBlock, screenType, gridHeight);
  const rowsAmount = gridHeight - activeBlock.position[screenType].spanY + 1;

  const fillGrid = (startRow: number, maxRow: number): Cms.CustomBlockModel[] =>
    range(startRow, maxRow + 1).flatMap(row =>
      range(1, MAX_COLUMN_SPAN[screenType] - activeSpanX + 1).map(col => {
        const screenPosition = { spanX: activeSpanX, spanY: activeSpanY, startX: col, startY: row };

        return {
          blockType: 'content',
          id: `filler-${row}-${col}`,
          isFiller: true,
          position: {
            desktop: screenPosition,
            mobile: screenPosition,
            tablet: screenPosition,
          },
        };
      }),
    );

  return [
    ...fillGrid(1, rowsAmount),
    ...(activeSpanY - emptyRowSpan > 0 ? fillGrid(rowsAmount + 1, rowsAmount + activeSpanY - emptyRowSpan) : []),
  ];
};

export const parseBackendCustomTemplateResponse = (layout: Cms.CustomLayoutData): Cms.CustomTemplate | null => {
  try {
    const layoutBlocks: Cms.CustomBlockModel[] = JSON.parse(layout.template);

    return {
      id: layout.id,
      name: layout.name,
      templateBlocks: layoutBlocks,
    };
  } catch (error) {
    Sentry.captureException(error);

    return null;
  }
};
