/* eslint-disable max-len */

import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 22;
const ORIGINAL_HEIGHT = 20;
const ORIGINAL_COLOR = '#5C5C5C';
const PROPORTION = ORIGINAL_HEIGHT / ORIGINAL_WIDTH;

export const DeviceDesktop = ({ color = ORIGINAL_COLOR, width = ORIGINAL_WIDTH }: IconComponentProps) => {
  return (
    <svg width={width} height={width * PROPORTION} viewBox="0 0 22 20" fill="none">
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M2.273.314C1.676.444 1.066.908.735 1.485a2.786 2.786 0 0 0-.191.458l-.086.275V8.36c0 5.859.003 6.152.068 6.362a2.552 2.552 0 0 0 1.637 1.655c.19.063.435.068 4.153.078l3.951.011V18.223l-1.238.001c-1.286 0-1.615.026-1.767.139-.196.146-.24.249-.251.587-.009.276.001.337.074.463a.72.72 0 0 0 .212.22c.127.075.162.075 3.703.075s3.576 0 3.703-.075a.72.72 0 0 0 .212-.22c.073-.126.083-.187.074-.463-.011-.338-.055-.441-.251-.587-.152-.113-.481-.139-1.767-.139l-1.238-.001V16.466l3.951-.011c3.718-.01 3.963-.015 4.153-.078a2.552 2.552 0 0 0 1.637-1.655c.065-.21.068-.503.068-6.362V2.218l-.104-.317c-.261-.796-.946-1.423-1.74-1.591C19.332.233 2.633.236 2.273.314m17.364 1.575c.111.069.222.18.29.291l.111.179.01 4.266.01 4.265H1.943V6.672c0-4.59-.011-4.324.202-4.604a.967.967 0 0 1 .282-.213l.192-.095 8.419.009 8.419.009.18.111m.419 11.43c-.001.853-.009.979-.068 1.11a1.004 1.004 0 0 1-.462.462c-.142.065-.49.067-8.526.067s-8.384-.002-8.526-.067a1.004 1.004 0 0 1-.462-.462c-.059-.131-.067-.257-.068-1.11l-.001-.962h18.114l-.001.962"
        fillRule="evenodd"
        fill={color}
      />
    </svg>
  );
};
