import { Selects } from '@typings';
import React from 'react';

import { getChildrenByComponent } from '../../../../utils/getChildrenByComponent';
import { getChildrenText } from '../../../../utils/getChildrenText';
import { isDefined } from '../../../../utils/is';

import { SelectContextProvider } from './context/SelectContext';
import { Option } from './Option';
import { Options } from './Options';
import { SelectButton } from './SelectTrigger';

type Props = Pick<Selects.SelectProps, 'shouldMatchTriggerWidth' | 'isScrollable'>;

const SelectComponent = ({ children, shouldMatchTriggerWidth, isScrollable }: React.PropsWithChildren<Props>) => {
  const validChildren = getChildrenByComponent(children, Option).map((child, index) => React.cloneElement(child, { index }));

  const options = React.useMemo(() => {
    return validChildren.map(child => ({
      label: child.props.children,
      selectedLabel: child.props.selectedLabel,
      value: child.props.value,
    }));
  }, [validChildren]);

  const renderValue = (value: Selects.NonNullableValue) => {
    const selectedLabel = validChildren.find(child => child.props.value === value)?.props.selectedLabel;

    if (isDefined(selectedLabel)) {
      return selectedLabel;
    }

    const optionChildren = validChildren.find(child => child.props.value === value)?.props.children;

    if (isDefined(optionChildren)) {
      return getChildrenText(optionChildren);
    }

    return value.toString();
  };

  return (
    <Options
      trigger={<SelectButton options={options} renderValue={renderValue} />}
      isScrollable={isScrollable}
      shouldMatchTriggerWidth={shouldMatchTriggerWidth}
    >
      {validChildren}
    </Options>
  );
};

export const Select = React.memo(
  <T extends Selects.Value>({ children, shouldMatchTriggerWidth, isScrollable, ...contextProps }: Selects.SelectProps<T>) => {
    return (
      <SelectContextProvider {...contextProps}>
        <SelectComponent shouldMatchTriggerWidth={shouldMatchTriggerWidth} isScrollable={isScrollable}>
          {children}
        </SelectComponent>
      </SelectContextProvider>
    );
  },
);
