import { useTranslation } from 'react-i18next';

import styles from './SelectedElementsInfo.module.scss';

interface Props {
  selected: number;
  total: number;
}

export const SelectedElementsInfo = ({ selected, total }: Props) => {
  const { t } = useTranslation(['cms']);

  return <span className={styles.text}>{t('cms:selected_elements', { selected, total })}</span>;
};
