import { Preset, Product, UnitsDistribution } from '@typings';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { createDistributionSet, distributeToMaximumAvailable, getDistributionByItem } from '../../../../../../../logic/unitsDistribution';
import { distributeToOtherAvailable } from '../../../../../../../logic/unitsDistribution/distributeToOtherAvailable';
import { useMatrixPresets } from '../../../../../../../utils/hooks/matrix/useMatrixPresets';
import { useVariantQuantities } from '../../../../../../../utils/hooks/useVariantQuantities';
import { useMatrixDistributionContext } from '../../../../../../products/ProductMatrix/context';
import { Alert } from '../../../../../Alert';
import Icon, { IconColor, IconType } from '../../../../../Icon';

import { ModeForm } from './Forms/ModeForm';
import { QuantitiesForm } from './Forms/QuantitiesForm';
import styles from './PresetsPopoverContent.module.scss';
import { useApplyDistribution } from './useApplyDistribution';

interface Props {
  variant: Product.Standard;
  deliveryWindowId: string;
  close: () => void;
  distributions: Preset[];
}

export const PresetsPopoverContent = ({ close, distributions, variant, deliveryWindowId }: Props) => {
  const {
    quantitiesFormMethods,
    distributionPreview,
    distributionStage,
    setDistributionStage,
    setDistributionResult,
    distributionResult,
    closeDistributionPopup,
  } = useMatrixDistributionContext();
  const { t } = useTranslation(['common', 'orders']);
  const { applyDistribution } = useApplyDistribution({
    deliveryWindowId,
    distributionVariant: variant,
  });
  const { productDistributions } = useMatrixPresets();
  const { availableToDistribute } = useVariantQuantities({ deliveryWindowId, variant });

  const isPreviewStage = distributionStage === 'preview';

  const calculateDistributionResult = React.useCallback(
    (mode: UnitsDistribution.Mode) => {
      const distributionsSet = createDistributionSet(quantitiesFormMethods.getValues(), productDistributions);
      const distributionPayload = { availableQuantities: availableToDistribute, distributions: distributionsSet };

      const result =
        mode === 'maxAvailable' ? distributeToMaximumAvailable(distributionPayload) : distributeToOtherAvailable(distributionPayload);

      return getDistributionByItem(result, variant.items);
    },
    [availableToDistribute, quantitiesFormMethods, productDistributions, variant.items],
  );

  const submitDistributionPreview = () => {
    const result = calculateDistributionResult('maxAvailable');
    const areDistributionsEqual = Object.entries(distributionPreview).every(([itemId, item]) => {
      const resultItem = result[itemId];

      return resultItem?.quantity === item.quantity;
    });

    if (areDistributionsEqual) {
      applyDistribution(result);

      return;
    }

    setDistributionResult(result);
    setDistributionStage('stockCorrection');
  };

  const submitDistributionCorrection = () => {
    applyDistribution(distributionResult);
    closeDistributionPopup();
  };

  const updateDistributionMode = React.useCallback(
    (distributionMode: UnitsDistribution.Mode) => {
      const result = calculateDistributionResult(distributionMode);

      setDistributionResult(result);
    },
    [calculateDistributionResult, setDistributionResult],
  );

  return (
    <>
      <div data-testid="addDistributionsPopup" className={styles.presetContainer}>
        <div className={styles.title}>
          <Trans t={t} i18nKey="orders:distribute_sizes_for_item" values={{ item: variant.variantName }}>
            Distribute sizes for <span>{variant.variantName}</span>
          </Trans>
        </div>
        {isPreviewStage ?
          <div className={styles.description}>{t('orders:distribute_description')}</div>
        : <Alert type="warning" message={t('orders:distribution_warning')} />}
        {isPreviewStage ?
          <QuantitiesForm close={close} onSubmit={submitDistributionPreview} variant={variant} distributions={distributions} />
        : <ModeForm onModeChange={updateDistributionMode} onSubmit={submitDistributionCorrection} />}
      </div>
      <button type="button" onClick={close} aria-label={t('common:close')} className={styles.closeButton}>
        <Icon type={IconType.Cancel} color={IconColor.Gray} size={16} />
      </button>
    </>
  );
};
