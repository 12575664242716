import { useIsMutating } from '@tanstack/react-query';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  getIsLoadingMoreProducts,
  getIsLoadingProducts,
  getIsLoggedIn,
  getIsProductsGridView,
  getProducts,
  getProductsOrder,
  getProductsPageCount,
  getProductsTotalItems,
  loadMoreProductsRequest,
} from '../../ducks';
import { getOrderDetails } from '../../ducks/order';
import { getUrlForProductAndVariant } from '../../logic/products';
import { paths } from '../../paths';
import { DELETE_SELECTIONS_MUTATION_KEY } from '../../services/hooks/orders/useDeleteSelections';
import { useAmplitude } from '../../utils/hooks/useAmplitude';
import { isDefined } from '../../utils/is';
import { isEmpty } from '../../utils/isEmpty';
import { CallToAction } from '../orders/CallToAction';
import { OrderTopBar } from '../orders/OrderTopBar/OrderTopBar';
import { BarcodeScannerModal } from '../various/BarcodeScannerModal';
import { BottomWrapper } from '../various/BottomWrapper';
import Button from '../various/Button';
import DefaultLoader from '../various/loaders/DefaultLoader';
import { Notification } from '../various/Notification';
import { PageTitle } from '../various/PageTitle';
import { Wrapper, WrapperSize } from '../various/Wrapper';

import { FiltersPanel } from './FiltersBar/FiltersPanel';
import styles from './AllProducts.module.scss';
import { NoResults } from './NoResults';
import ProductsActions from './ProductsActions';
import { ProductsList } from './ProductsList';

export const AllProducts = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'products', 'accounts', 'selections']);
  const isDeletingAnySelection = useIsMutating({ mutationKey: [DELETE_SELECTIONS_MUTATION_KEY] }) > 0;
  const isLoadingProducts = useSelector(getIsLoadingProducts);
  const isUserLoggedIn = useSelector(getIsLoggedIn);
  const orderDetails = useSelector(getOrderDetails);
  const products = useSelector(getProducts);
  const productsOrder = useSelector(getProductsOrder);
  const isProductsGridView = useSelector(getIsProductsGridView);
  const isLoadingMoreProducts = useSelector(getIsLoadingMoreProducts);
  const totalItems = useSelector(getProductsTotalItems);
  const pageCount = useSelector(getProductsPageCount);

  const { trackEvent } = useAmplitude();

  const orderedProducts = React.useMemo(() => productsOrder.map(id => products[id]).filter(isDefined), [products, productsOrder]);

  const title = React.useMemo(() => {
    if (!isDefined(orderDetails.order.orderNumber)) {
      return t('products:product_other');
    }

    return `${t('selections:selection_number', { orderNumber: orderDetails.order.orderNumber })} - ${t('products:product_other')}`;
  }, [orderDetails.order.orderNumber, t]);

  React.useEffect(() => {
    trackEvent({ name: isProductsGridView ? 'listing.view.grid' : 'listing.view.list' });
  }, [isProductsGridView, trackEvent]);

  const hasProducts = !isEmpty(productsOrder);

  const loadMoreProducts = React.useCallback(() => {
    dispatch(loadMoreProductsRequest());
  }, [dispatch]);

  if (isEmpty(orderDetails.account)) {
    const redirectPath = isUserLoggedIn ? paths.ROOT : paths.LOGIN;

    return (
      <Notification>
        {t('accounts:account_not_active')}
        <Button variant={['button']} to={redirectPath}>
          {t('common:home_page')}
        </Button>
      </Notification>
    );
  }

  const shouldShowNoResults = !hasProducts && !isLoadingProducts;

  return (
    <PageTitle title={title}>
      <OrderTopBar>
        <ProductsActions />
      </OrderTopBar>
      {isDeletingAnySelection && <DefaultLoader overlay />}
      <div className={styles.filtersAndProductsContainer}>
        <FiltersPanel isLoading={isLoadingProducts} />
        <Wrapper size={WrapperSize.FULL} className={cx(styles.wrapper, { [styles.noProducts]: !hasProducts })}>
          {shouldShowNoResults ?
            <NoResults />
          : <ProductsList
              isLoadingProducts={isLoadingProducts}
              orderedProducts={orderedProducts}
              onLoadMore={loadMoreProducts}
              isLoadingMoreProducts={isLoadingMoreProducts}
              totalItems={totalItems}
              pageCount={pageCount}
              getProductUrl={getUrlForProductAndVariant}
          />
          }
        </Wrapper>
      </div>
      <BottomWrapper>
        <CallToAction callToActionVersion="products" />
      </BottomWrapper>
      <BarcodeScannerModal />
    </PageTitle>
  );
};
