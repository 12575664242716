import { Cms } from '@typings';
import cx from 'classnames';
import React, { forwardRef, HTMLAttributes } from 'react';
import { useHover } from 'react-laag';

import { getCustomTemplateStyles } from '../../../../../logic/cms/styles';
import { getIsContentBlock, getIsProductBlock } from '../../../../../logic/pages';
import { isDefined } from '../../../../../utils/is';
import { BlockTemplate } from '../../../BlockTemplate';

import { BlockButtonWithHoverTools, BlockIdentifier, BlockResizer } from './components';
import styles from './TemplateEditorBlock.module.scss';

export interface TemplateEditorBlockProps extends HTMLAttributes<HTMLButtonElement> {
  block: Cms.CustomBlockModel;
  projectedBlock?: Cms.CustomBlockModel;
  index: number;
  updateBlock: (index: number, templateModel: Cms.CustomBlockModel) => void;
  removeBlock: (index: number) => void;
  screenType: Cms.ScreenType;
  style?: React.CSSProperties | undefined;
  isClone?: boolean;
  isDragging?: boolean;
  disabled?: boolean;
  activeResizeBlock?: Cms.CustomBlockModel | null;
  onResizeStart?: (block: Cms.CustomBlockModel) => void;
  onResizeUpdate?: (dimensions: Cms.BlockPositionRelativeProps) => void;
  onResizeEnd?: VoidFunction;
}

export const TemplateEditorBlock = forwardRef<HTMLDivElement, TemplateEditorBlockProps>((props, ref) => {
  const {
    block,
    projectedBlock,
    index,
    updateBlock,
    removeBlock,
    screenType,
    style,
    isClone = false,
    onResizeStart,
    onResizeEnd,
    activeResizeBlock,
    onResizeUpdate,
    isDragging = false,
    disabled = false,
    ...restButtonProps
  } = props;
  const resizableRef = React.useRef<HTMLDivElement>(null);
  const [isOver, hoverProps, resetHover] = useHover({ hideOnScroll: false });

  const isPlaceholder = isDragging && !isClone;
  const isEditable = !disabled && !isDragging;
  const isResizing = isDefined(activeResizeBlock) && activeResizeBlock.id === block.id;
  const isResizingOtherBlock = isDefined(activeResizeBlock) && !isResizing;
  const shouldShowActions = isEditable && !isDefined(activeResizeBlock) && !isClone && screenType === 'desktop';
  const isProductBlock = getIsProductBlock(block);
  const isContentBlock = getIsContentBlock(block);
  const shouldShowResizers = (isOver || isResizing) && !isClone && !isDragging && !disabled && !isResizingOtherBlock;
  const indexLabel = index + 1;
  const blockTemplateModel = projectedBlock ?? block;

  const blockTemplatePosition = blockTemplateModel.position[screenType];
  const shouldUpdateDimensions = isDefined(projectedBlock) || isDefined(isClone);

  const blockTemplateStylesOnDrag = React.useMemo(
    () => ({
      ...style,
      ...getCustomTemplateStyles({ blockPosition: blockTemplatePosition, shouldUpdateDimensions }),
    }),
    [blockTemplatePosition, shouldUpdateDimensions, style],
  );

  const handleRemoveBlock = React.useCallback(() => {
    removeBlock(index);
  }, [removeBlock, index]);

  const handleSwitchType = React.useCallback(() => {
    updateBlock(index, { ...block, blockType: isContentBlock ? 'product' : 'content' });
  }, [updateBlock, index, block, isContentBlock]);

  React.useEffect(() => {
    if (isDragging) {
      resetHover();
    }
  }, [resetHover, isDragging]);

  return (
    <BlockTemplate
      style={blockTemplateStylesOnDrag}
      ref={ref}
      templateModel={blockTemplateModel}
      screenType={screenType}
      className={cx({
        [styles.filler]: block.isFiller,
        [styles.clone]: isClone,
        [styles.placeholder]: isPlaceholder,
        [styles.product]: isProductBlock,
        [styles.content]: isContentBlock,
        [styles.resizing]: isResizing,
      })}
      isFiller={block.isFiller}
      isPlaceholder={isPlaceholder}
      isLarge
    >
      {!block.isFiller && !isPlaceholder && (
        <BlockIdentifier indexLabel={indexLabel} isContent={isContentBlock} isProduct={isProductBlock} />
      )}

      <div ref={resizableRef} className={styles.blockMarker} {...hoverProps}>
        <BlockButtonWithHoverTools
          shouldShowActions={shouldShowActions}
          isProductBlock={isProductBlock}
          isContentBlock={isContentBlock}
          handleSwitchType={handleSwitchType}
          handleRemoveBlock={handleRemoveBlock}
          {...restButtonProps}
        />
        <BlockResizer
          screenType={screenType}
          onResizeStart={onResizeStart}
          onResizeEnd={onResizeEnd}
          updateBlock={updateBlock}
          onResizeUpdate={onResizeUpdate}
          block={block}
          resizableRef={resizableRef}
          isVisible={shouldShowResizers}
          index={index}
        />
      </div>
    </BlockTemplate>
  );
});
