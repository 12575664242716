import { Cms } from '@typings';
import React from 'react';

import { getInternalLinkForSameOrigin, getIsUrlExternal } from '../../../../logic/pages';
import { useIsInViewMode } from '../../../../utils/hooks';
import { isDefined } from '../../../../utils/is';
import { isEmpty } from '../../../../utils/isEmpty';
import { isSafeUrl } from '../../../../utils/safeUrl';
import { Link } from '../../../various/Link';

interface Props {
  link: Cms.LinkSettings | undefined;
  anchorClassName?: string;
  style?: Record<string, unknown>;
}

export const LinkWrapper = ({ link, anchorClassName, children, style }: React.WithChildren<Props>) => {
  const isInEditor = useIsInViewMode('editor');

  if (!isDefined(link) || isEmpty(link.url) || isInEditor) {
    return <>{children}</>;
  }

  const { url, newTab } = link;

  const isExternal = getIsUrlExternal(url);
  const targetUrl = isSafeUrl(url) ? getInternalLinkForSameOrigin(url) : '';

  const target = newTab ? '_blank' : undefined;

  return (
    <Link external={isExternal} to={targetUrl} target={target} className={anchorClassName} style={style}>
      {children}
    </Link>
  );
};
