import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

import { changePasswordRequest, getBuyerMinimumPasswordLength, getChangePassword } from '../../../ducks';
import { paths } from '../../../paths';
import { getQueryParams } from '../../../utils/getQueryParams';
import { useValidatedForm } from '../../../utils/hooks/useValidatedForm';
import { isDefined } from '../../../utils/is';
import { Form, FormAlert, FormButtons, FormGrid, InputField } from '../../various/Form';
import { Button } from '../../various/NewButton';

interface Props {
  submitText: string;
}

interface FormData {
  password: string;
  passwordRepeat: string;
}

export const SetPasswordForm = ({ submitText }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['login', 'passwordManagement', 'validation']);
  const buyerMinimumPasswordLength = useSelector(getBuyerMinimumPasswordLength);
  const { isLoading, isFailed } = useSelector(getChangePassword);

  const validationSchema: yup.ObjectSchema<FormData> = yup.object({
    password: yup
      .string()
      .required(t('validation:password_hint'))
      .min(buyerMinimumPasswordLength, t('validation:password_length_hint', { count: buyerMinimumPasswordLength })),
    passwordRepeat: yup
      .string()
      .oneOf([yup.ref('password')], t('validation:password_match_hint'))
      .required(t('validation:repeat_password_hint')),
  });

  const formMethods = useValidatedForm<FormData>({
    validationSchema,
  });

  const { register } = formMethods;

  const handleSubmit = (values: FormData) => {
    const { i: token, id } = getQueryParams(location.search);

    if (!isDefined(token) || !isDefined(id)) {
      return;
    }

    dispatch(
      changePasswordRequest({
        i: token,
        id,
        newPassword: values.password,
      }),
    );
  };

  return (
    <Form formMethods={formMethods} onSubmit={handleSubmit}>
      <FormGrid>
        <InputField type="password" label={t('passwordManagement:password')} {...register('password', { deps: 'passwordRepeat' })} />
        <InputField type="password" label={t('passwordManagement:repeat_password')} {...register('passwordRepeat')} />
      </FormGrid>
      {isFailed && <FormAlert message={t('passwordManagement:change_password_fail')} />}
      <FormButtons direction="column">
        <Button type="submit" isLoading={isLoading} size="large" color="dark" isFluid>
          {submitText}
        </Button>
        <Button as={Link} to={paths.LOGIN} size="large" color="dark" variant="ghost" isFluid>
          {t('login:sign_in')}
        </Button>
      </FormButtons>
    </Form>
  );
};
