import { isEmpty } from 'ramda';
import React from 'react';

import { isDefined } from '../../../utils/is';

interface Props {
  hasPermissions: Nullable<boolean>;
}

export const useCamera = ({ hasPermissions }: Props) => {
  const isExposingMediaDevices = isDefined(navigator.mediaDevices);
  const [availableCameras, setAvailableCameras] = React.useState<MediaDeviceInfo[]>([]);

  const hasCamera = !isEmpty(availableCameras);

  const enumerateCameras = React.useCallback(() => {
    if (!isExposingMediaDevices) {
      return;
    }

    navigator.mediaDevices
      .enumerateDevices()
      .then(devices => {
        const videoDevices = devices.filter(device => {
          const isDeviceACamera = device.kind === 'videoinput';
          const isDeviceAccessible = isDefined(device.label);

          return isDeviceACamera && isDeviceAccessible;
        });

        setAvailableCameras(videoDevices);
      })
      .catch(() => setAvailableCameras([]));
  }, [isExposingMediaDevices]);

  React.useEffect(() => {
    if (hasPermissions) {
      enumerateCameras();
    }
  }, [enumerateCameras, hasPermissions]);

  React.useEffect(() => {
    isExposingMediaDevices && navigator.mediaDevices.addEventListener('devicechange', enumerateCameras);
    enumerateCameras();

    return () => {
      isExposingMediaDevices && navigator.mediaDevices.removeEventListener('devicechange', enumerateCameras);
    };
  }, [enumerateCameras, isExposingMediaDevices]);

  return { availableCameras, hasCamera };
};
