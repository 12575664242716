import { prop, sortBy } from 'ramda';
import { useSelector } from 'react-redux';

import { customStyleFonts } from '../../../../cmsTemplates';
import { getCustomFonts } from '../../../../ducks/config';
import { Autocomplete, Option } from '../../../various/Fields/Select';

import styles from './FontSelect.module.scss';

interface Props {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}

const defaultFonts = Object.entries(customStyleFonts).map(([value, { title }]) => ({
  title,
  value,
}));

export const FontSelect = ({ value, onChange, disabled = false }: Props) => {
  const customFontsData = useSelector(getCustomFonts);
  const customFonts = customFontsData.map(({ fontFamily, title }) => ({
    title,
    value: fontFamily,
  }));
  const fonts = sortBy(prop('title'), [...defaultFonts, ...customFonts]);

  return (
    <div className={styles.fontSelect}>
      <Autocomplete size="small" value={value} dataTestId="textStyleEditorFont" isDisabled={disabled} onChange={onChange}>
        {fonts.map(font => (
          <Option key={font.value} value={font.value}>
            {font.title}
          </Option>
        ))}
      </Autocomplete>
    </div>
  );
};
