/* eslint-disable max-len */
const ORIGINAL_WIDTH = 20;

export const Gift = ({ width = ORIGINAL_WIDTH }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        d="M6.17 3.474a2.513 2.513 0 0 1 3.533 0l.003.003c.112.111.21.236.294.368.085-.132.182-.257.294-.368l.003-.003a2.513 2.513 0 0 1 3.533 0 2.473 2.473 0 0 1 .372 3.05h2.215A1.33 1.33 0 0 1 17.75 7.85v2.306c0 .684-.515 1.236-1.166 1.317v4.45c0 .355-.143.693-.394.941-.25.248-.589.386-.94.386H4.75c-.35 0-.689-.138-.94-.386a1.324 1.324 0 0 1-.393-.94v-4.451a1.329 1.329 0 0 1-1.167-1.317V7.85c0-.741.605-1.327 1.333-1.327h2.215a2.468 2.468 0 0 1 .371-3.049Zm4.58 4.55v1.96h5.5v-1.96h-5.5Zm4.334 3.46H10.75v4.266h4.334v-4.267ZM9.25 15.75v-4.267H4.917v4.267H9.25Zm0-5.767v-1.96h-5.5v1.96h5.5Zm1.545-3.503c.138-.013.287-.03.44-.051a5.76 5.76 0 0 0 .967-.212c.303-.1.487-.207.575-.293a.973.973 0 0 0-.001-1.383 1.013 1.013 0 0 0-1.424-.001c-.086.086-.193.266-.293.564a5.56 5.56 0 0 0-.214.952c-.021.147-.038.29-.05.424Zm-1.59 0a9.458 9.458 0 0 1-.44-.051 5.76 5.76 0 0 1-.967-.212c-.304-.1-.487-.207-.575-.293a.973.973 0 0 1 .001-1.383 1.013 1.013 0 0 1 1.423-.001c.087.086.194.266.294.564.097.286.166.62.214.952.02.147.037.29.05.424Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
