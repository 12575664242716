import React from 'react';

import {
  MatrixBulkEditingContextProvider,
  MatrixNavigationContextProvider,
  MatrixTotalsContextProvider,
} from '../../products/ProductMatrix/context';

import styles from './Matrix.module.scss';
import { MatrixBody } from './MatrixBody';
import { MatrixHead } from './MatrixHead';

export const MatrixComponent = React.memo(() => {
  return (
    <table className={styles.table} data-matrix>
      <MatrixHead />
      <MatrixBody />
    </table>
  );
});

export const Matrix = React.memo(() => {
  return (
    <MatrixTotalsContextProvider>
      <MatrixNavigationContextProvider>
        <MatrixBulkEditingContextProvider>
          <MatrixComponent />
        </MatrixBulkEditingContextProvider>
      </MatrixNavigationContextProvider>
    </MatrixTotalsContextProvider>
  );
});
