import { Cms } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useHover } from 'react-laag';
import { useSelector } from 'react-redux';

import {
  getIsCurrentBlockSelected,
  getIsCustomStyleEditorVisibleInBlock,
  getIsHotspotEditingInBlock,
  getIsManualLayoutEditorEnabledInGroup,
  getIsRepositioningImageInBlock,
} from '../../../../../../ducks';
import { isButtonPart } from '../../../../../../logic/cms/styles';
import { useEventListener, useSpecifiedDeviceWidth } from '../../../../../../utils/hooks';
import { isDefined } from '../../../../../../utils/is';
import { ContentSetAdjustor } from '../../../../../cms/ContentSetAdjustor';
import { ContentBlockContext } from '../../../../../cms/context/ContentBlockContext';
import { useContentSetContext } from '../../../../../cms/context/ContentSetContext';
import { EditorButtonPart } from '../Button/EditorButtonPart';
import { SnapGuides } from '../SnapGuides';
import { EditorTextPart } from '../Text/EditorTextPart';
import { TextOverlay } from '../TextOverlay';

import styles from './ContentSet.module.scss';

interface Props {
  groupId: Maybe<string>;
  blockId: Nullable<string>;
  parameters: Cms.ContentBlockText;
}

export const EditorContentSet = (props: Props) => {
  const { groupId, blockId, parameters } = props;

  const isCustomStyleEditorVisibleForCurrentBlock = useSelector(getIsCustomStyleEditorVisibleInBlock(blockId));
  const isCurrentBlockSelected = useSelector(getIsCurrentBlockSelected(blockId));
  const isRepositioningImage = useSelector(getIsRepositioningImageInBlock(blockId));
  const isHotspotEditing = useSelector(getIsHotspotEditingInBlock(blockId));
  const isManualGroupChangingMode = useSelector(getIsManualLayoutEditorEnabledInGroup(groupId));

  const { isAddPartPopupVisible } = React.useContext(ContentBlockContext);
  const { initialContentPosition, isMoving, isResizing, shouldShowSnapGuides, setShouldShowSnapGuides } = useContentSetContext();

  const ref = React.useRef<HTMLDivElement>(null);
  const [isAdjustorVisible, setIsAdjustorVisible] = React.useState(false);
  const [isOver, hoverProps] = useHover();
  const screenWidth = useSpecifiedDeviceWidth();

  React.useEffect(() => {
    setIsAdjustorVisible(isOver || isMoving || shouldShowSnapGuides);
  }, [isOver, isMoving, shouldShowSnapGuides]);

  useEventListener({
    element: { current: document },
    eventName: 'keydown',
    handler: ({ altKey }: KeyboardEvent) => {
      if (altKey) {
        setShouldShowSnapGuides(true);
      }
    },
    isDisabled: !isAdjustorVisible || isCurrentBlockSelected || isResizing,
  });

  useEventListener({
    element: { current: document },
    eventName: 'keyup',
    handler: ({ altKey }: KeyboardEvent) => {
      if (!altKey) {
        setShouldShowSnapGuides(false);
      }
    },
    isDisabled: !shouldShowSnapGuides,
  });

  const parts = parameters.partsOrder.map((partId, idx) => {
    const part = parameters.parts[partId];

    if (!isDefined(part)) {
      return null;
    }

    return isButtonPart(part) ?
        <EditorButtonPart key={idx} parameters={part} screenWidth={screenWidth} index={idx} blockId={blockId} partId={partId} />
      : <EditorTextPart key={idx} parameters={part} screenWidth={screenWidth} index={idx} blockId={blockId} />;
  });

  const isDisabled = isRepositioningImage || isHotspotEditing || isManualGroupChangingMode;

  return (
    <>
      {isCustomStyleEditorVisibleForCurrentBlock && <TextOverlay />}
      <div className={cx(styles.textWrapper, { [styles.disabled]: isDisabled })} ref={ref} {...hoverProps}>
        {parts}
        <ContentSetAdjustor
          blockId={blockId}
          adjustableElement={ref}
          initialPosition={initialContentPosition}
          visible={isAdjustorVisible || isAddPartPopupVisible}
        />
      </div>
      {(shouldShowSnapGuides || isMoving) && <SnapGuides elementRef={ref} />}
    </>
  );
};
