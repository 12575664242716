import { WhatsNew } from '@typings';
import { useTranslation } from 'react-i18next';

import { isDefined } from '../../../utils/is';
import { Link } from '../../various/Link';

import styles from './WhatsNew.module.scss';

interface Props {
  article: WhatsNew.Article;
  month: number;
  year: number;
}

export const Article = ({ article, month, year }: Props) => {
  const { t } = useTranslation(['onboarding']);
  const { img, title, day, text, link } = article;
  const date = `${year}-${month}-${day}`;

  return (
    <article className={styles.article}>
      {isDefined(img) && <img src={img} alt="" />}
      <div className={styles.articleTextContainer}>
        <time className={styles.articleDate}>{date}</time>
        <h2 className={styles.articleTitle}>{title}</h2>
        <p className={styles.articleText}>{text}</p>
        {isDefined(link) && (
          <Link external target="_blank" variant="blue" to={link} className={styles.link}>
            {t('onboarding:go_to_support_article')}
          </Link>
        )}
      </div>
    </article>
  );
};
