import { Product } from '@typings';

import { isDefined } from '../../../../../utils/is';
import { isEmpty } from '../../../../../utils/isEmpty';
import { CustomImage } from '../../../CustomImage';
import { Help } from '../../../Help';

import styles from './Attribute.module.scss';

interface Props {
  name: string;
  attributesDetails: Product.AttributeDetails[];
}

export const AttributeName = ({ name, attributesDetails }: Props) => {
  return (
    <div className={styles.attributeName}>
      {name}
      {!isEmpty(attributesDetails) && (
        <Help placement="top-center" className={styles.helpTooltip}>
          {attributesDetails.map(attributeDetails => {
            return (
              <span className={styles.helpElement} key={attributeDetails.desc}>
                {isDefined(attributeDetails.image) && (
                  <div className={styles.helpImage}>
                    <CustomImage placeholderClassName={styles.helpPlaceholder} aspectRatio={1} src={attributeDetails.image.url} />
                  </div>
                )}
                {attributeDetails.desc}
              </span>
            );
          })}
        </Help>
      )}
    </div>
  );
};
