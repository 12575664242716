import React from 'react';

import Icon, { IconType } from '../../../various/Icon';

const UploadDownloadTranslations = React.lazy(async () =>
  import('./UploadDownloadTranslations').then(module => ({ default: module.UploadDownloadTranslations })),
);

export const UploadDownloadTranslationsLazy = () => {
  return (
    <React.Suspense fallback={<Icon type={IconType.Spinner} />}>
      <UploadDownloadTranslations />
    </React.Suspense>
  );
};
