import { Cms, Id } from '@typings';
import { pick } from 'ramda';
import React from 'react';
import { Option } from 'space-lift';

import { useEditPage } from '../../../services/hooks/pages/useEditPage';
import { isDefined } from '../../is';
import { useModalVisibility } from '../useModalVisibility';

const pageToDTO = (page: Cms.Page): Cms.PageDTO => ({
  ...pick(['name', 'slug', 'description', 'availableLanguages'], page),
  marketIds: page.markets.map(market => market.id),
});

export const usePageSettings = (pages: Nullable<Cms.Page[]>) => {
  const [pageSettingsToEdit, setPageSettingsToEdit] = React.useState<Cms.PageDTO | undefined>(undefined);
  const [pageId, setPageId] = React.useState<Id | undefined>(undefined);

  const { mutate: editPage, isPending: isSavingPage } = useEditPage();
  const { hideModal } = useModalVisibility('EditPageFormModal');

  React.useEffect(() => {
    if (!isDefined(pageId)) {
      setPageSettingsToEdit(undefined);

      return;
    }
    const pageDTO = Option(pages)
      .map(allPages => allPages.find(page => page.id === pageId))
      .map(pageToDTO)
      .get();
    setPageSettingsToEdit(pageDTO);
  }, [pageId, pages, setPageSettingsToEdit]);

  const onEdit = React.useCallback(
    (data: Cms.PageDTO) => {
      if (!isDefined(pageId)) {
        return;
      }

      editPage(
        { data, pageId },
        {
          onSuccess: hideModal,
        },
      );
    },
    [pageId, editPage, hideModal],
  );

  const selectedPageLanguages = React.useMemo(() => {
    return pages?.find(page => page.id === pageId)?.languages;
  }, [pageId, pages]);

  return { isSavingPage, onEdit, selectedPageLanguages, selectedPageSettings: pageSettingsToEdit, setEditingPage: setPageId };
};
