import { Query, QueryCache, QueryClient } from '@tanstack/react-query';

import { addToast } from '../components/various/Toasts';
import { isDefined } from '../utils/is';
import { isEmpty } from '../utils/isEmpty';

interface QueryMeta {
  errorMessage?: string;
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache({
    onError: (_, query: Query & { meta?: QueryMeta }) => {
      const errorMessage = query.meta?.errorMessage;

      if (isDefined(errorMessage) && !isEmpty(errorMessage)) {
        addToast(errorMessage);
      }
    },
  }),
});
