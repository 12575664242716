import { isDefined, isNull } from './is';
import { isEmpty } from './isEmpty';

export const isDeepEmpty = (candidate: unknown): boolean => {
  if (!isDefined(candidate) || isEmpty<Unrestricted>(candidate)) {
    return true;
  }

  return typeof candidate === 'object' && !isNull(candidate) && Object.values(candidate).every(value => isDeepEmpty(value));
};
