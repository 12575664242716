import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;

export const AddSquareOutline = ({ width = ORIGINAL_WIDTH, color = 'currentColor' }: IconComponentProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox="0 0 20 20" fill={color}>
      <path
        fillRule="evenodd"
        d="M3 6.387A3.387 3.387 0 0 1 6.387 3h7.226A3.387 3.387 0 0 1 17 6.387v7.226A3.387 3.387 0 0 1 13.613 17H6.387A3.387 3.387 0 0 1 3 13.613V6.387Zm3.387-2.032c-1.122 0-2.032.91-2.032 2.032v7.226c0 1.122.91 2.032 2.032 2.032h7.226c1.122 0 2.032-.91 2.032-2.032V6.387c0-1.122-.91-2.032-2.032-2.032H6.387ZM10 6.613c.374 0 .677.303.677.677v2.033h2.033a.677.677 0 0 1 0 1.354h-2.033v2.033a.677.677 0 0 1-1.354 0v-2.033H7.29a.677.677 0 0 1 0-1.354h2.033V7.29c0-.374.303-.677.677-.677Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
