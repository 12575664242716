import React from 'react';

import { CENTER_SNAP_POSITION, GUIDELINE_TOOLTIP_SAFE_AREA } from '../../../../../../constants/cms';
import { MAX_PERCENT } from '../../../../../../constants/limits';
import { useContentSetContext } from '../../../../../cms/context/ContentSetContext';

import { Line } from './Line';
import { Tooltip } from './Tooltip';

interface Props {
  elementRef: React.MutableRefObject<HTMLElement | null>;
}

export const SnapGuides = ({ elementRef }: Props) => {
  const { contentPosition } = useContentSetContext();

  const { height, parentHeight, parentWidth, width } = React.useMemo(() => {
    const parentRect = elementRef.current?.parentElement?.getBoundingClientRect();
    const elementRect = elementRef.current?.getBoundingClientRect();

    return {
      height: elementRect?.height ?? 0,
      parentHeight: parentRect?.height ?? 0,
      parentWidth: parentRect?.width ?? 0,
      width: elementRect?.width ?? 0,
    };
  }, [elementRef]);

  const { top: topPercent, left: leftPercent } = contentPosition;

  const top = Math.round((topPercent * parentHeight) / MAX_PERCENT - height / 2);
  const left = Math.round((leftPercent * parentWidth) / MAX_PERCENT - width / 2);
  const right = Math.round(parentWidth - (leftPercent * parentWidth) / MAX_PERCENT - width / 2);
  const bottom = Math.round(parentHeight - (topPercent * parentHeight) / MAX_PERCENT - height / 2);

  const horizontalTooltipPlacement = top + height / 2 < GUIDELINE_TOOLTIP_SAFE_AREA ? 'after' : 'before';

  return (
    <>
      {topPercent === CENTER_SNAP_POSITION && <Line direction="horizontal" />}
      {leftPercent === CENTER_SNAP_POSITION && <Line direction="vertical" />}
      <Line isDashed direction="horizontal" right={`${right + width}px`} top={`${topPercent}%`}>
        <Tooltip value={left} placement={horizontalTooltipPlacement} />
      </Line>
      <Line isDashed direction="horizontal" left={`${left + width}px`} top={`${topPercent}%`}>
        <Tooltip value={right} placement={horizontalTooltipPlacement} />
      </Line>
      <Line isDashed direction="vertical" left={`${leftPercent}%`} bottom={`${bottom + height}px`}>
        <Tooltip value={top} />
      </Line>
      <Line isDashed direction="vertical" left={`${leftPercent}%`} top={`${top + height}px`}>
        <Tooltip value={bottom} />
      </Line>
    </>
  );
};
