import { Cms, PillOptions, Translations } from '@typings';
import { without } from 'ramda';
import React from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { LANGUAGE_NAME_MAP } from '../../../../constants/translation';
import { useTranslationsConfig } from '../../../../utils/hooks';
import { isEmpty } from '../../../../utils/isEmpty';
import { Pills } from '../../../various/Fields/Pills';

import styles from './PageFormLanguages.module.scss';
import { PageFormLanguagesAlert } from './PageFormLanguagesAlert';
import { PageFormLanguagesRow } from './PageFormLanguagesRow';

interface Props {
  pageLanguages: Maybe<Cms.PageLanguage[]>;
  options: PillOptions[];
  isEditMode: boolean;
}

export const PageFormLanguages = ({ options, isEditMode, pageLanguages }: Props) => {
  const { t } = useTranslation(['translations']);
  const { supportedLanguages } = useTranslationsConfig();

  const { field } = useController<Cms.PageDTO, 'availableLanguages'>({ name: 'availableLanguages' });
  const [initialSelectedLanguages, setInitialSelectedLanguages] = React.useState(field.value);

  const availableLanguageCodes = React.useMemo(() => {
    const languageCodes = supportedLanguages.map(({ code }) => code);

    if (isEditMode) {
      return languageCodes.filter(code => !initialSelectedLanguages.includes(code));
    }

    return languageCodes;
  }, [initialSelectedLanguages, isEditMode, supportedLanguages]);

  const availableOptions = options.filter(({ value }) => availableLanguageCodes.includes(value as Translations.SupportedLanguagesCodes));

  const handleRemove = (code: Translations.SupportedLanguagesCodes) => () => {
    field.onChange(without([code], field.value));
    setInitialSelectedLanguages(without([code], initialSelectedLanguages));
  };

  const getLanguageStatusByCode = (code: Translations.SupportedLanguagesCodes) => {
    return pageLanguages?.find(language => language.code === code)?.status ?? 'unpublished';
  };

  return (
    <div className={styles.wrapper}>
      {isEditMode && (
        <div className={styles.rows}>
          {initialSelectedLanguages.map(code => (
            <PageFormLanguagesRow
              key={code}
              code={code}
              label={LANGUAGE_NAME_MAP[code]}
              pageStatus={getLanguageStatusByCode(code)}
              onRemove={handleRemove(code)}
            />
          ))}
        </div>
      )}

      {isEditMode && !isEmpty(availableOptions) && (
        <p id="addLanguageLabel" className={styles.addLanguageTitle}>
          {t('translations:add_language')}
        </p>
      )}

      <Pills
        value={field.value}
        options={availableOptions}
        labelledById="addLanguageLabel"
        onBlur={field.onBlur}
        onChange={field.onChange}
      />

      <PageFormLanguagesAlert />
    </div>
  );
};
