import { useMatch } from 'react-router-dom';

import { paths } from '../../paths';
import { isDefined } from '../is';

export const useHasCmsEditorBar = () => {
  const cmsEditorMatch = useMatch(paths.CMS_EDITOR);
  const cmsPreviewMatch = useMatch(paths.CMS_PREVIEW);

  return isDefined(cmsEditorMatch) || isDefined(cmsPreviewMatch);
};
