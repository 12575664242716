import styles from './errorPage.module.scss';

interface Props {
  message: string;
}

export const ErrorPage = ({ message }: Props) => {
  const parsedMessage = message.split(/\r?\n/).map((messageItem, idx) => (
    <p key={idx} className={styles.message}>
      {messageItem}
    </p>
  ));

  return (
    <div className={styles.wrapper}>
      <div>{parsedMessage}</div>
    </div>
  );
};
