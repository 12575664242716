import { Cms } from '@centra';
import cx from 'classnames';

import { calculateFontSize } from '../../../../../../logic/calculateFontSize';
import { splitParagraphs } from '../../../../../../logic/cms/contentSet';
import { getScreenRelativeStyles } from '../../../../../../logic/pages';
import { getHtmlAttributesFromString } from '../../../../../../utils/getHtmlAttributesFromString';
import { getSafeInnerHTML } from '../../../../../../utils/getSafeInnerHtml';
import { replaceHtmlWithComponent } from '../../../../../../utils/replaceHtmlWithComponent';
import { unescapeHtmlTagsFromString } from '../../../../../../utils/unescapeHtmlTagsFromString';
import styles from '../../../contentBlock.module.scss';
import { LinkWrapper } from '../../LinkWrapper';

const SPLIT_REGEXP = /(<a [^>]+>.*?<\/a>)/g;
const MATCH_REGEXP = /<a (.+)>(.*?)<\/a>/g;

const renderAnchor = (matches: string[]) => {
  const { 1: attributesString, 2: content } = matches;
  const attributes = getHtmlAttributesFromString(attributesString ?? '');
  const link = {
    newTab: attributes?.target === '_blank' || false,
    url: unescapeHtmlTagsFromString(attributes?.href || ''),
  };

  return (
    <LinkWrapper link={link}>
      <span dangerouslySetInnerHTML={getSafeInnerHTML(content ?? '')} />
    </LinkWrapper>
  );
};

export const TextPart = ({ screenWidth, parameters, index }: Cms.TextLineProps) => {
  const screenRelative = getScreenRelativeStyles(screenWidth, parameters);
  const { content, textStyle, textColor, spacing } = parameters.general;

  const style = {
    color: textColor,
    fontSize: calculateFontSize(parameters, screenWidth),
    marginTop: index !== 0 ? spacing : 0,
    textAlign: screenRelative.textAlignment,
  };

  const paragraphs = splitParagraphs(content);
  const parsedContent = paragraphs.map(paragraph =>
    replaceHtmlWithComponent({
      matchRegExp: MATCH_REGEXP,
      renderFunction: renderAnchor,
      sourceHtml: paragraph,
      splitRegExp: SPLIT_REGEXP,
    }),
  );

  return (
    <div className={cx(styles.textLine, textStyle)} style={style}>
      {parsedContent.map((paragraph, idx) => (
        <p key={idx}>{paragraph}</p>
      ))}
    </div>
  );
};
