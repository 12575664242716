import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { pushEvent } from '../../../../ducks';
import { MatrixTrackingEvent } from '../../../../utils/analytics/events';
import { useModalVisibility } from '../../../../utils/hooks';
import Icon, { IconType } from '../../../various/Icon';

import styles from './MatrixHelp.module.scss';

export const MatrixHelpIcon = () => {
  const { t } = useTranslation(['products']);
  const dispatch = useDispatch();
  const { showModal } = useModalVisibility('MatrixHelpModal');

  const handleShowModal = () => {
    dispatch(pushEvent({ event: MatrixTrackingEvent.HELP_BUTTON_CLICKED }));
    showModal();
  };

  return (
    <div className={styles.help}>
      <button className={styles.helpButton} onClick={handleShowModal} aria-label={t('products:show_product_matrix_help')}>
        <Icon type={IconType.HelpOutline} size={24} />
      </button>
    </div>
  );
};
