import { Cms } from '@centra';
import cx from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getButtonStyleElementStylesInBlock,
  getButtonStyles,
  getCurrentItemInBlock,
  getIsButtonStyleEditorVisibleInBlock,
  getIsCurrentBlock,
  getIsHoverTabOpen,
  setCurrentBlock,
  setCurrentItem,
} from '../../../../../../ducks';
import { calculateFontSize } from '../../../../../../logic/calculateFontSize';
import { getScreenRelativeStyles } from '../../../../../../logic/pages';
import { useToolbarVisibility } from '../../../../../../utils/hooks';
import { isDefined } from '../../../../../../utils/is';
import { ContentEditor } from '../../../../../cms/ContentEditor';
import { useContentSetContext } from '../../../../../cms/context/ContentSetContext';
import { EditorLanguageContext } from '../../../../../cms/context/EditorLanguageContext';
import blockStyles from '../../../contentBlock.module.scss';
import { ContentPartMover } from '../../ContentPartMover';

import { ButtonLinkMarkerPlugin } from './ButtonLinkMarkerPlugin';
import styles from './ButtonPart.module.scss';

const TOOLBAR_TYPE = 'Button';

export const EditorButtonPart = ({ screenWidth, parameters, index, partId, blockId }: Cms.ButtonLineProps) => {
  const { isDefaultLanguage } = React.useContext(EditorLanguageContext);
  const dispatch = useDispatch();
  const { setActivePartRef } = useContentSetContext();
  const isCurrentBlock = useSelector(getIsCurrentBlock(blockId));
  const currentItemIndex = useSelector(getCurrentItemInBlock(blockId))?.index;
  const buttonStyleElementStyles = useSelector(getButtonStyleElementStylesInBlock(blockId));
  const { isToolbarVisible, showToolbar } = useToolbarVisibility(TOOLBAR_TYPE, blockId);
  const isButtonStyleEditorVisible = useSelector(getIsButtonStyleEditorVisibleInBlock(blockId));
  const isHoverTabOpen = useSelector(getIsHoverTabOpen);
  const containerRef = React.useRef<HTMLParagraphElement | null>(null);

  const { content, link, buttonStyle } = parameters.general;
  const isActiveButtonPart = isDefined(index) && index === currentItemIndex && isCurrentBlock;
  const isPartInEdit = isActiveButtonPart && isToolbarVisible;
  const isButtonStyleEditorEnabled = isActiveButtonPart && isButtonStyleEditorVisible;
  const isHoverEffectEnabled = buttonStyleElementStyles?.properties.hover.isHoverEffectEnabled;
  const screenRelative = getScreenRelativeStyles(screenWidth, parameters);
  const buttonStyles = useSelector(getButtonStyles);
  const applyHoverStyles = isActiveButtonPart && isHoverEffectEnabled && isHoverTabOpen && isDefined(buttonStyleElementStyles);

  const style = React.useMemo(
    () => ({
      borderStyle: 'solid',
      fontSize: calculateFontSize(parameters, screenWidth, buttonStyles),
      justifySelf: screenRelative.justifySelf,
      ...(isActiveButtonPart && isDefined(buttonStyleElementStyles) ? buttonStyleElementStyles.properties.default : {}),
      ...(applyHoverStyles ? buttonStyleElementStyles.properties.hover : {}),
    }),
    [parameters, screenWidth, buttonStyles, screenRelative.justifySelf, isActiveButtonPart, buttonStyleElementStyles, applyHoverStyles],
  );

  const shouldFocus = isDefined(containerRef.current) && !isActiveButtonPart;
  const onFocus = React.useCallback(() => {
    if (!shouldFocus) {
      return;
    }
    if (isDefined(blockId)) {
      dispatch(setCurrentBlock(blockId));
    }

    dispatch(setCurrentItem({ element: containerRef, index }));
    showToolbar();
  }, [blockId, dispatch, index, shouldFocus, showToolbar]);

  const onTab = React.useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      isButtonStyleEditorEnabled && event.preventDefault();

      return true;
    },
    [isButtonStyleEditorEnabled],
  );

  const onMouseDown = React.useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    event.nativeEvent.stopImmediatePropagation();
  }, []);

  const classNames = React.useMemo(
    () =>
      cx(styles.editorWrapper, styles.common, styles.buttonBase, buttonStyle, {
        [styles.cursorText]: isButtonStyleEditorEnabled,
      }),
    [buttonStyle, isButtonStyleEditorEnabled],
  );

  const containerClasses = React.useMemo(
    () =>
      cx(styles.editorContainer, blockStyles.frame, {
        [blockStyles.selected]: isPartInEdit || isButtonStyleEditorEnabled,
        [styles.highlightedButtonPart]: isButtonStyleEditorEnabled,
      }),
    [isButtonStyleEditorEnabled, isPartInEdit],
  );

  React.useLayoutEffect(() => {
    if (isButtonStyleEditorEnabled) {
      setActivePartRef(containerRef);
    }

    return () => {
      setActivePartRef({ current: null });
    };
  }, [isButtonStyleEditorEnabled, setActivePartRef]);

  return (
    <div role="button" tabIndex={0} ref={containerRef} className={containerClasses} onMouseDown={onMouseDown}>
      <ContentEditor
        namespace={index.toString()}
        onFocus={onFocus}
        content={content}
        onTab={onTab}
        classNames={classNames}
        shouldFocus={shouldFocus}
        style={style}
        contentType="button"
      >
        {!isButtonStyleEditorEnabled && <ButtonLinkMarkerPlugin link={link} isInEditing={isActiveButtonPart} partId={partId} />}
      </ContentEditor>
      {isPartInEdit && isDefaultLanguage && <ContentPartMover index={currentItemIndex} blockId={blockId} containerRef={containerRef} />}
    </div>
  );
};
