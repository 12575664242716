import { CmsV0 } from '@centra/cms/legacy/v0';
import { Cms } from '@typings';

import { isDefined } from '../../../../../../utils/is';

const getPositionProperties = (
  block: CmsV0.AnyBlock,
  previousBlockPosition: Cms.BlockPosition | null,
  screenType: 'tablet' | 'mobile',
): Cms.BlockPositionRelativeProps => {
  const { position, blockType } = block;
  const isContentBlock = blockType === 'content';

  const TABLET_COLUMNS = 3;
  const MOBILE_COLUMNS = 2;

  const columns = screenType === 'tablet' ? TABLET_COLUMNS : MOBILE_COLUMNS;

  const spanY = isContentBlock ? position.spanY : Math.floor(position.spanX / columns) * position.spanY;

  const previousBlockPositionForScreenType = previousBlockPosition?.[screenType];

  const startY =
    isDefined(previousBlockPositionForScreenType) ?
      previousBlockPositionForScreenType.startY + previousBlockPositionForScreenType.spanY + 1
    : 1;

  return {
    spanX: columns,
    spanY,
    startX: 1,
    startY,
  };
};

const convertSectionBlocksPosition = (sectionBlocks: CmsV0.AnyBlock[]): Cms.AnyBlock[] => {
  return sectionBlocks.reduce<Cms.AnyBlock[]>((acc, cur, idx) => {
    const { position, groupId } = cur;

    const previousBlock = acc[idx - 1];
    const relevantPreviousBlockPosition = isDefined(previousBlock) && previousBlock.groupId === groupId ? previousBlock.position : null;

    const mappedBlockPosition = {
      ...cur,
      position: {
        desktop: position,
        mobile: getPositionProperties(cur, relevantPreviousBlockPosition, 'mobile'),
        tablet: getPositionProperties(cur, relevantPreviousBlockPosition, 'tablet'),
      },
    };

    return [...acc, mappedBlockPosition];
  }, []);
};

export const convertBlocksPositionToGrid = (response: CmsV0.SectionsResponse) => {
  return {
    ...response,
    sections: response.sections.map(section => ({
      ...section,
      blocks: convertSectionBlocksPosition(section.blocks),
    })),
  };
};
