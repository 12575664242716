import React from 'react';
import { useSelector } from 'react-redux';

import { isEmpty } from '../../../../../utils/isEmpty';
import { FiltersPanelSelectorsContext } from '../context/FiltersPanelSelectorsContext';

import { FiltersPanelGroup } from './FiltersPanelGroup';

interface Props {
  onFilterChange: (fieldName: string, selectedValues: string | string[]) => void;
  onToggleFilter: (filterField: string, isExpanded: boolean) => void;
}

export const FiltersPanelGroups = ({ onFilterChange, onToggleFilter }: Props) => {
  const { getAttributes, getFilters } = React.useContext(FiltersPanelSelectorsContext);
  const attributes = useSelector(getAttributes);
  const filters = useSelector(getFilters);

  const validFilterGroups = attributes.filter(({ values }) => !isEmpty(values));

  return validFilterGroups.map(attribute => (
    <FiltersPanelGroup
      attribute={attribute}
      key={attribute.field}
      filters={filters}
      onFilterChange={onFilterChange}
      onToggleFilter={onToggleFilter}
    />
  ));
};
