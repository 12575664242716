import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getDeliveryWindowMap } from '../../../ducks';
import { isDefined } from '../../../utils/is';

import { Info } from './Info';

interface Props {
  deliveryWindows: string[];
}

export const ExpiredDelwinError = ({ deliveryWindows }: Props) => {
  const { t } = useTranslation(['checkoutMessages']);
  const deliveryWindowsMap = useSelector(getDeliveryWindowMap);
  const expiredDelwinNames = deliveryWindows
    .map(id => deliveryWindowsMap[id]?.name)
    .filter(isDefined)
    .join(', ');

  const expiredDelwinHeader = (
    <Trans t={t} i18nKey="checkoutMessages:expired_delwins_error.header" values={{ expiredDelwinNames }}>
      Following delivery windows have expired: <b>{expiredDelwinNames}</b>
    </Trans>
  );

  return <Info header={expiredDelwinHeader} details={t('checkoutMessages:expired_delwins_error.details')} />;
};
