import * as amplitude from '@amplitude/analytics-browser';
import i18next from 'i18next';
import { push } from 'redux-first-history';
import { combineEpics } from 'redux-observable';
import { fromEvent, iif, of } from 'rxjs';
import { filter, ignoreElements, map, mergeMap, tap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { addToast } from '../components/various/Toasts';
import { ACCEPT_HEADER_BASE } from '../constants/api';
import { DISMISSED_ONBOARDINGS_KEY, TOKEN_KEY } from '../constants/localStorage';
import {
  fetchCountriesRequest,
  fetchDeliveryWindowsRequest,
  getLocation,
  setLookbookToken,
  setSoftLogoutMode,
  sharedSelectionFailure,
  sharedSelectionRequest,
  sharedSelectionSuccess,
} from '../ducks';
import {
  authoriseAccessRequest,
  authoriseAccessSuccess,
  fetchLoginSuccess,
  fetchUserRequest,
  fetchUserSuccess,
  logoutRequest,
  setUserAsViewer,
} from '../ducks/user';
import { getHasConfidentialId } from '../logic/validation';
import { paths } from '../paths';
import { queryClient } from '../services/queryClient';
import { getAuthHeader } from '../utils/auth';
import { CUSTOM_TEMPLATES_KEY } from '../utils/hooks/cms/useCustomTemplates';
import { isDefined, isNull } from '../utils/is';
import { mapResponse } from '../utils/operators/mapResponse';

const authoriseAccessEpic: AppEpic = (action$, store$, { persistentStorage }) =>
  action$.pipe(
    filter(isActionOf(authoriseAccessRequest)),
    map(() => persistentStorage.getItem(TOKEN_KEY)),
    mergeMap(token =>
      iif(
        () => isDefined(token),
        of(fetchUserRequest()),
        of(window.location.href).pipe(
          map(location => getHasConfidentialId(location)),
          map(hasPageIds => (hasPageIds ? setUserAsViewer() : push({ pathname: paths.LOGIN }, { from: getLocation(store$.value) }))),
        ),
      ),
    ),
  );

const fetchUserDataEpic: AppEpic = (action$, _, { userRepository, persistentStorage }) =>
  action$.pipe(
    filter(isActionOf(fetchUserRequest)),
    mergeMap(async () => userRepository.getUser()),
    mapResponse(
      ({ data }) => [
        fetchUserSuccess(data),
        authoriseAccessSuccess({
          seller: data.seller,
          token: persistentStorage.getItem(TOKEN_KEY)!,
          user: data.user,
        }),
        fetchCountriesRequest(),
        fetchDeliveryWindowsRequest(),
      ],
      () => logoutRequest(),
    ),
  );

const setApiTokenEpic: AppEpic = (action$, _, { showroomApi }) =>
  action$.pipe(
    filter(isActionOf([fetchLoginSuccess, setLookbookToken])),
    map(action => action.payload),
    tap(({ token }) => showroomApi.updateHeaders(getAuthHeader(ACCEPT_HEADER_BASE, token))),
    ignoreElements(),
  );

const handleSharedSelectionFailure: AppEpic = (action$, state$, { showroomApi }) =>
  action$.pipe(
    filter(isActionOf(sharedSelectionFailure)),
    tap(() => {
      showroomApi.updateHeaders(getAuthHeader(ACCEPT_HEADER_BASE, state$.value.user.accessToken));
    }),
    ignoreElements(),
  );

const shareSelectionEpic: AppEpic = (action$, _, { showroomApi }) =>
  action$.pipe(
    filter(isActionOf(sharedSelectionRequest)),
    map(action => action.payload),
    tap(({ sharedToken }) => showroomApi.updateHeaders(getAuthHeader(ACCEPT_HEADER_BASE, sharedToken))),
    ignoreElements(),
  );

const logoutEpic: AppEpic = (action$, _, { persistentStorage, auth, showroomApi }) =>
  action$.pipe(
    filter(isActionOf(logoutRequest)),
    tap(() => persistentStorage.clearExcept([DISMISSED_ONBOARDINGS_KEY, CUSTOM_TEMPLATES_KEY])),
    mergeMap(async () => auth.logout()),
    tap(() => {
      showroomApi.clearAuthToken();
      queryClient.clear();
      amplitude.reset();
    }),
    mergeMap(() => [push({ pathname: paths.LOGIN })]),
  );

const softLogoutEpic: AppEpic = (action$, _, { persistentStorage }) =>
  action$.pipe(
    filter(isActionOf(sharedSelectionSuccess)),
    map(action => action.payload),
    filter(({ token }) => {
      const storageToken = persistentStorage.getItem(TOKEN_KEY);

      return isDefined(storageToken) && storageToken !== token;
    }),
    map(() => {
      addToast(i18next.t('selections:external_selection_hint'), {
        description: i18next.t('selections:external_selection_editing_hint'),
        duration: 10000,
        position: 'bottom-left',
      });

      return setSoftLogoutMode();
    }),
  );

const tokenRemovedEpic: AppEpic = () =>
  fromEvent<StorageEvent>(window, 'storage').pipe(
    filter(event => event.storageArea === localStorage),
    filter(({ key, newValue }) => (key === TOKEN_KEY || isNull(key)) && isNull(newValue)),
    map(() => push({ pathname: paths.LOGIN })),
  );

export const authEpic = combineEpics(
  authoriseAccessEpic,
  shareSelectionEpic,
  fetchUserDataEpic,
  setApiTokenEpic,
  logoutEpic,
  softLogoutEpic,
  tokenRemovedEpic,
  handleSharedSelectionFailure,
);
