import { Order } from '@typings';
import cx from 'classnames';
import { prop, uniqBy } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getIsLoadingOrderDetails } from '../../../../ducks';
import { useAvailableOrderDetails } from '../../../../utils/hooks/useAvailableOrderDetails';
import { isEmpty } from '../../../../utils/isEmpty';
import { Skeleton } from '../../../various/Skeleton';
import { SkeletonLayout } from '../../../various/SkeletonLayout';
import { TextEllipsis } from '../../../various/TextEllipsis';
import { OrderSelect } from '../../OrderSelect';

import { Actions } from './Actions';
import styles from './summaries.module.scss';

interface Props {
  products: Order.Product[];
  isOpen: boolean;
}

export const Summaries = ({ products, isOpen }: Props) => {
  const { t } = useTranslation(['common', 'totals', 'products', 'selections']);
  const isLoadingOrderDetails = useSelector(getIsLoadingOrderDetails);
  const { orderTotals } = useAvailableOrderDetails();

  const productsCount = React.useMemo(() => {
    return isEmpty(products) && isLoadingOrderDetails ? undefined : uniqBy(prop('product'), products).length;
  }, [products, isLoadingOrderDetails]);

  const elements = React.useMemo(
    () => [
      {
        className: styles.products,
        title: t('products:product_other'),
        value: productsCount,
      },
      {
        className: styles.units,
        title: t('common:unit_other'),
        value: orderTotals?.itemsCount,
      },
      {
        className: styles.total,
        title: t('totals:subtotal'),
        value: orderTotals?.itemsTotalPrice,
      },
    ],
    [productsCount, t, orderTotals],
  );

  return (
    <div className={cx(styles.summaries, { [styles.open]: isOpen })}>
      <div className={cx(styles.orderSelect, styles.summary)}>
        <div className={styles.heading}>{t('selections:selection_one')}</div>
        <div className={styles.content}>
          <OrderSelect />
          <Actions isOpen={isOpen} />
        </div>
      </div>
      <div className={styles.totals}>
        {elements.map(({ title, className, value }) => (
          <div key={title} className={cx(styles.summary, className)}>
            <div className={styles.heading}>{title}</div>
            <SkeletonLayout
              isLoading={isLoadingOrderDetails}
              skeleton={<Skeleton color="dark" type="text" itemClassName={styles.summarySkeleton} />}
            >
              <TextEllipsis as="div" className={styles.content} data-testid={`${title}Value`}>
                {value}
              </TextEllipsis>
            </SkeletonLayout>
          </div>
        ))}
      </div>
    </div>
  );
};
