import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { addToast } from '../../../components/various/Toasts';
import { compiledPaths } from '../../../paths';
import { createAccount } from '../../accountRepository';
import { ACCOUNTS_QUERY_KEY } from '../accounts/useAccounts';
import { BUYERS_QUERY_KEY } from '../accounts/useBuyers';

export const useCreateAccount = () => {
  const { t } = useTranslation(['accounts']);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createAccount,
    onError: () => {
      addToast(t('accounts:account_create_fail'));
    },
    onSuccess: data => {
      addToast(t('accounts:account_created'));
      navigate(
        compiledPaths.ACCOUNT_DETAILS({
          accountId: data.account.account,
        }),
      );
      queryClient.invalidateQueries({ queryKey: [BUYERS_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [ACCOUNTS_QUERY_KEY] });
    },
  });
};
