import React from 'react';

import { isDefined, isNull } from '../is';

export interface MovementOffset {
  xOffset: number;
  yOffset: number;
  clientX: number;
  clientY: number;
}

interface UseMovementOptions {
  onStartMovement?: (startPosition: MovementOffset, event: MouseEvent | TouchEvent) => void;
  onMovement: (offset: MovementOffset, event: MouseEvent | TouchEvent) => void;
  onEndMovement?: (offset: MovementOffset, event: MouseEvent | TouchEvent) => void;
  useTouch?: boolean;
}

const getIsTouchEvent = (event: TouchEvent | MouseEvent): event is TouchEvent => {
  return 'touches' in event;
};

const getEventSource = (event: TouchEvent | MouseEvent) =>
  getIsTouchEvent(event) ? ((event.touches[0] ?? event.changedTouches[0]) as Touch) : event;

export const useMovement = (ref: React.MutableRefObject<Element | null>, options: UseMovementOptions, enable: boolean = true) => {
  React.useEffect(() => {
    const element = ref.current;

    if (isNull(element)) {
      return;
    }

    const position = {
      xPos: 0,
      yPos: 0,
    };

    const mouseDown = (event: MouseEvent | TouchEvent) => {
      if (!isDefined(element) || !element.contains(event.target as Node)) {
        return;
      }

      const isTouchEvent = getIsTouchEvent(event);
      const source = getEventSource(event);
      const { pageX, pageY, clientX, clientY } = source;

      // eslint-disable-next-line functional/immutable-data
      Object.assign(position, { xPos: pageX, yPos: pageY });
      options.onStartMovement?.({ clientX, clientY, xOffset: pageX, yOffset: pageY }, event);

      if (isTouchEvent) {
        window.addEventListener('touchmove', mouseMove);
        window.addEventListener('touchend', mouseUp, { once: true });

        return;
      }

      window.addEventListener('mousemove', mouseMove);
      window.addEventListener('mouseup', mouseUp, { once: true });
    };

    const mouseMove = (event: MouseEvent | TouchEvent) => {
      const source = getEventSource(event);
      const { pageX, pageY, clientX, clientY } = source;
      if (position.xPos === pageX && position.yPos === pageY) {
        return;
      }

      options.onMovement(
        {
          clientX,
          clientY,
          xOffset: pageX - position.xPos,
          yOffset: pageY - position.yPos,
        },
        event,
      );
    };

    const mouseUp = (event: MouseEvent | TouchEvent) => {
      window.removeEventListener('mousemove', mouseMove);
      window.removeEventListener('touchmove', mouseMove);

      if (!isDefined(options.onEndMovement) || (position.xPos === 0 && position.yPos === 0)) {
        return;
      }

      const source = getEventSource(event);
      const { pageX, pageY, clientX, clientY } = source;

      options.onEndMovement(
        {
          clientX,
          clientY,
          xOffset: pageX - position.xPos,
          yOffset: pageY - position.yPos,
        },
        event,
      );
    };

    if (enable) {
      window.addEventListener('mousedown', mouseDown);
      options.useTouch && window.addEventListener('touchstart', mouseDown);
    }

    return () => {
      window.removeEventListener('mousedown', mouseDown);
      window.removeEventListener('touchstart', mouseDown);
    };
  }, [ref, options.onStartMovement, options.onMovement, options.onEndMovement, enable]);
};
