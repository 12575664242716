import { useQuery } from '@tanstack/react-query';

import { DEFAULT_ITEMS_COUNT_PER_REQUEST } from '../../../constants/limits';
import { getSkipFirstForListing } from '../../../utils/getSkipFirstForListing';
import { getBuyers } from '../../accountsRepository';

interface Props {
  page: number;
  search?: string;
  limit?: number;
}

export const BUYERS_QUERY_KEY = 'buyers';

export const useBuyers = ({ page, search, limit = DEFAULT_ITEMS_COUNT_PER_REQUEST }: Props) => {
  return useQuery({
    queryFn: async () => getBuyers(limit, getSkipFirstForListing(page, limit), search),
    queryKey: [BUYERS_QUERY_KEY, { limit, page, search }],
  });
};
