import React from 'react';
import { useSelector } from 'react-redux';

import { getIsMatrixTotalsColumnPinned } from '../../../../ducks';
import { isDefined } from '../../../../utils/is';

const MIN_CONTENT_SCROLL = 1;

interface Context {
  canPinColumns: boolean;
  hasMoreContent: boolean;
  isTotalsColumnPinned: boolean;
  pixelsToScrollEnd: number;
  setCanPinColumns: (canPin: boolean) => void;
  setPixelsToScrollEnd: (pixelsToEnd: number) => void;
}

const MatrixColumnsContext = React.createContext<Context | null>(null);

export const useMatrixColumnsContext = () => {
  const context = React.useContext(MatrixColumnsContext);

  if (!isDefined(context)) {
    throw new Error('MatrixColumnsContext can not be used outside the scope of MatrixColumnsContextProvider');
  }

  return context;
};

export const MatrixColumnsContextProvider = (props: React.WithChildren) => {
  const { children } = props;

  const isTotalsColumnPinned = useSelector(getIsMatrixTotalsColumnPinned);

  const [canPinColumns, setCanPinColumns] = React.useState(false);
  const [pixelsToScrollEnd, setPixelsToScrollEnd] = React.useState(0);

  const hasMoreContent = Math.floor(pixelsToScrollEnd) > MIN_CONTENT_SCROLL;

  return (
    <MatrixColumnsContext.Provider
      value={{
        canPinColumns,
        hasMoreContent,
        isTotalsColumnPinned,
        pixelsToScrollEnd,
        setCanPinColumns,
        setPixelsToScrollEnd,
      }}
    >
      {children}
    </MatrixColumnsContext.Provider>
  );
};
