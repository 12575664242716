/* eslint-disable max-len */

import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 14;
const ORIGINAL_COLOR = '#5D5D5D';

export const GridView = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => {
  return (
    <svg width={width} height={width} viewBox="0 0 14 14" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path d="M12.6 0H1.4C0.63 0 0 0.63 0 1.4V12.6C0 13.37 0.63 14 1.4 14H12.6C13.37 14 14 13.37 14 12.6V1.4C14 0.63 13.37 0 12.6 0ZM4.2 12.6H2.1C1.715 12.6 1.4 12.285 1.4 11.9V9.8H4.2V12.6ZM4.2 8.4H1.4V5.6H4.2V8.4ZM4.2 4.2H1.4V2.1C1.4 1.715 1.715 1.4 2.1 1.4H4.2V4.2ZM8.4 12.6H5.6V9.8H8.4V12.6ZM8.4 8.4H5.6V5.6H8.4V8.4ZM8.4 4.2H5.6V1.4H8.4V4.2ZM11.9 12.6H9.8V9.8H12.6V11.9C12.6 12.285 12.285 12.6 11.9 12.6ZM12.6 8.4H9.8V5.6H12.6V8.4ZM12.6 4.2H9.8V1.4H11.9C12.285 1.4 12.6 1.715 12.6 2.1V4.2Z" />
    </svg>
  );
};
