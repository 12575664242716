import React from 'react';
import { useSelector } from 'react-redux';

import { getCompanyName, getDarkLogoImage, getIsLoggedIn } from '../../../../../ducks';
import { isDefined } from '../../../../../utils/is';
import { isEmpty } from '../../../../../utils/isEmpty';
import { LanguageSwitcher } from '../../../../header/LanguageSwitcher';

import styles from './LayoutContent.module.scss';

interface Props {
  title: string;
  text?: string;
}

export const LayoutContent = ({ children, title, text }: React.WithChildren<Props>) => {
  const isUserLoggedIn = useSelector(getIsLoggedIn);
  const logoImageUrl = useSelector(getDarkLogoImage);
  const companyName = useSelector(getCompanyName);

  return (
    <div className={styles.wrapper}>
      <div className={styles.logo}>
        {!isEmpty(logoImageUrl) ?
          <img src={logoImageUrl} alt={companyName} />
        : companyName}
        {!isUserLoggedIn && <LanguageSwitcher variant="dark" className={styles.switcher} />}
      </div>
      <div className={styles.content}>
        <h2 className={styles.header}>{title}</h2>
        {isDefined(text) && <p>{text}</p>}
      </div>
      {children}
    </div>
  );
};
