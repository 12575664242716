import { Skeleton } from '@typings';
import cx from 'classnames';

import styles from './SkeletonBase.module.scss';

interface Props {
  className?: string;
  style?: React.CSSProperties;
  color?: Skeleton.Color;
}

export const SkeletonBase = ({ style, className, color = 'light' }: Props) => {
  return <div className={cx(styles.skeletonBase, className, { [styles.dark]: color === 'dark' })} style={style} />;
};
