import { Cms } from '@typings';
import { ParseKeys } from 'i18next';
import { useTranslation } from 'react-i18next';

import { sectionCategories } from '../../../cmsTemplates';
import { useModalVisibility } from '../../../utils/hooks';
import { useCustomTemplates } from '../../../utils/hooks/cms/useCustomTemplates';
import Icon, { IconColor, IconType } from '../../various/Icon';
import { ModalShell } from '../../various/ModalShell';
import { Skeleton } from '../../various/Skeleton';

import styles from './TemplateModal.module.scss';
import { TemplateThumbnail } from './TemplateThumbnail';

interface Props {
  handleClickTemplate: (model: Cms.BlockModel[]) => void;
  titleKey: ParseKeys<['cms']>;
  isModalVisible: boolean;
  hideModal: () => void;
}

export const TemplateModal = ({ handleClickTemplate, isModalVisible, hideModal, titleKey }: Props) => {
  const { t } = useTranslation(['cms']);
  const { customTemplates, isLoading } = useCustomTemplates();
  const { showModal } = useModalVisibility('CustomTemplateEditorModal');

  return (
    <>
      <ModalShell isOpen={isModalVisible} onClose={hideModal} className={styles.modal} title={t(titleKey)}>
        <div className={styles.categoryWrapper}>
          <p className={styles.sectionTitle}>{t('cms:custom_layouts')}</p>
          <div className={styles.thumbnailsSet}>
            <button type="button" className={styles.createButton} onClick={showModal}>
              <Icon type={IconType.Plus} color={IconColor.Blue} size={16} />
              <span>{t('cms:create_new_layout')}</span>
            </button>
            {isLoading && (
              <>
                <Skeleton type="rectangle" aspectRatio={1} color="light" className={styles.skeleton} />
                <Skeleton type="rectangle" aspectRatio={1} color="light" className={styles.skeleton} />
              </>
            )}
            {customTemplates.map(template => (
              <TemplateThumbnail key={template.id} id={template.id} templateModel={template} handleClickTemplate={handleClickTemplate} />
            ))}
          </div>
        </div>

        {sectionCategories.map(({ i18nKey, templates }) => (
          <div key={i18nKey} className={styles.categoryWrapper}>
            <p className={styles.sectionTitle}>{t(i18nKey)}</p>
            <div className={styles.thumbnailsSet}>
              {templates.map((row, rowIdx) =>
                row.map((model, modelIdx) => {
                  const templateId = t(i18nKey) + modelIdx.toString() + rowIdx.toString();

                  return (
                    <TemplateThumbnail id={templateId} key={templateId} templateModel={model} handleClickTemplate={handleClickTemplate} />
                  );
                }),
              )}
            </div>
          </div>
        ))}
      </ModalShell>
    </>
  );
};
