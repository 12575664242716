import { equals } from 'ramda';
import { AnyAction, Middleware } from 'redux';

import { setHasUnsavedLayoutChanges, setModifiedLanguages } from '../ducks';
import { getIsLayoutAction, getIsLocalizationsAction } from '../logic/cms/status';

const layoutKeys: (keyof Store['cms'])[] = ['blocks', 'customStyles', 'groups', 'sections'];

export const cmsChangeMiddleware: Middleware<Record<string, unknown>, Store> = store => next => (action: AnyAction) => {
  const prevCmsState = store.getState().cms;
  const actionResult = next(action);
  const newCmsState = store.getState().cms;

  const availableLanguages = newCmsState.pageMeta.data?.availableLanguages ?? [];
  const { hasUnsavedLayoutChanges, modifiedLanguages: currentModifiedLanguages } = newCmsState.status;

  const isLayoutAction = getIsLayoutAction(action);
  const isLocalizationsAction = getIsLocalizationsAction(action);

  if (hasUnsavedLayoutChanges || (!isLayoutAction && !isLocalizationsAction)) {
    return actionResult;
  }

  const hasLayoutChanged = isLayoutAction && layoutKeys.some(key => !equals(prevCmsState[key], newCmsState[key]));

  if (hasLayoutChanged) {
    store.dispatch(setModifiedLanguages(availableLanguages));
    store.dispatch(setHasUnsavedLayoutChanges(true));

    return actionResult;
  }

  const modifiedLanguages = availableLanguages.filter(
    code => currentModifiedLanguages.includes(code) || !equals(prevCmsState.localizations[code], newCmsState.localizations[code]),
  );

  store.dispatch(setModifiedLanguages(modifiedLanguages));

  return actionResult;
};
