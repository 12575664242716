import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getHasOptimisticUpdatesByProductId, getProductsOrder, pushEvent } from '../../../ducks';
import { AllProductsTrackingEvent, getAddedProductPositionWithSearchQueryEvent } from '../../../utils/analytics/events';
import { useIsTouchScreen } from '../../../utils/hooks/useIsTouchScreen';
import { useSearchParamByName } from '../../../utils/hooks/useSearchParamByName';
import { useToggleInOrder } from '../../../utils/hooks/useToggleInOrder';
import { IconType } from '../../various/Icon';
import { Button, NewButtonProps } from '../../various/NewButton';

type Props = NewButtonProps<'button'> & {
  productId: string;
};

export const AddProductButton = ({ productId, ...rest }: Props) => {
  const { t } = useTranslation(['selections']);
  const dispatch = useDispatch();
  const [shouldShowRemoveButton, setShouldShowRemoveButton] = React.useState(false);
  const isTouchScreen = useIsTouchScreen();
  const hasSearchQuery = useSearchParamByName('filters')?.includes('search');
  const isMutatingUnits = useSelector(getHasOptimisticUpdatesByProductId(productId));
  const productsOrder = useSelector(getProductsOrder);
  const { hasUnits, isInOrder, isAdding, isRemoving, toggleInOrder } = useToggleInOrder({ productId });
  const isLoading = isRemoving || isAdding || isMutatingUnits;

  const handleProductTracking = () => {
    if (hasSearchQuery) {
      const productIndex = productsOrder.findIndex(product => product === productId) + 1;
      dispatch(pushEvent(getAddedProductPositionWithSearchQueryEvent(productIndex)));
    }

    dispatch(pushEvent({ event: AllProductsTrackingEvent.ADD_TO_SELECTION_CLICKED }));
  };

  const handleClick = () => {
    if (!isInOrder) {
      handleProductTracking();
      toggleInOrder();

      return;
    }

    if (shouldShowRemoveButton) {
      toggleInOrder();
      setShouldShowRemoveButton(false);

      return;
    }

    setShouldShowRemoveButton(true);
  };

  const buttonIconType = React.useMemo(() => {
    if (!isInOrder) {
      return IconType.Basket;
    }

    if (shouldShowRemoveButton) {
      return IconType.TrashLinear;
    }

    if (hasUnits) {
      return IconType.DoubleCheck;
    }

    return IconType.Check;
  }, [hasUnits, isInOrder, shouldShowRemoveButton]);

  const buttonColor = React.useMemo(() => {
    if (!isInOrder) {
      return 'primary';
    }

    if (shouldShowRemoveButton || isRemoving) {
      return 'danger';
    }

    return 'success';
  }, [isInOrder, isRemoving, shouldShowRemoveButton]);

  const buttonVariant = React.useMemo(() => {
    if (isInOrder) {
      return 'flat';
    }

    return 'shadow';
  }, [isInOrder]);

  const buttonLabel = isInOrder ? t('selections:remove_from_selection') : t('selections:add_to_selection');

  const buttonListeners = isInOrder && {
    onBlur: () => setShouldShowRemoveButton(false),
    ...(!isTouchScreen && {
      onFocus: () => setShouldShowRemoveButton(true),
      onMouseEnter: () => setShouldShowRemoveButton(true),
      onMouseLeave: () => setShouldShowRemoveButton(false),
    }),
  };

  const testId = isInOrder ? 'removeProductButton' : 'addProductButton';

  return (
    <Button
      {...rest}
      icon={buttonIconType}
      color={buttonColor}
      variant={buttonVariant}
      isLoading={isLoading}
      aria-label={buttonLabel}
      data-testid={testId}
      onClick={handleClick}
      {...(isInOrder && buttonListeners)}
    />
  );
};
