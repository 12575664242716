import { combineReducers } from 'redux';
import { StateType } from 'typesafe-actions';

import blocks, { CmsBlocksActions } from './blocks';
import customStyles, { CmsCustomStylesActions } from './customStyles';
import { CmsGeneralActions } from './general';
import groups, { CmsGroupsActions } from './groups';
import homepage, { CmsHomepageActions } from './homepage';
import localizations, { CmsLocalizationsActions } from './localizations';
import navigation, { CmsNavigationActions } from './navigation';
import pageMeta from './pageMeta';
import products, { CmsProductsActions } from './products';
import sections, { CmsSectionsActions } from './sections';
import status, { CmsStatusActions } from './status';
import ui, { CmsUIActions } from './ui';

const reducer = combineReducers({
  blocks,
  customStyles,
  groups,
  homepage,
  localizations,
  navigation,
  pageMeta,
  products,
  sections,
  status,
  ui,
});

export * from './sections';
export * from './blocks';
export * from './groups';
export * from './ui';
export * from './pageMeta';
export * from './status';
export * from './products';
export * from './navigation';
export * from './homepage';
export * from './customStyles';
export * from './general';

export type CmsActions =
  | CmsBlocksActions
  | CmsUIActions
  | CmsSectionsActions
  | CmsGroupsActions
  | CmsStatusActions
  | CmsProductsActions
  | CmsNavigationActions
  | CmsCustomStylesActions
  | CmsHomepageActions
  | CmsGeneralActions
  | CmsLocalizationsActions;

export type CmsStore = StateType<typeof reducer>;

export default reducer;
