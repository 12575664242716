import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { authoriseAccessRequest, getIsLoggedIn } from '../../../ducks';
import { paths } from '../../../paths';
import { getToken } from '../../../utils/auth';
import { isDefined } from '../../../utils/is';
import { StartPageLayout } from '../../layout/StartPageLayout';
import { Button } from '../../various/NewButton';

import styles from './LinkExpiredPage.module.scss';

export const LinkExpiredPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'genericErrors', 'login']);
  const isUserLoggedIn = useSelector(getIsLoggedIn);

  React.useEffect(() => {
    if (isDefined(getToken())) {
      dispatch(authoriseAccessRequest());
    }
  }, [dispatch]);

  return (
    <StartPageLayout
      title={t('genericErrors:link_expired')}
      text={isUserLoggedIn ? t('genericErrors:link_expired_description') : t('genericErrors:link_expired_guest_description')}
    >
      {isUserLoggedIn ?
        <Button as={Link} to={paths.ROOT} className={styles.button} size="large" color="dark" isFluid>
          {t('common:home_page')}
        </Button>
      : <Button as={Link} to={paths.LOGIN} className={styles.button} size="large" color="dark" isFluid>
          {t('login:sign_in')}
        </Button>
      }
    </StartPageLayout>
  );
};
