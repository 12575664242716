import { CmsV0 } from '@centra/cms/legacy/v0';
import { assocPath } from 'ramda';

import { isDefined } from '../../../../../../utils/is';
import { assignIdsToParts } from '../../../../assignIdsToParts';

const FULL_CONTENT_SET_WIDTH = 100;
const ADJUSTED_CONTENT_SET_WIDTH = 96;

const isProductBlockV0 = (block: Maybe<CmsV0.AnyBlock>): block is CmsV0.ProductBlock => block?.blockType === 'product';

const convertBlock = (block: CmsV0.AnyBlock) => {
  if (isProductBlockV0(block)) {
    return block;
  }

  const { text } = block.settings;

  if (!isDefined(text)) {
    return block;
  }

  const blockWithAdjustedWidth =
    text.desktop.width === FULL_CONTENT_SET_WIDTH ?
      assocPath(['settings', 'text', 'desktop', 'width'], ADJUSTED_CONTENT_SET_WIDTH, block)
    : block;

  if (!Array.isArray(text.parts)) {
    return blockWithAdjustedWidth;
  }

  return assocPath(['settings', 'text'], { ...block.settings.text, ...assignIdsToParts(text.parts) }, blockWithAdjustedWidth);
};

export const convertLegacyTextParts = (response: CmsV0.SectionsResponse) => {
  const { sections } = response;

  const convertedResponse = sections.map(section => ({
    ...section,
    blocks: section.blocks.map(block => convertBlock(block)),
  }));

  return { ...response, sections: convertedResponse };
};
