import { Order } from '@typings';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getInvoices } from '../../../ducks/order';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import Heading from '../../various/Heading';

import { InvoiceCard } from './InvoiceCard';
import { PaymentCard } from './PaymentCard';

interface Props {
  order: Order.Open | Order.Closed;
}

export const PaymentsList = ({ order }: Props) => {
  const { t } = useTranslation(['payments']);
  const invoices = useSelector(getInvoices);
  const { payments } = order;

  if (isEmpty(invoices) && (!isDefined(payments) || isEmpty(payments))) {
    return null;
  }

  return (
    <section>
      <Heading title={t('payments:payment_other')} />
      {invoices.map(invoice => (
        <InvoiceCard key={invoice.invoice} orderId={order.order} invoice={invoice} />
      ))}
      {payments?.map(payment => <PaymentCard key={payment.payment} order={order} payment={payment} />)}
    </section>
  );
};
