import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getHasProductDetailsFailed, getIsBarcodeScannerEnabled, pushEvent, resetAllProductDetailsData } from '../../../ducks';
import { BarcodeScannerTrackingEvent } from '../../../utils/analytics/events';
import { useModalVisibility } from '../../../utils/hooks';
import { ModalSize } from '../ModalElements';
import { ModalShell } from '../ModalShell';

import { ScannerContent } from './ScannerContent';

export const BarcodeScannerModal = () => {
  const { t } = useTranslation(['common']);

  const dispatch = useDispatch();
  const hasProductDetailsFailed = useSelector(getHasProductDetailsFailed);
  const isBarcodeScannerEnabled = useSelector(getIsBarcodeScannerEnabled);
  const { isModalVisible, hideModal } = useModalVisibility('BarcodeScannerModal');

  React.useEffect(() => {
    if (!isModalVisible) {
      return;
    }

    dispatch(pushEvent({ event: BarcodeScannerTrackingEvent.SCANNER_OPENED }));
  }, [dispatch, isModalVisible]);

  const handleCloseScanner = React.useCallback(() => {
    hideModal();

    if (hasProductDetailsFailed) {
      dispatch(resetAllProductDetailsData());
    }
  }, [dispatch, hasProductDetailsFailed, hideModal]);

  if (!isBarcodeScannerEnabled) {
    return null;
  }

  return (
    <ModalShell title={t('common:scan_barcode')} isOpen={isModalVisible} onClose={handleCloseScanner} size={ModalSize.Small} equalPadding>
      <ScannerContent />
    </ModalShell>
  );
};
