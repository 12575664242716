import { isDefined } from './is';

export const DEFAULT_ALLOWED_PROTOCOLS = ['http:', 'https:', 'ftp:', 'ftps:', 'mailto:', 'tel:'] as const;
export type Protocol = (typeof DEFAULT_ALLOWED_PROTOCOLS)[number];

const getUrlObject = (url: string, allowedProtocols: Protocol[] = [...DEFAULT_ALLOWED_PROTOCOLS]) => {
  try {
    const urlObject = new URL(url);

    if (!allowedProtocols.includes(urlObject.protocol as Protocol)) {
      return null;
    }

    return urlObject;
  } catch (_) {
    return null;
  }
};

export const isSafeUrl = (url: Maybe<string>, allowedProtocols?: Protocol[]) =>
  isDefined(url) && isDefined(getUrlObject(url, allowedProtocols));

export const getSafeUrl = (url: Maybe<string>, allowedProtocols?: Protocol[]) => {
  return isDefined(url) ? getUrlObject(url, allowedProtocols) : null;
};
