import { Cms } from '@centra';
import React from 'react';
import { mergeRefs, useLayer } from 'react-laag';
import { useSelector } from 'react-redux';

import { getCurrentItem } from '../../../ducks';
import { useClickOutside, useOnEscHandler, useResizeObserver, useToolbarVisibility } from '../../../utils/hooks';
import { EditorLanguageContext } from '../context/EditorLanguageContext';

import { ToolbarBase } from './ToolbarBase';

const DEFAULT_OFFSET = 0;
const DEFAULT_BOUNDS = {
  bottom: 0,
  height: 0,
  left: 0,
  right: 0,
  top: 0,
  width: 0,
};

interface Props {
  tools: JSX.Element[];
  type: Cms.ToolbarType;
  title: string;
  offset?: number;
  isLockedVisible?: boolean;
  roundedBottomCorners?: boolean;
  placement?: 'top-center' | 'top-end' | 'top-start';
  onClose?: (event?: MouseEvent | KeyboardEvent | UIEvent) => void;
}

export const Toolbar = React.memo(
  ({ type, title, tools, offset = DEFAULT_OFFSET, placement = 'top-start', isLockedVisible, roundedBottomCorners, onClose }: Props) => {
    const { isDefaultLanguage } = React.useContext(EditorLanguageContext);
    const ref = React.useRef<HTMLDivElement | null>(null);
    const { isToolbarVisible, hideToolbar } = useToolbarVisibility(type);
    const currentItem = useSelector(getCurrentItem);

    const closeToolbar = React.useCallback(
      (event?: MouseEvent | KeyboardEvent | UIEvent) => {
        if (!isToolbarVisible) {
          return;
        }

        hideToolbar();
        onClose?.(event);
      },
      [hideToolbar, onClose, isToolbarVisible],
    );

    useClickOutside({
      element: ref.current,
      enabled: !isLockedVisible,
      handler: closeToolbar,
    });

    useOnEscHandler(document.documentElement, closeToolbar, isToolbarVisible);

    // Forces react-laag to recalculate the position of the toolbar when the size of the current item changes
    const [, updateState] = React.useState({});
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useResizeObserver(currentItem.element, forceUpdate);

    const { layerProps } = useLayer({
      auto: true,
      isOpen: isToolbarVisible,
      placement,
      possiblePlacements: ['top-end', 'top-center', 'top-start'],
      trigger: {
        getBounds: () => currentItem.element?.current?.getBoundingClientRect() ?? DEFAULT_BOUNDS,
        getParent: () => currentItem.element?.current?.parentElement as HTMLElement,
      },
      triggerOffset: offset,
    });

    if (!isDefaultLanguage) {
      return null;
    }

    return (
      <ToolbarBase
        tools={tools}
        closeToolbar={closeToolbar}
        title={title}
        style={layerProps.style}
        isToolbarVisible={isToolbarVisible}
        ref={mergeRefs(ref, layerProps.ref)}
        roundedBottomCorners={roundedBottomCorners}
      />
    );
  },
);
