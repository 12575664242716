import { Selects } from '@typings';
import cx from 'classnames';
import React from 'react';

import { useSelectKeyboardHandler } from '../../../../../utils/hooks/select/useSelectKeyboardHandler';
import { isDefined } from '../../../../../utils/is';
import { isEmpty } from '../../../../../utils/isEmpty';
import Icon, { IconColor, IconType } from '../../../Icon';
import { useSelectContext } from '../context/SelectContext';

import styles from './SelectTrigger.module.scss';

const ICON_SIZE = {
  large: 20,
  regular: 20,
  small: 16,
};

interface Props {
  options: Selects.Option[];
  renderValue: (value: Selects.NonNullableValue) => Maybe<string>;
}

export const SelectButton = React.forwardRef<HTMLButtonElement, Props>(({ options, renderValue }, ref) => {
  const {
    dataTestId,
    describedById,
    errorMessageId,
    focusedPosition,
    isDisabled,
    isInvalid,
    isLoading,
    isOpen,
    onBlur,
    optionsId,
    placeholder,
    selection,
    showArrow,
    size,
    toggleIsOpen,
    triggerId,
  } = useSelectContext();

  const { handleKeyDown } = useSelectKeyboardHandler({ options });

  const selectionValue = React.useMemo(() => {
    if (!isDefined(selection)) {
      return '';
    }

    return [selection]
      .flat()
      .map(value => renderValue(value))
      .filter(isDefined)
      .join(', ');
  }, [renderValue, selection]);

  const classNames = cx(styles.trigger, {
    [styles.active]: isOpen,
    [styles.disabled]: isDisabled,
    [styles.invalid]: isInvalid,
    [styles.sizeSmall]: size === 'small',
    [styles.sizeLarge]: size === 'large',
  });

  const focusedValue = options[focusedPosition ?? -1]?.value;
  const focusedOptionId = isDefined(focusedValue) ? `${optionsId}-${focusedValue}` : undefined;

  return (
    <button
      ref={ref}
      id={triggerId}
      data-testid={dataTestId}
      type="button"
      role="combobox"
      aria-busy={isLoading}
      aria-expanded={isOpen}
      aria-controls={optionsId}
      aria-haspopup="listbox"
      aria-activedescendant={focusedOptionId}
      aria-describedby={describedById}
      aria-invalid={isInvalid}
      aria-errormessage={errorMessageId}
      className={classNames}
      disabled={isDisabled}
      onKeyDown={handleKeyDown}
      onBlur={onBlur}
      onClick={toggleIsOpen}
    >
      {isEmpty(selectionValue) && <span className={styles.placeholder}>{placeholder}</span>}
      {selectionValue}
      {showArrow && <Icon type={IconType.ArrowDown} color={IconColor.DarkGray} size={ICON_SIZE[size]} rotation={isOpen ? 180 : 0} />}
    </button>
  );
});
