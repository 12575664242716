import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { setCurrentGroup, setCurrentSection, showModal } from '../../../ducks';
import { useGroupCopyPaste } from '../../../utils/hooks/cms/useGroupCopyPaste';
import { useIsOver } from '../../../utils/hooks/useIsOver';
import { isDefined } from '../../../utils/is';
import { IconType } from '../../various/Icon';
import { MaybeTooltip } from '../../various/MaybeTooltip';
import { Button } from '../../various/NewButton';
import { EditorUIContext } from '../context/EditorUIContext';
import { GroupResizer } from '../GroupResizer';

import styles from './GroupButtons.module.scss';

interface Props {
  groupId?: string;
  groupIndex: number;
  sectionId: string;
  resizableBlocksIds?: Set<string>;
}

export const GroupButtons = ({ groupId, groupIndex, sectionId, resizableBlocksIds }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['cms', 'common']);

  const wrapperRef = React.useRef<HTMLDivElement>(null);

  const { screenType } = React.useContext(EditorUIContext);
  const { hasGroupInClipboard, pasteCopiedGroup } = useGroupCopyPaste();

  const isMobileScreenType = screenType === 'mobile';
  const { ref: hoverAreaRef, isOver } = useIsOver();

  const handleAddGroup = () => {
    if (isDefined(groupId)) {
      dispatch(setCurrentGroup(groupId));
    }

    dispatch(setCurrentSection(sectionId));
    dispatch(showModal('AddTemplateModal'));
  };

  const handlePasteGroup = () => {
    pasteCopiedGroup(groupIndex, sectionId);
  };

  const canResize = isDefined(resizableBlocksIds) && resizableBlocksIds.size > 0;
  const classNames = cx(styles.wrapper, 'groupButtons', {
    [styles.alignTop]: groupIndex === 0,
  });

  return (
    <div ref={wrapperRef} className={classNames}>
      {canResize && isDefined(resizableBlocksIds) ?
        <GroupResizer
          parentRef={wrapperRef}
          resizableBlocksIds={resizableBlocksIds}
          className={cx(styles.button, { [styles.dashed]: !isOver })}
        />
      : <div />}
      <MaybeTooltip content={isMobileScreenType && t('cms:add_layout')} mouseEnterDelay={100}>
        <Button
          size="mini"
          variant={isOver && !hasGroupInClipboard ? 'solid' : 'bordered'}
          data-testid="addLayoutButton"
          className={cx(styles.button, { [styles.dashed]: !isOver })}
          icon={IconType.AddSquareOutline}
          onClick={handleAddGroup}
        >
          {!isMobileScreenType && t('cms:add_layout')}
        </Button>
      </MaybeTooltip>
      {hasGroupInClipboard && (
        <MaybeTooltip content={isMobileScreenType && t('common:paste')} mouseEnterDelay={100}>
          <Button
            size="mini"
            variant={isOver ? 'solid' : 'bordered'}
            data-testid="pasteLayoutButton"
            className={cx(styles.button, { [styles.dashed]: !isOver })}
            icon={IconType.Paste}
            onClick={handlePasteGroup}
          >
            {!isMobileScreenType && t('common:paste')}
          </Button>
        </MaybeTooltip>
      )}
      <div ref={hoverAreaRef} className={styles.hoverArea} />
    </div>
  );
};
