import cx from 'classnames';
import { LayerProps, UseHoverProps } from 'react-laag';

import { isDefined } from '../../../../utils/is';
import styles from '../PopoverMenu.module.scss';

interface Props {
  layerProps: LayerProps;
  hoverProps?: UseHoverProps;
  groupLabel?: string;
  className?: string;
}

export const MenuContainer = ({ layerProps, children, className, groupLabel, hoverProps }: React.WithOptionalChildren<Props>) => {
  return (
    <div {...layerProps} {...hoverProps} className={cx(styles.menuContainer, className)}>
      {isDefined(groupLabel) && <div className={styles.groupLabel}>{groupLabel}</div>}
      {children}
    </div>
  );
};
