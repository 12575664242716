import cx from 'classnames';
import React from 'react';

import styles from './MatrixCells.module.scss';

interface Props {
  className?: string;
  rowSpan?: number;
  isDisabled?: boolean;
  isSelected?: boolean;
  dataTestId?: string;
  color?: string;
  isFocusTarget?: boolean;
}

export const RegularBodyCell = React.forwardRef<HTMLDivElement, React.WithChildren<Props>>((props, ref) => {
  const { children, className, rowSpan, isSelected, isDisabled = false, dataTestId, color, isFocusTarget } = props;
  const classNames = cx(styles.regularBodyCell, { [styles.disabled]: isDisabled, [styles.selected]: isSelected }, className);

  return (
    // this element doesn't need key event listener (rule jsx-a11y/click-events-have-key-events is disabled)
    // because keyboard navigation is already handled by MatrixNavigationContext
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
    <td data-testid={dataTestId} rowSpan={rowSpan} className={classNames} style={{ color }}>
      {/* tabIndex is neccessary to allow proper keyboard navigation */}
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
      <div ref={ref} tabIndex={0} data-focus-target={isFocusTarget}>
        {children}
      </div>
    </td>
  );
});
