import React from 'react';
import { useSelector } from 'react-redux';

import { getCompanyName } from '../../../ducks';
import { isDefined } from '../../../utils/is';

interface Props {
  title?: string;
}

export const PageTitle = React.memo(({ title, children }: React.WithChildren<Props>) => {
  const companyName = useSelector(getCompanyName);

  const composedTitle = React.useMemo(() => {
    if (!isDefined(title)) {
      return `${companyName} B2B Showroom`;
    }

    return `${companyName} B2B Showroom - ${title}`;
  }, [title]);

  React.useEffect(() => {
    // eslint-disable-next-line functional/immutable-data
    document.title = composedTitle;
  }, [title]);

  return <>{children}</>;
});
