import React from 'react';

import { EditorUIContext } from '../../../components/cms/context/EditorUIContext';
import { CMS_SCREENS_SIZES } from '../../../constants/cms';
import { useScreenWidth } from '../useScreenWidth';

export const useSpecifiedDeviceWidth = () => {
  const { screenType } = React.useContext(EditorUIContext);
  const screenWidth = useScreenWidth();
  const isInEditor = React.useContext(EditorUIContext).viewMode === 'editor';

  const specifiedWidth = CMS_SCREENS_SIZES[screenType];

  if (isInEditor || screenWidth >= specifiedWidth) {
    return specifiedWidth;
  }

  return screenWidth;
};
