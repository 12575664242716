import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './DelwinExpiredMessage.module.scss';

interface Props {
  className?: string;
  expiredMessage: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const DelwinExpiredMessage = ({ className, expiredMessage, onClick }: Props) => {
  const { t } = useTranslation(['common']);

  return (
    <div className={cx(styles.expiredMessageWrapper, className)}>
      <span>{expiredMessage}</span>
      {onClick && (
        <button className={styles.removeUnits} onClick={onClick}>
          {t('common:remove_units')}
        </button>
      )}
    </div>
  );
};
