import { IconComponentProps } from '@typings/internal';
import React from 'react';

import { CentraLogo } from './Logos/CentraLogo';
import { PayPalLogo } from './Logos/PayPalLogo';
import { StripeLogo } from './Logos/StripeLogo';

export const enum LogoType {
  Centra = 'centra',
  Stripe = 'stripe',
  PayPal = 'paypal',
}

interface Props {
  className?: string;
  type: LogoType;
  size?: number;
}

const icon: Record<LogoType, { Icon: React.FC<IconComponentProps> } & IconComponentProps> = {
  centra: { Icon: CentraLogo },
  paypal: { Icon: PayPalLogo },
  stripe: { Icon: StripeLogo },
};

export const Logo = ({ className, type, size }: Props) => {
  const { Icon: IconComponent, ...iconProps } = icon[type];

  return (
    <span className={className}>
      <IconComponent width={size} {...iconProps} />
    </span>
  );
};
