import React from 'react';
import { useTranslation } from 'react-i18next';

import { useDeleteSelections } from '../../../../services/hooks/orders/useDeleteSelections';
import { useConfirmationGuard } from '../../../../utils/hooks/useConfirmationGuard';
import { isEmpty } from '../../../../utils/isEmpty';
import { IconType } from '../../../various/Icon';
import { Button } from '../../../various/NewButton';

interface Props {
  selectedOrders: string[] | number[];
  resetSelect: () => void;
}

export const OrderActionsColumnTitle = ({ selectedOrders, resetSelect }: Props) => {
  const { t } = useTranslation(['confirmationConfig', 'common']);
  const confirmationGuard = useConfirmationGuard();
  const { deleteSelectionByIds } = useDeleteSelections();

  const deleteMultiple = confirmationGuard(() => ({
    content: t('confirmationConfig:delete_multiple_orders.content', {
      count: selectedOrders.length,
    }),
    onOk: () => {
      deleteSelectionByIds.mutate(selectedOrders as string[]);
      resetSelect();
    },
    title: t('confirmationConfig:delete_multiple_orders.title', {
      count: selectedOrders.length,
    }),
  }));

  const handleDeleteMultiple = React.useCallback(() => !isEmpty(selectedOrders) && deleteMultiple(), [selectedOrders, deleteMultiple]);

  if (isEmpty(selectedOrders)) {
    return null;
  }

  return (
    <Button
      variant="ghost"
      color="danger"
      icon={IconType.TrashLinear}
      onClick={handleDeleteMultiple}
      aria-label={t('common:delete_selected')}
    />
  );
};
