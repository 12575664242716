import { Tabs } from '@typings';
import cx from 'classnames';
import React from 'react';

import { Tab } from './Tab';
import styles from './TabList.module.scss';

interface Props {
  tabs: Tabs.Tab[];
  tablistName: string;
  selectedId: string | number;
  size?: Tabs.TabListSize;
  onChange: (id: string | number) => void;
  className?: string;
}

export const TabList = ({ tabs, tablistName, selectedId, onChange, size = 'regular', className }: Props) => {
  const isSmall = size === 'small';

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value);
    },
    [onChange],
  );

  return (
    <div className={cx(styles.tablist, { [styles.small]: isSmall }, className)} role="tablist" aria-label={tablistName}>
      {tabs.map(tab => {
        const isSelected = selectedId === tab.id;

        return <Tab key={tab.id} tabParameters={tab} isSelected={isSelected} onChange={handleChange} tablistName={tablistName} />;
      })}
    </div>
  );
};
