export function isEmpty<T extends string | any[] | Record<string, Unrestricted>>(
  subject: T | Empty.Array | Empty.Object | Empty.String,
): subject is Bottom<T> {
  switch (typeof subject) {
    case 'object':
      return Object.keys(subject).length === 0;
    case 'string':
      return subject === '';
    default:
      return false;
  }
}

type Bottom<T> =
  T extends string ? Empty.String
  : T extends any[] ? Empty.Array
  : T extends Record<string, Unrestricted> ? Empty.Object
  : never;
