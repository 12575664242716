import { Cms } from '@typings';
import { useTranslation } from 'react-i18next';

import { useModalVisibility, useUrlState } from '../../utils/hooks';
import { isEmpty } from '../../utils/isEmpty';
import { EmptyState } from '../various/EmptyState';
import { Link } from '../various/Link';
import { Button } from '../various/NewButton';

interface Props {
  hasError?: boolean;
  hasPages?: boolean;
}

export const PagesListFallback = ({ hasError = false, hasPages = false }: Props) => {
  const { t } = useTranslation(['common', 'cms', 'genericErrors']);

  const [search, setSearch] = useUrlState('search');
  const [statusFilter, setStatusFilter] = useUrlState<Cms.PageStatus>('filter');

  const { showModal } = useModalVisibility('CreatePageFormModal');

  if (hasError) {
    return (
      <EmptyState title={t('genericErrors:we_encountered_unexpected_error')}>
        <Button as={Link} to="/" variant="bordered" color="dark">
          {t('common:home_page').toLocaleUpperCase()}
        </Button>
      </EmptyState>
    );
  }

  if (hasPages) {
    return (
      <EmptyState title={t('cms:no_results.default.title')} subtitle={t('cms:no_results.default.subtitle')}>
        <Button variant="bordered" color="dark" onClick={showModal}>
          {t('cms:create_page')}
        </Button>
      </EmptyState>
    );
  }

  if (!isEmpty(search)) {
    return (
      <EmptyState title={t('cms:no_results.search.title', { searchQuery: search })} subtitle={t('cms:no_results.search.subtitle')}>
        <Button variant="bordered" color="dark" onClick={() => setSearch('')}>
          {t('common:clear_search')}
        </Button>
        <Button variant="bordered" color="dark" onClick={showModal}>
          {t('cms:create_page')}
        </Button>
      </EmptyState>
    );
  }

  if (!isEmpty(statusFilter)) {
    return (
      <EmptyState title={t('cms:no_results.filters.title')} subtitle={t('cms:no_results.filters.subtitle')}>
        <Button variant="bordered" color="dark" onClick={() => setStatusFilter('')}>
          {t('common:clear_filters')}
        </Button>
      </EmptyState>
    );
  }

  return null;
};
