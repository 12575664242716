import { useMatrixLayout } from '../../../../utils/hooks/matrix/useMatrixLayout';
import { getHasValidConfigurator } from '../../../../utils/matrix';
import { useMatrixDistributionContext, useMatrixPrepackContext, useMatrixSettingsContext } from '../../../products/ProductMatrix/context';

const VARIANT_OFFSET = 2;

export const OffsetFooterCells = () => {
  const { shouldHidePrepackSwitchColumn, visiblePrepacksLength } = useMatrixPrepackContext();
  const { isMobile, productConfiguration } = useMatrixSettingsContext();
  const { shouldHideDistributionColumn } = useMatrixDistributionContext();
  const { isTwoDimensional } = useMatrixLayout();

  const hasVisiblePrepacks = visiblePrepacksLength > 0;

  const dynamicColumnsOffsetMap: [boolean, number][] = [
    [isTwoDimensional, 1],
    [hasVisiblePrepacks, visiblePrepacksLength],
    [hasVisiblePrepacks && !shouldHidePrepackSwitchColumn, 1],
    [!shouldHideDistributionColumn, 1],
    [!isMobile, VARIANT_OFFSET + (getHasValidConfigurator(productConfiguration) ? productConfiguration.secondary.length : 0)],
  ];

  const offset = dynamicColumnsOffsetMap.filter(([condition]) => condition).reduce((acc, [_, cur]) => acc + cur, 0);

  return <th colSpan={offset} />;
};
