import { useSelector } from 'react-redux';

import { getProductDetailsAttributes } from '../../../../../ducks';
import { isEmpty } from '../../../../../utils/isEmpty';
import { Skeleton } from '../../../Skeleton';

import styles from './DetailsAttributesSkeleton.module.scss';

const MAX_NUMBER_OF_ATTRIBUTES = 6;

export const DetailsAttributesSkeleton = () => {
  const detailsAttributes = useSelector(getProductDetailsAttributes);

  if (isEmpty(detailsAttributes)) {
    return null;
  }

  const attributesCount = Math.min(detailsAttributes.length, MAX_NUMBER_OF_ATTRIBUTES);

  const leftColumnCount = Math.round(attributesCount / 2);
  const rightColumnCount = attributesCount - leftColumnCount;

  return (
    <div className={styles.container}>
      <Skeleton type="text" rows={leftColumnCount} size="medium" gap="medium" />
      {rightColumnCount > 0 && <Skeleton type="text" size="medium" gap="medium" rows={rightColumnCount} />}
    </div>
  );
};
