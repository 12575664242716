import cx from 'classnames';
import React from 'react';
import { SideProp } from 'react-laag/dist/Sides';
import { CSSTransition } from 'react-transition-group';

import styles from './Popover.module.scss';

interface Props {
  className?: string;
  layerSide: SideProp;
  isOpen: boolean;
}

export const PopoverSheet = React.forwardRef<HTMLDivElement, React.WithChildren<Props>>(
  ({ children, layerSide, className, isOpen, ...rest }, ref) => {
    const classNames = cx(styles.wrapper, className, {
      [styles.closed]: !isOpen,
    });

    return (
      <CSSTransition
        in={isOpen}
        timeout={150}
        unmountOnExit
        classNames={{
          enter: styles.animationEnter,
          enterActive: styles.animationEnterActive,
          exit: styles.animationExit,
          exitActive: styles.animationExitActive,
        }}
      >
        <div ref={ref} className={classNames} data-side={layerSide} {...rest}>
          {children}
        </div>
      </CSSTransition>
    );
  },
);
