import { clamp } from 'ramda';
import React from 'react';

import { StyleSelectContext } from '../../../components/cms/context/StyleSelectContext';
import { isDefined } from '../../is';
import { isAlphanumericKey, Key } from '../../keys';

interface Props {
  applyCustomStyle: (className: string) => () => void;
  editCustomStyle: (className: string) => () => void;
}

export const useStyleSelectKeyActions = ({ applyCustomStyle, editCustomStyle }: Props) => {
  const {
    SUBMENU_MAX_INDEX,
    activeOption,
    activeSubOption,
    scrollElement,
    filteredPartStyles,
    isLastOptionActive,
    hideDropdown,
    exitSubMenu,
    exitMenu,
    setActiveOption,
    setActiveSubOption,
    showDropdown,
  } = React.useContext(StyleSelectContext);

  const isInsideSubMenu = isDefined(activeSubOption);

  const updateSubOptionIndex = (offset: number) => {
    setActiveSubOption(option => (isDefined(option) ? clamp(0, SUBMENU_MAX_INDEX, option + offset) : 0));
  };

  const updateOptionIndex = React.useCallback(
    (offset: number) => {
      const max = Math.max(filteredPartStyles.length, 0);
      setActiveOption(option => (isDefined(option) ? clamp(0, max, option + offset) : 0));
    },
    [filteredPartStyles],
  );

  const handleVerticalNavigation = React.useCallback(
    (event: React.KeyboardEvent) => {
      event.preventDefault();

      const offset = event.code === Key.DOWN ? 1 : -1;

      if (isInsideSubMenu) {
        updateSubOptionIndex(offset);

        return;
      }
      updateOptionIndex(offset);
    },
    [isInsideSubMenu, updateOptionIndex],
  );

  const handleHomeEnd = React.useCallback(
    (event: React.KeyboardEvent) => {
      event.preventDefault();
      const isHome = event.code === Key.HOME;

      if (isInsideSubMenu) {
        setActiveSubOption(isHome ? 0 : SUBMENU_MAX_INDEX);

        return;
      }
      setActiveOption(isHome ? 0 : filteredPartStyles.length - 1);
    },
    [isInsideSubMenu, filteredPartStyles],
  );

  const openSubMenu = React.useCallback(() => {
    if (!isInsideSubMenu && isDefined(activeOption) && !filteredPartStyles[activeOption]?.isInternal) {
      setActiveSubOption(0);
    }
  }, [isInsideSubMenu, activeOption, filteredPartStyles]);

  const handleTab = React.useCallback(
    (event: React.KeyboardEvent) => {
      if (event.shiftKey) {
        return;
      }

      isLastOptionActive ? hideDropdown() : setActiveOption(filteredPartStyles.length);
    },
    [isLastOptionActive, filteredPartStyles, hideDropdown],
  );

  const selectActiveOption = React.useCallback(
    (event: React.KeyboardEvent) => {
      if (!isLastOptionActive && activeSubOption !== SUBMENU_MAX_INDEX) {
        event.preventDefault();
      }

      if (!isDefined(activeOption) || isLastOptionActive) {
        return;
      }

      const activeOptionClassName = filteredPartStyles[activeOption]?.className;

      if (!isDefined(activeOptionClassName)) {
        return;
      }

      if (activeSubOption === 0 || activeSubOption === null) {
        applyCustomStyle(activeOptionClassName)();
      } else if (activeSubOption === 1) {
        editCustomStyle(activeOptionClassName)();
      }
    },
    [activeOption, filteredPartStyles, activeSubOption, applyCustomStyle, editCustomStyle, isLastOptionActive],
  );

  const handleEscape = React.useCallback(
    (event: React.KeyboardEvent) => {
      event.stopPropagation();
      if (isInsideSubMenu) {
        return exitSubMenu();
      }
      if (isDefined(activeOption)) {
        return exitMenu();
      }

      return hideDropdown();
    },
    [isInsideSubMenu, activeOption, hideDropdown],
  );

  const handleAlphanumericKeys = React.useCallback(
    (event: React.KeyboardEvent) => {
      const key = event.key.toLowerCase();
      if (!isAlphanumericKey(key) || !isDefined(activeOption) || isInsideSubMenu) {
        return;
      }
      event.preventDefault();
      const cycledTextStyles = [...filteredPartStyles.slice(activeOption + 1), ...filteredPartStyles.slice(0, activeOption + 1)];
      const index = cycledTextStyles.findIndex(style => style.name.toLowerCase().startsWith(key));
      if (index >= 0) {
        setActiveOption((activeOption + index + 1) % cycledTextStyles.length);
      }
    },
    [filteredPartStyles, isInsideSubMenu, activeOption],
  );

  const onKeyDown = React.useCallback(
    (event: React.KeyboardEvent) => {
      if (!isDefined(scrollElement.current)) {
        return;
      }

      showDropdown();

      const handlers: Record<string, (event: React.KeyboardEvent) => void> = {
        [Key.DOWN]: handleVerticalNavigation,
        [Key.UP]: handleVerticalNavigation,
        [Key.HOME]: handleHomeEnd,
        [Key.END]: handleHomeEnd,
        [Key.LEFT]: exitSubMenu,
        [Key.RIGHT]: openSubMenu,
        [Key.TAB]: handleTab,
        [Key.ENTER]: selectActiveOption,
        [Key.NUMPADENTER]: selectActiveOption,
        [Key.ESCAPE]: handleEscape,
      };

      const handler = handlers[event.code];

      if (isDefined(handler)) {
        handler(event);

        return;
      }
      handleAlphanumericKeys(event);
    },
    [handleVerticalNavigation, openSubMenu, handleTab, selectActiveOption, handleHomeEnd, handleAlphanumericKeys, handleEscape],
  );

  return { onKeyDown };
};
