import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 19;
const ORIGINAL_HEIGHT = 14;
const PROPORTAION = ORIGINAL_HEIGHT / ORIGINAL_WIDTH;

export const FlagDe = ({ width = ORIGINAL_WIDTH }: IconComponentProps) => {
  return (
    <svg width={width} height={width * PROPORTAION} viewBox="0 0 19 14" fill="none" style={{ borderRadius: '3px' }}>
      <path d="M0 9.33333H19V14H0V9.33333Z" fill="#FFCE00" />
      <path d="M0 0H19V4.66667H0V0Z" fill="black" />
      <path d="M0 4.66667H19V9.33333H0V4.66667Z" fill="#DD0000" />
    </svg>
  );
};
