import { Cms, Id } from '@typings';
import cx from 'classnames';
import { groupBy, prop, uniq } from 'ramda';
import React from 'react';

import { getScreenRelativeStyles } from '../../../../logic/pages';
import { useSpecifiedDeviceWidth } from '../../../../utils/hooks';
import { SizeContext } from '../../context/SizeContext';

import styles from './GroupGrid.module.scss';

interface Props {
  groupId: Id;
  isManualLayoutChangeActive: boolean;
  blocks: Cms.AnyBlock[];
  isInEditor: boolean;
  screenType: Cms.ScreenType;
}

export const GroupGrid = ({
  groupId,
  children,
  isManualLayoutChangeActive,
  blocks,
  isInEditor,
  screenType,
}: React.PropsWithChildren<Props>) => {
  const { rowHeight } = React.useContext(SizeContext);
  const screenWidth = useSpecifiedDeviceWidth();

  const classNames = cx(styles.group, 'contentBlock', {
    [styles.highlightedGroup]: isManualLayoutChangeActive,
  });

  const blocksByType = groupBy(prop('blockType'), blocks);
  const blocksRanges = Object.entries(blocksByType).reduce<Record<Cms.BlockType, [number, number][]>>(
    (acc, [type, items]) => {
      const ranges = items.map<[number, number]>(block => {
        const { startY, spanY } = isInEditor ? block.position[screenType] : getScreenRelativeStyles(screenWidth, block.position);

        return [startY, startY + spanY];
      });

      return {
        ...acc,
        [type]: uniq(ranges),
      };
    },
    { content: [], product: [] },
  );

  const totalRows = Math.max(...Object.values(blocksRanges).flat(2));

  const templateRows = Array.from({ length: totalRows }, (_, index) => {
    if (blocksRanges.product.some(([start, end]) => index + 1 >= start && index + 1 < end)) {
      return `${rowHeight + 30}px`;
    }

    return 'min-content';
  }).join(' ');

  return (
    <div className={classNames} id={groupId} style={{ gridTemplateRows: templateRows }}>
      {children}
    </div>
  );
};
