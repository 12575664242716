import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Translations } from '@typings';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { addToast } from '../../../components/various/Toasts';
import { pushEvent } from '../../../ducks';
import { TranslationsTrackingEvent } from '../../../utils/analytics/events';
import { updateTranslations } from '../../translationsRepository';

import { TRANSLATIONS_QUERY_KEY } from './useTranslations';

export const useUpdateTranslations = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const handleError = () => {
    addToast(t('translations:saving_failed'));
  };

  const handleSuccess = (data: Translations.TranslationsDTOAll) => {
    dispatch(pushEvent({ event: TranslationsTrackingEvent.TRANSLATABLE_FIELDS_MODIFIED }));
    queryClient.setQueryData([TRANSLATIONS_QUERY_KEY], data);
  };

  return useMutation({
    mutationFn: updateTranslations,
    onError: handleError,
    onSuccess: handleSuccess,
  });
};
