import { Settings } from '@typings';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getIsStockTypeSeparationEnabled } from '../../../ducks';
import { FormFieldset, FormGrid, InputField } from '../../various/Form';

interface Props {
  isDisabled: boolean;
  isLoading: boolean;
}

export const CustomTextsFields = ({ isDisabled, isLoading }: Props) => {
  const { t } = useTranslation(['common', 'settings', 'translations']);
  const isStockSeparationEnabled = useSelector(getIsStockTypeSeparationEnabled);
  const { register } = useFormContext<Settings.CustomisationFormFields>();

  return (
    <FormFieldset isDisabled={isDisabled}>
      <FormGrid rowGap="xlarge">
        <InputField
          orientation="horizontal"
          placeholder={t('common:login_page_message')}
          label={t('common:login_page_message')}
          description={t('settings:login_page_description')}
          isLoading={isLoading}
          {...register('loginPageMessage')}
        />
        <InputField
          orientation="horizontal"
          placeholder={t('common:sold_out')}
          label={t('translations:sold_out_label')}
          description={t('settings:sold_out_label_description')}
          isLoading={isLoading}
          {...register('soldOutLabel')}
        />
        {isStockSeparationEnabled && (
          <>
            <InputField
              orientation="horizontal"
              placeholder={t('common:stock')}
              label={t('translations:stock_filter_name')}
              description={t('settings:filter_label_description')}
              isLoading={isLoading}
              {...register('stockTypeLabel')}
            />
            <InputField
              orientation="horizontal"
              placeholder={t('common:preorder')}
              label={t('translations:preorder_filter_name')}
              description={t('settings:filter_label_description')}
              isLoading={isLoading}
              {...register('preorderStockTypeLabel')}
            />
          </>
        )}
      </FormGrid>
    </FormFieldset>
  );
};
