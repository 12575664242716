import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { addVoucherRequest, getIsVoucherValid, pushEvent } from '../../../../ducks';
import { CheckoutTrackingEvent } from '../../../../utils/analytics/events';
import { isEmpty } from '../../../../utils/isEmpty';
import { Input } from '../../../various';
import Button from '../../../various/Button';

import styles from './VoucherForm.module.scss';

export const VoucherForm = () => {
  const dispatch = useDispatch();
  const isSubmittedVoucherValid = useSelector(getIsVoucherValid);
  const { t } = useTranslation(['common', 'checkout', 'validation']);

  const [voucherCode, setVoucherCode] = React.useState('');
  const [isVoucherInvalid, setIsVoucherInvalid] = React.useState(isSubmittedVoucherValid);

  const handleVoucherInput = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setVoucherCode(event.target.value);
    setIsVoucherInvalid(false);
  }, []);

  const handleAddVoucher = React.useCallback(() => {
    dispatch(pushEvent({ event: CheckoutTrackingEvent.APPLY_VOUCHER_BUTTON_CLICKED }));
    dispatch(addVoucherRequest(voucherCode));
  }, [voucherCode]);

  React.useEffect(() => {
    setIsVoucherInvalid(!isSubmittedVoucherValid);
    !isSubmittedVoucherValid && dispatch(pushEvent({ event: CheckoutTrackingEvent.VOUCHER_INVALID_ERROR_SHOWED }));
  }, [dispatch, isSubmittedVoucherValid]);

  return (
    <div className={styles.voucherForm}>
      <div className={styles.formRow}>
        <Input
          wrapperClassName={styles.voucherFormInput}
          onChange={handleVoucherInput}
          placeholder={t('checkout:voucher_add')}
          value={voucherCode}
          variant={['orange']}
        />
        <Button onClick={handleAddVoucher} variant={['simpleOrangeButton']}>
          {t('common:apply')}
        </Button>
      </div>
      {isVoucherInvalid && !isEmpty(voucherCode) && <div className={styles.error}>{t('validation:invalid_voucher_hint')}</div>}
    </div>
  );
};
