import { Cms } from '@typings';
import { omit } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { TextStyles } from '../../../cmsTemplates';
import { getCmsBlockById, getCurrentBlockId, getCurrentItem } from '../../../ducks';
import { isButtonPart } from '../../../logic/cms/styles';
import { getContentBlockPart, getScreenRelativeStyles } from '../../../logic/pages';
import { useSpecifiedDeviceWidth } from '../../../utils/hooks';
import { useContentPartToolbar } from '../../../utils/hooks/cms/useContentPartToolbar';
import { isDefined } from '../../../utils/is';
import { EditorUIContext } from '../context/EditorUIContext';
import { AlignmentSwitch, ColorEditor, RemoveButton, SizeSelect, TextPartStyleSelect } from '../TextTools';
import { Toolbar } from '../Toolbar';
import { AcceptDiscard } from '../Toolbar/Tools/AcceptDiscard';

const DEFAULT_TEXT_SETTINGS: Cms.LocalTextChanges & { textStyle: string } = {
  fontSize: 16,
  textAlignment: 'left',
  textColor: '#000000',
  textStyle: TextStyles.PrimaryHeading,
};

const TOOLBAR_TYPE = 'Text';
const TOOLBAR_POSITION_OFFSET = 20;

export const TextToolbar = () => {
  const [settings, setSettings] = React.useState(DEFAULT_TEXT_SETTINGS);
  const currentBlockId = useSelector(getCurrentBlockId);
  const [toolbarLocked, setToolbarLocked] = React.useState(false);
  const { screenType } = React.useContext(EditorUIContext);
  const screenWidth = useSpecifiedDeviceWidth();
  const currentItem = useSelector(getCurrentItem);
  const { t } = useTranslation(['cms']);
  const block = useSelector(getCmsBlockById(currentBlockId));
  const { closeToolbar, onStyleChange, removeCurrentPart, updateContentSettings } = useContentPartToolbar(TOOLBAR_TYPE);

  React.useLayoutEffect(() => {
    const contentPart = getContentBlockPart(block, currentItem.index);
    if (!isDefined(contentPart) || isButtonPart(contentPart)) {
      return;
    }

    setSettings({
      ...contentPart.general,
      ...getScreenRelativeStyles(screenWidth, contentPart),
    });
  }, [block, currentItem, screenWidth]);

  const onAlignmentChange = React.useCallback(
    (textAlignment: string) => updateContentSettings([screenType, 'textAlignment'], textAlignment),
    [updateContentSettings, screenType],
  );

  const onColorChange = React.useCallback(
    (color: string) => updateContentSettings(['general', 'textColor'], color),
    [updateContentSettings],
  );

  const onSizeChange = React.useCallback(
    (size: number | string) => {
      updateContentSettings([screenType, 'fontSize'], size);
    },
    [updateContentSettings, screenType],
  );

  const handleSetToolbarLocked = React.useCallback(
    (isLocked: boolean) => () => {
      setToolbarLocked(isLocked);
    },
    [setToolbarLocked],
  );

  const handleRemoveText = React.useCallback(() => {
    removeCurrentPart();
  }, [removeCurrentPart]);

  const handleCloseToolbar = React.useCallback(
    (event?: MouseEvent) => {
      closeToolbar(event);
    },
    [closeToolbar],
  );

  const tools = React.useMemo(() => {
    const isDesktop = screenType === 'desktop';

    const textPartStyleSelect = (
      <TextPartStyleSelect
        key="text_style_select"
        currentStyle={settings.textStyle}
        localChanges={omit(['textStyle'], settings)}
        onChange={onStyleChange}
      />
    );

    const colorEditor = (
      <ColorEditor
        key="color_editor"
        value={settings.textColor}
        onChange={onColorChange}
        onOpenEditor={handleSetToolbarLocked(true)}
        onCloseEditor={handleSetToolbarLocked(false)}
      />
    );

    return [
      ...(!isDesktop ? [] : [textPartStyleSelect]),
      <SizeSelect key="size_select" value={settings.fontSize} onChange={onSizeChange} />,
      <AlignmentSwitch key="alignment_switch" value={settings.textAlignment} onChange={onAlignmentChange} switcherType="text" />,
      ...(!isDesktop ? [] : [colorEditor]),
      ...(!isDesktop ? [] : [<RemoveButton key="remove_button" onClick={handleRemoveText} />]),
      <AcceptDiscard key="acceptdiscard" acceptAction={handleCloseToolbar} />,
    ];
  }, [
    screenType,
    settings,
    onStyleChange,
    onSizeChange,
    onAlignmentChange,
    onColorChange,
    handleSetToolbarLocked,
    handleRemoveText,
    handleCloseToolbar,
  ]);

  return (
    <Toolbar
      type={TOOLBAR_TYPE}
      title={t('cms:text')}
      tools={tools}
      placement="top-center"
      offset={TOOLBAR_POSITION_OFFSET}
      isLockedVisible={toolbarLocked}
      roundedBottomCorners
      onClose={handleCloseToolbar}
    />
  );
};
