import { Dates, DeliveryWindow, ItemsEtaPerDelwin, Product } from '@typings';

import { isDefined } from '../utils/is';
import { isEmpty } from '../utils/isEmpty';

import { isMultivariantRelation } from './merchandise';
import { getAllOrderedVariants } from './products';

const getVariantsFromProducts = (products: Product.Standard[]) =>
  products.flatMap(productDetails => {
    return getAllOrderedVariants(productDetails).filter(
      variant => variant.product === productDetails.product || isMultivariantRelation(variant),
    );
  });

const getItemsWithEtaByDelwin = (delwinId: DeliveryWindow.Id, products: Product.Standard[]) => {
  return getVariantsFromProducts(products).flatMap(variant =>
    variant.items.flatMap(item => {
      if (!isDefined(item.etaByDeliveryWindow?.[delwinId])) {
        return [];
      }

      return item;
    }),
  );
};

export const getItemsEtaPerDelwin = (delwinIds: DeliveryWindow.Id[], products: Product.Standard[]) =>
  delwinIds.reduce((acc: ItemsEtaPerDelwin, delwinId) => {
    const itemsWithEta = getItemsWithEtaByDelwin(delwinId, products);

    const etaPerItem = itemsWithEta.reduce(
      (itemsAcc, item) => ({
        ...itemsAcc,
        [item.item]: item.etaByDeliveryWindow?.[delwinId] as Dates.DateAsSQLString,
      }),
      {},
    );

    return { ...acc, [delwinId]: etaPerItem };
  }, {});

export const getHasEtaInSelectedDeliveryWindows = (delwinIds: string[], products: Product.Standard[]) =>
  delwinIds.some(delwinId => !isEmpty(getItemsWithEtaByDelwin(delwinId, products)));
