const ORIGINAL_WIDTH = 20;

export const HelpOutline = ({ width = ORIGINAL_WIDTH, color = 'currentColor' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox="0 0 20 20" fill={color}>
      <path
        fillRule="evenodd"
        d="M10 4.273a5.727 5.727 0 1 0 0 11.454 5.727 5.727 0 0 0 0-11.454ZM3 10a7 7 0 1 1 14 0 7 7 0 0 1-14 0Z"
        clipRule="evenodd"
      />
      <path d="M10 14.11a.663.663 0 1 0 0-1.326.663.663 0 0 0 0 1.326Z" />
      <path
        fillRule="evenodd"
        d="M9.046 6.372a2.492 2.492 0 1 1 1.59 4.712.636.636 0 0 1-1.272-.023v-.53A.636.636 0 0 1 10 9.893a1.22 1.22 0 1 0-1.22-1.22.636.636 0 1 1-1.272 0 2.492 2.492 0 0 1 1.538-2.302Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
