import React from 'react';
import { useTranslation } from 'react-i18next';

import { ExpandableContainer } from '../../../various/ExpandableContainer/ExpandableContainer';
import { HelpTooltip } from '../../../various/HelpTooltip';
import { useMenuSectionContext } from '../context/MenuSectionContext';
import { FolderForm, LinkForm, PagesForm } from '../Forms';

import { ItemsHelperTooltip } from './ItemsHelperTooltip';
import styles from './MenuSectionList.module.scss';

const PAGES_ID = 'expandablePages';
const LINK_ID = 'expandableLink';
const FOLDER_ID = 'expandableFolder';

export const AvailableList = () => {
  const { isInEditMode } = useMenuSectionContext();
  const [expandedItem, setExpandedItem] = React.useState<string | null>(PAGES_ID);
  const { t } = useTranslation(['common', 'settings']);

  const handleExpandedItemToggle = React.useCallback(
    (id: string) => {
      setExpandedItem(currentId => (currentId === id ? null : id));
    },
    [setExpandedItem],
  );

  const getIsItemExpanded = React.useCallback(
    (id: string) => {
      return expandedItem === id;
    },
    [expandedItem],
  );

  return (
    <div className={styles.container}>
      <div className={styles.containerTitle}>
        {t('common:items_to_add')}
        {isInEditMode && <ItemsHelperTooltip translationKey="settings:items_to_add_info" />}
      </div>
      <ExpandableContainer
        id={PAGES_ID}
        title={t('common:page_other')}
        isOpen={getIsItemExpanded(PAGES_ID)}
        toggleOpen={handleExpandedItemToggle}
      >
        <PagesForm />
      </ExpandableContainer>
      <ExpandableContainer id={LINK_ID} title={t('common:link')} isOpen={getIsItemExpanded(LINK_ID)} toggleOpen={handleExpandedItemToggle}>
        <LinkForm />
      </ExpandableContainer>
      <ExpandableContainer
        id={FOLDER_ID}
        title={
          <>
            <span>{t('common:folder')}</span>
            <HelpTooltip content={t('settings:folder_info')} />
          </>
        }
        isOpen={getIsItemExpanded(FOLDER_ID)}
        toggleOpen={handleExpandedItemToggle}
      >
        <FolderForm />
      </ExpandableContainer>
    </div>
  );
};
