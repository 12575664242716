import { Cms, Id } from '@typings';

import { showroomApi } from './api';

export const fetchCustomStyles = async () => showroomApi.GET('/cms/customStyles')({});

export const createTextStyle = async (textStyle: Cms.TextStyleBasic) =>
  showroomApi.POST('/cms/customStyles/text')({
    data: { customStyles: [textStyle] },
  });

export const createButtonStyle = async (buttonStyle: Cms.ButtonStyleBasic) =>
  showroomApi.POST('/cms/customStyles/button')({
    data: { customStyles: [buttonStyle] },
  });

export const deleteCustomStyle = async (customStyleId: Id) =>
  showroomApi.DELETE('/cms/customStyles/:id')({
    pathParams: { id: customStyleId },
  });

interface Payload {
  id: Id;
  customStyle: Cms.ContentPartStyleBasic;
}

export const editCustomStyle = async ({ id, customStyle }: Payload) =>
  showroomApi.PUT('/cms/customStyles/:id')({
    data: customStyle,
    pathParams: { id },
  });

export const translateDeletedCustomStyles = async (names: string[]) =>
  showroomApi.GET('/cms/translateDeletedCustomStyles')({
    query: { classNames: names },
  });
