import { uniq } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getOrderProducts, getOrderStatus, pushEvent, removeProductsFromOrder } from '../../ducks';
import { getIsOrderEditable } from '../../logic/Orders';
import { TopBarTrackingEvent } from '../analytics/events';
import { isDefined } from '../is';
import { isEmpty } from '../isEmpty';

import { useConfirmationGuard } from './useConfirmationGuard';

interface Options {
  onRemove?: () => void;
}

export const useRemoveAllProducts = ({ onRemove }: Options = {}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'confirmationConfig']);
  const products = useSelector(getOrderProducts);
  const orderStatus = useSelector(getOrderStatus);
  const confirmationGuard = useConfirmationGuard();

  const removeAllProductsConfig = t('confirmationConfig:remove_all_products', {
    returnObjects: true,
  });

  const clearAllConfig = {
    ...removeAllProductsConfig,
    okText: t('common:remove'),
  };

  const removeAllProducts = React.useCallback(
    confirmationGuard(() => ({
      onOk: () => {
        dispatch(
          removeProductsFromOrder({
            products: uniq(products.map(product => product.product).filter(isDefined)),
          }),
        );
        dispatch(pushEvent({ event: TopBarTrackingEvent.CLEAR_ALL_CLICKED }));
        onRemove?.();
      },
      ...clearAllConfig,
    })),
    [products, onRemove, confirmationGuard],
  );

  const canRemoveAllProducts = getIsOrderEditable(orderStatus) && !isEmpty(products);

  return { canRemoveAllProducts, removeAllProducts };
};
