import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  getActiveDeliveryWindowsIdsForOrderBuyer,
  getAllDeliveryWindows,
  getCheckoutFields,
  getIsDeliveryWindowsLoading,
  getIsRequestingPayment,
  getIsSavingAnything,
  getIsSubmittingOrder,
  getOrderDeliveryWindowsIds,
  getOrderDetails,
  getTermsText,
} from '../../ducks';
import { getDeliveryWindowsByIds, getIsExpired } from '../../logic/deliveryWindows';
import { getTotalAmountInWholeOrder, getUnitsCountInDelwin } from '../../logic/Orders';
import { isDefined } from '../../utils/is';
import { CallToAction } from '../orders/CallToAction';
import { OrderTopBar } from '../orders/OrderTopBar';
import { BottomWrapper } from '../various/BottomWrapper';
import { PageTitle } from '../various/PageTitle';

import { CheckoutActions } from './CheckoutActions/CheckoutActions';
import { CheckoutButtonContent } from './CheckoutButtonContent';
import { CheckoutContent } from './CheckoutContent';
import { CheckoutNoUnits } from './CheckoutNoUnits';
import { CheckoutTermsModal } from './CheckoutTermsModal';

export const Checkout = () => {
  const { t } = useTranslation(['checkout']);

  const termsText = useSelector(getTermsText);
  const isSubmittingOrder = useSelector(getIsSubmittingOrder);
  const isRequestingPayment = useSelector(getIsRequestingPayment);
  const orderDetails = useSelector(getOrderDetails);
  const isDeliveryWindowsLoading = useSelector(getIsDeliveryWindowsLoading);
  const isSaving = useSelector(getIsSavingAnything);
  const allDeliveryWindows = useSelector(getAllDeliveryWindows);
  const orderDeliveryWindowsIds = useSelector(getOrderDeliveryWindowsIds);
  const activeDeliveryWindowsIds = useSelector(getActiveDeliveryWindowsIdsForOrderBuyer);
  const checkoutFields = useSelector(getCheckoutFields);

  const isSavingOrLoading = !isDefined(orderDetails.order.order) || isDeliveryWindowsLoading || isSaving;
  const noUnitsAdded = getTotalAmountInWholeOrder(orderDetails.order) === 0;
  const isDisabled = isSavingOrLoading || noUnitsAdded;
  const showNoUnitsNotification = noUnitsAdded && !isSavingOrLoading;
  const delwinsWithProducts = React.useMemo(() => {
    const orderDelwins = getDeliveryWindowsByIds(allDeliveryWindows, orderDeliveryWindowsIds);

    return orderDelwins.filter(delwin => {
      const hasUnits = getUnitsCountInDelwin(delwin.deliveryWindow, orderDetails.order.products) > 0;
      const isExpired = getIsExpired(delwin, activeDeliveryWindowsIds);

      return hasUnits && !isExpired;
    });
  }, [allDeliveryWindows, orderDeliveryWindowsIds, orderDetails, activeDeliveryWindowsIds]);

  return (
    <PageTitle title={t('checkout:checkout_page_title', { orderNumber: orderDetails.order.orderNumber })}>
      <OrderTopBar>
        <CheckoutActions />
      </OrderTopBar>

      {!showNoUnitsNotification ?
        <CheckoutContent
          key={orderDetails.order.order} // key is used to reset the form when the order changes
          delwinsWithProducts={delwinsWithProducts}
          isSavingOrLoading={isSavingOrLoading}
          checkoutFields={checkoutFields}
        />
      : <CheckoutNoUnits />}

      <CheckoutTermsModal terms={termsText} />
      <BottomWrapper>
        <CallToAction
          callToActionVersion="checkout"
          checkoutButtonText={<CheckoutButtonContent orderDetails={orderDetails} />}
          isSubmitting={isSubmittingOrder || isRequestingPayment}
          isDisabled={isDisabled}
        />
      </BottomWrapper>
    </PageTitle>
  );
};
