/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 18;
const ORIGINAL_HEIGHT = 17;
const PROPORTION = ORIGINAL_HEIGHT / ORIGINAL_WIDTH;
const ORIGINAL_COLOR = '#5C5C5C';

export const Compose = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => {
  return (
    <svg width={width} height={width * PROPORTION} viewBox="0 0 18 17" fill="none">
      <path
        d="M14.1667 14.4854C12.2188 14.751 10.2177 14.875 8.21667 14.875C6.21563 14.875 4.21458 14.751 2.26667 14.4854V3.66562C4.25 3.41771 6.28646 3.25833 8.30521 3.25833L10.4656 1.18646C9.61563 1.15104 8.76562 1.13333 7.93333 1.13333C5.66667 1.13333 3.29375 1.32812 0.991667 1.68229C0.425 1.77083 0 2.26667 0 2.83333V15.3885C0 15.9552 0.425 16.3625 0.991667 16.451C3.36458 16.8229 5.80833 17 8.21667 17C10.625 17 13.051 16.8229 15.4417 16.451C16.0083 16.3625 16.4333 15.9552 16.4333 15.3885V6.8L14.1667 9.1375V14.4854ZM17.4781 3.08125C16.7167 1.85937 15.6542 0.832291 14.3969 0.0885403C14.1667 -0.0531262 13.8656 -0.0177101 13.6885 0.159375L6.92396 6.74687C6.85313 6.81771 6.81771 6.88854 6.78229 6.97708C6.32188 8.35833 5.77292 9.84583 5.27708 11.2271C5.20625 11.4396 5.25938 11.6698 5.41875 11.8292C5.525 11.9354 5.68438 11.9885 5.84375 11.9885C5.91458 11.9885 5.96771 11.9708 6.03854 11.9531C7.45521 11.475 8.96042 10.9615 10.4125 10.501C10.501 10.4656 10.5719 10.4302 10.6427 10.3594L17.3896 3.77187C17.5844 3.59479 17.6198 3.31146 17.4781 3.08125ZM9.93438 9.43854L9.91667 9.45625C9.20833 9.68646 8.51771 9.91667 7.80938 10.1469L7.13646 9.50937C7.38438 8.81875 7.63229 8.12812 7.84479 7.45521L7.8625 7.4375H8.76562V8.2875C8.76562 8.44687 8.88958 8.57083 9.04896 8.57083H9.93438V9.43854Z"
        fill={color}
      />
    </svg>
  );
};
