import React from 'react';

import { CheckoutSteps } from '../../various/CheckoutSteps';

import styles from './OrderTopBar.module.scss';

export const OrderTopBar = ({ children }: React.WithOptionalChildren) => {
  return (
    <div className={styles.container}>
      <CheckoutSteps />
      {children}
    </div>
  );
};
