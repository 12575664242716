import { Cms } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getCmsBlockById, getCurrentBlockId, updateBlockSettings } from '../../../ducks';
import { getSourceByUrl } from '../../../logic/cms/player';
import { getIsContentBlock } from '../../../logic/pages';
import { useModalVisibility } from '../../../utils/hooks';
import { isDefined } from '../../../utils/is';
import { getSecondsFromHHMMSS } from '../../../utils/time';
import { ModalShell } from '../../various/ModalShell';

import { EmbedForm } from './EmbedForm';

const getHasEmbedSettings = (block: Maybe<Cms.AnyBlock>): block is Cms.ContentBlock & Required<{ settings: { link: string } }> =>
  isDefined(block) && getIsContentBlock(block) && isDefined(block.settings.embed);

export const EmbedModal = () => {
  const { t } = useTranslation(['cms']);
  const { isModalVisible, hideModal } = useModalVisibility('EmbedModal');
  const dispatch = useDispatch();
  const [embedSource, setMediaSource] = React.useState<Cms.EmbedSource>('URL');
  const currentBlock = useSelector(getCurrentBlockId);
  const block = useSelector(getCmsBlockById(currentBlock));
  const embedSettings = getHasEmbedSettings(block) ? block.settings.embed : undefined;

  const setSourceFromSettings = () => () => {
    if (isDefined(embedSettings)) {
      setMediaSource(getSourceByUrl(embedSettings.url) || 'URL');
    }
  };

  React.useEffect(setSourceFromSettings(), [embedSettings]);

  const onSubmit = React.useCallback(
    (data: Cms.EmbedPayload) => {
      if (!isDefined(currentBlock)) {
        hideModal();

        return;
      }

      dispatch(
        updateBlockSettings({
          blockId: currentBlock,
          updatePath: ['embed'],
          value: getEmbedData(data),
        }),
      );
      hideModal();
    },
    [currentBlock],
  );

  const getEmbedData = (data: Cms.EmbedPayload) => {
    const { url, autoplay, loop, mute, start } = data;
    const isPlayer = getSourceByUrl(url) !== 'URL';

    return {
      url,
      ...(isPlayer && {
        playerData: {
          autoplay,
          loop,
          mute,
          start: getSecondsFromHHMMSS(start),
        },
      }),
    };
  };

  const title = getHasEmbedSettings(block) ? t('cms:change_embed') : t('cms:add_embed');

  return (
    <ModalShell title={title} isOpen={isModalVisible} onClose={hideModal} modalClosedCallback={setSourceFromSettings()}>
      <EmbedForm onSubmit={onSubmit} embedSettings={embedSettings} source={embedSource} setSource={setMediaSource} onCancel={hideModal} />
    </ModalShell>
  );
};
