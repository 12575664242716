import { Product } from '@typings';
import cx from 'classnames';
import React from 'react';

import { ETA_TOOLTIP_MOUSE_ENTER_DELAY } from '../../../constants/delays';
import { getIsDefaultBadge } from '../../../ducks/helpers';
import { useResizeObserver, useThrottledCallback } from '../../../utils/hooks';
import { useProductBadges } from '../../../utils/hooks/useProductBadges';
import { isDefined } from '../../../utils/is';
import { Badge } from '../../various/Badge';
import { ProductsEtaContext } from '../ProductsEta';

import styles from './ProductBadges.module.scss';

interface Props {
  hasAvailableVariants: boolean;
  variant: Product.Standard;
}

export const ProductBadges = React.memo(({ hasAvailableVariants, variant }: Props) => {
  const { currentStockTypeDelwinIds } = React.useContext(ProductsEtaContext);
  const { badgesCount, customBadges, defaultBadges, hasEtaBadge } = useProductBadges({
    delwinIds: currentStockTypeDelwinIds,
    isAvailable: hasAvailableVariants,
    variant,
  });

  const badgesRef = React.useRef<HTMLDivElement>(null);
  const [fittingBadgeCount, setFittingBadgeCount] = React.useState(badgesCount);

  const calculateFittingBadgeCount = useThrottledCallback(() => {
    const wrapperEl = badgesRef.current;
    const badgeEl = wrapperEl?.children[0];

    if (!isDefined(wrapperEl) || !isDefined(badgeEl)) {
      return;
    }

    setFittingBadgeCount(Math.floor(wrapperEl.clientHeight / badgeEl.clientHeight) - 1);
  });

  const badgesToShow = [...defaultBadges, ...customBadges].slice(0, fittingBadgeCount);

  useResizeObserver(badgesRef, calculateFittingBadgeCount);

  React.useEffect(() => {
    setFittingBadgeCount(badgesCount);
    calculateFittingBadgeCount();
  }, [badgesCount, calculateFittingBadgeCount]);

  return (
    <div ref={badgesRef} className={styles.badgesWrapper}>
      {badgesToShow.map(badge => {
        const { name, ...badgeProps } = badge;

        const isDefaultBadge = getIsDefaultBadge(badge);
        const isEtaBadge = isDefaultBadge && badge.badgeType === 'eta';
        const isSoldOutBadge = isDefaultBadge && badge.badgeType === 'soldOut';

        const wrapperClassNames = cx(styles.badgeWrapper, {
          [styles.inline]: isEtaBadge || isSoldOutBadge,
          [styles.soldOutWithEtaBadgeContainer]: isSoldOutBadge && hasEtaBadge,
        });

        const badgeClassNames = cx(styles.badge, {
          [styles.etaBadge]: isEtaBadge,
          [styles.soldOutWithEtaBadge]: isSoldOutBadge && hasEtaBadge,
        });

        const key = isDefaultBadge ? badge.badgeType : name;

        return (
          <div key={key} className={wrapperClassNames}>
            <Badge className={badgeClassNames} mouseEnterDelay={ETA_TOOLTIP_MOUSE_ENTER_DELAY} {...badgeProps}>
              {name}
            </Badge>
          </div>
        );
      })}
    </div>
  );
});
