import { Addresses } from '@typings';

import { TextEllipsis } from '../../various/TextEllipsis';

import styles from './shippingAddresses.module.scss';

interface Props {
  shippingAddress: Addresses.MixedShipping;
}

export const ShippingAddressHeader = ({ shippingAddress }: Props) => {
  return (
    <div data-sentry-mask>
      <TextEllipsis className={styles.companyName}>{shippingAddress.company}</TextEllipsis>
      <TextEllipsis className={styles.email}>{shippingAddress.email}</TextEllipsis>
    </div>
  );
};
