import { Image } from '@typings';
import cx from 'classnames';

import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import Icon, { IconType } from '../Icon';
import { MaybeTooltip } from '../MaybeTooltip';
import { TextEllipsis } from '../TextEllipsis';

import styles from './Badge.module.scss';

interface Props {
  backgroundColor?: string;
  children: string;
  className?: string;
  icon?: IconType;
  image?: Image;
  imagePlacement?: 'after' | 'before';
  mouseEnterDelay?: number;
  textColor?: string;
  tooltip?: string;
}

export const Badge = (props: Props) => {
  const { backgroundColor, children, className, icon, image, imagePlacement, mouseEnterDelay, textColor, tooltip } = props;

  const badgeImage = isDefined(image) && <img src={image.url} className={styles.image} width={image.width} height={image.height} alt="" />;

  const hasIcon = isDefined(icon);
  const hasContent = !isEmpty(children) || hasIcon;

  return (
    <MaybeTooltip content={tooltip} disableOnTouchScreen mouseEnterDelay={mouseEnterDelay}>
      <div className={cx(styles.badge, className)} style={{ backgroundColor, color: textColor }}>
        {imagePlacement === 'before' && badgeImage}
        {hasContent && (
          <TextEllipsis className={styles.content}>
            {hasIcon && <Icon type={icon} />}
            {children}
          </TextEllipsis>
        )}
        {imagePlacement === 'after' && badgeImage}
      </div>
    </MaybeTooltip>
  );
};
