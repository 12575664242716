import { createAction } from 'typesafe-actions';

export const loadSingleProductByBarcodeRequest = createAction('barcodeScanner/SINGLE_PRODUCT_BY_BARCODE_LOAD_REQUEST')<{
  productBarcode: string;
}>();

export const addProductByBarcodeRequest = createAction('barcodeScanner/ADD_PRODUCT_BY_BARCODE_REQUEST')();
export const addProductByBarcodeSuccess = createAction('barcodeScanner/ADD_PRODUCT_BY_BARCODE_SUCCESS')();
export const addProductByBarcodeFailure = createAction('barcodeScanner/ADD_PRODUCT_BY_BARCODE_FAILURE')();
export const addProductByBarcodeSoldOutFailure = createAction('barcodeScanner/ADD_PRODUCT_BY_BARCODE_SOLD_OUT_FAILURE')();

export const resetScannedBarcode = createAction('barcodeScanner/RESET_SCANNED_BARCODE')();

export const toggleShouldAddToSelection = createAction('barcodeScanner/TOGGLE_SHOULD_ADD_TO_SELECTION')<boolean>();

export const resetBarcodeScannerData = createAction('barcodeScanner/RESET_BARCODE_SCANNER_DATA')();
