import { Cms } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { CSSTransition } from 'react-transition-group';

import { useIsReorderingContent } from '../../../../../utils/hooks';
import { loadImage } from '../../../../../utils/loadImage';
import { ImageSizeContext } from '../../../../cms/context/ImageSizeContext';

import styles from './Background.module.scss';

interface Props {
  media: Cms.BackgroundMedia;
  style?: React.CSSProperties;
}

const SCREEN_HEIGHT_MULTIPLIER = 2;

export const ImageBackground = ({ media, style }: Props) => {
  const isReorderingContent = useIsReorderingContent();
  const { setImageSize } = React.useContext(ImageSizeContext);
  const [isLoading, setIsLoading] = React.useState(true);
  const [ref, isInView] = useInView({
    rootMargin: `${window.screen.height * SCREEN_HEIGHT_MULTIPLIER}px`,
    triggerOnce: true,
  });

  const onImageLoad = React.useCallback((event: React.SyntheticEvent<HTMLImageElement>) => {
    const image = event.target as HTMLImageElement;
    setImageSize({
      height: image.naturalHeight,
      width: image.naturalWidth,
    });
  }, []);

  React.useEffect(() => {
    if (isInView) {
      const handleLoad = () => {
        setIsLoading(false);
        loadImageSubscription.unsubscribe();
      };

      const loadImageSubscription = loadImage(media.src).subscribe({
        complete: handleLoad,
        error: handleLoad,
      });
    }
  }, [isInView, media.src]);

  const imageElement = (
    <img className={styles.medium} style={style} onLoad={onImageLoad} src={media.src} title={media.title} alt="" role="presentation" />
  );

  if (isReorderingContent) {
    return imageElement;
  }

  return (
    <div className={cx(styles.imageContainer, { [styles.loading]: isLoading })} ref={ref}>
      <CSSTransition
        timeout={1000}
        in={!isLoading}
        mountOnEnter
        classNames={{
          enter: styles.fade,
          enterActive: styles.fadeActive,
        }}
      >
        {imageElement}
      </CSSTransition>
    </div>
  );
};
