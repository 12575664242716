import { Id } from '@typings';
import { useTranslation } from 'react-i18next';

import Icon, { IconColor, IconType } from '../../../../various/Icon';
import { useMenuSectionContext } from '../../context/MenuSectionContext';

import styles from './AddedMenuItem.module.scss';

interface Props {
  handleRemoveMenuItem: () => void;
  handleToggleOpen: () => void;
  label: string;
  isOpen: boolean;
  id: Id;
}

export const AddedMenuItemActions = ({ handleRemoveMenuItem, label, isOpen, id, handleToggleOpen }: Props) => {
  const { areInputsDisabled } = useMenuSectionContext();
  const { t } = useTranslation(['common']);

  if (areInputsDisabled) {
    return null;
  }

  return (
    <div className={styles.actionButtons}>
      <button className={styles.actionButton} aria-label={t('common:remove_item', { item: label })} onClick={handleRemoveMenuItem}>
        <Icon type={IconType.TrashLinear} color={IconColor.Gray} />
      </button>

      <button
        className={styles.actionButton}
        aria-label={t('common:toggle_options')}
        onClick={handleToggleOpen}
        aria-expanded={isOpen}
        aria-controls={`menuItemOptions-${id}`}
      >
        <Icon type={IconType.ChevronDown} color={IconColor.Gray} rotation={isOpen ? 180 : 0} />
      </button>
    </div>
  );
};
