/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;
const ORIGINAL_COLOR = '#848484';

export const Duplicate = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => (
  <svg width={width} height={width} viewBox="0 0 20 20" fill="none">
    <path d="M13.3856 18.0002H8.15451H7.50623H2V16.4102H13.3856V18.0002Z" fill={color} />
    <path d="M13.3856 14.573V14.8908H2V6.61377H2.77999H4.38974H13.3856V14.573Z" fill={color} />
    <path
      d="M6.00488 5.09511L14.8402 2L17.6585 10.0457L14.906 10.9924V6.57172C14.906 5.75729 14.2438 5.09511 13.4295 5.09511H6.00488Z"
      fill={color}
    />
    <path d="M14.9053 14.0406V12.598L18.1593 11.4785L18.6032 12.7454L14.9053 14.0406Z" fill={color} />
  </svg>
);
