import React from 'react';

interface Props {
  activeIndex: number;
  onChange: (index: number) => void;
  orientation: 'horizontal' | 'vertical';
}

export const useGallery = ({ activeIndex = 0, orientation, onChange }: Props) => {
  const isHorizontal = orientation === 'horizontal';

  const ref = React.useRef<HTMLDivElement>(null);
  const [currentIndex, setCurrentIndex] = React.useState(activeIndex);

  const getScrollToOptions = React.useCallback(
    (width: number, height: number) => {
      const dimension = isHorizontal ? width : height;

      return isHorizontal ? { left: dimension * activeIndex } : { top: dimension * activeIndex };
    },
    [activeIndex, isHorizontal],
  );

  React.useEffect(() => {
    const { width = 0, height = 0 } = ref.current?.getBoundingClientRect() ?? {};

    const scrollToOptions = getScrollToOptions(width, height);

    ref.current?.scrollTo(scrollToOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (currentIndex === activeIndex) {
      return;
    }

    setCurrentIndex(activeIndex);

    const { width = 0, height = 0 } = ref.current?.getBoundingClientRect() ?? {};

    const scrollToOptions = getScrollToOptions(width, height);

    ref.current?.scrollTo(scrollToOptions);
  }, [activeIndex, currentIndex, getScrollToOptions, isHorizontal]);

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { target } = event;
    const { clientHeight, clientWidth, scrollLeft, scrollTop } = target as HTMLDivElement;
    const index =
      isHorizontal ?
        Math.max(Math.floor((scrollLeft + clientWidth / 2) / clientWidth), 0)
      : Math.max(Math.floor((scrollTop + clientHeight / 2) / clientHeight), 0);

    if (index !== activeIndex) {
      setCurrentIndex(index);
      onChange(index);
    }
  };

  return { handleScroll, ref };
};
