import { Cms } from '@typings';

import { isDefined } from '../../utils/is';

export const getImageDimensions = (
  positionerRef: React.MutableRefObject<HTMLDivElement | null>,
  mediumSize: Cms.MediumSize,
  imageOffset: Cms.ImageOffset,
): Cms.ImageDimensions => {
  const { width, height } = mediumSize;
  if (!isDefined(positionerRef.current) || width === 0 || height === 0) {
    return { height: 0, left: 0, top: 0, width: 0 };
  }

  const { offsetHeight, offsetWidth } = positionerRef.current;

  const imageProportion = width / height;

  const proportionalWidth = offsetHeight * imageProportion;
  const originalWidth = offsetWidth;

  const proportionalHeight = offsetWidth / imageProportion;

  const isProportionalWidthBigger = proportionalWidth > originalWidth;

  const computedWidth = isProportionalWidthBigger ? proportionalWidth : originalWidth;
  const computedHeight = isProportionalWidthBigger ? offsetHeight : proportionalHeight;

  const widthDifference = Math.abs(computedWidth - offsetWidth);
  const heightDifference = Math.abs(computedHeight - offsetHeight);

  return {
    height: computedHeight,
    left: imageOffset.x * widthDifference,
    top: imageOffset.y * heightDifference,
    width: computedWidth,
  };
};
