import { Cms } from '@typings';
import React from 'react';

import { useCreateCustomLayout } from '../../../services/hooks/cms/useCreateCustomLayout';
import { useCustomLayoutsList } from '../../../services/hooks/cms/useCustomLayoutsList';
import { useDeleteCustomLayout } from '../../../services/hooks/cms/useDeleteCustomLayout';
import { useUpdateCustomLayout } from '../../../services/hooks/cms/useUpdateCustomLayout';

export const CUSTOM_TEMPLATES_KEY = 'customTemplates';

interface UpdateLayoutPayload {
  id: string;
  name: string;
  templateBlocks: Cms.CustomBlockModel[];
}

interface CreateLayoutPayload {
  name: string;
  templateBlocks: Cms.CustomBlockModel[];
}

export const useCustomTemplates = () => {
  const { data, isLoading } = useCustomLayoutsList();
  const { mutate: createCustomLayout, isPending: isCreateLoading } = useCreateCustomLayout();
  const { mutate: updateCustomLayout, isPending: isUpdateLoading } = useUpdateCustomLayout();
  const { mutate: deleteCustomLayout, isPending: isDeleteLoading } = useDeleteCustomLayout();
  const customTemplates: Cms.CustomTemplate[] = React.useMemo(() => data ?? [], [data]);

  const addCustomTemplate = ({ name, templateBlocks }: CreateLayoutPayload, onSuccess: VoidFunction) => {
    createCustomLayout(
      {
        name,
        template: JSON.stringify(templateBlocks),
      },
      { onSuccess },
    );
  };

  const removeCustomTemplate = (id: string) => {
    deleteCustomLayout(id);
  };

  const updateCustomTemplate = ({ id, name, templateBlocks }: UpdateLayoutPayload, onSuccess: VoidFunction) => {
    updateCustomLayout(
      {
        data: {
          name,
          template: JSON.stringify(templateBlocks),
        },
        layoutId: id,
      },
      {
        onSuccess,
      },
    );
  };

  return {
    addCustomTemplate,
    customTemplates,
    isCreateLoading,
    isDeleteLoading,
    isLoading,
    isUpdateLoading,
    removeCustomTemplate,
    updateCustomTemplate,
  };
};
