/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;
const ORIGINAL_COLOR = '#848484';

export const UnpublishedPage = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => {
  return (
    <svg width={width} height={width} viewBox="0 0 20 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.49112 2.24112C3.72553 2.0067 4.04347 1.875 4.375 1.875H11.875C12.0408 1.875 12.1997 1.94085 12.3169 2.05806L16.6919 6.43306C16.8092 6.55027 16.875 6.70924 16.875 6.875V16.875C16.875 17.2063 16.7435 17.5242 16.509 17.7587C16.509 17.7587 16.5089 17.7588 16.5088 17.7588C16.5088 17.7589 16.5087 17.759 16.5087 17.759C16.2742 17.9935 15.9563 18.125 15.625 18.125H4.375C4.04358 18.125 3.72551 17.9934 3.49095 17.7587C3.25667 17.5243 3.125 17.2065 3.125 16.875V3.125C3.125 2.79347 3.2567 2.47553 3.49112 2.24112ZM11.25 3.125L4.375 3.125L4.375 16.875L4.37504 16.875H15.6249L15.625 16.8749V7.5H11.875C11.5298 7.5 11.25 7.22018 11.25 6.875V3.125ZM12.5 4.00888L14.7411 6.25H12.5V4.00888ZM7.5 11.875C7.5 11.5298 7.77982 11.25 8.125 11.25H11.875C12.2202 11.25 12.5 11.5298 12.5 11.875C12.5 12.2202 12.2202 12.5 11.875 12.5H8.125C7.77982 12.5 7.5 12.2202 7.5 11.875Z"
        fill={color}
      />
    </svg>
  );
};
