import { Cms } from '@typings';

import { compiledPaths } from '../../../../paths';
import { Link } from '../../../various/Link';
import { PreviouslyTableCell } from '../PreviouslyTableCell/PreviouslyTableCell';

interface Props {
  text: string;
  row: Cms.Page;
}

export const TitleCell = ({ text, row }: Props) => (
  <>
    <Link variant="blue" to={compiledPaths.CMS_EDITOR({ pageId: row.id })}>
      {text}
    </Link>
    <PreviouslyTableCell property="name" page={row} />
  </>
);
