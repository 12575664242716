import { Id } from '@typings';
import { useSelector } from 'react-redux';

import {
  getCurrentOrDefaultProductSortingValue,
  getIsProductSortingEnabled,
  getProductFilters,
  getProductSortingDefaultValue,
} from '../../ducks';
import { encodeSearchString, getOnlyNeccessaryFilters } from '../../logic/filters';
import { compiledPaths } from '../../paths';
import { isEmpty } from '../isEmpty';

export const useAllProductsLink = (selectionId?: Id) => {
  const isProductSortingEnabled = useSelector(getIsProductSortingEnabled);
  const sortingValue = useSelector(getCurrentOrDefaultProductSortingValue);
  const defaultSortingValue = useSelector(getProductSortingDefaultValue);
  const productFilters = useSelector(getProductFilters);

  const baseQuery = {
    ...(isProductSortingEnabled &&
      !isEmpty(sortingValue) &&
      sortingValue !== defaultSortingValue && {
        sort: sortingValue,
      }),
  };

  const allProductsLinkParams = encodeSearchString(baseQuery, getOnlyNeccessaryFilters(productFilters));

  return `${compiledPaths.PRODUCTS_ORDER({ id: selectionId })}?${allProductsLinkParams}`;
};
