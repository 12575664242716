import { ActionHandler, Media, ShowroomApiResponse, ShowroomConfiguration } from '@typings';
import { createReducer } from 'typesafe-actions';

import { DEFAULT_PRODUCTS_PER_REQUEST } from '../../constants/limits';
import { update as updated } from '../../utils/update';

import * as actions from './actions';

export interface ConfigReducer {
  data: Omit<ShowroomConfiguration.FullConfiguration, keyof ShowroomApiResponse> & {
    buyerMinimumPasswordLength: number;
    defaultImageSize: Media.Size;
  };
  isConfigLoaded: boolean;
  error: string | undefined;
}

const initialState: ConfigReducer = {
  data: {
    accountEditing: true,
    allProductsViewLayout: 'grid',
    backgroundImage: '',
    backordersEnabled: false,
    blockCheckoutOnUnpaid: false,
    buyerCheckout: false,
    buyerEditing: true,
    buyerMinimumPasswordLength: 4,
    checkoutFields: {},
    checkoutFieldsForSharedLinks: {},
    companyName: '',
    customFonts: [],
    defaultImageSize: 'standard',
    deliveryWindows: true,
    filterFields: [],
    filterOnAvailable: false,
    gaTrackingId: null,
    hotjarSiteId: null,
    imageSizes: [],
    loginPageMessage: '',
    logoImageDark: '',
    lookbookEnabled: false,
    productAttributes: {
      details: [],
      listing: [],
    },
    productSorting: {
      default: 'default',
      fields: [],
    },
    productsPerRequest: DEFAULT_PRODUCTS_PER_REQUEST,
    sharedLinksEnabled: true,
    showBarcodeScanner: false,
    soldOutLabel: '',
    stockNumbersMaximum: 0,
    stockNumbersVisible: true,
    unpaidInvoicesNotificationVisibility: 'always',
    version: {
      major: 0,
      minor: 0,
      patch: 0,
    },
  },
  error: undefined,
  isConfigLoaded: false,
};

const handleFetchConfigurationSuccess: ActionHandler<ConfigReducer, typeof actions.fetchConfigurationSuccess> = (state, action) => {
  const {
    imageSizes: originalImageSizes,
    filterFields: originalFilterFields,
    productsPerRequest: originalProductsPerRequest,
    debug,
    token,
    ...rest
  } = action.payload;

  /**
   * If Elasticsearch is disabled, don't try to filter by attributes.
   */
  const filterFields = rest.filterOnAvailable ? originalFilterFields : [];

  const defaultImageSize = originalImageSizes.length > 0 ? originalImageSizes[0].imageSize : initialState.data.defaultImageSize;

  const productsPerRequest = originalProductsPerRequest > 0 ? originalProductsPerRequest : initialState.data.productsPerRequest;

  return {
    data: {
      ...state.data,
      ...rest,
      defaultImageSize,
      filterFields,
      imageSizes: originalImageSizes,
      productsPerRequest,
    },
    error: undefined,
    isConfigLoaded: true,
  };
};

const handleInvalidConfiguration: ActionHandler<ConfigReducer, typeof actions.invalidConfiguration> = (state, action) =>
  updated(state, {
    error: action.payload.message,
  });

export default createReducer<ConfigReducer>(initialState)
  .handleAction(actions.fetchConfigurationSuccess, handleFetchConfigurationSuccess)
  .handleAction(actions.invalidConfiguration, handleInvalidConfiguration);
