import { Addresses } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useShippingCountries } from '../../../../../services/hooks/account/useShippingCountries';

import styles from './ShippingAddressRow.module.scss';

interface Props {
  address: Addresses.Shipping;
}

export const ShippingAddressRow = ({ address }: Props) => {
  const { t } = useTranslation(['common', 'shipping']);
  const { data: shippingCountries } = useShippingCountries();

  const fields = [
    {
      label: t('common:company_name'),
      name: 'company',
      value: address.company,
    },
    {
      label: t('common:contact_person'),
      name: 'contactPerson',
      value: address.contactPerson,
    },
    {
      label: t('common:email_address'),
      name: 'email',
      value: address.email,
    },
    {
      label: t('common:phone_number'),
      name: 'phoneNumber',
      value: address.phoneNumber,
    },
    {
      label: t('common:address'),
      name: 'address1',
      value: address.address1,
    },
    {
      label: t('common:address_continued'),
      name: 'address2',
      value: address.address2,
    },
    {
      label: t('common:city'),
      name: 'city',
      value: address.city,
    },
    {
      label: t('common:zip_code'),
      name: 'zipCode',
      value: address.zipCode,
    },
    {
      label: t('common:country'),
      name: 'countryName',
      value: address.countryName,
    },
    {
      label: t('common:state'),
      name: 'state',
      value: address.stateName ?? shippingCountries?.countries[address.country]?.states?.[address.state]?.name,
    },
  ] as const;

  return (
    <dl className={styles.addressDetails}>
      {fields.map(({ name, value, label }) => {
        return (
          <React.Fragment key={name}>
            <dt className={styles.label}>{label}:</dt>
            <dd className={styles.value} data-sentry-mask>
              {value}
            </dd>
          </React.Fragment>
        );
      })}
    </dl>
  );
};
