import { ActionHandler, Cms, Id } from '@typings';
import { nanoid } from 'nanoid';
import { assocPath, move, omit, pathOr, pick } from 'ramda';
import { createReducer } from 'typesafe-actions';

import { getTextPartsOrderForBlock } from '../../../logic/cms/getTextPartsOrderForBlock';
import { applyCustomStyleOnPart, isButtonPart } from '../../../logic/cms/styles';
import { getContentBlockPart, getIsContentBlock } from '../../../logic/pages';
import { isDefined } from '../../../utils/is';
import { listToRecord } from '../../../utils/normalize';
import { update } from '../../../utils/update';
import { fetchEditorPageDataSuccess, refreshPageDataSuccess } from '../../pages';
import { applyButtonStyle, applyEditedCustomStyle, applyTextStyle } from '../customStyles';
import { resetCms, setPreviewData } from '../general';
import { automaticGroupChangeSuccess, groupRemoved, manualLayoutEditingSuccess, pasteGroup } from '../groups/actions';
import { groupCreated, pasteSection, sectionRemoved } from '../sections';

import {
  moveContentPart,
  removeContentParts,
  replaceCustomStyles,
  setButtonPart,
  setTextPart,
  updateBlockSettings,
  updateMultipleBlocksSettings,
} from './actions';

type State = Record<Id, Cms.AnyBlock>;

const initialState: State = {};

const PATH_TO_PARTS_ORDER = ['settings', 'text', 'partsOrder'];
const PATH_TO_PARTS = ['settings', 'text', 'parts'];
const PATH_TO_TEXT = ['settings', 'text'];

const handleResetBlocks: ActionHandler<State, typeof resetCms> = () => initialState;

const handleFetchEditorPageSuccess: ActionHandler<State, typeof fetchEditorPageDataSuccess> = (_, action) => action.payload.data.blocks;
const handleSetPreviewData: ActionHandler<State, typeof setPreviewData> = (_, action) => action.payload.data.blocks;

const handleGroupCreated: ActionHandler<State, typeof groupCreated> = (state, action) => {
  const { blocksGroup } = action.payload;

  return { ...state, ...listToRecord(blocksGroup, 'id') };
};

const handleGroupChangeSuccess: ActionHandler<State, typeof automaticGroupChangeSuccess | typeof manualLayoutEditingSuccess> = (
  state,
  action,
) => {
  const { blocks, groupId } = action.payload;

  const blocksNotInGroup = Object.values(state)
    .filter(block => block.groupId !== groupId)
    .reduce(
      (acc, item) => ({
        ...acc,
        [item.id]: item,
      }),
      {},
    );

  return {
    ...blocksNotInGroup,
    ...listToRecord(blocks, 'id'),
  };
};

const handleUpdateBlockSetting: ActionHandler<State, typeof updateBlockSettings> = (state, action) => {
  const { blockId, updatePath, value } = action.payload;
  const block = state[blockId];

  return update(state, {
    [blockId]: assocPath(['settings', ...updatePath], value, block),
  });
};

const handleUpdateMultipleBlocksSettings: ActionHandler<State, typeof updateMultipleBlocksSettings> = (state, action) => {
  const blocks = action.payload;

  const updatedBlocks = blocks.reduce<Record<Id, Cms.AnyBlock>>((acc, current) => {
    const block = state[current.blockId];

    if (!isDefined(block)) {
      return acc;
    }

    return {
      ...acc,
      [current.blockId]: assocPath(['settings', ...current.updatePath], current.value, block),
    };
  }, {});

  return { ...state, ...updatedBlocks };
};

const handleGroupRemoved: ActionHandler<State, typeof groupRemoved> = (state, action) => omit(action.payload.blockIds, state);
const handleSectionRemoved: ActionHandler<State, typeof sectionRemoved> = (state, action) => omit(action.payload.blockIds, state);
const handlePasteGroupOrSection: ActionHandler<State, typeof pasteGroup | typeof pasteSection> = (state, action) => ({
  ...state,
  ...action.payload.blocks,
});
const handleRefreshPageDataSuccess: ActionHandler<State, typeof refreshPageDataSuccess> = (_, action) => action.payload.blocks;

const handleSetContentPart: ActionHandler<State, typeof setTextPart | typeof setButtonPart> = (state, action) => {
  const { blockId, value } = action.payload;
  const block = state[blockId];

  const partId = nanoid();
  const text = pathOr({}, PATH_TO_TEXT, block);
  const parts = pathOr({}, PATH_TO_PARTS, block);
  const partsOrder = pathOr([], PATH_TO_PARTS_ORDER, block);

  const updatedText = {
    ...text,
    parts: { ...parts, [partId]: value },
    partsOrder: [...partsOrder, partId],
  };

  return update(state, {
    [blockId]: assocPath(PATH_TO_TEXT, updatedText, block),
  });
};

const handleRemoveContentParts: ActionHandler<State, typeof removeContentParts> = (state, action) => {
  const { blockId, partIds } = action.payload;
  const block = state[blockId];
  const { parts, partsOrder, ...rest } = pathOr({ parts: {}, partsOrder: [] }, PATH_TO_TEXT, block);
  const removedPartsOrder = partsOrder.filter(partId => !partIds.includes(partId));
  const updatedText =
    partsOrder.length <= 1 ? undefined : { ...rest, parts: pick(removedPartsOrder, parts), partsOrder: removedPartsOrder };

  return update(state, {
    [blockId]: assocPath(PATH_TO_TEXT, updatedText, block),
  });
};

const handleMoveContentPart: ActionHandler<State, typeof moveContentPart> = (state, action) => {
  const { blockId, index, offset } = action.payload;

  const block = state[blockId];
  const parts = pathOr([], PATH_TO_PARTS_ORDER, block);

  return update(state, {
    [blockId]: assocPath(PATH_TO_PARTS_ORDER, move(index, index + offset, parts), block),
  });
};

const applyCustomStyle = (state: State, block: Cms.AnyBlock, partIndex: number, customStyle: Cms.ContentPartStyle) => {
  const part = getContentBlockPart(block, partIndex);
  const partId = getTextPartsOrderForBlock(block)[partIndex];

  if (!isDefined(part) || !isDefined(partId)) {
    return state;
  }

  const value = applyCustomStyleOnPart('desktop')(part, customStyle);

  return update(state, {
    [block.id]: assocPath([...PATH_TO_PARTS, partId], value, block),
  });
};

const handleApplyTextStyle: ActionHandler<State, typeof applyTextStyle> = (state, action) => {
  const { block, partIndex, textStyle } = action.payload;

  return applyCustomStyle(state, block, partIndex, textStyle);
};

const handleApplyButtonStyle: ActionHandler<State, typeof applyButtonStyle> = (state, action) => {
  const { block, partIndex, buttonStyle } = action.payload;

  return applyCustomStyle(state, block, partIndex, buttonStyle);
};

const handleApplyEditedCustomStyle: ActionHandler<State, typeof applyEditedCustomStyle> = (state, action) => {
  const { className } = action.payload;

  const contentBlocks = Object.values(state).filter(getIsContentBlock);
  const updatedBlocks = contentBlocks.reduce<Record<Id, Cms.AnyBlock>>((acc, current) => {
    const parts = current.settings.text?.parts;

    if (!isDefined(parts)) {
      return { ...acc, [current.id]: current };
    }

    const updatedParts = Object.entries(parts).reduce((partsAcc, part) => {
      const [key, value] = part;

      const isCustomStyleApplied = isButtonPart(value) ? value.general.buttonStyle === className : value.general.textStyle === className;

      const updatedPart = isCustomStyleApplied ? applyCustomStyleOnPart('desktop')(value, action.payload) : value;

      return {
        ...partsAcc,
        [key]: updatedPart,
      };
    }, {});

    return {
      ...acc,
      [current.id]: assocPath(PATH_TO_PARTS, updatedParts, current),
    };
  }, {});

  return { ...state, ...updatedBlocks };
};

const handleReplaceCustomStyles: ActionHandler<State, typeof replaceCustomStyles> = (state, action) => {
  const replaceMap = action.payload;

  const updatedBlocks = Object.values(state)
    .filter(getIsContentBlock)
    .reduce<Record<Id, Cms.AnyBlock>>((acc, current) => {
      const parts = current.settings.text?.parts;

      if (!isDefined(parts)) {
        return { ...acc, [current.id]: current };
      }

      const updatedParts = Object.entries(parts).reduce((partsAcc, part) => {
        const [key, value] = part;
        const style = isButtonPart(value) ? value.general.buttonStyle : value.general.textStyle;

        const mappedPartStyle = replaceMap[style];

        const updatedPart = isDefined(mappedPartStyle) ? applyCustomStyleOnPart('desktop')(value, mappedPartStyle) : value;

        return {
          ...partsAcc,
          [key]: updatedPart,
        };
      }, {});

      return {
        ...acc,
        [current.id]: assocPath(PATH_TO_PARTS, updatedParts, current),
      };
    }, {});

  return { ...state, ...updatedBlocks };
};

export default createReducer<State, AppAction>(initialState)
  .handleAction(fetchEditorPageDataSuccess, handleFetchEditorPageSuccess)
  .handleAction(setPreviewData, handleSetPreviewData)
  .handleAction(groupCreated, handleGroupCreated)
  .handleAction(automaticGroupChangeSuccess, handleGroupChangeSuccess)
  .handleAction(manualLayoutEditingSuccess, handleGroupChangeSuccess)
  .handleAction(updateBlockSettings, handleUpdateBlockSetting)
  .handleAction(updateMultipleBlocksSettings, handleUpdateMultipleBlocksSettings)
  .handleAction(groupRemoved, handleGroupRemoved)
  .handleAction(sectionRemoved, handleSectionRemoved)
  .handleAction(refreshPageDataSuccess, handleRefreshPageDataSuccess)
  .handleAction(setButtonPart, handleSetContentPart)
  .handleAction(setTextPart, handleSetContentPart)
  .handleAction(removeContentParts, handleRemoveContentParts)
  .handleAction(applyTextStyle, handleApplyTextStyle)
  .handleAction(applyButtonStyle, handleApplyButtonStyle)
  .handleAction(applyEditedCustomStyle, handleApplyEditedCustomStyle)
  .handleAction(moveContentPart, handleMoveContentPart)
  .handleAction(replaceCustomStyles, handleReplaceCustomStyles)
  .handleAction(resetCms, handleResetBlocks)
  .handleAction(pasteGroup, handlePasteGroupOrSection)
  .handleAction(pasteSection, handlePasteGroupOrSection);
