import { Overview } from '@typings';
import { isEmpty } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  getIsSoloCategory,
  getIsTopLevelRow,
  getSegmentWidth,
  getSVGHelpers,
  truncateTextByOneChar,
} from '../../../../logic/selectionOverview';
import { isDefined } from '../../../../utils/is';

const TEXT_MARGIN_BASE = 10;
const MIN_SIZE_TO_SHOW_PERCENTAGE = 44;

const MAX_WIDTH_THIN_SEGMENT = 70;
const MARGIN_THIN_SEGMENT = 30;

interface Props {
  node: Overview.PieChartNode;
  textScale: number;
  getPercentage: (node: Overview.PieChartNode) => string;
}

const MaybeDimensionText = ({ dimension, truncatedText }: { dimension: Maybe<string>; truncatedText: Nullable<string> }) => {
  if (!isDefined(dimension) || isEmpty(dimension)) {
    return <tspan fontStyle="italic">{truncatedText}</tspan>;
  }

  return <>{truncatedText}</>;
};

export const Text = ({ node, textScale, getPercentage }: Props) => {
  const { t } = useTranslation(['common']);
  const { primaryDimension, secondaryDimension } = node.data;
  const textMargin = TEXT_MARGIN_BASE * textScale;
  const [textToShow, setTextToShow] = React.useState<React.ReactNode>('');
  const percent = getPercentage(node);
  const segmentWidth = getSegmentWidth(node);

  const maxLength = React.useMemo(() => {
    const segmentHeight = node.y1 - node.y0;
    const isTopLevelRow = getIsTopLevelRow(node.data);

    if (isTopLevelRow && isDefined(node.parent)) {
      const parentSegmentHeight = node.parent.y1 - node.parent.y0;
      const additionalMargin = segmentWidth < MAX_WIDTH_THIN_SEGMENT ? MARGIN_THIN_SEGMENT : 0;

      return (segmentHeight + parentSegmentHeight - 2 * textMargin - additionalMargin) / textScale;
    }

    return Math.floor((segmentHeight - textMargin) / textScale);
  }, [node, textMargin, textScale, segmentWidth]);

  /* eslint-disable functional/immutable-data */
  const getUpdatedTextData = React.useCallback(() => {
    setTextToShow('');
    const noneSpanText = t('common:none');
    const { removeSVGHelpers, primaryTextSpan, percentageTextSpan, divider, secondaryTextSpan, appendSVGHelpers } =
      getSVGHelpers(textScale);
    appendSVGHelpers();

    percentageTextSpan.textContent = ` ${percent}`;
    primaryTextSpan.textContent = isEmpty(primaryDimension) || !isDefined(primaryDimension) ? noneSpanText : primaryDimension;
    divider.textContent = ', ';

    if (isDefined(secondaryDimension)) {
      secondaryTextSpan.textContent = isEmpty(secondaryDimension) ? noneSpanText : secondaryDimension;
    }

    const getTextLength = () => {
      if (isDefined(secondaryDimension)) {
        return primaryTextSpan.getBBox().width + secondaryTextSpan.getBBox().width + divider.getBBox().width;
      }

      return primaryTextSpan.getBBox().width;
    };

    while (getTextLength() > maxLength) {
      if (isEmpty(secondaryTextSpan.textContent) || !isDefined(secondaryTextSpan.textContent)) {
        primaryTextSpan.textContent = truncateTextByOneChar(primaryTextSpan.textContent);
        continue;
      }

      secondaryTextSpan.textContent = truncateTextByOneChar(secondaryTextSpan.textContent);
    }

    const nextTo = getTextLength() + percentageTextSpan.getBBox().width < maxLength;
    const below = segmentWidth > MIN_SIZE_TO_SHOW_PERCENTAGE * textScale;
    const primaryText = primaryTextSpan.textContent;
    const secondaryText = secondaryTextSpan.textContent;
    removeSVGHelpers();

    return {
      percentage: {
        below,
        nextTo,
      },
      primaryText,
      secondaryText,
    };
  }, [t, textScale, percent, primaryDimension, secondaryDimension, maxLength, segmentWidth]);
  /* eslint-enable functional/immutable-data */

  const textWithPercentage = React.useMemo(() => {
    const { primaryText, secondaryText, percentage } = getUpdatedTextData();
    const { below, nextTo } = percentage;

    return (
      <>
        <tspan x={0}>
          <MaybeDimensionText dimension={primaryDimension} truncatedText={primaryText} />
          {isDefined(secondaryDimension) && !isEmpty(secondaryText) && (
            <>
              , <MaybeDimensionText dimension={secondaryDimension} truncatedText={secondaryText} />
            </>
          )}
          {nextTo && ` ${percent}`}
        </tspan>
        {below && !nextTo && (
          <tspan x={0} dy="1.1em">
            {percent}
          </tspan>
        )}
      </>
    );
  }, [getUpdatedTextData, percent, primaryDimension, secondaryDimension]);

  React.useLayoutEffect(() => {
    setTextToShow(textWithPercentage);
  }, [maxLength, textWithPercentage]);

  const getTextTransform = React.useCallback(() => {
    const x = (((node.x0 + node.x1) / 2) * 180) / Math.PI;
    const rotateAngle = x - 90;

    const isTopLevelRow = getIsTopLevelRow(node.data);
    const isSoloCategory = getIsSoloCategory(node);
    const isFlipped = x > 180;

    if (isSoloCategory) {
      const translateY = isTopLevelRow ? 0 : (node.y0 + node.y1) / 2 - textMargin / 2;

      return {
        anchor: 'middle',
        dy: 0,
        transform: `rotate(${rotateAngle}) translate(${translateY}) scale(${textScale}) rotate(${-rotateAngle})`,
      };
    }

    const translateY = node.y1 - textMargin;
    const rotation = isFlipped ? 180 : 0;

    return {
      anchor: isFlipped ? 'start' : 'end',
      dy: isFlipped ? '0.35em' : '-0.35em',
      transform: `rotate(${rotateAngle}) translate(${translateY}) scale(${textScale}) rotate(${rotation})`,
    };
  }, [node, textMargin, textScale]);

  const { transform, anchor, dy } = getTextTransform();

  return (
    <text transform={transform} textAnchor={anchor} dy={dy}>
      {textToShow}
    </text>
  );
};
