import { useMatrixLayout } from '../../../../utils/hooks/matrix/useMatrixLayout';
import { RegularHeaderCell } from '../MatrixCells/RegularHeaderCell';

import styles from './MatrixHead.module.scss';

export const TwoDimensionalHeadCell = () => {
  const { isTwoDimensional } = useMatrixLayout();

  if (isTwoDimensional) {
    return <RegularHeaderCell className={styles.headTwoDimensionalCell} />;
  }

  return null;
};
