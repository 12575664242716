import { IconType } from '../../../../various/Icon';
import { IconButton } from '../../../../various/IconButton/IconButton';
import toolbarStyles from '../../Toolbar.module.scss';

interface Props {
  action: () => void;
  name: string;
}

export const Reset = ({ action, name }: Props) => (
  <div className={toolbarStyles.buttonSet}>
    <IconButton
      icon={IconType.Reset}
      name={name}
      onClick={action}
      variant="secondary-light"
      className={toolbarStyles.iconButton}
      showTooltip
    />
  </div>
);
