import { Variants } from '@typings';
import React from 'react';
import { useSelector } from 'react-redux';

import { getConfiguration } from '../../ducks';
import { formatQuantity } from '../../logic/formatQuantity';
import { isDefined } from '../is';

export const useStockFormatter = () => {
  const { stockNumbersMaximum, stockNumbersVisible } = useSelector(getConfiguration);

  return React.useCallback(
    (stock: Variants.Stock | undefined) => {
      if (!isDefined(stock)) {
        return '0';
      }

      return formatQuantity(stock, stockNumbersMaximum, stockNumbersVisible);
    },
    [stockNumbersMaximum, stockNumbersVisible],
  );
};
