import type { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import cx from 'classnames';

import Icon, { IconType } from '../../../../Icon';

import styles from './Handle.module.scss';

interface Props {
  id: UniqueIdentifier;
  isDisabled?: boolean;
}

export const Handle = ({ id, isDisabled }: Props) => {
  const { attributes, listeners, setActivatorNodeRef } = useSortable({ id });

  return (
    <span
      ref={setActivatorNodeRef}
      className={cx(styles.handle, {
        [styles.disabled]: isDisabled,
      })}
      {...attributes}
      {...listeners}
      tabIndex={-1}
    >
      <Icon type={IconType.Reorder} />
    </span>
  );
};
