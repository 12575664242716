import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrentSectionId, getGroupsOrderBySectionId, reorderGroups } from '../../../ducks';
import { useModalVisibility } from '../../../utils/hooks';
import { isDefined } from '../../../utils/is';
import { FormButtons } from '../../various/Form';
import { ModalSize } from '../../various/ModalElements';
import { ModalShell } from '../../various/ModalShell';
import { Button } from '../../various/NewButton';
import { Sortable } from '../../various/Sortable';

import { GroupPreview } from './GroupPreview';
import styles from './ReorderContentModal.module.scss';

export const ReorderContentModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'cms']);
  const { isModalVisible, hideModal } = useModalVisibility('ReorderContentModal');
  const sectionId = useSelector(getCurrentSectionId);
  const initialGroupsOrder = useSelector(getGroupsOrderBySectionId(sectionId));

  const [groupsOrder, setGroupsOrder] = React.useState<string[]>([]);

  React.useEffect(() => {
    setGroupsOrder(initialGroupsOrder);
  }, [initialGroupsOrder]);

  const handleSortEnd = React.useCallback((order: string[]) => {
    setGroupsOrder(order);
  }, []);

  const applyChanges = React.useCallback(() => {
    if (isDefined(sectionId)) {
      dispatch(reorderGroups({ groupsOrder, sectionId }));
      hideModal();
    }
  }, [dispatch, groupsOrder, hideModal, sectionId]);

  const resetGroupsOrder = React.useCallback(() => {
    setGroupsOrder(initialGroupsOrder);
  }, [initialGroupsOrder]);

  const renderGroup = React.useCallback((id: string) => <GroupPreview key={id} id={id} />, []);

  return (
    <ModalShell
      title={t('cms:reorder_section_content')}
      size={ModalSize.Medium}
      isOpen={isModalVisible}
      onClose={hideModal}
      modalClosedCallback={resetGroupsOrder}
    >
      <div className={styles.wrapper}>
        <div className={styles.title}>{t('cms:section_content')}</div>
        <Sortable items={groupsOrder} renderItem={renderGroup} handleSortEnd={handleSortEnd} />
      </div>
      <FormButtons>
        <Button size="large" variant="ghost" color="dark" onClick={hideModal}>
          {t('common:cancel')}
        </Button>
        <Button size="large" onClick={applyChanges}>
          {t('common:save')}
        </Button>
      </FormButtons>
    </ModalShell>
  );
};
