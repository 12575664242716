import cx from 'classnames';
import React from 'react';

import { isEmpty } from '../../../utils/isEmpty';
import { Link } from '../Link';
import { TextEllipsis } from '../TextEllipsis';

import styles from './Card.module.scss';

interface WrapperProps extends React.WithOptionalChildren {
  className?: string;
  dataTestId?: string;
}

interface DescriptionListProps extends WrapperProps {}

interface CardItemProps extends WrapperProps {
  name: string | JSX.Element;
}

interface DescriptionProps extends CardItemProps {
  value: React.ReactNode | React.ReactNode[];
}

interface AnchorProps extends CardItemProps {
  href: string;
  linkName: string;
}

interface GroupProps extends WrapperProps {
  wrapperRef?: React.RefObject<HTMLDivElement>;
}

const toLines = (line: string) => {
  if (isEmpty(line.trim())) {
    return null;
  }

  return (
    <div className={styles.line} key={line}>
      {line}
    </div>
  );
};

const Wrapper = ({ className, children }: WrapperProps) => <div className={cx(styles.card, className)}>{children}</div>;

const Group = ({ children, className, wrapperRef }: GroupProps) => (
  <div ref={wrapperRef} className={cx(styles.group, className)}>
    {children}
  </div>
);

const Title = ({ children }: React.WithChildren) => <div className={styles.title}>{children}</div>;

const DescriptionList = ({ children, className }: DescriptionListProps) => <dl className={cx(styles.wrapper, className)}>{children}</dl>;

const CardItem = ({ name, className, children, dataTestId }: CardItemProps) => (
  <div data-testid={dataTestId} className={cx(styles.cardItem, className)}>
    <TextEllipsis as="dt" className={styles.name}>
      {name}:
    </TextEllipsis>
    <dd className={styles.value} data-sentry-mask>
      {children}
    </dd>
  </div>
);

const Description = ({ name, value, className, dataTestId }: DescriptionProps) => (
  <CardItem dataTestId={dataTestId} name={name} className={className}>
    {Array.isArray(value) ? value.map(toLines) : value}
  </CardItem>
);

const Anchor = ({ href, name, className, linkName, dataTestId }: AnchorProps) => (
  <CardItem dataTestId={dataTestId} name={name} className={className}>
    <Link variant="blue" external target="_blank" to={href}>
      {linkName}
    </Link>
  </CardItem>
);

export const Card = {
  Anchor,
  Description,
  DescriptionList,
  Group,
  Title,
  Wrapper,
  styles,
};
