export const omit = <T extends object, K extends keyof T>(source: T, properties: K[]): Omit<T, K> =>
  (Object.keys(source) as K[]).reduce<Omit<T, K>>(
    (cumulus, property) =>
      properties.includes(property) ? cumulus
      : (
        // prettier-ignore
        // eslint-disable-next-line functional/immutable-data
        Object.assign(cumulus, {
          [property]: source[property],
        })
      ),
    Object.create({}),
  );
