import { createColumnHelper, getCoreRowModel, getSortedRowModel } from '@tanstack/react-table';
import { Translations } from '@typings';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useTranslations } from '../../../services/hooks/translations/useTranslations';
import { useTranslatableFields, useTranslationsConfig } from '../../../utils/hooks';
import { useTable } from '../../../utils/hooks/table/useTable';
import { isDefined } from '../../../utils/is';
import { renderStandardCell } from '../../../utils/table';
import { Input } from '../../various/Fields/Input';
import { HelpTooltip } from '../../various/HelpTooltip';
import { Table } from '../../various/Table';

import styles from './TranslationsContent.module.scss';

interface Props {
  isLoading: boolean;
}

interface TableRow extends Partial<Translations.AllLanguagesTranslations> {
  field: Translations.PageContentTranslatableField;
  fieldName: string;
  description?: string;
}

const columnHelper = createColumnHelper<TableRow>();

export const PageContentTable = ({ isLoading }: Props) => {
  const { t } = useTranslation(['common', 'translations']);

  const { data: translations } = useTranslations();

  const translatableFields = useTranslatableFields();
  const { additionalLanguages } = useTranslationsConfig();
  const { register } = useFormContext<Translations.TranslationsDTOAdditional>();

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('fieldName', {
        cell: info => {
          return (
            <span className={styles.spacedField}>
              {info.getValue()}
              {isDefined(info.row.original.description) && <HelpTooltip content={info.row.original.description} />}
            </span>
          );
        },
        header: t('common:field'),
        meta: {
          width: 210,
        },
      }),
      columnHelper.accessor('en', {
        cell: record => <span className={styles.field}>{renderStandardCell(record)}</span>,
        enableSorting: false,
        header: t('common:default'),
        meta: {
          width: 235,
        },
      }),
      ...additionalLanguages.map(({ code, label }) =>
        columnHelper.accessor(code, {
          cell: info => {
            const row = info.row.original;
            const { field, fieldName, en } = row;

            return (
              <label aria-label={t('translations:translation_for', { code, label: fieldName })}>
                <Input {...register(`labels.${field}.${code}`)} size="large" placeholder={en} />
              </label>
            );
          },
          enableSorting: false,
          header: label,
          meta: {
            skeletonType: 'input',
            width: 235,
          },
        }),
      ),
    ],
    [t, additionalLanguages, register],
  );

  const data: TableRow[] = React.useMemo(
    () =>
      translatableFields.map(field => {
        const fieldTranslations = translations?.labels[field.field];

        return {
          ...field,
          ...fieldTranslations,
        };
      }),
    [translatableFields, translations],
  );

  const table = useTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <>
      <h3 className={styles.header}>{t('translations:page_content')}</h3>
      <Table className={styles.table} table={table} fixedLayout stickyColumn isLoading={isLoading} skeletonRows={2} />
    </>
  );
};
