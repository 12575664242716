import { Settings } from '@typings';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FilePickerField, FormFieldset, FormGrid, InputField } from '../../various/Form';
import { RadioGroupField } from '../../various/Form/FormField/RadioGroupField';
import { IconType } from '../../various/Icon';

interface Props {
  isDisabled: boolean;
  isLoading: boolean;
}

export const BrandElementsFields = ({ isDisabled, isLoading }: Props) => {
  const { t } = useTranslation(['common', 'settings']);
  const { register } = useFormContext<Settings.CustomisationFormFields>();

  const allProductsViewLayoutOptions = [
    {
      icon: IconType.GridView,
      name: t('common:grid_view'),
      value: 'grid',
    },
    {
      icon: IconType.ListView,
      name: t('common:list_view'),
      value: 'list',
    },
  ];

  return (
    <FormFieldset isDisabled={isDisabled}>
      <FormGrid rowGap="xlarge">
        <InputField
          label={t('common:company_name')}
          description={t('settings:company_name_description')}
          placeholder={t('common:company_name')}
          orientation="horizontal"
          isLoading={isLoading}
          {...register('companyName')}
        />
        <FilePickerField
          label={t('settings:company_logo')}
          description={t('settings:company_logo_hint')}
          orientation="horizontal"
          name="logoImage"
          isDisabled={isDisabled}
          isLoading={isLoading}
          fitPreview
          size="mini"
        />
        <FilePickerField
          label={t('settings:background_media')}
          description={t('settings:background_media_hint')}
          orientation="horizontal"
          name="backgroundImage"
          isDisabled={isDisabled}
          isLoading={isLoading}
          size="small"
        />
        <RadioGroupField
          label={t('settings:default_all_products_view')}
          description={t('settings:default_all_products_view_description')}
          name="allProductsViewLayout"
          options={allProductsViewLayoutOptions}
          orientation="horizontal"
          groupOrientation="horizontal"
        />
      </FormGrid>
    </FormFieldset>
  );
};
