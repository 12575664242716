/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;
const ORIGINAL_COLOR = '#848484';

export const Logout = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => {
  return (
    <svg width={width} height={width} viewBox="0 0 20 20">
      <path
        d="M5.35281 9.93417C5.35281 8.04702 5.56396 6.55486 5.6997 5.79415C6.6348 5.60397 8.26367 5.31139 10.2394 5.23824V3C7.223 3.11703 4.49313 3.74608 4.37248 3.77534C3.93509 3.87774 3.5882 4.19958 3.48263 4.60919C3.46755 4.69697 3 6.61337 3 9.93417C3 13.255 3.45246 15.2738 3.46755 15.3615C3.57312 15.7858 3.93509 16.1076 4.37248 16.21C4.49313 16.2393 7.223 16.8683 10.2394 17V14.7471C8.26367 14.674 6.64988 14.3814 5.6997 14.1912C5.56396 13.4013 5.35281 11.8652 5.35281 9.93417ZM12.185 14.2497C12.3057 14.2497 12.4113 14.2205 12.5018 14.162C14.7339 12.6991 16.8002 10.4462 16.8756 10.3438C17.0415 10.139 17.0415 9.86102 16.8756 9.67085C16.8002 9.56844 14.7339 7.30094 12.5018 5.85266C12.3358 5.73563 12.1096 5.73563 11.9286 5.82341C11.7476 5.92581 11.6873 6.10136 11.6873 6.30616V8.14943C10.0584 8.31034 7.76596 8.76384 7.4794 8.92476C7.11743 9.11494 6.86103 9.46604 6.86103 10.0073C6.86103 10.5486 7.11743 10.8851 7.4794 11.0899C7.76596 11.2508 10.0584 11.6897 11.6873 11.8652V13.7085C11.6873 13.8986 11.7476 14.0888 11.9286 14.1912C12.004 14.2205 12.0945 14.2497 12.185 14.2497Z"
        fill={color}
      />
    </svg>
  );
};
