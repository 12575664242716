import { Skeleton } from '../../../Skeleton';

import styles from './LinesheetTemplatesSkeleton.module.scss';

export const LinesheetTemplatesSkeleton = () => {
  return (
    <div className={styles.templates}>
      {Array.from({ length: 2 }).map((_, index) => (
        <Skeleton key={index} type="rectangle" className={styles.template} />
      ))}
    </div>
  );
};
