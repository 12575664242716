import { Item, Matrices } from '@typings';
import React from 'react';
import { useSelector } from 'react-redux';

import { getOptimisticUpdatesMultipleProducts, getOrderDetails } from '../../../../ducks';
import { getOrderedItems } from '../../../../logic/Orders';
import { getMultivariantRelatedProductIds, getVariantsSizes } from '../../../../logic/products';
import { useMatrixLayout } from '../../../../utils/hooks/matrix/useMatrixLayout';
import { isDefined } from '../../../../utils/is';
import { isEmpty } from '../../../../utils/isEmpty';

import { useMatrixDeliveryWindowContext } from './MatrixDeliveryWindowContext';
import { useMatrixDistributionContext } from './MatrixDistributionContext';
import { useMatrixSettingsContext } from './MatrixSettingsContext';
import { useMatrixVariantsContext } from './MatrixVariantsContext';

interface Context {
  cellQuantitiesMap: Matrices.CellQuantities[][];
}

export const useMatrixQuantitiesContext = (): Context => {
  const context = React.useContext(MatrixQuantitiesContext);

  if (!isDefined(context)) {
    throw new Error('MatrixQuantitiesContext can not be used outside the scope of MatrixQuantitiesContextProvider');
  }

  return context;
};

export const MatrixQuantitiesContext = React.createContext<Context | null>(null);

export const MatrixQuantitiesContextProvider = ({ children }: React.WithChildren) => {
  const { showCancelled } = useMatrixSettingsContext();
  const { distributionPreview, distributionResult, distributionVariant } = useMatrixDistributionContext();
  const { variants } = useMatrixVariantsContext();
  const { deliveryWindowId } = useMatrixDeliveryWindowContext();
  const { isTwoDimensional } = useMatrixLayout();
  const [firstVariant] = variants;

  const orderDetails = useSelector(getOrderDetails);
  const allProductIds = [firstVariant?.product, ...getMultivariantRelatedProductIds(firstVariant)].filter(isDefined);
  const optimisticUpdates = useSelector(getOptimisticUpdatesMultipleProducts(allProductIds));

  const orderedItems = React.useMemo(
    () => getOrderedItems(variants, orderDetails, deliveryWindowId, showCancelled),
    [variants, orderDetails, deliveryWindowId, showCancelled],
  );

  const allSizes = getVariantsSizes(variants);

  const cellItemIdMap = React.useMemo(() => {
    if (isTwoDimensional && isDefined(firstVariant)) {
      const { x, y, dividerSymbol } = firstVariant.itemTable;

      return y.map(ySize => {
        return x.flatMap(xSize => {
          const cellItem = firstVariant.items.find(item => item.name === `${xSize}${dividerSymbol}${ySize}`);

          return {
            item: cellItem,
            variantId: firstVariant.variant,
          };
        });
      });
    }

    return variants.map(variant =>
      allSizes.map(size => {
        return {
          item: variant.items.find(item => item.name === size),
          variantId: variant.variant,
        };
      }),
    );
  }, [variants, firstVariant, allSizes]);

  const getQuantityForItem = (item: Item | undefined, variantId: string) => {
    const itemKey = `${deliveryWindowId}-${item?.item}`;
    const itemsByVariant = Object.values(orderedItems[variantId] ?? {});
    const itemData = itemsByVariant.find(itemByVariant => itemByVariant.item === item?.item);

    const itemQuantity = itemsByVariant
      .filter(itemByVariant => itemByVariant.item === item?.item)
      .reduce((sum, { quantity }) => sum + quantity, 0);

    const optimisticQuantity = optimisticUpdates[itemKey];
    const itemId = item?.item ?? '';
    const doesDistributionMatchVariant = variantId === distributionVariant?.variant;
    const distributionQuantitySource = !isEmpty(distributionResult) ? distributionResult : distributionPreview;
    const distributionQuantity = distributionQuantitySource[itemId]?.quantity ?? 0;
    const distributionPreviewQuantity = distributionPreview[itemId]?.quantity ?? 0;

    const quantity = (optimisticQuantity ?? itemQuantity) + distributionQuantity;
    const distributionQuantityBefore =
      doesDistributionMatchVariant ? (optimisticQuantity ?? itemQuantity) + distributionPreviewQuantity : undefined;
    const quantityBefore = doesDistributionMatchVariant ? distributionQuantityBefore : itemData?.quantityBefore;

    return {
      ean: item?.ean,
      itemId: item?.item,
      quantity,
      quantityBefore,
      variantId,
    };
  };

  const cellQuantitiesMap = cellItemIdMap.map(row => row.map(({ item, variantId }) => getQuantityForItem(item, variantId)));

  return <MatrixQuantitiesContext.Provider value={{ cellQuantitiesMap }}>{children}</MatrixQuantitiesContext.Provider>;
};
