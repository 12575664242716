import { useQuery } from '@tanstack/react-query';

import { getUnits } from '../../unitsRepository';

export const useUnits = () => {
  return useQuery({
    queryFn: getUnits,
    queryKey: ['units'],
  });
};
