/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;

export const Search = ({ width = ORIGINAL_WIDTH, color = 'currentColor' }: IconComponentProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox="0 0 20 20" fill={color}>
      <path
        fillRule="evenodd"
        d="M9.21 4.355a4.855 4.855 0 1 0 0 9.71 4.855 4.855 0 0 0 0-9.71ZM3 9.21a6.21 6.21 0 1 1 11.053 3.885l2.749 2.749a.677.677 0 0 1-.958.958l-2.748-2.748A6.21 6.21 0 0 1 3 9.21Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
