import { Checkout, CheckoutForm, DeliveryWindow } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import universalStyles from '../../../../css/utilities/universal.module.scss';
import {
  checkoutOrderRequest,
  getBillingAddress,
  getIsPrepaymentEnabled,
  getIsPrepaymentRequired,
  getIsSeller,
  getOrderDetails,
  getPaymentTerm,
  pushEvent,
  requestOrderPayment,
  updateCheckoutForm,
} from '../../../ducks';
import { compiledPaths } from '../../../paths';
import { getNavigationButtonsClickedEvent } from '../../../utils/analytics/events';
import { createAbsoluteUrl } from '../../../utils/createAbsoluteUrl';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { CancelledProductsAlert } from '../../orders/CancelledProductsAlert';
import { ExpiredDelwinAlert } from '../../orders/ExpiredDelwinAlert';
import { CheckoutAdditional } from '../../orders/partials/CheckoutAdditional/CheckoutAdditional';
import { CheckoutPayments } from '../../orders/partials/CheckoutPayments';
import { CheckoutSubtotal } from '../../orders/partials/CheckoutSubtotal';
import { ShippingAddresses } from '../../orders/partials/shippingAddresses';
import { CheckoutTerms } from '../../orders/partials/Terms';
import { useSharedToken } from '../../SharedTokenProvider';
import { Alert } from '../../various/Alert';
import { Form } from '../../various/Form';
import { ReceiptAddress } from '../../various/ReceiptAddress';
import { addToast } from '../../various/Toasts';
import { Wrapper, WrapperSize } from '../../various/Wrapper';
import { CheckoutProductsReceipt } from '../checkoutProductsReceipt';
import { CheckoutSection } from '../CheckoutSection';
import { CheckoutSummary } from '../CheckoutSummary';
import { convertCheckoutFormData } from '../convertCheckoutFormData';
import { DelwinDates } from '../DelwinDates';
import { useCheckoutForm } from '../useCheckoutForm';

import styles from './CheckoutContent.module.scss';

interface Props {
  delwinsWithProducts: DeliveryWindow.Mixed[];
  isSavingOrLoading: boolean;
  checkoutFields: Checkout.Requirements;
}

export const CheckoutContent = ({ delwinsWithProducts, isSavingOrLoading, checkoutFields }: Props) => {
  const { t } = useTranslation(['invoices', 'payments', 'validation']);

  const orderDetails = useSelector(getOrderDetails);
  const dispatch = useDispatch();

  const invoiceAddress = useSelector(getBillingAddress);
  const isPaymentRequired = useSelector(getIsPrepaymentRequired);
  const paymentTerm = useSelector(getPaymentTerm);
  const prepaymentPercent = paymentTerm?.prepaymentPercent;
  const isPrepaymentEnabled = useSelector(getIsPrepaymentEnabled);
  const isSeller = useSelector(getIsSeller);

  const sharedToken = useSharedToken();

  const paymentMessage = isPaymentRequired && isDefined(prepaymentPercent) ? t('payments:required_payment', { prepaymentPercent }) : null;

  const cancelledProducts = React.useMemo(
    () => orderDetails.order.products.filter(product => product.isCancelled),
    [orderDetails.order.products],
  );
  const hasCancelledProducts = !isEmpty(cancelledProducts);

  const formMethods = useCheckoutForm({ checkoutFields, deliveryWindows: delwinsWithProducts });

  const { getValues } = formMethods;

  React.useEffect(() => {
    return () => {
      dispatch(updateCheckoutForm(getValues()));
    };
  }, [dispatch, getValues]);

  const handleCheckout = (data: CheckoutForm) => {
    const checkoutData = convertCheckoutFormData({ data, orderDetails });
    const orderId = checkoutData.order;

    if (isDefined(data.paymentMethod) && data.paymentMethod !== 'pay_later') {
      const searchParams = isDefined(sharedToken) ? { sharedToken } : undefined;
      const cancelUrl = createAbsoluteUrl(compiledPaths.ORDER_PAYMENT_FAILURE({ id: orderId }), searchParams);
      const successUrl = createAbsoluteUrl(compiledPaths.ORDER_PAYMENT_RESULT({ id: orderId }), searchParams);

      dispatch(
        requestOrderPayment({
          checkoutData,
          orderId,
          payment: {
            cancelUrl,
            paymentMethod: data.paymentMethod,
            successUrl,
          },
        }),
      );

      return;
    }

    dispatch(pushEvent(getNavigationButtonsClickedEvent('confirmOrder')));
    dispatch(checkoutOrderRequest(checkoutData));
  };

  const handleInvalidSubmit = () => {
    addToast(t('validation:required_fields_hint'));
  };

  return (
    <Wrapper size={WrapperSize.LARGE}>
      <div className={styles.alertsWrapper}>
        {hasCancelledProducts && <CancelledProductsAlert orderProducts={cancelledProducts} />}
        <ExpiredDelwinAlert order={orderDetails.order} />
        {isDefined(paymentMessage) && <Alert message={paymentMessage} type="info" />}
      </div>
      <CheckoutSummary />
      <Form id="checkoutForm" formMethods={formMethods} onSubmit={handleCheckout} onInvalidSubmit={handleInvalidSubmit}>
        <CheckoutSection>
          <CheckoutProductsReceipt order={orderDetails.order} products={orderDetails.products} isLoading={isSavingOrLoading} />
        </CheckoutSection>
        <CheckoutSection>
          <div className={universalStyles.twoColumnsGrid}>
            <CheckoutSubtotal />
            <div>
              <CheckoutAdditional isLoading={isSavingOrLoading} checkoutFields={checkoutFields} />
              <ReceiptAddress address={invoiceAddress} isLoading={isSavingOrLoading} title={t('invoices:invoice_address')} />
            </div>
          </div>
        </CheckoutSection>
        <CheckoutSection>
          <div className={universalStyles.twoColumnsGrid}>
            <DelwinDates isLoading={isSavingOrLoading} deliveryWindows={delwinsWithProducts} checkoutFields={checkoutFields} />
            {isDefined(checkoutFields.shippingAddressId) && (
              <ShippingAddresses shippingAddresses={orderDetails?.account?.shippingAddresses} isLoading={isSavingOrLoading} />
            )}
          </div>
        </CheckoutSection>

        {isPrepaymentEnabled && (
          <CheckoutSection>
            <div className={universalStyles.twoColumnsGrid}>
              <CheckoutPayments order={orderDetails.order} />
            </div>
          </CheckoutSection>
        )}

        {!isSeller && !isSavingOrLoading && (
          <CheckoutSection>
            <CheckoutTerms isDisabled={isSavingOrLoading} />
          </CheckoutSection>
        )}
      </Form>
    </Wrapper>
  );
};
