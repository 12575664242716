import { combineEpics, ofType } from 'redux-observable';
import { takeUntil } from 'rxjs/operators';

import { authEpic } from './authEpic';
import { cmsEpics } from './cms';
import { configEpic } from './configEpic';
import { countriesEpic } from './countriesEpic';
import { deliveryWindowEpic } from './deliveryWindowEpic';
import { documentsEpic } from './documentsEpic';
import { filtersEpic } from './filtersEpic';
import { marketsEpic } from './marketsEpic';
import { orderEpic } from './orderEpic';
import { ordersEpic } from './ordersEpic';
import { pagesEpic } from './pagesEpic';
import { paymentsEpic } from './paymentsEpic';
import { presetsEpic } from './presetsEpics';
import { pricelistsEpic } from './pricelistsEpic';
import { productDetailsEpic } from './productDetailsEpic';
import { productsEpic } from './productsEpic';
import { selectionEpic } from './selectionEpic';
import { selectionImportEpic } from './selectionImportEpic';
import { trackEpic } from './trackEpic';
import { uiEpic } from './uiEpic';
import { userEpic } from './userEpic';

const rootEpic: AppEpic = (action$, store$, dependencies) =>
  combineEpics(
    authEpic,
    cmsEpics,
    configEpic,
    countriesEpic,
    deliveryWindowEpic,
    documentsEpic,
    marketsEpic,
    orderEpic,
    ordersEpic,
    selectionEpic,
    selectionImportEpic,
    pagesEpic,
    paymentsEpic,
    presetsEpic,
    pricelistsEpic,
    productDetailsEpic,
    productsEpic,
    trackEpic,
    uiEpic,
    userEpic,
    filtersEpic,
  )(action$, store$, dependencies).pipe(takeUntil(action$.pipe(ofType('END'))));

export default rootEpic;
