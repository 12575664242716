import { Order } from '@typings';
import { useTranslation } from 'react-i18next';

import ProductsLogic from '../../../logic/products';
import { isDefined } from '../../../utils/is';
import { Wrapper, WrapperSize } from '../../various/Wrapper';

import styles from './DeletedProducts.module.scss';

interface Props {
  deletedProducts: Order.DeletedProduct[];
  allProducts: Order.Single['products'];
}

const DeletedProducts = ({ allProducts, deletedProducts }: Props): JSX.Element => {
  const { t } = useTranslation(['selections']);

  return (
    <div className={styles.deletedProducts}>
      <Wrapper size={WrapperSize.LARGE}>
        <h3>{t('selections:products_removed_from_selection')}:</h3>
        <ol>
          {deletedProducts.map(product => {
            const productId = product.product;
            const productData = Array.isArray(allProducts) ? null : allProducts[productId];

            if (!isDefined(productData)) {
              return null;
            }

            const variants = ProductsLogic.getAllVariantsInfoFromProductInfo(productData);
            const variant = variants[product.variant];
            const key = `${product.product}-${product.variant}-${product.deliveryWindow}`;

            return (
              <li key={key}>
                {productData.name} {variant && variant.variantName && `- ${variant.variantName}`}
              </li>
            );
          })}
        </ol>
      </Wrapper>
    </div>
  );
};

export default DeletedProducts;
