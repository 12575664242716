import { ActionHandler, Filter, Filters } from '@typings';
import { always, map, zipObj } from 'ramda';
import { createReducer } from 'typesafe-actions';

import { isDefined } from '../../utils/is';
import { isEmpty } from '../../utils/isEmpty';
import { update, update as updated } from '../../utils/update';

import * as actions from './actions';

export interface LookbookReducer {
  attributes?: Filter.Field<any>[];
  filters: Partial<Filters>;
  expandedFilters: {
    firstFilter: string | undefined;
    states: Record<string, boolean>;
  };
}

const initialState: LookbookReducer = {
  expandedFilters: {
    firstFilter: undefined,
    states: {},
  },
  filters: {
    categories: [],
  },
};

export { initialState as initialLookbookState };

const handleUpdateFilters: ActionHandler<LookbookReducer, typeof actions.updateLookbookFilters> = (state, action) => {
  return updated(state, {
    filters: { ...state.filters, ...action.payload },
  });
};

const handleResetLookbookFilters = (state: LookbookReducer): LookbookReducer =>
  updated(state, {
    filters: initialState.filters,
  });

const handleSetAttributes: ActionHandler<LookbookReducer, typeof actions.setLookbookAttributes> = (state, action) => {
  const fieldNames = action.payload.map(attribute => attribute.field);
  const [firstValidFilter] = fieldNames;
  const validCurrentFilters = map(always(false), fieldNames);
  const hasDifferentFirstFilters = firstValidFilter !== state.expandedFilters.firstFilter;

  return updated(state, {
    attributes: action.payload,
    expandedFilters:
      isDefined(firstValidFilter) && hasDifferentFirstFilters ?
        {
          firstFilter: firstValidFilter,
          states: {
            ...zipObj(fieldNames, validCurrentFilters),
            [firstValidFilter]: true,
          },
        }
      : state.expandedFilters,
  });
};

const handleSetExpandedFilters: ActionHandler<LookbookReducer, typeof actions.setExpandedLookbookFilters> = (state, action) => {
  if (isEmpty(action.payload)) {
    return update(state, {
      expandedFilters: initialState.expandedFilters,
    });
  }

  return update(state, {
    expandedFilters: update(state.expandedFilters, {
      states: action.payload,
    }),
  });
};

export default createReducer<LookbookReducer, AppAction>(initialState)
  .handleAction(actions.updateLookbookFilters, handleUpdateFilters)
  .handleAction(actions.resetLookbookFilters, handleResetLookbookFilters)
  .handleAction(actions.setLookbookAttributes, handleSetAttributes)
  .handleAction(actions.setExpandedLookbookFilters, handleSetExpandedFilters);
