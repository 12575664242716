import cx from 'classnames';
import React from 'react';

import styles from './ListItem.module.scss';

interface Props {
  className?: string;
  isOpen?: boolean;
}

export const ListItem = ({ children, className, isOpen = false }: React.WithChildren<Props>) => {
  return <div className={cx(styles.listItem, className, { [styles.open]: isOpen })}>{children}</div>;
};
