import cx from 'classnames';

import styles from './DefaultLoader.module.scss';

interface Props {
  versionSmall?: boolean;
  fullHeight?: boolean;
  color?: string;
  overlay?: boolean;
  className?: string;
}

export default function DefaultLoader({
  versionSmall = false,
  fullHeight = false,
  color = 'black',
  overlay = false,
  className,
}: Props): JSX.Element {
  const classes = cx(
    {
      [styles.loader]: true,
      [styles.small]: versionSmall,
      [styles.fullHeight]: fullHeight,
      [styles.overlay]: overlay,
    },
    className,
  );

  const colorStyle = { backgroundColor: color };

  return (
    <div className={classes} data-testid="loader">
      <div className={styles.cubeGrid}>
        <div className={`${styles.cube} ${styles.cube1}`} style={colorStyle} />
        <div className={`${styles.cube} ${styles.cube2}`} style={colorStyle} />
        <div className={`${styles.cube} ${styles.cube3}`} style={colorStyle} />
        <div className={`${styles.cube} ${styles.cube4}`} style={colorStyle} />
        <div className={`${styles.cube} ${styles.cube5}`} style={colorStyle} />
        <div className={`${styles.cube} ${styles.cube6}`} style={colorStyle} />
        <div className={`${styles.cube} ${styles.cube7}`} style={colorStyle} />
        <div className={`${styles.cube} ${styles.cube8}`} style={colorStyle} />
        <div className={`${styles.cube} ${styles.cube9}`} style={colorStyle} />
      </div>
    </div>
  );
}
