import { Toast } from '@typings';
import cx from 'classnames';
import React from 'react';

import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import Icon, { IconColor, IconType } from '../Icon';

import { dismissToast } from './toast';
import { ToastActionButton } from './ToastActionButton';
import { ToastActionLink } from './ToastActionLink';
import styles from './Toaster.module.scss';

interface Props {
  toast: Toast;
  position: Toast.Position;
}

export const ToastBar = React.memo(({ toast, position }: Props) => {
  const rightPosition = position.includes('right');
  const classNames = cx(styles.toastBar, toast.className, {
    [styles[rightPosition ? 'enterRight' : 'enterLeft']]: toast.visible,
    [styles[rightPosition ? 'exitRight' : 'exitLeft']]: !toast.visible,
  });

  const handleClose = React.useCallback(() => {
    dismissToast(toast.id);
    toast.onClose?.();
  }, [toast.id, toast.onClose]);

  return (
    <div className={classNames} data-testid={toast.dataTestId}>
      <div className={styles.messageWrapper}>
        {isDefined(toast.icon) && <div className={styles.icon}>{toast.icon}</div>}
        <div className={styles.message} {...toast.ariaProps}>
          {toast.message}
        </div>
        <button onClick={handleClose} className={styles.closeIcon}>
          <Icon type={IconType.Close} color={IconColor.White} size={14} />
        </button>
      </div>
      {isDefined(toast.description) && <div className={styles.description}>{toast.description}</div>}
      {isDefined(toast.actions) && !isEmpty(toast.actions) && (
        <div className={styles.actionsWrapper}>
          {toast.actions.map(action => {
            if (isDefined(action.to)) {
              return <ToastActionLink key={action.label} to={action.to} label={action.label} onClick={action.onClick} />;
            }

            return <ToastActionButton key={action.label} action={action} handleClose={handleClose} />;
          })}
        </div>
      )}
    </div>
  );
});
