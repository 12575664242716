import { Textarea as TextareaNs } from '@typings';
import cx from 'classnames';
import React from 'react';

import { TEXTAREA_MAX_LENGTH } from '../../../../constants/limits';
import { Skeleton } from '../../Skeleton';

import styles from './Textarea.module.scss';

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaNs.Props>((props, ref) => {
  const {
    dataTestId,
    describedById,
    errorMessageId,
    isDisabled,
    isInvalid = false,
    isReadonly = false,
    isRequired = false,
    isLoading = false,
    className,
    onChange,
    ...rest
  } = props;

  const classNames = cx(styles.textarea, className);

  if (isLoading) {
    return <Skeleton type="rectangle" className={styles.defaultTextareaSkeleton} />;
  }

  return (
    <textarea
      ref={ref}
      aria-describedby={describedById}
      aria-errormessage={errorMessageId}
      aria-invalid={isInvalid}
      className={classNames}
      data-testid={dataTestId}
      disabled={isDisabled}
      maxLength={TEXTAREA_MAX_LENGTH}
      readOnly={isReadonly}
      required={isRequired}
      rows={6}
      onChange={onChange}
      {...rest}
    />
  );
});
