import { Preset } from '@typings';
import React from 'react';
import { useSelector } from 'react-redux';

import { useMatrixDeliveryWindowContext, useMatrixVariantsContext } from '../../../components/products/ProductMatrix/context';
import { getDistributionsForVariants } from '../../../ducks';

export const useDistributionsMap = () => {
  const { deliveryWindowId } = useMatrixDeliveryWindowContext();
  const { availableItemIds, variants } = useMatrixVariantsContext();

  const distributionsForVariantsSelector = React.useMemo(
    () => getDistributionsForVariants(variants, deliveryWindowId),
    [deliveryWindowId, variants],
  );

  const distributionsForVariants = useSelector(distributionsForVariantsSelector);

  return React.useMemo(() => {
    return variants.reduce<Record<string, Preset[]>>((acc, variant) => {
      const variantId = variant.variant;
      const distributions = distributionsForVariants[variantId] ?? [];

      const sizeAvailability = variant.items.reduce<Record<string, boolean>>(
        (items, item) => ({
          ...items,
          [item.sizeId]: availableItemIds.includes(item.item),
        }),
        {},
      );

      const validDistributions = distributions.filter(distribution =>
        distribution.items.some(item => item.quantity !== 0 && sizeAvailability[item.sizeId]),
      );

      return {
        ...acc,
        [variantId]: validDistributions,
      };
    }, {});
  }, [availableItemIds, distributionsForVariants, variants]);
};
