import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ContentBlockContext } from '../../../components/cms/context/ContentBlockContext';
import { EditorUIContext } from '../../../components/cms/context/EditorUIContext';
import { IconType } from '../../../components/various/Icon';
import {
  getSectionIdByGroupId,
  setCurrentBlock,
  setCurrentSection,
  setIsHotspotEditing,
  setIsRepositioningImage,
  showModal,
  updateBlockSettings,
} from '../../../ducks';
import { isDefined } from '../../../utils/is';
import { useConfirmationGuard } from '../useConfirmationGuard';

interface Props {
  areToolsDisabled: boolean;
}

export const useMediaTools = ({ areToolsDisabled }: Props) => {
  const { t } = useTranslation(['cms', 'cmsConfirmationConfig', 'common']);
  const dispatch = useDispatch();
  const confirmationGuard = useConfirmationGuard();
  const { screenType } = React.useContext(EditorUIContext);
  const { blockId, deleteBlockElement, hasHotspots, hasImageMedia, hasLink, hasMedia, hasOverlay, parameters } =
    React.useContext(ContentBlockContext);
  const sectionId = useSelector(getSectionIdByGroupId(parameters?.groupId));

  const setMedia = React.useCallback(() => {
    dispatch(setCurrentBlock(blockId));
    dispatch(showModal('UploadMediaModal'));
  }, [blockId, dispatch]);

  const handleShowMediaPositioner = React.useCallback(() => {
    dispatch(setCurrentBlock(blockId));
    dispatch(setIsRepositioningImage(true));
  }, [blockId, dispatch]);

  const handleDeleteMedia = confirmationGuard(() => ({
    onOk: () => {
      const value = hasOverlay ? { general: { color: parameters?.settings.background?.general.color } } : undefined;

      deleteBlockElement(['hotspots']);
      dispatch(
        updateBlockSettings({
          blockId,
          updatePath: ['background'],
          value,
        }),
      );
    },
    ...t(`cmsConfirmationConfig:${hasHotspots ? 'delete_media_with_hotspots' : 'delete_media'}`, {
      returnObjects: true,
    }),
    okText: t('common:delete'),
  }));

  const openHotspotsEditor = React.useCallback(() => {
    if (isDefined(sectionId)) {
      dispatch(setCurrentSection(sectionId));
    }
    dispatch(setCurrentBlock(blockId));
    dispatch(setIsHotspotEditing(true));
  }, [blockId, dispatch, sectionId]);

  const clearLinkBeforeAddingHotspots = confirmationGuard(() => ({
    onOk: () => {
      deleteBlockElement(['link']);
      openHotspotsEditor();
    },
    ...t('cmsConfirmationConfig:add_hotspots', { returnObjects: true }),
    okText: t('common:clear'),
  }));

  const handleAddHotspots = hasLink ? clearLinkBeforeAddingHotspots : openHotspotsEditor;

  const handleDeleteHotspots = confirmationGuard(() => ({
    onOk: () => {
      deleteBlockElement(['hotspots']);
    },
    ...t('cmsConfirmationConfig:delete_hotspots', { returnObjects: true }),
    okText: t('common:delete'),
  }));

  return {
    icon: IconType.Media,
    isDisabled: areToolsDisabled,
    items: [
      {
        icon: IconType.Media,
        items: [
          {
            icon: hasMedia ? IconType.ReplaceHorizontal : IconType.Add,
            key: 'addMedia',
            label: t(`cms:${hasMedia ? 'replace' : 'add'}_media`),
            onClick: setMedia,
            testId: `${hasMedia ? 'replace' : 'add'}Media`,
          },
          {
            icon: IconType.Reposition,
            isDisabled: !hasMedia,
            key: 'repositionMedia',
            label: t('cms:reposition_media'),
            onClick: handleShowMediaPositioner,
          },
          {
            icon: IconType.Trash,
            isDisabled: !hasMedia,
            key: 'deleteMedia',
            label: t('cms:delete_media'),
            onClick: handleDeleteMedia,
          },
        ],
        key: 'mediaToolsParent',
        label: t('cms:media'),
        testId: 'mediaToolsParent',
      },
      {
        disabledTooltip: t('cms:hotspots_hint'),
        icon: IconType.Hotspot,
        isDisabled: !hasImageMedia,
        items: [
          {
            icon: IconType.Hotspot,
            isDisabled: screenType !== 'desktop',
            key: 'addHotspots',
            label: t(`cms:${hasHotspots ? 'edit' : 'add'}_hotspots`),
            onClick: handleAddHotspots,
            testId: 'addHotspots',
          },
          {
            icon: IconType.Trash,
            isDisabled: !hasHotspots,
            key: 'removeHotspots',
            label: t('cms:delete_hotspots'),
            onClick: handleDeleteHotspots,
            testId: 'removeHotspots',
          },
        ],
        key: 'hotspotsParent',
        label: t('cms:hotspots'),
        testId: 'hotspotsParent',
      },
      {
        key: 'deleteSeparator',
      },
      {
        icon: IconType.Trash,
        isDisabled: !hasMedia,
        key: 'deleteMediaItems',
        label: t('cms:delete_media_items'),
        onClick: handleDeleteMedia,
      },
    ],
    key: 'mediaTools',
    label: t('cms:media_tools'),
    testId: 'mediaTools',
  };
};
