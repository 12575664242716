import { Checkboxes, Filter } from '@typings';
import React from 'react';

import { mapFilterOptionsToCheckboxOptions } from '../../../../../logic/filters';
import { useCheckboxGroupState } from '../../../../../utils/hooks/checkboxGroup/useCheckboxGroupState';
import { isDefined } from '../../../../../utils/is';
import { alphabeticSortByKey } from '../../../../../utils/objectSort';
import { CheckboxGroup } from '../../../../various/CheckboxGroup';

import { BadgeFilterName } from './BadgeFilterName';

interface Props {
  filterField: string;
  fieldOptions: Filter.Option<string>[];
  selectedOptions: string[];
  onSelectedOptionsChange: (selectedValues: string[]) => void;
}

const NON_ALPHABETICAL_FIELDS = ['categories', 'collections', 'brands'];

export const FiltersCheckboxGroup = ({ filterField, fieldOptions, selectedOptions, onSelectedOptionsChange }: Props) => {
  const options: Checkboxes.Option[] = React.useMemo(() => {
    if (filterField === 'sh_custom_badge.name') {
      return fieldOptions.map(({ data, filterValue, badge }) => ({
        name: <BadgeFilterName name={data} image={badge?.image} imagePlacement={badge?.imagePlacement} />,
        value: filterValue,
      }));
    }

    return mapFilterOptionsToCheckboxOptions(fieldOptions);
  }, [fieldOptions, filterField]);

  const sortedOptions = React.useMemo(
    () => (NON_ALPHABETICAL_FIELDS.includes(filterField) ? options : alphabeticSortByKey(options, 'name')),
    [filterField, options],
  );

  const { batchUpdate, optionsState, toggleOptionState } = useCheckboxGroupState({
    onOptionToggle: onSelectedOptionsChange,
    options: sortedOptions,
  });

  React.useEffect(() => {
    if (isDefined(selectedOptions) && Array.isArray(selectedOptions)) {
      batchUpdate(selectedOptions);
    }
  }, [selectedOptions, batchUpdate]);

  return <CheckboxGroup options={sortedOptions} optionsState={optionsState} onChange={toggleOptionState} noWrapText={false} />;
};
