import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrentBlockId, setCurrentBlock } from '../../../../ducks/cms';
import { StyleSelectContext } from '../../context/StyleSelectContext';

import styles from './NewStyleButton.module.scss';

interface Props {
  onCreate: () => void;
}

export const NewStyleButton = ({ onCreate }: Props) => {
  const { isLastOptionActive, isTextStyle } = React.useContext(StyleSelectContext);
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const dispatch = useDispatch();
  const currentBlockId = useSelector(getCurrentBlockId);
  const { t } = useTranslation(['cms']);

  const handleCreate = React.useCallback(() => {
    onCreate();
    dispatch(setCurrentBlock(currentBlockId));
  }, [onCreate, dispatch, currentBlockId]);

  React.useEffect(() => {
    if (isLastOptionActive) {
      buttonRef.current?.focus();
    }
  }, [isLastOptionActive]);

  return (
    <div role="menuitem">
      <button ref={buttonRef} className={styles.newStyleButton} tabIndex={-1} data-testid="newStyleButton" onClick={handleCreate}>
        {isTextStyle ? t('cms:create_new_text_style') : t('cms:create_new_button_style')}
      </button>
    </div>
  );
};
