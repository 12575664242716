import { Order } from '@typings';

import { getOrderDisplayDate } from '../../../../utils/dates';
import { useStageName } from '../../../../utils/hooks';

type Props = {
  status: string;
  record: Order.Essentials;
};

export const StatusColumn = ({ status, record }: Props) => (
  <>
    <div>{useStageName(status)}</div>
    <div>{getOrderDisplayDate(record)}</div>
  </>
);
