import { SwatchData } from '@typings';

import { getBackgroundBySwatch } from '../../../utils/getBackgroundBySwatch';

import styles from './Swatch.module.scss';

interface Props {
  swatchData: SwatchData;
}

export const Swatch = ({ swatchData }: Props) => {
  const background = getBackgroundBySwatch(swatchData);

  return <div className={styles.swatch} style={{ background }} />;
};
