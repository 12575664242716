import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getIsCsvImportEnabled, getIsLoadingOrderDetails, getIsLoggedIn, getOrderDetails, getOrderId, showModal } from '../../../../ducks';
import { paths } from '../../../../paths';
import { useDeleteSelections } from '../../../../services/hooks/orders/useDeleteSelections';
import { useClickOutside, useScreenWidth } from '../../../../utils/hooks';
import { useAmplitude } from '../../../../utils/hooks/useAmplitude';
import { useConfirmationGuard } from '../../../../utils/hooks/useConfirmationGuard';
import Icon, { IconColor, IconType } from '../../../various/Icon';
import { MaybeTooltip } from '../../../various/MaybeTooltip';
import { CopyToNewSelectionModal } from '../../CopyToNewSelectionModal';
import { SelectionImportModal } from '../../SelectionImportModal';

import styles from './actions.module.scss';

interface Props {
  isOpen: boolean;
  className?: string;
}

const DESKTOP_WIDTH = 1400;

export const Actions = ({ className, isOpen }: Props) => {
  const { t } = useTranslation(['confirmationConfig', 'common', 'orders', 'selectionOverview', 'selections']);
  const actionsRef = React.useRef<Nullable<HTMLDivElement>>(null);
  const confirmationGuard = useConfirmationGuard();
  const [isExpanded, setIsExpanded] = React.useState(false);
  const { order, buyer } = useSelector(getOrderDetails);
  const isLoadingOrderDetails = useSelector(getIsLoadingOrderDetails);
  const screenWidth = useScreenWidth();
  const navigate = useNavigate();
  const { trackEvent } = useAmplitude();

  const toggle = React.useCallback(() => setIsExpanded(!isExpanded), [isExpanded]);

  useClickOutside({
    element: actionsRef.current,
    handler: () => isExpanded && setIsExpanded(false),
  });

  const isUserLoggedIn = useSelector(getIsLoggedIn);
  const isCsvImportEnabled = useSelector(getIsCsvImportEnabled);
  const dispatch = useDispatch();
  const { deleteSelectionByIds } = useDeleteSelections();

  const orderId = useSelector(getOrderId);
  const deleteOrder = confirmationGuard(() => ({
    onOk: () => {
      deleteSelectionByIds.mutate([orderId], { onSuccess: () => navigate(paths.SELECTIONS) });
    },
    ...t('confirmationConfig:delete_order', { returnObjects: true }),
  }));

  const openSelectionShareModal = React.useCallback(() => {
    dispatch(showModal('SelectionShareModal'));
    trackEvent({ name: 'bottomBar.button.share.click' });
  }, [dispatch, trackEvent]);

  const openEditModal = React.useCallback(() => {
    dispatch(showModal('EditOrderModal'));
    trackEvent({ name: 'bottomBar.button.edit.click' });
  }, [dispatch, trackEvent]);

  const openOverviewModal = React.useCallback(() => {
    dispatch(showModal('SelectionOverviewModal'));
    trackEvent({ name: 'bottomBar.button.overview.click' });
  }, [dispatch, trackEvent]);

  const openCopySelectionModal = React.useCallback(() => {
    dispatch(showModal('CopyToNewSelectionModal'));
  }, [dispatch]);

  const openImportModal = React.useCallback(() => {
    dispatch(showModal('SelectionImportModal'));
    trackEvent({ name: 'bottomBar.button.import.click' });
  }, [dispatch, trackEvent]);

  const handleCopySelectionSubmit = () => {
    trackEvent({ name: 'bottomBar.button.copyselection.click' });
  };

  const actions = React.useMemo(
    () => [
      {
        iconType: IconType.Edit,
        isVisible: isUserLoggedIn,
        label: t('selections:edit_selection'),
        onClick: openEditModal,
      },
      {
        iconType: IconType.Copy,
        isVisible: isUserLoggedIn,
        label: t('selections:copy_to_new_selection'),
        onClick: openCopySelectionModal,
      },
      {
        iconType: IconType.Trash,
        isVisible: isUserLoggedIn,
        label: t('selections:delete_selection'),
        onClick: deleteOrder,
      },
      {
        iconType: IconType.Overview,
        isDesktopVisible: true,
        isVisible: true,
        label: t('selectionOverview:selection_overview'),
        onClick: openOverviewModal,
      },
      {
        iconType: IconType.Share,
        isDesktopVisible: true,
        isVisible: isUserLoggedIn,
        label: t('selections:share_selection'),
        onClick: openSelectionShareModal,
      },
      {
        iconType: IconType.FileImport,
        isDesktopVisible: true,
        isVisible: isCsvImportEnabled && isUserLoggedIn,
        label: t('selections:import_selection_csv'),
        onClick: openImportModal,
      },
    ],
    [
      isUserLoggedIn,
      t,
      openEditModal,
      openCopySelectionModal,
      deleteOrder,
      openOverviewModal,
      openSelectionShareModal,
      isCsvImportEnabled,
      openImportModal,
    ],
  );

  const isDesktop = screenWidth > DESKTOP_WIDTH;

  const standardActions = isDesktop ? actions.filter(action => action.isDesktopVisible) : [];

  const moreActions = isDesktop ? actions.filter(action => !action.isDesktopVisible) : actions;

  const shouldShowMoreButton = moreActions.some(action => action.isVisible);

  const renderActions = (actionsArray: typeof actions, shouldShowLabel = false) =>
    actionsArray
      .filter(action => action.isVisible)
      .map(action => {
        return (
          <MaybeTooltip key={action.label} content={shouldShowLabel ? undefined : action.label}>
            <button
              type="button"
              aria-label={action.label}
              disabled={isLoadingOrderDetails}
              className={styles.button}
              onClick={action.onClick}
            >
              <Icon type={action.iconType} color={IconColor.Light} />
              {shouldShowLabel && action.label}
            </button>
          </MaybeTooltip>
        );
      });

  return (
    <>
      <CopyToNewSelectionModal
        orderId={order.order}
        orderNumber={order.orderNumber}
        defaultBuyer={buyer.buyer}
        onSubmit={handleCopySelectionSubmit}
      />
      {isCsvImportEnabled && <SelectionImportModal />}
      <div className={cx(styles.wrapper, className, { [styles.open]: isOpen })}>
        {shouldShowMoreButton && (
          <button
            aria-label={t('orders:toggle_order_actions')}
            aria-haspopup="true"
            aria-controls="orderActionsMenu"
            aria-expanded={isExpanded}
            onClick={toggle}
            disabled={isLoadingOrderDetails}
            className={cx(styles.button, styles.moreButton)}
          >
            <Icon className={styles.more} type={IconType.MoreVertical} color={IconColor.Light} />
          </button>
        )}
        <div id="orderActionsMenu" ref={actionsRef} className={cx(styles.actionsWrapper, { [styles.show]: isExpanded })}>
          {renderActions(moreActions, true)}
        </div>
        {renderActions(standardActions)}
      </div>
    </>
  );
};
