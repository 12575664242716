import { Cms } from '@centra';

import { ButtonStyles, buttonStylesConfig, DEFAULT_BUTTON_FONT_SIZE } from '../cmsTemplates';
import { isDefined } from '../utils/is';

import { isButtonPart } from './cms/styles';
import { isDesktopWidth, isTabletWidth } from './pages';

const TABLET_FONT_SCALE = 0.8;
const MOBILE_FONT_SCALE = 0.65;

const getButtonFontSize = (buttonStyles: Record<string, Cms.ButtonStyle>, className: string) =>
  isDefined(buttonStyles[className]) ?
    buttonStyles[className]?.properties.default.fontSize
  : buttonStylesConfig[ButtonStyles.FilledPrimary]?.properties.default.fontSize;

export const calculateFontSize = (
  parameters: Cms.ContentBlockPart,
  screenWidth: number,
  buttonStyles: Record<string, Cms.ButtonStyle> = buttonStylesConfig,
) => {
  const isButton = isButtonPart(parameters);

  const fontSize =
    isButton ? getButtonFontSize(buttonStyles, parameters.general.buttonStyle) ?? DEFAULT_BUTTON_FONT_SIZE : parameters.desktop.fontSize;

  if (isDesktopWidth(screenWidth)) {
    return fontSize;
  }
  if (isTabletWidth(screenWidth)) {
    return (!isButton && isDefined(parameters.tablet) && parameters.tablet.fontSize) || fontSize * TABLET_FONT_SCALE;
  }

  return (!isButton && isDefined(parameters.mobile) && parameters.mobile.fontSize) || fontSize * MOBILE_FONT_SCALE;
};
