import { IconComponentProps } from '@typings';

import styles from '../Icon.module.scss';

const ORIGINAL_WIDTH = 20;

export const Spinner = ({ width = ORIGINAL_WIDTH, color = 'currentColor' }: IconComponentProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width={width} height={width} fill={color} className={styles.spinner}>
      <path d="M5.556 3.348A8 8 0 0 1 10 2a1 1 0 1 1 0 2 6 6 0 1 0 6 6 1 1 0 0 1 2 0A8 8 0 1 1 5.556 3.348Z" />
      <path
        fillOpacity="0.2"
        d="M14.45 5.55C13.485 4.585 12.015 4 10 4V2c2.402 0 4.433.706 5.864 2.136C17.294 5.566 18 7.598 18 10h-2c0-2.015-.585-3.485-1.55-4.45Z"
      />
    </svg>
  );
};
