import * as Sentry from '@sentry/react';
import { saveAs } from 'file-saver';
import React from 'react';
import { useDispatch } from 'react-redux';

import { pushEvent } from '../../../ducks';
import { DownloadItemTrackingEvent } from '../../../utils/analytics/events';
import { downloadOrderInvoice as downloadOrderInvoiceRequest } from '../../documentsRepository';

interface DownloadOrderInvoicePayload {
  orderId: string;
  invoiceId: string;
  invoiceNumber: string;
}

export const useOrderInvoiceDownload = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);

  const downloadOrderInvoice = React.useCallback(
    async (payload: DownloadOrderInvoicePayload) => {
      const { invoiceNumber, invoiceId, orderId } = payload;
      setHasError(false);
      setIsLoading(true);
      try {
        const response = await downloadOrderInvoiceRequest({ invoiceId, orderId });
        saveAs(response.data as Blob, `invoice_${invoiceNumber}.${(response.data as Blob).type.split('/')[1]}`);
      } catch (error) {
        Sentry.captureException(error);
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
      dispatch(pushEvent({ event: DownloadItemTrackingEvent.DOWNLOAD_INVOICE_GENERATED }));
    },
    [dispatch],
  );

  return {
    downloadOrderInvoice,
    hasError,
    isLoading,
  };
};
