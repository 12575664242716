import { Market } from '@typings';

import { isDefined } from '../utils/is';

/**
 * Gets market name from list of markets based on its id
 */
export const getMarketName = (markets: Market[] | undefined, id: string): string => {
  if (!isDefined(markets)) {
    return '';
  }

  const market = markets.find(item => item.market === id);

  return isDefined(market) ? market.name : '';
};
