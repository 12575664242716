import React from 'react';

import { Checkbox } from '../../Fields/Checkbox';
import styles from '../Table.module.scss';
interface Props {
  checked: boolean;
  disabled: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}
export const CheckboxCell = ({ onChange, checked }: Props) => {
  return (
    <div className={styles.checkboxCell}>
      <Checkbox checked={checked} onChange={onChange} />
    </div>
  );
};
