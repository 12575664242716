import React from 'react';
import { useTranslation } from 'react-i18next';

import { useOrderInvoiceDownload } from '../../../services/hooks/invoice/useOrderInvoiceDownload';
import { IconType } from '../../various/Icon';
import { IconButton } from '../../various/IconButton/IconButton';

interface Props {
  invoiceId: string;
  orderId: string;
  invoiceNumber: string;
}

export const InvoiceDownloadLink = React.memo(({ invoiceId, orderId, invoiceNumber }: Props) => {
  const { t } = useTranslation(['invoices']);
  const { downloadOrderInvoice } = useOrderInvoiceDownload();
  const onDownload = () => {
    downloadOrderInvoice({ invoiceId, invoiceNumber, orderId });
  };

  return <IconButton onClick={onDownload} name={t('invoices:download_invoice')} icon={IconType.DownloadCloud} variant="secondary" />;
});
