export const PHONE_ALLOWED_SYMBOLS = /^[0-9()+ -]+$/;
export const NAME_ALLOWED_CHARACTERS = /^([^0-9])+$/;
export const URL_ALLOWED_SYMBOLS = /^[a-zA-Z0-9._\-~]+$/;
export const URL_DISALLOWED_SYMBOLS = /[^a-zA-Z0-9.~\-_]/gi;
export const NUMBERS = /^\d+$/;
export const WHITESPACE = /\s/g;

/**
 * Regex pattern based on RFC 5322 standard ensures that email addresses are in a valid format,
 * although it doesn't cover cases in pracice with standard email providers.
 *
 * Note: The email '4@4' is technically valid according to the RFCs because both the local part and the domain part adhere to the allowed
 * character sets and formats. However, in practice, email providers may impose additional constraints that prevent such
 * addresses from being used.
 *
 * Applied regex source: https://www.npmjs.com/package/yup/v/0.1.0
 */
export const VALID_EMAIL =
  // eslint-disable-next-line no-control-regex, no-useless-escape
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;
