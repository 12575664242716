import { Lookbook } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { getAllDeliveryWindows } from '../../ducks';
import { useCreateLookbook } from '../../services/hooks/lookbook/useCreateLookbook';
import { useLookbooksList } from '../../services/hooks/lookbook/useLookbooksList';
import { useModalVisibility } from '../../utils/hooks';
import { useValidatedForm } from '../../utils/hooks/useValidatedForm';
import { getDeliveryWindowsSchema } from '../../utils/schemas';
import { Option } from '../various/Fields/Select';
import { AutocompleteField, Form, FormButtons, FormFieldset, FormGrid, InputField } from '../various/Form';
import { Button } from '../various/NewButton';

const defaultValues: Lookbook.CreateLookbookDTO = {
  deliveryWindows: [],
  email: '',
  market: '',
};

export const CreateLookbookForm = () => {
  const { t } = useTranslation(['common', 'validation', 'lookbook']);
  const { data } = useLookbooksList();
  const deliveryWindows = useSelector(getAllDeliveryWindows);

  const { hideModal } = useModalVisibility('CreateLookbookModal');
  const { mutate: createLookbook, isPending } = useCreateLookbook();

  const markets = React.useMemo(() => data?.markets ?? [], [data?.markets]);

  const validationSchema: yup.ObjectSchema<Lookbook.CreateLookbookDTO> = yup.object({
    deliveryWindows: getDeliveryWindowsSchema(),
    email: yup.string().defined().email(t('validation:invalid_email_hint')),
    market: yup.string().required(t('validation:market_hint')),
  });

  const formMethods = useValidatedForm<Lookbook.CreateLookbookDTO>({ defaultValues, validationSchema });
  const { register } = formMethods;

  const handleSubmit = (values: Lookbook.CreateLookbookDTO) => {
    createLookbook(values, {
      onSuccess: hideModal,
    });
  };

  return (
    <Form formMethods={formMethods} onSubmit={handleSubmit}>
      <FormFieldset isDisabled={isPending}>
        <FormGrid>
          <AutocompleteField
            isRequired
            name="deliveryWindows"
            label={t('common:delivery_window_other')}
            placeholder={t('common:select_delivery_window')}
          >
            {deliveryWindows.map(({ deliveryWindow, name }) => (
              <Option key={deliveryWindow} value={deliveryWindow}>
                {name}
              </Option>
            ))}
          </AutocompleteField>
          <AutocompleteField isRequired name="market" label={t('common:market')} placeholder={t('common:select_market')}>
            {markets.map(({ market, name }) => (
              <Option key={market} value={market}>
                {name}
              </Option>
            ))}
          </AutocompleteField>
          <InputField type="email" label={t('common:email_address')} placeholder={t('lookbook:email_optional')} {...register('email')} />
        </FormGrid>
        <FormButtons showDivider>
          <Button size="large" variant="ghost" color="dark" onClick={hideModal}>
            {t('common:cancel')}
          </Button>
          <Button type="submit" size="large" isLoading={isPending}>
            {t('lookbook:create_lookbook')}
          </Button>
        </FormButtons>
      </FormFieldset>
    </Form>
  );
};
