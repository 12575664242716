import { ActionHandler, Cms, Id } from '@typings';
import { omit } from 'ramda';
import { createReducer } from 'typesafe-actions';

import { isDefined } from '../../../utils/is';
import { fetchEditorPageDataSuccess, refreshPageDataSuccess } from '../../pages';
import { resetCms, setPreviewData } from '../general';
import { groupCreated, pasteSection, sectionRemoved } from '../sections';

import { automaticGroupChangeSuccess, groupRemoved, manualLayoutEditingSuccess, pasteGroup } from './actions';

type State = Record<Id, Cms.Group>;

const initialState = {};

const handleResetGroups: ActionHandler<State, typeof resetCms> = () => initialState;

const handleFetchEditorPageSuccess: ActionHandler<State, typeof fetchEditorPageDataSuccess> = (_, action) => action.payload.data.groups;
const handleSetPreviewData: ActionHandler<State, typeof setPreviewData> = (_, action) => action.payload.data.groups;

const handleGroupCreated: ActionHandler<State, typeof groupCreated> = (state, action) => {
  const { blocksGroup } = action.payload;

  const [firstBlock] = blocksGroup;

  if (!isDefined(firstBlock)) {
    return state;
  }

  const { groupId } = firstBlock;
  const blockIds = blocksGroup.map(block => block.id);

  if (!isDefined(groupId)) {
    return state;
  }

  return {
    ...state,
    [groupId]: {
      blocks: blockIds,
      id: groupId,
    },
  };
};

const handleGroupChangeSuccess: ActionHandler<State, typeof automaticGroupChangeSuccess | typeof manualLayoutEditingSuccess> = (
  state,
  action,
) => {
  const { groupId, blocks } = action.payload;
  const blockIds = blocks.map(block => block.id);

  return {
    ...state,
    [groupId]: {
      blocks: blockIds,
      id: groupId,
    },
  };
};

const handleGroupRemoved: ActionHandler<State, typeof groupRemoved> = (state, action) => omit([action.payload.groupId], state);
const handleSectionRemoved: ActionHandler<State, typeof sectionRemoved> = (state, action) => omit(action.payload.groupIds, state);

const handlePasteGroup: ActionHandler<State, typeof pasteGroup> = (state, action) => {
  const { group } = action.payload;

  return { ...state, [group.id]: group };
};

const handlePasteSection: ActionHandler<State, typeof pasteSection> = (state, action) => {
  return {
    ...state,
    ...action.payload.groups,
  };
};

const handleRefreshPageDataSuccess: ActionHandler<State, typeof refreshPageDataSuccess> = (_, action) => action.payload.groups;

export default createReducer<State, AppAction>(initialState)
  .handleAction(fetchEditorPageDataSuccess, handleFetchEditorPageSuccess)
  .handleAction(setPreviewData, handleSetPreviewData)
  .handleAction(groupCreated, handleGroupCreated)
  .handleAction(automaticGroupChangeSuccess, handleGroupChangeSuccess)
  .handleAction(manualLayoutEditingSuccess, handleGroupChangeSuccess)
  .handleAction(groupRemoved, handleGroupRemoved)
  .handleAction(sectionRemoved, handleSectionRemoved)
  .handleAction(refreshPageDataSuccess, handleRefreshPageDataSuccess)
  .handleAction(resetCms, handleResetGroups)
  .handleAction(pasteGroup, handlePasteGroup)
  .handleAction(pasteSection, handlePasteSection);
