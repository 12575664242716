import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;

export const Plus = ({ width = ORIGINAL_WIDTH, color }: IconComponentProps) => {
  return (
    <svg viewBox="0 0 20 20" width={width} height={width} fill={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3c.374 0 .677.303.677.677v5.646h5.646a.677.677 0 0 1 0 1.354h-5.646v5.646a.677.677 0 0 1-1.354 0v-5.646H3.677a.677.677 0 0 1 0-1.354h5.646V3.677c0-.374.303-.677.677-.677z"
      />
    </svg>
  );
};
