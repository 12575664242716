import { Cms, Translations } from '@typings';
import { nanoid } from 'nanoid';
import { assocPath } from 'ramda';

import { isDefined } from '../../utils/is';
import { getIsContentBlock } from '../pages';

export const mapToNewIds = <T extends { id: string }>(elementsArray: T[]) =>
  elementsArray.reduce<[T[], Cms.IdDictionary]>(
    ([elements, dictionary], element) => {
      const newId = nanoid();
      const newElement = { ...element, id: newId };

      return [[...elements, newElement], { ...dictionary, [element.id]: newId }];
    },
    [[], {}],
  );

const getPartsWithNewIdsAndDictionary = (parts: Cms.ContentBlockPartRecord) =>
  Object.entries(parts).reduce<[Cms.ContentBlockPartRecord, Cms.IdDictionary]>(
    ([partsAcc, dictionaryAcc], [partId, part]) => {
      const newPartId = nanoid();

      return [
        { ...partsAcc, [newPartId]: part },
        { ...dictionaryAcc, [partId]: newPartId },
      ];
    },
    [{}, {}],
  );

export const getBlocksWithNewPartsIdsAndPartsDictionary = (blocks: Cms.AnyBlock[]) =>
  blocks
    .map(block => {
      if (!getIsContentBlock(block) || !isDefined(block.settings.text)) {
        return [block, {}] as const;
      }

      const { parts, partsOrder } = block.settings.text;
      const [partsWithNewIds, partsDictionary] = getPartsWithNewIdsAndDictionary(parts);
      const newPartsOrder = partsOrder.map(partId => partsDictionary[partId]);
      const blockWithNewParts = assocPath(['settings', 'text', 'parts'], partsWithNewIds, block);
      const blockWithNewPartsAndPartsOrder = assocPath(['settings', 'text', 'partsOrder'], newPartsOrder, blockWithNewParts);

      return [blockWithNewPartsAndPartsOrder, partsDictionary] as const;
    })
    .reduce<[Cms.AnyBlock[], Cms.IdDictionary]>(
      ([blocksAcc, partsDictionaryAcc], [block, partsDictionary]) => [[...blocksAcc, block], { ...partsDictionaryAcc, ...partsDictionary }],
      [[], {}],
    );

interface GetCopiedLocalizationsProps {
  localizations: Cms.BlockLocalizations;
  blocksDictionary: Cms.IdDictionary;
  blocks: Cms.AnyBlock[];
  languages: Translations.SupportedLanguagesCodes[];
  partsDictionary: Cms.IdDictionary;
}

export const getCopiedLocalizations = ({
  blocks,
  blocksDictionary,
  languages,
  localizations,
  partsDictionary,
}: GetCopiedLocalizationsProps) => {
  return languages.reduce((acc, language) => {
    const localizedBlocks = blocks.filter(getIsContentBlock).reduce((blocksAcc, block) => {
      const localizedBlock = localizations[language]?.blocks[block.id];
      const matchingBlockId = blocksDictionary[block.id];

      if (!isDefined(matchingBlockId)) {
        return blocksAcc;
      }

      if (!isDefined(localizedBlock?.settings.text)) {
        return {
          ...blocksAcc,
          [matchingBlockId]: localizedBlock,
        };
      }

      const partsWithNewIds = Object.entries(localizedBlock.settings.text.parts).reduce((partsAcc, [partId, part]) => {
        const matchingPartId = partsDictionary[partId];

        if (!isDefined(matchingPartId)) {
          return partsAcc;
        }

        return { ...partsAcc, [matchingPartId]: part };
      }, {});

      return {
        ...blocksAcc,
        [matchingBlockId]: assocPath(['settings', 'text', 'parts'], partsWithNewIds, localizedBlock),
      };
    }, {});

    return {
      ...acc,
      [language]: {
        blocks: localizedBlocks,
      },
    };
  }, {});
};
