import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { downloadOrderExcel } from '../../../ducks/documents';
import { getIsLoadingOrderDetails, getOrderDetails } from '../../../ducks/order';
import { getProductsFromOrder } from '../../../logic/Orders';
import { getUniqueSwitchersMapping, isSwitchAvailable } from '../../../logic/unitsSwitch';
import { useRemoveAllProducts } from '../../../utils/hooks';
import { ActionButton } from '../../products/ActionButton';
import { RevealPopup } from '../../products/RevealPopup/RevealPopup';
import { UnitSwitcher } from '../../products/UnitSwitcher';
import { ClearAllButton } from '../../various/ClearAllButton/ClearAllButton';
import { IconType } from '../../various/Icon';
import { IconButton } from '../../various/IconButton/IconButton';
import { PopoverMenu } from '../../various/PopoverMenu';
import { DownloadLinesheet } from '../DownloadLinesheet';

import styles from './UnitsActions.module.scss';

const OVERFLOW_OFFSET = 20;

interface Props {
  handleToggleGrouping: () => void;
  handleSortBySku: () => void;
  sortBySKU: boolean;
  groupByDelwin: boolean;
}

const UnitsActions = ({ groupByDelwin, sortBySKU, handleSortBySku, handleToggleGrouping }: Props) => {
  const dispatch = useDispatch();
  const orderDetails = useSelector(getOrderDetails);
  const switchersMapping = getUniqueSwitchersMapping(getProductsFromOrder(orderDetails));
  const isLoadingOrderDetails = useSelector(getIsLoadingOrderDetails);
  const { removeAllProducts, canRemoveAllProducts } = useRemoveAllProducts();
  const { t } = useTranslation(['common', 'orders']);

  const handleDownloadOrderExcel = React.useCallback(() => {
    const { order } = orderDetails;
    dispatch(downloadOrderExcel({ orderId: order.order, orderNumber: order.orderNumber }));
  }, [dispatch, orderDetails]);

  const actions = React.useMemo(
    () => [
      {
        isDisabled: !canRemoveAllProducts || isLoadingOrderDetails,
        key: 'clearAll',
        label: t('common:clear_all'),
        onClick: removeAllProducts,
      },
      {
        isDisabled: isLoadingOrderDetails,
        key: 'sortByArtNo',
        label: t('common:sort_by_art_no'),
        onClick: handleSortBySku,
      },
      {
        isDisabled: isLoadingOrderDetails,
        key: 'groupByDelwins',
        label: t('common:group_by_delwins'),
        onClick: handleToggleGrouping,
      },
    ],
    [canRemoveAllProducts, isLoadingOrderDetails, t, removeAllProducts, handleSortBySku, handleToggleGrouping],
  );

  const availableSwitchers = Object.entries(switchersMapping).filter(([_, { tableMappings }]) => isSwitchAvailable(tableMappings));

  return (
    <div className={cx(styles.section, styles.tools)}>
      {Object.values(switchersMapping).length > 0 && (
        <div className={cx(styles.section, styles.switchersSection)}>
          <RevealPopup overflowOffset={OVERFLOW_OFFSET} containerClassName={styles.revealPopup}>
            {availableSwitchers.map(([switcherName, { tableMappings, defaultTableMapping }]) => (
              <div key={switcherName} className={styles.switcher}>
                <UnitSwitcher size="small" tableMappings={tableMappings} defaultUnit={defaultTableMapping} switcherName={switcherName} />
              </div>
            ))}
          </RevealPopup>
        </div>
      )}
      <ClearAllButton className={cx(styles.actionButton, styles.hideOnMobile)} />
      <ActionButton
        isHidden={false}
        title={t('common:sort_by_art_no')}
        onClick={handleSortBySku}
        className={cx(styles.actionButton, styles.hideOnMobile, { [styles.sortActive]: sortBySKU })}
        isDisabled={isLoadingOrderDetails}
      />
      <IconButton
        className={styles.hideOnMobile}
        onClick={handleToggleGrouping}
        name={t('common:group_by_delwins')}
        showTooltip
        variant="secondary"
        tooltipPlacement="bottom-center"
        icon={IconType.GroupByDelwin}
        highlighted={groupByDelwin}
        disabled={isLoadingOrderDetails}
      />
      <IconButton
        icon={IconType.OrderDetails}
        name={t('orders:download_order_details')}
        showTooltip
        variant="secondary"
        tooltipPlacement="bottom-center"
        onClick={handleDownloadOrderExcel}
        disabled={isLoadingOrderDetails}
      />
      <DownloadLinesheet />
      <div className={styles.mobileTools}>
        <PopoverMenu
          classNameRegular={styles.mobileToolsButton}
          containerClassName={styles.popover}
          name={t('common:more_options')}
          items={actions}
          icon={IconType.MoreHorizontal}
          overflowContainer
        />
      </div>
    </div>
  );
};

export default UnitsActions;
