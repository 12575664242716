import { CheckoutForm } from '@typings';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { showTerms } from '../../../../ducks/order';
import { useModalVisibility } from '../../../../utils/hooks';
import { Key } from '../../../../utils/keys';
import { CheckboxField } from '../../../various/Form';
import Heading from '../../../various/Heading';

import styles from './Terms.module.scss';

type Props = {
  isDisabled: boolean;
};

export const CheckoutTerms = ({ isDisabled }: Props) => {
  const { t } = useTranslation(['checkout']);
  const dispatch = useDispatch();
  const { showModal } = useModalVisibility('CheckoutTermsModal');
  const { register, watch } = useFormContext<CheckoutForm>();
  const checkboxValue = watch('isTermsAndConditionsChecked');

  const handleShowTerms = React.useCallback(
    (event: React.SyntheticEvent<EventTarget>) => {
      event.preventDefault();
      event.stopPropagation();
      showModal();
      dispatch(showTerms());
    },
    [dispatch, showModal],
  );

  const handleKeyDown = React.useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === Key.ENTER) {
      event.preventDefault();
    }
  }, []);

  return (
    <div>
      <Heading variant={['blue', 'h2']} title={t('checkout:terms_and_conditions')} />

      <CheckboxField
        {...register('isTermsAndConditionsChecked')}
        disabled={isDisabled}
        checked={checkboxValue}
        inputClassName={styles.termsCheckbox}
        className={styles.label}
        onKeyDown={handleKeyDown}
        label={
          <div>
            <Trans t={t} i18nKey="checkout:terms_and_conditions_check">
              <span className={styles.requiredMark} data-testid="terms-checkbox" /> I have read and understood
              <button className={styles.buttonShowTerms} onClick={handleShowTerms}>
                Terms & Conditions
              </button>
              regarding this purchase.
            </Trans>
          </div>
        }
      />
    </div>
  );
};
