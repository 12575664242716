import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { removeVoucherRequest } from '../../../../../ducks';
import Icon, { IconColor, IconType } from '../../../../various/Icon';
import { StandardItem } from '../StandardItem';

import styles from './VoucherItem.module.scss';

interface Props {
  label: string;
  value: React.ReactNode;
  id: string;
  isReadOnly: boolean;
}

export const VoucherItem = ({ label, value, id, isReadOnly }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['checkout']);

  const handleRemoveVoucher = React.useCallback(() => {
    dispatch(removeVoucherRequest(id));
  }, [dispatch, id]);

  return (
    <StandardItem label={label} value={value} className={styles.voucher}>
      {!isReadOnly && (
        <button type="button" aria-label={t('checkout:voucher_remove')} className={styles.removeButton} onClick={handleRemoveVoucher}>
          <Icon type={IconType.Cross} color={IconColor.Red} />
        </button>
      )}
    </StandardItem>
  );
};
