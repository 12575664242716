import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';

import universalStyles from '../../../../css/utilities/universal.module.scss';
import { getHasOptimisticAdditions, getOpenOrClosedOrder, getOrderProducts, pushEvent } from '../../../ducks';
import { getHasAnyUnitsAdded, getIsOrderClosed } from '../../../logic/Orders';
import { compiledPaths } from '../../../paths';
import { getCheckoutStepsClickedEvent } from '../../../utils/analytics/events';
import { useAllProductsLink } from '../../../utils/hooks';
import { useAmplitude } from '../../../utils/hooks/useAmplitude';
import Icon, { IconType } from '../../various/Icon';

import styles from './CheckoutSteps.module.scss';

export type CheckoutStepName = 'allProducts' | 'mySelection' | 'checkout';

export const CheckoutSteps = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation(['checkout', 'common', 'products', 'selections']);
  const orderStatus = useSelector(getOpenOrClosedOrder);
  const orderProducts = useSelector(getOrderProducts);
  const hasOptimisticAdditions = useSelector(getHasOptimisticAdditions);

  const hasAnyUnitsAdded = getHasAnyUnitsAdded(orderProducts) || hasOptimisticAdditions;
  const isOrderClosed = getIsOrderClosed(orderStatus);

  const allProductsLink = useAllProductsLink(id);
  const { trackEvent } = useAmplitude();

  const steps = [
    {
      checkoutStepName: 'allProducts' as CheckoutStepName,
      dataTestId: 'productsStep',
      icon: IconType.Products,
      isActive: !isOrderClosed,
      isSelected: compiledPaths.PRODUCTS_ORDER({ id }) === pathname,
      name: (
        <>
          <span className={styles.mobileHide}>{t('products:all_products')}</span>
          <span className={styles.desktopHide}>{t('products:product_other')}</span>
        </>
      ),
      onClick: () => {
        dispatch(pushEvent(getCheckoutStepsClickedEvent('allProducts')));
        trackEvent({ name: 'breadcrumbs.link.allproducts.click' });
      },
      to: allProductsLink,
    },
    {
      checkoutStepName: 'mySelection' as CheckoutStepName,
      dataTestId: 'selectionStep',
      icon: IconType.Selection,
      isActive: !isOrderClosed,
      isSelected: compiledPaths.ORDER_DETAILS({ id }) === pathname,
      name: (
        <>
          <span className={styles.mobileHide}>{t('selections:my_selection')}</span>
          <span className={styles.desktopHide}>{t('selections:selection_one')}</span>
        </>
      ),
      onClick: () => {
        dispatch(pushEvent(getCheckoutStepsClickedEvent('mySelection')));
        trackEvent({ name: 'breadcrumbs.link.myselection.click' });
      },
      to: compiledPaths.ORDER_DETAILS({ id }),
    },
    {
      checkoutStepName: 'checkout' as CheckoutStepName,
      dataTestId: 'checkoutStep',
      icon: IconType.Checkout,
      isActive: !isOrderClosed && hasAnyUnitsAdded,
      isSelected: compiledPaths.CHECKOUT_ORDER({ id }) === pathname,
      name: t('common:checkout'),
      onClick: () => {
        dispatch(pushEvent(getCheckoutStepsClickedEvent('checkout')));
        trackEvent({ name: 'breadcrumbs.link.checkout.click' });
      },
      to: compiledPaths.CHECKOUT_ORDER({ id }),
    },
  ];

  return (
    <nav className={styles.nav}>
      <h2 className={universalStyles.srOnly}>{t('checkout:checkout_steps')}</h2>
      <ul className={styles.stepsWrapper}>
        {steps.map(({ to, isSelected, isActive, icon, name, dataTestId, onClick }) => (
          <li key={to} className={cx({ [styles.selected]: isSelected })} data-testid={dataTestId}>
            {isActive ?
              <Link to={to} className={cx({ [styles.selectedLink]: isSelected })} onClick={onClick}>
                <Icon type={icon} className={styles.stepIcon} />
                {name}
              </Link>
            : <span className={styles.inactive}>
                <Icon type={icon} className={styles.stepIcon} />
                {name}
              </span>
            }
          </li>
        ))}
      </ul>
    </nav>
  );
};
