import { Cms } from '@typings';
import { useTranslation } from 'react-i18next';

import { Option } from '../../various/Fields/Select';
import { AutocompleteField } from '../../various/Form';
import Icon, { IconType } from '../../various/Icon';

import styles from './MissingCustomStylesModal.module.scss';

interface Props {
  name: string;
  className: string;
  type: Cms.StyleType;
  textStyles: Record<string, Cms.TextStyle>;
  buttonStyles: Record<string, Cms.ButtonStyle>;
}

export const CustomStyleSelectField = ({ name, className, type, textStyles, buttonStyles }: Props) => {
  const { t } = useTranslation(['cms']);

  const isTextStyle = type === 'text';
  const customStyles = isTextStyle ? textStyles : buttonStyles;

  return (
    <div className={styles.row}>
      <label htmlFor={className} className={styles.styleName}>
        <Icon type={IconType.Warning} />
        {name}
      </label>
      <AutocompleteField
        id={className}
        name={className}
        overflowContainer
        placeholder={t(isTextStyle ? 'cms:select_text_style' : 'cms:select_button_style')}
      >
        {Object.values(customStyles).map(style => (
          <Option key={style.id} value={style.className}>
            {style.name}
          </Option>
        ))}
      </AutocompleteField>
    </div>
  );
};
