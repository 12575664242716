import React from 'react';

import { Portal } from '../Portal';

import { ModalContextProvider } from './context/ModalContext';
import { ModalBackdrop } from './ModalBackdrop/ModalBackdrop';
import { ModalContent } from './ModalContent/ModalContent';

export const enum ModalSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  XLarge = 'xlarg',
  XXLarge = 'xxlarg',
}

interface Props {
  size?: ModalSize;
  fullScreenOnMobile?: boolean;
  customModalStyles?: Partial<Record<'content' | 'overlay', React.CSSProperties>>;
  shouldCloseOnLocationChange?: boolean;
  modalClosedCallback?: () => void;
  onRequestClose: () => void;
  hideCloseIcon?: boolean;
  isOpen: boolean;
  contentClassName?: string;
  backdropClassName?: string;
  shouldCloseOnOverlayClick?: boolean;
  shouldCloseOnEsc?: boolean;
  shouldReturnFocusAfterClose?: boolean;
  centerModal?: boolean;
}

export const Modal = ({
  children,
  isOpen,
  size,
  fullScreenOnMobile,
  hideCloseIcon = false,
  shouldCloseOnLocationChange = true,
  shouldCloseOnOverlayClick,
  shouldCloseOnEsc = true,
  centerModal = true,
  onRequestClose,
  modalClosedCallback,
  contentClassName,
  shouldReturnFocusAfterClose,
}: React.WithChildren<Props>) => {
  return (
    <Portal>
      <div role="dialog" aria-modal="true">
        <ModalContextProvider
          size={size}
          isOpen={isOpen}
          shouldCloseOnLocationChange={shouldCloseOnLocationChange}
          shouldCloseOnEsc={shouldCloseOnEsc}
          fullScreenOnMobile={fullScreenOnMobile}
          hideCloseIcon={hideCloseIcon}
          shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
          shouldReturnFocusAfterClose={shouldReturnFocusAfterClose}
          modalClosedCallback={modalClosedCallback}
          onClose={onRequestClose}
        >
          <ModalBackdrop />
          <ModalContent className={contentClassName} centerModal={centerModal}>
            {children}
          </ModalContent>
        </ModalContextProvider>
      </div>
    </Portal>
  );
};
