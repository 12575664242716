import { useTranslation } from 'react-i18next';

import Button from '../../Button';
import Icon, { IconColor, IconType } from '../../Icon';
import styles from '../ScannerContent.module.scss';

interface Props {
  hasRequestedPermissions: boolean;
  requestPermissions: () => void;
}

export const NoPermissions = ({ hasRequestedPermissions, requestPermissions }: Props) => {
  const { t } = useTranslation(['common']);

  return (
    <div className={styles.message}>
      <Icon type={IconType.NoCamera} color={IconColor.Faded} size={48} />
      <Button variant={['button', 'small', 'blue']} onClick={requestPermissions}>
        {hasRequestedPermissions ? t('common:try_again') : t('common:allow_camera')}
      </Button>
    </div>
  );
};
