import { Cms } from '@typings';
import { assocPath } from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { customStyleFonts } from '../../../cmsTemplates';
import {
  closeButtonStyleEditor,
  closeTextStyleEditor,
  createButtonStyleRequest,
  createTextStyleRequest,
  editButtonStyleRequest,
  editTextStyleRequest,
  getCurrentItem,
  getCustomFonts,
  getIsCreatingButtonStyle,
  getIsCreatingTextStyle,
  getIsEditingButtonStyle,
  getIsEditingTextStyle,
  setButtonStyleEditorStyles,
  setTextStyleEditorStyles,
} from '../../../ducks';
import { doesStyleNameAlreadyExist, getIsButtonPartStyle } from '../../../logic/cms/styles';
import { isDefined } from '../../is';
import { isEmpty } from '../../isEmpty';
import { omit } from '../../omit';

import { useGetCurrentBlockData } from './useGetCurrentBlockData';

interface Props {
  allStyles: Record<string, Cms.ContentPartStyle>;
  customStyle: Nullable<Cms.ContentPartStyle>;
  mode: Cms.CustomStyleEditorMode;
  isEditorVisible: boolean;
}

type SetStyleProps = Cms.SetStyleProperty<Cms.TextStyleProperties | Cms.ButtonStyleProperties>;

type CreateEditCustomStyleProps = {
  onNameExistsFailure: () => void;
};

export const useStyleEditor = ({ allStyles, customStyle, isEditorVisible, mode = 'create' }: Props) => {
  const dispatch = useDispatch();
  const customFonts = useSelector(getCustomFonts);
  const isCreatingButtonStyle = useSelector(getIsCreatingButtonStyle);
  const isEditingButtonStyle = useSelector(getIsEditingButtonStyle);
  const isCreatingTextStyle = useSelector(getIsCreatingTextStyle);
  const isEditingTextStyle = useSelector(getIsEditingTextStyle);
  const currentItem = useSelector(getCurrentItem);
  const currentBlock = useGetCurrentBlockData();

  const [name, setName] = React.useState('');
  const [availableFontWeights, setAvailableFontWeights] = React.useState<number[]>([]);
  const [nameError, setNameError] = React.useState<Nullable<string>>(null);

  const isStyleDefined = isDefined(customStyle);
  const isModifyingCustomStyle = isEditingButtonStyle || isCreatingButtonStyle || isEditingTextStyle || isCreatingTextStyle;
  const canCreateEditStyle = isStyleDefined && isDefined(currentBlock);

  const getWeights = React.useCallback(
    (fontFamily: string): number[] => {
      const customFont = customFonts.find(font => fontFamily === font.fontFamily);
      if (isDefined(customFont)) {
        return customFont.fontWeights.map(Number);
      }

      const textStyleFont = customStyleFonts[fontFamily];
      if (isDefined(textStyleFont)) {
        return textStyleFont.fontWeights;
      }

      return [];
    },
    [customFonts],
  );

  React.useEffect(() => {
    setNameError(null);
  }, [isEditorVisible]);

  React.useEffect(() => {
    if (!isDefined(customStyle)) {
      return;
    }

    setName(customStyle.name);
  }, [customStyle, mode]);

  React.useEffect(() => {
    if (!isDefined(customStyle)) {
      return;
    }
    const { fontFamily } = customStyle.properties.default;
    setAvailableFontWeights(getWeights(fontFamily));
  }, [customStyle, getWeights]);

  const applyNewStyle = React.useCallback(
    (newStyle: Cms.ContentPartStyle) => {
      getIsButtonPartStyle(newStyle) ? dispatch(setButtonStyleEditorStyles(newStyle)) : dispatch(setTextStyleEditorStyles(newStyle));
    },
    [dispatch],
  );

  const setStyleProperty = React.useCallback<SetStyleProps>(
    property => value => {
      if (!isStyleDefined) {
        return;
      }

      const newStyle = assocPath(['properties', 'default', String(property)], value, customStyle);
      applyNewStyle(newStyle);
    },
    [isStyleDefined, customStyle, applyNewStyle],
  );

  const setFontFamily = React.useCallback(
    (value: string) => {
      if (!isStyleDefined) {
        return;
      }

      const { properties } = customStyle;
      const weights = getWeights(value);
      const isCurrentFontAvailable = weights.includes(properties.default.fontWeight);
      const fontWeight = isCurrentFontAvailable || isEmpty(weights) ? properties.default.fontWeight : weights[0];

      const newStyle = assocPath(
        ['properties', 'default'],
        {
          ...properties.default,
          fontFamily: value,
          fontWeight,
        },
        customStyle,
      );

      setAvailableFontWeights(weights);
      applyNewStyle(newStyle);
    },
    [isStyleDefined, customStyle, applyNewStyle, getWeights],
  );

  const onNameChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!isStyleDefined) {
        return;
      }

      const { value: nameValue } = event.target;
      const newStyle = Object.assign({}, customStyle, { name: nameValue });
      setName(nameValue);
      applyNewStyle(newStyle);
    },
    [isStyleDefined, customStyle, applyNewStyle],
  );

  const closeEditor = React.useCallback(() => {
    if (isModifyingCustomStyle || !isStyleDefined) {
      return;
    }

    getIsButtonPartStyle(customStyle) ? dispatch(closeButtonStyleEditor()) : dispatch(closeTextStyleEditor());
  }, [isStyleDefined, customStyle, dispatch, isModifyingCustomStyle]);

  const handleCreateCustomStyle = React.useCallback(
    ({ onNameExistsFailure }: CreateEditCustomStyleProps) => {
      if (!canCreateEditStyle || !isDefined(currentItem.index)) {
        return;
      }

      const doesNameAlreadyExist = doesStyleNameAlreadyExist(Object.values(allStyles), customStyle.name);
      if (doesNameAlreadyExist) {
        onNameExistsFailure();

        return;
      }

      getIsButtonPartStyle(customStyle) ?
        dispatch(
          createButtonStyleRequest({
            block: currentBlock,
            buttonStyle: customStyle,
            partIndex: currentItem.index,
          }),
        )
      : dispatch(
          createTextStyleRequest({
            block: currentBlock,
            partIndex: currentItem.index,
            textStyle: customStyle,
          }),
        );
    },
    [canCreateEditStyle, customStyle, currentBlock, currentItem.index, allStyles, dispatch],
  );

  const handleEditCustomStyle = React.useCallback(
    ({ onNameExistsFailure }: CreateEditCustomStyleProps) => {
      if (!canCreateEditStyle || !isDefined(currentItem.index)) {
        return;
      }

      const doesNameAlreadyExist = doesStyleNameAlreadyExist(
        Object.values(allStyles).filter(({ id }) => id !== customStyle.id),
        customStyle.name,
      );

      if (doesNameAlreadyExist) {
        onNameExistsFailure();

        return;
      }

      getIsButtonPartStyle(customStyle) ?
        dispatch(
          editButtonStyleRequest({
            buttonStyle: omit(customStyle, ['id', 'className']),
            id: customStyle.id,
          }),
        )
      : dispatch(
          editTextStyleRequest({
            id: customStyle.id,
            textStyle: omit(customStyle, ['id', 'className']),
          }),
        );
    },
    [canCreateEditStyle, customStyle, currentItem.index, allStyles, dispatch],
  );

  return {
    availableFontWeights,
    closeEditor,
    handleCreateCustomStyle,
    handleEditCustomStyle,
    isModifyingCustomStyle,
    name,
    nameError,
    onNameChange,
    setFontFamily,
    setName,
    setNameError,
    setStyleProperty,
  };
};
