import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrentBlockId, updateBlockSettings } from '../../../ducks';
import { useModalVisibility } from '../../../utils/hooks';
import { isDefined } from '../../../utils/is';
import { ContentSwitcher } from '../../various/ContentSwitcher';
import { ModalShell } from '../../various/ModalShell';

import { FileForm } from './FileForm';
import { MediaForm, MediaPayload } from './types';
import styles from './UploadMediaModal.module.scss';
import { UrlForm } from './UrlForm';

const enum MediaSource {
  URL = 'URL',
  FILE = 'FILE',
}

const MediaForms: Record<MediaSource, React.ComponentType<MediaForm>> = {
  [MediaSource.URL]: UrlForm,
  [MediaSource.FILE]: FileForm,
};

export const UploadMediaModal = () => {
  const dispatch = useDispatch();
  const [mediaSource, setMediaSource] = React.useState(MediaSource.FILE);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const currentBlock = useSelector(getCurrentBlockId);
  const { t } = useTranslation(['cms', 'common']);
  const { isModalVisible, hideModal } = useModalVisibility('UploadMediaModal');

  const MediaUploadComp = MediaForms[mediaSource];

  const handleClose = React.useCallback(() => {
    if (isSubmitting) {
      return;
    }

    hideModal();
  }, [hideModal, isSubmitting]);

  const onSubmit = React.useCallback(
    (data: MediaPayload) => {
      if (!isDefined(currentBlock)) {
        handleClose();

        return;
      }

      dispatch(
        updateBlockSettings({
          blockId: currentBlock,
          updatePath: ['background', 'general', 'media'],
          value: { original: data.original, src: data.url, type: data.type },
        }),
      );
      handleClose();
    },
    [currentBlock, dispatch, handleClose],
  );

  const options = [
    {
      title: t('common:file'),
      value: MediaSource.FILE,
    },
    {
      dataTestId: 'uploadImageFromUrl',
      title: t('common:url'),
      value: MediaSource.URL,
    },
  ];

  return (
    <ModalShell title={t('cms:add_media')} isOpen={isModalVisible} onClose={handleClose}>
      <div className={styles.uploadSourceSelect}>
        <ContentSwitcher
          tabs={options}
          groupName={t('cms:media_source')}
          selectedValue={mediaSource}
          onChange={setMediaSource}
          variant="secondary"
          size="large"
          isDisabled={isSubmitting}
        />
      </div>
      <MediaUploadComp onSubmit={onSubmit} isSubmitting={isSubmitting} setIsSubmitting={setIsSubmitting} onCancel={handleClose} />
    </ModalShell>
  );
};
