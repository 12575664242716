import { Id, Selections } from '@typings';

import { CONTENT_TYPE_HEADER_FORM_DATA } from '../constants/api';

import { showroomApi } from './api';

export const loadOrder = async (orderId: Id) => showroomApi.GET('/orders/:orderId')({ pathParams: { orderId } });

export const loadOrders = async (data: Selections.FilterData) => showroomApi.POST('/orders/filter')({ data });
export const loadOrdersQuery = async (data: Selections.FilterData) => showroomApi.QUERY_POST('/orders/filter')({ data });

export const deleteMultipleSelections = async (orders: string[]) => showroomApi.QUERY_DELETE('/orders')({ data: { orders } });

export const createSelection = async (data: Selections.CreateSelectionDTO) => showroomApi.POST('/orders')({ data });

export const editSelection = async (orderId: Id, name: string) =>
  showroomApi.POST('/PUT/orders/:orderId')({
    data: { name },
    pathParams: { orderId },
  });

export const getSelectionShareToken = async (selectionId: Id) =>
  showroomApi.QUERY_GET('/orders/:orderId/share')({
    pathParams: { orderId: selectionId },
  });

export const shareSelection = async (selectionId: Id, data: Selections.Share) =>
  showroomApi.QUERY_POST('/orders/:orderId/share')({
    data: {
      emailLinkTo: data.emails,
      linkUri: `${data.path}/`,
    },
    pathParams: {
      orderId: selectionId,
    },
  });

export const getOrderFromLink = async (orderId: Id) =>
  showroomApi.GET('/link/:orderId')({
    pathParams: { orderId },
  });

export const importSelection = async (orderId: Id, data: FormData) =>
  showroomApi.POST('/orders/:orderId/import-csv')({
    data,
    headers: {
      'Content-Type': CONTENT_TYPE_HEADER_FORM_DATA,
    },
    pathParams: { orderId },
  });

export const getSelectionImportStatus = async (orderId: Id, jobId: string) =>
  showroomApi.GET('/orders/:orderId/import-csv-poll/:jobId')({
    pathParams: { jobId, orderId },
  });
