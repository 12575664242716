import { combineEpics } from 'redux-observable';
import { from } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import {
  createSelectionSuccess,
  fetchDeliveryWindowsByBuyerIdFailure,
  fetchDeliveryWindowsByBuyerIdRequest,
  fetchDeliveryWindowsByBuyerIdSuccess,
  fetchDeliveryWindowsFailure,
  fetchDeliveryWindowsRequest,
  fetchDeliveryWindowsSuccess,
  fetchOrderSuccess,
  getIsSeller,
  getUser,
  setDeliveryWindows,
} from '../ducks';
import { getIsOrderSplit } from '../logic/Orders';
import { isEmpty } from '../utils/isEmpty';
import { toIdList } from '../utils/normalize';
import { holdWhile } from '../utils/operators/holdWhile';
import { mapResponse } from '../utils/operators/mapResponse';

const fetchAllDeliveryWindowsEpic: AppEpic = (action$, _, { deliveryWindowRepository }) =>
  action$.pipe(
    filter(isActionOf(fetchDeliveryWindowsRequest)),
    mergeMap(async () => deliveryWindowRepository.fetchAllDeliveryWindows()),
    mapResponse(
      ({ data }) => fetchDeliveryWindowsSuccess(data),
      () => fetchDeliveryWindowsFailure(),
    ),
  );

const fetchDelwinsForOrderByBuyer: AppEpic = action$ =>
  action$.pipe(
    filter(isActionOf([fetchOrderSuccess, createSelectionSuccess])),
    map(({ payload }) => {
      return getIsOrderSplit(payload) ?
          {
            buyerId: payload.orders[0].buyer,
          }
        : {
            buyerId: payload.buyer.buyer,
            orderId: payload.order.order,
          };
    }),
    map(payload => fetchDeliveryWindowsByBuyerIdRequest(payload)),
  );

const fetchAllDeliveryWindowsByBuyerEpic: AppEpic = (action$, state$, { deliveryWindowRepository }) =>
  action$.pipe(
    filter(isActionOf(fetchDeliveryWindowsByBuyerIdRequest)),
    holdWhile(state$, state => !isEmpty(getUser(state))),
    map(({ payload }) => payload),
    mergeMap(({ buyerId, orderId }) => {
      const isSeller = getIsSeller(state$.value);

      return from(
        isSeller ?
          deliveryWindowRepository.fetchBuyerAndOrderSpecificDeliveryWindows(buyerId, orderId)
        : deliveryWindowRepository.fetchOrderSpecificDeliveryWindows(orderId),
      ).pipe(
        mapResponse(
          response => [
            fetchDeliveryWindowsByBuyerIdSuccess({
              buyerId,
              ids: toIdList(response.data.deliveryWindows, 'deliveryWindow'),
            }),
            setDeliveryWindows(response.data.deliveryWindows),
          ],
          () => fetchDeliveryWindowsByBuyerIdFailure(),
        ),
      );
    }),
  );

export const deliveryWindowEpic = combineEpics(
  fetchAllDeliveryWindowsEpic,
  fetchAllDeliveryWindowsByBuyerEpic,
  fetchDelwinsForOrderByBuyer,
);
