import { Product } from '@typings';
import { useSelector } from 'react-redux';

import { getDefaultImageSize } from '../../../ducks';
import { getImageAspectRatio, getProductImageOrPlaceholderBySize } from '../../../ducks/helpers';
import { CustomImage } from '../../various/CustomImage';

import styles from './ProductName.module.scss';

export const ProductName = (props: Product.Full | Product.Standard) => {
  const imageSize = useSelector(getDefaultImageSize);
  const picture = getProductImageOrPlaceholderBySize(props, imageSize);
  const aspecRatio = useSelector(getImageAspectRatio);

  return (
    <td className={styles.productNameWrapper}>
      <div className={styles.imageWrapper}>
        <CustomImage errorStyle={styles.errorMessage} aspectRatio={aspecRatio} src={picture.src} />
      </div>
      <div className={styles.productName}>{props.name}</div>
    </td>
  );
};
