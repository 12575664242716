import { BREAKPOINTS } from './breakpoints';

export const DEFAULT_CONTENT_ALIGNMENT = {
  desktop: {
    horizontalPosition: 50,
    verticalPosition: 50,
    width: 96,
  },
};

export const CENTER_SNAP_POSITION = 50;
export const SNAP_THRESHOLD = 10;
export const GUIDELINE_TOOLTIP_SAFE_AREA = 48;

export const EMPTY_TEXT_CONTENT = '<p><br></p>';

export const CMS_SCREENS_SIZES = {
  desktop: BREAKPOINTS.XLARGE,
  mobile: BREAKPOINTS.SMALL,
  tablet: BREAKPOINTS.LARGE,
};

export const CMS_BLOCK_GAP = 30;
export const MIN_BLOCK_HEIGHT = 60;

export const DESKTOP_PRODUCTS_PER_ROW = 6;
export const TABLET_PRODUCTS_PER_ROW = 3;
export const MOBILE_PRODUCTS_PER_ROW = 2;
export const PRODUCT_DESCRIPTION_HEIGHT = 111;
export const PRODUCT_MARGIN = 67;
export const PRODUCT_PADDING = 30;

export const MAX_DESKTOP_SECTION_WIDTH = 1340;
export const TOTAL_HORIZONTAL_SECTION_PADDING = 40;
export const TOTAL_HORIZONTAL_BLOCK_PADDING = 30;

export const TOP_BAR_HEIGHT = 72;

export const FULL_WIDTH_BLOCK_SPAN = 6;

export const MAX_COLUMNS = {
  desktop: 6,
  mobile: 2,
  tablet: 3,
};

export const COLUMN_WIDTH = 96;
export const ROW_HEIGHT = 194;
export const BLOCK_SPACING = 12;

export const MAX_COLUMN_SPAN = {
  desktop: MAX_COLUMNS.desktop + 1,
  mobile: MAX_COLUMNS.mobile + 1,
  tablet: MAX_COLUMNS.tablet + 1,
};
