import { AccountMarket, Id } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { createAbsoluteUrl } from '../../../utils/createAbsoluteUrl';
import { PreviewListItem } from '../../settings/PreviewListItem/PreviewListItem';
import { Input } from '../../various/Fields/Input';
import { FieldWrapper, FormGrid } from '../../various/Form';
import { ModalSize } from '../../various/ModalElements';
import { ModalShell } from '../../various/ModalShell';

import styles from './SharePageModal.module.scss';

interface Props {
  name: string;
  slug: string;
  markets: AccountMarket[];
  isOpen: boolean;
  id: Id;
  onClose: () => void;
}

export const SharePageModal = React.memo(({ name, slug, markets, isOpen, id, onClose }: Props) => {
  const { t } = useTranslation(['common', 'cms', 'selections']);

  return (
    <ModalShell title={t('cms:get_shareable_link')} isOpen={isOpen} onClose={onClose} size={ModalSize.Medium}>
      <p>{t('cms:shareable_link_help')}</p>
      <FormGrid>
        <FieldWrapper as="span" label={t('common:page_one')}>
          <div className={styles.pagePreviewItem}>
            <PreviewListItem name={name} slug={slug} markets={markets} id={id} />
          </div>
        </FieldWrapper>
        <FieldWrapper label={t('selections:shareable_link')}>
          <Input value={createAbsoluteUrl(['pages', slug])} size="large" isReadonly isCopyable onFocus={event => event.target.select()} />
        </FieldWrapper>
      </FormGrid>
    </ModalShell>
  );
});
