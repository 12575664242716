import { DeliveryWindow } from '@typings/index';

import { isDefined } from '../../utils/is';

const STOCK_TYPES = ['stock', 'preorder'];

export const getIsValidStockType = (stockTypeCandidate: unknown): stockTypeCandidate is DeliveryWindow.StockType => {
  return typeof stockTypeCandidate === 'string' && STOCK_TYPES.includes(stockTypeCandidate);
};

export const selectValidStockTypeForBuyer = (
  defaultStockType: DeliveryWindow.StockType | undefined,
  validStockTypes: DeliveryWindow.StockType[],
) => {
  const isDefaultStockTypeValid = isDefined(defaultStockType) && validStockTypes.includes(defaultStockType);

  return isDefaultStockTypeValid ? defaultStockType : validStockTypes[0] ?? defaultStockType;
};
