import { Settings } from '@typings';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { fetchConfigurationRequest, getBackgroundImage, getDarkLogoImage, getIsStockTypeSeparationEnabled } from '../../../ducks';
import { getImageFromProps } from '../../../ducks/helpers';
import { useEditCustomisationSettings } from '../../../services/hooks/settings/useEditCustomisationSettings';
import { useSettings } from '../../../services/hooks/settings/useSettings';
import { isEmpty } from '../../../utils/isEmpty';
import { Form } from '../../various/Form';
import Heading from '../../various/Heading';
import { SectionFormHeading } from '../../various/SectionHeading';
import { addToast } from '../../various/Toasts';

import { BrandElementsFields } from './BrandElementsFields';
import styles from './CustomisationSection.module.scss';
import { CustomTextsFields } from './CustomTextsFields';

export const CustomisationSection = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['settings']);

  const defaultBackgroundImage = useSelector(getBackgroundImage);
  const defaultLogoImage = useSelector(getDarkLogoImage);
  const isStockSeparationEnabled = useSelector(getIsStockTypeSeparationEnabled);

  const [isEditing, setIsEditing] = React.useState(false);

  const {
    data: { allProductsViewLayout, companyName, loginPageMessage, soldOutLabel, stockTypeSeparation } = {},
    isPending: isLoadingSettings,
  } = useSettings();
  const { mutate: editCustomisationSettings, isPending: isSavingChanges } = useEditCustomisationSettings();

  const defaultValues = React.useMemo(
    () => ({
      allProductsViewLayout,
      backgroundImage: getImageFromProps(defaultBackgroundImage, 'backgroundImage'),
      companyName,
      loginPageMessage,
      logoImage: getImageFromProps(defaultLogoImage, 'logoImageDark'),
      soldOutLabel,
      ...(isStockSeparationEnabled && {
        preorderStockTypeLabel: stockTypeSeparation?.stockTypeLabels.preorder,
        stockTypeLabel: stockTypeSeparation?.stockTypeLabels.stock,
      }),
    }),
    [
      allProductsViewLayout,
      companyName,
      defaultBackgroundImage,
      defaultLogoImage,
      isStockSeparationEnabled,
      loginPageMessage,
      soldOutLabel,
      stockTypeSeparation,
    ],
  );

  const formMethods = useForm<Settings.CustomisationFormFields>({
    defaultValues,
  });

  React.useEffect(() => {
    formMethods.reset(defaultValues);
  }, [defaultValues, formMethods]);

  const handleSubmit = async (values: Settings.CustomisationFormFields) => {
    editCustomisationSettings([defaultValues, values], {
      onError: () => {
        addToast(t('settings:customisation_update_fail'));
      },
      onSuccess: ({ errors }) => {
        dispatch(fetchConfigurationRequest({ ignoreCache: true }));

        if (!isEmpty(errors)) {
          return errors.forEach(message => addToast(message));
        }

        addToast(t('settings:customisation_updated'));
        setIsEditing(false);
      },
    });
  };

  const handleCancel = () => {
    setIsEditing(false);
    formMethods.reset(defaultValues);
  };

  return (
    <section>
      <Form formMethods={formMethods} onSubmit={handleSubmit}>
        <SectionFormHeading
          heading={t('settings:customisation')}
          isLoading={isSavingChanges}
          isEditing={isEditing}
          isDisabled={isLoadingSettings}
          onCancel={handleCancel}
          onEdit={() => setIsEditing(true)}
        />
        <section className={styles.group}>
          <Heading title={t('settings:brand_elements')} variant={['h3', 'black']} />
          <BrandElementsFields isDisabled={!isEditing || isSavingChanges} isLoading={isLoadingSettings} />
        </section>
        <section className={styles.group}>
          <Heading title={t('settings:custom_text')} variant={['h3', 'black']} />
          <CustomTextsFields isDisabled={!isEditing || isSavingChanges} isLoading={isLoadingSettings} />
        </section>
      </Form>
    </section>
  );
};
