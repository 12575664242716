import { Cms, CmsBlocks } from '@typings';
import cx from 'classnames';
import { times } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getSectionIdByGroupId, setCurrentBlock, setCurrentSection } from '../../../ducks';
import { getScreenRelativeStyles } from '../../../logic/pages';
import { useModalVisibility, useSpecifiedDeviceWidth } from '../../../utils/hooks';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { HoverTools } from '../../cms/hoverTools';
import hoverToolsStyles from '../../cms/hoverTools/HoverTools.module.scss';
import { IconType } from '../../various/Icon';

import styles from './productBlock.module.scss';

export const ProductBlockTools = ({ parameters, rowHeight, isManualLayoutEditing }: CmsBlocks.BlockProps<Cms.ProductBlock>) => {
  const { products } = parameters.settings;
  const dispatch = useDispatch();
  const { showModal } = useModalVisibility('AddProductsModal');
  const screenWidth = useSpecifiedDeviceWidth();
  const { spanX } = getScreenRelativeStyles(screenWidth, parameters.position);
  const isBlockEmpty = isEmpty(products);
  const productsCount = parameters.position.desktop.spanX * parameters.position.desktop.spanY;
  const sectionId = useSelector(getSectionIdByGroupId(parameters.groupId));
  const { t } = useTranslation(['cms']);

  const handleSetCurrentBlock = React.useCallback(() => {
    if (isDefined(sectionId)) {
      dispatch(setCurrentSection(sectionId));
    }

    dispatch(setCurrentBlock(parameters.id));
    showModal();
  }, [parameters.id, sectionId]);

  const hoverToolsClassNames = cx(hoverToolsStyles.product, {
    [styles.solidBorder]: isManualLayoutEditing,
  });

  return (
    <>
      {isBlockEmpty && (
        <div
          className={styles.placeholder}
          style={{
            gridAutoColumns: 'auto',
            gridTemplateColumns: `repeat(${spanX}, 1fr)`,
          }}
        >
          {times(
            idx => (
              <div key={idx} style={{ height: rowHeight }} />
            ),
            productsCount,
          )}
        </div>
      )}
      <HoverTools
        name={t('cms:product_block_one')}
        className={hoverToolsClassNames}
        elements={[
          {
            icon: IconType.Edit,
            key: 'chooseProducts',
            label: t('cms:choose_products'),
            onClick: handleSetCurrentBlock,
            testId: 'addProducts',
          },
        ]}
      />
    </>
  );
};
