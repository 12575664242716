import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { handleSpaceAndEnter } from '../../../../utils/handleEnterKey';
import Icon, { IconType } from '../../Icon';

import styles from './Input.module.scss';

interface Props {
  isVisible?: boolean;
  onClick?: () => void;
}

export const InputClearButton = ({ isVisible = false, onClick }: Props) => {
  const { t } = useTranslation(['common']);

  return (
    <div
      role="button"
      tabIndex={0}
      aria-label={t('common:clear')}
      className={cx(styles.button, { [styles.hidden]: !isVisible })}
      onKeyDown={handleSpaceAndEnter(onClick)}
      onClick={onClick}
    >
      <Icon type={IconType.Close} size={16} />
    </div>
  );
};
