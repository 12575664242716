import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { fetchSettings } from '../../settingsRepository';

export const SETTINGS_QUERY_KEY = 'settings';

export const useSettings = () => {
  const { t } = useTranslation(['settings']);

  return useQuery({
    meta: {
      errorMessage: t('settings:settings_load_failure'),
    },
    queryFn: fetchSettings,
    queryKey: [SETTINGS_QUERY_KEY],
    refetchOnMount: false,
  });
};
