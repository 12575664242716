import { LegendItem as LegendItemType } from '@typings';
import cx from 'classnames';

import { isDefined } from '../../../../utils/is';
import { isEmpty } from '../../../../utils/isEmpty';

import styles from './EtaLegend.module.scss';
import { EtaLegendItem } from './EtaLegendItem';

interface DefaultProps<T> {
  items: LegendItemType<T>[];
  label?: string;
  vertical?: boolean;
  className?: string;
}

interface NotHoverableProps<T> extends DefaultProps<T> {
  isHoverable?: false;
  onHover?: undefined;
}

interface HoverableProps<T> extends DefaultProps<T> {
  isHoverable: true;
  onHover: (value: Maybe<T>) => void;
}

type Props<T> = NotHoverableProps<T> | HoverableProps<T>;

export const EtaLegend = <T extends string>({ className, isHoverable, label, items, onHover, vertical = false }: Props<T>) => {
  const handleHover = (value: Maybe<T>) => () => {
    onHover?.(value);
  };

  const classNames = cx(styles.items, className, {
    [styles.vertical]: vertical,
  });

  if (isEmpty(items)) {
    return null;
  }

  return (
    <div className={classNames}>
      {isDefined(label) && <div className={styles.label}>{label}</div>}
      {items.map(({ color, label: itemLabel, value }) => (
        <EtaLegendItem
          key={color}
          label={itemLabel}
          color={color}
          isHoverable={isHoverable}
          onMouseEnter={handleHover(value)}
          onMouseLeave={handleHover(null)}
        />
      ))}
    </div>
  );
};
