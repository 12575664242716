import { LoadData } from '../loaders/LoadData/LoadData';

interface Props<T> {
  items: T[];
  allLoaded: boolean;
  isLoading: boolean;
  loaderMessage: string;
  children(item: T, idx?: number): JSX.Element;
  onLoadMore(): void;
  rootMargin?: string;
  className?: string;
}

export const LazyList = <T,>(props: Props<T>) => {
  const { className, items, allLoaded, loaderMessage, isLoading, onLoadMore, rootMargin, children } = props;

  return (
    <>
      <div className={className}>{items.map(children)}</div>

      {!allLoaded && <LoadData title={loaderMessage} isLoading={isLoading} onLoadMore={onLoadMore} rootMargin={rootMargin} />}
    </>
  );
};
