import { Cms } from '@typings';
import i18next from 'i18next';
import { replace } from 'redux-first-history';
import { combineEpics } from 'redux-observable';
import { EMPTY, from, of } from 'rxjs';
import { catchError, exhaustMap, filter, map, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { addToast } from '../components/various/Toasts';
import {
  discardChangesRequest,
  fetchEditorPageDataFailure,
  fetchEditorPageDataRequest,
  fetchEditorPageDataSuccess,
  fetchPageBySlugRequest,
  fetchPageBySlugSuccess,
  getAvailablePageLanguageCodes,
  getButtonStyles,
  getBuyerIdFromCurrentOrder,
  getCmsBlocks,
  getCmsGroups,
  getCmsSections,
  getIsSeller,
  getModifiedLanguages,
  getOrderId,
  getPageMetaData,
  getTextStyles,
  publishPageChangeFailure,
  publishPageChangeSuccess,
  publishPageRequest,
  refreshPageDataRequest,
  refreshPageDataSuccess,
  savePageFailure,
  savePageRequest,
  savePageSuccess,
  unpublishPageRequest,
  validateSectionsMarkets,
} from '../ducks';
import { getAllCmsLocalizations } from '../ducks/cms/localizations';
import { getUsedCustomStyleIds } from '../logic/cms/styles';
import { buildSaveObject, composeStatusUpdate, getIsPublished } from '../logic/pages';
import { compiledPaths } from '../paths';
import { PAGES_QUERY_KEY } from '../services/hooks/pages/usePages';
import { queryClient } from '../services/queryClient';
import { isDefined } from '../utils/is';
import { mapResponse } from '../utils/operators/mapResponse';

const gatherPageSaveData = (store: Store) => ({
  blocks: getCmsBlocks(store),
  groups: getCmsGroups(store),
  localizations: getAllCmsLocalizations(store),
  modifiedLanguages: getModifiedLanguages(store),
  pageLanguageCodes: getAvailablePageLanguageCodes(store),
  sections: getCmsSections(store),
});

const fetchPageBySlugEpic: AppEpic = (action$, store$, { pagesRepository }) =>
  action$.pipe(
    filter(isActionOf(fetchPageBySlugRequest)),
    mergeMap(action => {
      const store = store$.value;
      const buyerId = getIsSeller(store) ? getBuyerIdFromCurrentOrder(store) : undefined;

      return from(pagesRepository.getPageBySlug(action.payload, buyerId)).pipe(
        map(data => fetchPageBySlugSuccess(data)),
        catchError(() => {
          const order = getOrderId(store$.value);

          return of(replace({ pathname: compiledPaths.PRODUCTS_ORDER({ id: order }) }));
        }),
      );
    }),
  );

const fetchEditorPageEpic: AppEpic = (action$, _, { pagesRepository }) =>
  action$.pipe(
    filter(isActionOf(fetchEditorPageDataRequest)),
    map(action => action.payload),
    mergeMap(id =>
      from(pagesRepository.getFullPageData(id)).pipe(
        mergeMap(data => {
          const pageMarketIds = data.markets.map(market => market.id);

          return [fetchEditorPageDataSuccess({ data }), validateSectionsMarkets({ pageMarketIds })];
        }),
        catchError(() => of(fetchEditorPageDataFailure(), replace({ pathname: compiledPaths.PAGE_NOT_FOUND({}) }))),
      ),
    ),
  );

const refreshPageDataEpic: AppEpic = (action$, store$, { pagesRepository }) =>
  action$.pipe(
    filter(isActionOf(refreshPageDataRequest)),
    map(() => store$.value),
    map(getPageMetaData),
    mergeMap(pageData => (isDefined(pageData) ? of(pageData) : EMPTY)),
    mergeMap(pageData =>
      from(pagesRepository.getFullPageData(pageData.id)).pipe(
        map(response => refreshPageDataSuccess(response)),
        catchError(() => of(replace({ pathname: compiledPaths.PAGE_NOT_FOUND({}) }))),
      ),
    ),
  );

const savePageEpic: AppEpic = (action$, store$, { pagesRepository }) => {
  return action$.pipe(
    filter(isActionOf(savePageRequest)),
    map(action => action.payload),
    mergeMap(({ id }) =>
      from(pagesRepository.editPageSections(id, buildSaveObject(gatherPageSaveData(store$.value)))).pipe(
        mapResponse(
          () => {
            const pageMeta = getPageMetaData(store$.value) as Cms.Page;
            const isPublished = getIsPublished(pageMeta.status);

            const messageHint = isPublished ? ` ${i18next.t('cms:publish_changes_hint')}` : '';
            const message = `${i18next.t('cms:page_saved')}${messageHint}`;

            addToast(message);

            return [savePageSuccess(), refreshPageDataRequest()];
          },
          () => {
            addToast(i18next.t('cms:save_page_fail'));

            return savePageFailure();
          },
        ),
      ),
    ),
  );
};

const mapPublishError = (error: Responses.Errors | string): string => {
  if (typeof error !== 'string' && typeof error.errors !== 'string' && isDefined(error.errors.page)) {
    return error.errors.page;
  }

  return i18next.t('common:please_try_again_later');
};

const publishPageEpic: AppEpic = (action$, store$, { pagesRepository }) =>
  action$.pipe(
    filter(isActionOf(publishPageRequest)),
    map(action => action.payload),
    map(({ id, languages }) => ({
      customStyleIds: getUsedCustomStyleIds(getCmsBlocks(store$.value), {
        ...getTextStyles(store$.value),
        ...getButtonStyles(store$.value),
      }),
      data: buildSaveObject(gatherPageSaveData(store$.value)),
      id,
      languages,
    })),
    exhaustMap(({ id, data, customStyleIds, languages }) =>
      from(pagesRepository.editPageSections(id, data)).pipe(
        mapResponse(
          () =>
            from(pagesRepository.setPageStatus(composeStatusUpdate(languages, 'published'), customStyleIds)(id)).pipe(
              mapResponse(
                () => {
                  const pageMeta = getPageMetaData(store$.value) as Cms.Page;
                  const isPublished = getIsPublished(pageMeta.status);

                  isPublished ? addToast(i18next.t('cms:page_updated')) : addToast(i18next.t('cms:page_published'));

                  return [publishPageChangeSuccess(), refreshPageDataRequest()];
                },
                error => {
                  addToast(i18next.t('cms:publish_page_fail'), {
                    description: mapPublishError(error.data),
                  });

                  return publishPageChangeFailure();
                },
              ),
            ),
          () => {
            addToast(i18next.t('cms:save_page_fail'));

            return publishPageChangeFailure();
          },
        ),
      ),
    ),
  );

const unpublishPageEpic: AppEpic = (action$, _, { pagesRepository }) =>
  action$.pipe(
    filter(isActionOf(unpublishPageRequest)),
    map(action => action.payload),
    mergeMap(async ({ languages, id }) => pagesRepository.setPageStatus(composeStatusUpdate(languages, 'unpublished'))(id)),
    mapResponse(
      () => {
        addToast(i18next.t('cms:page_unpublished'));
        queryClient.invalidateQueries({ queryKey: [PAGES_QUERY_KEY] });

        return [publishPageChangeSuccess(), refreshPageDataRequest()];
      },
      () => {
        addToast(i18next.t('cms:unpublish_page_fail'));

        return publishPageChangeFailure();
      },
    ),
  );

const discardChangesEpic: AppEpic = (action$, _, { pagesRepository }) =>
  action$.pipe(
    filter(isActionOf(discardChangesRequest)),
    map(action => action.payload),
    mergeMap(async ({ id, language }) => pagesRepository.discardChanges(id, language)),
    mapResponse(
      () => {
        addToast(i18next.t('cms:changes_discarded'));

        return [refreshPageDataRequest()];
      },
      () => {
        addToast(i18next.t('cms:discard_changes_fail'));

        return [];
      },
    ),
  );

export const pagesEpic = combineEpics(
  fetchPageBySlugEpic,
  savePageEpic,
  publishPageEpic,
  fetchEditorPageEpic,
  refreshPageDataEpic,
  unpublishPageEpic,
  discardChangesEpic,
);
