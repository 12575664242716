import { Alerts } from '@typings';
import cx from 'classnames';
import { ReactNode } from 'react';

import { isDefined } from '../../../utils/is';
import Icon, { IconType } from '../../various/Icon';

import styles from './Alert.module.scss';

interface IconData {
  iconTypeMap: IconType;
  iconClassName: string;
}

interface Props {
  type?: Alerts.AlertType;
  message: ReactNode;
  className?: string;
  icon?: IconType;
  displayOverContent?: boolean;
  dataTestId?: string;
  noRoundCorners?: boolean;
}

const iconData: Record<Alerts.AlertType, IconData> = {
  error: { iconClassName: styles.alertError, iconTypeMap: IconType.Error },
  info: { iconClassName: styles.alertInfo, iconTypeMap: IconType.Info },
  success: {
    iconClassName: styles.alertSuccess,
    iconTypeMap: IconType.Success,
  },
  translationWarning: {
    iconClassName: styles.alertWarning,
    iconTypeMap: IconType.TranslationWarning,
  },
  warning: {
    iconClassName: styles.alertWarning,
    iconTypeMap: IconType.Warning,
  },
};

export const Alert = ({
  message,
  type = 'warning',
  className,
  icon,
  dataTestId,
  displayOverContent = false,
  noRoundCorners = false,
}: Props) => {
  const { iconClassName, iconTypeMap } = iconData[type];
  const iconType = isDefined(icon) ? icon : iconTypeMap;

  const additionalClassNames = cx(
    {
      [styles.overContent]: displayOverContent,
      [styles.noRoundCorners]: displayOverContent || noRoundCorners,
    },
    iconClassName,
    className,
  );

  return (
    <div className={cx(styles.alert, additionalClassNames)} data-testid={dataTestId}>
      <div className={cx(styles.alertIcon)}>
        <Icon type={iconType} />
      </div>
      <span className={styles.alertMessage}>{message}</span>
    </div>
  );
};
