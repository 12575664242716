import { Cms } from '@typings';

import { CENTER_SNAP_POSITION, SNAP_THRESHOLD } from '../../constants/cms';
import { MAX_PERCENT } from '../../constants/limits';
import { clamp } from '../../utils/clamp';
import { getSafeInnerHTML } from '../../utils/getSafeInnerHtml';
import { MovementOffset } from '../../utils/hooks';
import { isDefined } from '../../utils/is';
import { isEmpty } from '../../utils/isEmpty';
import { roundValueToQuarter } from '../../utils/roundValueToQuarter';

import { isButtonPart } from './styles';

const MIN_WIDTH_IN_PERCENTAGE = 17;

export interface ContentPosition {
  top: number;
  left: number;
  width: number;
}

interface ContentDimensions {
  height: number;
  width: number;
}

const getRoundedFloatValue = (value: number) => Math.round(value * MAX_PERCENT) / MAX_PERCENT;

export const getPixelsToPercentageRatio = (parentHeight: number, parentWidth: number) => ({
  ratioX: parentWidth / MAX_PERCENT,
  ratioY: parentHeight / MAX_PERCENT,
});

export const getOffsetParentSize = (element: Nullable<HTMLElement>) => {
  if (!isDefined(element) || !isDefined(element.offsetParent)) {
    return { height: 0, width: 0 };
  }

  return {
    height: (element.offsetParent as HTMLElement).offsetHeight,
    width: (element.offsetParent as HTMLElement).offsetWidth,
  };
};

interface CalculateMovementPositionProps extends MovementOffset {
  dimensions: ContentDimensions;
  parentDimensions: ContentDimensions;
  currentPosition: ContentPosition;
  snapDirection?: 'horizontal' | 'vertical' | null;
}

export const calculateMovementPosition = ({
  dimensions,
  currentPosition,
  parentDimensions,
  xOffset,
  yOffset,
  snapDirection,
}: CalculateMovementPositionProps) => {
  const { height: parentHeight, width: parentWidth } = parentDimensions;
  const { height, width } = dimensions;
  const { ratioX, ratioY } = getPixelsToPercentageRatio(parentHeight, parentWidth);

  const y = snapDirection === 'vertical' ? 0 : yOffset;
  const x = snapDirection === 'horizontal' ? 0 : xOffset;

  const top = Math.round(currentPosition.top * ratioY + y) / ratioY;
  const left = Math.round(currentPosition.left * ratioX + x) / ratioX;

  const minLeft = width / (2 * ratioX);
  const maxLeft = MAX_PERCENT - minLeft;
  const adjustedLeft = getRoundedFloatValue(clamp(minLeft, maxLeft)(left));

  const minTop = height / (2 * ratioY);
  const maxTop = MAX_PERCENT - minTop;
  const adjustedTop = getRoundedFloatValue(clamp(minTop, maxTop)(top));

  const shouldSnapToHorizontalCenter = Math.abs(parentWidth / 2 - (currentPosition.left * ratioX + x)) < SNAP_THRESHOLD;
  const shouldSnapToVerticalCenter = Math.abs(parentHeight / 2 - (currentPosition.top * ratioY + y)) < SNAP_THRESHOLD;

  return {
    ...currentPosition,
    left: shouldSnapToHorizontalCenter ? CENTER_SNAP_POSITION : adjustedLeft,
    top: shouldSnapToVerticalCenter ? CENTER_SNAP_POSITION : adjustedTop,
  };
};

interface CalculateResizePositionProps {
  dimensions: ContentDimensions;
  parentDimensions: ContentDimensions;
  currentPosition: ContentPosition;
  initialPosition: ContentPosition;
  xOffset: number;
}

export const calculateResizePosition = ({
  dimensions,
  currentPosition,
  initialPosition,
  parentDimensions,
  xOffset,
}: CalculateResizePositionProps) => {
  const { width: parentWidth } = parentDimensions;
  const { width: elementWidth } = dimensions;

  const offsetLeft = parentWidth * (currentPosition.left / MAX_PERCENT) - elementWidth / 2;
  const offsetRight = parentWidth - offsetLeft - elementWidth;
  const smallestOffset = Math.min(offsetLeft, offsetRight);

  const step = Math.min(xOffset, smallestOffset) * 2 + Math.max(0, xOffset - smallestOffset);
  const isOverflowing = xOffset > smallestOffset;

  const width = roundValueToQuarter(
    clamp(MIN_WIDTH_IN_PERCENTAGE, MAX_PERCENT)(initialPosition.width + (step / parentWidth) * MAX_PERCENT),
  );

  const minLeft = width / 2;
  const maxLeft = MAX_PERCENT - width / 2;
  const adjustedLeft = offsetLeft < offsetRight ? minLeft : maxLeft;

  return {
    ...currentPosition,
    ...(isOverflowing && { left: adjustedLeft }),
    width,
  };
};

export const splitParagraphs = (content: string) => {
  const newLineRegExp = /\r\n|\r|\n/g;
  const paragraphRegExp = /<p>(.*?)<\/p>/g;

  return getSafeInnerHTML(content.replace(newLineRegExp, ''))
    .__html.split(paragraphRegExp)
    .filter(paragraph => !isEmpty(paragraph));
};

export const getHasButtons = (text: Cms.ContentBlockText) => !isEmpty(text) && Object.values(text.parts).some(part => isButtonPart(part));

export const getHasText = (text: Cms.ContentBlockText) => !isEmpty(text) && Object.values(text.parts).some(part => !isButtonPart(part));

export const getContentPartsIds = (parts: Maybe<Cms.ContentBlockPartRecord>, condition: (candidate: Cms.ContentBlockPart) => boolean) => {
  if (!isDefined(parts)) {
    return [];
  }

  return Object.entries(parts)
    .map(([key, part]) => {
      return condition(part) ? key : undefined;
    })
    .filter(isDefined);
};
