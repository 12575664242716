import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './ProductsList.module.scss';

interface Props {
  id: string | null;
  action: JSX.Element | null;
  actionAlwaysVisible?: boolean;
}

export const MissingProduct = ({ id, action, actionAlwaysVisible = false }: Props) => {
  const { t } = useTranslation(['cms']);

  return (
    <div className={styles.missingProduct}>
      <p>
        {t('cms:missing_product_with_id', { id })}
        <span>{t('cms:product_no_longer_available')}</span>
      </p>
      <div
        className={cx(styles.action, {
          [styles.actionVisible]: actionAlwaysVisible,
        })}
      >
        {action}
      </div>
    </div>
  );
};
