import { Cms } from '@typings';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getButtonStyles, getCmsBlocks, getIsCustomStylesLoading, getPageMetaData, getTextStyles } from '../../../ducks';
import { extractMissingCustomStyles, getIsMissingButtonStyles, getIsMissingTextStyles } from '../../../logic/cms/styles';
import { isDefined } from '../../is';
import { isEmpty } from '../../isEmpty';

export const useCustomStyles = () => {
  const params = useParams<{ pageId: string }>();

  const [missingStyles, setMissingStyles] = React.useState<Cms.MissingStyles[]>([]);

  const isCustomStylesLoading = useSelector(getIsCustomStylesLoading);
  const pageMeta = useSelector(getPageMetaData);
  const blocks = useSelector(getCmsBlocks);
  const textStyles = useSelector(getTextStyles);
  const buttonStyles = useSelector(getButtonStyles);
  const allStyles = React.useMemo(() => ({ ...buttonStyles, ...textStyles }), [buttonStyles, textStyles]);

  const isTextStyleMissing = React.useMemo(() => getIsMissingTextStyles(missingStyles), [missingStyles]);
  const isButtonStyleMissing = React.useMemo(() => getIsMissingButtonStyles(missingStyles), [missingStyles]);
  const isOnlyTextStylesMissing = isTextStyleMissing && !isButtonStyleMissing;
  const isOnlyButtonStylesMissing = isButtonStyleMissing && !isTextStyleMissing;

  React.useEffect(() => {
    if (isCustomStylesLoading || !isDefined(pageMeta) || pageMeta.id !== params.pageId) {
      return;
    }

    const missingCustomStyles = extractMissingCustomStyles(blocks, allStyles);
    if (!isEmpty(missingCustomStyles)) {
      setMissingStyles(missingCustomStyles);
    }
  }, [blocks, allStyles, isCustomStylesLoading, pageMeta, params.pageId]);

  return {
    allStyles,
    buttonStyles,
    isButtonStyleMissing,
    isOnlyButtonStylesMissing,
    isOnlyTextStylesMissing,
    isTextStyleMissing,
    missingStyles,
    textStyles,
  };
};
