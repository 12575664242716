import { skipToken, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { isDefined } from '../../../utils/is';
import { getInvoiceDetails } from '../../invoicesRepository';

interface Props {
  invoiceHash?: string;
  invoiceId?: string;
}
export const useInvoiceDetails = ({ invoiceHash, invoiceId }: Props) => {
  const { t } = useTranslation(['invoices']);

  return useQuery({
    meta: {
      errorMessage: t('invoices:invoice_details_fetch_fail'),
    },
    queryFn: isDefined(invoiceHash) && isDefined(invoiceId) ? async () => getInvoiceDetails({ invoiceHash, invoiceId }) : skipToken,
    queryKey: ['invoice', { invoiceHash, invoiceId }],
    refetchOnWindowFocus: true,
  });
};
