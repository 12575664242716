import { Product } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useMatrixPrepackContext, useMatrixSettingsContext } from '../../../../products/ProductMatrix/context';
import { Switch } from '../../../Fields/Switch';

import styles from './PrepackSwitch.module.scss';

interface Props {
  isDisabled?: boolean;
  variantId: Product.Id;
  isFocusTarget?: boolean;
}

export const PrepackSwitch = React.forwardRef<HTMLLabelElement | HTMLInputElement, Props>(
  ({ isDisabled, variantId, isFocusTarget }, ref) => {
    const { t } = useTranslation(['common', 'orders']);
    const { isMobile } = useMatrixSettingsContext();
    const { setPrepacksState, activePrepacks } = useMatrixPrepackContext();

    const isChecked = activePrepacks[variantId];

    const handleSwitch = (isActive: boolean) => {
      setPrepacksState(variantId, isActive);
    };

    const labelProps = isDisabled && !isMobile ? { ref: ref as React.RefObject<HTMLLabelElement> } : {};

    const inputProps = !isDisabled && !isMobile ? { ref: ref as React.RefObject<HTMLInputElement> } : {};

    return (
      <label className={cx(styles.label, { [styles.disabled]: isDisabled })} {...labelProps}>
        {t('common:prepack')}
        <Switch
          onValueChange={handleSwitch}
          checked={isChecked}
          disabled={isDisabled}
          label={t('orders:toggle_prepack')}
          dataTestId={`matrixPrepackSwitch-${variantId}`}
          data-focus-target={isFocusTarget}
          hasFocusVisible={false}
          {...inputProps}
        />
      </label>
    );
  },
);
