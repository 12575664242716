import { Cms } from '@centra';
import cx from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getCurrentItemInBlock,
  getIsCurrentBlock,
  getIsTextStyleEditorVisibleInBlock,
  getTextStyleElementStylesInBlock,
  setCurrentBlock,
  setCurrentItem,
} from '../../../../../../ducks';
import { calculateFontSize } from '../../../../../../logic/calculateFontSize';
import { getScreenRelativeStyles } from '../../../../../../logic/pages';
import { useToolbarVisibility } from '../../../../../../utils/hooks';
import { isDefined } from '../../../../../../utils/is';
import { ContentEditor } from '../../../../../cms/ContentEditor';
import { useContentSetContext } from '../../../../../cms/context/ContentSetContext';
import { EditorLanguageContext } from '../../../../../cms/context/EditorLanguageContext';
import styles from '../../../contentBlock.module.scss';
import { ContentPartMover } from '../../ContentPartMover';

import { TextLinkMarkerPlugin } from './TextLinkMarkerPlugin';

const TOOLBAR_TYPE = 'Text';

export const EditorTextPart = ({ screenWidth, parameters, index, blockId }: Cms.TextLineProps) => {
  const { isDefaultLanguage } = React.useContext(EditorLanguageContext);
  const { setActivePartRef } = useContentSetContext();
  const dispatch = useDispatch();
  const isCurrentBlock = useSelector(getIsCurrentBlock(blockId));
  const currentItemIndex = useSelector(getCurrentItemInBlock(blockId))?.index;
  const { content, textStyle, textColor, spacing } = parameters.general;
  const containerRef = React.useRef<HTMLParagraphElement | null>(null);
  const { isToolbarVisible, showToolbar } = useToolbarVisibility(TOOLBAR_TYPE);
  const isTextStyleEditorVisible = useSelector(getIsTextStyleEditorVisibleInBlock(blockId));
  const textStyleElementStyles = useSelector(getTextStyleElementStylesInBlock(blockId));

  const isActiveTextPart = isDefined(index) && index === currentItemIndex && isCurrentBlock;
  const isTextToolbarVisible = isActiveTextPart && isToolbarVisible;
  const isTextStyleEditorEnabled = isActiveTextPart && isTextStyleEditorVisible;

  const screenRelative = getScreenRelativeStyles(screenWidth, parameters);
  const style = React.useMemo(
    () => ({
      color: textColor,
      fontSize: calculateFontSize(parameters, screenWidth),
      marginBottom: spacing,
      textAlign: screenRelative.textAlignment,
      ...(isActiveTextPart && isDefined(textStyleElementStyles) ? textStyleElementStyles.properties.default : {}),
    }),
    [textColor, parameters, screenWidth, spacing, screenRelative.textAlignment, isActiveTextPart, textStyleElementStyles],
  );

  const onFocus = React.useCallback(() => {
    if (!isDefined(containerRef.current) || isActiveTextPart) {
      return;
    }

    if (isDefined(blockId)) {
      dispatch(setCurrentBlock(blockId));
    }

    dispatch(setCurrentItem({ element: containerRef, index }));
    showToolbar();
  }, [blockId, dispatch, index, isActiveTextPart, showToolbar]);

  const onMouseDown = React.useCallback((event: React.MouseEvent) => event.nativeEvent.stopImmediatePropagation(), []);
  const onTab = React.useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      isTextStyleEditorEnabled && event.preventDefault();

      return true;
    },
    [isTextStyleEditorEnabled],
  );

  const classNames = React.useMemo(
    () =>
      cx(styles.textLine, textStyle, styles.frame, {
        [styles.selected]: isTextToolbarVisible || isTextStyleEditorEnabled,
        [styles.highlightedTextPart]: isTextStyleEditorEnabled,
      }),
    [textStyle, isTextToolbarVisible, isTextStyleEditorEnabled],
  );

  React.useLayoutEffect(() => {
    if (isTextStyleEditorEnabled) {
      setActivePartRef(containerRef);
    }

    return () => {
      setActivePartRef({ current: null });
    };
  }, [isTextStyleEditorEnabled, setActivePartRef]);

  return (
    <div role="button" tabIndex={0} ref={containerRef} className={classNames} style={style} onMouseDown={onMouseDown}>
      <ContentEditor namespace={index.toString()} onFocus={onFocus} content={content} onTab={onTab} contentType="text">
        {isTextToolbarVisible && <TextLinkMarkerPlugin />}
      </ContentEditor>
      {isTextToolbarVisible && isDefaultLanguage && (
        <ContentPartMover index={currentItemIndex} blockId={blockId} containerRef={containerRef} />
      )}
    </div>
  );
};
