import DOMPurify from 'dompurify';

import { ALLOWED_ATTRS, ALLOWED_TAGS } from '../constants/limits';

interface SafeInnerHTML {
  __html: string;
}

export function getSafeInnerHTML(
  text: string,
  allowedTags: string[] = ALLOWED_TAGS,
  allowedAttrs: string[] = ALLOWED_ATTRS,
): SafeInnerHTML {
  return {
    __html: DOMPurify.sanitize(text, {
      ALLOWED_ATTR: allowedAttrs,
      ALLOWED_TAGS: allowedTags,
    }),
  };
}
