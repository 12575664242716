import { ActionHandler, Navigation } from '@typings';
import { createReducer } from 'typesafe-actions';

import { update } from '../../../utils/update';
import { resetCms } from '../general';

import { fetchCmsNavigationFailure, fetchCmsNavigationRequest, fetchCmsNavigationSuccess } from './actions';

interface State {
  hasFailed: boolean;
  isLoading: boolean;
  items: Navigation.AnyMenuItem[];
}

const initialState: State = {
  hasFailed: false,
  isLoading: false,
  items: [],
};

const handleResetNavigation: ActionHandler<State, typeof resetCms> = () => initialState;

const handleFetchCmsNavigationRequest: ActionHandler<State, typeof fetchCmsNavigationRequest> = state =>
  update(state, {
    hasFailed: false,
    isLoading: true,
  });

const handleFetchCmsNavigationSuccess: ActionHandler<State, typeof fetchCmsNavigationSuccess> = (state, action) =>
  update(state, {
    hasFailed: false,
    isLoading: false,
    items: action.payload.items,
  });
const handleFetchCmsNavigationFailure: ActionHandler<State, typeof fetchCmsNavigationFailure> = state =>
  update(state, {
    hasFailed: true,
    isLoading: false,
  });

export default createReducer<State, AppAction>(initialState)
  .handleAction(fetchCmsNavigationRequest, handleFetchCmsNavigationRequest)
  .handleAction(fetchCmsNavigationSuccess, handleFetchCmsNavigationSuccess)
  .handleAction(fetchCmsNavigationFailure, handleFetchCmsNavigationFailure)
  .handleAction(resetCms, handleResetNavigation);
