import cx from 'classnames';
import React from 'react';

import styles from './Onboarding.module.scss';

interface Props {
  currentStep: number;
}

export const Carousel = ({ currentStep, children }: React.WithChildren<Props>) => {
  const offsetStyle = {
    transform: `translateX(${currentStep * -100}%)`,
  };

  return (
    <div className={styles.carousel} style={offsetStyle}>
      {React.Children.map(children, (child, idx) => (
        <div
          className={cx(styles.carouselItem, {
            [styles.active]: idx === currentStep,
          })}
        >
          {child}
        </div>
      ))}
    </div>
  );
};
