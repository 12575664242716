import { Checkboxes } from '@typings';
import React from 'react';

import { getSelectedValues, updateCheckboxTree } from '../../../logic/checkboxTree';
interface Props {
  options: Checkboxes.Option[];
  onOptionToggle?: (selectedValues: string[]) => void;
}

export const useCheckboxGroupState = ({ options, onOptionToggle }: Props) => {
  const [optionsState, setOptionsState] = React.useState<Checkboxes.OptionsState>({});
  const optionUpdater = React.useMemo(() => updateCheckboxTree(options), [options]);

  const toggleOptionState = React.useCallback(
    (changedValue: string) => {
      const isChecked = optionsState[changedValue] === 'checked';
      const newState = isChecked ? 'unchecked' : 'checked';

      const updatedState = optionUpdater(changedValue, newState, optionsState);

      setOptionsState({ ...optionsState, ...updatedState });
      onOptionToggle?.(getSelectedValues({ ...optionsState, ...updatedState }));
    },
    [onOptionToggle, optionsState, optionUpdater],
  );

  const selectedValues = React.useMemo(() => {
    return getSelectedValues(optionsState);
  }, [optionsState]);

  const selectAll = React.useCallback(() => {
    setOptionsState(
      options.reduce<Checkboxes.OptionsState>(
        (acc, cur) => ({
          ...acc,
          [cur.value]: 'checked',
        }),
        {},
      ),
    );
  }, [options]);

  const deselectAll = React.useCallback(() => setOptionsState({}), []);

  const batchUpdate = React.useCallback(
    (activeValues: string[]) => {
      const batchResult = activeValues.reduce((acc, cur) => {
        return optionUpdater(cur, 'checked', acc);
      }, {});

      setOptionsState(batchResult);
    },
    [optionUpdater],
  );

  return {
    batchUpdate,
    deselectAll,
    optionsState,
    selectAll,
    selectedValues,
    toggleOptionState,
  };
};
