import { Product } from '@typings';
import React from 'react';
import { useSelector } from 'react-redux';

import { getDefaultImageSize } from '../../../../ducks';
import { getGallerySlides, getHasAvailableVariants, getProductFamily } from '../../../../ducks/helpers';
import { isVariantOrMultivariantRelation } from '../../../../logic/merchandise';
import { filterProductRelatedProducts, GetProductUrl } from '../../../../logic/products';
import { useClickOutside, useEventListener, useUrlProductId } from '../../../../utils/hooks';
import { useContainsActiveElement } from '../../../../utils/hooks/useContainsActiveElement';
import { isDefined } from '../../../../utils/is';
import { isEmpty } from '../../../../utils/isEmpty';

interface Props {
  productInfo: Product.Standard;
  shouldHideButtons: boolean;
  getProductUrl: GetProductUrl;
}

interface Context {
  activeSlideIndex: number;
  containerRef: React.RefObject<HTMLDivElement>;
  gallerySlides: Product.GallerySlide[];
  hasAvailableVariants: boolean;
  hasHoverOrFocus: boolean;
  setActiveSlideIndex: (index: number) => void;
  containerBottomOffset: number;
  setContainerBottomOffset: (value: number) => void;
  shouldHideButtons: boolean;
  activeSlideVariant: Product.Standard | undefined;
  productPathName: string;
}

export const ProductContext = React.createContext<Context | null>(null);

export const useProductContext = (): Context => {
  const context = React.useContext(ProductContext);
  if (context === null) {
    throw new Error('ProductContext can not be used outside the scope of ProductContextProvider');
  }

  return context;
};

export const ProductContextProvider = ({ children, productInfo, shouldHideButtons, getProductUrl }: React.WithChildren<Props>) => {
  const defaultImageSize = useSelector(getDefaultImageSize);

  const [activeSlideIndex, setActiveSlideIndex] = React.useState(0);
  const [containerBottomOffset, setContainerBottomOffset] = React.useState(0);
  const [isOverProduct, setIsOverProduct] = React.useState(false);
  const { containerRef, hasActiveElement } = useContainsActiveElement<HTMLDivElement>();

  const productInfoWithFilteredVariants = filterProductRelatedProducts(isVariantOrMultivariantRelation)(productInfo);
  const gallerySlides = getGallerySlides(productInfoWithFilteredVariants, defaultImageSize);
  const productFamily = getProductFamily(productInfoWithFilteredVariants);
  const hasAvailableVariants = getHasAvailableVariants(productFamily);

  const urlProductId = useUrlProductId();
  const isProductModalVisible = isDefined(urlProductId) && !isEmpty(urlProductId);

  const handleMouseOver = React.useCallback(() => {
    setIsOverProduct(true);
  }, []);

  const handleMouseLeave = React.useCallback(() => {
    setIsOverProduct(false);
    setActiveSlideIndex(0);
  }, []);

  useClickOutside({
    element: containerRef.current,
    enabled: !isProductModalVisible,
    handler: handleMouseLeave,
  });

  useEventListener({
    element: containerRef,
    eventName: 'mouseover',
    handler: handleMouseOver,
    isDisabled: isOverProduct,
  });

  useEventListener({
    element: containerRef,
    eventName: 'mouseleave',
    handler: handleMouseLeave,
    isDisabled: !isOverProduct || isProductModalVisible,
  });

  const activeSlideVariant = gallerySlides[activeSlideIndex]?.variant;

  const productPathName = getProductUrl({ productId: activeSlideVariant?.product, variantId: activeSlideVariant?.variant });

  return (
    <ProductContext.Provider
      value={{
        activeSlideIndex,
        activeSlideVariant,
        containerBottomOffset,
        containerRef,
        gallerySlides,
        hasAvailableVariants,
        hasHoverOrFocus: isOverProduct || hasActiveElement,
        productPathName,
        setActiveSlideIndex,
        setContainerBottomOffset,
        shouldHideButtons,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};
