import * as yup from 'yup';

import { PHONE_ALLOWED_SYMBOLS } from '../../constants/regExp';
import i18n from '../../translation/i18n';

export const getPhoneSchema = (isRequired = false) => {
  const schema = yup
    .string()
    .defined()
    .matches(PHONE_ALLOWED_SYMBOLS, {
      excludeEmptyString: true,
      message: i18n.t('validation:valid_phone_number_hint'),
    });

  if (isRequired) {
    return schema.required(i18n.t('validation:phone_number_hint'));
  }

  return schema;
};
