import cx from 'classnames';
import React from 'react';

import styles from './Heading.module.scss';

type VariantTypes = 'big' | 'blue' | 'h2' | 'h3' | 'black';

interface Props {
  title: React.ReactNode;
  className?: string;
  variant?: VariantTypes[];
  dataTestId?: string;
}

const Heading = ({ className, title, variant = ['h2', 'blue'], dataTestId }: Props) => {
  const classNames = cx(styles.heading, {
    [styles.blue]: variant.includes('blue'),
    [styles.big]: variant.includes('big'),
    [styles.black]: variant.includes('black'),
  });

  const TypeOfHeading = variant.includes('h2') ? 'h2' : 'h3';

  return (
    <TypeOfHeading data-testid={dataTestId} className={cx(className, classNames)}>
      {title}
    </TypeOfHeading>
  );
};

export default Heading;
