import { Permission, User } from '@typings';

import { isEmpty } from '../utils/isEmpty';

export const getIsSeller = (user: User | Record<string, never>) => !isEmpty(user) && user.access === 'seller';

export const getIsBuyer = (user: User | Record<string, never>) => !isEmpty(user) && user.access === 'buyer';

export const getIsViewer = (user: User | Record<string, never>) => !isEmpty(user) && user.access === 'viewer';

export const getIsAdmin = (user: User | Record<string, never>) => !isEmpty(user) && user.isAdmin;

export const getHasPermissions = (user: User | Record<string, never>, permissions: Permission | Permission[]) => {
  if (isEmpty(user)) {
    return false;
  }

  return [permissions].flat().every(permission => user.permissions.includes(permission));
};

export default {
  getIsAdmin,
  getIsBuyer,
  getIsSeller,
  getIsViewer,
};
