import { Cms } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { hideAllToolbars } from '../../../ducks';
import { getScreenType } from '../../../logic/pages';
import { useScreenWidth } from '../../../utils/hooks';
import { EditorUIContext } from '../context/EditorUIContext';
import { DeviceSwitcher } from '../DeviceSwitcher';

interface Props {
  className?: string;
}

export const EditorWidthSwitcher = ({ className }: Props) => {
  const { t } = useTranslation(['cms']);
  const dispatch = useDispatch();
  const { screenType, setScreenType } = React.useContext(EditorUIContext);
  const screenWidth = useScreenWidth();

  const onChange = React.useCallback(
    (type: Cms.ScreenType) => {
      setScreenType(type);
      dispatch(hideAllToolbars());
    },
    [dispatch, setScreenType],
  );

  React.useEffect(() => {
    onChange(getScreenType(screenWidth));
  }, [onChange, screenWidth]);

  return <DeviceSwitcher groupName={t('cms:device_type')} value={screenType} onChange={onChange} className={className} />;
};
