import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { editSelection, getOrderDetails, getOrderId } from '../../ducks/order';
import { useModalVisibility } from '../../utils/hooks';
import { isEmpty } from '../../utils/isEmpty';
import { ModalSize } from '../various/ModalElements';
import { ModalShell } from '../various/ModalShell';

import { OrderSettingsForm } from './OrderSettingsForm';

const MODAL_NAME = 'EditOrderModal';

export const EditOrderModal = React.memo(() => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'selections']);
  const { isModalVisible, hideModal } = useModalVisibility(MODAL_NAME);
  const {
    order,
    buyer: { buyer },
  } = useSelector(getOrderDetails);
  const orderId = useSelector(getOrderId);

  const submit = React.useCallback(
    ({ name }: { name: string }) => {
      dispatch(editSelection({ name, orderId }));
    },
    [dispatch, orderId],
  );

  if (isEmpty(order)) {
    return null;
  }

  return (
    <ModalShell isOpen={isModalVisible} size={ModalSize.Medium} title={t('selections:edit_selection')} onClose={hideModal}>
      <OrderSettingsForm
        submitTitle={t('common:edit')}
        onClose={hideModal}
        onSubmit={submit}
        disableBuyerSelect
        initialData={{
          buyer,
          name: order.name,
        }}
      />
    </ModalShell>
  );
});
