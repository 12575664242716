import { createSelectorCreator, defaultMemoize } from 'reselect';

const isShallowEqual = (a: unknown, b: unknown) => {
  if (!Array.isArray(a) || !Array.isArray(b)) {
    return a === b;
  }

  if (b.length !== a.length) {
    return false;
  }

  return a.every(item => b.includes(item));
};

export const createShallowEqualSelector = createSelectorCreator(defaultMemoize, isShallowEqual);
