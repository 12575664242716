import { Id, Navigation } from '@typings';
import { SortableNestItems } from '@typings/components/sortableTree';
import cx from 'classnames';
import { assoc, map } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getHasCurrentMarket, getIsFolderMenuItem } from '../../../../logic/pages';
import { useMenuValidation, usePreviousValue } from '../../../../utils/hooks';
import { useConfirmationGuard } from '../../../../utils/hooks/useConfirmationGuard';
import { isDefined } from '../../../../utils/is';
import { isEmpty } from '../../../../utils/isEmpty';
import Icon, { IconType } from '../../../various/Icon';
import { SortableTree } from '../../../various/Sortable/SortableTree';
import { useMenuSectionContext } from '../context/MenuSectionContext';
import { MarketSelector } from '../MarketSelector';
import { AddedMenuItem } from '../MenuItems';

import { ItemsHelperTooltip } from './ItemsHelperTooltip';
import styles from './MenuSectionList.module.scss';

export const AddedList = () => {
  const { t } = useTranslation(['common', 'settings', 'confirmationConfig']);
  const {
    addedMenuItems,
    setAddedMenuItems,
    isInEditMode,
    isUnavailablePage,
    isMarketsMismatch,
    hasItemWithoutMarkets,
    areInputsDisabled,
    selectedMarket,
    itemRecords,
  } = useMenuSectionContext();

  const { canNest } = useMenuValidation();
  const confirmationGuard = useConfirmationGuard();

  const shouldShowWarning = isUnavailablePage || isMarketsMismatch || hasItemWithoutMarkets;

  const flattenedItems = React.useMemo(() => {
    return addedMenuItems.flatMap(item => {
      const childItems = item.children?.map(child => ({ id: child.id, parent: item.id })) ?? [];

      return [{ id: item.id }, ...childItems];
    });
  }, [addedMenuItems]);

  const addedIds: SortableNestItems = React.useMemo(
    () =>
      addedMenuItems.map(item => ({
        children: item.children?.map(child => ({ children: [], id: child.id })) ?? [],
        id: item.id,
      })),
    [addedMenuItems],
  );

  const [expandedItems, setExpandedItems] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (!isInEditMode) {
      setExpandedItems([]);
    }
  }, [isInEditMode]);

  const handleSortStart = React.useCallback(() => {
    setExpandedItems([]);
  }, [setExpandedItems]);

  const previousAddedCount = usePreviousValue(flattenedItems.length) ?? flattenedItems.length;

  const getDetailedItems = React.useCallback(
    (itemsList: SortableNestItems): Navigation.AnyMenuItem[] => {
      const detailedItems = itemsList.map(item => ({
        ...itemRecords[item.id],
        children: item.children.map(child => ({ ...itemRecords[child.id] })),
      }));

      return detailedItems as Navigation.AnyMenuItem[];
    },
    [itemRecords],
  );

  const handleSortEnd = React.useCallback(
    (itemsOrder: SortableNestItems) => {
      setAddedMenuItems(getDetailedItems(itemsOrder));
    },
    [setAddedMenuItems, getDetailedItems],
  );

  const getPreviewItemsByMarket = React.useCallback(
    (market: string | null) => {
      if (!isDefined(market)) {
        return addedIds;
      }

      return addedIds.filter(item => {
        const itemDetails = itemRecords[item.id];

        if (!isDefined(itemDetails)) {
          return false;
        }

        if (getIsFolderMenuItem(itemDetails)) {
          return itemDetails.children?.some(childItem => getHasCurrentMarket(childItem, market));
        }

        return getHasCurrentMarket(itemDetails, market);
      });
    },
    [addedIds, itemRecords],
  );

  const getHasChildItems = React.useCallback(
    (id: Id) => {
      const item = itemRecords[id];

      return isDefined(item?.children) && !isEmpty(item.children);
    },
    [itemRecords],
  );

  const renderAddedMenuItem = (id: Id) => (
    <AddedMenuItem
      key={id}
      id={id}
      isOpen={!isDefined(selectedMarket) && expandedItems.includes(id)}
      onToggleOpen={handleToggleOpen}
      onRemoveMenuItem={handleRemoveClick}
      onLabelChange={handleLabelChange}
      onMarketsChange={handleMarketsChange}
      onToggleNewTab={handleNewTabChange}
    />
  );

  const removeMenuItem = React.useCallback(
    (id: Id) => {
      const filteredList = addedIds.reduce((acc: SortableNestItems, item) => {
        if (item.id === id) {
          return acc;
        }

        const newItem = { ...item, children: item.children.filter(child => child.id !== id) };

        return [...acc, newItem];
      }, []);
      setAddedMenuItems(getDetailedItems(filteredList));
    },
    [addedIds, setAddedMenuItems, getDetailedItems],
  );

  const handleRemoveClick = React.useCallback(
    (id: Id) => {
      const hasNested = getHasChildItems(id);

      if (hasNested) {
        confirmationGuard(() => ({
          onOk: () => removeMenuItem(id),
          ...t('confirmationConfig:delete_nested_menu', { returnObjects: true }),
        }))();

        return;
      }

      removeMenuItem(id);
    },
    [getHasChildItems, removeMenuItem, confirmationGuard, t],
  );

  const updateMenuItem = React.useCallback(
    (field: string, value: any, id: Id) => {
      const updateItem = (items: Navigation.AnyMenuItem[]): Navigation.AnyMenuItem[] =>
        map(item => {
          if (item.id === id) {
            return assoc(field, value, item);
          }

          if (item.children) {
            return { ...item, children: updateItem(item.children) as Navigation.LinkMenuItem[] };
          }

          return item;
        }, items);

      setAddedMenuItems(updateItem(addedMenuItems));
    },
    [setAddedMenuItems, addedIds],
  );

  const handleLabelChange = React.useCallback(
    (value: string, id: Id) => {
      updateMenuItem('label', value, id);
    },
    [updateMenuItem],
  );

  const handleMarketsChange = React.useCallback(
    (value: string[], id: Id) => {
      updateMenuItem('markets', value, id);
    },
    [updateMenuItem],
  );

  const handleNewTabChange = React.useCallback(
    (checked: boolean, id: Id) => {
      updateMenuItem('openNewTab', checked, id);
    },
    [updateMenuItem],
  );

  const handleToggleOpen = React.useCallback(
    (currentItem: string) => {
      setExpandedItems(items => (items.includes(currentItem) ? items.filter(item => item !== currentItem) : [currentItem]));
    },
    [setExpandedItems],
  );

  const renderNestPlaceholder = (id: Id) => {
    const item = itemRecords[id];

    if (!isDefined(item) || !getIsFolderMenuItem(item) || getHasChildItems(id)) {
      return null;
    }

    return <div className={styles.emptyFolder}>{t('settings:empty_folder')}</div>;
  };

  const scrollToDirection = React.useMemo(() => {
    if (previousAddedCount > 0 && flattenedItems.length > previousAddedCount) {
      return { to: 'bottom' as const };
    }

    return null;
  }, [previousAddedCount, flattenedItems]);

  return (
    <div className={styles.container}>
      <div className={styles.containerTitle}>
        {t('settings:added_items_header')}
        {isInEditMode && <ItemsHelperTooltip translationKey="settings:added_items_info" />}
      </div>
      <div className={cx(styles.expandableContainer, { [styles.disabled]: !isInEditMode, [styles.disabledHover]: areInputsDisabled })}>
        <div className={styles.title}>
          <div className={styles.titleName}>
            {t('settings:menu_structure')}
            {shouldShowWarning && <Icon type={IconType.Warning} className={styles.warningIcon} />}
          </div>
          <MarketSelector />
        </div>
        <SortableTree
          maxDepth={1}
          items={getPreviewItemsByMarket(selectedMarket)}
          renderItem={renderAddedMenuItem}
          renderNestPlaceholder={renderNestPlaceholder}
          onSortEnd={handleSortEnd}
          onSortStart={handleSortStart}
          isDisabled={areInputsDisabled}
          validateNesting={canNest}
          scrollToDirection={scrollToDirection}
        />
      </div>
    </div>
  );
};
