import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useTranslationsConfig } from '../../../utils/hooks';
import { loadTranslations } from '../../translationsRepository';

export const TRANSLATIONS_QUERY_KEY = 'translations';

export const useTranslations = () => {
  const { t } = useTranslation(['translations']);
  const { areMultipleLanguagesAvailable } = useTranslationsConfig();

  return useQuery({
    enabled: areMultipleLanguagesAvailable,
    meta: {
      errorMessage: t('translations:loading_failed'),
    },
    queryFn: loadTranslations,
    queryKey: [TRANSLATIONS_QUERY_KEY],
    refetchOnMount: false,
  });
};
