import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Order } from '../../../../../typings';
import { DEFAULT_ITEMS_COUNT_PER_REQUEST } from '../../../constants/limits';
import { getNotEditableStages } from '../../../ducks/selections';
import { useOrdersList } from '../../../services/hooks/orders/useOrdersList';
import { getSkipFirstForListing } from '../../../utils/getSkipFirstForListing';
import { useDebouncedCallback, useSearchInput } from '../../../utils/hooks';
import { useAmplitude } from '../../../utils/hooks/useAmplitude';
import { useSyncPageAfterDelete } from '../../../utils/hooks/useSyncPageAfterDelete';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { ListFilter } from '../../ListFilter';
import { Input } from '../../various/Fields/Input';
import { IconType } from '../../various/Icon';
import { Notification } from '../../various/Notification/Notification';
import { TopBar } from '../../various/TopBar';
import { Wrapper, WrapperSize } from '../../various/Wrapper';
import { useOrdersSearchParams } from '../useOrdersSearchParams';

import styles from './OrdersList.module.scss';
import { OrdersListEmptyState } from './OrdersListEmptyState';
import { OrdersListTable } from './OrdersListTable';

export type SortingKeys = 'orderNumber' | 'accountName' | 'buyerName' | 'poNumber' | 'itemsCount';

export const OrdersList = () => {
  const { t } = useTranslation(['orders', 'common', 'selections']);

  const orderStagesWithAll = useSelector(getNotEditableStages);

  const { handlePageChange, handleSearchChange, handleSortChange, handleStatusChange, page, search, sortOrder, status } =
    useOrdersSearchParams();

  const { trackEvent } = useAmplitude();

  const statuses = React.useMemo(() => {
    return orderStagesWithAll.filter(stage => !isEmpty(stage.value)).map(stage => stage.value);
  }, [orderStagesWithAll]);

  const selectedStatuses = React.useMemo(() => (isEmpty(status) ? statuses : [status]), [status, statuses]) as Order.OrderStatus[];

  const skipFirst = getSkipFirstForListing(page);

  const { data, isError, isLoading } = useOrdersList({
    data: {
      limit: DEFAULT_ITEMS_COUNT_PER_REQUEST,
      search,
      skipFirst,
      sortOrder,
      statuses: selectedStatuses,
    },
  });

  const deliveryWindows = data?.deliveryWindows ?? [];
  const orders = data?.orders;
  const ordersCountTotal = data?.orderCount;

  useSyncPageAfterDelete({ ordersCountTotal, skipFirst });

  const hasOrders = isDefined(orders) && !isEmpty(orders);
  const filters = orderStagesWithAll.map(stage => ({ name: t(stage.i18nKey), value: stage.value }));

  const shouldShowEmptyState = !isLoading && !hasOrders && !isError;

  const { value, onChange } = useSearchInput({
    initialValue: search,
    onChangeValue: useDebouncedCallback(handleSearchChange),
  });

  const handleSelectionTypeChange = (selectionType: Order.SelectionStatus) => {
    handleStatusChange(selectionType);
    trackEvent({ name: 'ordersTable.radioButton.status.change' });
  };

  const handleSearchBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (!isEmpty(event.target.value)) {
      trackEvent({ name: 'ordersTable.input.search.filledin' });
    }
  };

  const topBarDetails =
    hasOrders ? <span className={styles.orderCount}>{t('common:result_count', { count: ordersCountTotal })}</span> : t('common:no_results');

  return (
    <>
      <TopBar title={t('orders:order_other')} details={isDefined(orders) ? topBarDetails : undefined}>
        <label aria-label={t('common:search_placeholder')}>
          <Input
            placeholder={t('common:search_placeholder')}
            icon={IconType.Search}
            value={value}
            isClearable
            onChange={onChange}
            onBlur={handleSearchBlur}
          />
        </label>
      </TopBar>

      <Wrapper size={WrapperSize.XXLARGE}>
        <>
          <ListFilter
            title={t('orders:select_order_type')}
            name="orderType"
            onChange={handleSelectionTypeChange}
            selected={status}
            filters={filters}
          />

          {shouldShowEmptyState && <OrdersListEmptyState />}

          {isError && <Notification>{t('orders:orders_fetch_failed')}</Notification>}

          {(isLoading || hasOrders) && (
            <OrdersListTable
              sortOrder={sortOrder}
              deliveryWindows={deliveryWindows}
              handleSortChange={handleSortChange}
              handlePageChange={handlePageChange}
              page={page}
              ordersCount={ordersCountTotal}
              orders={orders}
              isLoadingList={isLoading}
            />
          )}
        </>
      </Wrapper>
    </>
  );
};
