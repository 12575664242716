import { WhatsNew } from '@typings';
import { useTranslation } from 'react-i18next';

import { Article } from './Article';
import styles from './WhatsNew.module.scss';

export const MonthContent = ({ month, year, articles }: WhatsNew.ArticleSet) => {
  const { t } = useTranslation(['dates']);

  return (
    <>
      <p className={styles.popoverDate}>
        {t('dates:months', { returnObjects: true })[month - 1]} {year}
      </p>
      {articles.map(article => (
        <Article key={article.title} month={month} year={year} article={article} />
      ))}
    </>
  );
};
