import { Item, Items, Product } from '@typings';
import { Option } from 'space-lift';

import { EnsureKey, ensureKey, isDefined } from '../utils/is';
import { isEmpty } from '../utils/isEmpty';

import { isVariantRelation } from './merchandise';
import { getIsTwoDimensional } from './sizeCharts';

type Unit = string;

const MINIMUM_REQUIRED_MAPPINGS = 2;

export const getProductUnits = (tableMappings: Product.UnitMappings): string[] =>
  Object.keys(tableMappings).length >= MINIMUM_REQUIRED_MAPPINGS ? [...Object.keys(tableMappings)].sort() : [];

export const isSwitchAvailable = (tableMappings: Product.UnitMappings) =>
  getProductUnits(tableMappings).length >= MINIMUM_REQUIRED_MAPPINGS;

export const getUnitSwitcherName = (tableMappings: Product.UnitMappings) => [...Object.keys(tableMappings)].sort().toLocaleString();
export const getFirstUnit = (tableMappings: Product.UnitMappings) => Object.keys(tableMappings)[0] ?? '';

const getSelectedOrDefaultUnit = (
  tableMappings: Product.UnitMappings,
  selectedUnits: Record<string, string>,
  defaultUnit: Unit | undefined,
) => {
  const hasDefaultUnit = isDefined(defaultUnit) && !isEmpty(defaultUnit);

  const defaultUnitValue = hasDefaultUnit ? defaultUnit : getFirstUnit(tableMappings);

  return Option(getUnitSwitcherName(tableMappings))
    .map(switcherName => selectedUnits[switcherName])
    .getOrElse(defaultUnitValue);
};

const applyMappingToItem =
  (selectedMapping: Items.Table.Any) =>
  (item: Item): Item => ({
    ...item,
    name:
      getIsTwoDimensional(selectedMapping) ?
        `${selectedMapping.x[item.itemTableX]}${selectedMapping.dividerSymbol}${selectedMapping.y[item.itemTableY]}`
      : selectedMapping.x[item.itemTableX] ?? '',
  });

const applyToVariant = (unitToApply: string) => (variant: EnsureKey<Product.Standard, 'tableMappings'>) => {
  if (isVariantRelation(variant)) {
    const selectedMapping = getItemTable(variant, unitToApply);

    return {
      ...variant,
      itemTable: selectedMapping,
      items: variant.items.map(applyMappingToItem(selectedMapping)),
    };
  }

  return variant;
};

const applyMappingToProduct = (unitToApply: string, productDetails: EnsureKey<Product.Standard, 'tableMappings'>): Product.Standard => {
  const selectedMapping = getItemTable(productDetails, unitToApply);

  return {
    ...productDetails,
    itemTable: selectedMapping,
    items: productDetails.items.map(applyMappingToItem(selectedMapping)),

    ...(productDetails.relatedProducts &&
      !Array.isArray(productDetails.relatedProducts) && {
        relatedProducts: {
          variants: productDetails.relatedProducts.variants.map(variant =>
            applyToVariant(unitToApply)(variant as EnsureKey<Product.Standard, 'tableMappings'>),
          ),
        },
      }),
  };
};

const getItemTable = (productDetails: EnsureKey<Product.Standard, 'tableMappings'>, unit: Unit) => {
  if (!isDefined(productDetails.tableMappings) || !isDefined(productDetails.tableMappings[unit])) {
    return productDetails.itemTable;
  }

  const { dividerSymbol, original: itemTableOriginal, x: itemTableX, y: itemTableY } = productDetails.itemTable;

  const mapping = productDetails.tableMappings[unit];
  const x = itemTableX.map(size => mapping?.x[size]);
  const y = isEmpty(itemTableY) ? [] : itemTableY.map(size => mapping?.y[size]);

  const original =
    isDefined(itemTableOriginal) ?
      {
        x: itemTableOriginal.x.map(size => mapping?.x[size] ?? size),
        y: isEmpty(itemTableOriginal.y) || !isDefined(itemTableOriginal.y) ? [] : itemTableOriginal.y.map(size => mapping?.y[size] ?? size),
      }
    : [];

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return {
    dividerSymbol,
    original,
    x,
    y,
  } as Items.Table.Any;
};

export const switchProductUnit = (productDetails: Product.Standard, selectedUnits: Record<string, Unit>) => {
  if (!ensureKey(productDetails, 'tableMappings') || !isSwitchAvailable(productDetails.tableMappings)) {
    return productDetails;
  }

  const unitToApply = getSelectedOrDefaultUnit(productDetails.tableMappings, selectedUnits, productDetails.defaultLocalizedChart);

  return applyMappingToProduct(unitToApply, productDetails);
};

export const getUniqueSwitchersMapping = (products: Product.Full[]) => {
  return products.reduce<Record<string, { tableMappings: Product.UnitMappings; defaultTableMapping: Unit | undefined }>>((acc, current) => {
    if (!ensureKey(current, 'tableMappings') || isEmpty(current.tableMappings)) {
      return acc;
    }

    return {
      ...acc,
      [getUnitSwitcherName(current.tableMappings)]: {
        defaultTableMapping: current.defaultLocalizedChart,
        tableMappings: current.tableMappings,
      },
    };
  }, {});
};
