const enum DownloadItemTrackingEventWithParameters {
  DOWNLOAD_LINESHEET_GENERATED = 'download-linesheet-generate',
  DOWNLOAD_LINESHEET_ORDER_GENERATED = 'download-linesheet-order-generate',
}

interface LinesheetCommonProps {
  showCustomerPrice: boolean | undefined;
  template: string;
}

interface LinesheetProps extends LinesheetCommonProps {
  showStock: boolean | undefined;
  skipOutOfStock: boolean | undefined;
}

interface LineSheetOrderProps extends LinesheetCommonProps {
  location: string;
  onlySelected: boolean | undefined;
}

export const getDownloadLinesheetGeneratedEvent = (payload: LinesheetProps) => ({
  event: DownloadItemTrackingEventWithParameters.DOWNLOAD_LINESHEET_GENERATED,
  ...payload,
});

export const getDownloadLinesheetOrderGeneratedEvent = (payload: LineSheetOrderProps) => ({
  event: DownloadItemTrackingEventWithParameters.DOWNLOAD_LINESHEET_ORDER_GENERATED,
  ...payload,
});

export const enum DownloadItemTrackingEvent {
  DOWNLOAD_INVOICE_GENERATED = 'download-invoice-generate',
}
