import { createColumnHelper, getCoreRowModel, getSortedRowModel } from '@tanstack/react-table';
import { Buyer, Id } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getIsBuyerEditingEnabled } from '../../../../ducks/config';
import { useDeleteBuyer } from '../../../../services/hooks/account/useDeleteBuyer';
import { useTable } from '../../../../utils/hooks/table/useTable';
import { useConfirmationGuard } from '../../../../utils/hooks/useConfirmationGuard';
import { isDefined } from '../../../../utils/is';
import { ACTION_COLUMN_WIDTH, renderMaskedCell } from '../../../../utils/table';
import { DataPreview } from '../../../various/DataPreview';
import { PopoverMenu } from '../../../various/PopoverMenu';
import { Table } from '../../../various/Table';

interface Props {
  accountId: Id;
  buyers: Record<string, Buyer>;
  isLoading?: boolean;
  onEditRow: (buyerId: string) => void;
}
interface BuyerRecord {
  key: string;
  buyer: string;
  email: string;
  mobile: Nullable<string>;
  name: string;
  phone: Nullable<string>;
}

const columnHelper = createColumnHelper<BuyerRecord>();

export const BuyersTable = ({ accountId, buyers, isLoading = false, onEditRow }: Props) => {
  const { t } = useTranslation(['common', 'buyers', 'validation']);
  const confirmationGuard = useConfirmationGuard();
  const isBuyerEditingEnabled = useSelector(getIsBuyerEditingEnabled);

  const { mutate: deleteBuyer } = useDeleteBuyer();

  const handleDeleteBuyer = (buyerId: string) => {
    if (Object.keys(buyers).length === 1) {
      // there's only one buyer left, prevent deleting her
      confirmationGuard(() => ({
        content: t('buyers:last_buyer_warning'),
        maskClosable: true,
        title: `${t('common:warning')}!`,
        type: 'warning',
      }))();

      return;
    }
    const buyerDetails = buyers[buyerId];
    if (isDefined(buyerDetails)) {
      confirmationGuard(() => ({
        content: (
          <div>
            {t('buyers:delete_warning')}
            <DataPreview
              data={buyerDetails}
              labels={[
                ['firstName', t('common:first_name')],
                ['lastName', t('common:last_name')],
                ['email', t('common:email')],
              ]}
            />
          </div>
        ),
        onOk: () => deleteBuyer({ accountId, buyerId }),
        title: t('buyers:delete_buyer'),
      }))();
    }
  };

  const data: BuyerRecord[] = React.useMemo(
    () =>
      Object.entries(buyers).map(([key, buyer]) => ({
        buyer: buyer.buyer,
        email: buyer.email,
        key,
        mobile: buyer.mobileNumber,
        name: `${buyer.firstName} ${buyer.lastName}`,
        phone: buyer.phoneNumber,
      })),
    [buyers],
  );

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: renderMaskedCell,
        header: t('common:name'),
      }),
      columnHelper.accessor('email', {
        cell: renderMaskedCell,
        header: t('common:email'),
      }),
      columnHelper.accessor('phone', {
        cell: renderMaskedCell,
        header: t('common:phone'),
      }),
      columnHelper.accessor('mobile', {
        cell: renderMaskedCell,
        header: t('common:mobile'),
      }),
      ...(isBuyerEditingEnabled ?
        [
          columnHelper.display({
            cell: props => (
              <PopoverMenu
                overflowContainer
                items={[
                  {
                    key: 'edit',
                    label: t('common:edit'),
                    onClick: () => onEditRow(props.row.original.buyer),
                  },
                  {
                    key: 'delete',
                    label: t('common:delete'),
                    onClick: () => handleDeleteBuyer(props.row.original.buyer),
                  },
                ]}
                name={t('buyers:buyer_options')}
              />
            ),
            id: 'actions',
            meta: {
              width: ACTION_COLUMN_WIDTH,
            },
          }),
        ]
      : []),
    ],
    [t, isBuyerEditingEnabled, onEditRow],
  );

  const table = useTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return <Table table={table} isLoading={isLoading} skeletonRows={5} />;
};
