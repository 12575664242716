/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;
const ORIGINAL_COLOR = '#5C5C5C';

export const Copy = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => {
  return (
    <svg width={width} height={width} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66663 2.66675H16C16.7333 2.66675 17.3333 3.26675 17.3333 4.00008V13.3334C17.3333 14.0667 16.7333 14.6667 16 14.6667H6.66663C5.93329 14.6667 5.33329 14.0667 5.33329 13.3334V4.00008C5.33329 3.26675 5.93329 2.66675 6.66663 2.66675ZM2.66663 6.00008C2.66663 5.63341 2.96663 5.33341 3.33329 5.33341C3.69996 5.33341 3.99996 5.63341 3.99996 6.00008V15.3334C3.99996 15.7001 4.29996 16.0001 4.66663 16.0001H14C14.3666 16.0001 14.6666 16.3001 14.6666 16.6667C14.6666 17.0334 14.3666 17.3334 14 17.3334H3.99996C3.26663 17.3334 2.66663 16.7334 2.66663 16.0001V6.00008Z"
        fill={color}
      />
    </svg>
  );
};
