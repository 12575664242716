import { useTranslation } from 'react-i18next';

import { PageTitle } from '../various/PageTitle';

import { OrdersList } from './OrdersList/OrdersList';

export const Orders = () => {
  const { t } = useTranslation(['orders']);

  return (
    <PageTitle title={t('orders:order_other')}>
      <OrdersList />
    </PageTitle>
  );
};
