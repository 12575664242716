import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Cms, Id } from '@typings';
import { useTranslation } from 'react-i18next';

import { addToast } from '../../../components/various/Toasts';
import { deletePage } from '../../pagesRepository';

import { PAGES_QUERY_KEY } from './usePages';

interface MutationVariables {
  pageId: Id;
}

export const useDeletePage = () => {
  const { t } = useTranslation(['cms']);
  const queryClient = useQueryClient();

  const mutationFn = async ({ pageId }: MutationVariables) => {
    return deletePage(pageId);
  };

  const handleMutate = ({ pageId }: MutationVariables) => {
    queryClient.setQueriesData<Cms.Page[]>({ queryKey: [PAGES_QUERY_KEY] }, pages => {
      return pages?.filter(page => page.id !== pageId);
    });
  };

  const handleSettled = () => {
    queryClient.invalidateQueries({ queryKey: [PAGES_QUERY_KEY] });
  };

  const handleError = () => {
    addToast(t('cms:delete_page_fail'));
  };

  return useMutation({
    mutationFn,
    onError: handleError,
    onMutate: handleMutate,
    onSettled: handleSettled,
  });
};
