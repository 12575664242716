import { Cms } from '@typings';
import { Trans, useTranslation } from 'react-i18next';

import { getIsPublished } from '../../../../../../logic/pages';
import { formatDate, getRelativeTimeFormatter } from '../../../../../../utils/dates';
import { useTranslationsConfig } from '../../../../../../utils/hooks';
import { isDefined } from '../../../../../../utils/is';
import { HelpTooltip } from '../../../../../various/HelpTooltip';

import styles from './PublishLanguageItem.module.scss';

const HELP_ICON_SIZE = 16;

interface Props {
  lastModified: Cms.ModificationDetails;
  lastPublished: Cms.ModificationDetails;
  status: Cms.PageStatus;
}

export const LanguageItemDescription = ({ lastModified, lastPublished, status }: Props) => {
  const { t } = useTranslation(['cms']);
  const { currentLanguageCode } = useTranslationsConfig();

  const isPublished = getIsPublished(status);
  const shouldShowPublishedDate = isPublished && isDefined(lastPublished);

  const dateFormatter = formatDate('YYYY-MM-DD, HH:mm:ss');
  const relativeTimeFormatter = getRelativeTimeFormatter(currentLanguageCode);

  const getPublishDate = () => {
    if (!shouldShowPublishedDate) {
      return null;
    }

    const { date, user } = lastPublished;
    const formattedDate = dateFormatter(date);

    if (!isDefined(user)) {
      return (
        <div>
          {t('cms:last_published_at')} <span className={styles.important}>{formattedDate}</span>
        </div>
      );
    }

    return (
      <div>
        <Trans
          t={t}
          i18nKey="cms:last_published_by_at"
          defaults="Last published by <user>{{user}}</user> at <date>{{date}}</date>"
          values={{ date: formattedDate, user }}
          components={{
            date: <span className={styles.important}>{formattedDate}</span>,
            user: (
              <span className={styles.important} data-sentry-mask>
                {user}
              </span>
            ),
          }}
        />
      </div>
    );
  };

  const getModifiedDate = () => {
    const { date, user } = lastModified;
    const formattedDate = dateFormatter(date);

    if (!isDefined(user)) {
      return (
        <div>
          {t('cms:last_modified_at')} <span className={styles.important}>{formattedDate}</span>
        </div>
      );
    }

    return (
      <div>
        <Trans
          t={t}
          i18nKey="cms:last_modified_by_at"
          values={{ date: formattedDate, user }}
          defaults="Last modified by <user>{{user}}</user> at <date>{{date}}</date>"
          components={{
            date: <span className={styles.important}>{formattedDate}</span>,
            user: (
              <span className={styles.important} data-sentry-mask>
                {user}
              </span>
            ),
          }}
        />
      </div>
    );
  };

  const descriptionText =
    shouldShowPublishedDate ?
      t('cms:last_published_date', { date: relativeTimeFormatter(lastPublished.date) })
    : t('cms:last_saved_date', { date: relativeTimeFormatter(lastModified.date) });

  return (
    <>
      {descriptionText}
      <HelpTooltip
        iconSize={HELP_ICON_SIZE}
        overlayClassName={styles.tooltipOverlay}
        content={
          <>
            {getPublishDate()}
            {getModifiedDate()}
          </>
        }
      />
    </>
  );
};
