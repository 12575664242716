/* eslint-disable max-len */

import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 18;
const ORIGINAL_HEIGHT = 16;
const PROPORTION = ORIGINAL_HEIGHT / ORIGINAL_WIDTH;

export const Cross = ({ width = ORIGINAL_WIDTH }: IconComponentProps) => {
  const strokeWidth = ORIGINAL_WIDTH / width;

  return (
    <svg width={width} height={width * PROPORTION} viewBox="0 0 18 16" version="1.1">
      <g id="Views" strokeWidth={strokeWidth} fill="none" fillRule="evenodd" strokeLinecap="square">
        <g id="Product-details-(with-big-matrix-notification)" transform="translate(-964.000000, -525.000000)" strokeWidth="0.9">
          <g id="Matrix-notification-big" transform="translate(679.000000, 510.000000)">
            <g id="Cross" transform="translate(286.000000, 15.000000)">
              <path d="M0,0 L16,16" id="Line" />
              <path d="M0,16 L16,0" id="Line" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
