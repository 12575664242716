import { Checkboxes } from '@typings';
import React from 'react';
import { PlacementType } from 'react-laag/dist/PlacementType';

import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { CheckboxGroup } from '../CheckboxGroup';

import { Dropdown } from './Dropdown';
import styles from './Dropdown.module.scss';

interface Props {
  title: string;
  optionsState: Checkboxes.OptionsState;
  options: Checkboxes.Option[];
  onChange: (changedValue: string) => void;
  onDeselectAll?: () => void;
  placement?: PlacementType;
  emptyMessage?: string;
}

export const DropdownCheckboxGroup = ({ title, optionsState, options, onChange, onDeselectAll, placement, emptyMessage }: Props) => {
  const [isVisible, setIsVisible] = React.useState(false);

  const handleReset = React.useCallback(() => {
    onDeselectAll?.();
    setIsVisible(false);
  }, [onDeselectAll]);

  const selectedNames = React.useMemo(() => {
    return options.filter(option => optionsState[option.value] === 'checked').map(option => option.selectedLabel ?? option.name);
  }, [options, optionsState]);

  const selectedText = isEmpty(selectedNames) ? undefined : selectedNames.join();

  return (
    <Dropdown
      title={title}
      visible={isVisible}
      allowClear={isDefined(onDeselectAll)}
      selected={selectedText}
      onVisibilityChange={setIsVisible}
      onClear={handleReset}
      placement={placement}
    >
      {isEmpty(options) ?
        <p className={styles.emptyMesssage}>{emptyMessage}</p>
      : <div className={styles.dropdownCheckboxGroup}>
          <CheckboxGroup options={options} optionsState={optionsState} onChange={onChange} />
        </div>
      }
    </Dropdown>
  );
};
