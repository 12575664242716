import { ReceiptRow } from '../../../../checkout/checkoutProductsReceipt/ReceiptRow';
import { Skeleton } from '../../../Skeleton/Skeleton';

import styles from './ExpandableDeliveryWindowSkeleton.module.scss';

const SKELETON_ITEMS_COUNT = 4;

export const ExpandableDeliveryWindowSkeleton = () => {
  return (
    <ReceiptRow>
      {Array.from({ length: SKELETON_ITEMS_COUNT }).map((_, index) => (
        <Skeleton key={index} type="text" size="xlarge" itemClassName={styles.item} />
      ))}
    </ReceiptRow>
  );
};
