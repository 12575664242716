import { useTranslation } from 'react-i18next';

import { PageTitle } from '../various/PageTitle';

import { SelectionsList } from './SelectionsList/SelectionsList';

export const Selections = () => {
  const { t } = useTranslation(['selections']);

  return (
    <PageTitle title={t('selections:selection_other')}>
      <SelectionsList />
    </PageTitle>
  );
};
