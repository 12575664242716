import { FailedProducts, Preset, Product } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { itemsToChart } from '../../../ducks/helpers';
import { ListItem } from '../../various/List';
import { ItemsQuantity } from '../../various/List/ItemsQuantity';
import { ItemsQuantityCell } from '../../various/List/ItemsQuantityCell';
import { ProductName } from '../../various/List/ProductName';

import styles from './FailedProductsRowModels.module.scss';

const AllowedQuantitiesCell = (props: FailedProducts.FailedProduct<FailedProducts.ItemsWrongMultiple>) => {
  const {
    failedProductData: { items },
    itemTable,
  } = props;
  const { t } = useTranslation(['common']);
  const correctQuanityHigherData = items.reduce((acc: Preset.Item[], item) => acc.concat([item.correctQuantityHigher]), []);
  const correctQuanityLowerData = items.reduce((acc: Preset.Item[], item) => acc.concat([item.correctQuantityLower]), []);
  const correctQuantityHigher = items.reduce((sum, item) => sum + item.correctQuantityHigher.quantity, 0);
  const correctQuantityLower = items.reduce((sum, item) => sum + item.correctQuantityLower.quantity, 0);
  const correctQuantityHigherTable = itemsToChart(correctQuanityHigherData, itemTable);
  const correctQuantityLowerTable = itemsToChart(correctQuanityLowerData, itemTable);

  return (
    <td className={styles.itemsQuantityCell}>
      <div className={styles.itemsQuantityWrapper}>
        <ItemsQuantity quantity={correctQuantityHigher} table={correctQuantityHigherTable} />
        <span className={styles.separator}>{t('common:or')}</span>
        <ItemsQuantity quantity={correctQuantityLower} table={correctQuantityLowerTable} />
      </div>
    </td>
  );
};

const OriginalQuantityCell = (props: FailedProducts.FailedProduct) => {
  const {
    failedProductData: { items },
    itemTable,
  } = props;
  const data = items.map(item => item.original);
  const quantitySum = data.reduce((sum, { quantity }) => sum + quantity, 0);
  const table = itemsToChart(data, itemTable);

  return (
    <ItemsQuantityCell
      cellClassName={styles.originalQuantity}
      tooltipClassName={styles.originalPriceTag}
      quantity={quantitySum}
      table={table}
    />
  );
};

const useBaseModel = <T extends FailedProducts.FailedProduct>() => {
  const { t } = useTranslation(['products', 'checkout', 'common']);

  return React.useMemo(
    () =>
      new Map<string, ListItem<T>>()
        .set(t('products:product_name'), (props: Product.Full) => <ProductName {...props} />)
        .set(t('products:variant_name'), ({ variantName }) => <td>{variantName}</td>)
        .set(t('common:art_no', { artNo: '' }), ({ sku }) => <td>{sku}</td>)
        .set(t('checkout:original_quantity'), OriginalQuantityCell),
    [t],
  );
};

export const useMultiplicityModel = () => {
  const baseModel = useBaseModel<FailedProducts.FailedProduct<FailedProducts.ItemsWrongMultiple>>();
  const { t } = useTranslation(['checkout']);

  return React.useMemo(() => {
    return new Map<string, ListItem<FailedProducts.FailedProduct<FailedProducts.ItemsWrongMultiple>>>(baseModel).set(
      t('checkout:allowed_quantity'),
      AllowedQuantitiesCell,
    );
  }, [baseModel, t]);
};

export const useMinimumQuantityModel = () => {
  const baseModel = useBaseModel<FailedProducts.FailedProduct<FailedProducts.ItemsLessThenAllowedMinimum>>();
  const { t } = useTranslation(['checkout']);

  return React.useMemo(
    () =>
      new Map<string, ListItem<FailedProducts.FailedProduct<FailedProducts.ItemsLessThenAllowedMinimum>>>(baseModel).set(
        t('checkout:minimum_quantity'),
        ({ failedProductData: { items }, itemTable }) => {
          const data = items.map(item => item.minimum);
          const quantitySum = data.reduce((sum, { quantity }) => sum + quantity, 0);
          const table = itemsToChart(data, itemTable);

          return <ItemsQuantityCell quantity={quantitySum} table={table} />;
        },
      ),
    [t, baseModel],
  );
};

export const useMissingProductsModel = () => {
  const baseModel = useBaseModel<FailedProducts.FailedProduct<FailedProducts.ItemsMissing>>();
  const { t } = useTranslation(['checkout']);

  return React.useMemo(
    () =>
      new Map<string, ListItem<FailedProducts.FailedProduct<FailedProducts.ItemsMissing>>>(baseModel).set(
        t('checkout:new_quantity'),
        ({ failedProductData: { items }, itemTable }) => {
          const data = items.map(item => item.new);
          const quantitySum = data.reduce((sum, { quantity }) => sum + quantity, 0);
          const table = itemsToChart(data, itemTable);

          return <ItemsQuantityCell quantity={quantitySum} table={table} />;
        },
      ),
    [t, baseModel],
  );
};

export const useMissingProductsWithoutDataModel = () => {
  const { t } = useTranslation(['checkout']);

  return React.useMemo(
    () =>
      new Map<string, ListItem<FailedProducts.FailedProductWithoutData>>()
        .set('Product id', ({ failedProductData }) => <td>{failedProductData.productId}</td>)
        .set('Variant id', ({ failedProductData }) => <td>{failedProductData.variantId}</td>)
        .set(t('checkout:original_quantity'), ({ failedProductData: { items } }) => {
          const data = items.map(item => item.original);
          const quantitySum = data.reduce((sum, { quantity }) => sum + quantity, 0);

          return <td>{quantitySum}</td>;
        }),
    [t],
  );
};

export const useCancelledItemsModel = () => {
  const baseModel = useBaseModel();

  return React.useMemo(() => {
    return new Map<string, ListItem<FailedProducts.FailedProduct<FailedProducts.ItemsCancelled>>>(baseModel);
  }, [baseModel]);
};
