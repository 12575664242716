import { Cms } from '@typings';
import { assocPath } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { EMPTY_TEXT_CONTENT } from '../../../constants/cms';
import { isButtonPart } from '../../../logic/cms/styles';
import { getContentSetStyles } from '../../../logic/pages';
import { isDefined } from '../../../utils/is';
import { TextPart } from '../../pages/contentBlock/parts/ContentSet/Text/TextPart';

import styles from './AddTextTemplateModal.module.scss';

interface Props {
  blockId: Nullable<string>;
  text: Cms.ContentBlockText;
  handleAddTemplate: (textTemplate: Cms.ContentBlockText) => void;
}

const DESKTOP_SCREEN_WIDTH = 1400;
const EMPTY_TEMPLATE_COLOR = '#848484';

export const TextThumbnail = ({ text, handleAddTemplate, blockId }: Props) => {
  const { t } = useTranslation(['cms']);
  const handleClick = React.useCallback(() => handleAddTemplate(text), [text, handleAddTemplate]);

  const style = getContentSetStyles(DESKTOP_SCREEN_WIDTH, text);

  return (
    <button className={styles.thumbnail} onClick={handleClick}>
      <div className={styles.thumbnailContent} style={style}>
        {text.partsOrder.map((partId, idx) => {
          const part = text.parts[partId];

          const general = part?.general;

          if (isButtonPart(part) || !isDefined(general)) {
            return null;
          }

          const parameters = assocPath(
            ['general'],
            general.content === EMPTY_TEXT_CONTENT ?
              {
                ...general,
                ...{
                  content: t('cms:empty_text_template'),
                  textColor: EMPTY_TEMPLATE_COLOR,
                },
              }
            : general,
            part,
          );

          if (!isDefined(parameters)) {
            return null;
          }

          return <TextPart key={idx} parameters={parameters} screenWidth={DESKTOP_SCREEN_WIDTH} index={idx} blockId={blockId} />;
        })}
      </div>
    </button>
  );
};
