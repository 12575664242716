import React from 'react';
import { Location, useLocation } from 'react-router-dom';

import { history } from '../../store/store';

interface Props {
  isEnabled: boolean;
  message: string;
  shouldBlock?: (location: Location) => boolean;
}

export const usePrompt = ({ isEnabled, message, shouldBlock }: Props) => {
  const location = useLocation();

  React.useEffect(() => {
    if (!isEnabled) {
      return;
    }

    const unblock = history.block(transition => {
      if (!shouldBlock?.(transition.location)) {
        unblock();
        transition.retry();

        return;
      }

      if (window.confirm(message)) {
        unblock();
        transition.retry();
      }
    });

    return () => {
      unblock();
    };
  }, [isEnabled, location.key, message, shouldBlock]);
};
