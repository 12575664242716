import { Cms, Id, Translations } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHover, useLayer } from 'react-laag';

import universalStyles from '../../../../../../../css/utilities/universal.module.scss';
import { LANGUAGE_NAME_MAP } from '../../../../../../constants/translation';
import { usePagePublicationActions } from '../../../../../../utils/hooks';
import { useCloseMenu } from '../../../../../../utils/hooks/popoverMenu/useCloseMenu';
import { isDefined } from '../../../../../../utils/is';
import { Checkbox } from '../../../../../various/Fields/Checkbox';
import { Label } from '../../../../../various/PopoverMenu/MenuElements/Label';
import { MenuButton } from '../../../../../various/PopoverMenu/MenuElements/MenuButton';
import { NestedItems } from '../../../../../various/PopoverMenu/MenuElements/NestedItems';
import popoverMenuStyles from '../../../../../various/PopoverMenu/PopoverMenu.module.scss';
import { LanguageFlag } from '../../../../LanguageFlag';

import { LanguageItemDescription } from './LanguageItemDescription';
import styles from './PublishLanguageItem.module.scss';

interface Props {
  language: Translations.SupportedLanguagesCodes;
  status: Cms.PageStatus;
  lastModified: Cms.ModificationDetails;
  lastPublished: Cms.ModificationDetails;
  isChecked: boolean;
  isDisabled: boolean;
  onChange: (language: Translations.SupportedLanguagesCodes, isChecked: boolean) => void;
  closeMenu: () => void;
  pageId: Id;
}

export const PublishLanguageItem = (props: Props) => {
  const { language, status, lastModified, lastPublished, isChecked, isDisabled, pageId, onChange, closeMenu } = props;

  const { t } = useTranslation(['cms', 'cmsConfirmationConfig']);
  const [isOpen, setIsOpen] = React.useState(false);

  const [hasCursor, hoverProps] = useHover();

  const toggleOpen = React.useCallback(() => {
    setIsOpen(opened => !opened);
  }, []);

  const handleClose = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  useCloseMenu({
    handleClose,
    hasCursor,
  });

  const { renderLayer, triggerProps, layerProps } = useLayer({
    auto: true,
    isOpen,
    onParentClose: handleClose,
    placement: 'right-start',
    possiblePlacements: ['right-start', 'right-center', 'right-end', 'left-start', 'left-center', 'left-end'],
    triggerOffset: 4,
  });

  const { secondaryMenuActions } = usePagePublicationActions({
    closeMenu: handleClose,
    languages: [language],
    lastPublishedDate: isDefined(lastPublished) ? lastPublished.date : undefined,
    pageId,
    status,
  });

  const languageName = LANGUAGE_NAME_MAP[language];

  const handleChange = React.useCallback(() => {
    onChange(language, !isChecked);
  }, [isChecked, language, onChange]);

  const flagStatus = isChecked ? 'published' : status;

  return (
    <>
      <li {...triggerProps} className={popoverMenuStyles.menuItem}>
        <Checkbox className={styles.languageCheckbox} checked={isChecked} disabled={isDisabled} onChange={handleChange}>
          <span className={universalStyles.srOnly}>{t('cms:mark_for_publication', { language: languageName })}</span>
        </Checkbox>
        <div {...hoverProps} className={styles.menuButtonWrapper}>
          <MenuButton isParent onClick={toggleOpen} buttonClassName={styles.menuButton}>
            <Label
              className={styles.languageLabel}
              containerClassName={styles.languageLabelContainer}
              descriptionClassName={styles.languageDescription}
              description={<LanguageItemDescription lastModified={lastModified} lastPublished={lastPublished} status={status} />}
            >
              {languageName}
              <LanguageFlag code={language} status={flagStatus} />
            </Label>
          </MenuButton>
        </div>
      </li>
      {isOpen &&
        renderLayer(
          <NestedItems
            groupLabel={t('cms:language_version', { language: languageName })}
            layerProps={layerProps}
            hoverProps={hoverProps}
            items={secondaryMenuActions}
            closeMenu={closeMenu}
          />,
        )}
    </>
  );
};
