import { UniqueIdentifier } from '@dnd-kit/core';
import cx from 'classnames';
import { forwardRef, HTMLAttributes } from 'react';

import Icon, { IconType } from '../../../Icon';

import styles from './SortableItem.module.scss';

export interface Props extends Omit<HTMLAttributes<HTMLLIElement>, 'id'> {
  childCount?: number;
  isDragOverlay?: boolean;
  depth: number;
  disableInteraction?: boolean;
  disableSelection?: boolean;
  isDragging?: boolean;
  handleProps?: any;
  cannotDrop?: boolean;
  highlightParent?: boolean;
  indentationWidth: number;
  value: UniqueIdentifier;
  wrapperRef?(node: HTMLLIElement): void;
  handleRef?(node: HTMLElement): void;
  render: (id: UniqueIdentifier) => JSX.Element | null;
  renderNestPlaceholder: (id: UniqueIdentifier) => JSX.Element | null;
}

export const Item = forwardRef<HTMLDivElement, Props>(
  (
    {
      childCount = 0,
      isDragOverlay,
      depth,
      disableSelection,
      disableInteraction,
      isDragging,
      handleProps,
      indentationWidth,
      style,
      value,
      handleRef,
      wrapperRef,
      render,
      renderNestPlaceholder,
      cannotDrop = false,
      highlightParent = false,
    },
    ref,
  ) => {
    return (
      <li
        className={cx(styles.container, {
          [styles.dragOverlay]: isDragOverlay,
          [styles.ghost]: isDragging,
          [styles.invalid]: cannotDrop,
          [styles.disableInteraction]: disableInteraction,
          [styles.disableSelection]: disableSelection,
        })}
        ref={wrapperRef}
      >
        <div ref={ref} style={style}>
          <div
            className={cx(styles.item, {
              [styles.disabled]: disableInteraction,
              [styles.dragging]: isDragging,
              [styles.dragParent]: highlightParent,
            })}
            style={{
              marginLeft: `${indentationWidth * depth}px`,
            }}
          >
            <div ref={handleRef} className={cx(styles.handle)} {...handleProps} role="none">
              <Icon type={IconType.Reorder} />
            </div>
            <div className={styles.content}>{render(value)}</div>
            {childCount > 0 && (
              <div className={styles.counterIcon}>
                <Icon type={IconType.Multiples} />
                <span className={styles.counter}>{childCount + 1}</span>
              </div>
            )}
          </div>
          {!isDragging && renderNestPlaceholder?.(value)}
        </div>
      </li>
    );
  },
);
