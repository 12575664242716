import { Addresses } from '@typings';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { SHIPPING_ADDRESS_DEFAULT_VALUES } from '../../../../../constants/address';
import { useShippingCountries } from '../../../../../services/hooks/account/useShippingCountries';
import { useShippingCountryOptions } from '../../../../../utils/hooks/countries/useShippingCountryOptions';
import { useValidatedForm } from '../../../../../utils/hooks/useValidatedForm';
import { isDefined } from '../../../../../utils/is';
import { isEmpty } from '../../../../../utils/isEmpty';
import { getContactPersonSchema, getPhoneSchema, getStateSchema } from '../../../../../utils/schemas';
import { Option } from '../../../../various/Fields/Select';
import { AutocompleteField, Form, FormButtons, FormFieldset, FormGrid, InputField } from '../../../../various/Form';
import { Button } from '../../../../various/NewButton';

interface Props {
  defaultValues?: Addresses.ShippingAddressDTO;
  isLoading: boolean;
  onCancel: () => void;
  onSubmit: (data: Addresses.ShippingAddressDTO) => void;
}

export const ShippingAddressForm = ({ defaultValues, isLoading, onCancel, onSubmit }: Props) => {
  const { t } = useTranslation(['common', 'shipping', 'validation']);
  const { data: shippingCountries, isLoading: isLoadingCountries } = useShippingCountries();

  const validationSchema: yup.ObjectSchema<Addresses.ShippingAddressDTO> = yup.object({
    address1: yup.string().required(t('validation:address_hint')),
    address2: yup.string().defined(),
    city: yup.string().required(t('validation:city_hint')),
    company: yup.string().required(t('validation:company_name_hint')),
    contactPerson: getContactPersonSchema(),
    country: yup.string().required(t('validation:country_hint')),
    email: yup.string().defined().email(t('validation:invalid_email_hint')),
    phoneNumber: getPhoneSchema(),
    state: getStateSchema(shippingCountries?.countries ?? {}),
    zipCode: yup.string().required(t('validation:zip_code_hint')),
  });

  const formMethods = useValidatedForm<Addresses.ShippingAddressDTO>({
    defaultValues: defaultValues ?? SHIPPING_ADDRESS_DEFAULT_VALUES,
    validationSchema,
  });

  const { register, setValue, watch } = formMethods;
  const selectedCountry = watch('country');

  const handleCountryChange = () => {
    setValue('state', null);
  };

  const { countryOptions, stateOptions } = useShippingCountryOptions({ country: selectedCountry });

  return (
    <Form formMethods={formMethods} onSubmit={onSubmit}>
      <FormFieldset isDisabled={isLoading}>
        <FormGrid cols={2}>
          <InputField label={t('common:company')} isRequired {...register('company')} />
          <InputField label={t('common:contact_person')} {...register('contactPerson')} />
          <InputField label={t('common:email')} type="email" {...register('email')} />
          <InputField label={t('common:phone_number')} type="tel" {...register('phoneNumber')} />
          <InputField label={t('common:address')} isRequired {...register('address1')} />
          <InputField label={t('common:address_continued')} {...register('address2')} />
          <InputField label={t('common:city')} isRequired {...register('city')} />
          <InputField label={t('common:zip_code')} isRequired {...register('zipCode')} />
          <AutocompleteField
            name="country"
            label={t('common:country')}
            isLoading={isLoadingCountries}
            isRequired
            onChange={handleCountryChange}
          >
            {countryOptions.map(({ country, name }) => (
              <Option key={country} value={country}>
                {name}
              </Option>
            ))}
          </AutocompleteField>
          {!isEmpty(stateOptions) && (
            <AutocompleteField name="state" label={t('common:state')} isRequired>
              {stateOptions.map(({ state, name }) => (
                <Option key={state} value={state}>
                  {name}
                </Option>
              ))}
            </AutocompleteField>
          )}
        </FormGrid>
        <FormButtons showDivider>
          <Button size="large" variant="ghost" color="dark" onClick={onCancel}>
            {t('common:cancel')}
          </Button>
          <Button type="submit" size="large" isLoading={isLoading}>
            {isDefined(defaultValues) ? t('common:save_changes') : t('shipping:add')}
          </Button>
        </FormButtons>
      </FormFieldset>
    </Form>
  );
};
