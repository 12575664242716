import { Cms, Id, Navigation } from '@typings';
import React from 'react';

import { flattenNavigationItems } from '../../../../logic/navigation';
import {
  getIsFolderMenuItem,
  getIsLinkMenuItem,
  getIsPageAvailable,
  getIsPageMenuItem,
  getPublishedPageData,
} from '../../../../logic/pages';
import { usePages } from '../../../../services/hooks/pages/usePages';
import { isDefined } from '../../../../utils/is';
import { isEmpty } from '../../../../utils/isEmpty';
import { listToRecord } from '../../../../utils/normalize';

type WarningsOrderKeys = 'missingMarket' | 'missingPage';
type WarningsOrder = Record<WarningsOrderKeys, number>;

interface Context {
  isInEditMode: boolean;
  isLoadingPages: boolean;
  isMarketsMismatch: boolean;
  isUnavailablePage: boolean;
  areInputsDisabled: boolean;
  childIdsWithMarketsMismatch: Id[];
  setIsInEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  selectedMarket: Nullable<Id>;
  setSelectedMarket: React.Dispatch<React.SetStateAction<Nullable<string>>>;
  addedMenuItems: Navigation.AnyMenuItem[];
  setAddedMenuItems: React.Dispatch<React.SetStateAction<Navigation.AnyMenuItem[]>>;
  searchPhrase: string;
  setSearchPhrase: React.Dispatch<React.SetStateAction<string>>;
  itemRecords: Record<string, Navigation.AnyMenuItem>;
  pagesRecords: Record<string, Cms.Page>;
  hasItemWithoutMarkets: boolean;
  getItemMarketsIds: (item: Navigation.AnyMenuItem) => Id[];
  warningsOrder: WarningsOrder;
}

export const MenuSectionContext = React.createContext<Context | null>(null);
export const useMenuSectionContext = (): Context => {
  const context = React.useContext(MenuSectionContext);
  if (context === null) {
    throw new Error('MenuSectionContext can not be used outside the scope of MenuSectionContextProvider');
  }

  return context;
};
export const MenuSectionContextProvider = ({ children }: React.WithChildren) => {
  const [isInEditMode, setIsInEditMode] = React.useState(false);
  const [selectedMarket, setSelectedMarket] = React.useState<Nullable<Id>>(null);
  const [addedMenuItems, setAddedMenuItems] = React.useState<Navigation.AnyMenuItem[]>([]);
  const [searchPhrase, setSearchPhrase] = React.useState('');

  const warningsOrderMap = new Map<WarningsOrderKeys, number>();
  warningsOrderMap.set('missingMarket', 0);
  warningsOrderMap.set('missingPage', 0);

  const { data: pagesList = [], isLoading: isLoadingPages } = usePages({ search: '' });
  const pagesRecords = listToRecord(pagesList, 'id');

  const areInputsDisabled = !isInEditMode || isDefined(selectedMarket);

  const itemRecords = React.useMemo(() => {
    return listToRecord(flattenNavigationItems(addedMenuItems), 'id');
  }, [addedMenuItems]);

  const itemsWithOrderIndex = React.useMemo(() => addedMenuItems.flatMap((item, idx) => ({ ...item, orderIndex: idx })), [addedMenuItems]);

  const isUnavailablePage = React.useMemo(() => {
    if (isEmpty(pagesRecords)) {
      return false;
    }

    return itemsWithOrderIndex
      .filter(item => getIsPageMenuItem(item))
      .some(page => {
        const isAvailable = getIsPageAvailable(getPublishedPageData(pagesRecords[page.pageId]));
        if (!isAvailable && !isInEditMode) {
          warningsOrderMap.set('missingPage', page.orderIndex);
        }

        return !isAvailable;
      });
  }, [itemsWithOrderIndex, pagesRecords]);

  const hasItemWithoutMarkets = React.useMemo(() => {
    if (isEmpty(pagesRecords)) {
      return false;
    }

    return itemsWithOrderIndex
      .filter(item => getIsLinkMenuItem(item) || getIsPageMenuItem(item))
      .some(link => !isDefined(link.markets) || isEmpty(link.markets));
  }, [itemsWithOrderIndex, pagesRecords]);

  const getItemMarketsIds = React.useCallback(
    (item: Navigation.AnyMenuItem) => {
      if (!getIsPageMenuItem(item)) {
        return item.markets ?? [];
      }

      const page = getPublishedPageData(pagesRecords[item.pageId]);
      if (isDefined(page)) {
        return page.markets.map(market => market.id);
      }

      return [];
    },
    [pagesRecords],
  );

  const childIdsWithMarketsMismatch = React.useMemo(() => {
    if (isEmpty(pagesRecords)) {
      return [];
    }

    const itemsIdsSet: Set<Id> = new Set();
    itemsWithOrderIndex
      .filter(item => isDefined(item.children) && !isEmpty(item.children))
      .forEach(parent => {
        const isFolder = getIsFolderMenuItem(parent);

        if (isFolder) {
          return;
        }

        const isPageAvailable = getIsPageAvailable(getPublishedPageData(pagesRecords[parent.pageId]));

        if (!isPageAvailable) {
          return;
        }

        const parentMarkets = getItemMarketsIds(parent);
        const { children: childrenItems } = parent;

        childrenItems?.forEach(child => {
          const childMarkets = getItemMarketsIds(child);
          const hasCommonMarket = parentMarkets.some(id => childMarkets.includes(id));
          if (!hasCommonMarket) {
            if (warningsOrderMap.get('missingMarket') === 0 && !isInEditMode) {
              warningsOrderMap.set('missingMarket', parent.orderIndex);
            }

            if (getIsPageMenuItem(child)) {
              itemsIdsSet.add(child.pageId);

              return;
            }

            itemsIdsSet.add(child.id);
          }
        });
      });

    return Array.from(itemsIdsSet);
  }, [pagesRecords, itemsWithOrderIndex, getItemMarketsIds]);

  return (
    <MenuSectionContext.Provider
      value={{
        addedMenuItems,
        areInputsDisabled,
        childIdsWithMarketsMismatch,
        getItemMarketsIds,
        hasItemWithoutMarkets,
        isInEditMode,
        isLoadingPages,
        isMarketsMismatch: !isEmpty(childIdsWithMarketsMismatch),
        isUnavailablePage,
        itemRecords,
        pagesRecords,
        searchPhrase,
        selectedMarket,
        setAddedMenuItems,
        setIsInEditMode,
        setSearchPhrase,
        setSelectedMarket,
        warningsOrder: Object.fromEntries(warningsOrderMap) as WarningsOrder,
      }}
    >
      {children}
    </MenuSectionContext.Provider>
  );
};
