/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 16;
const ORIGINAL_HEIGHT = 12;
const DEFAULT_WIDTH = 14;
const PROPORTION = ORIGINAL_HEIGHT / ORIGINAL_WIDTH;

export const BarChart = ({ width = DEFAULT_WIDTH }: IconComponentProps) => {
  return (
    <svg width={width} height={width * PROPORTION} viewBox="0 0 16 12" fill="none">
      <path d="M1 10C0.447715 10 0 10.4477 0 11C0 11.5523 0.447715 12 1 12L1 10ZM5 12C5.55228 12 6 11.5523 6 11C6 10.4477 5.55228 10 5 10V12ZM1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7L1 5ZM9 7C9.55229 7 10 6.55228 10 6C10 5.44772 9.55229 5 9 5L9 7ZM1 2.98023e-07C0.447715 2.98023e-07 0 0.447716 0 1C0 1.55229 0.447715 2 1 2L1 2.98023e-07ZM15 2C15.5523 2 16 1.55228 16 1C16 0.447715 15.5523 0 15 0V2ZM1 12H5V10H1L1 12ZM1 7L9 7L9 5L1 5L1 7ZM1 2L15 2V0L1 2.98023e-07L1 2Z" />
    </svg>
  );
};
