import React from 'react';

interface Props {
  groupId: string;
  sectionId: string;
}

interface Context {
  groupId: string;
  sectionId: string;
}

const initialContext: Context = {
  groupId: '',
  sectionId: '',
};

export const GroupContext = React.createContext<Context>(initialContext);

export const GroupContextProvider = ({ children, groupId, sectionId }: React.WithChildren<Props>) => {
  return <GroupContext.Provider value={{ groupId, sectionId }}>{children}</GroupContext.Provider>;
};
