/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 24;
const ORIGINAL_COLOR = '#848484';

export const Unpin = ({ width = ORIGINAL_WIDTH }: IconComponentProps) => {
  return (
    <svg height={width} width={width} viewBox="0 0 24 24" fill={ORIGINAL_COLOR}>
      <path
        fillRule="evenodd"
        d="M12.95 5.35a1.2 1.2 0 0 1 1.7 0l4 4a1.2 1.2 0 0 1 0 1.7L15.7 14a.68.68 0 1 1-.96-.95l2.83-2.85-3.78-3.78-2.6 2.61a.68.68 0 1 1-.97-.95l2.72-2.73ZM9.86 14.14c.27.26.27.69 0 .95l-3.18 3.18a.68.68 0 0 1-.95-.95l3.18-3.18c.26-.27.69-.27.95 0ZM5.75 6.23c.28-.25.7-.23.96.05l10.53 11.59a.68.68 0 0 1-1 .9L5.7 7.2a.68.68 0 0 1 .05-.96Z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M5.45 9.75a4.47 4.47 0 0 1 3.2-1.06.68.68 0 0 1-.1 1.35c-.54-.04-1.3.05-2.12.66l6.86 6.86c.3-.41.6-1 .7-1.62a.68.68 0 1 1 1.34.19 5.37 5.37 0 0 1-1.06 2.4 1.2 1.2 0 0 1-1.8.12h-.01l-7.1-7.1a1.2 1.2 0 0 1 .1-1.8Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
