import accounts from '../../../translations/fr/accounts.json';
import buyers from '../../../translations/fr/buyers.json';
import cancelled from '../../../translations/fr/cancelled.json';
import checkout from '../../../translations/fr/checkout.json';
import checkoutMessages from '../../../translations/fr/checkoutMessages.json';
import cms from '../../../translations/fr/cms.json';
import cmsConfirmationConfig from '../../../translations/fr/cmsConfirmationConfig.json';
import common from '../../../translations/fr/common.json';
import configErrors from '../../../translations/fr/configErrors.json';
import confirmationConfig from '../../../translations/fr/confirmationConfig.json';
import dates from '../../../translations/fr/dates.json';
import expiredDelwins from '../../../translations/fr/expiredDelwins.json';
import genericErrors from '../../../translations/fr/genericErrors.json';
import invoices from '../../../translations/fr/invoices.json';
import linesheets from '../../../translations/fr/linesheets.json';
import login from '../../../translations/fr/login.json';
import lookbook from '../../../translations/fr/lookbook.json';
import menu from '../../../translations/fr/menu.json';
import onboarding from '../../../translations/fr/onboarding.json';
import orders from '../../../translations/fr/orders.json';
import pagination from '../../../translations/fr/pagination.json';
import passwordManagement from '../../../translations/fr/passwordManagement.json';
import payments from '../../../translations/fr/payments.json';
import products from '../../../translations/fr/products.json';
import selectionOverview from '../../../translations/fr/selectionOverview.json';
import selections from '../../../translations/fr/selections.json';
import settings from '../../../translations/fr/settings.json';
import shipping from '../../../translations/fr/shipping.json';
import totals from '../../../translations/fr/totals.json';
import translations from '../../../translations/fr/translations.json';
import validation from '../../../translations/fr/validation.json';

export const fr = {
  accounts,
  buyers,
  cancelled,
  checkout,
  checkoutMessages,
  cms,
  cmsConfirmationConfig,
  common,
  configErrors,
  confirmationConfig,
  dates,
  expiredDelwins,
  genericErrors,
  invoices,
  linesheets,
  login,
  lookbook,
  menu,
  onboarding,
  orders,
  pagination,
  passwordManagement,
  payments,
  products,
  selectionOverview,
  selections,
  settings,
  shipping,
  totals,
  translations,
  validation,
} as const;
