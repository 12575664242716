import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { compiledPaths } from '../../../paths';
import { EmptyState } from '../../various/EmptyState';
import { Button } from '../../various/NewButton';
import { Wrapper, WrapperSize } from '../../various/Wrapper';

import styles from './CheckoutNoUnits.module.scss';

export const CheckoutNoUnits = () => {
  const { t } = useTranslation(['checkout', 'common']);
  const { id: orderId } = useParams<{ id?: string }>();

  return (
    <Wrapper size={WrapperSize.LARGE} className={styles.noResultsWrapper}>
      <EmptyState title={t('checkout:no_units.title')} subtitle={t('checkout:no_units.subtitle')}>
        <Button as={Link} to={compiledPaths.ORDER_DETAILS({ id: orderId })} variant="bordered" color="dark">
          {t('common:select_units')}
        </Button>
      </EmptyState>
    </Wrapper>
  );
};
