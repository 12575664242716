export const GridTemplate = () => {
  return (
    <svg fill="none" height="80" viewBox="0 0 112 80" width="112">
      <path d="m0 0h112v80h-112z" fill="#fff" />
      <path d="m.5.5h111v79h-111z" fill="#fff" stroke="#d9d9d9" />
      <path d="m7.14893 31.2568h6.71567v6.77595h-6.71567z" fill="#bababa" />
      <path d="m15.8142 31.2568h9.53191v4.153h-9.53191z" fill="#d9d9d9" />
      <path d="m7.14893 40.4371h24.0464v7.10382h-24.0464z" fill="#d9d9d9" />
      <path d="m7.14893 55.3005h6.71567v6.77596h-6.71567z" fill="#bababa" />
      <path d="m15.8142 55.3005h9.53191v4.153h-9.53191z" fill="#d9d9d9" />
      <path d="m7.14893 64.4809h24.0464v7.10383h-24.0464z" fill="#d9d9d9" />
      <path d="m42.0271 7.21311h6.71567v6.77596h-6.71567z" fill="#bababa" />
      <path d="m50.6924 7.21311h9.53191v4.153h-9.53191z" fill="#d9d9d9" />
      <path d="m42.0271 16.3934h24.0464v7.10383h-24.0464z" fill="#d9d9d9" />
      <path d="m42.0271 31.2568h6.71567v6.77595h-6.71567z" fill="#bababa" />
      <path d="m50.6924 31.2568h9.53191v4.153h-9.53191z" fill="#d9d9d9" />
      <path d="m42.0271 40.4371h24.0464v7.10382h-24.0464z" fill="#d9d9d9" />
      <path d="m42.0271 55.3005h6.71567v6.77596h-6.71567z" fill="#bababa" />
      <path d="m50.6924 55.3005h9.53191v4.153h-9.53191z" fill="#d9d9d9" />
      <path d="m42.0271 64.4809h24.0464v7.10383h-24.0464z" fill="#d9d9d9" />
      <path d="m76.9053 7.21311h6.71567v6.77596h-6.71567z" fill="#bababa" />
      <path d="m85.5706 7.21311h9.53191v4.153h-9.53191z" fill="#d9d9d9" />
      <path d="m76.9053 16.3934h24.0464v7.10383h-24.0464z" fill="#d9d9d9" />
      <path d="m76.9053 31.2568h6.71567v6.77595h-6.71567z" fill="#bababa" />
      <path d="m85.5706 31.2568h9.53191v4.153h-9.53191z" fill="#d9d9d9" />
      <path d="m76.9053 40.4371h24.0464v7.10382h-24.0464z" fill="#d9d9d9" />
      <path d="m76.9053 55.3005h6.71567v6.77596h-6.71567z" fill="#bababa" />
      <path d="m85.5706 55.3005h9.53191v4.153h-9.53191z" fill="#d9d9d9" />
      <path d="m76.9053 64.4809h24.0464v7.10383h-24.0464z" fill="#d9d9d9" />
      <path d="m7.14893 7.21311h6.71567v6.77596h-6.71567z" fill="#bababa" />
      <g fill="#d9d9d9">
        <path d="m15.8142 7.21311h9.53191v4.153h-9.53191z" />
        <path d="m7.14893 16.3934h24.0464v7.10383h-24.0464z" />
      </g>
    </svg>
  );
};
