import { Order, Product } from '@typings';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getAllDeliveryWindows } from '../../../ducks';
import { getDeliveryWindowsByIds } from '../../../logic/deliveryWindows';
import { useAvailableOrderDetails } from '../../../utils/hooks/useAvailableOrderDetails';
import { SkeletonLayout } from '../../various/SkeletonLayout';
import { ExpandableDeliveryWindowSkeleton } from '../../various/SkeletonLayout/layouts/ExpandableDeliveryWindowSkeleton';

import styles from './CheckoutProductsReceipt.module.scss';
import { ExpandableProductOverview } from './ExpandableDeliveryWindow';
import { ReceiptRow } from './ReceiptRow';

interface Props {
  order: Order.Open;
  products: never[] | Record<Product.Id, Product.Full>;
  isLoading: boolean;
}

export const CheckoutProductsReceipt = ({ order, products, isLoading }: Props) => {
  const { t } = useTranslation(['common', 'totals']);
  const { deliveryWindowsIds } = useAvailableOrderDetails();

  const allDeliveryWindows = useSelector(getAllDeliveryWindows);

  const deliveryWindows = getDeliveryWindowsByIds(allDeliveryWindows, deliveryWindowsIds ?? []);

  return (
    <div>
      <ReceiptRow className={styles.headers}>
        <div className={styles.subtle}>{t('common:delivery_window_one')}</div>
        <div className={styles.subtle}>{t('common:unit_other')}</div>
        <div className={styles.subtle}>{t('totals:subtotal')}</div>
      </ReceiptRow>

      <div className={styles.body}>
        <SkeletonLayout isLoading={isLoading} skeleton={<ExpandableDeliveryWindowSkeleton />}>
          {deliveryWindows.map(deliveryWindow => (
            <ExpandableProductOverview
              key={deliveryWindow.deliveryWindow}
              order={order}
              deliveryWindow={deliveryWindow}
              products={products}
            />
          ))}
        </SkeletonLayout>
      </div>
    </div>
  );
};
