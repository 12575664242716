import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getConfiguration } from '../../../../ducks';
import { useProductsInRow } from '../../../../utils/hooks/useProductsInRow';
import { LoadData } from '../../../various/loaders/LoadData/LoadData';
import { SkeletonLayout } from '../../../various/SkeletonLayout';
import { ProductSkeleton } from '../../../various/SkeletonLayout/layouts/ProductSkeleton';

interface Props {
  totalItems: number;
  loadedCount: number;
  pageCount: number;
  isLoadingProducts: boolean;
  isLoadingMoreProducts: boolean;
  isGridView: boolean;
  onLoadMore: VoidFunction;
}

const LIST_VIEW_SKELETON_COUNT = 1;

export const LoadMoreProducts = ({
  isLoadingProducts,
  isLoadingMoreProducts,
  isGridView,
  onLoadMore,
  loadedCount,
  totalItems,
  pageCount,
}: Props) => {
  const { t } = useTranslation(['products']);
  const config = useSelector(getConfiguration);
  const productsInRow = useProductsInRow();

  const canLoadMoreProducts = !isLoadingProducts && totalItems > config.productsPerRequest * pageCount;

  const loadMoreProducts = React.useCallback(() => {
    onLoadMore();
  }, [onLoadMore]);

  const rowFillSkeletonCount = productsInRow - (loadedCount % productsInRow);
  const gridSkeletonsCount = rowFillSkeletonCount === productsInRow ? productsInRow : rowFillSkeletonCount + productsInRow;

  const layoutRepeat = isGridView ? gridSkeletonsCount : LIST_VIEW_SKELETON_COUNT;

  if (!canLoadMoreProducts) {
    return null;
  }

  return (
    <>
      {isLoadingMoreProducts && (
        <SkeletonLayout skeleton={<ProductSkeleton isGridView={isGridView} />} repeatLayout={layoutRepeat} isLoading />
      )}
      <LoadData
        title={t('products:loading_more_products')}
        isLoading={isLoadingMoreProducts}
        onLoadMore={loadMoreProducts}
        showLoader={false}
      />
    </>
  );
};
