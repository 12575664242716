/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;
const ORIGINAL_COLOR = '#5d5d5d';

export const GroupByDelwin = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => (
  <svg width={width} height={width} viewBox="0 0 20 20" fill={color}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.52778 6H17.9722C18.95 6 19.75 6.77143 19.75 7.71429V16.2857C19.75 17.2286 18.95 18 17.9722 18H5.52778C4.55 18 3.75 17.2286 3.75 16.2857V7.71429C3.75 6.77143 4.55 6 5.52778 6ZM6.41701 16.2858H17.0837C17.5726 16.2858 17.9726 15.9 17.9726 15.4286V8.57147C17.9726 8.10004 17.5726 7.71432 17.0837 7.71432H6.41701C5.92813 7.71432 5.52813 8.10004 5.52813 8.57147V15.4286C5.52813 15.9 5.92813 16.2858 6.41701 16.2858Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.25 3.71429L16.25 6L14.4719 6L14.4719 4.57139C14.4719 4.09996 14.0719 3.71425 13.583 3.71425L2.91632 3.71425C2.42743 3.71425 2.02743 4.09996 2.02743 4.57139L2.02743 11.4285C2.02743 11.9 2.42743 12.2857 2.91632 12.2857L3.75 12.2857L3.75 14L2.02778 14C1.05 14 0.25 13.2286 0.25 12.2857L0.250001 3.71428C0.250001 2.77143 1.05 2 2.02778 2L14.4722 2C15.45 2 16.25 2.77143 16.25 3.71429Z"
    />
  </svg>
);
