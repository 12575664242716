import { Order } from '@typings';
import React from 'react';
import { useSelector } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import fadeIn from '../../../../css/utilities/fadeIn.module.scss';
import { getIsLoggedIn, getOpenSelections, getOrderDetails } from '../../../ducks';
import { getAccountNameOrFullName } from '../../../utils/getAccountNameOrFullName';
import { useAvailableOrderDetails } from '../../../utils/hooks/useAvailableOrderDetails';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import Icon, { IconType } from '../../various/Icon';
import { Skeleton } from '../../various/Skeleton';
import { SkeletonLayout } from '../../various/SkeletonLayout';
import { TextEllipsis } from '../../various/TextEllipsis';

import styles from './OrderSelect.module.scss';
import { ORDER_SELECT_ID, OrderSelectPopup } from './OrderSelectPopup';

export const OrderSelect = React.memo(() => {
  const { order } = useSelector(getOrderDetails) as Partial<Order.Single>;
  const isLoggedIn = useSelector(getIsLoggedIn);
  const [isSelectPopupVisible, setIsSelectPopupVisible] = React.useState(false);
  const openSelections = useSelector(getOpenSelections);
  const tiggerRef = React.useRef<HTMLButtonElement>(null);

  const { accountName, buyerFirstName, buyerLastName, orderName, orderNumber, hasAnyOrderData } = useAvailableOrderDetails();

  const accountBuyerName = getAccountNameOrFullName(accountName, buyerFirstName, buyerLastName);

  const handleClick = React.useCallback(() => {
    setIsSelectPopupVisible(value => !value);
  }, [isLoggedIn]);

  const formattedOrderNumber = React.useMemo(
    () => (
      <>
        #{orderNumber}&nbsp;
        {!isEmpty(orderName) && (
          <TextEllipsis noWrapText={false} className={styles.orderName}>
            {orderName}
          </TextEllipsis>
        )}
        <TextEllipsis noWrapText={false} className={styles.account} data-sentry-mask>
          {accountBuyerName}
        </TextEllipsis>
      </>
    ),
    [orderNumber, orderName, accountBuyerName],
  );

  const onPopupClose = React.useCallback(() => {
    setIsSelectPopupVisible(false);
  }, []);

  return (
    <div className={styles.container}>
      <SkeletonLayout isLoading={!hasAnyOrderData} skeleton={<Skeleton color="dark" type="text" itemClassName={styles.selectSkeleton} />}>
        <TextEllipsis
          as="button"
          overflowHidden={false}
          data-testid="openOrderSelectPopupButton"
          className={styles.orderSelect}
          onClick={handleClick}
          ref={tiggerRef}
          aria-expanded={isSelectPopupVisible}
          aria-controls={ORDER_SELECT_ID}
          disabled={!isLoggedIn}
        >
          <div className={styles.order} data-testid="orderName">
            {formattedOrderNumber}
          </div>
          {isLoggedIn && (
            <div className={styles.toggle}>
              <Icon type={IconType.Arrows} />
            </div>
          )}
        </TextEllipsis>
      </SkeletonLayout>
      <TransitionGroup>
        {isSelectPopupVisible && isDefined(order) && (
          <CSSTransition classNames={fadeIn} timeout={{ enter: 150, exit: 150 }}>
            <OrderSelectPopup
              trigger={tiggerRef.current}
              onClose={onPopupClose}
              orders={openSelections}
              currentWorkingOrder={order}
              key="orderPopup"
            />
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>
  );
});
