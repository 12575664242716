import { ButtonVariant, Cms } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Location, useParams } from 'react-router-dom';

import {
  editPageSuccess,
  getHasUnsavedChanges,
  getIsPageSaved,
  getIsSavingPage,
  getPageMetaData,
  savePageRequest,
  setPageMeta,
  validateSectionsMarkets,
} from '../../../ducks';
import { paths } from '../../../paths';
import { useEditPage } from '../../../services/hooks/pages/useEditPage';
import { useModalVisibility } from '../../../utils/hooks';
import { usePagePreview } from '../../../utils/hooks/cms/usePagePreview';
import { usePrompt } from '../../../utils/hooks/usePrompt';
import { isDefined } from '../../../utils/is';
import Button from '../../various/Button';
import Icon, { ArrowThinLeft, IconType } from '../../various/Icon';
import { IconButton } from '../../various/IconButton/IconButton';
import { Link } from '../../various/Link';
import { TextEllipsis } from '../../various/TextEllipsis';
import { Tooltip } from '../../various/Tooltip';
import { EditorLanguageContext } from '../context/EditorLanguageContext';
import { EditorWidthSwitcher } from '../EditorWidthSwitcher';
import { PageFormModal } from '../PageFormModal';
import { PageStatus } from '../PageStatus/PageStatus';
import { SegmentationInfo } from '../segmentationInfo';

import { PublishButton } from './PublishButton';
import styles from './TopBar.module.scss';

export const EditorTopBar = () => {
  const { pageId } = useParams<{ pageId: string }>();
  const { editorLanguage } = React.useContext(EditorLanguageContext);

  const { t } = useTranslation(['cmsConfirmationConfig', 'common', 'cms']);
  const dispatch = useDispatch();
  const { isModalVisible, hideModal, showModal } = useModalVisibility('EditPageFormModal');

  const pageMeta = useSelector(getPageMetaData);
  const isSavingPage = useSelector(getIsSavingPage);
  const isPageSaved = useSelector(getIsPageSaved);
  const hasUnsavedChanges = useSelector(getHasUnsavedChanges);

  const { mutate: editPage, isPending: isSavingPageMeta } = useEditPage();
  const { navigateToPreview } = usePagePreview();

  const getShouldBlockNavigation = React.useCallback(
    (location: Location) => {
      return !location.pathname.startsWith(`/cms/${pageId}`);
    },
    [pageId],
  );

  usePrompt({
    isEnabled: hasUnsavedChanges,
    message: t('cmsConfirmationConfig:leave_with_unsaved_changes.content'),
    shouldBlock: getShouldBlockNavigation,
  });

  const handleSavePage = React.useCallback(() => {
    if (isDefined(pageId)) {
      dispatch(savePageRequest({ id: pageId, lang: editorLanguage }));
    }
  }, [dispatch, pageId, editorLanguage]);

  const handleOpenPreview = React.useCallback(() => {
    if (!isDefined(pageMeta)) {
      return;
    }

    navigateToPreview(editorLanguage);
  }, [pageMeta, navigateToPreview, editorLanguage]);

  const handleSubmit = React.useCallback(
    (data: Cms.PageDTO) => {
      if (!isDefined(pageMeta)) {
        return;
      }

      editPage(
        { data, pageId: pageMeta.id },
        {
          onSuccess: page => {
            const pageMarketIds = page.markets.map(market => market.id);

            hideModal();
            dispatch(editPageSuccess({ data: page }));
            dispatch(validateSectionsMarkets({ pageMarketIds }));
            dispatch(setPageMeta(page));
          },
        },
      );
    },
    [dispatch, editPage, hideModal, pageMeta],
  );

  const isPageSavedAndWithoutChanges = isPageSaved && !hasUnsavedChanges;

  if (!isDefined(pageMeta)) {
    return null;
  }

  const { availableLanguages, languages, markets, name, slug, description, status } = pageMeta;

  const defaultValues = {
    availableLanguages,
    description,
    marketIds: markets.map(({ id }) => id),
    name,
    slug,
  };

  const baseVariants: ReadonlyArray<ButtonVariant> = ['button', 'transparent', 'small'];
  const saveButtonVariants: ReadonlyArray<ButtonVariant> =
    isPageSavedAndWithoutChanges ? [...baseVariants, 'withDisabledText'] : baseVariants;

  return (
    <>
      <div className={styles.topBar}>
        <div className={styles.info}>
          <Link to={paths.PAGES_LIST} className={styles.link}>
            <ArrowThinLeft />
            <span className={styles.returnButton}>{t('common:page_other')}</span>
          </Link>

          <div className={styles.editorStatus}>
            <Tooltip content={name} placement="bottom-center">
              <span>
                <TextEllipsis lines={2} noWrapText={false} className={styles.pageTitle}>
                  {name}
                </TextEllipsis>
              </span>
            </Tooltip>

            {isDefined(pageMeta) && <PageStatus status={status} />}
          </div>

          <SegmentationInfo pageMeta={pageMeta} />
        </div>

        <EditorWidthSwitcher />

        <div className={styles.actionButtons}>
          <Button variant={['button', 'small']} onClick={handleOpenPreview}>
            <span className={styles.previewButtonContent}>
              <Icon type={IconType.Visibility} />
              {t('common:preview')}
            </span>
          </Button>

          <Button
            data-testid="savePage"
            variant={saveButtonVariants}
            isLoading={isSavingPage}
            onClick={handleSavePage}
            disabled={isPageSavedAndWithoutChanges || isSavingPage}
          >
            <span>{isPageSavedAndWithoutChanges ? t('common:saved') : t('common:save')}</span>
          </Button>

          <PublishButton pageMeta={pageMeta} />

          <IconButton
            icon={IconType.Cog}
            name="Cog"
            variant="secondary-light"
            onClick={showModal}
            className={styles.cogButton}
            highlighted
          />
        </div>
      </div>

      <PageFormModal
        isOpen={isModalVisible}
        pageLanguages={languages}
        defaultValues={defaultValues}
        isLoading={isSavingPageMeta}
        onClose={hideModal}
        onSubmit={handleSubmit}
        title={t('cms:page_settings')}
      />
    </>
  );
};
