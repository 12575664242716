import { Checkout, Id, Invoice, Payments } from '@typings';

import { showroomApi } from '../services/api';

export const getRequestInvoicePayment = async (payment: Payments.PaymentRequest, identifiers: Invoice.InvoiceIdentifiers) =>
  showroomApi.POST('/invoice/:invoiceId/:invoiceHash/request-payment')({ data: payment, pathParams: { ...identifiers } });

export const getInvoicePaymentResult = async (identifiers: Invoice.InvoiceIdentifiers) =>
  showroomApi.GET('/invoice/:invoiceId/:invoiceHash/payment-result')({ pathParams: { ...identifiers } });

export const getRequestOrderPayment = async (data: Checkout.PaymentRequest, orderId: Id) =>
  showroomApi.POST('/orders/:orderId/request-payment')({ data, pathParams: { orderId } });

export const getOrderPaymentResult = async (paymentMethodFields: Payments.PaymentMethodFields, orderId: Id) =>
  showroomApi.POST('/orders/:orderId/payment-result')({ data: { paymentMethodFields }, pathParams: { orderId } });
