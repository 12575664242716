import { AttributeDescription, Order, Overview, Product, Variants } from '@typings';
import { TFunction } from 'i18next';

import { is, isDefined } from '../../utils/is';
import { isEmpty } from '../../utils/isEmpty';

interface VariantsTotalsProps {
  orderedVariants: Order.Product[];
  deliveryWindowBase: Overview.DeliveryWindowBase;
}

interface PercentageSourceProps {
  record: Overview.Row;
  percentageBase: Overview.PercentageBase;
  variants: Overview.Variant[];
}

export const getPercentageSource = ({ record, percentageBase, variants }: PercentageSourceProps) => {
  if (percentageBase === 'total') {
    return {
      row: record.totalPrice,
      total: variants.reduce((acc, cur) => acc + cur.totalPrice, 0),
    };
  }

  return {
    row: record.totalUnits,
    total: variants.reduce((acc, cur) => acc + cur.totalUnits, 0),
  };
};

export const isVariantRow = is<Overview.VariantRow>(candidate => candidate.isVariantRow);

export const getIsTopLevelRow = (rowData: Overview.Row) => {
  return !isVariantRow(rowData) ? !isDefined(rowData.parentRowId) && !rowData.hasMergedVariants : false;
};

export const getTotalVariantQuantity = (items: Variants.Item[]) => items.reduce((sum, cur) => sum + cur.quantity, 0);

export const getVariantsByDeliveryWindow = (variants: Product.Standard[], deliveryWindowBase: Overview.DeliveryWindowBase) => {
  return deliveryWindowBase === 'all' ? variants : variants.filter(variant => variant.deliveryWindows.includes(deliveryWindowBase));
};

export const getOrderedVariantsTotals = ({ orderedVariants, deliveryWindowBase }: VariantsTotalsProps) => {
  return orderedVariants.reduce<Record<string, Overview.VariantTotals>>((acc, { variant, items, deliveryWindow, totalPriceAsNumber }) => {
    if (deliveryWindowBase !== 'all' && deliveryWindow !== deliveryWindowBase) {
      return acc;
    }

    const units = getTotalVariantQuantity(items);
    const accVariant = acc[variant];
    const totalPrice = isDefined(accVariant) ? accVariant.totalPrice + totalPriceAsNumber : totalPriceAsNumber;

    const totalUnits = isDefined(accVariant) ? accVariant.totalUnits + units : units;

    return {
      ...acc,
      [variant]: {
        totalPrice,
        totalUnits,
      },
    };
  }, {});
};

export const composeVariantName = (product: Product.Standard | undefined) =>
  isDefined(product) ? `${product.name.trim()}, ${product.variantName}` : '';

export const sortPrimaryDimension = (variants: Record<string, Product.Standard>) => (a: Overview.Row, b: Overview.Row) => {
  const nameA = isVariantRow(a) ? composeVariantName(variants[a.key]) : a.primaryDimension;

  const nameB = isVariantRow(b) ? composeVariantName(variants[b.key]) : b.primaryDimension;

  if (!isDefined(nameA) || !isDefined(nameB)) {
    return 0;
  }

  return isDefined(nameA) ? nameA.localeCompare(nameB) : 0;
};

export const sortOverviewListing = (sortKey: Overview.SortKeys) => (a: Overview.Row, b: Overview.Row) => {
  const valueA = a[sortKey as Diff<keyof typeof a, Overview.SortKeys>];
  const valueB = b[sortKey as Diff<keyof typeof b, Overview.SortKeys>];

  if (!isDefined(valueA) || !isDefined(valueB)) {
    return 0;
  }

  return valueA > valueB ? 1 : -1;
};

const mergeVariantsReducer = (acc: Overview.ListingRow, child: Overview.Row) => {
  if (isVariantRow(child)) {
    return {
      ...acc,
      totalPrice: acc.totalPrice + child.totalPrice,
      totalUnits: acc.totalUnits + child.totalUnits,
    };
  }

  return acc;
};

export const getMergedVariants = (rowData: Overview.ListingRow, t: TFunction<['products']>) => {
  const parentKey = rowData.key;
  const products: Overview.ListingRow = {
    ...rowData,
    children: [],
    hasMergedVariants: true,
    isVariantRow: false,
    key: `${parentKey}/${parentKey}-products`,
    primaryDimension: `${t('products:products_in')} ${rowData.primaryDimension}`,
    productsCount: 0,
    secondaryDimension: '',
    totalPrice: 0,
    totalUnits: 0,
    variantsCount: 0,
  };

  return rowData.children.reduce(mergeVariantsReducer, products);
};

export const getFilteredVariants = (
  overviewVariants: Overview.Variant[],
  filterValues: string[],
  primaryAttributeUri: keyof Overview.Variant | undefined,
) => {
  if (isEmpty(filterValues) || !isDefined(primaryAttributeUri)) {
    return overviewVariants;
  }

  return overviewVariants.filter(variant => {
    const attributeValue = variant[primaryAttributeUri];

    if (!isDefined(attributeValue) && filterValues.includes('')) {
      return true;
    }

    if (Array.isArray(attributeValue)) {
      if (isEmpty(attributeValue) && filterValues.includes('')) {
        return true;
      }

      return filterValues.some(value => attributeValue.some(attribute => attribute === value));
    }

    return filterValues.some(value => attributeValue === value);
  });
};

export const getPercentageFromTotal = (value: number, total: number) => {
  /* eslint-disable-next-line  no-magic-numbers */
  return ((value / total) * 100).toFixed(2);
};

export const createOverviewAttributes = (attributes: AttributeDescription[]) => {
  return attributes.reduce(
    (acc, { key, name }) => ({
      ...acc,
      [key]: {
        name: key,
        title: name,
        uri: key,
      },
    }),
    {},
  );
};
