import React from 'react';

export const useLatest = <T extends Unrestricted>(current: T) => {
  const latestValue = React.useRef<T>(current);

  React.useEffect(() => {
    latestValue.current = current;
  });

  return latestValue;
};
