import React from 'react';
import { useTranslation } from 'react-i18next';

import universalStyles from '../../../../../css/utilities/universal.module.scss';
import { copyToClipboard } from '../../../../utils/clipboard';
import { handleSpaceAndEnter } from '../../../../utils/handleEnterKey';
import { isDefined } from '../../../../utils/is';
import Icon, { IconType } from '../../Icon';
import { Tooltip } from '../../Tooltip';

import styles from './Input.module.scss';

const TOOLTIP_HIDE_DELAY = 2000;

interface Props {
  inputRef: React.RefObject<HTMLInputElement>;
  onClick?: () => void;
}

export const InputCopyButton = React.forwardRef<HTMLDivElement, Props>(({ inputRef, onClick }, ref) => {
  const { t } = useTranslation(['common']);
  const [isCopied, setIsCopied] = React.useState(false);
  const timeoutRef = React.useRef<number>();

  const handleClick = () => {
    const value = inputRef.current?.value;

    if (!isDefined(value)) {
      return;
    }

    window.clearTimeout(timeoutRef.current);
    setIsCopied(true);
    copyToClipboard(value);
    onClick?.();

    timeoutRef.current = window.setTimeout(() => {
      setIsCopied(false);
    }, TOOLTIP_HIDE_DELAY);
  };

  React.useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <div
      role="button"
      ref={ref}
      tabIndex={0}
      aria-label={t('common:copy')}
      className={styles.button}
      onKeyDown={handleSpaceAndEnter(handleClick)}
      onClick={handleClick}
    >
      <Tooltip content={t('common:copied_message')} visible={isCopied}>
        <div className={styles.buttonInner}>
          <Icon type={IconType.CopyOutline} size={20} />
          {isCopied && (
            <span className={universalStyles.srOnly} aria-live="polite">
              {t('common:copied_message')}
            </span>
          )}
        </div>
      </Tooltip>
    </div>
  );
});
