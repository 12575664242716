import { useUnitSwitcher } from './useUnitSwitcher';

interface Props {
  switchName: string;
}

export const useSelectedUnit = ({ switchName }: Props) => {
  const { selectedUnits } = useUnitSwitcher();

  return selectedUnits[switchName];
};
