import { flexRender, Row, Table } from '@tanstack/react-table';
import React, { Fragment } from 'react';

import styles from '../Table.module.scss';

import { TableSkeleton } from './Skeletons/TableSkeleton';
import { TableCell } from './TableCell';
import { TableRow } from './TableRow';

export interface TableBodyPropsProps<T> {
  table: Table<T>;
  className?: string;
  dataTestId?: string;
  isLoading: boolean;
  extraBodyRow?: () => React.ReactNode;
  onRowClick?: (row: Row<T>) => void;
  rowClassName?: (row: Row<T>) => string;
  renderSubComponent?: (row: Row<T>) => React.ReactElement | React.ReactElement[];
  skeletonRows: number;
}

export const TableBody = <T,>({
  table,
  renderSubComponent,
  rowClassName,
  isLoading,
  onRowClick,
  skeletonRows,
  extraBodyRow,
}: TableBodyPropsProps<T>) => {
  const skeletonRowItems = React.useMemo(() => Array.from({ length: skeletonRows }, () => ({})), [isLoading, skeletonRows]);

  return (
    <tbody className={styles.tableBody}>
      {isLoading ?
        skeletonRowItems.map((_, index) => (
          <tr key={`skeleton-${index}`} className={styles.tableRow}>
            {table
              .getAllLeafColumns()
              .filter(column => column.getIsVisible())
              .map(column => {
                const meta = column.columnDef.meta ?? {};

                return (
                  <TableCell key={column.id} columnMeta={meta}>
                    {meta.skeletonType !== 'none' && <TableSkeleton type={meta.skeletonType} isRightAligned={meta.align === 'right'} />}
                  </TableCell>
                );
              })}
          </tr>
        ))
      : table.getRowModel().rows.map(row => (
          <Fragment key={row.id}>
            <TableRow
              key={row.id}
              level={row.depth}
              className={rowClassName?.(row)}
              onClick={onRowClick ? () => onRowClick(row) : undefined}
            >
              {row.getVisibleCells().map(cell => {
                const meta = cell.column.columnDef.meta ?? {};
                if (meta?.skipCellRender?.(cell.getContext())) {
                  return null;
                }

                return (
                  <TableCell key={cell.id} colSpan={meta?.colSpan?.(row)} columnMeta={meta}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                );
              })}
            </TableRow>
            {row.getIsExpanded() && renderSubComponent && renderSubComponent(row)}
          </Fragment>
        ))
      }
      {extraBodyRow && extraBodyRow()}
    </tbody>
  );
};
