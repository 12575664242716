export function downloadURI(uri: string, name: string): void {
  const link = document.createElement('a');
  // eslint-disable-next-line functional/immutable-data
  link.download = name;
  // eslint-disable-next-line functional/immutable-data
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
