import { Cms, Id } from '@typings';

import { showroomApi } from './api';

export const fetchCustomLayouts = async () => showroomApi.QUERY_GET('/cms/layouts')({});

export const createCustomLayout = async (data: Cms.CustomLayoutRequestData) =>
  showroomApi.QUERY_POST('/cms/layouts')({
    data,
  });

export const updateCustomLayout = async (layoutId: string, data: Cms.CustomLayoutRequestData) =>
  showroomApi.QUERY_PUT('/cms/layouts/:layoutId')({
    data,
    pathParams: {
      layoutId,
    },
  });

export const deleteCustomLayout = async (layoutId: Id) =>
  showroomApi.QUERY_DELETE('/cms/layouts/:layoutId')({
    pathParams: { layoutId },
  });
