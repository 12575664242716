import React from 'react';

import { Checkbox } from '../../../Fields/Checkbox';

import styles from './Header.module.scss';
interface Props {
  checked: boolean;
  indeterminate?: boolean;
  disabled: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

export const HeaderCheckboxCell = ({ onChange, disabled, checked, indeterminate }: Props) => {
  return (
    <Checkbox
      className={styles.headerCheckbox}
      value="selectAll"
      checked={checked}
      disabled={disabled}
      indeterminate={!checked && indeterminate}
      onChange={onChange}
    />
  );
};
