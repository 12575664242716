import cx from 'classnames';
import React from 'react';

import { RadioGroup } from '../various/Fields/RadioGroup';
import { Option, Select } from '../various/Fields/Select';

import styles from './ListFilter.module.scss';

interface Filter<T extends string> {
  name: string;
  value: T;
}

interface Props<T extends string> {
  title: string;
  name: string;
  selected: T;
  filters: Filter<T>[];
  className?: string;
  onChange: (status: T) => void;
}

export const ListFilter = React.memo(<T extends string>({ selected, filters, onChange, title, name, className }: Props<T>) => {
  return (
    <div className={cx(styles.filterWrapper, className)}>
      <div className={styles.filter}>
        <RadioGroup
          value={selected}
          name={name}
          itemClassName={styles.item}
          options={filters}
          orientation="horizontal"
          onChange={onChange}
        />
      </div>
      <div className={styles.selectFilter}>
        <h3>{title}</h3>
        <div className={styles.selectWrapper}>
          <Select size="large" name="selectionType" value={selected} onChange={onChange}>
            {filters.map(filter => (
              <Option key={filter.value} value={filter.value}>
                {filter.name}
              </Option>
            ))}
          </Select>
        </div>
      </div>
    </div>
  );
});
