/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;

export const Tag = ({ width = ORIGINAL_WIDTH, color = 'currentColor' }: IconComponentProps) => {
  return (
    <svg width={width} height={width} viewBox="0 0 21 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.14 3.022a1.157 1.157 0 00-1.04.317L3.843 9.596a1.15 1.15 0 000 1.64l5.421 5.42a1.15 1.15 0 001.64 0l6.256-6.255a1.16 1.16 0 00.318-1.042v-.002l-.965-4.838a.678.678 0 00-.532-.532l-4.84-.965zm4.133 2.205l.855 4.29-6.044 6.044-5.145-5.145 6.044-6.044 4.29.855z"
        fill={color}
      />
      <path d="M13.33 7.769a.6.6 0 110-1.199.6.6 0 010 1.199z" fill={color} />
    </svg>
  );
};
