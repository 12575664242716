import { omit } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  getHasActiveFilters,
  getIsStockTypeSeparationEnabled,
  getProductFilters,
  getStockTypeFilter,
  getStockTypeLabels,
  updateFilters,
} from '../../../ducks';
import { isEmpty } from '../../../utils/isEmpty';
import { EmptyState, EmptyStateButtonSeparator } from '../../various/EmptyState';
import { Button } from '../../various/NewButton';

export const NoResults = () => {
  const { t } = useTranslation(['common', 'products']);
  const dispatch = useDispatch();

  const filters = useSelector(getProductFilters);
  const hasActiveFilters = useSelector(getHasActiveFilters(omit(['search'], filters)));
  const isStockTypeSeparationEnabled = useSelector(getIsStockTypeSeparationEnabled);
  const stockLabels = useSelector(getStockTypeLabels);
  const currentStockType = useSelector(getStockTypeFilter);

  const isSearchActive = !isEmpty(filters.search);
  const currentStockTypeLabel = stockLabels[currentStockType ?? 'stock'];

  const clearFilters = () => {
    dispatch(
      updateFilters({
        filters: {
          search: filters.search,
          ...(isStockTypeSeparationEnabled && {
            stockType: currentStockType,
          }),
        },
        preserveCurrent: false,
      }),
    );
  };

  const clearSearch = () => {
    dispatch(updateFilters({ filters: { search: '' }, preserveCurrent: true }));
  };

  const clearSearchAction = {
    label: t('common:clear_search'),
    onClick: clearSearch,
  };

  const clearFiltersAction = {
    label: t('common:clear_filters'),
    onClick: clearFilters,
  };

  const getErrorTemplate = () => {
    if (hasActiveFilters && isSearchActive) {
      return {
        actions: [clearFiltersAction, clearSearchAction],
        ...t('products:no_results.search_and_filters', { returnObjects: true, searchQuery: filters.search }),
      };
    }

    if (isSearchActive) {
      return {
        actions: [clearSearchAction],
        ...t('products:no_results.search', { returnObjects: true, searchQuery: filters.search }),
      };
    }

    if (hasActiveFilters) {
      return {
        actions: [clearFiltersAction],
        ...t('products:no_results.filters', { returnObjects: true }),
      };
    }

    if (isStockTypeSeparationEnabled) {
      return {
        actions: [],
        ...t('products:no_results.stock_type', { returnObjects: true, stockTypeLabel: currentStockTypeLabel }),
      };
    }

    return {
      actions: [],
      ...t('products:no_results.default', { returnObjects: true }),
    };
  };

  const { title, subtitle, actions } = getErrorTemplate();

  return (
    <EmptyState title={title} subtitle={subtitle}>
      {actions.map(({ label, onClick }, index) => (
        <React.Fragment key={index}>
          {index !== 0 && <EmptyStateButtonSeparator>{t('common:or')}</EmptyStateButtonSeparator>}
          <Button variant="bordered" color="dark" onClick={onClick}>
            {label}
          </Button>
        </React.Fragment>
      ))}
    </EmptyState>
  );
};
