import { ItemEtasList, Position } from '@typings';
import cx from 'classnames';
import React from 'react';

import universalStyles from '../../../../../css/utilities/universal.module.scss';
import { useMatrixEtaContext } from '../../../../components/products/ProductMatrix/context';
import { ProductsEtaContext } from '../../../../components/products/ProductsEta';
import { ETA_TOOLTIP_MOUSE_ENTER_DELAY } from '../../../../constants/delays';
import { useMatrixNavigation } from '../../../../utils/hooks/matrix/useMatrixNavigation';
import { isDefined } from '../../../../utils/is';
import { isEmpty } from '../../../../utils/isEmpty';
import Icon, { IconType } from '../../Icon';
import { Tooltip } from '../../Tooltip';
import { EtaTooltipContent } from '../MatrixContents/QuantityCellInfo/EtaTooltipContent';

import styles from './MatrixCells.module.scss';
import { RegularBodyCell } from './RegularBodyCell';

interface Props {
  itemEtas?: ItemEtasList;
  isDisabled?: boolean;
  position: Position;
}

export const NoStockCell = ({ isDisabled, position, itemEtas }: Props) => {
  const { isFocused, isSelected, setFocusableElement, isFocusTarget } = useMatrixNavigation(position);
  const { getShouldHighlightItemEta, isEtaVisible } = useMatrixEtaContext();
  const { legendColorPerEta } = React.useContext(ProductsEtaContext);
  const hasEta = isDefined(itemEtas) && !isEmpty(itemEtas);
  const isHighlighted = getShouldHighlightItemEta(itemEtas);

  const classNames = cx(styles.noStockCell, {
    [styles.highlighted]: isHighlighted,
    [styles.hoverable]: hasEta,
  });

  const firstEta = itemEtas?.[0];
  const etaColor = isDefined(firstEta) ? legendColorPerEta[firstEta] : undefined;

  const bodyCellContent = React.useMemo(() => {
    if (!hasEta) {
      return (
        <span className={styles.noStock}>
          <Icon type={IconType.Cross} />
        </span>
      );
    }

    return (
      <Tooltip content={<EtaTooltipContent itemEtas={itemEtas} />} mouseEnterDelay={ETA_TOOLTIP_MOUSE_ENTER_DELAY}>
        <div className={styles.noStockEtaWrapper}>
          <span
            className={cx(styles.noStockEta, {
              [styles.withColor]: isEtaVisible,
              [styles.withBackground]: isEtaVisible && !isHighlighted,
            })}
          >
            <Icon type={IconType.Hourglass} />
          </span>
        </div>
      </Tooltip>
    );
  }, [isEtaVisible, isHighlighted, itemEtas, hasEta]);

  return (
    <RegularBodyCell
      isDisabled={isDisabled}
      ref={setFocusableElement}
      color={etaColor}
      className={classNames}
      isSelected={isFocused || isSelected}
      isFocusTarget={isFocusTarget}
    >
      {bodyCellContent}
      <div className={universalStyles.srOnly}>0</div>
    </RegularBodyCell>
  );
};
