import { Navigation } from '@typings';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import universalStyles from '../../../../css/utilities/universal.module.scss';
import { getHasActivePageChild, getIsMenuItemActive } from '../../../logic/navigation';
import { useExpand } from '../../../utils/hooks';
import { isDefined } from '../../../utils/is';
import { NavigationItem } from '../../navigation/NavigationItem';
import Icon, { IconColor, IconType } from '../../various/Icon';

import styles from './SideNavigation.module.scss';

interface Props {
  item: Navigation.UserMenuItem;
  activeClassName: string;
  linkClassName: string;
}

export const ExpandableItem = ({ item, ...itemProps }: Props) => {
  const { pathname } = useLocation();
  const hasActivePageChild = getHasActivePageChild(item, pathname);
  const { t } = useTranslation(['common']);

  const { isOpen, toggle, wrapperRef, style } = useExpand<HTMLUListElement>({
    initialOpen: hasActivePageChild,
  });

  if (!isDefined(item.children)) {
    return null;
  }

  const moreButtonClassNames = cx(styles.expandButton, {
    [styles.dropdownOpened]: isOpen,
  });
  const targetUrl = `/pages/${item.url}`;
  const isMenuActive = getIsMenuItemActive(targetUrl, pathname);

  return (
    <li>
      <div
        className={cx(itemProps.linkClassName, styles.parentWrapper, {
          [itemProps.activeClassName]: isMenuActive,
        })}
      >
        <NavigationItem
          item={item}
          linkClassName={cx(itemProps.linkClassName, styles.withChildren, {
            [styles.parentExpanded]: isOpen,
          })}
          activeClassName={itemProps.activeClassName}
        />
        <button className={moreButtonClassNames} onClick={toggle}>
          <span className={universalStyles.srOnly}>{t('common:more_in', { parent: item.label })}</span>
          <Icon type={IconType.ArrowBlackDown} size={10} color={isMenuActive ? IconColor.White : IconColor.DarkGray} />
        </button>
      </div>
      <div style={style} className={cx(styles.listContainer, { [styles.hidden]: !isOpen })}>
        <ul ref={wrapperRef}>
          {item.children.map(child => (
            <li key={child.label}>
              <NavigationItem item={child} {...itemProps} />
            </li>
          ))}
        </ul>
      </div>
    </li>
  );
};
