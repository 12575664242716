/* eslint-disable max-len */

import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 10;
const ORIGINAL_HEIGHT = 6;
const ORIGINAL_COLOR = '#ffffff';
const PROPORTION = ORIGINAL_HEIGHT / ORIGINAL_WIDTH;

export const ArrowRounded = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => (
  <svg width={width} height={width * PROPORTION} viewBox="0 0 10 6" fill="none">
    <path
      d="M9.63286 0.169418C9.5138 0.0564413 9.37267 0 9.2096 0H0.782188C0.619051 0 0.478019 0.0564413 0.358862 0.169418C0.239705 0.282519 0.180176 0.416235 0.180176 0.570816C0.180176 0.725366 0.239705 0.859082 0.358862 0.97209L4.57258 4.96724C4.69187 5.08022 4.83291 5.13678 4.99591 5.13678C5.15892 5.13678 5.30008 5.08022 5.41914 4.96724L9.63286 0.972059C9.75189 0.859082 9.81165 0.725366 9.81165 0.570785C9.81165 0.416235 9.75189 0.282519 9.63286 0.169418Z"
      fill={color}
    />
  </svg>
);
