import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { compiledPaths } from '../../../paths';
import Button from '../../various/Button';

import styles from './MobileNotification.module.scss';

export const MobileNotification = () => {
  const { t } = useTranslation(['cms']);
  const { pageId } = useParams<{ pageId: string }>();
  const handleOpenPreview = React.useCallback(() => window.open(compiledPaths.CMS_PREVIEW({ pageId }), '_newtab'), [pageId]);

  return (
    <div className={styles.wrapper}>
      <p>
        <Trans t={t} i18nKey="cms:mobile_not_supported">
          Page editor is not supported on mobile devices.
          <br />
          Please switch to desktop device.
        </Trans>
      </p>

      <div className={styles.buttonContainer}>
        <Button variant={['small', 'button', 'transparent']} to={compiledPaths.PAGES_LIST({})}>
          {t('cms:return_to_pages')}
        </Button>
        <Button variant={['small', 'button', 'blue']} onClick={handleOpenPreview}>
          {t('cms:go_to_preview')}
        </Button>
      </div>
    </div>
  );
};
