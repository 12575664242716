import { Order } from '@typings';

import { getOrderPathByStatus } from '../../../../logic/Orders';
import { Link } from '../../../various/Link';

interface Props {
  order: Order.Essentials;
}

export const OrderNumberColumn = ({ order }: Props) => {
  const { order: orderId, status, orderNumber, name } = order;
  const path = getOrderPathByStatus(orderId, status);

  return (
    <Link variant="blue" to={path}>
      <>
        {orderNumber}
        {name !== '' && <span> - {name}</span>}
      </>
    </Link>
  );
};
