import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import { getPageMetaData } from '../../../../ducks';
import { getResponsiveClassNames } from '../../../../logic/cms/editor';
import { getIsSafari } from '../../../../utils/getIsSafari';
import { useCmsClipboard } from '../../../../utils/hooks/cms/useCmsClipboard';
import { isDefined } from '../../../../utils/is';
import Icon, { IconType } from '../../../various/Icon';

import styles from './ClipboardPreview.module.scss';

const ANIMATION_DURATION = 300;
const AUTO_COLLAPSING_TIMEOUT = 3000;

export const ClipboardPreview = () => {
  const { t } = useTranslation(['cms', 'common']);

  const pageMetaData = useSelector(getPageMetaData);
  const hasPageData = isDefined(pageMetaData);

  const svgRef = React.useRef<SVGSVGElement>(null);
  const [previewWidth, setPreviewWidth] = React.useState(0);
  const [isVisible, setIsVisible] = React.useState(false);
  const [isOverflowing, setIsOverflowing] = React.useState(false);

  const { cmsClipboard, setCmsClipboard } = useCmsClipboard();
  const { content, dimensions, isExpanded = true, screenType = 'desktop' } = cmsClipboard?.preview ?? {};

  React.useEffect(() => {
    setPreviewWidth(svgRef.current?.clientWidth ?? 0);
  }, [isVisible, content]);

  React.useEffect(() => {
    setIsVisible(isDefined(content) && hasPageData);
  }, [content, hasPageData]);

  React.useEffect(() => {
    if (!isDefined(cmsClipboard?.preview) || isDefined(cmsClipboard.preview.isExpanded)) {
      return;
    }

    const collapseClipboardTimeout = setTimeout(() => {
      setCmsClipboard({
        ...cmsClipboard,
        preview: {
          ...cmsClipboard.preview,
          isExpanded: false,
        },
      });
    }, AUTO_COLLAPSING_TIMEOUT);

    return () => {
      clearTimeout(collapseClipboardTimeout);
    };
  }, [cmsClipboard, setCmsClipboard]);

  const handleContentChange = (node: Nullable<HTMLDivElement>) => {
    if (!isDefined(node) || node.offsetHeight === 0) {
      return;
    }

    setIsOverflowing(node.scrollHeight > node.offsetHeight);
  };

  const toggleExpander = () => {
    if (!isDefined(cmsClipboard?.preview)) {
      return;
    }

    setCmsClipboard({
      ...cmsClipboard,
      preview: {
        ...cmsClipboard.preview,
        isExpanded: !isExpanded,
      },
    });
  };

  const clearClipboard = () => {
    setIsVisible(false);

    setTimeout(() => {
      setCmsClipboard(null);
    }, ANIMATION_DURATION);
  };

  const wrapperClassNames = cx(styles.wrapper, {
    [styles.expanded]: isExpanded,
    [styles.overflowing]: isOverflowing,
  });
  const previewClassNames = cx(styles.preview, getResponsiveClassNames(screenType));

  if (!isDefined(content) || !isDefined(dimensions)) {
    return;
  }

  const classNames = {
    enter: styles.animationEnter,
    enterActive: styles.animationEnterActive,
    exit: styles.animationExit,
    exitActive: styles.animationExitActive,
  };

  const previewContentStyle = {
    height: dimensions.height,
    width: dimensions.width,
    ...(getIsSafari() && {
      transform: `scale(${previewWidth / dimensions.width})`,
      transformOrigin: '0 0',
    }),
  };

  return (
    <CSSTransition in={isVisible} unmountOnExit classNames={classNames} timeout={ANIMATION_DURATION}>
      <div className={wrapperClassNames} data-testid="cmsClipboardPreview">
        <div className={styles.header}>
          <button type="button" className={styles.button} onClick={toggleExpander}>
            <Icon type={IconType.Chevron} className={styles.chevron} />
            {isDefined(cmsClipboard?.section) ? t('cms:section_copied') : t('cms:layout_copied')}
          </button>
          <button
            type="button"
            aria-label={t('common:remove')}
            className={styles.button}
            data-testid="clearCmsClipboard"
            onClick={clearClipboard}
          >
            <Icon type={IconType.TrashLinear} />
          </button>
        </div>
        <div ref={handleContentChange} className={styles.body} {...{ inert: '' }}>
          <svg ref={svgRef} viewBox={`0 0 ${dimensions.width} ${dimensions.height}`} className={previewClassNames}>
            <foreignObject>
              <div style={previewContentStyle} className={styles.previewContent} dangerouslySetInnerHTML={content} />
            </foreignObject>
          </svg>
        </div>
      </div>
    </CSSTransition>
  );
};
