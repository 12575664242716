import cx from 'classnames';

import { isDefined } from '../../../utils/is';
import { TextEllipsis } from '../TextEllipsis';

import styles from './SeparatorItem.module.scss';

interface Props {
  title?: string;
  classNames?: string;
}

export const SeparatorItem = ({ title, classNames }: Props) => {
  const hasTitle = isDefined(title);

  return (
    <div className={cx(styles.container, classNames)}>
      {hasTitle && <TextEllipsis className={styles.title}>{title}</TextEllipsis>}
      <span
        className={cx(styles.separator, {
          [styles.marginsWithTitle]: hasTitle,
        })}
      />
    </div>
  );
};
