import { Product } from '@typings';
import React from 'react';
import { useSelector } from 'react-redux';

import { getAllImages } from '../../ducks/helpers';
import { getUniqueVariantImages, getVariantVideos } from '../../logic/products';

interface Props {
  productDetails: Product.Full | Product.Standard;
}

export const useProductMedia = ({ productDetails }: Props) => {
  const getAllImagesSelector = React.useMemo(() => getAllImages(productDetails), [productDetails]);
  const getAllImagesFullSelector = React.useMemo(() => getAllImages(productDetails, 'full'), [productDetails]);
  const allImages = useSelector(getAllImagesSelector);
  const allImagesFull = useSelector(getAllImagesFullSelector);

  const images = React.useMemo(() => getUniqueVariantImages(allImages), [allImages]);
  const fullImages = React.useMemo(() => getUniqueVariantImages(allImagesFull), [allImagesFull]);
  const variantVideos = React.useMemo(() => getVariantVideos(productDetails), [productDetails]);
  const medias = React.useMemo(() => [...images, ...variantVideos], [images, variantVideos]);

  return { fullImages, images, medias };
};
