import cx from 'classnames';
import { isEmpty } from 'ramda';
import React from 'react';

import styles from './ResizerTooltip.module.scss';

interface Props {
  value: number | string;
  unit?: string;
  className?: string;
}

export const ResizerTooltip = React.memo(({ value, className, unit = 'px' }: Props) => (
  <div className={cx(styles.tooltip, className)} role="tooltip">
    {value}
    {!isEmpty(unit) && ` ${unit}`}
  </div>
));
