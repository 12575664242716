import { useTranslation } from 'react-i18next';

import { TopBar } from '../../components/various/TopBar';
import { PageTitle } from '../various/PageTitle';
import { Wrapper, WrapperSize } from '../various/Wrapper';

import { CustomisationSection } from './CustomisationSection';
import { HomePageSection } from './HomePageSection';
import { MenuSection } from './MenuSection';

export const Settings = () => {
  const { t } = useTranslation(['common']);

  return (
    <PageTitle title={t('common:settings')}>
      <TopBar title={t('common:settings')} />
      <Wrapper isSeparated size={WrapperSize.MEDIUM}>
        <CustomisationSection />
        <HomePageSection />
        <MenuSection />
      </Wrapper>
    </PageTitle>
  );
};
