import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getDefaultImageAspectRatio } from '../../../ducks/helpers';
import { CustomImage } from '../../various/CustomImage';
import { TextEllipsis } from '../../various/TextEllipsis';

import styles from './ProductsList.module.scss';

interface Props {
  thumbnail: string | undefined;
  name: string;
  sku: string;
  actionAlwaysVisible?: boolean;
}

export const ProductRow = ({ thumbnail, name, sku, children, actionAlwaysVisible = false }: React.WithChildren<Props>) => {
  const aspectRation = useSelector(getDefaultImageAspectRatio);
  const { t } = useTranslation(['common']);

  return (
    <div className={styles.productRow}>
      <div className={styles.imageWrapper}>
        <CustomImage aspectRatio={aspectRation} src={thumbnail} />
      </div>
      <div className={styles.productInfo}>
        <TextEllipsis as="p" className={styles.name}>
          {name}
        </TextEllipsis>
        <TextEllipsis className={styles.sku}>{t('common:art_no', { artNo: sku })}</TextEllipsis>
      </div>
      <div
        className={cx(styles.action, {
          [styles.actionVisible]: actionAlwaysVisible,
        })}
      >
        {children}
      </div>
    </div>
  );
};
