import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 11;
const ORIGINAL_HEIGHT = 18;
const ORIGINAL_COLOR = '#262626';
const PROPORTION = ORIGINAL_HEIGHT / ORIGINAL_WIDTH;

export const ArrowThinLeft = ({ width = 18, color = ORIGINAL_COLOR }: IconComponentProps) => {
  const strokeWidth = ORIGINAL_WIDTH / width;

  return (
    <svg width={width} height={width * PROPORTION} version="1.1" viewBox="0 0 19 11" style={{ display: 'inline' }}>
      <g stroke="none" strokeWidth={strokeWidth} fill="none" fillRule="evenodd">
        <g id="arrow_black_left">
          <path d="M9.519,17.875 L0.96,9.316 L9.519,0.758" id="Stroke-1" stroke={color} />
          <path stroke={color} />
        </g>
      </g>
    </svg>
  );
};
