import { Cms } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconType } from '../../../various/Icon';
import { IconOption, IconRadioGroup } from '../../../various/IconRadioGroup';

const enum TextTransform {
  NONE = 'none',
  CAPITALIZE = 'capitalize',
  UPPERCASE = 'uppercase',
  LOWERCASE = 'lowercase',
}

interface Props {
  value: Cms.TextTransform;
  onChange: (value: Cms.TextTransform) => void;
}

export const TextTransformSwitch = ({ value = TextTransform.NONE, onChange }: Props) => {
  const changeHandler = React.useCallback(
    (transform: Cms.TextTransform) => {
      onChange(transform);
    },
    [onChange],
  );
  const { t } = useTranslation(['cms', 'common']);

  const options: IconOption[] = [
    {
      icon: IconType.Dash,
      name: t('common:none'),
      value: TextTransform.NONE,
    },
    {
      icon: IconType.Uppercase,
      name: t('cms:transform_options.uppercase'),
      value: TextTransform.UPPERCASE,
    },
    {
      icon: IconType.Capitalize,
      name: t('cms:transform_options.capitalize'),
      value: TextTransform.CAPITALIZE,
    },
    {
      icon: IconType.Lowercase,
      name: t('cms:transform_options.lowercase'),
      value: TextTransform.LOWERCASE,
    },
  ];

  return (
    <IconRadioGroup
      groupName={t('cms:text_transform')}
      selectedValue={value}
      options={options}
      dataTestId="textStyleEditorTransform"
      onChange={changeHandler}
    />
  );
};
