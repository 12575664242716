import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  getBuyerIdFromCurrentOrder,
  getDeliveryWindowsByBuyerId,
  getHasAnyActiveFilters,
  getHasLoadedInitialFilters,
  getIsHideSwitchForSingleStockTypeEnabled,
  getIsStockTypeSeparationEnabled,
  getProductFilters,
  getProductsAttributes,
  getProductsExpandedFiltersStates,
  getProductsTotalItems,
  resetFilters,
  setExpandedProductFilters,
  updateFilters,
} from '../../../../ducks';
import { getDeliveryWindowsIdsByStockType } from '../../../../logic/deliveryWindows';
import { createAbsoluteUrl } from '../../../../utils/createAbsoluteUrl';
import { useAllProductsLink } from '../../../../utils/hooks';
import { isEmpty } from '../../../../utils/isEmpty';
import { SearchFilter } from '../../../various/SearchFilter';
import { FiltersBarHeader } from '../FiltersBarHeader';
import { StockSwitcher } from '../StockSwitcher';

import { FiltersPanelSelectorsContext } from './context/FiltersPanelSelectorsContext';
import { FiltersPanelGroups } from './FiltersPanelGroups';
import { FiltersPanelWrapper } from './FiltersPanelWrapper';

const filtersPanelSelectors = {
  getAttributes: getProductsAttributes,
  getExpandedFilters: getProductsExpandedFiltersStates,
  getFilters: getProductFilters,
  getHasActiveFilters: getHasAnyActiveFilters,
};

interface Props {
  isLoading: boolean;
}

export const FiltersPanel = ({ isLoading }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'products']);

  const hasLoadedInitialFilters = useSelector(getHasLoadedInitialFilters);
  const hasStockSeparationEnabled = useSelector(getIsStockTypeSeparationEnabled);
  const isHidingForSingleStockEnabled = useSelector(getIsHideSwitchForSingleStockTypeEnabled);
  const productsCount = useSelector(getProductsTotalItems);
  const expandedFilters = useSelector(getProductsExpandedFiltersStates);
  const buyerId = useSelector(getBuyerIdFromCurrentOrder);
  const buyerDeliveryWindows = useSelector(getDeliveryWindowsByBuyerId(buyerId));

  const deliveryWindowIdsByStockType = getDeliveryWindowsIdsByStockType(buyerDeliveryWindows);
  const hasTwoStockTypes = !isEmpty(deliveryWindowIdsByStockType.preorder) && !isEmpty(deliveryWindowIdsByStockType.stock);
  const hasStockSwitcherHiddenForSingleStock = isHidingForSingleStockEnabled && !hasTwoStockTypes;
  const shouldShowStockSwitcher = hasStockSeparationEnabled && !hasStockSwitcherHiddenForSingleStock;

  const shareUrl = createAbsoluteUrl(useAllProductsLink());

  const handleFilterChange = (fieldName: string, selectedValues: string[]) => {
    dispatch(
      updateFilters({
        filters: { [fieldName]: selectedValues },
        preserveCurrent: true,
      }),
    );
  };

  const handleToggleFilter = (fieldName: string, isExpanded: boolean) => {
    const newExpandedFilters = { ...expandedFilters, [fieldName]: !isExpanded };

    dispatch(setExpandedProductFilters(newExpandedFilters));
  };

  const handleClearFilters = () => {
    dispatch(resetFilters());
  };

  return (
    <FiltersPanelSelectorsContext.Provider value={filtersPanelSelectors}>
      <FiltersPanelWrapper showBarcodeScanner isLoading={isLoading && !hasLoadedInitialFilters}>
        {shouldShowStockSwitcher && (
          <StockSwitcher>
            <FiltersBarHeader
              label={t('common:shop_by')}
              shareUrl={shareUrl}
              productsCount={productsCount}
              showFilterDetails
              onClearFilters={handleClearFilters}
            />
          </StockSwitcher>
        )}
        <FiltersBarHeader
          label={t('common:filter_by')}
          shareUrl={shareUrl}
          productsCount={productsCount}
          showFilterDetails={!shouldShowStockSwitcher}
          onClearFilters={handleClearFilters}
        />
        <SearchFilter />
        <FiltersPanelGroups onFilterChange={handleFilterChange} onToggleFilter={handleToggleFilter} />
      </FiltersPanelWrapper>
    </FiltersPanelSelectorsContext.Provider>
  );
};
