import { ConfirmationGuard } from '@typings';
import React from 'react';

import { ConfirmationGuardContext } from '../../components/various/ConfirmationModal';

import { useModalVisibility } from './useModalVisibility';

export const useConfirmationGuard = () => {
  const { showModal } = useModalVisibility('ConfirmationModal');
  const { setConfig } = React.useContext(ConfirmationGuardContext);

  return React.useCallback(
    <T extends unknown[]>(fn: (...arg: T) => ConfirmationGuard.Config) =>
      (...arg: T): ConfirmationGuard.Function => {
        showModal();
        setConfig(fn(...arg));

        const update = (newConfig: ConfirmationGuard.Config) => {
          setConfig(newConfig);
        };

        return { update };
      },
    [showModal, setConfig],
  );
};
