import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import Icon, { IconType } from '../Icon';
import { Tooltip } from '../Tooltip';

import styles from './DelwinExpiredTooltip.module.scss';

interface Props {
  className?: string;
}

export const DelwinExpiredTooltip = ({ className }: Props) => {
  const { t } = useTranslation(['expiredDelwins']);

  return (
    <Tooltip content={t('expiredDelwins:delivery_window_expired_hint')}>
      <div className={cx(styles.alert, className)}>
        <Icon type={IconType.Warning} />
      </div>
    </Tooltip>
  );
};
