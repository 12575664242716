import { Id, Items, Product } from '@typings';
import { multiply, sum } from 'ramda';

import { isDefined } from '../utils/is';

const enum Measurements {
  TABLE_DEFAULT_ROW_HEIGHT = 52,
  PRESETS_LINE_HEIGHT = 40,
  SCROLLBAR_GAP_HEIGHT = 2,
}

export const getIsTwoDimensional = (table: Items.Table): table is Items.Table.TwoDimensional => table.x.length >= 1 && table.y.length >= 1;

export const getNumberOfRowsInMatrixToRender = (variants: Product.Standard[]): number => {
  const [firstVariant] = variants;

  if (!isDefined(firstVariant)) {
    return 0;
  }

  if (getIsTwoDimensional(firstVariant.itemTable)) {
    return firstVariant.itemTable.y.length;
  }

  return variants.length;
};

interface ProductMatrixConfig {
  variants: Product.Standard[];
  hasControls: boolean;
  hasSummaries: boolean;
  isMobile: boolean;
}

export const getEstimatedProductMatrixHeight = (
  { variants, hasControls, hasSummaries }: ProductMatrixConfig,
  modifierFn = (height: number): number => height,
): number => {
  return modifierFn(
    sum([
      Measurements.TABLE_DEFAULT_ROW_HEIGHT,
      multiply(Measurements.TABLE_DEFAULT_ROW_HEIGHT)(getNumberOfRowsInMatrixToRender(variants)),
      hasSummaries ? Measurements.TABLE_DEFAULT_ROW_HEIGHT : 0,
      Measurements.SCROLLBAR_GAP_HEIGHT,
      hasControls ? Measurements.PRESETS_LINE_HEIGHT : 0,
    ]),
  );
};

export const getExtraSizeInfo = (variant: Product.Standard, itemId: Id, localizationDefinitionName?: string) => {
  if (!isDefined(localizationDefinitionName)) {
    return {};
  }

  const localizedSize = variant.items.find(item => item.item === itemId)?.name ?? '';

  return {
    localizedProdSize: {
      localizationDefinitionName,
      localizedSize,
    },
  };
};
