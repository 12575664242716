import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

import { Id } from '../../../../../typings';
import { addToast } from '../../../components/various/Toasts';
import { getResponseErrors } from '../../../utils/errors';
import { deleteShippingAddress } from '../../accountRepository';

interface MutationVariables {
  accountId: Id;
  shippingAddressId: Id;
}

export const useDeleteShippingAddress = () => {
  const { t } = useTranslation(['shipping']);
  const queryClient = useQueryClient();

  const mutationFn = async ({ accountId, shippingAddressId }: MutationVariables) => {
    return deleteShippingAddress(accountId, shippingAddressId);
  };

  const handleSuccess = (_: unknown, { accountId }: MutationVariables) => {
    addToast(t('shipping:shipping_address_deleted'));
    queryClient.invalidateQueries({ queryKey: ['shippingAddresses', { accountId }] });
  };

  const handleError = (error: AxiosError<Responses.Errors>) => {
    const [errorMessage] = getResponseErrors(error);
    addToast(errorMessage);
  };

  return useMutation({
    mutationFn,
    onError: handleError,
    onSuccess: handleSuccess,
  });
};
