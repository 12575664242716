import { Cms, Id } from '@typings';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { buttonTemplate, placeholderButtonPart, placeholderTextPart, singleLineTextTemplate } from '../../../cmsTemplates';
import { getCurrentItemInBlock, removeContentParts, setButtonPart, setTextPart, updateBlockSettings } from '../../../ducks';
import { updateLocalizedBlockSettings } from '../../../ducks/cms/localizations';
import { getContentPartsIds, getHasButtons, getHasText } from '../../../logic/cms/contentSet';
import { isButtonPart } from '../../../logic/cms/styles';
import { isBlockColorDefined, isBlockMediaDefined, isBlockOverlayDefined } from '../../../logic/pages';
import { isImageMedia } from '../../../services/media';
import { isDefined } from '../../../utils/is';
import { isEmpty } from '../../../utils/isEmpty';
import { omit } from '../../../utils/omit';

import { EditorLanguageContext } from './EditorLanguageContext';

interface Context {
  addButtonPart: () => void;
  addTextPart: () => void;
  blockId: string;
  deleteBlockElement: (elementPath: string[]) => void;
  deleteButtonParts: (partId?: Id) => void;
  deleteTextParts: () => void;
  handleUpdateText: (newContent: string) => void;
  hasBackground: boolean;
  hasButtons: boolean;
  hasEmbed: boolean;
  hasHotspots: boolean;
  hasImageMedia: boolean;
  hasLink: boolean;
  hasMedia: boolean;
  hasOverlay: boolean;
  isBlockEmpty: boolean;
  hasText: boolean;
  isAddPartPopupVisible: boolean;
  parameters: Nullable<Cms.ContentBlock>;
  setIsAddPartPopupVisible: (isVisible: boolean) => void;
  setParameters: React.Dispatch<React.SetStateAction<Cms.ContentBlock>>;
}

const initialContext = {
  addButtonPart: () => null,
  addTextPart: () => null,
  blockId: '',
  deleteBlockElement: () => null,
  deleteButtonParts: () => null,
  deleteTextParts: () => null,
  handleUpdateText: () => null,
  hasBackground: false,
  hasButtons: false,
  hasEmbed: false,
  hasHotspots: false,
  hasImageMedia: false,
  hasLink: false,
  hasMedia: false,
  hasOverlay: false,
  hasText: true,
  isAddPartPopupVisible: false,
  isBlockEmpty: true,
  parameters: null,
  setIsAddPartPopupVisible: () => null,
  setParameters: () => null,
};

export const ContentBlockContext = React.createContext<Context>(initialContext);

export const ContentBlockContextProvider = ({ children }: React.WithChildren) => {
  const dispatch = useDispatch();
  const [parameters, setParameters] = React.useState<Nullable<Cms.ContentBlock>>(null);
  const [blockId, setBlockId] = React.useState('');
  const [hasBackground, setHasBackground] = React.useState(false);
  const [hasEmbed, setHasEmbed] = React.useState(false);
  const [hasHotspots, setHasHotspots] = React.useState(false);
  const [hasImageMedia, setHasImageMedia] = React.useState(false);
  const [hasLink, setHasLink] = React.useState(false);
  const [hasMedia, setHasMedia] = React.useState(false);
  const [hasOverlay, setHasOverlay] = React.useState(false);
  const [isBlockEmpty, setIsBlockEmpty] = React.useState(true);
  const [isAddPartPopupVisible, setIsAddPartPopupVisible] = React.useState(false);

  const { editorLanguage } = React.useContext(EditorLanguageContext);

  React.useEffect(() => {
    if (!isDefined(parameters)) {
      return;
    }

    const isHasMedia = isBlockMediaDefined(parameters);

    setHasBackground(isBlockColorDefined(parameters));
    setHasEmbed(isDefined(parameters.settings.embed));
    setHasHotspots(isDefined(parameters.settings.hotspots));
    setIsBlockEmpty(isEmpty(omit(parameters.settings, ['height'])));
    setHasMedia(isHasMedia);
    setHasImageMedia(isHasMedia && isImageMedia(parameters.settings.background?.general.media));
    setHasLink(isDefined(parameters.settings.link));
    setHasOverlay(isBlockOverlayDefined(parameters));
    setBlockId(parameters.id);
  }, [parameters]);

  const text = parameters?.settings.text;
  const hasButtons = React.useMemo(() => {
    if (!isDefined(text)) {
      return false;
    }

    return getHasButtons(text);
  }, [text]);
  const hasText = React.useMemo(() => {
    if (!isDefined(text)) {
      return false;
    }

    return getHasText(text);
  }, [text]);
  const isTextBlockEmpty = !hasText && !hasButtons;
  const textParts = React.useMemo(() => parameters?.settings.text?.parts, [parameters]);
  const currentItemIndex = useSelector(getCurrentItemInBlock(blockId))?.index;
  const textPartsOrder = parameters?.settings.text?.partsOrder ?? [];
  const currentItemId = isDefined(currentItemIndex) ? textPartsOrder[currentItemIndex] : null;

  const deleteBlockElement = React.useCallback(
    (elementPath: string[]) => {
      if (isEmpty(blockId)) {
        return;
      }

      const updatePayload = {
        blockId,
        updatePath: elementPath,
        value: undefined,
      };

      dispatch(updateLocalizedBlockSettings(updatePayload));
      dispatch(updateBlockSettings(updatePayload));
    },
    [blockId, dispatch],
  );

  const addTextPart = React.useCallback(() => {
    if (!isDefined(blockId)) {
      return;
    }

    if (isTextBlockEmpty) {
      dispatch(
        updateBlockSettings({
          blockId,
          updatePath: ['text'],
          value: singleLineTextTemplate(),
        }),
      );

      return;
    }

    dispatch(setTextPart({ blockId, value: placeholderTextPart }));
  }, [blockId, dispatch, isTextBlockEmpty]);

  const deleteTextParts = React.useCallback(
    (partId?: Id) => {
      if (!isDefined(blockId) || !isDefined(textParts)) {
        return;
      }

      const textPartsIds =
        isDefined(partId) ? [partId] : getContentPartsIds(textParts, (part: Cms.ContentBlockPart) => !isButtonPart(part));

      dispatch(removeContentParts({ blockId, partIds: textPartsIds }));
    },
    [blockId, dispatch, textParts],
  );

  const deleteButtonParts = React.useCallback(
    (partId?: Id) => {
      if (!isDefined(blockId)) {
        return;
      }

      const buttonPartsIds = isDefined(partId) ? [partId] : getContentPartsIds(textParts, isButtonPart);

      dispatch(removeContentParts({ blockId, partIds: buttonPartsIds }));
    },
    [blockId, dispatch, textParts],
  );

  const addButtonPart = React.useCallback(() => {
    if (!isDefined(blockId)) {
      return;
    }
    if (isTextBlockEmpty) {
      dispatch(
        updateBlockSettings({
          blockId,
          updatePath: ['text'],
          value: buttonTemplate(),
        }),
      );

      return;
    }

    dispatch(setButtonPart({ blockId, value: placeholderButtonPart }));
  }, [blockId, dispatch, isTextBlockEmpty]);

  const handleUpdateText = React.useCallback(
    (newContent: string) => {
      const shouldUpdate = isDefined(blockId) && isDefined(currentItemId);

      if (!shouldUpdate) {
        return;
      }

      const updatePath = ['text', 'parts', currentItemId, 'general', 'content'];

      const updatePayload = {
        blockId,
        updatePath,
        value: newContent,
      };

      dispatch(
        updateLocalizedBlockSettings({
          ...updatePayload,
          language: editorLanguage,
        }),
      );
    },
    [blockId, currentItemId, dispatch, editorLanguage],
  );

  return (
    <ContentBlockContext.Provider
      value={{
        addButtonPart,
        addTextPart,
        blockId,
        deleteBlockElement,
        deleteButtonParts,
        deleteTextParts,
        handleUpdateText,
        hasBackground,
        hasButtons,
        hasEmbed,
        hasHotspots,
        hasImageMedia,
        hasLink,
        hasMedia,
        hasOverlay,
        hasText,
        isAddPartPopupVisible,
        isBlockEmpty,
        parameters,
        setIsAddPartPopupVisible,
        setParameters,
      }}
    >
      {children}
    </ContentBlockContext.Provider>
  );
};
