import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import React from 'react';

interface Props {
  classNames: string;
  style: React.CSSProperties;
  shouldFocus: boolean;
}

export const ButtonWrapper = ({ children, classNames, style, shouldFocus }: React.WithChildren<Props>) => {
  const [editor] = useLexicalComposerContext();

  const handleFocus = React.useCallback(() => {
    shouldFocus && editor.focus();
  }, [editor, shouldFocus]);

  return (
    <button className={classNames} style={style} onFocus={handleFocus}>
      {children}
    </button>
  );
};
