import { Cms, Id, Translations } from '@typings';

import { latestPageStructureVersion } from '../logic/cms/convertPageToLatestVersion';
import { getNormalizedPage } from '../logic/cms/normalizePage';
import { getUniquePageProducts } from '../logic/pages';
import { showroomApi } from '../services/api';
import { fetchProductsByIds } from '../services/productsRepository';
import { handleResponseStatus } from '../utils/handleResponseStatus';
import { isDefined } from '../utils/is';
import { isEmpty } from '../utils/isEmpty';

const FALLBACK_NORMALIZED_CMS_SECTIONS: ReturnType<typeof getNormalizedPage> = {
  blocks: {},
  groups: {},
  localizations: undefined,
  sections: [],
};

const handleFetchProducts = async (blocks: Record<Id, Cms.AnyBlock>, buyer?: string) => {
  const uniqueProducts = getUniquePageProducts(blocks);

  if (isEmpty(uniqueProducts)) {
    return {};
  }

  const productsResponse = await fetchProductsByIds(uniqueProducts, buyer);

  if (productsResponse.status === 'FAILURE') {
    throw new Error('Unable to fetch products.');
  }

  return productsResponse.data.products;
};

export const getPageBySlug = async (slug: string, buyer?: string) =>
  showroomApi
    .POST('/v1/pages/:slug')({ data: { buyer }, pathParams: { slug } })
    .then(async response =>
      handleResponseStatus(response)(
        success => {
          if ((success.data.version ?? 0) > latestPageStructureVersion) {
            window.location.reload();

            return {
              ...success,
              data: {
                ...success.data,
                ...FALLBACK_NORMALIZED_CMS_SECTIONS,
              },
            };
          }

          return {
            ...success,
            data: {
              ...success.data,
              ...getNormalizedPage(success.data),
            },
          };
        },
        fail => fail,
      ),
    )
    .then(async pageResponse =>
      handleResponseStatus(pageResponse)(
        async pageData =>
          handleFetchProducts(pageData.data.blocks, buyer).then(products => ({
            pageData: pageData.data,
            products,
          })),
        () => {
          throw new Error('Unable to fetch page data.');
        },
      ),
    );

export const getPages = async ({ search }: Cms.PageFilterData) =>
  showroomApi.QUERY_GET('/cms/pages')(isDefined(search) && !isEmpty(search) ? { query: { search } } : {});

export const createPage = async (data: Cms.CreatePageDTO) => showroomApi.QUERY_POST('/cms/pages')({ data });
export const editPage = async (id: Id, data: Cms.PageDTO) => showroomApi.QUERY_PUT('/cms/pages/:id')({ data, pathParams: { id } });
export const deletePage = async (id: string) => showroomApi.QUERY_DELETE('/cms/pages/:id')({ pathParams: { id } });

export const editPageSections = async (
  id: Id,
  data: { sections: Cms.SectionsDTO; localizations?: Cms.BlockLocalizations; version: number },
) =>
  showroomApi.PUT('/cms/pages/:id/sections')({
    data,
    pathParams: { id },
  });

export const getPageData = async (id: Id) => showroomApi.GET('/cms/pages/:id')({ pathParams: { id } });

export const getPageSectionsNormalized = async (id: Id) =>
  showroomApi
    .GET('/cms/pages/:id/sections')({ pathParams: { id } })
    .then(handleResponseStatus)
    .then(handler =>
      handler(
        success => {
          if ((success.data.version ?? 0) > latestPageStructureVersion) {
            window.location.reload();

            return {
              ...success,
              data: FALLBACK_NORMALIZED_CMS_SECTIONS,
            };
          }

          return {
            ...success,
            data: {
              ...getNormalizedPage(success.data),
            },
          };
        },
        fail => fail,
      ),
    );

export const getFullPageData = async (id: Id) =>
  Promise.all([getPageData(id), getPageSectionsNormalized(id)]).then(([data, pageSections]) => ({
    ...handleResponseStatus(data)(
      res => res.data,
      () => {
        throw new Error('Unable to fetch page data.');
      },
    ),
    ...handleResponseStatus(pageSections)(
      res => res.data,
      () => {
        throw new Error('Unable to fetch page sections.');
      },
    ),
  }));

export const setPageStatus = (status: Cms.UpdateStatusDTO, customStyleIds?: Id[]) => async (id: Id) => {
  return showroomApi.PUT('/cms/pages/:id/change-status')({
    data: { customStyleIds, status },
    pathParams: { id },
  });
};

export const discardChanges = async (id: Id, languageCode: Translations.SupportedLanguagesCodes) => {
  return showroomApi.PUT('/cms/pages/:id/discard-changes/:languageCode')({
    pathParams: { id, languageCode },
  });
};

export const duplicatePage = async (id: Id) => showroomApi.QUERY_PUT('/cms/pages/:id/duplicate')({ pathParams: { id } });
