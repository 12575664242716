/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;
const ORIGINAL_COLOR = '#848484';

export const TextAdd = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => (
  <svg width={width} height={width} viewBox="0 0 20 20" fill="none">
    <path
      d="M5.84889 1.37683H8.75007V3.07249H10V0H0V3.07249H1.25046V1.37683H4.36525V10.2543H2.96568V11.5043H7.16174V10.2543H5.84889V1.37683Z"
      fill={color}
    />
    <path
      d="M12.625 8.5C12.8321 8.5 13 8.7052 13 8.95833L13 13.5417C13 13.7948 12.8321 14 12.625 14L11.875 14C11.6679 14 11.5 13.7948 11.5 13.5417L11.5 8.95833C11.5 8.7052 11.6679 8.5 11.875 8.5L12.625 8.5Z"
      fill={color}
    />
    <path
      d="M9.5 10.875C9.5 10.6679 9.7052 10.5 9.95833 10.5H14.5417C14.7948 10.5 15 10.6679 15 10.875V11.625C15 11.8321 14.7948 12 14.5417 12H9.95833C9.7052 12 9.5 11.8321 9.5 11.625V10.875Z"
      fill={color}
    />
  </svg>
);
