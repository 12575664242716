import { Cms } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { getManualLayoutEditorBlockTypeToSelect, getManualLayoutEditorModels } from '../../../../ducks/cms/ui';
import { getContentModelsCount } from '../../../../logic/cms/changeGroup';
import { isDefined, isNull } from '../../../../utils/is';
import { ManualLayoutEditorContext } from '../../context/ManualLayoutEditorContext';
import { SelectorsContext } from '../../context/SelectorsContext';

import { ManualLayoutSelector } from './ManualLayoutSelector';
import styles from './ManualLayoutSelector.module.scss';

interface Props {
  parameters: Cms.ContentBlock;
  isManualLayoutEditing: boolean;
}

export const ManualContentLayoutSelector = ({ parameters, isManualLayoutEditing }: Props) => {
  const { id: blockId } = parameters;
  const { setManualLayoutChangeData, manualLayoutChangeData } = React.useContext(ManualLayoutEditorContext);
  const { getBlockById } = React.useContext(SelectorsContext);
  const models = useSelector(getManualLayoutEditorModels);
  const blockData = useSelector(getBlockById(blockId));
  const contentModelsCount = getContentModelsCount(models);
  const blockTypeToSelect = useSelector(getManualLayoutEditorBlockTypeToSelect);

  const areAllRequiredElementsSelected = React.useMemo(() => {
    return Object.keys(manualLayoutChangeData).length === contentModelsCount;
  }, [manualLayoutChangeData, contentModelsCount]);

  const isDisabled = React.useMemo(() => {
    if (!isDefined(blockData)) {
      return false;
    }

    return blockTypeToSelect !== blockData.blockType;
  }, [blockTypeToSelect, blockData]);

  const isSelected = React.useMemo(() => {
    return Object.keys(manualLayoutChangeData).includes(blockId);
  }, [blockId, manualLayoutChangeData]);

  const onClick = React.useCallback(() => {
    if (isDisabled) {
      return;
    }

    if (isSelected) {
      setManualLayoutChangeData(data => {
        return Object.fromEntries(Object.entries(data).filter(([_, block]) => block.id !== blockId));
      });

      return;
    }

    if (areAllRequiredElementsSelected || isNull(blockData)) {
      return;
    }

    setManualLayoutChangeData(data => ({ ...data, [blockId]: blockData }));
  }, [blockId, setManualLayoutChangeData, isSelected, areAllRequiredElementsSelected, isDisabled]);

  const classNames = cx(styles.contentWrapper, {
    [styles.disabled]: isDisabled,
  });

  if (!isManualLayoutEditing) {
    return null;
  }

  return (
    <ManualLayoutSelector
      areAllRequiredElementsSelected={areAllRequiredElementsSelected}
      classNames={classNames}
      isSelected={isSelected}
      onClick={onClick}
      testId="manualContentLayoutSelector"
    />
  );
};
