import { useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';

import { getIsDeliveryWindowsLoading } from '../../../../ducks';
import { getHasProductDetailsFailed, getIsLoadingProductDetails, getProductDetails } from '../../../../ducks/productDetails';
import { getStandardRelationProducts } from '../../../../epics/productDetailsEpic';
import { getUrlForProductAndVariant } from '../../../../logic/products';
import { paths } from '../../../../paths';
import { isDefined } from '../../../../utils/is';
import { isEmpty } from '../../../../utils/isEmpty';
import DefaultLoader from '../../../various/loaders/DefaultLoader';
import { ProductsEtaContextProvider } from '../../ProductsEta';

import { ProductDetailsModalContent } from './ProductDetailsModalContent';
import styles from './ProductDetailsModalInner.module.scss';
import { ProductNotFoundContent } from './ProductNotFoundContent';

export const ProductDetailsModalInner = () => {
  const isAllProductsPage = useMatch(paths.PRODUCTS_ORDER)?.pattern.end ?? false;
  const isLoadingProductDetails = useSelector(getIsLoadingProductDetails);
  const isLoadingDeliveryWindows = useSelector(getIsDeliveryWindowsLoading);
  const productDetails = useSelector(getProductDetails);
  const hasProductDetailsFailed = useSelector(getHasProductDetailsFailed);
  const hasRelatedProducts =
    isDefined(productDetails.relatedProducts) &&
    isDefined(productDetails.relatedProducts.variants) &&
    !isEmpty(getStandardRelationProducts(productDetails.relatedProducts.variants));
  const shouldWaitForRelatedProducts = hasRelatedProducts && !isDefined(productDetails.standardRelation);
  const shouldShowLoader =
    (!hasProductDetailsFailed && isEmpty(productDetails)) ||
    isLoadingProductDetails ||
    isLoadingDeliveryWindows ||
    shouldWaitForRelatedProducts;

  if (shouldShowLoader) {
    return (
      <div className={styles.loaderWrapper}>
        <DefaultLoader className={styles.loader} />
      </div>
    );
  }

  if (hasProductDetailsFailed) {
    return <ProductNotFoundContent />;
  }

  if (!isEmpty(productDetails)) {
    return (
      <ProductsEtaContextProvider products={[productDetails]} isAllProductsPage={isAllProductsPage}>
        <ProductDetailsModalContent productDetails={productDetails} getProductUrl={getUrlForProductAndVariant} />
      </ProductsEtaContextProvider>
    );
  }

  return null;
};
