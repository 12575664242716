import { Cms } from '@centra';
import cx from 'classnames';
import { useSelector } from 'react-redux';

import { getIsCustomStylesLoading } from '../../../../../../ducks';
import { calculateFontSize } from '../../../../../../logic/calculateFontSize';
import { getScreenRelativeStyles } from '../../../../../../logic/pages';
import { useCustomStyles } from '../../../../../../utils/hooks/cms/useCustomStyles';
import { isDefined } from '../../../../../../utils/is';
import { isEmpty } from '../../../../../../utils/isEmpty';
import { LinkWrapper } from '../../LinkWrapper';

import styles from './ButtonPart.module.scss';

const ContentPart = ({ content }: { content: string }) => <div dangerouslySetInnerHTML={{ __html: content }} />;

export const ButtonPart = ({ screenWidth, parameters }: Cms.ButtonLineProps) => {
  const { content, buttonStyle, link } = parameters.general;
  const screenRelative = getScreenRelativeStyles(screenWidth, parameters);
  const isCustomStylesLoading = useSelector(getIsCustomStylesLoading);
  const { buttonStyles } = useCustomStyles();

  if (isCustomStylesLoading) {
    return null;
  }

  const style = {
    borderStyle: 'solid',
    fontSize: calculateFontSize(parameters, screenWidth, buttonStyles),
    justifySelf: screenRelative.justifySelf,
  };

  return (
    <div className={styles.container}>
      {isDefined(link) && !isEmpty(link) ?
        <LinkWrapper link={link} anchorClassName={cx(styles.buttonBase, buttonStyle)} style={style}>
          <ContentPart content={content} />
        </LinkWrapper>
      : <button className={cx(styles.buttonBase, buttonStyle)} style={style}>
          <ContentPart content={content} />
        </button>
      }
    </div>
  );
};
