export type NavigateTo = 'mySelection' | 'checkout' | 'confirmOrder' | 'back';

const enum BottomBarTrackingEventWithParameters {
  NAVIGATION_BUTTONS_CLICKED = 'bottom-bar-navigation-button',
}

export const getNavigationButtonsClickedEvent = (navigateTo: NavigateTo) => ({
  event: BottomBarTrackingEventWithParameters.NAVIGATION_BUTTONS_CLICKED,
  navigateTo,
});
