import { Cms, Id } from '@typings';
import { createSelector } from 'reselect';

import { getIsBlockLocalizable, getIsContentBlock } from '../../../logic/pages';
import { isDefined } from '../../../utils/is';
import { createShallowEqualSelector } from '../../utils';

export const findCmsBlockById = (id: Nullable<Id>, blocks: Record<Id, Cms.AnyBlock>) => (isDefined(id) ? blocks[id] : null);

export const getCmsBlocks = (state: Store) => state.cms.blocks;
export const getCmsBlockById = (id: Nullable<Id>) => (state: Store) => findCmsBlockById(id, state.cms.blocks) ?? null;

export const getCmsContentPartsCount = (id: Nullable<Id>) => (state: Store) => {
  const block = getCmsBlockById(id)(state);
  if (!isDefined(block) || !getIsContentBlock(block)) {
    return 0;
  }

  return block.settings.text?.partsOrder.length ?? 0;
};

const getBlocksByIds = (blockIds: Nullable<string>[]) => (state: Store) => {
  const { blocks } = state.cms;

  return blockIds.map(id => (isDefined(id) ? blocks[id] : null));
};

export const getCmsBlocksByIds = (blockIds: Nullable<string>[]) =>
  createShallowEqualSelector(getBlocksByIds(blockIds), blocks => blocks.filter(isDefined));

export const getLocalizableCmsBlocks = createSelector(getCmsBlocks, blocks => {
  return Object.values(blocks).filter(getIsBlockLocalizable);
});
