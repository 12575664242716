import { Checkout, Order, Product } from '@typings';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import universalStyles from '../../../../../css/utilities/universal.module.scss';
import { getAllDeliveryWindows, getCancelledOrderProducts, getOrderDeliveryWindowsIds } from '../../../../ducks';
import { getDeliveryWindowsByIds } from '../../../../logic/deliveryWindows';
import { getIsOrderClosed, getOrderCancelDate, getOrderPreferredShippingDate, getUnitsCountInDelwin } from '../../../../logic/Orders';
import { isDefined } from '../../../../utils/is';
import { isEmpty } from '../../../../utils/isEmpty';
import { CheckoutSubtotal } from '../../../orders/partials/CheckoutSubtotal';
import { Alert } from '../../../various/Alert';
import Heading from '../../../various/Heading';
import { IconType } from '../../../various/Icon';
import { ReceiptAddress } from '../../../various/ReceiptAddress';
import { CancelledProducts } from '../../CancelledProducts';
import { CheckoutProductsReceipt } from '../../checkoutProductsReceipt';
import { CheckoutSection } from '../../CheckoutSection';
import { CheckoutSummary } from '../../CheckoutSummary';

import { CancelledOrderProductsAlert } from './CancelledOrderProductsAlert';
import styles from './CheckoutInformation.module.scss';
import { ContentRow } from './ContentRow';

interface Props {
  checkoutFields: Checkout.Requirements;
  order: Order.Closed | Order.Open;
  products: Record<string, Product.Full> | never[];
}

export const CheckoutInformation = ({ checkoutFields, order, products }: Props) => {
  const { t } = useTranslation(['cancelled', 'checkout', 'shipping']);
  const productsWithFullyCancelledItems = useSelector(getCancelledOrderProducts);
  const orderDeliveryWindowIds = useSelector(getOrderDeliveryWindowsIds);
  const allDeliveryWindows = useSelector(getAllDeliveryWindows);
  const hasOrderId = isDefined(order.order);

  const orderProducts = React.useMemo(() => (Array.isArray(products) ? [] : Object.values(products)), [products]);

  const someProductAreUnavailable = order.products.some(
    (orderProduct: Order.Product) => !orderProducts.find(product => product.product === orderProduct.product),
  );

  const showCheckoutShippingField = 'preferredShippingDate' in checkoutFields || 'preferredShippingDateByDeliveryWindow' in checkoutFields;
  const showCheckoutCancelField = 'cancelDate' in checkoutFields || 'cancelDateByDeliveryWindow' in checkoutFields;
  const showShippingPreferences = showCheckoutShippingField || showCheckoutCancelField;

  const deliveryWindows = getDeliveryWindowsByIds(allDeliveryWindows, orderDeliveryWindowIds);

  const hasUnits = deliveryWindows.some(({ deliveryWindow }) => getUnitsCountInDelwin(deliveryWindow, order.products) > 0);

  const preferredShippingDate = getOrderPreferredShippingDate(order);
  const cancelDate = getOrderCancelDate(order);

  return (
    <>
      <div className={styles.alertsWrapper}>
        <CancelledOrderProductsAlert hasProducts={hasUnits} />

        {hasOrderId && getIsOrderClosed(order) && !isEmpty(order.attachments) && (
          <Alert type="info" icon={IconType.Info} message={t('checkout:attachments_info', { count: order.attachments.length })} />
        )}
      </div>
      <CheckoutSection>
        <div className={cx({ [styles.noProducts]: !hasUnits })}>
          <CheckoutSummary />

          {someProductAreUnavailable && <p>{t('checkout:some_products_are_unavailable')}</p>}
        </div>

        {(hasUnits || !hasOrderId) && <CheckoutProductsReceipt order={order} products={products} isLoading={!hasOrderId} />}
      </CheckoutSection>

      {!isEmpty(productsWithFullyCancelledItems) && (
        <section>
          <CancelledProducts products={orderProducts} cancelledProducts={productsWithFullyCancelledItems} />
        </section>
      )}

      <CheckoutSection>
        <div className={universalStyles.twoColumnsGrid}>
          <CheckoutSubtotal isReadOnly />
          <div>
            <Heading variant={['blue', 'h2']} title={t('checkout:additional_info')} />
            {'poNumber' in checkoutFields && <ContentRow label={t('checkout:po_number')} value={order.poNumber} />}
            {'additionalNotes' in checkoutFields && <ContentRow label={t('checkout:additional_notes')} value={order.additionalNotes} />}
            <ContentRow label={t('checkout:estimated_delivery_date')} value={order.estimatedShippingDate} />
          </div>
        </div>
      </CheckoutSection>

      <CheckoutSection>
        <div className={universalStyles.twoColumnsGrid}>
          <div>
            {showShippingPreferences && (
              <Heading variant={['blue', 'h2']} title={t('shipping:shipping_preferences')} className={styles.shippingPreferenceHeading} />
            )}
            {showCheckoutShippingField && <ContentRow label={t('shipping:preferred_shipping_date')} value={preferredShippingDate} />}
            {showCheckoutCancelField && <ContentRow label={t('checkout:cancel_date')} value={cancelDate} />}
          </div>
          <ReceiptAddress isLoading={!hasOrderId} address={order.shippingAddress ?? {}} title={t('shipping:shipping_address_one')} />
        </div>
      </CheckoutSection>
    </>
  );
};
