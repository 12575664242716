import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 19;
const ORIGINAL_HEIGHT = 11;
const ORIGINAL_COLOR = '#ffffff';
const PROPORTION = ORIGINAL_HEIGHT / ORIGINAL_WIDTH;

export const ArrowBlackDown = ({ width = 14, color = ORIGINAL_COLOR }: IconComponentProps): JSX.Element => {
  const strokeWidth = ORIGINAL_WIDTH / width;

  return (
    <svg width={width} height={width * PROPORTION} version="1.1" viewBox="0 0 19 11">
      <g id="Page-1" strokeWidth={strokeWidth} fill="none" fillRule="evenodd">
        <g id="arrow_black_down" fill="none" transform="translate(1.000000, 1.000000)">
          <path d="M17.117,0 L8.556,8.559 L0,0" id="Imported-Layers" stroke={color} />
        </g>
      </g>
    </svg>
  );
};
