import { useQuery } from '@tanstack/react-query';
import { Order, Selections } from '@typings';
import { useTranslation } from 'react-i18next';

import { loadOrdersQuery } from '../../selectionRepository';

export const ORDERS_QUERY_KEY = 'ordersList';

interface OrdersList {
  data: Selections.FilterData<Order.OrderStatus[]>;
}

export const useOrdersList = ({ data }: OrdersList) => {
  const { t } = useTranslation(['orders']);

  return useQuery({
    meta: {
      errorMessage: t('orders:orders_fetch_failed'),
    },
    queryFn: async () => loadOrdersQuery(data),
    queryKey: [ORDERS_QUERY_KEY, { data }],
  });
};
