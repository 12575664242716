import { BuyerDataChangePayloadDTO } from '../../../typings';
import { unAuthenticatedPaths } from '../paths';
import { showroomApi } from '../services/api';

export const resetPassword = async (email: string) => {
  return showroomApi.POST('/password-reset-email')({
    data: { email, linkUri: unAuthenticatedPaths.PASSWORD_CHANGE },
  });
};

export const changePassword = async (token: string, id: string, newPassword: string) => {
  return showroomApi.POST('/password-reset')({
    data: { i: token, id, newPassword },
  });
};

export const changeAccountPassword = async (currentPassword: string, newPassword: string) => {
  return showroomApi.PUT('/password')({
    data: { currentPassword, newPassword },
  });
};

export const changeBuyersData = async (data: BuyerDataChangePayloadDTO) => {
  return showroomApi.POST('/user')({ data });
};

export const getUser = async () => {
  return showroomApi.GET('/user')({});
};
