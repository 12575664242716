import { Link } from '../Link';

interface Props {
  to: string;
  label: string;
  onClick?: () => void;
}

export const ToastActionLink = ({ to, label, onClick }: Props) => {
  return (
    <Link to={to} variant="white" onClick={onClick}>
      {label}
    </Link>
  );
};
