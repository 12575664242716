import { Id, Navigation } from '@typings';
import { nanoid } from 'nanoid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import universalStyles from '../../../../../css/utilities/universal.module.scss';
import { getHomepageSlug } from '../../../../ducks';
import { getPublishedPageData } from '../../../../logic/pages';
import { isDefined } from '../../../../utils/is';
import sortableStyles from '../../../various/Sortable/SortableList/SortableList.module.scss';
import { ListItem } from '../../ListItem';
import { ListItemHead } from '../../ListItem/ListItemHead';
import { ListItemTitle } from '../../ListItem/ListItemTitle';
import { MenuItemMarkets } from '../../MenuItemMarkets';
import { useMenuSectionContext } from '../context/MenuSectionContext';

interface Props {
  pageId: Id;
}

export const AvailableMenuItem = ({ pageId }: Props) => {
  const { addedMenuItems, setAddedMenuItems, areInputsDisabled, pagesRecords } = useMenuSectionContext();

  const { t } = useTranslation(['common']);

  const homepageSlug = useSelector(getHomepageSlug);
  const page = getPublishedPageData(pagesRecords[pageId]);

  const handleAddMenuItem = React.useCallback(() => {
    if (!isDefined(page)) {
      return;
    }

    const newMenuItem: Navigation.PageMenuItem = {
      id: nanoid(),
      label: page.name,
      markets: page.markets.map(market => market.id),
      openNewTab: false,
      pageId,
      type: 'page',
      url: null,
    };

    setAddedMenuItems([...addedMenuItems, newMenuItem]);
  }, [page, pageId, addedMenuItems, setAddedMenuItems]);

  const pageMarkets = React.useMemo(() => page?.markets.map(market => market.name), [page]);

  if (!isDefined(page)) {
    return null;
  }

  return (
    <ListItem>
      <ListItemHead
        center
        action={
          areInputsDisabled ? undefined : (
            <button className={sortableStyles.actionButton} onClick={handleAddMenuItem}>
              {t('common:add')} <span className={universalStyles.srOnly}>{page.name}</span>
            </button>
          )
        }
      >
        <ListItemTitle name={page.name} slug={page.slug} pageId={pageId} isHomepage={homepageSlug === page.slug} />
        {isDefined(pageMarkets) && <MenuItemMarkets markets={pageMarkets} />}
      </ListItemHead>
    </ListItem>
  );
};
