import { TableOptions, useReactTable } from '@tanstack/react-table';
import { ColumnsConfiguration } from '@typings';
import React from 'react';

import { isDefined } from '../../is';
import { mapColumnsConfigurationToVisibilityRecord } from '../../table';

export const useTable = <T>(options: TableOptions<T>, columnsConfiguration?: ColumnsConfiguration) => {
  const [columnVisibility, setColumnVisibility] = React.useState<Record<string, boolean>>(
    columnsConfiguration ? mapColumnsConfigurationToVisibilityRecord(columnsConfiguration) : {},
  );
  const optionsState = options.state ?? {};

  return useReactTable({
    enableMultiSort: false,
    onColumnVisibilityChange: isDefined(columnsConfiguration) ? setColumnVisibility : undefined,
    sortDescFirst: false,
    ...options,
    state: { columnVisibility, ...optionsState },
  });
};
