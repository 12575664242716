import cx from 'classnames';
import React from 'react';

import { useGalleryDots } from '../../../../../utils/hooks/useGalleryDots';

import styles from './Gallery.module.scss';

interface Props {
  activeIndex: number;
  totalCount: number;
  onChange: (index: number) => void;
}

type DotSize = 'regular' | 'small' | 'mini';

const VISIBLE_DOTS_COUNT = 7;
const DOT_BUTTON_SIZE = 16;
const DOT_SIZE_BY_OFFSET: Record<number, DotSize> = {
  0: 'regular',
  1: 'small',
  2: 'mini',
};

export const GalleryDots = ({ activeIndex, totalCount, onChange }: Props) => {
  const { dotsStyles, wrapperRef } = useGalleryDots({
    activeIndex,
    dotSize: DOT_BUTTON_SIZE,
    onChange,
    orientation: 'vertical',
    totalCount,
    visibleCount: VISIBLE_DOTS_COUNT,
  });

  const dots = React.useMemo(() => {
    return Array.from(Array(totalCount)).map((_, index) => ({
      index,
      size: DOT_SIZE_BY_OFFSET[Math.min(2, Math.abs(index - activeIndex))] ?? 'regular',
    }));
  }, [activeIndex, totalCount]);

  if (totalCount <= 1) {
    return null;
  }

  return (
    <div ref={wrapperRef} className={styles.dotsWrapper}>
      <div className={styles.dots} style={dotsStyles}>
        {dots.map(({ index, size }) => (
          <button key={index} type="button" className={styles.dotButton} onClick={() => onChange(index)}>
            <div className={cx(styles.dot, styles[size])} />
          </button>
        ))}
      </div>
    </div>
  );
};
