/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 24;
const ORIGINAL_COLOR = '#5c5c5c';

export const DownloadCloud = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => {
  return (
    <svg width={width} height={width} viewBox="0 0 24 24" fill={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.57 6.198A5.796 5.796 0 0 1 13.75 3a5.804 5.804 0 0 1 5.8 6.016 3.942 3.942 0 0 1-.491 7.852h-3.99v-.953h3.99a2.988 2.988 0 0 0 .012-5.976h-.004a.588.588 0 0 1-.034.001h-.539l.066-.535a4.89 4.89 0 0 0 .042-.6 4.848 4.848 0 0 0-9.396-1.68l-.259.697-.526-.527A2.158 2.158 0 0 0 4.736 8.82v.005l.001.017v.006l.005.064.013.377-.363.1a3.324 3.324 0 0 0 .886 6.526H8.93v.953H5.279a4.275 4.275 0 0 1-1.486-8.286A3.111 3.111 0 0 1 8.57 6.198Zm10.513 3.74h-.003.003Zm-.075-.95h.004-.004ZM12 20.54l-1.855-1.855-.674.674L12 21.89l2.53-2.53-.675-.674L12 20.541Z"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M11.523 21.07v-8.625h.954v8.624h-.954Z" />
    </svg>
  );
};
