import { Selects } from '@typings';

import { isEmpty } from '../../../../../utils/isEmpty';
import Icon, { IconColor, IconType } from '../../../Icon/Icon';
import { useSelectContext } from '../context/SelectContext';

import styles from './Pills.module.scss';

interface Props {
  renderValue: (value: Selects.NonNullableValue) => string | undefined;
}

export const Pills = ({ renderValue }: Props) => {
  const { selection, handleSelect } = useSelectContext();

  const handleClick = (value: Selects.NonNullableValue) => (event: React.MouseEvent) => {
    event.stopPropagation();
    handleSelect(value);
  };

  const handleMouseDown = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
  };

  if (!Array.isArray(selection) || isEmpty(selection)) {
    return null;
  }

  return (
    <>
      {selection.map(value => (
        // Mouse down is used to prevent the blur event from firing
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div key={value} className={styles.pill} onMouseDown={handleMouseDown} data-sentry-mask>
          {renderValue(value)}
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
          <div role="button" tabIndex={-1} className={styles.pillButton} onClick={handleClick(value)}>
            <Icon type={IconType.Cross} color={IconColor.Medium} size={8} />
          </div>
        </div>
      ))}
    </>
  );
};
