import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import universalStyles from '../../../css/utilities/universal.module.scss';
import { HEADER_MOBILE_BREAKPOINT } from '../../constants/breakpoints';
import { getConfiguration, getIsLoggedIn, getIsSeller, getIsSoftLogoutMode, getIsUserKnown, getUser, getUserNavigation } from '../../ducks';
import { compiledPaths } from '../../paths';
import { createAbsoluteUrl } from '../../utils/createAbsoluteUrl';
import { getIsFeatureEnabled } from '../../utils/getIsFeatureEnabled';
import { useIsScreenSize } from '../../utils/hooks';
import { useAmplitude } from '../../utils/hooks/useAmplitude';
import { useIsScrollingDown } from '../../utils/hooks/useIsScrollingDown';
import { isEmpty } from '../../utils/isEmpty';
import { Menu } from '../menu/Menu';
import Button from '../various/Button';
import { ClientLogo } from '../various/ClientLogo';
import { SyncLoader } from '../various/loaders/SyncLoader';

import { AccountDropdown } from './AccountDropdown';
import styles from './Header.module.scss';
import { LanguageSwitcher } from './LanguageSwitcher';
import { MenuToggle } from './MenuToggle';
import { TopMenu } from './TopMenu';
import { WhatsNew } from './WhatsNew';

interface Props {
  isSimple?: boolean;
}

export const Header = ({ isSimple = false }: Props) => {
  const { t } = useTranslation(['common']);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const navigationItems = useSelector(getUserNavigation);
  const isSoftLogout = useSelector(getIsSoftLogoutMode);
  const isMobile = useIsScreenSize(HEADER_MOBILE_BREAKPOINT);
  const isSeller = useSelector(getIsSeller);
  const config = useSelector(getConfiguration);
  const user = useSelector(getUser);

  const { trackEvent } = useAmplitude();

  const isEnabledWhatsNew = getIsFeatureEnabled('ENABLE_WHATS_NEW');
  const isScrollingDown = useIsScrollingDown({ isEnabled: isMobile });
  const hasNavigationItems = !isEmpty(navigationItems);

  React.useEffect(() => {
    document.body.classList.toggle('scrollingDown', isMobile && isScrollingDown);
  }, [isMobile, isScrollingDown]);

  const reloadShowroom = React.useCallback(() => location.replace(createAbsoluteUrl('')), []);

  const handleClientLogoClick = () => {
    trackEvent({ name: 'topbar.logo.click' });
  };

  const homePath = isSeller ? compiledPaths.ROOT({}) : compiledPaths.HOME({});

  const link =
    isSoftLogout ? undefined
    : isLoggedIn ? homePath
    : compiledPaths.LOGIN({});

  return (
    <>
      <header id="headerTop" className={cx(styles.header, { [styles.simple]: isSimple })}>
        <div className={styles.content}>
          <div className={styles.contentLeft}>
            {(isLoggedIn || hasNavigationItems) && <MenuToggle className={cx({ [styles.simple]: isSimple })} isAnonymous={!isLoggedIn} />}
            {isSimple ?
              <h1 className={universalStyles.srOnly}>{config.companyName}</h1>
            : <ClientLogo link={link} src={config.logoImageDark} companyName={config.companyName} onClick={handleClientLogoClick} />}
            {hasNavigationItems && !isSimple && <TopMenu />}
          </div>
          <div className={styles.translationWarning}>
            <p>{t('common:translation_warning')}</p>
          </div>
          <div className={styles.contentRight}>
            <div className={styles.syncWrapper}>
              <SyncLoader />
            </div>
            {isEnabledWhatsNew && <WhatsNew />}
            {getIsUserKnown(user) && isLoggedIn && <AccountDropdown user={user} />}
            {!isLoggedIn && <LanguageSwitcher variant="dark" />}
            {isSoftLogout && (
              <Button variant={['button', 'small', 'transparent']} onClick={reloadShowroom}>
                {isMobile ? t('common:back') : t('common:back_to_my_showroom')}
              </Button>
            )}
          </div>
        </div>
      </header>
      <Menu user={user} lookbookEnabled={config.lookbookEnabled} />
    </>
  );
};
