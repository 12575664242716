import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useClickOutside, useScreenResize } from '../../../../utils/hooks';
import { useStyleSelectKeyActions } from '../../../../utils/hooks/cms/useStyleSelectKeyActions';
import { isDefined } from '../../../../utils/is';
import { isEmpty } from '../../../../utils/isEmpty';
import { Input } from '../../../various';
import Icon, { IconColor, IconType } from '../../../various/Icon';
import { TextEllipsis } from '../../../various/TextEllipsis';
import { StyleSelectContext } from '../../context/StyleSelectContext';
import { NewStyleButton } from '../NewStyleButton';

import styles from './ContentPartStyleSelect.module.scss';
import { SubMenu } from './SubMenu';

interface Props {
  onChange: (value: string) => void;
  dataTestId: string;
  onEdit: (className: string) => void;
  onCreate: () => void;
}

export const ContentPartStyleSelect = ({ onChange, onEdit, dataTestId, onCreate }: Props) => {
  const {
    activeOption,
    filteredPartStyles,
    isActive,
    partStyle,
    textStyleName,
    searchValue,
    scrollElement,
    exitMenu,
    setActiveOption,
    setSearchValue,
    setPartStyle,
    showDropdown,
    hideDropdown,
    toggleDropdown,
    currentStyle,
    exitSubMenu,
    isLastOptionActive,
  } = React.useContext(StyleSelectContext);
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const { t } = useTranslation(['common']);

  const holderRef = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const applyCustomStyle = React.useCallback(
    (className: string) => () => {
      onChange(className);
      hideDropdown();
      setSearchValue('');
    },
    [onChange, hideDropdown, setSearchValue],
  );

  const editCustomStyle = React.useCallback(
    (className: string) => () => {
      hideDropdown();
      setSearchValue('');
      onEdit(className);
    },
    [hideDropdown, setSearchValue, onEdit],
  );

  const { onKeyDown } = useStyleSelectKeyActions({
    applyCustomStyle,
    editCustomStyle,
  });

  const onScroll = React.useCallback(() => setScrollPosition(scrollElement.current?.scrollTop ?? 0), [scrollElement]);

  React.useEffect(() => onScroll(), [isActive, onScroll]);

  useClickOutside({
    element: holderRef.current,
    enabled: isActive,
    handler: hideDropdown,
  });
  useScreenResize(hideDropdown);

  const onSearch = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPartStyle(event.target.value);
      setSearchValue(event.target.value);
    },
    [setPartStyle, setSearchValue],
  );

  const activateOption = (index: number) => () => setActiveOption(index);

  const focusOption = React.useCallback(
    (optionIndex: number) => {
      (scrollElement.current?.children[optionIndex] as Maybe<HTMLLIElement>)?.focus();
    },
    [scrollElement],
  );

  const focusActiveChild = React.useCallback(() => {
    if (!isDefined(activeOption)) {
      inputRef.current?.focus();

      return;
    }

    if (!isLastOptionActive) {
      focusOption(activeOption);
    }
  }, [activeOption, isLastOptionActive, focusOption]);

  React.useEffect(() => {
    const currentStyleIndex = filteredPartStyles.findIndex(({ className }) => className === currentStyle);
    setActiveOption(currentStyleIndex);
    focusOption(currentStyleIndex);
    exitSubMenu();
  }, [isActive, currentStyle]);

  return (
    <div className={cx(styles.textStyleSelect, { [styles.active]: isActive })}>
      <div
        className={styles.selectHolder}
        ref={holderRef}
        role="combobox"
        aria-expanded={isActive}
        aria-controls="options"
        tabIndex={-1}
        onKeyDown={onKeyDown}
      >
        <Input
          ref={inputRef}
          value={partStyle}
          variant={['dropdown']}
          placeholder={textStyleName}
          data-testid={dataTestId}
          onClick={showDropdown}
          onChange={onSearch}
          onFocus={exitMenu}
        />
        <button className={styles.arrowButton} onClick={toggleDropdown}>
          <Icon type={IconType.ArrowDown} size={16} color={IconColor.DarkGray} className={styles.arrowIcon} />
        </button>
        {isActive && (
          <div className={styles.listHolder} id="options">
            <ul role="listbox" className={styles.list} ref={scrollElement} onScroll={onScroll} data-testid="customStyleList">
              {filteredPartStyles.map((item, index) => (
                <TextEllipsis
                  as="li"
                  tabIndex={-1}
                  role="option"
                  aria-selected={currentStyle === item.className}
                  key={item.id}
                  className={cx(styles.option, {
                    [styles.selected]: currentStyle === item.className,
                    [styles.active]: activeOption === index,
                  })}
                  onClick={applyCustomStyle(item.className)}
                  onMouseEnter={activateOption(index)}
                >
                  <span className={styles.optionValue}>
                    <TextEllipsis>{item.name}</TextEllipsis>
                    {!item.isInternal && (
                      <Icon
                        type={IconType.ArrowRounded}
                        className={cx(styles.arrowRight, {
                          [styles.active]: currentStyle === item.className,
                        })}
                      />
                    )}
                  </span>
                  {!item.isInternal && activeOption === index && (
                    <SubMenu
                      partStyle={item}
                      onEdit={editCustomStyle(item.className)}
                      onApply={applyCustomStyle(item.className)}
                      scrollPosition={scrollPosition}
                      focusActiveChild={focusActiveChild}
                    />
                  )}
                </TextEllipsis>
              ))}
              {isEmpty(filteredPartStyles) && !isEmpty(searchValue) && (
                <li role="option" key="not-found" className={cx(styles.option, styles.notFound)} aria-selected={false}>
                  {t('common:not_found')}
                </li>
              )}
            </ul>

            <NewStyleButton onCreate={onCreate} />
          </div>
        )}
      </div>
    </div>
  );
};
