import cx from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { getAttributeNameMap } from '../../../../../ducks';
import Icon, { IconColor, IconType } from '../../../../various/Icon';

import styles from './FilterGroupContainer.module.scss';

interface Props {
  filterField: string;
  isExpanded: boolean;
  selectedCount: number;
  onToggleExpanded: VoidFunction;
}

export const FilterGroupContainer = ({ filterField, selectedCount, onToggleExpanded, isExpanded, children }: React.WithChildren<Props>) => {
  const sectionId = React.useId();
  const expanderId = React.useId();
  const attributeNameMap = useSelector(getAttributeNameMap);
  const groupTitle = attributeNameMap.get(filterField) ?? filterField;
  const chevronRotation = isExpanded ? 0 : 180;

  // TS doesn't recognise inert prop
  const inertProp = !isExpanded ? { inert: '' } : undefined;

  return (
    <div className={styles.groupContainer}>
      <h3 className={styles.groupHeader}>
        <button className={styles.groupExpander} aria-expanded={isExpanded} aria-controls={sectionId} onClick={onToggleExpanded}>
          <span className={styles.groupTitle}>
            <span id={expanderId}>{groupTitle}</span>
            {selectedCount > 0 && <span className={styles.filtersCount}>{selectedCount}</span>}
          </span>
          <Icon className={styles.chevron} type={IconType.Chevron} rotation={chevronRotation} color={IconColor.Gray} />
        </button>
      </h3>
      <div
        {...inertProp}
        id={sectionId}
        aria-labelledby={expanderId}
        role="region"
        className={cx(styles.groupContent, { [styles.expanded]: isExpanded })}
      >
        <div className={styles.contentInner}>{children}</div>
      </div>
    </div>
  );
};
