import { QueryClient } from '@tanstack/react-query';
import { applyMiddleware, compose, createStore, Store as ReduxStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';

import rootReducer, { createReduxHistory, routerMiddleware } from '../ducks/root';
import rootEpic from '../epics/rootEpic';
import { cmsChangeMiddleware } from '../middleware/cmsChangeMiddleware';
import * as services from '../services';
import { queryClient } from '../services/queryClient';

import { actionSanitizer, stateSanitizer } from './sanitizers';

const epicMiddleware = createEpicMiddleware<AppAction, AppAction, Store, Services & { queryClient: QueryClient }>({
  dependencies: { ...services, queryClient },
});

const composeEnhancers =
  '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__' in window && window.__SHOWROOM_SETTINGS__.NODE_ENV === 'development' ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      actionSanitizer,
      stateSanitizer,
      trace: true,
      traceLimit: 30,
    })
  : compose;

const store: ReduxStore<Store> = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(epicMiddleware), applyMiddleware(routerMiddleware), applyMiddleware(cmsChangeMiddleware)),
);

export const history = createReduxHistory(store);

epicMiddleware.run(rootEpic);

/* Expose Redux store in development mode */
if (window.__SHOWROOM_SETTINGS__.NODE_ENV === 'development') {
  // eslint-disable-next-line functional/immutable-data
  window.store = store;
}

export default store;
