import cx from 'classnames';

import { Skeleton } from '../../../Skeleton';

import styles from './Skeletons.module.scss';

interface Props {
  isMultiLine?: boolean;
  isRightAligned?: boolean;
}

export const TextSkeleton = ({ isMultiLine, isRightAligned }: Props) => {
  const textProps = isMultiLine ? { lastWidth: 80, rows: 2 } : {};

  return (
    <Skeleton
      type="text"
      gap="small"
      {...textProps}
      itemClassName={styles.textSkeleton}
      containerClassName={cx({ [styles.textSkeletonRight]: isRightAligned })}
    />
  );
};
