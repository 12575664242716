import styles from './Iframe.module.scss';

interface Props {
  src: string;
  title: string;
}

export const Iframe = ({ src, title }: Props) => (
  <div className={styles.iframeWrapper}>
    {/* eslint-disable-next-line @microsoft/sdl/react-iframe-missing-sandbox */}
    <iframe
      title={title}
      className={styles.iframe}
      src={src}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      sandbox="allow-same-origin allow-scripts allow-presentation"
    />
  </div>
);
