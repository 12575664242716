import cx from 'classnames';
import React, { KeyboardEventHandler } from 'react';
import { CSSTransition } from 'react-transition-group';

import { Key } from '../../../../utils/keys';
import { FocusTrap } from '../../FocusTrap';
import { useModalContext } from '../context/ModalContext';
import { ModalSize } from '../Modal';

import styles from './ModalContent.module.scss';

interface Props {
  className?: string;
  centerModal?: boolean;
}

export const ModalContent = ({ children, className, centerModal = true }: React.WithChildren<Props>) => {
  const { containerRef, size, fullScreenOnMobile, onClose, isOpen, shouldCloseOnEsc, shouldReturnFocusAfterClose } = useModalContext();
  const sizeClasses = cx({
    [styles.sizeSmall]: size === ModalSize.Small,
    [styles.sizeMedium]: size === ModalSize.Medium,
    [styles.sizeLarge]: size === ModalSize.Large,
    [styles.sizeXLarge]: size === ModalSize.XLarge,
    [styles.sizeXXLarge]: size === ModalSize.XXLarge,
  });

  const classNames = cx(styles.content, sizeClasses, className, {
    [styles.fullScreen]: fullScreenOnMobile,
  });

  const onEscape: KeyboardEventHandler<HTMLDivElement> = event => {
    if (isOpen && event.code === Key.ESCAPE && shouldCloseOnEsc) {
      event.stopPropagation();
      onClose();
    }
  };

  return (
    <CSSTransition
      timeout={{
        enter: 250,
        exit: 150,
      }}
      in={isOpen}
      classNames={{
        enter: styles.enter,
        enterActive: styles.enterActive,
        exit: styles.exit,
        exitActive: styles.exitActive,
      }}
      unmountOnExit
    >
      <div ref={containerRef} className={cx(styles.wrapper, { [styles.alignTop]: !centerModal })}>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div className={classNames} tabIndex={-1} onKeyDown={onEscape}>
          <FocusTrap shouldReturnFocus={shouldReturnFocusAfterClose}>{children}</FocusTrap>
        </div>
      </div>
    </CSSTransition>
  );
};
