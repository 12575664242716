/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;

export const Basket = ({ width = ORIGINAL_WIDTH, color }: IconComponentProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width={width} height={width} fill={color}>
      <path
        d="M3.75 4.75v10.5h12.5V4.75H3.75Zm-1.5-.15c0-.726.577-1.35 1.333-1.35h12.834c.756 0 1.333.624 1.333 1.35v10.8c0 .725-.577 1.35-1.333 1.35H3.583A1.342 1.342 0 0 1 2.25 15.4V4.6ZM7 6.25a.75.75 0 0 1 .75.75 2.25 2.25 0 1 0 4.5 0 .75.75 0 0 1 1.5 0 3.75 3.75 0 0 1-7.5 0A.75.75 0 0 1 7 6.25Z"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  );
};
