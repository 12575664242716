import { Cms } from '@typings';
import React from 'react';

import { DEFAULT_CONTENT_ALIGNMENT } from '../../../constants/cms';
import { ContentPosition } from '../../../logic/cms/contentSet';
import { getScreenRelativeStyles } from '../../../logic/pages';
import { useResizeObserver, useSpecifiedDeviceWidth } from '../../../utils/hooks';
import { isDefined } from '../../../utils/is';

interface Context {
  activePartPosition: {
    top: number;
    bottom: number;
    left: number;
    right: number;
  };
  activePartRef: React.MutableRefObject<HTMLElement | null>;
  contentPosition: ContentPosition;
  isInteracting: boolean;
  isMoving: boolean;
  isResizing: boolean;
  shouldShowSnapGuides: boolean;
  initialContentPosition: ContentPosition;
  setActivePartRef: React.Dispatch<React.SetStateAction<React.MutableRefObject<HTMLElement | null>>>;
  setContentPosition: React.Dispatch<React.SetStateAction<ContentPosition>>;
  setIsMoving: (isMoving: boolean) => void;
  setIsResizing: (isResizing: boolean) => void;
  setShouldShowSnapGuides: (shouldShowSnapGuides: boolean) => void;
}

export const ContentSetContext = React.createContext<Context | null>(null);

export const useContentSetContext = (): Context => {
  const context = React.useContext(ContentSetContext);

  if (!isDefined(context)) {
    throw new Error('ContentSetContext can not be used outside the scope of ContentSetContextProvider');
  }

  return context;
};

const INITIAL_ACTIVE_PART_POSITION = {
  bottom: 0,
  left: 0,
  right: 0,
  top: 0,
};

interface Props {
  parameters: Cms.ContentBlockText;
}

export const ContentSetContextProvider = ({ children, parameters }: React.WithChildren<Props>) => {
  const screenWidth = useSpecifiedDeviceWidth();

  const screenRelativeParameters = React.useMemo(() => {
    return getScreenRelativeStyles(screenWidth, parameters);
  }, [screenWidth, parameters]);

  const initialContentPosition = {
    left: screenRelativeParameters.horizontalPosition ?? DEFAULT_CONTENT_ALIGNMENT.desktop.horizontalPosition,
    top: screenRelativeParameters.verticalPosition,
    width: screenRelativeParameters.width,
  };

  const [contentPosition, setContentPosition] = React.useState<ContentPosition>(initialContentPosition);
  const [activePartPosition, setActivePartPosition] = React.useState(INITIAL_ACTIVE_PART_POSITION);
  const initialRef = React.useRef<HTMLElement | null>(null);
  const [activePartRef, setActivePartRef] = React.useState(initialRef);
  const [isMoving, setIsMoving] = React.useState(false);
  const [isResizing, setIsResizing] = React.useState(false);
  const [shouldShowSnapGuides, setShouldShowSnapGuides] = React.useState(false);

  const updateOnResize = () => {
    const activePart = activePartRef.current;

    const width = activePart?.clientWidth ?? 0;
    const currentTop = activePart?.parentElement?.getBoundingClientRect().top ?? 0;
    const parentTop = activePart?.parentElement?.parentElement?.getBoundingClientRect().top ?? 0;
    const offsetToParent = currentTop - parentTop;

    const offsets = {
      height: activePart?.clientHeight ?? 0,
      left: (activePart?.parentElement?.offsetLeft ?? 0) - width / 2,
      top: (activePart?.offsetTop ?? 0) + offsetToParent,
      width,
    };

    setActivePartPosition({
      bottom: offsets.top + offsets.height,
      left: offsets.left,
      right: offsets.left + offsets.width,
      top: offsets.top,
    });
  };

  useResizeObserver(activePartRef, () => requestAnimationFrame(updateOnResize));

  return (
    <ContentSetContext.Provider
      value={{
        activePartPosition,
        activePartRef,
        contentPosition,
        initialContentPosition,
        isInteracting: isMoving || isResizing,
        isMoving,
        isResizing,
        setActivePartRef,
        setContentPosition,
        setIsMoving,
        setIsResizing,
        setShouldShowSnapGuides,
        shouldShowSnapGuides,
      }}
    >
      {children}
    </ContentSetContext.Provider>
  );
};
