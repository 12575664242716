import { Cms } from '@typings';
import { clamp } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getCmsBlockById, getCurrentBlockId, setIsRepositioningImage, updateBlockSettings } from '../../../ducks';
import { getImageDimensions } from '../../../logic/cms/mediaPositioner';
import { getIsContentBlock,getScreenRelativeStyles } from '../../../logic/pages';
import { useSpecifiedDeviceWidth } from '../../../utils/hooks';
import { isDefined } from '../../../utils/is';
import { Key } from '../../../utils/keys';
import { FocusTrap } from '../../various/FocusTrap/FocusTrap';
import { EditorUIContext } from '../context/EditorUIContext';
import { ScrollOverlay } from '../ScrollOverlay';
import { ToolbarBase } from '../Toolbar/ToolbarBase';
import { AcceptDiscard } from '../Toolbar/Tools/AcceptDiscard';
import { Reset } from '../Toolbar/Tools/Reset';

import styles from './MediaPositioner.module.scss';
import { Medium } from './Medium';
import { PreparingMessage } from './PreparingMessage';

const INITIAL_IMAGE_OFFSET = { x: -0.5, y: -0.5 };

interface BackgroundInfo {
  type: Cms.MediaType;
  original: undefined | string;
  src: string;
}

export const MediaPositioner = () => {
  const { t } = useTranslation(['cms']);
  const dispatch = useDispatch();
  const currentBlock = useSelector(getCurrentBlockId);
  const blockData = useSelector(getCmsBlockById(currentBlock));
  const screenWidth = useSpecifiedDeviceWidth();

  const { screenType } = React.useContext(EditorUIContext);
  const positionerRef = React.useRef<HTMLDivElement | null>(null);
  const mediumWrapperRef = React.useRef<HTMLDivElement | null>(null);

  const [mediumSize, setMediumSize] = React.useState<Cms.MediumSize>({ height: 0, width: 0 });
  const [imageOffset, setImageOffset] = React.useState<Cms.ImageOffset>(INITIAL_IMAGE_OFFSET);
  const [imageDimensions, setImageDimensions] = React.useState<Cms.ImageDimensions>({
    height: 0,
    left: 0,
    top: 0,
    width: 0,
  });

  const moveData = React.useRef({
    initialX: 0,
    initialY: 0,
  });

  const [backgroundInfo, setBackgroundInfo] = React.useState<BackgroundInfo>({
    original: undefined,
    src: '',
    type: 'image',
  });

  React.useEffect(() => {
    if (!isDefined(blockData) || !getIsContentBlock(blockData) || !isDefined(blockData.settings.background)) {
      return;
    }

    const screenRelativeBackground = getScreenRelativeStyles(
      screenWidth,
      blockData.settings.background,
    ) as Maybe<Cms.BackgroundScreenRelativeProps>;

    const offset = screenRelativeBackground?.offset;

    if (isDefined(offset)) {
      setImageOffset({
        x: -offset.x / 100,
        y: -offset.y / 100,
      });
    }

    if (!isDefined(blockData.settings.background.general.media)) {
      return;
    }

    const { type, original, src } = blockData.settings.background.general.media;

    setBackgroundInfo({ original, src, type });
  }, [blockData]);

  React.useLayoutEffect(() => {
    setImageDimensions(getImageDimensions(positionerRef, mediumSize, imageOffset));
  }, [positionerRef, mediumSize, imageOffset]);

  const handleMove = React.useCallback(
    (event: MouseEvent) => {
      event.preventDefault();

      if (!isDefined(positionerRef.current)) {
        return;
      }

      const { offsetHeight, offsetWidth } = positionerRef.current;
      const widthDifference = Math.abs(imageDimensions.width - offsetWidth);
      const heightDifference = Math.abs(imageDimensions.height - offsetHeight);

      const translationX = event.pageX - moveData.current.initialX + imageOffset.x * widthDifference;
      const translationY = event.pageY - moveData.current.initialY + imageOffset.y * heightDifference;

      setImageOffset({
        x: clamp(-widthDifference, 0, translationX) / widthDifference || 0,
        y: clamp(-heightDifference, 0, translationY) / heightDifference || 0,
      });
    },
    [positionerRef, imageDimensions, imageOffset],
  );

  const endMove = React.useCallback(() => window.removeEventListener('mousemove', handleMove), [handleMove]);

  const initMove = React.useCallback(
    (event: MouseEvent) => {
      Object.assign(moveData.current, {
        initialX: event.pageX,
        initialY: event.pageY,
      });

      window.addEventListener('mousemove', handleMove);
      window.addEventListener('mouseup', endMove, { once: true });
    },
    [handleMove, endMove],
  );

  React.useEffect(() => {
    mediumWrapperRef.current?.addEventListener('mousedown', initMove);
    positionerRef.current?.addEventListener('keydown', handleEscKey);

    return () => {
      mediumWrapperRef.current?.removeEventListener('mousedown', initMove);
      positionerRef.current?.removeEventListener('keydown', handleEscKey);
    };
  }, [mediumWrapperRef, initMove]);

  const handleClosePositioner = React.useCallback(() => dispatch(setIsRepositioningImage(false)), [setIsRepositioningImage]);

  const handleEscKey = React.useCallback(
    (event: KeyboardEvent) => {
      if (event.code === Key.ESCAPE) {
        handleClosePositioner();
      }
    },
    [handleClosePositioner],
  );

  const handleResetRepositioning = React.useCallback(() => {
    setImageOffset(INITIAL_IMAGE_OFFSET);
  }, [setImageOffset]);

  const handleAcceptRepostioning = React.useCallback(() => {
    if (!isDefined(currentBlock)) {
      return;
    }

    const { x, y } = imageOffset;

    dispatch(
      updateBlockSettings({
        blockId: currentBlock,
        updatePath: ['background', screenType],
        value: {
          offset: {
            x: Math.abs(x * 100),
            y: Math.abs(y * 100),
          },
        },
      }),
    );

    handleClosePositioner();
  }, [updateBlockSettings, currentBlock, screenType, imageOffset]);

  const handleUpdateMediumSize = React.useCallback(
    (val: Cms.MediumSize) => {
      setMediumSize(val);
    },
    [setMediumSize],
  );

  const isLoadingMedium = mediumSize.height === 0 && mediumSize.width === 0;

  return (
    <>
      <ScrollOverlay />
      <div className={styles.mediaPositioner} ref={positionerRef}>
        <FocusTrap>
          {isLoadingMedium && <PreparingMessage onClose={handleClosePositioner} />}
          {!isLoadingMedium && (
            <ToolbarBase
              title={t('cms:reposition')}
              tools={[
                <Reset key="reset" name={t('cms:reset_position')} action={handleResetRepositioning} />,
                <AcceptDiscard key="acceptdiscard" discardAction={handleClosePositioner} acceptAction={handleAcceptRepostioning} />,
              ]}
            />
          )}
          <div className={styles.previewMedium}>
            <Medium
              mediumWrapperRef={mediumWrapperRef}
              imageDimensions={imageDimensions}
              mediumType={backgroundInfo.type}
              mediumSrc={backgroundInfo.original || backgroundInfo.src}
              updateMediumSize={handleUpdateMediumSize}
            />
          </div>
          <div className={styles.mediumContainer}>
            <Medium
              imageDimensions={imageDimensions}
              mediumType={backgroundInfo.type}
              mediumSrc={backgroundInfo.original || backgroundInfo.src}
            />
          </div>
        </FocusTrap>
      </div>
    </>
  );
};
