import { User } from '@typings';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getIsUserKnown, getOrderDetails } from '../../../../ducks';
import { getIsOrderClosed } from '../../../../logic/Orders';
import { useModalVisibility } from '../../../../utils/hooks';
import { CopyToNewSelectionModal } from '../../../orders/CopyToNewSelectionModal';
import Button from '../../../various/Button';
import { DownloadActions } from '../../DownloadActions';

import styles from './CheckoutSummaryActions.module.scss';

interface Props {
  user: User | Empty.Object;
  isLoadingOrder: boolean;
}

export const CheckoutSummaryActions = ({ user, isLoadingOrder }: Props) => {
  const { order, buyer } = useSelector(getOrderDetails);
  const { showModal } = useModalVisibility('CopyToNewSelectionModal');
  const { t } = useTranslation(['selections']);

  return (
    <>
      <div className={styles.headerActions}>
        <DownloadActions order={order} isLoadingOrder={isLoadingOrder} />
        {getIsUserKnown(user) && (
          <Button disabled={isLoadingOrder} onClick={showModal} variant={['blue', 'button', 'small']}>
            {t('selections:copy_to_new_selection')}
          </Button>
        )}
      </div>
      {getIsOrderClosed(order) && (
        <CopyToNewSelectionModal orderId={order.order} orderNumber={order.orderNumber} defaultBuyer={buyer.buyer} />
      )}
    </>
  );
};
