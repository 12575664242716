import { User } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getIsUserKnown, getOrderDetails, getStagesByUserType } from '../../../../ducks';
import { getIsSeller } from '../../../../logic/User';
import { getAccountNameOrFullName } from '../../../../utils/getAccountNameOrFullName';
import { useAvailableOrderDetails } from '../../../../utils/hooks/useAvailableOrderDetails';
import { isDefined } from '../../../../utils/is';
import { isEmpty } from '../../../../utils/isEmpty';
import { Link } from '../../../various/Link';
import { Skeleton } from '../../../various/Skeleton';

import styles from './OrderInformation.module.scss';

interface Props {
  user: User | Empty.Object;
  isLoadingOrder: boolean;
}

export const OrderInformation = ({ user, isLoadingOrder }: Props) => {
  const { t } = useTranslation(['orders', 'common', 'checkout', 'accounts', 'selections']);
  const stages = useSelector(getStagesByUserType);
  const { account } = useSelector(getOrderDetails);
  const { order } = useSelector(getOrderDetails);
  const { orderStatus, orderDate, accountName, accountId, buyerFirstName, buyerLastName } = useAvailableOrderDetails();
  const orderStage = stages.find(stage => stage.value === orderStatus);

  const getAccountName = () => {
    if (!isDefined(account) && !isLoadingOrder) {
      return <span className={styles.deactivedAccount}>{t('common:deactivated')}</span>;
    }

    if (isEmpty(accountName) && isLoadingOrder) {
      return <Skeleton type="text" size="large" />;
    }

    const accountLink = `/accounts/${accountId}`;
    const formattedName = getAccountNameOrFullName(accountName, buyerFirstName, buyerLastName);

    if (getIsUserKnown(user) && getIsSeller(user)) {
      return (
        <Link variant="blue" to={accountLink}>
          {formattedName}
        </Link>
      );
    }

    return formattedName;
  };

  const orderInformation = [
    {
      name: t('accounts:account_one'),
      value: getAccountName(),
    },
    {
      name: t('checkout:order_status'),
      value: isDefined(orderStage) ? t(orderStage.i18nKey) : <Skeleton type="text" size="large" />,
    },
    {
      name: t('checkout:order_date'),
      value: orderDate,
    },
    {
      name: t('common:last_modified'),
      value: order.lastModifiedDate ?? <Skeleton type="text" size="large" />,
    },
  ];

  return (
    <div className={styles.orderInfo}>
      {orderInformation
        .filter(info => {
          const hasContent =
            typeof info.value === 'string' ?
              !isEmpty(info.value)
            : React.isValidElement(info.value) && React.Children.count(info.value) > 0;

          return isDefined(info.value) && hasContent;
        })
        .map(info => {
          return (
            <div className={styles.orderDetail} key={info.name}>
              <div className={styles.orderDetailName}>{info.name}</div>
              <span data-sentry-mask>{info.value}</span>
            </div>
          );
        })}
    </div>
  );
};
