/* eslint-disable no-magic-numbers */
import { Overview } from '@typings';
import * as d3 from 'd3';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getOrderCurrency } from '../../../../ducks';
import { formatPriceWithCurrencyAffix } from '../../../../logic/price';
import { getIsTopLevelRow, PIE_CHART_SIZE as SIZE } from '../../../../logic/selectionOverview';
import { isDefined, isNull } from '../../../../utils/is';
import { MaybeText } from '../MaybeText';

import styles from './PieChart.module.scss';

interface Props {
  color: d3.ScaleOrdinal<string, string>;
  node: Overview.PieChartNode;
  valueKey: 'totalPrice' | 'totalUnits';
  totalCount: number;
  getPercentage: (node: Overview.PieChartNode) => string;
  handleMouseOut: () => void;
  handleMouseMove: React.MouseEventHandler<SVGPathElement>;
  handleMouseOver: (newText: React.ReactNode) => () => void;
}

export const Segment = (props: Props) => {
  const { node, valueKey, getPercentage, totalCount, color, handleMouseMove, handleMouseOut, handleMouseOver } = props;
  const { t } = useTranslation(['common']);
  const currency = useSelector(getOrderCurrency);

  const fillOpacity = React.useMemo(() => {
    const depthOpacity = node.data.hasMergedVariants ? node.depth - 1 : node.depth;

    return Math.min(0.6, 0.6 / (depthOpacity - 0.5));
  }, [node]);

  const tooltipText = React.useMemo(() => {
    const value = node.data[valueKey];
    const { primaryDimension, secondaryDimension } = node.data;
    const isTopLevelRow = getIsTopLevelRow(node.data);
    const showSecondaryDimension = isDefined(secondaryDimension) && isTopLevelRow;
    const valueText = {
      totalPrice: `${formatPriceWithCurrencyAffix(currency)(value)}`,
      totalUnits: `${value} ${t('common:unit', { count: value }).toLowerCase()}`,
    };

    return (
      <>
        <MaybeText text={primaryDimension} />
        {showSecondaryDimension && (
          <>
            , <MaybeText text={secondaryDimension} />
          </>
        )}{' '}
        <span className={styles.productInfo}>
          {valueText[valueKey]} ({getPercentage(node)})
        </span>
      </>
    );
  }, [valueKey, getPercentage, currency, t, node]);

  const getInnerRadius = React.useCallback(() => {
    const isTopLevelRow = getIsTopLevelRow(node.data);

    if (node.data[valueKey] === totalCount && isTopLevelRow) {
      return 0;
    }

    return isTopLevelRow ? 0.25 : node.y0;
  }, [node.data, node.y0, totalCount, valueKey]);

  const getColor = React.useCallback(
    (nodeData: Overview.PieChartNode): string => {
      while (nodeData.depth > 1) {
        if (!isNull(nodeData.parent)) {
          return getColor(nodeData.parent);
        }
      }

      return color(nodeData.data.key);
    },
    [color],
  );

  const arc = React.useMemo(() => {
    return d3
      .arc<Overview.PieChartNode>()
      .startAngle(nodeData => nodeData.x0)
      .endAngle(nodeData => nodeData.x1)
      .padAngle(nodeData => Math.min((nodeData.x1 - nodeData.x0) / 2, 0.005))
      .padRadius(SIZE / 4)
      .innerRadius(getInnerRadius)
      .outerRadius(nodeData => nodeData.y1 - 1);
  }, [getInnerRadius]);

  return (
    <path
      fill={getColor(node)}
      d={arc(node) ?? undefined}
      fillOpacity={fillOpacity}
      onMouseOver={handleMouseOver(tooltipText)}
      onMouseMove={handleMouseMove}
      onMouseOut={handleMouseOut}
    />
  );
};
