import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Cms, Id } from '@typings';
import { useTranslation } from 'react-i18next';

import { addToast } from '../../../components/various/Toasts';
import { updateCustomLayout } from '../../customLayoutsRepository';

import { CUSTOM_LAYOUTS_QUERY_KEY } from './useCustomLayoutsList';

interface MutationVariables {
  layoutId: Id;
  data: Cms.CustomLayoutRequestData;
}

export const useUpdateCustomLayout = () => {
  const { t } = useTranslation(['cms']);
  const queryClient = useQueryClient();

  const mutationFn = async ({ layoutId, data }: MutationVariables) => {
    return updateCustomLayout(layoutId, data);
  };

  const handleSuccess = (updatedLayout: Cms.CustomLayoutData, { layoutId }: MutationVariables) => {
    addToast(t('cms:custom_layouts_edit_success'));

    queryClient.setQueriesData<Cms.CustomLayoutData[]>({ queryKey: [CUSTOM_LAYOUTS_QUERY_KEY] }, (layouts = []) => {
      return layouts.map(layout => (layout.id === layoutId ? updatedLayout : layout));
    });
    queryClient.invalidateQueries({ queryKey: [CUSTOM_LAYOUTS_QUERY_KEY] });
  };

  const handleError = () => {
    addToast(t('cms:custom_layouts_edit_fail'));
  };

  return useMutation({
    mutationFn,
    onError: handleError,
    onSuccess: handleSuccess,
  });
};
