import { Item, Product } from '@typings';
import React from 'react';
import { useSelector } from 'react-redux';

import { getAreBackordersEnabled, getOptimisticUpdatesForProduct, getOrderedProductByDeliveryWindow } from '../../ducks';
import { getPresetAvailableStock } from '../../logic/unitsDistribution';
import { isDefined } from '../is';
import { isEmpty } from '../isEmpty';

interface Props {
  variant: Product.Standard | undefined;
  deliveryWindowId: string;
}

export const useVariantQuantities = ({ variant, deliveryWindowId }: Props) => {
  const optimisticUpdatesForProductSelector = React.useMemo(() => getOptimisticUpdatesForProduct(variant?.product), [variant]);

  const optimisticUpdates = useSelector(optimisticUpdatesForProductSelector);

  const orderedProductSelector = React.useMemo(
    () => getOrderedProductByDeliveryWindow(variant?.product, variant?.variant, deliveryWindowId),
    [deliveryWindowId, variant],
  );

  const areBackordersEnabled = useSelector(getAreBackordersEnabled);
  const orderedProduct = useSelector(orderedProductSelector);

  if (!isDefined(variant)) {
    return {
      availableToDistribute: {},
      quantityAdded: {},
    };
  }

  const getCurrentItemQuantity = (itemId: string) => {
    const optimisticKey = Object.keys(optimisticUpdates).find(key => key === `${deliveryWindowId}-${itemId}`);
    const orderItem = orderedProduct?.items.find(orderProductItem => orderProductItem.item === itemId);

    if (!isDefined(optimisticKey) || isEmpty(optimisticKey)) {
      return orderItem?.quantity ?? 0;
    }

    return optimisticUpdates[optimisticKey] ?? 0;
  };

  return variant.items.reduce<{ quantityAdded: Record<Item['sizeId'], number>; availableToDistribute: Record<Item['sizeId'], number> }>(
    (acc, item) => {
      const currentItemQuantity = getCurrentItemQuantity(item.item);

      if (areBackordersEnabled) {
        return {
          availableToDistribute: { ...acc.availableToDistribute, [item.sizeId]: Number.MAX_SAFE_INTEGER },
          quantityAdded: { ...acc.quantityAdded, [item.sizeId]: currentItemQuantity },
        };
      }

      const presetAvailableStock = getPresetAvailableStock(item, deliveryWindowId);

      return {
        availableToDistribute: { ...acc.availableToDistribute, [item.sizeId]: presetAvailableStock - currentItemQuantity },
        quantityAdded: { ...acc.quantityAdded, [item.sizeId]: currentItemQuantity },
      };
    },
    { availableToDistribute: {}, quantityAdded: {} },
  );
};
