import { Invoice } from '@typings';
import { useTranslation } from 'react-i18next';

import { CMS_SCREENS_SIZES } from '../../../constants/cms';
import { getCalculatedInvoiceStatus, getIsInvoiceFull } from '../../../logic/invoices';
import { useInvoiceDownload } from '../../../services/hooks/invoice/useInvoiceDownload';
import { useIsScreenSize } from '../../../utils/hooks';
import { isDefined } from '../../../utils/is';
import Button from '../../various/Button';
import { Card } from '../../various/Card';
import Heading from '../../various/Heading';
import { IconType } from '../../various/Icon';
import { InvoiceStatusLabel } from '../../various/StatusLabel';

import styles from './InvoiceInfo.module.scss';

interface Props {
  invoiceIdentifiers: Invoice.InvoiceIdentifiers;
  invoice: Invoice.Full | Invoice.Base;
}

export const InvoiceInfo = ({ invoice, invoiceIdentifiers }: Props) => {
  const { t } = useTranslation(['common', 'invoices']);

  const invoiceHeading = t('invoices:invoice_number', {
    invoiceNumber: invoice.invoiceNumber,
  });
  const isMobile = useIsScreenSize(CMS_SCREENS_SIZES.mobile);
  const { downloadInvoice } = useInvoiceDownload();
  const onInvoiceDownload = () => {
    const { invoiceNumber } = invoice;
    downloadInvoice({ ...invoiceIdentifiers, invoiceNumber });
  };

  return (
    <>
      {getIsInvoiceFull(invoice) && (
        <Button
          aria-label={t('invoices:download_invoice')}
          className={styles.invoiceDownloadButton}
          icon={IconType.DownloadCloud}
          onClick={onInvoiceDownload}
          variant={['button', 'small', 'blue']}
        >
          {!isMobile ? t('invoices:download_pdf') : undefined}
        </Button>
      )}
      <Card.Wrapper className={styles.cardWrapper}>
        <Card.Group className={styles.cardGroup}>
          <Card.Title>
            <Heading dataTestId="invoice-heading" title={invoiceHeading} className={styles.header} />
            <InvoiceStatusLabel status={getCalculatedInvoiceStatus(invoice)} />
          </Card.Title>
          <Card.DescriptionList className={styles.list}>
            <Card.Description
              dataTestId="invoice-created-at"
              className={Card.styles.row1col2}
              name={t('common:created')}
              value={invoice.createdAt}
            />
            <Card.Description
              dataTestId="invoice-due-date"
              className={Card.styles.row1col3}
              name={t('common:due_date')}
              value={invoice.dueDate}
            />
            {getIsInvoiceFull(invoice) && (
              <>
                <Card.Description
                  dataTestId="invoice-company"
                  className={Card.styles.row2col1}
                  name={t('common:company')}
                  value={invoice.company}
                />
                <Card.Description
                  dataTestId="invoice-shipment-number"
                  className={Card.styles.row2col2}
                  name={t('common:shipment_number', { shipmentNumber: '' })}
                  value={isDefined(invoice.shippingIds) ? invoice.shippingIds : '-'}
                />
                <Card.Description
                  dataTestId="invoice-payment-terms"
                  className={Card.styles.row2col3}
                  name={t('invoices:payment_terms')}
                  value={invoice.paymentTerms}
                />
                <Card.Description
                  dataTestId="invoice-address"
                  className={Card.styles.row3col1}
                  name={t('common:address')}
                  value={invoice.address.split(',')}
                />
                <Card.Description
                  dataTestId="invoice-shipping-terms"
                  className={Card.styles.row3col3}
                  name={t('invoices:shipping_terms')}
                  value={invoice.shippingTerms}
                />
              </>
            )}
          </Card.DescriptionList>
        </Card.Group>
      </Card.Wrapper>
    </>
  );
};
