import classNames from 'classnames';
import React from 'react';

import styles from '../Pagination.module.scss';

import PagerValue from './PagerValue';

interface Props {
  title?: string;
  type: PagerType;
  page: number;
  value?: number;
  className?: string;
  onClick?: (pageNumber: number) => void;
  disabled?: boolean;
}

export type PagerType = 'prev' | 'next' | 'page' | 'dots';

export const Pager = React.memo(({ title = '', page, type, value, className, onClick, disabled }: Props) => {
  const handleClick = () => onClick?.(page);

  return (
    <li>
      <button aria-label={title} className={classNames(styles.pager, className)} onClick={handleClick} disabled={disabled}>
        <PagerValue type={type} value={value} />
      </button>
    </li>
  );
});
