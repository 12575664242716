import { Product } from '@typings';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getOrderCurrency } from '../../../../../ducks';
import { formatPriceWithCurrencyAffix, getIsOnSale } from '../../../../../logic/price';
import { isDefined } from '../../../../../utils/is';
import { useMatrixSettingsContext, useMatrixVariantsContext } from '../../../../products/ProductMatrix/context';

import styles from './VariantInfo.module.scss';
import { VariantName } from './VariantName';

interface Props {
  variant: Product.Standard;
}

export const VariantInfo = ({ variant }: Props) => {
  const { isLookbook, isRRPEnabled } = useMatrixSettingsContext();
  const { pricePerVariant } = useMatrixVariantsContext();
  const { rrpAsNumber, priceAsNumber, discountPercent, showAsOnSale } = pricePerVariant[variant.variant] ?? {};

  const { t } = useTranslation(['common']);
  const currency = useSelector(getOrderCurrency);
  const priceFormatter = formatPriceWithCurrencyAffix(currency);

  const isDiscounted = getIsOnSale(showAsOnSale, discountPercent);

  const priceValue = isDefined(priceAsNumber) ? priceFormatter(priceAsNumber) : null;
  const rrpValue = isRRPEnabled && isDefined(rrpAsNumber) ? priceFormatter(rrpAsNumber) : null;

  return (
    <div className={styles.variantInfo}>
      <VariantName variant={variant} />
      {!isLookbook && (
        <div className={styles.price}>
          <span className={styles.headPrice}>{priceValue}</span>
          {isDiscounted && <span className={styles.discount}> -{discountPercent}%</span>}
          {isDefined(rrpValue) && (
            <span className={styles.rrp}>
              {' '}
              ({t('common:rrp')}: {rrpValue})
            </span>
          )}
        </div>
      )}
      <div className={styles.artNo}>{t('common:art_no', { artNo: variant.sku })}</div>
    </div>
  );
};
