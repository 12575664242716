import { Product } from '@typings';

import { useProductConfiguration } from '../../../../../utils/hooks/matrix/useProductConfiguration';
import { useMatrixVariantsContext } from '../../../../products/ProductMatrix/context';
import { AttributeValue } from '../../MatrixContents/Attribute';

import styles from './VariantBodyCells.module.scss';

interface Props {
  productConfiguration: Product.ProductConfiguration;
  rowIndex: number;
  rowSpan?: number;
}

export const ConfigurationVariantCells = ({ productConfiguration, rowIndex, rowSpan }: Props) => {
  const { variants } = useMatrixVariantsContext();
  const { configurationAttributeValuesPerVariant } = useProductConfiguration({ productConfiguration, variants });

  const [previousVariantAttributeValue] = configurationAttributeValuesPerVariant[rowIndex - 1] ?? [];
  const [currentVariantAttributeValue, ...secondaryAttributesValues] = configurationAttributeValuesPerVariant[rowIndex] ?? [];

  const primaryAttributeValue = previousVariantAttributeValue === currentVariantAttributeValue ? '' : currentVariantAttributeValue;

  const attributesValues = [primaryAttributeValue, ...secondaryAttributesValues];

  return (
    <>
      {attributesValues.map((attributeValue, idx) => (
        <th key={idx} className={styles.attributeCell} rowSpan={rowSpan}>
          <AttributeValue value={attributeValue ?? ''} />
        </th>
      ))}
    </>
  );
};
