import { PolymorphicComponentProps } from '@typings';
import cx from 'classnames';
import React from 'react';

import { isDefined } from '../../../utils/is';

import styles from './TextEllipsis.module.scss';

interface Props {
  noWrapText?: boolean;
  overflowHidden?: boolean;
  className?: string;
  lines?: number;
}

type TextEllipsisProps<C extends React.ElementType> = PolymorphicComponentProps<C, Props>;

export const TextEllipsis = React.forwardRef(
  <C extends React.ElementType = 'span'>(
    { as, noWrapText = true, overflowHidden = true, className, children, lines, ...restProps }: TextEllipsisProps<C>,
    ref: React.Ref<HTMLElement>,
  ) => {
    const Component = isDefined(as) ? as : 'span';

    const styleLines = isDefined(lines) ? { WebkitLineClamp: lines } : {};

    return (
      <Component
        ref={ref}
        className={cx(
          {
            [styles.noWrapText]: noWrapText,
            [styles.overflowHidden]: overflowHidden,
            [styles.multipleLines]: lines,
          },
          className,
          styles.textEllipsis,
        )}
        style={styleLines}
        {...restProps}
      >
        {children}
      </Component>
    );
  },
);
