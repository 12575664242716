import React from 'react';
import { useTranslation } from 'react-i18next';

import { HelpTooltip } from '../../../various/HelpTooltip';

interface Props {
  translationKey: 'settings:added_items_info' | 'settings:items_to_add_info';
}

export const ItemsHelperTooltip = ({ translationKey }: Props) => {
  const { t } = useTranslation(['settings']);
  const { title, points } = t(translationKey, { returnObjects: true });

  const content = React.useMemo(
    () => (
      <>
        {title}
        <ul>{points?.map(point => <li key={point}>{point}</li>)}</ul>
      </>
    ),
    [points, title],
  );

  return <HelpTooltip content={content} />;
};
