import { Cms } from '@centra';

import styles from '../contentBlock.module.scss';

interface Props {
  parameters: Cms.ContentBlockOverlay;
}

export const Overlay = ({ parameters }: Props) => {
  const {
    general: { color, opacity },
  } = parameters;
  const style = { backgroundColor: color, opacity };

  return <div className={styles.overlay} style={style} />;
};
