/* eslint-disable max-len */

import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 22;
const ORIGINAL_COLOR = '#333333';

export const Filter = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => {
  return (
    <svg width={width} height={width} viewBox="0 0 24 24">
      <path fill="none" d="M0 0h24v24H0V0z" />
      <path
        fill={color}
        d="M11 18h2c.55 0 1-.45 1-1s-.45-1-1-1h-2c-.55 0-1 .45-1 1s.45 1 1 1zM3 7c0 .55.45 1 1 1h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1zm4 6h10c.55 0 1-.45 1-1s-.45-1-1-1H7c-.55 0-1 .45-1 1s.45 1 1 1z"
      />
    </svg>
  );
};
