import { AttributesSource, Product } from '@typings';
import React from 'react';
import { useSelector } from 'react-redux';

import { MATRIX_MOBILE_BREAKPOINT } from '../../../../constants/breakpoints';
import { getOrderDetails, isProductAttributeEnabled } from '../../../../ducks';
import { getIsOrderClosed } from '../../../../logic/Orders';
import { useIsScreenSize } from '../../../../utils/hooks';
import { isDefined } from '../../../../utils/is';

interface Props {
  attributesSource?: AttributesSource;
  isReadOnly?: boolean;
  isLookbook?: boolean;
  productConfiguration: Product.ProductConfiguration | undefined;
  showCancelled?: boolean;
}

interface Context {
  isLookbook: boolean;
  isMobile: boolean;
  isRRPEnabled: boolean;
  isReadOnly: boolean;
  productConfiguration: Product.ProductConfiguration | undefined;
  showCancelled?: boolean;
  isOrderClosed: boolean;
}

export const useMatrixSettingsContext = (): Context => {
  const context = React.useContext(MatrixSettingsContext);

  if (!isDefined(context)) {
    throw new Error('MatrixSettingsContext can not be used outside the scope of MatrixSettingsContextProvider');
  }

  return context;
};

export const MatrixSettingsContext = React.createContext<Context | null>(null);

export const MatrixSettingsContextProvider = (props: React.WithChildren<Props>) => {
  const {
    attributesSource = 'details',
    children,
    productConfiguration,
    isReadOnly = false,
    isLookbook = false,
    showCancelled = false,
  } = props;
  const isRRPEnabled = useSelector(isProductAttributeEnabled(attributesSource, 'rrp'));
  const isMobile = useIsScreenSize(MATRIX_MOBILE_BREAKPOINT);
  const order = useSelector(getOrderDetails);
  const isOrderClosed = getIsOrderClosed(order.order);

  return (
    <MatrixSettingsContext.Provider
      value={{ isLookbook, isMobile, isOrderClosed, isRRPEnabled, isReadOnly, productConfiguration, showCancelled }}
    >
      {children}
    </MatrixSettingsContext.Provider>
  );
};
