import { Cms, Id } from '@typings';

import { isDefined } from '../../../utils/is';

export const getCmsGroups = (state: Store) => state.cms.groups;

export const findCmsGroupById = (id: Nullable<Id>, groups: Record<Id, Cms.Group>) => {
  if (!isDefined(id)) {
    return null;
  }

  const groupData = groups[id];

  return isDefined(groupData) ? groupData : null;
};

export const getCmsGroupById = (id: Nullable<Id>) => (state: Store) => findCmsGroupById(id, state.cms.groups);
