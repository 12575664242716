import { DeliveryWindow, FailedProducts } from '@typings';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { useExpand } from '../../../utils/hooks';
import { CancelledProductsTooltip } from '../CancelledProductsTooltip';
import { DelwinNameAndDate } from '../DelwinNameAndDate';
import Icon, { IconColor, IconType } from '../Icon';
import { List, ListItem } from '../List';
import { Tooltip } from '../Tooltip';

import styles from './FailedProductsList.module.scss';

type Data = FailedProducts.FailedProduct | FailedProducts.FailedProductWithoutData;

interface Props {
  isExpired: boolean;
  delwinData: DeliveryWindow.Mixed;
  model: Map<string, ListItem<Data>>;
  variants: Data[];
  productsContainerClassname?: string;
  tableStyles?: string;
  isCancelled?: boolean;
}

export const FailedProductsCard = ({
  isExpired,
  delwinData,
  variants,
  productsContainerClassname,
  model,
  tableStyles,
  isCancelled = false,
}: Props) => {
  const { t } = useTranslation(['products', 'expiredDelwins']);
  const { isOpen, toggle, wrapperRef } = useExpand<HTMLDivElement>({ initialOpen: false });

  return (
    <div>
      <button className={styles.rowHeadings} onClick={toggle} data-delwin-id={delwinData.deliveryWindow}>
        <div className={styles.deliveryWindowName}>
          <span>
            <DelwinNameAndDate deliveryWindow={delwinData} />
          </span>
          {isExpired && (
            <Tooltip content={t('expiredDelwins:delivery_window_expired')}>
              <div className={styles.alert}>
                <Icon type={IconType.Alert} />
              </div>
            </Tooltip>
          )}
          {isCancelled && <CancelledProductsTooltip className={cx(styles.alert, styles.cancelled)} />}
        </div>
        <div className={styles.unitsInfo}>{t('products:product_count', { count: variants.length })}</div>
        <div>
          <Icon type={IconType.ArrowBlackDown} color={IconColor.Medium} rotation={isOpen ? 180 : 0} />
        </div>
      </button>
      <div ref={wrapperRef} className={cx(styles.productsContainer, productsContainerClassname, { [styles.expanded]: isOpen })}>
        <List className={cx(tableStyles, styles.table)} keyProperty="variant" data={variants} model={model} />
      </div>
    </div>
  );
};
