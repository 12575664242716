import { Lookbook } from '@typings';

import { ACCEPT_HEADER_BASE } from '../constants/api';

import { showroomApi } from './api';

export const fetchAllLookbooks = async () => showroomApi.QUERY_GET('/lookbooks')({});

export const createLookbook = async (data: Lookbook.CreateLookbookDTO) =>
  showroomApi.QUERY_POST('/lookbooks')({
    data: {
      ...data,
      linkUri: 'lookbook',
    },
  });

export const deleteLookbook = async (id: string) =>
  showroomApi.DELETE('/lookbooks/:id')({
    pathParams: {
      id,
    },
  });

export const lookbookLogin = async (id: string) =>
  showroomApi.QUERY_GET('/lookbook/:id')({
    headers: {
      Accept: ACCEPT_HEADER_BASE,
    },
    pathParams: { id },
  });
