import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { fetchMarketsRequest, fetchPricelistsRequest } from '../../../ducks';
import { useModalVisibility } from '../../../utils/hooks';
import { ModalShell } from '../../various/ModalShell';

import { CreateAccountForm } from './CreateAccountForm';

export const CreateAccountModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['accounts']);
  const { isModalVisible, hideModal } = useModalVisibility('CreateAccountModal');

  React.useEffect(() => {
    dispatch(fetchMarketsRequest());
    dispatch(fetchPricelistsRequest());
  }, [dispatch]);

  return (
    <ModalShell isOpen={isModalVisible} title={t('accounts:create_new_account')} onClose={hideModal}>
      <CreateAccountForm onCancel={hideModal} onSuccess={hideModal} />
    </ModalShell>
  );
};
