import React from 'react';
import { useSelector } from 'react-redux';

import { CMS_SCREENS_SIZES, TOP_BAR_HEIGHT } from '../../../constants/cms';
import { getCurrentItem } from '../../../ducks';
import { isFullWidthBlock, isLeftSideBlock } from '../../../logic/cms/structuralBlocks';
import { clamp } from '../../clamp';
import { isDefined } from '../../is';
import { useScreenWidth } from '../useScreenWidth';

import { useGetCurrentBlockData } from './useGetCurrentBlockData';

const POSITION_PADDING = 20;
const VALIDATION_MESSAGE_GAP = 50;
const HALF = 0.5;

export const useStyleEditorPosition = (isEditorVisible: boolean, editorRef: React.MutableRefObject<Nullable<HTMLDivElement>>) => {
  const currentItem = useSelector(getCurrentItem);
  const currentBlock = useGetCurrentBlockData();
  const screenWidth = useScreenWidth();

  const [top, setTop] = React.useState(0);
  const [left, setLeft] = React.useState(0);

  const currentItemElement = currentItem.element?.current;
  const editor = editorRef.current;

  const currentScreenAvailableSize = React.useCallback(
    () => (screenWidth < CMS_SCREENS_SIZES.desktop ? screenWidth : CMS_SCREENS_SIZES.desktop),
    [screenWidth],
  );

  const calculateLeftPositionForFullWidthBlock = React.useCallback(
    (right: number) => {
      const maxLeftPosition = right + POSITION_PADDING;
      if (!isDefined(editorRef.current)) {
        return 0;
      }
      const { offsetWidth } = editorRef.current;

      if (maxLeftPosition + offsetWidth < currentScreenAvailableSize()) {
        return maxLeftPosition;
      }

      return right - POSITION_PADDING - offsetWidth;
    },
    [currentScreenAvailableSize, editorRef],
  );

  const calculateLeftPosition = React.useCallback(() => {
    const isElementInBlock = isDefined(currentBlock) && isDefined(currentItemElement);

    if (!isElementInBlock || !isDefined(editor)) {
      return 0;
    }

    const screenSize = currentScreenAvailableSize();
    const leftOffset = Math.round((window.innerWidth - screenSize) * HALF);
    const { offsetWidth } = editor;
    const { left: elementLeft, right, width } = currentItemElement.getBoundingClientRect();

    if (isLeftSideBlock(currentBlock)) {
      return right + POSITION_PADDING - leftOffset;
    }

    if (isFullWidthBlock(currentBlock)) {
      const leftPosition = right + POSITION_PADDING - leftOffset;
      const shouldPlaceOnLeftSide = width < screenSize * HALF && leftPosition > (screenSize + width) * HALF;

      if (shouldPlaceOnLeftSide) {
        return elementLeft - POSITION_PADDING - offsetWidth - leftOffset;
      }

      return calculateLeftPositionForFullWidthBlock(right - leftOffset);
    }

    return elementLeft - POSITION_PADDING - offsetWidth - leftOffset;
  }, [calculateLeftPositionForFullWidthBlock, currentBlock, currentItemElement, currentScreenAvailableSize, editor]);

  const calculateTopPosition = React.useCallback(() => {
    if (!isDefined(currentItemElement) || !isDefined(editor)) {
      return 0;
    }
    const { height, top: elementTop } = currentItemElement.getBoundingClientRect();
    const { offsetHeight } = editor;
    const { scrollTop } = document.documentElement;
    const editorHeight = offsetHeight + VALIDATION_MESSAGE_GAP;
    const possibleTop = window.innerHeight + scrollTop - editorHeight - POSITION_PADDING - TOP_BAR_HEIGHT;
    const maxTop = Math.max(possibleTop, POSITION_PADDING);
    const minTop = POSITION_PADDING + scrollTop;
    const topBase = elementTop + scrollTop - TOP_BAR_HEIGHT;
    const elementHeightOffset = height * HALF - offsetHeight * HALF;

    return clamp(minTop, maxTop)(topBase + elementHeightOffset);
  }, [currentItemElement, editor]);

  React.useEffect(() => {
    if (!isEditorVisible) {
      return;
    }
    setLeft(calculateLeftPosition());
    setTop(calculateTopPosition());
  }, [isEditorVisible, calculateLeftPosition, calculateTopPosition]);

  return { left, top };
};
