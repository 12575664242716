import { Navigation } from '@typings';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getOrderDetails, getOrderId } from '../../ducks';
import { getIsMenuItemActive, getIsUserFolderMenuItem } from '../../logic/navigation';
import { getIsOrderClosed } from '../../logic/Orders';
import { Link } from '../various/Link';
import { TextEllipsis } from '../various/TextEllipsis';

interface Props extends Navigation.ItemStyles {
  item: Navigation.UserMenuItem;
  active?: boolean;
  hasChildren?: boolean;
}

export const NavigationItem = ({ item, linkClassName, activeClassName, active }: Props) => {
  const { pathname } = useLocation();
  const linkClassNames = cx(linkClassName, { [activeClassName]: active });
  const orderDetails = useSelector(getOrderDetails);
  const isOrderClosed = getIsOrderClosed(orderDetails.order);
  const selectionId = useSelector(getOrderId);
  const label = <TextEllipsis>{item.label}</TextEllipsis>;

  if (getIsUserFolderMenuItem(item)) {
    return <span className={linkClassNames}>{label}</span>;
  }

  const { type, url, openNewTab } = item;
  const target = openNewTab ? '_blank' : undefined;

  switch (type) {
    case 'link':
      return (
        <Link to={url} external target={target} className={linkClassNames}>
          {label}
        </Link>
      );

    case 'page': {
      const targetUrlBase = `/pages/${url}`;
      const targetUrl = isOrderClosed ? targetUrlBase : `${targetUrlBase}/${selectionId}`;
      const isMenuActive = getIsMenuItemActive(targetUrl, pathname);
      const classNames = cx(linkClassNames, {
        [activeClassName]: isMenuActive,
      });

      return (
        <Link to={targetUrl} className={classNames} target={target}>
          {label}
        </Link>
      );
    }

    default:
      return null;
  }
};
