import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getIsBarcodeScannerEnabled } from '../../../ducks';
import { useModalVisibility } from '../../../utils/hooks';
import Icon, { IconType } from '../Icon';
import { Tooltip } from '../Tooltip';

import styles from './SearchFilter.module.scss';

export const BarcodeScannerButton = () => {
  const { t } = useTranslation(['common']);
  const isBarcodeScannerEnabled = useSelector(getIsBarcodeScannerEnabled);
  const { showModal: showBarcodeScannerModal } = useModalVisibility('BarcodeScannerModal');

  if (!isBarcodeScannerEnabled) {
    return null;
  }

  return (
    <Tooltip content={t('common:barcode_scanner')} disableOnTouchScreen>
      <button type="button" aria-label={t('common:barcode_scanner')} className={styles.scanButton} onClick={showBarcodeScannerModal}>
        <span className={styles.scanLabel}>{t('common:scan_barcode')}</span>
        <Icon type={IconType.Barcode} className={styles.scanIcon} />
      </button>
    </Tooltip>
  );
};
