/* eslint-disable max-len */

import { IconComponentProps } from '@typings';

const ORIGINAL_WIDTH = 20;
const ORIGINAL_COLOR = '#848484';

export const Text = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => (
  <svg width={width} height={width} viewBox="0 0 20 20" fill="none">
    <path
      d="M7.84889 4.37683H10.7501V6.07249H12V3H2V6.07249H3.25046V4.37683H6.36525V13.2543H4.96568V14.5043H9.16174V13.2543H7.84889V4.37683Z"
      fill={color}
    />
    <path
      d="M12 12.5C12 12.2239 12.2239 12 12.5 12H17.5C17.7761 12 18 12.2239 18 12.5V13.5C18 13.7761 17.7761 14 17.5 14H12.5C12.2239 14 12 13.7761 12 13.5V12.5Z"
      fill={color}
    />
    <path
      d="M12 16C12 15.7239 12.2239 15.5 12.5 15.5H17.5C17.7761 15.5 18 15.7239 18 16V17C18 17.2761 17.7761 17.5 17.5 17.5H12.5C12.2239 17.5 12 17.2761 12 17V16Z"
      fill={color}
    />
  </svg>
);
