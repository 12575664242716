import { Cms } from '@typings';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ButtonStyles, buttonStylesConfig } from '../../../../cmsTemplates';
import { getButtonStyles, openButtonStyleEditor } from '../../../../ducks/cms';
import { useToolbarVisibility } from '../../../../utils/hooks';
import { isDefined } from '../../../../utils/is';
import { StyleSelectContextProvider } from '../../context/StyleSelectContext';

import { ContentPartStyleSelect } from './ContentPartStyleSelect';

interface Props {
  currentStyle: string;
  localChanges: Cms.LocalButtonChanges;
  onChange: (value: string) => void;
}

export const ButtonPartStyleSelect = ({ currentStyle, localChanges, onChange }: Props) => {
  const buttonStyles = useSelector(getButtonStyles);
  const dispatch = useDispatch();
  const { hideToolbar } = useToolbarVisibility('Button');

  const handleEdit = React.useCallback(
    (className: string) => {
      const elementStyles = buttonStyles[className];

      if (!isDefined(elementStyles)) {
        return;
      }

      dispatch(
        openButtonStyleEditor({
          elementStyles,
          mode: 'edit',
        }),
      );
      hideToolbar();
    },
    [buttonStyles, dispatch, hideToolbar],
  );

  const handleCreate = React.useCallback(() => {
    const { justifySelf } = localChanges;
    const buttonStyle = buttonStyles[currentStyle] ?? buttonStylesConfig[ButtonStyles.FilledPrimary];

    const elementStyles = {
      ...buttonStyle,
      name: '',
      properties: {
        default: {
          ...buttonStyle.properties.default,
          justifySelf,
        },
        hover: {
          ...buttonStyle.properties.hover,
        },
      },
    };

    hideToolbar();
    dispatch(openButtonStyleEditor({ elementStyles, mode: 'create' }));
  }, [localChanges, buttonStyles, currentStyle, hideToolbar, dispatch]);

  return (
    <StyleSelectContextProvider stylesType="button" currentStyle={currentStyle}>
      <ContentPartStyleSelect onEdit={handleEdit} onCreate={handleCreate} dataTestId="buttonStyleInput" onChange={onChange} />
    </StyleSelectContextProvider>
  );
};
