import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ACCEPT_HEADER_BASE } from '../../../constants/api';
import { getAccessToken, getIsSoftLogoutMode, getLanguageToSet, getUserAccess, resetAppState } from '../../../ducks';
import { showroomApi } from '../../../services';
import { getAuthHeader, getToken } from '../../../utils/auth';
import { isDefined } from '../../../utils/is';

const LOADER_FADE_TIME = 800;

export const LanguageLoaderWrapper = ({ children }: React.WithChildren) => {
  const { t, i18n } = useTranslation();
  const [shouldReset, setShouldReset] = React.useState(false);
  const dispatch = useDispatch();

  const languageToSet = useSelector(getLanguageToSet);
  const isSoftLogout = useSelector(getIsSoftLogoutMode);
  const isViewer = useSelector(getUserAccess) === 'viewer';
  const accessToken = useSelector(getAccessToken);
  const isUpdatingLanguage = isDefined(languageToSet);

  const token = isSoftLogout ? getToken() : accessToken;

  React.useEffect(() => {
    if (isDefined(languageToSet)) {
      const loaderTimeout = setTimeout(() => {
        setShouldReset(true);
      }, LOADER_FADE_TIME);

      return () => clearTimeout(loaderTimeout);
    }

    return () => undefined;
  }, [languageToSet]);

  React.useEffect(() => {
    if (!shouldReset) {
      return;
    }

    if (isViewer || isSoftLogout) {
      showroomApi.updateHeaders(getAuthHeader(ACCEPT_HEADER_BASE, token));
    }

    i18n.changeLanguage(languageToSet);
    dispatch(resetAppState());
    setShouldReset(false);
  }, [dispatch, i18n, shouldReset, isSoftLogout, isViewer, languageToSet, token]);

  return (
    <>
      <div className={cx('initial-loader', { hidden: !isUpdatingLanguage })}>
        <div className="dots">
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </div>
        <div className="messages">{t('common:setting_language', { lng: languageToSet })}</div>
      </div>
      {!shouldReset && children}
    </>
  );
};
