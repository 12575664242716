/* eslint-disable max-len */
import { IconComponentProps } from '@typings';

const WIDHT = 24;
const ORIGINAL_COLOR = '#B284B9';

export const Resize = ({ width = WIDHT, color = ORIGINAL_COLOR }: IconComponentProps) => {
  return (
    <svg width={width} height={width} viewBox="0 0 24 24" fill="none">
      <circle cx="12" cy="12" r="10" transform="rotate(-90 12 12)" fill={color} />
      <path
        d="M13.7594 9.59281C13.6976 9.65462 13.6667 9.72789 13.6667 9.81254V14.1875C13.6667 14.2722 13.6976 14.3454 13.7594 14.4072C13.8214 14.4691 13.8946 14.5 13.9792 14.5C14.0638 14.5 14.1371 14.4691 14.1989 14.4072L16.3864 12.2198C16.4483 12.1578 16.4792 12.0846 16.4792 12C16.4792 11.9154 16.4483 11.8421 16.3864 11.7803L14.1989 9.59281C14.1371 9.53102 14.0638 9.5 13.9792 9.5C13.8946 9.5 13.8214 9.53102 13.7594 9.59281Z"
        fill="#fff"
      />
      <path
        d="M9.7198 14.4072C9.78165 14.3454 9.81256 14.2721 9.81256 14.1875V9.81252C9.81256 9.72783 9.78165 9.65462 9.7198 9.59276C9.65787 9.5309 9.58466 9.5 9.50002 9.5C9.4154 9.5 9.34218 9.5309 9.28031 9.59276L7.09283 11.7802C7.03097 11.8422 7 11.9154 7 12C7 12.0846 7.03097 12.1579 7.09283 12.2197L9.28032 14.4072C9.34218 14.469 9.4154 14.5 9.50003 14.5C9.58466 14.5 9.65787 14.469 9.7198 14.4072Z"
        fill="#fff"
      />
    </svg>
  );
};
