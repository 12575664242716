import { Product } from '@typings';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { CustomImage } from '../../../../components/various/CustomImage';
import { getDefaultImageSize } from '../../../../ducks';
import { getProductImageOrPlaceholderBySize } from '../../../../ducks/helpers';
import { composeVariantName } from '../../../../logic/selectionOverview';

import styles from './ProductCell.module.scss';

interface Props {
  product: Product.Standard;
}

export const ProductCell = ({ product }: Props) => {
  const { t } = useTranslation(['common']);
  const imageSize = useSelector(getDefaultImageSize);
  const image = getProductImageOrPlaceholderBySize(product, imageSize);

  return (
    <div className={styles.container}>
      <div className={styles.image}>
        <CustomImage aspectRatio={1} src={image.src} />
      </div>
      <div className={styles.description}>
        {composeVariantName(product)}
        <span className={styles.sku}>{t('common:art_no', { artNo: product.sku })}</span>
      </div>
    </div>
  );
};
