import { Translations } from '@typings';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { externalLinks } from '../../../paths';
import { Link } from '../../various/Link';
import { SectionFormHeading } from '../../various/SectionHeading';
import { useTranslationsContext } from '../context/TranslationsContext';

import styles from './TranslationsContent.module.scss';

interface Props {
  sectionName: string;
  isEditable: boolean;
  isLoading: boolean;
}

export const TranslationsHead = ({ sectionName, isEditable, isLoading }: Props) => {
  const { t } = useTranslation(['translations']);

  const { isEditing, setIsEditing } = useTranslationsContext();
  const { reset } = useFormContext<Translations.TranslationsDTOAdditional>();

  const handleCancel = React.useCallback(() => {
    reset();
    setIsEditing(false);
  }, [reset, setIsEditing]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  return (
    <>
      <SectionFormHeading
        heading={sectionName}
        isEditing={isEditing}
        isLoading={isLoading}
        isDisabled={!isEditable}
        onCancel={handleCancel}
        onEdit={handleEdit}
      />
      <p className={styles.infoText}>
        <Trans t={t} i18nKey="translations:enable_languages_info">
          Showroom languages can be enabled in Centra AMS → Showroom plugin settings.
          <Link variant="blue" external target="_blank" to={externalLinks.SUPPORT_TRANSLATIONS}>
            Read more
          </Link>
        </Trans>
      </p>
    </>
  );
};
