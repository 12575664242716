/* eslint-disable max-len */
import { IconComponentProps } from '@typings';
const ORIGINAL_WIDTH = 20;
const ORIGINAL_COLOR = '#404040';

export const MoreHorizontal = ({ width = ORIGINAL_WIDTH, color = ORIGINAL_COLOR }: IconComponentProps) => {
  return (
    <svg width={width} height={width} viewBox="0 0 20 20" fill="none">
      <path
        d="M11.8223 9.99996C11.8223 8.99357 11.0064 8.17773 10 8.17773C8.99365 8.17773 8.17782 8.99357 8.17782 9.99996C8.17782 11.0063 8.99365 11.8222 10 11.8222C11.0064 11.8222 11.8223 11.0063 11.8223 9.99996Z"
        fill={color}
      />
      <path
        d="M6.48893 9.99996C6.48893 8.99357 5.67309 8.17773 4.6667 8.17773C3.66032 8.17773 2.84448 8.99357 2.84448 9.99996C2.84448 11.0063 3.66032 11.8222 4.6667 11.8222C5.67309 11.8222 6.48893 11.0063 6.48893 9.99996Z"
        fill={color}
      />
      <path
        d="M17.1556 9.99996C17.1556 8.99357 16.3398 8.17773 15.3334 8.17773C14.327 8.17773 13.5111 8.99357 13.5111 9.99996C13.5111 11.0063 14.327 11.8222 15.3334 11.8222C16.3398 11.8222 17.1556 11.0063 17.1556 9.99996Z"
        fill={color}
      />
    </svg>
  );
};
