import { UnitsDistribution } from '@typings';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { isDefined } from '../../../../../../../utils/is';
import { Button } from '../../../../../NewButton';

export const AddDistributionButton = () => {
  const formData = useWatch<UnitsDistribution.QuantitiesFormData>();
  const { t } = useTranslation(['orders']);

  const totalPresetsItems = Object.values(formData).reduce((acc = 0, item) => {
    if (!isDefined(item)) {
      return acc;
    }

    return acc + item;
  }, 0);

  return (
    <Button size="small" disabled={totalPresetsItems === 0} data-testid="addDistributionsButton" type="submit">
      {t('orders:distribute')}
    </Button>
  );
};
