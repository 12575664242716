import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { getIsLoadingOrderDetails } from '../../../ducks';
import { compiledPaths } from '../../../paths';
import { useRemoveAllProducts } from '../../../utils/hooks';
import { ClearAllButton } from '../../various/ClearAllButton/ClearAllButton';
import { IconType } from '../../various/Icon';
import { PopoverMenu } from '../../various/PopoverMenu';

import styles from './CheckoutActions.module.scss';

export const CheckoutActions = () => {
  const navigate = useNavigate();
  const orderId = useParams<{ id: string }>().id;
  const { t } = useTranslation(['common']);
  const isLoadingOrderDetails = useSelector(getIsLoadingOrderDetails);

  const { removeAllProducts, canRemoveAllProducts } = useRemoveAllProducts({
    onRemove: () => {
      navigate(compiledPaths.PRODUCTS_ORDER({ id: orderId }));
    },
  });

  const isClearAllDisabled = !canRemoveAllProducts || isLoadingOrderDetails;

  const actions = React.useMemo(
    () => [
      {
        isDisabled: isClearAllDisabled,
        key: 'clearAll',
        label: t('common:clear_all'),
        onClick: removeAllProducts,
      },
    ],
    [removeAllProducts, isClearAllDisabled, t],
  );

  return (
    <div className={styles.section}>
      <ClearAllButton
        removeAction={removeAllProducts}
        isDisabled={isClearAllDisabled}
        className={cx(styles.actionButton, styles.hideOnMobile)}
      />
      <div className={styles.mobileTools}>
        <PopoverMenu
          classNameRegular={styles.mobileToolsButton}
          containerClassName={styles.popover}
          name={t('common:more_options')}
          items={actions}
          icon={IconType.MoreHorizontal}
          overflowContainer
        />
      </div>
    </div>
  );
};
